import { slideIn, fade } from './../../animation';
import { GlobalsService } from 'src/app/globals';
import { CurrencyLocaleService } from './../../services/currency-locale.service';
import { EventTicketsService } from './../event-tikets-service/event-tickets.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.css'],
  animations: [slideIn, fade]
})
export class OrderSummaryComponent implements OnInit {

  @Input() promoCode: string;
  @Input() currency: string;
  @Input() couponCodeSent = false;
  @Input() choosedItems: any = {};
  @Input() commission_ils: string;
  @Input() tax_commission = '0.00';
  @Input() total_tax_commission = 0;
  @Input() total_purchaser_commission: string;
  @Input() total_service_commission: string;
  @Input() tax_and_fees = 0;
  @Input() couponTicketId = 0;
  @Input() total_discount = 0;
  @Input() total_ticket_discount = 0;
  @Input() total_price_w_commission: string;
  @Input() isSelectedTicketOutOfStock: boolean;
  @Input() total_platform_fee = 0;

  @Input() hasWaitedTicket: number = 0;
  @Input() hasRegularTicket: number = 0;
  @Input() hasDonateTicket: number = 0;
  @Input() donation_fee_mode: number = 0;
  @Input() waiting_list_mode: boolean = false;

  dir: string | null;

  @Input() isShowSection = true;
  @Input() isShowPromo = true;
  @Input() set setMobile (value) {
    this.isShowSection = !value;
  };

  constructor(private eventTicketsService: EventTicketsService,
              private currencyLocale: CurrencyLocaleService,
              public globals: GlobalsService) {

  }

  ngOnInit(): void {
    this.dir = this.globals.getDir() === 'rtl' ? 'rtl' : 'ltr' ;
  }

  getLocale(currency) {
    return this.currencyLocale.getLocaleForCurrencyPipe(currency)
  }

  checkPromo(couponCode) {
    this.eventTicketsService.checkPromo(couponCode);
  }

  setPromo(event) {}

  toggleSectionShowHide() {
    this.isShowSection = !this.isShowSection;
  }

}
