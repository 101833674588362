;import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SpotifyService {
  account_url = 'https://accounts.spotify.com/api/token';

  constructor(private http: HttpClient) { }

  getToken() {
    const auth_string = Buffer.from(environment.spotify.client_id + ':' + environment.spotify.client_secret).toString('base64');

    return this.http.post(this.account_url, 'grant_type=client_credentials',{
      headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Basic ' + (auth_string)
        })
    });
  }


  search(token: string, type: string = 'artist',  search_query: string = '', offset = 0) {
    return this.http.get('https://api.spotify.com/v1/search?', {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${token}` }),
      params: new HttpParams().set('type', type).set('q', search_query).set('limit', 50).set('offset', offset)
    });
  }


  
}
