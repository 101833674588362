import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'search_by_gateway'})

export class FilterByGatewayPipe implements PipeTransform {

    transform(value, args) {
     // console.log("FilterByGatewayPipe", value, args);
      if (!args || args == '0') {
        return value;
      } else if (value) {
          args = (typeof args == 'string' || args instanceof String) ? args.toLowerCase().trim() : String(args);
          return value.filter(item => {
            for (let key in item) {
              if (key === 'payment_gateways') {
                const gateway = item['payment_gateways'].find(x => x.payment_type == args);
               //  console.log('args,item,gateway:', args, item, gateway, );

                if (gateway !== undefined && (gateway.payment_type.toLowerCase().indexOf(args) !== -1)) {
                  return true;
                }

              }
            }
          });
        }
    }

}
