import { Injectable, LOCALE_ID, Inject  } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CurrencyLocaleService {

  constructor(@Inject(LOCALE_ID) private local_id) { }

  // Angular currency Pipe for PEN is working correctly only with es-PE locale
  getLocaleForCurrencyPipe(currency) {
    if (currency === 'PEN') {
      return 'es-PE';
    } else if (currency === 'MAD') {
      return 'ar_MA';
    } else if (currency === 'THB') {
      return 'th';
    } else if (currency === 'PLN') {
      return 'pl';
    } else {
      return ''
    }
  }

}
