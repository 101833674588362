<div class="approve-waiting-list-modal-header">
  <button class="close-btn" mat-dialog-close>
    <img class="x-close-modal" src="assets/images/svg-icons/close.svg" alt="close">
  </button>
</div>
<div class="approve-waiting-list-modal-body" mat-dialog-content>
  <h3 class="title">{{data.name}}</h3>
  <p class="sub-title">{{'approve-waiting-list-modal.WaitingList' | translate:'Waiting List'}}</p>
  <div class="waiting-tickets-total">
    <p>{{'approve-waiting-list-modal.Tickets' | translate:'Tickets'}}: {{data.count_ticket_waiting_list}}</p>
    <hr>
    <p>{{'approve-waiting-list-modal.TotalValue' | translate:'Total Value'}}: {{data.price * data.count_ticket_waiting_list | currency: data.currency:'symbol':'1.':getLocale(data.currency)}}</p>
  </div>

  <form [formGroup]="form" class="form-grp">
    <p class="form-txt-1">{{'approve-waiting-list-modal.AUTO-PAY' | translate:'AUTO-PAY'}}</p>
    <p class="form-txt-2">{{'approve-waiting-list-modal.txt-2' | translate:'the number of tickets from the waiting list that you would like to approve'}}
      <span matTooltip="Approve multiple tickets from the waiting list. If fewer tickets are available than selected, the maximum possible will be approved.
      If more tickets are available, you'll be prompted to increase the approval quantity"
            matTooltipTouchGestures="on"
            matTooltipClass="waiting-tooltip"
            class="approve-waiting-tooltip">&#9432;</span>
    </p>
    <input type="number" placeholder="400" class="count-input" formControlName="count">
    <app-error-creator *ngIf="form.get('count').touched && form.get('count').invalid" [name]="'count'" [control]="form.controls.count"></app-error-creator>

    <p class="total-charged">{{'approve-waiting-list-modal.Totaltobecharged' | translate:'Total to be charged'}} ({{(data.price*form.controls.count.value > 0 ? data.price*form.controls.count.value : 0) | currency: data.currency:'symbol':'1.':getLocale(data.currency)}})</p>
    <button (click)="approve()" [disabled]="!form.valid || fetching" class="approve-submit">
      <span>{{'approve-waiting-list-modal.APPROVE' | translate:'APPROVE'}}</span>
    </button>
  </form>

</div>
