<ng-container [ngSwitch]="type">
  <!--  DEFAULT VIEW-->
  <ng-container *ngSwitchDefault>
    <div class="card">
      <div class="producer">
        <a [routerLink]="['/producer/' + event.producer_url_identifier]" class="producer-link" aria-label="Producer" href="#">
          <div class="event-producer-logo"
               *ngIf="event.profile_img"
               [ngStyle]="{'backgroundImage': 'url(' + baseProducerImgUrl + event.profile_img + ')'}">
          </div>
          <div class="event-producer-logo"
               *ngIf="!event.profile_img && event.social_img"
               [ngStyle]="{'backgroundImage': 'url(' + event.social_img + ')'}">
          </div>
          <svg *ngIf="!event.profile_img && !event.social_img" width="32" height="32" [data-jdenticon-value]="event.user_id"></svg>
          <p class="event-producer-name">{{event.producer_name}}</p>
        </a>
        <app-producer-follow-edit-btn [producerId]="event.user_id" [userId]="userId" [producerfollowers]="event.followers" [eventId]="event.event_id" (updateFolowers)="updateFolowers($event)"></app-producer-follow-edit-btn>
      </div>
      <a class="event-wrap"
         [routerLink]="[(event.url_friendly) ? '/'+event.url_friendly : '/'+event.event_id]"
         [queryParams]="{ seller_code: 'getin'}"
         [ngStyle]="{'background-image': 'url(' + baseEventImgUrl + event.carousel_image + ')'}"
         attr.aria-label="{{event.title}}"
      >
      </a>
      <div class="event-data">
        <div class="event-info">
          <span class="event-title">{{event.title}}</span>
          <p class="event-location">
<!--            <img src="/assets/images/svg-icons/location.svg" alt="location">-->
            <span>{{event.address}}</span>
          </p>
        </div>
        <div class="event-date-views">
          <p><span class="event-date">{{event.start_date | date:'mediumDate'}}</span></p>
          <div class="event-views" *ngIf="event.views > 999">
            <img src="/assets/images/svg-icons/eye.svg" alt="view">
            <span>{{event.views | shortNumber}}</span>
          </div>
        </div>

      </div>
    </div>
  </ng-container>

  <!--  TYPE-1 VIEW-->
<!--  <ng-container *ngSwitchCase="'type-1'">-->
<!--    <div class="card">-->
<!--      <div class="card-type-1">-->
<!--        <a class="event-wrap-type-1"-->
<!--           [routerLink]="[(event.url_friendly) ? '/'+event.url_friendly : '/'+event.event_id]"-->
<!--           [queryParams]="{ seller_code: 'getin'}"-->
<!--           [ngStyle]="{'backgroundImage': 'url(' + baseEventImgUrl + event.carousel_image + ')'}"-->
<!--        >-->
<!--          <p><span class="event-date">{{event.start_date | date:'MMM, yy'}}</span></p>-->
<!--          <p class="event-views" *ngIf="event.views > 999"><img src="/assets/images/svg-icons/eye.svg" alt="view"><span>{{event.views | shortNumber}}</span></p>-->
<!--        </a>-->
<!--        <div class="event-data-type-1">-->
<!--          <div class="event-info">-->
<!--            <p class="event-location">-->
<!--              <img src="/assets/images/svg-icons/location.svg" alt="location">-->
<!--              <span>{{event.address}}</span>-->
<!--            </p>-->
<!--            <p class="event-title">{{event.title}}</p>-->
<!--          </div>-->
<!--          <div class="producer">-->
<!--            <a [routerLink]="['/producer/' + event.producer_url_identifier]" class="producer-link" aria-label="Producer" href="#">-->
<!--                <div class="event-producer-logo"-->
<!--                     *ngIf="event.profile_img"-->
<!--                     [ngStyle]="{'backgroundImage': 'url(' + baseProducerImgUrl + event.profile_img + ')'}">-->
<!--                </div>-->
<!--                <svg *ngIf="!event.profile_img" width="32" height="32" [data-jdenticon-value]="event.user_id"></svg>-->
<!--              <p class="event-producer-name">{{event.producer_name}}</p>-->
<!--            </a>-->
<!--            <app-producer-follow-edit-btn [producerId]="event.user_id" [userId]="userId" [producerfollowers]="event.followers" [eventId]="event.event_id" (updateFolowers)="updateFolowers($event)"></app-producer-follow-edit-btn>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </ng-container>-->

  <!--  TYPE-2 VIEW-->
  <!--  TYPE-2 mobile view-->
<!--  <ng-container *ngSwitchCase="'type-2-m'">-->
<!--    <div class="card">-->
<!--      <div class="card-type-2-m">-->
<!--        <div class="producer">-->
<!--          <a [routerLink]="['/producer/' + event.producer_url_identifier]" class="producer-link" aria-label="Producer" href="#">-->
<!--            <p class="event-producer-name">{{event.producer_name}}</p>-->
<!--          </a>-->
<!--          <app-producer-follow-edit-btn [producerId]="event.user_id" [userId]="userId" [producerfollowers]="event.followers" [eventId]="event.event_id" (updateFolowers)="updateFolowers($event)"></app-producer-follow-edit-btn>-->
<!--        </div>-->
<!--        <a class="event-wrap-type-2-m"-->
<!--           [routerLink]="[(event.url_friendly) ? '/'+event.url_friendly : '/'+event.event_id]"-->
<!--           [queryParams]="{ seller_code: 'getin'}"-->
<!--           [ngStyle]="{'backgroundImage': 'url(' + baseEventImgUrl + event.carousel_image + ')'}"-->
<!--        >-->
<!--          <p><span class="event-date">{{event.start_date | date:'MMM, yy'}}</span></p>-->
<!--        </a>-->
<!--        <div class="event-data-type-2-m">-->
<!--          <div class="event-info">-->
<!--            <p class="event-title">{{event.title}}</p>-->
<!--            <p class="event-location">-->
<!--              <img src="/assets/images/svg-icons/location.svg" alt="location">-->
<!--              <span>{{event.address}}</span>-->
<!--            </p>-->
<!--            <p class="event-views" *ngIf="event.views > 999">-->
<!--              <img src="/assets/images/svg-icons/eye.svg" alt="view">-->
<!--              <span>{{event.views | shortNumber}}</span>-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </ng-container>-->

  <!--  TYPE-2-big desktop view-->
<!--  <ng-container *ngSwitchCase="'type-2-big'">-->
<!--    <div class="card">-->
<!--      <div class="card-type-2-big">-->
<!--        <div class="producer">-->
<!--          <a [routerLink]="['/producer/' + event.producer_url_identifier]" class="producer-link" aria-label="Producer" href="#">-->
<!--            <p class="event-producer-name">{{event.producer_name}}</p>-->
<!--          </a>-->
<!--          <app-producer-follow-edit-btn [producerId]="event.user_id" [userId]="userId" [producerfollowers]="event.followers" [eventId]="event.event_id" (updateFolowers)="updateFolowers($event)"></app-producer-follow-edit-btn>-->
<!--        </div>-->
<!--        <a class="event-wrap-type-2-big"-->
<!--           [routerLink]="[(event.url_friendly) ? '/'+event.url_friendly : '/'+event.event_id]"-->
<!--           [queryParams]="{ seller_code: 'getin'}"-->
<!--           [ngStyle]="{'backgroundImage': 'url(' + baseEventImgUrl + event.carousel_image + ')'}"-->
<!--        >-->
<!--          <p><span class="event-date">{{event.start_date | date:'MMM, yy'}}</span></p>-->
<!--          <p class="event-views" *ngIf="event.views > 999">-->
<!--            <img src="/assets/images/svg-icons/eye.svg" alt="view">-->
<!--            <span>{{event.views | shortNumber}}</span>-->
<!--          </p>-->
<!--        </a>-->
<!--        <div class="event-data-type-2-big">-->
<!--          <div class="event-info">-->
<!--            <p class="event-title">{{event.title}}</p>-->
<!--            <div class="event-location-views">-->
<!--              <p class="event-location">-->
<!--                <img src="/assets/images/svg-icons/location.svg" alt="location">-->
<!--                <span>{{event.address}}</span>-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </ng-container>-->


  <!--  TYPE-2 desktop view-->
<!--  <ng-container *ngSwitchCase="'type-2'">-->
<!--    <div class="card">-->
<!--      <div class="card-type-2">-->
<!--        <a class="event-wrap-type-2"-->
<!--           [routerLink]="[(event.url_friendly) ? '/'+event.url_friendly : '/'+event.event_id]"-->
<!--           [queryParams]="{ seller_code: 'getin'}"-->
<!--           [ngStyle]="{'backgroundImage': 'url(' + baseEventImgUrl + event.carousel_image + ')'}"-->
<!--        >-->
<!--          <p><span class="event-date">{{event.start_date | date:'MMM, yy'}}</span></p>-->
<!--        </a>-->
<!--        <div class="event-data-type-2">-->
<!--          <div class="event-info">-->
<!--            <p class="event-location">-->
<!--              <img src="/assets/images/svg-icons/location.svg" alt="location">-->
<!--              <span>{{event.address}}</span>-->
<!--            </p>-->
<!--            <p class="event-title">{{event.title}}</p>-->
<!--          </div>-->
<!--          <div class="producer">-->
<!--            <a [routerLink]="['/producer/' + event.producer_url_identifier]" class="producer-link" aria-label="Producer" href="#">-->
<!--              <p class="event-producer-name">{{event.producer_name}}</p>-->
<!--            </a>-->
<!--            <app-producer-follow-edit-btn [producerId]="event.user_id" [userId]="userId"  [eventId]="event.event_id" [producerfollowers]="event.followers" (updateFolowers)="updateFolowers($event)"></app-producer-follow-edit-btn>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </ng-container>-->

  <!--  TYPE-3 VIEW-->
<!--  <ng-container *ngSwitchCase="'type-3'">-->
<!--    <div class="card">-->
<!--      <div class="card-type-3">-->
<!--        <a class="event-wrap-type-3"-->
<!--           [routerLink]="[(event.url_friendly) ? '/'+event.url_friendly : '/'+event.event_id]"-->
<!--           [queryParams]="{ seller_code: 'getin'}"-->
<!--           [ngStyle]="{'backgroundImage': 'url(' + baseEventImgUrl + event.carousel_image + ')'}"-->
<!--        >-->
<!--          <p><span class="event-date">{{event.start_date | date:'MMM, yy'}}</span></p>-->
<!--          <p class="event-views" *ngIf="event.views > 999"><img src="/assets/images/svg-icons/eye.svg" alt="view"><span>{{event.views | shortNumber}}</span></p>-->
<!--        </a>-->
<!--        <div class="event-data-type-3">-->
<!--          <div class="event-info">-->
<!--            <p class="event-location">-->
<!--              <img src="/assets/images/svg-icons/location.svg" alt="location">-->
<!--              <span>{{event.address}}</span>-->
<!--            </p>-->
<!--            <p class="event-title">{{event.title}}</p>-->
<!--          </div>-->
<!--          <div class="producer">-->
<!--            <a [routerLink]="['/producer/' + event.producer_url_identifier]" class="producer-link" aria-label="Producer" href="#">-->
<!--              <p class="event-producer-name">{{event.producer_name}}</p>-->
<!--            </a>-->
<!--            <app-producer-follow-edit-btn [producerId]="event.user_id" [userId]="userId" [producerfollowers]="event.followers" [eventId]="event.event_id" (updateFolowers)="updateFolowers($event)"></app-producer-follow-edit-btn>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </ng-container>-->

  <!--  TYPE-4 VIEW-->
<!--  <ng-container *ngSwitchCase="'type-4'">-->
<!--    <div class="card">-->
<!--      <div class="card-type-4">-->
<!--        <div class="producer">-->
<!--          <a [routerLink]="['/producer/' + event.producer_url_identifier]" class="producer-link" aria-label="Producer" href="#">-->
<!--            <p class="event-producer-name">{{event.producer_name}}</p>-->
<!--          </a>-->
<!--          <app-producer-follow-edit-btn [producerId]="event.user_id" [userId]="userId" [producerfollowers]="event.followers" [eventId]="event.event_id" (updateFolowers)="updateFolowers($event)"></app-producer-follow-edit-btn>-->
<!--        </div>-->
<!--        <a class="event-wrap-type-4"-->
<!--           [routerLink]="[(event.url_friendly) ? '/'+event.url_friendly : '/'+event.event_id]"-->
<!--           [queryParams]="{ seller_code: 'getin'}"-->
<!--           [ngStyle]="{'backgroundImage': 'url(' + baseEventImgUrl + event.carousel_image + ')'}"-->
<!--        >-->
<!--          <p><span class="event-date">{{event.start_date | date:'mediumDate'}}</span></p>-->
<!--        </a>-->
<!--        <div class="event-data-type-4">-->
<!--          <div class="event-info">-->
<!--            <p class="event-title">{{event.title}}</p>-->
<!--            <div class="event-location-views">-->
<!--              <p class="event-location">-->
<!--                <img src="/assets/images/svg-icons/location.svg" alt="location">-->
<!--                <span>{{event.address}}</span>-->
<!--              </p>-->
<!--              <p class="event-views" *ngIf="event.views > 999"><img src="/assets/images/svg-icons/eye.svg" alt="view"><span>{{event.views | shortNumber}}</span></p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </ng-container>-->

  <!--  TYPE-5 VIEW-->
  <ng-container *ngSwitchCase="'type-5'">
    <div class="card type-5-card">
      <div class="producer">
        <a [routerLink]="['/producer/' + event.producer_url_identifier]" class="producer-link" aria-label="Producer" href="#">
          <div class="event-producer-logo"
               *ngIf="event.profile_img"
               [ngStyle]="{'backgroundImage': 'url(' + baseProducerImgUrl + event.profile_img + ')'}">
          </div>
          <div class="event-producer-logo"
               *ngIf="!event.profile_img && event.social_img"
               [ngStyle]="{'backgroundImage': 'url(' + event.social_img + ')'}">
          </div>
          <svg *ngIf="!event.profile_img && !event.social_img" width="32" height="32" [data-jdenticon-value]="event.user_id"></svg>
          <p class="event-producer-name">{{event.producer_name}}</p>
        </a>
        <app-producer-follow-edit-btn [producerId]="event.user_id" [userId]="userId" [producerfollowers]="event.followers" [eventId]="event.event_id" (updateFolowers)="updateFolowers($event)"></app-producer-follow-edit-btn>
      </div>
      <a class="event-wrap"
         [routerLink]="[(event.url_friendly) ? '/'+event.url_friendly : '/'+event.event_id]"
         [queryParams]="{ seller_code: 'getin'}"
         [ngStyle]="{'background-image': 'url(' + baseEventImgUrl + event.carousel_image + ')'}"
         attr.aria-label="{{event.title}}"
      >
      </a>
      <div class="event-data">
        <div class="event-info">
          <span class="event-title">{{event.title}}</span>
          <p class="event-location">
            <!--            <img src="/assets/images/svg-icons/location.svg" alt="location">-->
            <span>{{event.address}}</span>
          </p>
        </div>
        <div class="event-date-views">
          <p><span class="event-date">{{event.start_date | date:'mediumDate'}}</span></p>
          <div class="event-views" *ngIf="event.views > 999">
            <img src="/assets/images/svg-icons/eye.svg" alt="view">
            <span>{{event.views | shortNumber}}</span>
          </div>
        </div>

      </div>
    </div>
  </ng-container>

</ng-container>
