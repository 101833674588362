import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {interval, timer, Subscription } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { Router } from '@angular/router';
@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.css']
})

export class CountdownTimerComponent implements OnInit, OnDestroy {
  @Input() countDownTimestamp: any; // seconds of our timer (not a timestamp!!!)
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  startTimer: Subscription;
  show: boolean;

  constructor(private router: Router) { }

  ngOnInit() {

    this.countDownTimestamp = +this.countDownTimestamp; // Convert to number
    console.log('Timer countdown! countDownTimestamp:', this.countDownTimestamp);
    if (this.countDownTimestamp >= 0) {
      this.show = true;
      const timerInterval = interval(1000); // 1s
      // Observable that will emit only after 'timestamp' seconds
      const timer$ = timer(this.countDownTimestamp);
      // Observable that will emit values 'timestamp' times with intervals of 1 second
      const countDown$ = timerInterval.pipe(take(this.countDownTimestamp));

      this.startTimer = countDown$.subscribe(val => {
            // console.log(val, this.countDownTimestamp);
            this.countDownTimestamp = this.countDownTimestamp - 1;
            // Time calculations for days, hours, minutes and seconds
            this.days = Math.floor(this.countDownTimestamp / (60 * 60 * 24));
            this.hours = Math.floor((this.countDownTimestamp % (60 * 60 * 24)) / (60 * 60));
            this.minutes = Math.floor((this.countDownTimestamp % (60 * 60)) / ( 60));
            this.seconds = Math.floor((this.countDownTimestamp % (60)) / 1);

            if (this.countDownTimestamp == 0) {
              this.show = false;
              this.startTimer.unsubscribe();
              const currentUrl = this.router.url;
              setTimeout(() => {
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                  this.router.navigate([currentUrl]);
                });
              }, 1500);
            }
          }
        );

    } else {
      this.show = false;
    }

  }

  ngOnDestroy() {
    if (this.countDownTimestamp > 0 || this.startTimer) {
      this.startTimer.unsubscribe();
    }
  }
}
