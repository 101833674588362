import { EventService } from '../../services/event.service';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-salesman-stats-breakdown',
  templateUrl: './salesman-stats-breakdown.component.html',
  styleUrls: ['./salesman-stats-breakdown.component.css']
})
export class SalesmanStatsBreakdownComponent implements OnInit {
  @ViewChild('chargeCashForm') chargeCashForm: NgForm;
  sellerData: any;
  userType: string;
  eventId: number;
  currencyCode: string;
  statsBreakdownTotals = {
    female: 0,
    male: 0,
    unknown_gender: 0,
    cred_sum: 0,
    cash_sum: 0
  };

  constructor(private eventService: EventService, public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data) {
    this.sellerData = this.data.salesmanData;
    this.userType = this.data.extraData.userType.toString();
    this.eventId = this.data.extraData.eventId;
    this.currencyCode = this.data.extraData.currencyCode;
  }

  ngOnInit() {
    
    if(this.sellerData) {
      for(const record of this.sellerData) {        
        this.statsBreakdownTotals.female = this.statsBreakdownTotals.female + +record.female;
        this.statsBreakdownTotals.male = this.statsBreakdownTotals.male + +record.male;
        this.statsBreakdownTotals.unknown_gender = this.statsBreakdownTotals.unknown_gender + +record.unknown_gender;
        this.statsBreakdownTotals.cred_sum = this.statsBreakdownTotals.cred_sum + +record.cred_sum;
        this.statsBreakdownTotals.cash_sum = this.statsBreakdownTotals.cash_sum + +record.cash_sum;
      }
      this.statsBreakdownTotals.female = this.statsBreakdownTotals.female;
      this.statsBreakdownTotals.male = this.statsBreakdownTotals.male;
      this.statsBreakdownTotals.unknown_gender = this.statsBreakdownTotals.unknown_gender;
      this.statsBreakdownTotals.cred_sum = this.statsBreakdownTotals.cred_sum;
      this.statsBreakdownTotals.cash_sum = this.statsBreakdownTotals.cash_sum;
    }
    
  }

  onSubmit() {
    const cashToCharge = this.chargeCashForm.controls.cashToCharge.value;    
    this.eventService.updateCash(this.sellerData[0].salesman_id, this.eventId, cashToCharge).subscribe(
      (response: any) => {
        this.dialogRef.close(response);
      },
      error => this.dialogRef.close(error)
    );
  }

  onClose() {
    this.dialogRef.close(false);
  }

}
