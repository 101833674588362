import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import $ from 'jquery';

@Component({
  selector: 'app-cms-edit-user',
  templateUrl: './cms-edit-user.component.html',
  styleUrls: ['./cms-edit-user.component.css']
})
export class CmsEditUserComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {

    $('#wrapper').css({'width': '100%'});

  }

  ngOnDestroy() {

    //Close full screen
    $('#wrapper').removeAttr('style');
    
  }

  onEditUser(form: NgForm){

  }

}
