<div class="row">
  <div class="col-md-12 col-xs-12">
    <h1>מדיניות ביטולים והחזרים</h1>

    <p><span>ביטול כרטיס שנרכש לאירוע וקבלת החזר כספי בעבור כרטיס מבוטל תתאפשר אך ורק בהתאם למדיניות הביטולים הכפופה לחוק. הרוכש יוכל לבטל כרטיס שהוזמן לאירוע באמצעות פניה למארגן האירוע. </span><br class="kix-line-break" />
    <span>החוק קובע כי אפשר לבטל עסקה לרכישת שירותי נסיעה, הארחה, חופש ובילוי שהתבצעה ב&quot;מכר מרחוק&quot; בתוך 14 יום מיום ביצוע העסקה, או מיום קבלת טופס הגילוי, לפי המאוחר ובתנאי שמועד ביטול העסקה חל בתוך 7 ימים שאינם ימי מנוחה לפני מועד מתן השירות. אם העסקה בוטלה &quot;ברגע האחרון&quot; &ndash; פחות מ &ndash; 7 ימים לפני מועד מתן השירות, זכות הביטול היא כקבוע בטופס הגילוי.</span></p>

    <p><span>ביטול כרטיס על ידי רוכש וקבלת החזר כספי עבור כרטיס שבוטל תהיה כפופה לאישורו של מארגן האירוע וביצוע ההחזר מותנה בקיומה של יתרת כספים מספקת בחשבונו. יובהר כי ככל ואישר מארגן האירוע את ההחזר הכספי לרוכש, ובתנאי שבחשבונו יתרה מספקת לצורך ביצוע ההחזר, יבוצע ההחזר בפועל בתוך 30 יום ממועד מתן האישור לביטול הרכישה על ידי מארגן האירוע. במקרה של ביטול רכישה לא תוחזר עמלת הרכישה.</span></p>

    <p><span>בכל מקרה בו בוטל אירוע על ידי מארגן האירוע, יבוטלו הכרטיסים לאותו האירוע וידרש לבצע החזר לכל רוכשי הכרטיסים לאירוע המבוטל עד לגובה הכרטיס הנרכש, אך למעט עמלת הרכישה. הנהלת האתר לא תהיה אחראית כלפי רוכש או כל צד שלישי בגין אירוע אשר בוטל, לרבות במקרה שבו אין יתרת כספים מספקת בחשבונו של מארגן האירוע. בכל מקרה בו יחולו עלויות בגין ביצוע הביטול ו/או הזיכוי, יחולו הן על הרוכש ויקוזזו מהסכום המוחזר.</span></p>

    <p><span>לפרטים נוספים וביטולים ניתן ליצור קשר במייל - <a href="mailto:Support@get-in.com">Support@get-in.com</a></span></p>
  </div>
</div>
