import { Pipe, PipeTransform } from '@angular/core';
import { getLocaleDirection } from '@angular/common';

const bidiExtractCurrencyValueAndSymbol =
  /(?<symbolLTR>^\D*)(?<value>[\d+,*]*\.?[\d*])(?<symbolRTL>\D*$)/;
@Pipe({
  name: 'shortNumber',
})
export class ShortNumberPipe implements PipeTransform {
  transform(
    value: number | string | null,
    format?: 'currency' | string,
    locale?: string,
    args?: any
  ): any {
    let prefix = null;
    if (value === null) return '0';
    if (value === 0) return '0';
    if (format === 'currency') {
      const {
        symbolLTR,
        symbolRTL,
        value: currencyValue,
      } = bidiExtractCurrencyValueAndSymbol.exec(value.toString()).groups;
      const currencyPrefix =
        getLocaleDirection(locale) === 'ltr' ? symbolLTR : symbolRTL;
      value = parseFloat(currencyValue.replace(/,/g, ''));
      prefix = currencyPrefix;
    }
    if (typeof value !== 'number') {
      return value;
    }

    const num = Math.abs(Number(value));

    if (num >= 1000) {
      return `${prefix ? prefix : ''}${parseFloat((num / 1000).toFixed(1))}K`;
    } else {
      return `${prefix ? prefix : ''}${num}`;
    }
    return null;
  }
}
