import {LocalizationService} from './../services/localization.service';
import {Subscription} from 'rxjs';
import {AppResponse} from '../interfaces/app-response.interface';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EventService} from '../services/event.service';
import {GlobalsService} from '../globals';
import {DialogService} from '../services/dialog.service';
import {fade, slideIn, SlideInOutAnimation} from '../animation';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {emailRegex, googleAnalyticsUa} from '../regex';
import Quill from 'quill';
import ImageResize from 'quill-image-resize-module';
import {EventData} from '../interfaces/event-data.interface';
// import {DomSanitizer} from '@angular/platform-browser';
// import {ClipboardService} from 'ngx-clipboard';
import {environment} from '../../environments/environment';
import { NavbarService } from '../services/navbar.service';
import {RudderstackService} from "../services/rudderstack.service";

Quill.register('modules/imageResize', ImageResize);

@Component({
  selector: 'app-sales-promotion',
  templateUrl: './sales-promotion.component.html',
  styleUrls: ['./sales-promotion.component.css'],
  animations: [SlideInOutAnimation, slideIn, fade]
})

export class SalesPromotionComponent implements OnInit, AfterViewInit, OnDestroy {
  getin_base_url = environment.getin_base_url;
  images_base_url = environment.images_base_url;
  localeId: string;
  eventData: EventData;
  endDateSubscribtion: Subscription;
  bootstrapBoolean: boolean = false;
  fireAsyncValidation: boolean = false;
  submitted: boolean = false;
  formsubmited = false;
  location : any;
  feedUrl;
  event_id: number = null;
  trackFB: boolean;
  fbChecked: any = {};

  seoStep: UntypedFormGroup;
  event: UntypedFormGroup;
  pixelsArray: UntypedFormArray = this.formBuilder.array([]);

  constructor(
    // private sanitizer: DomSanitizer,
    // private clipboardService: ClipboardService,
    private formBuilder: UntypedFormBuilder,
    private eventService: EventService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public globals: GlobalsService,
    private dialogService: DialogService,
    public localizationService: LocalizationService,
    private navbar: NavbarService,
    public rudderstackService: RudderstackService,
  ) {
    this.localeId = this.globals.getLocaleId();
  }

  ngAfterViewInit() {
    // document.getElementById('footer').style.display = 'none';
    this.rudderstackService.page('Event management','Sales Promotion');
  }

  ngOnInit() {
    this.navbar.setIsNewUi(true);
    setTimeout(() => {this.navbar.noPadding = 'removePadding';}, 200);
    this.navbar.hide();
    if (this.activatedRoute.params) {
      this.activatedRoute.params.subscribe(params => {
        if (this.bootstrapBoolean) {
          this.resetAllData(+this.event_id, this.event);
        }
        this.event_id = params['event_id'];
        this.getEventData();
      });
    }
  }

  closePage() {
    this.router.navigate(['/dashboard']);
  }

  ngOnDestroy() {
    this.navbar.setIsNewUi(false);
    // this.navbar.show();
    this.navbar.noPadding = '';
  }

  resetAllData(oldEventId: number, oldEvent: any) {
    this.eventService.setEventDraft(oldEventId, 'seo', {seo: oldEvent.value}).subscribe(
      (response: AppResponse) => {},
      (error: AppResponse) => this.dialogService.alertsModal((error.code) ? 'editEvent' : 'errors', (error.code) ? error.code : 4, 5000, false, error.log_id)
    );
    this.eventData = undefined;
    this.bootstrapBoolean = false;
    this.fireAsyncValidation = false;
    this.feedUrl = undefined;
    this.formsubmited = false;
  }

  getEventData() {
    this.eventService.getEventData(this.event_id, 'seo').subscribe(
      (eventData: {data: EventData}) => {
        this.eventData = eventData.data;
        this.formBootstrap();
      }
    )
  }

  formBootstrap() {
    this.seoStep = this.initSeoStep();
    this.event = this.initEventForm();
    this.bootstrapBoolean = true;
  }

  initSeoStep(): UntypedFormGroup {
    let seo = (this.eventData) ? this.eventData.seo : undefined;
    if(seo && seo.marketing_contact && seo.marketing_contact.match(/^0[\d]{1,2}[-]?[\d]{3}[-]?[\d]{4}$/)) {
      if(seo.marketing_contact.charAt(0) === '0') {
        seo.marketing_contact = seo.marketing_contact.substr(1);
      }
      seo.marketing_contact = '+972' + seo.marketing_contact;
    }
    this.pixelsArray = this.initFBStep();

    return this.formBuilder.group({
      facebook_pixels: this.pixelsArray,
      // facebook_pixel_id: this.formBuilder.control((seo && seo.facebook_pixel_id) ? seo.facebook_pixel_id : ''),
      // facebook_track_thanks: new FormControl((seo && seo.facebook_track_thanks) ? seo.facebook_track_thanks : '', {updateOn: 'change', validators: Validators.maxLength(30)}),
      google_analytics_ua: this.formBuilder.control((seo && seo.google_analytics_ua) ? seo.google_analytics_ua : '', [Validators.maxLength(100), Validators.pattern(googleAnalyticsUa)]),
      google_leads: this.formBuilder.control((seo && seo.google_leads) ? seo.google_leads : ''),
      marketing_contact: this.formBuilder.control((seo && seo.marketing_contact) ? seo.marketing_contact : ''),
      contact_email: this.formBuilder.control((seo && seo.contact_email) ? seo.contact_email : '', Validators.pattern(emailRegex)),
      contact_facebook: this.formBuilder.control((seo && seo.contact_facebook) ? seo.contact_facebook : '', Validators.maxLength(50)),
      phoneCountryCode: ''
    });
  }

  initFBStep() {
    const pixels = (this.eventData) ? this.eventData.seo.facebook_pixels : [];
    const group = {};
    const pixelsForm = new UntypedFormArray([]);
    if (pixels) {
      pixels.forEach((pixel, i) => {
        group['facebook_pixel_id'] = new UntypedFormControl((pixel && pixel.pixel_id) ? pixel.pixel_id : '', {updateOn: 'blur', validators: [Validators.required, Validators.minLength(1)]});
        group['facebook_track_thanks'] = new UntypedFormControl((pixel && pixel.type_name) ? pixel.type_name : '',{updateOn: 'blur', validators: [Validators.minLength(1), Validators.maxLength(30), Validators.required]});
        pixelsForm.push(this.formBuilder.group(group));
        this.fbChecked[i] = true;
        // return this.formBuilder.group({
        //   pixel_id: this.formBuilder.control((pixel && pixel.pixel_id) ? pixel.pixel_id : ''),
        //   type_name: new FormControl((pixel && pixel.type_name) ? pixel.type_name : '', {updateOn: 'change', validators: Validators.maxLength(30)}),
        // });
      });
    }
    return pixelsForm;
  }

  addPixel() {
    const group = {
      facebook_pixel_id: new UntypedFormControl( '', {validators: [Validators.required, Validators.minLength(1)]}),
      facebook_track_thanks: new UntypedFormControl( '', { updateOn: 'change', validators: [Validators.minLength(1), Validators.maxLength(30), Validators.required] })
    }
    this.pixelsArray.push(this.formBuilder.group(group));
    this.pixelsArray.length ? this.fbChecked[this.pixelsArray.length-1] = false : false;
  }

  deletePixel(id) {
    this.pixelsArray.removeAt(id);
  }

  initEventForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      seo: this.seoStep,
    }, { updateOn: 'blur' });
  }

  submitForm() {
    this.globals.startLoader();
    if(this.event.invalid && this.pixelsArray.invalid) {
      this.dialogService.alertsModal('errors', 18, 5000, false );
      this.markFormGroupTouched(this.event);
     // this.submitted = true;
      this.globals.stopLoader();
      return;
    } else {
       this.setEventData();
    }
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  setEventData() {
    this.formsubmited = true;
    this.eventService.setEventData(this.event_id, 'seo', {seo: this.event.value}).subscribe(
      (result: AppResponse) => {
        this.router.navigate(['/dashboard'])
          .then(() => {
            this.dialogService.alertsModal('success', 'seoUpdated', 3000, true)
          });
        this.globals.stopLoader();
      },
      (error: AppResponse) => {
        this.dialogService.alertsModal((error.code) ? 'editEvent' : 'errors', (error.code) ? error.code : 4 , 5000, false, error.log_id);
        this.globals.stopLoader();
      }
    )
  }

  openInfo(content: string) {
    this.dialogService.infoDialog(content);
  }

  checkInputChange(event: Event, index: number) {
    // console.log('checkInputChange', (event.target as HTMLInputElement).value);
    if (!event || !(event.target as HTMLInputElement).value) {
      this.fbChecked[index] = false;
      this.pixelsArray.controls[index].patchValue({facebook_track_thanks: ''});
    }
  }

  setFBtrack(eFlag: any, index) {
    const flag = eFlag.checked;
    // console.log('setFBtrack', flag, index);
    const InputValue = this.pixelsArray.controls[index]['controls']['facebook_pixel_id'].value;
    const isBlank = !InputValue;
    if (!isBlank) {
      this.fbChecked[index] = !this.fbChecked[index];
      if (this.pixelsArray.controls.length > 1) {
        this.pixelsArray.controls.forEach((control, i) => {
          // console.log('pixelsArray', this.pixelsArray, control['controls']['facebook_pixel_id'].value, InputValue, i, index);
          if (control['controls']['facebook_pixel_id'].value != InputValue && i != index) {
            // console.log('Was insert unique Pixel id', control['controls']['facebook_pixel_id'].value, InputValue, i, index);
            this.pixelsArray.controls[index].patchValue({facebook_track_thanks: (!isBlank && this.fbChecked[index]) ? 'AllPixelEvents' : ''}); // clear 'Choose conversion type'
          } else if (i != index) {
            this.dialogService.alertsModal('coupons', 'uniqueness', 3000, false);
            this.fbChecked[index] = false;
            // console.log('Please insert unique Pixel id', control['controls']['facebook_pixel_id'].value, InputValue, i, index);
          }
        });
      } else {
        this.pixelsArray.controls[index].patchValue({facebook_track_thanks: (!isBlank && this.fbChecked[index]) ? 'AllPixelEvents' : ''});
      }
    } else {
      this.fbChecked[index] = false;
    }
    this.pixelsArray.controls[index].updateValueAndValidity();
    console.log(this.pixelsArray.controls[index]['controls']['facebook_pixel_id'].value, isBlank, this.pixelsArray.controls, this.fbChecked[index]);
    // console.log(this.pixelsArray.controls[index]['controls']['facebook_track_thanks'].value, isBlank, flag, this.fbChecked[index], (!isBlank && this.fbChecked[index]) ? 'AllPixelOptions' : 'False!!!');
  }

  setErrorOnphoneNumber(hasErrorfromNg2TelInput) {
    const hasError = this.globals.hasErrorOnPhoneNumber(hasErrorfromNg2TelInput, this.event.get('seo.marketing_contact').value);
    const actionOnSetErrors = hasError ? {'invalid': true} : null;
    this.event.get('seo.marketing_contact').setErrors(actionOnSetErrors);
  }

  setMarketingPhoneNumber(phoneNumber) {
    this.event.get('seo.marketing_contact').patchValue(phoneNumber);
  }

  telInputObject(obj) {
    if(this.event.get('seo.marketing_contact').value) {
      obj.setNumber(this.event.get('seo.marketing_contact').value);
    }
    this.event.get('seo.phoneCountryCode').patchValue(obj.s.iso2);
  }

  onCountryChange(obj) {
    this.event.get('seo.phoneCountryCode').patchValue(obj.iso2);
  }

}
