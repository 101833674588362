import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as solanaWeb3 from '@solana/web3.js';
import { interval, Subject, Subscription, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

// declare let window: any;


@Injectable({
  providedIn: 'root'
})
export class SolanaConnectService {

  solConnection: solanaWeb3.Connection | null;
  wallet: solanaWeb3.Keypair | null;
  airdropSignature: any | null;
  recieverWallet: any | null;
  provider;
  cryptoCurrencies = ['ethereum,binancecoin,binance-coin,bitcoin,matic-network,fantom,xdai,usdc,busd,tether,solana'];
  siteCurrencies = ['usd'];
  claster: any = environment.solana;

  private timerSubscription: Subscription[] = [];
  private refreshTimer = new Subject<any>();
  timerStatus = this.refreshTimer.asObservable();
  private api_base_url = environment.api_base_url;

  constructor(private http: HttpClient) {
    // this.checkProviders();
    // this.getConnection();
  }

  async getConnection() {
    const publicKey = new solanaWeb3.PublicKey('6ff2vQKmc95BHNKJGT9Pcq21hxKt3oJvdzwozW666Gjz');
    this.solConnection = await new solanaWeb3.Connection(solanaWeb3.clusterApiUrl('mainnet-beta', this.claster.tls),'confirmed'); // testnet, devnet, mainnet-beta
    // this.solConnection = new solanaWeb3.Connection('https://rpc.theindex.io','finalized');
    this.solConnection = await new solanaWeb3.Connection('https://rpc.ankr.com/solana','confirmed');
    // this.solConnection = await new solanaWeb3.Connection('https://api.mainnet-beta.solana.com', { commitment: "confirmed", wsEndpoint: 'ws://api.mainnet-beta.solana.com' });
    console.log('solConnection', publicKey, this.solConnection);
    // const nonce = await this.solConnection.getNonce(publicKey);
    // const tokenAccountBalance = await this.solConnection.getTokenAccountBalance(publicKey);
    // const getMultipleAccountsInfo = await this.solConnection.getMultipleAccountsInfo([publicKey]);
    // console.log('nonce', nonce,); // tokenAccountBalance, getMultipleAccountsInfo

    const account = this.solConnection.getAccountInfo(publicKey);
    // const account = await this.solConnection.getParsedAccountInfo(publicKey);
    console.log('account', account);
        account.then(info => {
          console.log('account info', info);
          const accountBalance =  info ? (info.lamports / solanaWeb3.LAMPORTS_PER_SOL) : 0;
          console.log('accountBalance', accountBalance);
        }).catch(error => {
          console.log('getAccountInfo Error: ', error);
        });
  }

  startTimer() {
    this.stopTimer();
    // console.log('Starting the timer');
    const timerStatus = interval(1000).subscribe(event => {
      if (event >= 30) {
        this.refreshTimer.next(event);
        this.stopTimer();
      }
    });
    this.timerSubscription.push(timerStatus);
  }

  stopTimer(): void {
    // console.log('Timer stopped');
    this.timerSubscription.forEach(subscription => subscription.unsubscribe());
  }

  getCoinGecko(siteCurrencies = null) {
    let tx;
    tx = {ids: this.cryptoCurrencies, vs_currencies: siteCurrencies ? siteCurrencies : this.siteCurrencies};
    const url = 'https://api.coingecko.com/api/v3';
    return this.http.get(url + '/simple/price', {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }),
      params: tx
    })
    .pipe(
      map((response: any) => {
        // console.log('api.coingecko simplePrice value: ', response);
        return response;
      }),
      catchError( error => {
        console.log('api.coingecko simplePrice error: ', error);
        return throwError(error);
    }))
    .toPromise();
  }

  saveSolanaTransaction (data) {
		return this.http.post(this.api_base_url + '/api/solana-connect-response', data, {
			headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
		});
	}

  getTransactionByUrlHash(hash: string) {
		return this.http.get(this.api_base_url + '/api/check-solana-request/' + hash,
		{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
	}

  getAllPendingTransactions() {
    return this.http.get(this.api_base_url + '/api/user-solana-pending-request', {
			headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
		});
  }

  checkProviders() {
    window.onload = () => {
      if ('phantom' in window) {
        const anyWindow: any = window;
        const provider = anyWindow.phantom?.solana;

        if (provider?.isPhantom) {
          // console.log('phantom: ', provider?.isPhantom);
        }
      }
      // console.log('Window => phantom => solana: ', window);
    }
  }

}
