import { ErrorCodesService } from '../code-strings/error_codes';
import { ReaderService } from './../services/reader.service';
import { DialogService } from '../services/dialog.service';
import { GlobalsService } from './../globals';
import { NavbarService } from '../services/navbar.service';
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { EventService } from '../services/event.service';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { environment } from '../../environments/environment';
import { WalletConnectService } from '../services/wallet-connect.service';


@Component({
	selector: 'app-reader-participants',
	templateUrl: './reader-participants.component.html',
	styleUrls: ['./reader-participants.component.css']
})

export class ReaderParticipantsComponent implements OnInit, OnDestroy {
	getin_base_url = environment.getin_base_url;
	images_base_url = environment.images_base_url;
	@ViewChild('myCarousel') myCarousel: NguCarousel<any>;
	@HostListener('window:onQRReceived', ['$event'])
	onQRReceived (event) {
		this.confirmEnterParaticipant(event.detail);
	}
public carouselTile: NguCarouselConfig = {
	grid: { xs: 1, sm: 2, md: 4, lg: 4, all: 0 },
	slide: 1,
	speed: 250,
	load: 2,
	velocity: 0.2,
	touch: true,
	easing: 'cubic-bezier(0, 0, 0.2, 1)',
	point: {
		visible: true,
		hideOnSingleSlide: false,
	}
};
	reader_id: number;
	participants;
	event_title = '';
	reader_title = '';
	reader_participants_scaned;
	event_participants_scaned;
	filterText;
	inApp = false;
	show_top_bar = true;
	error_text = '';
	confirmedParticipant: any;
	associatedParticipant: any;
	showConfirmedParticipant = false;
	responseCode: number;
	inviterName: any;
	firstAttempt = true;
	scanData = [];
	pushRtlPullLtr: string;
	pullRtlPushLtr: string;
	localeId: string;
  apply_id_requests = [];
  interval;
  isProd: boolean = environment.getin_base_url === 'https://get-in.com';

	constructor(
		private eventService: EventService,
		private authService: AuthService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		public globals: GlobalsService,
		private navbarService: NavbarService,
		private diagloServie: DialogService,
		private readerService: ReaderService,
		private errorCodesService: ErrorCodesService,
		private cdr: ChangeDetectorRef,
    private walletConnectService: WalletConnectService
		) {
			this.navbarService.customWrapperClass = 'full-screen';
			if (location.search.indexOf('camscan') > 0) {
				this.show_top_bar = false;
				this.navbarService.hide();
				console.log('show_top_bar ', this.show_top_bar);
			} else {
				this.navbarService.show();
			}

			this.activatedRoute.params.subscribe(
				(params) => {
					this.reader_id = +params['reader_id'];
				}
			);
			this.localeId = this.globals.getLocaleId();
			this.pushRtlPullLtr = this.globals.getDir() === 'rtl' ? 'push' : 'pull';
			this.pullRtlPushLtr = this.globals.getDir() === 'rtl' ? 'pull' : 'push';
			this.carouselTile['RTL'] = this.globals.getDir() === 'rtl';
		}

	ngOnInit() {
		this.inApp = this.globals.inApp();
		this.getReaderData();
		this.navbarService.setIsNewUi(true);
		this.navbarService.hideHeader();
		// setTimeout(() => {this.navbarService.noPadding = 'removePadding';}, 200);
		this.navbarService.hide();
		const wrapper = document.getElementById('wrapper');
		if (wrapper) {
		  wrapper.style.paddingTop = '0';
		}
	}

	getReaderData () {
		this.globals.startLoader();
			this.eventService.getReaderData(this.reader_id).subscribe(
				(response: any) => {
					this.globals.stopLoader();
					this.participants = response.data.participants;
					this.reader_participants_scaned = response.data.reader_paraticipants_scaned ? response.data.reader_paraticipants_scaned: 0;
					this.event_participants_scaned = response.data.events_participants_scaned ? response.data.events_participants_scaned: 0;
				},
				error => {
					this.globals.stopLoader();
					if (error.code === 666) {
						this.authService.removeReaderApiKeyAndId();
						this.router.navigate(['/reader-signin']);
					} else {
            console.log('MYERR', error);
            this.handleTokenErrors(error);
					   // this.diagloServie.alertsModal('tokenErr', error.error.error, 5000, false, error.log_id);
					}
			}
		);
	}

	confirmEnterParaticipant (participantHash, isNft = null) {
		this.resetTextError();
		this.resetConfirmedParticipant();
		this.resetAssociatedParticipant();
		this.showConfirmedParticipant = false;
		this.responseCode = 0;
		this.inviterName = '';
		if (isNft && this.isProd) {
      this.checkIsNft();
      return
    }

		this.globals.startLoader();
		this.readerService.confirmParticipantEnter(participantHash).subscribe(
			(response: any) => {
				this.globals.stopLoader();
				this.confirmParticipantAction(response);
			},
			error => {
				this.readerService.scrollHandler.next(0);
				this.globals.stopLoader();
				this.handleTokenErrors(error);

				// error = {
        //   "code": "180-4",
        //   "data": {"apply_id": 15},
        //   "log_id": 0
        // }

        // For NFT Tickets
				if (error.data && error.data.hasOwnProperty('apply_id')) {
				  // Exit if apply_id exist in queue anf Interval is working
          if (this.apply_id_requests.includes(error.data.apply_id) && this.interval) {
            console.error('Already scanned. Waiting for action from user.', this.apply_id_requests, this.interval);
            this.error_text = this.errorCodesService.reader[10][this.localeId];
            return
          }
          // ADD new apply_id to apply_id_requests array
          this.apply_id_requests.push(error.data.apply_id);
          // Start interval
          if (!this.interval) {
            this.startNftInterval();
          }
          // Waiting for responce from our back end - checkNftScanParticipant
				  this.walletConnectService.getNftScanParticipantData.subscribe(response => {
				    console.log('getNftScanParticipantData', response.data);
				    if (response.data && response.data.scanned) {
              console.log('Start queue', this.apply_id_requests);

              for (let key in response.data.scanned) {
                const new_response = { code: response.code, data: response.data.scanned[key] };
                this.apply_id_requests = this.apply_id_requests.filter(id => id !== +key);

                // Continue as usual for each attendee
                this.confirmParticipantAction(new_response);
              }

              console.log('End queue', this.apply_id_requests);

				      if (!this.apply_id_requests.length) {
				        clearInterval(this.interval);
				        this.interval = null;
                this.error_text = '';
                this.startNftInterval();
              }
            }
          });
        }
        // For NFT Tickets END
			}
		);
	}

  startNftInterval() {
	  if (!this.apply_id_requests.length) {return}
    this.interval = setInterval( () => {
       console.log('checkNFTInterval', this.interval);
      this.walletConnectService.checkNftScanParticipantTimer(this.apply_id_requests);
    }, 10000);
  }

  confirmParticipantAction(response) {
    this.readerService.scrollHandler.next(0);
    this.showConfirmedParticipant = true;

    if (response.code !== 6) {
      this.updateReaderCount('confirm');
    }
    this.updateReaderData(response.data.participant);
    this.confirmedParticipant = response.data.participant;
    this.associatedParticipant = response.data.associated_participants;
    this.cdr.detectChanges();
    this.responseCode = response.code;
    if (response.code === 6 && this.confirmedParticipant.inviter_id !== null) {
      this.inviterName = this.associatedParticipant.filter((as) => {
        return as.id === this.confirmedParticipant.inviter_id;
      });
      this.inviterName = this.inviterName ? this.inviterName[0].full_name : '';
    }
  }

	cancelEnterParaticipant (participantHash) {
		this.resetTextError();
		this.resetConfirmedParticipant();
		this.resetAssociatedParticipant();
		this.showConfirmedParticipant = false;
		this.globals.startLoader();
		this.readerService.cancelParticipantEnter(participantHash).subscribe(
			(response: any) => {
				this.readerService.scrollHandler.next(0);
				this.globals.stopLoader();
				this.showConfirmedParticipant = true;
				this.updateReaderData(response.data.participant);
				if (this.reader_id === response.data.participant.reader_id) {
					this.updateReaderCount('cancel');
				}
				this.confirmedParticipant = response.data.participant;
				this.cdr.detectChanges();
			},
			error => {
				this.globals.stopLoader();
				if (error.data && error.data.participant) {this.updateReaderData(error.data.participant)}
				this.readerService.scrollHandler.next(0);
        this.handleTokenErrors(error);
			}
		);
	}

	updateReaderData(participant) {
		const participanIndex = this.participants.findIndex((obj: any) => {if (obj) { return obj.id === participant.id}});
		this.participants[participanIndex] = participant;
	}

	updateReaderCount (action) {
		if (action === 'confirm') {
			this.reader_participants_scaned++;
			this.event_participants_scaned++;
		}
		if (action === 'cancel') {
			this.reader_participants_scaned--;
			this.event_participants_scaned--;
		}
	}

	resetTextError () {
		this.error_text = '';
	}

	resetConfirmedParticipant () {
		this.confirmedParticipant = [];
	}

	resetAssociatedParticipant () {
			this.associatedParticipant = [];
	}

	startScannerOnApp () {
		this.globals.runOnMobile("startQRScanner",{url: this.getin_base_url + '/reader/' + this.reader_id + '?camscan=1', scanRate: 2000});
	};

	onLogout () {
		this.authService.removeReaderApiKeyAndId();
		this.router.navigate(['/reader-signin']);
	}

	scanQrCode(e) {
		if (this.firstAttempt) {
			this.firstAttempt = false
			this.startTimeOut();
		}
		if (e.code !== 'Enter') {
					this.scanData.push(e.key);
		} else {
			const hash = this.scanData.join('');
			this.confirmEnterParaticipant(hash);
			this.scanData = [];
		}
	}

	startTimeOut() {
		setTimeout(() => {
			this.scanData = [];
			this.firstAttempt = true;
		}, 200)
	}

	checkIsNft() {
	  this.diagloServie.alertsModal('reader', 11, 5000, false);
  }

  handleTokenErrors(error) {
    if (error.error === 4.1 || error.error === 4.3 || error.error === 18.4 || error.error === 18.5) {
      this.authService.removeReaderApiKeyAndId();
      this.diagloServie.alertsModal('tokenErr', error.error,5000,false);
      setTimeout(() => { window.location.reload() }, 4000);
    }
    if (error.code) {
      this.error_text = this.errorCodesService.reader[error.code][this.localeId];
    }
  }

	ngOnDestroy() {
		this.navbarService.show();
    this.navbarService.showHeader();
		this.navbarService.customWrapperClass = undefined;
		this.walletConnectService.unsubscribeNftScanParticipant();
		clearInterval(this.interval);
	}
}
