import { DialogService } from '../../services/dialog.service';
import { GlobalsService } from './../../globals';
import { CmsUsersService } from './../../services/cms-users.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
	selector: 'app-update-password-by-admin',
	templateUrl: './update-password-by-admin.component.html',
	styleUrls: ['./update-password-by-admin.component.css']
})
export class UpdatePasswordByAdminComponent implements OnInit {
	@ViewChild('updateUserPasswordUserForm') updateUserPasswordUserForm: NgForm;
	pwdModel = {
			new_password: '',
			confirmed_password: ''
	};

	constructor(
		public dialogRef: MatDialogRef<any>,
		@Inject(MAT_DIALOG_DATA) public data,
		private cmsUsersService: CmsUsersService,
		private globals: GlobalsService,
		private dialogService: DialogService
	) { }

	ngOnInit() {
	}

	submitForm () {
		if (this.updateUserPasswordUserForm.controls.new_password.value === this.updateUserPasswordUserForm.controls.confirmed_password.value) {
			const data = {
				id: this.data.user_id,
				new_password: this.updateUserPasswordUserForm.controls.new_password.value,
				confirmed_password: this.updateUserPasswordUserForm.controls.confirmed_password.value
			};
			this.globals.startLoader();
			this.cmsUsersService.updateUserPassword(data).subscribe(
				(response: any) => {
					this.globals.stopLoader();
					this.dialogRef.close({success: true});
					this.dialogService.alertsModal('success', response.code, 2000, true);
				},
				error => {
					this.globals.stopLoader();
					this.dialogService.alertsModal((error.data.error_type) ? error.data.error_type : 'errors', (error.code) ? error.code : 4 ,5000, false, error.log_id);
				}
			);
		} else {
			this.dialogService.alertsModal('errors', 13, 5000, false);
		}
	}

}
