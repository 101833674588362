<div class="carousel-wrap" [ngClass]="{'x-scroll-hidden' : !isMobile, 'producer-page': page=='producer'}" #carousel>
  <div class="tile-wrap" *ngFor="let event of carouselData | slice:0:(isMobile ? limit_end_m : limit_end_d); let i = index;" [ngClass]="{'rtl' : dir === 'rtl'}">
    <div class="producer" *ngIf="showProducer">
      <a [routerLink]="['/producer/' + event.producer_url_identifier]" class="producer-link" aria-label="Producer"
         [ngStyle]="{'backgroundImage': event.profile_img ? 'url(' + baseProducerImgUrl + event.profile_img + ')' : '' }"
      >
        <svg *ngIf="!event.profile_img" width="32" height="32" [data-jdenticon-value]="event.user_id"></svg>
      </a>
      <a [routerLink]="['/producer/' + event.producer_url_identifier]" class="producer-name one-line" title="{{event.producer_name}}">
        {{event.producer_name}}
      </a>
      <span class="separator"></span>
      <app-producer-follow-edit-btn [producerId]="event.user_id" [userId]="userId" [(producerfollowers)]="event.followers" [eventId]="event.event_id" (updateFolowers)="updateFolowers($event)"></app-producer-follow-edit-btn>
      <div class="remove-event-btn" *ngIf="type==2"(click)="removeEvent(event.event_id)">&#10006;</div>
    </div>

    <div class="event">
      <p *ngIf="event.daysToStart > 0" class="days-to-start">
        <span i18n="in">in</span>
        {{event.daysToStart}}
        <span *ngIf="event.daysToStart === 1" i18n="day">day</span>
        <span *ngIf="event.daysToStart !== 1" i18n="days">days</span>
      </p>
      <p *ngIf="event.daysToStart <= 0" class="days-to-start">
        <span i18n="today">today</span>
      </p>
      <a
        class="event-name one-line"
        title="{{event.title}}"
        [routerLink]="[(event.url_friendly) ? '/'+event.url_friendly : '/'+event.event_id]"
        [queryParams]="{ seller_code: 'getin'}"
      >
        {{event.title}}
      </a>
      <a
        class="event-link" aria-label="Event"
        [routerLink]="[(event.url_friendly) ? '/'+event.url_friendly : '/'+event.event_id]"
        [queryParams]="{ seller_code: 'getin'}"
        [ngStyle]="{'backgroundImage': 'url(' + baseEventImgUrl + event.carousel_image + ')'}"
      ></a>
      <div class="date-and-views">
        <p class="event-date with-icon">
          <span class="date-icon event-icon"></span>
          {{event.start_date.replace(' ', 'T') | date:'dd.MM.yyyy'}}
        </p>
        <p class="event-views with-icon" *ngIf="+event.views >= 1000">
          {{event.views}}
          <span class="views-icon event-icon"></span>
        </p>
      </div>
      <p class="event-address with-icon" title="{{event.address}}">
        <span class="geo-icon event-icon"></span>
        <span class="one-line">{{event.address}}</span>
      </p>
    </div>

  </div>
</div>
