<div class="main-sales-container" [ngClass]="{'rtl' : dir === 'rtl'}">
  <div class="sales-container" *ngIf="statsData">

    <!-- <div class="events-stat" *ngFor="let type of saleTypes; let i = index"></div> -->
    <div class="events-stat">
      <div class="events-type-container">
        <div class="events-type-number">{{statsData.purchase_status.approved_count | number:'1.'}}</div>
        <div i18n="table header | approved participants" class="events-type-name">APPROVED</div>
      </div>
    </div>
    <div class="events-stat">
      <div class="events-type-container">
        <div class="events-type-number">{{statsData.purchase_status.declined_count | number:'1.'}}</div>
        <div i18n="table header | declined participants" class="events-type-name">DECLINED</div>
      </div>
    </div>
    <div class="events-stat">
      <div class="events-type-container">
        <div class="events-type-number">{{statsData.purchase_status.abandon_count | number:'1.'}}</div>
        <div i18n="table header | abandoned participants" class="events-type-name">ABANDONING</div>
      </div>
    </div>
    <div class="events-stat">
      <div class="events-type-container">
        <div class="events-type-number">{{statsData.purchase_status.hidden_count | number:'1.'}}</div>
        <div i18n="table header | hidden participants" class="events-type-name">HIDDEN</div>
      </div>
    </div>
    <div class="events-stat">
      <div class="events-type-container">
        <div class="events-type-number">{{statsData.purchase_status.refund_count | number:'1.'}}</div>
        <div i18n="table header | cancelled participants" class="events-type-name">CANCELED</div>
      </div>
    </div>
    <div class="events-stat">
      <div class="events-type-container">
        <div class="events-type-number">{{statsData.purchase_status.pending_count | number:'1.'}}</div>
        <div i18n="table header | pending participants" class="events-type-name">PENDING</div>
      </div>
    </div>
    <div class="events-stat">
      <div class="events-type-container">
        <div class="events-type-number">{{statsData.purchase_status.waiting_list_count | number:'1.'}}</div>
        <div t key="Dashboard.EventParticipant.WaitingListTab" default="Waiting List" class="events-type-name">Waiting List</div>
      </div>
    </div>

  </div>
  <div class="sales-graph-container" *ngIf="lineChartEventData.length > 0 && lineChartEventLabels.length > 0">
             <!-- <h3 i18n="section sub title | total event sales">גרף מכירות לכלל האירוע</h3>
            <p class="disabled">{{statsData.end_date_sales}} - {{statsData.start_date_sales}}</p> -->
            <div class="display-chart">
<!--              <canvas class="display-chart" baseChart [datasets]="lineChartEventData" [labels]="lineChartEventLabels" [options]="lineChartOption"-->
<!--                [type]="lineChartType" [legend]="true" #salesChart></canvas>-->
              <canvas id="mychart" #mychart></canvas>
            </div>
    <button class="reset-zoom" (click)="resetZoom()">Reset Zoom</button>
  </div>

  <div class="events-sales-container">
    <div *ngIf="statsData" class="events-section-container">{{statsData?.discount_list | json}}
      <button *ngIf="statsData.event_editable" i18n="add coupon" class="add-btn" [routerLink]="['/coupons', eventId]">Add coupon +</button>
      <div i18n="header | discount name" class="events-sales-title"
           *ngIf="statsData?.coupons?.length"
           (click)="toggleSection('discount')"
           [ngStyle]="{'color':isShowDis ? 'white' : '#ff6465' }">Discounts<span class="toggle-badge">{{isShowDis ? '-' : '+'}}</span></div>
      <!-- <div class="events-section">
        <div class="events-section-title">Students ticket</div>
        <div class="events-section-text"><span>Discount:&nbsp;</span>44$</div>
        <div class="events-section-text"><span>Uses left:&nbsp;</span>5 out of 323</div>
      </div> -->
      <!-- <div *ngIf="statsData?.discount_list || statsData?.coupons" class="discounts_sum"> -->
        <div class="section" *ngIf="statsData?.discount_list || statsData?.coupons" [ngStyle]="{'display':isShowDis ? 'block' : 'none' }">
          <div class="events-section" *ngFor="let coupon of statsData.coupons">
            <div i18n="table header | discount name" class="events-section-title">{{coupon.name}}</div>
            <div class="events-section-text" *ngIf="coupon.discount_type == 1"><span
                i18n="table header | discount type">Discount method:&nbsp;</span>
              {{coupon.discount}}{{coupon.convertedDiscountType}}</div>
            <div class="events-section-text"
              *ngIf="coupon.discount_type == 3 || coupon.discount_type == 2"><span
                i18n="table header | discount amount">Discount:&nbsp;</span>
              {{coupon.discount | currency: statsData.event_currency:'symbol':'1.': getLocale(statsData.event_currency)}}
              <span *ngIf="coupon.discount_type == 2">({{coupon.convertedDiscountType}})</span></div>
            <div class="events-section-text"><span
                i18n="table header | available discounts">Uses left:&nbsp;</span>
              {{coupon.remaining | number:'1.'}}&nbsp;<span i18n="@@AnalyticsOutOf">out of</span>&nbsp;
              {{coupon.quantity | number:'1.'}}</div>
          </div>
          <!-- Discount_list never doesn't use -->
          <div class="events-section" *ngFor="let discount of statsData.discount_list">
            <div i18n="table header | discount name" class="events-section-title">{{discount.name}}</div>
            <div class="events-section-text"><span i18n="table header | discount type">Discount method:&nbsp;</span>{{discount.dis_type}}</div>
            <div class="events-section-text"><span i18n="table header | discount amount">Discount:&nbsp;</span>{{discount.discount_type}}</div>
            <div class="events-section-text"><span i18n="table header | available discounts">Uses left:&nbsp;</span>{{discount.used | number:'1.'}}&nbsp;
              <span i18n="@@AnalyticsOutOf">out of</span>&nbsp;{{discount.discount_total | number:'1.'}}</div>
          </div>
        </div>
      <!-- </div> -->
    </div>


    <div class="events-section-container">
      <button *ngIf="statsData.event_editable" i18n="add sale round" [routerLink]="['/edit-event', eventId]" class="add-btn">Add sale round +</button>
      <div i18n="section sub title | sale rounds" class="events-sales-title"
           *ngIf="statsData?.sale_rounds?.length"
           (click)="toggleSection('sale-round')"
           [ngStyle]="{'color':isShowSaleR ? 'white' : '#ff6465' }">Sales rounds<span class="toggle-badge">{{isShowSaleR ? '-' : '+'}}</span></div>
      <!-- <div class="events-section">
        <div class="events-section-title">Students ticket</div>
        <div class="events-section-text"><span>Total sales:&nbsp;</span>2423</div>
        <div class="events-section-text"><span>Total income:&nbsp;</span>4323$</div>
      </div> -->


          <div class="section" *ngIf="statsData?.sale_rounds" [ngStyle]="{'display':isShowSaleR ? 'block' : 'none' }">
          <div class="events-section" *ngFor="let sales_bd of statsData.sale_rounds">
            <div class="events-section-title" i18n="table header | item type">{{sales_bd.item_name}}</div>
            <div class="events-section-text">
              <span i18n="table header | credit">Credit:&nbsp;</span>{{sales_bd.cred_c | number:'1.'}}</div>
            <div class="events-section-text"><span i18n="table header | cash">Cash:&nbsp;</span>{{sales_bd.cash_c | number:'1.'}}</div>
            <div class="events-section-text">
              <span i18n="table header | registrations">Registration:&nbsp;</span>{{sales_bd.register_c | number:'1.'}}</div>
            <div class="events-section-text"><span i18n="table header | free">Free admission:&nbsp;</span>{{sales_bd.free_c | number:'1.'}}</div>
            <div class="events-section-text"><span i18n="table header | total">Total:&nbsp;</span>{{sales_bd.item_count  | number:'1.'}}</div>
          </div>
        </div>

    </div>

  </div>
</div>
