<div class="col-md-12 col-xs-12 links-section">
  <div class="modal-header">
      <button type="button" class="close" style="color: white;"(click)="dialogRef.close()">
          <span aria-hidden="true">&times;</span>
        </button>
  <div class="text-center"><h4>Links to event</h4></div>
  </div>
    <div class="modal-body mb15">
      <div class="table-responsive">
        <table class="table table-striped table-responsive table-bordered text-center copy_links_table">
          <tr class="event-links">
            <th>Link type</th>
            <th>Link</th>
            <th>Copy link</th>
          </tr>
          <tr class="event-links" *ngIf="data.current_status != 2 && data.current_status != 3">
            <td>Full purchase</td>
            <td>
              <a href="{{getin_base_url}}/full-purchase/{{url_element}}" target="blank">{{getin_base_url}}/full-purchase/{{url_element}}</a>
            </td> 
            <td class="event_links">
              <span (click)="copyLink(getin_base_url+'/full-purchase/'+url_element)"><img src="/assets/images/Copy.png" class="img-responsive center-block"></span>
            </td> 
          </tr>
          <tr class="event-links" *ngIf="data.current_status != 2 && data.current_status != 3">
            <td>Full purchase without Facebook</td>
            <td>
              <a href="{{getin_base_url}}/full-purchase/{{url_element}}?fb=false&nav=false" target="blank">{{getin_base_url}}/full-purchase/{{url_element}}?fb=false&nav=false</a>
            </td> 
            <td class="event_links">
                <span (click)="copyLink(getin_base_url+'/full-purchase/'+url_element+'?fb=false&nav=false')"><img src="/assets/images/Copy.png" class="img-responsive center-block"></span>
            </td> 
          </tr>
          <tr class="event-links" *ngIf="data.current_status != 2 && data.current_status != 3">
            <td>Regular purchase</td>
            <td>
              <a href="{{getin_base_url}}/{{url_element}}" target="blank">{{getin_base_url}}/{{url_element}}</a>
            </td> 
            <td class="event_links">
                <span (click)="copyLink(getin_base_url+'/'+url_element)"><img src="/assets/images/Copy.png" class="img-responsive center-block"></span>
            </td> 
          </tr>
          <tr class="event-links" *ngIf="data.current_status == 2 || data.current_status == 3">
              <td>Regular purchase</td>
              <td>
                  <a href="{{getin_base_url}}/preview/{{url_element}}" target="blank">{{getin_base_url}}/preview/{{url_element}}</a>
              </td> 
              <td class="event_links">
                  <span (click)="copyLink(getin_base_url+'/preview/'+url_element)"><img src="/assets/images/Copy.png" class="img-responsive center-block"></span>
              </td> 
            </tr>
          <tr class="event-links" *ngIf="data.current_status != 2 && data.current_status != 3">
            <td>Quick purchase</td>
            <td>
              <a href="{{getin_base_url}}/{{url_element}}?quick_purchase=true" target="blank">{{getin_base_url}}/{{url_element}}?quick_purchase=true</a>
            </td> 
            <td class="event_links">
              <span (click)="copyLink(getin_base_url+'/'+url_element+'?quick_purchase=true')"><img src="/assets/images/Copy.png" class="img-responsive center-block"></span>
            </td> 
          </tr>
          <tr class="event-links" *ngIf="data.current_status != 2 && data.current_status != 3">
            <td>GETIN link</td>
            <td>
              <a href="{{getin_base_url}}/{{url_element}}?seller_code=getin" target="blank">{{getin_base_url}}/{{url_element}}?seller_code=getin</a>
            </td> 
            <td class="event_links">
              <span (click)="copyLink(getin_base_url+'/'+url_element+'?seller_code=getin')"><img src="/assets/images/Copy.png" class="img-responsive center-block"></span>
            </td> 
          </tr>
        </table>
      </div>
  </div> 
  <div class="modal-footer">
      <button type="button" class="btn btn-default pull-left mr10" (click)="dialogRef.close()">Close</button>
  </div>
</div>
