<ng-container [ngSwitch]="type">
<!--  DEFAULT VIEW-->
  <ng-container *ngSwitchDefault>
    <div class="cdk-header">
      <app-cat-name [name]="name"></app-cat-name>
<!--      <div class="arrows">-->
<!--        <button NguCarouselPrev class="leftRs" (click)="scrollTo('prev')">-->
<!--          <img src="/assets/images/svg-icons/carousel-arrow-left.svg" alt="left">-->
<!--        </button>-->
<!--        <button NguCarouselNext class="rightRs" (click)="scrollTo('next')">-->
<!--          <img src="/assets/images/svg-icons/carousel-arrow-right.svg" alt="right">-->
<!--        </button>-->
<!--      </div>-->
      <div class="cat-link-wrap">
        <ng-container *ngIf="categoryId">
          <a class="cat-link" [routerLink]="['/categories', categoryId]">
            <span *ngIf="isMobile">See all</span>
            <span *ngIf="!isMobile">See all {{categoryName}}</span>
          </a>
        </ng-container>

      </div>
    </div>

    <div class="cdk-virtual-scroll-data-source-example" [ngClass]="direction">
      <cdk-virtual-scroll-viewport [dir]="direction" #virtual_scroll orientation="horizontal" itemSize="300" class="example-viewport" (scrolledIndexChange)=currentIndex($event)>
        <div *cdkVirtualFor="let event of events" class="example-item">
          <app-event-card [event]="event"></app-event-card>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </ng-container>

  <!--  TYPE-1 VIEW-->
<!--  <ng-container *ngSwitchCase="'type-1'">-->
<!--    <div class="cdk-header type-1">-->
<!--      <app-cat-name [name]="name"></app-cat-name>-->
<!--      <div class="arrows" *ngIf="!isMobile">-->
<!--        <button NguCarouselPrev class="leftRs" (click)="scrollTo('prev')">-->
<!--          <img src="/assets/images/svg-icons/carousel-arrow-left.svg" alt="left">-->
<!--        </button>-->
<!--        <button NguCarouselNext class="rightRs" (click)="scrollTo('next')">-->
<!--          <img src="/assets/images/svg-icons/carousel-arrow-right.svg" alt="right">-->
<!--        </button>-->
<!--      </div>-->
<!--      <div class="cat-link-wrap" *ngIf="isMobile">-->
<!--        <a class="cat-link" [routerLink]="['/categories', 'all']">See all event categories</a>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="cdk-virtual-scroll-data-source-example type-1" [ngClass]="direction">-->
<!--      <cdk-virtual-scroll-viewport [dir]="direction" #virtual_scroll orientation="horizontal" itemSize="{{isMobile ? '346' : '484'}}" class="example-viewport" (scrolledIndexChange)=currentIndex($event)>-->
<!--        <div *cdkVirtualFor="let event of events" class="example-item">-->
<!--          <app-event-card [event]="event" [type]="'type-1'"></app-event-card>-->
<!--        </div>-->
<!--      </cdk-virtual-scroll-viewport>-->
<!--    </div>-->
<!--  </ng-container>-->

  <!--  TYPE-2 VIEW-->
<!--  <ng-container *ngSwitchCase="'type-2'">-->
<!--    <div class="cdk-header">-->
<!--      <app-cat-name [name]="name"></app-cat-name>-->
<!--      <div class="arrows" *ngIf="!isMobile">-->
<!--      </div>-->
<!--      <div class="cat-link-wrap" *ngIf="isMobile">-->
<!--        <a class="cat-link" [routerLink]="['/categories', 'all']">See all event categories</a>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="type-2" [ngClass]="direction">-->

<!--      <ng-container *ngIf="isMobile; else desktopList">-->
<!--        <div *ngFor="let event of events; index as i" class="example-item-m">-->
<!--          <app-event-card [event]="event" [type]="'type-2-m'"></app-event-card>-->
<!--        </div>-->
<!--      </ng-container>-->

<!--      <ng-template #desktopList>-->
<!--        <div *ngFor="let event of events | slice:0: slice_end; index as i" class="example-item">-->
<!--          <div *ngIf="i == 0; else defaultCard">-->
<!--            <app-event-card [event]="event" [type]="'type-2-big'"></app-event-card>-->
<!--          </div>-->
<!--          <ng-template #defaultCard>-->
<!--            <app-event-card [event]="event" [type]="'type-1'"></app-event-card>-->
<!--          </ng-template>-->
<!--        </div>-->
<!--      </ng-template>-->

<!--    </div>-->

<!--    <ng-container *ngIf="categoryId; else elseCategoryId">-->
<!--      <button class="btn-black" [routerLink]="['/categories', categoryId]">View More</button>-->
<!--    </ng-container>-->
<!--    <ng-template #elseCategoryId>-->
<!--      <button class="btn-black" *ngIf="events.length > 8 && !isMobile && slice_end < events.length" (click)="viewMore()">View More</button>-->
<!--    </ng-template>-->
<!--  </ng-container>-->

  <!--  TYPE-3 VIEW-->
<!--  <ng-container *ngSwitchCase="'type-3'">-->
<!--    <div class="cdk-header">-->
<!--      <app-cat-name [name]="name"></app-cat-name>-->
<!--      <div class="arrows" *ngIf="!isMobile">-->
<!--        <button NguCarouselPrev class="leftRs" (click)="scrollTo('prev')">-->
<!--          <img src="/assets/images/svg-icons/carousel-arrow-left.svg" alt="left">-->
<!--        </button>-->
<!--        <button NguCarouselNext class="rightRs" (click)="scrollTo('next')">-->
<!--          <img src="/assets/images/svg-icons/carousel-arrow-right.svg" alt="right">-->
<!--        </button>-->
<!--      </div>-->
<!--      <div class="cat-link-wrap" *ngIf="isMobile">-->
<!--        <a class="cat-link" [routerLink]="['/categories', 'all']">See all event categories</a>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="type-3" [ngClass]="direction"> &lt;!&ndash;events array like [['a'],['b', 'c', 'd'],['e', 'f', 'g']]   ...&ndash;&gt;-->

<!--      <ng-container *ngIf="isMobile; else desktopList">-->

<!--        <div *ngFor="let event of events; index as i" class="example-item-m">-->
<!--          <app-event-card [event]="event" [type]="'type-2-m'"></app-event-card>-->
<!--        </div>-->
<!--      </ng-container>-->

<!--      <ng-template #desktopList>-->
<!--        <div class="cdk-virtual-scroll-data-source-example">-->
<!--          <cdk-virtual-scroll-viewport [dir]="direction" #virtual_scroll orientation="horizontal" itemSize="434" class="example-viewport" (scrolledIndexChange)=currentIndex($event)>-->
<!--            <div *cdkVirtualFor="let event of events; index as i" class="example-item">-->
<!--              <div *ngIf="i == 0; else defaultCard">-->
<!--                <app-event-card [event]="event[0]" [type]="'type-2-big'"></app-event-card>-->
<!--              </div>-->
<!--              <ng-template #defaultCard>-->
<!--                <ng-container *ngFor="let sub_event of event">-->
<!--                  <app-event-card [event]="sub_event" [type]="'type-3'"></app-event-card>-->
<!--                </ng-container>-->
<!--              </ng-template>-->
<!--            </div>-->
<!--          </cdk-virtual-scroll-viewport>-->
<!--        </div>-->
<!--      </ng-template>-->

<!--    </div>-->

<!--    <ng-container *ngIf="categoryId; else elseCategoryId">-->
<!--      <button class="btn-black" [routerLink]="['/categories', categoryId]">View More</button>-->
<!--    </ng-container>-->
<!--    <ng-template #elseCategoryId>-->
<!--      <button class="btn-black" *ngIf="events.length > 8 && !isMobile && slice_end < events.length" (click)="viewMore()">View More</button>-->
<!--    </ng-template>-->

<!--  </ng-container>-->

  <!--  TYPE-4 VIEW-->
<!--  <ng-container *ngSwitchCase="'type-4'">-->
<!--    <div class="cdk-header">-->
<!--      <app-cat-name [name]="name"></app-cat-name>-->
<!--    </div>-->

<!--    <div class="type-4" [ngClass]="direction">-->
<!--      <ng-container *ngIf="isMobile; else desktopList">-->
<!--        <div *ngFor="let event of events| slice:0: slice_end; index as i" class="example-item-m">-->
<!--          <app-event-card [event]="event" [type]="'type-2-m'"></app-event-card>-->
<!--        </div>-->
<!--      </ng-container>-->
<!--      <ng-template #desktopList>-->
<!--        <div *ngFor="let event of events | slice:0: slice_end; index as i" class="example-item">-->
<!--          <app-event-card [event]="event" [type]="'type-4'"></app-event-card>-->
<!--        </div>-->
<!--      </ng-template>-->

<!--      <ng-container *ngIf="categoryId; else elseCategoryId">-->
<!--        <button class="btn-black" [routerLink]="['/categories', categoryId]">View More</button>-->
<!--      </ng-container>-->
<!--      <ng-template #elseCategoryId>-->
<!--        <button class="btn-black" *ngIf="events.length > 8 && !isMobile && slice_end < events.length" (click)="viewMore()">View More</button>-->
<!--      </ng-template>-->

<!--    </div>-->
<!--  </ng-container>-->

  <!--  TYPE-5 VIEW-->
  <ng-container *ngSwitchCase="'type-5'">
    <div class="cdk-header">
      <app-cat-name [name]="name"></app-cat-name>
    </div>

    <div class="type-5-list" [ngClass]="direction">
      <div *ngFor="let event of events| slice:0: slice_end" class="example-item">
        <app-event-card [event]="event" [type]="'type-5'"></app-event-card>
      </div>
    </div>
    <button class="btn-white" *ngIf="!isSearch && isMobile && events.length > 5 && slice_end < events.length" (click)="viewMore(1000)">
      See all
    </button>
    <button class="btn-white" *ngIf="!isSearch && !isMobile && events.length > 25 && slice_end < events.length" (click)="viewMore()" i18n="@@MoreEvents">
      More Events
    </button>
    <button class="btn-white" *ngIf="isSearch && events.length > 5 && slice_end < events.length" (click)="viewMore(1000)">
      See all
    </button>
  </ng-container>

</ng-container>

