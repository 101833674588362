import { ErrorCodesService } from '../../code-strings/error_codes';
import { LoginToAppResponse } from './../../interfaces/authentication.interface';
import { FacebookPixelModel } from './../../facebook-pixel-model';
import { AppPurchaseRequest } from './../../interfaces/app-purchase-request.interface';
import { FacebookService } from '../../services/facebook.service';
import { NavbarService } from '../../services/navbar.service';
import { GlobalsService } from './../../globals';
import { EventService } from '../../services/event.service';
import { slideIn, fade } from './../../animation';
import { emailRegex } from './../../regex';
import { AuthService } from '../../services/auth.service';
import { Component, OnInit, Input, OnDestroy, AfterViewInit, Renderer2, Inject, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators, UntypedFormControl } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {DOCUMENT, getCurrencySymbol} from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { DialogService } from '../../services/dialog.service';
import { DatePipe } from '@angular/common';
import { Subscription ,  interval } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppResponse } from '../../interfaces/app-response.interface';
import { ClipboardService } from 'ngx-clipboard';
import { CryptoPaymentService } from '../../services/crypto-payment.service';
import { takeUntil } from 'rxjs/operators';
import {NftThankYouModalService} from '../../services/nft-thank-you-modal.service';
import { CountdownCheckoutTimerComponent } from '../../event-page/countdown-checkout-timer/countdown-checkout-timer.component';
import moment from 'moment';
import {CurrencyLocaleService} from '../../services/currency-locale.service';
import {RudderstackService} from "../../services/rudderstack.service";
declare var window: any;
declare var CryptoJS: any;
declare var Stripe;
declare var Hyper;

@Component({
  selector: 'app-purchase-modal',
  templateUrl: './purchase-modal.component.html',
  styleUrls: ['./purchase-modal.component.css'],
  animations: [slideIn, fade]
})
export class PurchaseModalComponent implements OnInit, AfterViewInit, OnDestroy {
  images_base_url = environment.images_base_url;
  getin_base_url = environment.getin_base_url;

  isProd = environment.production;
  /*
   * ALL INPUTS THAT WE HAVING FROM LP
   */

  @Input() event: string;
  @Input() salesman_id: number | string;
  @Input() salesman_user: true;
  @Input() event_details;
  @Input() tickets;
  @Input() categories;
  @Input() rooms;
  @Input() tables;
  @Input() facebook_link: string;
  @Input() tickets_reg;
  @Input() rooms_reg;
  @Input() tables_reg;
  @Input() discounts: string | number;
  @Input() coupons = false;
  @Input() mailing: any = {}
  @Input() marketing: any;
  @Input() facebookConnect: boolean;
  @Input() event_fb_friends = false;
  @Input() fb_module: boolean = true;
  @Input() eventCoupons;
  @Input() eventDiscounts;
  @Input() ticketsAvailability;
  @Input() isFromEventPayment;
  @Input() canUseCash: boolean = false;
  @Input() isOwner: boolean = false;
  @Output() closePurchaseModal =  new EventEmitter<any>();
  @Output() closePurchaseModalAndGetData =  new EventEmitter<any>();
  @ViewChild('couponCoderegular') couponCoderegular: ElementRef;
  @ViewChild('couponCodePeper') couponCodePeper: ElementRef;
  @ViewChild(CountdownCheckoutTimerComponent) checkoutTimer: CountdownCheckoutTimerComponent;

  localeId: string;
  dir: string;
  showCategory: any = {};
  ticketsFiltered: any = {};
  withoutCategory: any = [];
  ticketLimits = {};

  /* When we are in purchase modal mode only it will be true */
  full_purchase: boolean = false;

  /* Seller & Coupon code that we getting from the url */
  seller_code: string;
  coupon_code: string;

  // Steps variables
  step: string = 'category_step';
  externalApi: boolean = false;
  toggleParticipant: number = 1;
  couponModal: boolean = false;
  noCategory: boolean = false;
  toggleItem: number;
  toggleTerms: boolean = false;

  // Visual variables
  title: string = '';
  item_id: number | string = 0;
  checkboxArr: Object = {};
  choosedItems: any = {};

  // Purchase variables
  participantForm: UntypedFormGroup = this.fb.group({
    participants: this.fb.array([])
  });
  counter: number = 0;
  quantity: any;
  form_inputs = {
    default_inputs: [],
    custom_inputs: []
  };
  new_custom_inputs: any[]  = [];
  formSubmited: boolean = false;
  couponName: string;
  couponCheckBox;
  couponCheckBoxPeper;
  ageValidation: boolean = false;
  birth_date_creation = [];
  birthday_validation: number;

  private sub;

  // Sending purchase variables
  item_is_reg: boolean;
  event_id: number;
  commission: number; // Producer Fee (in fee management)
  purchaser_commission: number;
  total_purchaser_commission;
  purchase_validation: number;
  used_discount_type: number;
  used_discount_id: number;
  coupon_source: number;
  discount_search_key: string;
  total_discount: number = 0;
  total_ticket_discount = 0;
  total_price_n_commission: string;
  total_price_w_commission: string;
  commission_ils: string;
  tax_commission;
  total_tax_commission = 0;
  tax_and_fees;
  service_commission;
  total_service_commission;
  platform_fee;
  platform_fee_rule; // 1 - Each ticket fee, 2 - All purchase fee.
  platform_fee_type; // 1 - Sum or 2 -Percent
  total_platform_fee = 0;
  item_price: number;
  currency: string;
  purchaser: {};
  sub_purchaser: Array<any> = [];
  payment_method: number;
  purchase_type: number;
  all_tickets: Array<any> = [];
  purchase_item: number;
  total: {'total_price' : number, 'total_items': number};
  mailingStatus: number = 1;

  // User info
  user_info;
  facebook_id: string = '0';


  facebook_validation: number;
  queryParams;
  customParams: object = {};
  currentFacebookLoginDataSubscription: Subscription;

  // Tranzila
  tranzillaUrl: SafeResourceUrl;
  remaining_payment: string;
  purchaseTimer: Date;

  terms: boolean = false;
  corona: boolean;
  eventPaymentMode: boolean = false;
  freeItem: boolean;
  selectOptionCouponValue: string;
  faAngleLeftRtlRightLtr: string;
  faAngleRightRtlLeftLtr: string;

  // Bank Of America
  boa_url: string = environment.authorize_net_url;
  token: string;
  iframeDisplay = 'none';

  // stripe
  @ViewChild('cardInfo') cardInfo: ElementRef;
  card: any;
  cardErrors: any;
  stripe: any;
  stripe_form: any;
  checkStripeWindowID: any; // Hyper And Stripe collisions

  // hyper
  hyper: any;
  widgets: any;
  hyper_return_url: string = '';
  hyper_clientSecret: string = '';
  hyperSubmitDisable:boolean = true;
  hyperSubmitHidden:boolean = true;
  @ViewChild('unified_checkout') unified_checkout: ElementRef;
  @ViewChild('hyper_errors') hyper_errors: ElementRef;

  gateway_id: number | null;
  gateway_type: string;
  payment_id: number;
  isClickedStripeButton = false;
  showSearchInput: boolean;
  filterText;
  searchAttendeeResult: Array<any> = [];
  attendee_index: number;
  hideCorona = true;

  // without save credit card
  cardIsChecked: boolean = false;

  // STRIPE
  stripe_url: SafeResourceUrl = environment.stripe_accept_url;

  // HYPER
  hyper_script_url: SafeResourceUrl = environment.hyper_script_url;


  // CoinBase
  coinBaseData: any | null;
  toggleCripto: string | null;
  paymentGateways: any[] = [];


  isShowBackArrow: boolean = true;
  cryptoPaymentOrderCode: string | null;
  cryptoPaymentStatus: number = 0;
  checkPaymentSubscription: Subscription;

  isSelectedTicketOutOfStock: boolean;
  isOpenWallets = false;
  isCloseWallet = false;
  walletConnectData: any = {};
  transactionBtnStatus: boolean = false;
  trnBtnStatus: boolean = false;

  // Coupon_code functionality
  couponTicketId = 0;
  couponTicket: any | null;
  couponDiscount: 0;
  couponDiscountType: any | null;
  discount: 0; // new functionality
  discount_type: any | null; // new functionality 1 - percents, 3 - sum

  isOpenSolanaWallets: boolean = false;
  isCloseSolanaWallet: boolean = false;
  solanaTransactionBtnStatus: boolean = false;
  trnSolBtnStatus: boolean = false;
  solanaConnectData: any = {};

  event_pixel: number;
  event_pixels: number[] = [];

  purchase_id: number;
  main_purchase_identifier: string;
  isAdmin: boolean = false;

  chartSeats: any;
  showSeatsIo: boolean = false;
  seatsRendering: boolean = false;
  showSeatsDisplay: boolean = false;
  seatsHash: string = '';
  seatsSelectedObjects;
  chartSeatsRendered: boolean = false;
  seatsConfig: any;
  seatsUserConfig: any;
  seatsQuantity: number = 0;
  selectedSeats: [];
  listSelectedObjects: {};
  seatsTotal_n_comission = 0;
  seatsTickets = [];
  seatsCategoriesList = [];

  seatsPanelOpenState = false;
  toggleBestSeats: boolean = false;
  seatsDeselectInProgress: boolean = false;
  seatsTicketsHaveInvalidItem: boolean = false;
  seatsHoldToken: string = '';
  seatsSupportsBestAvailable: boolean = false;

  isMobile: boolean;

  checkBoxProducerSubscription = false;
  showProducerSubscription: boolean = false;

  user_id;
  producer_id: number;
  producerDetails: any;
  userSameAsProducer = false;

  hasRegularTicket: number = 0;
  hasDonateTicket: number = 0;
  donateTickets = [];
  donation_fee_mode: number = 0;

  checkout_timeout_counter = 900;

  constructor(
    private datePipe: DatePipe,
    private router: Router,
    private _cookieService: CookieService,
    private facebookService: FacebookService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private eventService: EventService,
    public globals: GlobalsService,
    public rudderstackService: RudderstackService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document,
    public sanitizer: DomSanitizer,
    private nav: NavbarService,
    private dialogService: DialogService,
    private fb_pixel_model: FacebookPixelModel,
    private cd: ChangeDetectorRef,
    private errorService: ErrorCodesService,
    private clipboardService: ClipboardService,
    private cryptoService: CryptoPaymentService,
    private nftThankYouDialog: NftThankYouModalService,
    private currencyLocale: CurrencyLocaleService,
  ) {

    this.localeId = this.globals.getLocaleId();
    this.dir = this.globals.getDir();
    this.nav.setPurchaseModalStatus('open');


    /* Here we know that we are on full purchase */
    if (this.router.url.indexOf('full-purchase') !== -1) {
      this.nav.customClass = 'fullPurchase';
      this.nav.hide();
      this.full_purchase = true;
     // this.loadStripe();

    } else if (this.router.url.indexOf('event-payment') !== -1) {
      this.eventPaymentMode = true;
    }

    this.faAngleLeftRtlRightLtr = this.dir === 'rtl' ? 'fa-angle-left' : 'fa-angle-right';
    this.faAngleRightRtlLeftLtr = this.dir === 'rtl' ? 'fa-angle-right' : 'fa-angle-left';
    if (!this.hideCorona) {
    this.localeId !== 'he' ? this.corona = true : this.corona = false;
    } else {
      this.corona = true;
    }

    this.isMobile = window.innerWidth <= 768;
  }

  // load STRIPE script
  loadStripe() {
    if(!window.document.getElementById('stripe-script')) {
      const node = this._renderer2.createElement('script');
      node.id = 'stripe-script';
      node.type = 'text/javascript';
      node.src = this.stripe_url;
      this._renderer2.appendChild(this._document.head, node);
    }
  }

  // load Hyper script
  loadHyper(isHyperProd = false) {
    if (!window.document.getElementById('hyper-script')) {
      const node = this._renderer2.createElement('script');
      node.id = 'hyper-script';
      node.type = 'text/javascript';
      node.src = isHyperProd ? 'https://checkout.hyperswitch.io/v0/HyperLoader.js' : this.hyper_script_url;
      this._renderer2.appendChild(this._document.head, node);
    }
  }



  ngAfterViewInit() {
    const navPanel = document.getElementById('footer');
    if (navPanel) {
      navPanel.style.display = 'none';
    }
  }

  createStripeElement(stripe_token, payment_details, stripeAccount?) {
    const style = {
      base: {
        color: '#ffffff',
        backgroundColor: '#22262F',
        fontFamily: ' Montserrat,\'Helvetica Neue\', Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#C4C4C4'
        }
      },
      invalid: {
        color: '#FF6465',
        iconColor: '#FF6465'
      }
    };
    // Initialization options.
    const options = {
      stripeAccount: stripeAccount,
      locale: 'auto'
    }

    if (this.stripe == undefined) {
      if (stripeAccount) {
        this.stripe = Stripe(stripe_token, {...options});
      } else {
        this.stripe = Stripe(stripe_token);
      }

    }

    const elements = this.stripe.elements();

    this.card = elements.create('card', {style: style});
    this.card.mount(this.cardInfo.nativeElement);

    this.card.addEventListener('change', (error) => {
      this.onChange(error);
    }, { passive: true });
    // this.card.on('ready', () => {
    //   this.globals.stopLoader();
    // })
  }

  // --------INIT HYPERSWITCH IFRAME--------
  async createHyperElement(token, clientSecret) {
    if (this.hyper == undefined) { this.hyper = Hyper(token); }
    // console.log(this.hyper.widgets, token, clientSecret);
    this.hyperSubmitDisable = true;
    this.hyperSubmitHidden = true;
    await this.initializeHyperUnifiedCheckout(clientSecret);
    setTimeout(()=> {
      this.hyperSubmitDisable = false;
      this.hyperSubmitHidden = false;
    }, 500);
  }

  async initializeHyperUnifiedCheckout(clientSecret) {
    // const response = await fetch("/create-payment", {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   // body: JSON.stringify({ items }),
    // });
    // const { clientSecret } = await response.json();

    const appearance = {
      theme: 'midnight', // Expected 'default', 'midnight', 'brutal', 'charcoal', 'soft', 'none'
      variables: {
        colorText: "#FFFFFF",
        colorPrimary: "#736CFF",
        fontFamily: "Montserrat,Open Sans Hebrew,sans-serif",
        colorBackground: "#39393957",
        spacingAccordionItem: "12px",
      },
      rules: {
        ".AccordionItem": {
          background: `transparent`
        },
        ".PickerItem": {
          color: "#FFFFFF !important",
        },
        ".Tab":{
          display: "none"
        }
      },
      // variables: {
      // colorText: "#FFFFFF",
      // colorPrimary: "#736CFF",
      // fontFamily: "Montserrat,Open Sans Hebrew,sans-serif",
      // colorBackground: "#252131", // "#252131"
      // spacingAccordionItem: "8px",
      // },
      // rules: {
      //   ".Tab": {
      //     background: `#ffffff`,
      //   },
      //   // ".Tab--selected": {
      //   //   color: `#ffffff`,
      //   // },
      //   ".Label": {
      //     color: `#ffffff`,
      //   }
      // }
    };
    const loader = 'auto';  // Can be either ‘auto’, ‘always’ or ‘never’
    const fonts = [{
      cssSrc: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap'
    },
    ];
    const locale = this.localeId;

    this.widgets = this.hyper.widgets({ appearance, clientSecret, loader, fonts, locale });

    const unifiedCheckoutOptions = {
      layout: "tabs",
      wallets: {
        walletReturnUrl: this.hyper_return_url, //Mandatory parameter for Wallet Flows such as Googlepay, Paypal and Applepay
      },
      //The walletReturnUrl is the return URL for all wallets. As the confirmPayment method handles other payment methods, wallets are displayed
      // as a button and require a return URL to redirect back to your website for payment confirmation.

    };
    console.log('unifiedCheckoutOptions', unifiedCheckoutOptions);

    const unifiedCheckout = this.widgets.create("payment", unifiedCheckoutOptions);
    unifiedCheckout.mount("#unified-checkout");

  }

  async hyper_switchSubmit(e) {
    e.preventDefault();
    this.hyperSubmitDisable = true;
    this.hyper_errors.nativeElement.innerText = '';
    // additional check for booked seats if Seats enabled
    if (this.showSeatsIo) {
      this.globals.startLoader();
      this.eventService.checkOwnerAndAvailable(this.seatsHash).subscribe(res => {
        this.hyper_confirmPayment(); // HYPER CONFIRM
      }, error => {
        this.dialogService.alertsModal('errors', error.code, 5000, false);
        this.purchase_id = undefined;
        this.main_purchase_identifier = '';
        this.listSelectedObjects = {};
        this.seatsTickets = [];
        this.total_price_w_commission = '0.00';
        this.showSeatsDisplay = true;
        this.seatsHash = '';
        this.step = 'items_section';
        // this.showHyperForm = false;
        this.globals.stopLoader();
      })

    } else {
      await this.hyper_confirmPayment(); // HYPER CONFIRM
    }
  }

  async hyper_confirmPayment() {
    this.globals.startLoader();
    const widgets = this.widgets;

    const { error, status } = await this.hyper.confirmPayment({
      widgets,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: this.hyper_return_url,
      },
      redirect: "if_required",
    });
    this.hyperSubmitDisable = false;

    console.log('error', error);
    console.log('status', status);


    if (status) {
      this.hyper_handlePaymentStatus(status);
    }

    // This point will only be reached if there is an immediate error occurring while
    // confirming the payment. Otherwise, your customer will be redirected to your `return_url`
    // For some payment flows such as Sofort, iDEAL, your customer will be redirected to an
    // intermediate page to complete authorization of the payment, and then
    // redirected to the `return_url`.
    if(error) {
      this.hyper_errors.nativeElement.innerText = 'An unexpected error occurred.';
      if (error.type === "validation_error") {
        this.hyper_errors.nativeElement.innerText = error.message;
      }
      this.globals.stopLoader();
    } else {
      // this.showPurchaseBtn = false;
      this.hyperSubmitHidden = true;
      this.hyperSubmitDisable = true;
      this.checkCancelPurchaseOnBackStep('items_section', false, true);
    }
  }

  hyper_handlePaymentStatus(status) {
    switch (status) {
      case "succeeded":
        this.hyperswitchResponse();
        break;
      case "processing":
        this.hyperswitchResponse();
        break;
      case "requires_capture":
        this.hyperswitchResponse();
        break;
      case "failed":
        this.hyperswitchResponse();
        break;
      case "requires_payment_method":
        console.log("Please select a payment method to continue");
        break;
      default:
        this.defaultHyperError();
        console.log("Something went wrong.");
        break;
    }
  }

  hyperswitchResponse() {
    if (!this.main_purchase_identifier || !this.hyper_clientSecret) {return}
    const data = {
      main_purchase_identifier: this.main_purchase_identifier,
      payment_intent_client_secret: this.hyper_clientSecret
    }

    this.globals.startLoader();
    this.eventService.hyperswitchResponse(data).subscribe((res: any) => {
      console.log('hyperswitchResponse', res);
      this.globals.stopLoader();
      if (res.data && res.data.url) {
        this.router.navigateByUrl(res.data.url);
      }
    }, error => {
      this.globals.stopLoader();
      console.log('hyperswitcheError', error);
      this.router.navigate(['/dashboard']);
      this.dialogService.alertsModal('errors', 4.1, 5000,false);
    });
  }

  defaultHyperError() {
    this.dialogService.alertsModal('errors', 2, 4000, false);
    this.globals.startLoader();
    this.eventService.cancelPurchase(this.main_purchase_identifier).subscribe(res => {
        this.purchase_id = undefined;
        this.main_purchase_identifier = '';
        this.step = 'items_section';
        if (this.unified_checkout !== undefined) {
          this.unified_checkout.nativeElement.innerHTML = '';
        }
        this.hyperSubmitHidden = true;
        this.globals.stopLoader();
      },
      error => {
        this.main_purchase_identifier = '';
        this.globals.stopLoader();
        this.hyperSubmitHidden = true;
        if (this.unified_checkout !== undefined) {
          this.unified_checkout.nativeElement.innerHTML = '';
          this.hyper_errors.nativeElement.innerText = '';
          this.hyper_return_url = '';
        }
      });
  }

  onChange(event) {
    if (event) {
      this.cardErrors = event && event.error;
      if (this.localeId !== 'en' && event.error !== undefined) {
        const hebrewErrorMsg = this.errorService.getAlertStr('stripeErrors', event.error.code);
        if (hebrewErrorMsg) {
          this.cardErrors.message = hebrewErrorMsg;
        }
      }
      this.stripe_form = event;
    } else {
      this.cardErrors = null;
    }
    this.cd.detectChanges();
  }

  // old version
  // async stripeOnSubmit(form) {
  //   if (!this.isClickedStripeButton) {
  //     this.isClickedStripeButton = true;
  //     const { token, error } = await this.stripe.createToken(this.card);
  //     if (!error) {
  //       this.sendStripeResponse({payment_id: this.payment_id, token});
  //     } else {
  //       this.isClickedStripeButton = false;
  //     }

  //   }
  // }

  async stripeOnSubmit(form) {
    if (!this.isClickedStripeButton) {
      this.isClickedStripeButton = true;
      const { paymentMethod, error } = await this.stripe.createPaymentMethod('card', this.card);
      // console.log('stripeOnSubmit', paymentMethod);
      if (!error) {
        this.sendStripeResponse({ main_purchase_identifier: this.main_purchase_identifier, paymentMethod, saveCard: this.cardIsChecked });
      } else {
        // console.log('stripeOnSubmit', error);
        this.isClickedStripeButton = false;
      }
    }
  }

  sendStripeResponse(data) {
    this.globals.startLoader();
    this.eventService.addStripeData(data)
    .subscribe(
      (response: any) => {
        this.globals.stopLoader();
        this.payment_id = null;
        // console.log('stripe response', response.data.url, response);
        this.router.navigateByUrl(response.data.url);
      },
      error => {
        this.globals.stopLoader();
        this.isClickedStripeButton = false;
        if (error.code) {
          this.dialogService.alertsModal('landingPage', error.code, 3000, false, (typeof error.log_id !== 'undefined') ? error.log_id : 0);
        } else
        if (error.message) {
          this.dialogService.alertsModal('purchaseEvent', 2, 3000, false, (typeof error.log_id !== 'undefined') ? error.log_id : 0);
        }
          setTimeout(() => {
            this.closePurchaseModalAndGetData.emit();
          }, 3000);
        }
    );
  }

  toggleSearch() {
    this.showSearchInput = !this.showSearchInput;
  }

  inputChanged(data) {
    // console.log('inputChanged', data.target.value);
    // this.searchAttendeeResult
  }

  searchFilter(data) {
    const searchString = data.target.value.toLowerCase().trim();
    // console.log('searchFilter', data.target.value.toLowerCase(), searchString, this.filterText);
    if (searchString.length >= 3) {
      this.searchAttendeeResult = [];
      this.attendee_index = undefined;
      this.eventService.getAttendeeBySearch({ find_data: searchString }).subscribe((result: any) => {
        this.searchAttendeeResult = result.users;
        // console.log('result', result);
      });
    }
  }

  fillAttendee(a, i, user) {
    // console.log('fillAttendee', a, i, user);
    this.attendee_index = a;
    this.toggleSearch();
    this.fillForMe(i, user)
  }

  ngOnInit() {
    this.nav.setIsNewUi(true);
    this.isAdmin = this.authService.getType() === 2;
    this.user_id = this.authService.getUser();

    this.categories && this.initChoosedItems();

    this.tickets && this.initChoosedTickets();

    this.currentFacebookLoginDataSubscription = this.authService.getCurrentFacebookLoginData.subscribe(
      (facebook: LoginToAppResponse) => {
        if (facebook.data !== undefined && !facebook.error && facebook.data.user_id) {
          this.user_info = facebook.data;
          this.facebook_link = facebook.data.user_link;
          this.facebook_id = facebook.data.facebook_id;
          this.categoryStepChoise();
        } else if(facebook.error) {
          this.dialogService.alertsModal('errors', facebook.errorCode, 3000, false);
        }
      }
    );

    if(!this.event){
      this.sub = this.route.params.subscribe(params => {
        // console.log('queryParams purchase page', params);
        this.event = params['event'];
        if(!this.seller_code){
          this.seller_code = params['seller_code'];
        }
        if(!this.coupon_code){
          this.coupon_code = params['coupon_code'];
        }
      });
      (async () => {
        await this.getEventLp().then((response: any) => {
          this.processActionsBasedOnQueryParams();
          this.initChoosedItems();
          this.initChoosedTickets();
        });
      })();
    } else {
      this.boostrapCode();
      this.processActionsBasedOnQueryParams();
    }
// console.log('params event', this.event);
    /* Saving navbar query param and passing in it forword to next pages */
    this.queryParams = this.router.routerState.snapshot.root.queryParams.nav;
    if(this.queryParams === 'false'){
      this.customParams['nav'] = 'false';
      this.nav.hideHeader();
    }

    /* Hidding the facebook modals */
    this.queryParams = this.router.routerState.snapshot.root.queryParams.fb;
    if(this.queryParams === 'false'){
      this.fb_module = false;
    }

  }

  initChoosedTickets() {
    // console.log('initChoosedTickets');
    this.tickets.forEach(ticket => {
      this.ticketLimits[ticket.id] = {
        available: ticket.limits[ticket.limits.length - 1],
        choosed: {},
        availableToChoose: ticket.limits[ticket.limits.length - 1],
        options: ticket.limits
      }
    });

    if (this.categories) {
      this.categories.forEach(category => {
        Object.keys(this.ticketLimits).forEach(ticket => {
          this.ticketLimits[ticket]['choosed'][category.id] = {};
          this.ticketLimits[ticket]['choosed'][category.id].amount = 0;
          this.ticketLimits[ticket]['choosed'][category.id].options = this.ticketLimits[ticket].options;
        })
      })
    }

    Object.keys(this.ticketLimits).forEach(ticket => {
      this.ticketLimits[ticket]['choosed']['noCat'] = {};
      this.ticketLimits[ticket]['choosed']['noCat'].amount = 0;
      this.ticketLimits[ticket]['choosed']['noCat'].options = this.ticketLimits[ticket].options;
    });
  }

  initChoosedItems() {
// console.log('initChoosedItems');
    this.categories.forEach((category, index) => {
      this.ticketsFiltered[category.id] = this.tickets.filter(ticket => ticket.category_id.indexOf(category.id) > -1 );
      category.show = !!this.ticketsFiltered[category.id].length;
      this.showCategory[index] = false;
      this.choosedItems[category.id] = {'name': category.name};
    });

    this.withoutCategory = this.tickets.filter(ticket => !ticket.category_id);

    if (this.withoutCategory.length) {
      this.choosedItems['noCat'] = {};
    }
  }

  processActionsBasedOnQueryParams () {
    this.route.queryParams.subscribe(params => {
      if (params.action === 'change_facebook_user') {
        this.facebookService.getFBAccessCode();
      }
    });
  }

  /**
   * @function that have all bootstrap code that start the component logic
   */

  boostrapCode(){
    // console.log('boostrapCode');
    /* Fetching inputs for registration form */
    if(this.marketing){
      this.form_inputs = JSON.parse(this.marketing.form_inputs);
    }

    /* Fetching all the data we need for the component */
    if(this.event_details){

      this.event_id = this.event_details.event_id;
      this.commission = this.event_details.commission;
      this.purchaser_commission = this.event_details.purchaser_commission;
      this.tax_commission = this.event_details.tax_commission;
      this.service_commission = this.event_details.service_commission;
      this.platform_fee = this.event_details.platform_fee;
      this.platform_fee_rule = this.event_details.platform_fee_rule;
      this.platform_fee_type = this.event_details.platform_fee_type;
      this.purchase_validation = this.event_details.purchase_validation;
      this.currency = this.event_details.currency;
      this.purchase_type = this.event_details.purchase_type;
      this.facebook_validation = this.event_details.facebook_validation;
      this.birthday_validation = this.event_details.birthday_validation;

      /* When facebook connection is necessary it will be true */
      if(this.purchase_validation === 1 && this.facebook_validation === 1){
        this.facebookConnect = true;
        this.fb_module = true;
      }
      if (this.event_id === 163076 || this.event_id === 5778) {
        // remove get-in fb pixel for these events
        // console.log('remove FB Pixel');
        this.fb_pixel_model.removeGetinPixel(this._renderer2);
      }
    }
    this.form_inputs.default_inputs.unshift({ input_name: 'gender', input_options: [{ input_name: '0', placeholder: 'זכר' }, { input_name: '1', placeholder: 'זכר' }, { input_name: '2', placeholder: 'נקבה' }], input_type: 'select', placeholder: 'מין', required: this.event_details.gender ? '1' : '0', custom_input: true });
    this.form_inputs.default_inputs.unshift({ input_name: 'identification_number', input_options: [], input_type: 'number', placeholder: 'id_number', required: '1', custom_input: true });
    if (this.birthday_validation || this.event_details.age_display === 2) {
      this.form_inputs.default_inputs.unshift({ input_name: 'birth_date', input_type: 'date', placeholder: 'Birth date', required: '1', custom_input: true});
    }
    this.form_inputs.default_inputs.unshift({ input_name: 'age', input_type: 'number', placeholder: 'age', required: this.event_details.age_display ? '1': '0', custom_input: true })
    this.form_inputs.default_inputs.unshift({ input_name: 'user_email', input_type: 'email', placeholder: 'Email', required: '1', custom_input: true});
    this.form_inputs.default_inputs.unshift({ input_name: 'user_phone', input_type: 'phone', placeholder: 'Phone number', required: '1', custom_input: true});
    this.form_inputs.default_inputs.unshift({ input_name: 'full_name', input_type: 'text', placeholder: 'First & last name', required: '1', custom_input: true });
    // console.log('setFormInputs', this.form_inputs );

    /* Category calculation */
    this.categoryStepChoise();

    /* User info for autofill */
    var user_info = this._cookieService.get('user_info');

    if(user_info){
      var dec_user_info = CryptoJS.AES.decrypt(user_info, 'user_info');
      this.user_info = dec_user_info.toString(CryptoJS.enc.Utf8);
      this.user_info = JSON.parse(this.user_info);
    }

    /* Facebook connect only */
    if (this.facebookConnect && this.facebook_id == '0' && this.fb_module && !this.isOwner) {
      // !this.isOwner - if not producer and not admin then should connect to FB
        this.step = 'facebook_connect';
    }


    /* Checking for add mailing from GETIN */
    if(this.mailing){
      this.mailing = (this.mailing.required === '1' || this.mailing === true) ? true : false;
    }
  }

  ngOnDestroy() {
    this.fb_pixel_model.removePixelInit(this._renderer2, this.event_pixel);
    if (this.checkPaymentSubscription) {
      this.checkPaymentSubscription.unsubscribe();
    }

    if (this.card) {
      this.card.removeEventListener('change', (error) => {this.onChange(error);});
      this.card.destroy();
    }

    this.nav.setPurchaseModalStatus('closed');

    /* Only when this is full purchase mode we unsubscribe */
    if(this.full_purchase || this.eventPaymentMode){
      this.sub.unsubscribe();
    }

    /* Showing reseting the nav and custom app component class  */
    if(this.full_purchase || this.eventPaymentMode){
      this.nav.customClass = undefined;
      // this.nav.show();
    }

    this.fb_pixel_model.removeGetinPurchaseTag(this._renderer2);
    // // OLD variant - only for 1 pixel
    // this.fb_pixel_model.removePurchaseTag(this._renderer2, this.marketing.facebook_pixel_id);
    if (this.marketing && this.marketing.facebook_pixels && this.marketing.facebook_pixels.length) {
      this.marketing.facebook_pixels.forEach(val => {
        this.fb_pixel_model.removePurchaseTag(this._renderer2, [+val.pixel_id]);
      });
    }

    //Unsubscribe from facebook_id
    this.currentFacebookLoginDataSubscription.unsubscribe();
    this.nav.showHeader();
    if (this.checkStripeWindowID) {clearInterval(this.checkStripeWindowID);}
  }


  closeModal(){
    // console.log('closeModal');
    if (this.checkStripeWindowID) {clearInterval(this.checkStripeWindowID);}
    if(!this.full_purchase && !this.eventPaymentMode){
      this.closePurchaseModal.emit();
    } else {
      this.returnTo('items_section', 'init', true);
    }
  }

  /**
   * @function: Closing the purchase modal
   */
  closePurchase() {
    if (this.checkStripeWindowID) {clearInterval(this.checkStripeWindowID);}
    if (this.purchase_id === undefined || this.showSeatsIo) {
      this.router.navigate(['/dashboard']);
    } else {
      this.checkCancelPurchaseOnBackStep('items_section', true);
    }

  }

  /**
   * @function: that checks if we have more then one category tickets/tables/rooms
   */
  categoryStepChoise(){
    // console.log('categoryStepChoise');
        //Check what step should set up
        if((this.tickets || this.tickets_reg) && (this.tables && this.tables.length === 0) && (this.tables_reg && this.tables_reg.length === 0) && (this.rooms && this.rooms.length === 0) && (this.rooms_reg && this.rooms_reg.length === 0)){
          this.step = 'items_section';
          this.title = 'ticket';
          this.purchase_item = 1;
          this.noCategory = true;
        } else if ((this.tables || this.tables_reg) && (this.tickets && this.tickets.length === 0) && (this.tickets_reg && this.tickets_reg.length === 0) && (this.rooms && this.rooms.length === 0) && (this.rooms_reg && this.rooms_reg.length === 0)) {
          this.step = 'items_section';
          this.title = 'table';
          this.purchase_item = 3;
          this.noCategory = true;
        } else if ((this.rooms || this.rooms_reg) && (this.tickets && this.tickets.length === 0) && (this.tickets_reg && this.tickets_reg.length === 0) && (this.tables && this.tables.length === 0) && (this.tables_reg && this.tables_reg.length === 0)){
          this.step = 'items_section';
          this.title = 'room';
          this.purchase_item = 2;
          this.noCategory = true;
        } else {
          this.step = 'category_step';
        }
  }

  checkTicketsAvailability(){

    let available_items_counter = 0;
    let no_active_items_counter = 0;
    let reg_only_counter = 0;

      if(this.all_tickets.length > 0){
        for(let ticket of this.all_tickets){
          if(ticket.availability == 1){
            available_items_counter++;
            if(ticket.reg == 1){
              reg_only_counter++;
            }
          } else {
            no_active_items_counter++;
          }
        }
        if(this.all_tickets.length == no_active_items_counter) {
          this.ticketsAvailability = 0;
        } else if(available_items_counter == reg_only_counter) {
          this.ticketsAvailability = 'register_only';
        } else {
          this.ticketsAvailability = 1;
        }

      }else{
        this.ticketsAvailability = 0;
      }

  }

  addPixelEvent(pixelAction) {
    // console.log('Add pixel event ', pixelAction);
    if (this.marketing.facebook_pixels && this.marketing.facebook_pixels.length) {
      this.event_pixel = null;
      this.event_pixels = [];
      this.marketing.facebook_pixels.forEach(val => {
        if (pixelAction === val.type_name) {
          this.event_pixel = +val.pixel_id;
          this.event_pixels.includes(+val.pixel_id) ? false: this.event_pixels.push(+val.pixel_id);
          // console.log('Purchase Modal: ', val.pixel_id, val.type_name, pixelAction, this.currency, +this.total_price_n_commission || 0);
          this.fb_pixel_model.setPixel(this._renderer2, this.event_pixels, false, this.currency, +this.total_price_n_commission || 0, pixelAction);
        }
      });
      // For compatibility with previous changes
      if (!this.event_pixel) {
        this.event_pixels = [];
        this.marketing.facebook_pixels.forEach(val => {
          this.event_pixels.includes(+val.pixel_id) ? false: this.event_pixels.push(+val.pixel_id);
          this.event_pixel = this.marketing.facebook_pixels[0].pixel_id;
        });
        this.fb_pixel_model.setPixel(this._renderer2, this.event_pixels, false, this.currency, +this.total_price_n_commission || 0, pixelAction);
        // console.log('addPixelEvent alternative', this.event_pixel, pixelAction);
      }
    } else {
      // add Getin Pixel when AddPaymentInfo
      this.fb_pixel_model.setGetinPixel(this._renderer2, false, this.currency, +this.total_price_n_commission || 0, pixelAction);
    }
  }


  /**
   * @function that fething data for component from backend and map it
   * @description (Relevent only for full-purchase mode)
   */
  async getEventLp(){
    // console.log('getEventLp');
    return new Promise((resolve, reject) => {
    this.eventService.getEventDatalp(this.event, this.seller_code, this.coupon_code)
        .subscribe(
          eventData => {
            this.salesman_id = (this.salesman_id) ? this.salesman_id : eventData.lp_salesman_id,
              this.salesman_user = (this.salesman_user) ? this.salesman_user : eventData.salesman_user,
              this.event_details = eventData.event_details[0],
              this.paymentGateways = eventData.payment_gateways;
              this.producer_id = eventData.event_details[0].user_id;
              // console.log('paymentGateways', this.paymentGateways);
              this.marketing = eventData.marketing,
              this.mailing = { input_name: 'mailing', input_options: [], input_type: 'checkbox', placeholder: 'צ\'קבוקס אישור דיוור של גטאין', required: '1', },
              this.tickets = eventData.tickets,
              this.categories = eventData.categories,
              this.rooms = eventData.rooms,
              this.tables = eventData.tables,
              this.tickets_reg = eventData.tickets_reg,
              this.rooms_reg = eventData.rooms_reg,
              this.tables_reg = eventData.tables_reg,
              this.coupons = eventData.coupons,
            this.discounts = eventData.discounts,
            this.event_fb_friends = eventData.event_fb_friends,
            this.form_inputs = JSON.parse(this.marketing.form_inputs);
            this.all_tickets = eventData.all_tickets;
            this.currency = eventData.event_details[0].currency;
            this.donation_fee_mode = eventData.event_details[0].donation_fee_mode;

            this.new_custom_inputs = eventData.custom_inputs;
            this.checkBoxProducerSubscription = this.event_details.producer_subscription ? true : false; // I accept receiving updates from this producer

            this.eventService.setSpikeToStorage(eventData.spike_protected_session);

            this.checkTicketsAvailability();
            this.getProducerData();

            if (eventData.seats && eventData.seats.hasOwnProperty('event') && eventData.seats.hasOwnProperty('workspaceKey')) {
              this.showSeatsIo = true; // seats.io
              this.seatsUserConfig = eventData.seats.event_tickets_categories;
              this.seatsSupportsBestAvailable = eventData.seats.supportsBestAvailable;
              if (!this.seatsUserConfig) {
                console.log('Please create Seats config!');
                return
              }
              this.createAvailableListCategories(this.seatsUserConfig);

              this.createSeatsConfig(eventData.seats.event, eventData.seats.workspaceKey, this.seatsUserConfig, eventData.seats.region);  // seats.io
              // this.createSeatsConfig("9fa18038-ed86-4e48-8abf-8f7b0881fa65", "9d84f1c5-ce98-4d24-990f-ca3b40121c9a", this.seatsUserConfig, 'eu');

            }


            if(this.event_details) {
              this.addPixelEvent('ViewContent');
              ////////// Google Analytics pixel //////////

              if(this.marketing.google_analytics_ua) {
                // create first script tag:
                let google_analytics_tag1 = this._renderer2.createElement('script');
                google_analytics_tag1.type = 'text/javascript';
                google_analytics_tag1.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.marketing.google_analytics_ua;

                // create second script tag:
                let google_analytics_tag2 = this._renderer2.createElement('script');
                google_analytics_tag2.type = 'text/javascript';
                google_analytics_tag2.text =  `
                                                window.dataLayer = window.dataLayer || [];
                                                function gtag(){dataLayer.push(arguments);}
                                                gtag('js', new Date());

                                                gtag('config', '` + this.marketing.google_analytics_ua + `');
                                              `;

                // append GA tag to document (in reverse order - since the method is prepend to head):
                this._renderer2.insertBefore(this._document.head, google_analytics_tag2, this._document.head.firstChild);
                this._renderer2.insertBefore(this._document.head, google_analytics_tag1, this._document.head.firstChild);

              }

              // plant scripts for Facebook and google pixels
              // // OLD variant - only for 1 pixel
              // if(this.marketing.facebook_pixel_id){
              //   this.fb_pixel_model.setPixel(this._renderer2, this.marketing.facebook_pixel_id, false);
              // }
              this.addPixelEvent('InitiateCheckout');

              ////////// Google leads pixel //////////

              if(this.marketing.google_leads){

                // part 1 of google leads (remarketing):
                let google_leads_pt1 = this._renderer2.createElement('script');
                google_leads_pt1.type = 'text/javascript';
                google_leads_pt1.text =  `
                                          /* <![CDATA[ */
                                          var google_conversion_id = `+ this.marketing.google_leads +`;
                                          var google_custom_params = window.google_tag_params;
                                          var google_remarketing_only = true;
                                          /* ]]> */
                                        `;

                // part 2 of google leads:
                let google_leads_pt2 = this._renderer2.createElement('script');
                google_leads_pt2.type = 'text/javascript';
                google_leads_pt2.src =  '//www.googleadservices.com/pagead/conversion.js';

                // create the noscript tag for google leads:
                let google_leads_noScript = this._renderer2.createElement('noscript');
                let noScriptContents = this._renderer2.createElement('div');
                let img_tag = this._renderer2.createElement('img');
                img_tag.src = '//googleads.g.doubleclick.net/pagead/viewthroughconversion/' + this.marketing.google_leads + '/?guid=ON&script=0';
                img_tag.height = 1;
                img_tag.width = 1;
                img_tag.alt = '';
                this._renderer2.setStyle(img_tag, 'width', '1');
                this._renderer2.setStyle(img_tag, 'height', '1');
                this._renderer2.setStyle(img_tag, 'border-style', 'none');
                this._renderer2.setStyle(noScriptContents, 'display', 'inline');
                this._renderer2.appendChild(noScriptContents, img_tag);
                this._renderer2.appendChild(google_leads_noScript, noScriptContents);

                // part 3 of google leads:
                let google_leads_pt3 = this._renderer2.createElement('script');
                google_leads_pt3.async = 'true';
                google_leads_pt3.src =  'https://www.googletagmanager.com/gtag/js?id=AW-' + this.marketing.google_leads;

                // part 4 of google leads:
                let google_leads_pt4 = this._renderer2.createElement('script');
                google_leads_pt4.text = `
                                          window.dataLayer = window.dataLayer || [];
                                          function gtag(){dataLayer.push(arguments);}
                                          gtag('js', new Date());

                                          gtag('config', 'AW-` + this.marketing.google_leads + `');
                                        `;

                // append google leads to document:
                this._renderer2.appendChild(this._document.body, google_leads_pt3);
                this._renderer2.appendChild(this._document.body, google_leads_pt4);
                this._renderer2.appendChild(this._document.body, google_leads_pt1);
                this._renderer2.appendChild(this._document.body, google_leads_pt2);
                this._renderer2.appendChild(this._document.body, google_leads_noScript);
              }

              this.event_id = this.event_details.event_id;

              this.checkedMailing(this.event_details.getin_subscription);

              /* Bootstraping the component */
              this.boostrapCode();
              resolve(true);
              //TODO - Meta tags need to be set if we will need them
              if(this.event_details.title) {
                this.rudderstackService.page('Event management','Checkout - ' + this.event_details.title);
              }
            }

            // get stripe payment methods and show saved credit cards
            if (this.paymentGateways && this.paymentGateways.length) {
              let stripe_count = 0, hyper_count = 0;
              let isHyperProd = false;

              this.paymentGateways.forEach(gw => {
                console.log(gw.payment_type);
                if (gw.payment_type === 'stripe_gateway') {stripe_count++}
                if (gw.payment_type === 'hyper_switch') {hyper_count++}
                if (gw.hasOwnProperty('isProd')) { isHyperProd = gw.isProd }
              })

              if (stripe_count && !hyper_count) {
                console.log('loadStripe');
                // let hyperScript = this._document.getElementById('hyper-script');
                // if (hyperScript) {
                //   hyperScript.remove();
                // }

                // We creates a copy of Stripe func
                // Issue - Hyper always override window.Stripe
                if(window.StripeCopy) {
                  window.Stripe = window.StripeCopy;
                } else {
                  this.checkStripeWindowID = setInterval(() => {
                    console.log('window.Stripe1', window.Stripe);
                    if (window.Stripe) {
                      window.StripeCopy = window.Stripe;
                      clearInterval(this.checkStripeWindowID);
                    }
                  }, 300);
                }
                // Issue - Hyper always override window.Stripe END
                this.loadStripe();
              }
              if (hyper_count && !stripe_count) {
                console.log('loadHyper');
                if (this.checkStripeWindowID) {clearInterval(this.checkStripeWindowID);}
                // Issue - Hyper always override window.Stripe
                this.loadStripe(); // Stripe First
                this.checkStripeWindowID = setInterval(() => {
                  console.log('Copy function from window.Stripe to window.StripeCopy', window.Stripe);
                  if (window.Stripe) {
                    if (!window.StripeCopy) {
                      window.StripeCopy = window.Stripe;
                    }
                    this.loadHyper(isHyperProd); // Hyper Second
                    clearInterval(this.checkStripeWindowID);
                  }
                }, 300);
                // Issue - Hyper always override window.Stripe END

              }
              if (hyper_count && stripe_count) {
                console.log('loadHyper but have also Stripe Payment Method');

                // Issue - Hyper always override window.Stripe
                this.loadStripe(); // Stripe First
                this.checkStripeWindowID = setInterval(() => {
                  console.log('Copy function from Stripe to StripeCopy', window.Stripe);
                  if (window.Stripe) {
                    window.StripeCopy = window.Stripe;
                    this.loadHyper(isHyperProd); // Hyper Second
                    clearInterval(this.checkStripeWindowID);
                  }
                }, 300);
                // Issue - Hyper always override window.Stripe END

              }
            }


          },
          error => {
            this.dialogService.alertsModal('landingPage', error.code, 3000, false, (typeof error.log_id !== 'undefined') ? error.log_id : 0);
            reject();
          }
        );
      });
  }

  /**
   * @function ShowItem: Show you proper item category
   * @param category : can be (1) tickets, (2) rooms, (3) tables
   */
  showItems(category) {
    // console.log('showItems');
    if(category == 1) {
      this.title = 'ticket';
      this.purchase_item = 1;
    } else if(category == 2) {
      this.title = 'room';
      this.purchase_item = 2;
    } else if(category == 3){
      this.title = 'table';
      this.purchase_item = 3;
    } else if(category == 4) {
      this.purchase_item = 1;
      this.title = 'register';
    }

    /* We only have tickets for now */
    this.step = 'items_section';


  }

  /**
   * @function for going back arrow
   * @param category respresent string that telling us what section to show
   */
  returnTo(category: string, param?, cancel_purchase = false) {
    console.log('returnTo items_section', category, param);
    if (param === 'init') {
      this.total_price_w_commission = '0.00';
      this.initChoosedTickets();

      this.isOpenWallets = this.isOpenSolanaWallets = false;
      this.walletConnectData = this.solanaConnectData = {};
      this.isCloseWallet = this.isCloseSolanaWallet = true;
      this.payment_id = null;
      this.clearDonationTicketsPrice();
    }
    if (category === 'items_section') {
      if(this.showSeatsIo) {
        this.chartSeats.clearSelection();
        this.seatsTickets = [];
        this.showSeatsDisplay = true;
      }

      //Remove formArray controls
      var arr = (<UntypedFormArray>this.participantForm.controls.participants);
      for(var x = 0; x < this.quantity; x++) {
        // We always delete first form array
        arr.removeAt(0);
      }

      // Reset all the variables
      this.checkboxArr = {};
      this.item_id = null;
      this.counter = 0;
      this.quantity = 0;
      this.formSubmited = false;
      this.toggleParticipant = 1;
      this.toggleItem = 0;
      this.initChoosedItems();

      // Delete all info about coupons
      this.refreshCouponData();

    }
    if (cancel_purchase) {
      if(this.showSeatsIo) {
        this.step = 'items_section';
        this.main_purchase_identifier = '';
        this.seatsHash = '';
        this.purchase_id = undefined;
      } else {
        this.checkCancelPurchaseOnBackStep('items_section');
      }
    }
    this.step = category;
  }

  checkCancelPurchaseOnBackStep(stepTemplate, navigateHomeOnClose = false, disableCancelPurchase = false) {
    this.checkoutTimer.resetTimer();
    if (this.checkPaymentSubscription) {this.checkPaymentSubscription.unsubscribe();}
    if (this.purchase_id === undefined || this.main_purchase_identifier.length == 0) return
    if(disableCancelPurchase) {return;}
    this.globals.startLoader();
    this.eventService.cancelPurchase(this.main_purchase_identifier).subscribe(res => {
        //console.log(res);
        if (navigateHomeOnClose) {
          this.router.navigate(['/dashboard']);
        } else {
          // this.stepTemplate = stepTemplate; //
          this.purchase_id = undefined;
          this.main_purchase_identifier = '';
          this.step = stepTemplate;
          if (this.unified_checkout !== undefined) {
            this.unified_checkout.nativeElement.innerHTML = '';
          }
          this.hyperSubmitHidden = true;
        }

        this.globals.stopLoader();
      },
      error => {
        this.main_purchase_identifier = '';
        this.hyperSubmitHidden = true;
        if (this.unified_checkout !== undefined) {
          this.unified_checkout.nativeElement.innerHTML = '';
          this.hyper_errors.nativeElement.innerText = '';
          this.hyper_return_url = '';
        }
        this.globals.stopLoader();
      })
  }

  //15 minutes timer goes to the end
  getStoppedCheckoutTimer(event) {
    if (event) {
      this.returnTo('init');
    }
  }

  /**
   * @function is for choosing the relevent item and setting all info we need for purchase
   * @param item object of this item from backend
   * @param quantity of the specific selected item
   * @param category of ticket
   * @param purchase_item type of the selected item (1) ticket (2) room (3) table
   */
  choose(item, sign, category = null, purchase_item) {

    if(!item.donation_type && this.hasDonateTicket || item.donation_type && this.hasRegularTicket) {
      return
    }

    const qty = 1;
    let quantity;
    if (sign === 'plus') {
      quantity = qty + this.ticketLimits[item.id]['choosed'][category ? category : 'noCat'].amount;
    } else {
      if (this.ticketLimits[item.id]['choosed'][category ? category : 'noCat'].amount > 1) {
        quantity = -qty + this.ticketLimits[item.id]['choosed'][category ? category : 'noCat'].amount;
      } else {
        quantity = 0;
      }
    }
    // console.log('choose');
    // if (quantity <= item.limits.length) {
    if (quantity <= (this.isAdmin?300:item.limits.length)) { // temporary set 300 for admins
      // Recalculate ticket limits
      this.ticketLimits[item.id]['choosed'][category ? category : 'noCat'] = {};
      this.ticketLimits[item.id]['choosed'][category ? category : 'noCat'].amount = +quantity;
      let availableToChoose = this.ticketLimits[item.id]['available'];
      Object.keys(this.ticketLimits[item.id]['choosed']).forEach(categoryName => {
        const currentAmount = this.ticketLimits[item.id]['choosed'][categoryName].amount;
        this.ticketLimits[item.id]['choosed'][categoryName].options = [];
        availableToChoose -= currentAmount;
      });
      this.ticketLimits[item.id]['availableToChoose'] = availableToChoose;
      Object.keys(this.ticketLimits[item.id]['choosed']).forEach(categoryName => {
        const currentAmount = this.ticketLimits[item.id]['choosed'][categoryName].amount;
        for (let i = 1; i <= availableToChoose + currentAmount; i++) {
          this.ticketLimits[item.id]['choosed'][categoryName].options.push(i)
        }
      });

      if (+quantity < 1) {

        delete this.choosedItems[category ? category : 'noCat'][item.id];

      } else {

        const ticket = {};
        ticket['amount'] = +quantity;
        ticket['price'] = +item.price;
        ticket['name'] = item.name;
        // console.log('choosedItems', quantity, category, item.price, item.name, item.id, ticket, item, this.choosedItems);
        this.choosedItems[category ? category : 'noCat'][item.id] = ticket;

      }

      // Category (1 for items), (2 for rooms), (3 for tables)
      this.purchase_item = purchase_item;

      // Register item
      let foundNotReg = false;

      Object.keys(this.choosedItems).forEach(cat => {
        Object.keys(this.choosedItems[cat]).forEach(id => {
          if (id !== 'name') {
            if (this.tickets.filter(ticket => ticket.id === +id)[0].reg === 0) {
              foundNotReg = true;
            }
          }
        })
      });

      this.item_is_reg = !foundNotReg;

      this.calcRegular();

      // Price calculation when choosing ticket and price
      this.total = this.calcTotalItemsAndPrice();

      // Item quantity
      this.quantity = this.total.total_items;

      this.item_id = item.id;

      this.calcTotalPrice(false);
    }
  }

  fillForMe(index, user?) {

    let user_info;
    if (user) {
      user_info = user;
    } else {
      user_info = this.user_info;
    }
    const ticketControls = this.participantForm.controls['participants']['controls'][index].controls;
     console.log('fillForMe', ticketControls, this.user_info);
    // if (ticketControls.first_name) {
    //   ticketControls.first_name.setValue(user_info.first_name ? user_info.first_name : '');
    // }
    // if (ticketControls.last_name) {
    //   ticketControls.last_name.setValue(user_info.last_name ? user_info.last_name : '');
    // }
    if (ticketControls.full_name) {
      ticketControls.full_name.setValue(this.user_info.full_name ? this.user_info.full_name : this.user_info.first_name + ' ' + this.user_info.last_name);
    }
    if (ticketControls.user_phone) {
      ticketControls.user_phone.setValue(user_info.phone ? user_info.phone : '');
    }
    if (ticketControls.user_email) {
      ticketControls.user_email.setValue(user_info.email ? user_info.email : '');
    }
    if (ticketControls.birth_date) {
      ticketControls.birth_date.setValue(user_info.birth_date ? user_info.birth_date : '');
    }
    if (ticketControls.gender) {
      ticketControls.gender.setValue(user_info.gender ? user_info.gender : '');
    }
    if (ticketControls.identification_number) {
      ticketControls.identification_number.setValue(user_info.identification_number ? user_info.identification_number : '');
    }
    if (ticketControls.id_number) {
      ticketControls.id_number.setValue(user_info.identification_number ? user_info.identification_number : '');
    }
    if (ticketControls.age) {
      ticketControls.age.setValue(this.user_info.age ? this.user_info.age : '');
    }
    if (ticketControls.age && user_info.birth_date) {
      const userAgeMs =  new Date().valueOf() - new Date(user_info.birth_date.replace(/-/g, '/')).valueOf();
      const userAgeYears = Math.floor(userAgeMs / (1000 * 60 * 60 * 24 * 365));
      ticketControls.age.setValue(userAgeYears);
    }
  }

  clearAttendee(index) {
    // console.log('clearAttendee');
    const ticketControls = this.participantForm.controls['participants']['controls'][index].controls;
    Object.keys(ticketControls).forEach(control => {
      if (control !== 'item_id' && control !== 'category') {
        ticketControls[control].setValue('')
      }
    })
  }

  /**
   * @function that moves you to participant step
   * @description triggering creation of the form controlls defering on quantity user choise
   */
  toParticipantData() {
    if(!this.ticketsAvailability && !this.isFromEventPayment){
      return;
    }
    //Doesn't choose ticket/table/room
    if(!this.quantity || this.quantity <= 0 || this.quantity === 'null') {
      this.dialogService.alertsModal('landingPage', 11, 2000, false);
      return;
    }
    if(this.showSeatsIo) {
      this.addSeatsTickets();
      this.showSeatsDisplay = false;
      return;
    }
    // Remove all formArray controls
    const arr = (<UntypedFormArray>this.participantForm.controls.participants);
    while (arr.length !== 0) {
      arr.removeAt(0)
    }
    this.counter = 0;

    /* Creation of form controlls */
    let setedFirstElement = false;
    Object.keys(this.choosedItems).forEach(cat => {
      if (Object.keys(this.choosedItems[cat]).length === 1 && this.choosedItems[cat].hasOwnProperty('name')) {
        delete this.choosedItems[cat];
      } else {
        Object.keys(this.choosedItems[cat]).forEach(ticket => {
          if (ticket !== 'name') {
            for (let i = 0; i < this.choosedItems[cat][ticket].amount; i++) {
              if (this.purchase_type !== 1 || (this.purchase_type === 1 && !setedFirstElement)) {
                (<UntypedFormArray>this.participantForm.controls['participants']).push(this.createControls(ticket, cat));
                setedFirstElement = true;
              }
            }
          }
        })
      }
    });
    // console.log('this.choosedItems', this.choosedItems, this.participantForm);
    // Show participant step
    this.step = 'participant_details';

    // Add pixel event - AddToCart
    this.addPixelEvent('AddToCart');
  }

  /**
   * @function Creation of the form controls and sets up the validation functions.
   * */
  createControls(id?, category?, seatsLabels = null, seatTicketId = null, seatTicketIds = []) {
    // console.log('createControls', this.form_inputs.default_inputs);
    const group = this.fb.group({});
    this.form_inputs.default_inputs.forEach(control =>
      {
        // default inputs created only if thay are required
        if (control.required === '1') {

          if (control.input_name === 'first_name') {

            group.addControl(control.input_name, this.fb.control((this.counter === 0 && this.user_info && !this.salesman_user) ? this.user_info.first_name : '',
              [
                Validators.required
              ]
            ));

          } else if (control.input_name === 'last_name') {

            group.addControl(control.input_name, this.fb.control((this.counter === 0 && this.user_info && !this.salesman_user) ? this.user_info.last_name : '',
              [
                Validators.required
              ]
            ));

          } else if (control.input_name === 'full_name') {

            group.addControl(control.input_name, this.fb.control((this.counter === 0 && this.user_info && !this.salesman_user) ? this.user_info.full_name ? this.user_info.full_name : this.user_info.first_name ? this.user_info.first_name + ' ' + this.user_info.last_name : ''  : '',
              [
                Validators.required
              ]
            ));

          } else if (control.input_name === 'gender') {

            if (this.event_details.gender !== 0) {
              group.addControl(control.input_name, this.fb.control((this.counter === 0 && this.user_info && !this.salesman_user && this.user_info.gender !== null) ? this.user_info.gender : '',
                this.event_details.gender ? [ Validators.required ] : []
              ));
            } else {
              control.required = '0';
            }

          } else if (control.input_name === 'user_phone') {

            group.addControl(control.input_name, this.fb.control((this.counter === 0 && this.user_info && !this.salesman_user) ? this.user_info.phone : '',
              [
                Validators.required
              ]
            ));
            let userPhone = group.get('user_phone').value;
            if (userPhone && userPhone.match(/^0[\d]{1,2}[-]?[\d]{3}[-]?[\d]{4}$/)) {
              if (userPhone.charAt(0) === '0') {
                userPhone = userPhone.substr(1);
              }
              userPhone = '+972' + userPhone;
              group.get('user_phone').patchValue(userPhone);
            }
            group.addControl('phoneCountryCode', new UntypedFormControl(''));

          } else if (control.input_name === 'user_email') {

            group.addControl(control.input_name, this.fb.control((this.counter === 0 && this.user_info && !this.salesman_user) ? this.user_info.email : '',
              [
                Validators.required,
                Validators.pattern(emailRegex)
              ]
            ));

          } else if (control.input_name === 'identification_number' || control.input_name === 'id_number') {
            if (this.event_details.id_number) {
              group.addControl(control.input_name, this.fb.control((this.counter === 0 && this.user_info && !this.salesman_user) ? this.user_info.identification_number : '',
                [
                  Validators.required,
                  Validators.minLength(7),
                  Validators.maxLength(10)
                ]
              ));
            } else {
              control.required = '0';
            }
            //Birth date control will be created when is no requirement for birth date and when birth date is limited
          } else if (control.input_name === 'birth_date' && control.required != '0') {

            // if (this.event_details.birth_date) {

              group.addControl(control.input_name, this.fb.control((this.counter === 0 && this.user_info && !this.salesman_user) ? this.user_info.birth_date : '',
                [
                  Validators.required,
                  this.validateDates.bind(this)
                ]
              ));
            // } else {
            //   control.required = '0';
            // }
          } else if (control.input_name === 'age' && control.required == '1') {
            // if (this.event_details.age_display !== 0) {
              //Setting this param for age validation function is required.
              this.ageValidation = true;

              group.addControl(control.input_name, this.fb.control((this.counter === 0 && this.user_info && !this.salesman_user && this.user_info.age !== '0') ? this.user_info.age : '',
                [
                  this.event_details.age_display !== 2 ? Validators.required : Validators.nullValidator,
                  Validators.min(0),
                  Validators.max(120),
                  // this.validateDates.bind(this)
                ]
              ));
            // } else {
            //   control.required = '0';
            // }
            //Making hidden control
            // group.addControl('birth_date', this.fb.control((this.counter === 0 && this.user_info && !this.salesman_user) ? this.user_info.birth_date : '', Validators.required));

          } else {
            group.addControl(control.input_name, this.fb.control('', Validators.required));
          }

        }
      });

    // Add instagram URL
    if (this.event_details.instagram_url === 1) {
      group.addControl('instagram_url', this.fb.control( '',[Validators.required]));
    }

    // Add ticketID and category
    group.addControl('item_id', this.fb.control( id ? id : null));

    if (category) {
      group.addControl('category', this.fb.control( category,
        [
          Validators.required
        ]
      ));
    }

      //Custom inputs will be created when they are required or not, only validation is changing
    // this.form_inputs.custom_inputs.forEach(control => {
    //     if (control.required === 1) {
    //       if (control.input_type === 'radio') {
    //         group.addControl(control.input_name, this.fb.control(control.input_options[0].input_name, Validators.required));
    //       } else {
    //         group.addControl(control.input_name, this.fb.control('', Validators.required));
    //       }
    //     } else {
    //       if (control.input_type === 'radio') {
    //         group.addControl(control.input_name, this.fb.control(control.input_options[0].input_name));
    //       } else {
    //         group.addControl(control.input_name, this.fb.control(''));
    //       }
    //     }
    //
    //     //If we having checkbox we creating array for his value.
    //     if (control.input_type === 'checkbox') {
    //       this.checkboxArr[this.counter + '-' + control.input_name] = [];
    //     }
    //   });

    // NEW Custom inputs START
    //console.log("this.custom_inputs", this.new_custom_inputs)
    this.new_custom_inputs.forEach(control => {
      if (control.blocked) {
        return
      }
      if (control.required) {
        if (control.type === 'radio') {
          group.addControl(control.id, this.fb.control(control.opinions[0].title, Validators.required));
        } else {
          group.addControl(control.id, this.fb.control('', [Validators.required]));
        }
      } else {
        if (control.type === 'radio') {
          group.addControl(control.id, this.fb.control(control.opinions[0].title));
        } else {
          group.addControl(control.id, this.fb.control(''));
        }
      }

      if (control.type === 'checkbox') {
        this.checkboxArr[this.counter + '-' + control.id] = [];
      }
    });
    // NEW Custom inputs END


    // SEATS IO ADDITIONAL INFO
    // ONLY FOR INDIVIDUAL PURCHASE FORM
    if (seatsLabels && this.purchase_type !== 1) {
      // LABELS OBJECT EXAMPLE
      // labels : {
      //   "own": "37",
      //   "parent": "12",
      //   "section": "12",
      //   "displayedLabel": "12-12-37",
      //   "type": "row" // additional param  row or table pass in
      // }
      group.addControl('labels', this.createSeatsLabelsControls(seatsLabels));

    }
    if (seatsLabels && this.purchase_type == 1) {
      // console.log('seatsLabels', seatsLabels)
      group.addControl('labels', this.createSeatsLabelsControlsAsArray(seatsLabels));
    }

    if (seatTicketId) {
      group.addControl('seat_id', this.fb.control(seatTicketId));
    }
    if (seatTicketIds.length) {
      group.addControl('seat_ids', this.fb.control(seatTicketIds));
    }

    // SEATS IO ADDITIONAL INFO END


    this.counter++;
      return group;
  }

  createSeatsLabelsControls(labels) {
    // console.log('lables', labels)
    const group = this.fb.group({});
    Object.keys(labels).forEach(label => {
      // console.log(label, labels[label]);
      group.addControl(label, this.fb.control(labels[label]));
    });
    return group;
  }

  createSeatsLabelsControlsAsArray(labels) {
    const arr = this.fb.array([]);
    Object.keys(labels).forEach(label => {
      const group = this.fb.group({});
      group.addControl(label, this.fb.control(labels[label]));
      arr.push(group);

    });
    return arr;
  }

  /**
   * @function toggles the participant form block
   * @param counter number of the blocks is open or clossed or undefined
   */
  toggleParticipantForm(counter){
    // console.log('toggleParticipantForm');
    if(counter === this.toggleParticipant){
      this.toggleParticipant = undefined;
    } else {
      this.toggleParticipant = counter;
    }
  }

  /**
   * @function checkbox validation and value handler
   * @param object checkbox event
   * @description it will push value in the form object and it will delete it if it unchecked
   */
  checkboxHandler(object: any) {

    if(object.isChecked) {
      this.checkboxArr[object.counter+'-'+object.checkboxName].push(object.checkboxValue);
    } else {
      for(let i = 0; i < this.checkboxArr[object.counter+'-'+object.checkboxName].length; i++){
        if(this.checkboxArr[object.counter+'-'+object.checkboxName][i] === object.checkboxValue){
          this.checkboxArr[object.counter+'-'+object.checkboxName].splice(i,1);
        }
      }
    }

    this.participantForm.controls['participants']['controls'][object.counter].controls[object.checkboxName].setValue(this.checkboxArr[object.counter+'-'+object.checkboxName]);
  }

  radioHandler(object: any) {
    this.participantForm.controls['participants']['controls'][object.counter].controls[object.radioName].setValue(object.radioValue);
  }

  cf_radioHandler(i, counter, value: { counter, radioName, radioValue, isChecked, index }) {
    this.participantForm.controls['participants']['controls'][counter].controls[value.radioName].setValue(value.radioValue);
  }

  selectHandler(object: any) {
    this.participantForm.controls['participants']['controls'][object.counter].controls[object.selectName].setValue(object.selectValue);
  }

  myBirthdayChanged(object: any) {
    const userAgeYears = moment(new Date()).diff(new Date(object.val), 'years');
    if (this.user_info) { this.user_info.age = userAgeYears; }
    const control = this.participantForm.controls['participants']['controls'][object.counter].controls[object.name];
    if (control) { control.setValue(userAgeYears); }
   // console.log('myBirthdayChanged', object, userAgeYears)
  }

  ageToDate(data: any){
    const counter = data.counter;
    const inputName = data.inputName;
    let age = data.age;
    if(!age && inputName) {
      age = this.participantForm.controls['participants']['controls'][counter].controls[inputName].value;
    }
    let day = (new Date()).getDate();
		let month = ((new Date()).getMonth())+1;
    let year = (new Date()).getFullYear();
		// Year of birth
		year = year - age;
    let date = year + '-' + month + '-' + day;
    if(inputName) this.birth_date_creation[counter] = date;
    else return date;
  }

  /**
   * @function dates validation
   * @param control FormControl of the angular reactive form
   * @param ageValidation Is the boolean we get when we create age control
   */
  validateDates(control: UntypedFormControl){
    // console.log('validateDates');
    const eventDate = (this.event_details.birth_date !== null) ? new Date(this.event_details.birth_date) : false;
    const ageDisplay = this.event_details.age_display;
    const minAge = +this.event_details.min_age;
    const userAge = this.dateToAge(control.value);
    // console.log('validateDates', minAge, ageDisplay, userAge,  control.value, eventDate);
    const ageValidator = {
      minDate: {
        he: `תאריך לידה מינימלי ${this.datePipe.transform(this.event_details.birth_date, 'dd/MM/yyyy')}`,
        en: `Minimum date of birth should be ${this.datePipe.transform(this.event_details.birth_date, 'dd/MM/yyyy')}`,
        es: `La fecha mínima de nacimiento debe ser ${this.datePipe.transform(this.event_details.birth_date, 'dd/MM/yyyy')}`
      },
      minAgeByEvent: {
        he: `גיל מינימלי ${(eventDate) ? this.minEventDate(eventDate) : ''}`,
        en: `Minimum age should be ${(eventDate) ? this.minEventDate(eventDate) : ''}`,
        es: `La edad mínima debe ser ${(eventDate) ? this.minEventDate(eventDate) : ''}`
      },
      minAge: {
        he: `גיל מינימלי ${minAge}`,
        en: `Minimum age should be ${minAge}`,
        es: `La edad mínima debe ser ${minAge}`
      }

    }

    if (ageDisplay === 1 && !this.ageValidation && eventDate) {
      if (control.value > eventDate) {
        const message = ageValidator.minDate[this.localeId];
        return { validateDates: message };
      }
      return null;
    } else if (ageDisplay === 2 && this.ageValidation && minAge) {
      if (+userAge < minAge) {
        const message = ageValidator.minAge[this.localeId];
        return { validateDates: message };
      }
      return null;
    } else {
      return null;
    }
  }

  minEventDate(eventDate) {
    return (new Date()).getFullYear() - eventDate.getFullYear();
  }

  dateToAge(date: string) {
    // console.log('date', date);
    if (date) {
      // const userAgeMs = new Date().valueOf() - new Date(date.replace(/-/g, '/')).valueOf();
      // const userAgeYears = Math.floor(userAgeMs / (1000 * 60 * 60 * 24 * 365));
      // found this by accident, exactly the same mistake as in birthDateCange, probably causes problems if it is used somewhere or could cause in the future
      // found also date-to-age-pipe.ts which I think again calculates the age from some date, maybe apply this to there as well, someone check
      const userAgeYears = moment(new Date()).diff(new Date(date.replace(/-/g, '/')), 'years');
      return userAgeYears;
    }
    return null;
  }

  /**
   * @function opening the coupon section
   * @param checked represent boolean value of checkbox
   */
  coupon(checked: boolean, type = ''){
    if (this.event_details.event_id === 53684) {
      this.couponCheckBoxPeper = type === 'peper';
      this.couponCheckBox = type === 'regular';
      if (this.couponCoderegular !== undefined) {
        this.couponCoderegular.nativeElement.value = '';
      }
      if (this.couponCoderegular !== undefined) {
        this.couponCodePeper.nativeElement.value = '';
      }
    }
    this.couponModal = checked;
  }

  refreshCouponData() {
    this.total_discount = 0;
    this.total_ticket_discount = 0;
    this.couponModal = false;
    this.couponCheckBox = false;
    this.couponCheckBoxPeper = false;
    this.couponName = '';
    this.used_discount_type = undefined;
    this.used_discount_id = undefined;
    this.item_is_reg = undefined;
    this.selectOptionCouponValue = undefined;
    this.freeItem = undefined;
  }

  getCorrectCouponDiscount(couponCoderegular, couponCodePeper){
    let codeCoupon = '';
    if (couponCoderegular) {
      codeCoupon = couponCoderegular;
    } else if (couponCodePeper) {
      codeCoupon = couponCodePeper;
    }
    this.couponCoderegular.nativeElement.value = '';
    this.couponCodePeper.nativeElement.value = '';
    this.checkCouponDiscount(codeCoupon);
  }

  /**
   * @function find user selected ticket from applied coupon
   * @param ticket ID which we receive from entered coupon in response
   */
  findTicketByCoupon(coupon_ticket_id) {
    let ticket = null;
    Object.keys(this.choosedItems).forEach(cat => {
      Object.keys(this.choosedItems[cat]).forEach(id => {
        if (coupon_ticket_id.toString() == id) {
          ticket = this.choosedItems[cat][id];
          //console.log("got it", ticket, id, this.choosedItems[cat][id]);
        }
      })
    });

    return ticket
  }
  /**
   * @function find all user selected tickets
   *
   */
  findAllSelectedTickets() {
    const ticket_ids = [];
    Object.keys(this.choosedItems).forEach(cat => {
      Object.keys(this.choosedItems[cat]).forEach(id => {
        if (id !== 'name') {
          ticket_ids.push(+id);
        }
      })
    });

    return ticket_ids
  }

  /**
   * @function find all user selected tickets
   * return {ticket_id: amount}
   */
  findAllSelectedTicketsObj() {
    const ticket_ids = {};
    console.log(this.choosedItems);
    Object.keys(this.choosedItems).forEach(cat => {

      Object.keys(this.choosedItems[cat]).forEach(id => {
        // console.log('this.choosedItems[cat]',+id , ':', this.choosedItems[cat][id].amount)
        if (id !== 'name') {
          ticket_ids[+id] = this.choosedItems[cat][id].amount;
        }
      })
    });

    return ticket_ids
  }
  /**
   * @function Recalculate discounts from the coupon
   *
   */
  calcCouponDiscounts() {
    if (this.couponDiscount === undefined || this.couponDiscountType === undefined) return

    this.couponTicket = this.findTicketByCoupon(this.couponTicketId);

    if (this.couponDiscountType === 1) {
      //console.log("type1 id, ticket, discount", this.couponTicketId, this.couponTicket, this.couponDiscount);
      if (this.couponTicket) { // if checkDiscount ticket exist in user selected tickets
        //console.log(1);
        this.total_ticket_discount = (this.couponTicket.price) * (this.couponDiscount / 100);
      } else if (this.couponTicketId > 0 && !this.couponTicket) { // if checkDiscount ticket doesn't exist in user selected tickets
        //console.log(2);
        this.total_discount = 0;
        this.total_ticket_discount = 0;
      } else {
        //console.log(3);
        this.total_discount = this.total.total_price * (this.couponDiscount / 100);
      }
    } else {
      //console.log("type3, id, ticket, discount", this.couponTicketId, this.couponTicket, this.couponDiscount);
      if (this.couponTicket) { // if checkDiscount ticket exist in user selected tickets
        //console.log(1);
        if (this.couponDiscount > this.couponTicket.amount * this.couponTicket.price) { // if discount greater than tickets price
          this.total_ticket_discount = this.couponTicket.amount * this.couponTicket.price;
        } else {
          this.total_ticket_discount = this.couponDiscount;
        }
      } else if (this.couponTicketId > 0 && !this.couponTicket) { // if checkDiscount ticket doesn't exist in user selected tickets
        //console.log(2);
        this.total_discount = 0;
        this.total_ticket_discount = 0;
      } else {
        //console.log(3);
        this.total_discount = this.couponDiscount;
      }
    }

  }

  /**
   * @function Recalculate discounts from the discount
   * @param type -percents(1) or sum(3)
   */
  calcCouponDiscountsByDiscount() {
    if (this.discount === 0 || !this.discount_type) return

    let selected_tickets = this.findAllSelectedTicketsInfo();
    let total_discount_per_ticket = 0;

    if (this.discount_type === 1) {
      this.total_discount = this.total.total_price * (this.discount / 100);
      // console.log('calcCouponDiscountsByDiscount type 1 %', this.total_discount)
    } else {
      selected_tickets.forEach(t => {
        // if ticket price lower than Discount -> using t.price, else using just discount
        total_discount_per_ticket += ((t.price - this.discount) < 0) ? t.price : this.discount;
      })

      this.total_discount = total_discount_per_ticket;
      // console.log('calcCouponDiscountsByDiscount type 3 SUM', this.total_discount)
    }

  }

  /**
   * @function find all user selected tickets info
   *
   */
  findAllSelectedTicketsInfo() {
    const tickets = [];
    Object.keys(this.choosedItems).forEach(cat => {
      Object.keys(this.choosedItems[cat]).forEach(id => {
        // console.log(id, this.choosedItems[cat][id]);
        if (id !== 'name') {
          const ticket = this.tickets.find(t => t.id === +id);
          for (let i = 0; i < this.choosedItems[cat][id].amount; i++) {
            tickets.push(ticket);
          }
        }
      })
    });

    return tickets
  }


  /**
   * @function checking coupon with backend
   * @param couponCode code that user enter
   */
  checkCouponDiscount(couponCode: string) {
    // console.log('couponDiscount', couponCode);
    if(couponCode === 'undefined' || couponCode === ''){
      this.refreshCouponData();
      return;
    }

    //Removing whitespaces
    couponCode = couponCode.replace(/\s/g, '')
    this.globals.startLoader();

    //Checking if this is not empty
    if(couponCode != '' && couponCode.length >= 3){
      // Sending coupon validation to backend
      // const ticket_ids = this.findAllSelectedTickets();
      const ticket_ids = this.findAllSelectedTicketsObj();
      this.eventService.couponCheckout(this.event_details.event_id, couponCode, ticket_ids).subscribe(
        (response: any) => {

          if(response.success){
            // Showing the coupon is accepted
            this.discount = response.discount.discount;
            this.discount_type = response.discount.discount_type;
            this.couponName = response.coupon.name;
            this.couponTicketId = response.ticket;
            this.couponTicket = this.findTicketByCoupon(response.ticket);
            this.couponDiscount = response.coupon.discount;
            this.couponDiscountType = response.coupon.discount_type;
            setTimeout(()=>{
              this.couponName = '';
            },8000);
            this.globals.stopLoader();

            // Set the discount to sending variables
            this.used_discount_type = 1;
            this.used_discount_id = response.coupon.id;
            this.coupon_source = response.coupon.coupon_source;
            this.discount_search_key = couponCode;
            this.dialogService.alertsModal('success', 'coupon', 5000, true);

            this.discount ? this.calcCouponDiscountsByDiscount() : this.calcCouponDiscounts(); // if discount or if coupon

            this.calcTotalPrice(false);
          } else if (response.error){

            this.dialogService.alertsModal('landingPage', response.error, 5000, false);
            this.globals.stopLoader();
          } else {

            this.dialogService.alertsModal('errors', 2, 5000, false);
            this.globals.stopLoader();
          }
        },
        (error) => {
          this.globals.stopLoader();
          this.dialogService.alertsModal('errors', 2, 5000, false);
        }
      )
    } else {
      //Not proper coupon format
      this.globals.stopLoader();
      this.dialogService.alertsModal('landingPage', 5, 5000, false);
    }
  }

  /**
   * @function Creating total price and quantity object
   */
  calcTotalItemsAndPrice() {
    // console.log('calcTotalItemsAndPrice');
		var total_price = 0;
		var total_items = 0;

		Object.keys(this.choosedItems).forEach(cat => {
		  Object.keys(this.choosedItems[cat]).forEach(ticket => {
        if (ticket !== 'name') {
          total_price += this.choosedItems[cat][ticket].price * this.choosedItems[cat][ticket].amount;
          total_items += this.choosedItems[cat][ticket].amount;
        }
      })
    });

		return {'total_price' : total_price, 'total_items': total_items};
  }

  /**
   * @function Calculating the price and converting it to string
   */
  calcTotalPrice(buyWithCash: boolean){
    // console.log('calcTotalPrice');
    //Getting the commision in %
    this.total_platform_fee = this.calcPlatformCommission();
    const purchaser_commission = (this.purchaser_commission > 0) ? this.purchaser_commission / 100 : 0;
    const service_commission = (this.service_commission > 0) ? this.service_commission / 100 : 0;
    const tax_commission = (this.tax_commission > 0) ? this.tax_commission / 100 : 0;
    const commission = +(purchaser_commission + service_commission);

    this.discount ? this.calcCouponDiscountsByDiscount() : this.calcCouponDiscounts(); // if discount or if coupon
    // Price without commision
    if(this.hasDonateTicket) { // Do not calculate coupons discount for donation tickets
      this.total_price_n_commission = this.total.total_price.toFixed(2);
    } else {
      if(this.couponTicket) { // if activated coupon exist for selected Ticket, in the api/checkDiscount - ticket value
        this.total_price_n_commission = (this.total.total_price - this.total_ticket_discount).toFixed(2);
      } else {
        this.total_price_n_commission = (this.total.total_price - this.total_discount).toFixed(2);
      }
    }

    // console.log('this.total_price_n_commission', this.total_price_n_commission)
    if (commission && !buyWithCash && this.commission === 0) {
      // Calculate service_commission and purchaser_commission
      const total_price_w_purchaser_commission = (parseFloat(this.total_price_n_commission) * ((purchaser_commission) + 1)).toFixed(2);
      const total_price_w_service_commission = (parseFloat(this.total_price_n_commission) * ((service_commission) + 1)).toFixed(2);
      this.total_purchaser_commission = (parseFloat(total_price_w_purchaser_commission) - parseFloat(this.total_price_n_commission)).toFixed(2);
      this.total_service_commission = (parseFloat(total_price_w_service_commission) - parseFloat(this.total_price_n_commission)).toFixed(2);

      const total_fees_without_tax = (parseFloat( this.total_purchaser_commission) + parseFloat(this.total_service_commission));
      this.total_tax_commission = +(total_fees_without_tax * tax_commission).toFixed(3);
      this.tax_and_fees = (+this.total_price_n_commission * commission) + (+this.total_tax_commission) + this.total_platform_fee;
      // if (+this.total_price_n_commission == 0) {
      //   this.tax_and_fees = 0;
      // } else {
      //   this.tax_and_fees = (+this.total_price_n_commission * commission) + (+this.total_tax_commission) + this.total_platform_fee;
      // }
      // Commisssion in shekels
      this.commission_ils = (Math.round(this.tax_and_fees * 100)/100).toFixed(2); // round to 2 decimals after comma;

      // Price with commission
      this.total_price_w_commission = (parseFloat(this.total_price_n_commission) + parseFloat(this.commission_ils)).toFixed(2);

      if(this.hasDonateTicket && this.event_details.donation_fee_mode == 0) {
        this.total_price_w_commission = parseFloat(this.total_price_n_commission).toFixed(2);
      }
    } else if(commission == 0 && !buyWithCash && this.platform_fee) { // if % Purchase fee = 0 and Platform fee
      this.tax_and_fees = this.total_platform_fee;
      this.commission_ils = (Math.round(this.tax_and_fees * 100)/100).toFixed(2); // round to 2 decimals after comma
      // Price with commission
      this.total_price_w_commission = (parseFloat(this.total_price_n_commission) + parseFloat(this.commission_ils)).toFixed(2);
      this.total_purchaser_commission = '0.00';
      this.total_service_commission = '0.00';
      if(this.hasDonateTicket && this.event_details.donation_fee_mode == 0) {
        this.total_price_w_commission = parseFloat(this.total_price_n_commission).toFixed(2);
      }
    } else {

      //Commission in shekels
      this.commission_ils = '0';
      this.total_purchaser_commission = '0.00';
      this.total_service_commission = '0.00';
      this.tax_and_fees = 0;
      this.total_tax_commission = 0;
      this.total_platform_fee = 0;

      // Price with commission (No change here)
      this.total_price_w_commission = this.total_price_n_commission;

    }

    // If coupon applied with 100% discount
    if (parseFloat(this.total_price_n_commission) == 0) {
      this.total_price_n_commission = '0.00';
      this.total_price_w_commission = '0.00';
      this.total_purchaser_commission = '0.00';
      this.total_service_commission = '0.00';
      this.tax_and_fees = 0;
      this.total_tax_commission = 0;
      this.total_platform_fee = 0;
    }

    // prevent total price from being a negative number:
    if (parseFloat(this.total_price_n_commission) < 0) {
      this.total_price_n_commission = '0.00';
    }

    // prevent total price with tax from being a negative number:
    if (parseFloat(this.total_price_w_commission) < 0) {
      this.total_price_w_commission = '0.00';
    }

    if (this.total_price_w_commission === '0.00') {
      this.freeItem = true;
    } else {
      this.freeItem = false;
    }

    console.log('total_service_commission + total_platform_fee: ', this.total_service_commission,' + ', this.total_platform_fee);
  }


  /**
   * @function Calculating Platform Fee
   */
  calcPlatformCommission() {
    let selected_tickets = this.findAllSelectedTicketsInfo();
    let total_platform_fee = 0;
    let platform_fee = parseFloat(this.platform_fee);
    console.log('selected_tickets',platform_fee, selected_tickets );

    // Each ticket fee
    if (this.platform_fee_rule === 1) {
      const ticket_count = selected_tickets.filter(t => t.reg === 0).length;
      // Sum
      if (ticket_count && this.platform_fee_type == 1) {
        total_platform_fee = ticket_count * platform_fee;
      }
    }

    // All purchase fee
    if (this.platform_fee_rule === 2) {
      if (this.platform_fee_type == 1) {
        // Sum
        total_platform_fee = platform_fee;
      }
    }

    // console.log('total_platform_fee',total_platform_fee );
    return total_platform_fee
  }

  /**
   * @function managing the participant form data to new object that ower backend understand
   */
  manageParticipantData(){
    this.sub_purchaser = [];

    for(var i = 0; i < this.counter; i++){

    // (i === 0) Means it going to be info only for main purchaser

      let form = this.participantForm.controls.participants['controls'][i].controls;

      let idNumber;
      // console.log('manageParticipantData', form);
      if (form.identification_number) { idNumber = form.identification_number.value }
      else if (form.id_number) {
        idNumber = form.id_number.value
      } else {
        idNumber = '';
      }

      const index = form.full_name.value.indexOf(' ', 0);
      const first_name = index ? form.full_name.value.substring(0, index) : undefined;
      const last_name  = index ? form.full_name.value.substring(index+1) : undefined;
      const age = (!this.event_details.age_display || (form.age !== undefined && form.age.value === 0)) ? '' : form.age.value;

      let custom_purchaser = {
        full_name: (form.full_name !== undefined) ? form.full_name.value : (form.first_name !== undefined) ? form.first_name.value + ' ' + form.last_name.value ? form.last_name.value : '' : '',
        first_name: first_name,
        last_name: last_name,
        email: (form.user_email !== undefined) ? form.user_email.value : "",
        phone: (form.user_phone !== undefined) ? form.user_phone.value : "" ,
        phoneCountryCode: (form.phoneCountryCode !== undefined) ? form.phoneCountryCode.value : "",
        identification_number: idNumber,
        gender: (form.gender !== undefined) ? form.gender.value : "",
        birth_date: (form.birth_date !== undefined) ? form.birth_date.value : '',
        age: age,
        payment: (i === 0) ? this.total_price_w_commission : 0,
        item_id: form.item_id.value,
        facebook_id: (i === 0 && this.fb_module) ? this.facebook_id : '0',
        user_link: (i === 0 && this.facebook_link) ? this.facebook_link : "",
        mailing: (i === 0) ? this.mailingStatus : 0,
        producer_subscription: (i === 0 && this.showProducerSubscription) ? this.checkBoxProducerSubscription : false,
        custom_inputs: [],
        instagram_url: (form.instagram_url !== undefined) ? form.instagram_url.value : ''
      }

      // NEW custom_inputs
      this.new_custom_inputs.forEach(input => {
        if (form[input.id]) {
          const custom = {
            id: input.id,
            title: input.title,
            value: form[input.id].value,
          }
          custom_purchaser.custom_inputs.push(custom);
        }
      });

      if (this.showSeatsIo) {
        custom_purchaser['seat_id'] = (form.seat_id !== undefined) ? form.seat_id.value : '';
        if (i === 0) {
          custom_purchaser['seat_ids'] = (form.seat_ids !== undefined) ? form.seat_ids.value : '';
        }
      }

      if (i === 0) {
        this.purchaser = custom_purchaser;
      } else {
        this.sub_purchaser.push(custom_purchaser);
      }
    }
  }
  /**
   * @function Getin contact permission
   * @param checked boolean representory of mailing checkbox
   */
  checkedMailing(checked){
    this.mailingStatus = (checked) ? 1 : 0;
  }

  checkedProducerSubscription(checked){
    this.checkBoxProducerSubscription = checked ? true : false;
  }

  /**
   * @function Getin terms approvment
   * @param checked boolean representory of terms checkbox
   */
  checkedTerms(checked){
    this.terms = checked;
  }

  checkedCorona(checked){
    this.corona = checked;
  }

  @ViewChild('tokenForm') formElementRef: HTMLFormElement;
  @ViewChild('tokenInput') inputElementRef: ElementRef;

  onLoadIframe() {
    this.globals.stopLoader();
  }

  sendToken(boa_url, token) {
    this.iframeDisplay = 'block';
    this.inputElementRef.nativeElement.value = this.token;
    this.formElementRef.nativeElement.submit();
    this.globals.stopLoader();
  }

  /**
   * @function that checking all validation after collecting all the data and sending it to backend
   */
  toPayment(buyWithCash: boolean = false, gateway: any = null) {
    console.log(this.participantForm);
    if (gateway) {
      this.setGatewayType(gateway);
      gateway.payment_type === 'walletconnect' ?  this.isCloseWallet = false : false;
      gateway.payment_type === 'solana' ?  this.isCloseSolanaWallet = false : false;

      if(gateway.payment_typee === 'stripe_gateway') {
        window.Hyper = null;
        this.loadStripe();
      }
      if(gateway.payment_type === 'hyper_switch') {
        window.Stripe = null;
        this.loadHyper();

        if (this.hyper_errors !== undefined) {
          this.hyper_errors.nativeElement.innerText = '';
        }
      }
    }
    // console.log('toPayment');
    let endPoint = (!buyWithCash) ? 'purchase' : 'checkoutPurchase';
    this.globals.startLoader();

    //User submitted the form
    this.formSubmited = true;

    // Need to check terms as well
    if(this.participantForm.valid && this.terms && this.corona){
      this.addPixelEvent('Lead');
      this.addPixelEvent('AddPaymentInfo');

      //Calculating price
      this.calcTotalPrice(buyWithCash);

      //Manage participant data
      this.manageParticipantData();


      if (parseFloat(this.total_price_w_commission) === 0) {
        // The payment is 0 and the item is of type registration than REGISTER
        if (this.item_is_reg) {
          this.payment_method = 4;
          // The payment is 0 and the item is not of type registration than FREE
        } else {
          this.payment_method = 2
        }
      // Payment type CASH
      } else if (buyWithCash) {
        this.payment_method = 3;
      // The payment is more then 0 than CREDIT
      } else if (parseFloat(this.total_price_w_commission) > 0) {
        this.payment_method = 1;
      }

      const purchaseTickets = [];

      Object.keys(this.choosedItems).forEach((cat, i) => {
        Object.keys(this.choosedItems[cat]).forEach((ticket, index) => {
          if (ticket !== 'name') {
            if (!(index === 0 && this.choosedItems[cat][ticket].amount - 1 === 0 && i === 0)) {
              purchaseTickets.push({
                'item_id': +ticket,
                'amount': index === 0 ? i === 0 ? this.choosedItems[cat][ticket].amount - 1 : this.choosedItems[cat][ticket].amount : this.choosedItems[cat][ticket].amount
              })
            }
          }
        })
      });

      const data: AppPurchaseRequest = {
        event_id: this.event_id,
        amount: this.quantity,
        payment_gateway_id: this.gateway_id,
        purchaser: JSON.stringify(this.purchaser),
        sub_purchasers: JSON.stringify(this.sub_purchaser),
        total_price_n_commission: (this.total_price_n_commission) ? this.total_price_n_commission : '',
        total_price_w_commission: (this.total_price_w_commission) ? this.total_price_w_commission : '',
        currency: (this.currency) ? this.currency : '',
        salesman_id: (this.salesman_id) ? +this.salesman_id : 0,
        purchase_type: (this.purchase_type) ? this.purchase_type : 0,
        purchase_item: (this.purchase_item) ? this.purchase_item : 0,
        payment_method: this.payment_method,
        total_discount: this.total_discount,
        used_discount_type: (this.used_discount_type) ? this.used_discount_type : 0,
        used_discount_id: (this.used_discount_id) ? this.used_discount_id : 0,
        coupon_source: (this.coupon_source) ? this.coupon_source : 0,
        discount_search_key: (this.discount_search_key) ? this.discount_search_key : 0,
        client_details: {
          source: this.globals.detectBrowser()
        },
        seat_hash: this.seatsHash,
        donation_tickets: JSON.stringify(this.donateTickets)
      };

      if (this.purchase_type === 1) {
        data.group_tickets = (this.choosedItems) ? JSON.stringify(purchaseTickets) : '';
      }

      this.eventService[endPoint](data).subscribe(
        (response: any) => {
          let isFunction = true;
          if (typeof window.ga !== "function") {
            console.log('window.ga is not a function');
            isFunction = false;
          }

          // init GA eCommerce component:
          isFunction ? window.ga('require', 'ecommerce') : false;

          this.globals.stopLoader();


          if (response.payment_details) {
            this.purchase_id = response.payment_details.purchase_id;
            this.main_purchase_identifier = response.payment_details.main_purchase_identifier;
            if (isFunction) {
              // GA addTransaction:
              window.ga('ecommerce:addTransaction', {
                'id': response.payment_details.purchase_id,               		// purchase_id. Required.
                'revenue': response.payment_details.remaining_payment,       	// Grand Total.
                'currency': this.currency,               									    // local currency code.
                'affiliation': this.event_id
              });

              // GA addItem - needs to happen for each item in cart.
              window.ga('ecommerce:addItem', {
                'id': response.payment_details.id,       						          // payment_id. Required.
                'name': response.payment_details.item_details.item_name,    	// Product name. Required.
                'sku': response.payment_details.item_details.item_id,         // SKU/code.
                'category': response.payment_details.item_details.item_type,  // ticket / table / room
                'price': response.payment_details.item_details.item_price,  	// Unit price.
                'quantity': this.sub_purchaser.length + 1,    					      // Quantity.
                'currency': this.currency                 							      // local currency code.
              });

              window.ga('ecommerce:send');
            }
            if (response.payment_details.payment_method == 1) {

              // Remaining payment show up
              this.remaining_payment = response.payment_details.remaining_payment;
              this.commission_ils = response.payment_details.commission_ils;

              // Open tranzilla
              this.step = 'payment_step';
              // for test stripe gateway
              // response.payment_details.payment_gateway_type = 'stripe_gateway';
              // response.payment_details.token = 'pk_test_MtCjQhqaSXe9FCJiaEgG5XGw';

              if (response.payment_details.iframe != 'external api') {
                this.gateway_type = response.payment_details.payment_gateway_type;
                if (!response.payment_details.payment_gateway_type || response.payment_details.payment_gateway_type == 'tranzilla') {
                  // Update iframe:
                  this.tranzillaUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response.payment_details.iframe);
                  // Set timer for 30 minutes and then close it.
                  this.purchaseTimer = new Date(+new Date() + 1.8e6);
                } else if (response.payment_details.payment_gateway_type && response.payment_details.payment_gateway_type == 'bank_of_america') {
                  // Send token to BOA
                  this.token = response.payment_details.token;
                  this.sendToken(this.boa_url, this.token);
                  this.globals.startLoader();
                  this.purchaseTimer = new Date(+new Date() + 1.8e6);
                } else if (response.payment_details.payment_gateway_type && response.payment_details.payment_gateway_type == 'stripe_gateway') {
                  // Stripe form
                  this.payment_id = response.payment_details.id;
                  let stripeAccount = null;
                  if (response.payment_details.payment_gateway_data &&
                    response.payment_details.payment_gateway_data.connect_account &&
                    response.payment_details.payment_gateway_data.connect_account.id) {
                    stripeAccount = response.payment_details.payment_gateway_data.connect_account.id
                  }
                  this.createStripeElement(response.payment_details.token, response.payment_details, stripeAccount);
                  this.purchaseTimer = new Date(+new Date() + 1.8e6);
                } else if (response.payment_details.payment_gateway_type && response.payment_details.payment_gateway_type == 'coinbase') {
                  // Coinbase
                  if (response.payment_details.token) {
                    this.purchaseTimer = new Date(+new Date() + 1.8e6);
                    this.coinBaseData = JSON.parse(response.payment_details.token);
                    // console.log('response.payment_details.token', this.coinBaseData, this.gateway_type);
                    this.startCheckCryptoPayments();
                    this.checkCryptoPayments();
                    // this.createCriptoForm();
                  }
                } else if (response.payment_details.payment_gateway_type && response.payment_details.payment_gateway_type == 'walletconnect') {
                  this.purchaseTimer = new Date(+new Date() + 1.8e6);
                  this.walletConnectData = response;
                  this.walletConnectData['event_details'] = {};
                  this.walletConnectData['event_details']['eventId'] = this.event_details.event_id;
                  this.walletConnectData['event_details']['eventName'] = this.event_details.title;
                  this.walletConnectData['event_details']['userEmail'] = this.event_details.contact_email;
                  this.walletConnectData['event_details']['totalTickets'] = this.quantity;
                  // console.log('walletConnect Data', data);
                  // console.log('walletConnectData response:', response);
                  this.openWallets();
                } else if (response.payment_details.payment_gateway_type && response.payment_details.payment_gateway_type == 'solana') {
                  this.purchaseTimer = new Date(+new Date() + 1.8e6);
                  this.solanaConnectData = response;
                  this.solanaConnectData['event_details'] = {};
                  this.solanaConnectData['event_details']['eventId'] = this.event_details.event_id;
                  this.solanaConnectData['event_details']['eventName'] = this.event_details.title;
                  this.solanaConnectData['event_details']['userEmail'] = this.event_details.contact_email;
                  this.solanaConnectData['event_details']['totalTickets'] = this.quantity;
                  // console.log('solanaConnect Data', data);
                  // console.log('solanaConnectData response:', response);
                  this.openSolanaWallets();
                } else if (response.payment_details.payment_gateway_type && response.payment_details.payment_gateway_type == 'hyper_switch') {
                  console.log('createHyperElement');
                  const clientSecret = response.payment_details.payment_gateway_data.client_secret;
                  this.hyper_clientSecret = response.payment_details.payment_gateway_data.client_secret;
                  const publishable_key = response.payment_details.payment_gateway_data.published_key;
                  const payment_methods = response.payment_details.payment_gateway_data.payment_methods;
                  const lng = this.isProd ? `/${this.localeId}` : '';
                  this.hyper_return_url = `${this.getin_base_url}${lng}/hyperswitch-payment/${this.main_purchase_identifier}`;
                  this.createHyperElement(publishable_key, clientSecret);
                }


              } else {

                // External api logic
                this.externalApi = true;

              }

              // if transaction made with free/register/cash
            } else if(response.success == 1){
                if (isFunction) {
                  // GA addTransaction:
                  window.ga('ecommerce:addTransaction', {
                    'id': response.payment_details.purchase_id,               		// purchase_id. Required.
                    'revenue': response.payment_details.remaining_payment,       	// Grand Total.
                    'currency': this.currency,               									    // local currency code.
                    'affiliation': this.event_id
                  });

                  // GA addItem - needs to happen for each item in cart.
                  window.ga('ecommerce:addItem', {
                    'id': response.payment_details.id,       						          // payment_id. Required.
                    'name': response.payment_details.item_details.item_name,    	// Product name. Required.
                    'sku': response.payment_details.item_details.item_id,         // SKU/code.
                    'category': response.payment_details.item_details.item_type,  // ticket / table / room
                    'price': response.payment_details.item_details.item_price,  	// Unit price.
                    'quantity': this.sub_purchaser.length + 1,    					      // Quantity.
                    'currency': this.currency                 							      // local currency code.
                  });

                  window.ga('ecommerce:send');
                }
                //There no purchase validation 0 on success page so we setting 2 for all others
                var purchaseValidation = (this.purchase_validation === 1) ? 1 : 2;
                let main_purchase_identifier = response.payment_details.main_purchase_identifier;
                this.customParams['main_purchase_identifier'] = main_purchase_identifier;
                this.purchase_id = undefined;
                // this.showNftThanksModal(main_purchase_identifier);
                if(!this.eventPaymentMode){
                  // if success with reg tickets only
                  if(response.reg == 1) {
                    this.router.navigate(['/order-status/1/4', this.currency, purchaseValidation, 1, this.event_id, response.payment_details.producer_credit, (this.full_purchase) ? 0 : 1], {queryParams: this.customParams});
                  // if success with priced tickets and 100% discount
                  } else {
                    this.router.navigate(['/order-status/1/1',this.currency, purchaseValidation, 1, this.event_id, response.payment_details.producer_credit, (this.full_purchase) ? 0 : 1], {queryParams: this.customParams});
                  }
                } else {
                  // OLD variant - only for 1 pixel
                  // this.fb_pixel_model.setPixel(this._renderer2, this.marketing.facebook_pixel_id, true, this.currency, response.payment_details.producer_credit, ((this.marketing.fb_track_thanks) ? this.marketing.fb_track_thanks : undefined));
                  // this.addPixelEvent('Purchase');
                  const pixelAction = 'OrderConfimed';
                  if (this.marketing.facebook_pixels && this.marketing.facebook_pixels.length) {
                    this.event_pixel = null;
                    this.event_pixels = [];
                    this.marketing.facebook_pixels.forEach(val => {
                      this.event_pixel = +val.pixel_id;
                      this.event_pixels.includes(+val.pixel_id) ? false: this.event_pixels.push(+val.pixel_id);
                      // this.fb_pixel_model.setPixel(this._renderer2, val.pixel_id, true, this.currency, response.payment_details.producer_credit, pixelAction);
                      // console.log('Purchase Modal: ', val.pixel_id, val.type_name, pixelAction, response.payment_details.producer_credit);
                    });
                    this.fb_pixel_model.setPixel(this._renderer2, this.event_pixels, true, this.currency, response.payment_details.producer_credit, pixelAction);
                    // console.log('Purchase Modal: ', this.event_pixel, pixelAction, response.payment_details.producer_credit);
                  } else {
                    // add Pixel when order confimed
                    this.fb_pixel_model.setGetinPixel(this._renderer2, true, this.currency, response.payment_details.producer_credit, pixelAction);
                  }
                  this.dialogService.alertsModal('success','orderConfirmed',2000,true);
                  this.returnTo('items_section', 'init');
                  this.ngOnInit();
                }

            } else {

              this.dialogService.alertsModal('landingPage', 4.1, 5000, false);

            }

            // Checkout Timer Conditions
            let pgt = response.payment_details.payment_gateway_type
            if (
              pgt === 'stripe_gateway' ||
              pgt === 'tranzilla' ||
              pgt === 'hyper_switch'
            ) {
              let timer = this.checkout_timeout_counter;
              if (response.payment_details.checkout_timeout_counter) {
                timer = response.payment_details.checkout_timeout_counter*60;
              }
              // Sending seconds here and Start Checkout Timer
              this.checkoutTimer.startTimer(timer); // 5-15 min timer
            }

          } else {

            //If we have coupon error we getting it here
            if(response.error){
              this.dialogService.alertsModal('landingPage', response.error, 5000, false);
              this.closeModal();
            } else {
                this.dialogService.alertsModal('errors', 4, 5000, false);
            }
          }

        },
        (error) => {

          if(error.code){
            this.dialogService.alertsModal('errors', error.code, 5000, false, error.log);

            if(error.code === 24 || error.code === 25){
              if(!this.full_purchase && !this.eventPaymentMode){
                this.closePurchaseModalAndGetData.emit();
              }else{
                (async () => {
                  await this.getEventLp();
                  this.initChoosedItems();
                  this.initChoosedTickets();
                })();
                this.returnTo('items_section', 'init');
              }
            }
          } else {
            this.dialogService.alertsModal('errors', 4, 5000, false);
          }
          this.globals.stopLoader();
        }
      );
    } else {

      this.globals.stopLoader();

      //Terms not checked
      if (!this.terms) {
        this.dialogService.alertsModal('errors', 16, 5000, false);
        //Participant data valid
      } else if (!this.participantForm.valid) {
        this.dialogService.alertsModal('errors', 18, 3000, false);
      } else if (!this.corona) {
        this.dialogService.alertsModal('errors', 33, 5000, false);
      }
    }
  }

  seatsToPayment(buyWithCash: boolean = false, gateway = null) {
    // console.log('seatsToPayment', this.participantForm.valid, this.terms);
    //Terms not checked
    if (!this.terms) {
      this.dialogService.alertsModal('errors', 16, 5000, false);
      return
      //Participant data valid
    } else if (!this.participantForm.valid) {
      this.dialogService.alertsModal('errors', 18, 3000, false);
      return
    }
    this.globals.startLoader();
    this.eventSeatsBook().subscribe((res:any)=> {
      console.log(res);
      if (res.code = 'success') {
        this.showSeatsDisplay = false;
        this.seatsHash = res.data.hash;
        this.toPayment(buyWithCash, gateway);
      }
      this.globals.stopLoader();
    }, error => {
      console.log(error);
      this.globals.stopLoader();
    })
  }

  setGatewayType(gateway) {
    this.gateway_type = gateway.payment_type;
    this.gateway_id = gateway.id;
  }

  toggleCoinBase(index) {
    this.toggleCripto === index ? this.toggleCripto = null : this.toggleCripto = index;
    // this.toggleCripto=index;
    // console.log('toggleCoinBase', index + '_icon.png', this.toggleCripto, this.coinBaseData);
  }

  copyWalletData(key) {
    this.clipboardService.copyFromContent(key);
    this.clipboardService.destroy();
    this.dialogService.alertsModal('success', 'couponCopied', 2000, true);
    // console.log('copyWalletKey' , key);
  }

  checkCryptoPayments() {
    if (this.coinBaseData) {
      const data = {coinbase_id: this.coinBaseData.data.id}
      this.cryptoService.checkCryptoPayments(data).subscribe((response: any) => {
        // console.log('checkCryptoPayments', response, this.gateway_type, this.coinBaseData);
        if (response && response.code === 'success') {
          if (response.data) {
            this.isShowBackArrow = response.data.back;
            this.cryptoPaymentStatus = response.data.status;
            if (response.data.status === 0) {  // status: 0 - not transactions,1 - isset transaction, 2 - success, 3 - unresolved

            } else if (response.data.status === 1) {

            } else if (response.data.status === 2) {
              // window.parent.parent.location.href = this.getin_base_url + response.data.url;
              // console.log('navigate', response.data.url);
              this.cryptoService.setStatus.next(this.cryptoPaymentStatus);
              this.checkPaymentSubscription.unsubscribe();
              this.router.navigate([response.data.url]);
            } else if (response.data.status === 3) {
              this.cryptoPaymentOrderCode = response.data.order_code ? response.data.order_code : '';
              this.cryptoService.setStatus.next(this.cryptoPaymentStatus);
              this.checkPaymentSubscription.unsubscribe();
            }
          }
        }
      }, errors => {
        console.log('checkCryptoPayments has errors', errors);
      });
    } else {
      console.log('checkCryptoPayments not exist coinBaseData', this.gateway_type);
    }
  }

  startCheckCryptoPayments() {
    // console.log('startCheckCryptoPayments');
    //emit value every 5s
    const source = interval(10000);
    //when service emits after cryptoPaymentStatus, complete source
    // const timer$ = timer(5000);  // this.cryptoService.checkStatus
    const checkingPayments = source.pipe(takeUntil(this.cryptoService.checkStatus));
    const stopChecking = checkingPayments
    this.checkPaymentSubscription = checkingPayments.subscribe(val => {
      // console.log('checkStatus', val);
      this.checkCryptoPayments();
    });
  }

  checkCryptoPayment(coinbase_code, coinbase_id, coin_name, wallet_key, amount, coin_abr) {
    const data = {coinbase_code, coinbase_id, coin_name, wallet_key, amount, coin_abr};
    // console.log('checkCryptoPayment', data);
    this.globals.startLoader();
    this.cryptoService.checkCryptoPayment(data)
      .subscribe(
        (response: any) => {
          // console.log('send purchase response', response);
          // send user to successful page
          if (!response.data) {
            this.dialogService.alertsModal('cryptoCurrency', 'cryptoTransaction', 3000, false);
          } else {
            // this.dialogService.alertsModal('success', 'orderConfirmed', 2000, true);
            this.router.navigate([response.data.url]);
          }
          this.globals.stopLoader();
        },
        error => {
          this.dialogService.alertsModal('landingPage', error.code, 3000, false, (typeof error.log_id !== 'undefined') ? error.log_id : 0)
          this.globals.stopLoader();
        }
      );
  }

  payWithCoinbase() {
    // console.log('payWithCoinbase', this.coinBaseData.data.hosted_url);
    this.router.navigate([]).then(result => {  window.open(this.coinBaseData.data.hosted_url, '_blank'); });
  }



  // checkIfTicketsWereSoldOut() {
  //   return new Promise((resolve) => {
  //     this.showPurchaseBtn = false;
  //     this.eventService.getTicketsForPurchase(this.event).subscribe((eventData) => {
  //       this.tickets = eventData.tickets;
  //       this.categories = eventData.categories;

  //       this.isSelectedTicketOutOfStock = false;
  //       this.isSelectedTicketExpired = false;
  //       this.tickets.forEach((ticket) => {
  //         if (ticket.id in this.choosedItemsCheckIfSoldOut) {
  //           const now = moment();
  //           const end = moment(ticket.end_date, 'DD/MM/YYYY HH:mm');
  //           if (this.choosedItemsCheckIfSoldOut[ticket.id].amount > ticket.limits.length) {
  //             // console.log("SelectedTicket Quantity bigger than available limits, ticket ID:", ticket.id);
  //             this.isSelectedTicketOutOfStock = true;
  //           } else if (ticket.availability == 2) {
  //             // console.log("SelectedTicket OutOfStock, ticket ID:", ticket.id);
  //             this.isSelectedTicketOutOfStock = true;
  //             this.dialogService.alertsModal('errors', 24, 5000, false);
  //           } else if ( moment(now).isAfter(end) ) {
  //             // console.log("SelectedTicket Expired, ticket ID:", ticket.id);
  //             this.isSelectedTicketExpired = true;
  //             this.stepTemplate = 'templateNoValidTicketDate';
  //             return
  //           } else {

  //           }
  //         }
  //       });
  //       resolve(true); // resolving our promise
  //     });
  //   });
  // }

  openWallets() {
    // console.log('openWallets');
    this.isOpenWallets = true;
  }

  openSolanaWallets() {
    // console.log('openSolanaWallets');
    this.isOpenSolanaWallets = true;
  }

  walletConnectPay(value) {
    this.transactionBtnStatus = value;
    // console.log('wallenConnectPay', value);
  }

  walletSolanaPay(value) {
    this.solanaTransactionBtnStatus = value;
    // console.log('wallenConnectPay', value);
  }

  setTrnBtnStatus(responce) {
    this.trnBtnStatus = responce;
    // console.log('setTrnBtnStatus', responce, this.isCloseWallet);
  }

  setSolBtnStatus(responce) {
    this.trnSolBtnStatus = responce;
    // console.log('setSolBtnStatus', responce, this.isCloseSolanaWallet);
  }

  setTransactionWallet(response) {
    this.transactionBtnStatus = false;
    this.globals.stopLoader();
    // console.log('setTransactionWallet', response); // code: success, data: {success: true/false, url:/order_status........}
    if (response.data.url) {
      this.dialogService.alertsModal('success', 'walletConnectSuccess', 5000, true);
      // this.router.navigate([response.data.url]);
    } else {
      this.dialogService.alertsModal('success', 'walletConnectTransaction', 3000, true);
    }
  }

  setSolanaTransactionWallet(response) {
    // console.log('setSolanaTransactionWallet', response);
    this.solanaTransactionBtnStatus = false;
    this.globals.stopLoader();
    if (response.state === 'processed') {
      this.dialogService.alertsModal('success', 'walletConnectProcessing', 3000, true);
      console.log('Your order for event has been processed. It should be confirmed on the blockchain shortly.');
    } else if (response.state === 'confirmed') {
      // this.dialogService.alertsModal('success', 'walletConnectTransaction', 3000, true);
      console.log('We are waiting for a transaction. Please wait until the transaction is mined.');
    } else if (response.state === 'finalized') {
      this.dialogService.alertsModal('success', 'walletConnectSuccess', 3000, true);
    } else if (response.state === 'close') {
      this.dialogService.alertsModal('cryptoCurrency', 'walletConnectModalClosed', 3000, false);
    } else if (response.state === 'timerStopped') {
      this.dialogService.alertsModal('cryptoCurrency', 'walletTransactionHash', 3000, false);
    } else if (response.state === 'finish') {
      response.transaction.data.url ? this.router.navigate([response.transaction.data.url]) : false;
    }
  }

  extranalApi(){}

  /**
   * @function getting the event facebook people and checking with user facebook friends
   */



  /**
   * @function logging you to facebook and to app
   * @async this function
   * @description Logging you to facebook and to the application when you not connected and take to place you need to be
   */
  FBlogin(){
    const extraData = (this.router.url.match(/\?/)) ? '&togglePurchaseModal=true' : '?togglePurchaseModal=true';
    this.facebookService.getFBAccessCode(extraData);
  }

  /**
   * @function loggend to app
   * @param facebook_data data we taking from facebook login
   */

  createAvailableListCategories(config) {
    config.pricing.forEach(cat => {
      // console.log(cat);
      if (cat.ticket_id || cat.ticketTypes.length) {
        this.seatsCategoriesList.push(''+cat.category);
      }
    })
    console.log('categoriesList', this.seatsCategoriesList);
  }

  createSeatsConfig(event, workspaceKey, seatsUserConfig, region) {
    console.log('seatsUserConfig', seatsUserConfig);
    const pricing = this.prepareCorrectPricing(seatsUserConfig.pricing);
    const maxSelectedObjects = this.prepareMaxSelectedobjects(seatsUserConfig.pricing, this.tickets);
    const currencySymbol = getCurrencySymbol(this.currency,'narrow');

    this.seatsConfig = {
      region: region, // e.g. "eu"
      workspaceKey: workspaceKey,
      event: event,
      session: 'continue',
      colorScheme: 'dark',
      language: this.localeId,
      style: {
        font: 'Montserrat'
      },
      showFullScreenButton: false,
      showMinimap: false,
      pricing: pricing,
      priceFormatter: (price) => {
        return currencySymbol + price;
      },
      maxSelectedObjects: this.isAdmin?300:maxSelectedObjects, // temporary set 300 fro admins
      categoryFilter: {
        enabled: true,
        multiSelect: true,
        zoomOnSelect: false
      },
      onChartRendered: (chart) => {
        this.chartSeats = chart;
        console.info('Render Finished', 'chart: ', chart);
        this.chartSeatsRendered = true;
        this.globals.stopLoader();
        this.chartSeats.clearSelection();
        this.seatsQuantity = this.chartSeats.selectedObjects.length;
        this.showSelected(); // fetch listSelectedObjects
        this.calcSeatsTotalPrice(); // Refresh price
        this.showSeatsDisplay = true;
        this.seatsRendering = false;
      },
      onRenderStarted: (chart) => {
        console.info('Render Started');
        this.seatsRendering = true;
        // this.globals.startLoader();
      },
      onChartRenderingFailed: (chart) => {
        chart.destroy();
        this.seatsRendering = false;
        this.globals.stopLoader();
      },
      onObjectSelected:  (object) => {
        // add the selected seat id to the array


        console.log('onObjectSelected this.selectedSeats', this.selectedSeats);
        if (this.chartSeats !== undefined && this.chartSeatsRendered) {
          // this.selectedSeats.push(object.label);
          // console.log('object', object);
          //console.log('onObjectSelected', this.chartSeats);
          this.seatsQuantity = this.chartSeats.selectedObjects.length;
          console.log('onObjectSelected chartHoldToken', this.chartSeats.holdToken, this.chartSeats.selectedObjects);
          // if (this.my_tickets_seats !== undefined) {this.my_tickets_seats.close();}
          if (this.showSeatsDisplay) {
            this.calcSeatsTotalPrice();
          }

        }

      },
      onObjectDeselected:  (object) => {
        this.seatsQuantity = this.chartSeats.selectedObjects.length;
        if (this.showSeatsDisplay) {
          this.calcSeatsTotalPrice();
        }
      },
      onHoldTokenExpired: () => {
      // if (this.stepTemplate === 'Order') {
      //   console.log('Order HOLD')
      // }
      // if (this.stepTemplate === 'templateSeats') {
      //   console.log('templateSeats HOLD')
      // }
      // if (this.stepTemplate === 'templateDescription') {
      //   console.log('templateDescription HOLD')
      // }
      // console.log('onHoldTokenExpired', this.chartSeats.holdToken);
      this.seatsHoldToken = '';
      // console.log('onHoldTokenExpired', this.chartSeats.holdToken);
    },
      onSessionInitialized: (holdToken) => {
      this.seatsHoldToken = holdToken.token;
    }
    };

  }

  async showSelected() {
    if (this.chartSeats !== undefined) {
      this.listSelectedObjects = await this.getSeatsSelectedObjectsListByCat();
    }
  }

  // Prepare correct pricing config array (without ticket_id, etc.)
  prepareCorrectPricing(pricing) {
    // console.log(pricing);
    const correctPricing = pricing.filter((i) => {
      if (i.price && i.ticket_id && i.type === 'simple') {
        return i;
      }
      if (i.ticketTypes.length && i.type === 'multi') {
        return i;
      }
    }).map((i) => {
      const item = {};
      if (i.price && i.ticket_id && i.type === 'simple') {
        item['category'] = i.category;
        item['price'] = i.price;
        return item;
      }
      if (i.ticketTypes.length && i.type === 'multi') {
        item['category'] = i.category;
        item['ticketTypes'] = i.ticketTypes;
        return item;
      }
    });
    console.log('correctPricing', correctPricing);
    return correctPricing
  }


  // https://docs.seats.io/docs/renderer/config-maxselectedobjects
  prepareMaxSelectedobjects(pricing, tickets) {
    let obj = [];
    pricing.forEach(cat => {
      let currentTicket = tickets.find(t => cat.ticket_id == t.id);
      if (currentTicket) {
        obj.push({
          category: cat.category,
          quantity: currentTicket.limits.length
        })
      }
    })

    console.log('maxSelectedObjects', obj);
    return obj
  }

  // PRICE AND TOTAL CALCULATIONS
  async calcSeatsTotalPrice() {
    let selectedSeatsTickets  = [];
    if (this.chartSeats == undefined) {
      console.log('chartSeats doesnt init');
      this.quantity = 0;
      this.seatsTotal_n_comission = 0;
      return
    }
    selectedSeatsTickets = await this.chartSeats.listSelectedObjects();
    this.listSelectedObjects = await this.getSeatsSelectedObjectsListByCat(selectedSeatsTickets);
    // console.log('calcSeatsTotalPrice',this.listSelectedObjects, selectedSeatsTickets);
    let tickets = [];

    // generate Our Tickets Array from Seats selected objects
    console.log('selectedSeatsTickets', selectedSeatsTickets)
    selectedSeatsTickets.forEach( (t, i) => {
      // console.log('t.category.pricing', t.category.pricing);
      if (!t.category.pricing) {console.log('Please update config and price!')}
      if (t.hasOwnProperty('selectedTicketType')) {
        // console.log('HAS selectedTicketType', t)
        const found = t.category.pricing.ticketTypes.find(ticketType => ticketType.ticketType === t.selectedTicketType);
        console.log(found);
        tickets.push({
          price: found.price,
          ticketType: found.ticketType,
          category: t.category.key,
          categoryLabel: t.category.label,
          labels: t.labels,
          seatId: t.id,
          parent: t.parent
        })
      } else {
        // console.log('NO selectedTicketType', t)
        tickets.push({
          price: t.category.pricing.price,
          category: t.category.key,
          categoryLabel: t.category.label,
          labels: t.labels,
          seatId: t.id,
          parent: t.parent
        })
      }
    });
    // console.log('tickets1',tickets);
    // Adding Ticket_id
    tickets.forEach((t, i) => {
      // console.log('Adding Ticket_id', t, i, this.seatsUserConfig.pricing);
      //  tickets[i]['ticket_id'] = t.category
      if (t.hasOwnProperty('ticketType')) {
        const foundT = this.seatsUserConfig.pricing.find(ticket => ticket.category === t.categoryLabel);
        const foundTType = foundT.ticketTypes.find(type => type.ticketType === t.ticketType)
        console.log('foundT',foundT);
        console.log('foundTType',foundTType);
        let tType = this.seatsUserConfig.pricing.find(type => type.ticketType === t.ticketType);
        // console.log('foundT',tType);
        if (foundTType === undefined) {
          console.log('Invalid Seats config. Category without ticketTypes!')
          this.chartSeats.clearSelection();
          return
        }
        tickets[i]['ticket_id'] = foundTType.ticket_id;
      } else {
        const found = this.seatsUserConfig.pricing.find(ticket => ticket.category === t.categoryLabel);
        // console.log('found',found);
        tickets[i]['ticket_id'] = found.ticket_id;
      }
    })
    // console.log('tickets2',tickets, this.tickets);
    tickets.forEach((t, i) => {
      const foundTicket = this.tickets.find(ticket => ticket.id === t.ticket_id);
      // console.log('foundTicket',foundTicket);
      if (foundTicket === undefined) {
        this.globals.startLoader();
        // https://docs.seats.io/docs/renderer/chart-properties-chartdeselectobjects/
        this.chartSeats.deselectObjects([t.seatId], () => {
          console.log('deselection successful');
          this.globals.stopLoader();
          this.dialogService.alertsModal('seats', 1, 5000, false);
        });

        return;
      } else {
        tickets[i]['ticket'] = foundTicket;
      }

    })
    console.log('tickets3',tickets);
    // console.log('this.tickets 3',this.tickets);

    this.quantity = tickets.length;
    let total_price = 0;
    tickets.forEach((t, i) => {
      total_price = total_price + t.price;
    });
    this.seatsTotal_n_comission = total_price;
    this.seatsTickets = tickets;
    this.checkInvalidTicketsForSeats(this.seatsTickets);
    this.total = { 'total_price': this.seatsTotal_n_comission, 'total_items': this.quantity };

    this.calcTotalPrice(false);

  }

  // Calculate if more than ticket.limits selected by user or check Availability
  checkInvalidTicketsForSeats(tickets) {
    let choseItems = {};
    let isInvalidTicketsCount = false;
    if (!tickets.length) {return;}
    tickets.forEach((t,i)=> {
      if (t.ticket === undefined) {return;}
      choseItems[t.ticket_id] = {}
      const count = tickets.reduce((acc, cur) => cur.ticket_id === t.ticket_id ? ++acc : acc, 0);
      // console.log(count, t);
      choseItems[t.ticket_id]['amount'] = count;
      // MAYBE NEED to DELETE THIS WARNING, because we have maxSelectedObjects in seats config
      if (count > (this.isAdmin?300:t.ticket.limits.length)) { // temporary set 300 fro admins
        // this.seatsTickets[i]['limitExceed'] = true;
        // this.seatsTickets[i]['limitExceedCount'] = count;
        isInvalidTicketsCount = true;
        this.dialogService.alertsModal('seats', 2, 5000, false);
        // console.log('More than LIMITs selected', t.seatId, 'count', count, 'ticket_id',  t.ticket_id, 'i', i, this.seatsTickets);
        //   return;
      } else {
        // this.seatsTickets[i]['limitExceed'] = false;
        // this.seatsTickets[i]['limitExceedCount'] = 0;
      }

    });

    // FOR CHECKOUT BTN
    const ifInvalidTicket = this.seatsTickets.some(t => (
      // t.limitExceed === true ||
      t.ticket.availability === 3 ||
      t.ticket.availability === 4));
    (ifInvalidTicket || isInvalidTicketsCount) ? this.seatsTicketsHaveInvalidItem = true : this.seatsTicketsHaveInvalidItem = false;

    // this.choosedItemsCheckIfSoldOut = choseItems;
    // console.log('this.choosedItemsCheckIfSoldOut', this.choosedItemsCheckIfSoldOut)
  }

  // Fetch Seats Selected Tickets
  async getSeatsSelectedObjectsListByCat(objects = null) {
    let listSelected;
    if (objects) {
      listSelected = objects;
    } else {
      listSelected = await this.chartSeats.listSelectedObjects();
    }
    const groupArrayObject = listSelected.reduce((group, arr) => {
      // console.log(group, arr, arr.category.label);
      group[arr.category.label] = group[arr.category.label] ?? [];
      group[arr.category.label].push(arr);
      return group;
    },{});
    // console.log(groupArrayObject);
    return groupArrayObject
  }

  // GENERATE SEATS ATTENDEES FORM CONTROLS
  addSeatsTickets() {
    // Remove all formArray controls
    const control = <UntypedFormArray>this.participantForm.controls['participants'];
    // console.log('participantForm 1', this.participantForm, control.length);
    for(let i = control.length-1; i >= 0; i--) {
      control.removeAt(i)
    }

    this.counter = 0;
    this.choosedItems['noCat'] = {};
    const ticket = {};
    ticket['amount'] = 0;
    let amount = {};
    let groupLabels = [];

    this.seatsTickets.forEach((t, i) => {
      // console.log('seatsTickets', t);
      if (amount.hasOwnProperty(t.ticket.id)) {
        amount[t.ticket.id]++
      } else {
        amount[t.ticket.id] = 1;
      }
      // ticket['price'] = +t.ticket.price;
      // ticket['name'] = t.ticket.name;
      this.choosedItems['noCat'][t.ticket.id] = {
        amount: amount[t.ticket.id],
        price: t.ticket.price,
        name: t.ticket.name,
      };

      let labels = {...t.labels, ...t.parent}; // add parent.type (row or table)
      groupLabels.push(labels)// group purchase labels on tickets view
    });
    console.log('choosedItems', this.choosedItems['noCat']);
    console.log('this.seatsTickets', this.seatsTickets);
    /* Creation of form controlls */
    let setedFirstElement = false;
    let seat_ids = [];
    this.seatsTickets.forEach((t, i) => {
      seat_ids.push(t.seatId);
      let labels = this.purchase_type === 1 ? groupLabels : {...t.labels, ...t.parent};
      if (this.purchase_type !== 1 || (this.purchase_type === 1 && !setedFirstElement)) {
        // console.log('CREATE', t);
        (<UntypedFormArray>this.participantForm.controls['participants']).push(this.createControls(t.ticket.id, null, labels, t.seatId, seat_ids));
        setedFirstElement = true;
      }
    });
    // console.log('participantForm 2', this.participantForm, control.length);
    // this.seatsTickets.forEach((t, i) => {
    //   this.editTicket(t.ticket, 'plus', t.ticket.category_id.length ? t.ticket.category_id : null, 1);
    // });

    this.total = { 'total_price': this.seatsTotal_n_comission, 'total_items': this.seatsQuantity };
    this.calcTotalPrice(false);

    this.step = 'participant_details';
  }

  eventSeatsBook() {
    return this.eventService.eventSeatsBook(this.event_id, this.chartSeats.selectedObjects, this.chartSeats.holdToken)
  }

  async bestSeats(number) {
    if(!this.seatsHoldToken.length) {
      console.log('Please start session');
      return
    }
    if (!this.chartSeats.holdToken) {
      console.log('No active chart!');
      return
    }
    const listCategories = await this.chartSeats.listCategories();
    let categories = [];
    listCategories.forEach(cat => {
      if (cat.pricing) {
        categories.push(''+cat.key);
      }
    })
    this.globals.startLoader();
    this.eventService.eventSeatsBestAvailable(this.event_id, number, categories, this.seatsHoldToken).subscribe(res => {
        console.log(res);
        this.globals.stopLoader();
      },
      error => {
        console.log(error);
        this.globals.stopLoader();
      })
  }

  tooltipTxt(ticket) {
    // console.log(ticket)
    const txt = {
      '1': {
        en: `Selected ticket sale start on ${ticket.ticket.start_date}.`,
        he: ` הכרטיס זמין לרכישה החל מתאריך ${ticket.ticket.start_date}`,
        es: `La venta de entradas seleccionadas comienza el ${ticket.ticket.start_date}.`
      },
      '2': {
        en: 'Selected ticket sales are closed.',
        he: 'הכרטיס אינו זמין לרכישה',
        es: 'La venta de entradas seleccionadas está cerrada.',
      },
    }
    if (ticket.ticket.availability == 3) {
      return txt[1][this.localeId]
    }
    if (ticket.ticket.availability == 4) {
      return txt[2][this.localeId]
    }
    // if (ticket.limitExceed) {
    //   return `You may select only ${ticket.ticket.limits.length} tickets of this type.`
    // }

    return ''
  }

  setSeatsObjects() {
    // console.log('this.seatsSelectedObjects b', this.seatsSelectedObjects)
    this.seatsSelectedObjects = JSON.stringify(this.chartSeats.selectedObjects);
    // console.log('this.seatsSelectedObjects A', this.seatsSelectedObjects)
  }

  async deselectObjects(id) {
    await this.chartSeats.deselectObjects([id]);
  }

  getLocale(currency) {
    return this.currencyLocale.getLocaleForCurrencyPipe(currency)
  }

  isShowProducerSubscription() {
    const isFollowing = this.producerDetails.followers.indexOf(this.user_id);
    return (isFollowing == -1) && this.event_details.producer_subscription && !this.userSameAsProducer
  }

  getProducerData() {
    this.authService.getProducerDetails(this.producer_id).subscribe(
      response => {
        // if (Object.keys(response).length === 0) {
        //   this.router.navigate(['/']);
        // }
        // if (this.producer_id.toString() === this.user_id.toString() || this.isAdmin) {
        //   this.showEventEdit = true;
        // } else {
        //   this.showEventEdit = false;
        // }
        if (this.producer_id.toString() === this.user_id.toString()) {
          this.userSameAsProducer = true;
        } else {
          this.userSameAsProducer = false;
        }
        // this.isShowBtn = true;
        this.producerDetails = response;
        // this.producerImage = this.nav.getProfileImageLink(this.producerDetails.social_img).imageLink;
        // this.producerName = this.producerDetails.producer_name;
        // this.producerImage = this.nav.getProfileImageLink(this.producerDetails.profile_img, this.producerDetails.facebook_id).imageLink;
        // this.producerCoverPhoto = this.producerDetails.background_image_url ? this.producerDetails.background_image_url : this.producerCoverPhotoDefault;
        // console.log('producerImage', this.producerImage);
        // if (this.producerDetails) {
        //   this.producerDetails['event_title'] = this.event_details.title;
        //   this.producerDetails['event_id'] =  this.event_details.event_id;
        //   this.producerDetails['producer_cover'] = this.coverPreview ? this.coverPreview.changingThisBreaksApplicationSecurity : '/assets/images/svg-icons/logo-new.svg';
        //   // console.log('send producer details', this.event_details, this.coverPreview, this.producerDetails);
        // }
        this.showProducerSubscription = this.isShowProducerSubscription();
      }
    );
  }

  showNftThanksModal(main_purchase_identifier) {
    this.facebookService.getPixelParams({main_purchase_identifier: main_purchase_identifier}).subscribe((params: any) => {
      if (params && params.code == 'success') {
        this.nftThankYouDialog.openThankYouPage(params);
      }
    }, error => {

    });
  }

  // Prevent entered 0 zero values
  sendTheNewValue(event, cat = null, i){
    let value = +event.target.value;
    event.target.value = value === 0 ? '' : value;
    if(cat) { this.ticketsFiltered[cat][i].price = value; } else { this.withoutCategory[i].price = value; }
  }

  donateChange(e, t, cat = null) {
    let value = +e.target.value;

    let index = this.donateTickets.findIndex(i=> {
      return i.item_id == t.id
    })

    if(index === -1) {
      this.donateTickets.push({item_id: t.id, sum: value});
      this.choose(t,'plus',cat,1);
    } else {
      this.choose(t,'minus',cat,1);
      this.donateTickets[index]['item_id'] = t.id;
      this.donateTickets[index]['sum'] = value;
      this.choose(t,'plus',cat,1);
    }

    if((value < t.donation_min || value > t.donation_max) && (index || index===0)) {
      this.donateTickets.splice(index, 1);
      this.choose(t,'minus',cat,1);
    }
    // console.log(this.donateTickets, index)
    // console.log('this.hasDonateTicket', this.hasDonateTicket)
  }

  calcRegular() {
    let regQty = 0;
    let donQty = 0;
    Object.keys(this.choosedItems).forEach(cat => {
      Object.keys(this.choosedItems[cat]).forEach(id => {
        if (id !== 'name') {
          let findTicket = this.tickets.filter(ticket => ticket.id === +id)
          if (findTicket[0].reg === 0 && findTicket[0].donation_type == 0) {
            regQty = regQty + this.choosedItems[cat][id].amount
          }
          if (findTicket[0].reg === 0 && findTicket[0].donation_type == 1) {
            donQty = donQty + this.choosedItems[cat][id].amount
          }
        }
      })
    });
    this.hasRegularTicket = regQty;
    this.hasDonateTicket = donQty;
    // console.log('regQty', regQty, 'donQty', donQty)
  }

  clearDonate(t, cat = null) {
    this.choose(t,'minus',cat,1);
  }

  clearDonationTicketsPrice() {

    // Clear without categories
    this.withoutCategory = this.withoutCategory.map(item => {
      if (item.donation_type) {
        let ticket = item;
        ticket.price = 0;
        return ticket
      }
      return item
    });

    // Clear with categories
    this.categories.forEach((category, index) => {
      let filtered = this.tickets.filter(ticket => ticket.category_id.indexOf(category.id) > -1 );
      if(filtered && filtered.length) {
        filtered = filtered.map(t => {
          if (t.donation_type) {
            let ticket = t;
            ticket.price = 0;
            return ticket
          } else {
            return t
          }
        })
      }
      this.ticketsFiltered[category.id] = filtered;
    });

    this.donateTickets = [];
    this.hasDonateTicket = 0;
    this.hasRegularTicket = 0;
  }

  getCurrencySymbolF(code) {
    return getCurrencySymbol(code, 'narrow')
  }

}
