import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuardService} from './route-guards/auth-guard.service';
import {AuthAdminGuardService} from './route-guards/auth-admin-guard.service';


const routes: Routes = [
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then(m => m.AboutModule)
  },
  {
    path: 'account-settings',
    loadChildren: () => import('./account-setings/account-settings.module').then(m => m.AccountSettingsModule),
    canActivate: [AuthGuardService],
    pathMatch: 'full'
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule),
    pathMatch: 'full'
  },
  {
    path: 'event-salesmen/:event_id',
    loadChildren: () => import('./dashboard-sub-pages/event-salesmen/event-salesmen.module').then(m => m.EventSalesmenModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'copy-event-salesmans/:event_id/:copy_to_event',
    loadChildren: () => import('./dashboard-sub-pages/event-salesmen/event-salesmen.module').then(m => m.EventSalesmenModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'event-participants/:event_id/:filter',
    loadChildren: () => import('./dashboard-sub-pages/event-participants/event-participants.module').then(m => m.EventParticipantsModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'event-participants/:event_id/:salesman_id/:filter',
    loadChildren: () => import('./dashboard-sub-pages/event-participants/event-participants.module').then(m => m.EventParticipantsModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'send-tickets/:event_id',
    loadChildren: () => import('./dashboard-sub-pages/send-tickets/send-tickets.module').then(m => m.SendTicketsModule),
    canActivate: [AuthAdminGuardService]
  },
  {
    path: 'my-tickets',
    loadChildren: () => import('./dashboard-sub-pages/my-tickets/my-tickets.module').then(m => m.MyTicketsModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'personal-info',
    loadChildren: () => import('./personal-info/personal-info.module').then(m => m.PersonalInfoModule),
    canActivate: [AuthGuardService],
    pathMatch: 'full'
  },
  { path: 'producer-edit',
    loadChildren: () => import('./producer-feed/producer-edit/producer-edit.module').then(m => m.ProducerEditModule),
    canActivate: [AuthGuardService],
    pathMatch: 'full'
  },
  {
    path: 'producer-edit/:id',
    loadChildren: () => import('./producer-feed/producer-edit/producer-edit.module').then(m => m.ProducerEditModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'cancellations',
    loadChildren: () => import('./terms/cancellation-policy/cancellation-policy.module').then(m => m.CancellationPolicyModule),
    pathMatch: 'full'
  },
  {
    path: 'stripe-connect/payouts',
    loadChildren: () => import('./dashboard-sub-pages/stripe-connect-embedded/stripe-connect-embedded.module').then(m => m.StripeConnectEmbeddedModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'cms/users-actions',
    loadChildren: () => import('./cms/cms-users-actions/cms-users-actions.module').then(m => m.CmsUsersActionsModule),
    canActivate: [AuthAdminGuardService]
  },
  {
    path: 'cms/gateways-list',
    loadChildren: () => import('./gateways/gateways-list/gateways-list.module').then(m => m.GatewaysListModule),
    canActivate: [AuthAdminGuardService]
  },
  {
    path: 'cms/seats-config/:event_id',
    loadChildren: () => import('./cms/cms-seats-config/cms-seats-config.module').then(m => m.CmsSeatsConfigModule),
    canActivate: [AuthAdminGuardService]
  },
  {
    path: 'cms/phone-black-list',
    loadChildren: () => import('./cms/cms-phone-black-list/cms-phone-black-list.module').then(m => m.CmsPhoneBlackListModule),
    canActivate: [AuthAdminGuardService]
  },
  {
    path: 'cms/phone-country-white-list',
    loadChildren: () => import('./cms/cms-phone-white-list/cms-phone-white-list.module').then(m => m.CmsPhoneWhiteListModule),
    canActivate: [AuthAdminGuardService]
  },
  {
    path: 'cms/email-black-list',
    loadChildren: () => import('./cms/cms-email-black-list/cms-email-black-list.module').then(m => m.CmsEmailBlackListModule),
    canActivate: [AuthAdminGuardService]
  },
  {
    path: 'cms/cms-user-suspended-list',
    loadChildren: () => import('./cms/cms-user-suspended-list/cms-user-suspended-list.module').then(m => m.CmsUserSuspendedListModule),
    canActivate: [AuthAdminGuardService]
  },
  {
    path: 'cms/sms-providers-list',
    loadChildren: () => import('./cms/sms-providers/sms-providers-list/sms-providers-list.module').then(m => m.SmsProvidersListModule),
    canActivate: [AuthAdminGuardService]
  },
  {
    path: 'cms/fee-management-templates',
    loadChildren: () => import('./cms/cms-fee-management-template/cms-fee-management-template.module').then(m => m.CmsFeeManagementTemplateModule),
    canActivate: [AuthAdminGuardService]
  },
  {
    path: 'cms/fee-management-templates/producers/:template_id',
    loadChildren: () => import('./cms/cms-fee-management-template/cms-producers-list/cms-producers-list.module').then(m => m.CmsProducersListModule),
    canActivate: [AuthAdminGuardService]
  },
  {
    path: 'cms/fee-management-templates/events/:template_id',
    loadChildren: () => import('./cms/cms-fee-management-template/cms-events-list/cms-events-list.module').then(m => m.CmsEventsListModule),
    canActivate: [AuthAdminGuardService]
  },
  {
    path: 'cms/fee-management-templates/events-history/:template_id',
    loadChildren: () => import('./cms/cms-fee-management-template/cms-events-history-template-list/cms-events-history-template-list.module').then(m => m.CmsEventsHistoryTemplateListModule),
    canActivate: [AuthAdminGuardService]
  },
  {
    path: 'cms/redis-dashboard',
    loadChildren: () => import('./cms/cms-redis-dashboard/cms-redis-dashboard.module').then(m => m.CmsRedisDashboardModule),
    canActivate: [AuthAdminGuardService]
  },
  {
    path: 'cms/redis-dashboard/type/:type',
    loadChildren: () => import('./cms/cms-redis-dashboard/cms-redis-type/cms-redis-type.module').then(m => m.CmsRedisTypeModule),
    canActivate: [AuthAdminGuardService]
  },
  {
    path: 'cms/currencies',
    loadChildren: () => import('./cms/cms-currencies/cms-currencies.module').then(m => m.CmsCurrenciesModule),
    canActivate: [AuthAdminGuardService]
  },
  {
    path: 'cms/currencies/gateways/:currency_id',
    loadChildren: () => import('./cms/cms-currencies/cms-currency-gateway/cms-currency-gateway.module').then(m => m.CmsCurrencyGatewayModule),
    canActivate: [AuthAdminGuardService]
  },
  {
    path: 'cms/gateways-list/currencies/:payment_gateway_id',
    loadChildren: () => import('./cms/cms-currencies/cms-gateway-currency/cms-gateway-currency.module').then(m => m.CmsGatewayCurrencyModule),
    canActivate: [AuthAdminGuardService]
  },
  {
    path: 'cms/stripe-payouts/:event_id',
    loadChildren: () => import('./cms/cms-stripe-payouts/cms-stripe-payouts.module').then(m => m.CmsStripePayoutsModule),
    canActivate: [AuthAdminGuardService]
  },
  {
    path: 'cms/stripe-payouts/:event_id/charges/:id',
    loadChildren: () => import('./cms/cms-stripe-payouts/cms-stripe-charges/cms-stripe-charges.module').then(m => m.CmsStripeChargesModule),
    canActivate: [AuthAdminGuardService]
  },


  {
    path: 'main-slider',
    loadChildren: () => import('./manage-main-slider/manage-main-slider.module').then(m => m.ManageMainSliderModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'main-slider/detail',
    loadChildren: () => import('./manage-main-slider/slide-detail/slide-detail.module').then(m => m.SlideDetailModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'cms/auto-approve-producer-list',
    loadChildren: () => import('./cms/cms-auto-approve-producer-list/cms-auto-approve-producer-list.module').then(m => m.CmsAutoApproveProducerListModule),
    canActivate: [AuthAdminGuardService]
  },
  {
    path: 'cms/discounts/:event_id',
    loadChildren: () => import('./cms/cms-discounts/cms-discounts.module').then(m => m.CmsDiscountsModule),
    canActivate: [AuthAdminGuardService]
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
