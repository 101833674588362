import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EventService} from '../../services/event.service';
import {DialogService} from '../../services/dialog.service';

@Component({
  selector: 'app-hyperswitch-payment',
  templateUrl: './hyperswitch-payment.component.html'
})
export class HyperswitchPaymentComponent implements OnInit {
  main_purchase_identifier: string = '';

  constructor(private activatedRoute: ActivatedRoute,
              private eventService: EventService,
              private router: Router,
              private dialogService: DialogService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {

      this.main_purchase_identifier = params['main_purchase_identifier'];
      const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');
      const status = new URLSearchParams(window.location.search).get('status');

      console.log('Get main_purchase_identifier', this.main_purchase_identifier, params);
      console.log('Get clientSecret', clientSecret);
      console.log('Get status', status);

      if (!clientSecret) {
        return;
      }

      const data = {
        main_purchase_identifier: this.main_purchase_identifier,
        payment_intent_client_secret: clientSecret
      }

      this.eventService.hyperswitchResponse(data).subscribe((res: any) => {
        console.log('hyperswitchResponse', res);
        if (res.data && res.data.url) {
          this.router.navigateByUrl(res.data.url);
        }
      }, error => {
        console.log('hyperswitcheError', error);
        this.dialogService.alertsModal('errors', error.code, 5000, false, error.log_id);
        this.router.navigate(['/dashboard']);
      });

    });
  }

}
