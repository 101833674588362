import {Component, OnInit} from '@angular/core';
import { TranslateService } from '@tolgee/ngx';
import {GlobalsService} from '../../globals';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.css']
})
export class LanguageSelectComponent implements OnInit {
  urlPath: string;
  currentRoute = '';
  langsArray = [];
  localeId: string;
  dir: string;
  languages: any | null;

  constructor(public globals: GlobalsService, public translateService: TranslateService) {
    this.languages = this.globals.getCountryData();
    this.localeId = this.globals.getLocaleId();
    this.dir = this.globals.getDir();
    this.urlPath = window.location.pathname;
  }

  ngOnInit(): void {

    const allLangs = this.globals.getCountryData();
    // console.log('locale', this.localeId, this.urlPath)
    this.langsArray = [];
    this.langsArray.push('/');
    for (const flagObj of allLangs) {
      this.langsArray.push(flagObj.lang);
    }
    this.translateService.changeLanguage(this.localeId).then(()=> {

    })
  }

  changeLanguage(e) {
    const lang = `${e.value}`;
   // const lang = e;
   //  console.log(lang, this.localeId)
   //  console.log('this.localeId !== lang', this.localeId !== lang);
    this.globals.changeLang(lang);
    this.setCurrentRoute();
    console.log('changeLanguage lang', lang);
    
    this.translateService.changeLanguage(lang).then(()=> {
      window.location.replace('/' + lang + this.currentRoute);
    })
    
    // if (this.localeId !== lang) {
    //   this.globals.changeLang(lang);
    //   // this.router.navigate(['/'+ lang + this.currentRoute]);
    //   window.location.replace('/' + lang + this.currentRoute);
    // }
    // console.log('changeLanguage', '/'+this.localeId, '/'+lang, this.currentRoute);
  }

  setCurrentRoute () {
    const urlQueryParams = window.location.search;
    const pathname = window.location.pathname;
    const urlLang = pathname.substr(pathname.length - 1) === '/' ? pathname.substring(1, pathname.length - 1) : pathname.substr(1, 2);
    const isUrlLang = this.langsArray.indexOf(urlLang) !== -1;
    let urlPath =  isUrlLang ? pathname.substr(3) : pathname;
    if (urlPath.charAt(0) === '/') {
      urlPath = urlPath.substr(1);
    }
    this.currentRoute = '/' + urlPath + urlQueryParams;
    console.log('urlQueryParams', urlQueryParams, 'pathname', pathname, 'urlLang', urlLang, 'isUrlLang', isUrlLang, 'urlPath',urlPath, 'pathname.substr(3)', pathname.substr(3));
    // console.log('urlPath', urlLang, isUrlLang, urlPath, urlQueryParams, pathname.substr(3));
    console.log('currentRoute', this.currentRoute, this.languages, this.langsArray);
  }
}
