import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-cat-name',
  templateUrl: './cat-name.component.html',
  styleUrls: ['./cat-name.component.css']
})
export class CatNameComponent implements OnInit {
  @Input() name = '';
  isCatPage: boolean = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
     if (this.router.url.indexOf('/categories') !== -1) {
       this.isCatPage = true;
    }
  }

}
