import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogService} from '../../../services/dialog.service';
import {EventService} from '../../../services/event.service';
import {PlaidService} from '../../../services/plaid.service';
declare var window: any;
declare var Plaid;

@Component({
  selector: 'app-plaid-redirrect',
  templateUrl: './plaid-redirrect.component.html',
  styleUrls: ['./plaid-redirrect.component.css']
})
// 'plaid/logic/redirect'
export class PlaidRedirrectComponent implements OnInit {


  user_plaid_id: string = '';

  constructor(private activatedRoute: ActivatedRoute,
              private eventService: EventService,
              private dialogService: DialogService,
              private plaidService: PlaidService,
              private router: Router) { }

  async ngOnInit() {
    this.activatedRoute.params.subscribe(async (params) => {

      this.user_plaid_id = localStorage.getItem('user_plaid_id')
      console.log('PlaidRedirrectComponent user_plaid_id', this.user_plaid_id)
      const tokenRes = await this.eventService.plaidTokensLinkRedirrect(this.user_plaid_id);
      tokenRes.subscribe(async( res: any) => {
        if(res && res.code == 'success' && res.data.token) {
          localStorage.setItem('link_token', JSON.stringify(res.data.token));
          localStorage.setItem('user_plaid_id', JSON.stringify(res.data.user_plaid_id));
          const handler = await this.plaidService.create(res.data.token, null, true);
          await handler.open();
        }
      })
      // if (!this.token) {console.log('No token');return;}
      // await this.plaidService.create(this.token, window.location.href, true);
      // const handler = await this.plaidService.create(this.token, null, true);
      // await handler.open();

    });
  }

}
