import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {MatDrawer, MatSidenav} from '@angular/material/sidenav';
import {IntercomService} from "./intercom.service";

@Injectable()
export class NavbarService {

  visible = true;
  customClass: string;
  customWrapperClass: string;
  masterContainerWidth: string;
  noPadding: string = "removePadding";
  prevUrl: string;

  public user_id = new BehaviorSubject(0);
  currentUserId = this.user_id.asObservable();
  private userType = new BehaviorSubject(0);
  currentUserType = this.userType.asObservable();
  private facebook_id = new BehaviorSubject(0);
  currentFacebookId = this.facebook_id.asObservable();

  private landingpageLangs = new BehaviorSubject({});
  currentLandingpageLangs = this.landingpageLangs.asObservable();
  private purchaseModalStatus = new BehaviorSubject('closed');
  currentPurchaseModalStatus = this.purchaseModalStatus.asObservable();
  private changedRouteUrl = new BehaviorSubject('');
  currentChangedRouteUrl = this.changedRouteUrl.asObservable();
  private userProfileImage = new BehaviorSubject('');
  currentUserProfileImage = this.userProfileImage.asObservable();
  private isAuthed = new BehaviorSubject(false);
  currentAuthStatus = this.isAuthed.asObservable();
  private isNewUi = new BehaviorSubject(false);
  currentNewUiStatus = this.isNewUi.asObservable();
  private userInfo: any = null;
  private sideNav = new BehaviorSubject<MatSidenav>(null);
  getSideNav = this.sideNav.asObservable();
  private isApiDocumentation = new BehaviorSubject('');
  apiDocumentation = this.isApiDocumentation.asObservable();
  private isPaymentStripeConnectAccounts = new BehaviorSubject(false);
  paymentStripeConnectAccounts = this.isPaymentStripeConnectAccounts.asObservable();
  private isPass_key_data = new BehaviorSubject({});
  pass_key_data = this.isPass_key_data.asObservable();
  private stickyOffsetTop = new BehaviorSubject(0);
  getStickyOffsetTop = this.stickyOffsetTop.asObservable();
  private drawer: MatDrawer;
  private isDrawerOpened = new BehaviorSubject<boolean>(false);
  getIsDrawerOpened = this.isDrawerOpened.asObservable();
  private isTelescopeViewUrl = new BehaviorSubject('');
  telescope_view_url = this.isTelescopeViewUrl.asObservable();
  private isRedisDashboardAccess = new BehaviorSubject(false);
  redis_dashboard_access = this.isRedisDashboardAccess.asObservable();
  constructor(
    private authService: AuthService,
    private intercomService: IntercomService
    ) {
    // this.visible = true;
    // this.checkUser();
  }

  checkUser(disableCache = false) {
    // console.log('checkUser', this.authService.getUser());
    if(this.authService.getUser()) {
      let facebookId = null;
      if(this.authService.getFacebookId()) {
        facebookId = this.authService.getFacebookId();
      }
      this.authService.getUserDetails(disableCache).subscribe(response => {
        // console.log('getUserDetails', response);
        if (response && response.user_id) {
          this.updateUserProfileImage(response.social_img);
          this.userInfo = response;
          this.isAuthed.next(true);
          // this.checkChatUsers();
          this.isApiDocumentation.next(response.apiDocumentation); //Show/Hide "Api documentation" menu Item
          this.isPaymentStripeConnectAccounts.next(response.payment_stripe_connect_accounts); //Show/Hide "Payout" menu Item
          this.isPass_key_data.next(response.pass_key_data); //Show/Hide "ADD Passkeys/ Remove ALL"

          this.isTelescopeViewUrl.next(response.telescope_view_url); //Show/Hide "Telescope" menu Item
          if(response.hasOwnProperty('redis_dashboard_access')) {
            this.isRedisDashboardAccess.next(response.redis_dashboard_access); //Show/Hide "Redis Dashboard" menu Item
          }

          if(response.hasOwnProperty('active_events') && response.active_events) { // Intercom chat
            if(window.innerWidth > 768) { // if Producer has Events
              this.intercomService.boot();
            }
          }
        }
      },
      error => {
        console.log('navbar service error auth', error);
      });
      this.UpdateUser(parseInt(this.authService.getUser()), this.authService.getType(), facebookId);
    } else {
      this.updateUserProfileImage('');
      if(this.isAuthed.getValue()) {
        this.authService.getUserDetails(disableCache).subscribe(response => {
            // console.log('getUserDetails', response);
            if (response && response.user_id) {
              this.userInfo = response;
              // this.checkChatUsers();
            }
          },
          error => {
            console.log('navbar service error auth', error);
          });
      }
    }
  }

  setSideNav(nav: MatSidenav) {
    this.sideNav.next(nav);
    // console.log('setSideNav', nav);
  }

  hide() {
    setTimeout(() => {
      this.visible = false;
    }, 10);
  }

  show() {
    setTimeout(() => {
      this.visible = true;
    }, 10);
  }


  setDrawer(drawer: MatDrawer) {
    // console.log(drawer);
    this.drawer = drawer;
    // this.drawer.closedStart.subscribe(val => {
    //   console.log('CLOSEDSTART');
    // })
    this.drawer.openedChange.subscribe((o: boolean) => {
     // console.log(`IsOpen: ${o}`) ;
      this.isDrawerOpened.next(o);
    });
  }
  toggleSidenav(isMobile = false) {
    this.drawer.toggle();

    const a = this.drawer.opened;
    const header = (<HTMLElement>document.getElementsByTagName('header')[0]);
    if (header == undefined) return
    // console.log('toggleSidenav', a, header, isMobile);
    if (a && isMobile) {
      header.style.position = 'static'; //header.style.height = '0px';
    } else {
      header.style.position = 'sticky'; //header.style.height = '68px';
    }

  }

  closeSidenav() {
    this.drawer.close();
  }

  toggle() { this.visible = !this.visible; }

  UpdateUser(new_user_id, new_user_type, new_facebook_id, setAsAuthed?) {
    this.user_id.next(parseInt(new_user_id));
    this.userType.next(parseInt(new_user_type));
    this.facebook_id.next(new_facebook_id);
    if (new_user_id > 0 && setAsAuthed) {
      this.isAuthed.next(true);
      this.checkUser();
    }
  }

  updateUserProfileImage (socialImg) {
    const imageLink = this.getProfileImageLink(socialImg);
    this.userProfileImage.next(imageLink);
  }

  getUserInfo() {
    return this.userInfo;
  }

  getProfileImageLink(socialImg): any {
    let imageLink;
    let isNoPhoto = false;
    let defaultImage = '';
    if (socialImg) {
      isNoPhoto = socialImg.indexOf('http') == -1;
      isNoPhoto ? imageLink = {imageLink: defaultImage, isNoPhoto} : imageLink = {imageLink: socialImg, isNoPhoto};
    } else {
      isNoPhoto = true;
      imageLink = {imageLink: defaultImage, isNoPhoto};
    }
    return imageLink;
  }



  UpdateFbID(new_facebook_id) {
    this.facebook_id.next(new_facebook_id);
  }


  setCurrentLandingpageLangs(defaultLang: string, langs: Array<string>) {
    this.landingpageLangs.next({defaultLang, langs});
  }

  setPurchaseModalStatus(status: string) {
    this.purchaseModalStatus.next(status);
  }
  setChangedRouteUrl(url: string) {
    this.changedRouteUrl.next(url);
  }


  setIsNewUi(status: boolean) {
    this.isNewUi.next(status);
  }

  setPreviousUrl(value: string) {
    this.prevUrl = value;
  }

  setStickyOffsetTop(value) {
    this.stickyOffsetTop.next(value);
  }

  showFooter() {
    const footer = (<HTMLElement>document.getElementById('footer'));
    if (footer) { footer.style.display = 'block'; }
  }

  hideFooter() {
    const footer = (<HTMLElement>document.getElementById('footer'));
    if (footer) { footer.style.display = 'none'; }
  }

  showHeader() {
    const header = (<HTMLElement>document.getElementsByTagName('header')[0]);
    const mat_drawer_content = (<HTMLElement>document.getElementsByClassName('mat-drawer-content')[0]);
    const isMobile = window.innerWidth < 768;
    if (header != undefined) { header.style.height = isMobile ? '61px': '68px';header.style.overflow = 'initial';header.style.opacity = '1'; }
    if (mat_drawer_content != undefined) { mat_drawer_content.style.height = isMobile ? 'calc(100% - 61px)': 'calc(100% - 68px)' ; }
  }

  hideHeader() {
    const header = (<HTMLElement>document.getElementsByTagName('header')[0]);
    const mat_drawer_content = (<HTMLElement>document.getElementsByClassName('mat-drawer-content')[0]);
    if (header != undefined) { header.style.height = '0px';header.style.overflow = 'hidden';header.style.opacity = '0'; }
    if (mat_drawer_content != undefined) { mat_drawer_content.style.height = '100%'; }
  }
}
