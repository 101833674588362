import {Component, Input, OnInit, OnDestroy, AfterViewInit, Output, EventEmitter} from '@angular/core';
import {interval, Subscription, Subject, timer} from 'rxjs';
import {take, takeUntil, repeatWhen, switchMap, materialize, dematerialize} from 'rxjs/operators';

@Component({
  selector: 'app-countdown-checkout-timer',
  templateUrl: './countdown-checkout-timer.component.html',
  styleUrls: ['./countdown-checkout-timer.component.css']
})
export class CountdownCheckoutTimerComponent implements OnInit, AfterViewInit, OnDestroy {
 // @Input() time: any; // seconds of our timer
  time: any;
  minutes: any;
  seconds: any;
  @Output() stopTimer: EventEmitter<any> = new EventEmitter();
  worker;
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if (this.time > 0) {
      this.startTimer(this.time);
    }
  }

  startTimer(seconds) {
    this.time = seconds;
    // Issue with slow down of the setInterval in inActive browser tab
    if (typeof Worker !== 'undefined') {
      // Create a new
      this.worker = new Worker(new URL('../../web-worker/countdown-checkout.worker', import.meta.url));
      this.worker.onmessage = ({ data }) => {
        // console.log(`page got message: ${data.message}`);
        if(data.stop) {
          this.sendTimerEnded();
          this.resetTimer();
          this.terminateWorker();
        } else {
          // console.log(this.time);
          this.time = this.time - 1;
          // Time calculations for days, hours, minutes and seconds
          this.minutes = Math.floor((this.time % (60 * 60)) / (60)).toString().padStart(2, '0');
          this.seconds = Math.floor((this.time % (60))).toString().padStart(2, '0');
          if (this.time === 0) {
            this.sendTimerEnded();
            this.terminateWorker();
          }
        }
      };
      this.worker.postMessage({
        delay: seconds*1000, // setTimeout 5-15 min
        interval: 1000 // setInterval 1s
      });
    } else {
      // Web Workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
    }


  }


  resetTimer() {
    this.time = 0;
    this.seconds = 0;
    this.minutes = 0;
    this.terminateWorker();
  }

  sendTimerEnded() {
    this.stopTimer.emit(true);
  }

  terminateWorker() {
    if(this.worker) {
      this.worker.terminate();
      this.worker = undefined;
    }
  }

  ngOnDestroy() {
    this.terminateWorker();
  }

}
