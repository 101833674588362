import {pairwise, filter} from 'rxjs/operators';
import { FacebookService } from './services/facebook.service';
import { FacebookPixelModel } from './facebook-pixel-model';
import { DialogService } from './services/dialog.service';
import {Component, OnInit, ViewChild, Injectable, Renderer2, OnDestroy, ChangeDetectorRef, AfterViewInit, AfterContentInit, HostListener} from '@angular/core';
import { AuthService } from './services/auth.service';
import {Router, NavigationStart, RoutesRecognized, Params, ActivatedRoute} from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
// import $ from 'jquery';
import { GlobalsService } from './globals';
import { NavbarService } from './services/navbar.service';
import {CookieService} from 'ngx-cookie-service';
import {MatDrawer, MatSidenav} from '@angular/material/sidenav';
import { ReaderService } from './services/reader.service';
import { Subscription , BehaviorSubject} from 'rxjs';
import { environment } from './../environments/environment';
import { UserService } from './services/user.service';
import { FreshChatService } from './services/freshchat.service';
import { WalletConnectService } from './services/wallet-connect.service';
import { SolanaConnectService } from './services/solana-connect.service';
import {CdkScrollable} from '@angular/cdk/scrolling';
import {FilterEventsService} from './services/filter-events.service';
import {IntercomService} from './services/intercom.service';
import {PaytweedService} from './services/paytweed.service';
import {RudderstackService} from './services/rudderstack.service';
import * as Sentry from "@sentry/angular-ivy";
import {MaxmindGeoIpService} from "./services/maxmind-geoip.service";
import {CookieConsentService} from "./services/cookie-consent.service";

declare var window: any;

@Injectable()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {

  FBaccessTokenSubscription: Subscription;
  sidebarPosition: string;
  metaDescription: string;
  localeId: string;
  api_base_url = environment.api_base_url;
  images_base_url = environment.images_base_url;
  isProd = environment.production;
  showBlurBackground = false;
  user_id = 0;
  facebook_id;
  type: number;
  isNewUi = false;
  wrapper: any;
  isScrolled = false;
  isMainPage = true;
  languages: any | null;
  langShow = false;
  direction: string | null;
  urlPath: string;
  currentRoute = '';
  langsArray = [];


  producerDetails: any | null;
  isMobile: boolean = false;
  // apiDocumentation: string = '';
  user_agent = navigator.userAgent.toLowerCase();
  isInstagramBrowser: boolean = false;

  mainSliderData = [];
  events = [];
  categories;

  // Events by category
  free = [];
  trending = [];
  location = [];
  week = [];
  week_splitBy3 = [];
  date_events = [];
  locationName = '';
  categorized = {}; // Created Categories
  stickyOffsetTop: number;



  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.isMobile = window.innerWidth <= 768;
  }

  constructor(
    private _cookieService: CookieService,
    private authService: AuthService,
    private router: Router,
    // private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
    public globals: GlobalsService,
    public nav: NavbarService,
    private dialogService: DialogService,
    private readerService: ReaderService,
    // private _renderer2: Renderer2,
    // private fb_pixel_model: FacebookPixelModel,
    private facebookService: FacebookService,
    private changeDetector: ChangeDetectorRef,
    private userService: UserService,
    private freshChatService: FreshChatService,
    private walletConnectService: WalletConnectService,
    private solanaConnectService: SolanaConnectService,
    private filterEventsService: FilterEventsService,
    private intercomService: IntercomService,
    private activatedRoute: ActivatedRoute,
    private paytweedService: PaytweedService,
    private rudderstackService: RudderstackService,
    private maxmindGeoIpService: MaxmindGeoIpService,
    public cookieConsentService: CookieConsentService
  ) {
    this.isMobile = window.innerWidth <= 768;
    window.api_base_url = this.api_base_url;
    window.fireBasePublicKey = environment.firebase.messagingSenderId;
    this.localeId = this.globals.getLocaleId();
    this.languages = this.globals.getCountryData();
    this.direction = this.globals.getDir();

    this.readerService.scrollHandler.asObservable().subscribe((value: number) => {
      if (document.querySelector('mat-sidenav-content')) {
        document.querySelector('mat-sidenav-content').scrollTop = value;
      }
    });

    // this.fb_pixel_model.setGetinPixel(this._renderer2, false, null, null, 'InitPageView');
    this.sidebarPosition = this.direction === 'rtl' ? 'end' : 'start';

    this.metaDescription = (this.localeId === 'he') ? 'פלטפורמה ייחודית וחדשנית לניהול הפקות והזמנת כרטיסים. יצירת אירועים | קידום ושיווק אירועים | ממשק נוח למפיקי האירוע ולרוכשים.' : 'An innovative event production and management eCommerce platform. Create events | Promote your event | A comprehensive platform for the event producer and attendees.';

  }
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('drawer') public drawer: MatDrawer;
  @ViewChild(CdkScrollable) scrollable: CdkScrollable;

  ngAfterViewInit() {
    this.nav.setDrawer(this.drawer);
    this.nav.setSideNav(this.sidenav);
    // this.scrollable.elementScrolled().subscribe(() => {
    //   const pageYOffset = this.scrollable.measureScrollOffset('top')
    //   this.nav.setStickyOffsetTop(pageYOffset);
    //   console.log('scrolled!', pageYOffset);
    // })
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      let error_message = params['error_message'];
      let error_code = params['error_code'];
      if(error_code){
        if (!(window.location.href.indexOf("stripe-connect/payouts") > -1)) {
          this.dialogService.alertsModal('errors',+error_code,8000,false);
        }
      }
      if(error_message){
        this.dialogService.txtMsgModal(error_message,8000,false);
      }
    });

  }

  ngAfterContentInit() {
    if(this.user_agent.includes("instagram")){ // Navbar 'Order' btn visibility in Instagram browser
      this.isInstagramBrowser = true;
    }


    this.maxmindGeoIpService.getGeoData().subscribe((geo: any) => {
      // console.log('geo', geo)
      if(geo && geo.country_data && geo.country_data.iso_code) {
        if (geo.country_data.iso_code !== 'IL') { // DO Not Show Cookies for IL
          this.cookieConsentService.init().then(res => {
            // Enable dark mode
            document.documentElement.classList.add('cc--darkmode');
          });
        }
      }
    });

    // Show Intercom help on DESKTOP
    if(!this.isMobile && this.authService.getActiveEvents()) { // if Producer has Events
      this.intercomService.boot();
    }





  }


  ngOnInit() {
    this.nav.checkUser();
    this.wrapper = document.querySelector('.main-container');
    window.addEventListener('scroll', this.onScroll.bind(this), { passive: true });

    this.nav.currentFacebookId.subscribe(facebook_id => this.facebook_id = facebook_id);
    this.nav.currentUserId.subscribe(async (user_id) => {
      this.user_id = user_id;

      if (user_id) {
        let user_info = this.authService.getUserInfo();
        let b_date = new Date(user_info.birth_date);
        let birthday = b_date.toLocaleString('default', {day: "numeric", month: 'long' });
        this.rudderstackService.identify(user_id, {
          "email": user_info.email,
          "name": user_info.first_name + ' ' + user_info.last_name,
          "phone": user_info.phone,
          "gender": user_info.gender == 1 ? 'Male': user_info.gender == 2 ? 'Female': 'Other',
          "age": +user_info.age,
          "avatar": user_info.social_img,
          // "follower_counter": user_info.follower_counter,
          "birthday": birthday,
          "website": user_info.producer_url,
          "description": user_info.bio,
          "createdAt": user_info.created_at,
          // "user_type": user_info.active_events ? 'Producer' : 'User'
        })

        Sentry.setUser({
          "id": user_id,
          "ip_address": '{{auto}}',
          "email": user_info.email,
          "name": user_info.first_name + ' ' + user_info.last_name,
          "phone": user_info.phone,
          "gender": user_info.gender == 1 ? 'Male': user_info.gender == 2 ? 'Female': 'Other',
          "age": +user_info.age,
          "follower_counter": user_info.follower_counter,
          "birthday": birthday,
          "website": user_info.producer_url,
          "description": user_info.bio,
          "createdAt": user_info.created_at,
          "user_type": user_info.active_events ? 'Producer' : 'User'
        })

        if(user_info.active_events) {
          let slug = ''.substring(''.lastIndexOf("/")+1);
          this.rudderstackService.group(user_info.user_id, {
            'bio': user_info.bio,
            'followers': user_info.follower_counter,
            'name': user_info.first_name + ' ' + user_info.last_name,
            'slug': slug,
            'img': user_info.social_img,
            'user_id': user_info.user_id
          })
        }
      }
    });
    // this.nav.currentNewUiStatus.subscribe(status => {
    //   this.isNewUi = status;
    //   document.body.style.backgroundColor = this.isNewUi ? '#24262B' : '#FFF';
    //   document.body.style.color = this.isNewUi ? '#fff' : '#212931';
    //   this.changeDetector.detectChanges();
    // });
    // console.log('user_id', this.user_id);

    this.nav.currentUserType.subscribe(type => this.type = +type);

    this.router.events.pipe(
      filter(event => {
        return event instanceof NavigationStart;
      }))
      .subscribe((event: NavigationStart) => {
        this.nav.setChangedRouteUrl(event.url);
        const pathname = window.location.pathname;
        const allLangs = this.globals.getCountryData();
        this.langsArray = [];
        this.langsArray.push('/');
        for (const flagObj of allLangs) {
          this.langsArray.push(flagObj.lang);
        }
        const urlLang = pathname.substr(pathname.length - 1) === '/' ? pathname.substring(1, pathname.length - 1) : pathname.substr(1);
        this.isMainPage = this.langsArray.indexOf(urlLang) !== -1;
        this.urlPath = pathname; // event.url;
        this.setCurrentRoute();
        // console.log('router ', event.url, pathname, urlLang, this.isMainPage);
        this.scrollable.scrollTo({ top: 0, behavior: 'auto' }) // Always scroll to top on route change
      });

      this.router.events.pipe(
      filter(event => {
        return event instanceof RoutesRecognized;
      }),
      pairwise(),)
        .subscribe((event: any[]) => {
          if (event && event.length) {
            this.nav.setPreviousUrl(event[0].urlAfterRedirects);
          }
        // console.log('router.events are ->', event);
      });

    if(this.authService.getToken()) {
      if(!this.authService.getRefreshToken()) {

        this._cookieService.delete('token', '/');
        this._cookieService.delete('facebook_id');
        // this.authService.removeReaderApiKeyAndId();
        this._cookieService.delete('ext_user', '/');
        this._cookieService.delete('user_info','/');
        this._cookieService.delete('FCMT', '/');
        this.nav.updateUserProfileImage('');
        window.location.replace('');
      }
    }

    if(!this.authService.getToken() && !this.authService.getRefreshToken() && (this.authService.getUser() || this._cookieService.get('user_info'))) {
      this._cookieService.delete('facebook_id');
      // this.authService.removeReaderApiKeyAndId();
      this._cookieService.delete('ext_user', '/');
      this._cookieService.delete('user_info','/');
      this._cookieService.delete('FCMT', '/');
      this.nav.updateUserProfileImage('');

      window.location.replace('');

    }

    // add meta title and description:
    this.titleService.setTitle('Getin');
    this.meta.updateTag({ name: 'description', content: (this.metaDescription)});
    this.meta.updateTag({ property: 'og:description', content: (this.metaDescription)});
    // document.addEventListener("DOMContentLoaded", (event) => {
    //   this.initAjaxPrefilter()
    // });

    // this.nav.apiDocumentation.subscribe(apiDoc => this.apiDocumentation = apiDoc);// Show or Hide

    this.nav.hide(); // OLD HEADER
    this.isMobile = window.innerWidth <= 768;
    // this.filterEventsService.autocomplete.subscribe(value => {
    //  // console.log("NEW", value)
    // })
    // this.filterEventsService.getEvents(null ).subscribe(value => {
    //   this.mainSliderData = value.sliders.sort((a, b) => a.number_position - b.number_position);
    //   this.categories = value.categories;
    //   this.handleEvents(value.events);
    //   this.filterEventsService.setEvents(value.events);
    //   // console.log('app-homepage getEvents', this.events, this.categories, this.categorized);
    // });
  }

  handleEvents(events) {
    // console.log('TRIGGERED UPDATE', events);
    this.events = [...events]; // console.log('events', this.events);
    this.free = this.filterEventsService.handleEvents(events, 'free'); // console.log('free', this.free);
    this.trending = this.filterEventsService.handleEvents(events, 'trending'); // console.log('trending', this.trending);
    this.week = this.filterEventsService.handleEvents(events, 'week'); // console.log('week', this.trending);
    this.week_splitBy3 = this.chunkEvents(events); // TODO pass this.week here
    // this.categorized = this.filterEventsService.handleEvents(this.events, 'categorized', this.categories);
  }

  // chunk events array to array like [['a'],['b', 'c', 'd'],['e', 'f', 'g']]
  chunkEvents(events) {
    const chunkSize = 3;
    const new_events = [];
    if (!events.length) {return new_events}
    new_events.push([events[0]]);
    for (let i = 1; i < events.length; i += chunkSize) {
      const chunk = events.slice(i, i + chunkSize);
      new_events.push(chunk);
      // do whatever
    }
    // console.log('Chunk new_evens', new_events);
    return new_events
  }

  setCurrentRoute () {
    const pathname = window.location.pathname;
    const urlLang = pathname.substr(pathname.length - 1) === '/' ? pathname.substring(1, pathname.length - 1) : pathname.substr(1, 2);
    const isUrlLang = this.langsArray.indexOf(urlLang) !== -1;

    if (this.urlPath.charAt(0) === '/') {
      this.urlPath = this.urlPath.substr(1);
    }
    const urlParhArray = this.urlPath.split('/');
    if (this.langsArray.indexOf(urlParhArray[0]) !== -1) {
      urlParhArray.shift();
    }
    const urlPath =  urlParhArray.join('/');
    // const querySymbol = urlPath.indexOf('?') === -1 ? '?' : '&';
    this.currentRoute = '/' + urlPath; // + querySymbol + 'f=1';
    // console.log('currentRoute', this.currentRoute, this.urlPath);
  }

  onScroll() {
    const scrollTop = this.wrapper.scrollTop;
    this.isScrolled = scrollTop > 70;
  }



  applyBlurBackound ($event) {
    setTimeout(() => {
      this.showBlurBackground = $event;
    }, 0);
  }

  ngOnDestroy() {
    this.FBaccessTokenSubscription.unsubscribe();
    window.removeEventListener('onMessageReceived');
    window.removeEventListener('scroll', this.onScroll.bind(this), true);
  }
}
