import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { EventTicketsService } from './event-tikets-service/event-tickets.service';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  TemplateRef,
  ViewChild,
  AfterViewInit,
  ElementRef,
  Renderer2,
  ViewContainerRef,
  HostListener,
  Inject,
  AfterViewChecked,
  AfterContentInit,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from '../services/event.service';
import { NavbarService } from '../services/navbar.service';
import { environment } from '../../environments/environment';
import {
  DomSanitizer,
  SafeResourceUrl,
  Meta,
  Title,
} from '@angular/platform-browser';
import { GlobalsService } from '../globals';
import { LoginComponent } from '../login/login.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from '../services/auth.service';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormArray,
  Validators,
  UntypedFormControl,
  RequiredValidator,
} from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { emailRegex, imageRegex } from './../regex';
import {
  DatePipe,
  Location,
  DOCUMENT,
  formatNumber,
  getCurrencySymbol,
} from '@angular/common';
import { slideIn, fade } from './../animation';
// import { LocalizationService } from '../services/localization.service';
import moment from 'moment';
import { DialogService } from '../services/dialog.service';
import { AppPurchaseRequest } from '../interfaces/app-purchase-request.interface';
import { FacebookPixelModel } from '../facebook-pixel-model';
import { ErrorCodesService } from '../code-strings/error_codes';
import { FacebookService } from '../services/facebook.service';
import { MbscDatetimeOptions, MbscSelectOptions } from '@mobiscroll/angular';
import { Subscription, interval, Subject, combineLatest } from 'rxjs';
import { UserService } from '../services/user.service';
import { CryptoPaymentService } from '../services/crypto-payment.service';
import { ClipboardService } from 'ngx-clipboard';
import { takeUntil, debounceTime } from 'rxjs/operators';
import $ from 'jquery';
import { CurrencyLocaleService } from '../services/currency-locale.service';
import { WalletConnectService } from '../services/wallet-connect.service';
import { CountdownCheckoutTimerComponent } from '../event-page/countdown-checkout-timer/countdown-checkout-timer.component';
// import { TicketImgModalComponent } from './ticket-img-modal/ticket-img-modal.component';
import { NftThankYouModalService } from '../services/nft-thank-you-modal.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ContactProducerComponent } from './contact-producer/contact-producer.component';
import { RudderstackService } from '../services/rudderstack.service';
import {
  PasscodeModalComponent
} from "./passcode-modal/passcode-modal.component";
import * as customValidator from "../directives/blocked-email-validator";

declare var window: any;
declare var CryptoJS: any;
declare var Stripe;
declare var Hyper;

@Component({
  selector: 'app-event-page',
  templateUrl: './event-page.component.html',
  styleUrls: ['./event-page.component.css'],
  animations: [slideIn, fade],
})
export class EventPageComponent implements OnInit, AfterViewInit, OnDestroy {
  getin_base_url = environment.getin_base_url;
  api_base_url = environment.api_base_url;

  isProd = environment.production;
  isIcon = false;
  isOpenWallets = false;
  isOpenSolanaWallets = false;
  isCloseWallet = false;
  isCloseSolanaWallet = false;
  bgPreview;
  event;
  seller_code = '0';
  coupon_code = '0';
  coupon_code_tickets = '0';
  producer_id: number;
  preview_page;
  coverPreview: any;
  coverPreviewDefault: any;
  isNewUi: boolean;
  images_base_url = environment.images_base_url;
  imgix_url = environment.imgix.domain;
  backgroundImageName: string;
  backgroundFeedImageName: string;
  backgroundFileName: string;
  coverImgSize = '';
  bgImgSize = '';
  screenHeight: any;
  screenWidth: any;

  media;
  salesman_user: number;
  salesman_id: number;
  event_details: any = [];
  description;
  descriptionToShow: any;
  marketing;
  tickets;
  categories;
  rooms;
  tables;
  tickets_reg;
  rooms_reg;
  tables_reg;
  coupons;
  discounts;
  mailing;
  all_tickets: Array<any> = [];
  localeId: string;
  currency: string;
  event_id: number;

  showCategory: any = {};
  ticketsFiltered: any = {};
  withoutCategory: any = [];
  totalTicketsInCategories: number = 0;
  totalTicketsWithoutCategories: number = 0;

  ticketLimits = {};
  choosedItems: any = {};
  toggleItem: number;

  purchase_item: number;
  total: { total_price: number; total_items: number };
  item_is_reg: boolean;
  quantity: number | null = 0;
  item_id: number | string = 0;
  title = '';
  checkboxArr: Object = {};

  purchaser: {};
  sub_purchaser: Array<any> = [];
  /**
   * Producer Fee (in fee management)
   */
  commission: number;
  purchaser_commission: any; // string digit like '10.00'
  total_purchaser_commission;
  total_discount = 0;
  total_ticket_discount = 0;
  total_price_n_commission: string;
  total_price_w_commission: string;
  commission_ils: string;
  tax_commission;
  total_tax_commission = 0;
  tax_and_fees;
  service_commission;
  total_service_commission;
  platform_fee;
  platform_fee_rule; // 1 - Each ticket fee, 2 - All purchase fee.
  platform_fee_type; // 1 - Sum or 2 -Percent
  total_platform_fee = 0;
  freeItem: boolean;

  isBottomTitle = true;
  currentUrl = '';
  component: any;
  dialogRef: MatDialogRef<any>;

  mailingStatus = 1;

  facebook_link: string;
  facebook_id = '0';
  event_fb_friends: Array<any> = [];
  templateDescIsOpen = false;
  producerDetails: any;

  ext_user_id = 0;
  user_id;
  edit_producer = false;

  years = [];
  months = [];
  checkBoxSave = false;
  checkBoxAgree = false;
  checkBoxMailing = true;
  checkBoxCustomEvent = false;
  isShowPromo = true;
  checkBoxProducerSubscription = false;

  form_inputs = {
    default_inputs: [],
    custom_inputs: [],
  };
  new_custom_inputs: any[] = [];
  purchase_passcode_mode: number = 0;
  purchase_passcode: string = '';
  purchase_type: number;
  purchase_validation: number;
  facebook_validation: number;
  email_validation = 0;
  facebookConnect = false;
  fb_module = true;
  radioBtn = false;
  birthday_validation: number;

  participantForm: UntypedFormGroup = this.fb.group({
    participants: this.fb.array([]),
  });
  formSubmited = false;
  eventPaymentMode = false;
  ticketsAvailability;
  ticketsSoldOut;
  isFromEventPayment;
  user_info;
  counter = 0;
  ageValidation = false;
  ageDisabled = false;
  toggleParticipant = 1;
  public stepTemplate: string;
  cardTemplate: string;
  newStepTemplate: string;
  stepArray = [];
  currentPath: string;
  dateTimer: any;
  formInputsOthers: any;
  localId = this.globals.getLocaleId();
  customClass = '';
  label;
  isInit = true;
  shareUrl: any;

  canUseCash = false;
  payment_method: number;
  used_discount_type: number;
  used_discount_id: number;
  coupon_source: number;
  discount_search_key: string;
  externalApi = false;
  feed_nav = true;

  // Tranzila
  tranzillaUrl: SafeResourceUrl;
  remaining_payment: string;
  purchaseTimer: Date;

  // Bank Of America
  boa_url: string = environment.authorize_net_url;
  token: string;
  iframeDisplay = 'none';

  // stripe
  @ViewChild('cardInfo') cardInfo: ElementRef;
  card: any;
  cardErrors: any;
  stripe: any;
  stripe_form: any;
  checkStripeWindowID: any; // Hyper And Stripe collisions
  enable_indirect_payment: boolean = false;
  stripe_client_secret: string = '';
  stripe_publishable_key: string = '';
  stripe_return_url: string = '';
  waiting_list_checkout: boolean = false;


  // hyper
  hyper: any;
  widgets: any;
  hyper_return_url: string = '';
  hyper_clientSecret: string = '';
  hyperSubmitDisable: boolean = true;
  hyperSubmitHidden: boolean = true;
  @ViewChild('unified_checkout') unified_checkout: ElementRef;
  @ViewChild('hyper_errors') hyper_errors: ElementRef;

  @ViewChild('templateBox') templateBox: ElementRef;
  // CoinBase
  coinBaseData: any | null;
  toggleCripto: string | null;

  gateway_type: string;
  gateway_id: number | null;
  payment_id: number | null;
  isClickedStripeButton = false;

  /* When we are in purchase modal mode only it will be true */
  full_purchase = false;

  togglePurchaseModal: boolean;
  couponCheckBox;
  couponCheckBoxPeper;
  couponModal = false;
  couponName: string;
  selectOptionCouponValue: string;
  customParams: object = {};
  quickPurchase = false;

  toggleTerms = false;
  templateTerms: string;
  togglePrivacy = false;
  templatePrivacy: string;

  cfReturnFlag = false;
  cfReturnStr = '';
  external_identifier = '';
  external_token = '';
  email = '';
  sub_purchaser_id = 0;
  toggleFacebook = false;
  hasSmartBanner = false;
  producerImage: any;
  producerName: string;

  // BOA
  accept_url: SafeResourceUrl = environment.authorize_net_accept_url;
  // STRIPE
  stripe_url: SafeResourceUrl = environment.stripe_accept_url;
  // HYPER
  hyper_script_url: SafeResourceUrl = environment.hyper_script_url;
  // APPLE PAY
  tranzila_apple_pay_url: SafeResourceUrl = environment.tranzila_apple_pay_url;

  submitted: boolean;
  customStripeForm: UntypedFormGroup;
  message: any;
  formProcess: boolean;

  // TODO add functionality for save credit card
  savedCard: boolean;
  cardMethods: Array<any> | null;
  sidenav: MatSidenav;
  paymentMethod: any | null;

  // External user
  currentUserType: number;
  loginExternalUserErrorMsg = '';
  currentUserSubscription: Subscription;
  currentFacebookLoginDataSubscription: Subscription;
  walletLoginSubscription: Subscription;
  walletSignUpSubscription: Subscription;

  coverImg: string;
  date = new Date();
  dayStart = this.date.setHours(0, 0, 0, 0).valueOf();
  // min max prices
  // ticket_min_price;
  // room_min_price;
  // table_min_price;
  timerTimestamp: string | number;
  lang: string;
  user_agent = navigator.userAgent.toLowerCase();
  base_url = window.location.protocol + '//' + window.location.host;
  showPurchaseBtn = true;
  showStripeForm = false;
  showHyperForm = false;

  isOpenShareBox = false;
  toggleShareBoxClicked: boolean = false;

  componentTranslations: object = {
    noAvailableItems: {
      he: 'אין כרטיסים זמינים למכירה כעת',
      en: 'No available items',
      es: 'No hay elementos disponibles',
    },
    defaultCounterTitle: {
      he: 'רצים אחרי השמועה',
      en: 'Catch up with the rumor',
      es: 'Ponte al día con el rumor',
    },
  };

  customAgreements = {
    '123657': 'https://static.get-in.com/files/page_sentences.pdf',
    '110047': 'https://static.get-in.com/files/back_of_card.pdf',
  };
  customIsPresent = false;

  loginShow = false;
  clicked = false;
  userEmail: string;

  creditCard = {
    cardNumber: '',
    cardMonth: '',
    cardYear: '',
    cardCvv: '',
    cardId: '',
  };

  promoCode: string;

  genderSettings: MbscSelectOptions = {
    select: 'single',
    theme: this.globals.mobiscrollTheme(),
    display: 'center',
    lang: this.globals.LOCALE_ID,
    filter: false,
  };
  currentLngFlag: any;
  showlngBox = false;
  cardIsChecked: boolean = false;

  today = new Date();
  myBirthdaySettings: MbscDatetimeOptions = {
    theme: this.globals.mobiscrollTheme(),
    lang: this.globals.getLocaleId(),
    // animate: 'pop',
    headerText:
      this.globals.getDir() === 'rtl' ? 'תאריך לידה' : 'Date of Birth',
    min: new Date(this.today.setFullYear(this.today.getFullYear() - 120)),
    display: 'center',
    dateWheels: 'yy MM dd',
    defaultValue: '1980-01-01',
    animate: 'slideup',
    returnFormat: 'iso8601',
    max: new Date(),
  };

  hardcodedEvents = [82077, 83266, 82949, 82432, 81894, 81604, 81553];
  showPhone = false;

  urlPath: string;
  currentRoute = '';
  langsArray = [];
  languages: any[] | null;
  langShow = false;
  dir: string | null;

  isShowBackArrow = true;
  cryptoPaymentOrderCode: string | null;
  cryptoPaymentStatus = 0;
  checkPaymentSubscription: Subscription;
  walletConnectData: any = {};
  solanaConnectData: any = {};

  options: {
    isDraggable: boolean;
    srcElement: any;
  };
  isMobile: boolean;
  emailRegex = emailRegex;
  showEventEdit = false;
  userSameAsProducer = false;
  isAdmin = false;
  isShowBtn = false;

  paymentGateways: any[] = [];
  isSelectedTicketOutOfStock: boolean;
  choosedItemsCheckIfSoldOut: any = {};

  // Coupon_code functionality
  active_coupon;
  isActiveCoupon: boolean;
  couponCodeSent = false;
  couponTicketId = 0;
  couponTicket: any | null;
  couponDiscount: 0;
  couponDiscountType: any | null; // 1 - percents, 3 - sum
  discount: 0; // new functionality
  discount_type: any | null; // new functionality 1 - percents, 3 - sum

  transactionBtnStatus: boolean = false;
  solanaTransactionBtnStatus: boolean = false;
  trnBtnStatus: boolean = false;
  trnSolBtnStatus: boolean = false;

  purchase_id: number;
  purchase_item_id: number;
  main_purchase_identifier: string;
  event_pixel: number;
  event_pixels: number[] = [];
  pixel_content: string = '';
  event_categories: string = '';

  isShowOtp: boolean = false;
  userData: any | null = null;
  showPersonalInfoComponent = false;
  showUserLoginComponent = false;
  ticketLimit = {};
  eventStartDate;

  chartSeats: any;
  showSeatsIo: boolean = false;
  seatsRendering: boolean = false;
  showSeatsDisplay: boolean = false;
  seatsHash: string = '';
  seatsSelectedObjects;
  chartSeatsRendered: boolean = false;
  seatsConfig: any;
  seatsUserConfig: any;
  seatsQuantity: number = 0;
  selectedSeats: [];
  listSelectedObjects: {};
  seatsTotal_n_comission = 0;
  seatsTickets = [];
  seatsCategoriesList = [];

  seatsPanelOpenState = false;
  toggleBestSeats: boolean = false;
  seatsDeselectInProgress: boolean = false;
  seatsTicketsHaveInvalidItem: boolean = false;
  seatsHoldToken: string = '';
  seatsSupportsBestAvailable: boolean = false;

  showProducerSubscription: boolean = false;

  url_ticketsArr = [];
  event_fetched = new Subject<boolean>();
  seatsonSessionInitialized = new Subject<boolean>();
  seatsonChartRendered = new Subject<boolean>();
  combineLatestSubscription;
  DisableGetTicketsForPurchase: boolean = false;
  waitedTermsChecked: false;
  @ViewChild('couponCoderegular') couponCoderegular: ElementRef;
  @ViewChild('couponCodePeper') couponCodePeper: ElementRef;

  @ViewChild('previewSection') previewSection: ElementRef;
  @ViewChild('templateTickets') templateTickets: TemplateRef<any>;
  @ViewChild('templateSeats') templateSeats: TemplateRef<any>;
  @ViewChild('templateSeatsTickets') templateSeatsTickets: TemplateRef<any>;
  @ViewChild('templateWaitedTicketsTerms') templateWaitedTicketsTerms: TemplateRef<any>;
  @ViewChild('templateDescription') templateDescription: TemplateRef<any>;
  @ViewChild('eventDescription') eventDescription: ElementRef;
  @ViewChild('templateOrder') templateOrder: TemplateRef<any>;
  @ViewChild('templateCard') templateCard: TemplateRef<any>;
  @ViewChild('templateGuest') templateGuest: TemplateRef<any>;
  @ViewChild('templateMain') templateMain: TemplateRef<any>;
  @ViewChild('templateNoValidCoupon') templateNoValidCoupon: TemplateRef<any>;
  @ViewChild('templateLoginRegistration')
  templateLoginRegistration: TemplateRef<any>;
  @ViewChild('templateUserRegistration')
  templateUserRegistration: TemplateRef<any>;
  @ViewChild('tokenForm') formElementRef: HTMLFormElement;
  @ViewChild('tokenInput') inputElementRef: ElementRef;
  @ViewChild('orderticket') orderTicket: ElementRef;
  @ViewChild('buy_ticket_btn_nui', { static: false })
  buy_ticket_btn_nui: ElementRef;
  @ViewChildren(CdkScrollable) cdkScrollable;

  @ViewChild(CountdownCheckoutTimerComponent)
  checkoutTimer: CountdownCheckoutTimerComponent;
  isInstagramBrowser: boolean = false;
  checkBtnOrderIntervalID: any;

  subscriptionCalcTotalPrice: Subscription;
  subscriptionCheckPromo: Subscription;

  hasRegularTicket: number = 0;
  hasDonateTicket: number = 0;
  hasWaitedTicket: number = 0;
  donate_tickets = [];
  donation_fee_mode: number = 0;
  show_price_with_fees: number = 0;
  checkout_timeout_counter = 900; // timer in seconds
  waiting_list_mode: boolean = false;
  waiting_list_gateway_features: boolean = false; // check from BE
  isAllTicketsFromWaitingList: boolean = false; // check for free and regular tickets which have waiting_list = 1
  isAtLeastOneTicketFromWaitingList: boolean = false;
  isAtLeastOneAvailableRegularTicket: boolean = false;

  live_cart_hash: string = '';
  live_cart_hashIntervalID: any;
  deleteLiveCartFetching: boolean = false;
  invalidEmailsError = [];
  sendSmsTimer = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private eventService: EventService,
    public globals: GlobalsService,
    private location: Location,
    public nav: NavbarService,
    private changeDetector: ChangeDetectorRef,
    public sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private renderer: Renderer2,
    public authService: AuthService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private errorService: ErrorCodesService,
    private _cookieService: CookieService,
    private datePipe: DatePipe,
    private viewContainerRef: ViewContainerRef,
    private userService: UserService,
    private dialogService: DialogService,
    private fb_pixel_model: FacebookPixelModel,
    private facebookService: FacebookService,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private _document: Document,
    private meta: Meta,
    private clipboardService: ClipboardService,
    private cryptoService: CryptoPaymentService,
    private currencyLocale: CurrencyLocaleService,
    private walletConnectService: WalletConnectService,
    private titleService: Title,
    private nftThankYouDialog: NftThankYouModalService,
    private eventTicketsService: EventTicketsService,
    private scrollDispatcher: ScrollDispatcher,
    private rudderstackService: RudderstackService,
  ) {
    this.subscriptionCalcTotalPrice =
      this.eventTicketsService.getCalcTotalPrice.subscribe(response => {
        // console.log('calcTotalPrice', response);
        this.calcTotalPrice(response);
      });
    this.subscriptionCheckPromo =
      this.eventTicketsService.getCheckPromo.subscribe(response => {
        // console.log('getCheckPromo', response);
        this.checkPromo(response);
      });
    const currentLangUrl = this.globals.getCurrentLangUrl();
    this.lang = currentLangUrl ? '/' + currentLangUrl + '/' : '/';
    this.date.setDate(this.date.getDate() + 7); // 7 days from now
    this.currentUserSubscription = this.nav.currentUserType.subscribe(
      type => (this.currentUserType = +type)
    );
    this.customStripeForm = this.fb.group({
      cardNumber: '',
      expMonth: '',
      expYear: '',
      cvv: '',
      // skills: this.fb.array([]),
    });
    // Preview event mode
    this.preview_page = this.activatedRoute.snapshot.data['preview'];
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    this.nav.masterContainerWidth = 'full-width';
    this.nav.customWrapperClass = 'full-screen';

    this.bgImgSize = 'desktop';

    /* Here we know that we are on full purchase */
    if (this.router.url.indexOf('full-purchase') !== -1) {
      this.nav.customClass = 'fullPurchase';
      this.nav.hide();
      this.full_purchase = true;
    } else if (this.router.url.indexOf('event-payment') !== -1) {
      this.eventPaymentMode = true;
    }

    this.getYears();
    // this.genders = [{ label: 'Male', id: 0 }, { label: 'Female', id: 1 }, { label: 'other', id: 0 }];

    this.stepArray = [
      { label: 'templateDescription', id: 0 },
      { label: 'templateTickets', id: 1 },
      { label: 'templateGuest', id: 2 },
      { label: 'templateOrder', id: 3 },
      // { label: 'templateLoginRegistration', id: 2 }, { label: 'templateUserRegistration', id: 3 },  { label: 'templateCard', id: 4 },
      { label: 'templatePay', id: 4 },
    ];
    this.nav.currentChangedRouteUrl.subscribe(urlChanged => {
      if (urlChanged) {
        this.currentPath = urlChanged;
      } else {
        this.currentPath = '/';
      }
      // console.log('urlChanged', urlChanged);
    });

    // load BOA script
    this.loadScript();
    // this.loadStripe();
    this.loadApplePay();
    // this.loadHyper();

    this.isMobile = window.innerWidth <= 768;
    this.nav.hideHeader();
  }

  /**
   *
   * @param event total: this.total, item_is_reg: this.item_is_reg, quantity: this.quantity, item_id: this.item_id,
        purchase_item: this.purchase_item, choosedItemsCheckIfSoldOut: this.choosedItemsCheckIfSoldOut,
        choosedItems: this.choosedItems, ticketLimits: this.ticketLimits,
        showCategory: this.showCategory, withoutCategory: this.withoutCategory,
        sign: sign,
        item: item

   */
  setEditTicket(event) {
    //  {total: this.total, item_is_reg: this.item_is_reg, quantity: this.quantity};
    this.total = event.total;
    this.item_is_reg = event.item_is_reg;
    this.quantity = event.quantity;
    this.item_id = event.item_id;
    this.purchase_item = event.purchase_item;
    this.choosedItemsCheckIfSoldOut = event.choosedItemsCheckIfSoldOut;
    this.choosedItems = event.choosedItems;
    this.ticketLimits = event.ticketLimits;
    this.hasRegularTicket = event.hasRegularTicket;
    this.hasDonateTicket = event.hasDonateTicket;
    this.hasWaitedTicket = event.hasWaitedTicket;
    this.donate_tickets = event.donateTickets;
    this.calcTotalPrice(false);
    // console.log(this.choosedItemsCheckIfSoldOut)
    // console.log(this.choosedItems)
    // console.log(event)

    if (this.tickets.length > 4 && event.isLastBtn && !this.isMobile) {
      // scroll down templateBox if last '+' button was clicked
      setTimeout(() => {
        this.scrollToBottomDiv(this.templateBox.nativeElement);
      }, 50);
    }

    if (event.sign === 'plus') {
      this.rudderstackAddedToCart(event.item.id, event.item.price);
    }
    if (event.sign === 'minus') {
      this.rudderstackRemovedFromCart(event.item.id, event.item.price);
    }
  }

  checkIsMobile() {
    const windowWidth = window.innerWidth;
    this.isMobile = windowWidth <= 768;
  }

  copyUrl(the_url, code = 'copied') {
    this.clipboardService.copyFromContent(the_url);
    this.clipboardService.destroy();
    this.dialogService.alertsModal('success', code, 2000, true);
  }

  fixOrderButton() {
    setTimeout(() => {
      // const results = (<HTMLElement>window.document.getElementById('orderticket'));
      // console.log('fixOrderButton', results, this.orderTicket);
      // if(results) {
      //   results.focus();
      //   results.style.position = 'fixed';
      // }
      this.orderTicket.nativeElement.style.position = 'fixed';
      this.orderTicket.nativeElement.focus();
    }, 200);
  }

  changeLanguage(lang) {
    this.setCurrentRoute();
    if (this.localeId !== lang) {
      this.globals.changeLang(lang);
      window.location.replace('/' + lang + this.currentRoute);
    }
    // console.log('changeLanguage', '/'+this.localeId, '/'+lang, this.currentRoute);
    this.langShow === false && this.localeId === lang
      ? (this.langShow = true)
      : (this.langShow = false); // Mobile language-box show
  }

  setCurrentRoute() {
    const urlQueryParams = window.location.search;
    const pathname = window.location.pathname;
    const urlLang =
      pathname.substr(pathname.length - 1) === '/'
        ? pathname.substring(1, pathname.length - 1)
        : pathname.substr(1, 2);
    const isUrlLang = this.langsArray.indexOf(urlLang) !== -1;
    let urlPath = isUrlLang ? pathname.substr(3) : pathname;
    if (urlPath.charAt(0) === '/') {
      urlPath = urlPath.substr(1);
    }
    this.currentRoute = '/' + urlPath + urlQueryParams;
    // console.log('urlPath', urlLang, isUrlLang, urlPath, urlQueryParams, pathname.substr(3));
    // console.log('currentRoute', this.currentRoute, this.languages, this.langsArray);
  }

  // @ViewChild('sidenav') sidenav: MatSidenav;

  onCall() {
    if (!!this.producerDetails.producer_phone) {
      this.showPhone = !this.showPhone;
    }
  }

  onEmail() {
    // window.open("mailto:"+ this.producerDetails.email + "?subject=" + 'subject' +"&body="+ 'message',"_self");
  }

  onDialer() {
    setTimeout(() => {
      window.open(
        this.sanitizer.bypassSecurityTrustUrl(
          `tel:${this.producerDetails.producer_phone}`
        ),
        '_system'
      );
    }, 100);
  }

  checkRadioBtn(event, btn) {
    // console.log('checkRadioBtn', event, btn);
    // event.target.classList.remove('mat-radio-checked');
    // event.target.classList.add('marked-unchecked');
  }

  toggleShareBox() {
    // console.log(this.description.value.length, this.shareUrl?.encodedShareUrl);
    // let text = this.description.value;
    // if(text.length > 100) {
    //   text = text.substring(0,100);
    //   text = text + '...';
    // }
    if (this.toggleShareBoxClicked) {
      return;
    }
    let url = this.event_details.url_friendly
      ? this.api_base_url +
        '/' +
        this.event_details.url_friendly +
        '?lang=' +
        this.localeId
      : this.api_base_url + '/' + this.event_id + '?lang=' + this.localeId;
    // let url2 = this.api_base_url + '/' + this.localeId + '/' +  this.event_details.event_id
    let data = {
      title: this.event_details.title,
      text: 'Crafted to empower creators',
      url: url,
    };
    if (
      'canShare' in window.navigator ||
      ('share' in window.navigator && this.isMobile)
    ) {
      if (window.navigator.share) {
        this.toggleShareBoxClicked = true;
        window.navigator
          .share(data)
          .then(() => {
            this.toggleShareBoxClicked = false;
          })
          .catch(e => {
            console.error('Error', e);
            this.toggleShareBoxClicked = false;
            this.isOpenShareBox = !this.isOpenShareBox; // use default share view
          });
      }
    } else {
      this.isOpenShareBox = !this.isOpenShareBox; // use default share view
    }
  }

  openContactProducerDialog() {
    const sendProducerEmail = this.dialog.open(ContactProducerComponent, {
      data: { event_id: this.event_id, dir: this.dir },
      autoFocus: true,
      panelClass: 'contactProducerModal',
    });
  }

  setShareSocial() {
    this.shareUrl = {};
    this.shareUrl.encodedShareUrl = this.event_details.url_friendly
      ? encodeURIComponent(
          this.api_base_url +
            '/' +
            this.event_details.url_friendly +
            '?lang=' +
            this.localeId
        )
      : encodeURIComponent(
          this.api_base_url + '/' + this.event_id + '?lang=' + this.localeId
        );
    // this.shareUrl.whatsapp_link = this.sanitizer.bypassSecurityTrustUrl('whatsapp://send?text=' + this.shareUrl.encodedShareUrl);
  }

  sideNavToggle() {
    if (this.sidenav) {
      this.sidenav.toggle();
    } else {
      this.nav.setSideNav(this.sidenav);
    }
  }

  toggleCoinBase(index) {
    this.checkIfTicketsWereSoldOut().then(() => {
      this.showPurchaseBtn = true;
      if (!this.isSelectedTicketOutOfStock) {
        this.toggleCripto === index
          ? (this.toggleCripto = null)
          : (this.toggleCripto = index);
      }
    });
    // this.toggleCripto=index;
    // console.log('toggleCoinBase', index + '_icon.png', this.toggleCripto, this.coinBaseData);
  }

  copyWalletData(key) {
    this.clipboardService.copyFromContent(key);
    this.clipboardService.destroy();
    this.dialogService.alertsModal('success', 'couponCopied', 2000, true);
    // console.log('copyWalletKey' , key);
  }

  checkCryptoPayments() {
    if (this.coinBaseData) {
      const data = { coinbase_id: this.coinBaseData.data.id };
      this.cryptoService.checkCryptoPayments(data).subscribe(
        (response: any) => {
          // console.log('checkCryptoPayments', response, this.gateway_type, this.coinBaseData);
          if (response && response.code === 'success') {
            if (response.data) {
              this.isShowBackArrow = response.data.back;
              this.cryptoPaymentStatus = response.data.status;

              // this.cryptoPaymentOrderCode = this.coinBaseData.data.code;
              // const source = interval(1000);
              // const seconds = val => val === 11;
              // const sourceNumbers = source.pipe(filter(seconds));
              // const numberCount = sourceNumbers.pipe(scan((acc, _) => acc + 1, 0));
              // const countNumbers = numberCount.pipe(filter(val => val === 10));
              // const setstatus = sourceNumbers.pipe(
              //   withLatestFrom(numberCount),
              //   takeUntil(countNumbers)
              // );
              // const st = setstatus.subscribe(val => {
              //   this.cryptoPaymentStatus = response.data.status = 2;
              //   console.log('change Status', this.cryptoPaymentStatus, response.data.status, val, this.isShowBackArrow);
              // });
              // if (this.cryptoPaymentStatus === 2) {
              //   console.log('unsubscribe', this.cryptoPaymentStatus, response.data.status);
              //   this.cryptoService.setStatus.next(this.cryptoPaymentStatus);
              //   st.unsubscribe();
              //   this.checkPaymentSubscription.unsubscribe();
              //   // this.cryptoPaymentOrderCode = response.data.order_code ? response.data.order_code : '';
              //   // this.router.navigate([response.data.url]);
              // }

              // console.log('cryptoPaymentStatus', this.cryptoPaymentStatus, response.data.status);
              if (response.data.status === 0) {
                // status: 0 - not transactions,1 - isset transaction, 2 - success, 3 - unresolved
              } else if (response.data.status === 1) {
              } else if (response.data.status === 2) {
                // window.parent.parent.location.href = this.getin_base_url + response.data.url;
                // console.log('navigate', response.data.url);
                this.cryptoService.setStatus.next(this.cryptoPaymentStatus);
                this.checkPaymentSubscription.unsubscribe();
                // console.log('navigateByUrl', response.data.url);
                this.router.navigateByUrl(response.data.url);
              } else if (response.data.status === 3) {
                this.cryptoPaymentOrderCode = response.data.order_code
                  ? response.data.order_code
                  : '';
                this.cryptoService.setStatus.next(this.cryptoPaymentStatus);
                this.checkPaymentSubscription.unsubscribe();
              }
            }
          }
        },
        errors => {
          console.log('checkCryptoPayments gas errors', errors);
        }
      );
    } else {
      console.log(
        'checkCryptoPayments not exist coinBaseData',
        this.gateway_type
      );
    }
  }

  startCheckCryptoPayments() {
    // console.log('startCheckCryptoPayments');
    // emit value every 5s
    const source = interval(10000);
    // when service emits after cryptoPaymentStatus, complete source
    // const timer$ = timer(5000);  // this.cryptoService.checkStatus
    const checkingPayments = source.pipe(
      takeUntil(this.cryptoService.checkStatus)
    );
    const stopChecking = checkingPayments;
    this.checkPaymentSubscription = checkingPayments.subscribe(val => {
      // console.log('checkStatus', val);
      this.checkCryptoPayments();
    });
  }

  checkCryptoPayment(
    coinbase_code,
    coinbase_id,
    coin_name,
    wallet_key,
    amount,
    coin_abr
  ) {
    const data = {
      coinbase_code,
      coinbase_id,
      coin_name,
      wallet_key,
      amount,
      coin_abr,
    };
    // console.log('checkCryptoPayment', data);
    this.globals.startLoader();
    this.cryptoService.checkCryptoPayment(data).subscribe(
      (response: any) => {
        // console.log('send purchase response', response);
        // send user to successful page
        if (!response.data) {
          this.dialogService.alertsModal(
            'cryptoCurrency',
            'cryptoTransaction',
            3000,
            false
          );
        } else {
          // this.dialogService.alertsModal('success', 'orderConfirmed', 2000, true);
          // console.log('navigateByUrl', response.data.url);
          this.router.navigateByUrl(response.data.url);
        }
        this.globals.stopLoader();
      },
      error => {
        this.dialogService.alertsModal(
          'landingPage',
          error.code,
          3000,
          false,
          typeof error.log_id !== 'undefined' ? error.log_id : 0
        );
        this.globals.stopLoader();
      }
    );
  }

  payWithCoinbase() {
    // console.log('payWithCoinbase', this.coinBaseData.data.hosted_url);
    this.checkIfTicketsWereSoldOut().then(() => {
      if (!this.isSelectedTicketOutOfStock) {
        this.router.navigate([]).then(result => {
          window.open(this.coinBaseData.data.hosted_url, '_blank');
        });
      }
    });
  }

  extranalApi() {}

  @HostListener('window:message', ['$event'])
  receiveMessage(event: MessageEvent) {
    if (event && event.data) {
      this.callParentFunction(event.data);
    }
  }

  // load BOA script
  loadScript() {
    const node = this.renderer.createElement('script');
    node.src = this.accept_url;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    this.renderer.appendChild(this._document.head, node);
  }

  // load STRIPE script
  loadStripe() {
    if (!window.document.getElementById('stripe-script')) {
      const node = this.renderer.createElement('script');
      node.id = 'stripe-script';
      node.type = 'text/javascript';
      node.src = this.stripe_url;
      this.renderer.appendChild(this._document.head, node);
    }
  }

  // load Hyper script
  loadHyper(isHyperProd = false) {
    if (!window.document.getElementById('hyper-script')) {
      const node = this.renderer.createElement('script');
      node.id = 'hyper-script';
      node.type = 'text/javascript';
      node.src = isHyperProd
        ? 'https://checkout.hyperswitch.io/v0/HyperLoader.js'
        : this.hyper_script_url;
      this.renderer.appendChild(this._document.head, node);
    }
  }

  loadApplePay() {
    const node = this.renderer.createElement('script');
    node.src = this.tranzila_apple_pay_url + '?v=' + Date.now() + '';
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    this.renderer.appendChild(this._document.body, node);
  }

  callParentFunction(str) {
    if (str && str.length > 0) {
      const referrer = document.referrer;
      this.onReceiveCommunication({ qstr: str, parent: referrer });
    }
  }

  onReceiveCommunication(argument) {
    const params = this.parseQueryString(argument.qstr);
    const parentFrame = argument.parent.split('/')[4];
    const frame = $('#load_payment');
    switch (params['action']) {
      case 'resizeWindow':
        frame.outerHeight(parseInt(params['height']));
        break;
      case 'successfulSave':
        location.reload();
        break;
      case 'cancel':
        this.stepTemplate = 'templateDescription';
        this.ngOnInit();
        break;
      case 'transactResponse':
        const transResponse = JSON.parse(params['response']);
        this.sendPurchaseResponse(transResponse);
        break;
    }
  }

  parseQueryString(str) {
    const vars = [];
    const arr = str.split('&');
    let pair;
    for (let i = 0; i < arr.length; i++) {
      pair = arr[i].split('=');
      vars[pair[0]] = pair[1];
    }
    return vars;
  }

  sendPurchaseResponse(data) {
    this.globals.startLoader();
    this.eventService.addPurchase(data).subscribe(
      (response: any) => {
        // console.log('send purchase response', response);
        this.globals.stopLoader();
        this.closeDialogModal();
        window.parent.parent.location.href =
          this.getin_base_url + response.data.url;
      },
      error => {
        this.dialogService.alertsModal(
          'landingPage',
          error.code,
          3000,
          false,
          typeof error.log_id !== 'undefined' ? error.log_id : 0
        );
        setTimeout(() => {
          // console.log('send purchase response error');
          this.closeDialogModal();
          window.parent.parent.location.href = this.getin_base_url;
        }, 3000);
      }
    );
  }

  createStripeElement(stripe_token, payment_details, stripeAccount?) {
    // console.log('createStripeElement', payment_details);
    const style = {
      style: {
        base: {
          iconColor: '#ffffff',
          color: '#ffffff',
          backgroundColor: '#22262F00',
          fontFamily: " Montserrat,'Helvetica Neue', Helvetica, sans-serif",
          fontSmoothing: 'antialiased',
          fontSize: '17px',
          '::placeholder': {
            color: '#C4C4C4',
          },
        },
        invalid: {
          color: '#FF6465',
          iconColor: '#FF6465',
        },
      },
    };

    const elementClasses = {
      focus: 'focus',
      empty: 'empty',
      invalid: 'invalid',
    };

    // Initialization options.
    const options = {
      stripeAccount: stripeAccount,
      locale: 'auto',
    };

    if (this.stripe == undefined) {
      if (stripeAccount) {
        this.stripe = Stripe(stripe_token, { ...options });
      } else {
        this.stripe = Stripe(stripe_token);
      }
    }
    // this.stripe = Stripe('pk_test_MtCjQhqaSXe9FCJiaEgG5XGw'); //test token pk_test_TYooMQauvdEDq54NiTphI7jx
    const elements = this.stripe.elements();

    // --------- STRIPE PAYMENT REQUEST BUTTON ------------
    // https://stripe.com/docs/stripe-js/elements/payment-request-button?platform=html-js-testing-payment-request-api
    this.card = elements.create('card', style);
    if(this.cardInfo) {
      this.card.mount(this.cardInfo.nativeElement);
    }


    const reqObj = {
      country: 'US',
      currency: payment_details.currency.toLowerCase(),
      total: {
        label: payment_details.item_details.item_name,
        amount: Math.round(Number(payment_details.payment) * 100),
      },
      requestPayerName: true,
      requestPayerEmail: true,
    };
    const paymentRequest = this.stripe.paymentRequest(reqObj);

    const prButton = elements.create('paymentRequestButton', {
      paymentRequest,
    });

    (async () => {
      // Check the availability of the Payment Request API first.
      const result = await paymentRequest.canMakePayment();
      if (result) {
        prButton.mount('#payment-request-button');
      } else {
        document.getElementById('payment-request-button').style.display =
          'none';
      }
    })();
    // --------- STRIPE PAYMENT REQUEST BUTTON END ------------

    // ---------  Complete the STRIPE BUTTON payment ------------
    //
    // Listen to the paymentmethod event to receive a PaymentMethod object.
    // Pass the PaymentMethod ID and the PaymentIntent’s client secret to stripe.confirmCardPayment to complete the payment.
    paymentRequest.on('paymentmethod', async ev => {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      // const {paymentIntent, error: confirmError} = await this.stripe.confirmCardPayment(
      //   clientSecret,
      //   {payment_method: ev.paymentMethod.id},
      //   {handleActions: false}
      // );

      console.log('paymentmethod EV obj CREATED!', ev);
      this.sendStripeResponse(
        {
          main_purchase_identifier: this.main_purchase_identifier,
          paymentMethod: ev.paymentMethod,
          saveCard: this.cardIsChecked,
        },
        ev
      );
    });

    // Complete the STRIPE BUTTON payment END

    this.card.addEventListener(
      'change',
      error => {
        this.onChange(error);
      },
      { passive: true }
    );
    // this.card.on('ready', () => {
    //   this.globals.stopLoader();
    // })
  }

  // --------INIT HYPERSWITCH IFRAME--------
  async createHyperElement(token, clientSecret) {
    // if (this.hyper == undefined) { this.hyper = Hyper(token); }
    this.hyper = Hyper(token);
    // console.log(this.hyper.widgets, token, clientSecret);
    this.hyperSubmitDisable = true;
    this.hyperSubmitHidden = true;
    await this.initializeHyperUnifiedCheckout(clientSecret);
    setTimeout(() => {
      this.hyperSubmitDisable = false;
      this.hyperSubmitHidden = false;
    }, 500);
  }

  async initializeHyperUnifiedCheckout(clientSecret) {
    // const response = await fetch("/create-payment", {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   // body: JSON.stringify({ items }),
    // });
    // const { clientSecret } = await response.json();

    const appearance = {
      theme: 'midnight', // Expected 'default', 'midnight', 'brutal', 'charcoal', 'soft', 'none'
      variables: {
        colorText: '#FFFFFF',
        colorPrimary: '#efefef',
        fontFamily: 'Montserrat,Open Sans Hebrew,sans-serif',
        colorBackground: '#39393957',
        spacingAccordionItem: '12px',
      },
      rules: {
        '.AccordionItem': {
          background: `transparent`,
        },
        '.PickerItem': {
          color: '#FFFFFF !important',
        },
        // '.Tab': {
        //   display: 'none',
        // },
      },
      // variables: {
      // colorText: "#FFFFFF",
      // colorPrimary: "#736CFF",
      // fontFamily: "Montserrat,Open Sans Hebrew,sans-serif",
      // colorBackground: "#252131", // "#252131"
      // spacingAccordionItem: "8px",
      // },
      // rules: {
      //   ".Tab": {
      //     background: `#ffffff`,
      //   },
      //   // ".Tab--selected": {
      //   //   color: `#ffffff`,
      //   // },
      //   ".Label": {
      //     color: `#ffffff`,
      //   }
      // }
    };
    const loader = 'auto'; // Can be either ‘auto’, ‘always’ or ‘never’
    const fonts = [
      {
        cssSrc:
          'https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap',
      },
    ];
    const locale = this.localeId;

    this.widgets = this.hyper.widgets({
      appearance,
      clientSecret,
      loader,
      fonts,
      locale,
    });

    const unifiedCheckoutOptions = {
      layout: 'tabs', // 'tabs' | 'accordion'
      wallets: {
        walletReturnUrl: this.hyper_return_url, //Mandatory parameter for Wallet Flows such as Googlepay, Paypal and Applepay
        style: {
          theme: 'light', //white color
          type: 'default',
          height: 50, // height should be same as all the other buttons
        },
      },
      //The walletReturnUrl is the return URL for all wallets. As the confirmPayment method handles other payment methods, wallets are displayed
      // as a button and require a return URL to redirect back to your website for payment confirmation.

    };
    console.log('unifiedCheckoutOptions', unifiedCheckoutOptions);

    const unifiedCheckout = this.widgets.create(
      'payment',
      unifiedCheckoutOptions
    );
    unifiedCheckout.mount('#unified-checkout');
    this.showHyperForm = true;
  }

  async hyper_switchSubmit(e) {
    e.preventDefault();
    this.hyperSubmitDisable = true;
    this.hyper_errors.nativeElement.innerText = '';
    // additional check for booked seats if Seats enabled
    if (this.showSeatsIo) {
      this.globals.startLoader();
      this.eventService.checkOwnerAndAvailable(this.seatsHash).subscribe(
        res => {
          this.hyper_confirmPayment(); // HYPER CONFIRM
        },
        error => {
          this.dialogService.alertsModal('errors', error.code, 5000, false);
          this.purchase_id = undefined;
          this.main_purchase_identifier = '';
          this.listSelectedObjects = {};
          this.seatsTickets = [];
          this.total_price_w_commission = '0.00';
          this.showSeatsDisplay = false;
          this.seatsHash = '';
          this.stepTemplate = 'templateDescription';
          this.showHyperForm = false;
          this.globals.stopLoader();
        }
      );
    } else {
      await this.hyper_confirmPayment(); // HYPER CONFIRM
    }
  }

  async hyper_confirmPayment() {
    this.globals.startLoader();
    // Check for Available tickets
    let checkAvailable = await this.checkAvailablePurchase();
    if (!checkAvailable) {
      this.globals.stopLoader();
      this.purchase_id = undefined;
      this.main_purchase_identifier = '';
      this.stepTemplate = 'templateDescription';
      return
    }
    // Check for Available tickets END

    const widgets = this.widgets;

    const { error, status } = await this.hyper.confirmPayment({
      widgets,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: this.hyper_return_url,
      },
      redirect: 'if_required',
    });
    this.hyperSubmitDisable = false;

    console.log('error', error);
    console.log('status', status);
    this.rudderstackAddPaymentInfo('hyper_switch');

    if (status) {
      this.hyper_handlePaymentStatus(status);
    }

    // This point will only be reached if there is an immediate error occurring while
    // confirming the payment. Otherwise, your customer will be redirected to your `return_url`
    // For some payment flows such as Sofort, iDEAL, your customer will be redirected to an
    // intermediate page to complete authorization of the payment, and then
    // redirected to the `return_url`.
    if (error) {
      // this.hyper_errors.nativeElement.innerText =
      //   'An unexpected error occurred.';
      if (error.type === 'validation_error') {
        this.hyper_errors.nativeElement.innerText = error.message;
      } else {
        this.showPurchaseBtn = false;
        this.hyperSubmitHidden = true;
        this.hyperSubmitDisable = true;
        if(error.message) {
          this.dialogService.txtMsgModal(error.message, 6000, false);
        }
        this.checkCancelPurchaseOnBackStep('templateDescription', false, false);
      }
      this.globals.stopLoader();
    } else {
      this.showPurchaseBtn = false;
      this.hyperSubmitHidden = true;
      this.hyperSubmitDisable = true;
      this.checkCancelPurchaseOnBackStep('templatePay', false, true);
    }
  }

  hyper_handlePaymentStatus(status) {
    this.globals.stopLoader();
    switch (status) {
      case 'succeeded':
        this.hyperswitchResponse();
        break;
      case 'processing':
        this.hyperswitchResponse();
        break;
      case 'requires_capture':
        this.hyperswitchResponse();
        break;
      case 'failed':
        this.hyperswitchResponse();
        break;
      case 'requires_payment_method':
        console.log('Please select a payment method to continue');
        break;
      default:
        this.defaultHyperError();
        console.log('Something went wrong.');
        break;
    }
  }

  hyperswitchResponse() {
    if (!this.main_purchase_identifier || !this.hyper_clientSecret) {
      return;
    }
    const data = {
      main_purchase_identifier: this.main_purchase_identifier,
      payment_intent_client_secret: this.hyper_clientSecret,
    };

    this.globals.startLoader();
    this.eventService.hyperswitchResponse(data).subscribe(
      (res: any) => {
        console.log('hyperswitchResponse', res);
        if (res.data && res.data.url) {
          this.router.navigateByUrl(res.data.url);
        }
        this.globals.stopLoader();
      },
      error => {
        console.log('hyperswitcheError', error);
        this.globals.stopLoader();
        this.dialogService.alertsModal(
          'errors',
          error.code,
          5000,
          false,
          error.log_id
        );
        if (error.code == 52) {
          this.purchase_id = undefined;
          this.main_purchase_identifier = '';
          this.listSelectedObjects = {};
          this.seatsTickets = [];
          this.total_price_w_commission = '0.00';
          this.showSeatsDisplay = false;
          this.seatsHash = '';
          this.stepTemplate = 'templateDescription';
        } else {
          this.router.navigate(['/dashboard']);
        }
      }
    );
  }

  defaultHyperError() {
    this.dialogService.alertsModal('errors', 2, 4000, false);
    this.globals.startLoader();
    this.eventService.cancelPurchase(this.main_purchase_identifier).subscribe(
      res => {
        this.purchase_id = undefined;
        this.main_purchase_identifier = '';
        this.stepTemplate = 'templateDescription';
        if (this.unified_checkout !== undefined) {
          this.unified_checkout.nativeElement.innerHTML = '';
        }
        this.hyperSubmitHidden = true;
        this.globals.stopLoader();
      },
      error => {
        this.main_purchase_identifier = '';
        this.globals.stopLoader();
        this.hyperSubmitHidden = true;
        if (this.unified_checkout !== undefined) {
          this.unified_checkout.nativeElement.innerHTML = '';
          this.hyper_errors.nativeElement.innerText = '';
          this.hyper_return_url = '';
        }
      }
    );
  }

  onChange(event) {
    if (event) {
      this.cardErrors = event && event.error;
      if (this.localeId !== 'en' && event.error !== undefined) {
        const hebrewErrorMsg = this.errorService.getAlertStr(
          'stripeErrors',
          event.error.code
        );
        if (hebrewErrorMsg) {
          this.cardErrors.message = hebrewErrorMsg;
        }
      }
      this.stripe_form = event;
    } else {
      this.cardErrors = null;
    }
    this.changeDetector.detectChanges();
  }

  setSaveCard(event) {
    this.cardIsChecked = event.target.checked;
    // console.log('setSaveCard', checked);
  }

  async stripeOnSubmit(form) {
    // console.log('stripeOnSubmit');
    if (!this.isClickedStripeButton) {
      this.isClickedStripeButton = true;
      const checked = await this.checkIfTicketsWereSoldOut();
      if (checked) {
        if (!this.isSelectedTicketOutOfStock) {
          const { paymentMethod, error } =
            await this.stripe.createPaymentMethod('card', this.card);
          // console.log('stripeOnSubmit', paymentMethod);
          if (!error) {
            this.sendStripeResponse({
              main_purchase_identifier: this.main_purchase_identifier,
              paymentMethod,
              saveCard: this.cardIsChecked,
            });
            this.rudderstackAddPaymentInfo('stripe_gateway');
          } else {
            // console.log('stripeOnSubmit', error);
            this.isClickedStripeButton = false;
            this.showPurchaseBtn = true;
          }
        }
      }
    }
  }

  sendStripeResponse(data, ev = null) {
    this.globals.startLoader();
    this.eventService.addStripeData(data).subscribe(
      (response: any) => {
        this.globals.stopLoader();
        this.payment_id = null;
        // console.log('stripe response', response.data.url, response);
        // console.log('navigateByUrl', response.data.url);
        this.router.navigateByUrl(response.data.url);
        if (ev) {
          ev.complete('success');
        } // browser popup
      },
      error => {
        this.globals.stopLoader();
        this.isClickedStripeButton = false;
        this.seatsHash = '';
        this.payment_id = null;
        this.showPurchaseBtn = true;
        if (ev) {
          ev.complete('fail');
        } // browser popup
        if (error.code === 52) {
          this.stepTemplate = 'templateDescription';
          this.dialogService.alertsModal('errors', 52, 5000, false);
          this.purchase_id = undefined;
          this.main_purchase_identifier = '';
          this.listSelectedObjects = {};
          this.seatsTickets = [];
          this.total_price_w_commission = '0.00';
          this.showSeatsDisplay = false;
        }
        if (error.code && error.code !== 52) {
          this.dialogService.alertsModal(
            'purchaseEvent',
            error.code,
            3000,
            false,
            typeof error.log_id !== 'undefined' ? error.log_id : 0
          );
        } else if (error.message) {
          this.dialogService.alertsModal(
            'purchaseEvent',
            2,
            3000,
            false,
            typeof error.log_id !== 'undefined' ? error.log_id : 0
          );
        }
        setTimeout(() => {
          this.closeDialogModal();
        }, 3000);
      }
    );
  }

  setFormInputs() {
    // this.form_inputs.default_inputs = [
    //   { input_name: 'full_name', input_options: [], input_type: 'text', placeholder: 'First & last name', required: '1', },
    //   { input_name: 'first_name', input_options: [], input_type: 'text', placeholder: 'שם פרטי', required: '1', },
    //   { input_name: 'last_name', input_options: [], input_type: 'text', placeholder: 'שם משפחה', required: '1', },
    //   { input_name: 'user_email', input_options: [], input_type: 'email', placeholder: 'דוא\'ל', required: '1', },
    //   { input_name: 'user_phone', input_options: [], input_type: 'phone', placeholder: 'טלפון', required: '1', },
    //   { input_name: 'birth_date', input_options: [], input_type: 'date', placeholder: 'תאריך לידה', required: '0', },
    //   { input_name: 'age', input_options: [], input_type: 'number', placeholder: 'age', required: '0', },
    //   { input_name: 'gender', input_options: [{ input_name: '1', placeholder: 'זכר' }, { input_name: '2', placeholder: 'נקבה' }], input_type: 'select', placeholder: 'מין', required: '1', },
    //   { input_name: 'identification_number', input_options: [], input_type: 'text', placeholder: 'Id number', required: '0', }
    // ]
    this.form_inputs = JSON.parse(this.marketing.form_inputs);

    this.form_inputs.default_inputs.unshift({
      input_name: 'gender',
      input_options: [
        { input_name: '1', placeholder: 'זכר' },
        { input_name: '2', placeholder: 'נקבה' },
      ],
      input_type: 'select',
      placeholder: 'מין',
      required: this.event_details.gender ? '1' : '0',
      custom_input: true,
    });
    this.form_inputs.default_inputs.unshift({
      input_name: 'identification_number',
      input_options: [],
      input_type: 'number',
      placeholder: 'id_number',
      required: '1',
      custom_input: true,
    });
    if (this.birthday_validation || this.event_details.age_display === 2) {
      this.form_inputs.default_inputs.unshift({
        input_name: 'birth_date',
        input_type: 'date',
        placeholder: 'Birth date',
        required: '1',
        custom_input: true,
      });
    }
    this.form_inputs.default_inputs.unshift({
      input_name: 'age',
      input_type: 'number',
      placeholder: 'age',
      required: this.event_details.age_display ? '1' : '0',
      custom_input: true,
    });
    this.form_inputs.default_inputs.unshift({
      input_name: 'user_email',
      input_type: 'email',
      placeholder: 'Email',
      required: '1',
      custom_input: true,
    });
    this.form_inputs.default_inputs.unshift({
      input_name: 'user_phone',
      input_type: 'phone',
      placeholder: 'Phone number',
      required: '1',
      custom_input: true,
    });
    this.form_inputs.default_inputs.unshift({
      input_name: 'full_name',
      input_type: 'text',
      placeholder: 'First & last name',
      required: '1',
      custom_input: true,
    });
    // console.log('setFormInputs', this.form_inputs );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // console.log('on resize', window.innerWidth);
    this.hasSmartBanner =
      window.innerHeight !== document.documentElement.clientHeight;
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    this.isMobile = window.innerWidth <= 768;
    this.getCover();
  }

  async ngAfterViewInit() {
    this.stepTemplate ||= 'templateDescription';
    const header = document.getElementsByTagName('header')[0];
    if (header) {
      header.style.display = 'none';
    }
    const top = this.isMobile
      ? document.querySelector('mat-drawer-content')
      : document.getElementById('templateBox');
    top ? top.scrollTo(0, 0) : false;

    this.nav.getSideNav.subscribe(sidenav => {
      this.sidenav = sidenav;
    });
    this.nav.currentUserId.subscribe(user_id => {
      this.user_id = user_id;
      this.user_info = this.authService.getUserInfo();
      this.detectIfUserIsProducer();
    });
    if (window.location.hash && window.location.hash.length > 1) {
      this.callParentFunction(window.location.hash.substring(1));
    }
    this.addStylesToElements();
    // this.scrollDispatcher.scrolled().subscribe((event: CdkScrollable) => {
    //   console.log(event.measureScrollOffset('top'));
    // });
    this.changeDetector.detectChanges();
    const user_id = await this.nav.user_id.getValue();
    this.user_id = user_id;
    this.user_info = this.authService.getUserInfo();

    // AUTO TICKETS SELECTION SUBSC
    this.event_fetched.subscribe(fetched => {
      // if event was fetched
      if (fetched && this.url_ticketsArr.length && !this.showSeatsIo) {
        this.selectTicketsFromUrlParams(); // select tickets from URL
        // deep link participants view/templateGuest
        if (this.stepTemplate === 'templateGuest') {
          // update tickets parsed from url params using the editTicket
          // this.eventTicketsService.getEditTicket.subscribe(data => {
          //   this.editTicket(
          //     data.item,
          //     data.sign,
          //     data.category,
          //     data.purchase_item
          //   );
          // });
        }
      }
      if (fetched && this.url_ticketsArr.length && this.showSeatsIo) {
        this.showSeatsDisplay = true;
        this.stepTemplate = 'templateSeats'; // switch to tickets step
      }
    });
    // AUTO TICKETS SELECTION SUBSC FOR SEATS
    this.combineLatestSubscription = combineLatest([
      this.event_fetched,
      this.seatsonSessionInitialized,
      this.seatsonChartRendered,
    ]).subscribe(([fetched, inited, rendered]) => {
      console.log('combineLatest', fetched, inited, rendered);
      if (this.url_ticketsArr.length && fetched && inited && rendered) {
        if (this.url_ticketsArr[0] === 'list') {
          return;
        } else {
          this.selectTicketsFromUrlParamsSeats(); // select tickets from URL
        }
      }
    });

  }

  setCountdownTime() {
    const timeNow = new Date();
    this.dateTimer = moment(timeNow).add(30, 'm').format('YYYY-MM-DDTHH:mm:ss');
  }
  //15 minutes timer goes to the end
  getStoppedCheckoutTimer(event) {
    if (event) {
      this.backStep(this.stepTemplate);
    }
  }

  goBack() {
    this.router.navigate(['/']);
    // this.location.back();
    // this.router.navigate([this.currentPath]);
  }

  choose() {
    // credit card input
  }

  getAgeement() {
    const path = this.customAgreements[this.event_id]
      ? this.customAgreements[this.event_id]
      : '';
    window.open(path, '_blank');
  }

  getTerms() {
    // get and show terms
    this.toggleTerms = !this.toggleTerms;
    if (this.toggleTerms) {
      this.templateTerms = this.stepTemplate;
      this.stepTemplate = 'templateTerms';
    } else {
      this.stepTemplate = this.templateTerms;
    }
    // console.log('get templateTerms', this.toggleTerms, this.stepTemplate);
  }

  getPrivacy() {
    // get and show privacy
    this.togglePrivacy = !this.togglePrivacy;
    if (this.togglePrivacy) {
      this.templatePrivacy = this.stepTemplate;
      this.stepTemplate = 'templatePrivacy';
    } else {
      this.stepTemplate = this.templatePrivacy;
    }
  }

  checkPromo(promo: any) {
    // console.log('checkPromo', promo.value, 'promoCode', this.promoCode, "item_is_reg", this.item_is_reg);
    // Removing whitespaces
    const couponCode = promo.value ? promo.value.replace(/\s/g, '') : '';
    // set promo code
    if (couponCode && couponCode.length >= 5 && couponCode.length <= 12) {
      this.globals.startLoader();
      const updateTickets = this.isActiveCoupon ? false : true;
      this.checkIfTicketsWereSoldOut(updateTickets).then(val => {
        if (this.isSelectedTicketOutOfStock) {
          this.globals.stopLoader();
          return;
        }
        // this.globals.startLoader();
        // const ticket_ids = this.findAllSelectedTickets();
        const ticket_ids = this.findAllSelectedTicketsObj();
        this.eventService
          .couponCheckout(this.event_details.event_id, couponCode, ticket_ids)
          .subscribe(
            (response: any) => {
              if (response.success) {
                this.discount = response.discount.discount;
                this.discount_type = response.discount.discount_type;
                this.couponName = response.coupon.name;
                this.couponTicketId = response.ticket;
                this.couponTicket = this.findTicketByCoupon(response.ticket);
                this.couponDiscount = response.coupon.discount;
                this.couponDiscountType = response.coupon.discount_type;
                setTimeout(() => {
                  this.couponName = '';
                }, 8000);
                this.globals.stopLoader();
                // Set the discount to sending variables
                this.used_discount_type = 1;
                this.used_discount_id = response.coupon.id;
                this.coupon_source = response.coupon.coupon_source;
                this.discount_search_key = couponCode;
                this.dialogService.alertsModal('success', 'coupon', 5000, true);

                this.discount
                  ? this.calcCouponDiscountsByDiscount()
                  : this.calcCouponDiscounts(); // if discount or if coupon

                this.calcTotalPrice(false);
                this.couponCodeSent = true; // active coupon functionality
                if (!this.item_is_reg) {
                  this.showPurchaseBtn = true;
                }
              } else if (response.error) {
                this.dialogService.alertsModal(
                  'landingPage',
                  response.error,
                  5000,
                  false
                );

                if (!this.item_is_reg) {
                  this.showPurchaseBtn = true;
                }
                this.globals.stopLoader();
              } else {
                this.dialogService.alertsModal('errors', 2, 5000, false);
                if (!this.item_is_reg) {
                  this.showPurchaseBtn = true;
                }
                this.globals.stopLoader();
              }
            },
            error => {
              if (!this.item_is_reg) {
                this.showPurchaseBtn = true;
              }
              this.globals.stopLoader();
              this.dialogService.alertsModal('errors', 2, 5000, false);
            }
          );
      });
    } else {
      if (!this.item_is_reg) {
        this.showPurchaseBtn = true;
      }
      if (couponCode.length === 0) {
        return;
      } else {
        this.dialogService.alertsModal('landingPage', 5, 5000, false);
      }
    }
  }
  // /**
  //  * @function find user selected ticket from applied coupon
  //  * @param ticket ID which we receive from entered coupon in response
  //  */
  findTicketByCoupon(coupon_ticket_id) {
    let ticket = null;
    Object.keys(this.choosedItems).forEach(cat => {
      Object.keys(this.choosedItems[cat]).forEach(id => {
        if (coupon_ticket_id.toString() == id) {
          ticket = { ...this.choosedItems[cat][id], ticket_id: id };
          //console.log("got it", ticket, id, this.choosedItems[cat][id]);
        }
      });
    });

    return ticket;
  }
  /**
   * @function find all user selected tickets for LocaleStorage
   * FOR Rudderstack case
   */
  findAllSelectedTicketsForLocaleStorage() {
    const ticket_ids = [];
    Object.keys(this.choosedItems).forEach(cat => {
      Object.keys(this.choosedItems[cat]).forEach(id => {
        if (id !== 'name') {
          // console.log('findAllSelectedTickets',this.choosedItems[cat], id)
          ticket_ids.push({
            product_id: +id,
            name: this.choosedItems[cat][id].name,
            price: this.choosedItems[cat][id].price,
            quantity: this.choosedItems[cat][id].amount,
          });
        }
      });
    });

    return ticket_ids;
  }
  /**
   * @function find all user selected tickets
   *
   */
  findAllSelectedTickets() {
    const ticket_ids = [];
    Object.keys(this.choosedItems).forEach(cat => {
      Object.keys(this.choosedItems[cat]).forEach(id => {
        if (id !== 'name') {
          ticket_ids.push(+id);
        }
      });
    });

    return ticket_ids;
  }

  /**
   * @function find all user selected tickets
   * return {ticket_id: amount}
   */
  findAllSelectedTicketsObj() {
    const ticket_ids = {};
    console.log(this.choosedItems);
    Object.keys(this.choosedItems).forEach(cat => {
      Object.keys(this.choosedItems[cat]).forEach(id => {
        // console.log('this.choosedItems[cat]',+id , ':', this.choosedItems[cat][id].amount)
        if (id !== 'name') {
          ticket_ids[+id] = this.choosedItems[cat][id].amount;
        }
      });
    });

    return ticket_ids;
  }
  /**
   * @function Recalculate discounts from the coupon
   * @param type -percents(1) or sum(3)
   */
  calcCouponDiscounts() {
    if (
      this.couponDiscount === undefined ||
      this.couponDiscountType === undefined
    )
      return;

    this.couponTicket = this.findTicketByCoupon(this.couponTicketId);

    if (this.couponDiscountType === 1) {
      //console.log("type1, id, ticket, discount", this.couponTicketId, this.couponTicket, this.couponDiscount);
      if (this.couponTicket) {
        // if checkDiscount ticket exist in user selected tickets
        //console.log(1);
        this.total_ticket_discount =
          this.couponTicket.price * (this.couponDiscount / 100);
      } else if (this.couponTicketId > 0 && !this.couponTicket) {
        // if checkDiscount ticket doesn't exist in user selected tickets
        //console.log(2);
        this.total_discount = 0;
        this.total_ticket_discount = 0;
      } else {
        //console.log(3);
        this.total_discount =
          this.total.total_price * (this.couponDiscount / 100);
      }
    } else {
      //console.log("type3, id, ticket, discount", this.couponTicketId, this.couponTicket, this.couponDiscount);
      if (this.couponTicket) {
        // if checkDiscount ticket exist in user selected tickets
        //console.log(1);
        if (
          this.couponDiscount >
          this.couponTicket.amount * this.couponTicket.price
        ) {
          // if discount greater than tickets price
          this.total_ticket_discount =
            this.couponTicket.amount * this.couponTicket.price;
        } else {
          this.total_ticket_discount = this.couponDiscount;
        }
      } else if (this.couponTicketId > 0 && !this.couponTicket) {
        // if checkDiscount ticket doesn't exist in user selected tickets
        //console.log(2);
        this.total_discount = 0;
        this.total_ticket_discount = 0;
      } else {
        //console.log(3);
        this.total_discount = this.couponDiscount;
      }
    }
  }

  /**
   * @function Recalculate discounts from the discount
   * @param type -percents(1) or sum(3)
   */
  calcCouponDiscountsByDiscount() {
    if (this.discount === 0 || !this.discount_type) return;

    let selected_tickets = this.findAllSelectedTicketsInfo();
    let total_discount_per_ticket = 0;

    if (this.discount_type === 1) {
      this.total_discount = this.total.total_price * (this.discount / 100);
      // console.log('calcCouponDiscountsByDiscount type 1 %', this.total_discount)
    } else {
      selected_tickets.forEach(t => {
        // if ticket price lower than Discount -> using t.price, else using just discount
        total_discount_per_ticket +=
          t.price - this.discount < 0 ? t.price : this.discount;
      });

      this.total_discount = total_discount_per_ticket;
      // console.log('calcCouponDiscountsByDiscount type 3 SUM', this.total_discount)
    }
  }

  // setPromo(event) {}

  onPromo(couponCode: string) {
    // Removing whitespaces
    couponCode = couponCode.replace(/\s/g, '');
    // set promo code
    // console.log('onPromo', couponCode);
    if (couponCode && couponCode.length >= 6 && couponCode.length <= 10) {
      this.globals.startLoader();
      const ticket_ids = this.findAllSelectedTickets();
      this.eventService
        .couponCheckout(this.event_details.event_id, couponCode, ticket_ids)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.couponName = response.coupon.name;
              this.couponTicketId = response.ticket;
              this.couponTicket = this.findTicketByCoupon(response.ticket);
              this.couponDiscount = response.coupon.discount;
              this.couponDiscountType = response.coupon.discount_type;
              setTimeout(() => {
                this.couponName = '';
              }, 8000);
              this.globals.stopLoader();
              // Set the discount to sending variables
              this.used_discount_type = 1;
              this.used_discount_id = response.coupon.id;
              this.coupon_source = response.coupon.coupon_source;
              this.discount_search_key = couponCode;
              this.dialogService.alertsModal('success', 'coupon', 5000, true);

              this.discount
                ? this.calcCouponDiscountsByDiscount()
                : this.calcCouponDiscounts(); // if discount or if coupon

              this.calcTotalPrice(false);
            } else if (response.error) {
              this.dialogService.alertsModal(
                'landingPage',
                response.error,
                5000,
                false
              );
              this.globals.stopLoader();
            } else {
              this.dialogService.alertsModal('errors', 2, 5000, false);
              this.globals.stopLoader();
            }
          },
          error => {
            this.globals.stopLoader();
            this.dialogService.alertsModal('errors', 2, 5000, false);
          }
        );
    }
    // else {
    //   //Not proper coupon format
    //   this.globals.stopLoader();
    //   this.dialogService.alertsModal('landingPage', 5, 5000, false);
    // }
  }

  handleEvent(event) {
    this.dialogService.alertsModal('purchaseEvent', 1, 3000, false);
    // console.log('event purchase timer', event);
    this.closeDialogModal();
    // location.reload();
  }

  onCheckBox(type: string) {
    this['checkBox' + type] = !this['checkBox' + type];
  }

  getYears() {
    const year = new Date().getFullYear();
    for (let i = 0; i < 12; i++) {
      this.months.push({
        label: i + 1,
      });
      this.years.push({
        label: year + i,
        value: parseInt(String(year + i).slice(2, 4)),
      });
    }
    // console.log('date array', this.years);
  }

  backStep(value: string) {
    const top = this.isMobile
      ? document.querySelector('mat-drawer-content')
      : document.getElementById('templateBox');
    top ? top.scrollTo(0, 0) : false;

    console.log(
      'backStep',
      value,
      this.stepTemplate,
      this.cardTemplate,
      this.choosedItems
    );
    if (value === 'templateLogin') {
      this.stepTemplate = 'templateDescription';
      return;
    }
    if (value === 'templateUserRegistration') {
      // this.isShowOtp = false;
      this.stepTemplate = 'templateTickets';
      this.showPersonalInfoComponent = true;
      this.showUserLoginComponent = false;
      // this.showPurchaseBtn = true;
      return;
    }
    if (value === 'templateLoginRegistration') {
      // this.isShowOtp = false;
      if (this.showSeatsIo) {
        this.stepTemplate = 'templateDescription';
        return;
      }
      this.stepTemplate = 'templateTickets';
      this.showUserLoginComponent = true;
      this.showPersonalInfoComponent = false;
      // this.showPurchaseBtn = false;
      this.deleteLiveCart();
      return;
    }
    if (value === 'templateOrder') {
      this.showPurchaseBtn = false;
      this.showSeatsDisplay = true;
      this.seatsPanelOpenState = false;

      if (this.seatsHash) {
        const objects = JSON.parse(this.seatsSelectedObjects);
        this.eventService
          .eventSeatsUnBook(
            this.seatsHash,
            this.event_id,
            objects,
            this.chartSeats.holdToken
          )
          .subscribe(
            res => {
              this.stepTemplate = 'templateSeats';
              this.seatsHash = '';
              this.listSelectedObjects = {};
              this.seatsTickets = [];
              this.total_price_w_commission = '0.00';
              console.log(res);
              this.globals.stopLoader();
            },
            error => {
              this.stepTemplate = 'templateSeats';
              this.globals.stopLoader();
            }
          );
      } else {
        this.stepTemplate = this.showSeatsIo
          ? 'templateSeats'
          : 'templateGuest';
      }
      return;
    }
    if (value === 'templateSeats') {
      this.showSeatsDisplay = false;
      this.stepTemplate = 'templateDescription';
      return;
    }
    if (value === 'templateSeatsTickets') {
      this.showSeatsDisplay = true;
      this.showPurchaseBtn = true;
      // this.returnTo('items_section', false);
      this.stepTemplate = 'templateSeats';
      return;
    }
    if (value === 'templateWaitedTicketsTerms') {
      this.showPurchaseBtn = true;
      this.stepTemplate = 'templateTickets';
      return;
    }
    if (value === 'templateGuest') {
      if(this.waitedTermsChecked && this.waiting_list_mode && this.hasWaitedTicket) {
        this.stepTemplate = 'templateWaitedTicketsTerms';
        return;
      }
      this.deleteLiveCart();
      this.showPurchaseBtn = false;
      if (this.categories) {
        //this.initChoosedItems(); // to set category.show to true in templateTickets (GIR-1072 issue)
        this.categories.forEach(category => {
          // this.ticketsFiltered[category.id] = this.tickets.filter(ticket => ticket.category_id.indexOf(category.id) > -1);
          // category.show = !!this.ticketsFiltered[category.id].length;
        });
      }
      if (this.showSeatsIo) {
        this.showSeatsDisplay = true;
        this.seatsPanelOpenState = false;
        this.stepTemplate = 'templateSeats';
        return;
      }
    }
    if (value === 'templateTickets') {
      this.showPurchaseBtn = false;
      if (this.showSeatsIo) {
        this.showSeatsDisplay = true;
        this.stepTemplate = 'templateSeats';
        return;
      }
      this.deleteLiveCart();
    }
    if (value === 'templateGuest' && this.isActiveCoupon) {
      // active coupon functionality
      // console.log('templateGuest with ActiveCoupon');
      // coupone_code is available only for 1 tickets from tickets or tickets_reg for now
      this.editTicket(
        this.all_tickets[0],
        'minus',
        this.all_tickets[0].category_id.length
          ? this.all_tickets[0].category_id
          : null,
        1
      );
      // this.eventTicketsService.editTicket(this.all_tickets[0], 'minus', this.all_tickets[0].category_id.length ? this.all_tickets[0].category_id : null, 1);
      this.stepTemplate = 'templateDescription';
      return;
    }
    if (this.stepTemplate === 'templatePay') {
      // console.log('need to check card methods', this.cardMethods, this.showPurchaseBtn);
      this.isShowPromo = true;
      if (this.cardMethods && this.cardMethods.length > 0) {
        this.savedCard = true;
      }
      this.isOpenWallets = this.isOpenSolanaWallets = false;
      this.walletConnectData = this.solanaConnectData = {};
      this.isCloseWallet = this.isCloseSolanaWallet = true;
      this.payment_id = null;
      this.tranzillaUrl = undefined;
      this.showStripeForm = false;
    }
    if (this.cardTemplate === 'templateCardInner') {
      this.cardTemplate = undefined;
    }
    if (this.cardTemplate === 'templateSavedCard') {
      this.cardTemplate = 'templateOrder';
    }
    if (value === 'templateWaitedTicketsTerms') {
      this.stepTemplate = 'templateTickets';
      return;
    }
    // if (value === 'templateOrder' && this.isActiveCouponValid) { // active coupon functionality
    //   this.stepTemplate = 'templateGuest';
    //   return;
    // }
    // if (value === 'templateNoValidCoupon' || value === 'templateNoValidTicketDate') { // active coupon functionality
    //   this.stepTemplate = 'templateDescription';
    //   return;
    // }
    this.stepArray.map((step, i) => {
      if (step.label === value) {
        if (value === 'templatePay') {
          // console.log(this.purchase_id, this.main_purchase_identifier, this.seatsHash, this.stepArray[i - 1].label);
          if(this.stepArray[i - 1]) {
            this.checkCancelPurchaseOnBackStep(this.stepArray[i - 1].label);
          }
        } else {
          if(this.stepArray[i - 1]) {
            this.stepTemplate = this.stepArray[i - 1].label;
          }
        }
      }
    });
    this.isSelectedTicketOutOfStock = false;
    this.showPurchaseBtn = true;
  }

  switchCardTemplate(value: string) {
    if (this.cardTemplate === 'template' + value) {
      this.cardTemplate = undefined;
      return;
    } else {
      this.cardTemplate = 'template' + value;
    }
    // console.log('templateCard CardInner', this.cardTemplate);
  }

  skipTicketsStep() {
    // active coupon functionality

    this.editTicket(
      this.all_tickets[0],
      'plus',
      this.all_tickets[0].category_id.length
        ? this.all_tickets[0].category_id
        : null,
      1
    ); // add +1 - only 1 ticket from tickets or tickets_reg
    // this.eventTicketsService.editTicket(this.all_tickets[0], 'plus', this.all_tickets[0].category_id.length ? this.all_tickets[0].category_id : null, 1); // add +1 - only 1 ticket from tickets or tickets_reg
    if (this.isInit) {
      this.getParticipantData();
    }
    if (!this.user_id) {
      this.showPersonalInfoComponent = false;
      this.showUserLoginComponent = true;
      this.stepTemplate = 'templateLoginRegistration';
    } else {
      this.stepTemplate = 'templateGuest';
    }
  }
  // GENERATE SEATS ATTENDEES FORM CONTROLS
  addSeatsTickets() {
    // Remove all formArray controls
    const control = <UntypedFormArray>(
      this.participantForm.controls['participants']
    );
    // console.log('participantForm 1', this.participantForm, control.length);
    for (let i = control.length - 1; i >= 0; i--) {
      control.removeAt(i);
    }

    this.counter = 0;
    this.choosedItems['noCat'] = {};
    const ticket = {};
    ticket['amount'] = 0;
    let amount = {};
    let groupLabels = [];

    this.seatsTickets.forEach((t, i) => {
      // console.log('seatsTickets', t);
      if (amount.hasOwnProperty(t.ticket.id)) {
        amount[t.ticket.id]++;
      } else {
        amount[t.ticket.id] = 1;
      }
      // ticket['price'] = +t.ticket.price;
      // ticket['name'] = t.ticket.name;
      this.choosedItems['noCat'][t.ticket.id] = {
        amount: amount[t.ticket.id],
        price: t.ticket.price,
        name: t.ticket.name,
      };

      let labels = { ...t.labels, ...t.parent }; // add parent.type (row or table)
      groupLabels.push(labels); // group purchase labels on tickets view
    });
    console.log('choosedItems', this.choosedItems['noCat']);
    console.log('this.seatsTickets', this.seatsTickets);
    /* Creation of form controlls */
    let setedFirstElement = false;
    let seat_ids = [];
    this.seatsTickets.forEach((t, i) => {
      seat_ids.push(t.seatId);
      let labels =
        this.purchase_type === 1 ? groupLabels : { ...t.labels, ...t.parent };
      if (
        this.purchase_type !== 1 ||
        (this.purchase_type === 1 && !setedFirstElement)
      ) {
        // console.log('CREATE', t);
        (<UntypedFormArray>this.participantForm.controls['participants']).push(
          this.createControls(t.ticket.id, null, labels, t.seatId, seat_ids)
        );
        setedFirstElement = true;
      }
    });
    // console.log('participantForm 2', this.participantForm, control.length);
    // this.seatsTickets.forEach((t, i) => {
    //   this.editTicket(t.ticket, 'plus', t.ticket.category_id.length ? t.ticket.category_id : null, 1);
    // });

    this.total = {
      total_price: this.seatsTotal_n_comission,
      total_items: this.seatsQuantity,
    };
    this.calcTotalPrice(false);
    this.showPurchaseBtn = true;

    this.stepTemplate = 'templateSeatsTickets';
  }
  // PRICE AND TOTAL CALCULATIONS
  async calcSeatsTotalPrice() {
    let selectedSeatsTickets = [];
    if (this.chartSeats == undefined) {
      console.log('chartSeats doesnt init');
      this.quantity = 0;
      this.seatsTotal_n_comission = 0;
      return;
    }
    selectedSeatsTickets = await this.chartSeats.listSelectedObjects();
    this.listSelectedObjects =
      await this.getSeatsSelectedObjectsListByCat(selectedSeatsTickets);
    // console.log('calcSeatsTotalPrice',this.listSelectedObjects, selectedSeatsTickets);
    let tickets = [];

    // generate Our Tickets Array from Seats selected objects
    console.log('selectedSeatsTickets', selectedSeatsTickets);
    selectedSeatsTickets.forEach((t, i) => {
      // console.log('t.category.pricing', t.category.pricing);
      if (!t.category.pricing) {
        console.log('Please update config and price!');
      }
      if (t.hasOwnProperty('selectedTicketType')) {
        // console.log('HAS selectedTicketType', t)
        const found = t.category.pricing.ticketTypes.find(
          ticketType => ticketType.ticketType === t.selectedTicketType
        );
        console.log(found);
        tickets.push({
          price: found.price,
          ticketType: found.ticketType,
          category: t.category.key,
          categoryLabel: t.category.label,
          labels: t.labels,
          seatId: t.id,
          parent: t.parent,
        });
      } else {
        // console.log('NO selectedTicketType', t)
        tickets.push({
          price: t.category.pricing.price,
          category: t.category.key,
          categoryLabel: t.category.label,
          labels: t.labels,
          seatId: t.id,
          parent: t.parent,
        });
      }
    });
    // console.log('tickets1',tickets);
    // Adding Ticket_id
    tickets.forEach((t, i) => {
      // console.log('Adding Ticket_id', t, i, this.seatsUserConfig.pricing);
      //  tickets[i]['ticket_id'] = t.category
      if (t.hasOwnProperty('ticketType')) {
        const foundT = this.seatsUserConfig.pricing.find(
          ticket => ticket.category === t.categoryLabel
        );
        const foundTType = foundT.ticketTypes.find(
          type => type.ticketType === t.ticketType
        );
        console.log('foundT', foundT);
        console.log('foundTType', foundTType);
        let tType = this.seatsUserConfig.pricing.find(
          type => type.ticketType === t.ticketType
        );
        // console.log('foundT',tType);
        if (foundTType === undefined) {
          console.log('Invalid Seats config. Category without ticketTypes!');
          this.chartSeats.clearSelection();
          return;
        }
        tickets[i]['ticket_id'] = foundTType.ticket_id;
      } else {
        const found = this.seatsUserConfig.pricing.find(
          ticket => ticket.category === t.categoryLabel
        );
        // console.log('found',found);
        tickets[i]['ticket_id'] = found.ticket_id;
      }
    });
    // console.log('tickets2',tickets, this.tickets);
    tickets.forEach((t, i) => {
      const foundTicket = this.tickets.find(
        ticket => ticket.id === t.ticket_id
      );
      // console.log('foundTicket',foundTicket);
      if (foundTicket === undefined) {
        this.globals.startLoader();
        // https://docs.seats.io/docs/renderer/chart-properties-chartdeselectobjects/
        this.chartSeats.deselectObjects([t.seatId], () => {
          console.log('deselection successful');
          this.globals.stopLoader();
          this.dialogService.alertsModal('seats', 1, 5000, false);
        });

        return;
      } else {
        tickets[i]['ticket'] = foundTicket;
      }
    });
    console.log('tickets3', tickets);
    // console.log('this.tickets 3',this.tickets);

    this.quantity = tickets.length;
    let total_price = 0;
    tickets.forEach((t, i) => {
      total_price = total_price + t.price;
    });
    this.seatsTotal_n_comission = total_price;
    this.seatsTickets = tickets;
    this.checkInvalidTicketsForSeats(this.seatsTickets);
    this.total = {
      total_price: this.seatsTotal_n_comission,
      total_items: this.quantity,
    };

    this.calcTotalPrice(false);
  }
  // Calculate if more than ticket.limits selected by user or check Availability
  checkInvalidTicketsForSeats(tickets) {
    let choseItems = {};
    let isInvalidTicketsCount = false;
    if (!tickets.length) {
      return;
    }
    tickets.forEach((t, i) => {
      if (t.ticket === undefined) {
        return;
      }
      choseItems[t.ticket_id] = {};
      const count = tickets.reduce(
        (acc, cur) => (cur.ticket_id === t.ticket_id ? ++acc : acc),
        0
      );
      // console.log(count, t);
      choseItems[t.ticket_id]['amount'] = count;
      // MAYBE NEED to DELETE THIS WARNING, because we have maxSelectedObjects in seats config
      if (count > t.ticket.limits.length) {
        // this.seatsTickets[i]['limitExceed'] = true;
        // this.seatsTickets[i]['limitExceedCount'] = count;
        isInvalidTicketsCount = true;
        this.dialogService.alertsModal('seats', 2, 5000, false);
        // console.log('More than LIMITs selected', t.seatId, 'count', count, 'ticket_id',  t.ticket_id, 'i', i, this.seatsTickets);
        //   return;
      } else {
        // this.seatsTickets[i]['limitExceed'] = false;
        // this.seatsTickets[i]['limitExceedCount'] = 0;
      }
    });

    // FOR CHECKOUT BTN
    const ifInvalidTicket = this.seatsTickets.some(
      t =>
        // t.limitExceed === true ||
        t.ticket.availability === 3 || t.ticket.availability === 4
    );
    ifInvalidTicket || isInvalidTicketsCount
      ? (this.seatsTicketsHaveInvalidItem = true)
      : (this.seatsTicketsHaveInvalidItem = false);

    this.choosedItemsCheckIfSoldOut = choseItems;
    // console.log('this.choosedItemsCheckIfSoldOut', this.choosedItemsCheckIfSoldOut)
  }

  tooltipTxt(ticket) {
    // console.log(ticket)
    const txt = {
      '1': {
        en: `Selected ticket sale start on ${ticket.ticket.start_date}.`,
        he: ` הכרטיס זמין לרכישה החל מתאריך ${ticket.ticket.start_date}`,
        es: `La venta de entradas seleccionadas comienza el ${ticket.ticket.start_date}.`,
      },
      '2': {
        en: 'Selected ticket sales are closed.',
        he: 'הכרטיס אינו זמין לרכישה',
        es: 'La venta de entradas seleccionadas está cerrada.',
      },
    };
    if (ticket.ticket.availability == 3) {
      return txt[1][this.localeId];
    }
    if (ticket.ticket.availability == 4) {
      return txt[2][this.localeId];
    }
    // if (ticket.limitExceed) {
    //   return `You may select only ${ticket.ticket.limits.length} tickets of this type.`
    // }

    return '';
  }

  editTicket(item, sign, category = null, purchase_item) {
    // console.log('editTicket', this.ticketLimits);
    const qty = 1;
    let quantity;
    if (sign === 'plus') {
      quantity =
        qty +
        this.ticketLimits[item.id]['choosed'][category ? category : 'noCat']
          .amount;
    } else {
      if (
        this.ticketLimits[item.id]['choosed'][category ? category : 'noCat']
          .amount > 1
      ) {
        quantity =
          -qty +
          this.ticketLimits[item.id]['choosed'][category ? category : 'noCat']
            .amount;
      } else {
        quantity = 0;
      }
    }

    if (quantity <= item.limits.length) {
      // Recalculate ticket limits
      this.ticketLimits[item.id]['choosed'][category ? category : 'noCat'] = {};
      this.ticketLimits[item.id]['choosed'][
        category ? category : 'noCat'
      ].amount = +quantity;
      let availableToChoose = this.ticketLimits[item.id]['available'];
      Object.keys(this.ticketLimits[item.id]['choosed']).forEach(
        categoryName => {
          const currentAmount =
            this.ticketLimits[item.id]['choosed'][categoryName].amount;
          this.ticketLimits[item.id]['choosed'][categoryName].options = [];
          availableToChoose -= currentAmount;
        }
      );
      this.ticketLimits[item.id]['availableToChoose'] = availableToChoose;
      Object.keys(this.ticketLimits[item.id]['choosed']).forEach(
        categoryName => {
          const currentAmount =
            this.ticketLimits[item.id]['choosed'][categoryName].amount;
          for (let i = 1; i <= availableToChoose + currentAmount; i++) {
            this.ticketLimits[item.id]['choosed'][categoryName].options.push(i);
          }
        }
      );

      if (+quantity < 1) {
        // console.log('delete items', quantity);
        delete this.choosedItems[category ? category : 'noCat'][item.id];
        // delete this.choosedItems[category ? category : 'noCat'];
      } else {
        const ticket = {};
        ticket['amount'] = +quantity;
        ticket['price'] = +item.price;
        ticket['name'] = item.name;
        // console.log('choosedItems', quantity, category, item.price, item.name, item.id, ticket, item, this.choosedItems);
        this.choosedItems[category ? category : 'noCat'][item.id] = ticket;
        this.choosedItemsCheckIfSoldOut[item.id] = ticket; // check if tickets was sold out
      }

      // Category (1 for items), (2 for rooms), (3 for tables)
      this.purchase_item = purchase_item;

      // Register item
      let foundNotReg = false;

      Object.keys(this.choosedItems).forEach(cat => {
        Object.keys(this.choosedItems[cat]).forEach(id => {
          if (id !== 'name') {
            if (this.tickets.filter(ticket => ticket.id === +id)[0].reg === 0) {
              foundNotReg = true;
            }
          }
        });
      });

      this.item_is_reg = !foundNotReg;

      // Price calculation when choosing ticket and price
      let total_price = 0;
      let total_items = 0;

      Object.keys(this.choosedItems).forEach(cat => {
        Object.keys(this.choosedItems[cat]).forEach(ticket => {
          if (ticket !== 'name') {
            total_price +=
              this.choosedItems[cat][ticket].price *
              this.choosedItems[cat][ticket].amount;
            total_items += this.choosedItems[cat][ticket].amount;
          }
        });
      });
      this.total = { total_price: total_price, total_items: total_items };

      // Item quantity
      this.quantity = this.total.total_items;
      // console.log('this.quantity', this.quantity);

      this.item_id = item.id;

      this.calcTotalPrice(false);
    }
    // console.log('choosedItems',this.item_is_reg, this.ticketLimits, this.choosedItems, this.item_id, this.quantity);
  }

  insertAndSentCoupon() {
    // active coupon functionality
    if (this.couponCodeSent == false) {
      this.promoCode = this.active_coupon.code; // set input field value

      this.checkPromo({ value: this.promoCode }); // send code
    }
  }

  insertAndSentCouponTickets() {
    // active coupon for all tickets functionality
    if (this.couponCodeSent == false) {
      this.promoCode = this.coupon_code_tickets; // set input field value

      this.checkPromo({ value: this.coupon_code_tickets }); // send code
    }
  }

  setUserData(event) {
    // console.log('setUserData', event);
    this.userData = event;
    if (this.userData.user_id !== 0) {
      this.walletConnectService.detectWeb3Provider();
    }
    this.getFormInputs();
  }

  setRealPhoneNumber(event) {
    // console.log('setRealPhoneNumber', event);
  }

  intlTelInputObject(event) {
    // console.log('intlTelInputObject', event.s.iso2, event); // .patchValue(event.s.iso2);
  }

  intlOnCountryChange(event) {
    // console.log('intlOnCountryChange', event.iso2, event); //  .patchValue(event.iso2);
  }

  updateAuthUser(data) {
    // console.log('UpdateAuthUser Info!');
    this.authService.storeTokens(
      data.response.data.auth.session,
      data.response.data.auth.refresh
    );
    this.authService.saveUserCookies(
      data.response.data.user_data,
      data.response.data.user_data.facebook_id
    );
    // this.dialogService.alertsModal('success', data.response.code, 2000, true);
    this.nav.checkUser();
    this.nav.updateUserProfileImage(data.response.data.user_data.social_img);
    let tokenData = data.response.data;
    tokenData['original'] = {
      personal_info: data.response.data.user_data.personal_info,
    };
    tokenData['original'] = { success: data.response.code };
    tokenData['original'] = { uid: data.response.data.user_data.user_id };
    tokenData['original'] = {
      facebook_id: data.response.data.user_data.facebook_id,
    };
    if (data.response.data.user_data.personal_info) {
      this.nav.UpdateUser(
        tokenData.original.uid,
        tokenData.user_data.type,
        tokenData.original.facebook_id,
        false
      );
    } else {
      console.log('Need to set personal user Info!');
      // this.dialogService.personalInfo(tokenData, true);
    }
    if (data.response.hasOwnProperty('wallet')) {
      this.authService.storeLoggedInWallet(data.response.wallet);
    }
    // if (!this.isCreateEvent) {
    //   window.location.replace(this.currenUsertUrl);
    // }
  }

  pendingStep(event) {
    // console.log('pendingStep', event);
    setTimeout(() => {
      this.switchStep(event);
    }, 200);
  }

  switchStep(step: string, gateway: any = null) {
    // Check if event protected by code
    if (this.purchase_passcode_mode) {
      this.openPasscodeModal();
      return;
    }
    const top = this.isMobile
      ? document.querySelector('mat-drawer-content')
      : document.getElementById('templateBox');
    top ? top.scrollTo(0, 0) : false;
    // console.log('item_is_reg', this.item_is_reg);
    this.user_id = this.authService.getUser();
      console.log('switchStep is: ', step, gateway, this.choosedItems);

    if (!this.user_id && this.facebook_validation !== 0) {
      this.stepTemplate = 'templateLogin';
      // console.log('get templateLogin');
      return;
    }
    if ('template' + step === 'templateTickets') {
      this.showPurchaseBtn = true;
      if (this.coupon_code.length > 1 && !this.ticketsAvailability) {
        // active coupon functionality
        this.stepTemplate = 'templateDescription';
        return;
      }
      if (this.showSeatsIo) {
        this.showSeatsDisplay = false;
        //  return
      }
      this.showSeatsDisplay = false;

      // if (this.coupon_code.length > 1 && !this.isActiveCouponValid) { // active coupon functionality
      //   this.stepTemplate = 'templateNoValidCoupon';
      //   return;
      // }
      this.stepTemplate = 'template' + step;

      this.addPixelEvent('InitiateCheckout');
    }
    if ('template' + step === 'templateGuest') {
      //  console.log("templateGuest", this.participantForm.controls['participants'].value);
      if (!this.user_id) {
        this.stepTemplate = 'templateLoginRegistration';
        this.showPersonalInfoComponent = false;
        this.showUserLoginComponent = true;
        console.log('User not authorized, get templateLoginRegistration');
        this.updateLiveCart();
        return;
      } else {
        // console.log("coupon activate", this.user_id);
        if (this.coupon_code.length > 1 && this.isActiveCoupon) {
          // this.coupons, this.active_coupon
          this.insertAndSentCoupon();
        }
        if (this.coupon_code_tickets.length > 1 && !this.hasDonateTicket) {
          this.insertAndSentCouponTickets();
        }
        this.updateLiveCart(false);
      }
      // console.log('getParticipant Data');
      if (this.isInit) {
        this.getParticipantData(); // this.isInit = false;
      }
      this.showPurchaseBtn = false;
      this.stepTemplate = 'template' + step;

      // console.log('Add pixel event - AddToCart in templateGuest');
      this.addPixelEvent('AddToCart');
    }
    if ('template' + step === 'templateLoginRegistration') {
      this.showPersonalInfoComponent = false;
      this.showUserLoginComponent = true;
      this.showPurchaseBtn = false;
      this.updateLiveCart();
      this.stepTemplate = 'template' + step;
      // console.log('set template templateLoginRegistration');
    }
    if ('template' + step === 'templateUserRegistration') {
      this.showPersonalInfoComponent = true;
      this.showUserLoginComponent = false;
      this.showPurchaseBtn = false;
      this.updateLiveCart();
      this.stepTemplate = 'template' + step;
      // console.log('set template templateUserRegistration');
    }
    if ('template' + step === 'templateSeats') {
      // Chart template
      // Prevent user choose more than attendees quantity
      // this.chartSeats.changeConfig({
      //   maxSelectedObjects: this.quantity
      // });

      // // If user goes back Clear selection
      // if (this.chartSeats.selectedObjects.length > this.quantity) {
      //      this.chartSeats.clearSelection();
      // }
      //  console.log("templateGuest", this.participantForm.controls['participants'].value);
      if (this.seatsRendering) {
        console.log('Chart is loading please wait!');
        return;
      }
      this.showSelected(); // fetch listSelectedObjects
      this.calcSeatsTotalPrice(); // Refresh price
      this.showSeatsDisplay = true;
      this.seatsPanelOpenState = false;
      this.stepTemplate = 'template' + step;
    }

    if ('template' + step === 'templateSeatsTickets') {
      // Seats hidden tickets template
      console.log(
        'templateSeatsTickets',
        this.seatsQuantity,
        this.seatsTotal_n_comission
      );
      if (!this.seatsQuantity || !this.seatsTotal_n_comission) {
        return;
      }
      this.showPurchaseBtn = true;
      this.showSeatsDisplay = false;
      const updateTickets =
        this.coupon_code.length > 1 && this.isActiveCoupon ? false : true;

      if (!this.user_id) {
        // show login after ticket selection
        this.stepTemplate = 'templateLoginRegistration';
        this.showPersonalInfoComponent = false;
        this.showUserLoginComponent = true;
        return;
      }

      this.checkIfTicketsWereSoldOut(updateTickets).then(res => {
        // CHECK SOLD OUT SEATS
        console.log(
          this.choosedItemsCheckIfSoldOut,
          'this.isSelectedTicketOutOfStock',
          this.isSelectedTicketOutOfStock
        );
        if (this.isSelectedTicketOutOfStock) {
          this.globals.stopLoader();
          this.stepTemplate = 'templateDescription';
          this.dialogService.alertsModal(
            'seats',
            'ticketsSoldOut',
            5000,
            false
          );
          return;
        } else {
          this.globals.stopLoader();
          this.addSeatsTickets();
          if (this.coupon_code.length > 1 && this.isActiveCoupon) {
            this.insertAndSentCoupon(); // active coupon functionality
          }
          if (this.coupon_code_tickets.length > 1) {
            this.insertAndSentCouponTickets();
          }
          this.stepTemplate = 'templateGuest';
        }
      });
    }

    if ('template' + step === 'templateOrder') {
      // console.log('participants: ', this.participantForm.controls.participants);
      if (this.participantForm.valid) {
        // console.log('valid form');
        this.invalidEmailsError = []; // Invalid Emails box under Summary
        if (this.showSeatsIo && !this.item_is_reg) {
          // only for regular tickets

          if (this.seatsHash) {
            this.stepTemplate = 'template' + step;
            this.showSeatsDisplay = false;
            this.showPurchaseBtn = true;
            return;
          }

          if (this.chartSeats.selectedObjects.length) {
            this.globals.startLoader();
            this.eventSeatsBook().subscribe(
              (res: any) => {
                console.log(res);
                this.setCountdownTime();
                if ((res.code = 'success')) {
                  this.stepTemplate = 'template' + step;
                  this.showSeatsDisplay = false;
                  this.showPurchaseBtn = true;
                  this.seatsHash = res.data.hash;

                  // if we have only one gateway and do not have saved card - go to payment form
                  // SEATS events only 1 gateway
                  if (this.paymentGateways.length == 1) {
                    const gateway = this.paymentGateways[0].name;
                    if (this.coupon_code.length > 1 && this.isActiveCoupon) {
                      this.insertAndSentCoupon(); // active coupon functionality
                    }
                    if (gateway === 'Stripe' && this.savedCard) {
                      this.globals.stopLoader();
                      return;
                    }
                    this.switchStep('Pay', this.paymentGateways[0]);
                    this.globals.stopLoader();
                    return;
                  }
                  // SEATS events only 1 gateway END
                }
                this.globals.stopLoader();
                return;
              },
              error => {
                console.log(error);
                this.globals.stopLoader();
                return;
              }
            );
          } else {
            this.dialogService.alertsModal('purchaseEvent', 3, 5000, false);
          }
        } else {
          if (this.item_is_reg) {
            // console.log('reg form');
            this.checkIfTicketsWereSoldOut().then(() => {
              // console.log('checkIfTicketsWereSoldOut', !this.isSelectedTicketOutOfStock);
              if (!this.isSelectedTicketOutOfStock) {
                this.toPayment();
                this.showPurchaseBtn = false;
              } else {
                this.showPurchaseBtn = true;
              }
            });
            // console.log('go toPayment', this.item_is_reg, 'template' + step);
            // this.stepTemplate = 'template' + step;
            // this.setCountdownTime();
          } else {
            // console.log('go to next step template', step);
            this.stepTemplate = 'template' + step;
            this.setCountdownTime();
            this.showPurchaseBtn = true;
            // console.log('Payment gateways: ', this.paymentGateways, 'and card is ', this.savedCard);
            // if we have only one gateway and do not have saved card - go to payment form
            if (this.paymentGateways.length == 1) {
              const gateway = this.paymentGateways[0].name;
              // console.log('gateway', gateway, this.savedCard);
              if (gateway === 'Stripe' && this.savedCard) {
                console.log(
                  'We have a few types of payment',
                  gateway,
                  'and Saved card'
                );
                return;
              }
              this.switchStep('Pay', this.paymentGateways[0]);
              return;
            }
          }
        }

        if (this.coupon_code.length > 1 && this.isActiveCoupon) {
          this.insertAndSentCoupon(); // active coupon functionality
        }

        this.updateLiveCart();
      } else {
        // not valid form
        console.log('not valid form');
      }


      this.addPixelEvent('Lead');

      this.hyperSubmitDisable = true;
    }
    if ('template' + step === 'templateCard') {
      this.stepTemplate = 'template' + step;
      // console.log('templateCard -> ', this.stepTemplate);
    }
    if ('template' + step === 'templateSavedCard') {
      this.stepTemplate = 'template' + step;
      this.toPayment();
      // console.log('templateSavedCard -> ', this.stepTemplate);
    }
    if ('template' + step === 'templatePay') {
      this.isShowPromo = false;
      // console.log('checkBoxAgree', this.checkBoxAgree);
      if (this.checkBoxAgree && this.checkBoxCustomEvent) {
        if (this.item_is_reg) {
        } else {
          this.stepTemplate = 'template' + step;
        }
        if (gateway) {
          this.setGatewayType(gateway);
          gateway.payment_type === 'walletconnect'
            ? (this.isCloseWallet = false)
            : false;
          gateway.payment_type === 'solana'
            ? (this.isCloseSolanaWallet = false)
            : false;
        }
        this.savedCard = false;

        if (this.hyper_errors !== undefined) {
          this.hyper_errors.nativeElement.innerText = '';
        }

        this.checkIfTicketsWereSoldOut().then(() => {
          if (!this.isSelectedTicketOutOfStock) {
            this.toPayment();
          }
        });
        if (this.cardTemplate === 'templateCardInner') {
          this.cardTemplate = undefined;
        }
      } else {
        this.radioBtn = false;
        // not agree with terms
        // console.log('not agree with terms');
        this.dialogService.alertsModal('errors', '16', 3000, false);
      }
      // console.log('Go to Purchase Form' , this.checkBoxAgree);
      // console.log('Add pixel event - AddPaymentInfo in templatePay');
      this.addPixelEvent('AddPaymentInfo');
    }
    if ('template' + step === 'templateWaitedTicketsTerms') {
      this.stepTemplate = 'template' + step;
      this.updateLiveCart();
    }

    // console.log('switch Step', this.templateGuest, step, this.stepTemplate, this.cardTemplate, this.quantity);
  }

  setSeatsObjects() {
    // console.log('this.seatsSelectedObjects b', this.seatsSelectedObjects)
    this.seatsSelectedObjects = JSON.stringify(this.chartSeats.selectedObjects);
    // console.log('this.seatsSelectedObjects A', this.seatsSelectedObjects)
  }

  async showSelected() {
    if (this.chartSeats !== undefined) {
      this.listSelectedObjects = await this.getSeatsSelectedObjectsListByCat();
    }
  }

  async deselectObjects(id) {
    await this.chartSeats.deselectObjects([id]);
  }

  async bestSeats(number) {
    if (!this.seatsHoldToken.length) {
      console.log('Please start session');
      return;
    }
    if (!this.chartSeats.holdToken) {
      console.log('No active chart!');
      return;
    }
    const listCategories = await this.chartSeats.listCategories();
    let categories = [];
    listCategories.forEach(cat => {
      if (cat.pricing) {
        categories.push('' + cat.key);
      }
    });
    this.globals.startLoader();
    this.eventService
      .eventSeatsBestAvailable(
        this.event_id,
        number,
        categories,
        this.seatsHoldToken
      )
      .subscribe(
        res => {
          console.log(res);
          this.globals.stopLoader();
        },
        error => {
          console.log(error);
          this.globals.stopLoader();
        }
      );
  }
  async getSeatsCats() {
    let listtt = await this.chartSeats.listSelectedObjects();
    console.log('getSeatsCats', this.seatsConfig, listtt);
    let report = await this.chartSeats.getReportBySelectability();
    console.log('getReportBySelectability', report);
    // const cats = await this.chartSeats.listCategories();
    this.chartSeats.listCategories().then(categories => {
      console.log(categories);
      let categoryKeys = categories.map(c => c.key);
    });
    // console.log(cats);
  }

  // Fetch Seats Selected Tickets
  async getSeatsSelectedObjectsListByCat(objects = null) {
    let listSelected;
    if (objects) {
      listSelected = objects;
    } else {
      listSelected = await this.chartSeats.listSelectedObjects();
    }
    const groupArrayObject = listSelected.reduce((group, arr) => {
      // console.log(group, arr, arr.category.label);
      group[arr.category.label] = group[arr.category.label] ?? [];
      group[arr.category.label].push(arr);
      return group;
    }, {});
    // console.log(groupArrayObject);
    return groupArrayObject;
  }

  getPixelContent() {
    const content = this.total; // this.calcTotalItemsAndPrice(); //  { 'total_price': total_price, 'total_items': total_items }
    const clearedTitle = this.event_details.title.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]|['"]/gu, ''); // Remove quotes and double quotes from event name because we have error
    this.pixel_content = '';
    if (content && content.total_price && content.total_items) {
      this.pixel_content = `'currency':'${
        this.event_details.currency
      }','event_name':'${clearedTitle}','event_categories':'${
        this.event_categories ? this.event_categories : 'Festivals'
      }','page_path':'${this.event_details.event_id}','num_items':'${
        content && content.total_items ? content.total_items : ''
      }','value':'${
        content && content.total_price
          ? formatNumber(content.total_price, 'en', '1.2-2')
          : ''
      }'`;
    } else {
      this.pixel_content = `'currency':'${
        this.event_details.currency
      }','event_name':'${clearedTitle}','event_categories':'${
        this.event_categories ? this.event_categories : 'Festivals'
      }','page_path':'${this.event_details.event_id}'`;
    }
    const pixelContent = this.pixel_content;
    // console.log('getPixelContent: ',  this.pixel_content, '\n Content: ', content);
    return pixelContent;
  }

  addPixelEvent(pixelAction) {
    const pixelContent = this.getPixelContent();
    // console.log('Add pixel event: ', pixelAction, '\n pixelContent: ',  pixelContent);
    if (
      this.marketing.facebook_pixels &&
      this.marketing.facebook_pixels.length
    ) {
      this.event_pixels = [];
      this.event_pixel = null;
      // console.log('Pixel events ', this.marketing.facebook_pixels);
      this.marketing.facebook_pixels.forEach(val => {
        if (pixelAction === val.type_name) {
          this.event_pixel = +val.pixel_id;
          this.event_pixels.includes(+val.pixel_id)
            ? false
            : this.event_pixels.push(+val.pixel_id);
          // console.log('Event Page: ',this.event_pixels, this.event_pixel, pixelAction, this.currency, +this.total_price_n_commission || 0);
          this.fb_pixel_model.setPixel(
            this.renderer,
            this.event_pixels,
            false,
            this.currency,
            +this.total_price_n_commission || 0,
            pixelAction,
            pixelContent
          );
        }
      });
      // For compatibility with previous changes
      if (!this.event_pixel) {
        this.event_pixels = [];
        this.marketing.facebook_pixels.forEach(val => {
          this.event_pixels.includes(+val.pixel_id)
            ? false
            : this.event_pixels.push(+val.pixel_id);
          this.event_pixel = this.marketing.facebook_pixels[0].pixel_id;
        });
        this.fb_pixel_model.setPixel(
          this.renderer,
          this.event_pixels,
          false,
          this.currency,
          +this.total_price_n_commission || 0,
          pixelAction,
          pixelContent
        );
        // console.log('addPixelEvent alternative', this.event_pixel, this.event_pixels, pixelAction);
      }
    } else {
      // add Getin Pixel when event fired
      this.fb_pixel_model.setGetinPixel(
        this.renderer,
        false,
        this.currency,
        +this.total_price_n_commission || 0,
        pixelAction,
        pixelContent
      );
    }
  }

  setCurrentLng() {
    this.currentLngFlag = this.languages.find(lng => lng.lang == this.localId);
  }

  toggleBoxLng() {
    this.showlngBox = !this.showlngBox;
  }

  setNewLanguage(lng) {
    this.setCurrentRoute();
    if (this.localeId !== lng) {
      this.globals.changeLang(lng);
      window.location.replace('/' + lng + this.currentRoute);
    }
  }

  ngOnInit() {
    this.nav.setIsNewUi(true);
    // this.nav.noPadding = 'no-padding';
    setTimeout(() => {
      this.nav.noPadding = 'removePadding';
    }, 200);
    this.isAdmin = this.authService.getType() === 2;
    this.formInputsOthers = this.globals.formInputsOthers;
    this.user_id = this.authService.getUser();
    this.categories && this.initChoosedItems();
    this.tickets && this.initChoosedTickets();

    this.localeId = this.globals.getLocaleId();
    this.languages = this.globals.getCountryData();
    this.dir = this.globals.getDir();

    this.langsArray = [];
    this.langsArray.push('/');
    for (const flagObj of this.languages) {
      this.langsArray.push(flagObj.lang);
    }
    this.setCurrentLng();
    // console.log('languages', this.languages, this.langsArray);
    this.setCurrentRoute();
    this.activatedRoute.queryParams.subscribe(params => {
      // console.log('queryParams event page', params);
      // When we get this query from feed we want to give it back to show only relevent producer events
      if (params['producer']) {
        this.producer_id = +params['producer'];
        this.customParams['producer'] = this.producer_id;
      }
      if (params['seller_code']) {
        this.seller_code = params['seller_code'];
      }
      if (params['coupon_code']) {
        this.coupon_code = params['coupon_code'];
      }
      if (params['coupon_code_tickets']) {
        this.coupon_code_tickets = params['coupon_code_tickets'];
      }
      if (params['user_id']) {
        this.ext_user_id = params['user_id'];
      }
      if (this.event) {
        this.external_identifier = this.event;
      }
      if (params['external_token']) {
        this.external_token = params['external_token'];
      }
      if (params['email']) {
        this.email = params['email'];
      }
      if (params['cf']) {
        this.sub_purchaser_id = params['cf'];
      }
      const tickets = params['ticket'] || params['tickets'] || params['tix'];
      if (tickets) {
        if (typeof tickets === 'string') {
          // 1 tickets
          this.url_ticketsArr = [tickets];
        } else {
          // multi tickets
          this.url_ticketsArr = tickets;
        }
        // console.log(this.url_ticketsArr);
      }

      this.cfReturnFlag = params['cfReturnFlag'] ? true : false;

      if (this.sub_purchaser_id > 0 && !this.cfReturnFlag) {
        this.toggleFacebook = true;
      }
      if (params.action === 'change_facebook_user') {
        const extraData = this.router.url.match(/\?/)
          ? '&togglePurchaseModal=true'
          : '?togglePurchaseModal=true';
        this.facebookService.getFBAccessCode(extraData);
      }
      if (params['quick_purchase'] === 'true') {
        this.quickPurchase = true;
      }
      // When we get this param we want to give it back and hide some elements on page
      if (params['nav'] === 'false') {
        this.feed_nav = false;
        this.customParams['nav'] = this.feed_nav;
      }

      if (Object.keys(params).length > 0) {
        this.cfReturnStr = '&';
      } else {
        this.cfReturnStr = '?';
      }
      this.currentFacebookLoginDataSubscription =
        this.authService.getCurrentFacebookLoginData.subscribe(
          (facebook: any) => {
            this.clicked = false;
            if (!facebook.error && facebook.data && facebook.data.user_id) {
              if (facebook.data.validate && facebook.data.facebook_id) {
                this.togglePurchaseModal = true;
              }
              this.user_info = facebook.data;
              this.facebook_link = facebook.data.user_link;
              this.facebook_id = facebook.data.facebook_id;
              if (params.cf) {
              } else {
                if (params.togglePurchaseModal !== undefined) {
                  this.togglePurchaseModal = params.togglePurchaseModal;
                }
                if (this.quickPurchase) {
                  this.togglePurchaseModal = true;
                }
              }
              // console.log('facebook login data', this.togglePurchaseModal, facebook.data);
            } else if (facebook.error) {
              this.dialogService.alertsModal(
                'errors',
                facebook.errorCode,
                3000,
                false
              );
            }
          }
        );
    });
    setTimeout(() => {
      if (!this.globals.inApp()) {
        // When safari application smart bar is on we changing css of the button
        this.hasSmartBanner =
          window.innerHeight !== document.documentElement.clientHeight;
      }
    }, 500);
    const decoded: any = window.location.href;
    this.activatedRoute.params.subscribe(params => {
      // console.log('params', params);
      if (decodeURIComponent(params['event'])) {
        const lastSlash = decodeURIComponent(params['event']).lastIndexOf('/');
        this.event = decodeURIComponent(params['event']).substring(
          lastSlash + 1
        );
      }
      // clean encoded url (if encoded):
      if (window.location.href !== decodeURIComponent(window.location.href)) {
        const decoded: any = decodeURIComponent(window.location.href);
        if (this.event.indexOf('?') > 0) {
          const clean_url = this.event.substring(0, this.event.indexOf('?'));
          this.event = clean_url;
        }
      }
    });

    this.activatedRoute.data.subscribe(data => {
      // Set step template (purachse wizard step ViewChild) provided by router data params
      if (data['step']) {
        this.stepTemplate = `template${data['step']}`;
      }
    });

    if (this.event) {
      this.getEvent();
    }

    if (this.ext_user_id) {
      if (!this.user_id) {
        this.loginExternalUser();

        // a different external user is logged in - log him out, then log new external user in.
      } else if (parseInt(this.user_id) != this.ext_user_id) {
        this._cookieService.delete('token', '/');
        this._cookieService.delete('user_info', '/');
        this._cookieService.set('facebook_id', '0');
        this.authService.removeReaderApiKeyAndId();
        this._cookieService.delete('ext_user', '/');
        this.nav.updateUserProfileImage('');
        // log in new user and load custom feed
        this.loginExternalUser();
      }
    }

    // console.log('user', this.user_id, this.quickPurchase, this.sub_purchaser_id, this.email_validation, this.facebook_validation, this.togglePurchaseModal)
  }

  initChoosedItems() {
    // console.log('initChoosedItems', this.ticketLimits, this.choosedItems);
    this.totalTicketsInCategories = 0;
    this.totalTicketsWithoutCategories = 0;
    this.categories.forEach((category, index) => {
      this.ticketsFiltered[category.id] = this.tickets.filter(
        ticket => {
          if(ticket.category_id.indexOf(category.id) > -1) {
            this.totalTicketsInCategories++
            return true
          } else {
            return false
          }
        }
      );
      category.show = !!this.ticketsFiltered[category.id].length;
      this.showCategory[index] = false;
      this.choosedItems[category.id] = { name: category.name };
      this.ticketsFiltered[category.id].sort((a, b) => {
        return a.sort - b.sort;
      });

      // Donation tickets
      // Set tickets price as donation_min or ''
      // this.ticketsFiltered[category.id] = this.ticketsFiltered[category.id].map(t=> {
      //   console.log(t)
      //   if (t.donation_type) {
      //     let ticket = t;
      //     ticket.price = t.donation_min;
      //     return ticket
      //   } else {
      //     return t
      //   }
      // })
    });

    this.withoutCategory = this.tickets.filter(ticket => !ticket.category_id);
    this.withoutCategory.sort((a, b) => {
      return a.sort - b.sort;
    });
    this.categories.sort((a, b) => {
      return a.sort - b.sort;
    });

    // Variant 1 - expand/collapse categories with first active ticket
    // For collapsable categories
    // Detect if category has at least 1 available ticket and show it
    let hasAvailabilityTicket = false;
    this.categories.forEach((category, i) => {
      this.ticketsFiltered[category.id].forEach(ticket => {
        if(ticket.availability == 1 && !hasAvailabilityTicket && ticket.category_id.indexOf(category.id) > -1) {

          hasAvailabilityTicket = true;
          let cat_regexp = '\\b'+category.id+'\\b';
          let isCatExist = ticket.category_id.match(cat_regexp);
          if(isCatExist) {
            this.showCategory[i] = true;
          }
        }
      })
    })
    // Variant 1 - END
    this.totalTicketsWithoutCategories = this.withoutCategory.length;
    // Variant 2 - expand/collapse categories with active tickets inside it
    // const totalTickets = this.totalTicketsInCategories + this.totalTicketsWithoutCategories;
    // // console.log('this.totalTicketsWithoutCategories', this.totalTicketsWithoutCategories)
    // // console.log('totalTicketsInCategories', this.totalTicketsInCategories)
    // this.categories.forEach((category, index) => {
    //   if (totalTickets > 4) {
    //     this.ticketsFiltered[category.id].forEach(ticket => {
    //       if (ticket.availability == 1 && !hasAvailabilityTicket && ticket.category_id.indexOf(category.id) > -1) {
    //         hasAvailabilityTicket = true;
    //         let indexReal = this.categories.findIndex(e => e.id == ticket.category_id)
    //         if (indexReal >= 0) {
    //           this.showCategory[indexReal] = true;
    //         }
    //       }
    //     })
    //   } else {
    //     this.showCategory[index] = true;
    //   }
    // })
    // Variant 2 - END
    // END For collapsable categories

    // Donation tickets
    // Set tickets price as donation_min or ''
    // this.withoutCategory = this.withoutCategory.map(t=> {
    //   if (t.donation_type) {
    //     let ticket = t;
    //     ticket.price = t.donation_min;
    //     return ticket
    //   } else {
    //     return t
    //   }
    // })

    // console.log('For sort', this.ticketsFiltered, this.withoutCategory, this.categories);

    if (this.withoutCategory.length && this.stepTemplate !== 'templateOrder') {
      this.choosedItems['noCat'] = {};
    }
    // console.log('ChoosedItems: ', this.categories, this.ticketsFiltered, this.tickets, this.showCategory, this.choosedItems, this.withoutCategory, this.stepTemplate);
  }

  initChoosedTickets() {
    // console.log('initChoosedTickets', this.ticketLimits, this.choosedItems);
    // this.ticketLimits = await this.eventTicketsService.initChoosedTickets(this.tickets, this.categories, this.ticketLimits);
    this.tickets.forEach(ticket => {
      this.ticketLimits[ticket.id] = {
        available: ticket.limits[ticket.limits.length - 1],
        choosed: {},
        availableToChoose: ticket.limits[ticket.limits.length - 1],
        options: ticket.limits,
      };
    });

    if (this.categories) {
      this.categories.forEach(category => {
        Object.keys(this.ticketLimits).forEach(ticket => {
          this.ticketLimits[ticket]['choosed'][category.id] = {};
          this.ticketLimits[ticket]['choosed'][category.id].amount = 0;
          this.ticketLimits[ticket]['choosed'][category.id].options =
            this.ticketLimits[ticket].options;
        });
      });
    }

    Object.keys(this.ticketLimits).forEach(ticket => {
      this.ticketLimits[ticket]['choosed']['noCat'] = {};
      this.ticketLimits[ticket]['choosed']['noCat'].amount = 0;
      this.ticketLimits[ticket]['choosed']['noCat'].options =
        this.ticketLimits[ticket].options;
    });
    // console.log('ChoosedTickets: ', this.ticketLimits);
  }

  categoryStepChoise() {
    // console.log('categoryStepChoise');
    // this.title, this.purchase_item = await this.eventTicketsService.categoryStepChoise(this.tickets, this.tickets_reg, this.tables, this.tables_reg, this.rooms, this.rooms_reg);
    // Check what step should set up
    if (
      (this.tickets || this.tickets_reg) &&
      this.tables &&
      this.tables.length === 0 &&
      this.tables_reg &&
      this.tables_reg.length === 0 &&
      this.rooms &&
      this.rooms.length === 0 &&
      this.rooms_reg &&
      this.rooms_reg.length === 0
    ) {
      // this.step = 'items_section';
      this.title = 'ticket';
      this.purchase_item = 1;
      // this.noCategory = true;
    } else if (
      (this.tables || this.tables_reg) &&
      this.tickets &&
      this.tickets.length === 0 &&
      this.tickets_reg &&
      this.tickets_reg.length === 0 &&
      this.rooms &&
      this.rooms.length === 0 &&
      this.rooms_reg &&
      this.rooms_reg.length === 0
    ) {
      // this.step = 'items_section';
      this.title = 'table';
      this.purchase_item = 3;
      // this.noCategory = true;
    } else if (
      (this.rooms || this.rooms_reg) &&
      this.tickets &&
      this.tickets.length === 0 &&
      this.tickets_reg &&
      this.tickets_reg.length === 0 &&
      this.tables &&
      this.tables.length === 0 &&
      this.tables_reg &&
      this.tables_reg.length === 0
    ) {
      // this.step = 'items_section';
      this.title = 'room';
      this.purchase_item = 2;
      // this.noCategory = true;
    } else {
      // this.step = 'category_step';
    }
    // console.log('StepChoise: ', this.title, this.purchase_item);
  }

  // SET waiting_list_mode
  detectWaitedListMode() {
    if (!this.tickets.length || !this.waiting_list_gateway_features) {return}
    let waitedTicketsQty = 0;
    this.tickets.forEach(t => {
      if (t.waiting_list && t.reg == 0 && t.availability !== 1) {
        this.waiting_list_mode = true;
      }

      // Detect waiting list regular and free items
      if (
        t.waiting_list && ((t.reg == 0 && t.availability !== 1) || (t.reg == 1))
      ) {
        waitedTicketsQty = waitedTicketsQty + 1;
        this.isAtLeastOneTicketFromWaitingList = true;
      }
      // Detect Available to purchase Regular Ticket
      if(t.reg == 0 && t.availability == 1) {
        this.isAtLeastOneAvailableRegularTicket = true;
      }
    })

    if (this.tickets.length == waitedTicketsQty) {
      this.isAllTicketsFromWaitingList = true;
    }
    // console.log('isAllTicketsFromWaitingList', this.isAllTicketsFromWaitingList);
    // console.log('isAtLeastOneTicketFromWaitingList', this.isAtLeastOneTicketFromWaitingList);
    // console.log('isAtLeastOneAvailableRegularTicket', this.isAtLeastOneAvailableRegularTicket);
    console.log('waiting_list_mode', this.waiting_list_mode)
  }

  processActionsBasedOnQueryParams() {
    this.route.queryParams.subscribe(params => {
      if (params.action === 'change_facebook_user') {
        this.facebookService.getFBAccessCode();
      }
    });
  }

  getProducerData() {
    this.authService
      .getProducerDetails(this.producer_id)
      .subscribe(response => {
        // if (Object.keys(response).length === 0) {
        //   this.router.navigate(['/']);
        // }
        if (
          this.producer_id.toString() === this.user_id.toString() ||
          this.isAdmin
        ) {
          this.showEventEdit = true;
        } else {
          this.showEventEdit = false;
        }
        this.detectIfUserIsProducer();
        this.isShowBtn = true;
        this.producerDetails = response;
        this.producerImage = this.nav.getProfileImageLink(
          this.producerDetails.social_img
        ).imageLink;
        this.producerName = this.producerDetails.producer_name;
        // this.producerImage = this.nav.getProfileImageLink(this.producerDetails.profile_img, this.producerDetails.facebook_id).imageLink;
        // this.producerCoverPhoto = this.producerDetails.background_image_url ? this.producerDetails.background_image_url : this.producerCoverPhotoDefault;
        // console.log('producerImage', this.producerImage);
        if (this.producerDetails) {
          this.producerDetails['event_title'] = this.event_details.title;
          this.producerDetails['event_id'] = this.event_details.event_id;
          this.producerDetails['producer_cover'] = this.coverPreview
            ? this.coverPreview.changingThisBreaksApplicationSecurity
            : '/assets/images/svg-icons/logo-new.svg';
          // console.log('send producer details', this.event_details, this.coverPreview, this.producerDetails);
        }
        this.showProducerSubscription = this.isShowProducerSubscription();
      });
  }

  editEvent() {
    // console.log('edit event', this.event_id);
    this.router.navigate(['/edit-event/' + this.event_id]);
  }

  editProducer() {
    this.router.navigate(['/producer/' + this.producer_id]);
    // this.router.navigate(['/producer-info']);
    // if (this.dialogRef && this.dialogRef.id) { this.dialogRef.close(); }
    // this.edit_producer = true;
    // console.log('edit producer');
  }

  returnTo(category: string, init = true) {
    console.log('Delete all info about coupons');
    if (category === 'items_section') {
      // Remove formArray controls
      const arr = <UntypedFormArray>this.participantForm.controls.participants;
      for (let x = 0; x < this.quantity; x++) {
        // We always delete first form array
        arr.removeAt(0);
      }

      // Reset all the variables
      // this.checkboxArr = {};
      this.checkBoxSave = false;
      this.checkBoxAgree = false;
      this.item_id = null;
      this.counter = 0;
      this.quantity = 0;
      this.formSubmited = false;
      this.toggleParticipant = 1;
      this.toggleItem = 0;
      this.isInit = true;
      // this.initChoosedItems();
      if (init) {
        this.ngOnInit();
      }
      // Delete all info about coupons
      this.refreshCouponData();
    }
    // this.step = category;
  }

  refreshCouponData() {
    this.total_discount = 0;
    this.total_ticket_discount = 0;
    this.couponModal = false;
    this.couponCheckBox = false;
    this.couponCheckBoxPeper = false;
    this.couponName = '';
    this.used_discount_type = undefined;
    this.used_discount_id = undefined;
    this.item_is_reg = undefined;
    this.selectOptionCouponValue = undefined;
    this.freeItem = undefined;
  }

  coupon(checked: boolean, type = '') {
    if (this.event_details.event_id === 53684) {
      this.couponCheckBoxPeper = type === 'peper';
      this.couponCheckBox = type === 'regular';
      if (this.couponCoderegular !== undefined) {
        this.couponCoderegular.nativeElement.value = '';
      }
      if (this.couponCoderegular !== undefined) {
        this.couponCodePeper.nativeElement.value = '';
      }
    }
    this.couponModal = checked;
  }

  getCover() {
    // desktop dimentions:
    if (1920 < this.screenWidth) {
      this.coverImgSize = 'desktop';
      // tablet dimantions:
    } else if (1920 >= this.screenWidth && this.screenWidth > 820) {
      this.coverImgSize = 'tablet';
      // Mobile dimantion
    } else if (this.screenWidth <= 820) {
      this.coverImgSize = 'mobile';
    }
    // bg image size calculatore

    // desktop dimentions:
    if (2560 < this.screenWidth) {
      this.bgImgSize = 'desktop';
      // tablet dimantions:
    } else if (2560 >= this.screenWidth && this.screenWidth > 1444) {
      this.bgImgSize = 'tablet';
      // Mobile dimantion
    } else if (this.screenWidth <= 1444) {
      this.bgImgSize = 'mobile';
    }
    if (this.media) {
      for (const m of this.media) {
        // cover:
        if (m.media_type == 1) {
          // console.log('coverPreview',this.images_base_url, this.coverImgSize, m.file);
          // let isRegularTicketExist = false;
          // if (this.all_tickets.some(t => t.price > 0)) {
          //   if(this.getin_base_url == 'https://get-in.com') {
          //     isRegularTicketExist = true;
          //   }
          // }
          // let domain = isRegularTicketExist ? this.imgix_url : this.images_base_url;
          // let coverPreviewUrl = domain + '/gallery/' + this.coverImgSize + m.file;
          // // Imgix case
          // if(isRegularTicketExist) {
          //   const imgixUrlParams = '?h=610&w=610';
          //   coverPreviewUrl = domain + 'gallery/desktop'+m.file+imgixUrlParams;
          // }
          // // console.log(coverPreviewUrl)
          // this.coverPreview = this.sanitizer.bypassSecurityTrustUrl( coverPreviewUrl);
          // // coverPreviewUrl like https://getin.imgix.net/gallery/desktop_cover_20240905_143420_347248.png?h=610&w=610

          // OLD VARIANT
          this.coverPreview = this.sanitizer.bypassSecurityTrustUrl(
            this.images_base_url + '/gallery/' + this.coverImgSize + m.file
          );


        } else if (m.media_type == 2) {
          this.backgroundImageName = m.file;
          // this.bgPreview = this.images_base_url + '/gallery/' + this.bgImgSize + this.backgroundImageName;
          this.bgPreview =
            'url(' +
            this.images_base_url +
            '/gallery/' +
            this.bgImgSize +
            this.backgroundImageName +
            ')';
        } else if (m.media_type == 4) {
          this.backgroundFeedImageName = m.file;
          if (m.file == 'logo.jpg') {
            this.coverImg =
              this.api_base_url +
              '/images/share' +
              this.backgroundFeedImageName;
          } else {
            this.coverImg =
              this.images_base_url +
              '/gallery/share' +
              this.backgroundFeedImageName;
          }
        }
        if (m.media_type !== 1) {
          this.coverPreviewDefault = '/assets/images/svg-icons/logo-new.svg'; // if event image doesnt set
        }
      }
      if (this.screenWidth <= 414) {
        this.backgroundFileName = this.backgroundFeedImageName
          ? this.backgroundFeedImageName
          : this.backgroundImageName;
        this.bgImgSize = this.backgroundFeedImageName ? 'portrait' : 'mobile';
        // this.bgPreview = this.images_base_url + '/gallery/' + this.bgImgSize + this.backgroundFileName;
        this.bgPreview =
          'url(' +
          this.images_base_url +
          '/gallery/' +
          this.bgImgSize +
          this.backgroundFileName +
          ')';
      }
    } else {
      this.coverPreviewDefault = '/assets/images/svg-icons/logo-new.svg'; // if no any image
    }
    // console.log('cover media', this.media, this.bgImgSize, this.bgPreview, this.backgroundFeedImageName, this.coverImgSize, this.coverPreview, coverImg);
  }

  setGatewayType(gateway) {
    this.gateway_type = gateway.payment_type;
    this.gateway_id = gateway.id;
  }

  getEvent() {
    this.eventService
      .getEventDatalp(
        this.event,
        this.seller_code,
        this.coupon_code,
        this.preview_page
      )
      .subscribe(
        eventData => {
          this.media = eventData.media;
          this.salesman_id = eventData.lp_salesman_id;
          this.salesman_user = eventData.salesman_user;
          this.event_details = eventData.event_details[0];
          this.eventStartDate =
            moment(this.event_details.start_date, 'DD-MM-YYYY') ||
            moment(this.event_details.start_date, 'MM-DD-YYYY').format(
              'MMM DD, YYYY'
            ) ||
            this.event_details.start_date;
          // console.log('eventStartDate', (this.event_details.start_date as string).replace(/\//g, '-'), this.eventStartDate);
          this.event_id = eventData.event_details[0].event_id;
          this.producer_id = eventData.event_details[0].user_id;
          this.currency = eventData.event_details[0].currency;
          this.description = this.getDescription(
            eventData.event_details[0].description
          );
          this.marketing = eventData.marketing;
          // this.gateway_type = eventData.payment_gateway_type;
          this.paymentGateways = eventData.payment_gateways;
          this.tickets = eventData.tickets;console.log('eventData', eventData)
          this.categories = eventData.categories;
          this.rooms = eventData.rooms;
          this.tables = eventData.tables;
          this.tickets_reg = eventData.tickets_reg;
          this.rooms_reg = eventData.rooms_reg;
          this.tables_reg = eventData.tables_reg;
          this.coupons = eventData.coupons;
          this.discounts = eventData.discounts;
          this.commission = eventData.event_details[0].commission;
          this.purchaser_commission =
            eventData.event_details[0].purchaser_commission;
          this.tax_commission = eventData.event_details[0].tax_commission;
          this.service_commission =
            eventData.event_details[0].service_commission;
          this.platform_fee = eventData.event_details[0].platform_fee;
          this.platform_fee_rule = eventData.event_details[0].platform_fee_rule;
          this.platform_fee_type = eventData.event_details[0].platform_fee_type;
          this.purchase_validation =
            eventData.event_details[0].purchase_validation;
          this.purchase_passcode_mode = eventData.event_details[0].purchase_passcode_mode;
          this.purchase_type = eventData.event_details[0].purchase_type;
          this.facebook_validation =
            eventData.event_details[0].facebook_validation;
          this.email_validation = eventData.event_details[0].facebook_validation
            ? 0
            : 1;
          this.all_tickets = eventData.all_tickets;
          this.event_fb_friends = eventData.event_fb_friends;
          this.timerTimestamp = eventData.custom_data.start_timer;
          this.active_coupon = eventData.active_coupon;
          this.new_custom_inputs = eventData.custom_inputs;
          this.event_categories = eventData.event_categories;
          this.birthday_validation =
            eventData.event_details[0].birthday_validation;
          this.donation_fee_mode = eventData.event_details[0].donation_fee_mode;
          this.show_price_with_fees = eventData.event_details[0].show_price_with_fees;
          this.waiting_list_gateway_features = eventData.waiting_list_gateway_features;

          this.mailing = {
            input_name: 'mailing',
            input_options: [],
            input_type: 'checkbox',
            placeholder: "צ'קבוקס אישור דיוור של גטאין",
            required: '1',
          };
          this.checkBoxMailing = this.event_details.getin_subscription
            ? true
            : false; // I accept receiving updates from GETIN
          // console.log('event data', eventData, this.all_tickets, eventData.tickets_reg, eventData.tickets);

          Object.keys(this.customAgreements).map(key => {
            if (this.event_id !== +key) {
              this.checkBoxCustomEvent = true;
            } else {
              this.customIsPresent = true;
            }
          });
          if (this.event_id === 163076 || this.event_id === 5778) {
            // remove get-in fb pixel for these events
            // console.log('remove Pixel Tag');
            this.fb_pixel_model.removeGetinPixel(this.renderer);
          }

          this.eventService.setSpikeToStorage(eventData.spike_protected_session);
          // this.eventService.setSpikeToStorage(true);

          this.getCover();

          this.initChoosedTickets();
          this.initChoosedItems();

          this.getProducerData();
          this.getFormInputs();
          this.checkTicketsAvailability();

          this.categoryStepChoise();
          this.detectWaitedListMode();
          if (this.event_details) {
            this.setPixelAnalytics();
            this.setShareSocial();
            // this.setEventTimer();
          }
          this.processActionsBasedOnQueryParams();
          // get stripe payment methods and show saved credit cards
          if (this.paymentGateways && this.paymentGateways.length) {
            let stripe_count = 0,
              hyper_count = 0;
            let isHyperProd = false;

            this.paymentGateways.forEach(gw => {
              this.getPaymentMethods(gw.payment_type);
              if (gw.payment_type === 'stripe_gateway') {
                stripe_count++;
              }
              if (gw.payment_type === 'hyper_switch') {
                hyper_count++;
                this.reloadPageIfOtherHyperswitchGateway(gw.id);
              }
              if (gw.hasOwnProperty('isProd')) {
                isHyperProd = gw.isProd;
              }
            });

            if (stripe_count && !hyper_count) {
              console.log('loadStripe');
              // let hyperScript = this._document.getElementById('hyper-script');
              // if (hyperScript) {
              //   hyperScript.remove();
              // }

              // We creates a copy of Stripe func
              // Issue - Hyper always override window.Stripe
              if (window.StripeCopy) {
                window.Stripe = window.StripeCopy;
              } else {
                this.checkStripeWindowID = setInterval(() => {
                  // console.log('window.Stripe1', window.Stripe);
                  if (window.Stripe) {
                    window.StripeCopy = window.Stripe;
                    clearInterval(this.checkStripeWindowID);
                  }
                }, 300);
              }
              // Issue - Hyper always override window.Stripe END
              this.loadStripe();
            }
            if (hyper_count && !stripe_count) {
              console.log('loadHyper');

              // Issue - Hyper always override window.Stripe
              this.loadStripe(); // Stripe First
              this.checkStripeWindowID = setInterval(() => {
                // console.log('Copy function from window.Stripe to window.StripeCopy', window.Stripe);
                if (window.Stripe) {
                  if (!window.StripeCopy) {
                    window.StripeCopy = window.Stripe;
                  }
                  this.loadHyper(isHyperProd); // Hyper Second
                  clearInterval(this.checkStripeWindowID);
                }
              }, 300);
              // Issue - Hyper always override window.Stripe END
            }
            if (hyper_count && stripe_count) {
              console.log('loadHyper but have also Stripe Payment Method');

              // Issue - Hyper always override window.Stripe
              this.loadStripe(); // Stripe First
              this.checkStripeWindowID = setInterval(() => {
                // console.log('Copy function from Stripe to StripeCopy', window.Stripe);
                if (window.Stripe) {
                  window.StripeCopy = window.Stripe;
                  this.loadHyper(isHyperProd); // Hyper Second
                  clearInterval(this.checkStripeWindowID);
                }
              }, 300);
              // Issue - Hyper always override window.Stripe END
            }
          }

          // console.log('eventData', eventData, this.description);
          this.setEventMetaTags();

          if (
            eventData.seats &&
            eventData.seats.hasOwnProperty('event') &&
            eventData.seats.hasOwnProperty('workspaceKey')
          ) {
            this.showSeatsIo = true; // seats.io
            this.seatsUserConfig = eventData.seats.event_tickets_categories;
            this.seatsSupportsBestAvailable =
              eventData.seats.supportsBestAvailable;
            if (!this.seatsUserConfig) {
              console.log('Please create Seats config!');
              return;
            }
            this.createAvailableListCategories(this.seatsUserConfig);

            this.createSeatsConfig(
              eventData.seats.event,
              eventData.seats.workspaceKey,
              this.seatsUserConfig,
              eventData.seats.region
            ); // seats.io
            // this.createSeatsConfig("9fa18038-ed86-4e48-8abf-8f7b0881fa65", "9d84f1c5-ce98-4d24-990f-ca3b40121c9a", this.seatsUserConfig, 'eu');
          }

          // console.log('active_coupon', this.showSeatsIo, this.coupons, this.active_coupon, this.coupon_code);

          this.checkIfActiveCouponValid(this.active_coupon);
          this.event_fetched.next(true);

          // Automatically apply coupone code from LINK
          if (this.stepTemplate === 'templateTickets') {
            console.log('Triggered templateTickets');
            if (
              this.coupon_code.length > 1 &&
              this.isActiveCoupon &&
              this.user_id &&
              !this.showSeatsIo
            ) {
              // active coupon functionality
              this.insertAndSentCoupon(); // active coupon functionality
              this.skipTicketsStep();
              return;
            } else if (
              this.coupon_code.length > 1 &&
              this.isActiveCoupon &&
              !this.user_id &&
              !this.showSeatsIo
            ) {
              this.skipTicketsStep();
              return;
            }
          }
          // Automatically apply coupone code from LINK END

          // Track event Tracking
          this.rudderstackService.track('Viewed Event', {
            event_id: this.event_details.event_id,
            event_name: this.event_details.title,
            category: this.event_categories,
            location: this.event_details.address,
            date: this.event_details.full_start_date,
          });

          if (eventData.hasOwnProperty('csrf_token')) {
            this.eventService.eventViews(eventData.csrf_token);
          }

        },
        error => {
          this.router.navigate(['/']);
        }
      );
  }

  getEventBeforeAction() {
    return this.eventService.getTicketsForPurchase(this.event);
  }

  checkIfTicketsWereSoldOut(updateTickets = true) {
    // console.log(updateTickets, 'checkIfTicketsWereSoldOut')
      if(this.DisableGetTicketsForPurchase || this.waiting_list_mode) {
        return new Promise(resolve => {
          resolve(true);
        });
      } else {
        return new Promise(resolve => {
          this.showPurchaseBtn = false;
          this.tranzillaUrl = undefined;
          this.showStripeForm = false;
          this.eventService
            .getTicketsForPurchase(this.event)
            .subscribe((response: any) => {
              // response = data: {custom_data: {}, tickets: [], tickets_reg: [] }
              if (updateTickets) {
                this.tickets = [
                  ...response.data.tickets,
                  ...response.data.tickets_reg,
                ];
              }
              this.timerTimestamp = response.data.custom_data.start_timer;
              this.isSelectedTicketOutOfStock = false;
              // console.log('paymentGateways', eventData.payment_gateways);

              this.tickets.forEach(ticket => {
                if (ticket.id in this.choosedItemsCheckIfSoldOut) {
                  const now = moment();
                  const end = moment(ticket.end_date, 'DD/MM/YYYY HH:mm');
                  if (
                    this.choosedItemsCheckIfSoldOut[ticket.id].amount >
                    ticket.limits.length
                  ) {
                    // console.log("SelectedTicket Quantity bigger than available limits, ticket ID:", ticket.id);
                    this.isSelectedTicketOutOfStock = true;
                    setTimeout(()=> {
                      window.location.reload()
                    }, 2000);
                  } else if (ticket.availability == 2) {
                    // console.log("SelectedTicket OutOfStock, ticket ID:", ticket.id);
                    this.isSelectedTicketOutOfStock = true;
                    this.dialogService.alertsModal('errors', 24, 5000, false);
                  } else {
                  }
                }
              });
              resolve(true); // resolving our promise
            });
        });
      }

  }

  checkAvailablePurchase() {
    return new Promise(resolve => {
      this.eventService.checkAvailablePurchase(this.main_purchase_identifier).subscribe((response: any) => {
          if(response.code == 'success') {
            resolve(true); // resolving our promise
          } else {
            if(response.code) {
              this.dialogService.alertsModal('purchaseEvent',response.code,5000,false);
            }
            resolve(false);
          }
        }, error => {
        if(error.code) {
          this.dialogService.alertsModal('purchaseEvent',error.code,5000,false);
        }
        resolve(false);
      });
    });
  }

  checkAvailableFromStripe() {
    this.purchase_id = undefined;
    this.main_purchase_identifier = '';
    this.stepTemplate = 'templateDescription';
  }

  checkIfActiveCouponValid(coupon) {
    if (coupon) {
      const timeNow = new Date();
      moment(coupon.end_date).isAfter(timeNow)
        ? (this.isActiveCoupon = true)
        : (this.isActiveCoupon = false);
      // console.log("checkIfActiveCouponValid", this.isActiveCoupon, this.item_is_reg, this.tickets_reg);
      // this.isActiveCoupon = true;
      // this.couponCodeSent = true;
      if (
        this.tickets &&
        this.tickets.length > 0 &&
        !this.tickets[0].reg &&
        !this.showSeatsIo
      ) {
        // console.log("this is a not register ticket");
        this.isActiveCoupon
          ? this.switchStep('Tickets')
          : this.dialogService.alertsModal('landingPage', 8, 5000, false);
      } else {
        if (this.showSeatsIo) {
          this.switchStep('Seats');
        }
        // console.log("this is a register ticket, no need to apply coupon discounts");
      }
    }
  }

  setPixelAnalytics() {
    // console.log('setPixelAnalytics');
    // hot-fix for events with Facebook pixel id is 329855344890909
    this.hardcodedEvents.forEach(event => {
      if (this.event_id === event) {
        this.fb_pixel_model.setPixel(
          this.renderer,
          [329855344890909],
          false,
          null,
          null,
          'ViewContent'
        );
      }
    });
    // hot-fix for ivent 163076 add Facebook pixel id 376968190835651
    if (this.event_id === 163076) {
      this.fb_pixel_model.setPixel(
        this.renderer,
        [376968190835651],
        false,
        null,
        null,
        'ViewContent'
      );
    }
    // plant scripts for Facebook and google pixels
    // // OLD variant - only for 1 pixel
    // this.fb_pixel_model.setPixel(this.renderer, +this.marketing.facebook_pixel_id, false);
    // console.log('setPixelAnalytics facebook_pixels', this.marketing.facebook_pixels);
    this.addPixelEvent('ViewContent');
    ////////// Google Analytics pixel //////////
    if (this.marketing.google_analytics_ua) {
      // create first script tag:
      const google_analytics_tag1 = this.renderer.createElement('script');
      google_analytics_tag1.type = 'text/javascript';
      google_analytics_tag1.src =
        'https://www.googletagmanager.com/gtag/js?id=' +
        this.marketing.google_analytics_ua;

      // create second script tag:
      const google_analytics_tag2 = this.renderer.createElement('script');
      google_analytics_tag2.type = 'text/javascript';
      google_analytics_tag2.text =
        `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '` +
        this.marketing.google_analytics_ua +
        `');
      `;

      // append GA tag to document (in reverse order - since the method is prepend to head):
      this.renderer.insertBefore(
        this._document.head,
        google_analytics_tag2,
        this._document.head.firstChild
      );
      this.renderer.insertBefore(
        this._document.head,
        google_analytics_tag1,
        this._document.head.firstChild
      );
    }
    ////////// Google leads pixel //////////
    if (this.marketing.google_leads) {
      // part 1 of google leads (remarketing):
      const google_leads_pt1 = this.renderer.createElement('script');
      google_leads_pt1.type = 'text/javascript';
      google_leads_pt1.text =
        `
      /* <![CDATA[ */
      var google_conversion_id = ` +
        this.marketing.google_leads +
        `;
      var google_custom_params = window.google_tag_params;
      var google_remarketing_only = true;
      /* ]]> */
      `;

      // part 2 of google leads:
      const google_leads_pt2 = this.renderer.createElement('script');
      google_leads_pt2.type = 'text/javascript';
      google_leads_pt2.src = '//www.googleadservices.com/pagead/conversion.js';

      // create the noscript tag for google leads:
      const google_leads_noScript = this.renderer.createElement('noscript');
      const noScriptContents = this.renderer.createElement('div');
      const img_tag = this.renderer.createElement('img');
      img_tag.src =
        '//googleads.g.doubleclick.net/pagead/viewthroughconversion/' +
        this.marketing.google_leads +
        '/?guid=ON&script=0';
      img_tag.height = 1;
      img_tag.width = 1;
      img_tag.alt = '';
      this.renderer.setStyle(img_tag, 'width', '1');
      this.renderer.setStyle(img_tag, 'height', '1');
      this.renderer.setStyle(img_tag, 'border-style', 'none');
      this.renderer.setStyle(noScriptContents, 'display', 'inline');
      this.renderer.appendChild(noScriptContents, img_tag);
      this.renderer.appendChild(google_leads_noScript, noScriptContents);

      // part 3 of google leads:
      const google_leads_pt3 = this.renderer.createElement('script');
      google_leads_pt3.async = 'true';
      google_leads_pt3.src =
        'https://www.googletagmanager.com/gtag/js?id=AW-' +
        this.marketing.google_leads;

      // part 4 of google leads:
      const google_leads_pt4 = this.renderer.createElement('script');
      google_leads_pt4.text =
        `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'AW-` +
        this.marketing.google_leads +
        `');
      `;

      // append google leads to document:
      this.renderer.appendChild(this._document.body, google_leads_pt3);
      this.renderer.appendChild(this._document.body, google_leads_pt4);
      this.renderer.appendChild(this._document.body, google_leads_pt1);
      this.renderer.appendChild(this._document.body, google_leads_pt2);
      this.renderer.appendChild(this._document.body, google_leads_noScript);
    }
  }

  getDescription(data: Array<any>) {
    let description = { lang: this.localeId, value: '' };
    data.forEach(values => {
      if (values.lang === this.localeId) {
        description = { lang: values.lang, value: values.value };
      } else if (values.lang) {
        description = { lang: values.lang, value: values.value };
      }
    });
    // console.log('description is', description);
    return description;
  }

  htmlToPlaintext(string) {
    return string ? String(string).replace(/<[^>]+>/gm, ' ') : '';
  }

  IGLogin() {
    // Instagram Login Doesn't work yet
  }

  FBLogin(purchase_id = null, step = '') {
    // console.log('FBLogin', step);
    this.clicked = true;
    if (
      this.authService.getFacebookId() == '0' ||
      this.authService.getFacebookId() === undefined ||
      !this.authService.getFacebookId()
    ) {
      let extraData = purchase_id ? this.cfReturnStr + 'cfReturnFlag=true' : '';
      if (extraData || this.router.url.match(/\?/)) {
        extraData = extraData + '&togglePurchaseModal=true';
      } else {
        extraData = '?togglePurchaseModal=true';
      }
      this.facebookService.getFBAccessCode(extraData);
    } else {
      if (!this.sub_purchaser_id) {
        this.facebookConnect = true;
        this.togglePurchaseModal = true;
        if (step) {
          this.switchStep(step);
        }
      } else {
        this.user_info = this.authService.getUserInfo();
        if (!this.user_info) {
          return false;
        }
      }
    }
  }

  getFormInputs() {
    // console.log('getFormInputs', this.marketing);
    /* Fetching inputs for registration form */
    this.setFormInputs();
    /* Fetching all the data we need for the component */
    if (this.event_details) {
      /* When facebook connection is necessary it will be true */
      if (this.purchase_validation === 1 && this.facebook_validation === 1) {
        this.facebookConnect = true;
        this.fb_module = true;
      }
    }

    /* Category calculation */
    this.categoryStepChoise();

    /* Facebook connect only */
    // if(this.facebookConnect && this.facebook_id == '0' && this.fb_module){
    //   this.step = 'facebook_connect';
    // }

    /* We need a little time for facebook friends to get back */

    /* User info for autofill */
    const user_info = this._cookieService.get('user_info');
    if (user_info) {
      const dec_user_info = CryptoJS.AES.decrypt(user_info, 'user_info');
      this.user_info = dec_user_info.toString(CryptoJS.enc.Utf8);
      this.user_info = JSON.parse(this.user_info);
    }
  }

  getParticipantData() {
    // console.log('getParticipant Data', this.ticketsAvailability, this.quantity);
    if ((!this.ticketsAvailability && !this.waiting_list_mode && !this.hasRegularTicket)) {
      return;
    }
    // Doesn't choose ticket/table/room
    if (!this.quantity || this.quantity <= 0 || this.quantity === null) {
      this.dialogService.alertsModal('landingPage', 11, 2000, false);
      return;
    }
    // Remove all formArray controls
    const arr = <UntypedFormArray>this.participantForm.controls.participants;
    while (arr.length !== 0) {
      arr.removeAt(0);
    }
    this.counter = 0;

    /* Creation of form controlls */
    let setedFirstElement = false;
    Object.keys(this.choosedItems).forEach(cat => {
      if (
        Object.keys(this.choosedItems[cat]).length === 1 &&
        this.choosedItems[cat].hasOwnProperty('name')
      ) {
        delete this.choosedItems[cat].name;
      } else {
        Object.keys(this.choosedItems[cat]).forEach(ticket_id => {
          if (ticket_id !== 'name') {
            for (let i = 0; i < this.choosedItems[cat][ticket_id].amount; i++) {
              if (
                this.purchase_type !== 1 ||
                (this.purchase_type === 1 && !setedFirstElement)
              ) {
                (<UntypedFormArray>(
                  this.participantForm.controls['participants']
                )).push(this.createControls(ticket_id, cat));
                setedFirstElement = true;
              }
            }
          }
        });
      }
    });
    // console.log('this.choosedItems', this.user_info, this.salesman_user, this.form_inputs, this.choosedItems, this.participantForm);
    // Show participant step
    // this.step = 'participant_details';
  }

  recalcPaticipents() {
    // for recalculate if user changing number of tickets insted of refrash all user data
    console.log('recalcPaticipents....');
    let setedFirstElement = false;
    Object.keys(this.choosedItems).forEach(cat => {
      if (
        Object.keys(this.choosedItems[cat]).length === 1 &&
        this.choosedItems[cat].hasOwnProperty('name')
      ) {
        // delete this.choosedItems[cat].name;
        console.log('hasOwnProperty name', cat);
      } else {
        Object.keys(this.choosedItems[cat]).forEach(ticket_id => {
          if (ticket_id !== 'name') {
            const amount = this.choosedItems[cat][ticket_id].amount;
            // console.log('choosedItems ticket is', ticket_id, amount);
            for (let i = 0; i < amount; i++) {
              if (
                this.purchase_type !== 1 ||
                (this.purchase_type === 1 && !setedFirstElement)
              ) {
                const arr = <UntypedFormArray>(
                  this.participantForm.controls.participants
                );
                arr.value.forEach((element, i) => {
                  // console.log('controls get element', element, ticket_id, element.item_id);
                });
                // console.log('participants array', arr.value.length, amount, arr);
                // for (var x = 0; x < this.quantity; x++) {
                //   if (arr.controls[ticket_id] && arr.controls[ticket_id].value) {
                //     console.log('ticket is true', ticket_id, arr.controls[ticket_id].value);
                //   } else {
                //     console.log('ticket is false', ticket_id);
                //   }
                // }
                // (<FormArray>this.participantForm.controls['participants']).push(this.createControls(ticket_id, cat));
                setedFirstElement = true;
              }
            }
          }
        });
      }
    });
  }

  checkTicketsAvailability() {
    let available_items_counter = 0;
    let no_active_items_counter = 0;
    let reg_only_counter = 0;
    let sold_out_items_counter = 0;

    if (this.all_tickets.length > 0) {
      for (const ticket of this.all_tickets) {
        if (ticket.availability == 1) {
          available_items_counter++;
          if (ticket.reg == 1) {
            reg_only_counter++;
          }
        } else {
          no_active_items_counter++;
        }
      }
      if (this.all_tickets.length == no_active_items_counter) {
        this.ticketsAvailability = 0;
      } else if (available_items_counter == reg_only_counter) {
        this.ticketsAvailability = 'register_only';
      } else {
        this.ticketsAvailability = 1;
      }
    } else {
      this.ticketsAvailability = 0;
    }

    for (const ticket of this.all_tickets) {
      if (ticket.availability == 2) {
        sold_out_items_counter++;
        if (this.all_tickets.length == sold_out_items_counter) {
          this.ticketsSoldOut = 1;
        }
      } else {
        this.ticketsSoldOut = 0;
      }
      /* console.log(
        "Ticket ID",ticket.id,'\n',
        "Ticket Availability", ticket.availability , '\n',
        "Ticket start date",moment(ticket.start_date, "DD-MM-YYYY HH:mm").format('YYYY-MM-DD HH:mm'), moment(ticket.start_date, "DD-MM-YYYY HH:mm").valueOf(), '\n',
        "Ticket end date",moment(ticket.end_date, "DD-MM-YYYY HH:mm").format('YYYY-MM-DD HH:mm'), moment(ticket.end_date, "DD-MM-YYYY HH:mm").valueOf(),
      ); */
    }
    // console.log(this.all_tickets,  this.ticketsSoldOut)
    // console.log('checkTicketsAvailability', this.ticketsAvailability, no_active_items_counter, reg_only_counter, available_items_counter);
    // this.fixOrderButton();
  }

  createControls(
    id?,
    category?,
    seatsLabels = null,
    seatTicketId = null,
    seatTicketIds = []
  ) {
    // console.log('createControls');
    const group = this.fb.group({});
    // console.log("createControls this.form_inputs.default_inputs", this.form_inputs.default_inputs, this.event_details)
    this.form_inputs.default_inputs.forEach(control => {
      // default inputs created only if thay are required
      if (control.required == 1) {
        if (control.input_name === 'first_name') {
          group.addControl(
            control.input_name,
            this.fb.control(
              this.counter === 0 && this.user_info && !this.salesman_user
                ? this.user_info.first_name
                : '',
              [
                // Validators.required
              ]
            )
          );
        } else if (control.input_name === 'last_name') {
          group.addControl(
            control.input_name,
            this.fb.control(
              this.counter === 0 && this.user_info && !this.salesman_user
                ? this.user_info.last_name
                : '',
              [
                // Validators.required
              ]
            )
          );
        } else if (control.input_name === 'full_name') {
          group.addControl(
            control.input_name,
            this.fb.control(
              this.counter === 0 && this.user_info && !this.salesman_user
                ? this.user_info.full_name
                  ? this.user_info.full_name
                  : this.user_info.first_name
                    ? this.user_info.first_name + ' ' + this.user_info.last_name
                    : ''
                : '',
              [Validators.required]
            )
          );
        } else if (control.input_name === 'gender') {
          if (
            this.event_details.gender !== 0 ||
            this.event_details.gender == 0
          ) {
            group.addControl(
              control.input_name,
              this.fb.control(
                this.counter === 0 &&
                  this.user_info &&
                  !this.salesman_user &&
                  this.user_info.gender !== null
                  ? this.user_info.gender
                  : '',
                this.event_details.gender ? [Validators.required] : []
              )
            );
          }
        } else if (control.input_name === 'user_phone') {
          group.addControl(
            control.input_name,
            this.fb.control(
              this.counter === 0 && this.user_info && !this.salesman_user
                ? this.user_info.phone
                : '',
              this.user_info && this.user_info.phone
                ? [Validators.required]
                : []
            )
          );
          let userPhone = group.get('user_phone').value;
          if (
            userPhone &&
            userPhone.match(/^0[\d]{1,2}[-]?[\d]{3}[-]?[\d]{4}$/)
          ) {
            if (userPhone.charAt(0) === '0') {
              userPhone = userPhone.substr(1);
            }
            userPhone = '+972' + userPhone;
            group.get('user_phone').patchValue(userPhone);
          }
          group.addControl('phoneCountryCode', new UntypedFormControl(''));
        } else if (control.input_name === 'user_email') {
          group.addControl(
            control.input_name,
            this.fb.control(
              this.counter === 0 && this.user_info && !this.salesman_user
                ? this.user_info.email
                : '',
              [Validators.required, Validators.pattern(emailRegex), customValidator.blockedEmailValidator(this.invalidEmailsError)]
            )
          );
        } else if (
          control.input_name === 'identification_number' ||
          control.input_name === 'id_number'
        ) {
          // console.log('add control id_number');
          if (this.event_details.id_number) {
            group.addControl(
              control.input_name,
              this.fb.control(
                this.counter === 0 && this.user_info && !this.salesman_user
                  ? this.user_info.identification_number
                  : '',
                [
                  Validators.required,
                  Validators.minLength(7),
                  Validators.maxLength(10),
                ]
              )
            );
          }
          // Birth date control will be created when is no requirement for birth date and when birth date is limited
        } else if (
          control.input_name === 'birth_date' &&
          !this.eventPaymentMode
        ) {
          // if (this.event_details.birth_date) {
          group.addControl(
            control.input_name,
            this.fb.control(
              this.counter === 0 && this.user_info && !this.salesman_user
                ? this.user_info.birth_date
                : '',
              [Validators.required, this.validateDates.bind(this)]
            )
          );
          // }
        } else if (control.input_name === 'age') {
          // Setting this param for age validation function is required.
          this.ageValidation = true;
          if (
            this.user_info &&
            this.user_info.birth_date &&
            this.user_info.birth_date !== '0000-00-00'
          ) {
            const userAgeMs =
              new Date().valueOf() -
              new Date(this.user_info.birth_date.replace(/-/g, '/')).valueOf();
            const userAgeYears = Math.floor(
              userAgeMs / (1000 * 60 * 60 * 24 * 365)
            );
            this.user_info.age = Math.round(userAgeYears);
          }

          const index = this.form_inputs.default_inputs.findIndex(e => {
            return e.input_name === 'birth_date' && e.required === '1';
          });
          // ageDisabled - to prevent change age by user if 'birth_date' and 'age' fields exist
          this.ageDisabled = index !== -1 ? true : false;

          group.addControl(
            control.input_name,
            this.fb.control(
              this.counter === 0 &&
                this.user_info &&
                !this.salesman_user &&
                this.user_info.age !== '0'
                ? this.user_info.age
                : '',
              [
                Validators.required,
                Validators.min(0),
                Validators.max(120),
                Validators.pattern('^[0-9]*$'),
                // this.validateDates.bind(this)
              ]
            )
          );
          // Making hidden control
          // group.addControl('birth_date', this.fb.control((this.counter === 0 && this.user_info && !this.salesman_user) ? this.user_info.birth_date : '', Validators.required));
        } else {
          group.addControl(
            control.input_name,
            this.fb.control('', [
              Validators.required,
              Validators.min(1),
              Validators.pattern('^[0-9]*$'),
            ])
          );
        }
      }
    });

    // Add instagram URL
    if (this.event_details.instagram_url === 1) {
      group.addControl(
        'instagram_url',
        this.fb.control('', [Validators.required])
      );
    }

    // Add ticketID and category
    group.addControl('item_id', this.fb.control(id ? id : null));

    if (category) {
      group.addControl(
        'category',
        this.fb.control(category, [Validators.required])
      );
    }

    // NEW Custom inputs START
    //console.log("this.custom_inputs", this.new_custom_inputs)
    this.new_custom_inputs.forEach(control => {
      if (control.blocked) {
        return;
      }
      if (control.required) {
        if (control.type === 'radio') {
          group.addControl(
            control.id,
            this.fb.control(control.opinions[0].title, Validators.required)
          );
        } else {
          group.addControl(
            control.id,
            this.fb.control('', [Validators.required])
          );
        }
      } else {
        if (control.type === 'radio') {
          group.addControl(
            control.id,
            this.fb.control(control.opinions[0].title)
          );
        } else {
          group.addControl(control.id, this.fb.control(''));
        }
      }

      if (control.type === 'checkbox') {
        this.checkboxArr[this.counter + '-' + control.id] = [];
      }
    });
    // console.log('createControls checkboxArr', this.checkboxArr);
    // NEW Custom inputs END

    // SEATS IO ADDITIONAL INFO
    // ONLY FOR INDIVIDUAL PURCHASE FORM
    if (seatsLabels && this.purchase_type !== 1) {
      // LABELS OBJECT EXAMPLE
      // labels : {
      //   "own": "37",
      //   "parent": "12",
      //   "section": "12",
      //   "displayedLabel": "12-12-37",
      //   "type": "row" // additional param  row or table pass in
      // }
      group.addControl('labels', this.createSeatsLabelsControls(seatsLabels));
    }
    if (seatsLabels && this.purchase_type == 1) {
      // console.log('seatsLabels', seatsLabels)
      group.addControl(
        'labels',
        this.createSeatsLabelsControlsAsArray(seatsLabels)
      );
    }

    if (seatTicketId) {
      group.addControl('seat_id', this.fb.control(seatTicketId));
    }
    if (seatTicketIds.length) {
      group.addControl('seat_ids', this.fb.control(seatTicketIds));
    }

    // SEATS IO ADDITIONAL INFO END

    this.counter++;
    // console.log('form_inputs', this.form_inputs, group, this.event_details, this.counter, this.checkboxArr );
    return group;
  }

  createSeatsLabelsControls(labels) {
    // console.log('lables', labels)
    const group = this.fb.group({});
    Object.keys(labels).forEach(label => {
      // console.log(label, labels[label]);
      group.addControl(label, this.fb.control(labels[label]));
    });
    return group;
  }

  createSeatsLabelsControlsAsArray(labels) {
    const arr = this.fb.array([]);
    Object.keys(labels).forEach(label => {
      const group = this.fb.group({});
      group.addControl(label, this.fb.control(labels[label]));
      arr.push(group);
    });
    return arr;
  }

  minEventDate(eventDate) {
    return new Date().getFullYear() - eventDate.getFullYear();
  }

  dateToAge(date: string) {
    // console.log('date', date);
    if (date) {
      // const userAgeMs = new Date().valueOf() - new Date(date.replace(/-/g, '/')).valueOf();
      // const userAgeYears = Math.floor(userAgeMs / (1000 * 60 * 60 * 24 * 365));
      // found this by accident, exactly the same mistake as in birthDateCange, probably causes problems if it is used somewhere or could cause in the future
      // found also date-to-age-pipe.ts which I think again calculates the age from some date, maybe apply this to there as well, someone check
      const userAgeYears = moment(new Date()).diff(
        new Date(date.replace(/-/g, '/')),
        'years'
      );
      return userAgeYears;
    }
    return null;
  }

  ageToDate(data: any) {
    const counter = data.counter;
    const inputName = data.inputName;
    let age = data.age;
    if (!age && inputName) {
      age =
        this.participantForm.controls['participants']['controls'][counter]
          .controls[inputName].value;
    }
    const day = new Date().getDate();
    const month = new Date().getMonth() + 1;
    let year = new Date().getFullYear();
    // Year of birth
    year = year - age;
    const date = year + '-' + month + '-' + day;
    if (!inputName) {
      return date;
    }
  }

  validateDates(control: UntypedFormControl) {
    // console.log('validateDates');
    const eventDate =
      this.event_details.birth_date !== null
        ? new Date(this.event_details.birth_date)
        : false;
    const ageDisplay = this.event_details.age_display;
    const minAge = +this.event_details.min_age;
    const userAge = this.dateToAge(control.value);
    // console.log('validateDates', minAge, ageDisplay, userAge,  control.value, eventDate);
    const ageValidator = {
      minDate: {
        he: `תאריך לידה מינימלי ${this.datePipe.transform(
          this.event_details.birth_date,
          'dd/MM/yyyy'
        )}`,
        en: `Minimum date of birth should be ${this.datePipe.transform(
          this.event_details.birth_date,
          'dd/MM/yyyy'
        )}`,
        es: `La fecha mínima de nacimiento debe ser ${this.datePipe.transform(
          this.event_details.birth_date,
          'dd/MM/yyyy'
        )}`,
      },
      minAgeByEvent: {
        he: `גיל מינימלי ${eventDate ? this.minEventDate(eventDate) : ''}`,
        en: `Minimum age should be ${
          eventDate ? this.minEventDate(eventDate) : ''
        }`,
        es: `La edad mínima debe ser ${
          eventDate ? this.minEventDate(eventDate) : ''
        }`,
      },
      minAge: {
        he: `גיל מינימלי ${minAge}`,
        en: `Minimum age should be ${minAge}`,
        es: `La edad mínima debe ser ${minAge}`,
      },
    };

    if (ageDisplay === 1 && !this.ageValidation && eventDate) {
      if (control.value > eventDate) {
        const message = ageValidator.minDate[this.localeId];
        return { validateDates: message };
      }
      return null;
    } else if (ageDisplay === 2 && this.ageValidation && minAge) {
      if (+userAge < minAge) {
        const message = ageValidator.minAge[this.localeId];
        return { validateDates: message };
      }
      return null;
    } else {
      return null;
    }
  }

  resizeIframeVideo() {
    setTimeout(() => {
      if (this.eventDescription) {
        let elementPadding = this.eventDescription.nativeElement.style.padding;
        elementPadding = elementPadding.replace(/%\s?/g, '');
        const iframeSinglePadding = this.screenWidth * (+elementPadding / 100); // only work with padding as percentage
        const iframeWidth = this.screenWidth - iframeSinglePadding * 2;
        const heightSize = iframeWidth / (16 / 9) + 'px';
        const iframesObject =
          this.eventDescription.nativeElement.querySelectorAll('.ql-video');
        if (iframesObject) {
          for (const key in iframesObject) {
            if (iframesObject.hasOwnProperty(key)) {
              const e = iframesObject[key];
              this.renderer.setStyle(e, 'height', heightSize);
              this.renderer.addClass(e, 'ql-media');
            }
          }
        }
      }

      // $('iframe.ql-video').on('load', () => {
      //   console.log('onload video');
      //   let iframeVideo;
      //   setTimeout(() => { iframeVideo = this.eventDescription.nativeElement.querySelectorAll('.ytp-large-play-button'); console.log('iframeVideo', iframeVideo); });
      //   console.log('test', $('iframe.ql-video').contents().html(), $('iframe.ql-video').contents().hasClass('.ytp-large-play-button'));
      //   $('iframe.ql-video').contents().addClass('iframeVideo');
      // });

      // setTimeout(() => { $('iframe').contents().find('.ytp-large-play-button').addClass('iframeVideo')}, 500);

      // if (iframeVideo) {
      //     this.renderer.addClass(
      //       iframeVideo,
      //       'watch-video'
      //     );
      // }
    }, 0);
  }

  /**
   * @function Calculating the price and converting it to string
   */
  calcTotalPrice(buyWithCash: boolean) {
    //console.log('calcTotalPrice', this.ticketLimits, this.purchaser_commission, this.total, this.total_ticket_discount, this.total_discount, this.couponTicket);
    // Getting the commision in %
    // console.log(this.platform_fee, 'platform_fee_rule',this.platform_fee_rule, 'platform_fee_type', this.platform_fee_type );
    // platform_fee_rule; // 1 - Each ticket fee, 2 - All purchase fee.
    // platform_fee_type; // 1 - Sum or 2 -Percent
    this.total_platform_fee = this.calcPlatformCommission();
    const purchaser_commission =
      this.purchaser_commission > 0 ? this.purchaser_commission / 100 : 0;
    const service_commission =
      this.service_commission > 0 ? this.service_commission / 100 : 0;
    const tax_commission =
      this.tax_commission > 0 ? this.tax_commission / 100 : 0;
    const total_commission_in_percentage = +(
      purchaser_commission + service_commission
    );

    this.discount
      ? this.calcCouponDiscountsByDiscount()
      : this.calcCouponDiscounts(); // if discount or if coupon
    // console.log('commission', commission, 'total_ticket_discount', this.total_ticket_discount, 'total_discount', this.total_discount, 'total.total_price', this.total.total_price)
    // Price without commision
    if (this.hasDonateTicket) {
      // Do not calculate coupons discount for donation tickets
      this.total_price_n_commission = this.total.total_price.toFixed(2);
    } else {
      if (this.couponTicket) {
        // if activated coupon exist for selected Ticket, in the api/checkDiscount - ticket value
        this.total_price_n_commission = (
          this.total.total_price - this.total_ticket_discount
        ).toFixed(2);
      } else {
        this.total_price_n_commission = (
          this.total.total_price - this.total_discount
        ).toFixed(2);
      }
    }
    if (
    ((total_commission_in_percentage &&
        !buyWithCash &&
        this.commission !== 0) ||
      this.total_platform_fee) || (total_commission_in_percentage !== 0 &&
      !buyWithCash)
    ) {
      // Calculate service_commission and purchaser_commission
      const total_price_w_purchaser_commission = (
        parseFloat(this.total_price_n_commission) *
        (purchaser_commission + 1)
      ).toFixed(2);
      const total_price_w_service_commission = (
        parseFloat(this.total_price_n_commission) *
        (service_commission + 1)
      ).toFixed(2);
      this.total_purchaser_commission = (
        parseFloat(total_price_w_purchaser_commission) -
        parseFloat(this.total_price_n_commission)
      ).toFixed(2);
      this.total_service_commission = (
        parseFloat(total_price_w_service_commission) -
        parseFloat(this.total_price_n_commission)
      ).toFixed(2);

      const total_fees_without_tax =
        parseFloat(this.total_purchaser_commission) +
        parseFloat(this.total_service_commission) +
        this.total_platform_fee;
      this.total_tax_commission = +(
        total_fees_without_tax * tax_commission
      ).toFixed(3);
      this.tax_and_fees =
        +this.total_price_n_commission * total_commission_in_percentage +
        +this.total_tax_commission +
        this.total_platform_fee;
      // if (+this.total_price_n_commission == 0) {
      //   this.tax_and_fees = 0;
      // } else {
      //   this.tax_and_fees = (+this.total_price_n_commission * commission) + (+this.total_tax_commission) + this.total_platform_fee;
      // }
      // Commisssion in shekels
      this.commission_ils = (Math.round(this.tax_and_fees * 100) / 100).toFixed(
        2
      ); // round to 2 decimals after comma
      // Price with commission
      this.total_price_w_commission = (
        parseFloat(this.total_price_n_commission) +
        parseFloat(this.commission_ils)
      ).toFixed(2);

      if (this.hasDonateTicket && this.event_details.donation_fee_mode == 0) {
        this.total_price_w_commission = parseFloat(
          this.total_price_n_commission
        ).toFixed(2);
      }
    } else if (
      total_commission_in_percentage == 0 &&
      !buyWithCash &&
      this.platform_fee
    ) {
      // if % Purchase fee = 0 and Platform fee
      this.tax_and_fees = this.total_platform_fee;
      this.commission_ils = (Math.round(this.tax_and_fees * 100) / 100).toFixed(
        2
      ); // round to 2 decimals after comma
      // Price with commission
      this.total_price_w_commission = (
        parseFloat(this.total_price_n_commission) +
        parseFloat(this.commission_ils)
      ).toFixed(2);
      this.total_purchaser_commission = '0.00';
      this.total_service_commission = '0.00';
      if (this.hasDonateTicket && this.event_details.donation_fee_mode == 0) {
        this.total_price_w_commission = parseFloat(
          this.total_price_n_commission
        ).toFixed(2);
      }
    } else {
      // Commission in shekels
      this.commission_ils = '0';
      this.total_purchaser_commission = '0.00';
      this.total_service_commission = '0.00';
      this.tax_and_fees = 0;
      this.total_tax_commission = 0;
      this.total_platform_fee = 0;

      // Price with commission (No change here)
      this.total_price_w_commission = this.total_price_n_commission;
    }

    // If coupon applied with 100% discount
    if (parseFloat(this.total_price_n_commission) == 0) {
      this.total_price_n_commission = '0.00';
      this.total_price_w_commission = '0.00';
      this.total_purchaser_commission = '0.00';
      this.total_service_commission = '0.00';
      this.tax_and_fees = 0;
      this.total_tax_commission = 0;
      this.total_platform_fee = 0;
    }

    // prevent total price from being a negative number:
    if (parseFloat(this.total_price_n_commission) < 0) {
      this.total_price_n_commission = '0.00';
    }

    // prevent total price with tax from being a negative number:
    if (parseFloat(this.total_price_w_commission) < 0) {
      this.total_price_w_commission = '0.00';
    }

    if (this.total_price_w_commission === '0.00') {
      this.freeItem = true;
    } else {
      this.freeItem = false;
    }

    console.log(
      'total_service_commission + total_platform_fee: ',
      this.total_service_commission,
      ' + ',
      this.total_platform_fee
    );
  }

  /**
   * @function Calculating Platform Fee
   */
  calcPlatformCommission() {
    let selected_tickets = this.findAllSelectedTicketsInfo();
    let total_platform_fee = 0;
    let platform_fee = parseFloat(this.platform_fee);
    // console.log('selected_tickets',platform_fee, selected_tickets );

    // Each ticket fee
    if (this.platform_fee_rule === 1) {
      const ticket_count = selected_tickets.filter(t => t.reg === 0).length;

      // Sum
      if (ticket_count && this.platform_fee_type == 1) {
        total_platform_fee = ticket_count * platform_fee;
      }
    }

    // All purchase fee
    if (this.platform_fee_rule === 2) {
      if (this.platform_fee_type == 1) {
        // Sum
        total_platform_fee = platform_fee;
      }
    }

    // console.log('total_platform_fee',total_platform_fee );
    return total_platform_fee;
  }

  setCheckBoxArray(value) {
    // console.log('checkbox array');
    this.checkboxArr = value;
  }

  isNumber(event) {
    const charCode = event.which ? event.which : event.keyCode;
    return /\d/.test(String.fromCharCode(charCode));
  }

  isLogin(value: boolean) {
    if (!value) {
      this.userService.openLoginModal();
      // this.stepTemplate = 'templateLogin';
      // this.openLoginModal();
      // console.log('get templateLogin');
    }
  }

  // -------------------Login Modal------------------------
  openLoginModal() {
    // console.log('get templateLogin');
    this.currentUrl = window.location.href;
    // console.log('Current Path:', this.currentUrl);
    const dialogRef = this.dialog.open(LoginComponent, {
      data: {},
      autoFocus: true,
      disableClose: true,
      height: '600px',
      width: '400px',
      maxWidth: '100vw',
      maxHeight: '100vh',
      backdropClass: 'full-width-ModalBackdrop', // 'loginModalBackdrop',
      panelClass: 'full-width-dialog', // 'loginModal'
    });
  }

  openPasscodeModal() {
    const dialogRef = this.dialog.open(PasscodeModalComponent, {
      data: {event_id: this.event_id},
      disableClose: true,
      autoFocus: true,
      maxWidth: '450px',
      maxHeight: '400px',
      backdropClass: 'full-width-ModalBackdrop', // 'loginModalBackdrop',
      panelClass: 'passcode-dialog', // 'loginModal'
    });

    dialogRef.afterClosed().subscribe(purchase_passcode => {

      if (purchase_passcode) {
        this.purchase_passcode = purchase_passcode;
        this.purchase_passcode_mode = 0; // Disable this to enable ORDER button
        this.switchStep('Tickets'); // navigate to Tickets step
      }

    });
  }

  openDialogWithTemplateRef(
    templateRef: any,
    event?: any,
    height?: string,
    isDesc?: boolean
  ) {
    const backdropClass = isDesc
      ? 'full-width-ModalBackdropOverlay'
      : 'full-width-ModalBackdrop';
    if (!this.authService.getUser() && this.facebook_validation !== 0) {
      // console.log('get templateLogin');
      this.userService.openLoginModal();
      return;
    }
    if (this.dialogRef && this.dialogRef.id) {
      this.dialogRef.close();
    }
    if (!height) {
      height = '95vh';
    }
    this.templateDescIsOpen = true;
    this.dialogRef = this.dialog.open(templateRef, {
      data: {},
      autoFocus: true,
      disableClose: true,
      width: '95vw',
      maxWidth: '100vw',
      maxHeight: '100vh',
      minHeight: height,
      backdropClass: backdropClass,
      panelClass: 'full-width-dialog',
    });
    this.dialogRef.afterOpened().subscribe(() => {
      if (isDesc) {
        this.isBottomTitle = false;
        this.resizeIframeVideo();
      }
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (!this.isBottomTitle) {
        this.isBottomTitle = !this.isBottomTitle;
      }
      if (event) {
        event.srcElement.scrollTo(0, 0);
      }
      this.templateDescIsOpen = false;
      if (this.edit_producer) {
        this.router.navigate(['/producer-info']);
        this.edit_producer = false;
      }
      if (result !== undefined && result.success) {
        // console.log('result', result);
      }
      this.stepTemplate = 'templateTickets';
      // console.log('closed modal dialog');
    });
  }

  manageParticipantData() {
    this.sub_purchaser = [];

    for (let i = 0; i < this.counter; i++) {
      // (i === 0) Means it going to be info only for main purchaser

      const form =
        this.participantForm.controls.participants['controls'][i].controls;
      let idNumber;
      // console.log('manageParticipantData', form);
      if (form.identification_number) {
        idNumber = form.identification_number.value;
      } else if (form.id_number) {
        idNumber = form.id_number.value;
      } else {
        idNumber = '';
      }

      const index = form.full_name.value.indexOf(' ', 0);
      const first_name = index
        ? form.full_name.value.substring(0, index)
        : undefined;
      const last_name = index
        ? form.full_name.value.substring(index + 1)
        : undefined;
      const age =
        !this.event_details.age_display ||
        (form.age !== undefined && form.age.value === 0)
          ? ''
          : form.age.value;

      const custom_purchaser = {
        full_name:
          form.full_name !== undefined
            ? form.full_name.value
            : form.first_name !== undefined
              ? form.first_name.value + ' ' + form.last_name.value
                ? form.last_name.value
                : ''
              : '',
        first_name: first_name,
        last_name: last_name,
        email: form.user_email !== undefined ? form.user_email.value : '',
        phone: form.user_phone !== undefined ? form.user_phone.value : '',
        phoneCountryCode:
          form.phoneCountryCode !== undefined
            ? form.phoneCountryCode.value
            : '',
        identification_number: idNumber,
        gender: form.gender !== undefined ? form.gender.value : '',
        birth_date: form.birth_date !== undefined ? form.birth_date.value : '',
        age: age,
        payment: i === 0 ? this.total_price_w_commission : 0,
        item_id: form.item_id.value,

        facebook_id: i === 0 && this.fb_module ? this.facebook_id : '0',
        user_link: i === 0 && this.facebook_link ? this.facebook_link : '',
        mailing: i === 0 ? this.checkBoxMailing : 0,
        producer_subscription:
          i === 0 && this.showProducerSubscription
            ? this.checkBoxProducerSubscription
            : false,
        custom_inputs: [],
        instagram_url:
          form.instagram_url !== undefined ? form.instagram_url.value : '',
      };

      // this.form_inputs.custom_inputs.forEach(input => {
      //   if (form[input.input_name]) {
      //     const custom = {
      //       input_name: input.input_name,
      //       value: form[input.input_name].value,
      //       placeholder: input.placeholder
      //     }
      //     custom_purchaser.custom_inputs.push(custom);
      //   }
      // });

      // NEW custom_inputs
      this.new_custom_inputs.forEach(input => {
        if (form[input.id]) {
          const custom = {
            id: input.id,
            title: input.title,
            value: form[input.id].value,
          };
          custom_purchaser.custom_inputs.push(custom);
        }
      });

      if (this.showSeatsIo) {
        custom_purchaser['seat_id'] =
          form.seat_id !== undefined ? form.seat_id.value : '';
        if (i === 0) {
          custom_purchaser['seat_ids'] =
            form.seat_ids !== undefined ? form.seat_ids.value : '';
        }
      }

      if (i === 0) {
        this.purchaser = custom_purchaser;
      } else {
        this.sub_purchaser.push(custom_purchaser);
      }
    }
  }

  checkedMailing(checked) {
    this.mailingStatus = checked ? 1 : 0;
  }

  onLoadIframe() {
    this.globals.stopLoader();
  }

  closeDialogModal() {
    console.log('close modal dialog');
    this.stepTemplate = 'templateDescription';
    // if (this.dialogRef && this.dialogRef.id) { this.dialogRef.close(); } else { console.log('modal dialog doesn\'t have ID'); }
    this.returnTo('items_section', false);
  }

  sendToken(boa_url, token) {
    this.iframeDisplay = 'block';
    this.inputElementRef.nativeElement.value = this.token;
    this.formElementRef.nativeElement.submit();
    this.globals.stopLoader();
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      // console.log('control', control, 'group', formGroup);
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  toPayment(buyWithCash: boolean = false) {
    const endPoint = !buyWithCash ? 'purchase' : 'checkoutPurchase';
    // console.log('toPayment', endPoint);
    this.globals.startLoader();

    // User submitted the form
    this.formSubmited = true;

    // Need to check terms as well
    if (this.participantForm.valid) {
      // console.log('participantForm valid');
      // Calculating price
      this.calcTotalPrice(buyWithCash);
      // console.log('calcTotalPrice After calc: ', this.total_price_w_commission, this.commission_ils);

      // Manage participant data
      this.manageParticipantData();
      // console.log('manageParticipantData:', this.sub_purchaser);

      if (parseFloat(this.total_price_w_commission) === 0) {
        // The payment is 0 and the item is of type registration than REGISTER
        if (this.item_is_reg) {
          this.payment_method = 4;
          // The payment is 0 and the item is not of type registration than FREE
        } else {
          this.payment_method = 2;
        }
        // Payment type CASH
      } else if (buyWithCash) {
        this.payment_method = 3;
        // The payment is more then 0 than CREDIT
      } else if (parseFloat(this.total_price_w_commission) > 0) {
        this.payment_method = 1;
      }

      let purchaseTickets = [];

      Object.keys(this.choosedItems).forEach((cat, i) => {
        Object.keys(this.choosedItems[cat]).forEach((ticket, index) => {
          if (ticket !== 'name') {
            if (
              !(
                index === 0 &&
                this.choosedItems[cat][ticket].amount - 1 === 0 &&
                i === 0
              )
            ) {
              purchaseTickets.push({
                item_id: +ticket,
                amount:
                  index === 0
                    ? i === 0
                      ? this.choosedItems[cat][ticket].amount - 1
                      : this.choosedItems[cat][ticket].amount
                    : this.choosedItems[cat][ticket].amount,
              });
            }
          }
        });
      });

      const data: AppPurchaseRequest = {
        event_id: this.event_id,
        amount: this.quantity,
        payment_gateway_id: this.gateway_id,
        purchaser: JSON.stringify(this.purchaser),
        sub_purchasers: JSON.stringify(this.sub_purchaser),
        total_price_n_commission: this.total_price_n_commission
          ? this.total_price_n_commission
          : '',
        total_price_w_commission: this.total_price_w_commission
          ? this.total_price_w_commission
          : '',
        currency: this.currency ? this.currency : '',
        salesman_id: this.salesman_id ? +this.salesman_id : 0,
        purchase_type: this.purchase_type ? this.purchase_type : 0,
        purchase_item: this.purchase_item ? this.purchase_item : 0,
        payment_method: this.payment_method,
        total_discount: this.total_discount,
        used_discount_type: this.used_discount_type
          ? this.used_discount_type
          : 0,
        used_discount_id: this.used_discount_id ? this.used_discount_id : 0,
        coupon_source: this.coupon_source ? this.coupon_source : 0,
        discount_search_key: this.discount_search_key
          ? this.discount_search_key
          : 0,
        client_details: {
          source: this.globals.detectBrowser(),
        },
        seat_hash: this.seatsHash,
        donation_tickets: JSON.stringify(this.donate_tickets),
        purchase_passcode: this.purchase_passcode
      };

      if (this.purchase_type === 1) {
        // purchase_type === 1  - Group purchase form
        // Recalculate purchaseTickets for GROUP purchase only --START
        purchaseTickets = [];
        let totalAmount = 0;
        Object.keys(this.choosedItems).forEach((cat, i) => {
          const items_size = Object.keys(this.choosedItems[cat]).length;
          Object.keys(this.choosedItems[cat]).forEach((ticket, index) => {
            const mainPurchaserItemId = Object.keys(this.choosedItems[cat])[0]; // main user item_id
            //total_amount += this.choosedItems[cat][ticket].amount;
            const chosenTicket = this.choosedItems[cat][ticket];
            if (ticket !== 'name') {
              purchaseTickets.push({
                item_id: +ticket,
                amount: chosenTicket.amount,
              });
              totalAmount = totalAmount + chosenTicket.amount;
            }
          });
        });
        // Decrease tickets 'amount' by 1 (Only for group purchase)
        // Because we have 1 main user who bought all other tickets
        // if (totalAmount >= 1) {
        //   purchaseTickets[purchaseTickets.length - 1].amount = purchaseTickets[purchaseTickets.length - 1].amount - 1;
        // }
        // Recalculate purchaseTickets for GROUP purchase only --END
        const firstTicketAmount = purchaseTickets[0].amount;
        const main_purchaser = JSON.parse(data.purchaser);
        const index = purchaseTickets.findIndex(
          t => +t.item_id === +main_purchaser.item_id
        );
        if (purchaseTickets.length === 1) {
          if (firstTicketAmount === 1) {
            purchaseTickets = [];
          }
          if (firstTicketAmount > 1) {
            purchaseTickets[0].amount = firstTicketAmount - 1;
          }
        }

        if (purchaseTickets.length > 1) {
          const new_amount = purchaseTickets[index].amount - 1;
          if (new_amount === 0) {
            purchaseTickets.splice(index, 1); // remove element
          } else {
            purchaseTickets[index].amount = new_amount; // decrease by 1
          }
        }
        data.group_tickets = this.choosedItems
          ? JSON.stringify(purchaseTickets)
          : '';
      }
      // console.log('choosedItems: ', this.choosedItems);

      this.hyperSubmitDisable = true;
      this.hyperSubmitHidden = true;

      if(this.live_cart_hash) {
        data['live_cart_hash'] = this.live_cart_hash;
      }

      this.handlePayment(endPoint, data);
      // console.log('handlePayment: ', this.purchase_type, purchaseTickets,  endPoint, data);
    } else {
      this.globals.stopLoader();
      // console.log('checking item_is_reg');
      //Terms not checked
      if (!this.checkBoxAgree && !this.item_is_reg) {
        this.dialogService.alertsModal('errors', 16, 5000, false);
        //Participant data valid
      } else if (!this.participantForm.valid && !this.item_is_reg) {
        this.dialogService.alertsModal('errors', 18, 3000, false);
      }
    }
  }

  setTrnBtnStatus(responce) {
    this.trnBtnStatus = responce;
    // console.log('setTrnBtnStatus', responce);
  }

  setSolBtnStatus(responce) {
    this.trnSolBtnStatus = responce;
    // console.log('setSolBtnStatus', responce);
  }

  walletConnectPay(value) {
    this.transactionBtnStatus = value;
    // console.log('wallenConnectPay', value);
  }

  solanaConnectPay(value) {
    this.solanaTransactionBtnStatus = value;
    // console.log('solanaConnectPay', value);
  }

  setTransactionWallet(response) {
    this.transactionBtnStatus = false;
    this.globals.stopLoader();
    // console.log('setTransactionWallet', response); // code: success, data: {success: true/false, url:/order_status........}
    if (response.data.url) {
      this.dialogService.alertsModal(
        'success',
        'walletConnectSuccess',
        5000,
        true
      );
      // console.log('navigateByUrl', response.data.url);
      this.router.navigateByUrl(response.data.url);
    } else if (response.data.sendTo) {
      // console.log('navigateByUrl', response.data.sendTo);
      this.router.navigateByUrl(response.data.sendTo);
      // this.router.navigate(['/order-status/1/1/ILS/2/1/4608/3.00/1']);
    } else {
      this.dialogService.alertsModal(
        'success',
        'walletConnectTransaction',
        2000,
        true
      );
    }
  }

  setSolanaTransactionWallet(response) {
    // console.log('setSolanaTransactionWallet', response);
    this.solanaTransactionBtnStatus = false;
    this.globals.stopLoader();
    if (response.state === 'processed') {
      this.dialogService.alertsModal(
        'success',
        'walletConnectProcessing',
        5000,
        true
      );
      console.log(
        'Your order for event has been processed. It should be confirmed on the blockchain shortly.'
      );
    } else if (response.state === 'confirmed') {
      // this.dialogService.alertsModal('success', 'walletConnectTransaction', 3000, true);
      console.log(
        'We are waiting for a transaction. Please wait until the transaction is mined.'
      );
    } else if (response.state === 'finalized') {
      this.dialogService.alertsModal(
        'success',
        'walletConnectSuccess',
        3000,
        true
      );
    } else if (response.state === 'close') {
      this.dialogService.alertsModal(
        'cryptoCurrency',
        'walletConnectModalClosed',
        3000,
        false
      );
    } else if (response.state === 'timerStopped') {
      this.dialogService.alertsModal(
        'cryptoCurrency',
        'walletTransactionHash',
        3000,
        false
      );
    } else if (response.state === 'finish') {
      console.log('navigateByUrl', response.transaction.data.url);
      response.transaction.data.url
        ? this.router.navigateByUrl(response.transaction.data.url)
        : false;
    } else if (response.state === 'rejected') {
      console.log(
        'set solanaTransactionBtnStatus to: ',
        this.solanaTransactionBtnStatus
      );
    }
  }

  openWallets() {
    // console.log('openWallets');
    this.isOpenWallets = true;
  }

  openSolanaWallets() {
    // console.log('openSolanaWallets');
    this.isOpenSolanaWallets = true;
  }

  handlePayment(endPoint, data) {
    this.eventService[endPoint](data).subscribe(
      (response: any) => {
        // console.log('handlePayment response', response);
        let isFunction = true;
        if (typeof window.ga !== 'function') {
          console.log('window.ga is not a function');
          isFunction = false;
        }
        // init GA eCommerce component:
        isFunction ? window.ga('require', 'ecommerce') : false;

        this.globals.stopLoader();

        if (response.payment_details) {
          this.purchase_id = response.payment_details.purchase_id;
          this.purchase_item_id = response.payment_details.purchase_item_id;
          this.main_purchase_identifier =
            response.payment_details.main_purchase_identifier;
          if (isFunction) {
            // GA addTransaction:
            window.ga('ecommerce:addTransaction', {
              id: response.payment_details.purchase_id, // purchase_id. Required.
              revenue: response.payment_details.remaining_payment, // Grand Total.
              currency: this.currency, // local currency code.
              affiliation: this.event_id,
            });

            // GA addItem - needs to happen for each item in cart.
            window.ga('ecommerce:addItem', {
              id: response.payment_details.id, // payment_id. Required.
              name: response.payment_details.item_details.item_name, // Product name. Required.
              sku: response.payment_details.item_details.item_id, // SKU/code.
              category: response.payment_details.item_details.item_type, // ticket / table / room
              price: response.payment_details.item_details.item_price, // Unit price.
              quantity: this.sub_purchaser.length + 1, // Quantity.
              currency: this.currency, // local currency code.
            });

            window.ga('ecommerce:send');
          }
          // Temporary disable SOLD OUT checking for NYC spike events
          const spike_protected_session = sessionStorage.getItem('spike_protection_session');
          if (spike_protected_session) {
            this.DisableGetTicketsForPurchase = true;
          } else {
            this.DisableGetTicketsForPurchase = false;
          }

          this.saveTicketsToLocaleStorage(); // for rudderstack Order completed
          if (
            response &&
            response.payment_details &&
            response.payment_details.hasOwnProperty('producer_subscription')
          ) {
            this.saveSubscribeToLocaleStorage(
              response.payment_details.producer_subscription
            ); // for Track Subscribe to producer
          }

          if (response.payment_details.payment_method == 1) {
            // Remaining payment show up
            this.remaining_payment = response.payment_details.remaining_payment;
            this.commission_ils = response.payment_details.commission_ils;

            // Open tranzilla
            // this.step = 'payment_step';
            // for test stripe gateway
            // response.payment_details.payment_gateway_type = 'stripe_gateway';
            // response.payment_details.token = 'pk_test_MtCjQhqaSXe9FCJiaEgG5XGw';

            if (response.payment_details.iframe != 'external api') {
              this.gateway_type = response.payment_details.payment_gateway_type;
              // console.log('gateway_type', this.gateway_type);
              if (
                !response.payment_details.payment_gateway_type ||
                response.payment_details.payment_gateway_type == 'tranzilla'
              ) {
                // if payment type doesn't consist in response
                this.gateway_type = 'tranzilla';
                // Update iframe:
                this.tranzillaUrl =
                  this.sanitizer.bypassSecurityTrustResourceUrl(
                    response.payment_details.iframe
                  );
                // Set timer for 30 minutes and then close it.
                this.purchaseTimer = new Date(+new Date() + 1.8e6);
                // console.log('response.payment_details.payment_gateway_type tranzilla', this.tranzillaUrl);
              } else if (
                response.payment_details.payment_gateway_type &&
                response.payment_details.payment_gateway_type ==
                  'bank_of_america'
              ) {
                // Send token to BOA
                this.token = response.payment_details.token;
                this.sendToken(this.boa_url, this.token);
                // console.log('response.payment_details.payment_gateway_type bank_of_america');
                this.globals.startLoader();
                this.purchaseTimer = new Date(+new Date() + 1.8e6);
              } else if (
                response.payment_details.payment_gateway_type &&
                response.payment_details.payment_gateway_type ==
                  'stripe_gateway'
              ) {
                // Stripe form
                this.payment_id = response.payment_details.id;
                this.showStripeForm = true;
                // console.log('showStripeForm');
                let stripeAccount = null;

                const lng = this.isProd ? `/${this.localeId}` : '';
                this.stripe_return_url = `${this.getin_base_url}${lng}/stripe-payment/${this.main_purchase_identifier}`;

                if (
                  response.payment_details.payment_gateway_data &&
                  response.payment_details.payment_gateway_data
                    .connect_account &&
                  response.payment_details.payment_gateway_data.connect_account
                    .id
                ) {
                  stripeAccount =
                    response.payment_details.payment_gateway_data
                      .connect_account.id;
                }
                if(response.payment_details.payment_gateway_data &&
                  response.payment_details.payment_gateway_data.enable_indirect_payment &&
                  response.payment_details.payment_gateway_data.client_secret
                ) {
                  this.enable_indirect_payment = response.payment_details.payment_gateway_data.enable_indirect_payment;
                  this.stripe_client_secret = response.payment_details.payment_gateway_data.client_secret;
                  this.stripe_publishable_key = response.payment_details.payment_gateway_data.token;
                }
                if(response.payment_details.payment_gateway_data &&
                  response.payment_details.payment_gateway_data.waiting_list_checkout
                ) {
                  this.waiting_list_checkout = response.payment_details.payment_gateway_data.waiting_list_checkout;
                  const lng = this.isProd ? `/${this.localeId}` : '';
                  this.stripe_return_url = `${this.getin_base_url}${lng}/stripe-future-payment/${this.main_purchase_identifier}`;
                }
                if (!this.savedCard) {
                  if(!this.enable_indirect_payment) {
                    this.createStripeElement(response.payment_details.token,response.payment_details,stripeAccount);
                  }

                  this.purchaseTimer = new Date(+new Date() + 1.8e6);
                } else {
                  // console.log('sendPayment');
                  this.sendPayment();
                }
                // console.log('response.payment_details.payment_gateway_type stripe_gateway');
              } else if (
                response.payment_details.payment_gateway_type &&
                response.payment_details.payment_gateway_type == 'coinbase'
              ) {
                // Coinbase
                if (response.payment_details.token) {
                  this.purchaseTimer = new Date(+new Date() + 1.8e6);
                  this.coinBaseData = JSON.parse(
                    response.payment_details.token
                  );
                  // strt checking crypto payments;
                  this.startCheckCryptoPayments();
                  this.checkCryptoPayments();
                  // console.log('response.payment_details.token', this.coinBaseData, this.gateway_type);
                  this.createCriptoForm();
                }
              } else if (
                response.payment_details.payment_gateway_type &&
                response.payment_details.payment_gateway_type == 'walletconnect'
              ) {
                this.purchaseTimer = new Date(+new Date() + 1.8e6);
                this.walletConnectData = response;
                this.walletConnectData['event_details'] = {};
                this.walletConnectData['event_details']['eventId'] =
                  this.event_details.event_id;
                this.walletConnectData['event_details']['eventName'] =
                  this.event_details.title;
                this.walletConnectData['event_details']['userEmail'] =
                  this.event_details.contact_email;
                this.walletConnectData['event_details']['totalTickets'] =
                  this.quantity;
                // console.log('walletConnect Data', data);
                // console.log('walletConnectData response:', response);
                this.openWallets();
              } else if (
                response.payment_details.payment_gateway_type &&
                response.payment_details.payment_gateway_type == 'solana'
              ) {
                this.purchaseTimer = new Date(+new Date() + 1.8e6);
                this.solanaConnectData = response;
                this.solanaConnectData['event_details'] = {};
                this.solanaConnectData['event_details']['eventId'] =
                  this.event_details.event_id;
                this.solanaConnectData['event_details']['eventName'] =
                  this.event_details.title;
                this.solanaConnectData['event_details']['userEmail'] =
                  this.event_details.contact_email;
                this.solanaConnectData['event_details']['totalTickets'] =
                  this.quantity;
                // console.log('solanaConnect Data', data);
                // console.log('solanaConnectData response:', response);
                this.openSolanaWallets();
              } else if (
                response.payment_details.payment_gateway_type &&
                response.payment_details.payment_gateway_type == 'hyper_switch'
              ) {
                console.log('createHyperElement');
                const clientSecret =
                  response.payment_details.payment_gateway_data.client_secret;
                this.hyper_clientSecret =
                  response.payment_details.payment_gateway_data.client_secret;
                const publishable_key =
                  response.payment_details.payment_gateway_data.published_key;
                const payment_methods =
                  response.payment_details.payment_gateway_data.payment_methods;

                const lng = this.isProd ? `/${this.localeId}` : '';
                this.hyper_return_url = `${this.getin_base_url}${lng}/hyperswitch-payment/${this.main_purchase_identifier}`;
                this.createHyperElement(
                  publishable_key,
                  clientSecret
                );
              }
            } else {
              // External api logic
              this.externalApi = true;
            }

            // if transaction made with free/register/cash
          } else if (response.success == 1) {
            if (isFunction) {
              // GA addTransaction:
              window.ga('ecommerce:addTransaction', {
                id: response.payment_details.purchase_id, // purchase_id. Required.
                revenue: response.payment_details.remaining_payment, // Grand Total.
                currency: this.currency, // local currency code.
                affiliation: this.event_id,
              });

              // GA addItem - needs to happen for each item in cart.
              window.ga('ecommerce:addItem', {
                id: response.payment_details.id, // payment_id. Required.
                name: response.payment_details.item_details.item_name, // Product name. Required.
                sku: response.payment_details.item_details.item_id, // SKU/code.
                category: response.payment_details.item_details.item_type, // ticket / table / room
                price: response.payment_details.item_details.item_price, // Unit price.
                quantity: this.sub_purchaser.length + 1, // Quantity.
                currency: this.currency, // local currency code.
              });

              window.ga('ecommerce:send');
            }
            //There no purchase validation 0 on success page so we setting 2 for all others
            this.purchase_validation = response.payment_details.purchase_validation_new_logic;
            const purchaseValidation = this.purchase_validation === 1 ? 1 : 2;
            // console.log('eventPaymentMode', !this.eventPaymentMode);
            if (!this.eventPaymentMode) {
              // if success with reg tickets only

              // this.closeDialogModal();
              let main_purchase_identifier =
                response.payment_details.main_purchase_identifier;
              this.customParams['main_purchase_identifier'] =
                main_purchase_identifier;
              // this.showNftThanksModal(main_purchase_identifier);
              if (response.reg == 1) {
                // console.log('success with registration');
                this.router.navigate(
                  [
                    '/order-status/1/4',
                    this.currency,
                    purchaseValidation,
                    1,
                    this.event_id,
                    response.payment_details.producer_credit,
                    this.full_purchase ? 0 : 1,
                  ],
                  { queryParams: this.customParams }
                );
                // if success with priced tickets and 100% discount
              } else {
                // console.log('success with priced tickets and 100% discount');
                this.router.navigate(
                  [
                    '/order-status/1/1',
                    this.currency,
                    purchaseValidation,
                    1,
                    this.event_id,
                    response.payment_details.producer_credit,
                    this.full_purchase ? 0 : 1,
                  ],
                  { queryParams: this.customParams }
                );
              }
            } else {
              // console.log('orderConfirmed and seted Pixel');
              // // OLD variant - only for 1 pixel
              // this.fb_pixel_model.setPixel(this.renderer, this.marketing.facebook_pixel_id, true, this.currency, response.payment_details.producer_credit, ((this.marketing.fb_track_thanks) ? this.marketing.fb_track_thanks : undefined));
              const pixelContent = this.getPixelContent();
              const pixelAction = 'OrderConfimed';
              if (
                this.marketing.facebook_pixels &&
                this.marketing.facebook_pixels.length
              ) {
                this.event_pixel = null;
                this.event_pixels = [];
                this.marketing.facebook_pixels.forEach(val => {
                  this.event_pixel = +val.pixel_id;
                  this.event_pixels.includes(+val.pixel_id)
                    ? false
                    : this.event_pixels.push(+val.pixel_id);
                  // this.fb_pixel_model.setPixel(this.renderer, +val.pixel_id, true, this.currency, response.payment_details.producer_credit, pixelAction);
                  // console.log('Event Page: ', val.pixel_id, val.type_name, pixelAction, this.currency, response.payment_details.producer_credit);
                });
                this.fb_pixel_model.setPixel(
                  this.renderer,
                  this.event_pixels,
                  true,
                  this.currency,
                  response.payment_details.producer_credit,
                  pixelAction,
                  pixelContent
                );
                // console.log('Event Page: ', this.event_pixel, pixelAction, this.currency, response.payment_details.producer_credit);
              } else {
                // add Pixel when order confimed
                this.fb_pixel_model.setGetinPixel(
                  this.renderer,
                  true,
                  this.currency,
                  response.payment_details.producer_credit,
                  pixelAction,
                  pixelContent
                );
              }
              this.dialogService.alertsModal(
                'success',
                'orderConfirmed',
                2000,
                true
              );
              this.returnTo('items_section');
            }
          } else {
            // console.log('response error');
            this.dialogService.alertsModal('landingPage', 4.1, 5000, false);
          }

          // Checkout Timer Conditions
          // Checkout Timer Conditions
          let pgt = response.payment_details.payment_gateway_type
          if (
            pgt === 'stripe_gateway' ||
            pgt === 'tranzilla' ||
            pgt === 'hyper_switch'
          ) {
            let timer = this.checkout_timeout_counter;
            if (response.payment_details.checkout_timeout_counter) {
              timer = response.payment_details.checkout_timeout_counter*60;
            }
            // Sending seconds here and Start Checkout Timer
            this.checkoutTimer.startTimer(timer); // 5-15 min timer
          }


        } else {
          //If we have coupon error we getting it here
          if (response.error) {
            this.dialogService.alertsModal(
              'landingPage',
              response.error,
              5000,
              false
            );
            this.closeModal();
            // console.log('no payment details error');
          } else {
            this.dialogService.alertsModal('errors', 4, 5000, false);
            // console.log('no payment details');
          }
        }
      },
      error => {
        if (error.code) {
          this.dialogService.alertsModal(
            'errors',
            error.code,
            5000,
            false,
            error.log
          );
          // console.log('coupon error');
          this.tranzillaUrl = undefined;
          this.showStripeForm = false;
          this.checkoutTimer.resetTimer();
          if (error.code === 24 || error.code === 25) {
            this.eventTicketsService.clearDataTickets();
            this.eventTicketsService.clearDataParticipents();
            this.returnTo('items_section', true);
            this.stepTemplate = 'templateDescription';
            // if (!this.full_purchase && !this.eventPaymentMode) {
            //   this.eventTicketsService.clearDataTickets();
            //   this.eventTicketsService.clearDataParticipents();
            //   this.returnTo('items_section', true);
            //   this.stepTemplate = 'templateDescription';
            // } else {
            //   this.returnTo('items_section');
            // }
          }
          if (error.code === 48) {
            this.dialogService.alertsModal('errors', 48, 7000, false);
          }
          if (error.code === 52) {
            this.stepTemplate = 'templateDescription';
            this.showSeatsDisplay = false;
            this.listSelectedObjects = {};
            this.seatsTickets = [];
            this.seatsHash = '';
          }
        } else {
          if(error.error) {
            this.dialogService.alertsModal('errors', error.error, 5000, false);
            if (error.error == 17 || error.error == 64) {
              if(error.emails) {
                this.invalidEmailsError = error.emails;
                const arr = <UntypedFormArray>(this.participantForm.controls.participants);
                arr.value.forEach((element, i) => {
                  if (element.hasOwnProperty('user_email')) {
                    arr.controls[i].get('user_email').setValidators([
                      Validators.required,
                      Validators.pattern(emailRegex),
                      customValidator.blockedEmailValidator(this.invalidEmailsError)])
                    arr.controls[i].get('user_email').updateValueAndValidity();
                    arr.controls[i].get('user_email').markAsTouched();
                  }
                });
              }
              this.stepTemplate = 'templateGuest';
            }
          } else {
            this.dialogService.alertsModal('errors', 4, 5000, false);
          }
        }

        this.globals.stopLoader();
      }
    );
  }

  getPaymentMethods(type: string) {
    if (type === 'stripe_gateway' && this.user_id) {
      this.eventService
        .getPaymentMethods(this.event_id, this.user_id)
        .subscribe((response: any) => {
          if (response.code === 'success' && response.data.length) {
            this.cardMethods = response.data;
            this.savedCard = true;
          }
        });
    }
  }

  reloadPageIfOtherHyperswitchGateway(id) {
    const hyperswitch_gw_id = sessionStorage.getItem('hyperswitch_gw_id');
    if(hyperswitch_gw_id) {
      if (hyperswitch_gw_id == id) {
        // console.log('Nothing to DO!')
      } else {
        sessionStorage.setItem('hyperswitch_gw_id', id);
        window.location.reload(); // reload page if Hyper Gateway ID was changed
        // Because error occurs while init Hyper() script
        return;
      }
    } else {
      sessionStorage.setItem('hyperswitch_gw_id', id)
      return
    }
  }

  setPaymentMethod(method: any) {
    this.paymentMethod = {
      id: method.id,
      card: {
        exp_year: method.card.exp_year,
        exp_month: method.card.exp_month,
        last4: method.card.last4,
      },
    };
    this.cardIsChecked = false;
    this.gateway_id = method.payment_gateway_id;
    this.checkIfTicketsWereSoldOut().then(() => {
      if (!this.payment_id) {
        if (
          this.checkBoxAgree &&
          this.checkBoxCustomEvent &&
          !this.isSelectedTicketOutOfStock
        ) {
          this.toPayment();
        } else {
          this.showPurchaseBtn = true;
          this.dialogService.alertsModal('errors', '16', 3000, false);
        }
      }
    });
    // console.log('setPaymentMethod', this.paymentMethod, this.cardIsChecked, method, this.payment_id);
  }

  sendPayment() {
    this.sendStripeResponse({
      main_purchase_identifier: this.main_purchase_identifier,
      paymentMethod: this.paymentMethod,
      saveCard: this.cardIsChecked,
    });
    // console.log('sendPayment', this.payment_id);
  }

  createCriptoForm() {
    // console.log('createCriptoForm');
  }

  closeModal() {
    console.log('closeModal');
    if (!this.full_purchase && !this.eventPaymentMode) {
    } else {
      this.returnTo('items_section');
    }
  }

  pay(form) {
    if (!window['Stripe']) {
      alert('Oops! Stripe did not initialize properly.');
      return;
    }
    this.submitted = true;

    // console.log(this.customStripeForm);
    if (this.customStripeForm.invalid) {
      return;
    }

    this.formProcess = true;
    // console.log('form', form);
    if (!window['Stripe']) {
      alert('Oops! Stripe did not initialize properly.');
      return;
    }
  }

  loginExternalUser() {
    this.globals.startLoader();
    if (
      !this.email.match(
        /^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(\.[a-z]{2,20})$/i
      )
    ) {
      this.globals.stopLoader();
      this.dialogService.alertsModal('errors', 12.1, 5000, false);
      return;
    }

    // log user in to Getin
    this.authService
      .signinExternalUser(this.email, this.external_token)
      .subscribe(
        (response: any) => {
          this.globals.stopLoader();
          this.user_id = response.original.uid;

          if (response.original.success) {
            this.loginExternalUserErrorMsg = '';

            const user_info = JSON.stringify(response.user_data);
            const enc_user_info = CryptoJS.AES.encrypt(user_info, 'user_info');
            this._cookieService.set('user_info', enc_user_info, {
              expires: this.date,
              secure: location.protocol == 'https:' ? true : false,
            });
            this._cookieService.set(
              'facebook_id',
              response.original.facebook_id,
              {
                expires: this.date,
                secure: location.protocol == 'https:' ? true : false,
              }
            );
            this.authService.storeTokens(
              response.auth.session,
              response.auth.refresh
            );

            this.facebook_id = this.authService.getFacebookId();

            // embed cookie for external param - in order to show custom feed whenever user accesses feed.
            const external_params = JSON.stringify({
              external_identifier: this.external_identifier,
              user_id: this.user_id,
              email: this.email,
              external_token: this.external_token,
            });
            // var iframe = '/'+this.event+'?external_identifier=' + this.external_identifier + '&user_id=' + this.user_id + '&email=' + this.email + '&external_token=' + this.external_token;
            const external_user_info = CryptoJS.AES.encrypt(
              external_params,
              'ext_user'
            );
            this._cookieService.set('ext_user', external_user_info, {
              expires: this.date,
              path: '/',
              secure: location.protocol == 'https:' ? true : false,
            });
            // localStorage.setItem('ext_user', external_user_info);

            if (this.currentUserType == 2) {
              // redirect to cms dashboard
              window.location.replace('/cms/dashboard/pending');
            }
          } else if (response.original.error) {
            this.loginExternalUserErrorMsg = response.original.error;
          }
        },
        error => {
          this.globals.stopLoader();
        }
      );
  }

  checkCancelPurchaseOnBackStep(
    stepTemplate,
    navigateHomeOnClose = false,
    disableCancelPurchase = false
  ) {
    // console.log('checkCancelPurchaseOnBackStep', stepTemplate, navigateHomeOnClose, this.purchase_id, this.main_purchase_identifier, this.seatsHash)
    this.checkoutTimer.resetTimer();
    if (this.checkPaymentSubscription) {
      this.checkPaymentSubscription.unsubscribe();
    }
    // console.log(this.customParams, this.main_purchase_identifier);
    if (
      (this.purchase_id === undefined &&
        !this.main_purchase_identifier &&
        this.seatsHash &&
        navigateHomeOnClose) ||
      (this.purchase_id === undefined &&
        !this.main_purchase_identifier &&
        !this.seatsHash &&
        navigateHomeOnClose)
    ) {
      this.deleteLiveCart();
      this.router.navigate(['/']);
    }
    if (
      this.purchase_id === undefined &&
      !this.main_purchase_identifier &&
      !this.seatsHash &&
      !navigateHomeOnClose
    ) {
      if (this.showSeatsIo) {
        this.showSeatsDisplay = true;
        this.stepTemplate = 'templateSeats';
      }
    }

    // CASE 1 for templates: Tickets, Guest, Seats
    if (
      this.purchase_id === undefined &&
      this.main_purchase_identifier == undefined &&
      this.seatsHash.length == 0
    ) {
      console.log('CASE 1');
      if (this.showSeatsIo) {
        this.router.navigate(['/']);
      } else {
        this.deleteLiveCart();
        this.stepTemplate = 'templateDescription';
      }
    }

    // CASE 2 for templates: Order
    if (
      this.purchase_id === undefined &&
      this.main_purchase_identifier == undefined &&
      this.seatsHash.length &&
      stepTemplate !== 'templateOrder'
    ) {
      console.log('CASE 2');
      const objects = JSON.parse(this.seatsSelectedObjects);
      this.globals.startLoader();
      this.eventService
        .eventSeatsUnBook(
          this.seatsHash,
          this.event_id,
          objects,
          this.chartSeats.holdToken
        )
        .subscribe(res => {
          if (navigateHomeOnClose) {
            this.seatsHash = '';
            this.router.navigate(['/']);
          } else {
            this.seatsHash = '';
            this.purchase_id = undefined;
            this.main_purchase_identifier = '';
            this.listSelectedObjects = {};
            this.seatsTickets = [];
            this.total_price_w_commission = '0.00';
            this.showSeatsDisplay = true;
            this.stepTemplate = 'templateSeats';
          }
          this.globals.stopLoader();
        });
    }

    // CASE 3 for templates: Pay
    if (
      this.purchase_id &&
      this.main_purchase_identifier &&
      this.seatsHash.length &&
      stepTemplate !== 'templatePay'
    ) {
      console.log('CASE 3', this.gateway_type, stepTemplate);
      if (this.gateway_type == 'tranzilla' && this.showSeatsIo) {
        this.seatsHash = '';
        this.purchase_id = undefined;
        this.main_purchase_identifier = '';
        this.showSeatsDisplay = true;
        this.stepTemplate = 'templateSeats';
        return;
      }
      this.globals.startLoader();
      this.eventService.cancelPurchase(this.main_purchase_identifier).subscribe(
        res => {
          if (stepTemplate !== 'templateOrder' || this.seatsHash.length) {
            const objects = JSON.parse(this.seatsSelectedObjects);
            // this.globals.startLoader();
            this.eventService
              .eventSeatsUnBook(
                this.seatsHash,
                this.event_id,
                objects,
                this.chartSeats.holdToken
              )
              .subscribe(res => {
                if (navigateHomeOnClose) {
                  this.seatsHash = '';
                  this.router.navigate(['/']);
                } else {
                  this.seatsHash = '';
                  this.purchase_id = undefined;
                  this.main_purchase_identifier = '';
                  this.listSelectedObjects = {};
                  this.seatsTickets = [];
                  this.total_price_w_commission = '0.00';
                  this.showSeatsDisplay = true;
                  this.stepTemplate = 'templateSeats';
                  if (this.unified_checkout !== undefined) {
                    this.unified_checkout.nativeElement.innerHTML = '';
                  }
                  this.hyperSubmitHidden = true;
                }
                this.globals.stopLoader();
              });
          } else {
            if (navigateHomeOnClose) {
              this.router.navigate(['/']);
            } else {
              this.purchase_id = undefined;
              this.main_purchase_identifier = '';
              this.listSelectedObjects = {};
              this.seatsTickets = [];
              this.seatsHash = '';
              this.showSeatsDisplay = true;
              this.stepTemplate = this.showSeatsIo
                ? 'templateSeats'
                : stepTemplate;
            }
            this.globals.stopLoader();
          }

          this.hyperSubmitHidden = true;
          if (this.unified_checkout !== undefined) {
            this.unified_checkout.nativeElement.innerHTML = '';
            this.hyper_errors.nativeElement.innerText = '';
            this.hyper_return_url = '';
          }
        },
        error => {
          this.main_purchase_identifier = '';
          this.globals.stopLoader();
        }
      );
    }

    // CASE 4 for templates: Pay (Without Seats unbook)
    if (
      this.purchase_id &&
      this.main_purchase_identifier &&
      this.seatsHash.length == 0
    ) {
      console.log('CASE 4');
      if (disableCancelPurchase) {
        return;
      }
      this.globals.startLoader();
      this.eventService.cancelPurchase(this.main_purchase_identifier).subscribe(
        res => {
          if (navigateHomeOnClose) {
            this.purchase_id = undefined;
            this.main_purchase_identifier = '';
            this.deleteLiveCart();
            this.router.navigate(['/']);
          } else {
            this.purchase_id = undefined;
            this.main_purchase_identifier = '';
            this.stepTemplate = stepTemplate;
            if (this.unified_checkout !== undefined) {
              this.unified_checkout.nativeElement.innerHTML = '';
            }
            this.hyperSubmitHidden = true;
            this.updateLiveCart(true);
          }
          this.globals.stopLoader();
        },
        error => {
          this.main_purchase_identifier = '';
          this.globals.stopLoader();
          this.hyperSubmitHidden = true;
          if (this.unified_checkout !== undefined) {
            this.unified_checkout.nativeElement.innerHTML = '';
            this.hyper_errors.nativeElement.innerText = '';
            this.hyper_return_url = '';
          }
        }
      );
    }

    // CASE 5 for templates: Pay
    if (
      this.purchase_id === undefined &&
      this.main_purchase_identifier &&
      this.main_purchase_identifier.length == 0 &&
      this.seatsHash.length == 0
    ) {
      console.log('CASE 5');
      this.deleteLiveCart();
      this.router.navigate(['/']);
    }

    // CASE 6 for templates
    if (
      this.purchase_id === undefined &&
      this.main_purchase_identifier &&
      this.main_purchase_identifier.length == 0 &&
      this.seatsHash.length &&
      stepTemplate == 'templateDescription'
    ) {
      console.log('CASE 6');
      const objects = JSON.parse(this.seatsSelectedObjects);
      this.globals.startLoader();
      this.eventService
        .eventSeatsUnBook(
          this.seatsHash,
          this.event_id,
          objects,
          this.chartSeats.holdToken
        )
        .subscribe(
          res => {
            if (navigateHomeOnClose) {
              this.seatsHash = '';
              this.router.navigate(['/']);
            }
            this.listSelectedObjects = {};
            this.seatsTickets = [];
            this.total_price_w_commission = '0.00';
            this.showSeatsDisplay = true;
            this.stepTemplate = 'templateSeats';
            this.globals.stopLoader();
          },
          error => {
            this.globals.stopLoader();
            this.router.navigate(['/']);
          }
        );
    }

    // CASE 7
    if (
      this.purchase_id === undefined &&
      !this.main_purchase_identifier &&
      this.seatsHash &&
      !navigateHomeOnClose &&
      stepTemplate == 'templateOrder'
    ) {
      console.log('CASE 7');
      const objects = JSON.parse(this.seatsSelectedObjects);
      this.globals.startLoader();
      this.eventService
        .eventSeatsUnBook(
          this.seatsHash,
          this.event_id,
          objects,
          this.chartSeats.holdToken
        )
        .subscribe(
          res => {
            if (navigateHomeOnClose) {
              this.seatsHash = '';
              this.router.navigate(['/']);
            }
            this.seatsHash = '';
            this.purchase_id = undefined;
            this.main_purchase_identifier = '';
            this.listSelectedObjects = {};
            this.seatsTickets = [];
            this.total_price_w_commission = '0.00';
            this.showSeatsDisplay = true;
            this.stepTemplate = 'templateSeats';
            this.globals.stopLoader();
          },
          error => {
            this.globals.stopLoader();
            this.router.navigate(['/']);
          }
        );
    }

    // CASE 8
    if (
      stepTemplate == 'templateOrder' &&
      !navigateHomeOnClose &&
      this.purchase_id === undefined &&
      !this.seatsHash
    ) {
      console.log('CASE 8');
      if (this.showSeatsIo) {
        this.showSeatsDisplay = true;
        this.stepTemplate = 'templateSeats';
      } else {
        this.stepTemplate = 'templateDescription';
      }
      this.deleteLiveCart();
    }
  }

  closeEvent() {
    // console.log(this.purchase_id, this.main_purchase_identifier, this.seatsHash);
    this.checkCancelPurchaseOnBackStep('templateDescription', true);
    if (this.checkStripeWindowID) {
      clearInterval(this.checkStripeWindowID);
    }
    if (this.checkBtnOrderIntervalID) {
      clearInterval(this.checkBtnOrderIntervalID);
    }
    this.deleteLiveCart()
  }
  closeEvent2() {
    this.showSeatsDisplay = false;
    this.stepTemplate = 'templateDescription';
  }

  setEventMetaTags() {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = this.description.value;
    // console.log("setEventMetaTags", tmp.textContent);
    let title = this.event_details.title.substring(0, 60);
    const og_meta_description = tmp.textContent.substring(0, 157) + '...';
    // const og_url = this.getin_base_url + this.router.url;
    title = title.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '')

    // SET Meta tags
    this.titleService.setTitle('Getin - ' + title);
    this.meta.updateTag({ name: 'description', content: og_meta_description });

    // OG tags works only with SSR
    // this.meta.updateTag({ property: 'og:title', content: 'Getin - ' + title });
    // this.meta.updateTag({ property: 'og:url', content: og_url});
    // this.meta.updateTag({ property: 'og:description', content: og_meta_description});
    // this.meta.updateTag({ property: 'og:image', content: this.coverPreview.changingThisBreaksApplicationSecurity });
  }

  /**
   * @function find all user selected tickets info
   *
   */
  findAllSelectedTicketsInfo() {
    const tickets = [];
    Object.keys(this.choosedItems).forEach(cat => {
      Object.keys(this.choosedItems[cat]).forEach(id => {
        // console.log(id, this.choosedItems[cat][id]);
        if (id !== 'name') {
          const ticket = this.tickets.find(t => t.id === +id);
          for (let i = 0; i < this.choosedItems[cat][id].amount; i++) {
            tickets.push(ticket);
          }
        }
      });
    });

    return tickets;
  }

  showNftThanksModal(main_purchase_identifier) {
    this.facebookService
      .getPixelParams({ main_purchase_identifier: main_purchase_identifier })
      .subscribe(
        (params: any) => {
          if (params && params.code == 'success') {
            params.data.main_purchase_identifier = main_purchase_identifier;
            this.nftThankYouDialog.openThankYouPage(params);
          }
        },
        error => {}
      );
  }

  createSeatsConfig(event, workspaceKey, seatsUserConfig, region) {
    console.log('seatsUserConfig', seatsUserConfig);
    const pricing = this.prepareCorrectPricing(seatsUserConfig.pricing);
    const maxSelectedObjects = this.prepareMaxSelectedobjects(
      seatsUserConfig.pricing,
      this.tickets
    );
    const currencySymbol = getCurrencySymbol(this.currency, 'narrow');

    this.seatsConfig = {
      region: region, // e.g. "eu"
      workspaceKey: workspaceKey,
      event: event,
      session: 'continue',
      colorScheme: 'dark',
      language: this.localeId,
      style: {
        font: 'Montserrat',
      },
      showFullScreenButton: false,
      showMinimap: false,
      pricing: pricing,
      priceFormatter: price => {
        return currencySymbol + price;
      },
      maxSelectedObjects: maxSelectedObjects,
      // tooltipInfo: object => "[b]This[/b] object's [i]id[/i] is [pre]" + object.label + "[/pre]",
      // colors: {
      //   colorSelected: 'hsl(100, 96%, 53%)'
      // },
      categoryFilter: {
        enabled: true,
        multiSelect: true,
        zoomOnSelect: false,
      },
      onChartRendered: chart => {
        this.chartSeats = chart;
        console.info('Render Finished', 'chart: ', chart);
        this.chartSeatsRendered = true;
        this.globals.stopLoader();
        this.chartSeats.clearSelection();
        this.seatsQuantity = this.chartSeats.selectedObjects.length;
        this.seatsRendering = false;
        this.seatsonChartRendered.next(true);
      },
      onRenderStarted: chart => {
        console.info('Render Started');
        this.seatsRendering = true;
        // this.globals.startLoader();
      },
      onChartRenderingFailed: chart => {
        // chart.destroy();
        this.seatsRendering = false;
        this.globals.stopLoader();
      },
      onObjectSelected: object => {
        // add the selected seat id to the array

        // console.log('onObjectSelected this.selectedSeats', this.selectedSeats);
        if (this.chartSeats !== undefined && this.chartSeatsRendered) {
          // this.selectedSeats.push(object.label);
          // console.log('object', object);
          //console.log('onObjectSelected', this.chartSeats);
          this.seatsQuantity = this.chartSeats.selectedObjects.length;
          console.log(
            'onObjectSelected chartHoldToken',
            this.chartSeats.holdToken,
            this.chartSeats.selectedObjects
          );
          // if (this.my_tickets_seats !== undefined) {this.my_tickets_seats.close();}
          if (this.showSeatsDisplay) {
            this.calcSeatsTotalPrice();
          }
        }
      },
      onObjectDeselected: object => {
        // remove the deselected seat id from the array
        // let index = this.selectedSeats.indexOf(object.label);
        // if (index !== -1) this.selectedSeats.splice(index, 1);
        this.seatsQuantity = this.chartSeats.selectedObjects.length;
        if (this.showSeatsDisplay) {
          this.calcSeatsTotalPrice();
        }

        // if (this.my_tickets_seats !== undefined) {this.my_tickets_seats.close();}
      },
      onHoldTokenExpired: () => {
        // if (this.stepTemplate === 'Order') {
        //   console.log('Order HOLD')
        // }
        // if (this.stepTemplate === 'templateSeats') {
        //   console.log('templateSeats HOLD')
        // }
        // if (this.stepTemplate === 'templateDescription') {
        //   console.log('templateDescription HOLD')
        // }
        // console.log('onHoldTokenExpired', this.chartSeats.holdToken);
        this.seatsHoldToken = '';
        // console.log('onHoldTokenExpired', this.chartSeats.holdToken);
      },
      onSessionInitialized: holdToken => {
        this.seatsonSessionInitialized.next(true);
        this.seatsHoldToken = holdToken.token;
      },
    };
  }

  // Prepare correct pricing config array (without ticket_id, etc.)
  prepareCorrectPricing(pricing) {
    // console.log(pricing);
    const correctPricing = pricing
      .filter(i => {
        if (i.price && i.ticket_id && i.type === 'simple') {
          return i;
        }
        if (i.ticketTypes.length && i.type === 'multi') {
          return i;
        }
      })
      .map(i => {
        const item = {};
        if (i.price && i.ticket_id && i.type === 'simple') {
          item['category'] = i.category;
          item['price'] = i.price;
          return item;
        }
        if (i.ticketTypes.length && i.type === 'multi') {
          item['category'] = i.category;
          item['ticketTypes'] = i.ticketTypes;
          return item;
        }
      });
    // console.log('correctPricing', correctPricing);
    return correctPricing;
  }

  // https://docs.seats.io/docs/renderer/config-maxselectedobjects
  prepareMaxSelectedobjects(pricing, tickets) {
    let obj = [];
    pricing.forEach(cat => {
      let currentTicket = tickets.find(t => cat.ticket_id == t.id);
      if (currentTicket) {
        obj.push({
          category: cat.category,
          quantity: currentTicket.limits.length,
        });
      }
    });

    console.log('maxSelectedObjects', obj);
    return obj;
  }

  createAvailableListCategories(config) {
    config.pricing.forEach(cat => {
      // console.log(cat);
      if (cat.ticket_id || cat.ticketTypes.length) {
        this.seatsCategoriesList.push('' + cat.category);
      }
    });
    console.log('categoriesList', this.seatsCategoriesList);
  }

  getLocale(currency) {
    return this.currencyLocale.getLocaleForCurrencyPipe(currency);
  }

  eventSeatsBook() {
    return this.eventService.eventSeatsBook(
      this.event_id,
      this.chartSeats.selectedObjects,
      this.chartSeats.holdToken
    );
  }

  // Add additional classes and styles after render
  addStylesToElements() {
    if (this.user_agent.includes('instagram')) {
      // Navbar 'Order' btn visibility in Instagram browser
      this.isInstagramBrowser = true;
      this.checkBtnOrderIntervalID = setInterval(() => {
        // console.log('window.checkBtnOrderIntervalID', this.checkBtnOrderIntervalID);
        if(this.buy_ticket_btn_nui) {
          const btn = this.buy_ticket_btn_nui.nativeElement;
          if (btn && this.isMobile) {
            btn.style.position = 'fixed';
            btn.style.bottom = '6dvh';
            clearInterval(this.checkBtnOrderIntervalID);
          }
        }
      }, 500);
    }
  }

  // Track: Clicked Buy Tickets
  rudderstackBuyTickets() {
    this.rudderstackService.track('Clicked Buy Tickets', {
      event_id: this.event_details.event_id,
      event_name: this.event_details.title,
      category: this.event_categories,
      location: this.event_details.address,
      date: this.event_details.full_start_date,
    });
  }

  // Track: Added to Cart
  rudderstackAddedToCart(ticket_type, price) {
    this.rudderstackService.track('Added to Cart', {
      event_id: this.event_details.event_id,
      event_name: this.event_details.title,
      ticket_type: ticket_type,
      price: price,
      currency: this.currency,
      total: this.total.total_price,
    });
  }

  // Track: Removed from Cart
  rudderstackRemovedFromCart(ticket_type, price) {
    this.rudderstackService.track('Removed from Cart', {
      event_id: this.event_details.event_id,
      event_name: this.event_details.title,
      ticket_type: ticket_type,
      price: price,
      currency: this.currency,
      total: this.total.total_price,
    });
  }

  // Track: Started Checkout
  rudderstackStartedCheckout() {
    this.rudderstackService.track('Started Checkout', {
      event_id: this.event_details.event_id,
      event_name: this.event_details.title,
      total_amount: this.total.total_price,
    });
  }

  // Track: AddPaymentInfo
  rudderstackAddPaymentInfo(payment_method) {
    this.rudderstackService.track('AddPaymentInfo', {
      event_id: this.event_details.event_id,
      event_name: this.event_details.title,
      total_amount: this.total.total_price,
      payment_method: payment_method,
    });
  }

  isShowProducerSubscription() {
    const isFollowing = this.producerDetails.followers.indexOf(this.user_id);
    return (
      isFollowing == -1 &&
      this.event_details.producer_subscription &&
      !this.userSameAsProducer
    );
  }

  detectIfUserIsProducer() {
    if (!this.producer_id) {
      return;
    }
    if (this.producer_id.toString() === this.user_id.toString()) {
      this.userSameAsProducer = true;
    } else {
      this.userSameAsProducer = false;
    }
    if (!this.producerDetails) {
      return;
    }
    this.showProducerSubscription = this.isShowProducerSubscription();
  }

  scrollToBottomDiv(el) {
    if(el=='lineupEvent') { // For Line UP component
      setTimeout(() => {
        // console.log('setTimeout Scroll', this.templateBox.nativeElement.scrollTop, this.templateBox.nativeElement.scrollHeight);
        if (!this.isMobile) {
          // scroll inside container
          // this.templateBox.nativeElement.scrollTop = this.templateBox.nativeElement.scrollHeight;
          this.templateBox.nativeElement.scrollTo({top: this.templateBox.nativeElement.scrollTop + 150, left: 0, behavior: 'smooth'});
        } else {
          // scroll whole window on Mobile
          let matDrawerC = document.querySelector('mat-drawer-content');
          // console.log('setTimeout Scroll2', matDrawerC.scrollTop, matDrawerC.scrollHeight);
          matDrawerC.scrollTo({top: matDrawerC.scrollTop + 150, left: 0, behavior: 'smooth'});

        }

      }, 300);
      return
    }

    el.scrollTop = el.scrollHeight;
  }

  followAction(action) {
    // console.log('followProducer', action);
    if (action.action == 'follow') {
      this.producerDetails.followers.push(action.userId);
    }
    if (action.action == 'unfollow') {
      const index = this.producerDetails.followers.indexOf(action.userId);
      if (index > -1) {
        this.producerDetails.followers.splice(index, 1); // remove user from followers
      }
    }

    this.showProducerSubscription = this.isShowProducerSubscription();
    // console.log('followers', this.producerDetails.followers)
  }

  // Link example: https://localhost:4200/5161?ticket=555-2
  // ticket=555-2: 555 - is ticket id, 2 - is a quantity to select
  // we may add several ?ticket params
  selectTicketsFromUrlParams() {
    if (this.stepTemplate !== 'templateGuest') {
      this.stepTemplate = 'templateTickets'; // switch to tickets step
    }
    if (this.url_ticketsArr[0] === 'list') {
      return;
    }

    setTimeout(() => {
      // wait for user-tickets component init
      this.url_ticketsArr.forEach(t => {
        let t_id = t.split('-')[0]; // to get id before the '-'; for example: &ticket=555-2
        let t_qty = +t.split('-')[1]; // to get qty to select after the '-'; for example: &ticket=555-2
        const item = this.tickets.find(ticket => ticket.id === +t_id);
        // console.log('item', item, t, t_id, t_qty, 'this.tickets', this.tickets);
        while (t_qty) {
          if (item && item.availability === 1) {
            this.eventTicketsService.editTicket(item, 'plus', item.category_id, 1);
            
            if (this.stepTemplate === 'templateGuest') {
              this.editTicket(item, 'plus', item.category_id, 1);
            }
          }
          t_qty--;
        }
      });

      if (this.stepTemplate === 'templateGuest') {
        if (this.isInit) {
          this.getParticipantData();
        }
      }
    }, 700);
  }
  // Link example: https://localhost:4200/5161?ticket=125-17-6
  // ticket=125-17-6: 125-17-6 - is SEAT ID
  // we may add several ?ticket params
  selectTicketsFromUrlParamsSeats() {
    // console.log('selectTicketsFromUrlParamsSeats', this.url_ticketsArr, this.chartSeats)
    setTimeout(() => {
      this.chartSeats.selectObjects(this.url_ticketsArr);
    }, 300);
  }

  saveTicketsToLocaleStorage() {
    let tickets = this.findAllSelectedTicketsForLocaleStorage();
    let tickets_data = {
      products: tickets,
    };

    if (this.purchase_id) {
      tickets_data['order_id'] = this.purchase_id;
    }
    if (this.total_discount) {
      tickets_data['discount'] = +this.total_discount.toFixed(2);
    }
    if (this.discount_search_key) {
      tickets_data['coupon'] = this.discount_search_key;
    }
    if (this.tax_and_fees) {
      tickets_data['tax'] = +this.tax_and_fees.toFixed(2);
    }
    localStorage.setItem('tickets_data', JSON.stringify(tickets_data));
  }

  saveSubscribeToLocaleStorage(subscribe_status) {
    let subscribe_data = {
      subscribe_status: subscribe_status ? true : false,
      user_id: this.user_id,
      producer_id: this.producer_id,
      event_id: this.event_id,
    };
    localStorage.setItem('subscribe_data', JSON.stringify(subscribe_data));
  }

  async liveCart() {
    const spike_protected_session = sessionStorage.getItem('spike_protection_session');
    if (spike_protected_session) {
      let tickets = this.prepareLiveCartTickets();

      this.eventService.addLiveCart(this.event_id, tickets).subscribe((res: any) => {
        if(res.code == 'success' && res.data && res.data.live_cart_hash) {
          this.live_cart_hash = res.data.live_cart_hash;
        }
      }, error => {
/*        this.live_cart_hash = 'fsdfdsfdsf4';*/
      });


    }
  }
  updateLiveCart(immediateFetch = false) {
    const spike_protected_session = sessionStorage.getItem('spike_protection_session');
    if (spike_protected_session) {
      // Clear Old Interval
      if(this.live_cart_hashIntervalID) {
        clearInterval(this.live_cart_hashIntervalID);
      }
      // Immediately Patch tickets
      if(immediateFetch) {
        let tickets = this.prepareLiveCartTickets();
        this.eventService.updateLiveCart(this.event_id, tickets, this.live_cart_hash).subscribe((res: any) => {}, error => {});
      }

      // Patch tickets with Interval
      this.live_cart_hashIntervalID = setInterval(() => {
        // console.log('live_cart_hashIntervalID', this.live_cart_hashIntervalID);
        if (this.stepTemplate == 'templateDescription' || this.stepTemplate == 'templateTickets' ||  this.stepTemplate == 'templatePay') {
          clearInterval(this.live_cart_hashIntervalID);
        } else {
          let tickets = this.prepareLiveCartTickets();
          this.eventService.updateLiveCart(this.event_id, tickets, this.live_cart_hash).subscribe((res: any) => {}, error => {})
        }

      }, 60000);
    }

  }

  deleteLiveCart() {
    console.log('Delete Live Cart')
    if(this.live_cart_hash && !this.deleteLiveCartFetching) {
      this.deleteLiveCartFetching = true;
      this.eventService.deleteLiveCart(this.event_id,this.live_cart_hash).subscribe(()=> {
        this.live_cart_hash = '';
        this.deleteLiveCartFetching = false;
      });
    }
    if (this.live_cart_hashIntervalID) {
      clearInterval(this.live_cart_hashIntervalID);
    }
  }

  prepareLiveCartTickets() {
    let tickets = [];
    let ticketsObj = this.findAllSelectedTicketsObj();
    for (const key in ticketsObj) {
      if (ticketsObj.hasOwnProperty(key)) {
        tickets.push({
          ticket_id: +key,
          count: ticketsObj[key]
        })
      }
    }
    return tickets
  }

  issendSmsTimer(time) {
    // console.log('issendSmsTimer', time);
    this.sendSmsTimer = time;
  }

  ngOnDestroy(): void {
    // console.log('OnDestroy event-page');
    this.eventTicketsService.clearDataTickets();
    this.eventTicketsService.clearDataParticipents();
    this.subscriptionCalcTotalPrice.unsubscribe();
    this.subscriptionCheckPromo.unsubscribe();
    // this.fb_pixel_model.removeGetInPixelInit(this.renderer);
    this.fb_pixel_model.removePixelInit(this.renderer, this.event_pixel);
    this.marketing = null;
    if (this.checkPaymentSubscription) {
      this.checkPaymentSubscription.unsubscribe();
    }
    this.nav.setIsNewUi(false);
    this.nav.noPadding = '';
    // this.nav.show();
    const header = document.getElementsByTagName('header')[0];
    if (header) {
      header.style.display = 'block';
    }
    if (this.currentUserSubscription) {
      this.currentUserSubscription.unsubscribe();
    }
    if (this.currentFacebookLoginDataSubscription) {
      this.currentFacebookLoginDataSubscription.unsubscribe();
    }
    if (this.walletLoginSubscription) {
      this.walletLoginSubscription.unsubscribe();
    }
    this.titleService.setTitle('Getin');
    this.nav.showHeader();
    // this.card.removeEventListener('change', (error) => {
    //   this.onChange(error);
    // });
    // this.card.destroy();
    // window.removeEventListener('scroll', this.onSwipe, true);
    if (this.checkStripeWindowID) {
      clearInterval(this.checkStripeWindowID);
    }
    if (this.checkBtnOrderIntervalID) {
      clearInterval(this.checkBtnOrderIntervalID);
    }
    if (this.event_fetched) {
      this.event_fetched.unsubscribe();
    }
    if (this.combineLatestSubscription) {
      this.combineLatestSubscription.unsubscribe();
    }
    if(this.live_cart_hashIntervalID) {
      clearInterval(this.live_cart_hashIntervalID);
    }
  }
}
