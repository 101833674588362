import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  api_base_url = environment.api_base_url;
  constructor(
    private http: HttpClient
  ) { }

  sendSupportForm(data: any) {
    return this.http.post(this.api_base_url + '/api/support-page-form', data,
        {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
    });
  }

  sendContactForm(data: any) {
    return this.http.post(this.api_base_url + '/api/contact-page-form', data,
        {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
    });
  }

}
