export default [
  {
    id: 2,
    name: {
      he: 'BANAMEX',
      en: 'BANAMEX',
      es: 'BANAMEX'
    },
    fullname: {
      he: 'Banco Nacional de México, S.A., Institución de Banca Múltiple, Grupo Financiero Banamex',
      en: 'Banco Nacional de México, S.A., Institución de Banca Múltiple, Grupo Financiero Banamex',
      es: 'Banco Nacional de México, S.A., Institución de Banca Múltiple, Grupo Financiero Banamex'
    }
  },
  {
    id: 6,
    name: {
      he: 'BANCOMEXT',
      en: 'BANCOMEXT',
      es: 'BANCOMEXT'
    },
    fullname: {
      he: 'Banco Nacional de Comercio Exterior, Sociedad Nacional de Crédito, Institución de Banca de Desarrollo',
      en: 'Banco Nacional de Comercio Exterior, Sociedad Nacional de Crédito, Institución de Banca de Desarrollo',
      es: 'Banco Nacional de Comercio Exterior, Sociedad Nacional de Crédito, Institución de Banca de Desarrollo'
    }
  },
  {
    id: 9,
    name: {
      he: 'BANOBRAS',
      en: 'BANOBRAS',
      es: 'BANOBRAS'
    },
    fullname: {
      he: 'Banco Nacional de Obras y Servicios Públicos, Sociedad Nacional de Crédito, Institución de Banca de Desarrollo',
      en: 'Banco Nacional de Obras y Servicios Públicos, Sociedad Nacional de Crédito, Institución de Banca de Desarrollo',
      es: 'Banco Nacional de Obras y Servicios Públicos, Sociedad Nacional de Crédito, Institución de Banca de Desarrollo'
    }
  },
  {
    id: 12,
    name: {
      he: 'BBVA BANCOMER',
      en: 'BBVA BANCOMER',
      es: 'BBVA BANCOMER'
    },
    fullname: {
      he: 'BBVA Bancomer, S.A., Institución de Banca Múltiple, Grupo Financiero BBVA Bancomer',
      en: 'BBVA Bancomer, S.A., Institución de Banca Múltiple, Grupo Financiero BBVA Bancomer',
      es: 'BBVA Bancomer, S.A., Institución de Banca Múltiple, Grupo Financiero BBVA Bancomer'
    }
  },
  {
    id: 14,
    name: {
      he: 'SANTANDER',
      en: 'SANTANDER',
      es: 'SANTANDER'
    },
    fullname: {
      he: 'Banco Santander (México), S.A., Institución de Banca Múltiple, Grupo Financiero Santander',
      en: 'Banco Santander (México), S.A., Institución de Banca Múltiple, Grupo Financiero Santander',
      es: 'Banco Santander (México), S.A., Institución de Banca Múltiple, Grupo Financiero Santander'
    }
  },
  {
    id: 19,
    name: {
      he: 'BANJERCITO',
      en: 'BANJERCITO',
      es: 'BANJERCITO'
    },
    fullname: {
      he: 'Banco Nacional del Ejército, Fuerza Aérea y Armada, Sociedad Nacional de Crédito, Institución de Banca de Desarrollo',
      en: 'Banco Nacional del Ejército, Fuerza Aérea y Armada, Sociedad Nacional de Crédito, Institución de Banca de Desarrollo',
      es: 'Banco Nacional del Ejército, Fuerza Aérea y Armada, Sociedad Nacional de Crédito, Institución de Banca de Desarrollo'
    }
  },
  {
    id: 21,
    name: {
      he: 'HSBC',
      en: 'HSBC',
      es: 'HSBC'
    },
    fullname: {
      he: 'HSBC México, S.A., institución De Banca Múltiple, Grupo Financiero HSBC',
      en: 'HSBC México, S.A., institución De Banca Múltiple, Grupo Financiero HSBC',
      es: 'HSBC México, S.A., institución De Banca Múltiple, Grupo Financiero HSBC'
    }
  },
  {
    id: 30,
    name: {
      he: 'BAJIO',
      en: 'BAJIO',
      es: 'BAJIO'
    },
    fullname: {
      he: 'Banco del Bajío, S.A., Institución de Banca Múltiple',
      en: 'Banco del Bajío, S.A., Institución de Banca Múltiple',
      es: 'Banco del Bajío, S.A., Institución de Banca Múltiple'
    }
  },
  {
    id: 32,
    name: {
      he: 'IXE',
      en: 'IXE',
      es: 'IXE'
    },
    fullname: {
      he: 'Banco, S.A., Institución de Banca Múltiple, IXE Grupo Financiero',
      en: 'Banco, S.A., Institución de Banca Múltiple, IXE Grupo Financiero',
      es: 'Banco, S.A., Institución de Banca Múltiple, IXE Grupo Financiero'
    }
  },
  {
    id: 36,
    name: {
      he: 'INBURSA',
      en: 'INBURSA',
      es: 'INBURSA'
    },
    fullname: {
      he: 'Banco Inbursa, S.A., Institución de Banca Múltiple, Grupo Financiero Inbursa',
      en: 'Banco Inbursa, S.A., Institución de Banca Múltiple, Grupo Financiero Inbursa',
      es: 'Banco Inbursa, S.A., Institución de Banca Múltiple, Grupo Financiero Inbursa'
    }
  },
  {
    id: 37,
    name: {
      he: 'INTERACCIONES',
      en: 'INTERACCIONES',
      es: 'INTERACCIONES'
    },
    fullname: {
      he: 'Banco Interacciones, S.A., Institución de Banca Múltiple',
      en: 'Banco Interacciones, S.A., Institución de Banca Múltiple',
      es: 'Banco Interacciones, S.A., Institución de Banca Múltiple'
    }
  },
  {
    id: 42,
    name: {
      he: 'MIFEL',
      en: 'MIFEL',
      es: 'MIFEL'
    },
    fullname: {
      he: 'Banca Mifel, S.A., Institución de Banca Múltiple, Grupo Financiero Mifel',
      en: 'Banca Mifel, S.A., Institución de Banca Múltiple, Grupo Financiero Mifel',
      es: 'Banca Mifel, S.A., Institución de Banca Múltiple, Grupo Financiero Mifel'
    }
  },
  {
    id: 44,
    name: {
      he: 'SCOTIABANK',
      en: 'SCOTIABANK',
      es: 'SCOTIABANK'
    },
    fullname: {
      he: 'Scotiabank Inverlat, S.A.',
      en: 'Scotiabank Inverlat, S.A.',
      es: 'Scotiabank Inverlat, S.A.'
    }
  },
  {
    id: 58,
    name: {
      he: 'BANREGIO',
      en: 'BANREGIO',
      es: 'BANREGIO'
    },
    fullname: {
      he: 'Banco Regional de Monterrey, S.A., Institución de Banca Múltiple, Banregio Grupo Financiero',
      en: 'Banco Regional de Monterrey, S.A., Institución de Banca Múltiple, Banregio Grupo Financiero',
      es: 'Banco Regional de Monterrey, S.A., Institución de Banca Múltiple, Banregio Grupo Financiero'
    }
  },
  {
    id: 59,
    name: {
      he: 'INVEX',
      en: 'INVEX',
      es: 'INVEX'
    },
    fullname: {
      he: 'Banco Invex, S.A., Institución de Banca Múltiple, Invex Grupo Financiero',
      en: 'Banco Invex, S.A., Institución de Banca Múltiple, Invex Grupo Financiero',
      es: 'Banco Invex, S.A., Institución de Banca Múltiple, Invex Grupo Financiero'
    }
  },
  {
    id: 60,
    name: {
      he: 'BANSI',
      en: 'BANSI',
      es: 'BANSI'
    },
    fullname: {
      he: 'Bansi, S.A., Institución de Banca Múltiple',
      en: 'Bansi, S.A., Institución de Banca Múltiple',
      es: 'Bansi, S.A., Institución de Banca Múltiple'
    }
  },
  {
    id: 62,
    name: {
      he: 'AFIRME',
      en: 'AFIRME',
      es: 'AFIRME'
    },
    fullname: {
      he: 'Banca Afirme, S.A., Institución de Banca Múltiple',
      en: 'Banca Afirme, S.A., Institución de Banca Múltiple',
      es: 'Banca Afirme, S.A., Institución de Banca Múltiple'
    }
  },
  {
    id: 72,
    name: {
      he: 'BANORTE',
      en: 'BANORTE',
      es: 'BANORTE'
    },
    fullname: {
      he: 'Banco Mercantil del Norte, S.A., Institución de Banca Múltiple, Grupo Financiero Banorte',
      en: 'Banco Mercantil del Norte, S.A., Institución de Banca Múltiple, Grupo Financiero Banorte',
      es: 'Banco Mercantil del Norte, S.A., Institución de Banca Múltiple, Grupo Financiero Banorte'
    }
  },
  {
    id: 102,
    name: {
      he: 'THE ROYAL BANK',
      en: 'THE ROYAL BANK',
      es: 'THE ROYAL BANK'
    },
    fullname: {
      he: 'The Royal Bank of Scotland México, S.A., Institución de Banca Múltiple',
      en: 'The Royal Bank of Scotland México, S.A., Institución de Banca Múltiple',
      es: 'The Royal Bank of Scotland México, S.A., Institución de Banca Múltiple'
    }
  },
  {
    id: 103,
    name: {
      he: 'AMERICAN EXPRESS',
      en: 'AMERICAN EXPRESS',
      es: 'AMERICAN EXPRESS'
    },
    fullname: {
      he: 'American Express Bank (México), S.A., Institución de Banca Múltiple',
      en: 'American Express Bank (México), S.A., Institución de Banca Múltiple',
      es: 'American Express Bank (México), S.A., Institución de Banca Múltiple'
    }
  },
  {
    id: 106,
    name: {
      he: 'BAMSA',
      en: 'BAMSA',
      es: 'BAMSA'
    },
    fullname: {
      he: 'Bank of America México, S.A., Institución de Banca Múltiple, Grupo Financiero Bank of America',
      en: 'Bank of America México, S.A., Institución de Banca Múltiple, Grupo Financiero Bank of America',
      es: 'Bank of America México, S.A., Institución de Banca Múltiple, Grupo Financiero Bank of America'
    }
  },
  {
    id: 108,
    name: {
      he: 'TOKYO',
      en: 'TOKYO',
      es: 'TOKYO'
    },
    fullname: {
      he: 'Bank of Tokyo-Mitsubishi UFJ (México), S.A.',
      en: 'Bank of Tokyo-Mitsubishi UFJ (México), S.A.',
      es: 'Bank of Tokyo-Mitsubishi UFJ (México), S.A.'
    }
  },
  {
    id: 110,
    name: {
      he: 'JP MORGAN',
      en: 'JP MORGAN',
      es: 'JP MORGAN'
    },
    fullname: {
      he: 'Banco J.P. Morgan, S.A., Institución de Banca Múltiple, J.P. Morgan Grupo Financiero',
      en: 'Banco J.P. Morgan, S.A., Institución de Banca Múltiple, J.P. Morgan Grupo Financiero',
      es: 'Banco J.P. Morgan, S.A., Institución de Banca Múltiple, J.P. Morgan Grupo Financiero'
    }
  },
  {
    id: 112,
    name: {
      he: 'BMONEX',
      en: 'BMONEX',
      es: 'BMONEX'
    },
    fullname: {
      he: 'Banco Monex, S.A., Institución de Banca Múltiple',
      en: 'Banco Monex, S.A., Institución de Banca Múltiple',
      es: 'Banco Monex, S.A., Institución de Banca Múltiple'
    }
  },
  {
    id: 113,
    name: {
      he: 'VE POR MAS',
      en: 'VE POR MAS',
      es: 'VE POR MAS'
    },
    fullname: {
      he: 'Banco Ve Por Mas, S.A. Institución de Banca Múltiple',
      en: 'Banco Ve Por Mas, S.A. Institución de Banca Múltiple',
      es: 'Banco Ve Por Mas, S.A. Institución de Banca Múltiple'
    }
  },
  {
    id: 116,
    name: {
      he: 'ING',
      en: 'ING',
      es: 'ING'
    },
    fullname: {
      he: 'ING Bank (México), S.A., Institución de Banca Múltiple, ING Grupo Financiero',
      en: 'ING Bank (México), S.A., Institución de Banca Múltiple, ING Grupo Financiero',
      es: 'ING Bank (México), S.A., Institución de Banca Múltiple, ING Grupo Financiero'
    }
  },
  {
    id: 124,
    name: {
      he: 'DEUTSCHE',
      en: 'DEUTSCHE',
      es: 'DEUTSCHE'
    },
    fullname: {
      he: 'Deutsche Bank México, S.A., Institución de Banca Múltiple',
      en: 'Deutsche Bank México, S.A., Institución de Banca Múltiple',
      es: 'Deutsche Bank México, S.A., Institución de Banca Múltiple'
    }
  },
  {
    id: 126,
    name: {
      he: 'CREDIT SUISSE',
      en: 'CREDIT SUISSE',
      es: 'CREDIT SUISSE'
    },
    fullname: {
      he: 'Banco Credit Suisse (México), S.A. Institución de Banca Múltiple, Grupo Financiero Credit Suisse (México)',
      en: 'Banco Credit Suisse (México), S.A. Institución de Banca Múltiple, Grupo Financiero Credit Suisse (México)',
      es: 'Banco Credit Suisse (México), S.A. Institución de Banca Múltiple, Grupo Financiero Credit Suisse (México)'
    }
  },
  {
    id: 127,
    name: {
      he: 'AZTECA',
      en: 'AZTECA',
      es: 'AZTECA'
    },
    fullname: {
      he: 'Banco Azteca, S.A. Institución de Banca Múltiple',
      en: 'Banco Azteca, S.A. Institución de Banca Múltiple',
      es: 'Banco Azteca, S.A. Institución de Banca Múltiple'
    }
  },
  {
    id: 128,
    name: {
      he: 'AUTOFIN',
      en: 'AUTOFIN',
      es: 'AUTOFIN'
    },
    fullname: {
      he: 'Banco Autofin México, S.A. Institución de Banca Múltiple',
      en: 'Banco Autofin México, S.A. Institución de Banca Múltiple',
      es: 'Banco Autofin México, S.A. Institución de Banca Múltiple'
    }
  },
  {
    id: 129,
    name: {
      he: 'BARCLAYS',
      en: 'BARCLAYS',
      es: 'BARCLAYS'
    },
    fullname: {
      he: 'Barclays Bank México, S.A., Institución de Banca Múltiple, Grupo Financiero Barclays México',
      en: 'Barclays Bank México, S.A., Institución de Banca Múltiple, Grupo Financiero Barclays México',
      es: 'Barclays Bank México, S.A., Institución de Banca Múltiple, Grupo Financiero Barclays México'
    }
  },
  {
    id: 130,
    name: {
      he: 'COMPARTAMOS',
      en: 'COMPARTAMOS',
      es: 'COMPARTAMOS'
    },
    fullname: {
      he: 'Banco Compartamos, S.A., Institución de Banca Múltiple',
      en: 'Banco Compartamos, S.A., Institución de Banca Múltiple',
      es: 'Banco Compartamos, S.A., Institución de Banca Múltiple'
    }
  },
  {
    id: 131,
    name: {
      he: 'BANCO FAMSA',
      en: 'BANCO FAMSA',
      es: 'BANCO FAMSA'
    },
    fullname: {
      he: 'Banco Ahorro Famsa, S.A., Institución de Banca Múltiple',
      en: 'Banco Ahorro Famsa, S.A., Institución de Banca Múltiple',
      es: 'Banco Ahorro Famsa, S.A., Institución de Banca Múltiple'
    }
  },
  {
    id: 132,
    name: {
      he: 'BMULTIVA',
      en: 'BMULTIVA',
      es: 'BMULTIVA'
    },
    fullname: {
      he: 'Banco Multiva, S.A., Institución de Banca Múltiple, Multivalores Grupo Financiero',
      en: 'Banco Multiva, S.A., Institución de Banca Múltiple, Multivalores Grupo Financiero',
      es: 'Banco Multiva, S.A., Institución de Banca Múltiple, Multivalores Grupo Financiero'
    }
  },
  {
    id: 133,
    name: {
      he: 'ACTINVER',
      en: 'ACTINVER',
      es: 'ACTINVER'
    },
    fullname: {
      he: 'Banco Actinver, S.A. Institución de Banca Múltiple, Grupo Financiero Actinver',
      en: 'Banco Actinver, S.A. Institución de Banca Múltiple, Grupo Financiero Actinver',
      es: 'Banco Actinver, S.A. Institución de Banca Múltiple, Grupo Financiero Actinver'
    }
  },
  {
    id: 134,
    name: {
      he: 'WAL-MART',
      en: 'WAL-MART',
      es: 'WAL-MART'
    },
    fullname: {
      he: 'Banco Wal-Mart de México Adelante, S.A., Institución de Banca Múltiple',
      en: 'Banco Wal-Mart de México Adelante, S.A., Institución de Banca Múltiple',
      es: 'Banco Wal-Mart de México Adelante, S.A., Institución de Banca Múltiple'
    }
  },
  {
    id: 135,
    name: {
      he: 'NAFIN',
      en: 'NAFIN',
      es: 'NAFIN'
    },
    fullname: {
      he: 'Nacional Financiera, Sociedad Nacional de Crédito, Institución de Banca de Desarrollo',
      en: 'Nacional Financiera, Sociedad Nacional de Crédito, Institución de Banca de Desarrollo',
      es: 'Nacional Financiera, Sociedad Nacional de Crédito, Institución de Banca de Desarrollo'
    }
  },
  {
    id: 136,
    name: {
      he: 'INTERBANCO',
      en: 'INTERBANCO',
      es: 'INTERBANCO'
    },
    fullname: {
      he: 'Inter Banco, S.A. Institución de Banca Múltiple',
      en: 'Inter Banco, S.A. Institución de Banca Múltiple',
      es: 'Inter Banco, S.A. Institución de Banca Múltiple'
    }
  },
  {
    id: 137,
    name: {
      he: 'BANCOPPEL',
      en: 'BANCOPPEL',
      es: 'BANCOPPEL'
    },
    fullname: {
      he: 'BanCoppel, S.A., Institución de Banca Múltiple',
      en: 'BanCoppel, S.A., Institución de Banca Múltiple',
      es: 'BanCoppel, S.A., Institución de Banca Múltiple'
    }
  },
  {
    id: 138,
    name: {
      he: 'ABC CAPITAL',
      en: 'ABC CAPITAL',
      es: 'ABC CAPITAL'
    },
    fullname: {
      he: 'ABC Capital, S.A., Institución de Banca Múltiple',
      en: 'ABC Capital, S.A., Institución de Banca Múltiple',
      es: 'ABC Capital, S.A., Institución de Banca Múltiple'
    }
  },
  {
    id: 139,
    name: {
      he: 'UBS BANK',
      en: 'UBS BANK',
      es: 'UBS BANK'
    },
    fullname: {
      he: 'UBS Bank México, S.A., Institución de Banca Múltiple, UBS Grupo Financiero',
      en: 'UBS Bank México, S.A., Institución de Banca Múltiple, UBS Grupo Financiero',
      es: 'UBS Bank México, S.A., Institución de Banca Múltiple, UBS Grupo Financiero'
    }
  },
  {
    id: 140,
    name: {
      he: 'CONSUBANCO',
      en: 'CONSUBANCO',
      es: 'CONSUBANCO'
    },
    fullname: {
      he: 'Consubanco, S.A. Institución de Banca Múltiple',
      en: 'Consubanco, S.A. Institución de Banca Múltiple',
      es: 'Consubanco, S.A. Institución de Banca Múltiple'
    }
  },
  {
    id: 141,
    name: {
      he: 'VOLKSWAGEN',
      en: 'VOLKSWAGEN',
      es: 'VOLKSWAGEN'
    },
    fullname: {
      he: 'Volkswagen Bank, S.A., Institución de Banca Múltiple',
      en: 'Volkswagen Bank, S.A., Institución de Banca Múltiple',
      es: 'Volkswagen Bank, S.A., Institución de Banca Múltiple'
    }
  },
  {
    id: 143,
    name: {
      he: 'CIBANCO',
      en: 'CIBANCO',
      es: 'CIBANCO'
    },
    fullname: {
      he: 'CIBanco, S.A.',
      en: 'CIBanco, S.A.',
      es: 'CIBanco, S.A.'
    }
  },
  {
    id: 145,
    name: {
      he: 'BBASE',
      en: 'BBASE',
      es: 'BBASE'
    },
    fullname: {
      he: 'Banco Base, S.A., Institución de Banca Múltiple',
      en: 'Banco Base, S.A., Institución de Banca Múltiple',
      es: 'Banco Base, S.A., Institución de Banca Múltiple'
    }
  },
  {
    id: 166,
    name: {
      he: 'BANSEFI',
      en: 'BANSEFI',
      es: 'BANSEFI'
    },
    fullname: {
      he: 'Banco del Ahorro Nacional y Servicios Financieros, Sociedad Nacional de Crédito, Institución de Banca de Desarrollo',
      en: 'Banco del Ahorro Nacional y Servicios Financieros, Sociedad Nacional de Crédito, Institución de Banca de Desarrollo',
      es: 'Banco del Ahorro Nacional y Servicios Financieros, Sociedad Nacional de Crédito, Institución de Banca de Desarrollo'
    }
  },
  {
    id: 168,
    name: {
      he: 'HIPOTECARIA FEDERAL',
      en: 'HIPOTECARIA FEDERAL',
      es: 'HIPOTECARIA FEDERAL'
    },
    fullname: {
      he: 'Sociedad Hipotecaria Federal, Sociedad Nacional de Crédito, Institución de Banca de Desarrollo',
      en: 'Sociedad Hipotecaria Federal, Sociedad Nacional de Crédito, Institución de Banca de Desarrollo',
      es: 'Sociedad Hipotecaria Federal, Sociedad Nacional de Crédito, Institución de Banca de Desarrollo'
    }
  },
  {
    id: 600,
    name: {
      he: 'MONEXCB',
      en: 'MONEXCB',
      es: 'MONEXCB'
    },
    fullname: {
      he: 'Monex Casa de Bolsa, S.A. de C.V. Monex Grupo Financiero',
      en: 'Monex Casa de Bolsa, S.A. de C.V. Monex Grupo Financiero',
      es: 'Monex Casa de Bolsa, S.A. de C.V. Monex Grupo Financiero'
    }
  },
  {
    id: 601,
    name: {
      he: 'GBM',
      en: 'GBM',
      es: 'GBM'
    },
    fullname: {
      he: 'GBM Grupo Bursátil Mexicano, S.A. de C.V. Casa de Bolsa',
      en: 'GBM Grupo Bursátil Mexicano, S.A. de C.V. Casa de Bolsa',
      es: 'GBM Grupo Bursátil Mexicano, S.A. de C.V. Casa de Bolsa'
    }
  },
  {
    id: 602,
    name: {
      he: 'MASARI',
      en: 'MASARI',
      es: 'MASARI'
    },
    fullname: {
      he: 'Masari Casa de Bolsa, S.A.',
      en: 'Masari Casa de Bolsa, S.A.',
      es: 'Masari Casa de Bolsa, S.A.'
    }
  },
  {
    id: 605,
    name: {
      he: 'VALUE',
      en: 'VALUE',
      es: 'VALUE'
    },
    fullname: {
      he: 'Value, S.A. de C.V. Casa de Bolsa',
      en: 'Value, S.A. de C.V. Casa de Bolsa',
      es: 'Value, S.A. de C.V. Casa de Bolsa'
    }
  },
  {
    id: 606,
    name: {
      he: 'ESTRUCTURADORES',
      en: 'ESTRUCTURADORES',
      es: 'ESTRUCTURADORES'
    },
    fullname: {
      he: 'Estructuradores del Mercado de Valores Casa de Bolsa, S.A. de C.V.',
      en: 'Estructuradores del Mercado de Valores Casa de Bolsa, S.A. de C.V.',
      es: 'Estructuradores del Mercado de Valores Casa de Bolsa, S.A. de C.V.'
    }
  },
  {
    id: 607,
    name: {
      he: 'TIBER',
      en: 'TIBER',
      es: 'TIBER'
    },
    fullname: {
      he: 'Casa de Cambio Tiber, S.A. de C.V.',
      en: 'Casa de Cambio Tiber, S.A. de C.V.',
      es: 'Casa de Cambio Tiber, S.A. de C.V.'
    }
  },
  {
    id: 608,
    name: {
      he: 'VECTOR',
      en: 'VECTOR',
      es: 'VECTOR'
    },
    fullname: {
      he: 'Vector Casa de Bolsa, S.A. de C.V.',
      en: 'Vector Casa de Bolsa, S.A. de C.V.',
      es: 'Vector Casa de Bolsa, S.A. de C.V.'
    }
  },
  {
    id: 610,
    name: {
      he: 'B&B',
      en: 'B&B',
      es: 'B&B'
    },
    fullname: {
      he: 'B y B, Casa de Cambio, S.A. de C.V.',
      en: 'B y B, Casa de Cambio, S.A. de C.V.',
      es: 'B y B, Casa de Cambio, S.A. de C.V.'
    }
  },
  {
    id: 614,
    name: {
      he: 'ACCIVAL',
      en: 'ACCIVAL',
      es: 'ACCIVAL'
    },
    fullname: {
      he: 'Acciones y Valores Banamex, S.A. de C.V., Casa de Bolsa',
      en: 'Acciones y Valores Banamex, S.A. de C.V., Casa de Bolsa',
      es: 'Acciones y Valores Banamex, S.A. de C.V., Casa de Bolsa'
    }
  },
  {
    id: 615,
    name: {
      he: 'MERRILL LYNCH',
      en: 'MERRILL LYNCH',
      es: 'MERRILL LYNCH'
    },
    fullname: {
      he: 'Merrill Lynch México, S.A. de C.V. Casa de Bolsa',
      en: 'Merrill Lynch México, S.A. de C.V. Casa de Bolsa',
      es: 'Merrill Lynch México, S.A. de C.V. Casa de Bolsa'
    }
  },
  {
    id: 616,
    name: {
      he: 'FINAMEX',
      en: 'FINAMEX',
      es: 'FINAMEX'
    },
    fullname: {
      he: 'Casa de Bolsa Finamex, S.A. de C.V.',
      en: 'Casa de Bolsa Finamex, S.A. de C.V.',
      es: 'Casa de Bolsa Finamex, S.A. de C.V.'
    }
  },
  {
    id: 617,
    name: {
      he: 'VALMEX',
      en: 'VALMEX',
      es: 'VALMEX'
    },
    fullname: {
      he: 'Valores Mexicanos Casa de Bolsa, S.A. de C.V.',
      en: 'Valores Mexicanos Casa de Bolsa, S.A. de C.V.',
      es: 'Valores Mexicanos Casa de Bolsa, S.A. de C.V.'
    }
  },
  {
    id: 618,
    name: {
      he: 'UNICA',
      en: 'UNICA',
      es: 'UNICA'
    },
    fullname: {
      he: 'Unica Casa de Cambio, S.A. de C.V.',
      en: 'Unica Casa de Cambio, S.A. de C.V.',
      es: 'Unica Casa de Cambio, S.A. de C.V.'
    }
  },
  {
    id: 619,
    name: {
      he: 'MAPFRE',
      en: 'MAPFRE',
      es: 'MAPFRE'
    },
    fullname: {
      he: 'MAPFRE Tepeyac, S.A.',
      en: 'MAPFRE Tepeyac, S.A.',
      es: 'MAPFRE Tepeyac, S.A.'
    }
  },
  {
    id: 620,
    name: {
      he: 'PROFUTURO',
      en: 'PROFUTURO',
      es: 'PROFUTURO'
    },
    fullname: {
      he: 'Profuturo G.N.P., S.A. de C.V., Afore',
      en: 'Profuturo G.N.P., S.A. de C.V., Afore',
      es: 'Profuturo G.N.P., S.A. de C.V., Afore'
    }
  },
  {
    id: 621,
    name: {
      he: 'CB ACTINVER',
      en: 'CB ACTINVER',
      es: 'CB ACTINVER'
    },
    fullname: {
      he: 'Actinver Casa de Bolsa, S.A. de C.V.',
      en: 'Actinver Casa de Bolsa, S.A. de C.V.',
      es: 'Actinver Casa de Bolsa, S.A. de C.V.'
    }
  },
  {
    id: 622,
    name: {
      he: 'OACTIN',
      en: 'OACTIN',
      es: 'OACTIN'
    },
    fullname: {
      he: 'OPERADORA ACTINVER, S.A. DE C.V.',
      en: 'OPERADORA ACTINVER, S.A. DE C.V.',
      es: 'OPERADORA ACTINVER, S.A. DE C.V.'
    }
  },
  {
    id: 623,
    name: {
      he: 'SKANDIA',
      en: 'SKANDIA',
      es: 'SKANDIA'
    },
    fullname: {
      he: 'Skandia Vida, S.A. de C.V.',
      en: 'Skandia Vida, S.A. de C.V.',
      es: 'Skandia Vida, S.A. de C.V.'
    }
  },
  {
    id: 626,
    name: {
      he: 'CBDEUTSCHE',
      en: 'CBDEUTSCHE',
      es: 'CBDEUTSCHE'
    },
    fullname: {
      he: 'Deutsche Securities, S.A. de C.V. CASA DE BOLSA',
      en: 'Deutsche Securities, S.A. de C.V. CASA DE BOLSA',
      es: 'Deutsche Securities, S.A. de C.V. CASA DE BOLSA'
    }
  },
  {
    id: 627,
    name: {
      he: 'ZURICH',
      en: 'ZURICH',
      es: 'ZURICH'
    },
    fullname: {
      he: 'Zurich Compañía de Seguros, S.A.',
      en: 'Zurich Compañía de Seguros, S.A.',
      es: 'Zurich Compañía de Seguros, S.A.'
    }
  },
  {
    id: 628,
    name: {
      he: 'ZURICHVI',
      en: 'ZURICHVI',
      es: 'ZURICHVI'
    },
    fullname: {
      he: 'Zurich Vida, Compañía de Seguros, S.A.',
      en: 'Zurich Vida, Compañía de Seguros, S.A.',
      es: 'Zurich Vida, Compañía de Seguros, S.A.'
    }
  },
  {
    id: 629,
    name: {
      he: 'SU CASITA',
      en: 'SU CASITA',
      es: 'SU CASITA'
    },
    fullname: {
      he: 'Hipotecaria Su Casita, S.A. de C.V. SOFOM ENR',
      en: 'Hipotecaria Su Casita, S.A. de C.V. SOFOM ENR',
      es: 'Hipotecaria Su Casita, S.A. de C.V. SOFOM ENR'
    }
  },
  {
    id: 630,
    name: {
      he: 'CB INTERCAM',
      en: 'CB INTERCAM',
      es: 'CB INTERCAM'
    },
    fullname: {
      he: 'Intercam Casa de Bolsa, S.A. de C.V.',
      en: 'Intercam Casa de Bolsa, S.A. de C.V.',
      es: 'Intercam Casa de Bolsa, S.A. de C.V.'
    }
  },
  {
    id: 631,
    name: {
      he: 'CI BOLSA',
      en: 'CI BOLSA',
      es: 'CI BOLSA'
    },
    fullname: {
      he: 'CI Casa de Bolsa, S.A. de C.V.',
      en: 'CI Casa de Bolsa, S.A. de C.V.',
      es: 'CI Casa de Bolsa, S.A. de C.V.'
    }
  },
  {
    id: 632,
    name: {
      he: 'BULLTICK CB',
      en: 'BULLTICK CB',
      es: 'BULLTICK CB'
    },
    fullname: {
      he: 'Bulltick Casa de Bolsa, S.A., de C.V.',
      en: 'Bulltick Casa de Bolsa, S.A., de C.V.',
      es: 'Bulltick Casa de Bolsa, S.A., de C.V.'
    }
  },
  {
    id: 633,
    name: {
      he: 'STERLING',
      en: 'STERLING',
      es: 'STERLING'
    },
    fullname: {
      he: 'Sterling Casa de Cambio, S.A. de C.V.',
      en: 'Sterling Casa de Cambio, S.A. de C.V.',
      es: 'Sterling Casa de Cambio, S.A. de C.V.'
    }
  },
  {
    id: 634,
    name: {
      he: 'FINCOMUN',
      en: 'FINCOMUN',
      es: 'FINCOMUN'
    },
    fullname: {
      he: 'Fincomún, Servicios Financieros Comunitarios, S.A. de C.V.',
      en: 'Fincomún, Servicios Financieros Comunitarios, S.A. de C.V.',
      es: 'Fincomún, Servicios Financieros Comunitarios, S.A. de C.V.'
    }
  },
  {
    id: 636,
    name: {
      he: 'HDI SEGUROS',
      en: 'HDI SEGUROS',
      es: 'HDI SEGUROS'
    },
    fullname: {
      he: 'HDI Seguros, S.A. de C.V.',
      en: 'HDI Seguros, S.A. de C.V.',
      es: 'HDI Seguros, S.A. de C.V.'
    }
  },
  {
    id: 637,
    name: {
      he: 'ORDER',
      en: 'ORDER',
      es: 'ORDER'
    },
    fullname: {
      he: 'Order Express Casa de Cambio, S.A. de C.V',
      en: 'Order Express Casa de Cambio, S.A. de C.V',
      es: 'Order Express Casa de Cambio, S.A. de C.V'
    }
  },
  {
    id: 638,
    name: {
      he: 'AKALA',
      en: 'AKALA',
      es: 'AKALA'
    },
    fullname: {
      he: 'Akala, S.A. de C.V., Sociedad Financiera Popular',
      en: 'Akala, S.A. de C.V., Sociedad Financiera Popular',
      es: 'Akala, S.A. de C.V., Sociedad Financiera Popular'
    }
  },
  {
    id: 640,
    name: {
      he: 'CB JPMORGAN',
      en: 'CB JPMORGAN',
      es: 'CB JPMORGAN'
    },
    fullname: {
      he: 'J.P. Morgan Casa de Bolsa, S.A. de C.V. J.P. Morgan Grupo Financiero',
      en: 'J.P. Morgan Casa de Bolsa, S.A. de C.V. J.P. Morgan Grupo Financiero',
      es: 'J.P. Morgan Casa de Bolsa, S.A. de C.V. J.P. Morgan Grupo Financiero'
    }
  },
  {
    id: 642,
    name: {
      he: 'REFORMA',
      en: 'REFORMA',
      es: 'REFORMA'
    },
    fullname: {
      he: 'Operadora de Recursos Reforma, S.A. de C.V., S.F.P.',
      en: 'Operadora de Recursos Reforma, S.A. de C.V., S.F.P.',
      es: 'Operadora de Recursos Reforma, S.A. de C.V., S.F.P.'
    }
  },
  {
    id: 646,
    name: {
      he: 'STP',
      en: 'STP',
      es: 'STP'
    },
    fullname: {
      he: 'Sistema de Transferencias y Pagos STP, S.A. de C.V.SOFOM ENR',
      en: 'Sistema de Transferencias y Pagos STP, S.A. de C.V.SOFOM ENR',
      es: 'Sistema de Transferencias y Pagos STP, S.A. de C.V.SOFOM ENR'
    }
  },
  {
    id: 647,
    name: {
      he: 'TELECOMM',
      en: 'TELECOMM',
      es: 'TELECOMM'
    },
    fullname: {
      he: 'Telecomunicaciones de México',
      en: 'Telecomunicaciones de México',
      es: 'Telecomunicaciones de México'
    }
  },
  {
    id: 648,
    name: {
      he: 'EVERCORE',
      en: 'EVERCORE',
      es: 'EVERCORE'
    },
    fullname: {
      he: 'Evercore Casa de Bolsa, S.A. de C.V.',
      en: 'Evercore Casa de Bolsa, S.A. de C.V.',
      es: 'Evercore Casa de Bolsa, S.A. de C.V.'
    }
  },
  {
    id: 649,
    name: {
      he: 'SKANDIA',
      en: 'SKANDIA',
      es: 'SKANDIA'
    },
    fullname: {
      he: 'Skandia Operadora de Fondos, S.A. de C.V.',
      en: 'Skandia Operadora de Fondos, S.A. de C.V.',
      es: 'Skandia Operadora de Fondos, S.A. de C.V.'
    }
  },
  {
    id: 651,
    name: {
      he: 'SEGMTY',
      en: 'SEGMTY',
      es: 'SEGMTY'
    },
    fullname: {
      he: 'Seguros Monterrey New York Life, S.A de C.V.',
      en: 'Seguros Monterrey New York Life, S.A de C.V.',
      es: 'Seguros Monterrey New York Life, S.A de C.V.'
    }
  },
  {
    id: 652,
    name: {
      he: 'ASEA',
      en: 'ASEA',
      es: 'ASEA'
    },
    fullname: {
      he: 'Solución Asea, S.A. de C.V., Sociedad Financiera Popular',
      en: 'Solución Asea, S.A. de C.V., Sociedad Financiera Popular',
      es: 'Solución Asea, S.A. de C.V., Sociedad Financiera Popular'
    }
  },
  {
    id: 653,
    name: {
      he: 'KUSPIT',
      en: 'KUSPIT',
      es: 'KUSPIT'
    },
    fullname: {
      he: 'Kuspit Casa de Bolsa, S.A. de C.V.',
      en: 'Kuspit Casa de Bolsa, S.A. de C.V.',
      es: 'Kuspit Casa de Bolsa, S.A. de C.V.'
    }
  },
  {
    id: 655,
    name: {
      he: 'SOFIEXPRESS',
      en: 'SOFIEXPRESS',
      es: 'SOFIEXPRESS'
    },
    fullname: {
      he: 'J.P. SOFIEXPRESS, S.A. de C.V., S.F.P.',
      en: 'J.P. SOFIEXPRESS, S.A. de C.V., S.F.P.',
      es: 'J.P. SOFIEXPRESS, S.A. de C.V., S.F.P.'
    }
  },
  {
    id: 656,
    name: {
      he: 'UNAGRA',
      en: 'UNAGRA',
      es: 'UNAGRA'
    },
    fullname: {
      he: 'UNAGRA, S.A. de C.V., S.F.P.',
      en: 'UNAGRA, S.A. de C.V., S.F.P.',
      es: 'UNAGRA, S.A. de C.V., S.F.P.'
    }
  },
  {
    id: 659,
    name: {
      he: 'OPCIONES EMPRESARIALES DEL NOROESTE',
      en: 'OPCIONES EMPRESARIALES DEL NOROESTE',
      es: 'OPCIONES EMPRESARIALES DEL NOROESTE'
    },
    fullname: {
      he: 'OPCIONES EMPRESARIALES DEL NORESTE, S.A. DE C.V., S.F.P.',
      en: 'OPCIONES EMPRESARIALES DEL NORESTE, S.A. DE C.V., S.F.P.',
      es: 'OPCIONES EMPRESARIALES DEL NORESTE, S.A. DE C.V., S.F.P.'
    }
  },
  {
    id: 670,
    name: {
      he: 'LIBERTAD',
      en: 'LIBERTAD',
      es: 'LIBERTAD'
    },
    fullname: {
      he: 'Libertad Servicios Financieros, S.A. De C.V.',
      en: 'Libertad Servicios Financieros, S.A. De C.V.',
      es: 'Libertad Servicios Financieros, S.A. De C.V.'
    }
  },
  {
    id: 901,
    name: {
      he: 'CLS',
      en: 'CLS',
      es: 'CLS'
    },
    fullname: {
      he: 'Cls Bank International',
      en: 'Cls Bank International',
      es: 'Cls Bank International'
    }
  },
  {
    id: 902,
    name: {
      he: 'INDEVAL',
      en: 'INDEVAL',
      es: 'INDEVAL'
    },
    fullname: {
      he: 'SD. Indeval, S.A. de C.V.',
      en: 'SD. Indeval, S.A. de C.V.',
      es: 'SD. Indeval, S.A. de C.V.'
    }
  }
];
