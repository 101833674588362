import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { getLocaleDirection } from '@angular/common';
import { Subject } from 'rxjs';

declare var window: any;
declare var Android: any;
declare var webkit: any;

@Injectable()
export class GlobalsService {

  hideReaderContent = new Subject<boolean>();
  defaultSystemLang = 'en';
  weekDays = ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת'];
  genders = {
    0: {
      he: 'לא ידוע',
      en: 'Other',
      es: 'Desconocido',
      fr: 'Inconnue'
    },
    1: {
      he: 'זכר',
      en: 'Male',
      es: 'Masculino',
      fr: 'Femelle'
    },
    2: {
      he: 'נקבה',
      en: 'Female',
      es: 'Mujer',
      fr: 'Femelle'
    }
  }
  currency = {
    ils: 'ש"ח'
  }
  formInputsOthers = {
    he: {'first_name': 'שם פרטי', 'last_name': 'שם משפחה', 'user_phone': 'טלפון', 'user_email': 'דואל', 'identification_number': 'תז', 'birth_date': 'תאריך לידה', 'gender': 'מין', 'id_number': 'ת.ז.', 'id number': 'ת.ז.'},
    en: {'first_name': 'First name', 'last_name': 'Last name', 'user_phone': 'Telephone', 'user_email': 'Email', 'identification_number': 'Id', 'birth_date': 'Date of Birth', 'gender': 'Gender' , 'id_number': 'ID number', 'id number': 'ID number'},
    es: {'first_name': 'Nombre de pila', 'last_name': 'Apellido', 'user_phone': 'Teléfono', 'user_email': 'Correo electrónico', 'identification_number': 'Id', 'birth_date': 'Fecha de Nacimiento ','gender':'Género', 'id_number': 'Identificación', 'id number': 'Identificación'},
    fr: {
      'first_name': 'Prénom', 
      'last_name': 'Nom de famille', 
      'user_phone': 'Téléphone', 
      'user_email': 'E-mail', 
      'identification_number': 'Identifiant', 
      'birth_date': 'Date de naissance', 
      'gender': 'Genre' , 
      'id_number': 'Numéro d\'identification', 
      'id number': 'Numéro d\'identification'
    },
  };
  quillPlaceholder = {
    he: {'placeholderText': 'הכנס טקסט כאן'},
    en: {'placeholderText': 'Insert text here'},
    es: {'placeholderText': 'Insertar texto aquí'},
    fr: {'placeholderText': 'Insérer du texte ici'},
  };
  graphsBackgroundColors = [
    'rgba(255, 99, 132, 0.5)',
    'rgba(54, 162, 235, 0.5)',
    'rgba(255, 206, 86, 0.5)',
    'rgba(75, 192, 192, 0.5)',
    'rgba(153, 102, 255, 0.5)',
    'rgba(255, 159, 64, 0.5)',
    'rgba(255, 99, 132, 0.5)',
    'rgba(54, 162, 235, 0.5)',
    'rgba(255, 206, 86, 0.5)',
    'rgba(75, 192, 192, 0.5)',
    'rgba(153, 102, 255, 0.5)',
    'rgba(255, 159, 64, 0.5)',
    'rgba(255, 99, 132, 0.5)',
    'rgba(54, 162, 235, 0.5)',
    'rgba(255, 206, 86, 0.5)',
    'rgba(75, 192, 192, 0.5)',
    'rgba(153, 102, 255, 0.5)',
    'rgba(255, 159, 64, 0.5)',
    'rgba(255, 99, 132, 0.5)',
    'rgba(54, 162, 235, 0.5)',
    'rgba(255, 206, 86, 0.5)',
    'rgba(75, 192, 192, 0.5)',
    'rgba(153, 102, 255, 0.5)',
    'rgba(255, 159, 64, 0.5)',
    'rgba(255, 99, 132, 0.5)',
    'rgba(54, 162, 235, 0.5)',
    'rgba(255, 206, 86, 0.5)',
    'rgba(75, 192, 192, 0.5)',
    'rgba(153, 102, 255, 0.5)',
    'rgba(255, 159, 64, 0.5)',
    'rgba(255, 99, 132, 0.5)',
    'rgba(54, 162, 235, 0.5)',
    'rgba(255, 206, 86, 0.5)',
    'rgba(75, 192, 192, 0.5)',
    'rgba(153, 102, 255, 0.5)',
    'rgba(255, 159, 64, 0.5)',
    'rgba(255, 99, 132, 0.5)',
    'rgba(54, 162, 235, 0.5)',
    'rgba(255, 206, 86, 0.5)',
    'rgba(75, 192, 192, 0.5)',
    'rgba(153, 102, 255, 0.5)',
    'rgba(255, 159, 64, 0.5)',
    'rgba(255, 99, 132, 0.5)',
    'rgba(54, 162, 235, 0.5)',
    'rgba(255, 206, 86, 0.5)',
    'rgba(75, 192, 192, 0.5)',
    'rgba(153, 102, 255, 0.5)',
    'rgba(255, 159, 64, 0.5)'
  ];
  graphsBorderColors = [
    'rgba(255,99,132,1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(255,99,132,1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(255,99,132,1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(255,99,132,1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(255,99,132,1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(255,99,132,1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(255,99,132,1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(255,99,132,1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)'
  ];

  private loaderSubject = new Subject<boolean>();
  loaderState = this.loaderSubject.asObservable();

  constructor(@Inject(LOCALE_ID) public LOCALE_ID) {}

  startLoader() {
    this.loaderSubject.next(true);
  }

  stopLoader() {
    this.loaderSubject.next(false);
  }

  shouldBePlural(amount: number) {
    return (amount === 0 || amount > 1)
  }

  inApp() {
    if (window.webkit !== undefined || window.Android !== undefined) {
      return true;
    } else {
      return false;
    }
  }

  mobiscrollTheme() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (
      userAgent.match(/iPad/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPod/i)
    ) {
      return 'ios';
    } else if (userAgent.match(/Android/i)) {
      return 'material';
    } else {
      return 'windows';
    }
  }

  translateDiscount(discount_type) {
    if (discount_type == '') {
      return '-';
    } else if (discount_type == 1) {
      return '%';
    } else if (discount_type == 2) {
      return 'סכום סופי';
    } else if (discount_type == 3) {
      return 'ש"ח';
    }
  }

  translateDiscountMethod(discount_method) {
    if(discount_method === 1) {
      return 'הנחה אחידה';
    } else if (discount_method === 2) {
      return 'הנחה פרטנית';
    }
  }

  date_to_age(dob: Date){
    dob = new Date(dob);
    var now = new Date();
    var age = Math.floor((now.getTime() - dob.getTime()) / (365.25 * 24 * 60 * 60 * 1000));
    return age;
  }

  runOnMobile (functionName, args){
    try {
        if (args === undefined) {
            Android[functionName]();
        } else {
            Android[functionName](JSON.stringify(args));
        }
    } catch (e) {/* Failed because we are not using an Android device or no such method declared in mobile bridge */}
    try {
        if (args === undefined) {
            webkit.messageHandlers[functionName].postMessage('{}');
        } else {
            webkit.messageHandlers[functionName].postMessage(JSON.stringify(args));
        }
    } catch (e) {/* Failed because we are not using an iOS device or no such method declared in mobile bridge */}
};

  changeLang(lang: string) {
    localStorage.setItem('Language', lang);
  }

  getStorageLocale(): string {
    return localStorage ? localStorage.getItem('Language') : 'en';
  }

  getBrowserLanguage() {
    const lng = navigator.language || navigator['userLanguage'] || navigator['browserLanguage'] || 'en';
    return  lng.substring(0,2);
  }

  getLocaleId (): string {
    return this.LOCALE_ID.substring(0,2);
  };

  getDir ():'ltr'|'rtl' {
    return getLocaleDirection(this.LOCALE_ID);
  };

  isIos() {
    return navigator.userAgent.match(/ipad|iphone|iPod/i);
  }

  isIosNew() {
    // https://stackoverflow.com/questions/61769340/cant-add-pkpass-to-ipad-safari-cant-download
    // (navigator.maxTouchPoints && !(navigator.maxTouchPoints > 2) - not IPAD
    // https://stackoverflow.com/questions/57776001/how-to-detect-ipad-pro-as-ipad-using-javascript
    return [
        'iPhone Simulator',
        'iPhone'
      ].includes(navigator.platform)
      || (navigator.userAgent.includes('Mac')) && (navigator.maxTouchPoints && !(navigator.maxTouchPoints > 2))
      || (navigator.userAgent.includes('MacIntel')) && (navigator.maxTouchPoints && !(navigator.maxTouchPoints > 2))
      || (navigator.platform.toUpperCase().indexOf('MAC') >= 0) && (navigator.maxTouchPoints && !(navigator.maxTouchPoints > 2))
      || (navigator.platform.toUpperCase().indexOf('MACINTEL') >= 0) && (navigator.maxTouchPoints && !(navigator.maxTouchPoints > 2))
  }

  isSafari() {
    return navigator.userAgent.match('CriOS');
  }
  getCountryData () {
    return [
      {country: 'il', lang: 'he', langName: 'עברית', imgLink: 'assets/images/svg-icons/he.svg', urlLink: '/he'},
      {country: 'us', lang: 'en', langName: 'English', imgLink: 'assets/images/svg-icons/en.svg', urlLink: '/en'},
      {country: 'es', lang: 'es', langName: 'Española', imgLink: 'assets/images/svg-icons/es.svg', urlLink: '/es'},
      {country: 'fr', lang: 'fr', langName: 'Français', imgLink: 'assets/images/svg-icons/fr.svg', urlLink: '/fr'},
      {country: 'ar', lang: 'ar', langName: 'عربي', imgLink: 'assets/images/svg-icons/ar.svg', urlLink: '/ar'}
    ];
  }

  getCurrentLangUrl() {
    const locale = this.getLocaleId();
    const langObj = this.getCountryData().find(langObj => langObj.lang === locale);
    if (!langObj || langObj.urlLink) {
      return this.getCountryData().find(langObj => langObj.lang === 'en');
    }
    return langObj.urlLink;
  }

  hasErrorOnPhoneNumber(hasError, value) {
    if (value) {
      let phoneNumberContainsLetters = false;
      phoneNumberContainsLetters = !value.match(/(^\+[\d]+)$/gi) ? true : false;
      if (phoneNumberContainsLetters) {
        hasError = true;
      }
    }
    return hasError === true;
  }

  hasErrorOnIlsPhoneNumber(hasError, value, countryCode) {
    // console.log('hasErrorOnIlsPhoneNumber GIR-1294', hasError, value, countryCode);
    let phone = value;
    if (countryCode === undefined) {
      hasError = true;
    }
    if (phone.charAt(0) === '0') {
      phone = '+972' + phone.substr(1);
    }
    const il_phone_regexp = '^(?:(?:(\\+?972|\\(\\+?972\\)|\\+?\\(972\\))(?:\\s|\\.|-)?([1-9]\\d?))|(0[23489]{1})|(0[57]{1}[0-9]))(?:\\s|\\.|-)?([^0\\D]{1}\\d{2}(?:\\s|\\.|-)?\\d{4})$';
    if (countryCode === 'il' && phone.match(il_phone_regexp)) {
      hasError = false;
    }
    const isILSError = hasError === true

    return {isILSError, phone};
  }

  formatUrl(url){
    const httpString = 'http://';
    const httpsString = 'https://';
    if (url.substr(0, httpString.length) !== httpString && url.substr(0, httpsString.length) !== httpsString)
      url = httpString + url;
    return url;
  }

  isOnMobile() {
    return typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1;
  }

  detectBrowser() {

    // https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser

    // desktop_web: Order was placed on the website, using a desktop device
    // mobile_web: Order was placed on the mobile website, using a mobile device
    // mobile_app: Order was placed on the mobile app, using a mobile device
    // mobile_app_android: Order was placed on the mobile app, using an android mobile device
    // mobile_app_ios: Order was placed on the mobile app, using an iOS mobile device
    // web: Order was placed on the website, with no available info about the type of device used
    const userAgent = navigator.userAgent;

    const match_mobile1 = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i;
    const match_mobile2 = /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i;
    let source;
    // if ( (window.innerWidth <= 768) && (('ontouchstart' in window) || (navigator.maxTouchPoints > 0)) ) {
    //   source = 'mobile_web';
    // } else if ( (window.innerWidth >= 768) && !('ontouchstart' in window) ) {
    //   source = 'desktop_web';
    // } else {
    //   source = 'web'
    // }

    if (match_mobile1.test(userAgent) || match_mobile2.test(userAgent.substring(0, 4))) {
      source = 'mobile_web';
    } else if ((!match_mobile1.test(userAgent) || !match_mobile2.test(userAgent.substring(0, 4))) && window.innerWidth >= 768) {
      source = 'desktop_web';
    } else {
      source = 'web';
    }


    // if (userAgent.match(/chrome|chromium|crios/i)) {
    //   browserName = "Chrome";
    // } else if (userAgent.match(/firefox|fxios/i)) {
    //   browserName = "Firefox";
    // } else if (userAgent.match(/safari/i)) {
    //   browserName = "Safari";
    // } else if (userAgent.match(/opr\//i)) {
    //   browserName = "Opera";
    // } else if (userAgent.match(/edg/i)) {
    //   browserName = "Edge";
    // } else if (userAgent.match(/android/i)) {
    //   browserName = "Android";
    // } else if (userAgent.match(/iphone/i)) {
    //   browserName = "iPhone";
    // } else {
    //   browserName = "Unknown";
    //   source = 'web'
    // }

    return source
  }

  // mm/dd/yy - return true
  // dd/mm/yy - return false
  detectMonthFirstCountryFromNavigator() {
    const monthFirstCountries = ['en-US','en_AS','en_KE','en_PH','en-CA'];
    let language = navigator.language;
    if (monthFirstCountries.includes(language)) {
      return true
    } else {
      return  false
    }
  }

}
