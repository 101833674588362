<div class="col-md-12 col-xs-12">  
    <div class="modal-header">
      <button type="button" class="close" (click)="onClose(false)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="col-md-12 col-xs-12">
      <h4 class="text-center">Please allow Getin to access your name and profile on Facebook in order to allow you full usability with Facebook in Getin app</h4>
      <p  class="text-center mt10">Granting these permissions will not allow Getin to perform any operation whatsoever in your Facebook account.</p>
    </div>
    <div class="col-md-6 col-xs-12 mutate-float-left mb10">
        <button type="button" class="btn btn-default col-md-12 col-xs-12" (click)="onClose(false)">Continue back to Getin without Facebook</button>
    </div>
    <div class="col-md-6 col-xs-12 mutate-float-right mb10">
        <button type="button" class="btn btn-primary col-md-12 col-xs-12" (click)="onClose(true)">Back to Facebook to change permissions</button>
    </div>
  </div>
