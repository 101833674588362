<!-- <div class="" [formGroup]="userRegistrationForm"> -->
  <form class="" (ngSubmit)="onSubmit()" #userRegistrationForm="ngForm">
    <div class="user-reg-form-container">
      <div class="first-group">
        <div class="name-section">
          <div i18n="Section Label | full name" class="name-section-title">Full Name</div>
            <div class="name-section-input">
              <!-- <img style="width: 24px; height: 43px;" [src]="'assets/images/svg-icons/tag-user.svg'" alt="User name icon"> -->
              <input i18n-placeholder="placeholder | name" pattern="[a-zA-Zñáéíóúü\u0590-\u05fe\u200f\u200e\u0400-\u04FF][a-zA-Zñáéíóúü\u0590-\u05fe\u200f\u200e\u0400-\u04FF -'.ʼ-]+$" (input)="checkFullName($event)" [minlength]="2" class="name-input" type="text" name="name" #name="ngModel" ngModel placeholder="Your name" [(ngModel)]="user_info.first_name" required>
            </div>
<!--            <div i18n="error alert | required full name" *ngIf="name.touched && name.invalid && !name.errors?.required">REQUIRED</div>-->
            <span i18n="error alert | enter full name" *ngIf="name.invalid && name.touched" class="item-error">Please enter full name</span>
            <app-error-creator [@slideIn] *ngIf="name.touched && name.invalid && !name.errors?.required" [name]="'name'" [control]="name"></app-error-creator>
        </div>
        <div class="birth-section">
          <div i18n="label | date of birth" for="dob" class="birth-section-title">Day of birth</div>
            <div class="birth-section-input">
              <!-- <img style="width: 24px; height: 43px;" [src]="'assets/images/svg-icons/sms-tracking.svg'" alt="User birth day icon"> -->
              <input i18n-placeholder="placeholder | date of birth" class="birth-input" type="date" name="dob" placeholder="DD-MM-YY" #dob="ngModel" ngModel [(ngModel)]="user_info.birth_date" [mbsc-options]="myBirthdaySettings" mbsc-date value="" required>
            </div>
            <div i18n="error alert | required birthday" *ngIf="dob.touched && dob.invalid && !dob.errors?.required">REQUIRED</div>
            <span i18n="error alert | enter birthday" *ngIf="dob.invalid && dob.touched" class="item-error">Please enter birthday</span>
            <app-error-creator [@slideIn] *ngIf="dob.touched && dob.invalid && !dob.errors?.required" [name]="'dob'" [control]="dob"></app-error-creator>
        </div>
      </div>
      <div class="second-group">
        <!-- [style]="{'display': !disablePhoneSection ? 'none' : 'flex'}" -->
        <div class="phone-section" *ngIf="disablePhoneSection">
            <!-- <div class="phone-section-title">Enter your phone number to receive a one time password to ensure it's you</div> -->
            <div i18n="label | Phone" class="email-section-title">Phone</div>
            <div class="phone-section-input" [ngClass]="disablePhoneSection ? 'inactive-section':'active-section'">
                <input appPhoneNumber ng2TelInput i18n-placeholder="placeholder | phone"  trim="change" [(ngModel)]="user_info.phone" #phone="ngModel"
                  class="phone-input" [ngClass]="disablePhoneSection ? 'inactive-section':'active-section'" [disabled]="disablePhoneSection || disableEmailSection"
                  inputmode="tel" type="tel" (keyup)="onChangePhone($event)"
                  name="phone" id="phone"
                  placeholder="phone number"
                  (hasError)="setErrorOnPhoneNumber(!$event)"
                  (ng2TelOutput)="setRealPhoneNumber($event)"
                  (intlTelInputObject)="intlTelInputObject($event)"
                  (countryChange)="intlOnCountryChange($event)"
                  [ng2TelInputOptions]="localizationService.getPhoneInputOptions()" />
              </div>
              <span i18n="error alert | required phone" *ngIf="phone.errors?.required && phone.touched" class="item-error"></span>
              <app-error-creator [@slideIn] *ngIf="phone.touched && phone.invalid && !phone.errors?.required" [name]="'phone'" [control]="phone"></app-error-creator>
        </div>
        <!--  [style]="{'display': !disableEmailSection ? 'none' : 'flex'}" -->
        <div class="email-section" *ngIf="disableEmailSection">
          <div i18n="Label email | email input" class="email-section-title">Email</div>
          <div class="email-section-input" [ngClass]="disableEmailSection ? 'active-section':'inactive-section'">
            <!-- <img style="width: 24px; height: 43px;" [src]="'assets/images/svg-icons/sms.svg'" alt="email icon"> -->
            <input i18n-placeholder="placeholder | email" class="email-input" [ngClass]="disableEmailSection ? 'active-section':'inactive-section'" [pattern]="emailRegex" type="email" name="email" placeholder="email" [(ngModel)]="user_info.email" #email="ngModel" ngModel [disabled]="disableEmailSection || disablePhoneSection" inputmode="email">
            <!--  [required]="!disableEmailSection" -->
          </div>
          <span i18n="@@PersonalInfoErrorRequiredEmail" *ngIf="email.errors?.required && email.touched" class="item-error">Email required</span>
          <app-error-creator [@slideIn] *ngIf="email.touched && email.invalid && !email.errors?.required || (!email.errors?.pattern && email.touched)" [name]="'email'" [control]="email"></app-error-creator>
        </div>
      </div>
    <div class="slider-wrapper">
      <label i18n="Section Label | Gender" class="email-section-title">Gender</label>
      <section class="slide-toggle-section">

        <mat-radio-group [(ngModel)]="user_info.gender" name="gender" #gender="ngModel" (change)="redioToggle($event)">
          <mat-radio-button i18n="Label Female | radio-button" class="slide-toggle-margin" value="2" [checked]="user_info.gender=='Female'">Female</mat-radio-button>
          <mat-radio-button i18n="Label Male | radio-button" class="slide-toggle-margin" value="1" [checked]="user_info.gender=='Male'">Male</mat-radio-button>
          <mat-radio-button i18n="Label other | radio-button" class="slide-toggle-margin" value="0" [checked]="user_info.gender=='other'">other</mat-radio-button>
        </mat-radio-group>
      </section>
      <span i18n="@@PersonalInfoErrorPleaseSelectGender" *ngIf="gender.errors?.required && gender.touched" class="item-error">Please select a gender</span>
    </div>
    <div class="thrid-group">
      <div i18n="Slider section Title | Updates and recommendations" class="third-group-upd">Updates and recommendations</div>

          <div class="third-group-getin">
            <div i18n="Slider section get emails | from Getin" class="third-group-title">Emails from Getin</div>
              <mat-slide-toggle
                [(ngModel)]="getinSlider" name="emailsFromGetin" #emailsFromGetin="ngModel"
                (toggleChange)="slideToggleSection('emailsFromGetin')"
                class="slide-toggle-margin"
                [color]="colorSlideToggle"
                [checked]="getinSlider"
                [disabled]="isDisabledSlideToggle">
              </mat-slide-toggle>
          </div>
          <div class="third-group-getin">
            <div i18n="Slider section get emails | from organisers" class="third-group-title">Emails from event organisers</div>
              <mat-slide-toggle
                [(ngModel)]="eventSlider" name="emailsFromEvent" #emailsFromEvent="ngModel"
                (toggleChange)="slideToggleSection('emailsFromEvent')"
                class="slide-toggle-margin"
                [color]="colorSlideToggle"
                [checked]="eventSlider"
                [disabled]="isDisabledSlideToggle">
              </mat-slide-toggle>
        </div>

    </div>
    <div class="forth-group">
      <div class="login-reg-continue">
        <button  i18n="Continue to login | button" class="continue-btn"  type="submit" [disabled]="userRegistrationForm.invalid" [ngStyle]="{'background-color': (!userRegistrationForm.invalid && userRegistrationForm.touched) ? '#FFFFFF' : '#FFFFFF50'}">Continue</button>
      </div>
    </div>
  </div>
  <div class="notice-wrapper">
    <div class="form-notice" (click)="getAgeementAndTerms()"><span i18n="Notice | purshase button">By clicking on the Continue purshase button, you&nbsp;</span>
      <span i18n="Notice | agree to GET IN terms" class="order-terms">agree to GET IN terms&nbsp;</span><span>and&nbsp;</span>
      <span i18n="Notice | conditions of use" class="order-terms">conditions of use.</span></div>
  </div>
</form>

