import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, UntypedFormControl } from '@angular/forms';

@Directive({
  selector:
    '[appValidationMax]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: ValidationMaxDirective, multi: true }
  ]
})
export class ValidationMaxDirective implements Validator {
  @Input('appValidationMax') ValidationMaxDirective: number;

  validate(c: UntypedFormControl): { [key: string]: any } {
    const v = c.value;
    if (v !== '') {
      return v > this.ValidationMaxDirective ? { ValidationMaxDirective: true } : null;
    } else {
      return null;
    }
  }
}
