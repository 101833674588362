<div class="col-md-12 col-xs-12">
  <div class="modal-header">
    <button type="button" class="close" (click)="dialogRef.close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="text-center">
      <h4 i18n="modal title">Set event categories</h4>
    </div>
  </div>
  <div class="modal-body">
      <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Filter">
      </mat-form-field>
      <div class="categiry-container">
        <table mat-table [dataSource]="categoryList" matSort class="mat-elevation-z8">

          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox color="primary" (change)="$event ? masterToggle($event) : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(element) : null; fixChanges($event)" [checked]="selection.isSelected(element)"
                [aria-label]="checkboxLabel(element)">
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Position Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name[language]}} </td>
          </ng-container>

          <!-- status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
            <td mat-cell *matCellDef="let element"> {
              element.status, plural,
              =1 {Active}
              =0 {Inactive}
              } </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>
        </table>
      </div>
  </div>
  <div class="modal-footer">
    <button i18n="@@Close" type="button" class="btn btn-default mutate-float-left mr10"
      (click)="dialogRef.close()">Close</button>
    <button i18n="save button" type="submit" class="btn btn-success mutate-float-right" [disabled]="!wasChanged"
      (click)="onSubmit()">Save</button>
  </div>
</div>
