<section *ngIf="isResponse">
	<app-purchase-modal
		[canUseCash]="canUseCash"
		[eventCoupons]="eventCoupons"
		[eventDiscounts]="eventDiscounts"
		[fb_module]="false"
		[salesman_id]="salesmanID"
		[salesman_user]="true"
		[isFromEventPayment]="isFromEventPayment"
		[isOwner]="isOwner">
	</app-purchase-modal>
</section>
