<form class="events-filter-form">
    <input type="text"
           class="events-filter-input"
           placeholder="choose location"
           i18n-placeholder="placeholder | choose location"
           aria-label="Filter"
           matInput
           [formControl]="myControl"
           [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" class="events-filter-results" panelWidth="'auto'" (optionSelected)="optionSelected($event.option.value)">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option.address_short">
        <span class="option">
          <span>{{option.address_short}}</span>
          <small>{{option.address}}</small>
        </span>
      </mat-option>
    </mat-autocomplete>
<!--  <span (click)="clear()">x</span>-->
</form>
