import { AppResponse } from './../../interfaces/app-response.interface';
import { QuickLinksModalComponent } from './../modals/quick-links-modal/quick-links-modal.component';
import { ApproveEventModalComponent } from './../modals/approve-event-modal/approve-event-modal.component';
import { RestoreEventComponent } from './../modals/restore-event/restore-event.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from '../../services/dialog.service';
import { Component, OnInit, Input } from '@angular/core';
import { EventService } from '../../services/event.service';
import { GlobalsService } from '../../globals';
import { Router, ActivatedRoute } from '@angular/router';
import { BankDetailsModalComponent } from '../modals/bank-details-modal/bank-details-modal.component';
import { EventCategoryService } from '../../services/event-category.service';
import { CategoriesEventModalComponent } from '../../manage-categories/categories-event-modal/categories-event-modal.component';
import { PaymentGatewayService } from '../../services/payment-gateway.service';

@Component({
  selector: 'app-cms-events-table',
  templateUrl: './cms-events-table.component.html',
  styleUrls: ['./cms-events-table.component.css']
})
export class CmsEventsTableComponent implements OnInit {
  @Input() events_user_id: number;
  status;
  event_statuses;
  single_event;
  events;
  page;
  pageSize = 50;
  filter_statuses;
  currentStatus;
  filterText;
  current_event_id;
  is_confirm_event;
  dropdownSelected = false;
  events_statuses_codes = [
    {status: 'declined'},
    {status: 'active'},
    {status: 'on-edit'},
    {status: 'pending'},
    {status: 'closed'},
    {status: 'deleted'},
    {status: 'ended'}
  ];

  filter_countries = {
  en: [{country: 'Others'}, {country: 'Israel'}, {country: 'All'}],
  he: [{country: 'ישראל'}, {country: 'אחרים'}, {country: 'את כל'}],
  es: [{country: 'Otros'}, {country: 'Israel'}, {country: 'Todos'}]
  // {country: 'USA'}
  };
  country;

  activeFilterString: string;
  user_details: any;
  filterByCountry: string | null;
  selectedGatewayType: string | null;

  localeId: string;
  gatewayTypes: string[];

  constructor(
    private router: Router,
    private eventService: EventService,
    private globals: GlobalsService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private eventCategoryService: EventCategoryService,
    private gatewayService: PaymentGatewayService
  ) { this.localeId = this.globals.getLocaleId();}

  ngOnInit() {
    this.getGatewayTypes().then((value: string[]) => {
      this.gatewayTypes = value;
    }).catch(error => {
      if(error.code) {
        this.dialogService.alertsModal('gateways', error.code, 3000, false, (error.log_id ? error.log_id : null));
      }
    });
    this.events_statuses_codes[10] = {status: 'all'};
    this.filter_statuses = this.eventService.getPosibleStatuses(
      this.events_user_id,
      this.status
    );

    if (this.activatedRoute.params) {
      this.filterText = this.activatedRoute.snapshot.queryParams['s'];
      // this.filterByCountry = this.activatedRoute.snapshot.queryParams['country'];
      this.selectedGatewayType =  this.activatedRoute.snapshot.queryParams['gateway'];
      // if(this.filterByCountry) {
      //     const countryIndex = this.filter_countries[this.localeId].findIndex((obj: any) => {if (obj) { return obj.country === this.filterByCountry}});
      //     if (countryIndex == -1) {
      //       this.country = this.filterByCountry = 'All';
      //     } else {
      //       this.country = this.filter_countries[this.localeId][countryIndex].country;
      //     }
      // } else {
      //   this.country = this.filterByCountry = 'All';
      // }
      if (this.selectedGatewayType && this.gatewayTypes) {
        const gatewayIndex = this.gatewayTypes.findIndex((obj: any) => {if (obj) { return obj === this.selectedGatewayType}});
        if (gatewayIndex == -1) {
         this.selectedGatewayType = '0';
        } else {
          this.selectedGatewayType = this.gatewayTypes[gatewayIndex];
        }
      } else {
        this.selectedGatewayType = '0';
      }
      // console.log('country', this.country, this.filterByCountry);
      this.activatedRoute.params.subscribe((params) => {
        const temp_status = params['status'];
        this.status = this.events_statuses_codes.findIndex((obj: any) => {if (obj) { return obj.status === temp_status}});
        if(!this.activeFilterString || this.activeFilterString !== temp_status) {
          this.activeFilterString = temp_status;
          if (this.events_user_id > 0) {
            this.status = this.status !== -1 ? this.status : 3;
            this.getEventsByUserAndByStatus(this.events_user_id, this.status);
          } else {
            this.status = this.status === -1 || this.status === 10 ? 3 : this.status;
            this.getOrderedEventsByStatus(this.status);
          }
        }
        this.currentStatus = this.status;
      });
    }
  }

  addQueryParam(search_text) {
    const status = this.events_statuses_codes[+this.status].status;
    if (this.events_user_id > 0) {
      this.router.navigate(['cms/events', this.events_user_id,  status], { queryParams: { s: search_text}, queryParamsHandling: 'merge'});
    } else {
      // tslint:disable-next-line:max-line-length
      this.router.navigate(['cms/dashboard', status], { queryParams: { s: search_text}, queryParamsHandling: 'merge'});
    }
  }

  addCountryToQueryParam(search_text) {
    const status = this.events_statuses_codes[+this.status].status;
    if (this.events_user_id > 0) {
      this.router.navigate(['cms/events', this.events_user_id,  status], { queryParams: { c: search_text}});
    } else {
      this.router.navigate(['cms/dashboard', status], { queryParams: { c: search_text}, queryParamsHandling: 'merge'});
    }
  }

  getEventsByUserAndByStatus(events_user_id, status) {
    this.globals.startLoader();
    this.eventService.getEventsByUserAndByStatus(events_user_id, status).subscribe(
      (response: any) => {
        this.globals.stopLoader();
        status = this.events_statuses_codes[+status].status;
        this.events = response.events;
        this.user_details = response.user_details;
        if (this.events) {
        this.events.sort((a, b) => {
          return b.id - a.id;
        });
      }
        this.event_statuses = response.events_statuses;
        if (!this.filterText) {
          this.router.navigate(['cms/events', events_user_id,  status], {replaceUrl: true});
        } else {
          this.router.navigate(['cms/events', events_user_id,  status], { queryParams: { s: this.filterText}, replaceUrl: true});
        }
        if (this.filterByCountry) {
          this.router.navigate(['cms/events', events_user_id,  status], { queryParams: { country: this.filterByCountry}, queryParamsHandling: 'merge'});
        }
        if (this.selectedGatewayType) {
          this.router.navigate(['cms/events', events_user_id,  status], { queryParams: { gateway: this.selectedGatewayType}, queryParamsHandling: 'merge'});
        }
      },
      error => {
        this.globals.stopLoader();
        }
      );
  }

  getOrderedEventsByStatus(status) {
      this.globals.startLoader();
      this.eventService.getOrderedEventsByStatus(status).subscribe(
        response => {
          this.globals.stopLoader();
          status = this.events_statuses_codes[+status].status;
          this.events = response.result;
          if (this.events) {
          this.events.sort((a, b) => {
            return b.id - a.id;
          });
          // this.events.forEach(element => {
          //   if (element && element.address) {
          //     const address = element.address.split(',');
          //     const country = address[address.length-1].trim();
          //     element.address = country;
          //   }
          //   console.log('event country', element.id, element.address);
          // });
        }
          if (!this.filterText) {
            this.router.navigate(['cms/dashboard', status ], {replaceUrl: true});
          } else {
            this.router.navigate(['cms/dashboard', status], { queryParams: { s: this.filterText}, replaceUrl: true});
          }
          if (this.filterByCountry) {
            this.router.navigate(['cms/dashboard', status], { queryParams: { country: this.filterByCountry}, queryParamsHandling: 'merge'});
          }
          if (this.selectedGatewayType) {
            this.router.navigate(['cms/dashboard', status], { queryParams: { gateway: this.selectedGatewayType}, queryParamsHandling: 'merge'});
          }
        },
        error => {
          this.globals.stopLoader();
        }
        );
  }

  filterEventsByStatus(status) {
    this.page = undefined;
    status = this.events_statuses_codes[+status].status;
    this.currentStatus = status;
    let route = (this.events_user_id > 0) ? 'cms/events/' + this.events_user_id : '/cms/dashboard';
    if (!this.filterText) {
      this.router.navigate([route, status ], {replaceUrl: true});
    } else {
      this.router.navigate([route, status], { queryParams: { s: this.filterText}, replaceUrl: true});
    }
    if (this.filterByCountry) {
      this.router.navigate([route, status], { queryParams: { country: this.filterByCountry}, queryParamsHandling: 'merge'});
    }
    if (this.selectedGatewayType) {
      this.router.navigate([route, status], { queryParams: { gateway: this.selectedGatewayType}, queryParamsHandling: 'merge'});
    }
    // if (this.countryFilter) {
    //   this.router.navigate([route, status], { queryParams: { country: this.countryFilter}, queryParamsHandling: 'merge'});
    // }
  }

  filterEventsByCountry(country) {
    const status = this.events_statuses_codes[+this.status].status;
    if (this.events_user_id > 0) {
      this.router.navigate(['cms/events', this.events_user_id,  status], { queryParams: { country: country}});
    } else {
      this.router.navigate(['cms/dashboard', status], { queryParams: { country: country}, queryParamsHandling: 'merge'});
    }
    // console.log('country', country, this.filterByCountry);
  }

  getGatewayTypes() {
    return new Promise<String[]>(
      (resolve, reject) => {
        this.gatewayService.getGatewayTypes().subscribe(
          (response: String[]) => {
            // console.log('getGatewayTypes', response);
            resolve(response);
          },
          error => {reject(error)}
        );
      }
    );
  }

  filterByGateways(gatewayType) {
    // console.log('filterGateways', gatewayType);
    const status = this.events_statuses_codes[+this.status].status;
    if (this.events_user_id > 0) {
      this.router.navigate(['cms/events', this.events_user_id,  status], { queryParams: { gateway: gatewayType}});
    } else {
      this.router.navigate(['cms/dashboard', status], { queryParams: { gateway: gatewayType}, queryParamsHandling: 'merge'});
    }
  }

  setAction(value, event_id) {
    if (value !== '') {
      switch (value) {
        case 'update_commission':
        this.approveEventAndOrUpdateCommission(event_id, 'update');
        break;
        case 'delete_event':
        this.updateEventByStatus(event_id, 5);
        break;
        case 'close-event':
        this.updateEventByStatus(event_id, 4);
        break;
        case 'event_info_option':
        this.openEventInfoModal(event_id);
        break;
        case 'show_bank_details':
        this.openEventBankDetailsModal(event_id);
        break;
        case 'restore_event':
        this.restoreEvent(event_id);
        break;
        case 'event-link':
        this.openEventLinkModal(event_id);
        break;
        case 'event-catigories':
        this.openEventCategoriesModal(event_id);
        break;
        default:
          let queryParams = {};
          if (value.includes('/analytics/dashboard')) {
            queryParams = {type: 'sales'}
          }
        this.router.navigate([value],{ queryParams: queryParams});
        break;
      }
    }
  }

  restoreEvent(event_id) {
    this.dropdownSelected = true;
    this.eventService.getById(event_id).subscribe(
      response => {
        const restoreEventModal = this.dialog.open(RestoreEventComponent, {
          data: response,
          disableClose: true,
          autoFocus: false,
          panelClass: 'restoreEventmodal'
        });
        restoreEventModal.afterClosed().subscribe(
          result => {
            this.dropdownSelected = false;
            if (result && result.success) {
              if (this.events_user_id > 0) {
                this.getEventsByUserAndByStatus(this.events_user_id, this.currentStatus);
              } else {
                this.getOrderedEventsByStatus(this.currentStatus);
              }
            }
          }
        );
      },
      error => {
        this.dialogService.alertsModal('errors', (error.code) ? error.code : 4 ,5000, false, error.log);
      }
    );
  }

  approveEventAndOrUpdateCommission(event_id, action) {
    this.dropdownSelected = true;
    this.is_confirm_event = false;
    this.eventService.getById(event_id).subscribe(
      response => {
        response['action'] = action;
        // get all categories
       this.eventCategoryService.getCategories().subscribe(
      (rescat: any) => {
          response['categories'] = rescat.categories;
          const approveEventModal = this.dialog.open(ApproveEventModalComponent, {
          data: response,
          disableClose: true,
          autoFocus: false,
          maxHeight: '90vh',
          panelClass: 'approveEventmodal'
        });
          approveEventModal.afterClosed().subscribe(
            result => {
              if(result === 'payouts') {return}
              if(result !== undefined && result === '-1') {
                this.router.navigate(['cms/create-new-gateway']);
              } else {
                this.dropdownSelected = false;
                if (this.events_user_id > 0) {
                  this.getEventsByUserAndByStatus(this.events_user_id, this.currentStatus);
                } else {
                  this.getOrderedEventsByStatus(this.currentStatus);
                }
              }
            }
            );
          },
          error => {
            this.dialogService.alertsModal('eventCategories', (error.code) ? error.code : 4 ,5000, false, error.log);
          });
        },
        error => {
          this.dialogService.alertsModal('errors', (error.code) ? error.code : 4 ,5000, false, error.log);
        }
        );
      }

  openEventInfoModal (event_id) {
    this.dropdownSelected = true;
    this.dialogService.eventDetailsModal(event_id, 0, 0, 0);
    setTimeout(() => {
      this.dropdownSelected = false;
    }, 2000);
  }

  openEventBankDetailsModal (event_id) {
    this.dropdownSelected = true;
    this.eventService.getBankDetailsByEventId(event_id).subscribe(
      (response: AppResponse) => {
        const bankDetailsModalByEventId = this.dialog.open(BankDetailsModalComponent, {
          data: response.data,
          disableClose: true,
          autoFocus: false,
          panelClass: 'bankDetailsmodalByEventId'
        });
        bankDetailsModalByEventId.afterClosed().subscribe(
          result => {
            this.dropdownSelected = false;
          }
          );
        },
        (error: AppResponse) => {
        this.dropdownSelected = false;
        this.dialogService.alertsModal('cms', (error.code) ? error.code : 4 , 5000, false, error.log_id);
      }
    );
  }

  updateEventByStatus(event_id, update_status) {
    this.dropdownSelected = true;
    let text = '';
    switch (update_status) {
      case 0:
      text = 'declineEvent'
      break;
      case 5:
      text = 'deleteEvent'
      break;
      case 4:
      text = 'closeEvent'
      break;
      default:
      break;
    }
    this.dialogService.areUSureModal(text).subscribe(
      result => {
        this.dropdownSelected = false;
        if (result) {
          this.globals.startLoader();
          this.eventService.updateEventByStatus(event_id, update_status).subscribe(
            response => {
              this.globals.stopLoader();
              this.dialogService.alertsModal('success', 'success', 3000, true);
              if (this.events_user_id > 0) {
                this.getEventsByUserAndByStatus(this.events_user_id, this.currentStatus);
              } else {
                this.getOrderedEventsByStatus(this.currentStatus);
              }
            },
            error => {
              this.globals.stopLoader();
              this.dialogService.alertsModal((error.code) ? 'cms' :'errors', (error.code) ? error.code : 4 ,5000, false, error.log_id);
            }
            );
          }
        }
        );
      }

  openEventLinkModal (event_id) {
    const event_index = this.events.findIndex(obj => event_id === obj.id);
    this.dropdownSelected = true;
    const linksModal = this.dialog.open(QuickLinksModalComponent, {
      data: {event_id: event_id, current_status: this.currentStatus, url_friendly: this.events[event_index].url_friendly},
      disableClose: true,
      autoFocus: false,
      width: '500px',
      panelClass: 'quickLinksModal'
  });
  linksModal.afterClosed().subscribe(
    result => {
    this.dropdownSelected = false;
    }
  );
}

  openEventCategoriesModal(event_id) {
    this.dropdownSelected = true;
      // get all categories
       this.eventCategoryService.getCategories().subscribe(
      (response: any) => {
      const linksModal = this.dialog.open(CategoriesEventModalComponent, {
        data: {event_id: event_id, categories: response.categories},
        disableClose: true,
        autoFocus: false,
        width: '500px',
        panelClass: 'quickLinksModal'
      });
      linksModal.afterClosed().subscribe(
        result => {
        this.dropdownSelected = false;
        }
      );
      },
        (error: AppResponse) => {
        this.dropdownSelected = false;
        this.dialogService.alertsModal('eventCategories', (error.code) ? error.code : 4 , 5000, false, error.log_id);
      }
    );
  }


}
