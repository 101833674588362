<!----------------------Sign in Form-------------------------(ngSubmit)="onSignin(userSigninForm)"--->
<div *ngIf="!isShowOtp" class="button-group"
     [ngClass]="{'selected-flow' : (selectedFlow == 'phone' || selectedFlow == 'email')}">
  <div i18n (click)="setRegType('email')" class="reg-type"
       [ngClass]="{'active' : !isPhone}">
    <span class="type-icon"><img src="./assets/images/login/l_email_login.svg" alt="Email Login"></span>
    <span *ngIf="(selectedFlow == 'phone' || selectedFlow == 'email')">{{'signin.Email' | translate:'Email'}}</span>
    <span *ngIf="selectedFlow == ''">{{'signin.ProceedusingEmail' | translate:'Proceed using Email'}}</span>
  </div>
  <div i18n (click)="setRegType('phone')" class="reg-type"
       [ngClass]="{'active' : isPhone}">
    <span class="type-icon"><img src="./assets/images/login/l_phone_login.svg" alt="Phone Login"></span>
    <span *ngIf="(selectedFlow == 'phone' || selectedFlow == 'email')">{{'signin.Phone' | translate:'Phone'}}</span>
    <span *ngIf="selectedFlow == ''">{{'signin.ProceedusingPhone' | translate:'Proceed using Phone'}}</span>
  </div>
</div>
<div class="captcha-container">

</div>
<form #userSigninForm="ngForm" class="new-form otp-form">
  <div [style]="!isShowOtp && (selectedFlow == 'phone' || selectedFlow == 'email') ? 'display:block': 'display:none'">
    <div class="form-group row">
      <div class="col-md-12 col-xs-12">
        <p *ngIf="!isPhone && !showPasswordInput" class="phone-txt-hint">{{'signin.EnterEmailTxt' | translate:'Enter your email and we\'ll send you a code.'}}</p>
        <input *ngIf="!isPhone"
          [ngClass]="eventPage? 'form-control-event' : 'form-control'" type="email" #email="ngModel" name="phone_email" minlength="6"
          [attr.data-cy]="'phoneEmailInput'" [ngModel]="userEmail" placeholder="{{'signin.EmailAddress' | translate:'Email Address'}}" [pattern]="emailRegex" required>

        <!-- Toggle Password Input-->
        <div *ngIf="!isPhone" class="toggle-password">
          <span *ngIf="!showPasswordInput">{{'signin.Lookingforthepassword' | translate:'Looking for the password?'}}</span>
          <mat-checkbox [(ngModel)]="showPasswordInput" (change)="togglePassword($event)"
                        [ngModelOptions]="{standalone: true}">
            <span *ngIf="!showPasswordInput">{{'signin.Clicktousepassword' | translate:'Click to use password'}}</span>
            <span *ngIf="showPasswordInput">{{'signin.Clicktohidepassword' | translate:'Click to hide password'}}</span>
          </mat-checkbox>
        </div>
        <!-- Toggle Password Input END-->

        <!-- <input *ngIf="isPhone" i18n-placeholder="placeholder | phone" [ngClass]="eventPage? 'form-control-event' : 'form-control'" type="tel" #phone="ngModel" name="phone_email" [ngModel]="userEmail" inputmode="numeric" placeholder="טלפון או מייל" required> -->
        <div *ngIf="isPhone" [ngStyle]="{'display': isPhone ? 'block' : 'block'}">
          <p class="phone-txt-hint">{{'signin.EnterPhoneTxt' | translate:'Enter your phone no. and we\'ll send you a code.'}}</p>
          <input ng2TelInput trim="change" class="phoneInput" #phoneInput id="phoneInput"
                 pattern="^[\+0-9]{5,14}$"
          [ngClass]="eventPage? 'form-control-event' : 'form-control'"
          inputmode="tel" type="tel"
          minlength="6"
          name="phone_email"
          [attr.data-cy]="'phoneEmailInput'"
          placeholder="{{'signin.PhoneNoPlaceholder' | translate:'Phone No.'}}" value=""
          [(ngModel)]="userEmail"
          #phone_email="ngModel"
          (keyup)="onChangePhone($event, phone_email, 'keyup')"
          (hasError)="setErrorOnPhoneNumber(!$event, userSigninForm)"
          (ng2TelOutput)="setRealPhoneNumber($event, userSigninForm)"
          (intlTelInputObject)="telInputObject($event, userEmail)"
          (countryChange)="onCountryChange($event)"
          [ng2TelInputOptions]="localizationService.getPhoneInputOptions()" />
        </div>

        <ng-container *ngIf="viewInited && (userSigninForm.form.controls['phone_email'].dirty ||
     userSigninForm.form.controls['phone_email'].touched)">
          <app-error-handling [@slideIn]
                              ngDefaultControl
                              style="font-size: 13px;"
                              [formControl]="convertToFormControl(userSigninForm.form.controls['phone_email'])"
                              [inputName]="isPhone ? 'user_phone': 'user_email'"
                              [customClass]="'error item-error big-error mt5'">
          </app-error-handling>
        </ng-container>

      </div>
    </div>
    <div *ngIf="!isPhone && showPasswordInput" class="form-group row">
      <div class="col-md-12 col-xs-12">
        <input i18n-placeholder="@@Password" [ngClass]="eventPage? 'form-control-event' : 'form-control'"
          type="password" name="password" [attr.data-cy]="'passwordInput'" [ngModel]="userSigninForm.value.password"
          placeholder="Password">
        <span i18n="@@PasswordRequired." *ngIf="passwordMissing" class="item-error mt10">Password required.</span>
      </div>
    </div>
    <div id="recaptcha_signin" style="display: flex;justify-content: center;"></div>
  </div>
  <div *ngIf="isShowOtp && testOnlyOtpToken" style="color: #CCC; line-height: 0px;">(test only) Otp code: <span style="line-height: 0px;">&nbsp;&nbsp;{{testOnlyOtpToken}}&nbsp;&nbsp;</span></div>
  <app-check-otp [showOtp]="isShowOtp" (timeCounter) = setTimeCounter($event) [isShowTimer]="true" (sendUserData)="sendUserData($event)"
                 [(disabbleBtn)]="disabbleBtn" [userEmailOrPhone]="userSigninForm.value.phone_email" (otpIsShow)="startOTPTimer($event)"
                 [isSendAgainAttempts]="isSendAgainAttempts" [isPhone]="isPhone"></app-check-otp>

  <!--OTP block below otp input-->
  <div *ngIf="isShowOtp">
    <ng-container *ngIf="sendSmsTimer">
      <p class="otp-timer-txt">{{'signin.TimeLeftforNewOTP' | translate:'Time Left for New OTP'}} <span class="otp-timer-count">00:{{sendSmsTimer < 10 ? '0':''}}{{sendSmsTimer}}</span></p>
    </ng-container>


    <div class="otp-warning" *ngIf="sendSmsTimer">
      <img src="./assets/images/svg-icons/information.svg" alt="info">
      <p>{{'signin.infoMsgPleasedonotclosethiswindowOTP' | translate:'Please do not close this window until your OTP is received.'}}</p>
    </div>

<!--    <ng-container *ngIf="isPhone">-->
<!--      <p class="otp-timer-txt attempts" [@fade] *ngIf="isSendAgainAttempts < 3 && isSendAgainAttempts !== 0">{{3 - isSendAgainAttempts}} of 3 Attempts Left</p>-->
<!--      <p class="otp-timer-txt no-attempts" [@fade] *ngIf="isSendAgainAttempts >= 3">No More Attempts Left</p>-->
<!--    </ng-container>-->

<!--    <div class="otp-resend" *ngIf="isSendAgainAttempts >= 3 && !sendSmsTimer">-->
<!--      <p>Please Try Again Later or</p>-->
<!--      <button (click)="intercomMessage()">Contact Support</button>-->
<!--    </div>-->

    <div class="otp-resend" [@fade] [ngClass]="{'disabled' : sendSmsTimer}">
      <p class="mt10">{{'signin.DidntReceiveOTPCode' | translate:'Didn’t Receive OTP Code?'}}</p>
      <button [disabled]="sendSmsTimer" (click)="logregCheckContinue(null, true, userSigninForm)">{{'signin.ResentCode' | translate:'Resent Code'}}</button>
      <p style="margin-bottom: 0;">{{'signin.or' | translate:'or'}}</p>
      <button [disabled]="sendSmsTimer" (click)="showTab()" class="proceed-btn">
        <div *ngIf="isPhone">
          <span class="type-icon"><img src="./assets/images/login/l_email_login.svg" alt="Proceed using Email"></span>
          <span>{{'signin.ProceedusingEmail' | translate:'Proceed using Email'}}</span>
        </div>
        <div *ngIf="!isPhone">
          <span class="type-icon"><img src="./assets/images/login/l_phone_login.svg" alt="Phone Login"></span>
          <span>{{'signin.ProceedusingPhone' | translate:'Proceed using Phone'}}</span>
        </div>
      </button>
    </div>
  </div>
  <!--OTP block below otp input END-->

  <div *ngIf="!isShowOtp">
    <app-passkeys [showAddPassKeyBtn]="false" [showCheckPassKeyBtn]="true" *ngIf="!(selectedFlow == 'phone' || selectedFlow == 'email')"></app-passkeys>

		<div *ngIf="(selectedFlow == 'phone' || selectedFlow == 'email')">
      <!-- [class.disable]="(!userSigninForm.valid && userSigninForm.touched && userSigninForm.dirty) || attemps || fetched" -->
      <button (click)="logregCheckContinue(null, false, userSigninForm)" [attr.data-cy]="'nextButton'" id="otp_btn"
        class="login-btn continue-btn col-md-12 col-xs-12" [ngClass]="eventPage? 'login-next' : 'login-next-box'"
        [disabled]="(!userSigninForm.valid && userSigninForm.touched && userSigninForm.dirty) || attemps || fetched || retryTime">
        <span *ngIf="!eventPage && !isShowOtp">{{'signin.Login' | translate:'Login'}}</span>
        <!-- <span i18n="signin button text" *ngIf="eventPage && !isShowOtp">Log in to your account</span> -->
			</button>
			<span *ngIf="attemps" class="item-error mt10"><span i18n="@@SigninAccountLockedError">Your account has been suspended due to an excessive amount of failed login attempts.</span> <a style="text-decoration: underline;" class="item-error" [routerLink]="['/reset-password']" i18n="@@SigninClickhereToResetError">Click here to reset your password.</a></span>
		</div>

    <p *ngIf="(selectedFlow == 'phone' || selectedFlow == 'email')"
       style="margin-bottom: 0;"
       class="signin-terms">{{'signin.Bycontinuingyouagreeto' | translate:'By continuing, you agree to'}}
      <a href="https://get-in.com/en/terms" target="_blank">{{'signin.GetinGeneralTermsandConditions' | translate:'Getin General Terms and Conditions'}}</a> {{'signup.and' | translate:'and'}}
      <a href="https://get-in.com/en/privacy" target="_blank">{{'signup.PrivacyPolicy' | translate:'Privacy Policy'}}</a>.
    </p>

    <p *ngIf="(selectedFlow == 'phone' || selectedFlow == 'email')"
       class="signin-terms">{{'signin.siteProtectedreCAPTCHAandtheGoogle' | translate:'This site is protected by reCAPTCHA and the Google'}}
      <a href="https://policies.google.com/privacy" target="_blank">{{'signin.PrivacyPolicy' | translate:'Privacy Policy'}}</a> {{'signup.and' | translate:'and'}}
      <a href="https://policies.google.com/terms" target="_blank">{{'signin.TermsofService' | translate:'Terms of Service'}}</a> {{'signup.apply' | translate:'apply'}}.
    </p>


<!--    <div class="col-md-12 col-xs-12" *ngIf="(selectedFlow == 'phone' || selectedFlow == 'email')">-->
<!--      <p class="login-subtitle text-center">-->
<!--        <span class="">{{'signin.Haventmetyet' | translate:'Haven\'t met yet?'}}</span>-->
<!--        <a class="signin_link" (click)="logInToggleEmit()">{{'signin.Registerhere' | translate:'Register here'}}</a>-->
<!--      </p>-->
<!--    </div>-->

    <!-----------------------Reset Password------------------>
    <div *ngIf="(selectedFlow == 'phone' || selectedFlow == 'email') && showPasswordInput" class="forgot-password">
      <span class="text-center">{{'signin.Forgotyourpassword' | translate:'Forgot your password?'}}</span>
      <button type="button" class="center-block" (click)="resetPassword()">{{'signin.Reset' | translate:'Reset'}}</button>
    </div>

	</div>
</form>
