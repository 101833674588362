import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import 'rxjs/Rx';

@Injectable({
	providedIn: 'root'
})
export class CmsUsersService {
	api_base_url = environment.api_base_url;
	constructor(
		private http: HttpClient
	) { }

	getCmsUsers (filter_type: string, start: number, end: number, filterText: any) {
		const endQuery = filterText ? '/' + filterText : '';
		return this.http.get(this.api_base_url + '/api/get-users/' + filter_type + '/' + start + '/' + end + endQuery,
		{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
	}

	updateUserStatus (user_id: number, new_status: number) {
		return this.http.post(this.api_base_url + '/api/update-user-status', {user_id: user_id, status: new_status},
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
	}

	editUser (data) {
		return this.http.post(this.api_base_url + '/api/edit-user', data,
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
	}

	getById (id) {
		return this.http.get(this.api_base_url + '/api/get-user-by-id/' + id,
		{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
	}

	updateUserPassword (data) {
		return this.http.post(this.api_base_url + '/api/update-user-password-by-admin', data,
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
	}

  impersonationAsUser(user_id: number) {
    return this.http.post(this.api_base_url + '/api/user-auth/impersonationAsUser', {user_id: user_id},
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }
}
