<div class="page-header-arrow-back">
  <div class="page-back-arrow">
    <div class="close-dialog-arrow" (click)="closePage()">
      <svg class="arrow-close-modal" width="10" height="14" viewBox="0 0 10 14" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M9.49219 1.64063L4.13281 7L9.49219 12.3594L7.85156 14L0.851562 7L7.85156 0L9.49219 1.64063Z"
          fill="white" />
      </svg>
    </div>
  </div>
</div>
<div class="coupons-wrapper">
  <div class="coupons-container">
    <div class="coupons-main">
      <div i18n="@@CouponsTitle" class="coupons-title">Edit coupons for event</div>
      <div class="coupons-event">&nbsp;{{event_id}}&nbsp;</div>
    </div>
                <div *ngIf="total > pageSize" class="col-md-12 col-xs-12 text-center">
                  <pagination-controls i18n-nextLabel i18n-previousLabel class="pagination col-md-12 col-xs-12"
                    (pageChange)="getCoupons($event)" [responsive]="false" nextLabel="Next page" previousLabel="Previous page" id="server">
                  </pagination-controls>
                </div>
    <div class="coupons-main-container">
      <form [formGroup]="event" class="status new-form" *ngIf="bootstrapBoolean">
        <div formGroupName="discounts">
          <div class="nc">
            <button i18n="@@CouponsAddCouponBtn" *ngIf="couponsArray.length === 0" class="coupons-field coupons-btn"
              type="button" (click)="addCoupon()">Add coupon</button>
          </div>
          <div class="coupon-form">
            <div formArrayName="coupons" *ngFor="let coupon of couponsArray.controls | paginate: { id: 'server', itemsPerPage: pageSize, currentPage: page,  totalItems: total }; let x = index;"
              class="coupon-container" #couponItem>
              <div [formGroupName]="x" class="coupons-form">
                <div class="coupons-control-group-title">
                  <span i18n="@@CouponsCoupon" class="section-title">Coupon </span>
                  <span class="section-number">{{x + 1}}</span>
                  <button *ngIf="couponsArray.controls[x]['controls'].id.value && eventData.eventStatus !== 2"
                    type="button" (click)="copyCode(coupon.value.code)"
                    class="mutate-float-left btn btn-danger">{{coupon.value.code}}</button>
                </div>
                <div *ngIf="!couponsArray.controls[x]['controls'].id.value && eventData.eventStatus !== 2" class="coupons-control-group-title">
                  <label class="checkbox-label-container check-label col-xs-12 col-sm-12">
                    <input formControlName="manually_entered" type="checkbox" [checked]="couponsArray.controls[x]['controls'].manually_entered.value"
                    (change)="couponCodeChange(couponsArray.controls[x], 'manually_entered', $event)">
                    <span class="checkmark"></span>
                    <span i18n="coupon code check box label" class="checkbox-label">Enter code manually</span>
                  </label>
                </div>
                <div *ngIf="!couponsArray.controls[x]['controls'].id.value && eventData.eventStatus !== 2 && couponsArray.controls[x]['controls'].manually_entered.value" class="coupons-control-group" style="padding-bottom: 10px;">
                  <input i18n-placeholder="placeholder | Coupon code" pattern="^[a-zA-Z0-9-_]+$" class="form-control" trim="blur" type="text" formControlName="code" placeholder="Coupon code"
                  (input)="onKeyUp(couponsArray.controls[x], 'code', $event)" (blur)="onBlurCouponCode(couponsArray.controls[x], 'code', $event, x)">
                  <app-error-creator [@slideIn]
                  *ngIf="couponsArray.controls[x]['controls'].code.touched && couponsArray.controls[x]['controls'].code.invalid"
                  [control]="couponsArray.controls[x]['controls'].code" [name]="'code'"></app-error-creator>
                </div>
                <div class="coupons-control-group">
                  <label i18n="@@CouponsCouponName" for="couponName{{x}}" class="section-title">Coupon name</label>
                  <!-- <div class="coupons-control-group"> -->
                  <input i18n-placeholder="@@CouponsCouponName" id="couponName{{x}}" pattern="^[^<>%!@#%^&*()+~?\/|\$.,\+]*$" class="form-control" (input)="onKeyUp(couponsArray.controls[x], 'name', $event)"
                    trim="blur" type="text" formControlName="name" placeholder="Coupon name">
                  <app-error-creator [@slideIn]
                    *ngIf="couponsArray.controls[x]['controls'].name.touched && couponsArray.controls[x]['controls'].name.invalid"
                    [control]="couponsArray.controls[x]['controls'].name" [name]="'name'"></app-error-creator>
                  <!-- </div> -->
                </div>
                <div class="row">
                  <div class="coupons-control-group form-group col-md-12 col-xs-12">
                    <label class="control-label" for="ticket_id{{x}}">
                      <span i18n="item label | ticket">Choose ticket</span>&nbsp;
                      <span i18n-matTooltip="coupon-link-hint"
                            matTooltip="The coupon link and code will be valid for all event tickets if you do not select a ticket type."
                            matTooltipTouchGestures="on"
                            class="coupon-choose-tickets-tooltip">&#9432;</span></label>
                    <select class="form-control" id="ticket_id{{x}}" formControlName="ticket_id"
                            (change)="ticketChange(x, $event)">
                      <option value="" i18n="coupon | select_ticket">Select ticket</option>
                      <option *ngFor="let ticket of itemTickets" [value]="ticket.id">
                        {{ticket.name}} x{{ticket.quantity}}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-12 col-xs-12" *ngIf="coupon.value.url.length">
                    <label class="control-label" for="url{{x}}" i18n="link">Link</label>
                    <textarea id="url{{x}}" rows="3" class="form-control coupon-url" formControlName="url" (click)="copyCode(coupon.value.url)" readonly>
                    </textarea>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group mutate-float-right col-md-6 col-xs-6">
                    <label i18n="@@CouponsValidFrom" for="start_date">Valid from -</label>
                    <input i18n-placeholder="@@CouponsChooseDate" class="form-control datepicker_start" (change)="onKeyUp(couponsArray.controls[x], 'start_date', $event)"
                      trim="blur" type="text" formControlName="start_date" placeholder="Choose date"
                      [mbsc-options]="itemsDatesSetting" mbsc-datetime>
                    <app-error-creator [@slideIn]
                      *ngIf="couponsArray.controls[x]['controls'].start_date.touched && couponsArray.controls[x]['controls'].start_date.invalid"
                      [control]="couponsArray.controls[x]['controls'].start_date"></app-error-creator>
                  </div>
                  <div class="form-group col-md-6 col-xs-6">
                    <label i18n="@@CouponsValidUntil" for="end_date">Valid until</label>
                    <input i18n-placeholder="@@CouponsChooseDate" class="form-control datepicker_end" (change)="onKeyUp(couponsArray.controls[x], 'end_date', $event)"
                    trim="blur" type="text" formControlName="end_date" placeholder="Choose date" [mbsc-options]="itemsDatesSetting"
                      mbsc-datetime>
                    <app-error-creator [@slideIn]
                      *ngIf="couponsArray.controls[x]['controls'].end_date.touched && couponsArray.controls[x]['controls'].end_date.invalid"
                      [control]="couponsArray.controls[x]['controls'].end_date"></app-error-creator>
                  </div>
                </div>

                <!-- <div *ngIf="!couponsArray.controls[x].value.id" class="form-group">
                  <label i18n="coupon label | discount format select" for="discount">חישוב הנחת קופון</label><br>
                  <input type="radio" formControlName="discount_type" [value]="1" (change)="discountTypeChange(x)">
                  <span i18n="coupon label | discount format option - percent">אחוז הנחה</span><br>
                  <input type="radio" formControlName="discount_type" [value]="3" (change)="discountTypeChange(x)">
                  <span i18n="coupon label | discount format option - sum">סכום הנחה</span><br>
                  <app-error-creator [@slideIn]
                    *ngIf="couponsArray.controls[x]['controls'].discount_type.touched && couponsArray.controls[x]['controls'].discount_type.invalid"
                    [control]="couponsArray.controls[x]['controls'].discount_type"></app-error-creator>
                  <input i18n-placeholder="placeholder | discount amount" type="number" formControlName="discount" class="form-control" placeholder="אחוז/סכום הנחה" [max]="couponsArray.controls[x]['controls'].discount_type.value === 1 ? 100 : null">
              <app-error-creator [@slideIn] *ngIf="couponsArray.controls[x]['controls'].discount.touched && couponsArray.controls[x]['controls'].discount.invalid" [control]="couponsArray.controls[x]['controls'].discount"></app-error-creator>
                </div> -->

                <div class="row">
                  <div class="form-group col-md-6 col-xs-6">
                    <label i18n="@@CmsCouponsAmount" for="quantity">Amount</label>
                    <input i18n-placeholder="@@CmsCouponsAmount" class="form-control" type="number" (input)="onKeyUp(couponsArray.controls[x], 'quantity', $event)"
                      formControlName="quantity" placeholder="Amount">
                    <app-error-creator [@slideIn]
                      *ngIf="couponsArray.controls[x]['controls'].quantity.touched && couponsArray.controls[x]['controls'].quantity.invalid"
                      [control]="couponsArray.controls[x]['controls'].quantity"></app-error-creator>
                  </div>
                  <div class="form-group col-md-6 col-xs-6">
                    <!-- <label class="control-label" i18n="item label | discount type" for="discount_type">Value</label> -->
                    <div class="discount-value">
                    <label i18n="coupon label | discount amount" for="discount">Discount</label>
                    <span *ngIf="couponsArray.controls[x].value.id" i18n="@@CmsCouponsDiscount_type" class="">&nbsp;
                      {couponsArray.controls[x].value.discount_type, plural,
                        =1 {%}
                        =2 {Total}
                        =3 {∑}
                        other {-}
                      }
                    &nbsp;</span>
                    </div>
                    <div class="input-group">
                      <div class="ba-control-group" *ngIf="!couponsArray.controls[x].value.id">
                        <!-- <label class="control-label" i18n="item label | discount type" for="discount_type">Discount type</label> -->
                        <select i18n-placeholder="placeholder | discount type" [ngClass]="dir ==='ltr' ? 'input-group-amount-ltr' : 'input-group-amount-rtl'"
                          class="ba-control-text form-control" id="discount_type" formControlName="discount_type"
                          placeholder="Discount type" (change)="discountTypeChange(x)">
                          <option i18n="input option | discount percent" [value]="1">%</option>
                          <option i18n="input option | discount amount" [value]="3">&#931;</option>
                          <!-- 	&#8721; -->
                        </select>
                      </div>
                      <div class="input-group-append" [ngClass]="couponsArray.controls[x].value.id? 'ba-control-group-full':'ba-control-group'">
                        <!-- <label i18n="coupon label | discount value" for="discount">VALUE</label> -->
                        <input i18n-placeholder="@@CmsCouponsDiscountAmPerc" type="number" formControlName="discount" [readonly]="couponsArray.controls[x].value.id ? true : false" (input)="onKeyUp(couponsArray.controls[x], 'discount', $event)"
                          class="form-control" placeholder="Discount amount\percentage" [ngClass]="couponsArray.controls[x].value.id? 'input-group-full': dir ==='ltr' ?'input-group-value-ltr' : 'input-group-value-rtl'"
                          [max]="couponsArray.controls[x]['controls'].discount_type.value === 1 ? 100 : 65535">

                      </div>
                    </div>
                    <app-error-creator [@slideIn]
                      *ngIf="couponsArray.controls[x]['controls'].discount_type.touched && couponsArray.controls[x]['controls'].discount_type.invalid"
                      [control]="couponsArray.controls[x]['controls'].discount_type"></app-error-creator>
                    <app-error-creator [@slideIn]
                      *ngIf="couponsArray.controls[x]['controls'].discount.touched && couponsArray.controls[x]['controls'].discount.invalid"
                      [control]="couponsArray.controls[x]['controls'].discount"></app-error-creator>
                  </div>
                </div>

                   <div class="row mb10"
                      *ngIf="couponsArray.controls[x]['controls'].id.value && eventData.eventStatus !== 2">
                      <label class="check-label col-xs-12 col-sm-12">
                        <input type="checkbox" [checked]="couponsArray.controls[x]['controls'].status.value === 1"
                          (change)="changeItemStatus($event, 'coupons', x)">
                        <span class="checkmark"></span>
                        <span i18n="@@CmsCouponsActiveCoupon">Active coupon</span>
                        <app-error-creator [@slideIn]
                          *ngIf="couponsArray.controls[x]['controls'].status.touched && couponsArray.controls[x]['controls'].status.invalid"
                          [control]="couponsArray.controls[x]['controls'].status"></app-error-creator>
                      </label>
                    </div>


                <!-- <div *ngIf="couponsArray.controls[x].value.id" class="form-group">
                  <label i18n="coupon label | discount amount" for="discount">הנחה:</label>
                  <span i18n="coupon input | discount amount">
                    {{couponsArray.controls[x].value.discount}}{couponsArray.controls[x].value.discount_type, plural,
                    =1{%}
                    =2{סכום סופי}
                    =3{ש"ח}
                    other{-}
                    }
                  </span>
                </div> -->

            <!-- <div class="row mb10" *ngIf="couponsArray.controls[x]['controls'].id.value && eventData.eventStatus !== 2">
              <div class="form-group col-md-12 col-xs-12">
                <input formControlName="status" type="checkbox" [checked]="couponsArray.controls[x]['controls'].status.value === 1" (change)="changeItemStatus($event.srcElement.checked, 'coupons', x)">
                <span i18n="coupon input | status - enabled or disabled">קופון פעיל</span>
                <app-error-creator [@slideIn] *ngIf="couponsArray.controls[x]['controls'].status.touched && couponsArray.controls[x]['controls'].status.invalid" [control]="couponsArray.controls[x]['controls'].status"></app-error-creator>
              </div>
            </div> -->
            <div class="actions-box row">
              <div *ngIf="couponsArray.valid" [@fade] (click)="createNewCoupon()" class="create-btn col-md-6 col-xs-6 text-center add_ticket add-item">
                <!-- <img class="img-responsive" width="30" src="/assets/images/editEvent/Plus2.png"> -->
                <span i18n="@@CmsCouponsCreateNew" class="add-new-item">Create new coupon</span>
              </div>
              <div [@fade] (click)="removeCoupon(x)" class="text-center remove_ticket mutate-float-left" [ngClass]="!couponsArray.valid? 'col-md-12 col-xs-12 remove-btn-only': 'col-md-6 col-xs-6 remove-btn'">
                <!-- <img class="img-responsive" width="30" src="/assets/images/dashboard/Delete.png"> -->
                <span i18n="@@CmsCouponsDelete" class="add-new-item">Delete coupon</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
            <div *ngIf="total > pageSize" class="col-md-12 col-xs-12 text-center">
              <pagination-controls i18n-nextLabel i18n-previousLabel class="pagination col-md-12 col-xs-12"
                (pageChange)="getCoupons($event)" [responsive]="false" nextLabel="Next page" previousLabel="Previous page" id="server">
              </pagination-controls>
            </div>
    <button (click)="submitForm()" type="button" class="coupons-btn-field coupons-btn" [disabled]="event.invalid && submitted" *ngIf="couponsArray.length && !event.invalid">
      <span i18n="save form" class="btn-text">SAVE CHANGES</span>
    </button>
  </form>
  </div>
</div>
</div>
