import { EventService } from '../../services/event.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-event-details-modal',
  templateUrl: './event-details-modal.component.html',
  styleUrls: ['./event-details-modal.component.css']
})
export class EventDetailsModalComponent implements OnInit {

  eventInfo;
  type = 0;
  noData = false;
  paymentGateways: any[] = [];
  sellers_coupons;

  constructor(private eventService: EventService, public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    if(this.data.event_id){
      this.eventService.getEventInfo(this.data.event_id, this.data.salesman_id, this.data.type, this.data.event_seller).subscribe(
        (response: any) => {
          if(response.events_info){
            this.eventInfo = response.events_info;
            this.paymentGateways = response.events_info.payment_gateways;
            this.type = this.data.type;
          } else {
            this.noData = true;
          }
        },
        (error) => {
          this.noData = true;
        }
      );
    }
  }
}
