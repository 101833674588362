import { Component, OnInit, EventEmitter, Output, Input, VERSION } from '@angular/core';
import {GlobalsService} from '../../globals';
import {CurrencyLocaleService} from '../../services/currency-locale.service';

@Component({
  selector: 'app-analytic-balance',
  templateUrl: './analytic-balance.component.html',
  styleUrls: ['./analytic-balance.component.css']
})
export class AnalyticBalanceComponent implements OnInit {
  @Input() event_id: number;
  @Input() statsData: any;

  event_currency: string;
  expenses: any;
  balance: any;
  crypto: any = [];
  // private banks = [];
  dir: string;

  constructor(private globals: GlobalsService, private currencyLocale: CurrencyLocaleService) {
  }


  getLocale(currency) {
    return this.currencyLocale.getLocaleForCurrencyPipe(currency)
  }

  ngOnInit() {
    this.event_currency = this.statsData.event_currency;
    this.expenses = this.statsData.expenses;
    this.balance = this.statsData.balance;
    this.crypto = this.statsData.crypto_balance;
    // temporary set 0 to the "Total Balance" and "Total Amount Now Available" fields
    this.dir = this.globals.getDir();
  }

}
