import { DialogService } from '../../services/dialog.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { slideIn } from './../../animation';
import { CmsDashboardService } from './../../services/cms-dashboard.service';
import { EventService } from '../../services/event.service';
import { ActivatedRoute } from '@angular/router';
import { GlobalsService } from '../../globals';
import { NavbarService } from '../../services/navbar.service';


@Component({
  selector: 'app-cms-web-hooks',
  templateUrl: './cms-web-hooks.component.html',
  styleUrls: ['./cms-web-hooks.component.css'],
  animations: [slideIn]
})
export class CmsWebHooksComponent implements OnInit, OnDestroy {
  event_id = 0;
  form_inputs: any;
  custom_inputs: any;
  instagram_url: any;
  existing_web_hook: any = [];

  constructor(
    private eventService: EventService,
    private activatedRoute: ActivatedRoute,
    public globals: GlobalsService,
    public cmsDashboardService: CmsDashboardService,
    private dialogService: DialogService,
    public nav: NavbarService
  ) {
    this.nav.customWrapperClass = 'full-screen';
  }

  ngOnInit() {
    if (this.activatedRoute.params) {
      this.activatedRoute.params.subscribe(
				params => {
        this.event_id = params['event_id'];
      });
    }
    this.eventService.getPaymentFormData(this.event_id).subscribe(
      (response: any) => {
        this.form_inputs = response.data.form_inputs;
        this.custom_inputs = response.data.custom_inputs;
        this.instagram_url = response.data.form_inputs.custom_instagram_url;
      },
      error =>
      this.dialogService.alertsModal((error.code) ? 'cms' :'errors', (error.code) ? error.code : 4 ,5000, false, error.log_id)
    );
    this.getWebHooks();
  }

  getWebHooks () {
    this.cmsDashboardService.getWebHookByEventId(this.event_id).subscribe(
      (response: any) => {
        this.existing_web_hook = response.data;
        this.existing_web_hook.forEach(e => {
          e['visible_state'] = 'closed';
        });
      },
      error =>
      this.dialogService.alertsModal((error.code) ? 'cms' :'errors', (error.code) ? error.code : 4 ,5000, false, error.log_id)
    );
  }

  toggleWebHookBlock (web_hook_id) {
    const current_web_hook_index = this.existing_web_hook.findIndex((obj: any) => {if (obj) {return obj.id === web_hook_id}});
    if (this.existing_web_hook[current_web_hook_index].visible_state === 'closed') {
      this.existing_web_hook[current_web_hook_index].visible_state = 'opened';
    } else {
      this.existing_web_hook[current_web_hook_index].visible_state = 'closed';
    }
  }

  updateWehookLabel (event) {
    const current_web_hook_index = this.existing_web_hook.findIndex((obj: any) => {if (obj) { return obj.id === event.id}});
    this.existing_web_hook[current_web_hook_index].method = event.http_method;
  }

  ngOnDestroy() {
    this.nav.customWrapperClass = '';
  }
}
