import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, HostBinding} from '@angular/core';
import {TranslateService} from "@tolgee/ngx";

@Component({
  selector: 'app-user-alerts-modal',
  templateUrl: './user-alerts-modal.component.html',
  styleUrls: ['./user-alerts-modal.component.css']
})
export class UserAlertsModalComponent implements OnInit {
  alertsArr: Array<any>;
  successOrErrorGroup: string = '';
  successOrErrorCodes: any;
  timeout: number;
  type: boolean;
  log_id: number;

  @HostBinding('@.disabled') disabled = true;

  constructor(public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) public data,
              private translateService: TranslateService) {

    if (this.data) {
      this.alertsArr = data.alertsArr;
      this.successOrErrorGroup = data.successOrErrorGroup;
      this.successOrErrorCodes = data.successOrErrorCodes;
      this.timeout = data.timeout;
      this.type = data.type;
      this.log_id = data.log_id;
    }

  }

  ngOnInit() {

    setTimeout(() => {
          this.dialogRef.close(true);
      }, this.timeout
    );
  }

  isObject(val) { return typeof val === 'object' }

}
