// Add/Edit blacklist phone dialog
import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  Inject,
  OnInit, ViewChild
} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {GlobalsService} from "../../globals";
import {DialogService} from "../../services/dialog.service";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {EventCategories} from "../../interfaces/event-categories.interface";
import {SelectionModel} from "@angular/cdk/collections";
import {MatTableDataSource} from "@angular/material/table";
import {EventCategoryService} from "../../services/event-category.service";
import {CmsGatewaysService} from "../../services/cms-gateways.service";
import {
  CmsFeeManagmentTemplatesService
} from "../../services/cms-fee-managment-templates.service";
import {FeeTemplate} from "../../interfaces/fee-templates.interface";
import {MatAccordion} from "@angular/material/expansion";

@Component({
  selector: 'cms-fee-management-template-dialog',
  templateUrl: './cms-fee-management-template-dialog.html',
  styleUrls: ['./cms-fee-management-template-dialog.component.scss']
})
export class CmsFeeManagementTemplateDialogComponent implements AfterViewInit{

  form = this.fb.group({
    name: ['', Validators.required],
    categories: this.fb.array([]),
    gateway_ids: this.fb.array([]),
    purchaser_commission: 5,
    commission: 0,
    refund_commission: 0,
    service_commission: 0,
    platform_fee: 0,
    tax_commission: 17,
    refund_type: 'ticket_price',
    isNft: false,
    is_svix_webhook_mode: false,
    transfer_ticket_mode: false,
    donation_fee_mode: false,
    isDonation: false,
    scanner_provider_type: '1',
    stripe_connect_account_mode: false,
    stripe_connect_account_id: '',
    show_price_with_fees: false,
    purchase_passcode_mode: false,
    purchase_passcodes: this.fb.array([]),
    created_at: null,
    updated_at: null
  });
  step = 123321;

  // for categories table
  displayedColumns: string[] = ["select", "id", "name", "status"];
  categoryList = new MatTableDataSource<EventCategories>([]);
  selection = new SelectionModel<EventCategories>(true, []);
  language;

  // for gateway selects
  gatewayTypeControl = this.fb.control('', Validators.required);
  gatewayControl = this.fb.control('', Validators.required);
  gatewayTypes = [];
  allGateways: [];
  filteredByTypeGateways: any[];
  selectedGateways = {};

  previewAction = false;

  @ViewChild(MatAccordion) accordion: MatAccordion;
  constructor(
    public dialogRef: MatDialogRef<CmsFeeManagementTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdRef: ChangeDetectorRef,
    private eventCategoryService: EventCategoryService,
    private cmsGatewaysService: CmsGatewaysService,
    private feeService: CmsFeeManagmentTemplatesService,
    private globals: GlobalsService,
    private dialogService: DialogService,
    private fb: FormBuilder
  ) {
    this.language = this.globals.getLocaleId();
  }

  ngAfterViewInit() {
    console.log('data', this.data);
    this.globals.startLoader();
    this.eventCategoryService.getCategories().subscribe((res: any)=> {console.log('res.categories', res.categories)
      this.categoryList = new MatTableDataSource<EventCategories>(res.categories);
      this.cdRef.detectChanges();
      this.globals.stopLoader();
    }, error => {
      this.globals.stopLoader();
      this.dialogService.alertsModal('eventCategories', (error.code) ? error.code : 4 , 5000, false, error.log_id);
    })
    // filter_type: string,
    // type_gateway: string,
    // start: number,
    // end: number
    // Get 100 all gateways
    this.cmsGatewaysService.getCmsGateways('1', 'all', 0, 100, '').subscribe((res: any) => {
      this.allGateways = res.gateways;
      this.gatewayTypes = [...new Set(res.gateways.map(item => item.payment_type))];
      console.log('getCmsGateways',this.allGateways, this.gatewayTypes);
      this.bootStrapForm();
    })

  }

  bootStrapForm() {
    let template = this.data.template;
    if (this.data.action === 'update'||'preview' && template) {
      for (let key in template) {
        console.log(key, template[key], this.form.get(key));
        if(this.form.get(key)) {
          let value = template[key];

          // Special fields check
          if (key == 'scanner_provider_type') {
            value = value+'';
          }
          // console.log(value);
          this.form.get(key).patchValue(value);
        }
      }

      // Fill gateways
      template.gateways.forEach(gateway => {
        this.selectedGateways[gateway.payment_type] = gateway;
        this.gatewayTypeControl.patchValue(gateway.payment_type);
        this.gatewayControl.patchValue(gateway.id);
        this.filteredByTypeGateways = this.allGateways.filter((item: any) => {
          if (item.payment_type === gateway.payment_type) {return item}
        });

      })

      // Fill categories
      template.categories.forEach((cat: EventCategories) => {
        this.categoryList.data.forEach((row: any) => {
            if (cat.id == row.id) {
              this.selection.select(row)
            }
        });
      })

      // Fill passcodes
      template.purchase_passcodes.forEach((passcode: any) => {
        this.passcodes.push(this.newPassCode(passcode));
      })


      console.log('rEAdy', this.selectedGateways);

      if (this.data.action == 'preview') {
        this.disableForm();
        this.previewAction = true;
        this.accordion.openAll();
      }
      this.form.markAllAsTouched();
    }


  }

  disableForm() {
    this.form.disable();
    this.gatewayTypeControl.disable();
    this.gatewayControl.disable();
  }
  enableForm() {
    this.form.enable();
    this.gatewayTypeControl.enable();
    this.gatewayControl.enable();
  }

  gatewayTypeChange(payment_type) {
    this.filteredByTypeGateways = this.allGateways.filter((item: any) => {
      if (item.payment_type === payment_type.value) {return item}

    });
    this.allowOnlyOneCardMethod();
    console.log(payment_type.value, this.filteredByTypeGateways);
    this.gatewayControl.patchValue('');
  }
  gatewayChange(gatewayId) {

    if (gatewayId.value) {
      this.selectedGateways[this.gatewayTypeControl.value] =
        this.allGateways.find((object: any) => {
          return object.id == gatewayId.value;
        })
    }
    console.log(this.selectedGateways);
    this.prepareGateway_ids();
  }

  prepareGateway_ids() {
    let gateway_ids = <FormArray>this.form.get('gateway_ids');
    gateway_ids.clear();
    Object.keys(this.selectedGateways).forEach((key: any) => {
      gateway_ids.push(this.fb.control(this.selectedGateways[key].id));
    });
  }

  // Leave only Stripe or Tranzilla or Hyper_switch at the same time (not all)
  allowOnlyOneCardMethod() {
    if (this.gatewayTypeControl.value == "tranzilla") {
      this.deleteGateway("stripe_gateway");
      this.deleteGateway("hyper_switch");
    }
    if (this.gatewayTypeControl.value == "stripe_gateway") {
      this.deleteGateway("tranzilla");
      this.deleteGateway("hyper_switch");
    }
    if (this.gatewayTypeControl.value == "hyper_switch") {
      this.deleteGateway("tranzilla");
      this.deleteGateway("stripe_gateway");
    }
  }
  deleteGateway(key, resetInputs = false) {
    delete this.selectedGateways[key];
    console.log('this.selectedGateways', this.selectedGateways)
    console.log('this.Object.keys(obj).length === 0', Object.keys(this.selectedGateways).length === 0)
    if (resetInputs && Object.keys(this.selectedGateways).length === 0) {
      this.gatewayTypeControl.patchValue('')
      this.gatewayControl.patchValue('')
    }
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  save() {
    if (this.previewAction) {
      this.enableForm();
      this.previewAction = false;
      return;
    }
    this.prepareSelectedCategoriesID();
    this.prepareGateway_ids();
    this.checkStripe_connect_account_mode();
    console.log('save', this.data, this.form.value, this.selectedGateways);
    console.log('gatewayTypeControl', this.gatewayTypeControl.value);
    console.log('formVAlid', this.form.valid, this.gatewayTypeControl.valid, this.gatewayControl.valid);
    let data = this.form.value as FeeTemplate;
    let dataValid = false;
    if (this.gatewayTypeControl.valid && this.gatewayControl.valid && this.form.valid) {
      dataValid = true;
    } else {
      if (!this.gatewayTypeControl.valid || !this.gatewayControl.valid) {
        this.setStep(1);
      }
      return;
    }



    if(!dataValid) {
      return;
    }

    if (this.data.action == 'add') {
      this.createFeeTemplate(data);
    }
    if (this.data.action == 'update' || (this.data.action == 'preview' && !this.previewAction)) {
      this.updateFeeTemplate(this.data.template.id, data);
    }
  }

  createFeeTemplate(data) {
    this.feeService.createFeeTemplate(data).subscribe((res: any) => {
      console.log(res)
      if (res.code === 'success') {
        this.dialogService.alertsModal('success', 'success', 3000, true);
        this.dialogRef.close( res.data);
      }
    },error => {
      this.dialogRef.close('data');
      this.dialogService.alertsModal('errors', 4, 5000, false);
    })
  }
  updateFeeTemplate(id, data) {
    this.feeService.updateFeeTemplate(id, data).subscribe((res: any) => {
      console.log(res)
      if (res.code === 'success') {
        this.dialogService.alertsModal('success', 'success', 3000, true);
        this.dialogRef.close(res.data);
      }
    },error => {
      this.dialogRef.close('data');
      this.dialogService.alertsModal('errors', 4, 5000, false);
    })
  }

  checkStripe_connect_account_mode() {
    if(!this.selectedGateways.hasOwnProperty('stripe_gateway')) {
      this.form.get('stripe_connect_account_mode').patchValue(false);
      this.form.get('stripe_connect_account_id').patchValue('');
    }
  }
  // Switch Tabs
  setStep(index: number) {
    this.step = index;
  }

  // Passcode related
  get passcodes() : FormArray {
    return this.form.get("purchase_passcodes") as FormArray
  }
  newPassCode(item = null): FormGroup {
    return this.fb.group({
      id: item ? item.id : null,
      passcode: item ? item.passcode : '',
    })
  }
  addPassCode() {
    this.passcodes.push(this.newPassCode());
  }
  removePassCode(i:number) {
    this.passcodes.removeAt(i);

    // if(!this.passcodes.length) {
    //   this.event.purchase_passcode_mode = 0; // uncheck mode checkbox
    // }
  }


  // Event Categories
  isAllSelected() {
    const numSelected = this.selection.selected
      ? this.selection.selected.length
      : 0;
    const numRows = this.categoryList.data ? this.categoryList.data.length : 0;
    return numSelected === numRows;
  }
  checkboxLabel(row?: EventCategories): string {
    if (!row) { return `${this.isAllSelected() ? "select" : "deselect"} all`;}
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.id + 1}`;
  }
  masterToggle(event) {
    this.isAllSelected()
      ? this.selection.clear()
      : this.categoryList.data.forEach((row: any) =>
        this.selection.select(row)
      );
  }
  rowToggle(row) {
    if (this.previewAction) {return}
    this.selection.toggle(row)
  }
  prepareSelectedCategoriesID() {
    let categories = <FormArray>this.form.get('categories');
    categories.clear();
    this.selection.selected.forEach((category) => {
      categories.push(this.fb.control(category.id))
    });
  }
}
