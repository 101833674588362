<!-- For Select -->
<div class="cards-nui" [formGroup]="form" *ngIf="input.type === 'select'" [ngClass]="customClass">
  <label *ngIf="label" for="{{input.id}}_{{counter}}">{{input.title}}</label>
  <select [mbsc-options]="selectSettings" mbsc-select (change)="selectHandler(counter,input.id ,$event.target.value)" class="guest-input-text-nui form-control" [ngClass]="(item && item.check) ? (item && item.value) ? 'active-section-input' : 'inactive-section-input' : ''" name="{{input.title}}" formControlName="{{input.id}}" id="{{input.id}}_{{counter}}">
    <option selected disabled hidden value="">{{input.title}}</option>
    <option *ngFor="let option of input.opinions" [value]="option.title">{{option.title}}</option>
  </select>
</div>

<!-- For Radio buttons -->
<div [formGroup]="form" *ngIf="input.type === 'radio'" [ngClass]="customClass">
  <label [innerHTML]="input.title | trustHtml"></label><br>
  <div class="checkbox-section" *ngFor="let value of input.opinions; let x = index;" >
    <div class="custom-radio">
      <span [ngClass]="radioBox[x] ? 'radio-mark': ''"></span>
      <input class="promotion-radio" [checked]="radioBox[x]" (change)="radioHandler(counter,input.id ,$event.target.value, $event.target.checked, value, x)" id="{{input.id}}_{{counter+''+x}}" formControlName="{{input.id}}" name="{{input.id}}_{{x}}" type="{{input.type}}" value="{{value.title}}">
    </div>
    <label class="inline checkbox-text" for="{{input.id}}_{{counter+''+x}}">&nbsp;{{value.title}}</label>
  </div>
</div>

<!-- For Checkboxes -->
<div [formGroup]="form" *ngIf="input.type === 'checkbox'" [ngClass]="customClass">
  <label [innerHTML]="input.title | trustHtml"></label><br>
  <div class="checkbox-section" *ngFor="let value of input.opinions; let x = index;" style="display: flex;">
    <div class="card-checkbox-on-nui">
      <svg *ngIf="checkBox[x]" class="card-cb-on-nui" fill="none" height="8" viewBox="0 0 12 8"
           width="12" xmlns="http://www.w3.org/2000/svg">
        <path _ngcontent-c9="" class="ng-tns-c9-1"
              d="M11.8243 0.159541C11.59 -0.0531803 11.2101 -0.0531803 10.9757 0.159541L3.78737 6.68516L1.02428 4.17684C0.789972 3.96412 0.410097 3.96414 0.175746 4.17684C-0.058582 4.38954 -0.058582 4.73439 0.175746 4.94711L3.3631 7.84053C3.59734 8.05323 3.97749 8.05308 4.21163 7.84053L11.8243 0.929831C12.0586 0.717131 12.0586 0.372262 11.8243 0.159541Z"
              fill="#82FFCA"></path>
      </svg>
      <input class="promotion-checkbox" [checked]="checkBox[x]" (change)="checkboxHandler(counter,input.id ,value.title, $event.target.checked, value, x)" id="{{input.id}}_{{x}}" formControlName="{{input.id}}" name="{{input.id}}_{{x}}" type="{{input.type}}">
    </div>
    <label class="inline checkbox-text" for="{{input.id}}_{{x}}">&nbsp;{{value.title}}</label>
  </div>
</div>

<!-- For all the others -->
<div class="cards-nui" [formGroup]="form"
     *ngIf="input.type !== 'select' && input.type !== 'checkbox' && input.type !== 'radio'"
     [ngClass]="customClass" >
  <label *ngIf="label" for="{{input.id}}_{{counter}}">{{input.title}}</label>
  <input trim="blur" id="{{input.id}}_{{counter}}" class="guest-input-text-nui form-control" [ngClass]="(item && item.check) ? (item && item.value) ? 'active-section-input' : 'inactive-section-input' : ''" type="{{input.type}}" name="{{input.id}}_{{counter}}" formControlName="{{input.id}}" title="{{(input.title | i18nSelect: formInputsOthers[localId]) ? (input.title | i18nSelect: formInputsOthers[localId]) : (input.title)}}" placeholder="{{input.title}}">
</div>
