import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-he',
  templateUrl: './privacy-he.component.html',
  styleUrls: ['./privacy-he.component.css']
})
export class PrivacyHeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
