import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByGatewayId'
})
export class FilterByGatewayIdPipe implements PipeTransform {

  transform(connect_accounts_obj: any, payment_gateway_id: number): any {
    // console.log(connect_accounts_obj, payment_gateway_id);
    let obj = {}
    if (connect_accounts_obj) {
      for (const key in connect_accounts_obj) {
        if (connect_accounts_obj.hasOwnProperty(key)) {
          // console.log(`${key}: ${connect_accounts_obj[key].payment_gateway_id}`);
          if(connect_accounts_obj[key].payment_gateway_id == payment_gateway_id) {
            obj[key] = connect_accounts_obj[key];
          }
        }
      }
      return obj
    }
  }

}
