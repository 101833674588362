  <div class="col-md-12 col-xs-12">
    <form (ngSubmit)="submitForm()" #editUserForm="ngForm">
      <div class="modal-header">
          <button type="button" class="close" style="position: absolute;z-index: 1;" (click)="dialogRef.close()">
             <span aria-hidden="true">&times;</span>
          </button>
      <h4 i18n="@@UpdateUserMEditUser" class="text-center" mat-dialog-title>Edit user</h4>
      </div>
        <div class="modal-body" mat-dialog-content>
            <div class="row mt20">
                <div class="form-group col-md-12 col-xs-12">
                    <label i18n="@@FirstName" for="first_name">First name</label>
                    <input i18n-placeholder="@@FirstName" type="text" id="first_name" name="first_name" class="form-control" placeholder="First name" [(ngModel)]="user_details.first_name" #first_name="ngModel" required>
                    <span i18n="@@ThisFieldIsRequired" *ngIf="first_name.dirty && first_name.invalid" class="item-error">This field is required</span>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12 col-xs-12">
                    <label i18n="@@LastName" for="last_name">Last name</label>
                    <input  i18n-placeholder="@@LastName" type="text" id="last_name" name="last_name" class="form-control" placeholder="Last name" [(ngModel)]="user_details.last_name" #last_name="ngModel" required>
                    <span i18n="@@ThisFieldIsRequired" *ngIf="last_name.dirty && last_name.invalid" class="item-error">This field is required</span>
                </div>
            </div>
            <div class="row">
                <div class="form-group {{email.invalid ? 'has-error': ''}} col-md-6 col-xs-12">
                    <label i18n="@@Email" for="email">Email</label>
                    <input  i18n-placeholder="@@Email" type="email" id="email" name="email" class="form-control" placeholder="Email" [(ngModel)]="user_details.email" #email="ngModel"  [pattern]="emailRegex">
<!--                    <div *ngIf="email?.invalid" class="item-error">-->
<!--                            <div i18n="error alert | invalid email" *ngIf="email?.errors?.pattern">-->
<!--                                יש למלא מייל תקין-->
<!--                            </div>-->
<!--                            <div i18n="must have any value error" *ngIf="email?.errors?.required">-->
<!--                                חובה למלא שדה זה-->
<!--                            </div>-->
<!--                        </div>-->
<!--                  <app-error-creator [@slideIn] *ngIf="email.touched && email.invalid" [name]="'email'" [control]="email"></app-error-creator>-->
                </div>
                <div class="form-group {{phone_number.invalid ? 'has-error': ''}} col-md-6 col-xs-12">
                    <label i18n="@@Phone" for="phone_number">Phone</label>
                    <input ng2TelInput
                    i18n-placeholder="@@Phone"
                    class="form-control"
                    trim="blur"
                    type="phone"
                    name="phone_number"
                    placeholder="Phone"
                    value=""
                    [(ngModel)]="user_details.phone_number"
                    #phone_number="ngModel"
                    (hasError)="setErrorOnphoneNumber(!$event)"
                    (ng2TelOutput)="setPhoneNumber($event)"
                    (intlTelInputObject)="telInputObject($event, user_details.phone_number)"
                    (countryChange)="onCountryChange($event, user_details.phone_number)"
                    [ng2TelInputOptions]="localizationService.getPhoneInputOptions()"
                    />
                    <div *ngIf="phone_number?.invalid" class="item-error">
                        <div i18n="@@PersonalInfoErrorInvalidPhone" *ngIf="phone_number?.errors?.pattern">Please enter a valid phone number</div>
<!--                        <app-error-creator [@slideIn] *ngIf="phone_number.touched && phone_number.invalid" [name]="'phone_number'" [control]="phone_number"></app-error-creator>-->
<!--                        <div i18n="must have any value error" *ngIf="phone_number?.errors?.required">-->
<!--                            חובה למלא שדה זה-->
<!--                        </div>-->
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-xs-12">
                    <label i18n="@@CompanyName" for="company" class="col-form-label">Company name</label>
                    <input i18n-placeholder="@@CompanyName" class="form-control" type="text" name="company" placeholder="Company name" [(ngModel)]="user_details.company" #company="ngModel">
                </div>
                <div class="col-md-6 col-xs-12">
                  <label i18n="@@IDNumber" for="identification_number" class="col-form-label">ID number</label>
                  <input i18n-placeholder="@@IDNumber" class="form-control" type="text" name="identification_number" placeholder="ID number" [(ngModel)]="user_details.identification_number" #identification_number="ngModel">
                </div>
                </div>
                <div class="row mt15">
                    <div class="form-group col-md-12 col-xs-12">
                        <input type="checkbox" name="agent" id="agent" [checked]="user_details.agent" [(ngModel)]="user_details.agent" #agent="ngModel">
                        <label i18n="@@UpdateUserMAddMeAsSalesperson" for="agent" class="col-form-label" style="font-weight: normal">Add me as a salesperson in the system</label>
                 </div>
            </div>
      </div>
      <div class="modal-footer">
          <button i18n="@@Cancel" type="button" class="btn btn-default mutate-float-right mr10" (click)="dialogRef.close()">Cancel</button>
          <button i18n="@@Update" type="submit" class="btn btn-success mutate-float-left" >Update</button>
      </div>
    </form>
  </div>
