import { trigger, style, transition,
    animate,
    state,
    group
} from '@angular/animations';

export const enterAnimation = [
    trigger(
        'enterAnimation', [
            transition(':enter', [
                style({transform: 'translateX(100%)', opacity: 0}),
                animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
            ]),
            transition(':leave', [
                style({transform: 'translateX(0)', opacity: 1}),
                animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
            ])
        ]
    )
]

export const slideIn = [
    trigger('slideIn', [
        state('in', style({height: '*', opacity: 0})),
        transition(':leave', [
            style({height: '*', opacity: 1}),

            group([
                animate(300, style({height: 0})),
                animate('300ms ease-in-out', style({'opacity': '0'}))
            ])

        ]),
        transition(':enter', [
            style({height: '0', opacity: 0}),

            group([
                animate(300, style({height: '*'})),
                animate('300ms ease-in-out', style({'opacity': '1'}))
            ])

        ])
    ])
]

export const slideOut = [
    trigger('slideOut', [
        state('in', style({height: '*', opacity: 0})),
        transition(':leave', [
            style({height: '*', opacity: 1}),

            group([
                animate(300, style({height: 0})),
                animate('300ms ease-in-out', style({'opacity': '0'}))
            ])

        ])
    ])
]

export const fade = [
        // the fade-in/fade-out animation.
        trigger('fade', [
    
            // the "in" style determines the "resting" state of the element when it is visible.
            state('in', style({opacity: 1})),
    
            // fade in when created. this could also be written as transition('void => *')
            transition(':enter', [
                style({opacity: 0}),
                animate(400)
            ]),
    
            // fade out when destroyed. this could also be written as transition('void => *')
            transition(':leave',
                animate(200, style({opacity: 0})))
            ])
]

export const SlideInOutAnimation = [
    trigger('SlideInOutAnimation', [
        state('in', style({
            'opacity': '1', 'display': 'block'
        })),
        state('out', style({
            'opacity': '0', 'display': 'none'
        })),
        transition('in => out', [group([
            animate('200ms ease-in-out', style({
                'opacity': '0'
            })),
            animate('300ms ease-in-out', style({
                'display': 'none'
            }))
        ]
        )]),
        transition('out => in', [group([
            animate('1ms ease-in-out', style({
                'display': 'block'
            })),
            animate('200ms ease-in-out', style({
                'opacity': '1'
            }))
        ]
        )])
    ]),
]