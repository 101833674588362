import {Component, Inject} from '@angular/core';
import {EventService} from "../../services/event.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogService} from "../../services/dialog.service";
import {GlobalsService} from "../../globals";
import { timer } from "rxjs";
import { scan, takeWhile } from "rxjs/operators";

@Component({
  selector: 'app-passcode-modal',
  templateUrl: './passcode-modal.component.html',
  styleUrls: ['./passcode-modal.component.css']
})
export class PasscodeModalComponent {
  show: boolean = false;
  public purchase_passcode: string;
  retryTime: number = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private eventService: EventService,
              private dialogRef: MatDialogRef<PasscodeModalComponent>,
              private dialogService: DialogService,
              public globals: GlobalsService) {


  }
  // click event function toggle
  password() {
    this.show = !this.show;
  }
  sendPasscode() {
    if((!this.purchase_passcode || !this.data.event_id) || this.purchase_passcode.length > 50) return

    // Delay Timer
    const result$ = timer(0, 1000).pipe(
      scan(ticks => --ticks, 10),
      takeWhile(v => v >= 0)
    ); // 10 s Retry delay
    result$.subscribe(res => {
      this.retryTime = res;
      // console.log('res', this.retryTime);
    })
    // Delay Timer End

    this.globals.startLoader();
    this.eventService.eventCheckCanPurchase(this.data.event_id, this.purchase_passcode).subscribe((res: any)=> {
      if (res.code && res.code == 'success') {
        this.dialogRef.close(this.purchase_passcode);
        this.globals.stopLoader();
      }

    }, error => {
      this.dialogService.alertsModal('landingPage',  18,2000, false); // Invalid password Error
      this.globals.stopLoader();
    })
  }
}
