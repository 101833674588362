<div class="spinner-container" (mouseover)="isShow=true" (mouseout)="isShow=false" [ngClass]="{'rtl' : dir === 'rtl'}">
  <mat-progress-spinner
    *ngIf="userProfileImage"
    [mode]="mode"
    [value]="verificationPercent"
    class="custom-spinner"
    [diameter]="diameter"
  >
  </mat-progress-spinner>
  <img *ngIf="userProfileImage" class="spinner-profile-image" src="{{userProfileImage}}">
  <svg *ngIf="!userProfileImage" class="spinner-profile-identicon" [data-jdenticon-value]="user_id"></svg>
  <div [ngClass]="isShow ? 'showEditBtn' : 'hideEditBtn'" class="upload-photo-container">
    <input type="file" class="sr-only" #uploader (change)="cropImage($event)" name="file" accept="image/*">
    <button class="upload-photo-btn" (click)="uploader.click()">
      <p i18n="personal info | add photo" *ngIf="isNoPhoto">add</p>
      <p i18n="@@Edit" *ngIf="!isNoPhoto">Edit</p>
      <img src="../../../assets/images/personalInfo/upload_photo.png" alt="upload_photo" class="upload-photo-icon">
    </button>
  </div>
</div>
