<section class="payment-form-section col-md-12">
	<div class="col-md-offset-3 col-md-6 col-xs-12">
		<h1 i18n="@@CmsPurchaseformsettings" class="text-center">Purchase form settings</h1>
		<p i18n="@@CmsPurchaseformsettingsInstr">The purchase form's fields are listed below. Please tick the fields you wish to set as required.</p>
	</div>
	<div class="col-md-offset-3 col-md-6 col-xs-12 mb30">

		<form [formGroup]="formInputsForm">
      <p>Field</p>
			<div ngDefaultControl *ngFor="let form_input of form_inputs | keyvalue; let i=index">
        <ng-container *ngIf="form_input?.key !== 'getin_subscription'">
          <input type="checkbox"
                 formControlName="{{form_input?.key}}"
                 [checked]="form_input?.value"
                 id="{{form_input?.key}}">
          <label i18n="form inputs label" for="{{form_input?.key}}" class="mr5">{
            form_input?.key, select,
            id_number {ID Number}
            gender {Gender}
            getin_subscription {Getin subscription checkbox}
            producer_subscription {Convert purchaser to follow}
            custom_instagram_url {Instagram URL}
            other {{{form_input?.key}}}
            }</label>
        </ng-container>
			</div>
		</form>

    <form [formGroup]="blockedInputsForm">
      <p>Blocked</p>
      <div ngDefaultControl *ngFor="let blocked_input of blocked_inputs | keyvalue; let i=index">
        <input type="checkbox"
               formControlName="{{blocked_input?.key}}"
               [checked]="blocked_input?.value"
               id="b_{{blocked_input?.key}}">
        <label for="b_{{blocked_input?.key}}" class="mr5">Block</label>
      </div>
    </form>

    <div class="custom_inputs_container cms_payment_form p0">
      <button class="btn btn-success" (click)="openCustomFieldsModal()" style="width:100%">Manage custom fields</button>
    </div>
	</div>
	<div class="col-md-12 col-xs-12 pl0 pr0 mt20 save_settings text-center" [ngClass]="{save_settings_disabled: formInputsForm.invalid}">
		<button i18n="@@CmsUpdatepurchaseformBtn" class="col-md-12 col-xs-12 pl0 pr0 " [disabled]="formInputsForm.invalid || blockedInputsForm.invalid" (click)="onSubmit()">
      Update purchase form
		</button>
	</div>
</section>
