<footer id="footer" style="display: none">
  <div class="footer-wrapper">
    <div class="footer-item-logo">
      <a [routerLink]="['/']" (click)="scrollUp()">
        <img class="footer-logo" src="/assets/images/logo_animation.gif" alt="logo"/>
      </a>
      <p class="footer-txt">{{'footer.YourGatewayToExclusiveExp' | translate:'Your Gateway to Exclusive Experiences. Our premier ticketing platform offers seamless registration tailored for elite event communities.'}}</p>
      <div class="footer-partnering"><span>{{'footer.partneringWith' | translate:'partnering with'}}</span>
        <a href="https://www.getin.capital/" target="_blank" class="capital-link">
          <img class="footer-logo" style="width: 80px;" src="/assets/images/svg-icons/getin_capital.svg" alt="Getin Capital"/>
        </a>
      </div>
    </div>

    <div class="footer-item">
      <p class="footer-title" i18n="footer | Explore">Explore</p>
      <ul>
        <li><a i18n="footer menu | Home" href="javascript:void(0);" [routerLink]="['/']">Home</a></li>
        <li><a i18n="footer menu | Trending events" href="javascript:void(0);" [routerLink]="['/categories', 'trending']">Trending events</a></li>
        <li><a i18n="footer menu | All events" href="javascript:void(0);" [routerLink]="['/categories', 'all']">All events</a></li>
      </ul>
    </div>

    <div class="footer-item">
      <p class="footer-title" i18n="footer | Company">Company</p>
      <ul>
        <li><a i18n="footer menu | about" href="javascript:void(0);" [routerLink]="['/about']">About us</a></li>
<!--        <li><a href="javascript:void(0);" [routerLink]="['/blog']">Blog</a></li>-->
        <li><a i18n="footer menu | Contact" href="javascript:void(0);" [routerLink]="['/contact-us']">Contact us</a></li>
        <li><a i18n="footer menu | Help Center" href="javascript:void(0);" (click)="bootIntercom();">Help Center</a></li>
        <li><a href="https://careers.get-in.com/" target="_blank">{{'footer.Career' | translate:'Career'}}</a></li>
      </ul>
    </div>

    <div class="footer-item">
      <p class="footer-title">Terms</p>
<!--      <p class="footer-title" i18n="footer | Terms">Terms</p>-->
      <ul>
        <li><a i18n="footer menu | Purchase Terms" href="javascript:void(0);" [routerLink]="['/terms']">Purchase Terms</a></li>
        <li><a i18n="footer menu | Privacy Terms" href="javascript:void(0);" [routerLink]="['/privacy']">Privacy Terms</a></li>
        <li><a i18n="footer menu | Refund policy" href="javascript:void(0);" [routerLink]="['/cancellations']">Refund policy</a></li>
        <li><a i18n="@@FooterCookiePreferences" href="javascript:void(0);" data-cc="show-preferencesModal">{{'footer.CookiePreferences' | translate:'Cookie Preferences'}}</a></li>
      </ul>
    </div>

    <div class="footer-item apps">
      <p class="footer-title">Mobile app</p>
      <div>
        <a class="footer-bottom-app-link" href="https://play.google.com/store/apps/details?id=com.getin.app" title="Download Getin App" target="_blank">
          <div class="footer-app-inner-link">
            <div><img src="/assets/images/svg-icons/footer-g-play-logo.svg" alt="Download Getin App"></div>
            <div class="footer-app-inner-txt-wrap">
              <p class="footer-app-inner-txt1">Get it on</p>
              <p class="footer-app-inner-txt2">Google Play</p>
            </div>
          </div>
        </a>
        <a class="footer-bottom-app-link" href="https://apps.apple.com/app/getin/id1381205665" title="Download Getin App" target="_blank">
          <div class="footer-app-inner-link">
            <div><img src="/assets/images/svg-icons/footer-apple-logo.svg" alt="Download Getin App"></div>
            <div class="footer-app-inner-txt-wrap">
              <p class="footer-app-inner-txt1">Download on the</p>
              <p class="footer-app-inner-txt2">App Store</p>
            </div>
          </div>
        </a>
      </div>
    </div>

    <div class="footer-bottom-container">
      <div class="social">
        <a class="social-logo" href="https://twitter.com/getin_official" target="_blank">
          <img class="social-logo-img" src="/assets/images/svg-icons/footer-twitter.svg" alt="twitter"/>
        </a>
        <a class="social-logo" href="https://www.facebook.com/getinofficiaI" target="_blank">
          <img class="social-logo-img" src="/assets/images/svg-icons/footer-facebook.svg" alt="facebook"/>
        </a>
        <a class="social-logo" href="https://www.youtube.com/channel/UCS-K8zsYM7vCBTCFmLOerjA" target="_blank">
          <img class="social-logo-img" src="/assets/images/svg-icons/footer-youtube.svg" alt="youtube"/>
        </a>
        <a class="social-logo" href="https://www.instagram.com/getinofficial/" target="_blank">
          <img class="social-logo-img" src="/assets/images/svg-icons/footer-instagram.svg" alt="instagram"/>
        </a>
        <a class="social-logo" href="https://discord.com/invite/d8PAYdGTAW" target="_blank">
          <img class="social-logo-img" src="/assets/images/svg-icons/footer-discord.svg" alt="discord"/>
        </a>
        <a class="social-logo" href="https://www.tiktok.com/@getin_official" target="_blank">
          <img class="social-logo-img" src="/assets/images/svg-icons/footer-tiktok.svg" alt="tik-tok"/>
        </a>
      </div>
      <div class="status-box">
        <a class="status-indicator-link" href="https://status.get-in.com/" title="Open Site Status page">
          <span class="status-indicator" [ngStyle]="{'background-color': true ? '#25FF3B' : '#FBFF25'}"></span>
          <span>{{'footer.SiteStatus' | translate:'Site Status'}}</span>
        </a>
      </div>
    </div>

  </div>
</footer>
