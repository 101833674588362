import {debounceTime, scan, takeWhile} from 'rxjs/operators';
import {Component, AfterViewInit, Output, EventEmitter, Input, OnDestroy, OnInit, ViewChild, ElementRef} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { GlobalsService } from '../../globals';
import { emailRegex } from '../../regex';
import {fade, slideIn} from '../../animation';
import { DialogService } from '../../services/dialog.service';
// import { NavbarService } from '../../services/navbar.service';
import { ActivatedRoute } from '@angular/router';
import { LocalizationService } from 'src/app/services/localization.service';
import {RecaptchaEnterpriseService} from '../../services/recaptcha-enterprise.service';
import {Subscription, timer} from 'rxjs';
import {IntercomService} from "../../services/intercom.service";

declare var window: any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  animations: [slideIn, fade]
})
export class SignupComponent implements AfterViewInit, OnInit, OnDestroy {

  captchaToken: string = '';
  captchaReset: boolean = false;
  testOnlyOtpToken = '';
  isShowOtp: boolean = false;
  @Output() setCaptcha = new EventEmitter<string>()
  @Output() signUp = new EventEmitter<any>();
  @Input() eventPage: boolean;
  @Input() isWalletSignUp: boolean = false;
  @Output() userWalletReg = new EventEmitter<any>();
  @Input() set walletReg(value: boolean) {
    this.fetched = value;
    console.log('set walletReg: ', value, 'isWalletSignUp: ', this.isWalletSignUp);
  };
  @Output() showOtpChange = new EventEmitter();
  @Input() set showOtp(value) {
    this.isShowOtp = value;
  }
  @Input() userNotExitsFields; // Fields from login from if user do not exist
  @Input() userEmail: string;
  @Output() logInToggle = new EventEmitter<any>();
  @Output() sendSmsTimerEmit = new EventEmitter<number>();

  @Input() selectedFlow: 'phone'|'email'|'p_key'| '' = '';
  checkBtn = false;
  isDisabbleBtn = {isDisable: false, otp: null};
  inputsOtp = [{0:''},{1:''},{2:''},{3:''}];
  disabbleBtn = {isDisable: false, otp: null};
  interval;
  timeLeft: number;
  timeCounter: number = null;
  formData: any;
  ipInfo: any;
  isPhone: boolean = false;

  emailRegex = emailRegex;
  fetched: boolean = false;
  retryTime: number = 0;
  registerForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('',{updateOn: 'change', validators: [Validators.pattern(emailRegex), Validators.required]}),
    // phone_email: new FormControl('',{updateOn: 'change', validators: [Validators.pattern(emailRegex), Validators.required]}),
    password: new UntypedFormControl('',{updateOn: 'change'}),
    phone_email: new UntypedFormControl('')
  });
  registerWalletForm: UntypedFormGroup = new UntypedFormGroup({
    // first_name: new FormControl(''),
    // last_name: new FormControl(''),
    email: new UntypedFormControl(''),
    phone: new UntypedFormControl(''),
  });
  mailExists = false;
  phoneExists = false;

  captchaV3Token: string = '';
  captchaV3TokenSubscription: Subscription;
  loginType: string = 'V2';
  csrf_token: string = '';
  @ViewChild('recaptcha_signup') public recaptcha_signup: ElementRef;

  showPasswordInput: boolean = false;
  sendSmsTimer: number = 0;
  isSendAgain = false;
  isSendAgainAttempts: number = 0; // max 3 Attempts


  constructor(
    private authService: AuthService,
    private globals: GlobalsService,
    private dialogService: DialogService,
    private activatedRoute: ActivatedRoute,
    // public nav: NavbarService,
    public localizationService: LocalizationService,
    private recaptchaService: RecaptchaEnterpriseService,
    private intercomService: IntercomService
    ) {
      // console.log('isWalletSignUp: ', this.isWalletSignUp);
      const IPdata = this.localizationService.getIpInfo();
      console.log('getIpInfo: ', IPdata);
      if (!IPdata) {
        this.localizationService.ourLatLng.subscribe(info => {
          console.log('localizationService: ', info);
          this.ipInfo = info;
        });
      } else {
        this.ipInfo = IPdata;
      }
  }

  ngOnInit() {
    // console.log('selectedFlow', this.selectedFlow)
    this.captchaV3TokenSubscription = this.recaptchaService.token.subscribe(token => {
      this.captchaV3Token = token;
      this.fetched = false;
    })
  }

  ngAfterViewInit() {
    this.activatedRoute.queryParams.subscribe(params => {

      }
    );
    if (this.userNotExitsFields && this.userNotExitsFields.hasOwnProperty('type')) {
      this.setRegType(this.userNotExitsFields.type, false);
    }
    if (this.userEmail) {
      this.registerForm.get('email').setValue(this.userEmail);
    }
  }

  setTimeCounter(value) {
    this.timeCounter = value;
    console.log('setTimeCounter', value);
  }

  onChangePhone(event) {
    // console.log('onChangePhone', event.target.value);
    this.registerForm.get('phone').valueChanges.pipe(
      debounceTime(800))
      .subscribe((input) => {
        console.log('Phone valueChanges', input);
      });
  }

  inputChange(type: string) {
    // console.log("inputChange ", type, this.registerForm, this.registerWalletForm);
    if (this.isWalletSignUp) {
      if (this.registerWalletForm.get(type).valid) {
        type == "email" ? this.fetched = false : false;
        // type == 'phone' ? this.checkBtn = false : false;
        // this.checkUserEmailorPhone(type);
        // console.log('checking form for wallet', this.registerWalletForm.get(type).invalid, this.registerWalletForm.controls[type].invalid, this.registerWalletForm.controls[type]);
      }
    } else {
      if (this.registerForm && this.registerForm.get(type).valid) {
        this.registerForm.get(type).valueChanges.pipe(
          debounceTime(800)).subscribe((input) => {
            console.log('Phone valueChanges', input);
          });
        // console.log('checking form', this.registerForm.get(type).valid, this.registerForm.controls[type].valid, this.registerForm.controls[type]);
      }
    }
  }

  checkWalletPhone(type) {
    this.checkUserEmailorPhone(type);
  }

  setRealPhoneNumber(phoneNumber, form) {
    // this.formData = form;
    console.log("setRealPhoneNumber", phoneNumber, form);
		this.registerForm.controls['phone'].patchValue(phoneNumber);
    this.registerForm.controls['phone'].updateValueAndValidity();
    // this.registerForm.value.phone = phoneNumber;
	}
	telInputObject(obj, userPhone) {
    console.log('telInputObject', obj, userPhone);
		if(userPhone) {
			obj.setNumber(userPhone);
		}
    obj ? this.ipInfo.country_code = obj.s.iso2 : false;
	}
	onCountryChange(obj) {
		obj ? this.ipInfo.country_code = obj.iso2 : false;
    console.log('onCountryChange', obj);
	}
	setErrorOnPhoneNumber(hasErrorfromNg2TelInput, form) {
    // this.formData = form;
		// const countryCode = this.user_info.phone.substr(0,4);
		// if (this.user_info.phone.charAt(0) === '0') {
		// 	this.user_info.phone = this.user_info.phone.substr(1);
		// 	this.user_info.phone = '+972' + this.user_info.phone;
		// }
		// if (countryCode.includes('972')) {
		// 	if (this.user_info.phone.charAt(0) !== '+') {this.user_info.phone = '+' + this.user_info.phone};
		// 	this.registerForm.controls['phone_email'].patchValue(this.user_info.phone);
		// 	const localPrefix = this.user_info.phone.substr(4,4);
		// 	const localNumber = this.user_info.phone.substr(8);
		// 	// console.log('includes 972', localPrefix, localNumber);
		// 	if(localNumber.length === 5 && (localPrefix.includes('5526') || localPrefix.includes('5527') || localPrefix.includes('5551'))) {
		// 		hasErrorfromNg2TelInput = false;
		// 	}
		// }
		const hasError = this.globals.hasErrorOnPhoneNumber(hasErrorfromNg2TelInput, this.registerForm.controls['phone'].value);
		const actionOnSetErrors = hasError ? {'invalid': true} : null;
		this.registerForm.controls['phone'].setErrors(actionOnSetErrors);
		// console.log('setErrorOnphoneNumber', hasError, actionOnSetErrors, hasErrorfromNg2TelInput, this.registerForm.controls['phone_email'].value);
	}

  setRegType(type, isWalletSignUp) {
    // this.registerForm.clearAsyncValidators();
    // this.registerForm.clearValidators();
    // this.registerForm.updateValueAndValidity();
    if (!isWalletSignUp) {
      if (type == 'phone') {
        // console.log(11111111, this.userNotExitsFields)
        this.isPhone = true;
        this.registerForm = new UntypedFormGroup({
          phone: new UntypedFormControl(this.userNotExitsFields && this.userNotExitsFields.hasOwnProperty('phone_email') ? this.userNotExitsFields.phone_email : '', {updateOn: 'change', validators: [ Validators.required]}),
          phone_email: new UntypedFormControl(this.userNotExitsFields && this.userNotExitsFields.hasOwnProperty('phone_email') ? this.userNotExitsFields.phone_email : ''),
        });
        this.registerForm.updateValueAndValidity(); //.markAsUntouched();
        console.log('setRegType', type, this.registerForm);
      } else {
       // console.log(2222222222222, this.userNotExitsFields)
        this.isPhone = false;
        this.registerForm = new UntypedFormGroup({
          email: new UntypedFormControl(this.userNotExitsFields && this.userNotExitsFields.hasOwnProperty('phone_email') ? this.userNotExitsFields.phone_email : '', {updateOn: 'change', validators: [Validators.pattern(emailRegex), Validators.required]}),
          password: new UntypedFormControl(this.userNotExitsFields && this.userNotExitsFields.hasOwnProperty('password') ? this.userNotExitsFields.password : ''),
          phone_email: new UntypedFormControl(this.userNotExitsFields && this.userNotExitsFields.hasOwnProperty('phone_email') ? this.userNotExitsFields.phone_email : ''),
        });
        this.registerForm.updateValueAndValidity(); //.markAsUntouched();
        console.log('setRegType', type, this.registerForm);
      }
      this.userNotExitsFields = {};
    } else {
      // this.registerWalletForm.get('email')
      if (type == 'phone') {
        this.checkBtn = this.isPhone = true;
        this.registerWalletForm = new UntypedFormGroup({
          phone: new UntypedFormControl(''),
        });
      } else {
        this.fetched = this.checkBtn = this.isPhone = false;
        this.registerWalletForm = new UntypedFormGroup({
          email: new UntypedFormControl(''),
        });
      }
      this.registerWalletForm.updateValueAndValidity();
      // this.registerWalletForm.clearValidators();
      console.log('setRegType', type, this.registerWalletForm);
    }
    this.selectedFlow = type ? type : 'email';
  }

  sendUserData(event) {
    console.log('sendUserData', event);
    event ? this.onSignup(event) : false;
  }

  setTokenCaptcha(token) {
    this.setCaptcha.emit(token);
    token ? this.globals.stopLoader() : false;
    console.log('setTokenCaptcha', token? token.substring(token.length-11,token.length-1):'no token'); // send to backend with key: g-recaptcha-response
  }

  checkUser() {
    // this.formData = this.registerForm;
    // console.log('Check OTP', this.disabbleBtn.otp, this.registerForm, this.formData);
    this.isSendAgainAttempts = 0;
    if (this.registerForm.get('phone') && this.registerForm.get('phone').value) {
      this.registerForm.controls['phone_email'].patchValue(this.registerForm.get('phone').value);
      // console.log('Phone to Phone_Email value: ', this.registerForm.get('phone_email').value, 'phone', this.registerForm.get('phone').value);
    } else if (this.registerForm.get('email') && this.registerForm.get('email').value) {
      this.registerForm.controls['phone_email'].patchValue(this.registerForm.get('email').value);
      // console.log('Email to Phone_Email value: ', this.registerForm.get('phone_email').value, 'email', this.registerForm.get('email').value);
    }
      // console.log('start check user service', cc, this.ipInfo, this.registerForm.value.phone_email, this.registerForm.value.password);
      if (this.registerForm.valid && this.registerForm.value.phone_email) {


        if (this.loginType == 'V3' && !this.captchaV3Token && this.csrf_token) { // V3 badge is not checked
          this.dialogService.alertsModal('sendProducerEmail', 1, 2000, false);
          return;
        }
        this.startRetryTimer();

        // LOGIN V3 - additional capthca badge
        if (this.loginType == 'V3') {
          this.preSignUp(this.captchaV3Token, 'V3');
        }

        // LOGIN V2  - Default
        if (this.loginType == 'V2') {
          this.recaptchaService.execute('LOGIN').then(token => {
            this.preSignUp(token, 'V2');
          });
        }


      } else {
        this.registerForm.markAllAsTouched();
        this.globals.stopLoader();
      }
  }

  preSignUp(token, type) {
    this.globals.startLoader();
    const cc = this.ipInfo.country_code ? this.ipInfo.country_code : 'il';
    this.authService.preSignUp('registration', this.registerForm.value.phone_email, this.registerForm.value.password, cc,this.csrf_token, token, type).subscribe((response: any) => {
      this.globals.stopLoader();
      console.log('preSignUp', response);
      this.csrf_token = response && response.original.csrf_token ? response.original.csrf_token : this.csrf_token;
      if (response && (response.code == 'success' || response.original.success)) {
        this.isShowOtp = true;
        this.showOtpChange.emit(true);
        this.testOnlyOtpToken = response.original.token;

        // 3 attempts to SEND OTP
        if(this.isSendAgain) {
          this.startOTPTimer(true);
        }

        // console.log('Response success and open otp inputs');
      } else if (response && (response.code != 'success' || response.original.error)) {
        if (type == 'V2' && response.original.error !== 53) {
          this.csrf_token = '';
        }
        if (response.original.error === 53) {
          this.loginType = 'V3'; // if we receive 53 from V2 other requests should be from V3
          this.dialogService.alertsModal('errors', 57, 5000, false);
          this.recaptchaService.render('recaptcha_signup', 'LOGIN').then(() => {
            if(this.recaptcha_signup){this.recaptcha_signup.nativeElement.style.visibility = "visible";}
          });

        } else if (response.original.error === 54) {
          this.csrf_token = '';

          this.recaptchaService.reset().then(() => { // reset badge
            this.recaptchaService.setToken('');
            this.recaptcha_signup.nativeElement.style.visibility = "hidden";
          })
        } else if (response.original.error === 41) {
          this.dialogService.alertsModal('success', 'loginAttemptsBlocked1hour', 5000, true);
        } else {
          this.dialogService.alertsModal('errors', response.original.error, 5000, false);
        }
      } else {
        console.log('Do Not know the error!');
        if (type == 'V3') {
          this.recaptchaService.reset().then(() => { // reset badge
            this.recaptchaService.setToken('');
          })
        }
      }
    });
  }

  onSignup(otp_code='') {
    console.log('User data and OTP', this.isWalletSignUp, otp_code, this.disabbleBtn.otp);
    const phone_country_code = this.ipInfo.country_code ? this.ipInfo.country_code : 'il';
    if (this.isWalletSignUp) {
      console.log('Wallet onSugnUp', this.registerWalletForm.valid, this.registerWalletForm);
      if(this.registerWalletForm.valid) {
        console.log('registerWalletForm', this.registerWalletForm.valid);
        const email = this.registerWalletForm.get('email') ? this.registerWalletForm.get('email').value : '';
        const phone = this.registerWalletForm.get('phone') ? this.registerWalletForm.get('phone').value : '';
        // const userData = {first_name: this.registerWalletForm.get('first_name').value, last_name: this.registerWalletForm.get('last_name').value, email: this.registerWalletForm.get('email').value, phone: this.registerWalletForm.get('phone').value};
        const userData = {email, phone, phone_country_code, otp_code};
        console.log('send userWalletReg data', userData);
        this.userWalletReg.emit(userData);
      } else {
        this.registerWalletForm.markAllAsTouched();
        // this.registerWalletForm.updateValueAndValidity();
        this.dialogService.alertsModal('errors', 18, 2000, false);
      }
    } else {
      console.log('onSignUp', this.registerForm.valid, this.registerForm);
      if (this.registerForm.get('phone') && this.registerForm.get('phone').value) {
        this.registerForm.controls['phone_email'].patchValue(this.registerForm.get('phone').value);
        console.log('Phone_Email value: ', this.registerForm.get('phone_email').value, 'phone', this.registerForm.get('phone').value);
      } else if (this.registerForm.get('email') && this.registerForm.get('email').value) {
        this.registerForm.controls['phone_email'].patchValue(this.registerForm.get('email').value);
        console.log('Phone_Email value: ', this.registerForm.get('phone_email').value, 'email', this.registerForm.get('email').value);
      }
      const phone_email = this.registerForm.get('phone_email') ? this.registerForm.get('phone_email').value :'';
      const password = this.registerForm.get('password') ? this.registerForm.get('password').value : '';

      this.fetched = true;
      this.globals.startLoader();
      // this.recaptchaService.execute('LOGIN').then(token => {
        this.authService.preSignUpSignIn('registration',phone_email, password, phone_country_code,this.csrf_token, otp_code, this.loginType)
          .subscribe(
            (response: any) => {
              if(response.original.success && !response.original.personal_info) {
                console.log('tokenData no personal_info');
                window.requestPushTokenFromApp();
                this.authService.storeTokens(response.auth.session, response.auth.refresh);
                this.signUp.emit();
                setTimeout(() => {
                  this.dialogService.alertsModal('success', response.original.success, 5000, true);
                  this.dialogService.personalInfo(response, true);
                }, 1500);
              } else if(response.original.error){
                if (response.original.error == 35) {
                  this.isShowOtp = true;
                  this.isSendAgainAttempts = this.isSendAgainAttempts+1;
                } else {
                  this.isShowOtp = false;
                }
                this.dialogService.alertsModal('errors', response.original.error, 5000, false);
                if(this.loginType == 'V3') {
                  this.recaptchaService.reset().then(() => { // reset badge
                    this.recaptchaService.setToken('');
                  })
                }
              }
              this.globals.stopLoader();
              this.fetched = false;
            },
            error =>  {
              this.fetched = false;
              this.isShowOtp = false;
              this.dialogService.alertsModal('errors', 4.1, 5000, false);
              if(this.loginType == 'V3') {
                this.recaptchaService.reset().then(() => { // reset badge
                  this.recaptchaService.setToken('');
                })
              }
              this.globals.stopLoader();
            }
          );
      // });
    }
  }

  checkUserEmailorPhone(type) {
    let checkingValue = '';
    if(type == 'email' && (this.registerWalletForm.get('email') && this.registerWalletForm.get('email').valid)) {
      checkingValue = this.registerWalletForm.get('email').value;
    } else if (this.registerWalletForm.get('phone') && this.registerWalletForm.get('phone').valid) {
      checkingValue = this.registerWalletForm.get('phone').value
    }
    // if (this.registerWalletForm.get(type) && this.registerWalletForm.get(type).valid) {
    //   checkingValue = this.registerWalletForm.get(type).value;
    // }
    console.log('checkUserEmailorPhone', checkingValue, type, this.registerWalletForm.get(type).valid, this.registerWalletForm, );
    if(checkingValue) {
      this.authService.walletCheckEmail(checkingValue).subscribe(response => {
        console.log('response', response, type);
        if (response) {
          if (type == 'email') {
            this.mailExists = response;
            // response ? this.dialogService.alertsModal('cryptoCurrency', 'walletConnectEmail', 3000, false) : false;
          } else {
            this.checkBtn = this.phoneExists = response;
            // response ?  this.dialogService.alertsModal('cryptoCurrency', 'walletConnectPhone', 3000, false) : false;
          }
        } else { // continue registration
        }

        // response ? this.dialogService.alertsModal('cryptoCurrency', 'walletConnectEmail', 3000, false) : false;
      }, error => {
        this.fetched = true;
        // type == 'email' ? this.mailExists = true : this.phoneExists = true;
        console.log('checkUserEmailorPhone error: ', error);
        if (error && error.code && error.fields) {
          if ( error.fields.phone_email[0].includes('format')) {
            this.dialogService.alertsModal('cryptoCurrency', 'walletConnectFormat', 3000, false);
          } else {
            this.dialogService.alertsModal('errors', 4.1, 3000, false);
          }
        } else {
          this.dialogService.alertsModal('errors', 4.1, 3000, false);
        }
      });
    } else {console.log('Something went wrong')}
  }

	setPhoneNumber(phoneNumber) {
		this.registerWalletForm.controls['phone'].patchValue(phoneNumber);
    this.registerWalletForm.controls['phone'].updateValueAndValidity();
    console.log('setPhoneNumber', phoneNumber, this.registerWalletForm.get('phone'));
	}
	setTelInputObject(obj, userPhone) {
    console.log('telInputObject', obj, userPhone);
    if(userPhone) {
			obj.setNumber(userPhone);
		}
    obj ? this.ipInfo.country_code = obj.s.iso2 : false;
	}
	countryChange(obj) {
    console.log('onCountryChange', obj);
    obj ? this.ipInfo.country_code = obj.iso2 : false;
	}

  setErrorOnphoneNumber(hasErrorfromNg2TelInput) {
    // console.log('setErrorOnphoneNumber', hasErrorfromNg2TelInput);
    const hasError = this.globals.hasErrorOnPhoneNumber(hasErrorfromNg2TelInput, this.registerWalletForm.controls['phone'].value);
		const actionOnSetErrors = hasError ? {'invalid': true} : null;
		this.registerWalletForm.controls['phone'].setErrors(actionOnSetErrors);
  }

  logInToggleEmit() {
    this.logInToggle.emit();
  }


  togglePassword(e) {
    // console.log(e.checked, this.registerForm.get('password'));
    if(!e.checked) {
      this.registerForm.get('password').setValue('');
      this.registerForm.get('password').clearValidators();
      this.registerForm.get('password').clearAsyncValidators();
      this.registerForm.get('password').updateValueAndValidity();
    } else {
      this.registerForm.get('password').addValidators([Validators.required, Validators.minLength(6)]);
      this.registerForm.get('password').updateValueAndValidity();
    }
  }

  startOTPTimer(value) {
    clearInterval(this.interval);
    this.interval = null;
    const time = 1000;
    if(this.isPhone && this.isSendAgainAttempts >= 3) {return}
    if (value) {
      this.sendSmsTimer = 59;
      this.sendSmsTimerEmit.emit(this.sendSmsTimer);
      this.interval = setInterval( () => {
        if (this.sendSmsTimer > 0) {
          this.sendSmsTimer--;
          this.sendSmsTimerEmit.emit(this.sendSmsTimer);
        } else {
          clearInterval(this.interval);
          this.interval = null;
          this.isSendAgain = value;
          this.sendSmsTimer = 0;
          this.sendSmsTimerEmit.emit(this.sendSmsTimer);
        }
      }, time);
    } else {
      this.sendSmsTimer = 0;
      this.sendSmsTimerEmit.emit(this.sendSmsTimer);
    }
    // console.log('startTimer', this.sendSmsTimer, this.isSendAgain, value);
  }

  intercomMessage() {
    this.intercomService.message('')
  }

  showTab() {
    this.isShowOtp = false;
    this.setRegType(this.isPhone ? 'email' : 'phone', false); // switch tab
  }

  startRetryTimer() {
    // Delay Timer
    const result$ = timer(0, 1000).pipe(
      scan(ticks => --ticks, 5),
      takeWhile(v => v >= 0)
    ); // 5 s Retry delay
    result$.subscribe(res => {
      this.retryTime = res;
    })
    // Delay Timer End
  }

  ngOnDestroy() {
    if (this.captchaV3TokenSubscription) {
      this.captchaV3TokenSubscription.unsubscribe();
    }
    this.captchaV3Token = '';
    this.csrf_token = '';
    this.recaptchaService.setToken('');
  }

}
