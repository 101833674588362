import {Injectable} from '@angular/core';

@Injectable()
export class SuccessCodesService {

  success = {
    'readerRegister': 'Please check your cell phone, a text message was sent with your reader\'s credentials.',
    'readerLogin': 'Login was successful!',
    'signUpSuccess': 'Account Created! Thank you for signing up!',
    'resetReaderPwd': 'A password for this reader has been sent to it\'s owner.',
    'closeEvent': 'The event was successfully closed.',
    'success': 'Updated',
    'succesEmailNotSent': 'Updated, but Email wasn\'t sent.',
    'discounts': 'Discounts were successfully saved.',
    'colorImage': 'Background color was defined.',
    'coverImage': 'Logo image was defined.',
    'bgImage': 'Background image was defined.',
    'feedImage': 'Share image was defined.',
    'imageDeleted': 'Image has been deleted.',
    'orderConfirmed': 'Your order was successful.',
    'sellerAdded': 'Salesman has been successfully added.',
    'sellerInvited': 'Salesman has been successfully invited.',
    'checkMail': 'Please check your Email to continue reset password process.',
    'userCreated': 'Your account was created, please check your email to validate account and continue the registration process.',
    'passwordUpdated': 'Password updated',
    'letsGoHome': 'Welcome, loading home page...',
    'byeBye': 'Logged out.',
    'copied': 'Link copied.',
    'coupon': 'Coupon activated.',
    'successWebhook': 'Update was successful, please check if any web hooks were defined and update their settings accordingly.',
    'copiedPurchaseUrl': 'Quick purchase link copied.',
    'sellerAddedNoPhone': 'Salesman added to event, no notification sent since no phone was set.',
    'reportSaved': 'Saved.',
    'reportDeleted': 'Deleted.',
    'salesmansCopied': 'Salesmen copied to event.',
    'passwordUpdatedEmailNotSent': 'Password updated, but Email wasn\'t sent to user.',
    'cmsTestCustomTemplate': 'The test was successfully sent',
    'ticketSent': 'The ticket was successfully sent',
    'couponCopied': 'Copied',
    'emailCopied': 'Copied',
    'sentForApproval': 'The participant was sent for approval',
    'successCommissionsUnchanged': 'The event has already been approved by another admin - commissions are left unchanged',
    'bankUpdated': 'Your bank account details were updated successfully ',
    'seoUpdated': 'Sales promotions details of the event were updated successfully',
    'couponsUpdated': 'Coupons details of the event were updated successfully ',
    'discountsUpdated': 'Discounts details of the event were updated successfully',
    'slideDelete': 'Slide was deleted successfully',
    'slideCreate': 'Slide was created successfully',
    'slideEdit': 'Slide was changed successfully',
    'saveCoverPhoto': 'Cover photo was uploaded successfully',
    'deleteCoverPhoto': 'Cover photo was deleted successfully',
    'addCategory': 'Category was created successfully',
    'editCategory': 'Category was updated successfully',
    'deleteCategory': 'Category was deleted successfully',
    'joinSocialAccount': 'Social account was connected successfully',
    'walletConnectSuccess': 'Transaction was successful',
    "walletConnectTransaction": 'We are waiting for a transaction.',
    "walletConnectProcessing": 'Your order for event has been processed. It should be confirmed on the blockchain shortly.',
    "nftWalletConnectedToAccount": 'Wallet has been connected to your account',
    'claimed': 'Claimed',
    'MINT_MOBILE_RECOMMEND': 'We recommend using the wallet browser',
    'OtpSendAgain': "OTP code has been sent",
    "otpCode": "OTP code was sent, please enter the code",
    "walletConnectReg": "Enter your phone number or email to register.",
    "uploadTicketsInProgress": "Your tickets are in process, we will inform you when the process is done",
    "messageSent": "Message has been sent.",
    "messageSentWarning5": 'The message was sent to the producer, but we could not send a confirmation to your email. Please check that email was added in Profile settings',
    "messageSentWarning6": 'The message was sent to the producer, but we could not send a confirmation to your email. We have problem with send',
    "noData": "No Data.",
    "bankServiceStripeCountryChange": "Your Stripe account has been unlinked after country change. Please click Connect To Stripe again.",
    "loginAttemptsBlocked1hour": "Too many login attempts. Try again after 1 hour or use your email."
  }

  transferTickets = {
    14: 'Ticket transferred, but we could not send the message to the email.',
    15: 'Ticket transferred, but we could not send SMS.',
  }

  waitingListApprove = {
    1: 'We have approved a smaller amount of tickets. Try in the second request.'
  }
  stripe_payout_success = {
    1: 'Account successfully activated!'
  }

  passkeys = {
    1: 'Success! Passkey Registered!'
  }

  constructor() {
  }

  getAlertStr(successGroup, successCode) {
    return (this[successGroup][successCode] ? this[successGroup][successCode] : '');
  }
}
