
import {tap, map, catchError, shareReplay} from 'rxjs/operators';
import {LoginToAppResponse, LoginToAppIGResponse} from './../interfaces/authentication.interface';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import $ from 'jquery';
import {environment} from '../../environments/environment';
import {BehaviorSubject, EMPTY, throwError} from 'rxjs';
import {FirebaseMessagingService} from './firebase.messanging.service';
import { AuthCacheService } from '../services-cache/auth-cache.service';

declare var CryptoJS: any;

@Injectable()

export class AuthService {
  api_base_url = environment.api_base_url;
  emptyUserData = {
    user_id: 0,
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    identification_number: '',
    facebook_id: '0',
    age: '',
    birth_date: '',
    gender: '',
    user_link: '',
    type: 0,
    company: '',
    img: '',
    phoneCountryCode: '',
    fb_user_link: '',
    personal_info: 0,
    id: ''
  };
  auth = {
    refresh: '',
    session: ''
  };
  original = {
    error: '',
    facebook_id: '0',
    personal_info: 0,
    success: '',
    uid: ''
  };
  emptyFacebookObj: LoginToAppResponse = {
    data: this.emptyUserData,
    personal_info: 0,
    error: false,
    errorCode: 0,
    auth: this.auth,
    original: this.original
  };

  emptyInstagramObj: LoginToAppIGResponse = {
    data: this.emptyUserData,
    personal_info: 0,
    error: false,
    errorCode: 0,
    auth: this.auth,
    user_data: this.emptyUserData,
    instagram_id: '',
    instagram_img: '',
    lang: ''
  };

  private currentFacebookLoginData = new BehaviorSubject(this.emptyFacebookObj);
  getCurrentFacebookLoginData = this.currentFacebookLoginData.asObservable();


  date = new Date();
  fromWallet: string = '';
  toWallet: string = '';
  tokenId: string = '';

  constructor(private http: HttpClient,
              private _cookieService: CookieService,
              private firebase: FirebaseMessagingService,
              private authCacheService: AuthCacheService
              ) {
    this.date.setDate(this.date.getDate() + 7); // 7 days from now

  }

  preSignUpSignIn(action: string, phone_email: string, password: string, phone_country_code, csrf_token = '', otp_code?, type = 'V2') {
    // if (action) {
    let data = {phone_email, password, phone_country_code, otp_code};
    if (csrf_token) {
      data['csrf_token'] = csrf_token;
    }
      console.log('Send as ', action);
      return this.http.post(this.api_base_url + '/api/user-auth/login/'+type,
        data,
        {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})}).pipe(
        map((response: any) => {
            const res = response;
            if (res.original.error) {
              $(document).find('.loader').remove();
            }
            return res;
          }
        ),tap(
          tokenData => {
            // this.setRiskifiedSessionId('remove');
          }
        ),);
    // }
    // else {
    //   console.log('Send as ', action);
    //   return this.http.post(this.api_base_url + '/api/user-auth/login',
    //   {phone_email, password, phone_country_code, otp_code},
    //   {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
    // }
  }

  preSignUp(action: string, phone_email: string, password: string, phone_country_code, csrf_token = '', captchaToken: string = '', type = 'V2', event_id = 0) {
    let data = {phone_email, password, phone_country_code, action, 'g-recaptcha-response': captchaToken, event_id}
    if (csrf_token) {
      data['csrf_token'] = csrf_token;
    }
    if (captchaToken) {
      data['g-recaptcha-response'] = captchaToken;
    }
    return this.http.post(this.api_base_url + '/api/user-auth/check/'+type,
    data,
    {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }

  preSignUpV3(action: string, phone_email: string, password: string, phone_country_code, captchaToken: string) {
    return this.http.post(this.api_base_url + '/api/user-auth/check/V3',
      {phone_email, password, phone_country_code, action, 'g-recaptcha-response': captchaToken},
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }

  signup_(phone_email: string, password: string) {
    return this.http.post(this.api_base_url + '/api/user',
      {phone_email: phone_email, password: password},
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }

  signin_(phone_email: string, password: string) {

    return this.http.post(this.api_base_url + '/api/user/signin',
      {phone_email: phone_email, password: password},
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})}).pipe(
      map(
        (response: any) => {

          const res = response;

          if (res.original.error) {
            $(document).find('.loader').remove();
          }

          return res;
        }
      ),tap(
        tokenData => {

        }
      ),);
  }

  switchPushNotifications(param: string) {
    this.isTokenExist();
    return this.http.post(this.api_base_url + '/api/user/switchPushNotification',
      {notifyDisabled: param},
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }

  signinExternalUser(email: string, external_password: string) {
    return this.http.post(this.api_base_url + '/api/user/external-user-login',
      {email, external_password},
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }

  public storeReaderApiKey(apiKey: string) {
    const tokenCookieExpDate = new Date();
    tokenCookieExpDate.setDate(tokenCookieExpDate.getDate() + 1); // tomorrow
    this._cookieService.set('readerApiKey', apiKey, {
      expires: tokenCookieExpDate,
      path: '/',
      secure: environment.production && location.protocol === 'https:',
    });
  }

  public removeReaderApiKey() {
    this._cookieService.delete('readerApiKey', '/');
  }

  public storeCurrentConnectedReaderId(readerId) {
    const tokenCookieExpDate = new Date();
    tokenCookieExpDate.setDate(tokenCookieExpDate.getDate() + 1); // tomorrow
    this._cookieService.set('connectedReaderId', readerId, {
      expires: tokenCookieExpDate,
      path: '/',
      secure: environment.production && location.protocol === 'https:',
    });
  }

  public removeConnectedReaderId() {
    this._cookieService.delete('connectedReaderId', '/');
  }

  storeReaderApiKeyAndId(apiKey, readerId) {
    this.storeReaderApiKey(apiKey);
    this.storeCurrentConnectedReaderId(readerId);
  }

  removeReaderApiKeyAndId() {
    this.removeConnectedReaderId();
    this.removeReaderApiKey();
  }

  public storeTokens(sessionToken: string, refreshToken: string) {
    const tokenCookieExpDate = new Date();
    tokenCookieExpDate.setDate(tokenCookieExpDate.getDate() + 60); // two months from now (much longer than our token's lifespan
    this._cookieService.set('token', sessionToken, {
      expires: this.date,
      path: '/',
      secure: environment.production && location.protocol === 'https:',
    });
    this._cookieService.set('refresh-token', refreshToken, {
      expires: tokenCookieExpDate,
      path: '/',
      secure: environment.production && location.protocol === 'https:',
    });
  }

  public storeToken(sessionToken: string, refreshToken: string = '') {
    const tokenCookieExpDate = new Date();
    tokenCookieExpDate.setDate(tokenCookieExpDate.getDate() + 60); // two months from now (much longer than our token's lifespan
    this._cookieService.set('token', sessionToken, {
      expires: this.date,
      path: '/',
      secure: environment.production && location.protocol === 'https:',
    });
    if (refreshToken) {
      this._cookieService.set('refresh-token', refreshToken, {
        expires: tokenCookieExpDate,
        path: '/',
        secure: environment.production && location.protocol === 'https:',
      });
    }
    this.refreshUserInfoExpireDate();
  }

  logout(firebaseInfo?) {
    return this.http.post(this.api_base_url + '/api/user/logout',
      {firebase: firebaseInfo ? firebaseInfo : null,
            refresh_token: this.getRefreshToken()},
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})}).pipe(
      map(
        (response: any) => {
          // return response:
          if (response.success) {
            this.clearUser();
          }
          return response;
        }
      ))
  }

  clearUser() {
    this.emptyFacebookObj.data = {
      user_id: 0,
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      identification_number: '',
      facebook_id: '0',
      age: '',
      birth_date: '',
      gender: '',
      user_link: '',
      type: 0,
      company: '',
      img: '',
      phoneCountryCode: '',
      fb_user_link: '',
      personal_info: 0,
      id: ''
    };
    // console.log('set empty obj', this.emptyFacebookObj, this.emptyUserData);
    this._cookieService.delete('token', '/');
    this._cookieService.delete('refresh-token', '/');
    this._cookieService.set('facebook_id', null);
    this._cookieService.set('instagram_id', null);
    // this.removeReaderApiKeyAndId();
    this._cookieService.delete('ext_user','/');
    this._cookieService.delete('user_info','/');
    this._cookieService.delete('FCMT', '/');
    if (this._cookieService.get('user_id')) {
      this._cookieService.delete('user_id');
    }
    this._cookieService.delete('u_logged_wallet');
    this._cookieService.deleteAll('/');
    this._cookieService.deleteAll('/en');
    this._cookieService.deleteAll('/es');
    this._cookieService.deleteAll('/he');
  }

  refreshToken() {
    return this.http.post(this.api_base_url + '/api/user/refresh',
      {token: this.getRefreshToken()},
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})}).pipe(
      map(
        (response: any) => {
          this.storeToken(response.session, response.refresh);

          // return response:
          return response;
        }
      ),tap(
        responseData => {
        }
      ),);
  }


  getReaderSignupData(event_id: number) {
    this.isTokenExist();
    return this.http.get(this.api_base_url + '/api/reader-events/' + event_id,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }

  readerSignin(loginData: Object) {
    return this.http.post(this.api_base_url + '/api/reader-login',
      {loginData},
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }

  readerSignup(event_id: Number, events: any, type: Number, reader_credentials: Object) {
    this.isTokenExist();
    return this.http.post(this.api_base_url + '/api/reader-register',
      {event_id, events, type, reader_credentials},
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }

  resendReaderPassword(reader_id: number, event_id: number) {
    return this.http.get(this.api_base_url + '/api/reader-resendpass/' + reader_id + '/' + event_id,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }

  removeReader(reader_id: number, event_id: number) {
    this.isTokenExist();
    return this.http.post(this.api_base_url + '/api/reader-remove',
      {event_id, reader_id},
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }

  getCurrentConnectedReader() {
    return this._cookieService.get('connectedReaderId');
  }

  getReaderApiKey() {
    return this._cookieService.get('readerApiKey');
  }

  getToken() {
    return this._cookieService.get('token');
  }

  getRefreshToken() {
    // return localStorage.getItem('token');
    return this._cookieService.get('refresh-token');
  }

  getFCMToken() {
    return this._cookieService.get('FCMT');
  }

  getUser() {
    return this.getUserInfo() && (this.getUserInfo().user_id || this.getUserInfo().id) ? this.getUserInfo().user_id || this.getUserInfo().id : 0;
  }

  getUserInfo() {
    let user_info = this._cookieService.get('user_info');
    if (user_info) {
      const dec_user_info = CryptoJS.AES.decrypt(user_info, 'user_info');
      user_info = dec_user_info.toString(CryptoJS.enc.Utf8);
      return user_info = JSON.parse(user_info);
    }
    return {};
  }

  getType() {
    const user = this.getUserInfo();
    if (user) {
      return +user.type;
    }
    return undefined;
  }

  isAdmin() {
    return this.getType() === 2;
  }

  getFacebookId() {
    return this._cookieService.get('facebook_id');
  }

  getInstagramId() {
    return this._cookieService.get('instagram_id');
  }

  resetPassword(email: string, token: string) {
    return this.http.post(this.api_base_url + '/api/user/reset-password',
      {email: email, g_recaptcha_response: token},
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }

  newPassword(password: string, token: string) {
    return this.http.post(this.api_base_url + '/api/user/new-password',
      {password: password, token: token},
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }

  changePassword(oldPassword: string, newPassword: string) {
    this.isTokenExist();
    return this.http.post(this.api_base_url + '/api/user/change-password',
      {oldPassword: oldPassword, newPassword: newPassword},
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }

  getUserDetails(disableCache = false) {
    this.isTokenExist(false);
    let user_details$ = disableCache ? null : this.authCacheService.getValue('user-details');

    if (!user_details$) {
        user_details$ = this.http.post(this.api_base_url + '/api/user-details',
        {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})}).pipe(
        map(
          (response: any) => {
            // console.log('getUserDetails', response);
            return this.mapUserDetailsResponse(response);
          }
        ),
        shareReplay(1));
        this.authCacheService.setValue(user_details$, 'user-details', 3600); // = 60 min
    }
    return user_details$
  }

  getProducerDetails(userId) {
    let producer_details$ = this.authCacheService.getValue(userId);
    if (!producer_details$) {
      producer_details$ = this.http.post(this.api_base_url + '/api/producer-details',
        {userId},
        {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})}).pipe(
        map(
          (response: any) => {
            return this.mapUserDetailsResponse(response);
          }
        ),
        shareReplay(1));

      this.authCacheService.setValue(producer_details$, userId, 3600); // = 60 min
    }

    return producer_details$
  }

  mapUserDetailsResponse(userDetailsResponse: any = {}) {
    if (userDetailsResponse && userDetailsResponse.user_id) {
      if (Object.keys(userDetailsResponse).length > 0) {
        if (userDetailsResponse.first_name == null) {
          userDetailsResponse.first_name = '';
        }
        if (userDetailsResponse.last_name == null) {
          userDetailsResponse.last_name = '';
        }
      }
    }
    return userDetailsResponse;
  }


  updateUserDetails(form: any) {
    this.authCacheService.clearCache();
    return this.http.post(this.api_base_url + '/api/update-user-details',
      {
        fname: form.fname,
        lname: form.lname,
        dob: form.dob,
        email: form.email,
        phone: form.phone,
        phoneCountryCode: form.phoneCountryCode,
        validate_phone: form.validate_phone,
        gender: form.gender,
        id_number: form.id_number,
        company: form.company,
        facebookPageLink: form.facebookPageLink,
        instagramPageLink: form.instagramPageLink,
        bio: form.bio,
        instagram_url: form.instagram_url,
      },
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})}).pipe(
      map(
        (response: any) => {
          let user_info = JSON.stringify(response.user_info);
          let enc_user_info = CryptoJS.AES.encrypt(user_info, 'user_info');
          this._cookieService.set('user_info', enc_user_info, {
            expires: this.date,
            path: '/',
            secure: ((location.protocol == 'https:') ? true : false)
          });

          return {response: response};
        }
      ),tap(
        response => {
          return response;
        }
      ),);

  }



  refreshUserInfoExpireDate() {
    let user_info = this._cookieService.get('user_info');
    if (user_info) {
      let date = new Date();
      date.setDate(date.getDate() + 60); // 90 days from now
      this._cookieService.set('user_info', user_info, {
        expires: date,
        path: '/',
        secure: ((location.protocol == 'https:') ? true : false)
      });
    }
  }

  updateAgt(agt: string) {
    this.isTokenExist();

    return this.http.post(this.api_base_url + '/api/user/is-salesman',
      {agt: agt},
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }

  addPaymentMethod(form) {
    return this.http.get(this.api_base_url + '/api/user/?').pipe(map(response =>
      setTimeout(() => {
        response
      }, 3000)
    ));
  }

  saveUserCookies(user_info_data, user_social_id, social?) {
    const user_info = JSON.stringify(user_info_data);
    const enc_user_info = CryptoJS.AES.encrypt(user_info, 'user_info');
    const tokenCookieExpDate = new Date();
    tokenCookieExpDate.setDate(tokenCookieExpDate.getDate() + 60); // two months from now (much longer than our token's lifespan
    this._cookieService.set('user_info', enc_user_info, {
      expires: tokenCookieExpDate,
      path: '/',
      secure: (location.protocol == 'https:') ? true : false});
    if (social === 'instagram') {
      this._cookieService.set('instagram_id', user_social_id, {
        expires: tokenCookieExpDate,
        secure: ((location.protocol == 'https:') ? true : false)
      });
    } else {
      this._cookieService.set('facebook_id', user_social_id, {
        expires: tokenCookieExpDate,
        secure: ((location.protocol == 'https:') ? true : false)
      });
    }
  }


  walletCheckEmail(email) {
    return this.http.post(this.api_base_url + '/api/user/check', {email},
    {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
      .pipe(map((response: any) => {
        if (response && response.code == 'success') {
          return response.data.check; // true - if email exists in db
        }
      }), catchError((error) => {
        return throwError(error);
      })
    );
  }


  updateUserPreferredLang(data) {
    return this.http.post(this.api_base_url + '/api/user/update-user-preferred-lang', data,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }

  verifyPhoneBySMS(phone, phoneCountryCode) {
    const data = {
      phone: phone,
      phoneCountryCode: phoneCountryCode,
    }
    return this.http.post(this.api_base_url + '/api/user/activation/sms' , data, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  verifyPhoneBySMSCheck(phone, phoneCountryCode, phone_validate_code) {
    const data = {
      phone: phone,
      phoneCountryCode: phoneCountryCode,
      phone_validate_code: phone_validate_code
    }
    // console.log(data);
    return this.http.post(this.api_base_url + '/api/user/activation/sms/check' , data, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }


  // Generate unique identifier
  // uid() {
  //   return moment().valueOf().toString(36) + Math.random().toString(36).substr(2);
  // }

  public storeLoggedInWallet(wallet: string) {
    this._cookieService.set('u_logged_wallet', wallet, {
      expires: this.date,
      secure: environment.production && location.protocol === 'https:',
    });
  }

  getActiveEvents() {
    const user_info = this.getUserInfo();
    if(user_info && user_info.hasOwnProperty('active_events')) {
      return <number>user_info.active_events
    } else {
      return <number>0
    }
  }



  isConnected() {
    return this.getToken() !== '';
  }
  // PAYTWEED RELATED
  setFromWallet(wlt) {
    this.fromWallet = wlt;
  }
  getFromWallet() {
    return this.fromWallet
  }
  setToWallet(wlt) {
    this.toWallet = wlt;
  }
  getToWallet() {
    return this.toWallet
  }
  setTokenId(t) {
    this.tokenId = t;
  }
  getTokenId() {
    return this.tokenId
  }

  isTokenExist(reload = true) {
    // console.log('this.getRefreshToken()', this.getRefreshToken())
    // console.log('this.this.getToken()()', this.getToken())
    if (this.getRefreshToken() && (this.getToken() === '')) {
      console.log('REFRESH')
      // setTimeout(()=>{window.location.reload()}, 300);

      return EMPTY
    }
    if ((this.getToken() === '' && this.getRefreshToken() === '') || (this.getRefreshToken() === '')) {
      console.log('NO TOKEN!!!');
      this.clearUser();
      this.authCacheService.clearCache();
      if (reload) {setTimeout(()=>{window.location.replace('')}, 500);}
      return EMPTY
    }
  }

}
