<!-- {{control.errors.pattern.actualValue}} -->
<span i18n="error creator | unique code" class="item-error {{class}}" *ngIf="control.hasError('notUnique')">Enter a unique code</span>
<span i18n="@@ErrorCreatorFieldRequired" class="item-error {{class}}" *ngIf="control.hasError('required') && name !== 'tickets'">Field is required</span>
<span i18n="error creator | no tickets" class="item-error {{class}}" *ngIf="control.hasError('required') && name == 'tickets'">Please chose the tickets</span>
<span i18n="@@ErrorCreatorMinValue" class="item-error {{class}}" *ngIf="control.hasError('min')">Minimum value {{control.errors.min.min}}</span>
<span i18n="@@ErrorCreatorMaxValue" class="item-error {{class}}" *ngIf="control.hasError('max')">Maximum value {{control.errors.max.max}}</span>
<span i18n="@@ErrorCreatorMaxLength" class="item-error {{class}}" *ngIf="control.hasError('maxlength')">Maximum field length is {{control.errors.maxlength.requiredLength}} Characters</span>
<span i18n="@@ErrorCreatorMinLength" class="item-error {{class}}" *ngIf="control.hasError('minlength')">Minimum field length is {{control.errors.minlength.requiredLength}} Characters</span>
<span class="item-error {{class}}" *ngIf="control.hasError('pattern')">
  <span i18n="@@ErrorCreatorfriendlyurl" *ngIf="name === 'url_friendly'">Letters and numbers only, must begin with a letter.</span>
  <span i18n="@@ErrorCreatorvalidemail" *ngIf="name === 'contact_email'">Please enter a valid email</span>
  <span i18n="@@ErrorCreatorvalidgoogle" *ngIf="name === 'google_analytics_ua'">Please enter a valid Google code</span>
  <span i18n="@@ErrorCreatorvalidaba_number" *ngIf="name === 'aba_number'">Please enter a valid ABA number, containing 9 digits</span>
  <span i18n="error creator | special characters" *ngIf="name == 'name' || name == 'code'">Special characters are not allowed</span>
  <span i18n="error creator | ticket_price" *ngIf="name == 'ticket_price'">Ticket price must be an integer</span>
  <span i18n="error creator | bank_id" *ngIf="name == 'bank_id'">Please enter Bank</span>
  <span i18n="error creator | recipient_phone" *ngIf="name == 'recipient_phone'">Please enter a valid phone number</span>
  <span i18n="error creator | valid characters" *ngIf="name == 'bank_name' || name == 'branch' || name == 'account_number' || name == 'account_name'
   || name == 'company_user_id' || name == 'address' || name == 'other_bank_details'">Please enter valid characters</span>
</span>
<span i18n="@@ErrorCreatorvalidphone" class="item-error {{class}}" *ngIf="name === 'marketing_contact' || (name === 'phone' && control.hasError('invalid')) || (name === 'phone_number' && control.hasError('invalid'))">Please enter a valid phone number</span>
<span i18n="@@ErrorCreatorfriendlyurltaken" class="item-error" *ngIf="control.hasError('urlTaken')">Friendly URL already in use</span>
<span i18n="@@ErrorCreatordatenotafter" class="item-error" *ngIf="control.hasError('invalidStartDate')">Start date can't be later than end date</span>
<span i18n="@@ErrorCreatordatenotbefore" class="item-error" *ngIf="control.hasError('invalidEndDate')">End date can't be earlier than start date</span>
<span i18n="@@ErrorCreatormaxsize10mb" class="item-error" *ngIf="control.hasError('fieldToBig')">Field is limited to 10MB</span>
<span i18n="@@ErrorCreatordatenotafterendevent" class="item-error" *ngIf="control.hasError('endDate')">Start date can't be later than end date</span>
