import {
  catchError,
  map,
  distinctUntilChanged,
  debounceTime,
  startWith,
  switchMap,
  tap
} from 'rxjs/operators';
import {LocalizationService} from './../services/localization.service';
import {Subscription, BehaviorSubject, Observable, timer, of} from 'rxjs';
import {AppResponse} from '../interfaces/app-response.interface';
import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injectable,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  AfterViewInit
} from '@angular/core';
import {ActivatedRoute, Router, NavigationEnd, NavigationStart, RoutesRecognized} from '@angular/router';
import {EventService} from '../services/event.service';
import {GlobalsService} from '../globals';
import {DialogService} from '../services/dialog.service';
import {fade, slideIn, SlideInOutAnimation} from '../animation';
import {
  AbstractControl,
  FormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {MbscColorOptions, MbscDatetimeOptions, MbscSelectOptions, mobiscroll} from '@mobiscroll/angular';
import {emailRegex, emojiRegex, googleAnalyticsUa, imageRegex, urlRegex} from '../regex';
import {Buffer} from 'buffer';
import Quill from 'quill';
import ImageResize from 'quill-image-resize-module';
import {
  EventData,
  image,
  Item,
  Category,
  NftStatus,
  BlockedInputs,
  Artists
} from '../interfaces/event-data.interface';
import {CropperComponent} from 'angular-cropperjs';
import {DomSanitizer} from '@angular/platform-browser';
import {QuillEditorComponent} from 'ngx-quill';
import {ClipboardService} from 'ngx-clipboard';
import {BankService} from '../services/bank.service';
import {environment} from '../../environments/environment';

import {CountryCurrencies} from '../static/country.currencies';
import {NavbarService} from '../services/navbar.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { CdTimerComponent } from 'angular-cd-timer';
import $ from 'jquery';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { CustomFieldsDialogComponent } from './create-edit-event-dialogs/custom-fields-dialog/custom-fields-dialog.component';
import {RudderstackService} from "../services/rudderstack.service";
import sanitizeHtml from 'sanitize-html';
import sanitizeOptions from '../static/sanitized-html-options'
import {
  ManagePasscodesDialogComponent
} from "./create-edit-event-dialogs/manage-passcodes-dialog/manage-passcodes-dialog.component";
import {CmsCurrency} from "../interfaces/currency.interface";
import {SpotifyService} from "../services/spotify.service";
import {CookieService} from "ngx-cookie-service";

Quill.register('modules/imageResize', ImageResize);

declare var google: any;
declare var window: any;

@Injectable()
@Component({
  selector: 'app-create-edit-event',
  templateUrl: './create-edit-event.component.html',
  styleUrls: ['./create-edit-event.component.css'],
  animations: [SlideInOutAnimation, slideIn, fade]
})
export class CreateEditEventComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {

  getin_base_url = environment.getin_base_url;
  images_base_url = environment.images_base_url;
  currencyCodes: Array<CmsCurrency> = [];
  countryCurrencies = CountryCurrencies;
  timerDate = 0;
  langsList: Array<any>;
  localeId: string;
  eventStartDate: string;
  eventEndDate: string;
  eventData: EventData;
  draftSubscription: Subscription;
  endDateSubscribtion: Subscription;
  routerSubscriprion: Subscription;
  bootstrapBoolean = false;
  fireAsyncValidation = false;
  visibleStep: string;
  submitted = false;
  selectedlanguages = [];
  formsubmited = false;
  cropperConfig = {
    movable: false,
    scalable: false,
    zoomable: false,
    viewMode: 0
  }
  preventSubmittingWhileDraftSaving = false;

  location: any;
  coverCropperConfig = {...this.cropperConfig, aspectRatio: 1 / 1};
  bgCropperConfig = {...this.cropperConfig, aspectRatio: 16 / 9};
  isMainImgSet = false;
  isBgImgSet = false;
  feedCropperConfig = {...this.cropperConfig, aspectRatio: 1 / 2};

  @ViewChild('basicTimer') public cdTimer: CdTimerComponent;

  @ViewChild('coverImageCropper') public coverImageCropper: CropperComponent;
  @ViewChild('bgImageCropper') public bgImageCropper: CropperComponent;
  @ViewChild('feedImageCropper') public feedImageCropper: CropperComponent;

  @ViewChild('coverFileInput') public coverFileInput: ElementRef;
  @ViewChild('bgFileInput') public bgFileInput: ElementRef;
  @ViewChild('feedFileInput') public feedFileInput: ElementRef;
  @ViewChild('min_age') public min_age: ElementRef;

  @ViewChild('ticketCheckBoxRef')
  ticketCheckBoxRef: ElementRef;
  @ViewChild('ticketRegCheckBoxRef')
  ticketRegCheckBoxRef: ElementRef;

  coverCropperComponent;
  coverUrl;
  bgCropperComponent;
  bgUrl;
  feedCropperComponent;
  feedUrl;
  registrationMode = 0;
  needCurrency = true;
  quill;
  quillModule;
  quillInstances = {};
  userCur = 0;
  draft = false;
  frontId = 0;
  imageFile: File;

  @ViewChildren('quillEditor') quillComponent: QueryList<QuillEditorComponent>;
  quillSubscriptionLangIndexes = [];
  desc = {};
  quillDescriptionValues = {};
  currentLangOnQuill = '';

  toolbarOptions = {
    container: [
      [{'font': []}],
      ['bold', 'italic', 'underline', 'strike'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      [{'indent': '-1'}, {'indent': '+1'}],
      [{'direction': 'rtl'}, {'align': []}],
      ['image'],
      ['video'],
      ['link'],
      [{'size': ['small', false, 'large', 'huge']}],
      [{'header': [1, 2, 3, 4, 5, 6, false]}],
      [{'color': []}, {'background': []}],
      ['soundCloud']
    ],
    handlers: {
      'soundCloud': this.insertIframe.bind(this)
    }
  }

  quillPlaceholder: any;
  event_id: number = null;

  colorPickerSetting: MbscColorOptions = {
    // themeVariant: 'dark',
    display: 'center',
    theme: this.globals.mobiscrollTheme(),
    lang: this.globals.LOCALE_ID,
    select: 'single',
    mode: 'refine',
    onSet: (event) => {
      this.colorPicker(event.valueText);
    }
  }

  mainDateTimeSettings: MbscDatetimeOptions = {
    // themeVariant: 'dark',
    display: 'center',
    theme: this.globals.mobiscrollTheme(),
    dateFormat: this.globals.detectMonthFirstCountryFromNavigator() ? 'mm/dd/yy' : 'dd/mm/yy',
    dateWheels: this.globals.detectMonthFirstCountryFromNavigator() ? '|mm/dd/yy|' : '|dd/mm/yy|',
    lang: this.globals.LOCALE_ID,
    animate: 'slideup',
    returnFormat: 'iso8601'
  }

  startDateSettings: MbscDatetimeOptions = {
    // themeVariant: 'dark',
    display: 'center',
    ...this.mainDateTimeSettings,
    onBeforeShow: (event, inst) => {
      if (new Date(this.eventStartDate).getTime() < new Date().getTime()) {
        this.eventStartDate = new Date().toUTCString();
      }
      ;
      inst.option({
        min: new Date(),
        max: (this.eventEndDate) ? new Date(this.eventEndDate) : undefined
      })
    },
    onSet: (event, inst) => {
      console.log(inst.getDate());
      this.eventStartDate = inst.getDate()
    }
  };

  endDateSettings: MbscDatetimeOptions = {
    // themeVariant: 'dark',
    display: 'center',
    ...this.mainDateTimeSettings,
    onBeforeShow: (event, inst) => {
      inst.option({
        min: (this.eventStartDate) ? this.eventStartDate : new Date()
      })
    }
  };

  itemsDatesSetting: MbscDatetimeOptions = {
    // themeVariant: 'dark',
    display: 'center',
    ...this.mainDateTimeSettings,
    onBeforeShow: (event, inst) => {
      inst.option({
        min: new Date(),
        max: (this.eventEndDate) ? this.eventEndDate : undefined
      })
    }
  };

  birthDateSetting: MbscDatetimeOptions = {
    // themeVariant: 'dark',
    display: 'center',
    theme: this.globals.mobiscrollTheme(),
    dateWheels: 'yy MM dd',
    defaultValue: '1980-01-01',
    lang: this.globals.LOCALE_ID,
    animate: 'slideup',
    returnFormat: 'iso8601',
    max: new Date()
  };

  purchaseValidation = true;
  birthdayValidation = false;
  gallery_cover;
  gallery_bg;
  gallery_feed;
  gallery_portrait_feed: string;
  coverImgSize = '';
  bgImgSize = '';
  ticket_images = [];
  isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  editEventTimeout = false;
  mainStep: UntypedFormGroup;
  advancedStep: UntypedFormGroup;
  ticketsArray: UntypedFormArray = this.formBuilder.array([]);
  categoriesArray: UntypedFormArray = this.formBuilder.array([]);
  descriptionArray: UntypedFormArray = this.formBuilder.array([], [this.validateInBytes, this.validateDescLength]);
  itemsStep: UntypedFormGroup;
  couponsArray: UntypedFormArray = this.formBuilder.array([]);

  boostGroup: UntypedFormGroup;

  isAccept = true;
  event: UntypedFormGroup;
  toggleOptions = false;

  selectLangSettings: MbscSelectOptions = {
    // themeVariant: 'dark',
    display: 'center',
    filter: false,
    theme: this.globals.mobiscrollTheme(),
    lang: this.globals.LOCALE_ID,
    select: 'single', // multiple , single
    // touchUi: this.globals.inApp() ? true : false,
    onSet: (event, inst) => {
      const vals = inst.getVal();
      // console.log('electLangSettings onSet', vals);
      if (Array.isArray(vals)) {
        this.handleAddLang(vals);
        this.setSelectedLanguages(vals);
        this.handleRemovaLang(vals);
        this.setLastTabActive(vals);
      } else {
        this.handleAddLang([vals]);
        this.setSelectedLanguages([vals]);
        this.handleRemovaLang([vals]);
        this.setLastTabActive([vals]);
      }
      this.cdr.detectChanges();
    }
  }

  timeZones: Array<any> = [];
  timeZoneFiltered: Array<any> = [];

  selectZonesSettings: MbscSelectOptions = {
    // themeVariant: 'dark',
    display: 'center',
    filter: true,
    // theme: this.globals.mobiscrollTheme(),
    lang: this.globals.LOCALE_ID,
    onFilter: (event: any, inst) => {
      const cleanSearchText = event.filterText.toLowerCase().split(' ').filter(String).join('_');
      this.timeZoneFiltered = this.timeZones.filter(zone => zone.toLowerCase().includes(cleanSearchText));
      inst.refresh(this.timeZoneFiltered, cleanSearchText);
      return false; // return false to prevent default filtering
    }
  }

  selectTicketsSettings: MbscSelectOptions = {
    // themeVariant: 'dark',
    display: 'center',
    filter: false,
    theme: this.globals.mobiscrollTheme(),
    lang: this.globals.LOCALE_ID,
    // touchUi: this.globals.inApp() ? true : false,
  }
  isShowId = true;
  defaultCurency: String = '';
  navigateUrl: string;
  isOnMobile: boolean;
  @ViewChildren (MatExpansionPanel) pannels: QueryList<MatExpansionPanel>

  ticketTimeZoneChangedByUser: boolean = false;

  nftStatus: NftStatus;
  donateStatus: NftStatus;
  blockedInputs: BlockedInputs;

  ticketImages: any[] = [];

  // kyc verification
  hasKyc: boolean = false;
  party_emoji = '&#129395;';
  showBoost: boolean = false; // Boost conditions
  quantityVal: number = 0; // Boost conditions
  priceVal: number = 0; // Boost conditions

  currFlags = {
      "ILS": 'IL',
      "USD": 'US',
      "EUR": 'EU',
      "GBP": 'GB',
      "PEN": 'PE',
      "MXN": 'MX',
      "CAD": 'CA',
      "MAD": 'MA',
      "THB": 'TH',
      "BRL": 'BR',
      "AED": 'AE',
      "TRY": 'TR',
      "UAH": 'UA',
      "PLN": 'PL',
  }

  spotifyControl = new FormControl();
  spotifyOptions: Observable<any[]>;
  selectedSpotifyArtists = {};
  artistsData: Artists[] = [];

  constructor(
    private sanitizer: DomSanitizer,
    private clipboardService: ClipboardService,
    private formBuilder: UntypedFormBuilder,
    private eventService: EventService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public globals: GlobalsService,
    public rudderstackService: RudderstackService,
    private dialogService: DialogService,
    private bankService: BankService,
    private cdr: ChangeDetectorRef,
    public localizationService: LocalizationService,
    private navbar: NavbarService,
    private el: ElementRef,
    private dialog: MatDialog,
    private spotifyService: SpotifyService,
    private _cookieService: CookieService,
    // private kycService: KycVerificationService
  ) {
    const Image = Quill.import('formats/image');
    Image.className = 'img-responsive';
    const Link = Quill.import('formats/link');
    Link.sanitize = function (url) {
      const getValidUrl = (link = '') => {
        let newUrl = window.decodeURIComponent(link);
        newUrl = newUrl.trim().replace(/\s/g, '');

        if (/^(:\/\/)/.test(newUrl)) {
          return `http${newUrl}`;
        }
        if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
          return `http://${newUrl}`;
        }

        return newUrl;
      };

      const validUrl = getValidUrl(url);
      return validUrl;
    };

    Quill.register(Image, true);
    this.quillModule = {imageResize: {}, toolbar: this.toolbarOptions}
    mobiscroll.settings = {
      theme: this.globals.mobiscrollTheme(),
      lang: this.globals.LOCALE_ID
    }
    this.localeId = this.globals.getLocaleId();
    this.langsList = this.globals.getCountryData();
    this.langsList.forEach(e => {
      e['active'] = false;
    });
    this.quillPlaceholder = this.globals.quillPlaceholder;
    // console.log('this.navigateUrl', this.navbar.prevUrl);
  }


  scrollToErrorField() {
    setTimeout(() => {
      const results = (<HTMLElement>document.querySelector('input.form-control:not(.datepicker).ng-invalid'));
      // console.log('results', results);
      // const mat = results.closest('mat-expansion-panel');
      if (results) {
        results.focus();
        results.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 200);
  }

  setLang(event) {
    // console.log('selected language', event, this.langsList);
    if (Array.isArray(event)) {
      this.setCurrentLangOnQuill(event[0]);
    } else {
      this.setCurrentLangOnQuill(event);
    }
  }

  submitForm() {
    console.log(this.event.valid)
    console.log(this.event)

    if (this.registrationMode === 1 && this.event.get('boost') && this.event.get('boost').invalid) {
      this.event.removeControl('boost');
    }

    if (this.preventSubmittingWhileDraftSaving) {return} // If DraftSaving in progress
    if(this.advancedStep.get('min_age').invalid) {
      this.pannels.get(0).open();
      this.dialogService.alertsModal('editEvent', 18, 3000, false);
      setTimeout(()=>{ this.min_age.nativeElement.focus();},100);
      return;
    }
   // console.log('this.event', this.event.value, this.event.controls.boost, this.boostGroup.valid, this.event.controls.boost)
   // console.log('this.event', this.registrationMode)
    this.globals.startLoader();
    this.pannels.some((value, index, array) => {
      // console.log('x, index', value, index, array, this.pannels);
      if (index === 0 && this.event.get('main').invalid) {
        value.open();
        return true;
      }
      if (index === 2 && this.event.get('items').invalid) {
        value.open();
        return true;
      }
      if (index === 3 && this.event.get('advanced').invalid) {
        value.open();
        return true;
      }
      if (index === 1 && this.event.get('main').invalid) {
        // x.open();
        return false;
      }
      if (index === 5 && this.event.get('boost') && this.event.get('boost').invalid) {
        value.open();
        this.boostGroup.markAllAsTouched();
        return true;
      }
    });

    if (this.event.invalid) {
      this.markFormGroupTouched(this.event);
      this.submitted = true;
      this.globals.stopLoader();
      return;
    }

    this.setDescriptionValuesIfNeeded(this.event);
    if (this.eventData.eventStatus === 2 && this.event.valid) {
      this.globals.stopLoader();
      this.dialogService.termsDialog().subscribe((data) => {
        if (data) {
          // if (data.auto_approve_in_the_future) {
          //   this.event.controls.main.get('auto_approve_in_the_future').patchValue(true)
          // }
          this.globals.startLoader();
          this.setEventData();
        }
      });
    } else if (this.event.valid) {
      this.setEventData();
    } else {
      this.markFormGroupTouched(this.event);
      this.globals.stopLoader();
      return;
    }
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      // console.log('control', control);
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
    this.scrollToErrorField();
  }

  closePage() {
    if (this.navbar.prevUrl === undefined) {
      this.router.navigate(['/']);
    } else {
      this.router.navigate([this.navbar.prevUrl]);
    }
  }

  ngAfterViewInit() {
    // document.getElementById('footer').style.display = 'none';
  }

  ngOnInit() {
    this.getTimeZones();
    if (Intl.DateTimeFormat().resolvedOptions().timeZone !== 'Asia/Jerusalem') {
      this.isShowId = false;
    }
    this.isOnMobile = this.globals.isOnMobile();
    this.navbar.setIsNewUi(true);
    setTimeout(() => {this.navbar.noPadding = 'removePadding'; }, 200);
    this.navbar.hide();
    if (this.activatedRoute.params) {
      this.activatedRoute.params.subscribe(params => {
        if (this.bootstrapBoolean) {
          this.resetAllData(+this.event_id, this.event);
        }
        this.event_id = params['id'];
        this.eventService.getEventDraft(this.event_id, 'main').subscribe((eventData: { data: EventData }) => {
          // console.log('data', eventData);
          this.eventData = eventData.data;
          if (this.eventData) {
            let maxFrontId = 0;
            this.eventData.items.categories.forEach(category => category.front_id && category.front_id > maxFrontId ? maxFrontId = category.front_id : null);
            this.frontId = maxFrontId;
            this.setArtistsData(this.eventData.artists);
          }
          this.userCur = eventData.data && eventData.data.advanced.userCur === 1 ? 1 : 0;
          if (this.eventData && this.eventData.eventStatus !== 2) {

            this.dialogService.choiseDataSource().subscribe((flagToGetEventData: boolean) => {  // default behaviour
              if (flagToGetEventData) {
                this.getEventData();
              } else {
                this.formBootstrap();
              }
            });
            this.rudderstackService.page('Event management','Edit Event page');
          } else if (this.eventData && this.eventData.eventStatus === 2) {
            this.timerDate = (new Date(this.eventData.eventCreated).valueOf() - new Date().valueOf()) / 1000;
            // console.log('timerDate', this.timerDate, this.eventData.eventCreated.toString());
            this.rudderstackService.page('Event management','Create New Event page');
            this.formBootstrap();
          } else {
            this.getEventData();
          }
          this.getTicketImages(this.event_id);
        }, error => {
          this.dialogService.alertsModal('editEvent', error.code, 5000, false);
          timer(5000).subscribe(() => {this.router.navigate(['/dashboard'])});
          })
      });
    }

    this.spotifyOptions = this.spotifyControl.valueChanges.pipe(
        startWith(''),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap((val) => {
          if(typeof val === 'object') {
            return of([]);
          } else {
            return this.filterSpotifySearchInput(val || '');
          }

        })
    );
  }
  age_displayChange(e) {
    console.log(e.target.checked);
    if (e.target.checked) {
      this.advancedStep.controls['age_display'].setValue(2)
    } else {
      this.advancedStep.controls['age_display'].setValue(0)
    }
  }

  optionSelected(e: any) {
    // console.log(e);
    this.selectedSpotifyArtists[e.id] = e;
    const artist = {
      artist_id: e.id,
      name: e.name,
      image_url: e.images.length ? e.images[0].url : '',
      external_urls: e.external_urls.spotify,
      followers: e.followers.total
    }
    const item = this.artistsData.find((a: Artists) => a.artist_id === e.id);
    if(!item) {
      this.artistsData.push(artist);
    }
    // console.log(this.artistsData, item);
    this.spotifyControl.patchValue('');

  }
  getOptionText(option) {
    return option ? option.name : '';
  }

  removeSpotifyArtist(id) {
    delete this.selectedSpotifyArtists[id];
    let index = this.artistsData.findIndex(artist => artist.artist_id == id);
    this.artistsData.splice(index, 1);

    if(!Object.keys(this.selectedSpotifyArtists).length) {
      this.artistsData = [];
    }
    // console.log("removeSpotifyArtist", id, this.selectedSpotifyArtists, this.artistsData);
  }

  onStopCDTimer(event) {
    console.log('onStopCDTimer', event);
  }

  handleCDTimer(event) {
    console.log('handleCDTimer', event);
    this.editEventTimeout = true;
    this.dialogService.alertsModal('editEvent', 12, 5000, false);
  }

  ngAfterContentInit() {
    this.setQuillDirection();
  }

  setQuillDirection() {
    if (this.event) {
      let direction = '';
      let align = '';
      setTimeout(() => {
        for (const q in this.quillInstances) {
          if (this.quillInstances.hasOwnProperty(q)) {
            const element = this.quillInstances[q];
            if (this.globals.getDir() === 'rtl') {
              const rtlDescription = this.quillDescriptionValues[this.localeId];
              direction = 'rtl';
              if (!rtlDescription) {
                align = 'right';
              } else {
                const firstRtlLineOpenTag = rtlDescription.substring(rtlDescription.indexOf('<') + 1, rtlDescription.indexOf('>'));
                const query = 'ql-align-';
                if (firstRtlLineOpenTag.indexOf(query) !== -1) {
                  align = firstRtlLineOpenTag.substring(firstRtlLineOpenTag.indexOf(query) + query.length, firstRtlLineOpenTag.length - 1);
                }
              }
            } else {
              direction = 'ltr';
              align = 'left';
            }
            element.format('direction', direction);
            element.format('align', align);
          }
        }
      }, 500);
    }
  }

  resetAllData(oldEventId: number, oldEvent: any) {
    this.setDescriptionValuesIfNeeded(oldEvent).then(valueChanged => {
      if (valueChanged) {
        this.eventService.setEventDraft(oldEventId, 'main', {'main': oldEvent.value}).subscribe(
          (response: AppResponse) => {
          },
          (error: AppResponse) => this.dialogService.alertsModal((error.code) ? 'editEvent' : 'errors', (error.code) ? error.code : 4, 5000, false, error.log_id)
        );
      }
    });
    this.quillDescriptionValues = {};
    this.eventStartDate = undefined;
    this.eventEndDate = undefined;
    this.eventData = undefined;
    this.visibleStep = undefined;
    this.bootstrapBoolean = false;
    this.fireAsyncValidation = false;
    this.coverUrl = undefined;
    this.bgUrl = undefined;
    this.feedUrl = undefined;
    this.gallery_cover = undefined;
    this.gallery_bg = undefined;
    this.gallery_feed = undefined;
    this.gallery_portrait_feed = undefined;
    this.ticket_images = [];
    this.editEventTimeout = false;
    this.ticketsArray = this.formBuilder.array([]);
    this.categoriesArray = this.formBuilder.array([]);
    this.descriptionArray = this.formBuilder.array([]);
    this.formsubmited = false;
    this.currentLangOnQuill = '';
    this.setQuillDirection();
  }

  getTimeZones() {
    this.eventService.getTimeZones().subscribe((zones: any) => {
      this.timeZones = zones.data;
      this.timeZoneFiltered = zones.data;
    });
  }

  onChangeTimeZone(e, tz?) {

    const val = e.target.value;
    const id = e.target.id;
    const time_zone_val = this.advancedStep.controls.time_zone.value;

    // Event Time zone
    if (id === 'time_zone') {
      if (this.advancedStep.controls.time_zone.value !== val) {
        this.advancedStep.controls['ticket_time_zone'].setValue(val);
        setTimeout(() => {this.advancedStep.controls['time_zone'].setValue(time_zone_val); }, 300);
        this.ticketTimeZoneChangedByUser = true;
      }
    }

    // Tickets Time zone
    if (id === 'ticket_time_zone') {
      this.advancedStep.controls['time_zone'].setValue(time_zone_val);
      this.advancedStep.controls['ticket_time_zone'].setValue(val);
    }

    // console.log('onChangeTimeZone', e, val, this.advancedStep.controls.time_zone.value);
  }

  getEventData() {
    this.eventService.getEventData(this.event_id, 'main').subscribe(
      (eventData: { data: EventData }) => {
        this.eventData = eventData.data;
        // console.log('getEventData', eventData);
        // if (this.eventData && this.eventData.bank && this.eventData.bank['event_id'] == this.event_id) {
        //   this.bankData = this.eventData;
        // }
        this.setArtistsData(this.eventData.artists);
        const user_agent = navigator.userAgent.toLowerCase();
        if (this.eventData !== undefined && user_agent.indexOf('safari/') != -1 && user_agent.indexOf('chrome') == -1) {
          this.timerDate = (new Date(this.eventData.eventCreated.toString().replace(' ', 'T')).valueOf() - new Date().valueOf()) / 1000;
        } else if (this.eventData !== undefined) {
          this.timerDate = (new Date(this.eventData.eventCreated).valueOf() - new Date().valueOf()) / 1000;
        } else {
          this.timerDate = 0;
        }
        // console.log('timerDate', this.timerDate, this.eventData.eventCreated.toString());
        console.log('eventStatus', this.eventData.eventStatus)
        this.quantityVal = 0; // boost
        this.priceVal = 0; // boost
        this.formBootstrap(true);
      }
    )
  }

  formBootstrap(eventData = false) {
    this.getCurrencyCodes().then(currencyCodes => {
      this.mainStep = this.initMainStep();
      this.itemsStep = this.initItemsStep();
      this.advancedStep = this.initAdvancedStep();
      this.boostGroup = this.initBoostGroup();
      this.event = this.initEventForm();
      this.bootstrapBoolean = true;
      this.checkWhatStepsToShow();
      // setTimeout(() => {
      //   this.initDraftAutoSaveAndDateValidation();
      //   this.fireAsyncValidation = true;
      // }, 1);
      this.initDraftAutoSaveAndDateValidation();
      this.fireAsyncValidation = true;

      if (this.eventData.media) {
        this.setImages(this.eventData.media);
      }
      this.setQuillDirection();
      if (eventData) {
        this.setTicketCategoryValidatorsData()
      }

      // If ticket_time_zone set by user on new event creation page
      if (this.eventData.advanced.ticket_time_zone !== null && (this.eventData.advanced.time_zone === '' || this.eventData.advanced.time_zone === null)) {
        this.ticketTimeZoneChangedByUser = true;
      }
      if (this.eventData.nft) {
        this.setNftStatus();
      }
      if (this.eventData.donate) {
        this.setDonateStatus();
      }
      if (this.eventData.blocked_inputs) {
        this.setBlockedInputs();
      }
      this.checkHideBoost();

        // console.log('getCurrencyCodes', currencyCodes, this.advancedStep, this.itemsStep);
    });
  }
  checkHideBoost() {
    const tickets = this.itemsStep.controls['tickets'].value;
    const privacy = this.advancedStep.controls['privacy'].value; // 0 - Public event , 1 - Private event
    let hide = false;

    // console.log('this.itemsStep tickets', tickets)
    // console.log('registrationMode', this.registrationMode);
    // console.log('this.advancedStep', this.advancedStep.controls['privacy'].value);
    if(!this.registrationMode) {
      tickets.forEach(t=> {
        if(!t.reg) {
          // console.log('price', t.price, 'quantity', t.quantity, 'privacy', privacy);
          if((t.price == '' || t.quantity == '') || ((t.price == '' || t.quantity == '') && privacy === 1) || privacy === 1) {
            console.log('HIDDEN BOOST');
            hide = true;
          }
        }
      })
    }

    this.showBoost = hide ? false : true;
    // console.log('showBoost', this.showBoost, 'hide', hide)

  }

  setTicketCategoryValidatorsData() {
    this.categoriesArray.controls.forEach(category => {
      this.ticketsArray.controls.forEach(ticket => {
        if (ticket['controls']['category_id'].value.indexOf(category['controls']['id'].value) > -1 ) {
          category.get('tickets').setValue(category['controls']['tickets'].value + '+');
        }
      })
    })
  }

  initMainStep(): UntypedFormGroup {
    const main = (this.eventData) ? this.eventData.main : undefined;

    this.eventStartDate = (main && main.main_start_date) ? main.main_start_date.replace(' ', 'T') : undefined;
    this.eventEndDate = (main && main.main_end_date) ? main.main_end_date.replace(' ', 'T') : undefined;

    // NEED TO CHECK IF STILL NEEDED AND IF NOT DELETE IT. Bug from second point of gir-454 appears without this check
    if (typeof main.description === 'string') {
      main.description = JSON.parse(main.description);
    }

    const currentLangsSelected = [];

    if (main != undefined && main.description != undefined && main.description.length > 0) {
      let uniqDescriptionByLang = new Set([]);
      for (const d of main.description) {
        if(!uniqDescriptionByLang.has(d['lang'])) {
          uniqDescriptionByLang.add(d['lang']);
          currentLangsSelected.push(d['lang']);
          this.addDescription(d);
          this.quillDescriptionValues[d['lang']] = d['value'];
        }

      }
      this.setSelectedLanguages(currentLangsSelected);
      this.selectedlanguages[0].active = true;
    } else {
      this.setSelectedLanguages([this.localeId]);
      this.selectedlanguages[0].active = true;
      if (this.selectedlanguages.length > 0) {
        this.selectedlanguages.forEach((sl) => {
          this.addDescription({lang: sl.lang, value: null});
        });
      }
    }

    const mainStepFb = this.formBuilder.group({
      title: this.formBuilder.control((main && main.title) ? main.title : '', [Validators.required, Validators.maxLength(100), Validators.pattern('^[^<>]+$')]),
      address: this.formBuilder.control((main && main.address) ? main.address : ''),
      main_start_date: this.formBuilder.control((main && main.main_start_date) ? main.main_start_date.replace(' ', 'T') : null, [Validators.required, this.validateEventStartDate]),
      main_end_date: this.formBuilder.control((main && main.main_end_date) ? main.main_end_date.replace(' ', 'T') : null, [Validators.required, this.validateEventEndDate]),
      // lat: this.formBuilder.control((main && main.lat) ? main.lat : ''),
      // lon: this.formBuilder.control((main && main.lon) ? main.lon : ''),
      // time_zone: new FormControl((main && main.time_zone) ? main.time_zone : ''),
      langs: new UntypedFormControl([], {updateOn: 'change', validators: Validators.required}),
      description: this.descriptionArray,
      registration_mode: 0,
      // auto_approve_in_the_future: false
    });
    // console.log('mainStepFb main dates', mainStepFb);
    mainStepFb.patchValue({langs: currentLangsSelected.length > 0 ? currentLangsSelected : [this.localeId]});
    this.currentLangOnQuill = this.selectedlanguages[0].lang;
    return mainStepFb;
  }

  initAdvancedStep(): UntypedFormGroup {
    const advanced = (this.eventData) ? this.eventData.advanced : undefined;
    this.purchaseValidation = (advanced && !advanced.purchase_validation) ? false : true;
    this.birthdayValidation = (advanced && advanced.birthday_validation) ? true : false;

    if (advanced && advanced.currency) {
      this.defaultCurency = advanced.currency;
      if (this.currencyCodes.length === 0) {
        this.currencyCodes.push({name: advanced.currency});
      }
    }

    return this.formBuilder.group({
      url_friendly: new UntypedFormControl((advanced && advanced.url_friendly) ? advanced.url_friendly : '', {
        updateOn: 'blur',
        validators: [Validators.minLength(2), Validators.maxLength(15), Validators.pattern(urlRegex)],
        asyncValidators: this.validateFriendlyUrl.bind(this)
      }),
      age_display: new UntypedFormControl((advanced && advanced.age_display) ? advanced.age_display : 0, {
        updateOn: 'change',
        validators: Validators.required
      }),
      purchase_validation: this.formBuilder.control((advanced && (!advanced.purchase_validation || advanced.purchase_validation)) ? advanced.purchase_validation : 1, Validators.required),
      facebook_validation: new UntypedFormControl((advanced && advanced.facebook_validation) ? advanced.facebook_validation : 0, {
        updateOn: 'change',
        validators: Validators.required
      }),
      instagram_validation: new UntypedFormControl((advanced && advanced.instagram_validation) ? advanced.instagram_validation : 0, {
        updateOn: 'change',
        validators: Validators.required
      }),
      custom_instagram_url: new UntypedFormControl((advanced && advanced.custom_instagram_url) ? advanced.custom_instagram_url : 0, {
        updateOn: 'change',
        validators: Validators.required
      }),
      accept_crypto_payment: new UntypedFormControl((advanced && advanced.accept_crypto_payment) ? advanced.accept_crypto_payment : 0, {
        updateOn: 'change',
        validators: Validators.required
      }),
      email_validation: new UntypedFormControl((advanced && (advanced.facebook_validation || advanced.instagram_validation)) ? 0 : 1, {
        updateOn: 'change',
        validators: Validators.required
      }),
      birthday_validation: this.formBuilder.control((advanced && advanced.birthday_validation) ? advanced.birthday_validation : 0, Validators.required),
      privacy: new UntypedFormControl((advanced && advanced.privacy) ? advanced.privacy : 0, {
        updateOn: 'change',
        validators: Validators.required
      }),
      birth_date: this.formBuilder.control((advanced && advanced.birth_date) ? advanced.birth_date : ''),
      min_age: this.formBuilder.control({value: (advanced && advanced.min_age) ? advanced.min_age : '', disabled: false}, [Validators.min(0), Validators.max(120)]),
        currency: new UntypedFormControl(this.defaultCurency, { updateOn: 'change', validators: Validators.required}),
      userCur: this.userCur ? 1 : 0,
      purchase_type: new UntypedFormControl((advanced && advanced.purchase_type) ? advanced.purchase_type : 2, {
        updateOn: 'change',
        validators: Validators.required
      }),
      id_number: new UntypedFormControl((advanced && advanced.id_number) ? advanced.id_number : 0),
      gender: new UntypedFormControl((this.eventData && this.eventData.eventStatus === 2) ? 1 : (advanced && advanced.gender) ? advanced.gender : 0),
      getin_subscription: new UntypedFormControl((this.eventData && this.eventData.eventStatus === 2) ? 1 : (advanced && advanced.getin_subscription) ? advanced.getin_subscription : 0),
      producer_subscription: new UntypedFormControl((this.eventData && this.eventData.eventStatus === 2) ? 1 : (advanced && advanced.producer_subscription) ? advanced.producer_subscription : 0),
      time_zone: new UntypedFormControl((advanced && advanced.time_zone) ? advanced.time_zone : ''),
      ticket_time_zone: new UntypedFormControl((advanced && advanced.ticket_time_zone) ? advanced.ticket_time_zone : ''),
      lat: new UntypedFormControl((advanced && advanced.lat) ? advanced.lat : ''),
      lon: new UntypedFormControl((advanced && advanced.lon) ? advanced.lon : ''),
      inputIsNft: new UntypedFormControl((advanced && advanced.inputIsNft) ? advanced.inputIsNft : 0),
      checkout_timeout_counter: this.formBuilder.control((advanced && advanced.checkout_timeout_counter) ? advanced.checkout_timeout_counter : 15, Validators.required),
    });
  }

  initItemsStep(): UntypedFormGroup {
    const advanced = (this.eventData) ? this.eventData.advanced : undefined;
    if (advanced && advanced.currency) {
        this.defaultCurency = advanced.currency;
        if (this.currencyCodes.length === 0) {
            this.currencyCodes.push({name: advanced.currency});
        }
    }
    const items = (this.eventData) ? this.eventData.items : undefined;
    if (items && items.tickets.length) {
      items.tickets.forEach((ticket) => this.addItem('tickets', ticket));
    } else {
      this.addItem('tickets');
    }

    if (items && items.categories && items.categories.length) {
      items.categories.forEach((category) => this.addItem('categories', category));
    }
    // console.log('this.eventData', this.eventData);
    this.setTicketImagesFormValues();
    this.categoriesArray.controls.sort((a, b) => {
      return a.value.sort - b.value.sort;
    });
    this.ticketsArray.controls.sort((a, b) => {
      return a.value.sort - b.value.sort;
    });
    // console.log('sort array', this.categoriesArray.controls, this.ticketsArray.controls);

    return this.formBuilder.group({
      tickets: this.ticketsArray,
      categories: this.categoriesArray,
    }, Validators.required);

  }


  moreOption() {
    console.log('moreOption');
  }


  initBoostGroup() {
    const boost = (this.eventData) ? this.eventData.boost : undefined;
    // console.log('boost.status', boost.status);
    return this.formBuilder.group({
      marketing_terms: new UntypedFormControl((boost && (boost.status !== 0))?1:0, [(boost && boost.status) ? Validators.required : Validators.nullValidator]),
      status: new UntypedFormControl((boost && boost.status) ? boost.status : 0),
    });
  }

  initEventForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      main: this.mainStep,
      advanced: this.advancedStep,
      items: this.itemsStep,
      boost: this.boostGroup
    }, {updateOn: 'blur'});
  }

  onChangeCurrency(event) {
    const val = event.target.value;
    if (this.advancedStep.controls.time_zone.value !== val) {
      this.ticketsArray.controls.forEach(ticket => {
        ticket['controls']['currency'].setValue(val);
        // console.log('controls ticket', ticket);
      });
    }
  }

  addDescription(desc = undefined) {
    this.descriptionArray.push(this.createDesc(desc));
    this.setQuillDirection();
  }

  createDesc(desc) {
    return this.formBuilder.group({
      lang: (desc && desc.lang) ? desc.lang : undefined,
      value: (desc && desc.value) ? desc.value : undefined
    }, { updateOn: 'change' });
  }

  removeDescription(index) {
    this.descriptionArray.removeAt(index);
  }

  addItem(type: string, oldItem: any = undefined) {
    this[`${type}Array`].push(type === 'categories' ? this.createCategory(oldItem) : this.createItem(oldItem));
    if (this.bootstrapBoolean) {
      this.checkWhatStepsToShow();
    }
  }

  removeItem(type: string, index: number) {
    if (type === 'categories') {
      const category = this.categoriesArray.controls[index];
      this.ticketsArray.controls
        .filter(ticket => (ticket['controls'].category_id.value && ticket['controls'].category_id.value.indexOf(category['controls'].id.value) > -1) || (ticket['controls'].front_category_id.value && ticket['controls'].front_category_id.value.indexOf(category['controls'].front_id.value) > -1))
          .forEach(ticket => this.deleteCategoryFromTicket(category, ticket['controls']));
    }
    if (this[`${type}Array`].controls[index].controls.id.value) {
      if (type === 'categories') {
        this.removeCategoryDB(this.event_id, this[`${type}Array`].controls[index]['controls'].id.value);
      } else {
        this.removeItemDB(this[`${type}Array`].controls[index].controls.id.value, type);
      }
    }
    this[`${type}Array`].removeAt(index);
    if (type !== 'categories' && index === 0 && this[`${type}Array`].length === 0) {
      this.addItem(type);
    }
    this.checkWhatStepsToShow();
  }

  createCategory(oldItem: Category = undefined): UntypedFormGroup {
    if (!oldItem || !oldItem.id) {
      this.frontId += 1;
    }
    return this.formBuilder.group({
      tickets: this.formBuilder.control((oldItem && oldItem.tickets) ? oldItem.tickets : '', [Validators.required]),
      id: (oldItem && oldItem.id) ? oldItem.id : '',
      front_id: (oldItem && oldItem.front_id) ? oldItem.front_id : (this.frontId),
      sort: (oldItem && oldItem.sort) ? oldItem.sort : '0',
      name: this.formBuilder.control((oldItem && oldItem.name) ? oldItem.name : '', [Validators.maxLength(50), Validators.required, Validators.pattern(emojiRegex)]),
      comment: this.formBuilder.control((oldItem && oldItem.comment) ? oldItem.comment : '', [Validators.maxLength(500)])
    })
  }

  createItem(oldItem: Item = undefined): UntypedFormGroup {
    return this.formBuilder.group({
      id: (oldItem && oldItem.id) ? oldItem.id : undefined,
      name: this.formBuilder.control((oldItem && oldItem.name) ? oldItem.name : '', [Validators.maxLength(50), Validators.required, Validators.pattern(emojiRegex)]),
      comment: this.formBuilder.control((oldItem && oldItem.comment) ? oldItem.comment : '', [Validators.maxLength(500)]),
      start_date: this.formBuilder.control((oldItem && oldItem.start_date) ? oldItem.start_date.replace(' ', 'T') : null, [Validators.required, this.validateItemsStartDate]),
      end_date: this.formBuilder.control((oldItem && oldItem.end_date) ? oldItem.end_date.replace(' ', 'T') : null, [Validators.required, this.validateItemsEndDate, this.itemEndDateValidator.bind(this)]),
      quantity: this.formBuilder.control((oldItem && oldItem.quantity) ? oldItem.quantity : '', [Validators.required, Validators.min(1)]),
      price: (!oldItem || !oldItem.id) ? this.formBuilder.control((oldItem && oldItem.price) ? oldItem.price : '', [Validators.min(1), Validators.max(500000), Validators.pattern("^[0-9]*$")]) : oldItem.price,
        currency: (!oldItem || !oldItem.id) ? this.formBuilder.control((oldItem && oldItem.currency) ? oldItem.currency : this.defaultCurency) : oldItem.currency,
      limits: this.formBuilder.control((oldItem && oldItem.limits) ? oldItem.limits : 3, [Validators.min(1), Validators.max(20), Validators.required]),
      reg: (!oldItem || !oldItem.id) ? new UntypedFormControl((oldItem && oldItem.reg) ? oldItem.reg : 0, {
        updateOn: 'change',
        validators: Validators.required
      }) : oldItem.reg,
      status: new UntypedFormControl((oldItem && oldItem.status) ? oldItem.status : 1, {updateOn: 'change', validators: Validators.required}),
      category_id: (oldItem && oldItem.category_id) ? oldItem.category_id.toString() : '',
      front_category_id: (oldItem && oldItem.front_category_id) ? oldItem.front_category_id : '',
      nft_image_id: (oldItem && oldItem.nft_image_id) ? oldItem.nft_image_id : '',
      sort: (oldItem && oldItem.sort) ? oldItem.sort : '0',
      donation_type: this.formBuilder.control((oldItem && oldItem.donation_type) ? oldItem.donation_type : 0),
      donation_min: this.formBuilder.control((oldItem && oldItem.donation_min) ? oldItem.donation_min : 1, [Validators.required, Validators.min(1)]),
      donation_max: this.formBuilder.control((oldItem && oldItem.donation_max) ? oldItem.donation_max : 1, [Validators.required, Validators.min(1), Validators.max(oldItem && oldItem.donation_max && !this.eventData.donate.pending ? +oldItem.donation_max: 1000)]),
      donation_ticket_approved: (oldItem && oldItem.donation_ticket_approved) ? oldItem.donation_ticket_approved : 0,
      waiting_list: (oldItem && oldItem.waiting_list) ? oldItem.waiting_list : 0,
    })
  }

  initDraftAutoSaveAndDateValidation() {
    this.draftSubscription = this.event.valueChanges.pipe(debounceTime(3000), distinctUntilChanged((a, b) => this.compareStringAndValidateSize(a, b)), )
      .subscribe((draft: UntypedFormGroup) => {
      if (!this.formsubmited) {
        this.preventSubmittingWhileDraftSaving = true;
        this.eventService.setEventDraft(this.event_id, 'main', { main: {...this.event.value, artists: this.artistsData}  }).subscribe(
          (response: AppResponse) => {
            if (response.data) {
              const draftData = JSON.parse(response.data);
              // console.log("draftData", draftData.advanced);
              this.setArtistsData(draftData.artists);
              const ticket_time_zone_draft = draftData.advanced.ticket_time_zone;
              for (const q in this.quillInstances) {
                // console.log('hasFOCUS',q, this.quillInstances[q].hasFocus())
                if (this.quillInstances.hasOwnProperty(q)) {
                  if (!this.quillInstances[q].hasFocus()) {
                    this.changeDescriptionFromDraft(draftData);
                  }
                }
              }

              // console.log('initDraftAutoSaveAndDateValidation', draftData.advanced.time_zone, this.eventData.advanced.time_zone, this.advancedStep.controls['time_zone'].value, this.event.value);
              if (draftData && (draftData.advanced.time_zone !== this.eventData.advanced.time_zone)) {
                this.eventData.advanced.time_zone = draftData.advanced.time_zone;
                    this.advancedStep.controls['time_zone'].setValue(draftData.advanced.time_zone);
              }
              // If User decide to manually change the Timezone of ticket
              if (draftData && (ticket_time_zone_draft == null || ticket_time_zone_draft === '') && draftData.advanced.time_zone != null) {
                this.advancedStep.controls['time_zone'].setValue(draftData.advanced.time_zone);
                // this.advancedStep.controls['ticket_time_zone'].setValue(ticket_time_zone_draft);
              }
              if (draftData && (ticket_time_zone_draft !== null) && (ticket_time_zone_draft.length > 1) &&
                ticket_time_zone_draft !== draftData.advanced.time_zone)  {
                this.ticketTimeZoneChangedByUser = true;
              }
              if (draftData && (ticket_time_zone_draft === '' || ticket_time_zone_draft === null) && draftData.advanced.time_zone) {
                this.ticketTimeZoneChangedByUser = false;
              }

            }
            this.preventSubmittingWhileDraftSaving = false;
          },
          (error: AppResponse) => {
            this.preventSubmittingWhileDraftSaving = false;
            this.dialogService.alertsModal((error.code) ? 'editEvent' : 'errors', (error.code) ? error.code : 4, 5000, false, error.log_id)
          }
        );
      }
    });
    // console.log('initDraftAutoSaveAndDateValidation', this.eventData);
    if (this.eventData && this.eventData.main && this.eventData.main.address) {
      this.geocoder(this.eventData.main.address).subscribe(latlng => {
        if (latlng) {
          this.advancedStep.controls['lat'].setValue(latlng.lat());
          this.advancedStep.controls['lon'].setValue(latlng.lng());
        }
      });
    }

    this.endDateSubscribtion = this.mainStep.controls.main_end_date.valueChanges.subscribe((val: any) => {
      this.eventEndDate = val;
      this.itemsStep.enable();
    });
  }

  /*
  * replace description values from draft
  * */
  changeDescriptionFromDraft(draftData) {
    // console.log('changeDescriptionFromDraft',draftData);
    const description = JSON.parse(draftData.main.description);

    for (const q in this.quillDescriptionValues) {
      const index = this.event.get('main.description').value.findIndex(ob => ob.lang === q);
      const responseIndex = description.findIndex(ob => ob.lang === q);
      const descFormArr = this.event.controls['main'].get('description') as UntypedFormArray; // Description values in all lang

      // console.log('index', index, 'responseIndex', responseIndex, description, descFormArr);

      if ((index !== -1)
        && descFormArr.at(index) !== undefined
        && descFormArr.at(index).get('value').value !== description[responseIndex].value) { // If returned draft description not equal form descr
        this.quillInstances[q].blur();
        descFormArr.at(index).patchValue({lang: q, value: description[responseIndex].value})
        this.quillDescriptionValues[q] = description[responseIndex].value;
      }
    }
  }

  geocoder(address): Observable<any> {
    const geocoder = new google.maps.Geocoder();
    return Observable.create(observer => {
      geocoder.geocode({
        'address': address
      }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          observer.next(results[0].geometry.location);
          observer.complete();
        } else {
          observer.error();
        }
      });
    });
  }

  compareStringAndValidateSize(oldData, newData) {
    const newJson = JSON.stringify(newData);
    if ((Buffer.from(newJson).length) / 1024 / 1024 > 16) {
      this.dialogService.alertsModal('editEvent', 29, 5000, false);
      return true;
    } else {
      const oldJson = JSON.stringify(oldData);
      return oldJson === newJson;
    }
  }

  validateFriendlyUrl(control: AbstractControl) {
    if (!control.value || control.invalid || !this.fireAsyncValidation) {
      return Promise.resolve(null)
    }
    return this.eventService.validateFriendlyUrl({url_friendly: control.value, event_id: this.event_id}).pipe(map(
      (res: AppResponse) => res.data.urlTaken ? {urlTaken: control.value} : null
    ), catchError((error: AppResponse) => {
      if (error.httpStatus === 403) {
        this.dialogService.alertsModal('errors', 23, 5000, false);
      } else {
        this.dialogService.alertsModal((error.code) ? 'editEvent' : 'errors', (error.code) ? error.code : 4.1, 5000, false, error.log_id);
      }
      return Promise.resolve({urlTaken: control.value})
    }), );
  }

  validateEventEndDate(control: AbstractControl) {
    let start_date;
    if (control.parent) {
      start_date = control.parent.value.main_start_date;
    }
    if (start_date && start_date >= control.value) {
      control.parent.controls['main_start_date'].setErrors(null);
      return {invalidEndDate: true};
    }
    return null;
  }

  validateEventStartDate(control: AbstractControl) {
    let end_date;
    if (control.parent) {
      end_date = control.parent.value.main_end_date;
    }
    if (end_date && end_date <= control.value) {
      control.parent.controls['main_end_date'].setErrors(null);
      return {invalidStartDate: true};
    }
    return null;
  }

  validateItemsStartDate(control: AbstractControl) {
    let end_date;
    if (control.parent) {
      end_date = control.parent.value.end_date;
    }
    if (end_date && end_date <= control.value) {
      control.parent.controls['end_date'].setErrors(null);
      return {invalidStartDate: true};
    }
    return null;
  }

  validateItemsEndDate(control: AbstractControl) {
    let start_date;
    if (control.parent) {
      start_date = control.parent.value.start_date;
    }
    if (start_date && start_date >= control.value) {
      control.parent.controls['start_date'].setErrors(null);
      return {invalidEndDate: true};
    }
    return null;
  }

  itemEndDateValidator(control: AbstractControl) {
    if (new Date(control.value).getTime() > new Date(this.eventEndDate).getTime()) {
      return {endDate: true};
    } else {
      return null;
    }
  }

  validateInBytes(control: AbstractControl) {
    if (control.value && (Buffer.from(control.value).length) / 1024 / 1024 > 10) {
      return {fieldToBig: true};
    }
    return null;
  }

  validateDescLength(control: AbstractControl) {
    let lengthError = null;
    if (control.value) {
       control.value.forEach(key => {
         // Get text length without tags
         const tmp = document.createElement('DIV');
         tmp.innerHTML = key.value;
         // console.log("validateDescLength", key.value.length, tmp.textContent.length);
         if (tmp.textContent.length > 15000) {
              lengthError =  {maxlength: {requiredLength: 15000}};
         }
      });
    }
    return lengthError;
  }

  quillCreated(quill, selectedLang) {
    this.quillInstances[selectedLang] = quill;
  }

  updateQuillsValues(quill, lang) {
    let quillhtml = quill.html;
    let sanitized = sanitizeHtml(quillhtml, sanitizeOptions)
    // console.log('not sanitized', quillhtml, quillhtml.length);
    // console.log('sanitized', sanitized, sanitized.length);
    this.quillDescriptionValues[lang] = sanitized;
  }

  setCurrentLangOnQuill(lang) {
    if (lang !== undefined && lang) {
      this.currentLangOnQuill = lang;
    }
  }

  insertIframe() {
    this.dialogService.soundCloudDialog().subscribe((result) => {
      if (result) {
        const range = this.quillInstances[this.currentLangOnQuill].getSelection();
        if (range) {
          this.quillInstances[this.currentLangOnQuill].pasteHTML(range.index, result);
        }
      }
    })
  }

  purchaseCheckboxChange(control) {
    const controlToChange = this.advancedStep.controls[control];
    controlToChange.setValue(controlToChange.value == 0 ? 1 : 0)
    // console.log('purchaseCheckboxChange', controlToChange, this.advancedStep.controls[control].value)
  }
  boostCheckboxChange(control, e) {
    // console.log('EVENT', e.target.checked);
    const value = e.target.checked

    const controlToChange = this.boostGroup.controls[control];
    controlToChange.setValue(value ? 1 : 0);
    this.event.controls['boost'].get(control).setValue(value ? 1 : 0)

    if(control ==='status' && value) {
      this.boostGroup.controls['marketing_terms'].setValidators([Validators.min(1)]);
      this.boostGroup.controls['marketing_terms'].updateValueAndValidity();
      this.event.controls['boost'].get('marketing_terms').setValidators([Validators.min(1)]);
      this.event.controls['boost'].get('marketing_terms').updateValueAndValidity();
      return
    }
    if(control === 'status' && !value) {
      this.boostGroup.controls['marketing_terms'].clearValidators();
      this.boostGroup.controls['marketing_terms'].updateValueAndValidity();
      this.event.controls['boost'].get('marketing_terms').clearValidators();
      this.event.controls['boost'].get('marketing_terms').updateValueAndValidity();
    }
  }

  disabledBoost() {
    if ( (this.eventData.eventStatus != 2 && this.eventData.boost.status === 0) ||
      (this.eventData.eventStatus === 2 && (this.eventData.boost.status === 0 || this.eventData.boost.status === 1)) ) {
      return false
    } else {
      return true
    }
  }


  categoryTicketChange(e, index, category) {
    const ticketControls = this.ticketsArray.controls[index]['controls'];
    if (e.target.checked) {
      category.get('tickets').setValue(category['controls']['tickets'].value + '+');
      if (category.controls.id.value) {
        ticketControls.category_id.setValue(ticketControls.category_id.value ? ticketControls.category_id.value + ',' + category.controls.id.value.toString() : category.controls.id.value.toString());
      } else {
        ticketControls.front_category_id.setValue(ticketControls.front_category_id.value ? ticketControls.front_category_id.value + ',' + category.controls.front_id.value.toString() : category.controls.front_id.value.toString());
      }
    } else {
      category.get('tickets').setValue(category['controls']['tickets'].value.slice(0, -1));
      this.deleteCategoryFromTicket(category, ticketControls);
    }
  }

  deleteCategoryFromTicket(category, ticketControls) {
    if (category.controls.id.value) {
      const i = ticketControls.category_id.value.indexOf(category.controls.id.value.toString());
      ticketControls.category_id.setValue(ticketControls.category_id.value.replace(category.controls.id.value.toString(), ''));
      if (i > 0) {
        ticketControls.category_id.setValue(ticketControls.category_id.value.slice(0, i - 1) + ticketControls.category_id.value.slice(i, ticketControls.category_id.value.length))
      }
    } else {
      const position = ticketControls.front_category_id.value.indexOf(category.controls.front_id.value.toString());
      ticketControls.front_category_id.setValue(ticketControls.front_category_id.value.replace(category.controls.front_id.value.toString(), ''));
      if (position > 0) {
        ticketControls.front_category_id.setValue(ticketControls.front_category_id.value.slice(0, position - 1) + ticketControls.front_category_id.value.slice(position, ticketControls.front_category_id.value.length))
      }
    }
  }

  onChangeArrayIndex(event, oldIndex, isCat) {
    const val = event.target.value-1;
      let newArray: AbstractControl[] =  [];
      let tArray: AbstractControl[] = [];
      isCat ? tArray = this.categoriesArray.controls : tArray = this.ticketsArray.controls;
      tArray.forEach((item, i) => {
        if (oldIndex == i) {
          item.get('sort').setValue(val.toString());
          // item.get('comment').setValue(val.toString());
          newArray[val] = item;
        } else if (val == i) {
          item.get('sort').setValue(oldIndex.toString());
          // item.get('comment').setValue(oldIndex.toString());
          newArray[oldIndex] = item;
        } else {
          item.get('sort').setValue(i.toString());
          // item.get('comment').setValue(i.toString());
          newArray[i] = item;
        }
      });
      if (isCat) {
        this.categoriesArray.controls = newArray;
        this.categoriesArray.updateValueAndValidity();
      } else {
        this.ticketsArray.controls = newArray;
        this.ticketsArray.updateValueAndValidity();
      }
    // console.log('onChangeArrayIndex', this.ticketsArray, this.categoriesArray);
  }

  noMoreTimeToEdit() {
    this.editEventTimeout = true;
    this.dialogService.alertsModal('editEvent', 12, 5000, false);
  }

  setSocialValidation(control) {

    if (control === 'email_validation') {
      this.advancedStep.controls['email_validation'].setValue(1);
      this.advancedStep.controls['instagram_validation'].setValue(0);
      this.advancedStep.controls['facebook_validation'].setValue(0);
    } else {
      const controlToChange = this.advancedStep.controls[control];
      controlToChange.setValue(controlToChange.value === 0 ? 1 : 0)
    }

    if (this.advancedStep.controls['instagram_validation'].value === 0 && this.advancedStep.controls['facebook_validation'].value === 0) {
      this.advancedStep.controls['email_validation'].setValue(1);
    } else {
      this.advancedStep.controls['email_validation'].setValue(0);
    }

  }

  setPurchaseValidation(checked: boolean) {
    if (checked) {
      this.advancedStep.controls['purchase_validation'].setValue(1);
    } else {
      this.advancedStep.controls['purchase_validation'].setValue(0);
      this.advancedStep.controls['email_validation'].setValue(1);
      this.advancedStep.controls['instagram_validation'].setValue(0);
      this.advancedStep.controls['facebook_validation'].setValue(0);
    }
    this.purchaseValidation = checked;
  }

  setBirthDay(checked: boolean) {
    if (checked) {
      this.advancedStep.controls['birthday_validation'].setValue(1);
    } else {
      this.advancedStep.controls['birthday_validation'].setValue(0);
    }
    this.birthdayValidation = checked;
  }

  setEventData() {
    this.formsubmited = true;

    if (this.registrationMode && this.event.value.advanced.currency === '') {
      this.event.value.advanced.currency = 'ILS';
    }

    this.event.value.main.registration_mode = this.registrationMode ? 1 : 0;
    this.eventService.setEventData(this.event_id, 'main', {main: {...this.event.value, artists: this.artistsData}}).subscribe(
      (result: AppResponse) => {
        let show_bank_modal = false;
        if (result && result.data && result.data.hasOwnProperty('active_bank') && result.data.hasOwnProperty('showModalBank')) {
          if (result.data.showModalBank && !result.data.active_bank) {
            show_bank_modal = true;
          }
        }
        let auto_approve = false;
        if (result && result.data && result.data.hasOwnProperty('auto_approve')) {
          auto_approve = result.data.auto_approve;
        }
        this.router.navigate(['/pending-event', this.event_id, this.eventData.eventStatus, show_bank_modal, auto_approve]);
        this.globals.stopLoader();
      },
      (error: AppResponse) => {
        this.globals.stopLoader();
        if(error.code === 37 && error.data) {
          this.showErrorOnTicketQuantity(error.data.ticket_id, error.data.purchase);
         // this.dialogService.alertsModal((error.code) ? 'editEvent' : 'errors', (error.code) ? error.code : 4, 5000, false, error.log_id);
        } else {
          this.dialogService.alertsModal((error.code) ? 'editEvent' : 'errors', (error.code) ? error.code : 4, 5000, false, error.log_id);
        }
      }
    )
  }

  ticketTypeChange(event) {
    const selectedTicket = event.inst.getVal(); // Call the getVal method
    // '1' - registration ticket , '0' - regular
    // '2' - donation
    this.checkWhatStepsToShow(+selectedTicket);
  }
  checkWhatStepsToShow(registration_mode = null) {
    // '1' - registration ticket , '0' - regular
    let registerModeTempValue = true;
    let activeRegistrationTickets = 0;
    let activeRegularTickets = 0;
    let notActiveRegularTickets = 0;

    // ACTIVE TICKETS
    for (let x = 0; x < this.ticketsArray.controls.length; x++) {
      if ((this.ticketsArray.controls[x].value.reg == '0' ||
        this.ticketsArray.controls[x].value.reg == '2') && // calc donation tickets also as regular
        this.ticketsArray.controls[x].value.status === 1) {
        activeRegularTickets++;
      }
      if (this.ticketsArray.controls[x].value.reg == '1' && this.ticketsArray.controls[x].value.status === 1) {
        activeRegistrationTickets++
      }
    }
    // TICKETS FROM EVENTs DRAFT
    for (let x = 0; x < this.ticketsArray.controls.length; x++) {
     // console.log(this.ticketsArray.controls[x].value.reg);
      if (this.ticketsArray.controls[x].value.reg == '0') {
        notActiveRegularTickets++;
      }
    }
    // console.log("activeRegistrationTickets", activeRegularTickets, activeRegistrationTickets, notActiveRegularTickets);

    if (activeRegularTickets > 0) { registerModeTempValue = false }
    if (registration_mode === 0) { registerModeTempValue = false } // set mod from passed value
    if (registration_mode === 1) { registerModeTempValue = true } // set mod from passed value
    if (notActiveRegularTickets > 0 || activeRegularTickets > 0) {
      registerModeTempValue = false
    }
    this.needCurrency = !registerModeTempValue;
    this.registrationMode = (registerModeTempValue) ? 1 : 0;
    if (this.registrationMode === 0 && !this.event.get('boost')) {
      this.event.addControl('boost', this.initBoostGroup())
      this.boostGroup = this.initBoostGroup();
    }

    this.checkHideBoost();

  }

  changedToDonateType(e, x){
    let ticket = this.ticketsArray.controls.at(x);
    if (this.ticketsArray.controls[x].value.reg == 2) { // donation ticket
      ticket.get('price').clearValidators(); ticket.get('price').updateValueAndValidity();
      ticket.get('donation_min').setValidators([Validators.min(1), Validators.max(999), Validators.pattern("^[0-9]*$")]); ticket.get('donation_min').updateValueAndValidity();
      ticket.get('donation_max').setValidators([Validators.min(1), Validators.max(1000), Validators.pattern("^[0-9]*$")]); ticket.get('donation_max').updateValueAndValidity();
    } else if(this.ticketsArray.controls[x].value.reg == 0) { // regular ticket
      ticket.get('price').setValidators([Validators.min(1), Validators.max(1000), Validators.pattern("^[0-9]*$")]); ticket.get('price').updateValueAndValidity();
      ticket.get('donation_min').clearValidators(); ticket.get('donation_min').updateValueAndValidity();
      ticket.get('donation_max').clearValidators(); ticket.get('donation_max').updateValueAndValidity();
    } else {  // free ticket
      ticket.get('price').clearValidators(); ticket.get('price').updateValueAndValidity();
      ticket.get('donation_min').clearValidators(); ticket.get('donation_min').updateValueAndValidity();
      ticket.get('donation_max').clearValidators(); ticket.get('donation_max').updateValueAndValidity();
    }
    // console.log('changedToDonateType', this.ticketsArray.controls[x].value);
  }

  changeItemStatus(checked: boolean, type: string, index: number) { //Checked and formControlName should not be used together
    // status 1 - Active
    // status 2 - Not Active
    // status 0 - Deleted (not returned from db)
    const status = (checked) ? 1 : 2;
    // console.log(checked, type, index, 'status', status)
    // this[`${type}Array`].controls[index].patchValue(status);
    this[`${type}Array`].controls[index].get('status').patchValue(status);
    this.checkWhatStepsToShow();
  }
  changeWaitingList(checked: boolean, type: string, index: number) {
    console.log('checked', checked, type, index);
    const status = (checked) ? 1 : 0;
    this[`${type}Array`].controls[index].get('waiting_list').patchValue(status);
    this.checkWhatStepsToShow();
  }

  // changeDonateTicketStatus(checked: boolean, type: string, index: number) {
  //   const status = (checked) ? 1 : 0;
  //   this[`${type}Array`].controls[index].get('open_ticket_price').patchValue(status);
  //   this.checkWhatStepsToShow();
  // }

  removeItemPriceForRegItem(type: string, index: number) {
    if (this[`${type}Array`].controls[index].value.reg == 1) {
      if (this[`${type}Array`].controls[index].value.price != 0) {
        (<UntypedFormArray>(<UntypedFormGroup>this.event.controls.items).controls[type]).at(index).get('price').patchValue(0);
      }
      (<UntypedFormArray>(<UntypedFormGroup>this.event.controls.items).controls[type]).at(index).get('price').clearValidators();
      (<UntypedFormArray>(<UntypedFormGroup>this.event.controls.items).controls[type]).at(index).get('currency').clearValidators();
    } else {
      (<UntypedFormArray>(<UntypedFormGroup>this.event.controls.items).controls[type]).at(index).get('price').setValidators([Validators.min(1), Validators.pattern("^[0-9]*$"), Validators.required]);
      (<UntypedFormArray>(<UntypedFormGroup>this.event.controls.items).controls[type]).at(index).get('price').patchValue('');

      // (<FormArray>(<FormGroup>this.event.controls.items).controls[type]).at(index).get('currency').setValidators([Validators.required]);
    }
    (<UntypedFormArray>(<UntypedFormGroup>this.event.controls.items).controls[type]).at(index).get('currency').updateValueAndValidity();
    (<UntypedFormArray>(<UntypedFormGroup>this.event.controls.items).controls[type]).at(index).get('price').updateValueAndValidity();
  }


  setImages(media: image[]) {
    this.calculateImageSize();
    for (let x = 0; x < media.length; x++) {
      if (media[x].media_type === 1) {
        this.gallery_cover = media[x];
        this.gallery_cover.file = this.coverImgSize + media[x].file;
      } else if (media[x].media_type === 2) {
        this.gallery_bg = media[x];
        this.gallery_bg.file = this.bgImgSize + media[x].file;
      } else if (media[x].media_type === 4) {
        this.gallery_feed = media[x];
        this.gallery_portrait_feed = 'portrait' + media[x].file;
        this.gallery_feed.file = 'mobile' + media[x].file;
      }
    }
  }

  calculateImageSize(): void {
    if (1920 < window.innerWidth) {
      this.coverImgSize = 'desktop';
    } else if (1920 >= window.innerWidth && window.innerWidth > 820) {
      this.coverImgSize = 'tablet';
    } else if (window.innerWidth <= 820) {
      this.coverImgSize = 'mobile';
    }
    if (2560 < window.innerWidth) {
      this.bgImgSize = 'desktop';
    } else if (2560 >= window.innerWidth && window.innerWidth > 1444) {
      this.bgImgSize = 'tablet';
    } else if (window.innerWidth <= 1444) {
      this.bgImgSize = 'mobile';
    }
  }

  // uploadImgix(event) {
  //   console.log(event.target.files[0]);
  //   let files: FileList = event.target.files;
  //   let file : File = files[0];
  //   if (file) {
  //     this.eventService.uploadImgixImage(file, file.name).subscribe(res => {
  //       console.log(res);
  //     },error => {
  //       console.log(error)
  //     })
  //   }
  // }
  setCropper(input, type: string) {
    const file = input.files[0];
    this.imageFile = file;
    if (file) {
      // console.log('image size', this.imageFile, file.size, file.size / 1000000);
      if (file.size /  1048576 > 5) {
        return this.dialogService.alertsModal('fileUploads', 3, 5000, false);
      }
      if (!imageRegex.test(file.type)) {
        return this.dialogService.alertsModal('fileUploads', 2.1, 5000, false);
      }

      this[`${type}CropperComponent`] = false;
      setTimeout(() => {
        this[`${type}Url`] = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
        this[`${type}CropperComponent`] = true;
        this[`${type}FileInput`].nativeElement.value = '';
      }, 1);
    }

  }

  saveCroppedImage(cropperComponent, image_type) {
    if (cropperComponent) {
      this.globals.startLoader();
      cropperComponent.cropper.getCroppedCanvas().toBlob((blob) => {
        const formData = new FormData();
        // console.log('cropped image blob', blob);
        formData.append('croppedImage', blob);
        this.eventService.saveImages(formData, this.event_id, image_type).subscribe(
          (response: any) => {
            if (response.success) {
              let name;
              if (image_type === 1) {
                if (response.update_media) {
                  this.gallery_cover = response.update_media;
                  this.gallery_cover.file = this.coverImgSize + response.update_media.file;
                  name = 'cover';
                }
              }
              if (image_type === 2) {
                if (response.update_media) {
                  this.gallery_bg = response.update_media;
                  this.gallery_bg.file = this.bgImgSize + response.update_media.file;
                  name = 'bg';
                }
              }
              if (image_type === 4) {
                if (response.update_media) {
                  this.gallery_feed = response.update_media;
                  this.gallery_portrait_feed = 'portrait' + response.update_media.file;
                  this.gallery_feed.file = 'mobile' + response.update_media.file;
                  name = 'feed';
                }
              }
              if (response.updated_feed_media) {
                this.gallery_feed = response.updated_feed_media;
                this.gallery_portrait_feed = 'portrait' + response.updated_feed_media.file;
                this.gallery_feed.file = 'mobile' + response.updated_feed_media.file;
              }
              this[`${name}CropperComponent`] = false;
              this[`${name}Url`] = undefined;
              this.dialogService.alertsModal('success', `${name}Image`, 5000, true);
            } else if (response.errors) {
              this.dialogService.alertsModal('fileUploads', response.errors, 5000, false);
            }
            this.globals.stopLoader();
          },
          (error: any) => {
            this.dialogService.alertsModal('fileUploads', 8, 5000, false);
            this.globals.stopLoader();
          }
        );
      }, 'image/jpeg',
        0.9
      );
    }
  }

  removeImage(item_id: number, type: string) {
    this.globals.startLoader();
    const data = {item_id, event_id: this.event_id};
    this.eventService.removeImage(data).subscribe(
      (response: any) => {
        this.globals.stopLoader();
        if (response.file) {
          this[`gallery_${type}`] = undefined;
          if (type === 'feed') {
            this.gallery_portrait_feed = undefined;
          }
          this.dialogService.alertsModal('success', 'imageDeleted', 5000, true);
        } else {
          this.dialogService.alertsModal('fileUploads', 8, 5000, false);
        }
      },
      (error: any) => {
        this.dialogService.alertsModal('fileUploads', 8, 5000, false);
        this.globals.stopLoader();
      }
    )
  }

  removeCategoryDB(eventId, categoryId) {
    this.globals.startLoader();
    this.eventService.removeCategory(eventId, categoryId).subscribe(
      (response: AppResponse) => {
        this.globals.stopLoader();
      },
      (error: AppResponse) => {
        this.dialogService.alertsModal('fileUploads', 8, 5000, false, error.log_id);
        this.globals.stopLoader();
      }
    )
  }

  removeItemDB(item_id: number, table: string) {
    this.globals.startLoader();
    const data = {item_id, event_id: this.event_id, table};
    this.eventService.removeItem(data).subscribe(
      (response: AppResponse) => {
        this.globals.stopLoader();
        if (response && response.data) {
          this.checkWhatStepsToShow(response.data.registration_mode);
        }
      },
      (error: AppResponse) => {
        this.dialogService.alertsModal('fileUploads', 8, 5000, false, error.log_id);
        this.globals.stopLoader();
      }
    )
  }

  cropperSetDragMode(cropperComponent: any, mode: string) {
    if (cropperComponent) {
      cropperComponent.cropper.setDragMode(mode);
    }
  }

  cropperDestroy(cropperComponent, imageType) {
    if (cropperComponent) {
      this[`${imageType}Url`] = undefined, this[`${imageType}CropperComponent`] = false;
    }
  }

  colorPicker(color) {
    this.globals.startLoader()
    const data = {
      bg_color: color,
      event_id: this.event_id,
      media_type: 2,
    }
    this.eventService.setColorBackground(data).subscribe(
      (response: any) => {
        if (response.success) {
          if (response.update_media) {
            this.gallery_bg = response.update_media;
            this.gallery_bg.file = this.bgImgSize + response.update_media.file;
          }
          if (response.updated_feed_media) {
            this.gallery_feed = response.updated_feed_media;
            this.gallery_portrait_feed = 'portrait' + response.updated_feed_media.file;
            this.gallery_feed.file = 'mobile' + response.updated_feed_media.file;
          }
          this.dialogService.alertsModal('success', 'bgImage', 5000, true);
        } else if (response.errors) {
          this.dialogService.alertsModal('fileUploads', response.errors, 5000, false);
        }
        this.globals.stopLoader();
      },
      (error: any) => {
        this.dialogService.alertsModal('fileUploads', 8, 5000, false);
        this.globals.stopLoader();
      }
    );
  }

  openInfo(content: string) {
    this.dialogService.infoDialog(content);
  }

  setAddress(location) {
    this.location = location;
    this.advancedStep.controls['lat'].setValue(location.lat);
    this.advancedStep.controls['lon'].setValue(location.lon);
    this.mainStep.controls.address.setValue(location.place);

    const localeCurrencyCode = this.currencyCodes.find((c: any) =>  c.name == this.getCurrencyByCountryCode( location.countryCode ))
      ? this.getCurrencyByCountryCode( location.countryCode )
      : 'USD';

    // console.log('localeCurrencyCode', localeCurrencyCode, this.userCur);
    if ( this.eventData.eventStatus === 2 && localeCurrencyCode !== undefined && !this.userCur) {
      this.advancedStep.controls['currency'].setValue(localeCurrencyCode);
    }
    this.advancedStep.controls['time_zone'].setValue('');
    // console.log('setAddress', location);
  }

  checkAddress(e) {
    const val = e.target.value;
    if (this.mainStep.controls.address.value !== val) {
      this.mainStep.controls.address.setValue('');
    }
  }

  getCurrencyByCountryCode(code) {
    return this.countryCurrencies[code];
  }

  setSelectedLanguages(langs) {
    this.selectedlanguages = [];
    const uniqLangs = [...new Set(langs)];
    // console.log('setSelectedLanguages uniqLangs', uniqLangs);
    for (const l of uniqLangs) {
      this.selectedlanguages.push(this.langsList[this.langsList.findIndex(object => object.lang === l)])
    }
  }

  handleAddLang(vals) {
    for (const v of vals) {
      const langIndex = this.selectedlanguages.findIndex(langOb => langOb.lang === v);
      // const descIndex = this.event.get('main.description').value.findIndex(ob => ob.lang === v);// issue with descriptions duplicates
      if (langIndex === -1) {
        let newValue = '';
        if (this.quillDescriptionValues[v] !== undefined && this.quillDescriptionValues[v]) {
          newValue = this.quillDescriptionValues[v];
        }
        this.addDescription({lang: v, value: newValue});
        if (!this.mainStep.get('description')) {
          this.mainStep.addControl('description', this.descriptionArray);
        }
      }
    }
  }

  handleRemovaLang(vals) {
    let index = -1;
    for (const q in this.quillInstances) {
      if (this.quillInstances.hasOwnProperty(q)) {
        index++;
        if (vals.indexOf(q) === -1) {
          this.removeDescription(index);
          if (this.mainStep.get('description') && this.mainStep.get('description').value.length === 0) {
            this.mainStep.removeControl('description');
          }
          this.quillSubscriptionLangIndexes.splice(index, 1);
          delete this.quillInstances[q];
        }
      }
    }
  }

  setLastTabActive(vals) {
    if (vals.length === 1) {
      const l = this.selectedlanguages.find(object => object.lang === vals[0]);
      l.active = true;
    }
  }

  setDescriptionValuesIfNeeded(event) {
    return new Promise((resolve) => {
      let valueChanged = false;
      if (event && event.get('main.description')) {
        for (const q in this.quillDescriptionValues) {
          if (this.quillDescriptionValues.hasOwnProperty(q)) {
            const element = this.quillDescriptionValues[q];
            const fromDescIndex = event.get('main.description').value.findIndex(ob => ob.lang === q);
            if (event.get('main.description').value[fromDescIndex] !== undefined && event.get('main.description').value[fromDescIndex].value !== element) {
              event.get('main.description').at(fromDescIndex).patchValue({lang: q, value: element});
              valueChanged = true;
            }
          }
        }
      }
      resolve(valueChanged);
    });
  }

  getCurrencyCodes() {
    return new Promise((resolve) => {
      this.bankService.getCurrencycodes().subscribe(
        (response: AppResponse) => {
          this.currencyCodes = response.data;
          resolve(true);
        }, error => {
          resolve(false);
        }
      );
    });
  }

  openCustomFieldsModal(event, eventData) {
    //console.log(event, eventData, this.event.value);
    const data = {id: eventData.id, cmsPage: false}
    this.dialog.open(CustomFieldsDialogComponent, {
      data: data,
      panelClass: 'custom-fields-modal',
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100vh',
      width: '100vw',
    });
  }

  openManagePasscodesModal(event, eventData) {
    // console.log(event, eventData, this.event.value);
    const data = {id: eventData.id, cmsPage: false}
    this.dialog.open(ManagePasscodesDialogComponent, {
      data: data,
      panelClass: 'custom-fields-modal',
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100vh',
      width: '100vw',
    });
  }

  // Upload Ticket image START
  setTicketImage(nft_image_id, ticket_id, ticketFileInput, index) {
    const file: File = ticketFileInput.files[0];

    if (file) {
      if (file.size /  1048576 > 5) {
        return this.dialogService.alertsModal('fileUploads', 3, 5000, false);
      }
    }
    const formData = new FormData();
    formData.append('file', file);
    //console.log("setTicketImage",'nft', nft_image_id, 't',ticket_id, 'i',index);

    ticket_id ?
      this.uploadTicketImageWithID(formData, this.event_id, ticket_id, index) :
      this.uploadTicketImageWithoutID(formData, this.event_id, nft_image_id, index);


  }

  getTicketImages(event_id) {
    this.eventService.getTicketImages(event_id).subscribe(
      (response: any) => {
        if (response) {
          this.ticketImages = response.data;
        }
        // console.log('getTicketImages',  this.ticketImages, 'this.ticketsArray', this.ticketsArray);
      },
      (error: any) => {
       // console.log('getTicketImages error', error);
      }
    );
  }

  setTicketImagesFormValues() {
    this.ticketsArray.controls.forEach((control, i) => {
      //console.log('ticketsArrayFormControl1', control.value.id, i, this.ticketsArray.controls[i].value)
      if (control.value.id) {
        const imgObj = this.ticketImages.find(img => img.ticket_id === control.value.id);
        if (imgObj === undefined) return

        this.ticketsArray.controls[i].patchValue({nft_image_id: imgObj.id});
      }

    });
  }

  getTicketImageSRC(nft_image_id, ticket_id) {
    let src;
    if (this.ticketImages && this.ticketImages.length) {
      if (ticket_id || (nft_image_id && ticket_id)) {
        src = this.ticketImages.find(x => x.ticket_id === ticket_id);
      } else  {
        src = this.ticketImages.find(x => x.id === nft_image_id);
      }
    }
      return src === undefined ? null : src.url;
  }

  uploadTicketImageWithID(formData, event_id, ticket_id, index) {
    const ticket_index = this.ticketImages.findIndex(x => x.ticket_id === ticket_id);
    console.log('uploadTicketImageWithID', event_id, ticket_id, 'i', index, 'ticket_index:', ticket_index)
    this.globals.startLoader();
    this.eventService.uploadTicketImage(formData, event_id, ticket_id).subscribe(
      (response: any) => {
        if (response) {
          if (ticket_index === -1) {
            const data = response.data;
            data['ticket_id'] = ticket_id;
            this.ticketImages.push(data)
          } else {
            this.ticketImages[ticket_index].id = response.data.id;
            this.ticketImages[ticket_index].url = response.data.url;
            this.ticketImages[ticket_index].ticket_id = ticket_id;
          }
          this.ticketsArray.controls[index].patchValue({nft_image_id: response.data.id});
        }
        this.globals.stopLoader();
      },
      (error: any) => {
        console.log('uploadTicketImage error', error);
        this.globals.stopLoader();
        this.dialogService.alertsModal('fileUploads', 5, 5000, false);

      }
    );
  }

  uploadTicketImageWithoutID(formData, event_id, nft_image_id, index) {
    console.log('uploadTicketImageWithoutID', event_id, nft_image_id, index, this.ticketImages);
    this.globals.startLoader();
    const ticket_index = this.ticketImages.findIndex(x => x.id === nft_image_id);
    this.eventService.uploadTicketImageNoId(formData, event_id).subscribe(
      (response: any) => {
        if (response) {
          if (ticket_index === -1) {
            this.ticketImages.push(response.data)
          } else {
            this.ticketImages[ticket_index] = response.data;
          }
          this.ticketsArray.controls[index].patchValue({nft_image_id: response.data.id});
        }
        this.globals.stopLoader();
      },
      (error: any) => {
        console.log('uploadTicketImageWithoutID error', error);
        this.globals.stopLoader();
        this.dialogService.alertsModal('fileUploads', 5, 5000, false);
      }
    );
  }

  removeTicketImage(nft_image_id, index) {
    // console.log('removeTicketImage', nft_image_id, this.ticketImages, index);
    const ticket_index = this.ticketImages.findIndex(x => x.id === nft_image_id);
    this.globals.startLoader();
    this.eventService.deleteTicketImageById(this.event_id, nft_image_id).subscribe(
      response => {
        if (ticket_index === -1) {
          return
        } else {
          this.ticketImages[ticket_index].url = '';
        }
        this.ticketsArray.controls[index].patchValue({nft_image_id: ''});
        this.globals.stopLoader();
      },
      error => {
        // console.log('deleteTicketImageById error', error);
        this.globals.stopLoader();
      });

  }
  // Upload Ticket image END

  setNftStatus() {
    this.nftStatus = this.eventData.nft;
    // console.log('setNftStatus', this.nftStatus)
  }
  setDonateStatus() {
    this.donateStatus = this.eventData.donate;
    // console.log('setDonateStatus', this.donateStatus)
  }
  setBlockedInputs() {
    this.blockedInputs = this.eventData.blocked_inputs;
    // console.log('setBlockedInputs', this.blockedInputs)
  }

  priceChange(e) { // Boost conditions
    console.log('priceChange',e.target.value,'priceVal', this.priceVal);
    if((this.eventData.eventStatus == 1 || this.eventData.eventStatus == 2 || this.eventData.eventStatus == 3) && +e.target.value > 0) {
      setTimeout(()=>{this.checkHideBoost()},200);
    };
  }
  privacyPublic(e) { // Boost conditions
   // console.log('privacyPublic', this.registrationMode, e.target.checked, this.eventData.boost.editable, this.quantityVal, this.priceVal, this.eventData.eventStatus);
   if(this.eventData.eventStatus == 1 || this.eventData.eventStatus == 2 || this.eventData.eventStatus == 3) {this.checkHideBoost()};
  }
  privacyPrivate(e) { // Boost conditions
    // console.log('privacyPrivate', e.target.checked);
    if(this.eventData.eventStatus == 1 || this.eventData.eventStatus == 2 || this.eventData.eventStatus == 3) {this.checkHideBoost()};
  }
  quantityChange(e) { // Boost conditions
    console.log('quantityChange',e.target.value,'quantityVal', this.quantityVal);
    if((this.eventData.eventStatus == 1 || this.eventData.eventStatus == 2 || this.eventData.eventStatus == 3) && +e.target.value > 0) {
      setTimeout(()=>{this.checkHideBoost()},200);
    };
  }
  donatePriceChange(e, i) {
    // console.log('donatePriceChange',e.target.value,'i', i);
    setTimeout(()=>{
      if(this.ticketsArray.controls[i].get('donation_min').value >= this.ticketsArray.controls[i].get('donation_max').value) {
        this.ticketsArray.controls[i].get('donation_min').patchValue(1);
      }
    },200);
  }

  showErrorOnTicketQuantity(ticket_id, purchase) {
    // this.pannels.get(2).open();
    this.ticketsArray.controls.forEach((control, i) => {
      if (control.value.id === ticket_id) {
        // console.log('EXIST',this.ticketsArray.controls[i]['controls'].quantity);
        this.ticketsArray.controls[i]['controls'].quantity.setValidators([Validators.required,Validators.min(+purchase)]);
        this.ticketsArray.controls[i]['controls'].quantity.updateValueAndValidity();
        this.submitForm(); // to scroll to error field

      }
    });
  }

  // END NOT SURE IT IS UTIL

  setArtistsData(data) {
    this.artistsData = data;
    this.artistsData.forEach(artist => {
      this.selectedSpotifyArtists[artist.artist_id] = {
        external_urls: {
          spotify: artist.external_urls
        },
        id: artist.artist_id,
        images: [{url: artist.image_url}],
        name: artist.name,
        followers: {
          total: artist.followers
        }
      }
    })
  }
  checkSpotifyToken() {
    const access_token = this._cookieService.get('spotify_access_token');
    if(access_token) {
      return
    }

    this.globals.startLoader();
    this.spotifyService.getToken().subscribe((res: {access_token: '', token_type: '', expires_in: 3600}) => {
      console.log(res)
      const tokenCookieExpDate = new Date();
      tokenCookieExpDate.setHours(tokenCookieExpDate.getHours() + 1); // +1 hour
      this._cookieService.set('spotify_access_token', res.access_token, {
        expires: tokenCookieExpDate,
        secure: location.protocol == 'https:' ? true : false,
      });
      this.globals.stopLoader();
    }, error => {
      this.globals.stopLoader();
    })

  }

  filterSpotifySearchInput(val: string): Observable<any[]> {
    const access_token = this._cookieService.get('spotify_access_token');
    if (!val || val.length < 2) {return of([])}
    // call the service which makes the http-request
    return this.spotifyService.search(access_token, 'artist', val).pipe(
      tap((val) => console.log(val)),
      map((response: any) => {
        return response?.artists?.items
        }
      )
    );
  }

  ngOnDestroy() {
    this.navbar.noPadding = '';
    this.navbar.setIsNewUi(false);
    if (!this.formsubmited) {
      this.setDescriptionValuesIfNeeded(this.event).then(valueChanged => {
        if (valueChanged) {
          this.eventService.setEventDraft(this.event_id, 'main', {main: this.event.value}).subscribe(
            (response: AppResponse) => {
            },
            (error: AppResponse) => this.dialogService.alertsModal((error.code) ? 'editEvent' : 'errors', (error.code) ? error.code : 4, 5000, false, error.log_id)
          );
        }
      });
    }
    if (this.draftSubscription) {
      this.draftSubscription.unsubscribe();
    }
    if (this.endDateSubscribtion) {
      this.endDateSubscribtion.unsubscribe();
    }
    if (this.routerSubscriprion) {
      this.routerSubscriprion.unsubscribe();
    }
    this.formsubmited = false;
  }
}
