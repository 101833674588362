<section class="dashboard" [ngClass]="{'rtl' : dir === 'rtl'}">
  <div class="events-back-arrow">
    <div class="close-dialog-arrow" (click)="closeMyEvents()">
      <svg class="arrow-close-modal" width="10" height="14" viewBox="0 0 10 14" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path d="M9.49219 1.64063L4.13281 7L9.49219 12.3594L7.85156 14L0.851562 7L7.85156 0L9.49219 1.64063Z"
              fill="white" />
      </svg>
    </div>
  </div>
	<div class="my-events">
		<div class="event-header-title">
      <div class="event-header-wrap">
        <div class="producer-logo">
          <img *ngIf="user_details?.profile_img" [src]="images_base_url + '/profile/' + user_details?.profile_img" alt="logo">
          <img *ngIf="!user_details?.profile_img && user_details?.social_img" [src]="user_details?.social_img" alt="logo">
          <svg *ngIf="!user_details?.profile_img && !user_details?.social_img" width="90" height="90" [data-jdenticon-value]="user_details?.user_id"></svg>
        </div>
        <div>
          <div class="producer-name" i18n="producer name">{{user_name}}'s Events</div>
          <div class="followers"><strong>{{followers ? followers : 0}} </strong><span i18n="dashboard | followers">{followers, plural, =1 {Follower} other {Followers}}</span></div>
          <div class="view-edit-btns">
            <a class="view" [routerLink]="['/producer/' + user_info?.user_id]" title="{{user_name}}" i18n="dashboard | view" >View</a>
            <i class="line"></i>
            <a class="edit" i18n="dashboard | edit" routerLink="/producer-edit">Edit</a>
          </div>
        </div>
      </div>
		</div>

		<div class="hendler-events">
			<div class="filter-events">
				<button class="filter-btn" aria-label="filter" (click)="toggleEventByStatus()" [ngClass]="{'active': showPromCat && !isMobile}">
					<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M22.0306 10.9563V19.6705C22.0306 20.9782 22.0306 22.2859 22.0306 23.5936C22.0306 24.3827 21.7555 24.7555 21.1957 24.7379C20.6653 24.7202 20.3913 24.3345 20.3913 23.6007C20.3913 19.5411 20.3913 15.4862 20.3995 11.422C20.3995 11.0692 20.3066 10.8787 19.9703 10.7164C18.9742 10.2248 18.4415 9.37455 18.4332 8.27616C18.425 7.17777 18.9213 6.26284 19.9503 5.76186C20.316 5.58429 20.4042 5.37731 20.3984 5.00569C20.3784 3.65917 20.3913 2.31265 20.3878 0.966122C20.3878 0.532176 20.5465 0.142919 20.9758 0.0664791C21.2451 0.0229669 21.6132 0.165263 21.8401 0.352248C21.9918 0.469848 22.0201 0.809713 22.0224 1.05079C22.0389 2.39732 22.0224 3.74384 22.0448 5.09037C22.0448 5.28911 22.1941 5.57841 22.3587 5.66661C23.4172 6.23109 24.024 7.07781 23.9993 8.2891C23.9758 9.4651 23.3925 10.3154 22.307 10.8034C22.2188 10.8375 22.1377 10.8939 22.0306 10.9563Z"
							fill="#dadada" />
						<path
							d="M11.1987 7.43774C11.1987 5.29271 11.1987 3.14651 11.1987 1.0003C11.1987 0.573409 11.3104 0.227664 11.7373 0.0594958C12.2923 -0.161593 12.8286 0.254713 12.8286 0.956787C12.8462 2.32213 12.8368 3.68747 12.8368 5.05398C12.8368 7.95989 12.8368 10.867 12.8274 13.7741C12.8274 14.1668 12.9203 14.375 13.3049 14.5679C14.2704 15.0535 14.7749 15.8979 14.7878 16.9787C14.8008 18.0806 14.3268 18.9555 13.3284 19.4577C12.9333 19.6576 12.8145 19.8857 12.8251 20.3079C12.8533 21.4392 12.8404 22.5717 12.8321 23.7031C12.8321 24.3781 12.5158 24.7473 11.9889 24.7368C11.4621 24.7262 11.2022 24.384 11.1975 23.7242C11.1904 22.5341 11.1857 21.344 11.1975 20.1539C11.1975 19.8446 11.1152 19.6835 10.8141 19.5353C9.79099 19.0484 9.24532 18.2099 9.22533 17.0763C9.20416 15.9144 9.73924 15.0453 10.7753 14.5326C11.1187 14.3632 11.2022 14.168 11.1998 13.8176C11.1916 11.6913 11.1987 9.56396 11.1987 7.43774Z"
							fill="#dadada" />
						<path
							d="M3.61662 5.92284L3.61662 13.5116L3.61662 23.6934C3.61662 24.3696 3.31909 24.7424 2.79342 24.7377C2.26775 24.733 1.97845 24.3685 1.97845 23.6793C1.97845 18.0227 1.97845 12.3662 1.97845 6.70959V5.92166C0.349686 5.07612 -0.255956 3.9695 0.0968451 2.53595C0.247362 1.93165 0.596012 1.39521 1.08713 1.01227C1.57824 0.629336 2.1835 0.421993 2.80626 0.423346C3.42902 0.4247 4.03337 0.634674 4.52281 1.01974C5.01226 1.40481 5.35857 1.94276 5.50646 2.54771C5.85221 3.95539 5.23833 5.06318 3.61662 5.92284Z"
							fill="#dadada" />
					</svg>
				</button>
        <button class="sort-btn" (click)="sort()" title="{{'dashboard.SortByStartDate' | translate:'Sort By Event Start Date'}}">
          <svg *ngIf="sortType == 'ASC'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#dadada" d="M21 17h3l-4 4l-4-4h3V3h2zM8 16h3v-3H8zm5-11h-1V3h-2v2H6V3H4v2H3c-1.11 0-2 .89-2 2v11c0 1.11.89 2 2 2h10c1.11 0 2-.89 2-2V7c0-1.11-.89-2-2-2M3 18v-7h10v7z"/></svg>
          <svg *ngIf="sortType == 'DESC'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#dadada" d="M19 7h-3l4-4l4 4h-3v14h-2zM8 16h3v-3H8zm5-11h-1V3h-2v2H6V3H4v2H3c-1.11 0-2 .89-2 2v11c0 1.11.89 2 2 2h10c1.11 0 2-.89 2-2V7c0-1.11-.89-2-2-2M3 18v-7h10v7z"/></svg>
        </button>
			</div>
			<div class="active-events">
				<div i18n="filtered events">
					{
				eventsStatus, select,
				active {Active}
				editing {Editing}
				pending {Pending}
				closed {Closed}
				deleted {Deleted}
				ended {Ended}
					}
				</div>
        &nbsp;<span i18n="filtered events">&nbsp;EVENTS</span>
				<div *ngIf="events">&nbsp;({{eventsCount(eventsStatus)}})&nbsp;</div>
			</div>
			<div class="search-events">
				<button class="search-btn" aria-label="search" (click)="toggleSearch()" [ngClass]="{'active': showSearchInput}">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd"
							d="M17.1544 15.0935H16.0649L15.6862 14.7175C17.0241 13.1574 17.8377 11.1349 17.8377 8.91887C17.8377 3.99291 13.8448 0 8.91887 0C3.99291 0 0 3.99291 0 8.91887C0 13.8448 3.99291 17.8377 8.91887 17.8377C11.1349 17.8377 13.156 17.0254 14.7161 15.689L15.0949 16.0649V17.1517L21.9528 24L23.9986 21.9541L17.1544 15.0935ZM8.91888 15.0935C5.50776 15.0935 2.74428 12.33 2.74428 8.91888C2.74428 5.50913 5.50776 2.74428 8.91888 2.74428C12.3286 2.74428 15.0935 5.50913 15.0935 8.91888C15.0935 12.33 12.3286 15.0935 8.91888 15.0935Z"
							fill="#dadada" />
					</svg>
				</button>
				<div class="search-filter" *ngIf="showSearchInput">
					<input i18n-placeholder="@@SearchEvents" type="search" id="searchInput" class="search-input"
						(input)="searchFilter($event)" [(ngModel)]="filterText" name="search" placeholder="Search Events">
				</div>
			</div>
		</div>

		<!-- <div class="row">
		<div class="col-md-12 col-xs-12 pull-right">
      <button type="button" class="my-feed-button" [routerLink]="['/producer/' + user_id]">
        <span i18n="my feed | button text" [ngClass]="{'btn-text': true, 'bold': true}">הפיד שלי</span>
      </button>
		</div>
		<div class="col-md-12 col-xs-12">
			<span (click)="getAffiliateEvents()" class="affiliate_table_btn">הגדר אירועים לפיד אישי</span>
		</div>
	</div> -->
		<div>
			<div class="event-statuses" *ngIf="toggleByStatus" [@fade] role="dialog" data-backdrop="false">
				<div class="event-back-arrow">
					<div class="close-dialog" (click)="closeEventStatuses()">
						<svg class="arrow-close-modal" width="10" height="14" viewBox="0 0 10 14" fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								d="M9.49219 1.64063L4.13281 7L9.49219 12.3594L7.85156 14L0.851562 7L7.85156 0L9.49219 1.64063Z"
								fill="white" />
						</svg>
					</div>
				</div>
				<div class="col-md-12 col-xs-12 mt15 mb15 event_filter">

					<div i18n="dashboard status filter" class="filtered-events" *ngFor="let s of eventsStatuses"
						(click)="getEventsByStatus(s.filterNameEn)">
						{
						s.code, select,
						1 {Active Events}
						2 {Events Being Edited}
						3 {Events Pending Approval}
						4 {Closed Events}
						5 {Cancelled \ Deleted Events}
						6 {Events That Have Ended}
						}
					</div>
				</div>
			</div>
		</div>
		<div class="clearfix"></div>
		<!-- <div class="bright-divider"></div> -->


		<div *ngIf="events" class="producer_events">
			<div id="{{event.event_id}}" class="dashboard-event"
				*ngFor="let event of events | search_params: filterText" [@fade]>
				<div class="title-section">
					<div class="event-title" *ngIf="!isMobile">{{event.title}}</div>
          <p class="event-id" *ngIf="!isMobile">id: <span>{{event.event_id}}</span></p>
					<div i18n-title="@@AdditionalOptions" [attr.data-cy]="'eventMenuButton'" title="Additional Options"
						*ngIf="event.status != 3 && event.status != 6" class="main-menu" (click)="openEventMenu(event)">
					</div>
          <div class="producer-share">
            <!-- <i class="fa fa-share-alt" aria-hidden="true"></i> -->
            <div class="uploads" *ngIf="event.status == 1" (click)="toggleShare(event.event_id)">
              <!-- <i class="fa fa-upload" aria-hidden="true"></i> -->
              <img class="share" src="/assets/images/svg-icons/share.svg" />
            </div>
          </div>
				</div>
				<div class="main-section">
					<div class="event-main">
						<div class="event-avatar">
              <a [attr.data-cy]="'eventLogoButton'" [routerLink]="[linkParams(event).encodedUrl]">
                <img class="event-avatar-img"
                     [src]="event.file ? images_base_url + '/gallery/mobile' + event.file: eventDefaultImg">
              </a>
						</div>
					</div>
					<div class="event-ext">
            <div class="event-title" *ngIf="isMobile">{{event.title}}</div>
            <p class="event-id" *ngIf="isMobile">id: <span>{{event.event_id}}</span></p>
						<div class="event-producer">
							<div class="address">
								<i class="marker-size fa fa-map-marker" aria-hidden="true"></i>
								<div class="address-event">{{event?.address}}</div>
							</div>
							<div class="dates-event">
								<div class="date-event">
									{{event.start_date | date : 'fullDate'}} - {{event.start_date | date : 'shortTime'}}
								</div>
<!--								<div class="time-event">-->
<!--									{{event.end_date | date: 'fullDate'}} - {{event.end_date | date : 'shortTime'}}-->
<!--								</div>-->
							</div>
							<!-- <div class="producer">
							<div *ngIf="event" class="details">
								<p class="mb0">
									<span i18n="dashboard | event status">
										{
										event.privacy, select,
										0 {ציבורי}
										1 {פרטי}
										}
									</span>
									|<span i18n="dashboard | producer"> מפיק </span></p>
							</div>
						</div> -->
<!--							<div class="viev-event">-->
<!--								<a i18n="see event" class="event-link"-->
<!--									*ngIf="event.status == 1 || event.status == 3 || event.status == 4"-->
<!--									[routerLink]="['/'+event.encodedUrl]">צפיה באירוע</a>-->
<!--							</div>-->
			<app-event-counters [views]="event.views" [sales]="event.sales" [approved]="event.approved" [pending]="event.pending"
				[eventID]="event.event_id" [currency]="event.currency"></app-event-counters>
						</div>
						<!-- <div class="event-status">
						<span i18n="dashboard | active" *ngIf="event.status == 1 && eventsStatus != 'ended'" class="green"><i class="fa fa-circle"></i> פעיל</span>
						<span i18n="dashboard | editing" *ngIf="event.status == 2" class="orange"><i class="fa fa-circle"></i> בעריכה</span>
						<span i18n="dashboard | pending" *ngIf="event.status == 3" class="blue"><i class="fa fa-circle"></i> ממתין </span>
						<span i18n="dashboard | closed" *ngIf="event.status == 4" class="black"><i class="fa fa-circle"></i> סגור</span>
						<span i18n="dashboard | deleted" *ngIf="event.status == 5" class="gray"><i class="fa fa-circle"></i> מחוק / מבוטל</span>
						<span i18n="dashboard | ended" *ngIf="event.status == 1 && eventsStatus == 'ended'" class="black"><i class="fa fa-circle"></i> הסתיים</span>
					</div> -->

					</div>
				</div>

				<div class="share-section">
					<div [@slideIn]
						*ngIf="event.status == 1 && toggleShareObj.hasOwnProperty(event.event_id) && toggleShareObj[event.event_id]"
						class="col-md-12 col-xs-12 pl0 pr0">
						<div class="shareOptions">
							<div class="text-center">
								<p i18n="@@Dashboardshareandinvitefriends" class="shareTitle">Share and invite friends:</p>
							</div>
							<div class="text-center">
								<img class="img-divider" src="/assets/images/share_divider.png">
							</div>
							<div class="col-sm-12 col-xs-12 shareIcons">
								<div class="col-xs-2 col-centered">
									<a i18n-href="@@Dashboardsharebyemaillink"
										i18n-title="@@Dashboardsharebyemail" title="Share by email"
										href="mailto:?subject=Check out this link&amp;body=Take a look at this website, it's worth checking out {{event.encodedShareUrl}}">
										<img class="img-responsive" src="/assets/images/email.png">
									</a>
								</div>
								<div class="col-xs-2 col-centered">
									<a i18n-title="@@Dashboardsharebyfacebook" target="_blank" title="Share on Facebook"
										href="https://www.facebook.com/sharer/sharer.php?u={{event.encodedShareUrl}}">
										<img class="img-responsive" src="/assets/images/facebook.png">
									</a>
								</div>
								<div class="col-xs-2 col-centered">
									<a i18n-title="@@Dashboardsharebywhatsapp"
										[href]="(event.whatsapp_link)" target="_blank"
										data-action="share/whatsapp/share" data-title="Worth checking!"
										title="Share by WhatsApp">
										<img class="img-responsive" src="/assets/images/whatsApp.png">
									</a>
								</div>
								<div class="col-xs-2 col-centered">
									<a i18n-title="@@CopyLink" href="javascript:void(0)" title="Copy link"
										(click)="copyUrl(api_base_url + '/' + localeId + '/' + (event.url_friendly ? event.url_friendly : event.event_id))">
										<img class="img-responsive" src="/assets/images/Copy.png">
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="seller_events?.length > 0" class="producer_events">
			<div id="{{event.event_id}}" class="dashboard-event"
				*ngFor="let event of seller_events | search_params: filterText">
				<!-- <div class="row">
					<div class="col-md-10 col-xs-10 event_status">
						<div *ngIf="event.agt_status == 1 || event.status != 1" class="event_status">
							<span i18n="dashboard | active" *ngIf="event.status == 1 && eventsStatus != 'ended'"
								class="green"><i class="fa fa-circle"></i> פעיל</span>
							<span i18n="dashboard | editing" *ngIf="event.status == 2" class="orange"><i
									class="fa fa-circle"></i> בעריכה</span>
							<span i18n="dashboard | pending" *ngIf="event.status == 3" class="blue"><i class="fa fa-circle"></i>
								ממתין לאישור</span>
							<span i18n="dashboard | closed" *ngIf="event.status == 4" class="black"><i class="fa fa-circle"></i>
								סגור</span>
							<span i18n="dashboard | del<div class="row">
					<div class="col-md-10 col-xs-10">
						<h3>{{event.title}}</h3>
						<div *ngIf="event" class="event_details">
							<p class="mb0">{{event.end_date}} - {{event.start_date}} |
								<span i18n="dashboard | event status">
									{
									event.privacy, select,
									0 {ציבורי}
									1 {פרטי}
									}
								</span>
								|<span i18n="dashboard | manager"> מנהל </span></p>
							<a i18n="see event" *ngIf="event.status == 1 || event.status == 3 || event.status == 4"
								[routerLink]="['/'+event.encodedUrl]">צפיה באירוע</a>
						</div>
					</div>
					<div class="col-md-2 col-xs-2">
						<div *ngIf="event.agt_status == 1 || (event.agt_status == 2 && event.status != 1)"
							class="text-center">
							<div *ngIf="event.status == 1" (click)="toggleShare(event.event_id)"
								class="sharer fa-position">
								<img src="/assets/images/Share.png">
							</div>
						</div>
						<div *ngIf="event.agt_status == 2 && event.status == 1" class="text-center">
							<div *ngIf="event.status == 1" (click)="toggleShare(event.event_id)"
								class="sharer fa-position">
								<img src="/assets/images/Share.png">
							</div>
						</div>
					</div>
				</div>eted" *ngIf="event.status == 5" class="gray"><i class="fa fa-circle"></i>
								מחוק / מבוטל</span>
							<span i18n="dashboard | ended" *ngIf="event.status == 1 && eventsStatus == 'ended'" class="black"><i
									class="fa fa-circle"></i> הסתיים</span>
						</div>
						<div *ngIf="event.agt_status == 2 && event.status == 1" class="agt_status">
							<img class="img-responsive" src="/assets/images/dashboard/Bell.png">
							<p i18n="dashboard | wait for confirmation as seller in event">ממתין לאישורך <span
									class="blue">כיחצ"ן</span> באירוע</p>
						</div>
					</div>
					<div class="col-md-2 col-xs-2">
						<div *ngIf="event.agt_status == 1 || (event.agt_status == 2 && event.status != 1)" class="text-center">
							<button (click)="openEventMenu(event)" class="event_options fa-position"
								*ngIf="event.status != 3 && event.status != 6">
								<span i18n-title="more options" title="אפשרויות נוספות"><i class="fa fa-angle-down"></i></span>
							</button>
						</div>
						<div *ngIf="event.agt_status == 2 && event.status == 1" (click)="openAgentModal(event)"
							class="text-center">
							<button class="agt_status_btn fa-position" data-toggle="modal" data-target="#open_agent_status">
								<span i18n-title="more options" title="אפשרויות נוספות"><i class="fa fa-angle-down"></i></span>
							</button>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-10 col-xs-10">
						<h3>{{event.title}}</h3>
						<div *ngIf="event" class="event_details">
							<p class="mb0">{{event.end_date}} - {{event.start_date}} |
								<span i18n="dashboard | event status">
									{
									event.privacy, select,
									0 {ציבורי}
									1 {פרטי}
									}
								</span>
								|<span i18n="dashboard | seller"> יחצ"ן </span></p>
							<a i18n="see event" *ngIf="event.status == 1 || event.status == 3 || event.status == 4"
								[routerLink]="['/'+event.encodedUrl]">צפיה באירוע</a>
						</div>
					</div>
					<div class="col-md-2 col-xs-2">
						<div *ngIf="event.agt_status == 1 || (event.agt_status == 2 && event.status != 1)" class="text-center">
							<div *ngIf="event.status == 1" (click)="toggleShare(event.event_id)" class="sharer fa-position">
								<img src="/assets/images/Share.png">
							</div>
						</div>
						<div *ngIf="event.agt_status == 2 && event.status == 1" class="text-center">
							<div *ngIf="event.status == 1" (click)="toggleShare(event.event_id)" class="sharer fa-position">
								<img src="/assets/images/Share.png">
							</div>
						</div>
					</div>
				</div> -->
				<div *ngIf="event.agt_status == 2 && event.status == 1" class="agt_status">
					<span i18n="@@DashboardWaitForConfirmationasseller">Pending your approval as seller</span>
				</div>
				<div class="title-section">
					<div class="event-title" *ngIf="!isMobile">{{event.title}}</div>
          <p class="event-id" *ngIf="!isMobile">id: <span>{{event.event_id}}</span></p>
          <div class="producer-share">
            <div class="uploads" *ngIf="event.status == 1" (click)="toggleShare(event.event_id)">
              <img class="share" src="/assets/images/svg-icons/share.svg" />
            </div>
          </div>
					<div *ngIf="event.agt_status == 2 && event.status == 1"  class="text-center">
						<div i18n-title="@@AdditionalOptions" title="Additional Options"
							*ngIf="event.status != 3 && event.status != 6" class="main-menu" (click)="openAgentModal(event)">
						</div>
					</div>
					<div *ngIf="event.agt_status == 1 || (event.agt_status == 2 && event.status != 1)" class="text-center">
						<div i18n-title="@@AdditionalOptions" title="Additional Options"
							*ngIf="event.status != 3 && event.status != 6" class="main-menu" (click)="openEventMenu(event)">
						</div>
					</div>
				</div>
				<div class="main-section">
					<div class="event-main">
						<div class="event-avatar">
              <a [routerLink]="[linkParams(event).encodedUrl]" [queryParams]="linkParams(event, 'seller_events').queryParams">
                <img class="event-avatar-img"
                     [src]="event.file ? images_base_url + '/gallery/mobile' + event.file: eventDefaultImg">
              </a>
						</div>
					</div>
					<div class="event-ext">
            <div class="event-title" *ngIf="isMobile">{{event.title}}</div>
            <p class="event-id" *ngIf="isMobile">id: <span>{{event.event_id}}</span></p>
						<div class="event-producer">
							<div class="address">
								<i class="marker-size fa fa-map-marker" aria-hidden="true"></i>
								<div class="address-event">{{event?.address}}</div>
							</div>
							<div class="dates-event">
								<div class="date-event">
									{{event.start_date | date : 'fullDate'}} - {{event.start_date | date : 'shortTime'}}
								</div>
<!--								<div class="time-event">-->
<!--									{{event.end_date | date: 'fullDate'}} - {{event.end_date | date : 'shortTime'}}-->
<!--								</div>-->
							</div>
							<!-- <div class="producer">
											<div *ngIf="event" class="details">
												<p class="mb0">
													<span i18n="dashboard | event status">
														{
														event.privacy, select,
														0 {ציבורי}
														1 {פרטי}
														}
													</span>
													|<span i18n="dashboard | producer"> מפיק </span></p>
											</div>
										</div> -->
<!--							<div class="viev-event">-->
<!--								<a i18n="see event" class="event-link"-->
<!--									*ngIf="event.status == 1 || event.status == 3 || event.status == 4"-->
<!--									[routerLink]="['/'+event.encodedUrl]">צפיה באירוע</a>-->
<!--							</div>-->
			<app-event-counters [views]="event.views" [sales]="event.sales" [approved]="event.approved" [pending]="event.pending"
				[eventID]="event.event_id" [currency]="event.currency"></app-event-counters>
						</div>
						<!-- <div class="event-status">
										<span i18n="dashboard | active" *ngIf="event.status == 1 && eventsStatus != 'ended'" class="green"><i class="fa fa-circle"></i> פעיל</span>
										<span i18n="dashboard | editing" *ngIf="event.status == 2" class="orange"><i class="fa fa-circle"></i> בעריכה</span>
										<span i18n="dashboard | pending" *ngIf="event.status == 3" class="blue"><i class="fa fa-circle"></i> ממתין לאישור</span>
										<span i18n="dashboard | closed" *ngIf="event.status == 4" class="black"><i class="fa fa-circle"></i> סגור</span>
										<span i18n="dashboard | deleted" *ngIf="event.status == 5" class="gray"><i class="fa fa-circle"></i> מחוק / מבוטל</span>
										<span i18n="dashboard | ended" *ngIf="event.status == 1 && eventsStatus == 'ended'" class="black"><i class="fa fa-circle"></i> הסתיים</span>
									</div> -->


					</div>
				</div>

				<div class="share-section">
					<div [@slideIn]
						*ngIf="event.status == 1 && toggleShareObj.hasOwnProperty(event.event_id) && toggleShareObj[event.event_id]"
						class="col-md-12 col-xs-12 pl0 pr0">
						<div class="shareOptions">
							<div class="text-center">
								<p i18n="@@Dashboardshareandinvitefriends" class="shareTitle">Share and invite friends:</p>
							</div>
							<div class="text-center">
								<img class="img-divider" src="/assets/images/share_divider.png">
							</div>
							<div class="col-sm-12 col-xs-12 shareIcons">
								<div class="col-xs-2 col-centered">
									<a i18n-href="@@Dashboardsharebyemaillink"
                     i18n-title="@@Dashboardsharebyemail" title="Share by email"
										href="mailto:?subject=Check out this link&amp;body=Take a look at this website, it's worth checking out {{event.encodedShareUrl}}">
										<img class="img-responsive" src="/assets/images/email.png">
									</a>
								</div>
								<div class="col-xs-2 col-centered">
									<a i18n-title="@@Dashboardsharebyfacebook" target="_blank" title="Share on Facebook"
										href="https://www.facebook.com/sharer/sharer.php?u={{event.encodedShareUrl}}">
										<img class="img-responsive" src="/assets/images/facebook.png">
									</a>
								</div>
								<div class="col-xs-2 col-centered">
									<a i18n-title="@@Dashboardsharebywhatsapp" [href]="(event.whatsapp_link)" target="_blank"
										data-action="share/whatsapp/share" data-title="Worth checking!"
										title="Share by WhatsApp">
										<img class="img-responsive" src="/assets/images/whatsApp.png">
									</a>
								</div>
								<div class="col-xs-2 col-centered">
									<a i18n-title="@@CopyLink" href="javascript:void(0)" title="Copy link"
										(click)="copyUrl(api_base_url + '/' + localeId + '/' + (event.url_friendly ? event.url_friendly : event.event_id) + '?seller_code=' + event.code)">
										<img class="img-responsive" src="/assets/images/Copy.png">
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="manager_events?.length > 0" class="manager_events">
			<div id="{{event.event_id}}" class="dashboard-event"
				*ngFor="let event of manager_events | search_params: filterText">
				<!-- <div class="row">
					<div class="col-md-10 col-xs-10 event_status">
						<div *ngIf="event.agt_status == 1 || event.status != 1" class="event_status">
							<span i18n="dashboard | active" *ngIf="event.status == 1 && eventsStatus != 'ended'"
								class="green"><i class="fa fa-circle"></i> פעיל</span>
							<span i18n="dashboard | editing" *ngIf="event.status == 2" class="orange"><i
									class="fa fa-circle"></i> בעריכה</span>
							<span i18n="dashboard | pending" *ngIf="event.status == 3" class="blue"><i
									class="fa fa-circle"></i> ממתין לאישור</span>
							<span i18n="dashboard | closed" *ngIf="event.status == 4" class="black"><i
									class="fa fa-circle"></i> סגור</span>
							<span i18n="dashboard | deleted" *ngIf="event.status == 5" class="gray"><i
									class="fa fa-circle"></i> מחוק / מבוטל</span>
							<span i18n="dashboard | ended" *ngIf="event.status == 1 && eventsStatus == 'ended'"
								class="black"><i class="fa fa-circle"></i> הסתיים</span>
						</div>
						<div *ngIf="event.agt_status == 2 && event.status == 1" class="agt_status">
							<img class="img-responsive" src="/assets/images/dashboard/Bell.png">
							<p i18n="dashboard | wait for confirmation as manager in event">ממתין לאישורך <span
									class="blue">כמנהל</span> באירוע</p>
						</div>
					</div>
					<div class="col-md-2 col-xs-2">
						<div *ngIf="event.agt_status == 1 || (event.agt_status == 2 && event.status != 1)"
							class="text-center">
							<button (click)="openEventMenu(event)" class="event_options fa-position"
								*ngIf="event.status != 3 && event.status != 6">
								<span i18n-title="more options" title="אפשרויות נוספות"><i
										class="fa fa-angle-down"></i></span>
							</button>
						</div>
						<div *ngIf="event.agt_status == 2 && event.status == 1" (click)="openAgentModal(event)"
							class="text-center">
							<button class="agt_status_btn fa-position">
								<span i18n-title="more options" title="אפשרויות נוספות"><i
										class="fa fa-angle-down"></i></span>
							</button>
						</div>
					</div>
				</div> -->
				<div *ngIf="event.agt_status == 2 && event.status == 1" class="agt_status">
          <span i18n="@@DashboardWaitForConfirmationasseller">Pending your approval as seller</span>
				</div>
				<div class="title-section">
					<div class="event-title" *ngIf="!isMobile">{{event.title}}</div>
          <p class="event-id" *ngIf="!isMobile">id: <span>{{event.event_id}}</span></p>
          <div class="producer-share">
            <div class="uploads" *ngIf="(event.agt_status == 2 || event.agt_status == 1) && event.status == 1"
                 (click)="toggleShare(event.event_id)">
              <img class="share" src="/assets/images/svg-icons/share.svg" />
            </div>
          </div>
					<div *ngIf="event.agt_status == 2 && event.status == 1" class="text-center">
						<div i18n-title="@@AdditionalOptions" title="Additional Options" *ngIf="event.status != 3 && event.status != 6"
							class="main-menu" (click)="openAgentModal(event)">
						</div>
					</div>
					<div *ngIf="event.agt_status == 1 || (event.agt_status == 2 && event.status != 1)" class="text-center">
						<div i18n-title="@@AdditionalOptions" title="Additional Options" *ngIf="event.status != 3 && event.status != 6"
							class="main-menu" (click)="openEventMenu(event)">
						</div>
					</div>
				</div>
				<div class="main-section">
					<div class="event-main">
						<div class="event-avatar">
              <a [routerLink]="[linkParams(event).encodedUrl]">
                <img class="event-avatar-img"
                     [src]="event.file ? images_base_url + '/gallery/mobile' + event.file: eventDefaultImg">
              </a>
						</div>
					</div>
					<div class="event-ext">
            <div class="event-title" *ngIf="isMobile">{{event.title}}</div>
            <p class="event-id" *ngIf="isMobile">id: <span>{{event.event_id}}</span></p>
						<div class="event-producer">
							<div class="address">
								<i class="marker-size fa fa-map-marker" aria-hidden="true"></i>
								<div class="address-event">{{event?.address}}</div>
							</div>
							<div class="dates-event">
								<div class="date-event">
									{{event.start_date | date : 'fullDate'}} - {{event.start_date | date : 'shortTime'}}
								</div>
<!--								<div class="time-event">-->
<!--									{{event.end_date | date: 'fullDate'}} - {{event.end_date | date : 'shortTime'}}-->
<!--								</div>-->
							</div>
<!--							<div class="viev-event">-->
<!--								<a i18n="see event" class="event-link" *ngIf="event.status == 1 || event.status == 3 || event.status == 4"-->
<!--									[routerLink]="['/'+event.encodedUrl]">צפיה באירוע</a>-->
<!--							</div>-->
			<app-event-counters [views]="event.views" [sales]="event.sales" [approved]="event.approved" [pending]="event.pending"
				[eventID]="event.event_id" [currency]="event.currency"></app-event-counters>
						</div>
					</div>
				</div>
				<!-- <div class="row">
					<div class="col-md-10 col-xs-10">
						<h3>{{event.title}}</h3>
						<div *ngIf="event" class="event_details">
							<p class="mb0">{{event.end_date}} - {{event.start_date}} |
								<span i18n="dashboard | event status">
									{
									event.privacy, select,
									0 {ציבורי}
									1 {פרטי}
									}
								</span>
								|<span i18n="dashboard | manager"> מנהל </span></p>
							<a i18n="see event" *ngIf="event.status == 1 || event.status == 3 || event.status == 4"
								[routerLink]="['/'+event.encodedUrl]">צפיה באירוע</a>
						</div>
					</div>
					<div class="col-md-2 col-xs-2">
						<div *ngIf="event.agt_status == 1 || (event.agt_status == 2 && event.status != 1)"
							class="text-center">
							<div *ngIf="event.status == 1" (click)="toggleShare(event.event_id)"
								class="sharer fa-position">
								<img src="/assets/images/Share.png">
							</div>
						</div>
						<div *ngIf="event.agt_status == 2 && event.status == 1" class="text-center">
							<div *ngIf="event.status == 1" (click)="toggleShare(event.event_id)"
								class="sharer fa-position">
								<img src="/assets/images/Share.png">
							</div>
						</div>
					</div>
				</div> -->
				<div class="share-section">
					<div [@slideIn]
						*ngIf="event.status == 1 && toggleShareObj.hasOwnProperty(event.event_id) && toggleShareObj[event.event_id]"
						class="col-md-12 col-xs-12 pl0 pr0">
						<div class="shareOptions">
							<div class="text-center">
                <p i18n="@@Dashboardshareandinvitefriends" class="shareTitle">Share and invite friends:</p>
							</div>
							<div class="text-center">
								<img class="img-divider" src="/assets/images/share_divider.png">
							</div>
							<div class="col-sm-12 col-xs-12 shareIcons">
								<div class="col-xs-2 col-centered">
									<a i18n-href="@@Dashboardsharebyemaillink"
                     i18n-title="@@Dashboardsharebyemail" title="Share by email"
                     href="mailto:?subject=Check out this link&amp;body=Take a look at this website, it's worth checking out {{event.encodedShareUrl}}">
										<img class="img-responsive" src="/assets/images/email.png">
									</a>
								</div>
								<div class="col-xs-2 col-centered">
									<a i18n-title="@@Dashboardsharebyfacebook" target="_blank" title="Share on Facebook"
										href="https://www.facebook.com/sharer/sharer.php?u={{event.encodedShareUrl}}">
										<img class="img-responsive" src="/assets/images/facebook.png">
									</a>
								</div>
								<div class="col-xs-2 col-centered">
                  <a i18n-title="@@Dashboardsharebywhatsapp" [href]="(event.whatsapp_link)" target="_blank"
                     data-action="share/whatsapp/share" data-title="Worth checking!"
                     title="Share by WhatsApp">
										<img class="img-responsive" src="/assets/images/whatsApp.png">
									</a>
								</div>
								<div class="col-xs-2 col-centered">
									<a i18n-title="@@CopyLink" href="javascript:void(0)" title="Copy link"
										(click)="copyUrl(api_base_url + '/' + localeId + '/' + (event.url_friendly ? event.url_friendly : event.event_id) + '?seller_code=' + event.code)">
										<img class="img-responsive" src="/assets/images/Copy.png">
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="head_seller_events?.length > 0" class="head_seller_events">
			<div id="{{event.event_id}}" class="dashboard-event"
				*ngFor="let event of head_seller_events | search_params: filterText">
				<!-- <div class="row">
					<div class="col-md-10 col-xs-10 event_status">
						<div *ngIf="event.agt_status == 1 || event.status != 1" class="event_status">
							<span i18n="dashboard | active" *ngIf="event.status == 1 && eventsStatus != 'ended'"
								class="green"><i class="fa fa-circle"></i> פעיל</span>
							<span i18n="dashboard | editing" *ngIf="event.status == 2" class="orange"><i
									class="fa fa-circle"></i> בעריכה</span>
							<span i18n="dashboard | pending" *ngIf="event.status == 3" class="blue"><i
									class="fa fa-circle"></i> ממתין לאישור</span>
							<span i18n="dashboard | closed" *ngIf="event.status == 4" class="black"><i
									class="fa fa-circle"></i> סגור</span>
							<span i18n="dashboard | deleted" *ngIf="event.status == 5" class="gray"><i
									class="fa fa-circle"></i> מחוק / מבוטל</span>
							<span i18n="dashboard | ended" *ngIf="event.status == 1 && eventsStatus == 'ended'"
								class="black"><i class="fa fa-circle"></i> הסתיים</span>
						</div>
						<div *ngIf="event.agt_status == 2 && event.status == 1" class="agt_status">
							<img class="img-responsive" src="/assets/images/dashboard/Bell.png">
							<p i18n="dashboard | wait for confirmation as head seller in event">ממתין לאישורך <span
									class="blue">כראש צוות</span> באירוע</p>
						</div>
					</div>
					<div class="col-md-2 col-xs-2">
						<div *ngIf="event.agt_status == 1 || (event.agt_status == 2 && event.status != 1)"
							class="text-center">
							<button (click)="openEventMenu(event)" class="event_options fa-position"
								*ngIf="event.status != 3 && event.status != 6">
								<span i18n-title="more options" title="אפשרויות נוספות"><i
										class="fa fa-angle-down"></i></span>
							</button>
						</div>
						<div *ngIf="event.agt_status == 2 && event.status == 1" (click)="openAgentModal(event)"
							class="text-center">
							<button class="agt_status_btn fa-position" data-toggle="modal"
								data-target="#open_agent_status">
								<span i18n-title="more options" title="אפשרויות נוספות"><i
										class="fa fa-angle-down"></i></span>
							</button>
						</div>
					</div>
				</div> -->
				<div *ngIf="event.agt_status == 2 && event.status == 1" class="agt_status">
          <span i18n="@@DashboardWaitForConfirmationasseller">Pending your approval as seller</span>
				</div>
				<div class="title-section">
					<div class="event-title" *ngIf="!isMobile">{{event.title}}</div>
          <p class="event-id" *ngIf="!isMobile">id: <span>{{event.event_id}}</span></p>
          <div class="producer-share">
            <div class="uploads" *ngIf="(event.agt_status == 2 || event.agt_status == 1) && event.status == 1"
                 (click)="toggleShare(event.event_id)">
              <img class="share" src="/assets/images/svg-icons/share.svg" />
            </div>
          </div>
					<div *ngIf="event.agt_status == 2 && event.status == 1" class="text-center">
						<div i18n-title="@@AdditionalOptions" title="Additional Options" *ngIf="event.status != 3 && event.status != 6"
							class="main-menu" (click)="openAgentModal(event)">
						</div>
					</div>
					<div *ngIf="event.agt_status == 1 || (event.agt_status == 2 && event.status != 1)" class="text-center">
						<div i18n-title="@@AdditionalOptions" title="Additional Options" *ngIf="event.status != 3 && event.status != 6"
							class="main-menu" (click)="openEventMenu(event)">
						</div>
					</div>
				</div>

				<!-- <div class="row">
					<div class="col-md-10 col-xs-10">
						<h3>{{event.title}}</h3>
						<div *ngIf="event" class="event_details">
							<p class="mb0">{{event.end_date}} - {{event.start_date}} |
								<span i18n="dashboard | event status">
									{
									event.privacy, select,
									0 {ציבורי}
									1 {פרטי}
									}
								</span>
								|<span i18n="dashboard | head seller"> ראש צוות </span></p>
							<a i18n="see event" *ngIf="event.status == 1 || event.status == 3 || event.status == 4"
								[routerLink]="['/'+event.encodedUrl]">צפיה באירוע</a>
						</div>
					</div>
				</div> -->

				<div class="main-section">
					<div class="event-main">
						<div class="event-avatar">
              <a [routerLink]="[linkParams(event).encodedUrl]">
                <img class="event-avatar-img"
                     [src]="event.file ? images_base_url + '/gallery/mobile' + event.file: eventDefaultImg">
              </a>
						</div>
					</div>
					<div class="event-ext">
            <div class="event-title" *ngIf="isMobile">{{event.title}}</div>
            <p class="event-id" *ngIf="isMobile">id: <span>{{event.event_id}}</span></p>
						<div class="event-producer">
							<div class="address">
								<i class="marker-size fa fa-map-marker" aria-hidden="true"></i>
								<div class="address-event">{{event?.address}}</div>
							</div>
							<div class="dates-event">
								<div class="date-event">
									{{event.start_date | date : 'fullDate'}} - {{event.start_date | date : 'shortTime'}}
								</div>
<!--								<div class="time-event">-->
<!--									{{event.end_date | date: 'fullDate'}} - {{event.end_date | date : 'shortTime'}}-->
<!--								</div>-->
							</div>
<!--							<div class="viev-event">-->
<!--								<a i18n="see event" class="event-link"-->
<!--									*ngIf="event.status == 1 || event.status == 3 || event.status == 4"-->
<!--									[routerLink]="['/'+event.encodedUrl]">צפיה באירוע</a>-->
<!--							</div>-->
			<app-event-counters [views]="event.views" [sales]="event.sales" [approved]="event.approved" [pending]="event.pending"
				[eventID]="event.event_id" [currency]="event.currency"></app-event-counters>
						</div>
					</div>
				</div>

				<div class="share-section">
					<div [@slideIn]
						*ngIf="event.status == 1 && toggleShareObj.hasOwnProperty(event.event_id) && toggleShareObj[event.event_id]"
						class="col-md-12 col-xs-12 pl0 pr0">
						<div class="shareOptions">
							<div class="text-center">
                <p i18n="@@Dashboardshareandinvitefriends" class="shareTitle">Share and invite friends:</p>
							</div>
							<div class="text-center">
								<img class="img-divider" src="/assets/images/share_divider.png">
							</div>
							<div class="col-sm-12 col-xs-12 shareIcons">
                <div class="col-xs-2 col-centered">
                  <a i18n-href="@@Dashboardsharebyemaillink"
                     i18n-title="@@Dashboardsharebyemail" title="Share by email"
                     href="mailto:?subject=Check out this link&amp;body=Take a look at this website, it's worth checking out {{event.encodedShareUrl}}">
                    <img class="img-responsive" src="/assets/images/email.png">
                  </a>
                </div>
                <div class="col-xs-2 col-centered">
                  <a i18n-title="@@Dashboardsharebyfacebook" target="_blank" title="Share on Facebook"
                     href="https://www.facebook.com/sharer/sharer.php?u={{event.encodedShareUrl}}">
                    <img class="img-responsive" src="/assets/images/facebook.png">
                  </a>
                </div>
                <div class="col-xs-2 col-centered">
                  <a i18n-title="@@Dashboardsharebywhatsapp" [href]="(event.whatsapp_link)" target="_blank"
                     data-action="share/whatsapp/share" data-title="Worth checking!"
                     title="Share by WhatsApp">
                    <img class="img-responsive" src="/assets/images/whatsApp.png">
                  </a>
                </div>
                <div class="col-xs-2 col-centered">
                  <a i18n-title="@@CopyLink" href="javascript:void(0)" title="Copy link"
                     (click)="copyUrl(api_base_url + '/' + localeId + '/' + (event.url_friendly ? event.url_friendly : event.event_id) + '?seller_code=' + event.code)">
                    <img class="img-responsive" src="/assets/images/Copy.png">
                  </a>
                </div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<div class="event-not-found" i18n="@@DashboardNoEvents" *ngIf="noEvents">No events matching the filter were found</div>
    <div [attr.data-cy]="'createEventButton'" class="create-event" (click)="createEvent()">
      <div i18n="create new event" class="new-event">Create new event</div>
    </div>
	</div>
</section>


<!-- event_options modal -->
<div *ngIf="eventOptionModal" [@fade] id="open_event_options" class="modal-options show" role="dialog"
	data-backdrop="false">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<div class="event-back-arrow">
					<div class="close-dialog" (click)="closeEventMenu()">
						<svg class="arrow-close-modal" width="10" height="14" viewBox="0 0 10 14" fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								d="M9.49219 1.64063L4.13281 7L9.49219 12.3594L7.85156 14L0.851562 7L7.85156 0L9.49219 1.64063Z"
								fill="white" />
						</svg>
					</div>
				</div>
				<!-- <button type="button" class="close" (click)="closeEventMenu()">
				<span aria-hidden="true">&times;</span>
			</button> -->
			</div>
			<div class="modal-body">
				<div class="event-main">
					<div class="menu-avatar">
						<img class="menu-avatar-img" [src]="currentOptionEvent?.file ? images_base_url + '/gallery/mobile' + currentOptionEvent.file: eventDefaultImg"
                 [ngStyle]="{'border-radius': currentOptionEvent?.file ? '93px' : '0'}">
					</div>
				</div>
				<div class="manage_event">
<!--					<div class="row vertical-align mb15 mt10 menu-line" [routerLink]="['/producer/' + user_id]">-->
<!--						<div class="col-md-9 col-xs-9">-->
<!--							<span i18n="my feed | button text" class="menu-text">הפיד שלי</span>-->
<!--						</div>-->
<!--					</div>-->
					<div *ngIf="(!currentOptionEvent.type || currentOptionEvent.type === 3) && currentOptionEvent.status !== 4 && currentOptionEvent.event_editable"
						[routerLink]="['/edit-event', currentOptionEvent.event_id]"
						class="row vertical-align mb15 mt10 menu-line">
						<!-- <div class="col-md-3 col-xs-3 text-center">
							<img class="img-responsive ml0" src="/assets/images/dashboard/Edit.png">
						</div> -->
						<div class="col-md-9 col-xs-9">
							<span i18n="@@DashboardEditEvent" [attr.data-cy]="'editEventButton'" class="menu-text">Edit event</span>
							<!-- <span i18n="dashboard | edit your event" class="disabled">ערוך את האירוע שלך</span> -->
						</div>
					</div>
					<!-- <div (click)="openEventDetailsModal(currentOptionEvent)"
						class="row vertical-align mb15 mt10 menu-line">
						<div class="col-md-3 col-xs-3 text-center">
								<img class="img-responsive ml0" src="/assets/images/dashboard/eye.png">
							</div>
						<div class="col-md-9 col-xs-9">
							<span i18n="dashboard | event details" class="menu-text">פרטי אירוע</span>
							<span i18n="dashboard | see summary event details" class="disabled">צפה בסיכום פרטי האירוע</span>
						</div>
					</div> -->
					<div [routerLink]="['/analytics/dashboard', currentOptionEvent.event_id]" [queryParams]="{type: 'sales'}"
						class="row vertical-align mb15 mt10 menu-line">
						<!-- <div class="col-md-3 col-xs-3 text-center">
							<img class="img-responsive ml0" src="/assets/images/dashboard/Graph.png">
						</div> -->
						<div class="col-md-9 col-xs-9">
							<span i18n="dashboard | Analytics" [attr.data-cy]="'eventAnalyticsButton'" class="menu-text">Analytics</span>
							<!-- <span i18n="dashboard | analyse event parformance stats" class="disabled">ניתוח סטטיסטי של ביצועי האירוע</span> -->
						</div>
					</div>
					<div [routerLink]="['/reports', currentOptionEvent.event_id]"
						class="row vertical-align mb15 mt10 menu-line reports" *ngIf="user_id !== 209397">
						<!-- <div class="col-md-3 col-xs-3 text-center">
								<img class="img-responsive ml0" src="/assets/images/dashboard/report.png">
							</div> -->
						<div class="col-md-9 col-xs-9">
							<span i18n="@@Reports" [attr.data-cy]="'eventReportsButton'" class="menu-text">Reports</span>
							<!-- <span i18n="dashboard | download reports" class="disabled">הורדת בדוחות האירוע</span> -->
						</div>
					</div>
					<div [routerLink]="['/event-participants', currentOptionEvent.event_id,'all']"
						class="row vertical-align mb15 mt10 menu-line">
						<!-- <div class="col-md-3 col-xs-3 text-center">
							<img class="img-responsive ml0" src="/assets/images/dashboard/User.png">
						</div> -->
						<div class="col-md-9 col-xs-9">
							<span i18n="@@Attendees" [attr.data-cy]="'eventAttendeesButton'" class="menu-text">Attendees</span>
							<!-- <span i18n="dashboard | participants details list" class="disabled">רשימת פרטי משתתפי האירוע</span> -->
						</div>
					</div>
					<div *ngIf="!currentOptionEvent.type || currentOptionEvent.type === 3 || currentOptionEvent.type === 2"
						[routerLink]="['/event-salesmen', currentOptionEvent.event_id]"
						class="row vertical-align mb15 mt10 menu-line">
						<!-- <div class="col-md-3 col-xs-3 text-center">
							<img class="img-responsive ml0" src="/assets/images/dashboard/bag.png">
						</div> -->
						<div class="col-md-9 col-xs-9">
							<span i18n="@@Salespeople" [attr.data-cy]="'eventSalespeopleButton'" class="menu-text">Salespeople</span>
							<!-- <span i18n="dashboard | event sellers management" class="disabled">ניהול אנשי המכירות של האירוע</span> -->
						</div>
					</div>
					<!-- <div class="col-md-12 col-xs-12 vertical-align mb10 mt10 action event_newsletters">
						<div class="col-md-3 col-xs-3 pull-right text-center">
							<img class="img-responsive ml0" src="/assets/images/dashboard/mail.png">
						</div>
						<div class="col-md-9 col-xs-9">
							<span>דיוורים</span>
							<span class="disabled">ניהול ההודעות למשתתפים</span>
						</div>
					</div> -->
					<div *ngIf="(!currentOptionEvent.type || currentOptionEvent.type === 3) && !currentOptionEvent.registration_mode"
						[routerLink]="['/coupons/' + currentOptionEvent.event_id]"
						class="row vertical-align mb10 mt10 menu-line">
						<!-- <div class="col-md-3 col-xs-3 text-center">
              <img class="img-responsive ml0" src="/assets/images/dashboard/ticket.png">
            </div> -->
						<div class="col-md-9 col-xs-9">
							<span i18n="@@Coupons" [attr.data-cy]="'eventCouponButton'" class="menu-text">Coupons</span>
							<!-- <span i18n="dashboard | event coupons manage" class="disabled">ניהול הקופונים באירוע</span> -->
						</div>
					</div>

					<div *ngIf="!currentOptionEvent.type || currentOptionEvent.type === 3"
						[routerLink]="['/sales-promotion/' + currentOptionEvent.event_id]"
						class="row vertical-align mb10 mt10 menu-line">
						<!-- <div class="col-md-3 col-xs-3 text-center">
              <img class="img-responsive ml0" src="/assets/images/dashboard/Copy.png">
            </div> -->
						<div class="col-md-9 col-xs-9">
							<span i18n="@@SalesPromotion" class="menu-text">Sales promotion</span>
							<!-- <span i18n="dashboard | event promotions manage" class="disabled">ניהול הקידום מכירות באירוע</span> -->
						</div>
					</div>

<!--          <div *ngIf="!currentOptionEvent.type || currentOptionEvent.type === 3"-->
          <div *ngIf="user_details.type === 2"
               [routerLink]="['/send-tickets/' + currentOptionEvent.event_id]"
               class="row vertical-align mb10 mt10 menu-line">
            <div class="col-md-9 col-xs-9">
              <span i18n="dashboard | send-tickets" class="menu-text">Send Tickets</span>
            </div>
          </div>

<!--					<div *ngIf="(!currentOptionEvent.type || currentOptionEvent.type === 3) && !currentOptionEvent.registration_mode"-->
<!--						[routerLink]="['/bank-account/' + currentOptionEvent.event_id]"-->
<!--						class="row vertical-align mb10 mt10 menu-line">-->
<!--						&lt;!&ndash; <div class="col-md-3 col-xs-3 text-center">-->
<!--              <img class="img-responsive ml0" src="/assets/images/dashboard/Cash.png">-->
<!--            </div> &ndash;&gt;-->
<!--						<div class="col-md-9 col-xs-9">-->
<!--							<span i18n="dashboard | bank" class="menu-text">פרטי חשבון בנק</span>-->
<!--							&lt;!&ndash; <span i18n="dashboard | event bank manage" class="disabled">ניהול פרטי חשבון הבנק באירוע</span> &ndash;&gt;-->
<!--						</div>-->
<!--					</div>-->

					<div *ngIf="!currentOptionEvent.type || currentOptionEvent.type === 3"
						[routerLink]="['/reader-signup', currentOptionEvent.event_id]"
						class="row vertical-align mb10 mt10 menu-line">
						<!-- <div class="col-md-3 col-xs-3 text-center">
							<img class="img-responsive ml0" src="/assets/images/dashboard/Scan.png">
						</div> -->
						<div class="col-md-9 col-xs-9">
							<span i18n="@@ScannerManagement" [attr.data-cy]="'eventAcannerManagementButton'" class="menu-text">Scanner management</span>
							<!-- <span i18n="dashboard | open new scanning stand" class="disabled">פתיחת עמדות סריקה חדשות וניהולן</span> -->
						</div>
					</div>
					<div *ngIf="currentOptionEvent.status !== 4"
						[routerLink]="(currentOptionEvent.salesman_id) ? ['/event-payment', currentOptionEvent.event_id, currentOptionEvent.salesman_id] : ['/event-payment', currentOptionEvent.event_id]"
						class="row vertical-align mb10 mt10 menu-line">
						<!-- <div class="col-md-3 col-xs-3 text-center">
							<img class="img-responsive ml0" src="/assets/images/dashboard/Pig.png">
						</div> -->
						<div class="col-md-9 col-xs-9">
							<span i18n="@@Checkout" [attr.data-cy]="'eventCheckoutButton'" class="menu-text">Checkout</span>
							<!-- <span i18n="dashboard | cashier see amount" class="disabled">צפיה בסכום שנצבר ומשיכה מהיתרה</span> -->
						</div>
					</div>

          <div class="row vertical-align mb10 mt10 menu-line" *ngIf="(!currentOptionEvent.type || currentOptionEvent.type === 3) && currentOptionEvent.status !== 4 && currentOptionEvent.stripe_connect_account_flow">
            <div class="col-md-9 col-xs-9" (click)="copyStripeConnectAccount(currentOptionEvent.event_id)">
              <span i18n="dashboard | stripe connect accout menu" class="menu-text">Stripe Connect Account</span>
            </div>
          </div>
          <div *ngIf="user_details.type === 2 && (!currentOptionEvent.type || currentOptionEvent.type === 3) && currentOptionEvent.status !== 4 && currentOptionEvent.status !== 5"
               class="row vertical-align mb10 mt10 menu-line" [routerLink]="['/cms/seats-config/' + currentOptionEvent.event_id]">
            <div class="col-md-9 col-xs-9">
              <span i18n="dashboard | sets config menu" class="menu-text">Seats Config</span>
            </div>
          </div>
					<div (click)="removeEvent(currentOptionEvent)"
						*ngIf="(!currentOptionEvent.type || currentOptionEvent.type === 3) && currentOptionEvent.status !== 4 && currentOptionEvent.status !== 5"
						class="row vertical-align mb10 mt10 menu-line">
						<!-- <div class="col-md-3 col-xs-3 text-center">
							<img class="img-responsive ml0" src="/assets/images/dashboard/Delete.png">
						</div> -->
						<div class="col-md-9 col-xs-9">
							<span i18n="@@DeleteEvent" [attr.data-cy]="'eventDeleteButton'" class="menu-text">Delete event</span>
							<!-- <span i18n="dashboard | event will be deleted from events list" class="disabled">האירוע יימחק מרשימת האירועים</span> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<!-- open_agent_status modal:  קבל הזמנה  ,  דחה הזמנה -->
<div *ngIf="salesmanAgentModal" [@fade] id="open_agent_status" class="modal-options show" role="dialog"
	data-backdrop="false">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<div class="event-back-arrow">
					<div class="close-dialog" (click)="closeAgentModal()">
						<svg class="arrow-close-modal" width="10" height="14" viewBox="0 0 10 14" fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path d="M9.49219 1.64063L4.13281 7L9.49219 12.3594L7.85156 14L0.851562 7L7.85156 0L9.49219 1.64063Z"
								fill="white" />
						</svg>
					</div>
				</div>
			</div>
				<div class="modal-body">
					<div class="event-main">
						<div class="menu-avatar">
							<img class="menu-avatar-img"
								[src]="currentAgentEvent?.file ? images_base_url + '/gallery/mobile' + currentAgentEvent.file: eventDefaultImg"
                [ngStyle]="{'border-radius': currentAgentEvent?.file ? '93px' : '0'}">
						</div>
					</div>
					<div class="manage_event">
						<div (click)="updateAgentStatus(currentAgentEvent, 'approve')" class="row vertical-align mb15 mt10 menu-line">
							<div class="col-md-9 col-xs-9">
								<span i18n="dashboard | event details" class="menu-text">Accept invitation</span>
							</div>
						</div>
						<div (click)="updateAgentStatus(currentAgentEvent, 'decline')" class="row vertical-align mb15 mt10 menu-line">
							<div class="col-md-9 col-xs-9">
								<span i18n="dashboard | event details" class="menu-text">Decline invitation</span>
							</div>
						</div>
					</div>
				</div>
		</div>
	</div>
</div>

<!-- open_agent_status modal -->
<!-- <div *ngIf="salesmanAgentModal" id="open_agent_status" class="modal show" data-backdrop="false">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<button (click)="closeAgentModal()" type="button" class="close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body col-md-12 col-xs-12 pl0 pr0">
				<div class="manage_event">
					<div (click)="updateAgentStatus(currentAgentEvent, 'approve')"
						class="col-md-12 col-xs-12 vertical-align mb10 mt10 action approved_agent">
						<div class="col-md-3 col-xs-3 pull-right text-center">
							<img class="img-responsive ml0" src="/assets/images/dashboard/Check.png">
						</div>
						<div class="col-md-9 col-xs-9 pl0 pr0">
							<p i18n="dashboard | accept invitation event" class="mb0">קבל הזמנה <span
									class="agent_type"></span> באירוע</p>
							<span i18n="dashboard | accept event permission" class="disabled">אישור קבלת הרשאה <span
									class="agent_type"></span> באירוע</span>
						</div>
					</div>
					<div (click)="updateAgentStatus(currentAgentEvent, 'decline')"
						class="col-md-12 col-xs-12 vertical-align mb10 mt10 action declined_agent">
						<div class="col-md-3 col-xs-3 pull-right text-center">
							<img class="img-responsive ml0" src="/assets/images/dashboard/Cancel.png">
						</div>
						<div class="col-md-9 col-xs-9 pl0 pr0">
							<p i18n="dashboard | decline invitation event" class="mb0">דחה הזמנה <span
									class="agent_type"></span> באירוע</p>
							<span i18n="dashboard | event will be removed from events list" class="disabled">דחיית
								ההזמנה תסיר את האירוע מהרשימות שלך</span>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</div> -->
