import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {EventService} from "../../../services/event.service";
import {DialogService} from "../../../services/dialog.service";
import {GlobalsService} from "../../../globals";
import {EventCacheService} from "../../../services-cache/event-cache.service";
import {CurrencyLocaleService} from "../../../services/currency-locale.service";

@Component({
  selector: 'app-approve-waiting-list-modal',
  templateUrl: './approve-waiting-list-modal.component.html',
  styleUrls: ['./approve-waiting-list-modal.component.scss']
})
export class ApproveWaitingListModalComponent implements OnInit{
  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private fb: UntypedFormBuilder,
              private dialogRef: MatDialogRef<ApproveWaitingListModalComponent>,
              private eventService: EventService,
              private eventCacheService: EventCacheService,
              private dialogService: DialogService,
              private globals: GlobalsService,
              private currencyLocale: CurrencyLocaleService) {
  }
  form = this.fb.group({
    "count": 0,
  });
  fetching: boolean = false;
  ngOnInit() {
    // Update Validator max
     this.form.get('count').setValidators([Validators.required,Validators.min( 1), Validators.max(this.data.count_ticket_waiting_list < 400 ? this.data.count_ticket_waiting_list : 400)]);
  }

  approve() {
    // console.log(this.data.event_id, this.data.ticket_id, this.form.get('count').value);
    if (!this.form.valid || !this.data.event_id || !this.data.ticket_id) {return};
    this.fetching = true;
    this.globals.startLoader();
    this.eventService.approveWaitingList(this.data.event_id, this.data.ticket_id, this.form.get('count').value).subscribe((res: any) => {
      this.eventCacheService.clearCacheByHash({event_id: this.data.event_id, type: 'tickets'}); // clear cache

      if (res.code && res.code == 'success' && res.data) {
        if(res.data.count_tickets == this.form.get('count').value) {
          this.dialogService.alertsModal('success', 'success', 3000, true);
          this.dialogRef.close('tickets'); // Close modal and emit onApprovedWaitingList
        }
        if(res.data.count_tickets < this.form.get('count').value) {
          this.dialogService.alertsModal('waitingListApprove', '1', 4000, true);
          this.dialogRef.close('tickets'); // Close modal and emit onApprovedWaitingList
        }

      }
      this.fetching = false;
      this.globals.stopLoader();

    }, error => {
      this.eventCacheService.clearCacheByHash({event_id: this.data.event_id, type: 'tickets'}); // clear cache
      if (error.code) {
        let code = 4.1;
        if (error.code == 1) {code = 1}
        if ((error.code == 2)||(error.code == 3)) {code = 2}
        if (error.code == 4) {code = 4}
        if (error.code == 5) {code = 5}
        if (error.code == 6) {code = 6}
        if (error.code == 7) {code = 7}
        this.dialogService.alertsModal('waitingListApprove', code, 3000, false);
      }
      this.fetching = false;
      this.globals.stopLoader();

    })

  }

  getLocale(currency) {
    return this.currencyLocale.getLocaleForCurrencyPipe(currency)
  }

}
