import {ChangeDetectorRef, Component, Input, AfterViewInit, OnChanges, HostListener, ViewChild, OnInit, Output, EventEmitter} from '@angular/core';
import { EventService } from '../../services/event.service';
import { NavbarService } from '../../services/navbar.service';
import {environment} from '../../../environments/environment';
import {GlobalsService} from '../../globals';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-events-carousel',
  templateUrl: './events-carousel.component.html',
  styleUrls: ['./events-carousel.component.css']
})
export class EventsCarouselComponent implements AfterViewInit, OnChanges, OnInit {
  @Input() set carouseldata(data) {
    this.carouselData = [];
    this.incomingCarouselData = JSON.stringify(data);
    this.carouselData = data;
  };
  @Input() showProducer = true;
  @Input() userId: any = null;
  @Input() isSearch = false;
  @Input() isMobile = false;
  @Input() page: string;
  @Output() updFolowers: EventEmitter<any> = new EventEmitter();

  public carouselData: any = [];
  public baseProducerImgUrl = environment.images_base_url + '/profile/';
  public baseEventImgUrl = environment.images_base_url + '/gallery/mobile';
  public getin_base_url = environment.getin_base_url;
  public dir: string;
  public carouselWidth: number;
  private incomingCarouselData: any;
  private isSetIncoming = false;
  private isDown = false;
  private startX;
  private scrollLeft;
  public type: number|null;
  public limit_end_m = 4; // 4 items on mobile
  public limit_end_d: number; // items on desktop
  public event_width_d = 310 + 20; // event element width on desktop + margin
  public viewportVisibleEvents: number;

  @ViewChild('carousel') slider;

  @HostListener('mousedown', ['$event']) onCarouselMouseDown(e) {
    this.isDown = true;
    if (e.target && (e.target.nodeName === 'IMG')) {
      e.preventDefault();
    }
    this.startX = e.pageX - this.slider.nativeElement.offsetLeft;
    this.scrollLeft = this.slider.nativeElement.scrollLeft;
  }

  @HostListener('mousemove', ['$event']) onCarouselMouseMove(e) {
    if (!this.isDown) return;

    e.preventDefault();
    const x = e.pageX - this.slider.nativeElement.offsetLeft;
    const walk = (x - this.startX) * 2; //scroll-fast
    if (!this.slider.nativeElement.classList.contains('dragging') && Math.abs(this.startX - e.pageX) > 10) {
      this.slider.nativeElement.classList.add('dragging');
    }
    this.slider.nativeElement.scrollLeft = this.scrollLeft - walk;
  }

  // For Tablets and sensor devices
  @HostListener('touchmove', ['$event'])
  handleTouch(event) {
    this.limit_end_d =  this.limit_end_d + this.viewportVisibleEvents;
  }

  @HostListener('mouseleave', ['$event']) onCarouselMouseLeave(e) {
    this.isDown = false;
    this.slider.nativeElement.classList.remove('dragging');
  }

  @HostListener('mouseup', ['$event']) onCarouselMouseUp(e) {
    this.isDown = false;
    this.slider.nativeElement.classList.remove('dragging');
    this.desktopScroll(); // add new items on the end of scrolling
  }

  @HostListener('window:resize', ['$event']) onResizeWindow(event) {
    this.carouselWidth = document.body.clientWidth;
    this.setCarouselData();
    this.setCarouselOffset();
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private globals: GlobalsService,
    private dialogService: DialogService,
    private eventService: EventService,
    private nav: NavbarService
  ) {
    this.dir = this.globals.getDir();
    //this.carouselWidth = document.getElementById('master-container').scrollWidth - 30;
    this.carouselWidth = document.body.clientWidth;
    this.nav.currentUserType.subscribe(type => this.type = +type);
  }


  get carouseldata() {
    return this.carouselData;
  }

  ngOnInit() {
    if (this.isMobile) {
      window.addEventListener('scroll', this.mobileScroll, true);
    }
    this.viewportVisibleEvents = Math.ceil(window.innerWidth / this.event_width_d);
    this.limit_end_d = this.viewportVisibleEvents + (this.viewportVisibleEvents - 1); // show only items which can be visible in viewport + several buffer events
    if (this.page === 'producer') {
      this.limit_end_m = 15; // We have another card view on producer page
      //this.limit_end_d = this.carouselData.length || 500; // On producer personal doesn't set events limitations
    }
  }

  ngOnDestroy() {
    if (this.isMobile) {
      window.removeEventListener('scroll', this.mobileScroll, true);
    }
  }

  ngAfterViewInit() {
    this.setCarouselOffset();
    this.cdr.detectChanges();
  }

  ngOnChanges() {
    if (!this.isSearch || !this.isMobile) {
      if (!this.isSetIncoming) {
        this.incomingCarouselData = JSON.stringify(this.carouselData);
        this.isSetIncoming = true;
      }
      this.setCarouselData();
    }
  }

  updateFolowers(producerAction) {
    // console.log('producerAction', this.userId, producerAction);
    this.updFolowers.emit(producerAction);
  }

  // *** Show more item on scroll on mobile ***
  mobileScroll = (event): void => {
    const scrollTop = event.target.scrollTop;
    const sliderHeight = this.slider.nativeElement.clientHeight;
    // const screenHeight = document.documentElement.scrollHeight;
    const currentScrollingPosition = Math.floor(scrollTop / sliderHeight * 100);
    // console.log('Scrolling', scrollTop, sliderHeight,  this.isMobile, currentScrollingPosition);

    // Show +3 more items if more that 70 percents of events were scrolled

    const maxScrollPos = this.page === 'producer' ? 15 : 70;
    if (currentScrollingPosition > maxScrollPos ) {
      this.limit_end_m = this.limit_end_m + 3;
    }
  };

  // *** Show more item on scroll on desktop ***
  desktopScroll() {
    const scrolledEvents = Math.abs(Math.ceil(this.slider.nativeElement.scrollLeft / this.event_width_d));
    const partOfRenderedEvents = Math.floor(this.limit_end_d / 5);
    if ((scrolledEvents >= partOfRenderedEvents) && (this.limit_end_d <= this.carouselData.length)) {
      this.limit_end_d =  this.limit_end_d + this.viewportVisibleEvents;
      // this.limit_end_d =  this.viewportVisibleEvents + scrolledEvents; // add and delete items the same time
      // console.log(scrolledEvents, partOfRenderedEvents, this.limit_end_d, this.carouselData.length);
    }
  }

  removeEvent(event_id) {
    // change event setting private or public
    // console.log('removeEvent', event_id, this.carouselData);
    this.dialogService.areUSureModal('setPrivacyStatus', event_id).subscribe(
      result => {
        if (result) {
          this.globals.startLoader();
          this.eventService.setPrivacy(event_id).subscribe(
            (response: any) => {
              if (response.data) {
                const eventIndx = this.carouselData.findIndex(obj =>  obj.event_id === event_id);
                this.carouselData.splice(eventIndx, 1);
                this.globals.stopLoader();
                this.dialogService.alertsModal('success', 'success', 3000, true);
              } else {
                this.dialogService.alertsModal('errors','4.1', 3000, false);
              }
            },
            error => {
              this.globals.stopLoader();
              this.dialogService.alertsModal('errors', error.code, 5000, false);
            }
            );
          }
        }
      );
  }

  setCarouselOffset() {
    if (!this.isMobile) {
      this.slider.nativeElement.scrollLeft = this.dir === 'rtl' ? (this.slider.nativeElement.scrollWidth - this.carouselWidth - 105) : 105;
    }
  }

  setCarouselData() {
    const items = Math.floor((this.carouselWidth) / 330);
    const margins = items * 20;
    const show = (this.carouselWidth - margins) / 310;
    let arrayLength = Math.ceil(show);

    if (((this.carouselWidth - margins + 105) / 310) > arrayLength) {
      arrayLength += 1;
    }

    if (!this.isSearch && this.page !== 'producer') {
      this.carouselData = JSON.parse(this.incomingCarouselData);
      if (this.carouselData.length < arrayLength) {
        const defaultData = JSON.stringify(this.carouselData);
        const myData = [...this.carouselData];
        const fill = () => {
          JSON.parse(defaultData).forEach(el => {
            if (myData.length < arrayLength) {
              myData.push(el);
            }
          });
          if (myData.length < arrayLength) {
            fill();
          } else {
            this.carouselData = [...myData];
          }
        };
        fill();
      }
    }
  }
}
