import { DialogService } from '../../services/dialog.service';
import {Component, ViewChild, OnInit, OnDestroy} from '@angular/core';
import { GlobalsService } from '../../globals';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { phoneRegex } from '../../regex';
import { NgForm } from '@angular/forms';
import { slideIn } from '../../animation';
import { LocalizationService } from '../../services/localization.service';
import { NavbarService } from '../../services/navbar.service';
import {Meta, Title} from '@angular/platform-browser';
import {RudderstackService} from "../../services/rudderstack.service";


@Component({
  selector: 'app-reader-signin',
  templateUrl: './reader-signin.component.html',
	styleUrls: ['./reader-signin.component.css'],
	animations: [slideIn]
})
export class ReaderSigninComponent implements OnInit, OnDestroy {
	@ViewChild('signinForm') signinForm: NgForm;
	phoneRegex = phoneRegex;
	reader_id: String;
	date = new Date();
	event_id: Number;

  constructor(
		private authService: AuthService,
		private dialogService: DialogService,
		private router: Router,
		private globals: GlobalsService,
		private activatedRoute: ActivatedRoute,
		public localizationService: LocalizationService,
    public rudderstackService: RudderstackService,
		private navbar: NavbarService,
    private titleService: Title,
    private meta: Meta
	) {
		this.date.setDate(this.date.getDate() + 7);
		this.activatedRoute.queryParams.subscribe(
			(params: Params) => {
				this.event_id = +params['event_id'];
			}
		);
  	}
	ngOnInit() {
		this.navbar.setIsNewUi(true);
		setTimeout(() => {this.navbar.noPadding = 'removePadding';}, 200);
		this.navbar.hide();

    this.titleService.setTitle('Getin - Scanner');
    this.meta.updateTag({ name: 'description', content: 'Scanner login'});
    this.rudderstackService.page('Scanner page','Scanner login');
	}

	onSubmit() {
		this.globals.startLoader();
		if (this.signinForm.form.controls['phone'].value && !this.signinForm.form.controls['phoneCountryCode'].value) {
			const countryCode: any = this.localizationService.getPhoneInputOptions();
			this.signinForm.form.controls['phoneCountryCode'].setValue(countryCode.initialCountry);
		}
		this.authService.readerSignin(this.signinForm.value)
		.subscribe(
		    (response: any) => {
					this.globals.stopLoader();
					this.reader_id = response.data;
					this.authService.storeReaderApiKeyAndId(response.apiKey, response.data)
					this.router.navigate(['/reader/' + this.reader_id]);
		    },
		    error => {
					this.globals.stopLoader();
					this.dialogService.alertsModal((error.code) ? 'reader' :'errors', (error.code) ? error.code : 4 , 5000, false, error.log);
				}
		);
	}

	setErrorOnphoneNumber(hasErrorfromNg2TelInput) {
		const hasError = this.globals.hasErrorOnPhoneNumber(hasErrorfromNg2TelInput, this.signinForm.form.controls['phone'].value);
		const actionOnSetErrors = hasError ? {'invalid': true} : null;
		this.signinForm.form.controls['phone'].setErrors(actionOnSetErrors);
	}
	setPhoneNumber(phoneNumber) {
		this.signinForm.form.controls['phone'].patchValue(phoneNumber);
	}
	telInputObject(obj, userPhone) {
		if (userPhone) {
			obj.setNumber(userPhone);
		}
	}
	onCountryChange(obj) {
		if (this.signinForm.form.controls['phoneCountryCode']) {
			this.signinForm.form.controls['phoneCountryCode'].setValue(obj.iso2);
		}
	}

  ngOnDestroy() {
    this.titleService.setTitle('Getin');
  }
}
