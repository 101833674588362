<ng-container [ngSwitch]="isMobile">
<!--  MOBILE-->
  <ng-container *ngSwitchDefault>
    <div class="sidenav-user" *ngIf="user.user_id">
      <div>
        <img *ngIf="userImg" [src]="userImg" alt="logo">
        <div *ngIf="!userImg" class="no-user-photo">
          <svg [width]="isMobile?64:47" [height]="isMobile?64:47" [data-jdenticon-value]="user.user_id"></svg>
        </div>

      </div>
      <div>
        <p class="name" [attr.data-cy]="'sideNavUserFullName'">{{user.first_name}} {{user.last_name}}</p>
        <p class="type"></p>
      </div>
    </div>

    <div class="sidenav-no-user" *ngIf="!user.user_id">
      <div>
        <img [src]="'/assets/images/svg-icons/menu-profile.svg'" alt="logo">
      </div>
      <div>
        <button class="sidenav-login" [attr.data-cy]="'loginModalButton'" (click)="openLoginModal();close()"
          i18n="sidenav|Connect">
          Connect
        </button>
      </div>
    </div>

    <div class="sidenav-close" [ngClass]="direction" (click)="close()"><img src="/assets/images/svg-icons/close.svg" alt="close"></div>

    <div class="sidenav-cat" [ngClass]="{'guest': !user.user_id}">
      <div class="cat-name" [ngClass]="direction">
        <span i18n="sidenav|MENU">MENU</span>
        <div class="lng-sel">
          <app-language-select></app-language-select>
        </div>
      </div>

      <ul class="sidenav-menu">

        <!--        for Admins type === 2-->
        <ng-container *ngIf="user.type === 2 && user.user_id">
          <li [attr.data-cy]="'adminDashboardButton'" class="sidenav-menu-item cms">
            <a [routerLink]="['/cms/dashboard/pending']" (click)="close()" class="sidenav-menu-link" i18n="sidenav|Dashboard">
              Dashboard
            </a>
          </li>
          <li class="sidenav-menu-item cms">
            <a [routerLink]="['/cms/auto-approve-producer-list']" (click)="close()" class="sidenav-menu-link">
              {{'Auto Approve Producer List'|translate:'Auto Approve Producer List'}}
            </a>
          </li>
          <li class="sidenav-menu-item cms">
            <a [routerLink]="['/cms/phone-black-list']" (click)="close()" class="sidenav-menu-link" i18n="sidenav|Phone Blacklist">
              Phone Blacklist
            </a>
          </li>
          <li class="sidenav-menu-item cms">
            <a [routerLink]="['/cms/phone-country-white-list']" (click)="close()" class="sidenav-menu-link" i18n="sidenav|Phone Country Whitelist">
              Phone Country Whitelist
            </a>
          </li>
          <li class="sidenav-menu-item cms">
            <a [routerLink]="['/cms/email-black-list']" (click)="close()" class="sidenav-menu-link" i18n="sidenav|Email Blacklist">
              Email Blacklist
            </a>
          </li>
          <li class="sidenav-menu-item cms">
            <a [routerLink]="['/cms/currencies']" (click)="close()" href="#" class="sidenav-menu-link">
              {{'menu.Currencies'| translate: 'Currencies'}}
            </a>
          </li>
          <li class="sidenav-menu-item cms">
            <a [routerLink]="['/cms/fee-management-templates']" (click)="close()" class="sidenav-menu-link">
              {{'Fee Templates' | translate}}
            </a>
          </li>
          <li class="sidenav-menu-item cms">
            <a [routerLink]="['/cms/gateways-list']" (click)="close()" class="sidenav-menu-link" i18n="sidenav|Payment gateways">
              Payment gateways
            </a>
          </li>
          <li class="sidenav-menu-item cms">
            <a [routerLink]="['/cms/sms-providers-list']" (click)="close()" class="sidenav-menu-link" i18n="sidenav|Dashboard">
              SMS Providers
            </a>
          </li>
          <li class="sidenav-menu-item cms">
            <a [routerLink]="['/main-slider']" (click)="close()" class="sidenav-menu-link" i18n="sidenav|Sliders Management">
              Sliders Management
            </a>
          </li>
          <li class="sidenav-menu-item cms">
            <a [routerLink]="['/manage-categories']" (click)="close()" class="sidenav-menu-link" i18n="sidenav|Categories Management">
              Categories Management
            </a>
          </li>
          <li class="sidenav-menu-item cms">
            <a [routerLink]="['/cms/users/producers']" (click)="close()" class="sidenav-menu-link" i18n="sidenav|Users">
              Users
            </a>
          </li>
          <li class="sidenav-menu-item cms">
            <a [routerLink]="['/cms/users-actions']" (click)="close()" class="sidenav-menu-link" i18n="sidenav|Users actions">
              Audit of users actions
            </a>
          </li>
          <li class="sidenav-menu-item cms">
            <a [routerLink]="['/cms/cms-user-suspended-list']" (click)="close()" class="sidenav-menu-link">
              {{'menu.UserSuspendedList'|translate:'User Suspended List'}}
            </a>
          </li>

          <li class="sidenav-menu-item cms">
            <a [routerLink]="['/cms/reports']" (click)="close()" class="sidenav-menu-link" i18n="sidenav|Reports">
              Reports
            </a>
          </li>

          <li class="sidenav-menu-item cms" *ngIf="redis_dashboard_access">
            <a [routerLink]="['/cms/redis-dashboard']" (click)="close()" href="#" class="sidenav-menu-link">
              {{'menu.Redis Dashboard'| translate: 'Redis Dashboard'}}
            </a>
          </li>
          
        </ng-container>
        <li class="sidenav-menu-item" *ngIf="apiDocumentation && user.user_id">
          <img src="/assets/images/svg-icons/menu-apidoc.svg" alt="api-doc" style="max-height: 24px; margin: 3px 0; filter: brightness(10);">
          <a (click)="redirrectToSwagger($event); close()" href="#" class="sidenav-menu-link" i18n="sidenav|apiDocumentation">
            Api documentation
          </a>
        </li>
        <li class="sidenav-menu-item" *ngIf="telescope_view_url && user.user_id">
          <img src="/assets/images/svg-icons/menu_telescope.svg" alt="Laravel Telescope" style="max-height: 24px; margin: 3px 0; filter: brightness(10);">
          <a href="{{telescope_view_url}}" target="_blank" class="sidenav-menu-link">
            {{'menu.Telescope'|translate:'Telescope'}}
          </a>
        </li>

        <li [attr.data-cy]="'financeButton'" class="sidenav-menu-item" *ngIf="user.user_id && !payment_stripe_connect_accounts">
          <img src="/assets/images/svg-icons/bank.svg" alt="my-finance" style="max-width: 24px; filter: brightness(10);">
          <a [routerLink]="['/my-finance']" (click)="close()" class="sidenav-menu-link" i18n="sidenav|my finance">
            Finance
          </a>
        </li>

        <li class="sidenav-menu-item" *ngIf="user.user_id && payment_stripe_connect_accounts">
          <img src="/assets/images/svg-icons/card-pos.svg" alt="my-finance" style="max-width: 24px; filter: brightness(10);">
          <a [routerLink]="['/stripe-connect/payouts']" (click)="close()" class="sidenav-menu-link">
            {{'menu.Payouts'|translate:'Payouts'}}
          </a>
        </li>

        <li [attr.data-cy]="'myEventsButton'" class="sidenav-menu-item" *ngIf="user.user_id">
          <img src="/assets/images/svg-icons/menu-calendar.svg" alt="my-events">
          <a [routerLink]="['/dashboard']" (click)="close()" class="sidenav-menu-link" i18n="sidenav|my events">
            My Events
          </a>
        </li>
        <li [attr.data-cy]="'myTicketsButton'" class="sidenav-menu-item" *ngIf="user.user_id">
          <img src="/assets/images/svg-icons/menu-ticket.svg" alt="my-tickets">
          <a [routerLink]="['/my-tickets']" (click)="close()" class="sidenav-menu-link" i18n="sidenav|my tickets">
            My Tickets
          </a>
        </li>
        <li [attr.data-cy]="'producerButton'" class="sidenav-menu-item" *ngIf="user.user_id">
          <img src="/assets/images/svg-icons/menu-user-edit.svg" alt="producer">
          <a [routerLink]="['/producer', user.user_id]" (click)="close()" class="sidenav-menu-link" i18n="sidenav|producer page">
            Producer page
          </a>
        </li>
        <li [attr.data-cy]="'scannerButton'" class="sidenav-menu-item">
          <img src="/assets/images/svg-icons/menu-scan.svg" alt="scanner">
          <a [routerLink]="['/reader-signin']" (click)="close()" class="sidenav-menu-link" i18n="sidenav|scanner">
            Scanner
          </a>
        </li>
<!--        <li class="sidenav-menu-item">-->
<!--          <img src="/assets/images/svg-icons/menu-help.svg" alt="help">-->
<!--          <p (click)="showFreshChat();close()" class="sidenav-menu-link" i18n="sidenav|help">-->
<!--            Help-->
<!--          </p>-->
<!--        </li>-->
      </ul>
    </div>
    <div class="sidenav-cat" [ngClass]="{'guest': !user.user_id}">
      <p class="cat-name" *ngIf="user.user_id" i18n="sidenav|SETTINGS">SETTINGS</p>
      <ul class="sidenav-menu">
        <li [attr.data-cy]="'profileSettingsButton'" class="sidenav-menu-item" *ngIf="user.user_id">
          <img src="/assets/images/svg-icons/menu-profile.svg" alt="profile-settings">
          <a [routerLink]="['/personal-info']" (click)="close()" class="sidenav-menu-link" i18n="sidenav|profile settings">
            Profile settings
          </a>
        </li>
        <li [attr.data-cy]="'accountSettingsButton'" class="sidenav-menu-item" *ngIf="user.user_id">
          <img src="/assets/images/svg-icons/menu-setting.svg" alt="account-settings">
          <a [routerLink]="['/account-settings']" (click)="close()" class="sidenav-menu-link" i18n="sidenav|account settings">
            Account settings
          </a>
        </li>
        <li [attr.data-cy]="'aboutUsButton'" class="sidenav-menu-item">
          <img src="/assets/images/svg-icons/menu-info-circle.svg" alt="about">
          <a [routerLink]="['/about']" (click)="close()" class="sidenav-menu-link" i18n="sidenav|about">
            About us
          </a>
        </li>
        <li [attr.data-cy]="'supportButton'" class="sidenav-menu-item" *ngIf="!(isMobile && getActiveEvents())">
          <img src="/assets/images/svg-icons/menu-support2.svg" alt="support">
          <a (click)="showSpaceIntercom('messages');close()" href="#" class="sidenav-menu-link">
            {{'menu.SupportChat'| translate: 'Support chat'}}
          </a>
        </li>

        <li class="sidenav-menu-item" *ngIf="isMobile && getActiveEvents()">
<!--          Show Intercom help on MOBILE-->
          <img src="/assets/images/svg-icons/menu-intercom.svg" style="margin: 0 1px;" alt="Talk to an Agent">
          <a (click)="intercomMessage();close()" href="#" class="sidenav-menu-link">
            {{'menu.talkToAgent'| translate: 'Talk to an Agent'}}
          </a>
        </li>

        <li class="sidenav-menu-item">
          <img src="/assets/images/svg-icons/menu-help.svg" alt="help">
          <p (click)="showSpaceIntercom('help');close()" class="sidenav-menu-link">
            {{'menu.HelpCenter'| translate: 'Help center'}}
          </p>
        </li>

        <li [attr.data-cy]="'logOutButton'" class="sidenav-menu-item logout" *ngIf="user.user_id">
          <img src="/assets/images/svg-icons/menu-logout.svg" alt="logout">
          <a (click)="logout()" class="sidenav-menu-link" i18n="sidenav|logout">
            Log out
          </a>
        </li>
      </ul>
    </div>
    <div class="sidenav-social" [ngClass]="{'guest': !user.user_id}">
      <a href="https://twitter.com/getin_official" target="_blank">
        <img class="social-logo-img" src="/assets/images/svg-icons/menu-x-twitter.svg" alt="twitter"/>
        <span>Twitter</span>
      </a>
      <a href="https://www.facebook.com/getinofficiaI" target="_blank">
        <img class="social-logo-img" src="/assets/images/svg-icons/menu-facebook.svg" alt="facebook"/>
        <span>Facebook</span>
      </a>
      <a href="https://www.youtube.com/channel/UCS-K8zsYM7vCBTCFmLOerjA" target="_blank">
        <img class="social-logo-img" src="/assets/images/svg-icons/menu-youtube.svg" alt="youtube"/>
        <span>Youtube</span>
      </a>
      <a href="https://www.instagram.com/getinofficial/" target="_blank">
        <img class="social-logo-img" src="/assets/images/svg-icons/menu-instagram.svg" alt="instagram"/>
        <span>Instagram</span>
      </a>
      <a href="https://discord.com/invite/d8PAYdGTAW" target="_blank">
        <img class="social-logo-img" src="/assets/images/svg-icons/menu-discord.svg" alt="discord"/>
        <span>Discord</span>
      </a>
      <a href="https://www.tiktok.com/@getin_official" target="_blank">
        <img class="social-logo-img" src="/assets/images/svg-icons/menu-tiktok.svg" alt="tik-tok"/>
        <span>Tik-Tok</span>
      </a>
<!--      <a href="#" target="_blank">-->
<!--        <img class="social-logo-img" src="/assets/images/svg-icons/menu-telegram.svg" alt="telegram"/>-->
<!--        <span>Telegram</span>-->
<!--      </a>-->

    </div>

    <div class="sidenav-apps">
      <p class="cat-apps">Mobile app</p>
      <div>
        <a class="sidenav-app-link" href="https://apps.apple.com/app/getin/id1381205665" target="_blank"><img src="/assets/images/footer-app-store.png" alt="get-in-app-store"></a>
        <a class="sidenav-app-link" href="https://play.google.com/store/apps/details?id=com.getin.app" target="_blank"><img src="/assets/images/footer-google-play.png" alt="get-in-google-play"></a>
      </div>
    </div>
    <ul class="sidenav-additional-links">
      <li>
        <a href="https://twitter.com/getin_official" [routerLink]="['/terms']" (click)="close()" class="sidenav-additional-link" i18n="sidenav|terms">
          Terms
        </a>
      </li>
      <li>
        <a href="https://twitter.com/getin_official" [routerLink]="['/privacy']" (click)="close()" class="sidenav-additional-link" i18n="sidenav|privacy">
          Privacy
        </a>
      </li>
    </ul>
  </ng-container>
  <!--  DESKTOP-->
<!--  <ng-container *ngSwitchCase="false">-->

<!--  </ng-container>-->
</ng-container>
