import {
  Component,
  HostListener,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  AfterViewInit
} from '@angular/core';
import {FilterEventsService} from '../services/filter-events.service';
import {ActivatedRoute, Params} from '@angular/router';
import {NavbarService} from '../services/navbar.service';
import {GlobalsService} from '../globals';
import {RudderstackService} from "../services/rudderstack.service";
declare var window: any;

@Component({
  selector: 'app-categories-page',
  templateUrl: './categories-page.component.html',
  styleUrls: ['./categories-page.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CategoriesPageComponent implements OnInit, AfterViewInit, OnDestroy {
  categories = [];
  sliders = [];
  mainSliderData = [];
  active = '';
  activeName = '';

  events = [];
  trending = [];
  filter_events = [];
  explore = [];
  banner_event;
  direction = '';
  isMobile: boolean = false;
  @HostListener('window:resize', ['$event'])onResize() {
    this.isMobile = window.innerWidth <= 768;
  }
  region = '';


  constructor(private filterEventsService: FilterEventsService,
              public rudderstackService: RudderstackService,
              private activatedRoute: ActivatedRoute,
              private nav: NavbarService,
              private globals: GlobalsService
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if(params['region']){
        this.region = params['region'];
      }
    });
  }

  ngOnInit(): void {
    this.isMobile = window.innerWidth <= 768;
    this.direction = this.globals.getDir();
    this.nav.showFooter();
    this.activatedRoute.params.subscribe(params => {
      // console.log('params', params['id'])
      if (params['id']) {
        this.active = params['id'];
        this.fetchEvents();
        this.filterEventsService.setActiveCategory(params['id']);
      }
    });
    //this.fetchEvents();
   //  console.log('CategoriesPageComponent getEvent1', this.events)

    this.initEventsSubscriptions();
  }
  fetchEvents() {
    this.sliders = this.filterEventsService.slidersSource.getValue();
    this.events = this.filterEventsService.eventsSource.getValue();
    this.categories = this.filterEventsService.categoriesSource.getValue();
    if (this.sliders.length) { this.handleSliders(this.sliders) }
    if (this.categories.length) { this.handleCategories(this.categories) }
    if (this.events.length) { this.handleEvents(this.events) }
    if (!this.events.length) {
      this.filterEventsService.getEvents( null, this.region ).subscribe(value => {
        this.categories = value.categories;
        this.handleSliders(value.sliders);
        this.handleEvents(value.events);
        this.handleCategories(value.categories);
        this.filterEventsService.setSliders(value.sliders);
        this.filterEventsService.setEvents(value.events);
        this.filterEventsService.setCategories(value.categories);
      });
    }
  }

  handleEvents(events) {
    this.explore = this.filterEventsService.handleEvents(events, this.active);
    this.trending = this.filterEventsService.handleEvents(events, 'trending');

    const explr_l = this.explore.length;
    // Find event with max views
    this.banner_event = explr_l ? this.explore.reduce((prev, cur) => {return (prev.views > cur.views) ? prev : cur}) : null;

    // we need it just to not show empty categroies inside app-category-sidenav
    this.filterEventsService.handleEvents(events, 'categorized', this.categories);
  }

  handleCategories(cats) {
    const activeCat = cats.filter(cat => cat.id === +this.active);
    if (activeCat.length) {
      this.activeName = activeCat[0].name[this.globals.getLocaleId()];
    } else {
      this.activeName = this.active;
    }
    this.rudderstackService.page('Category page',this.activeName);
  }

  handleSliders(sliders) {
    this.mainSliderData = sliders.sort((a, b) => a.number_position - b.number_position);
  }

  initEventsSubscriptions() {
    // Search by 'address' subscription
    this.filterEventsService.getAutocompleteSource.subscribe(value => {
      // console.log('VAL getAutocompleteSource', value);
      this.filter_events = this.filterEventsService.handleEvents(this.explore, 'location');
    })

    // Search by 'datepicker' subscription
    this.filterEventsService.getDatepickerSource.subscribe(value => {
      // console.log('VAL getDatepickerSource', value);
      this.filter_events = this.filterEventsService.handleEvents(this.explore, 'datepicker');
    })

    // Search by 'date Select' subscription
    this.filterEventsService.getSelectSource.subscribe(value => {
      // console.log('VAL getSelectSource', value);
      this.filter_events = this.filterEventsService.handleEvents(this.explore, 'datepicker');
    })
    this.nav.currentAuthStatus.subscribe(authed => {
      // console.log('currentAuthStatus', authed)
      if (authed) {
        setTimeout(()=> {
          if (this.sliders.length) { this.handleSliders(this.sliders) }
          if (this.categories.length) { this.handleCategories(this.categories) }
          if (this.events.length) { this.handleEvents(this.events) }
},1000)
      }
    })
  }

  ngAfterViewInit() {

  }

  ngOnDestroy(): void {
    this.nav.hideFooter();
  }

}
