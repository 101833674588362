<div class="col-md-12 col-xs-12">
  <div class="modal-header">
    <button type="button" class="close" (click)="dialogRef.close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="text-center" *ngIf="eventBankDetails"><h3>Bank account details for event {{eventBankDetails.event_id}}</h3></div>
  </div>
  <div class="modal-body mb15" style="color:#000;" *ngIf="eventBankDetails">
    <div class="row">
      <div class="col-md-6 col-xs-12">
        <ul>
          <li *ngIf="eventBankDetails.producer_name"><span><strong>Producer name:</strong></span> {{eventBankDetails.producer_name}}</li>
          <li *ngIf="eventBankDetails.producer_phone"><span><strong>Phone number:</strong></span> {{eventBankDetails.producer_phone}}</li>
          <li *ngIf="eventBankDetails.producer_email"><span><strong>Email:</strong></span> {{eventBankDetails.producer_email}}</li>
        </ul>
      </div>
      <div class="col-md-6 col-xs-12">
        <ul>
          <li *ngIf="eventBankDetails.country"><span><strong>Country:</strong></span> {{eventBankDetails.country}}</li>
          <li *ngIf="eventBankDetails.city"><span><strong>City:</strong></span> {{eventBankDetails.city}}</li>
          <li *ngIf="eventBankDetails.bank_name"><span><strong>Bank Name:</strong></span> {{eventBankDetails.bank_name}}</li>
          <li *ngIf="eventBankDetails.other_bank_details"><span i18n="label | bank other_bank_details"><strong>Bank details:</strong></span> {{eventBankDetails.other_bank_details}}</li>
          <li *ngIf="eventBankDetails.address"><span><strong>Living Address:</strong></span> {{eventBankDetails.address}}</li>
          <li *ngIf="eventBankDetails.account_name"><span><strong>Account holder:</strong></span> {{eventBankDetails.account_name}}</li>
          <li *ngIf="eventBankDetails.recipient_phone"><span><strong>Account owner phone number:</strong></span> {{eventBankDetails.recipient_phone}}</li>
          <li *ngIf="eventBankDetails.bank_id"><span><strong>Bank serial number:</strong></span> {{eventBankDetails.bank_id}}</li>
          <li *ngIf="eventBankDetails.branch"><span><strong>Branch number:</strong></span> {{eventBankDetails.branch}}</li>
          <li *ngIf="eventBankDetails.company_user_id"><span><strong>ID or VAT Number:</strong></span> {{eventBankDetails.company_user_id}}</li>
          <li *ngIf="eventBankDetails.account_number"><span><strong>Account number:</strong></span> {{eventBankDetails.account_number}}</li>
          <li *ngIf="eventBankDetails.aba_number"><span><strong>ABA Number:</strong></span> {{eventBankDetails.aba_number}}</li>
        </ul>
      </div>
      <div class="col-md-6 col-xs-12" *ngIf="eventBankDetails.payout_stripe_connect && eventBankDetails.payout_stripe_connect.length">
        <strong>Stripe Connect:</strong>
        <ul style="line-height: 1.3;">
          <li *ngIf="eventBankDetails.payout_stripe_connect.id"><span><strong>ID:</strong></span> {{eventBankDetails.payout_stripe_connect.id}}</li>
          <li *ngIf="eventBankDetails.payout_stripe_connect.account_id"><span><strong>Account ID:</strong></span> {{eventBankDetails.payout_stripe_connect.account_id}}</li>
          <li *ngIf="eventBankDetails.payout_stripe_connect.payment_gateway"><span><strong>Gateway:</strong></span> {{eventBankDetails.payout_stripe_connect.payment_gateway | json}}</li>
          <li *ngIf="eventBankDetails.payout_stripe_connect.type"><span><strong>Type:</strong></span> {{eventBankDetails.payout_stripe_connect.type}}</li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="!eventBankDetails">
    <h2 style="color: #1c1c1c;">No Bank data.</h2>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default pull-left mr10" (click)="dialogRef.close()">Close</button>
  </div>
</div>
