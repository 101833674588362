import { Injectable } from '@angular/core';
import {AuthService} from './auth.service';
import {environment} from '../../environments/environment';
import {GlobalsService} from '../globals';
declare var window: any;
@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  APP_ID = environment.intercom.appId;

  constructor(
    private authService: AuthService,
    private globals: GlobalsService
  ) {}

  loadScript() {
    const settings = this.createSettings(false);

    window.intercomSettings = { ...settings };

    // Prepare script to head
    //  let node = document.createElement('script');
    //  node.text = `(function(){
    //  var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;
    //  s.src='https://widget.intercom.io/widget/${this.APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;
    //  node.async = true;
    // document.getElementsByTagName('head')[0].appendChild(node);
  }

  createSettings(hide_default_launcher) {
    const user = this.authService.getUserInfo();

    let settings = {
      app_id: this.APP_ID,
      background_color: '#080416',
      action_color: '#252045',
      hide_default_launcher: hide_default_launcher,
      language_override: this.globals.getLocaleId(),
    };

    // If user object is not empty
    if (!(Object.keys(user).length === 0)) {
      // https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects#data-attributes
      const userInfo = {
        email: user.email,
        user_id: user.user_id,
        name: `${user.first_name} ${user.last_name}`,
        phone: user.phone,
        avatar: {
          type: 'avatar',
          image_url: user.social_img,
        },
      };
      settings = { ...settings, ...userInfo };
    }

    return settings;
  }

  shutdown() {
    (<any>window).Intercom('shutdown');
  }
  update() {
    (<any>window).Intercom('update');
  }
  show() {
    (<any>window).Intercom('show');
  }
  showSpace(space: 'home'|'messages'|'news'|'tasks'|'help'|'tickets') {
    (<any>window).Intercom('showSpace', space);
  }

  message(text) {
    this.loadScript();
    (<any>window).Intercom('showNewMessage', text);
  }

  boot(hide_default_launcher = false) {
    const settings = this.createSettings(hide_default_launcher);
    (<any>window).Intercom('boot', {
      ...settings,
    });
  }
}
