<form #guestForm [formGroup]="participantForm" class="{{showSeatsIo?'seats-guest-form':''}}">

  <div class="guest-cover">
    <!-- <div i18n="label | Attendees page title" class="guest-title">ATTENDEES</div> -->
      <!-- <div *ngIf="purchase_type !== 1"> -->
          <div *ngFor="let category of choosedItems | keyvalue">
              <div class="guest-form-category" *ngIf="category.key != 'noCat'">
                  <!-- <span *ngIf="purchase_type !== 1" class="category-name">{{category.value.name}}</span> -->
                  <div class="form-ticket-wrap" *ngFor="let ticket of category.value | keyvalue">
                      <div class="form-ticket mb10" *ngIf="ticket.key !== 'name'">
                          <p *ngIf="purchase_type !== 1" class="guest-detail-title">{{ticket.value.name}}</p>
                          <div class="participant-form" id="item-{{i + 1}}" formArrayName="participants"
                              *ngFor="let item of participantForm?.get('participants')['controls']; let i = index; let last = last;">
                              <div class="participant-toggle" [formGroupName]="i" #participant [ngClass]="item.valid ? 'active-participant' : 'inactive-participant'"
                                  *ngIf="item.controls.item_id.value == ticket.key && item.controls.category.value == category.key">
                                  <div (click)="toggleParticipantForm(i + 1)"
                                      [class.participant-invalid]="participantForm.controls['participants']['controls'][i].invalid && formSubmited"
                                      [ngClass]="last? 'last-participant-title' : 'participant-title'" class="validation_block col-md-12 col-xs-12">
                                      <div class="dropdown-guest-inputs complete">
                                          <div class="guest-complete-text">
                                              <!-- <img class="profile-img" src="../../../assets/images/svg-icons/profile-circle.svg" alt="profile icon"> -->
                                              <p *ngIf="item.invalid" id="cardName" class="mb0">
                                                <ng-container i18n *ngIf="i + 1 == 1">You</ng-container><ng-container i18n *ngIf="i + 1 != 1">Attendee</ng-container> <span class="participant_counter">{{ (i + 1 == 1) ? '' : i + 1}}</span>
                                              </p>
                                              <p *ngIf="item.valid" id="cardName" class="mb0">
                                                {{item.controls.full_name.value}}</p>
                                          </div>
                                          <!-- <span *ngIf="item.valid" class="sign-complete-nui">
                                              <svg class="card-cb-on-nui" width="12" height="8"
                                                  viewBox="0 0 12 8" fill="none"
                                                  xmlns="http://www.w3.org/2000/svg">
                                                  <path
                                                      d="M11.8243 0.159541C11.59 -0.0531803 11.2101 -0.0531803 10.9757 0.159541L3.78737 6.68516L1.02428 4.17684C0.789972 3.96412 0.410097 3.96414 0.175746 4.17684C-0.058582 4.38954 -0.058582 4.73439 0.175746 4.94711L3.3631 7.84053C3.59734 8.05323 3.97749 8.05308 4.21163 7.84053L11.8243 0.929831C12.0586 0.717131 12.0586 0.372262 11.8243 0.159541Z"
                                                      fill="#82FFCA" />
                                              </svg>
                                          </span> -->
                                      </div>
                                  </div>
                                  <!-- *ngIf="toggleParticipant === i + 1" [@slideIn] -->
                                  <div
                                  [ngClass]="last? 'last-ticket-fields' : 'ticket-fields'" class="form_item col-md-12 col-xs-12">
                                      <!-- Auto fill -->
                                      <!-- <div class="autofill-container">
                                          <span i18n="purchase modal | Fill with my data"
                                              class="auto-fill" (click)="fillForMe(i)"
                                              *ngIf="(!!user_info && item.invalid)">Auto
                                              fill</span>
                                          <span i18n="Clear attendee details" class="auto-clear"
                                              (click)="clearAttendee(i)"
                                              *ngIf="(!!user_info && item.valid)">Clear</span>
                                      </div> -->
                                      <!-- Default inputs -->
                                      <ng-container ngDefaultControl
                                          [ngTemplateOutlet]="templateForm"
                                          [ngTemplateOutletContext]="{item:item, form: participantForm.controls['participants']['controls'][i], counter: i}">
                                      </ng-container>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      <!-- </div> -->

      <!-- No cat -->
      <div class="guest-form-category">

        <ng-container *ngIf="showSeatsIo && purchase_type === 1"> <!--SEATSIO TIckets list-->
          <div style="border: 1px solid rgba(255,255,255,0.32);border-radius: 16px; padding: 10px;">
            <div class="guest-complete-text2" *ngFor="let labels of participantForm.controls['participants']['controls'][0].get('labels')['controls']">
              <p class="seats-t-info" *ngFor="let label of labels.value | keyvalue">{{+label.key + 1}}.
                <!--                          <span *ngIf="label.value.section">Section: {{label.value.section}}</span>&nbsp;-->
                <span *ngIf="label.value.parent">
                  <span i18n="seats|Row" *ngIf="label.value.type === 'row'">Row: </span>
                  <span i18n="seats|Table" *ngIf="label.value.type === 'table'">Table: </span>
                  {{label.value.parent}}</span>&nbsp;
                <span *ngIf="label.value.own"><span i18n="seats|Seat"> Seat: </span>{{label.value.own}}</span>&nbsp;
              </p>
            </div>
          </div>
        </ng-container>

          <div class="form-ticket-wrap" *ngFor="let ticket of choosedItems['noCat'] | keyvalue">
              <div class="form-ticket mb10" *ngIf="ticket.key !== 'name'">
                  <p *ngIf="purchase_type !== 1" class="guest-detail-title">{{ticket.value.name}}</p>
                  <div id="item-{{i + 1}}" formArrayName="participants" class="participant-form"
                      *ngFor="let item of participantForm?.get('participants')['controls']; let i = index; let last = last;">

                      <div class="participant-toggle {{purchase_type==1 ? 'group-form':'individual-form'}}" [formGroupName]="i" #participant [ngClass]="item.valid ? 'active-participant' : 'inactive-participant'"
                          *ngIf="item.controls.item_id.value == ticket.key">
                          <div (click)="  (i + 1)"
                              [class.participant-invalid]="participantForm.controls['participants']['controls'][i].invalid && formSubmited"
                              [ngClass]="last? 'last-participant-title' : 'participant-title'" class="validation_block col-md-12 col-xs-12">
                              <div class="dropdown-guest-inputs complete">
                                  <div class="guest-complete-text" *ngIf="!showSeatsIo">
                                      <!-- <img class="profile-img" src="../../../assets/images/svg-icons/profile-circle.svg" alt="profile icon"> -->
                                      <p *ngIf="item.invalid"
                                          id="cardName" class="mb0"> <ng-container i18n *ngIf="i + 1 == 1">You</ng-container><ng-container i18n *ngIf="i + 1 != 1">Attendee</ng-container> <span
                                              class="participant_counter">{{ (i + 1 == 1) ? '' : i + 1}}</span></p>
                                      <p *ngIf="item.valid" id="cardName" class="mb0">
                                          {{item.controls.full_name.value}}</p>
                                  </div>
                                  <!-- <span *ngIf="item.valid" class="sign-complete-nui">
                                      <svg class="card-cb-on-nui" width="12" height="8"
                                          viewBox="0 0 12 8" fill="none"
                                          xmlns="http://www.w3.org/2000/svg">
                                          <path
                                              d="M11.8243 0.159541C11.59 -0.0531803 11.2101 -0.0531803 10.9757 0.159541L3.78737 6.68516L1.02428 4.17684C0.789972 3.96412 0.410097 3.96414 0.175746 4.17684C-0.058582 4.38954 -0.058582 4.73439 0.175746 4.94711L3.3631 7.84053C3.59734 8.05323 3.97749 8.05308 4.21163 7.84053L11.8243 0.929831C12.0586 0.717131 12.0586 0.372262 11.8243 0.159541Z"
                                              fill="#82FFCA" />
                                      </svg>
                                  </span> -->
                                <div class="guest-complete-text" *ngIf="showSeatsIo && purchase_type !== 1"> <!--SEATSIO TIckets list-->
<!--                                  <p class="seats-label" *ngIf="item.contains('labels')">Ticket: {{item.controls.labels.controls.displayedLabel.value}}</p>-->
                                  <p class="seats-t-info" *ngIf="item.contains('labels')">
<!--                                    <span *ngIf="item.controls.labels.contains('section')">Section: {{item.controls.labels.controls.section.value}}</span>&nbsp;-->
                                    <span *ngIf="item.controls.labels.contains('parent')">
                                      <span i18n="seats|Row" *ngIf="item.controls.labels.controls.type.value === 'row'">Row: </span>
                                      <span i18n="seats|Table" *ngIf="item.controls.labels.controls.type.value === 'table'">Table: </span>
                                      {{item.controls.labels.controls.parent.value}}</span>&nbsp;
                                    <span *ngIf="item.controls.labels.contains('own')">
                                      <span i18n="seats|Seat"> Seat: </span>
                                      {{item.controls.labels.controls.own.value}}</span>&nbsp;
                                  </p>
                                </div>
                              </div>
                          </div>
                          <!-- *ngIf="toggleParticipant === i + 1" [@slideIn] -->
                          <div
                          [ngClass]="last? 'last-ticket-fields' : 'ticket-fields'" class="form_item col-md-12 col-xs-12">
                              <!-- Auto fill -->
                              <!-- <div class="autofill-container">
                                  <span i18n="purchase modal | Fill with my data"
                                      class="auto-fill" (click)="fillForMe(i)"
                                      *ngIf="(!!user_info && item.invalid)">Auto
                                      fill</span>
                                  <span i18n="Clear attendee details" class="auto-clear"
                                      (click)="clearAttendee(i)"
                                      *ngIf="(!!user_info && item.valid)">Clear</span>
                              </div> -->
                              <ng-container [ngTemplateOutlet]="templateForm"
                                  [ngTemplateOutletContext]="{item:item, form: participantForm.controls['participants']['controls'][i], counter: i }">
                              </ng-container>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="checkbox-terms">
        <label class="radio-label switch">
        <input type="checkbox" type="checkbox" [attr.data-cy]="'termsCheckbox'" name="agt" [checked]="checkBoxAgree"
            id="updateAgt" (click)="onCheckBox('Agree')">
          <!-- <span class="slider round"></span> -->
          <span class="checkmark" [ngStyle]="{'background': checkBoxAgree ? 'rgba(40, 33, 65, 0.97)' : 'none'}"></span>
        </label>
        <div class="checkbox-text"><span t key="guestInfo.IAcceptGetin" default="I accept Getin"></span><span class="order-terms"
            (click)="getTerms()" t key="guestInfo.termOfService" default="term of service"></span>
        </div>
      </div>
      <div class="checkbox-terms" *ngIf="showProducerSubscription">
        <label class="radio-label switch">
        <input type="checkbox" type="checkbox" name="agt" [attr.data-cy]="'subscribeCheckbox'"
            [checked]="checkBoxProducerSubscription" id="updateAgt" (click)="onCheckBox('ProducerSubscription', $event)">
          <!-- <span class="slider round"></span> -->
          <span class="checkmark" [ngStyle]="{'background': checkBoxProducerSubscription ? 'rgba(40, 33, 65, 0.97)' : 'none'}"></span>
        </label>
        <div i18n="checkbox | get updates from producer" class="checkbox-text">I accept receiving updates from this producer
        </div>
      </div>
    <!-- TODO Delete CUstom checkbox in future -->
      <div class="checkbox-terms 1" *ngIf="event_details?.event_id == 325871 || event_details?.event_id == 8808">
        <label class="radio-label switch">
          <input type="checkbox" type="checkbox" name="custom_text" id="custom_text">
          <span class="checkmark"></span>
        </label>
        <div class="checkbox-text">
          <span t key="guestInfo.customCheckboxTxt1" default="Yes! I consent to be the first to receive personalized communications by email from House of 10 and be eligible to receive invitations to exclusive VIP events.
          You can withdraw your consent at any time by using the Unsubscribe mechanism provided within the content.
          For more information on your rights and the processing of your data, please consult our ">
          </span>
          <a href="https://www.belvederevodka.com/en-int/legal/privacy/" target="_blank" class="custom-link"
             t key="guestInfo.customCheckboxLink1" default="Information note on the processing of personal data and cookies."></a>
        </div>
      </div>
      <div class="checkbox-terms 2" *ngIf="event_details?.event_id == 325871 || event_details?.event_id == 8808">
        <label class="radio-label switch">
          <input type="checkbox" type="checkbox" name="custom_text" id="custom_text">
          <span class="checkmark"></span>
        </label>
        <div class="checkbox-text">
            <span>
              Yes! I consent to be the first to receive personalized communications by email from Belvedere Vodka (Polmos Zyrardow SP.Z.O.O) and be eligible to receive invitations to exclusive VIP events.
              In order to better serve my needs, I understand that my
              <a href="https://www.belvederevodka.com/en-int/legal/privacy/" target="_blank" class="custom-link" t key="guestInfo.customCheckboxLinkData1" default="data"></a>
              is processed, analyzed and shared with our parent company, LVMH, which includes Belvedere plus other prestigious champagne, spirits, fashion, jewelry and hospitality
              <a href="https://www.lvmh.com/houses/" target="_blank" class="custom-link" t key="guestInfo.customCheckboxLinkBrands2" default="brands">brands</a>.
              You can withdraw your consent at any time by using the Unsubscribe mechanism provided within the content.
              For more information on your rights and the processing of your data, please consult our
            </span>
          <a href="https://www.belvederevodka.com/en-int/legal/privacy/" target="_blank" class="custom-link"
             t key="guestInfo.customCheckboxLink1" default="Information note on the processing of personal data and cookies."></a>
        </div>
      </div>
    <div class="checkbox-terms 3" *ngIf="event_details?.event_id == 8297 || event_details?.event_id == 337438">
      <label class="radio-label switch">
        <input type="checkbox" type="checkbox" name="custom_text" id="custom_text" [checked]="checkBoxCustom" (click)="onCheckBox('Custom')">
        <span class="checkmark"></span>
      </label>
      <div class="checkbox-text">
        <span>I accept Day Zero 2025
          <a href="https://intercom.help/getin-93198e5c33cd/en/articles/9626536-day-zero-tulum-2025-terms" target="_blank" class="custom-link">terms</a>
        </span>
      </div>
    </div>
      <div class="login-reg-continue" (click)="sendParticipentForm()">
        <div i18n="Continue to login | button" [attr.data-cy]="'GuestInfoContinueButton'"
             id="continuebtn" class="continue-btn"
             *ngIf="!hasWaitedTicket">
            Continue</div>
        <div [attr.data-cy]="'GuestInfoContinueButton'" id="continuebtn" class="continue-btn join-waited-btn icon-right"
             t key="eventPageComponent.JoinTheWaitingListButton"
             default="JOIN THE WAITING LIST"
             *ngIf="hasWaitedTicket">
          JOIN THE WAITING LIST</div>
          <div class="purchase-btn-total"
          [ngStyle]="{'border-right': dir == 'rtl' ? '1px solid #A29BB1' : 'none',
                            'border-left': dir == 'rtl' ? 'none' : '1px solid #A29BB1'}">
          <p class="purchase-btn-total-tickets">{{payBtnAddon?.quantity}}&nbsp;<span i18n="purchase-btn-total">{payBtnAddon?.quantity,
              plural, =1 {ticket} other {tickets}}</span></p>
          <p class="purchase-btn-total-price">{{payBtnAddon?.total_price_w_commission | currency:
            payBtnAddon?.currency:payBtnAddon?.currency === 'MXN' || payBtnAddon?.currency === 'CAD' ? 'symbol': 'symbol-narrow':'1.0-2':getLocale(payBtnAddon?.currency)}}</p>
        </div>
      </div>
  </div>
</form>

<ng-template #templateForm let-item="item" let-form="form" let-counter=counter>
  <section class="order-section" [ngClass]="{'rtl' : dir === 'rtl'}">
      <div class="guest-details-section-nui row">
          <ng-container *ngFor="let input of form_inputs?.default_inputs">
                <!-- For Full_name input -->
                <div class="dropdown-guest-inputs section-input section-item1-nui col-col-md-6 col-xs-6 col-sm-12"
                *ngIf="input.input_type === 'text' && input.input_name ==='full_name'">
                <div i18n="input section | title" class="new-placeholder">Full name*</div>
                <div class="email-section-input" [formGroup]="form" [ngClass]="item.valid ? 'active-section-input' : 'inactive-section-input'">
                    <label i18n="input label | full name" *ngIf="label" for="{{input.input_name}}"></label>
                    <!-- <img [src]="'assets/images/svg-icons/tag-user.svg'" alt="full name icon"> -->
                    <input class="email-input form-control" trim="blur" [ngClass]="item.valid ? 'active-section-input' : 'inactive-section-input'"
                        id="{{input.input_name + counter}}" type="{{input.input_type}}"
                        name="{{input.input_name + counter}}" [attr.data-cy]="input.input_name" formControlName="{{input.input_name}}"
                        [minlength]="2" pattern="[a-zA-Zñáéíóúü\u0590-\u05fe\u200f\u200e\u0400-\u04FF][a-zA-Zñáéíóúü\u0590-\u05fe\u200f\u200e\u0400-\u04FF -'.ʼ-]+$"
                           (input)="checkFullName(counter, $event)"
                        placeholder="{{(input.input_name | i18nSelect: formInputsOthers[localId]) ? (input.input_name | i18nSelect: formInputsOthers[localId]) : (input.placeholder)}}">
                </div>
                <app-error-handling [@slideIn] *ngIf="input.required != '0'" ngDefaultControl
                    [formControl]="item.controls[input.input_name]" [inputName]="input.input_name"
                    [formSubmited]="formSubmited" [customClass]="'error item-error'">
                </app-error-handling>
            </div>
              <!-- For first_name input -->
              <!-- <div class="dropdown-guest-inputs section-item1-nui col-col-md-6 col-xs-6 col-sm-12"
                  *ngIf="input.input_type === 'text' && input.input_name ==='first_name' && input.required != '0'">
                  <div class="email-section-input" [formGroup]="form" [ngClass]="item.valid ? 'active-section-input' : 'inactive-section-input'">
                      <label i18n="input label | first name" *ngIf="label"
                          for="{{input.input_name}}"></label>
                      <img [src]="'assets/images/svg-icons/tag-user.svg'" alt="email icon">
                      <input class="email-input form-control" trim="blur" [ngClass]="item.valid ? 'active-section-input' : 'inactive-section-input'"
                          id="{{input.input_name}}" type="{{input.input_type}}"
                          name="{{input.input_name}}" formControlName="{{input.input_name}}"
                          placeholder="{{(input.input_name | i18nSelect: formInputsOthers[localId]) ? (input.input_name | i18nSelect: formInputsOthers[localId]) : (input.placeholder)}}">
                  </div>
                  <app-error-handling [@slideIn] *ngIf="input.required != '0'" ngDefaultControl
                      [formControl]="item.controls[input.input_name]" [inputName]="input.input_name"
                      [formSubmited]="formSubmited" [customClass]="'error item-error'">
                  </app-error-handling>
              </div> -->

              <!-- For last_name input -->
              <!-- <div class="dropdown-guest-inputs section-item2-nui col-col-md-6 col-xs-6 col-sm-12"
                  *ngIf="input.input_type === 'text' && input.input_name ==='last_name' && input.required != '0'">
                  <div class="email-section-input" [formGroup]="form" [ngClass]="item.valid ? 'active-section-input' : 'inactive-section-input'">
                      <label i18n="input label | first name" *ngIf="label"
                          for="{{input.input_name}}"></label>
                      <img [src]="'assets/images/svg-icons/tag-user.svg'" alt="email icon">
                      <input class="email-input form-control" trim="blur" [ngClass]="item.valid ? 'active-section-input' : 'inactive-section-input'"
                          id="{{input.input_name}}" type="{{input.input_type}}"
                          name="{{input.input_name}}" formControlName="{{input.input_name}}"
                          placeholder="{{(input.input_name | i18nSelect: formInputsOthers[localId]) ? (input.input_name | i18nSelect: formInputsOthers[localId]) : (input.placeholder)}}">
                  </div>
                  <app-error-handling [@slideIn] *ngIf="input.required != '0'" ngDefaultControl
                      [formControl]="item.controls[input.input_name]" [inputName]="input.input_name"
                      [formSubmited]="formSubmited" [customClass]="'error item-error'">
                  </app-error-handling>
              </div> -->

              <!-- For user_email -->
              <div class="dropdown-guest-inputs section-input section-item4-nui col-col-md-6 col-xs-6 col-sm-12"
               *ngIf="input.input_name === 'user_email' && input.custom_input">
                  <div i18n="input section | title" class="new-placeholder">Email*</div>
                  <div class="email-section-input" [formGroup]="form" [ngClass]="item.valid ? 'active-section-input' : 'inactive-section-input'">
                      <label *ngIf="label" for="{{'user_email' + counter}}">User email</label>
                      <!-- <img [src]="'assets/images/svg-icons/sms.svg'" alt="email icon"> -->
                      <input class="email-input form-control" id="{{'user_email' + counter}}" [ngClass]="item.valid ? 'active-section-input' : 'inactive-section-input'"
                          type="text" formControlName="user_email" name="{{'user_email' + counter}}" [attr.data-cy]="'user_email'"
                          placeholder="{{input.placeholder}}" (change)="mailChange($event.target.value, counter)">

                  </div>
                  <div *ngIf="suggestion && suggestion.hasOwnProperty(counter) && suggestion[counter]" class="suggestion">
                    {{'guestInfo.DidYouMean' | translate:'Did you mean'}} <span (click)="setSuggestedMail(suggestion[counter].full, counter)">{{suggestion[counter].full}}</span> ?
                  </div>
                  <app-error-handling [@slideIn] *ngIf="input.required != '0'" ngDefaultControl
                      [formControl]="item.controls[input.input_name]" [inputName]="input.input_name"
                      [formSubmited]="formSubmited" [customClass]="'error item-error'">
                  </app-error-handling>
                <div class="field-message error item-error" *ngIf="item.controls[input.input_name].errors?.emailBlocked" t key="guestInfo.ErrorEmailBlocked" default="This email is not valid. Please contact Support.">
                  This email is not valid. Please contact Support.
                </div>
<!--                <div class="error-email-list error item-error" *ngIf="invalidEmailsError.length">-->
<!--&lt;!&ndash;                  <span class="warning">!</span>&ndash;&gt;-->
<!--                  <span t key="eventPageComponent.InvalidEmails" default="Invalid Emails:"></span>&nbsp;-->
<!--&lt;!&ndash;                  <br>&ndash;&gt;-->
<!--                  <ng-container *ngFor="let email of invalidEmailsError">-->
<!--                    <span class="error-email">"{{email}}"</span>&nbsp;-->
<!--                  </ng-container>-->
<!--                </div>-->
              </div>

              <!-- For birthday input -->
              <div class="dropdown-guest-inputs section-input section-item6-nui col-col-md-6 col-xs-6 col-sm-12"
                  *ngIf="input.input_type === 'date' && input.input_name ==='birth_date' && input.required != '0' && input.custom_input">
                  <div i18n="input section | title" class="new-placeholder">Birth date*</div>
                  <div class="email-section-input " [formGroup]="form" [ngClass]="item.valid ? 'active-section-input' : 'inactive-section-input'">
                      <label i18n="input label | user birth date" *ngIf="label" for="{{input.input_name + counter}}">Date of Birth</label>
                      <!-- <img [src]="'assets/images/svg-icons/sms.svg'" alt="birthday icon"> -->
                      <input class="email-input form-control" (change)="birthDateCange(counter, $event)" type="date"  [ngClass]="item.valid ? 'active-section-input' : 'inactive-section-input'"
                      i18n-placeholder="input label | birth date" [mbsc-options]="myBirthdaySettings" mbsc-date
                        name="{{input.input_name + counter}}" [attr.data-cy]="input.input_name" id="{{input.input_name + counter}}" formControlName="{{input.input_name}}" placeholder="Date of Birth">

                  </div>
                  <app-error-handling [@slideIn] *ngIf="input.required != '0'" ngDefaultControl
                      [formControl]="item.controls[input.input_name]" [inputName]="input.input_name" [formSubmited]="formSubmited"
                      [customClass]="'error item-error'">
                  </app-error-handling>
              </div>

            <!-- For age input -->
            <div class="" *ngIf="input.input_type === 'number' && input.input_name === 'age' && input.required != '0'">
<!--              <div i18n="input section | title" class="new-placeholder">Age*</div>-->
              <div class="" [formGroup]="form">
<!--                <label i18n="input label | user age" *ngIf="label" for="{{input.input_name + counter}}">גיל</label>-->
                <!-- <img [src]="'assets/images/svg-icons/sms.svg'" alt="age icon"> -->
                <input class="email-input form-control" (change)="ageChange(counter, $event)" type="hidden" [ngClass]="item.valid ? 'active-section-input' : 'inactive-section-input'"
                       i18n-placeholder="@@Age" id="{{input.input_name + counter}}" name="{{input.input_name + counter}}" [attr.data-cy]="input.input_name"
                       formControlName="{{input.input_name}}" placeholder="Age" [readonly]="ageDisabled">

              </div>
            </div>

              <!-- For id_number input -->
              <div class="dropdown-guest-inputs section-input section-item5-nui col-col-md-6 col-xs-6 col-sm-12"
                  *ngIf="input.input_type === 'number' && (input.input_name ==='id_number' || input.input_name ==='identification_number') && event_details.id_number !== 0 && input.custom_input">
                  <div i18n="input section | title" class="new-placeholder">ID number*</div>
                  <div class="email-section-input" [formGroup]="form" [ngClass]="item.valid ? 'active-section-input' : 'inactive-section-input'">
                      <label i18n="input label | id number" *ngIf="label" for="{{input.input_name}}"></label>
                      <!-- <img [src]="'assets/images/svg-icons/sms.svg'" alt="id icon"> -->
                      <input class="email-input form-control" trim="blur" [ngClass]="item.valid ? 'active-section-input' : 'inactive-section-input'"
                          id="{{input.input_name + counter}}" type="{{input.input_type}}"
                          name="{{input.input_name + counter}}" [attr.data-cy]="input.input_name" formControlName="{{input.input_name}}"
                          placeholder="{{(input.placeholder | i18nSelect: formInputsOthers[localId])}}">

                  </div>
                  <app-error-handling [@slideIn] *ngIf="input.required != '0'" ngDefaultControl
                      [formControl]="item.controls[input.input_name]" [inputName]="input.input_name"
                      [formSubmited]="formSubmited" [customClass]="'error item-error'">
                  </app-error-handling>
              </div>

              <!-- For New Select gender -->
              <div class="dropdown-guest-inputs section-input section-item7-nui col-col-md-6 col-xs-6 col-sm-12"
                  *ngIf="input.input_type === 'select' && input.input_name === 'gender' && input.required == '1' && input.custom_input">
                  <div i18n="input section | title" class="new-placeholder">Gender*</div>
                  <div [formGroup]="form" class="email-section-input gender-section-input" style="padding: 0 5px;">
                      <div class="slider-wrapper">
                        <!-- <label class="email-section-title">Gender</label> -->
                        <section class="slide-toggle-section">
                        <mat-radio-group name="{{input.input_name + counter}}" formControlName="{{input.input_name}}"
                            [attr.data-cy]="'genderRadioGroup'" id="{{input.input_name + counter}}"
                            (change)="radioToggle($event, counter, form, item)">
                            <mat-radio-button i18n="Label Female | radio-button" class="slide-toggle-margin" value="2"
                                [attr.data-cy]="'femaleRadioButton'" [checked]="form.controls.gender.value=='2'">Female</mat-radio-button>
                            <mat-radio-button i18n="Label Male | radio-button" class="slide-toggle-margin" value="1"
                                [attr.data-cy]="'maleRadioButton'" [checked]="form.controls.gender.value=='1'">Male</mat-radio-button>
                            <mat-radio-button i18n="Label other | radio-button" class="slide-toggle-margin" value="0"
                                [attr.data-cy]="'otherRadioButton'" [checked]="form.controls.gender.value=='0'">other</mat-radio-button>
                          </mat-radio-group>
                        </section>
                      </div>
                  </div>
                  <app-error-handling [@slideIn] *ngIf="input.required != '0'" ngDefaultControl
                      [formControl]="item.controls[input.input_name]" [inputName]="input.input_name"
                      [formSubmited]="formSubmited" [customClass]="'error item-error'">
                  </app-error-handling>
              </div>

              <!-- For Old Select gender -->
              <!-- <div class="dropdown-guest-inputs section-item7-nui col-col-md-6 col-xs-6 col-sm-12"
                  *ngIf="input.input_type === 'select' && input.input_name === 'gender' && event_details.gender !== 0 && input.required == '1'">
                  <div [formGroup]="form" class="email-section-input" [ngClass]="item.valid ? 'active-section-input' : 'inactive-section-input'">
                      <label *ngIf="label" for="{{input.input_name}}">{{input.placeholder}}</label>
                      <select [mbsc-options]="genderSettings" mbsc-select class="form-control email-input guest-select-option"
                          [ngClass]="{'rtl' : dir == 'rtl'}"
                          name="{{input.input_name}}" formControlName="{{input.input_name}}"
                          id="{{input.input_name}}">
                          <option selected disabled hidden value="">
                              {{(input.input_name | i18nSelect: formInputsOthers[localId]) ? (input.input_name | i18nSelect: formInputsOthers[localId]) : (input.placeholder)}}
                          </option>
                          <option i18n="select label | gender"
                              *ngFor="let option of input.input_options; let x = index" [value]="x + 1">
                              {option.input_name,plural,
                              =0 {לא הוגדר}
                              =1 {זכר}
                              =2 {נקבה}
                              other {לא הוגדר}
                              }
                          </option>
                          <option i18n="select label | gender_other" value="0">Other</option>
                      </select>
                  </div>
                  <app-error-handling [@slideIn] *ngIf="input.required != '0'" ngDefaultControl
                      [formControl]="item.controls[input.input_name]" [inputName]="input.input_name"
                      [formSubmited]="formSubmited" [customClass]="'error item-error'">
                  </app-error-handling>
              </div> -->

              <!-- PHONE NUMBER -->
              <div class="dropdown-guest-inputs section-input section-item3-nui col-col-md-6 col-xs-6 col-sm-12"
                  *ngIf="input.input_type === 'phone' &&  input.input_name === 'user_phone' && input.custom_input">
                  <div i18n="input section | title" class="new-placeholder">Phone number*</div>
                  <div [formGroup]="form" class="email-section-input" [ngClass]="item.valid ? 'active-section-input' : 'inactive-section-input'">
                      <label *ngIf="label" for="{{input.input_name + counter}}">{{input.placeholder}}</label>
                      <input appPhoneNumber ng2TelInput inputmode="tel"
                          class="email-input phone-input-nui form-control" [ngClass]="item.valid ? 'active-section-input' : 'inactive-section-input'"
                          i18n-placeholder="placeholder | customer service contact info"
                          id="{{input.input_name + counter}}" trim="blur" type="{{input.input_type}}"
                          name="{{input.input_name + counter}}" [attr.data-cy]="input.input_name" formControlName="{{input.input_name}}"
                          (hasError)="setErrorOnphoneNumber(!$event, counter)"
                          (ng2TelOutput)="setPhoneNumber($event, counter)"
                          (intlTelInputObject)="telInputObject($event, counter, item)"
                          (countryChange)="onCountryChange($event, counter)"
                          [ng2TelInputOptions]="localizationService.getPhoneInputOptions()"
                          placeholder="{{(input.input_name | i18nSelect: formInputsOthers[localId]) ? (input.input_name | i18nSelect: formInputsOthers[localId]) : (input.placeholder)}}" />

                  </div>
                  <app-error-handling [@slideIn] *ngIf="input.required != '0'" ngDefaultControl
                      [formControl]="item.controls[input.input_name]" [inputName]="input.input_name"
                      [formSubmited]="formSubmited" [customClass]="'error item-error'">
                  </app-error-handling>
              </div>
          </ng-container>

        <!-- For instagram_url input -->
        <div class="dropdown-guest-inputs section-input instagram_url section-item8-nui col-col-md-6 col-xs-6 col-sm-12"
             *ngIf="event_details.instagram_url === 1">
             <div i18n="input section | title" class="new-placeholder">Insert account handle*</div>
          <div class="email-section-input" [formGroup]="form" [ngClass]="item.valid ? 'active-section-input' : 'inactive-section-input'">
            <label i18n="input label | instagram_url" *ngIf="label" for="instagram_url"></label>
            <!-- <img [src]="'assets/images/svg-icons/sms.svg'" alt="instagram icon"> -->
            <input class="email-input form-control" trim="blur" [ngClass]="item.valid ? 'active-section-input' : 'inactive-section-input'"
                   [id]="'instagram_url' + counter" type="text"
                   [name]="'instagram_url' + counter" [attr.data-cy]="'instagram_url'" formControlName="instagram_url"
                   placeholder="Instagram" i18n-placeholder>
          </div>
          <app-error-handling [@slideIn] *ngIf="event_details.instagram_url === 1" ngDefaultControl
                              [formControl]="item.controls['instagram_url']" [inputName]="'instagram_url'"
                              [formSubmited]="formSubmited" [customClass]="'error item-error'">
          </app-error-handling>
        </div>

          <!-- Custom inputs -->
<!--                        <ng-container *ngFor="let input of form_inputs?.custom_inputs; let i = index;">-->
<!--                            <app-form-inputs class="section-item-end" style="width: 100%;" ngDefaultControl (selectInput)="selectHandler($event)" (radio)="radioHandler(i,counter,$event)"-->
<!--                                (checkbox)="checkboxHandler(i,counter,$event)" [form]="form"-->
<!--                                [input]="input" [counter]="counter" [ageDisplay]="event.age_display" [customClass]="'mt10 mb10 col-md-12 col-xs-12'">-->
<!--                            </app-form-inputs>-->

<!--                            <app-error-handling class="section-item-end" ngDefaultControl-->
<!--                                [formControl]="item.controls[input.input_name]"-->
<!--                                [inputName]="input.input_name" [formSubmited]="formSubmited"-->
<!--                                [customClass]="'error col-md-12 col-xs-12 item-error'">-->
<!--                            </app-error-handling>-->
<!--                        </ng-container>-->

        <!-- NEW Custom inputs -->
        <ng-container *ngFor="let input of new_custom_inputs; let i = index;">
          <div *ngIf="!input.blocked" class="dropdown-guest-inputs section-input instagram_url section-item8-nui col-col-md-6 col-xs-6 col-sm-12">
            <div class="new-placeholder">{{input?.title}}*</div>
            <app-custom-form-inputs class="section-item-end" style="width: 100%;" ngDefaultControl
                                    (selectInput)="selectHandler($event)"
                                    (radio)="radioHandler(i,counter,$event)"
                                    (checkbox)="checkboxHandler(i,counter,$event)"
                                    [item]="{check: true, value: item.valid}"
                                    [form]="form"
                                    [input]="input"
                                    [counter]="counter"
                                    [customClass]="'mt10 mb10 col-md-12 col-xs-12'">
            </app-custom-form-inputs>


            <app-error-handling class="section-item-end" ngDefaultControl
                                [formControl]="item.controls[input.id]"
                                [inputName]="input.title" [formSubmited]="formSubmited"
                                [customClass]="'error col-md-12 col-xs-12 item-error'">
            </app-error-handling>
          </div>
        </ng-container>

      </div>
  </section>
</ng-template>

