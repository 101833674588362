import { environment } from './../../environments/environment';
import { DialogService } from '../services/dialog.service';
import { fade, slideIn } from './../animation';
import { Component, OnInit, HostListener, OnDestroy, AfterViewInit } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { EventService } from '../services/event.service';
import { AuthService } from '../services/auth.service';
import { GlobalsService } from '../globals';
import { AppResponse } from '../interfaces/app-response.interface';
import { ClipboardService } from 'ngx-clipboard';
import { NavbarService } from '../services/navbar.service';
import {RudderstackService} from "../services/rudderstack.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  animations: [fade, slideIn]
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  api_base_url = environment.api_base_url;
  eventsStatus = 'active';
  lang: string;
  localeId: string = '';
  dir: string;

  events = [];
  seller_events = [];
  manager_events = [];
  head_seller_events = [];
  event_info;
  affiliate_events;
  chosen_affiliate_events;
  filterText;
  noEvents = false;
  toggleShareObj = {};

  eventOptionModal: boolean = false;
  currentOptionEvent = undefined;
  salesmanAgentModal: boolean = false;
  currentAgentEvent = undefined;
  firstStatusLoad = false;

  user_id = this.authService.getUser();
  user_name = '';
  eventsStatuses = [
    {filterNameEn: 'active', code: 1},
    {filterNameEn: 'editing', code: 2},
    {filterNameEn: 'pending', code: 3},
    // {filterNameEn: 'closed', code: 4},GIR-1082
    {filterNameEn: 'deleted', code: 5},
    {filterNameEn: 'ended', code: 6},
  ];
  showSearchInput: boolean;
  showPromCat: boolean;
  isMobile: boolean;
  windowWidth = null;
  toggleByStatus = false;
  eventDefaultImg = '/assets/images/svg-icons/logo-new.svg';

  images_base_url = environment.images_base_url;
  user_info: any | null;
  user_details: any | null;
  followers = 0;
  paramsUserId: any | null;
  paramsVersion: any | null;
  sortType: 'ASC'|'DESC' = 'ASC';

  constructor(
    private dialogService: DialogService,
    public rudderstackService: RudderstackService,
    private eventService: EventService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private globals: GlobalsService,
    private clipboardService: ClipboardService,
    private navbar: NavbarService
  ) {
    this.localeId = this.globals.getLocaleId();
    this.dir = this.globals.getDir();
    const currentLangUrl = this.globals.getCurrentLangUrl();
    this.lang = (currentLangUrl) ? currentLangUrl  + '/' : '/';
  }

  ngAfterViewInit() {
    // document.getElementById('footer').style.display = 'none';
  }

  ngOnInit() {
    this.navbar.setIsNewUi(true);
    setTimeout(() => {this.navbar.noPadding = 'removePadding';}, 200);
    this.navbar.hide();
    this.activatedRoute.params.subscribe(params => {
      if(params['status']){
        this.eventsStatus = params['status'];
        this.firstStatusLoad = true;
      }
    });
    this.activatedRoute.queryParams.subscribe(params => {
      if(params['user_id']){
        this.paramsUserId = params['user_id'];
      }
      if(params['version']){
        this.paramsVersion = params['version'];
      }
    });
    this.getDashboard();
    this.user_info = this.navbar.getUserInfo();
    // console.log('getUserInfo', this.user_info);
    // console.log('queryParams', this.eventsStatus, this.paramsUserId, this.paramsVersion);
    this.authService.getUserDetails().subscribe(
      userData => {
        // console.log('getUserDetails', this.user_details, userData);
        if (userData && userData.user_id) {
          this.user_details = userData;
          this.followers = userData.follower_counter;
        }
      },
      error => {
        this.dialogService.alertsModal('errors', error.code, 3000, false);
        console.log('dashboard error auth', error);
      });
    this.checkIsMobile();
    this.rudderstackService.page('Event management','My Events');
  }
  ngOnDestroy() {
    this.navbar.noPadding = '';
    this.navbar.setIsNewUi(false);
  }

  @HostListener('window:resize', ['$event']) onResizeWindow(event) {
    this.checkIsMobile();
  }

  checkIsMobile() {
    this.windowWidth = window.innerWidth;
    this.isMobile = this.windowWidth <= 768;
  }

  closeMyEvents() {
    this.navbar.show();
    this.router.navigate(['/']);
  }

  searchFilter(value) {
    console.log('searchFilter', value);
  }

  toggleEventByStatus() {
    console.log('toggleEventByStatus');
    this.toggleByStatus = true;
  }

  closeEventStatuses() {
    this.toggleByStatus = false;
  }

  toggleSearch() {
    this.showSearchInput = !this.showSearchInput;
  }

  createEvent() {
    this.user_info = this.navbar.getUserInfo();
    if (this.user_info && !this.user_info.personal_info) {
      // console.log('checkUser and open personal info');
      this.dialogService.personalInfo(this.user_info, false);
    } else {
      this.eventService.createNewEvent()
      .subscribe(
        (response: AppResponse) => this.router.navigate(['/edit-event/' + response.data.event_id]),
        (error: AppResponse) => this.dialogService.alertsModal('errros', (error.code) ? error.code : '4.1', 5000, false)
      );
    }
  }

  // accepts the users selection from html:
  getEventsByStatus(value) {
    console.log('getEventsByStatus', value);
    this.eventsStatus = value;
    this.toggleShareObj = {};
    this.router.navigate(['/dashboard', this.eventsStatus]);
    this.toggleByStatus = false;
    if(this.firstStatusLoad){
      this.getDashboard();
    }else{
      this.firstStatusLoad = false;
    }
  }


  getDashboard(){

    // API call to get data:
    this.eventService.getDashboard(this.eventsStatus, this.paramsUserId, this.paramsVersion)
      .subscribe(
        eventData => {
          this.user_name = eventData.user_name,
          this.events = eventData.events,
          this.seller_events = eventData.seller_events,
          this.manager_events = eventData.manager_events,
          this.head_seller_events = eventData.head_seller_events;
          this.sortASC();

          if (eventData && eventData.events && eventData.events.length > 0) {
            this.noEvents = false;
          } else if (eventData && eventData.seller_events && eventData.seller_events.length > 0) {
            this.noEvents = false;
          } else {
            this.noEvents = true;
          }

        },
        error => {}

      );
  }

  onCreateEvent(){
    this.user_info = this.navbar.getUserInfo();
    if (this.user_info && !this.user_info.personal_info) {
      // console.log('checkUser and open personal info');
      this.dialogService.personalInfo(this.user_info, false);
    } else {
      this.eventService.createNewEvent()
      .subscribe(
        (response: AppResponse) => this.router.navigate(['/edit-event/' + response.data.event_id]),
        (error: AppResponse) => this.dialogService.alertsModal('errros', (error.code) ? error.code : '4.1', 5000, false)
      );
    }
  }

	copyUrl(the_url){
		this.clipboardService.copyFromContent(the_url);
    this.clipboardService.destroy();
		this.dialogService.alertsModal('success', 'copied', 2000, true)
	}

  openEventMenu(event){
    this.eventOptionModal = true;
    this.currentOptionEvent = event;
  }
  closeEventMenu(){
    this.eventOptionModal = false;
    this.currentOptionEvent = undefined;
  }

  openAgentModal(event){
    this.salesmanAgentModal = true;
    this.currentAgentEvent = event;
  }

  closeAgentModal(){
    this.salesmanAgentModal = false;
    this.currentAgentEvent = undefined;
  }

  updateAgentStatus(event, action){
    this.globals.startLoader();
    this.closeAgentModal();
    if(action && event.event_id && event.salesman_id && event.event_seller){
      this.eventService.updateAgentStatus(action, event.event_id, event.salesman_id, event.event_seller).subscribe(
        (response: any)=>{
          this.globals.stopLoader();
          if(response.success && response.action_made === 'approve'){
            if(event.type === 3) {
              var currentEvent = this.manager_events.find((e) => {
                return e.event_id === event.event_id;
              });
            } else if(event.type === 2){
              var currentEvent = this.head_seller_events.find((e) => {
                return e.event_id === event.event_id;
              });
            } else if(event.type === 1){
              var currentEvent = this.seller_events.find((e) => {
                return e.event_id === event.event_id;
              });
            }
            currentEvent.agt_status = 1
          } else if(response.success && response.action_made === 'decline'){
            if(event.type === 3) {
              this.manager_events = this.manager_events.filter((value)=>{
                return value.event_id !== event.event_id
              });
            } else if(event.type === 2){
              this.head_seller_events = this.head_seller_events.filter((value)=>{
                return value.event_id !== event.event_id
              });
            } else if(event.type === 1){
              this.seller_events = this.seller_events.filter((value)=>{
                return value.event_id !== event.event_id
              });
            }
          } else {
            this.dialogService.alertsModal('errors', 4.1, 3000, false);
          }
        },
        (error)=> {
          this.globals.stopLoader();
          this.dialogService.alertsModal('errors', (error.code) ? error.code : 4 ,5000, false, error.log);
        }
      );
    } else {
      this.globals.stopLoader();
      this.dialogService.alertsModal('errors', 4.1, 3000, false);
    }
  }

  openEventDetailsModal(event){
    this.closeEventMenu();
    this.dialogService.eventDetailsModal(
      event.event_id,
      (event.salesman_id) ? event.salesman_id : 0,
      (event.type) ? event.type : 0,
      (event.event_seller) ? event.event_seller : 0
    );
  }

  removeEvent(event){
    this.closeEventMenu();
    this.dialogService.areUSureModal('deleteEventPlusTitle', event.title).subscribe(
      (response: boolean) => {
        if(response === true){
          this.eventService.removeEvent(event.event_id).subscribe(
            (response: any) => {
              if(response.status === 5) {
                if(event.type === 3) {
                  this.manager_events = this.manager_events.filter((value)=>{
                    return value.event_id !== event.event_id
                  });
                } else if(event.type === 2){
                  this.head_seller_events = this.head_seller_events.filter((value)=>{
                    return value.event_id !== event.event_id
                  });
                } else if(event.type === 1){
                  this.seller_events = this.seller_events.filter((value)=>{
                    return value.event_id !== event.event_id
                  });
                } else {
                  this.events = this.events.filter((value)=>{
                    return value.event_id !== event.event_id
                  });
                }
              } else {
                this.dialogService.alertsModal('errors', 4.1, 3000, false);
              }
            },
            (error) => {
              this.dialogService.alertsModal('errors', 4.1, 3000, false);
            }
          );
        }
      }
    );
  }

  toggleShare(event_id){
		if(!this.toggleShareObj.hasOwnProperty(event_id)) return this.toggleShareObj[event_id] = true;
		else if (this.toggleShareObj.hasOwnProperty(event_id)) return this.toggleShareObj[event_id] = !this.toggleShareObj[event_id];
  }

  eventsCount(eventStatus) {
    const allEventsCount = this.events.length + this.seller_events.length + this.manager_events.length + this.head_seller_events.length;
    switch (eventStatus) {
      case 'active':
        return allEventsCount
        break;
      case 'ended':
        return allEventsCount
        break;
      default:
        return this.events.length
    }
  }

  linkParams(event, type = null) {
    const params = {
      encodedUrl: null,
      queryParams: null
    }

    if (event.status === 1) {
      params.encodedUrl = event.url_friendly ? '/' + encodeURIComponent(event.url_friendly) : `/${event.event_id}`;
      if (type === 'seller_events') {
        params.queryParams = {seller_code: event.code}
      }
    } else {
      params.encodedUrl = event.url_friendly ? '/preview/' + encodeURIComponent(event.url_friendly) : '/preview/' + event.event_id;
    }

    return params
  }

  copyStripeConnectAccount(event_id) {
    this.globals.startLoader();
    this.eventService.getStripeAccount(event_id).subscribe((res: any)=> {
      if (res.data && res.data.url) {
        window.open(res.data.url, '_blank');
      }
      this.globals.stopLoader();
    }, error => {
      this.globals.stopLoader();
      this.dialogService.alertsModal('errors', 4.1, 3000, false);
    });
  }
  // ASC/DESC sorting
  sort() {
    this.sortType === 'ASC' ? this.sortType = 'DESC' : this.sortType = 'ASC';
    // console.log(this.sortType, this.events)
    switch (this.sortType) {
      case 'ASC':
        this.sortASC();
        // console.log('ASC',JSON.stringify(this.events));
        break;
      case 'DESC':
        this.sortDESC();
        // console.log('DESC',JSON.stringify(this.events));
        break;
      default:
        break;
    }
  }
  sortASC() {
    if(this.events) {
      this.events.sort((b, a) => <any>new Date(b.start_date).getTime() - <any>new Date(a.start_date).getTime());
    }
    if(this.seller_events) {
      this.seller_events.sort((b, a) => <any>new Date(b.start_date).getTime() - <any>new Date(a.start_date).getTime());
    }
    if(this.manager_events) {
      this.manager_events.sort((b, a) => <any>new Date(b.start_date).getTime() - <any>new Date(a.start_date).getTime());
    }
    if(this.head_seller_events) {
      this.head_seller_events.sort((b, a) => <any>new Date(b.start_date).getTime() - <any>new Date(a.start_date).getTime());
    }
  }
  sortDESC() {
    if(this.events) {
      this.events.sort((a, b) => <any>new Date(b.start_date).getTime() - <any>new Date(a.start_date).getTime());
    }
    if(this.seller_events) {
      this.seller_events.sort((a, b) => <any>new Date(b.start_date).getTime() - <any>new Date(a.start_date).getTime());
    }
    if(this.manager_events) {
      this.manager_events.sort((a, b) => <any>new Date(b.start_date).getTime() - <any>new Date(a.start_date).getTime());
    }
    if(this.head_seller_events) {
      this.head_seller_events.sort((a, b) => <any>new Date(b.start_date).getTime() - <any>new Date(a.start_date).getTime());
    }
  }

}


