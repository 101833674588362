import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ViewEncapsulation,
  ViewContainerRef,
  ComponentFactoryResolver,
  NgModuleRef,
  createNgModule, Injector, ComponentRef,
} from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BankInfoModalComponent } from './bank-info-modal/bank-info-modal.component';
import { PayoutModalComponent } from './payout-modal/payout-modal.component';
import { GlobalsService } from '../../globals';
import { BankService } from '../../services/bank.service';
import { StripePayoutService } from '../../services/stripe-payout.service';
import { PlaidService } from '../../services/plaid.service';
import {DialogService} from "../../services/dialog.service";
import {
  StripeConnectEmbeddedModule
} from "../stripe-connect-embedded/stripe-connect-embedded.module";
import {timer} from "rxjs";
import {scan, takeWhile} from "rxjs/operators";
import {NavbarService} from "../../services/navbar.service";

type StripeConnectResponseDataNotReady = {
  stripe_connect_request?: boolean;
};
type StripeConnectResponseDataReady = {
  stripe_connect_request?: boolean;
  stripe_connect_request_countries_list_link?: string;
  countries: string[];
};
type StripeConnectResponseDataRequestPendingOrCompleted = {
  record?: {
    status: 0 | 1; // 0 == pending, 1 == active
    activate_url: string;
  } & StripeConnectResponseDataReady;
  stripe_connect_request_countries_list_link?: string;
};

type StripeConnectResponseData = StripeConnectResponseDataNotReady &
  StripeConnectResponseDataReady &
  StripeConnectResponseDataRequestPendingOrCompleted;

type StripeConnectResponse = {
  data: StripeConnectResponseData;
  code: 'success' | number;
};

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FinanceComponent implements AfterViewInit {
  bank: any = null;
  bFetched: boolean = false;
  stripeStatus: any = null;
  stripeConnectAllowed: boolean = null;
  stripeConnectData: any = null;
  stripeConnectPending: boolean = false;
  stripeConnectCompleted: boolean = false;
  plaidConnectData: any = null;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild("payments", {read: ViewContainerRef}) payments!: ViewContainerRef;
  @ViewChild("payouts", {read: ViewContainerRef}) payouts!: ViewContainerRef;
  ref:ComponentRef<any>;
  retryTime: number = 0;
  renderedComponent: string = '';
  payment_stripe_connect_accounts: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    public globals: GlobalsService,
    private bankService: BankService,
    private dialogRef: MatDialogRef<PayoutModalComponent>,
    private plaidService: PlaidService,
    private stripePayoutService: StripePayoutService,
    private dialogService: DialogService,
    private injector: Injector,
    public nav: NavbarService,
  ) {

  }

  ngOnInit(): void {
    this.getBank();
    this.getStripeStatus();
    this.getPlaidStatus();

    // FOR PLAID REDIRRECTS
    // this.plaidService.plaidRedirectSubj.subscribe(res=> {
    //   console.log('plaidRedirectSubj',res);
    //   if(res) {
    //     this.formBootstrap(); // for plaid
    //   } else {
    //     this.dialogService.choiseDataSource().subscribe((flagToGetEventData: boolean) => {  // default behaviour
    //       if (flagToGetEventData) {
    //         this.bankData = null;
    //         this.reinitBank();
    //         this.getEventData();
    //       } else {
    //         this.formBootstrap();
    //       }
    //     });
    //   }
    // })

    this.plaidService.plaidTokenExchangeSubj.subscribe((bank)=> {
      console.log('plaidTokenExchange Finance',bank);
      if(bank) {
        this.bank = bank;
      }
    })
    this.stripePayoutService.createRequestSuccessSubj.subscribe(res => {
      // console.log(res);
      if(res.country) {
        this.bank['country'] = res.country;
      }
      if(res.url) {
        // this.getStripeStatus(true);
      }
    });
    this.nav.paymentStripeConnectAccounts.subscribe(
      (payment_stripe_connect_accounts) => this.payment_stripe_connect_accounts = payment_stripe_connect_accounts);
  }

  ngAfterViewInit() {
  }

  getBank() {
    this.bankService.getBankInfo().subscribe((value: any) => {
      this.bank = value.data;
      this.bFetched = true;
    });
  }

  getStripeStatus(openBankInfoModal = false) {
    this.globals.startLoader();
    this.stripePayoutService
      .checkStatus()
      .subscribe(({ data, code }: StripeConnectResponse) => {
        if (code === 'success') {
          console.log('stripe status', data);

          // User already started onboarding stripe
          if (data?.record) {
            switch (data.record.status) {
              case 0:
                this.stripeConnectPending = true;
                break;
              case 1:
                this.stripeConnectCompleted = true;
                // TODO: show success connection dialog
                this.activatedRoute.url.subscribe(url => {
                  if (url[1]?.path === 'stripe') {
                    console.log('stripe success');
                  }
                });
                break;
            }
          }

          // User can use Stripe as Payout method
          this.setStripeState(data);
          if(openBankInfoModal) {
            this.openBankInfoModal();
          }
        }
        this.globals.stopLoader();
      });
  }

  setStripeState(data) {
    this.stripeStatus = data;
    this.stripeConnectAllowed =
      data?.stripe_connect_request || !!data?.record;

    this.stripeConnectData = {
      data,
      ...{
        stripeConnectDisabled: !this.stripeConnectAllowed,
        stripeConnectPending: this.stripeConnectPending,
        stripeConnectCompleted: this.stripeConnectCompleted,
        stripeConnectRequestCountriesListLink:
        data?.stripe_connect_request_countries_list_link,
        stripeConnectOnboardingLink: data?.record?.activate_url,
      },
    };
  }

  resetStripeConnect() {
    this.stripeConnectPending = false;
    this.stripeConnectCompleted = false;
  }
  getPlaidStatus() {
    this.globals.startLoader();
    this.plaidService.checkStatus().subscribe((res: any) => {
      if (res.code === 'success') {
        if (res.data) {
          this.plaidConnectData = res.data;
          console.log('plaid status', this.plaidConnectData)
        }
      }
      this.globals.stopLoader();
    })
  }


  payout(type = 'request', row, i) {
    console.log(row, i);
    this.dialog.open(PayoutModalComponent, {
      data: {
        type: type,
      },
      panelClass: 'bulk-mint-modal',
      backdropClass: 'mint-modal-bc',
      maxWidth: '500px',
    });
  }

  openBankInfoModal() {
    const bank = this.bank;

    // if stripeConnect status is not "ready", wait 50ms
    // if (this.stripeConnectAllowed === null) {
    //   setTimeout(() => {
    //     this.openBankInfoModal();
    //   }, 50);
    // }

    const dialogRef = this.dialog.open(BankInfoModalComponent, {
      data: { bank: bank, ...this.stripeConnectData, plaid: this.plaidConnectData },
      panelClass: 'bank-info-modal',
      backdropClass: 'mint-modal-bc',
      maxWidth: '612px',
    });

    dialogRef.afterClosed().subscribe(data => {
      console.log('afterClosed', data);
      // Update bank after save or after Delete Stripe Account
      // If data exist
      if (data !== undefined) {
        if(data?.stripe_connect_request == false || data?.stripe_connect_request == true) { // After Stripe delete action (status 0 and country changed)
          this.resetStripeConnect(); // set to false some variables
          this.setStripeState(data); // data used from Delete Stripe Account response
          this.bFetched = true; // starting point on Finance page
          this.bank = null; // starting point on Finance page
          this.openBankInfoModal();
          this.dialogService.alertsModal('success', 'bankServiceStripeCountryChange', 5000, true);
        } else {
          this.bank = data;
        }
        console.log('afterClosed', data)
      }

    });
  }

  async loadEmbededComponent(type) {
    // this.payouts.element.nativeElement.innerHTML = '';
    // this.payments.element.nativeElement.innerHTML = '';


    if(this.retryTime > 0 || (this.renderedComponent == type)) {
      return
    }
    // Delay Timer
    const result$ = timer(0, 1000).pipe(
      scan(ticks => --ticks, 10),
      takeWhile(v => v >= 0)
    ); // 10 s Retry delay
    result$.subscribe(res => {
      this.retryTime = res;
      console.log('res', this.retryTime);
    })
    // Delay Timer End

    this.globals.startLoader();
    if(this.ref) {
      this.ref.destroy();
    }
    const {StripeConnectEmbeddedModule} = await import('../stripe-connect-embedded/stripe-connect-embedded.module');
    const {StripeConnectEmbeddedComponent} = await import('../stripe-connect-embedded/stripe-connect-embedded.component');
    const moduleRef: NgModuleRef<any> = createNgModule(StripeConnectEmbeddedModule, this.injector);
    let componentRef = this[type].createComponent(StripeConnectEmbeddedComponent, {ngModuleRef: moduleRef});
    componentRef.instance.component_type = type;
    this.ref = componentRef;
    this.renderedComponent = type;
    // this[type].createComponent(StripeConnectEmbeddedComponent, {ngModuleRef: moduleRef}).instance.component_type = 'payouts';
  }
}
