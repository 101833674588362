<ng-container *ngIf="(data.type === 0 || data.type === 3 || data.type === 4);else other_content">
  <div class="main-tickets-container">
    <div class="main-container">
      <div class="events-stat">
        <div class="events-type-container">
          <div class="events-type-name" [ngStyle]="{'justify-content': 'flex-start'}">{{'analytic-tickets.TODAY' | translate:'TODAY'}}</div>
          <div class="events-type-number">{{data.main.total_today | currency: data.event_currency:'symbol':'1.': getLocale(data.event_currency)}}</div>
        </div>
      </div>
      <div class="events-stat">
        <div class="events-type-container">
          <div class="events-type-name" [ngStyle]="{'justify-content': 'space-between'}">{{'analytic-tickets.TICKETSSOLD' | translate:'TICKETS SOLD'}}</div>
          <div class="events-type-number">{{data.main.total_tickets_sold}}</div>
        </div>
      </div>
      <div class="events-stat">
        <div class="events-type-container">
          <div class="events-type-name" [ngStyle]="{'justify-content': 'space-between'}">{{'analytic-tickets.TOTALFACEVALUE' | translate:'TOTAL FACE VALUE'}}</div>
          <div class="events-type-number">{{data.main.total_face_value | currency: data.event_currency:'symbol':'1.':getLocale(data.event_currency)}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="tickets-list">
    <div class="ticket" *ngFor="let t of data.tickets; let i = index">
      <div class="ticket-name">
        <p class="name">{{t.name}}</p>
        <p #feesTooltip><span class="price">{{t.price | currency: data.event_currency:'symbol':'1.':getLocale(data.event_currency)}}</span>
          <span class="fees-txt" *ngIf="t.reg != 1"> +
            <span style="text-decoration: underline; cursor: pointer;"
                  (click)="toggleBox(i)"
            >{{'analytic-tickets.fees' | translate:'fees'}}</span>
            <div class="feesTooltip">
              <p *ngIf="+data.event_info.purchaser_commission">{{'analytic-tickets.Purchasefee' | translate:'Purchase fee (%):'}} {{+data.event_info.purchaser_commission}}</p>
              <p *ngIf="+data.event_info.refund_commission">{{'analytic-tickets.Refundfee' | translate:'Refund fee (%):'}} {{+data.event_info.refund_commission}}</p>
              <p *ngIf="+data.event_info.tax_commission">{{'analytic-tickets.Tax' | translate:'Tax (%):'}} {{+data.event_info.tax_commission}}</p>
              <p *ngIf="+data.event_info.service_commission">{{'analytic-tickets.Servicefee' | translate:'Service fee (%):'}} {{+data.event_info.service_commission}}</p>
              <p *ngIf="+data.event_info.platform_fee">{{'analytic-tickets.Platformfee' | translate:'Platform fee:'}} {{+data.event_info.platform_fee | currency: data.event_currency:'symbol':'1.':getLocale(data.event_currency)}}</p>
              <p *ngIf="+data.event_info.commission">{{'analytic-tickets.Producerfee' | translate:'Producer fee (%):'}} {{+data.event_info.commission}}</p>
            </div>
          </span>
        </p>
        <p class="sold" *ngIf="!t.remaining">{{'analytic-tickets.SoldOut' | translate:'Sold Out'}}</p>
      </div>
      <div class="ticket-progress">
        <mat-progress-bar mode="determinate" value="{{t.count_ticket_approved*100/t.quantity}}"></mat-progress-bar>
        <div class="progress-total">
          <div class="progress-total-qty">{{t.count_ticket_approved}}/{{t.quantity}}</div>
          <div class="progress-total-remaining">
            <p *ngIf="data.main?.live_cart_mode && t.live_cart_count">{{t.live_cart_count}} {{'analytic-tickets.inLiveCart' | translate:'in Live Cart'}}</p>
            <p *ngIf="t.count_ticket_waiting_list">{{t.count_ticket_waiting_list}} {{'analytic-tickets.onWaitingList' | translate:'on Waiting List'}}</p>
            <p *ngIf="t.remaining" >{{t.remaining}} {{'analytic-tickets.remaining' | translate:'remaining'}}</p>
          </div>
        </div>
        <button class="ticket-approve-waiting-list-btn"
                *ngIf="t.count_ticket_waiting_list && data.show_approve_waiting_list"
                (click)="showApproveWaitingListModal(t.id, t.name, t.price, data.event_currency, t.count_ticket_waiting_list)">{{'analytic-tickets.ApproveWaitingList' | translate:'Approve Waiting List'}}</button>
      </div>
      <div class="ticket-total">
        <div>
          <p class="ticket-total-title">{{'analytic-tickets.TOTALFACEVALUE' | translate:'TOTAL FACE VALUE'}}</p>
          <p class="ticket-total-price">{{t.total_face_value | currency: data.event_currency:'symbol':'1.':getLocale(data.event_currency)}}</p>
        </div>
        <div *ngIf="t.total_waiting_list_value && t.total_waiting_list_value !== '0.00' ">
          <p class="ticket-total-title" style="margin-top: 30px;">{{'analytic-tickets.WAITINGLIST' | translate:'WAITING LIST'}}</p>
          <p class="ticket-total-price">{{t.total_waiting_list_value | currency: data.event_currency:'symbol':'1.':getLocale(data.event_currency)}}</p>
        </div>
      </div>
    </div>
  </div>

</ng-container>

<ng-template #other_content><p style="text-align: center;">{{'analytic-tickets.NoData' | translate:'No data to display...'}}</p></ng-template>
