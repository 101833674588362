import { SnackBarMessageComponent } from './../snack-bar-message/snack-bar-message.component';
import { InfoButtonDialogComponent } from '../create-edit-event/create-edit-event-dialogs/info-button-dialog/info-button-dialog.component';
import { DataSourceDialogComponent } from '../create-edit-event/create-edit-event-dialogs/data-source-dialog/data-source-dialog.component';
import { ErrorCodesService } from '../code-strings/error_codes';
import { GlobalsService } from '../globals';
import { AuthService } from './auth.service';
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavbarService } from './navbar.service';
import { PersonalInfoModalComponent } from '../personal-info/personal-info-modal/personal-info-modal.component';
import { AreUSureModalComponent } from '../shared-dialogs/are-u-sure-modal/are-u-sure-modal.component';
import { EventDetailsModalComponent } from '../shared-dialogs/event-details-modal/event-details-modal.component';
import { UserAlertsModalComponent } from '../shared-dialogs/user-alerts-modal/user-alerts-modal.component';
import { SuccessCodesService } from '../code-strings/success_codes';
import { SalesmanStatsBreakdownComponent } from '../shared-dialogs/salesman-stats-breakdown/salesman-stats-breakdown.component';
import { SoundCloudUrlDialogComponent } from '../create-edit-event/create-edit-event-dialogs/sound-cloud-url-dialog/sound-cloud-url-dialog.component';
import { TermsDialogComponent } from '../create-edit-event/create-edit-event-dialogs/terms-dialog/terms-dialog.component';
import { ChangeSellerModalComponent } from '../shared-dialogs/change-seller-modal/change-seller-modal.component';
import { FacebookDeclinedPermissionsComponent } from '../shared-dialogs/facebook-declined-permissions/facebook-declined-permissions.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ShowTxtMessageModalComponent} from '../shared-dialogs/show-txt-message-modal/show-txt-message-modal.component';

@Injectable()
export class DialogService {

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private router: Router,
    private globals: GlobalsService,
    private nav: NavbarService,
    private errorCodeService: ErrorCodesService,
    private successCodeService: SuccessCodesService,
    private _snackBar: MatSnackBar
    ) {}

  personalInfo(modalData, token?: boolean) {
    // console.log('personalInfo', token, modalData);
    const personalModal = this.dialog.open(PersonalInfoModalComponent, {
      data: token ? modalData.user_data : modalData,
      panelClass: 'personalInfoModal',
      backdropClass: 'personalInfoBackdrop',
      // maxHeight: '85vh',
      disableClose: false,
      autoFocus: false,
      maxWidth: '100%',
      maxHeight: '100%',
      height: '100%',
      width: '100%',
    });

    personalModal.afterClosed().subscribe(result => {
      // console.log('afterClosed', result, token);
      if (result !== undefined) {
        if (token) { this.authService.storeTokens(modalData.auth.session, modalData.auth.refresh) };
        this.authService.updateUserDetails(result).subscribe(
          (response: any) => {
            // console.log('updateUserDetails response', response);
            this.authService.saveUserCookies(response.response.user_info, token ? modalData.original.facebook_id : response.response.user_info.facebook_id);
            this.alertsModal('success', 'success', 5000, true);
            this.globals.stopLoader();
            this.router.navigate(['/dashboard']);
            this.nav.checkUser();
            if (token) {this.nav.UpdateUser(parseInt(modalData.original.uid), response.response.user_info.type, modalData.original.facebook_id, false)};
          },
          error => {
            this.authService.logout();
            this.globals.stopLoader();
            this.alertsModal('errors', 2, 5000, false);
          }
        );
      } else {
        if (token) { this.authService.storeTokens(modalData.auth.session, modalData.auth.refresh)};
        this.nav.checkUser();
        return false;
      }
    });
  }

  eventDetailsModal(event_id:number, salesman_id: number, type: number, event_salesman: number) {
    var data = {event_id,salesman_id,type,event_salesman};
    this.dialog.open(EventDetailsModalComponent, {
      data: data,
      autoFocus: false,
      panelClass: 'eventDetailsModal'
    });
  }

  areUSureModal(textCode: string, extrasStr?: string) {
    var data = {textCode, extrasStr};
    let dialogRef = this.dialog.open(AreUSureModalComponent, {
      data: data,
      autoFocus: false,
      panelClass: 'areUSUreModal'
    });
    return dialogRef.afterClosed();
  }

  alertsModal(successOrErrorGroup: string, successOrErrorCodes: any, timeout: number, type: boolean, log_id?: number) {

    let alertsArr = [];
    let alertFile = (type) ? 'successCodeService' : 'errorCodeService';

		if(successOrErrorCodes instanceof Array) {
			for(let alert of successOrErrorCodes) {
        console.log(alert);
        if(alert instanceof Object) {
          let alertTxt = this[alertFile].getAlertStr(successOrErrorGroup, alert.code);
          alertsArr.push({code: alertTxt, codeData: alert.codeData});
        } else {
          let alertTxt = this[alertFile].getAlertStr(successOrErrorGroup, alert);
          alertsArr.push(alertTxt);
        }
			}
		} else {
			const alertTxt = this[alertFile].getAlertStr(successOrErrorGroup, successOrErrorCodes);
			alertsArr.push(alertTxt);
    }


    let data = {alertsArr, timeout, type, log_id, successOrErrorGroup, successOrErrorCodes};

    this.dialog.open(UserAlertsModalComponent, {
      data: data,
      autoFocus: false,
      panelClass: (type) ? 'userAlertsModalSuccess' : 'userAlertsModalError',
      maxWidth: '90vw',
      maxHeight: '90vh',
      // width: '90vw',
      // height: '90vh',
      // maxWidth: '1367px',
    });
  }

  salesmanStatsBreakdownDialog(salesmanData: any, extraData: any) {
    const data = {'salesmanData': salesmanData, 'extraData': extraData};
    let dialogRef = this.dialog.open(SalesmanStatsBreakdownComponent, {
      data: data,
      autoFocus: false,
      panelClass: 'salesmenBreakdown'
    });
    return dialogRef.afterClosed();
  }

  soundCloudDialog() {
    let dialogRef = this.dialog.open(SoundCloudUrlDialogComponent, {
      autoFocus: false,
      panelClass: 'soundCloud'
    });

    return dialogRef.afterClosed();
  }

  termsDialog() {
    let dialogRef = this.dialog.open(TermsDialogComponent, {
      autoFocus: false,
      panelClass: 'terms',
      maxWidth: '100vw'
    });

    return dialogRef.afterClosed();
  }

  choiseDataSource() {
    let dialogRef = this.dialog.open(DataSourceDialogComponent, {
      autoFocus: false,
      panelClass: 'dataSource',
      disableClose: true
    })

    return dialogRef.afterClosed();
  }

  infoDialog(data) {
    let dialogRef = this.dialog.open(InfoButtonDialogComponent, {
      data,
      autoFocus: false,
      panelClass: 'info'
    });
  }

  changeSellerDialog(data) {
    let dialogRef = this.dialog.open(ChangeSellerModalComponent, {
      data
    });
    return dialogRef.afterClosed();
  }

  handleFbDeclinedPermissions() {
    let dialogRef = this.dialog.open(FacebookDeclinedPermissionsComponent, {
      data: {},
      autoFocus: false,
      panelClass: 'areUSUreModal'
    });
    return dialogRef.afterClosed();
  }

  openSnackBarFromComponent(text, component = SnackBarMessageComponent, duration = 3000) {
    	this._snackBar.openFromComponent(component, {
			duration: duration,
			data: text
	});
  }

  txtMsgModal(message, timeout: number, type: boolean) {

    let data = {message, timeout, type};
    this.dialog.open(ShowTxtMessageModalComponent, {
      data: data,
      autoFocus: false,
      panelClass: (type) ? 'userAlertsModalSuccess' : 'userAlertsModalError',
      maxWidth: '90vw',
      maxHeight: '90vh',
    });
  }

}
