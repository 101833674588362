import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ClipboardService} from "ngx-clipboard";
import {DialogService} from "../../services/dialog.service";

@Component({
  selector: 'app-show-txt-message-modal',
  templateUrl: './show-txt-message-modal.component.html',
  styleUrls: ['./show-txt-message-modal.component.css']
})
export class ShowTxtMessageModalComponent implements OnInit {
  message: string;
  timeout: number;
  type: boolean;

  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data,
              private clipboardService: ClipboardService, private dialogService: DialogService) {
    if (this.data) {
      this.message = data.message;
      this.timeout = data.timeout;
      this.type = data.type;
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
        this.dialogRef.close(true);
      }, this.timeout
    );
  }

  copyMsg(){
      this.clipboardService.copyFromContent(this.message);
      this.clipboardService.destroy();
      this.dialogService.alertsModal('success', 'couponCopied', 2000, true);
  }

}
