import { FacebookService } from './../../services/facebook.service';
import { DialogService } from '../../services/dialog.service';
import { FacebookPixelModel } from './../../facebook-pixel-model';
import { NavbarService } from '../../services/navbar.service';
import {Component, OnInit, Inject, Renderer2, OnDestroy, HostListener} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventService } from '../../services/event.service';
import { DOCUMENT } from '@angular/common';
import { GlobalsService } from '../../globals';
import { ErrorCodesService } from '../../code-strings/error_codes';
import {NftSuccessModalComponent} from '../../dashboard-sub-pages/my-tickets/nft-success-modal/nft-success-modal.component';
import {MatDialog} from '@angular/material/dialog';
import { NftThankYouModalService } from '../../services/nft-thank-you-modal.service';
import {environment} from '../../../environments/environment';
import {NftService} from '../../services/nft.service';
import {PaytweedService} from '../../services/paytweed.service';
import {RudderstackService} from '../../services/rudderstack.service';
declare var window: any;

@Component({
  selector: 'app-successful-order',
  templateUrl: './successful-order.component.html',
  styleUrls: ['./successful-order.component.css']
})

export class SuccessfulOrderComponent implements OnInit,OnDestroy {

	event_id;
	order_status;
	payment_method;
	purchaser_type;
	purchase_validation;
	tranzilla_response: string;
	tranzilla_error: string;
	redirect: boolean = true;
	nav;
	producer_credit: number;
	event_pixel: number;
  event_pixels: number[] = [];
	currency: String;
  // purchase_user_id = '';
  main_purchase_identifier = '';
  isNft: boolean = false;
  party_emoji = '&#127881;';
  eventInfo = null;
  public baseEventImgUrl = environment.images_base_url + '/gallery/mobile';

  api_base_url = environment.api_base_url;

  timeoutIDs: any[] = []; // for redirects after n second
  purchase_user_ids: [];
  waiting_list_purchase_ticket: number = 0;
  purchaseInfoFetched:boolean = false;
  add_ticket_to_apple_wallet: string = '';

	constructor(
		private navbar: NavbarService,
		private activatedRoute: ActivatedRoute,
		private eventService: EventService,
		private router: Router,
		private _renderer2: Renderer2,
		@Inject(DOCUMENT) private _document,
		private globals : GlobalsService,
		private fb_pixel_model: FacebookPixelModel,
		private dialogService: DialogService,
		private errorCodes: ErrorCodesService,
    private facebookService: FacebookService,
    // private dialog: MatDialog,
    private nftService: NftService,
    private paytweedService: PaytweedService,
    private nftThankYouDialog: NftThankYouModalService,
    private rudderstackService: RudderstackService) {
		this.nav = this.router.routerState.snapshot.root.queryParams.nav;
		//Hide navbar when query string nav=false
		if(this.nav === 'false'){
			this.nav = false;
			this.navbar.hide();
			this.navbar.hideHeader();
		}
		this.navbar.customClass = "blackBackGround";
		}

  @HostListener('window:beforeunload', ['$event'])
  tabClose($event) {
    $event.preventDefault();
    return ($event.returnValue = "Prevent tab closing!");
  }

	ngOnInit() {

		this.activatedRoute.params.subscribe(params => {
			// console.log('activatedRoute', params);
			this.order_status = +params['status'];
			this.payment_method = +params['payment_method'];
			this.purchaser_type = +params['purchaser_type'];
			this.purchase_validation = +params['purchase_validation'];
			this.event_id = +params['event_id'];
			this.tranzilla_response = params['tranzilla_response'];
			this.redirect = (params['redirect'] === '1') ? true : false;
			this.producer_credit = +params['producer_credit'];
			this.currency = params['currency'];
		});


    this.activatedRoute.queryParams.subscribe(params => {
      // if (params['purchase_user_id']) {
      //   this.purchase_user_id = params['purchase_user_id'];
      // }
      if (params['main_purchase_identifier']) {
        this.main_purchase_identifier = params['main_purchase_identifier'];
      }
    });

		if(this.order_status == 2) {
			this.tranzilla_error = (typeof this.errorCodes.tranzilla[this.tranzilla_response] !== 'undefined') ? this.errorCodes.tranzilla[this.tranzilla_response] : '';
		}
    if(this.order_status !== 2 && this.order_status !== 3) {
      this.setFaceBookPixel();
    }
		// redirect to feed after X seconds:
		if(this.redirect != false){
			// console.log('redirect', this.event_id);
      this.timeoutIDs.push(
        setTimeout((router: Router) => {
          if (this.event_id == 159494 || this.event_id == 5778) { // temporary changes (19.10.21) task: "redirect-to-producer-site" - redirect all users to the specified url after purchase tickets
            const url = 'https://dayzerofestival.com/locations/tulum/tulum-thank-you-mini/';
            window.location.replace(url);
          } else {
            this.navigateTo('/')
          }
        }, (this.purchase_validation === 2 && this.main_purchase_identifier && this.globals.isIosNew()) ? 900000 : 500000)
      );
		}
	}

  getPurchaseInfo(): any {
    // console.log('getPurchaseInfo');
    return new Promise((resolve, reject) => {
      let purchaseInfo = null;
      this.facebookService.getPixelParams({main_purchase_identifier: this.main_purchase_identifier}).subscribe(async (params: any) => {
        if (params && params.code == 'success') {
          purchaseInfo = params.data;
          this.eventInfo = {
            event_name: params.data.event_name,
            event_address: params.data.event_address,
            event_start_date: params.data.event_start_date,
            event_end_date: params.data.event_end_date,
            file: params.data.media ? params.data.media[0].file : null
          }
          if ('add_ticket_to_apple_wallet' in params.data) {
            this.add_ticket_to_apple_wallet = params.data.add_ticket_to_apple_wallet
          }
          if ( ('isNft' in params.data.nft && params.data.nft.isNft)
            || ('tickets' in params.data.nft && params.data.nft.tickets.length) ) {
            this.isNft = true;
            this.purchase_user_ids = params.data.nft.purchase_user_ids
          } // show only in modal
          // add main_purchase_identifier to events which didn't required of approve
          params.data.main_purchase_identifier = (this.purchase_validation === 2 && this.globals.isIosNew()) ? this.main_purchase_identifier : '';
          params.data.purchase_validation = this.purchase_validation;
          this.waiting_list_purchase_ticket = params.data.waiting_list_purchase_ticket;
          this.purchaseInfoFetched = true; // prevent text blinking

          if(this.isNft) {
            this.paytweedService.exists().then(exist=> {
              console.log('paytweedService.exists', exist);
              if(!exist) {
                params.data.need_to_create_wallet = true; // To show congratulations text
                this.paytweedService.walletCreateInit(this.main_purchase_identifier, false); // CREATE TWEED WALLET
              } else {
                params.data.need_to_create_wallet = false;
              }
              this.nftThankYouDialog.openThankYouPage(params);
            }).catch(err => {
              params.data.need_to_create_wallet = false;
              this.nftThankYouDialog.openThankYouPage(params);
            });

          }
          if(this.order_status === 1) {
            this.rudderstackOrderCompleted();
            this.rudderstackSubscribeToProducer();
          }
        }
        resolve(purchaseInfo);
      }, error => {
        reject(error);
      });
    });
  }
  async makeAirdrop() {
	  console.log('isNft', this.isNft);
	  let wallet_address = null;
    if(this.isNft) {
      const isProd = environment.api_tweed_base_url === 'https://tweed.get-in.com' ? true : false;
      const blockchainIds = isProd ? ["polygon"] : ["polygon"];
      wallet_address = await this.paytweedService.getAddressesByBlochainId(blockchainIds);
      console.log('NFT EVENT wallet_address', wallet_address);
      if (wallet_address) {
        let token = '';
        this.nftService.sendAirdrop(this.purchase_user_ids, wallet_address[blockchainIds[0]], token).subscribe(res=> {
          console.log(res);
        })
      }
    }
  }

  async setFaceBookPixel() {
    // set facebook pixel for thank you page:
    // this.fb_pixel_model.setGetinPixel(this._renderer2, true, this.currency, this.producer_credit);
    // console.log('set facebook pixel for thank you page', response.data, this.currency, this.producer_credit);
    const purchaseContent = await this.getPurchaseInfo();
    const pixelAction = 'Purchase';
    // const contents = purchaseContent ? JSON.stringify(purchaseContent) : {};
    let contentString = '';
    if(purchaseContent) {
      Object.keys(purchaseContent).forEach((key) => {
        contentString += `'${key}':'${purchaseContent[key]}',`;
      });

      if(purchaseContent.google_leads){

        // part 1 of google leads:
        let google_leads_pt1 = this._renderer2.createElement('script');
        google_leads_pt1.type = 'text/javascript';
        google_leads_pt1.text =  `
																				/* <![CDATA[ */
																				var google_conversion_id = `+ purchaseContent.google_leads +`;
																				var google_custom_params = window.google_tag_params;
																				var google_remarketing_only = true;
																				/* ]]> */
																			`;

        // part 2 of google leads:
        let google_leads_pt2 = this._renderer2.createElement('script');
        google_leads_pt2.type = 'text/javascript';
        google_leads_pt2.src =  '//www.googleadservices.com/pagead/conversion.js';

        // create the noscript tag for google leads:
        let google_leads_noScript = this._renderer2.createElement('noscript');
        let noScriptContents = this._renderer2.createElement('div');
        let img_tag = this._renderer2.createElement('img');
        img_tag.src = '//googleads.g.doubleclick.net/pagead/viewthroughconversion/' + purchaseContent.google_leads + '/?guid=ON&script=0';
        img_tag.height = 1;
        img_tag.width = 1;
        img_tag.alt = '';
        this._renderer2.setStyle(img_tag, 'width', '1');
        this._renderer2.setStyle(img_tag, 'height', '1');
        this._renderer2.setStyle(img_tag, 'border-style', 'none');
        this._renderer2.setStyle(noScriptContents, 'display', 'inline');
        this._renderer2.appendChild(noScriptContents, img_tag);
        this._renderer2.appendChild(google_leads_noScript, noScriptContents);

        // part 3 of google leads:
        let google_leads_pt3 = this._renderer2.createElement('script');
        google_leads_pt3.async = 'true';
        google_leads_pt3.src =  'https://www.googletagmanager.com/gtag/js?id=AW-' + purchaseContent.google_leads;

        // part 4 of google leads:
        let google_leads_pt4 = this._renderer2.createElement('script');
        google_leads_pt4.text = `
																			window.dataLayer = window.dataLayer || [];
																			function gtag(){dataLayer.push(arguments);}
																			gtag('js', new Date());

																			gtag('config', 'AW-` + purchaseContent.google_leads + `');
																		`;

        // append google leads to document:
        this._renderer2.appendChild(this._document.body, google_leads_pt3);
        this._renderer2.appendChild(this._document.body, google_leads_pt4);
        this._renderer2.appendChild(this._document.body, google_leads_pt1);
        this._renderer2.appendChild(this._document.body, google_leads_pt2);
        this._renderer2.appendChild(this._document.body, google_leads_noScript);
      }

      if(purchaseContent.facebook_pixels.length) {
        this.event_pixel = null;
        this.event_pixels = [];
        purchaseContent.facebook_pixels.forEach((val) => {
          this.event_pixel = +val.pixel_id;
          this.event_pixels.includes(+val.pixel_id) ? false: this.event_pixels.push(+val.pixel_id);
          if (pixelAction === val.type_name) {
            // this.fb_pixel_model.setPixel(this._renderer2, +val.pixel_id, false, this.currency, this.producer_credit, pixelAction); // val.type_name
            // console.log('ThankYouPage: ', val.pixel_id, val.type_name, pixelAction);
          }
        })
        this.fb_pixel_model.setPixel(this._renderer2, this.event_pixels, true, this.currency, this.producer_credit, pixelAction, contentString);
        // console.log('ThankYouPage: ', this.event_pixel, 'Purchase', contents);
      } else {
        // set facebook pixel for thank you page:
        this.fb_pixel_model.setGetinPixel(this._renderer2, true, this.currency, this.producer_credit, pixelAction, contentString);
      }
      // await this.makeAirdrop();
    }

  }
  // Track: Order Completed
  rudderstackOrderCompleted() {
    let retrievedObject = localStorage.getItem('tickets_data');
	  let tickets_data = JSON.parse(retrievedObject);

    let traits = {
      event_id: this.event_id,
      event_name: this.eventInfo.event_name,
      event_start_date: this.eventInfo.event_start_date,
      event_address: this.eventInfo.event_address,
      payment_method: this.payment_method,
      currency: this.currency,
      total: this.producer_credit
    }
    if (tickets_data && tickets_data.order_id) {traits['order_id'] = tickets_data.order_id;}
    if (tickets_data && tickets_data.products) {traits['products'] = tickets_data.products;}
    if (tickets_data && tickets_data.coupon) {traits['coupon'] = tickets_data.coupon;}
    if (tickets_data && tickets_data.discount) {traits['discount'] = tickets_data.discount;}
    if (tickets_data && tickets_data.tax) {traits['tax'] = tickets_data.tax;}

    // console.log('ORDER Completed traits', traits)
    this.rudderstackService.track('Order Completed', traits);
    localStorage.removeItem('tickets_data');
  }

  // Track: Subscribe to producer
  rudderstackSubscribeToProducer() {
    let retrievedObject = localStorage.getItem('subscribe_data');
    let tickets_data = JSON.parse(retrievedObject);
    this.rudderstackService.track('Subscribe to producer', {...tickets_data});
    localStorage.removeItem('subscribe_data');
  }

  navigateTo(routeString) {
    this.router.navigate([routeString], { replaceUrl: true });
  }

	ngOnDestroy(): void {
		this.navbar.customClass = "";
		this.navbar.show();
		this.navbar.showHeader();
		this.fb_pixel_model.removeGetinPurchaseTag(this._renderer2);
		this.fb_pixel_model.removePurchaseTag(this._renderer2, this.event_pixels);
    this.fb_pixel_model.removePixelInit(this._renderer2, this.event_pixel);
    this.timeoutIDs.forEach(id => clearTimeout(id));
	}

}
