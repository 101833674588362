<div class="header-search" #headerSearch>
  <input type="search" id="headerSearchInput" #headerSearchInput class="header-search-input" (input)="search($event)" (change)="search($event)"
         placeholder="Search events, producers .." i18n-placeholder
          (focus)="onFocus($event)">
</div>
<section class="header-search-result" #result [ngClass]="direction">
  <div class="header-search-wrap">
    <div class="header-search-close"><img src="/assets/images/svg-icons/close.svg" alt="close" (click)="close()"></div>
    <app-events-list [isSearch]="true" [events]="filteredEvents" [name]="'Search result:'" [type]="'type-5'" [isMobile]="isMobile" *ngIf="filteredEvents && filteredEvents.length"></app-events-list>
    <h3 class="no-events" *ngIf="filteredEvents && !filteredEvents.length" i18n="search | no events">No events to display</h3>
  </div>
</section>
