<h3 mat-dialog-title style="margin: 10px;">
  <span *ngIf="data.action == 'add'">Add Fee Template</span>
  <span *ngIf="data.action == 'update' || (data.action == 'preview' && !previewAction)">Edit #{{data.template.id}}</span>
  <span *ngIf="data.action == 'preview' && previewAction">Show #{{data.template.id}}</span>
  {{data.id}}
</h3>
<div class="accordion-action-buttons">
  <button mat-button (click)="accordion.openAll()">Expand All</button>
  <button mat-button (click)="accordion.closeAll()">Collapse All</button>
</div>
<div mat-dialog-content style="color: #000;">
  <form [formGroup]="form">
    <mat-accordion class="headers-align" multi>
      <mat-form-field appearance="fill" style="padding: 0 24px;">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" placeholder="name">
        <mat-error *ngIf="form.controls.name.invalid">Invalid name</mat-error>
      </mat-form-field>

      <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Commissions
          </mat-panel-title>
          <mat-panel-description>
            Edit commission
            <mat-icon>credit_card_gear</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-form-field appearance="fill">
          <mat-label>Purchase fee</mat-label>
          <input matInput formControlName="purchaser_commission" placeholder="purchaser_commission" type="number">
          <span matTextPrefix>%</span>
          <mat-error *ngIf="form.controls.purchaser_commission.invalid">Invalid Purchase fee</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Tax</mat-label>
          <input matInput formControlName="tax_commission" placeholder="tax_commission" type="number">
          <span matTextPrefix>%</span>
          <mat-error *ngIf="form.controls.tax_commission.invalid">Invalid Tax</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Service fee</mat-label>
          <input matInput formControlName="service_commission" placeholder="service_commission" type="number">
          <span matTextPrefix>%</span>
          <mat-error *ngIf="form.controls.service_commission.invalid">Invalid Service fee</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Platform fee</mat-label>
          <span matTextPrefix>&Sigma;</span>
          <input matInput formControlName="platform_fee" placeholder="platform_fee" type="number">
          <mat-error *ngIf="form.controls.platform_fee.invalid">Invalid Platform fee</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Producer fee</mat-label>
          <span matTextPrefix>%</span>
          <input matInput formControlName="commission" placeholder="commission" type="number">
          <mat-error *ngIf="form.controls.commission.invalid">Invalid Producer fee</mat-error>
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
          <mat-label>Refund fee</mat-label>
          <input matInput formControlName="refund_commission" placeholder="refund_commission" type="number">
          <span matTextPrefix>%</span>
          <mat-error *ngIf="form.controls.refund_commission.invalid">Invalid Refund fee</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Refund type</mat-label>
          <mat-select formControlName="refund_type" required>
            <mat-option value="ticket_price">Ticket price</mat-option>
            <mat-option value="full_price">Full price</mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls.refund_type.hasError('required')">Please choose Refund type</mat-error>
        </mat-form-field>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Gateways for purchase
          </mat-panel-title>
          <mat-panel-description>
            Edit gateways for purchase
            <mat-icon>account_balance</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-container>
          <mat-form-field appearance="fill">
            <mat-label>Gateway Provider</mat-label>
            <mat-select [formControl]="gatewayTypeControl" required (selectionChange)=gatewayTypeChange($event)>
              <mat-option>--</mat-option>
              <mat-option *ngFor="let gw of gatewayTypes" [value]="gw">
                {{gw}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="gatewayTypeControl.hasError('required')">Please choose Gateway Provider Type</mat-error>
<!--            <mat-hint>{{gatewayTypeControl.value}}</mat-hint>-->
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Gateway</mat-label>
            <mat-select [formControl]="gatewayControl" required (selectionChange)=gatewayChange($event)>
              <mat-option>--</mat-option>
              <mat-option *ngFor="let gw of filteredByTypeGateways" [value]="gw.id">
                {{gw.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="gatewayControl.hasError('required')">Please choose Gateway</mat-error>
          </mat-form-field>

          <p style="margin-top: 20px" [ngClass]="{'preview': previewAction}">Selected gateways:</p>
          <div class="selected-gateways-table" [ngClass]="{'preview': previewAction}">
            <div class="selected-gateway" *ngFor="let gateway of selectedGateways | keyvalue">
              <span class="added-gateway">{{gateway?.value?.name}}</span>
              <button type="button" class="delete-gateway btn btn-danger" *ngIf="!previewAction" (click)="deleteGateway(gateway?.key, true)">x</button>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="selectedGateways.hasOwnProperty('stripe_gateway')">
          <mat-checkbox formControlName="stripe_connect_account_mode">Stripe Connect</mat-checkbox>
          <mat-form-field appearance="fill">
            <mat-label>Account ID</mat-label>
            <input matInput formControlName="stripe_connect_account_id" placeholder="stripe_connect_account_id" type="text" [required]="form.controls.stripe_connect_account_mode.value == true">
            <mat-error *ngIf="form.controls.stripe_connect_account_id.invalid">Invalid Account ID</mat-error>
          </mat-form-field>
        </ng-container>

      </mat-expansion-panel>


      <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Passcodes
          </mat-panel-title>
          <mat-panel-description>
            Edit passcodes
            <mat-icon>vpn_key_off</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-container>
          <mat-checkbox formControlName="purchase_passcode_mode">Purchase Passcode mode</mat-checkbox>
          <div formArrayName="purchase_passcodes" *ngIf="form.controls.purchase_passcode_mode.value">
            <div *ngFor="let code of passcodes.controls; let i=index" >
              <div [formGroupName]="i" class="form-group" style="display: flex; margin-bottom: 2px;">
                #{{i}}
                <input formControlName="passcode" class="form-control"
                       [type]="'text'"
                       [required]="true">
                <button class="btn btn-danger" type="button" *ngIf="!previewAction" (click)="removePassCode(i)" style="padding: 0 15px;">x</button>
              </div>
              <span *ngIf="code.invalid" style="padding: 0 15px;" class="item-error">Required</span>
            </div>
            <div class="text-center">
              <button class="btn btn-default" type="button" *ngIf="!previewAction" (click)="addPassCode()">Add Passcode</button>
            </div>
          </div>
        </ng-container>
      </mat-expansion-panel>


      <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Categories
          </mat-panel-title>
          <mat-panel-description>
            Assign Event to Categories
            <mat-icon>category</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-container>
          <table mat-table [dataSource]="categoryList" class="fee-template-categories">
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox color="primary" (change)="$event ? masterToggle($event) : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()"
                              [disabled]="previewAction">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                              (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)"
                              [aria-label]="checkboxLabel(element)"
                              [disabled]="previewAction">
                </mat-checkbox>
              </td>
            </ng-container>

            <!-- Position Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef> # </th>
              <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Name </th>
              <td mat-cell *matCellDef="let element"> {{element.name[language]}} </td>
            </ng-container>

            <!-- status Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell *matCellDef="let element"> {{element.status}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'preview': previewAction}" (click)="rowToggle(row)"></tr>
          </table>
        </ng-container>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Additional
          </mat-panel-title>
          <mat-panel-description>
            Edit Additional settings
            <mat-icon>settings_applications</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-checkbox formControlName="isNft">NFT</mat-checkbox>
        <mat-checkbox formControlName="is_svix_webhook_mode">Svix</mat-checkbox>
        <mat-checkbox formControlName="transfer_ticket_mode">Transfer ticket</mat-checkbox>
        <mat-checkbox formControlName="show_price_with_fees">Show Price with Fees</mat-checkbox>
        <div class="group">
          <mat-checkbox formControlName="isDonation">Donate tickets</mat-checkbox>
          <mat-checkbox formControlName="donation_fee_mode">Use fee & taxes</mat-checkbox>
        </div>
        <br>
        <mat-form-field appearance="fill" style="width: 300px;">
          <mat-label>Scanner providers:</mat-label>
          <mat-select formControlName="scanner_provider_type" required>
            <mat-option value="1">Platform scanner</mat-option>
            <mat-option value="2">Guest manager scanner</mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls.scanner_provider_type.hasError('required')">Please choose Scanner provider</mat-error>
        </mat-form-field>
      </mat-expansion-panel>


    </mat-accordion>

    <hr>
    <div mat-dialog-actions class="mat-dialog-actions">
      <button mat-button cdkFocusInitial class="btn" (click)="onCloseClick()">Close</button>
      <button mat-button class="btn btn-success" (click)="save()" type="submit">
        <span *ngIf="data.action == 'add'">Add</span>
        <span *ngIf="data.action == 'update' || (data.action == 'preview' && !previewAction)">Update</span>
        <span *ngIf="data.action == 'preview' && previewAction">Edit</span>
      </button>
    </div>
  </form>
</div>
