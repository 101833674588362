import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {FilterEventsService} from '../../services/filter-events.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsListComponent implements OnInit, AfterViewInit {
  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;
  @Input() name;
  @Input() events;
  @Input() type = '';
  @Input() isMobile = true;
  @Input() categoryId = '';
  @Input() categoryName = '';
  @Input() direction = '';
  @Input() isSearch = false;

  index: 0;
  slice_end = this.isMobile ? 5 : 25;
  active_category = '';

  private isDown = false;
  private startX;
  private scrollLeft;
  @ViewChild('virtual_scroll') v_scroll;

  @HostListener('mousedown', ['$event']) onCarouselMouseDown(e) {
    if (this.v_scroll == undefined) return;
    this.isDown = true;
    this.startX = e.pageX - this.v_scroll.elementRef.nativeElement.offsetLeft;
    this.scrollLeft = this.v_scroll.elementRef.nativeElement.scrollLeft;
    // this.v_scroll.elementRef.nativeElement.classList.add('dragging');
    // console.log(e, e.target, this.v_scroll, this.startX, this.scrollLeft)
  }

  @HostListener('mousemove', ['$event']) onCarouselMouseMove(e) {
    if (!this.isDown || this.v_scroll == undefined) return;
    e.preventDefault();
    const x = e.pageX - this.v_scroll.elementRef.nativeElement.offsetLeft;
    const walk = (x - this.startX) * 2; //scroll-fast
    if (!this.v_scroll.elementRef.nativeElement.classList.contains('dragging') && Math.abs(this.startX - e.pageX) > 2) {
      this.v_scroll.elementRef.nativeElement.classList.add('dragging');
    }
    this.v_scroll.elementRef.nativeElement.scrollLeft = this.scrollLeft - walk;
  }

  // // For Tablets and sensor devices
  // @HostListener('touchmove', ['$event'])
  // handleTouch(event) {
  //   // this.limit_end_d =  this.limit_end_d + this.viewportVisibleEvents;
  // }

  @HostListener('mouseleave', ['$event']) onCarouselMouseLeave(e) {
    this.isDown = false;
    if (this.v_scroll !== undefined) {
      this.v_scroll.elementRef.nativeElement.classList.remove('dragging');
    }

  }

  @HostListener('mouseup', ['$event']) onCarouselMouseUp(e) {
    this.isDown = false;
    if (this.v_scroll == undefined) return;
    this.v_scroll.elementRef.nativeElement.classList.remove('dragging');
    // this.desktopScroll(); // add new items on the end of scrolling
  }

  constructor(private activatedRoute: ActivatedRoute, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    // console.log('this.events', this.events);
    this.activatedRoute.params.subscribe(params => {
      if (params['id']) {
        if (this.active_category !== params['id']) {
          this.slice_end = this.isMobile ? 5 : 25;
          this.active_category = params['id'];
        }
      }
    });
  }

  scrollTo(val) {
    // let rendered = this.viewport.getRenderedRange();
    // console.log(this.viewport, rendered);
    let index = 0;
    if (val === 'prev') {
      index = this.index - 1
    }
    if (val === 'next') {
      index = this.index + 1
    }
    this.viewport.scrollToIndex(index, 'smooth' );
  }

  currentIndex(i) {
    this.index = i;
  }

  viewMore(quantity = 15) {
    this.slice_end = this.slice_end + quantity;
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

}
