import {AfterContentChecked, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {NguCarousel, NguCarouselConfig} from '@ngu/carousel';
import {GlobalsService} from '../../globals';

@Component({
  selector: 'app-home-carousel',
  templateUrl: './home-carousel.component.html',
  styleUrls: ['./home-carousel.component.css']
})
export class HomeCarouselComponent implements OnInit, AfterContentChecked {
  @ViewChild('myCarousel') myCarousel: NguCarousel<any>;
  @Input() setSliderData: any[];
  @Input() isMobile: boolean;
  locale: string;
  public carouselTile: NguCarouselConfig = {
    // https://www.npmjs.com/package/@ngu/carousel/v/3.0.2
    grid: { xs: 1, sm: 2, md: 2, lg: 3, all: 0 },
    load: 3,
    speed: 800, // It is used for time taken to slide the number items
    interval: { timing: 6000, initialDelay: 1000 },
    loop: true,
    touch: true,
    velocity: 0.5,
    RTL: this.globals.getDir() === 'rtl' ? true : false,
  };

  constructor(private globals: GlobalsService, private changeDetector: ChangeDetectorRef) {
    this.locale = this.globals.getLocaleId();
  }

  ngOnInit(): void {
    // console.log(this.setSliderData);
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

}
