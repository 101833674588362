import { SafeResourceUrl } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { FacebookPixelModel } from './../../facebook-pixel-model';
import { AppResponse } from './../../interfaces/app-response.interface';
import { Component, OnInit, Renderer2, Inject, AfterViewInit, OnDestroy} from '@angular/core';
import { EventService } from '../../services/event.service';
import {Router, ActivatedRoute} from '@angular/router';
import { DialogService } from '../../services/dialog.service';
import { GlobalsService } from '../../globals';
import { environment } from '../../../environments/environment';
import { NavbarService } from '../../services/navbar.service';

@Component({
  selector: 'app-event-payment',
  templateUrl: './event-payment.component.html',
  styleUrls: ['./event-payment.component.css']
})
export class EventPaymentComponent implements OnInit, AfterViewInit, OnDestroy {

	eventID: number;
  salesmanID: number = null;

  //api data:
  isOwner: boolean = false;
  canUseCash: boolean;
  eventCoupons: number;
  eventDiscounts: any;
  data: boolean = false;
  isFromEventPayment = true;
  // STRIPE
  stripe_url: SafeResourceUrl = environment.stripe_accept_url;
  isResponse: boolean | null;

  event_pixel: number;
  event_pixels: number[] = [];

  constructor(
    private router: Router,
    private eventService: EventService,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private fb_pixel_model: FacebookPixelModel,
    private _renderer2: Renderer2,
    private globals: GlobalsService,
    private navbar: NavbarService,
    @Inject(DOCUMENT) private _document
  ) {
      this.loadStripe();
    }

  ngOnInit() {
    this.isResponse = false;
    setTimeout(() => {this.navbar.noPadding = 'removePadding';}, 200);
    this.navbar.setIsNewUi(true);
    this.navbar.hide();
      this.activatedRoute.params.subscribe(params => {
        if(+params['event']){
          this.eventID = +params['event'];
        }
        if(+params['salesman_id']){
          this.salesmanID = +params['salesman_id'];
        }
      });
      this.globals.startLoader();
      this.eventService.getCheckoutDetails(this.eventID, this.salesmanID)
      .subscribe(
        (response: AppResponse) => {
          this.globals.stopLoader();
          this.isResponse = true;
          this.canUseCash = response.data.canUseCash;
          this.eventCoupons = response.data.coupons;
          this.eventDiscounts = response.data.discounts;
          this.isOwner = response.data.isOwner;
        },
        (error: AppResponse) => {
          this.globals.stopLoader();
          if(error.httpStatus !== 403) {
            this.dialogService.alertsModal('errors', (error.code) ? error.code : 4 ,5000, false, error.log_id);
          } else {
            this.router.navigate(['/']);
          }
        }
      );
  }

  ngAfterViewInit() {
    // document.getElementById('footer').style.display = 'none';
  }

  // load STRIPE script
  loadStripe() {
    if(!window.document.getElementById('stripe-script')) {
      const node = this._renderer2.createElement('script');
      node.id = 'stripe-script';
      node.type = 'text/javascript';
      node.src = this.stripe_url;
      this._renderer2.appendChild(this._document.head, node);
    }
  }

  ngOnDestroy() {
    this.fb_pixel_model.removePixelInit(this._renderer2, this.event_pixel);
    this.navbar.setIsNewUi(false);
    // this.navbar.show();
    this.navbar.noPadding = '';
  }

}
