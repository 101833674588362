<div class="page-header-arrow-back" *ngIf="false">
  <div class="page-back-arrow">
    <div class="close-dialog-arrow" (click)="closePage()">
      <svg class="arrow-close-modal" width="10" height="14" viewBox="0 0 10 14" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M9.49219 1.64063L4.13281 7L9.49219 12.3594L7.85156 14L0.851562 7L7.85156 0L9.49219 1.64063Z"
          fill="white" />
      </svg>
    </div>
  </div>
</div>
<div class="support-section" *ngIf="false">
  <div class="support-wrapper">
    <div i18n="title | page title" class="support-page-title">SUPPORT</div>
    <div class="main-section">
      <div class="person-section">


        <form class="support-form" (ngSubmit)="onSubmit()" #supportPageForm="ngForm">
        <div class="personal-info-controls">
          <div class="personal-info-control">
            <label i18n="@@FirstName" for="fname" class="col-form-label">First name</label>
            <input i18n-placeholder="@@FirstName" class="form-control-input" type="text" value=""
              name="fname" placeholder="First name" [(ngModel)]="support_info.first_name" #first_name="ngModel" required>
            <span i18n="@@PersonalInfoErrorRequiredFirstName" *ngIf="first_name.invalid && first_name.touched" class="item-error">Please enter first name</span>
          </div>

          <div class="personal-info-control">
            <label i18n="@@LastName" for="lname" class="col-form-label">Last name</label>
            <input i18n-placeholder="@@LastName" class="form-control-input" type="text" name="lname"
              placeholder="Last name" value="" [(ngModel)]="support_info.last_name" #last_name="ngModel" required>
            <span i18n="@@PersonalInfoErrorRequiredLastName" *ngIf="last_name.invalid && last_name.touched" class="item-error">Please enter last name</span>
          </div>
        </div>

        <div class="personal-info-controls">
          <div class="personal-info-control">
            <label i18n="label | email" for="email" class="col-form-label">Email</label>
            <input i18n-placeholder="placeholder | email" class="form-control-input" type="email" name="email"
              placeholder="email" value="" [(ngModel)]="support_info.email" [pattern]="emailRegex" #email="ngModel" required>
            <span i18n="@@PersonalInfoErrorInvalidEmail" *ngIf="email.errors?.pattern && email.touched" class="item-error">Please enter a valid email address.</span>
            <span i18n="@@PersonalInfoErrorRequiredEmail" *ngIf="email.errors?.required && email.touched" class="item-error">Email required</span>
          </div>

          <div class="personal-info-control">
            <label i18n="label | order id" for="order_id" class="col-form-label">Order id</label>
            <input i18n-placeholder="order id" class="form-control-input" type="text" name="order_id"
              placeholder="order id" value="" [(ngModel)]="support_info.order_id" minlength="7" maxlength="10" #order_id="ngModel">
            <span i18n="error alert | invalid order id - minimum 7 characters" *ngIf="order_id.errors?.minlength && order_id.touched" class="item-error">minimum 7 characters</span>
          </div>
        </div>

        <div class="personal-info-controls">
            <div class="personal-info-control" style=" width: 100%;">
              <label i18n="label | issue type" for="issue_type" class="col-form-label">Issue type</label>
              <div class="personal-info-select">
                <select #issue_type="ngModel" class="form-control-input" style="direction: ltr;" name="issue_type" [(ngModel)]="support_info.issue_type" id="issue_type" (change)="setIssueType(issue_type.value)" required>
                  <option selected disabled hidden value="">...</option>
                  <option i18n="option label | types" [value]="type" *ngFor="let type of issue_types[this.localeId]; let i = index;">{{type}}</option>
                </select>
                <span i18n="error alert | invalid issue type - required" *ngIf="issue_type.errors?.required && issue_type.touched" class="item-error">Issue type required</span>
              </div>
            </div>
            <!-- <div class="personal-info-control">
              <label i18n="label | event venue" for="event_venue" class="col-form-label">Event venue</label>
              <div class="personal-info-select">
                <select #event_venue="ngModel" class="form-control-input" style="direction: ltr;" name="event_venue" [(ngModel)]="support_info.event_venue" id="event_venue" (change)="setEventVenue(event_venue.value)">
                  <option i18n="option label | event venue" selected disabled hidden value="">...</option>
                  <option i18n="option label | types" [value]="venue" *ngFor="let venue of event_venues[this.localeId]; let i = index;">
                    {{venue}}</option>
                </select>
                <span i18n="error alert | invalid order id - minimum 7 characters" *ngIf="event_venue.errors?.invalid && event_venue.touched" class="item-error">Essue type required</span>
              </div>
            </div> -->
        </div>
        <div class="attachment">
            <div class="personal-info-control subject-control">
              <label i18n="label | subject" for="description" class="subject">Subject</label>
              <input i18n-placeholder="placeholder | subject" placeholder="Subject" class="form-control-input input-control" type="text" name="subject"
                value="" [(ngModel)]="support_info.subject" #subject="ngModel" required>
              <span i18n="error alert | required subject" *ngIf="subject.invalid && subject.touched" class="item-error">Field is required</span>
            </div>
            <div class="personal-info-control description-control">
              <label for="description" class="form-labels">
                <span i18n="label | description" class="description">Description</span>
                <input class="sr-only" type="file" id="file" name="attachment" #attachment (change)="handleFileInput($event.target)" accept="image/*,.pdf">
                <span i18n="label | attach" class="attach" (click)="attachment.click()">+ Attach a file</span>
              </label>
              <span *ngIf="fileToUpload?.name" class="loaded-item">{{fileToUpload.name}}</span>
              <textarea i18n-placeholder="placeholder | description" placeholder="Description" rows="8" class="form-control-input area-control area-radius" type="text" name="description"
                value="" [(ngModel)]="support_info.description" #description="ngModel" maxlength="250" required>
              </textarea>
              <span i18n="error alert | required description" *ngIf="description.invalid && description.touched" class="item-error">Field is required</span>
            </div>
        </div>
      </form>
      </div>
    </div>
    <div class="buttons-section">
      <div i18n="button | submit" class="email-btn" (click)="onSubmit()">SUBMIT</div>
    </div>
  </div>
</div>
