import { EventService } from '../../../services/event.service';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalsService } from './../../../globals';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { DialogService } from '../../../services/dialog.service';

@Component({
  selector: 'app-restore-event',
  templateUrl: './restore-event.component.html',
  styleUrls: ['./restore-event.component.css']
})
export class RestoreEventComponent implements OnInit {
  @ViewChild('restoreEventForm') restoreEventForm: NgForm;

  event: any;

  constructor(
    private globals: GlobalsService,
    private eventService: EventService,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.event = {
      id: this.data.id,
      status: this.data.status
    };
  }
  onSubmit () {
    this.globals.startLoader();
    this.eventService.updateEventByStatus(this.event.id, this.restoreEventForm.value.status).subscribe(
      (response: any) => {
        this.globals.stopLoader();
        this.dialogRef.close({success: true, event_id: response.data});
        this.dialogService.alertsModal('success', response.code, 5000, true);
      },
      error => {
        this.globals.stopLoader();
        this.dialogService.alertsModal((error.code) ? 'cms' :'errors', (error.code) ? error.code : 4 ,5000, false, error.log_id);
      }
    );
  }
}
