import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  api_base_url = environment.api_base_url;

  constructor(
    private http: HttpClient
  ) { }


  getNotifications () {
    return this.http.get(this.api_base_url + '/api/user-notifications',  {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  readNotifications(idList: any) {
    return this.http.post(this.api_base_url + '/api/user-notifications/read',  {'notifications': idList.toString()}, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }
}
