import {AppResponse} from '../interfaces/app-response.interface';
import {
  Component,
  Injectable,
  OnInit,
  AfterViewInit,
  OnDestroy, QueryList, ViewChildren,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EventService} from '../services/event.service';
import {GlobalsService} from '../globals';
import {DialogService} from '../services/dialog.service';
import {fade, slideIn, SlideInOutAnimation} from '../animation';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Coupon, EventData} from '../interfaces/event-data.interface';
import {ClipboardService} from 'ngx-clipboard';
import {environment} from '../../environments/environment';
import { MbscDatetimeOptions } from '@mobiscroll/angular';
import { NavbarService } from '../services/navbar.service';
import {Location} from '@angular/common';
import {RudderstackService} from "../services/rudderstack.service";


@Injectable()

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.css'],
  animations: [SlideInOutAnimation, slideIn, fade]
})
export class CouponsComponent implements OnInit, AfterViewInit, OnDestroy {

  getin_base_url = environment.getin_base_url;
  images_base_url = environment.images_base_url;
  localeId: string;
  eventData: EventData;
  bootstrapBoolean: boolean = false;
  fireAsyncValidation: boolean = false;
  submitted = false;
  formsubmited = false;
  feedUrl;
  event_id: number = null;
  eventEndDate: string;
  couponsArray: UntypedFormArray = this.formBuilder.array([]);
  discountsStep: UntypedFormGroup;
  event: UntypedFormGroup;
  itemTickets: any;

  mainDateTimeSettings: MbscDatetimeOptions = {
    display: 'center',
    theme: this.globals.mobiscrollTheme(),
    dateFormat: this.globals.detectMonthFirstCountryFromNavigator() ? 'mm/dd/yy' : 'dd/mm/yy',
    dateWheels: this.globals.detectMonthFirstCountryFromNavigator() ? '|mm/dd/yy|' : '|dd/mm/yy|',
    lang: this.globals.LOCALE_ID,
    animate: 'slideup',
    returnFormat: 'iso8601'
  };

  itemsDatesSetting: MbscDatetimeOptions = {
    ...this.mainDateTimeSettings,
    onBeforeShow: (event, inst) => {
      inst.option({
        min: new Date(),
        max: (this.eventEndDate) ? this.eventEndDate : undefined
      })
    }
  };

  page = 1;
  total: number;
  pageSize = 50;
  dir: string | null;
  @ViewChildren('couponItem') couponItems:QueryList<any>;

  constructor(
    private clipboardService: ClipboardService,
    private formBuilder: UntypedFormBuilder,
    private eventService: EventService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public globals: GlobalsService,
    public rudderstackService: RudderstackService,
    private dialogService: DialogService,
    private navbar: NavbarService,
    private _location: Location
  ) {
    this.dir = this.globals.getDir(); // document.dir;
    // console.log('direction', this.direction);
  };

  ngAfterViewInit() {
    // document.getElementById('footer').style.display = 'none';
    this.rudderstackService.page('Event management','Coupons');
  }

  onKeyUp(formGroup: AbstractControl, controll, form) {
    // console.log('form changed',  controll, form.target.value, this.couponsArray.valid);
    let value = form.target.value;
    if (controll === 'code') {
      value = form.target.value.replace(/\s/g,'');
    }
    // console.log('OnKeyUp2', name_no_whitespaces, form.target.value);
    formGroup['controls'][controll].setValue(value);
    (<UntypedFormGroup>formGroup).updateValueAndValidity();
    // console.log('OnKeyUp', this.event, controll, formGroup['controls'], this.couponsArray.controls);
  }

  couponCodeChange(formGroup: AbstractControl, controll, event) {
    const status = (event.srcElement.checked) ? 1 : 0;
    formGroup['controls'][controll].setValue(status);
    (<UntypedFormGroup>formGroup).updateValueAndValidity();
    // const specialCharRegexp: RegExp = /^[a-zA-Z0-9]+$/;
    const specialCharRegexp: RegExp = /^[a-zA-Z0-9-_]+$/;
    if (status) {
      formGroup['controls']['code'] = new UntypedFormControl('', {updateOn: 'change', validators: [Validators.required, Validators.minLength(5), Validators.maxLength(12), Validators.pattern(specialCharRegexp)]});
      (<UntypedFormGroup>formGroup).updateValueAndValidity();
    } else {
      (<UntypedFormGroup>formGroup).removeControl('code');
      (<UntypedFormGroup>formGroup).addControl('code', new UntypedFormControl(''));
      (<UntypedFormGroup>formGroup).updateValueAndValidity();
    }
    // console.log('couponCodeChange', status, controll, event, formGroup['controls']);
  }

  async onBlurCouponCode(formGroup: AbstractControl, controll, event, index) {
    if (formGroup['controls'][controll].valid) {
      this.couponsArray.controls.forEach((subcontroll, i) => {
        // console.log('check controll', subcontroll, controll, index, subcontroll['controls'][controll].value, event.target.value);
        if (i !== index && subcontroll['controls'][controll].value == event.target.value) {
          // console.log('the same value found', controll, index, event.target.value);
          this.dialogService.alertsModal('coupons', 'uniqueness', 3000, false);
          (<UntypedFormGroup>formGroup['controls'][controll]).setErrors({notUnique: true, emitEvent: true});
        }
      });
      (<UntypedFormGroup>formGroup).updateValueAndValidity();
    }
    if (formGroup['controls'][controll].valid) {
      const coupon_code = formGroup['controls'][controll].value;
      // const item_id = this.couponsArray.controls[index].value.id;
      const data = {event_id: this.event_id, coupon_code }; // table: 'coupons',
      console.log('checkCouponUniqueness', data);
      this.eventService.checkCouponUniqueness(data).subscribe((response: AppResponse) => {

      },(error: AppResponse) => {
        this.dialogService.alertsModal('coupons', 'uniqueness', 3000, false);
        (<UntypedFormGroup>formGroup['controls'][controll]).setErrors({notUnique: true, emitEvent: true});
        (<UntypedFormGroup>formGroup).updateValueAndValidity();
      });
    }
    // console.log('AbstractControl', formGroup['controls'], controll, index);
  }

  // test server response
  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  async checkUniqueness(value) {
    const data = {success: true, value};
    await this.timeout(500);
    return data;
  }
  // end test server

  closePage() {
    // this.router.navigate(['/sales-promotion/', this.event_id]);
    //this.router.navigate(['/dashboard'])
    this._location.back();
  }

  ngOnInit() {
    this.navbar.setIsNewUi(true);
    setTimeout(() => {this.navbar.noPadding = 'removePadding';}, 200);
    this.navbar.hide();
    if (this.activatedRoute.params) {
      this.activatedRoute.params.subscribe(params => {
        if (this.bootstrapBoolean) {
          this.resetAllData();
        }
        this.event_id = params['event_id'];
        // this.getEventData();
        this.getCoupons(this.page);
      });
    }
  }

  resetAllData() {
    this.eventData = undefined;
    this.bootstrapBoolean = false;
    this.fireAsyncValidation = false;
    this.feedUrl = undefined;
    this.couponsArray = this.formBuilder.array([]);
    this.formsubmited = false;
  }

  getEventData() {
    this.eventService.getEventData(this.event_id, 'coupons').subscribe(
      (eventData: { data: EventData }) => {
        this.globals.stopLoader();
        this.eventData = eventData.data;
        this.eventEndDate = (this.eventData && this.eventData.main && this.eventData.main.main_end_date) ? this.eventData.main.main_end_date.replace(' ', 'T') : undefined;
        this.formBootstrap();
      }
    )
  }

  getCoupons(pageNumber, newCoupon = false) {
    this.globals.startLoader();
    const perPage = this.pageSize;
    const start = (pageNumber - 1) * perPage;
    const end = start + perPage;
    // console.log('getCoupons', perPage, start, end);
    if (this.bootstrapBoolean) {
      this.resetAllData();
    }
    this.eventService.getCouponsData(this.event_id, 'coupons', start, end).subscribe(
      (eventData: { data: EventData }) => {
        this.globals.stopLoader();
        this.eventData = eventData.data;
        this.itemTickets = eventData.data.items.tickets;
        this.eventEndDate = (this.eventData && this.eventData.main && this.eventData.main.main_end_date) ? this.eventData.main.main_end_date.replace(' ', 'T') : undefined;
        this.page = pageNumber;
        this.total = (this.eventData && this.eventData.main && this.eventData.main.total) ? this.eventData.main.total : undefined;
        this.formBootstrap();
        if(newCoupon) {
          this.addCoupon();
          this.scrollToNewCoupon();
        }

      })
  }

  scrollToNewCoupon() {
    setTimeout(() => {
      const results = this.couponItems;
      const last = results.last;
      if (last) {
        last.nativeElement.focus();
        last.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 200);
  }

  formBootstrap() {
    this.discountsStep = this.initDiscountStep();
    this.event = this.initEventForm();
    this.bootstrapBoolean = true;
  }

  initEventForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      discounts: this.discountsStep,
    }, { updateOn: 'blur' });
  }

  initDiscountStep(): UntypedFormGroup {
    const discount = (this.eventData) ? this.eventData.discounts : undefined;
    if (discount && discount.coupons.length > 0) {
      discount.coupons.forEach(coupon => this.addCoupon(coupon));
    } else {
      this.addCoupon();
    }
    return this.formBuilder.group({
      coupons: this.couponsArray
    });
  }

  addCoupon(oldCoupon: Coupon = undefined) {
    this.couponsArray.push( this.createCoupon(oldCoupon) );
  }
  createNewCoupon() {
    if(this.page == 1 && this.total > this.pageSize) {
      const lastPage = Math.ceil(this.total/this.pageSize)
      this.getCoupons(lastPage, true);
    } else {
      this.addCoupon();
      this.scrollToNewCoupon();
    }
  }


  removeCoupon(index) {
    if(this.couponsArray.controls[index].value.id) {
      this.removeItemDB(this.couponsArray.controls[index].value.id, 'coupons');
    }
    this.couponsArray.removeAt(index);
  }

  removeItemDB(item_id: number, table: string) {
    this.globals.startLoader();
    const data = {item_id, event_id: this.event_id, table};
    this.eventService.removeItem(data).subscribe(
      (response: AppResponse) => {
        this.globals.stopLoader();
      },
      (error: AppResponse) => {
        this.dialogService.alertsModal('fileUploads', 8, 5000, false, error.log_id);
        this.globals.stopLoader();
      }
    )
  }

  createCoupon(oldCoupon: Coupon = undefined): UntypedFormGroup {
    return this.formBuilder.group({
      id: (oldCoupon && oldCoupon.id) ? oldCoupon.id : undefined,
      name: this.formBuilder.control((oldCoupon && oldCoupon.name) ? oldCoupon.name : '', [Validators.maxLength(50), Validators.required]),
      ticket_id: this.formBuilder.control((oldCoupon && oldCoupon.ticket && oldCoupon.ticket.ticket_id) ? +oldCoupon.ticket.ticket_id : ''),
      quantity: this.formBuilder.control((oldCoupon && oldCoupon.quantity) ? oldCoupon.quantity : '', [Validators.required, Validators.min(1), Validators.max(10000)]),
      discount_type: (!oldCoupon || !oldCoupon.id) ?  new UntypedFormControl((oldCoupon && oldCoupon.discount_type) ? +oldCoupon.discount_type : 1,{updateOn: 'change', validators: Validators.required}) : oldCoupon.discount_type,
      discount: (!oldCoupon || !oldCoupon.id) ? this.formBuilder.control((oldCoupon && oldCoupon.discount) ? oldCoupon.discount : '', [Validators.required, Validators.min(1), Validators.max(100)]) : oldCoupon.discount,
      start_date: this.formBuilder.control((oldCoupon && oldCoupon.start_date) ? oldCoupon.start_date.replace(' ', 'T') : '', [Validators.required]),
      end_date: this.formBuilder.control((oldCoupon && oldCoupon.end_date) ? oldCoupon.end_date.replace(' ', 'T') : '', [Validators.required]),
      status: this.formBuilder.control((oldCoupon && oldCoupon.status) ? oldCoupon.status : 1, Validators.required),
      manually_entered: (!oldCoupon || !oldCoupon.manually_entered) ?  new UntypedFormControl((oldCoupon && oldCoupon.manually_entered) ? +oldCoupon.manually_entered : 0,{updateOn: 'change', validators: Validators.required}) : oldCoupon.manually_entered,
      code: (oldCoupon && oldCoupon.code) ? oldCoupon.code : undefined,
      url: (oldCoupon && oldCoupon.ticket && oldCoupon.ticket.url) ? oldCoupon.ticket.url : ''
    });
  }

  discountTypeChange(index) {

    const discountTypeControl = this.couponsArray.controls[index]['controls'].discount_type;
    const discountControl = this.couponsArray.controls[index]['controls'].discount;
    discountControl.clearValidators();
    if (discountTypeControl.value === '1') {
      discountControl.setValidators([Validators.required, Validators.min(1), Validators.max(100)]);
    } else {
      discountControl.setValidators([Validators.required, Validators.min(1), Validators.max(65535)]);
    }
    discountControl.updateValueAndValidity();
    // console.log('discountTypeChange', index), this.couponsArray.controls[index]['controls'];
  }

  ticketChange(index, event) {
    this.couponsArray.controls[index]['controls'].ticket_id.setValue(+event.target.value);
  }

  submitForm() {
    this.globals.startLoader();

    if (this.event.invalid) {
      this.markFormGroupTouched(this.event);
      this.submitted = true;
      this.globals.stopLoader();
      return;
    } else {
      this.setEventData();
    }
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      // control.markAsPristine();
      control.updateValueAndValidity();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  copyCode(code) {
    this.clipboardService.copyFromContent(code);
    this.clipboardService.destroy();
    this.dialogService.alertsModal('success', 'couponCopied', 2000, true);
  }

  setEventData() {
    this.formsubmited = true;
    this.eventService.setEventData( this.event_id, 'coupons', {coupons: this.event.value.discounts} )
      .subscribe(
        (result: AppResponse) => {
          this.dialogService.alertsModal('success', 'couponsUpdated', 3000, true)
          this.resetAllData();
          if(this.page > 1) {
            this.getCoupons(this.page)
          } else {
            this.getEventData();
          }

        },
        (error: AppResponse) => {
          this.globals.stopLoader();
          if(error.code === 38 && error.data) {
            this.showErrorOnCouponQuantity(error.data.id, error.data.purchase);
            this.dialogService.alertsModal((error.code) ? 'editEvent' : 'errors', (error.code) ? error.code : 4 , 5000, false, error.log_id);
          } else {
            this.dialogService.alertsModal((error.code) ? 'editEvent' : 'errors', (error.code) ? error.code : 4 , 5000, false, error.log_id);
          }
        }
      )
  }

  changeItemStatus(event: any, type: string, index: number) {
    const status = (event.target.checked) ? 1 : 2;
    this[`${type}Array`].controls[index].patchValue({status});
  }

  showErrorOnCouponQuantity(id, purchase) {
    this.couponsArray.controls.forEach((control, i) => {
      if (control.value.id === id) {
        // console.log('EXIST',this.couponsArray.controls[i]['controls'].quantity);
        this.couponsArray.controls[i]['controls'].quantity.setValidators([Validators.required,Validators.min(+purchase)]);
        this.couponsArray.controls[i]['controls'].quantity.updateValueAndValidity();
      }

    });
  }

  ngOnDestroy() {
    this.navbar.setIsNewUi(false);
    // this.navbar.show();
    this.navbar.noPadding = '';
  }

}
