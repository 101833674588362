import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {debounceTime, map, startWith, tap} from 'rxjs/operators';
import {FilterEventsService} from '../../services/filter-events.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-autocomplete-filter-events',
  templateUrl: './autocomplete-filter-events.component.html',
  styleUrls: ['./autocomplete-filter-events.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AutocompleteFilterEventsComponent implements OnInit {
  myControl = new UntypedFormControl();
  // FAKE DATA
  options: any[] = [];
  filteredOptions: Observable<string[]>;

  constructor(private filterEventsService: FilterEventsService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.filterEventsService.getEventsSource.subscribe(value => {
      // this.options = value;
      this.options = this.addShortAddress(value);
      this.setFilteredOptions();
    });

    // Clear filter when route change
    this.activatedRoute.params.subscribe(params => {
      if (params['id']) {
        this.clear();
        this.filterEventsService.setAutocomplete([]);
      }
    });

  }

  private setFilteredOptions() {
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value)),
        debounceTime(300),
        tap((value: any) => {
          // console.log('setFilteredOptions', value);
          // console.log('setFilteredOptions2', [{address: value, address_short: value}]);

        })
      );
  }

  private addShortAddress(events: any) {
    const events_w_sh_addr = events.map(ev => {
        return {
          event_id: ev.event_id,
          address: ev.address,
          address_short: ev.address.split(',')[0]
        }
      });

    const unique = events_w_sh_addr.filter((obj, index) => {
      return index === events_w_sh_addr.findIndex(o => obj.address === o.address && obj.address_short === o.address_short);
    });

    return unique
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.address.toLowerCase().includes(filterValue));
  }

  clear() {
    this.myControl.patchValue('')
  }

  optionSelected(option) {
    this.filterEventsService.setAutocomplete(option);
    if (option) {
      const el_home_p = (<HTMLElement>document.getElementById('category-location'));
      const el_cat_p = (<HTMLElement>document.getElementById('category-filter_events'));
      const el = el_home_p ? el_home_p : el_cat_p;
      if (el) {
        el.scrollIntoView({behavior: 'smooth', block: 'center' });
      }
    }
  }

}
