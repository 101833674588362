import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import { MbscSelectOptions } from '@mobiscroll/angular';
import {DomSanitizer} from '@angular/platform-browser';
import {GlobalsService} from '../../globals';

@Component({
  selector: 'app-custom-form-inputs',
  templateUrl: './custom-form-inputs.component.html',
  styleUrls: ['./custom-form-inputs.component.css']
})
export class CustomFormInputsComponent implements OnInit {
  @Input() item: {check: boolean, value: boolean};
  @Input() form: UntypedFormGroup;
  @Input() input: CustomInput;
  @Input() counter: number;
  @Input() customClass: string;
  @Output() checkbox =  new EventEmitter<any>();
  @Output() radio =  new EventEmitter<any>();
  @Output() selectInput =  new EventEmitter<any>();
  label = false;

  isChecked = false;
  checkBox: any = [];
  radioBox: any = [true];


  selectSettings: MbscSelectOptions = {
    select: 'single',
    theme: this.globals.mobiscrollTheme(),
    display: 'center',
    lang: this.globals.LOCALE_ID,
    filter: false,
  }

  formInputsOthers;
  localId = this.globals.getLocaleId();

  constructor(public sanitizer: DomSanitizer, private globals: GlobalsService) {}

  ngOnInit() {
    this.formInputsOthers = this.globals.formInputsOthers;
    this.initCheckboxState();
    this.initRadioboxState();
  }

  initCheckboxState() {
    if (this.input.type === 'checkbox') {
      this.checkBox = this.input.opinions.map(option => {
        const name = this.form.get(this.input.id.toString()).value;
        return name.includes(option.title) ? true : false;
      });
    }
  }

  initRadioboxState() {
    if (this.input.type === 'radio') {
      this.radioBox = this.input.opinions.map((option, i) => {
        // console.log('radio Options', option, i);
        // console.log('radio this.form.get', this.form.get(this.input.id.toString()));
        const name = this.form.get(this.input.id.toString()).value;
        return i === 0 ? true : false;
      });
      // console.log('radioBox', this.radioBox)
    }
  }

  checkboxHandler(counter, checkboxName, checkboxValue, isChecked, val, index) {
    // Giving information for parent component to validate the checkboxes
    // console.log("checkboxHandler custom form ", this.counter, counter, checkboxName, checkboxValue, isChecked, val, index);
    this.isChecked = isChecked;
    this.checkBox[index] = isChecked;
    this.checkbox.emit({counter, checkboxName, checkboxValue, isChecked, val, index});
  }

  radioHandler(counter, radioName, radioValue, isChecked, val, index) {
    this.radioBox.forEach((element, i) => {
      this.radioBox[i] = !isChecked;
    });
    this.radioBox[index] = isChecked;
    this.radio.emit({counter: counter, radioName: radioName, radioValue: radioValue, isChecked: isChecked, val: val, index: index});
  }

  selectHandler(counter, selectName, selectValue) {
    // Giving information for parent component to validate the checkboxes
    this.selectInput.emit({counter: counter, selectName: selectName, selectValue: selectValue});
  }

}

interface CustomInput {
  'id': any,
  'title': string;
  'type': string;
  'opinions': Array<any>;
  'required': string;
}
