import { environment } from './../../environments/environment';
import { Subject } from 'rxjs';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import 'rxjs/Rx';

@Injectable({
	providedIn: 'root'
})

export class ReaderService {
	api_base_url = environment.api_base_url;
	scrollHandler: Subject<number>;
	constructor(
		private http: HttpClient,
		private authService: AuthService
	) {
		this.scrollHandler = new Subject<any>();
	}

	confirmParticipantEnter (participantHash: string) {
		return this.http.post(this.api_base_url + '/api/confirm-participant-enter', {hash: participantHash},
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest', 'x-api-key': this.authService.getReaderApiKey()})});
	}
	cancelParticipantEnter (participantHash: string) {
		return this.http.post(this.api_base_url + '/api/cancel-participant-enter', {hash: participantHash},
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest', 'x-api-key': this.authService.getReaderApiKey()})});
	}
}
