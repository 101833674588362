<section class="bank-warning-modal">
  <div class="bank-warning-modal-header">
    <button type="button" [matDialogClose]>
      <img src="/assets/images/svg-icons/close.svg" alt="close">
    </button>
  </div>
  <div class="block_container">
    <div class="blur-wrap">
      <div class="img-wrap">
        <img class="img-responsive" src="/assets/images/svg-icons/order-success.svg">
      </div>
      <div class="">
        <h1 *ngIf="data.event_status !== '1' || data.auto_approve !== 'false'">Event Published</h1>
        <h1 *ngIf="data.event_status == '1' && data.auto_approve == 'false'">Event Saved</h1>
      </div>
      <div class="">
        <p class="desc">Please note that in order to receive payouts, and manage you fund requests you need to add you bank details and account information. </p>
      </div>
      <div class="btns">
        <a routerLink="/my-finance" [matDialogClose] class="btn-white">
          add finance details
        </a>
      </div>
    </div>
  </div>
</section>
