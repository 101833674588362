<div class="main-insight-container" [ngClass]="{'rtl' : dir === 'rtl'}">
  <div class="insight-container">
    <!-- <div class="events-stat" *ngFor="let total of totals; let i = index">
      <div class="events-type-container">
        <div class="events-type-name">{{total.name}}</div>
        <div class="events-type-number">{{total.col}}</div>
      </div>
    </div> -->
    <div class="events-stat">
      <div class="events-type-container">

        <div class="events-type-container">
          <div i18n="event info | return customer" class="events-type-name">RETURN CUSTOMER</div>
          <div class="events-type-number">{{statsData.main_statistic.return_costumer}}%</div>
        </div>

      </div>
    </div>
    <div class="events-stat">
      <div class="events-type-container">
        <div i18n="event info | total views" class="events-type-name">TOTAL WEBSITE VISITS</div>
        <div class="events-type-number">{{statsData.main_statistic.total_views}}</div>
      </div>
    </div>
    <div class="events-stat">
      <div class="events-type-container">
        <div i18n="event info | total shares" class="events-type-name">CONVERTION RATE</div>
        <div class="events-type-number">{{statsData.main_statistic.convertion_rate}}%</div>
      </div>
    </div>
  </div>
  <div class="insight-graph-container">
    <div class="insight-gender-container"
      *ngIf="statsData.gender_stat.length > 0 && barChartGenderLabels.length > 0 && barChartColors.length > 0">
      <div i18n="@@AnalyticsDistributionbygender" class="graph-title">Distribution by gender</div>
      <div class="display-chart" style="width: 360px">
        <canvas #genderCanvas width="360" height="180" class="display-chart" baseChart [datasets]="barChartGenderData" [labels]="barChartGenderLabels"
          [options]="doughnutChartOptions" [type]="doughnutChartType" [legend]="true"
          (chartHover)="onChartHover($event)" (chartClick)="onChartClick($event)"></canvas>
      </div>

    </div>
    <div class="insight-age-container"
      *ngIf="barChartAgeData.length > 0 && barChartAgeLabels.length > 0 && barChartColors.length > 0">
      <div i18n="@@AnalyticsDistributionbyage" class="graph-title">Distribution by age</div>
      <div class="display-chart">
        <canvas class="display-chart" width="460" baseChart [datasets]="barChartAgeData" [labels]="barChartAgeLabels"
          [options]="barChartOptions" [type]="barChartTypes" [legend]="true"></canvas>
      </div>

    </div>
  </div>

</div>
