<div *ngIf="user_id >= 3">
  <button type="button" class="create-event-button" [ngStyle]="{'float': direction}" (click)="createEvent()">
    <span><img src="/assets/images/svg-icons/create-event-plus.svg" alt="create-event"></span>
    <!-- <span i18n="create event | button text" [ngClass]="{'text-position': textFromRight, 'btn-text': true}">
      {buttonText, select,
        event {אירוע}
        create event {צור אירוע}
      }
    </span> -->
  </button>
</div>
<div *ngIf="user_id == 0">
  <button type="button" class="create-event-button" [ngStyle]="{'float': direction}" (click)="onLogin()">
    <span><img src="/assets/images/svg-icons/create-event-plus.svg" alt="create-event"></span>
      <!-- <span i18n="create event | button text" [ngClass]="{'text-position': textFromRight, 'btn-text': true}">
          {buttonText, select,
            event {אירוע}
            create event {צור אירוע}
          }
        </span> -->
  </button>
</div>
