import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { UserAlertsModalComponent } from '../shared-dialogs/user-alerts-modal/user-alerts-modal.component';
import { SuccessCodesService } from '../code-strings/success_codes';
import { ErrorCodesService } from '../code-strings/error_codes';


@Injectable()
export class ModalService {

  constructor(
    private dialog: MatDialog,
    private errorCodeService: ErrorCodesService,
    private successCodeService: SuccessCodesService
    ) {}

  alertsModal(successOrErrorGroup: string, successOrErrorCodes: any, timeout: number, type: boolean, log_id?: number) {

    let alertsArr = [];
    let alertFile = (type) ? 'successCodeService' : 'errorCodeService';

		if(successOrErrorCodes instanceof Array) {
			for(let alert of successOrErrorCodes) {
        if(alert instanceof Object) {
          let alertTxt = this[alertFile].getAlertStr(successOrErrorGroup, alert.code);
          alertsArr.push({code: alertTxt, codeData: alert.codeData});
        } else {
          let alertTxt = this[alertFile].getAlertStr(successOrErrorGroup, alert);
          alertsArr.push(alertTxt);
        }
			}
		} else {
			const alertTxt = this[alertFile].getAlertStr(successOrErrorGroup, successOrErrorCodes);
			alertsArr.push(alertTxt);
    }

    let data = {alertsArr, timeout, type, log_id, successOrErrorGroup, successOrErrorCodes};
    this.dialog.open(UserAlertsModalComponent, {
      data: data,
      autoFocus: false,
      panelClass: (type) ? 'userAlertsModalSuccess' : 'userAlertsModalError',
      maxWidth: '90vw',
      maxHeight: '90vh',
    });
  }

  }
