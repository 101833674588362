<div *ngIf="!isIcon">
<button
  *ngIf="userId && userId === producerId && authService.isConnected()" style="color: #FFFFFF"
  (click)="editProducer()"
  i18n="@@FollowButtonEdit"
  class="follow-btn"
  type="button"
>
  Edit
</button>
<button
  *ngIf="userId && userId !== producerId && (authService.isConnected() && isFollowingProducer)"
  [disabled]="disableFollowButton"
  (click)="updateFollowProducer('unfollow')"
  i18n="producer-header | unfollow"
  class="follow-btn"
  type="button"
>
following
</button>
<button
  *ngIf="userId !== producerId && (!authService.isConnected() || !isFollowingProducer)" style="color: #FFFFFF"
  [disabled]="disableFollowButton"
  (click)="updateFollowProducer('follow')"
  i18n="@@FollowButtonFollow"
  class="follow-btn" type="button"
>
  Follow
</button>
</div>
<div *ngIf="isIcon">
  <button
  *ngIf="userId && userId === producerId && authService.isConnected()" style="color: #82FFCA"
  (click)="editProducer()"
  class="follow-btn"
  type="button">
  <img [src]="'assets/images/svg-icons/follow.svg'" alt="follow">
</button>
<button
  *ngIf="userId && userId !== producerId && (authService.isConnected() && isFollowingProducer)"
  [disabled]="disableFollowButton"
  (click)="updateFollowProducer('unfollow')"
  class="follow-btn"
  type="button">
  <img [src]="'assets/images/svg-icons/follow.svg'" alt="follow">
</button>
<button
  *ngIf="userId !== producerId && (!authService.isConnected() || !isFollowingProducer)" style="color: #82FFCA"
  [disabled]="disableFollowButton"
  (click)="updateFollowProducer('follow')"
  class="follow-btn" type="button">
  <img [src]="'assets/images/svg-icons/follow.svg'" alt="follow">
</button>
</div>
