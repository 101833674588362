import { GlobalsService } from './../../../globals';
import { MatDialogRef } from '@angular/material/dialog';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ViewContainerRef, Injector, createNgModule, NgModuleRef
} from '@angular/core';
import {
  EditEventTermsModule
} from "../../../terms/edit-event-terms/edit-event-terms.module";
import {
  EditEventTermsComponent
} from "../../../terms/edit-event-terms/edit-event-terms.component";

@Component({
  selector: 'app-terms-dialog',
  templateUrl: './terms-dialog.component.html',
  styleUrls: ['./terms-dialog.component.css']
})
export class TermsDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('isConfirmTerms') isConfirmTerms: ElementRef;
  auto_approve_in_the_future: boolean = false;
  dir: string;
  termsAreApproved = false;

  @ViewChild("editEventTerms", {read: ViewContainerRef}) editEventTerms!: ViewContainerRef;
  constructor(public dialogRef: MatDialogRef<TermsDialogComponent>,
              private globals: GlobalsService, private injector: Injector) {
    this.dir = this.globals.getDir();
  }

  ngOnInit() {
    // this.isConfirmTerms.nativeElement.checked = false;
    this.loadTerms();
  }

  async loadTerms() {
    const {EditEventTermsModule} = await import('../../../terms/edit-event-terms/edit-event-terms.module');
    const {EditEventTermsComponent} = await import('../../../terms/edit-event-terms/edit-event-terms.component');
    const moduleRef: NgModuleRef<any> = createNgModule(EditEventTermsModule, this.injector);
    this.editEventTerms.createComponent(EditEventTermsComponent, {ngModuleRef: moduleRef});
  }

  ngAfterViewInit(): void {
    this.isConfirmTerms.nativeElement.checked = false;
  }

  toggleIsConfirmTerms(){
    // this.isConfirmTerms.nativeElement.value = !this.isConfirmTerms.nativeElement.value;
  }

  closeDialog() {
    this.dialogRef.close({auto_approve_in_the_future: this.auto_approve_in_the_future})
  }
}
