import {
  Component, ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList, ViewChildren
} from '@angular/core';
import {
  ApproveWaitingListModalComponent
} from "./approve-waiting-list-modal/approve-waiting-list-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {CurrencyLocaleService} from "../../services/currency-locale.service";

@Component({
  selector: 'app-analytic-tickets',
  templateUrl: './analytic-tickets.component.html',
  styleUrls: ['./analytic-tickets.component.scss']
})

export class AnalyticTicketsComponent implements OnInit {
  @Input() data: AnalyticTicketsData;
  @Input() event_id: number;
  @Output() onApprovedWaitingList = new EventEmitter<any>();
  @ViewChildren("feesTooltip") private feesTooltip: QueryList<ElementRef>;
  feesTooltipIndexArr: number[] = [];
  constructor(private dialog: MatDialog, private currencyLocale: CurrencyLocaleService) {
  }

  ngOnInit() {
    // console.log(this.data);
  }

  showApproveWaitingListModal(ticket_id, name, price, currency, count_ticket_waiting_list) {
    const dialogRef = this.dialog.open(ApproveWaitingListModalComponent, {
      data: {
        event_id: this.event_id,
        ticket_id: ticket_id,
        name: name,
        price: price,
        currency: currency,
        count_ticket_waiting_list: count_ticket_waiting_list
      },
      autoFocus: true,
      backdropClass: 'mint-modal-bc',
      panelClass: 'approve-waiting-list-dialog',
    });

    dialogRef.afterClosed().subscribe(
      (type: any) => {
        if (type === 'tickets') {
          this.onApprovedWaitingList.emit('tickets');
        }
      }
    );


  }


  toggleBox(index) {
    // console.log(index, this.feesTooltip.toArray(), this.feesTooltipIndexArr, this.feesTooltipIndexArr.includes(index))
    // toggle
    if(this.feesTooltipIndexArr.includes(index)) {
      let removeIndex = this.feesTooltipIndexArr.indexOf(index);
      this.feesTooltipIndexArr.splice(removeIndex, 1);
      this.feesTooltip.toArray()[index].nativeElement.classList.remove("showTooltip");
    } else {
      this.feesTooltipIndexArr.push(index);
      this.feesTooltip.toArray()[index].nativeElement.classList.add("showTooltip");
    }
    // Show only 1 tooltip
    if(this.feesTooltipIndexArr.length) {
      this.feesTooltip.toArray().forEach((el) => {
        el.nativeElement.classList.remove("showTooltip");
      });
      let nativeElement = this.feesTooltip.toArray()[index].nativeElement;
      nativeElement.classList.add("showTooltip");
      this.feesTooltipIndexArr = [];
      this.feesTooltipIndexArr.push(index);
    }
    // console.log(index, this.feesTooltipIndexArr, this.feesTooltipIndexArr.length);
  }
  getLocale(currency) {
    return this.currencyLocale.getLocaleForCurrencyPipe(currency)
  }
}
interface AnalyticTicketsData {
  main: AnalyticMain,
  tickets: AnalyticTicket[] | null | [],
  event_info?: AnalyticEventInfo,
  event_currency: string,
  type: number,
  show_approve_waiting_list: boolean
}
interface AnalyticMain {
  total_face_value: number;
  total_tickets_sold: number;
  total_today:number;
  live_cart_mode?: boolean;
}
interface AnalyticTicket  {
  id: number;
  name: string;
  price: number;
  reg: number;
  quantity: number;
  remaining: number;
  total_face_value: string | number;
  total_waiting_list_value: string | number;
  count_ticket_waiting_list: number;
  count_ticket_approved: string | number;
  total_ticket_today: string | number;
  live_cart_count?: string | number;
}

interface AnalyticEventInfo  {
  commission: string | number;
  donation_fee_mode: string | number;
  platform_fee: string | number;
  purchaser_commission: string | number;
  refund_commission: string | number;
  service_commission: string | number;
  tax_commission: string | number;
}


