import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { PaymentGatewayService } from '../../services/payment-gateway.service';
import { DialogService } from '../../services/dialog.service';
import { slideIn } from '../../animation';
import { NgForm } from '@angular/forms';
import { GlobalsService } from '../../globals';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-create-new-gateway',
  templateUrl: './create-new-gateway.component.html',
  styleUrls: ['./create-new-gateway.component.css'],
  animations: [slideIn]
})
export class CreateNewGatewayComponent implements OnInit, OnDestroy {

  @ViewChild('gatewayNewForm') gatewayForm: NgForm;
  gatewayTypes: Promise<String[]> | any;
  selectedGatewayType: string = '';
  selectedOption: string = '';
  gatewayMainFields: any;
  gatewayExtFields: any;
  mainFields: any = {};
  extFields: any = {};
  private subscriptions: Subscription[] = [];

  constructor(private dialogService: DialogService, private router: Router, private gatewayService: PaymentGatewayService, private globals : GlobalsService) {}

  ngOnInit() {
    this.gatewayTypes = this.getGatewayTypes().catch(error => {
      if(error.code) {
        this.dialogService.alertsModal('gateway', error.code, 3000, false, (error.log_id ? error.log_id : null));
      }
    });
  }

  onSubmit() {
    this.globals.startLoader();
    if (this.gatewayForm.valid) {
      this.subscriptions.push(
        this.gatewayService.setNewGateway(this.gatewayForm.value).subscribe(
          response => {
            this.globals.stopLoader();
            this.dialogService.alertsModal('success', 'success', 5000, true);
            this.router.navigate(['/cms/dashboard/pending']);
          },
          error => {
            this.globals.stopLoader();
            this.dialogService.alertsModal('errors', 2, 5000, false);
          }
        )
      );
    }
  }

  getGatewayTypes() {
    return new Promise(
      (resolve, reject) => {
        this.subscriptions.push(
          this.gatewayService.getGatewayTypes().subscribe(
            response => {
              resolve(response);
            },
            error => {reject(error)}
          )
        );
      }
    );
  }

  getGatewayFields(gatewayType) {
    this.selectedGatewayType = gatewayType;
    if(gatewayType) {
      this.getPaymentGatewayFields(gatewayType)
      .then( (response: any) => {
        const responseKeys = Object.keys(response.data);
        this.gatewayMainFields = [];
        for (const prop of responseKeys) {
          if (prop !== gatewayType) {
            this.gatewayMainFields.push(response.data[prop]);
          }
        }
        this.gatewayExtFields = response.data[gatewayType];
      })
        .catch(error => {
          if(error.code) {
            if(error.code === 4.1) {
              this.dialogService.alertsModal('errors', error.code, 3000, false, (error.log_id ? error.log_id : null));
            } else {
              this.dialogService.alertsModal('gateway', error.code, 3000, false, (error.log_id ? error.log_id : null));
            }
          }
        });
    }
  }

  getPaymentGatewayFields(gatewayType) {
    return new Promise(
      (resolve, reject) => {
        this.subscriptions.push(
          this.gatewayService.getGatewayFields(gatewayType).subscribe(
            response => {
              resolve(response);
            },
              error => {
                reject(error)
              }
          )
        );
      }
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
