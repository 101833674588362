import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavbarService } from 'src/app/services/navbar.service';
import * as solanaWeb3 from '@solana/web3.js';
import { environment } from 'src/environments/environment';
import { SolanaConnectService } from 'src/app/services/solana-connect.service';
import { DialogService } from 'src/app/services/dialog.service';
import { GlobalsService } from 'src/app/globals';
import { ClipboardService } from 'ngx-clipboard';


@Component({
  selector: 'app-solana-transaction',
  templateUrl: './solana-transaction.component.html',
  styleUrls: ['./solana-transaction.component.css']
})
export class SolanaTransactionComponent implements OnInit {

  hash: string = '';
  solConnection: solanaWeb3.Connection | null;
  claster: any = environment.solana;
  transaction: any;
  transactionStatus: any;
  isChecking: boolean = false;
  transactionData: any = {};

  constructor(
    private router: Router,
    public globals: GlobalsService,
    private navbarService: NavbarService,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private clipboardService: ClipboardService,
    private solanaConnectService: SolanaConnectService
    ) {
      this.solConnection = new solanaWeb3.Connection(solanaWeb3.clusterApiUrl(this.claster.solana_endpoint_url, this.claster.tls), 'finalized'); // 'confirmed'
    }

  ngOnInit() {
    this.navbarService.setIsNewUi(true);
    this.activatedRoute.params.subscribe(params => {
			this.hash = params['hash'];
      // console.log('Get transactiom', this.hash, params);
      if (this.hash) {
        this.setSolanaProvider();
      }
		});
  }

  setSolanaProvider() {
    this.transactionStatus = 'Pending...';
    this.isChecking = true;
    let dbTransaction = {};
    if(this.hash) {
      this.solanaConnectService.getTransactionByUrlHash(this.hash).subscribe((response: any) => {
        // console.log('get Transaction By Url Hash', response);
        if (response.code === 'pending') { // response.data && response.data.transaction_status === 0
          this.transaction = response.data;

          if (this.transaction && this.transaction.transaction_data && this.transaction.transaction_data.tickets_price) {
            this.transactionData.isUsd = this.transaction.transaction_data.tickets_currency === 'usd';
            this.transactionData.ticketsPrice = parseFloat((this.transaction.transaction_data.tickets_price / this.transaction.transaction_data.event_currency_rate).toFixed(8));
            this.transactionData.transaction_fee = this.transaction.transaction_data.transaction_fee;
            this.transactionData.ticketsTotal = this.transaction.transaction_data.transaction_amount;
            this.transactionData.ticketsCurrency = this.transaction.transaction_data.tickets_currency.toUpperCase();
            this.transactionData.chainCurrency = this.transaction.transaction_data.chain_currency;
            this.transactionData.eventCurRate = this.transaction.transaction_data.event_currency_rate;
            this.transactionData.usdCurRate = this.transaction.transaction_data.usd_currency_rate;
            this.transactionData.eventName = this.transaction.transaction_data.event_name;
            this.transactionData.totalTickets = this.transaction.transaction_data.total_tickets;
            this.transactionData.userEmail = this.transaction.transaction_data.user_email;
            this.transactionData.eventId = this.transaction.transaction_data.event_id;
            this.transactionData.ticketPrice = parseFloat(this.transaction.transaction_data.tickets_price);
            this.transactionData.ticketsAmount = parseFloat(this.transaction.transaction_data.tickets_amount);
            this.transactionData.networkName = this.transaction.transaction_data.network;
            // console.log('getTransactionByUrl', this.transactionData.ticketsPrice, this.transactionData.ticketPrice, this.transactionData.ticketsAmount);
            dbTransaction = {txHash: this.transaction.transaction_hash, state: 'pending', transaction_data: this.transaction.transaction_data, dbResponse: this.transaction};
            this.startCheckingTransaction(dbTransaction);
          }
        } if (response.code === 'success') {
          // console.log('Transaction is succes');
          this.transactionStatus = 'Success!'
          this.isChecking = false;
          this.dialogService.alertsModal('success', 'walletConnectSuccess', 3000, true);
          setTimeout(() => {
             this.router.navigate(['/']);
          }, 5000);
        }
      },
      error => {
        console.log('Transaction error', error);
        if (error.code === 'invalid' || error.code === 'expired') {
          console.log('Transaction expired or invalid');
          this.dialogService.alertsModal('cryptoCurrency', 'urlHashIndalid', 3000, false);
          this.transactionStatus = 'Error';
          this.isChecking = false;
        } else {
          this.dialogService.alertsModal('errors', (error.code) ? error.code : 4 ,3000, false, error.log_id);
          this.transactionStatus = 'Error';
          this.isChecking = false;
        }
      });
    }
  }

  startCheckingTransaction(dbTransaction) { // transaction.transaction_hash, 'pending', transaction.transaction_data, transaction
    this.globals.startLoader();
    // console.log('Got Transaction', dbTransaction);
    this.getTransactionByHash(dbTransaction.txHash).then(transactionState => {
      this.globals.stopLoader();
      // console.log('Got Transaction Response', transactionState);
      this.isChecking = false;
      if (transactionState && !transactionState.meta.err) {
        this.transactionStatus = 'Confirmation...';
        this.getSignatureStatus(dbTransaction.txHash).then(getSignatureStatus => {
          // console.log('getSignatureStatuses subscription ', getSignatureStatus);
          if (getSignatureStatus && getSignatureStatus.value) {
            // console.log('Signature Status is: ', getSignatureStatus.value.confirmationStatus);
            this.saveTransaction(transactionState, getSignatureStatus.value.confirmationStatus, dbTransaction.txHash, dbTransaction.dbResponse); // save transaction data into DB
          }
        }).catch(error => { console.log('getSignatureStatuses error: ', error); });
      }
    }).catch(error => {
      console.log('Get Pending Transaction error', error);
    });


  }

  saveTransaction(transactionState, state, signature, dbResponse?) {
    // console.log('saveTransaction: ', transactionState, state, signature, dbResponse);
    let txHash: any = {};
    if (state && dbResponse.transaction_data) {
      txHash = dbResponse.transaction_data;
      txHash[state] = transactionState;
    }
    // console.log('txHash: ', txHash);
    let params;
    const res = dbResponse && dbResponse.transaction_data ? dbResponse.transaction_data : false;
      txHash.status = state;
      params = {
        payment_id: dbResponse && dbResponse.payment_id ? dbResponse.payment_id : '',
        transaction_hash: res.hash,
        transaction_status: state === 'finalized' ? 1 : 0,
        transaction_data: txHash
      };
      // console.log('createTransactionToDb params', params, state);
      this.isChecking = true;
      this.globals.startLoader();
      this.solanaConnectService.saveSolanaTransaction(params).subscribe((response: any) => {
        this.globals.stopLoader();
        // console.log('Save Transaction response', response);
        if (response.data && response.data.url) {
          this.isChecking = false;
          this.transactionStatus = 'Success!'
          this.dialogService.alertsModal('success', 'walletConnectSuccess', 5000, true);
            // setTimeout(() => {
            // this.router.navigate([response.data.url]);
            // }, 10000);
        } else if (response.code === 'success' && !response.data.success) {
          // console.log('Save Transaction is pending...');
          // TODO: to offer pass to the main page and login to ckeck the tikets
          // this.dialogService.alertsModal('success', 'walletConnectTransaction', 5000, true);
        } else {
          console.log('Could not save transaction..');
          this.dialogService.alertsModal('cryptoCurrency', 'walletTransactionSave', 3000, false);
        }
      });
      // this.dialogService.alertsModal('success', 'walletConnectSuccess', 5000, true);
  }

  getTransactionByHash(signature) {
    const state = 'finalized';
    return this.solConnection.getTransaction(signature, {commitment: state});
  }

  getSignatureStatus(signature) {
    // console.log('getSignatureStatus', signature);
    const result =this.solConnection.getSignatureStatus(signature, {
      searchTransactionHistory: true,
    });
    return result; // .value?.confirmationStatus;
  }

  copyToClipboard() {
    this.clipboardService.copyFromContent(this.transaction.transaction_hash);
    this.clipboardService.destroy();
    this.dialogService.alertsModal('success', 'couponCopied', 2000, true);
  }

}
