<div class="reader_signin">
	<section class="reader-main-section">
    <app-go-back [bigArrow]="true" [top]="90" [left]="30" style="margin-top: 50px;"></app-go-back>
		<div class="reader-title-section">
			<div class="reader-title">Scanner login</div>
		</div>
		<div class="reader-form-section">
			<form class="reader-form col-md-12 col-xs-12"  (ngSubmit)="onSubmit()" #signinForm="ngForm">
			<input type="hidden" name="phoneCountryCode" ngModel #phoneCountryCode="ngModel" value="">

			<div class="form-group col-md-12 col-xs-12">
				<input appPhoneNumber ng2TelInput
					i18n-placeholder="@@Phone"
					class="form-control new-ui-input"
					trim="blur"
					type="phone"
					name="phone"
					placeholder="Phone"
					value=""
					ngModel
					#phone="ngModel"
					required
					(hasError)="setErrorOnphoneNumber(!$event)"
					(ng2TelOutput)="setPhoneNumber($event)"
					(intlTelInputObject)="telInputObject($event, phone.value)"
					(countryChange)="onCountryChange($event)"
					[ng2TelInputOptions]="localizationService.getPhoneInputOptions()"
				/>
				<app-error-creator [@slideIn] *ngIf="phone.touched && phone.invalid" [name]="'phone'" [control]="phone"></app-error-creator>
			</div>

			<div class="form-group col-md-12 col-xs-12">
				<!-- <label i18n="label | password" for="[password]" class="col-form-label">*סיסמה</label> -->
				<input
					i18n-placeholder="@@Password"
					class="form-control new-ui-input"
					type="password"
					name="password"
					ngModel
					#password="ngModel"
					required
					placeholder="Password">
				<span i18n="@@PasswordRequired." class="item-error" *ngIf="password.touched && !password.valid">Password required.</span>
			</div>
			<div class="reader-btn col-md-12 col-xs-12 form-group">
				<button i18n="@@ReaderSigninLoginIntoAccount" class="reader-login col-md-12 col-xs-12" type="submit" [disabled]="!signinForm.valid" [ngClass]="{disabledBG: !signinForm.valid}">Log in to your account</button>
			</div>
			</form>
		</div>
	</section>
	<section>
		<div *ngIf="event_id" [routerLink]="['/reader-signup', event_id]" class="reader-signup">
			<div class="signin-signup">
				<p i18n="@@ReaderSigninRegisternewscanne" class="text-center back_to_login">Register new scanner</p>
			</div>
		</div>
	</section>
</div>
