<div class="otp-wrapper" *ngIf="isShowOtp" [ngStyle]="{'justify-content': isShowTimer?'center':'center'}"
     (window:beforeunload)="tabClose($event)">
  <div class="form-group otp-container">
    <h3>{{'check-otp.OTPVerification' | translate:'OTP Verification'}}</h3>
    <!-- <div class="is-ios">Is this IOS: {{iOS}} and {{isIOS}} index: {{index}} otp: {{otpValue}} length:  {{otpValue.length}}</div> -->
    <div *ngIf="isShowTimer && (timeLeft == null || timeLeft == 0)"
         class="enter-otp">
      <span *ngIf="userEmailOrPhone">{{'check-otp.EntertheCodeSentto' | translate:'Enter the Code Sent to'}} <span class="user-otp-email-or-phone">{{userEmailOrPhone}}</span></span>
      <span *ngIf="!userEmailOrPhone">{{'check-otp.Pleaseenteronetimepassword' | translate:'Please enter one-time password'}}</span>
    </div>
    <div i18n="Otp section | re-enter password" *ngIf="isShowTimer && timeLeft > 0"
         class="enter-otp">Re-enter password through {{timeLeft}}</div>
    <div class="otp-array">
<!--      <div class="otp-input-wrapper" *ngFor="let inputOtp of inputsOtp; let i = index;">-->
<!--        <input #otp i18n-placeholder="placeholder | otp" autocomplete="false"-->
<!--               (paste)="onPaste($event, 'paste', otp, i)"-->
<!--               (input)="onChangeOtp(otp, i)"-->
<!--               (focus)="onFocusInput(otp, $event)"-->
<!--               (keyup)="onKeyUpOTP(i,  $event)"-->
<!--               (change)="onChangeInput($event, otp)"-->
<!--               [id]="'otpInputId'+i" class="otp-input" type="number" inputmode="numeric"-->
<!--               name="otp" size="1" minlength="1" maxlength="1" [value]="inputOtp[i]"-->
<!--               placeholder="" size="1" required>-->
<!--      </div>-->
    </div>
    <div class="input-otp-wrap">
      <input type="text"
             inputmode="numeric"
             autocomplete="one-time-code"
             pattern="\d{6}"
             placeholder="XXXXXX"
             class="web-otp-input"
             i18n-placeholder="placeholder | otp"
             name="otp_input"
             #otp_input
             (input)="onInputOtp(otp_input)"
             (paste)="onPasteOtp($event, otp_input)"
             autofocus
             required>
    </div>
  </div>
</div>
