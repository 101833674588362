<!------------Producer Header: Cover Photo / Logo--------------------->
<div class="producer-cover" *ngIf="producerDetails">
  <img src="{{producerCoverPhoto}}" alt="producer-cover-img" class="producer-cover-img">
  <div class="producer-cover-upload" *ngIf="connectedUserInfo && producerDetails && connectedUserInfo.user_id && connectedUserInfo.user_id === producerDetails.user_id && authService.isConnected()">
    <input type="file" class="sr-only" #uploader (change)="cropImage($event)" name="file" accept="image/*">
    <button type="button" class="upload-cover-photo-btn" (click)="uploader.click()">
      <img class="add-cover-icon" src="../../assets/images/producerFeed/producer-add-cover.png" alt="add-cover-icon" >
      <p i18n="button | upload cover photo" class="add-cover-graph">
        upload cover photo
      </p>
    </button>
    <div *ngIf="producerCoverPhoto !== producerCoverPhotoDefault">
      <button type="button" class="upload-cover-photo-btn" (click)="deleteCoverPhoto()">
        <p i18n="button | delete cover photo" class="add-cover-graph">
          delete
        </p>
      </button>
    </div>
  </div>
  <div class="producer-logo-box">
    <img *ngIf="producerImage" class="producer-logo" src="{{producerImage}}" alt="producer-logo">
    <svg *ngIf="!producerImage" class="no-producer-logo" width="90" height="90" [data-jdenticon-value]="producer_id"></svg>
  </div>
</div>
<!------------Producer Info: Followers and Events count----------------------->
<div class="follow-wrapper">
  <div class="producer-followers"><strong>{{producerFollowers}}</strong><p i18n="producer page | followers">Followers</p></div>
  <div class="producer-active-events"><strong>{{activeEvents?.length || 0}}</strong><p i18n="producer page | active events">active events</p></div>
  <div class="producer-past-events"><strong>{{pastEvents?.length || 0}}</strong><p i18n="producer page | past events">past events</p></div>
</div>
<div class="horizontal-line"></div>
<!------------Producer Info: Follow/Unfollow/Edit----------------------->
<div class="producer-desc" *ngIf="producerDetails">

<!------------------Producer Info: Producer Name, BIO-------------------->
  <div class="producer-desc-item">
    <h2 class="producer-name">
      {{producerDetails.producer_name}}
    </h2>
  </div>

  <div class="producer-desc-item">
    <h2 class="producer-bio">
      {{producerDetails.bio}}
    </h2>
  </div>

  <div class="horizontal-line"></div>
  <div class="follow-wrapper">
    <div class="contact-btns">
      <app-producer-follow-edit-btn [producerId]="producer_id" [userId]="connectedUserInfo ? connectedUserInfo.user_id : ''" [producerfollowers]="producerDetails.followers" *ngIf="producer_id && connectedUserInfo && producerDetails.followers" page="producer"></app-producer-follow-edit-btn>
    </div>
  </div>
  <div *ngIf="connectedUserInfo?.user_id && svixBtn">
    <button title="Svix Accounts" class="svix-btn" (click)="showSvixModal(producer_id)">
      <img style="max-width: 100px; height: 100%" src="assets/images/svg-icons/svix-light.svg">
    </button>
  </div>
</div>

<!---------------Producer Events: Filters & Search------------------------->
<div>
  <div #events_filter class="filters" *ngIf="!no_events && scrollPoint" [ngClass]="{'rtl' : dir === 'rtl'}">
    <button class="filter-btn" aria-label="filter" (click)="showCategories = !showCategories; activeCategory = '' " [ngClass]="{'active': showCategories}">
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.0306 10.9563V19.6705C22.0306 20.9782 22.0306 22.2859 22.0306 23.5936C22.0306 24.3827 21.7555 24.7555 21.1957 24.7379C20.6653 24.7202 20.3913 24.3345 20.3913 23.6007C20.3913 19.5411 20.3913 15.4862 20.3995 11.422C20.3995 11.0692 20.3066 10.8787 19.9703 10.7164C18.9742 10.2248 18.4415 9.37455 18.4332 8.27616C18.425 7.17777 18.9213 6.26284 19.9503 5.76186C20.316 5.58429 20.4042 5.37731 20.3984 5.00569C20.3784 3.65917 20.3913 2.31265 20.3878 0.966122C20.3878 0.532176 20.5465 0.142919 20.9758 0.0664791C21.2451 0.0229669 21.6132 0.165263 21.8401 0.352248C21.9918 0.469848 22.0201 0.809713 22.0224 1.05079C22.0389 2.39732 22.0224 3.74384 22.0448 5.09037C22.0448 5.28911 22.1941 5.57841 22.3587 5.66661C23.4172 6.23109 24.024 7.07781 23.9993 8.2891C23.9758 9.4651 23.3925 10.3154 22.307 10.8034C22.2188 10.8375 22.1377 10.8939 22.0306 10.9563Z" fill="#dadada"/>
        <path d="M11.1987 7.43774C11.1987 5.29271 11.1987 3.14651 11.1987 1.0003C11.1987 0.573409 11.3104 0.227664 11.7373 0.0594958C12.2923 -0.161593 12.8286 0.254713 12.8286 0.956787C12.8462 2.32213 12.8368 3.68747 12.8368 5.05398C12.8368 7.95989 12.8368 10.867 12.8274 13.7741C12.8274 14.1668 12.9203 14.375 13.3049 14.5679C14.2704 15.0535 14.7749 15.8979 14.7878 16.9787C14.8008 18.0806 14.3268 18.9555 13.3284 19.4577C12.9333 19.6576 12.8145 19.8857 12.8251 20.3079C12.8533 21.4392 12.8404 22.5717 12.8321 23.7031C12.8321 24.3781 12.5158 24.7473 11.9889 24.7368C11.4621 24.7262 11.2022 24.384 11.1975 23.7242C11.1904 22.5341 11.1857 21.344 11.1975 20.1539C11.1975 19.8446 11.1152 19.6835 10.8141 19.5353C9.79099 19.0484 9.24532 18.2099 9.22533 17.0763C9.20416 15.9144 9.73924 15.0453 10.7753 14.5326C11.1187 14.3632 11.2022 14.168 11.1998 13.8176C11.1916 11.6913 11.1987 9.56396 11.1987 7.43774Z" fill="#dadada"/>
        <path d="M3.61662 5.92284L3.61662 13.5116L3.61662 23.6934C3.61662 24.3696 3.31909 24.7424 2.79342 24.7377C2.26775 24.733 1.97845 24.3685 1.97845 23.6793C1.97845 18.0227 1.97845 12.3662 1.97845 6.70959V5.92166C0.349686 5.07612 -0.255956 3.9695 0.0968451 2.53595C0.247362 1.93165 0.596012 1.39521 1.08713 1.01227C1.57824 0.629336 2.1835 0.421993 2.80626 0.423346C3.42902 0.4247 4.03337 0.634674 4.52281 1.01974C5.01226 1.40481 5.35857 1.94276 5.50646 2.54771C5.85221 3.95539 5.23833 5.06318 3.61662 5.92284Z" fill="#dadada"/>
      </svg>
    </button>
    <button class="search-btn" aria-label="search" (click)="toggleSearch()" [ngClass]="{'active': showSearchInput}">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1544 15.0935H16.0649L15.6862 14.7175C17.0241 13.1574 17.8377 11.1349 17.8377 8.91887C17.8377 3.99291 13.8448 0 8.91887 0C3.99291 0 0 3.99291 0 8.91887C0 13.8448 3.99291 17.8377 8.91887 17.8377C11.1349 17.8377 13.156 17.0254 14.7161 15.689L15.0949 16.0649V17.1517L21.9528 24L23.9986 21.9541L17.1544 15.0935ZM8.91888 15.0935C5.50776 15.0935 2.74428 12.33 2.74428 8.91888C2.74428 5.50913 5.50776 2.74428 8.91888 2.74428C12.3286 2.74428 15.0935 5.50913 15.0935 8.91888C15.0935 12.33 12.3286 15.0935 8.91888 15.0935Z" fill="#dadada"/>
      </svg>
    </button>
    <div class="categories-filter x-scroll-hidden" *ngIf="showCategories">
      <ul class="category-list">
        <li class="category-list-item" *ngIf="activeEvents && activeEvents.length"
            [ngClass]="{active: activeCategory === 'active-events'}"
            (click)="categoryChoosed('active-events')"
            i18n="filter | active events"
        >
          active events
        </li>
        <li class="category-list-item" *ngIf="pastEvents && pastEvents.length"
            [ngClass]="{active: activeCategory === 'past-events'}"
            (click)="categoryChoosed('past-events')"
            i18n="filter | past events"
        >
          past events
        </li>
        <li class="category-list-item" *ngIf="associateEvents && associateEvents.length"
            [ngClass]="{active: activeCategory === 'associate-events'}"
            (click)="categoryChoosed('associate-events')"
            i18n="filter | associate events"
        >
          associate events
        </li>
      </ul>
    </div>

    <div class="search-filter">
      <input type="search" id="searchInput" class="search-input" *ngIf="showSearchInput" (input)="searchFilter($event)">
    </div>
  </div>

<!------------Producer Events: Search result / Active / Past---------------->

<div class="producer-events" *ngIf="searchString.length >= 3" id="search-results">
    <h2 i18n="producer page | search results">search results</h2>
    <p i18n="producer page | no search results" *ngIf="searchString.length >= 3 && filteredEvents === null" class="events-filter-message">
      Unfortunately, nothing was found
    </p>
    <app-events-carousel *ngIf="filteredEvents && filteredEvents.length" [carouseldata]="filteredEvents" [isSearch]="true" [showProducer]="false" page="producer"></app-events-carousel>
  </div>

  <div class="producer-events" *ngIf="activeEvents && activeEvents.length" id="active-events">
    <h2 i18n="producer page | active events">active events</h2>
    <app-events-carousel [carouseldata]="activeEvents" [showProducer]="false" [isMobile]="isMobile" page="producer"></app-events-carousel>
  </div>

  <div class="producer-events" *ngIf="pastEvents && pastEvents.length" id="past-events">
    <h2 i18n="producer page | past events">past events</h2>
    <app-events-carousel [carouseldata]="pastEvents" [showProducer]="false" [isMobile]="isMobile" page="producer"></app-events-carousel>
  </div>

  <div class="producer-events" *ngIf="associateEvents && associateEvents.length" id="associate-events">
    <h2 i18n="producer page | associate events">associate events</h2>
    <app-events-carousel [carouseldata]="associateEvents" [showProducer]="false" [isMobile]="isMobile" page="producer"></app-events-carousel>
  </div>
  <div class="first-event-wrapper">
    <div class="first-event" *ngIf="connectedUserInfo && producerDetails && connectedUserInfo.user_id && connectedUserInfo.user_id === producerDetails.user_id && authService.isConnected() && no_events"
         (click)="createEvent()"
         i18n="producer page | create first event"
    >
      create your first event
    </div>
  </div>
  <div class="no_events" *ngIf="connectedUserInfo && producerDetails && connectedUserInfo.user_id !== producerDetails.user_id && no_events">
    <p i18n="ProducerNoEventstodisplay">No events to display</p>
  </div>

  <mat-spinner *ngIf="loader" style="margin:50px auto;" strokeWidth="5" diameter="70"></mat-spinner>

    <!---------------Producer Gallery: Filters & Search------------------------->



</div>

<div style="display: flex; justify-content: center; padding: 30px 0;">
  <a [routerLink]="['/']">
    <img class="logo" src="/assets/images/svg-icons/logo-new.svg" alt="logo"/>
  </a>
</div>
