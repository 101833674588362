import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ticketsWPrice',
  pure: false
})
export class TicketsWPricePipe implements PipeTransform {

  transform(tickets: any[], ...args: any[]): any{
    if (!tickets) {
      return tickets;
    }
    return tickets.filter(t => t.price > 0)

  }

}
