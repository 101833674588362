import { Component, OnInit } from '@angular/core';
import {WalletConnectService} from '../../services/wallet-connect.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PaytweedService} from '../../services/paytweed.service';
import {DialogService} from '../../services/dialog.service';

@Component({
  selector: 'app-wallet-exists',
  templateUrl: './wallet-exists.component.html',
  styleUrls: ['./wallet-exists.component.css']
})
export class WalletExistsComponent implements OnInit {
  hash: string = '';
  hasWallet: boolean = false;
  noWallet: boolean = false;

  constructor(private activatedRoute: ActivatedRoute,
              private walletConnectService: WalletConnectService,
              private paytweedService: PaytweedService,
              private dialogService: DialogService,
              private router: Router) { }

  async ngOnInit() {
    this.activatedRoute.params.subscribe(async (params) => {

      this.hash = params['hash'];
      console.log('Get hash', this.hash);

      if (!this.hash) {
        return;
      }


      this.walletConnectService.checkPaytweedWallets(this.hash).subscribe((res: any) => {
        if (res.code && res.code.id) {
          this.noWallet = true;
          this.paytweedService.walletCreateInit('', true);
        }
        if (res.code && res.code.check) {
          this.hasWallet = true; // show message on page
        }
      }, error => {
        this.dialogService.alertsModal('errors', error.code, 5000, false, error.log_id);
        setTimeout(()=> {
          this.router.navigate(['/']);
        },2000)
      });

    });
  }

}
