<div class="notifications-list">
  <p i18n="@@YouHaveNoNotificationsAtTheMoment" class="p10 text-center" *ngIf="!notifications.read.length && !notifications.unread.length">
    You have no notifications at the moment
  </p>
  <div class="sub-list-wrap" *ngFor="let item of notifications | keyvalue : reverseKeyOrder">
    <ul [class]="item.key + ' notifications'" *ngIf="notifications[item.key].length">
      <li class="notification-block-title">{{item.key+'' | titlecase}}</li>
      <li class="notification" *ngFor="let notification of item.value, let i = index">
        <span class="notification-img"
              [ngClass]="{'notification-logo': $any(notification).image_url.includes('.svg')}"
              [ngStyle]="{'background-image': 'url('+$any(notification).image_url+')'}">
        </span>
        <p class="notification-message type-{{$any(notification).type}}">{{$any(notification).message}}
          <span class="notification-btns" *ngIf="$any(notification).type == '19' && $any(notification).custom_blockAction === '1'">
              <button class="confirm" (click)="updateAgentStatus(notification, 'approve', i)"><span i18n="notification approve">Confirm</span></button>
              <button class="decline" (click)="updateAgentStatus(notification, 'decline', i)"><span i18n="notification decline">Decline</span></button>
          </span>
        </p>
        <span class="date">{{$any(notification).timeDiff}}</span>
        <a href="{{notification.link}}" class="notification-link" *ngIf="notification.link"></a>
      </li>
    </ul>
  </div>
</div>
