import {AfterViewInit, Component, HostListener, OnInit, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {NavbarService} from '../services/navbar.service';
import {FilterEventsService} from '../services/filter-events.service';
import {GlobalsService} from '../globals';
import {NguCarousel} from '@ngu/carousel';
import {RudderstackService} from "../services/rudderstack.service";
import {ActivatedRoute, Params} from "@angular/router";
import {CookieService} from "ngx-cookie-service";
declare var window: any;

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit, AfterViewInit, OnDestroy {
  isMobile: boolean = false;
  dir: string = 'ltr';
  showNotifications = false;
  showBlurBackground = false;
  mainSliderData = [];
  events = [];
  // events1: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  categories;

  // Events by category
  featured = [];
  free = [];
  trending = [];
  location = [];
  followed = [];
  week = [];
  week_splitBy3 = [];
  date_events = [];
  locationName = '';
  categorized = {}; // Created Categories
  stickyOffsetTop: number;
  region = '';

  @HostListener('window:resize', ['$event'])onResize() {
    this.isMobile = window.innerWidth <= 768;
    this.calcEventsMarginOffset();
  }
  @ViewChild('home_main') home_main: ElementRef;
  @ViewChild('cats_filters') cats_filters: ElementRef;
  // @ViewChild('drawer') public drawer: MatDrawer;

  constructor(public nav: NavbarService, private filterEventsService: FilterEventsService,
              private globals: GlobalsService, public rudderstackService: RudderstackService,
              private activatedRoute: ActivatedRoute, private _cookieService:CookieService) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if(params['region']){
        this.region = params['region'];
      }
    });
  }

  ngOnInit(): void {
    this.nav.hide(); // OLD HEADER
    this.nav.showFooter();
    this.isMobile = window.innerWidth <= 768;
    this.dir = this.globals.getDir();
    // this.filterEventsService.autocomplete.subscribe(value => {
    //  // console.log("NEW", value)
    // })
    this.filterEventsService.getEvents( null, this.region ).subscribe(value => {
      this.mainSliderData = value.sliders.sort((a, b) => a.number_position - b.number_position);
      this.categories = value.categories;
      this.handleEvents(value.events);
      this.filterEventsService.setEvents(value.events);
      this.filterEventsService.setCategories(value.categories);
      this.filterEventsService.setSliders(value.sliders);
      // console.log('app-homepage getEvents', this.events, value.categories, this.categorized);
      this.findEventWithMostViews(value.events)
    });

  }

  handleEvents(events) {
    // console.log('TRIGGERED UPDATE', events);
    this.events = [...events]; //console.log('events', this.events);
    this.featured = this.filterEventsService.handleEvents(events, 'featured'); //console.log('featured', this.free);
    this.free = this.filterEventsService.handleEvents(events, 'free'); //console.log('free', this.free);
    this.trending = this.filterEventsService.handleEvents(events, 'trending'); //console.log('trending', this.trending);
    // this.week = this.filterEventsService.handleEvents(events, 'week'); //console.log('week', this.trending);
    this.followed = this.filterEventsService.handleEvents(events, 'followed'); //console.log('week', this.followed);
    // this.week_splitBy3 = this.chunkEvents(events, 3, true); // TODO pass this.week here
    this.categorized = this.filterEventsService.handleEvents(this.events, 'categorized', this.categories);
  }

  findEventWithMostViews(events) {
    if(this._cookieService.get('eventWithMostViews')) {return}
    const max = events.reduce(function(prev, current) {
      return (prev && prev.views > current.views) ? prev : current
    }) //returns object
    const expDate = new Date();
    expDate.setDate(expDate.getDate() + 7); // week
    this._cookieService.set('eventWithMostViews', JSON.stringify(max), {
      expires: expDate,
      secure: false,
    });
  }

  // Get margin-right of 'cats_filters' and set padding left to events-list
  calcEventsMarginOffset() {
    const cats_filters_styles = window.getComputedStyle(this.cats_filters.nativeElement);
    const marginL = cats_filters_styles.getPropertyValue('margin-left');
    if (this.dir === 'ltr') {
      this.home_main.nativeElement.style.paddingLeft = marginL;
    } else {
      this.home_main.nativeElement.style.paddingRight = marginL;
    }
  }
  ngAfterViewInit() {
    this.calcEventsMarginOffset();
    // this.nav.setDrawer(this.drawer);
    this.nav.getStickyOffsetTop.subscribe(value => {
      // console.log(value);
      this.stickyOffsetTop = value;
    })

    // Search by 'address' subscription
    this.filterEventsService.getAutocompleteSource.subscribe(value => {
      // console.log('Home getAutocompleteSource', value);
      this.locationName = value.length ? `Location ${value}` : 'Location';
      this.location = this.filterEventsService.handleEvents(this.events, 'location');
    })

    // Search by 'datepicker' subscription
    this.filterEventsService.getDatepickerSource.subscribe(value => {
      // console.log('getDatepickerSource', value);
      this.date_events = this.filterEventsService.handleEvents(this.events, 'datepicker');
    })

    // Search by 'date Select' subscription
    this.filterEventsService.getSelectSource.subscribe(value => {
      this.date_events = this.filterEventsService.handleEvents(this.events, 'datepicker');
    })

    // EventsSource subscription
    this.filterEventsService.getEventsSource.subscribe(events => {
      this.handleEvents(events);
    })
    this.rudderstackService.page('Home page','Home');
  }

  // chunk events array to array like [['a'],['b', 'c', 'd'],['e', 'f', 'g']]
  chunkEvents(events, chunkSize, isFirstDefault = false) {
    const new_events = [];
    if (!events.length) {return new_events}
    if (isFirstDefault) {new_events.push([events[0]])};
    for (let i = isFirstDefault ? 1 : 0; i < events.length; i += chunkSize) {
      const chunk = events.slice(i, i + chunkSize);
      new_events.push(chunk);
      // do whatever
    }
    // console.log('Chunk new_evens', new_events);
    return new_events
  }

  ngOnDestroy(): void {
    this.nav.hideFooter();
  }
}
