import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-ticket-img-modal',
  templateUrl: './ticket-img-modal.component.html',
  styleUrls: ['./ticket-img-modal.component.css']
})
export class TicketImgModalComponent implements OnInit {

  imgURL: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.imgURL = this.data.url;
  }

}
