<section class="my-finance">
<app-go-back [top]="18" [left]="6" [bigArrow]="false"></app-go-back>
  <div class="header">
    <h2>{{'finance.Finance' | translate:'Finance'}}</h2>
  </div>
  <div class="total-wrap">
<!--    <div class="total-info"-->
<!--         *ngIf="payment_stripe_connect_accounts"-->
<!--         (click)="loadEmbededComponent('payments')"-->
<!--         [ngClass]="{'disabled': retryTime}">-->
<!--      <div class="total-info-title">-->
<!--        <div style="display: flex;">-->
<!--          <img src="/assets/images/svg-icons/f_transaction.svg" alt="">-->
<!--          <span class="total-txt-1">Transactions</span>-->
<!--        </div>-->
<!--        <img src="/assets/images/svg-icons/f_toc.svg" alt="">-->
<!--      </div>-->
<!--      <div style="margin: 0 26px;">-->
<!--        <div class="total-info-flex">-->
<!--          <p class="total-txt-2" style="color: #A29BB1;">Available Balance</p>&nbsp;-->
<!--          <p class="total-txt-amount"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="total-info"-->
<!--         *ngIf="payment_stripe_connect_accounts"-->
<!--         (click)="loadEmbededComponent('payouts')"-->
<!--         [ngClass]="{'disabled': retryTime}">-->
<!--      <div class="total-info-title">-->
<!--        <div style="display: flex;">-->
<!--          <img src="/assets/images/svg-icons/f_payout.svg" alt="">-->
<!--          <span class="total-txt-1">Payout</span>-->
<!--        </div>-->
<!--        <img src="/assets/images/svg-icons/f_chip_extraction.svg" alt="">-->
<!--      </div>-->
<!--      <div style="margin: 0 26px;">-->
<!--        <div class="total-info-flex">-->
<!--          <p class="total-txt-2" style="color: #A29BB1;">Last Payout</p>&nbsp;-->
<!--          <p class="total-txt-amount"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="total-no-bank"
         *ngIf="!bank && bFetched">
      <p class="no-bank-title">{{'finance.SetYourBankAccount' | translate:'Set Up Your Bank Account'}}</p>
      <p class="no-bank-text mb50">{{'finance.Toensureasmoothexp' | translate:'To ensure a smooth experience and access to all our features, please take a moment to set up your bank account.'}}</p>
      <p class="no-bank-list-title">{{'finance.WhydoIneedtodothis' | translate:'Why do I need to do this?'}}</p>
      <ul class="no-bank-list">
        <li>{{'finance.FastPaymentsReceivepayments' | translate:'Fast Payments: Receive payments quickly and securely.'}}</li>
        <li>{{'finance.SeamlessTransactionsEnjoy' | translate:'Seamless Transactions: Enjoy hassle-free transactions with integrated banking.'}}</li>
        <li>{{'finance.EnhancedSecurityProtect' | translate:'Enhanced Security: Protect your funds with industry-standard security measures.'}}</li>
      </ul>
    </div>

    <div class="total-set" *ngIf="!bank && bFetched">
      <div class="total-set-inner">
        <img src="/assets/images/svg-icons/f_no_bank.svg" alt="bank">
        <p class="total-txt-4">{{'finance.SetYourBankInfo' | translate:'Set your bank info'}}</p>
        <button class="btn-white" (click)="openBankInfoModal()">{{'finance.AddAccount' | translate:'Add Account'}}</button>
      </div>
    </div>

    <div class="total-edit" *ngIf="bank && bFetched">
      <div class="total-info-title with-info">
        <div class="total-set-inner">
          <img src="/assets/images/svg-icons/f_no_bank.svg" alt="bank">
<!--          <span class="total-txt-1">{{'finance.YourAccount' | translate:'Your Account'}}</span>-->
        </div>
      </div>
      <div style="margin: 20px 26px;">
        <div class="total-info-flex" *ngIf="bank.account_number">
          <p class="total-txt-2" style="color: #A29BB1;">{{'finance.AccountNumber' | translate:'Account Number'}}&#160;</p>&nbsp;
          <p class="total-txt-amount">
            <span>{{bank.account_number}}</span>
          </p>
        </div>
        <div *ngIf="stripeConnectCompleted" style="margin-bottom: 15px;">
          <span class="total-txt-2">Payout via Stripe connect&#160;</span><br>
          <span *ngIf="stripeStatus&&stripeStatus.record">{{stripeStatus.record.account_id}}</span>
        </div>
        <div *ngIf="!stripeConnectCompleted">
          <p *ngIf="bank.country" class="total-txt-centered">
            <span class="total-txt-2">{{'finance.Country' | translate:'Country'}}&#160;</span>
            <span *ngIf="bank.country == 'other'">{{bank.country | uppercase}}</span>
            <img *ngIf="bank.country && bank.country != 'other'" style="width: 30px; margin: 0 10px;" [src]="'assets/images/country-flags3x2/'+bank.country.toUpperCase()+'.svg'">
          </p>

          <p *ngIf="bank.city" class="total-txt-centered">
            <span class="total-txt-2">{{'finance.City' | translate:'City'}}&#160;</span>
            <span class="total-txt-3">{{bank.city}}</span>
          </p>
          <p *ngIf="bank.bank_name" class="total-txt-centered">
            <span class="total-txt-2">{{'finance.bankName' | translate:'Bank Name'}}&#160;</span>
            <span class="total-txt-3">{{bank.bank_name}}</span>
          </p>
          <p *ngIf="bank.address" class="total-txt-centered">
            <span  class="total-txt-2">{{'finance.LivingAddress' | translate:'Living Address'}}&#160;</span>
            <span class="total-txt-3">{{bank.address}}</span>
          </p>
          <p *ngIf="bank.account_name" class="total-txt-centered">
            <span class="total-txt-2">{{'finance.AccountOwnerName' | translate:'Account owner\'s name'}}&#160;</span>
            <span class="total-txt-3">{{bank.account_name}}</span>
          </p>
          <p *ngIf="bank.country === 'other'&&bank.other_bank_details" class="total-txt-centered">
            <span  class="total-txt-2">{{'finance.Bankdetails' | translate:'Bank details'}}&#160;</span>
            <span class="total-txt-3">{{bank.other_bank_details}}</span>
          </p>
        </div>
      </div>
      <button class="btn-white" style="display: block;margin: auto;" (click)="openBankInfoModal()">
        <span *ngIf="!stripeConnectPending && !(stripeConnectCompleted || (bank && bank.user_plaid_id))">{{'finance.EditAccount' | translate:'Edit Account'}}</span>
        <span *ngIf="stripeConnectPending"> Continue setting payout via Stripe</span>
        <span *ngIf="stripeConnectCompleted || (bank && bank.user_plaid_id)">{{'finance.ViewBankDetails' | translate:'View Bank Details'}}</span>
      </button>

    </div>
  </div>


  <ng-template #payments></ng-template><!--   Payments-->
  <ng-template #payouts></ng-template><!--   Payout-->


</section>
