<div class="custom-modal-footer">
  <div class="socials">
    <div class="app-links">
        <a href="https://play.google.com/store/apps/details?id=com.getin.app" target="_blank">
          <img src="/assets/images/svg-icons/google-play.svg" alt="get-in-google-play">
        </a>
        <a href="https://apps.apple.com/app/getin/id1381205665" target="_blank">
          <img src="/assets/images/svg-icons/app-store.svg" alt="get-in-app-store">
        </a>
    </div>
    <div class="social-links">
      <a href="https://www.youtube.com/channel/UCS-K8zsYM7vCBTCFmLOerjA" target="_blank"><img src="/assets/images/svg-icons/modal-footer/youtube.svg" alt="youtube"></a>
      <a href="https://www.instagram.com/getinofficial/"  target="_blank"><img src="/assets/images/svg-icons/modal-footer/instagram.svg" alt="instagram"></a>
      <a href="https://www.tiktok.com/@getin_official"  target="_blank"><img src="/assets/images/svg-icons/modal-footer/tik-tok.svg" alt="tik-tok"></a>
      <a href="https://discord.com/invite/d8PAYdGTAW"  target="_blank"><img src="/assets/images/svg-icons/modal-footer/discord.svg" alt="discord"></a>
      <a href="https://twitter.com/getin_official"  target="_blank"><img src="/assets/images/svg-icons/modal-footer/twitter.svg" alt="twitter"></a>
      <a href="https://www.facebook.com/getinofficiaI"  target="_blank"><img src="/assets/images/svg-icons/modal-footer/facebook.svg" alt="facebook"></a>
    </div>
  </div>
  <hr>

</div>
