import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CryptoPaymentService {

  api_base_url = environment.api_base_url;

  setStatus = new Subject();
  checkStatus = this.setStatus.asObservable();

  constructor(private http: HttpClient) { }

  checkCryptoPayments (data: any) { // data: {coinbase_id: coinbase_id}
		return this.http.post(this.api_base_url + '/api/check-crypto-payments/automatic', data,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
    });
  }

  checkCryptoPayment(data: any) {
		return this.http.post(this.api_base_url + '/api/check-crypto-payments', data,
		{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
	}

}
