import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { EventService } from '../../services/event.service';
import { NavbarService } from '../../services/navbar.service';
import { GlobalsService } from '../../globals';

@Component({
  selector: 'app-cms-dashboard',
  templateUrl: './cms-dashboard.component.html',
  styleUrls: ['./cms-dashboard.component.css']
})
export class CmsDashboardComponent implements OnInit, OnDestroy {
  loader = false;
  chartScope = {
    yearly: 1,
    monthly: 2,
    weekly: 3,
    hourly: 4
  };
  DropdownIncomeValue: 3; // weekly by first page load
  revenues_data = [];
  count_data = [];
  barChartIncomeLabels: string[] = [];
  barChartIncomeData: any[] = [];
  barChartCountLabels: string[] = [];
  barChartCountData: any[] = [];
  barChartOptions: any = {
    responsive: true,
    scales: {
      y: {
        ticks: {
          z: 0
        }
      }
    }
  };
  barChartType = 'bar';
  // barChartColors = [
  //   {
  //     backgroundColor: this.globals.graphsBackgroundColors,
  //     borderColor: this.globals.graphsBorderColors,
  //     borderWidth: 1
  //   }
  // ];
  monthArr = ["Jan","Feb","March","Apr","May","Jun","Jul","Aug","Sep","Octo","Nov","Dec"];
  selectedMonthIndex: number = null;
  constructor(
    private eventService: EventService,
    private globals: GlobalsService,
    public nav: NavbarService
  ) {
    this.nav.customWrapperClass = 'full-screen';
  }
  @ViewChild('scopeDropdownIncome') selectIncomes;
  @ViewChild('scopeDropdownCount') selectCounts;

  ngOnInit() {
    this.nav.setIsNewUi(false);
  }
  changeScope(scope, type) {
    this.getAndUpdateChartData(scope, type);
  }
  changeGraph(type) {
    let scope = 2;
    if (type === '') {
      this.clearChartData('');
      return
    }
    if (type === 'incomes' && this.selectIncomes !== undefined) {
      scope = this.selectIncomes.nativeElement.value;
    }
    if (type === 'counts' && this.selectCounts !== undefined) {
      scope = this.selectCounts.nativeElement.value;
    }

    this.getAndUpdateChartData(scope, type);
  }

  getAndUpdateChartData(scope, type = 'incomes', month = null) {
    this.loader = true;

    // Default month
    if (scope == 2 && type == 'incomes' && month === null) {
      const d = new Date();
      // month = d.getMonth()+1;
      this.setIndex(d.getMonth());
    }

    this.eventService.getDashboardGraphEvent(scope, type, month).subscribe(
      (resp: any) => {
        this.updateChart(resp);
        this.loader = false;
      },
      error => {
        this.loader = false;
      }
    );
  }
  setIndex(index: number) {
    this.selectedMonthIndex = index;
  }

  updateChart(data) {
    // if (data.hasOwnProperty('events_incomes')) {
    //   this.clearChartData('incomes');
    //   for (const income of data.events_incomes) {
    //     this.revenues_data.push(parseFloat(income.sum_income));
    //     this.barChartIncomeLabels.push(income.date_filter);
    //   }
    //   this.barChartIncomeData.push({ data: this.revenues_data });
    // }

    if (data.hasOwnProperty('events_incomes')) {
      this.clearChartData('incomes');
      this.barChartIncomeLabels = data.events_incomes.labels;
      this.barChartIncomeData = data.events_incomes.data;
    }

    if (data.hasOwnProperty('events_counts')) {
      this.clearChartData('counts');
      for (const count of data.events_counts) {
        this.count_data.push(parseFloat(count.total_events));
        this.barChartCountLabels.push(count.date_filter);
      }
      this.barChartCountData.push({ data: this.count_data });
    }
  }

  clearChartData(type) {
    if (type === 'incomes') {
      this.barChartIncomeLabels = [];
      this.barChartIncomeData = [];
      this.revenues_data = [];
    }
    if (type === 'counts') {
      this.barChartCountLabels = [];
      this.barChartCountData = [];
      this.count_data = [];
    }
    if (type === '') {
      this.barChartCountLabels = [];
      this.barChartCountData = [];
      this.count_data = [];
      this.barChartIncomeLabels = [];
      this.barChartIncomeData = [];
      this.revenues_data = [];
    }
  }

  ngOnDestroy() {
    this.nav.customWrapperClass = '';
  }
}
