export default [
  {
    id: 13,
    name: {
      he: 'בנק אגוד לישראל בע"מ',
      en:'Union Bank of Israel',
      es: 'Union Bank of Israel'
    }
  },
  {
    id: 14,
    name: {
      he: 'בנק אוצר החייל בע"מ',
      en:'Bank Otsar Ha-Hayal',
      es:'Bank Otsar Ha-Hayal'
    }
  },
  {
    id: 11,
    name: {
      he: 'בנק דיסקונט לישראל בע"מ',
      en:'Israel Discount Bank',
      es:'Israel Discount Bank'
    }
  },
  {
    id: 68,
    name: {
      he: 'בנק דקסיה ישראל בע"מ',
      en:'Dexia Israel Bank',
      es:'Dexia Israel Bank'
    }
  },
  {
    id: 12,
    name: {
      he: 'בנק הפועלים בע"מ',
      en:'Bank Hapoalim',
      es:'Bank Hapoalim'
    }
  },
  {
    id: 4,
    name: {
      he: 'בנק יהב לעובדי המדינה בע"מ',
      en:'Bank Yahav for Government Employees',
      es:'Bank Yahav for Government Employees'
    }
  },
  {
    id: 54,
    name: {
      he: 'בנק ירושלים בע"מ',
      en:'Bank of Jerusalem',
      es:'Bank of Jerusalem'
    }
  },
  {
    id: 10,
    name: {
      he: 'בנק לאומי לישראל בע"מ',
      en:'Bank Leumi Le-Israel',
      es:'Bank Leumi Le-Israel'
    }
  },
  {
    id: 30,
    name: {
      he: 'בנק למסחר בע"מ',
      en:'Trade Bank',
      es:'Trade Bank'
    }
  },
  {
    id: 20,
    name: {
      he: 'בנק מזרחי טפחות בע"מ​',
      en:'Mizrahi Tefahot Bank',
      es: 'Mizrahi Tefahot Bank'
    }
  },
  {
    id: 46,
    name: {
      he: 'בנק מסד בע"מ',
      en:'Bank Massad',
      es:'Bank Massad'
    }
  },
  {
    id: 17,
    name: {
      he: 'בנק מרכנתיל דיסקונט בע"מ',
      en:'Mercantile Discount Bank',
      es:'Mercantile Discount Bank'
    }
  },
  {
    id: 34,
    name: {
      he: 'בנק ערבי ישראלי בע"מ',
      en:'Arab Israel Bank',
      es:'Arab Israel Bank'
    }
  },
  {
    id: 52,
    name: {
      he: 'בנק פועלי אגודת ישראל בע"מ',
      en:'Poaley Agudat Israel Bank',
      es:'Poaley Agudat Israel Bank'
    }
  },
  {
    id: 31,
    name: {
      he: 'הבנק הבינלאומי הראשון לישראל בע"מ',
      en:'The First International Bank of Israel',
      es:'The First International Bank of Israel'
    }
  },
  {
    id: 26,
    name: {
      he: 'יובנק בע"מ',
      en:'UBank',
      es:'UBank'
    }
  },
  {
    id: 9,
    name: {
      he: 'בנק הדואר',
      en:'Postal Bank',
      es:'Postal Bank'
    }
  },
  {
    id: 18,
    name: {
      he: 'וואן זירו הבנק הדיגיטלי',
      en:'One Zero Digital Bank',
      es:'One Zero Digital Bank'
    }
  }
];
