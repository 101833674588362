<div class="wallets-wrapper" *ngIf="isOpenWallets">
    <!-- Construct a Bootstrap layout -->
    <div class="wallets-container">
        <div class="wallet-content">
            <div i18n="Title page | Confirmation" class="wallet-title">Confirmation</div>
            <div *ngIf="transactionUrl && isShowUrl" class="transaction-copy" (click)="copyToClipboard('url')">
                <div class="transaction-url">{{transactionUrl}}</div>
                <span i18n="Label | Copy URL to check payment" class="transaction-notice">Copy this URL to check payment later</span>
            </div>
            <div class="wallet-data-wrapper" [style]="!isConnected ? 'background: transparent;' : 'background: #22262F00;'" *ngIf="eventTicketsData">
                <!-- <div *ngIf="eventTicketsData && ticketsPrice" class="ticket-amount">
                    <div>Comission: {{eventTicketsData.payment_details.commission_ils}}</div>
                    <div>Price w/o fee: {{eventTicketsData.payment_details.net_payment}}</div>
                    <div class="ticket-price">Ticket price: {{'ETH: '+ ticketsPrice + ' / ' + eventTicketsData.payment_details.currency + ': ' + eventTicketsData.payment_details.payment}}</div>
                </div> -->
                <div *ngIf="eventTicketsData && ticketsPrice" class="wallet-connect">
                    <!-- <div class="wallet-user" *ngIf="!isConnected">
                        <span class="wallet-info">No wallet connected.</span>
                        <span class="wallet-info">Connect wallet to show accounts and their ETH balances.</span>
                    </div> -->
                    <div i18n="Label | Connect to wallet" id="btn-connect" i18n="button payment | Pay with WalletConnect" class="connect-with-btn" [style]="!isConnected && !isSuccessful? 'opacity: 1; cursor: pointer;' : 'opacity: 0.5; cursor: not-allowed;'" *ngIf="!isConnected && showConnectBtn" (click)="!isSuccessful ? onConnect() : null">Connect wallet</div>
                    <!-- <div i18n="button payment | Pay with WalletConnect" class="disconnect-btn" *ngIf="isConnected" (click)="onDisconnect()"><span class="disconnect-text">Disonnect</span></div> -->
                </div>
                <div class="reconnect-bar">
                  <div  class="disconnect-btn" *ngIf="!isConnected && !showConnectBtn && !isSuccessful && showReconnectBtn" (click)="onConnect()"><span i18n="Label | Reconnect to wallet" class="disconnect-text">Reconnect</span></div>
                </div>
                <!-- To test on droduct server -->
                <div style="display: none;" #wallettestbuttons *ngIf="eventTicketsData && ticketsPrice" class="wallet-connect">
                    <!-- <div class="wallet-connect" style="display: block;">
                        <div id="btn-connect" i18n="button payment | Pay with WalletConnect" class="temp-btn" (click)="setSecondProvider()">Test get Trn</div>
                    </div>
                    <div class="wallet-connect" style="display: block;">
                        <div id="btn-connect" i18n="button payment | Pay with WalletConnect" class="temp-btn" (click)="getPendingBlock()">Get blocks</div>
                    </div>
                    <div class="wallet-connect" style="display: block;">
                        <div id="btn-connect" i18n="button payment | Pay with WalletConnect" class="temp-btn" (click)="getBlockByHash()">Get Block by Hash</div>
                    </div> -->

                    <!-- <div class="wallet-connect" style="display: block;">
                        <div id="btn-connect" i18n="button payment | Pay with WalletConnect" class="temp-btn" (click)="getPendingTrn()">Get Pending </div>
                    </div> -->
                    <!-- <div class="wallet-connect" style="display: block;">
                        <div id="btn-connect" i18n="button payment | Pay with WalletConnect" class="temp-btn" (click)="hashPrewiev()">Prewiev hash </div>
                    </div> -->
                   <!-- <div *ngIf="isUser" class="wallet-connect" style="display: block;">
                    <div id="btn-connect" i18n="button payment | Pay with WalletConnect" class="temp-btn" (click)="getCoinsList()">Get Coins List</div>
                  </div> -->
                  <!-- <div *ngIf="isUser" class="wallet-connect" style="display: block;">
                    <div id="btn-connect" i18n="button payment | Pay with WalletConnect" class="temp-btn" (click)="setGasPrice()">Set Gas Price 1/2 </div>
                  </div>
                  <div *ngIf="isUser" class="wallet-connect" style="display: block;">
                      <div id="btn-connect" i18n="button payment | Pay with WalletConnect" class="temp-btn" (click)="getTransactionByHash()">Get Trn by hash </div>
                  </div>
                  <div class="wallet-connect" *ngIf="isUser && hasBalances && eventTicketsData" style="display: block;">
                      <div id="btn-connect" i18n="button payment | Pay with WalletConnect" class="temp-btn" (click)="saveWalletConnectTransaction()">Save result</div>
                  </div> -->
                </div>
                <!-- <div *ngIf="isConnected" class="accounts"><span i18n="section title | Crypto wallets" class="account-title">User account:</span>
                    <div class="user-account">
                        <div class="network-names">
                            <span class="">Connected blockchain:</span>
                            <span class="">{{userAccounts.chainData.name}}</span>
                        </div>
                        <div class="account-addresses" *ngFor="let user_account of userAccounts.accounts">
                            <span class="account-address">Selected account:</span>
                            <span class="account-address">{{user_account}}</span>
                        </div>
                    </div>
                </div> -->
                <div *ngIf="hasBalances" class="all-balances">
                    <!-- <div class="balances-title">
                        <span class="balances-address">Address:</span>
                        <span class="balances-amount">Balance:</span>
                    </div> -->
                    <div class="account-balances" *ngFor="let account of accountBalances">
                        <div class="balances-block-main">
                            <div class="price-block">
                              <!-- <div class="balances-address">Address:</div> -->
                              <div class="balance-data">
                                <div class="account-address">{{account.address}}</div>
                                <div class="account-network">{{account.networkName}}</div>
                              </div>
                              <div i18n="Label | Wallet disconnect" class="disconnect-btn" *ngIf="isConnected" (click)="onDisconnect()"><span class="disconnect-text">Disonnect</span></div>
                            </div>
                        </div>
                        <div class="balances-block-wrapper">
                            <div class="price-block">
                                <div i18n="Label | Balance" class="balances-amount">Balance:</div>
                                <div class="account-prices">
                                    <div class="account-balance" [style]="dir ==='ltr' ? 'font-weight: 600; width: 90%;' : 'font-weight: 600; width: auto;'">{{ account.humanFriendlyBalance }} {{chainData.shortName.toUpperCase()}}</div>
                                    <div class="account-balance" [style]="dir ==='ltr' ? 'font-weight: 600; width: 90%;' : 'font-weight: 600; width: auto;'">{{"$ "}}{{account.balanceInUSD}}</div>
                                </div>
                            </div>
                            <!-- <div *ngIf="account.humanFriendlyBalance > transactionData?.ticketPriceTotal; then checkFounds(account.humanFriendlyBalance)"></div> -->
                            <div i18n="Error label | Insufficient funds" class="error-founds" *ngIf="!founds">Insufficient funds.</div>
                        </div>
                    </div>

                </div>
                <!-- <div *ngIf="hasBalances && eventTicketsData" class="update-timer">
                    <span class="">Update every 15 sec</span>
                </div> -->
                <div *ngIf="hasBalances && eventTicketsData" class="wallet-transaction-params">
                    <div class="rate-block-wrapper">
                        <div class="price-block">
                            <span  class="wallet-transaction-gas" [style]="'width: auto;'">Gas: </span>
                            <span id="wallet-transactions" class="transaction-gas" [style]="dir ==='ltr' ? 'font-weight: 600; width: 90%;' : 'font-weight: 600; width: auto;'">{{transactionData?.gas | number: '1.0-12'}} {{' GWei'}}</span>
                        </div>
                    </div>
                    <div class="rate-block-wrapper">
                        <div class="price-block">
                            <span  class="wallet-transaction-gasPrice" [style]="'width: auto;'">Gas price: </span>
                            <span id="wallet-transactions" class="transaction-gasPrice" [style]="dir ==='ltr' ? 'font-weight: 600; width: 90%;' : 'font-weight: 600; width: auto;'">{{transactionData?.gasPriceEth | number: '1.2-12' }} {{chainData.shortName.toUpperCase()}}</span>
                        </div>
                    </div>
                    <div class="balances-block">
                      <span i18n="Label | Max fee" class="wallet-transaction-maxFee" [style]="'width: auto;'">Max fee: </span>
                      <div class="account-prices">
                          <span id="wallet-transactions" class="transaction-maxFee" [style]="dir ==='ltr' ? 'font-weight: 600; width: 90%;' : 'font-weight: 600; width: auto;'">{{ transactionData?.fee | number: '0.2-12' }} {{chainData?.shortName.toUpperCase()}}</span>
                          <span *ngIf="!isUsd" id="wallet-transactions" class="transaction-maxFee" [style]="dir ==='ltr' ? 'width: 90%;' : 'width: auto;'">{{eventTicketsData.payment_details.currency + ' '}}{{eventCurRate*transactionData.fee | number: '0.2-2' }}</span>
                          <span id="wallet-transactions" class="transaction-maxFee" [style]="dir ==='ltr' ? 'width: 90%;' : 'width: auto;'">{{'$ '}}{{usdCurRate*transactionData.fee | number: '0.2-2' }}</span>
                      </div>
                  </div>
                    <div class="balances-block" style="border-bottom: none;">
                        <span *ngIf="globals.shouldBePlural(eventTicketsData?.event_details?.totalTickets)" i18n="Label | Ticket price" class="wallet-transaction-ticket" [style]="'width: auto;'">Tickets price: </span>
                        <span *ngIf="!globals.shouldBePlural(eventTicketsData?.event_details?.totalTickets)" i18n="Label | Ticket price" class="wallet-transaction-ticket" [style]="'width: auto;'">Ticket price: </span>
                        <div class="account-prices">
                            <span id="wallet-transactions" class="transaction-ticket" [style]="dir ==='ltr' ? 'font-weight: 600; width: 90%;' : 'font-weight: 600; width: auto;'">{{ ticketsPrice }} {{chainData.shortName.toUpperCase()}}</span>
                            <span *ngIf="!isUsd" id="wallet-transactions" class="transaction-ticket" [style]="dir ==='ltr' ? 'width: 90%;' : 'width: auto;'">{{eventTicketsData.payment_details.currency + ' '}} {{eventTicketsData.payment_details.payment}}</span>
                            <span id="wallet-transactions" class="transaction-ticket" [style]="dir ==='ltr' ? 'width: 90%;' : 'width: auto;'">{{'$ '}} {{eventTicketsData.payment_details.payment/eventCurRate*usdCurRate | number: '0.2-2'}}</span>
                        </div>
                    </div>
                </div>
                <div  *ngIf="hasBalances && eventTicketsData" class="balances-total">
                  <span i18n="Label | Total price" class="wallet-transaction-total" [style]="'width: auto;'">Total price: </span>
                  <div class="account-prices">
                      <span id="wallet-transactions" class="transaction-total" [style]="dir ==='ltr' ? 'font-weight: 500; width: 90%;' : 'font-weight: 500; width: auto;'">{{ transactionData.ticketPriceTotal | number: '0.2-12' }} {{chainData.shortName.toUpperCase()}}</span>
                      <span *ngIf="!isUsd" id="wallet-transactions" class="transaction-total" [style]="dir ==='ltr' ? 'width: 90%;' : 'width: auto;'">{{eventTicketsData.payment_details.currency + ' '}}{{eventCurRate*transactionData.ticketPriceTotal | number: '0.2-2' }}</span>
                      <span id="wallet-transactions" class="transaction-total" [style]="dir ==='ltr' ? 'width: 90%;' : 'width: auto;'">{{'$ '}}{{usdCurRate*transactionData.ticketPriceTotal | number: '0.2-2' }}</span>
                  </div>
                </div>

                <div *ngIf="isCheckTrnStatus" class="transaction-status">
                    <div *ngIf="transaction?.hashPrew" class="hash-wrapper">
                        <div class="hash-title">Transaction Hash: </div>
                        <div id="hashPrew" class="hash-prew" (click)="copyToClipboard('hash')"> {{ transaction?.hashPrew}}</div>
                    </div>
                    <div *ngIf="dbTransactionState === 'stopped'" class="error-message">Something went wrong, please save the transaction hash and contact Getin support</div>
                    <!-- <div *ngIf="transaction?.hash" class="trn-message">Transaction Hash: {{transaction?.hash}}</div> -->
                    <div *ngIf="transaction?.blockNumber" class="trn-message">Transaction Block: {{transaction?.blockNumber}}</div>
                    <div class="status-bar">
                      <div i18n="Label | Transaction status" *ngIf="transaction?.message" class="trn-message" style="white-space: normal;">Transaction Status: {{transaction?.message}}</div>
                      <div *ngIf="isChecking" class="spinner-dual-ring checking-status"></div>
                    </div>
                    <div *ngIf="isChecking" class="status-message">
                      <span class="checking-message">Your order for event has been processed. It should be confirmed on the blockchain shortly.</span>
                    </div>
                </div>
            </div>
                <div class="wallet-connect" *ngIf="hasBalances && eventTicketsData">
                    <!-- <div i18n="button payment | Pay with WalletConnect" [style]="!isCheckTrnStatus && founds && dbTransactionState !== 'stopped' ? 'opacity: 1; cursor: pointer;' : 'opacity: 0.5; cursor: not-allowed;'" class="pay-with-btn fixed-btn" (click)="!isCheckTrnStatus && founds && dbTransactionState !== 'stopped' ? sendTransaction() : null">Send Payment</div> -->
                    <!-- <div *ngIf="!isCheckTrnStatus && founds && dbTransactionState !== 'stopped'; then sendTransactionBtn(true)"></div> -->
                  </div>
                <div class="check-state" *ngIf="canCheckTransaction">
                    <div i18n="Label | Check transaction state" i18n="button payment | Pay with WalletConnect" [style]="isShowButton ? 'opacity: 1; cursor: pointer;' : 'opacity: 0.5; cursor: not-allowed;'" class="check-with-btn" (click)="isShowButton ? checkTransactionAfter() : null">Check state manually</div>
                </div>
        </div>
    </div>
</div>
