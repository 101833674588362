<h1 mat-dialog-title>
  <div class="row">
    <div class="col-md-12 col-xs-12 text-center">
      <span i18n="@@ChangeSellerModalChangeSalespesonOf">Change salesperson of</span>&nbsp;{{data.purchase_user.first_name}} {{data.purchase_user.last_name}} {{data.purchase_user.id}}
    </div>
  </div>
</h1>
<div mat-dialog-content>
  <div class="col-md-12 col-xs-12 text-center">
      <form class="example-form">
          <mat-form-field class="example-full-width" [style.text-align]="'center'">
            <input i18n-placeholder="@@ChangeSellerModalChooseSalesperson" type="text" placeholder="Choose salesperson" matInput [formControl]="sellerInput" [matAutocomplete]="auto" required>
            <mat-error i18n="@@ChangeSellerModalErrorsalesmanrequired" *ngIf="sellerInput.hasError('required')">
              Valid salesperson must be specified.
            </mat-error>
            <mat-error i18n="@@ChangeSellerModalErrorInvalidsalesman" *ngIf="sellerInput.hasError('noSeller')">
              Salesperson does not exist
            </mat-error>
            <mat-autocomplete #auto="matAutocomplete" >
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option.email" (click)="setSeller()" >
                {{option.email}} <span *ngIf="option.event_seller === 0">-- PRODUCER</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </form>
  </div>
</div>
<div class="row">
  <div class="col-md-6 col-xs-6">
    <button i18n="@@Change" (click)="changeSeller()" class="btn btn-success mutate-float-right">Change</button>
  </div>
  <div class="col-md-6 col-xs-6">
    <button i18n="@@Cancel" class="btn btn-danger mutate-float-left" [matDialogClose]>Cancel</button>
  </div>
</div>
