<section class="cms_users">
	<h1 i18n="@@CmsUsers">Users</h1>
	<form (ngSubmit)="searchUser()" #searchForm="ngForm">
	<div class="row">
		<div class="search col-md-3 col-xs-12">
			<label i18n="@@UserType" for="user_type">User type:</label>
			<select id="user_type" class="form-control" #t (change)="filter_users(t.value)">
					<option i18n="@@CmsUsersTypes" *ngFor="let filter of filter_type_code" [selected]="filter.code == filter_type" [value]="filter.code">{
						filter.code, plural,
            =1 {Producers} =2 {Salesmen} =3 {All active users} =4 {All inactive users}
					}</option>
			</select>
		</div>
		<div class="search col-md-6 col-xs-12">
			<label i18n="@@CmsUserLookup" for="search_user">User lookup:</label>
			<input id="search_user" class="form-control" type="text" [(ngModel)]="filterText" name="search" placeholder="Search by email or phone number">
		</div>
		<div class="col-md-2 col-xs-8 pt25">
				<button i18n="@@CmsSearch" type="submit" class="btn btn-primary" style="width:100%;">Search</button>
		</div>
		<div class="col-md-1 col-xs-4 pt25">
			<button i18n="@@CmsClear" type="button" class="btn btn-danger" style="width:100%;" (click)="clearSearch()">Clear</button>
		</div>
	</div>
</form>
	<br>
	<div *ngIf="users?.length == 0"><h3 class="text-center">No users found</h3></div>
	<div *ngIf="users?.length > 0" id="search_event" class="pending_table">
		<div class="row">
			<div class="col-md-12 col-xs-12">
				<div class="table-responsive">
					<div *ngIf="loader" class="mat-spinner-center">
							<!-- <img class="loader img-responsive" src="assets/images/preloader.gif"/> -->
							<mat-spinner style="margin:0 auto;" strokeWidth="5" diameter="70"></mat-spinner>
					</div>
					<table class="table table-striped table-hover">
						<thead>
						<tr>
							<th>#</th>
							<th i18n="@@CmsUsername">Username</th>
							<th i18n="@@CmsStatus" *ngIf="t.value == 4">Status</th>
							<th i18n="@@CmsEmail">Email</th>
							<th i18n="@@CmsPhone">Phone</th>
							<th i18n="@@CmsEvents">Events</th>
							<th i18n="@@CmsOptions">Options</th>
						</tr>
						</thead>
						<tbody>
						<tr id="{{user.id}}" *ngFor="let user of users | paginate: { id: 'server', itemsPerPage: pageSize, currentPage: page,  totalItems: total }">
								<th scope="row">{{user.id}}</th>
								<td>{{user.first_name}} {{user.last_name}} <span *ngIf="user.type">({
									salesmen_type[user.type].type, plural,
                  =0 {Producer} =1 {Salesman} =2 {Team Leader} =3 {Manager}
								})</span></td>
								<td *ngIf="t.value == 4">
										<span i18n="@@CmsDeleted" *ngIf="user.status == 0" class="label label-danger">Deleted</span>
										<span i18n="@@CmsInactive" *ngIf="user.status == 1 && user.active == 0" class="label label-danger">Inactive</span>
								</td>
								<td>{{user.email}}</td>
								<td class="table-cell-phone-number">{{user.phone}}</td>
								<td><a i18n="@@CmsViewEvents" href="#" [routerLink]="['/cms/events/' + user.id + '/active']">View events</a></td>
								<td>
                  <button title="Swix" class="transparent" (click)="showSvixModal(user.id)">
                    <img style="width: 45px;" src="assets/images/svg-icons/svix.svg">
                  </button>
                  <button title="Impersonation as a user" class="transparent mr10" (click)="impersonation(user.id)">
                    <i class="fa fa-user-secret"></i>
                  </button>
									<button i18n-title="@@CmsEdit" title="Edit" class="transparent mr10" (click)="updateUserDetails(user.id)">
										<i class="fa fa-edit"></i>
									</button>
									<button i18n-title="@@CmsResetPassword" title="Reset password" class="mr10 transparent" (click)="updateUserPassword(user.id)"><i class="fa fa-unlock-alt"></i></button>
<!--									<button i18n-title="title attribute | add external API" title="הוספת API חיצוני" class="mr10 transparent"><a href="#" [routerLink]="['/cms/external-api/' + user.id + '/0']"><i class="fa fa-code"></i></a></button>-->
									<button i18n-title="@@CmsDelete" title="Delete" *ngIf="user.status == 1" class="mr10 transparent" (click)="updateUserStatus(user.id, 0)"><i class="fa fa-trash-o"></i></button>
									<button i18n-title="@@CmsRestoreUser" title="Restore user" *ngIf="user.status == 0" class="mr10 transparent" (click)="updateUserStatus(user.id, 1)"><i class="fa fa-plus"></i></button>
								</td>
						</tr>
						</tbody>
					</table>
				</div>
				<div *ngIf="total > pageSize" class="col-md-12 col-xs-12 text-center">
					<pagination-controls
            i18n-nextLabel="@@CmsNextPage" i18n-previousLabel="@@CmsPrevPage"
						class="pagination col-md-12 col-xs-12"
						(pageChange)="getCmsUsers($event, t.value, false, true)"
						responsive="true"
						nextLabel="Next page"
						previousLabel="Previous page"
						id="server">
					</pagination-controls>
				</div>
			</div>
		</div>
	</div>
</section>

