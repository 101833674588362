<div class="main-scanned-container" [ngClass]="{'rtl' : dir === 'rtl'}">
  <!-- <div class="scanned-container">
    <div class="events-stat" *ngFor="let total of totals; let i = index">
      <div class="events-type-container">
        <div class="events-type-name">{{total.name}}</div>
        <div class="events-type-number">{{total.col}}</div>
      </div>
    </div>
  </div> -->
  <div class="scanned-container">
    <div class="events-stat">
      <div class="events-type-container">
        <div class="events-type-name" i18n="table header | scanner status - scanned">TOTAL VISITORS</div>
          <div class="events-type-number">{{statsData.scanned_data?.views || 0 | number:'1.'}}</div>
      </div>
    </div>
    <div class="events-stat">
      <div class="events-type-container">
        <div class="events-type-name" i18n="table header | scanner status - scanned">SCANNED</div>
          <div class="events-type-number">{{statsData.scanned_data?.scanned || 0 | number:'1.'}}</div>
      </div>
    </div>
    <div class="events-stat">
      <div class="events-type-container">
        <div class="events-type-name" i18n="table header | scanner status - not scanned">DIDN’T SCAN</div>
          <div class="events-type-number">{{statsData.scanned_data?.unscanned || 0 | number:'1.'}}</div>
      </div>
    </div>
  </div>

  <div class="display-chart gm-chart" *ngIf="lineChartScannedDataByMinutes.length > 0 && lineChartScannedLabelsByMinutes.length > 0">
    <div class="check-ins">
      <div class="per-min">
        <span class="per-min-count">&asymp;{{checkInsPerMin}}</span>
        <p class="per-min-count-txt">
          <span>{{'analytic-scanned.Check-ins' | translate:'Check-ins'}}</span><br>
          <span>{{'analytic-scanned.perMin' | translate:'per min'}}</span>
        </p>
      </div>
      <div>
        <button class="refresh-btn" (click)="refreshScannedChart()">
          <img src="/assets/images/svg-icons/refresh-2.svg" alt="">
          <span>{{'analytic-scanned.RefreshData' | translate:'Refresh Data'}}</span>
        </button>
      </div>
      <div class="enter-time">
        <mat-form-field appearance="fill" *ngIf="statsData.scanned_graph.list">
          <mat-label>{{firstCheckIn}}</mat-label>
          <mat-select class="dark" panelClass="scanned-select">
            <mat-option disabled *ngFor="let list of statsData.scanned_graph.list" [value]="list.date_filter">
              {{list.date_filter | date : 'short'}} <span class="badge">{{list.total}}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <img class="img-dropdown" src="/assets/images/svg-icons/arrow-circle-down.svg" alt="">
      </div>
    </div>
    <canvas id="myScannedChart" #myScannedChart></canvas>
  </div>

    <ng-container  *ngIf="statsData.hasOwnProperty('guest_manager_table') && statsData.guest_manager_table; else readerList">
    <div *ngIf="statsData.reader_list.length" class="scannedByScanner-table">
      <p style="color: #C4C4C4;font-weight: bold;">{{'analytic-scanned.ScannedByScanner' | translate:'Scanned by scanner'}}</p>
      <table class="table">
        <thead>
        <tr>
          <th>{{'analytic-scanned.DeviceID' | translate:'Device ID'}}</th>
          <th>{{'analytic-scanned.DeviceName' | translate:'Device Name'}}</th>
          <th *ngFor="let t of statsData.reader_ticket_list">{{t.name}} (id: {{t.id}})</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let d of statsData.reader_list; let i = index;">
          <td><span *ngIf="d?.id">{{d.id}}</span></td>
          <td>
            <span *ngIf="d?.title">{{d.title}}
              <button
                *ngIf="statsData.type === 0 || statsData.type === 3 || statsData.type === 4"
                (click)="showFormAs('edit', d.id, d.title, i)" title="Edit Device Name" class="btn-scanned-d"><i class="fa fa-edit"></i></button>
            </span>
            <span *ngIf="!d?.title">
              <button
                *ngIf="statsData.type === 0 || statsData.type === 3 || statsData.type === 4"
                (click)="showFormAs('add', d.id, d.title, i)" title="Add Device Name" class="btn-scanned-d"><i class="fa fa-plus"></i></button>
            </span>
          </td>
          <td *ngFor="let ticket of d?.tickets; let j = index;">
            <p *ngIf="ticket?.scan_count >= 0">{{ticket?.scan_count}}</p>
          </td>
        </tr>
        </tbody>
      </table>

      <div *ngIf="showForm" class="scanner-form-wrap">
        <div class="scanner-form-wrap-inner">
          <button (click)="showForm = !showForm" class="close-scanner-form" title="Close">
            <img src="/assets/images/svg-icons/close.svg" alt="">
          </button>
          <form [formGroup]="form" class="scanner-form" (ngSubmit)="saveDevice()">
            <input formControlName="device_id" type="hidden">
            <mat-form-field appearance="fill" style="width: 100%;">
              <mat-label>{{'analytic-scanned.DeviceName' | translate:'Device Name'}}</mat-label>
              <input matInput formControlName="name" placeholder="Name">
              <mat-error *ngIf="form.controls.name.invalid && form.controls.name.touched">{{'analytic-scanned.InvalidName' | translate:'Invalid Name'}}</mat-error>
            </mat-form-field>
            <div>
              <button class="btn btn-save" [disabled]="form.invalid">{{'analytic-scanned.Save' | translate:'Save'}}</button>
            </div>
          </form>
        </div>
      </div>

    </div>

    <div *ngIf="statsData.checkins_by_ticket_type_tickets" class="checkins_by_ticket_type_tickets">
      <p style="color: #C4C4C4;font-weight: bold;">{{'analytic-scanned.CheckinsByTicketType' | translate:'Checkins by ticket type'}}</p>
      <table class="table checkins-table">
        <thead>
        <tr>
          <th>{{'analytic-scanned.TicketType' | translate:'Ticket Type'}}</th>
          <th>{{'analytic-scanned.CheckedIn' | translate:'Checked In'}}</th>
          <th>{{'analytic-scanned.CheckedOut' | translate:'Checked Out'}}</th>
          <th>{{'analytic-scanned.Noshow' | translate:'No show'}}</th>
          <th>{{'analytic-scanned.Total' | translate:'Total'}}</th>
          <th>{{'analytic-scanned.Arrived' | translate:'Arrived'}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of statsData.checkins_by_ticket_type_tickets | keyvalue;">
          <td><span>{{item?.value.ticket_name}}</span></td>
          <td><span>{{item?.value.checked_in}}</span></td>
          <td><span>{{item?.value.checked_out}}</span></td>
          <td><span>{{item?.value.no_show}}</span></td>
          <td><span>{{item?.value.total}}</span></td>
          <td><span>{{item?.value.arrived}}%</span></td>
        </tr>
        <tr *ngIf="statsData.checkins_by_ticket_type_totals">
          <td>{{'analytic-scanned.Totals' | translate:'Totals'}}</td>
          <td>{{statsData.checkins_by_ticket_type_totals.checked_in}}</td>
          <td>{{statsData.checkins_by_ticket_type_totals.checked_out}}</td>
          <td>{{statsData.checkins_by_ticket_type_totals.no_show}}</td>
          <td>{{statsData.checkins_by_ticket_type_totals.total}}</td>
          <td>{{statsData.checkins_by_ticket_type_totals.arrived}}%</td>
        </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
  <ng-template #readerList>
    <div class="scanners-title" *ngIf="statsData?.reader_list.length > 0">
      <div i18n="table header | Scanners" class="scanner-title">Scanners</div>
      <div i18n="table header | Total Scanned" class="scanner-title">Total Scanned</div>
    </div>
    <div class="scanners-container" *ngIf="statsData?.reader_list.length > 0">
      <div class="scanner-wrapper" *ngFor="let reader of statsData?.reader_list">
        <div class="scanner-main">
          <div class="scanner-avatar">
            <img class="sc-avatar" src="assets/images/svg-icons/logo-new.svg">
          </div>
          <div i18n="table header | title" class="scanner-initials">{{reader.title}}</div>
        </div>
        <div class="scanner-total">
          <span i18n="table header | Total Scanned">Total Scanned:&nbsp;</span>
          <span>{{reader.total_reader_count}}</span>
        </div>
      </div>
    </div>
  </ng-template>
  <div i18n="analytic | add-first-scanner" class="add-scanner" [routerLink]="['/reader-signup', event_id]"
       *ngIf="!statsData?.reader_list.length && statsData.event_editable && (statsData.hasOwnProperty('guest_manager_table') && !statsData.guest_manager_table)">
    Add your first scanner +
  </div>
</div>
