import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from './auth.service';
import {environment} from '../../environments/environment';
// import {BehaviorSubject, interval, Subject, Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NftService {
  api_nft_base_url = environment.api_nft_base_url;
  // wallet = new BehaviorSubject<string>('');
  // chains = 'https://chainid.network/chains_mini.json'; // https://github.com/ethereum-lists/chains
  // private refreshTimer = new Subject<any>();
  // private timerSubscription: Subscription[] = [];

  constructor(private http: HttpClient,
              private authService: AuthService) {
  }
  //
  // setWallet(wallet) {
  //   this.wallet.next(wallet);
  // }
  //
  // getWallet() {
  //   return this.wallet.getValue();
  // }

  // startTimer() {
  //   this.stopTimer();
  //   // console.log('Starting the timer');
  //   const timerStatus = interval(15000).subscribe(event => {
  //     this.refreshTimer.next(event);
  //     event >= 60 ? this.stopTimer() : null; // refresh until 15 minutes is up
  //   });
  //   this.timerSubscription.push(timerStatus);
  // }
  //
  // stopTimer(): void {
  //   this.timerSubscription.forEach(subscription => subscription.unsubscribe());
  // }

 sendAirdrop(purchaseIds = [], wallet, NftToken) {
    const token = this.authService.getToken();
    console.log('NftToken', NftToken)
    return this.http.post(this.api_nft_base_url + `/nft/airdrop`, {
      purchaseIds: purchaseIds,
      wallet,
      token: NftToken
    } , {
      headers: new HttpHeaders({ 'authorization': `Bearer ${token}` })
    });
  }

}
