<section class="cms_edit_user">
	<h1>Edit user</h1>
	<form (ngSubmit)="onEditUser(f)" #f="ngForm">
		<div class="form-group">
			<label for="name">Name</label>
			<input type="text" id="name" name="name" ngModel class="form-control" placeholder="Name" required>
		</div>
		<div class="form-group">
			<label for="phone">Phone</label>
			<input type="number" id="phone" name="phone" ngModel class="form-control" placeholder="Phone" required>
		</div>
		<div class="form-group">
			<label for="email">Email</label>
			<input type="email" id="email" name="email" ngModel class="form-control" placeholder="Email" required>
		</div>
		<button i18n="@@CmsUpdate" class="btn btn-primary" type="submit">Update</button>
	</form>

</section>
