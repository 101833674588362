import { Injectable, LOCALE_ID, Inject  } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  select = {
    he: 'בחר',
    en: 'select',
    es: 'seleccione',
    fr: 'sélectionner'
  };
  noResult = {
    he: 'לא נמצאו תוצאות',
    en: 'no results found',
    es: 'No se han encontrado resultados',
    fr: 'Aucun résultat trouvé',
  };
  search = {
    he: 'חפש',
    en: 'search',
    es: 'búsqueda',
    fr: 'recherche',
  }
  
  constructor(@Inject(LOCALE_ID) private local_id) { }

  getStringTranslation (key, toCapitalize = false) {
    const string = this[key][this.local_id];
    return toCapitalize ? string.charAt(0).toUpperCase() + string.slice(1) : string;
  }
}
