import {
  Component,
  ElementRef, EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {PasskeyService} from "../../services/passkey.service";
import {NavbarService} from "../../services/navbar.service";
import {AuthService} from "../../services/auth.service";
import {AuthCacheService} from "../../services-cache/auth-cache.service";
import {Router} from "@angular/router";
import {GlobalsService} from "../../globals";
import {DialogService} from "../../services/dialog.service";
import {MatDialogRef} from "@angular/material/dialog";
import {LoginComponent} from "../login.component";
@Component({
  selector: 'app-passkeys',
  templateUrl: './passkeys.component.html',
  styleUrls: ['./passkeys.component.scss']
})
export class PasskeysComponent implements OnInit{
  // @ViewChild('error') public error: ElementRef;
  @Input() showAddPassKeyBtn: boolean = false;
  @Input() showCheckPassKeyBtn: boolean = false;
  @Input() isEventPage: boolean = false;
  @Output() passkeyLoginSuccess: EventEmitter<any> = new EventEmitter();
  user_id = 0;
  user_info: {};
  pass_key_identifier = '';
  pass_key_data_accounts = [];
  saved_pass_key_identifier_from_Storage = '';

  constructor(public passkeyService: PasskeyService,
              public nav: NavbarService,
              private authService: AuthService,
              private authCacheService: AuthCacheService,
              private router: Router,
              public globals : GlobalsService,
              private dialogService: DialogService,
              private dialogRef: MatDialogRef<LoginComponent>) {
  }

  ngOnInit() {
    this.nav.currentUserId.subscribe(user_id => {
      this.user_id = user_id;
      if (user_id) {
        this.user_info = this.authService.getUserInfo();
      }
    });
    this.nav.pass_key_data.subscribe((pass_key_data: any) => {
        this.pass_key_identifier = pass_key_data.identifier;
        this.pass_key_data_accounts = pass_key_data.accounts;
    });
    this.saved_pass_key_identifier_from_Storage = localStorage ? JSON.parse(localStorage.getItem('pass_key_identifier')) : '';

  }

  // *** PASSKEY REGISTRATION ***

  async createRegistration() {
    if(!this.checkBrowser()) {return}

    this.globals.startLoader();
    this.passkeyService.connectGET({...this.user_info, pass_key_identifier: this.pass_key_identifier}).subscribe(async(res: any) => {
      console.log('createRegistration',res);
      const createArgs = res.data.createArgs;
      console.log('createArgs',createArgs);
      // error handling
      if (res.success === false) {
        // this.error.nativeElement.innerHTML = createArgs.msg || 'unknown error occured';
      }
      this.globals.stopLoader();
      // replace binary base64 data with ArrayBuffer. a other way to do this
      // is the reviver function of JSON.parse()
      this.passkeyService.recursiveBase64StrToArrayBuffer(createArgs);
      // console.log('credentials', navigator.credentials.create());
      // create credentials
      const cred = await navigator.credentials.create(createArgs) as PublicKeyCredential;
      console.log('cred',cred);
      const credResponse  = cred.response as AuthenticatorAttestationResponse;

      // create object
      const authenticatorAttestationResponse = {
        transports: credResponse.getTransports  ? credResponse.getTransports() : null,
        clientDataJSON: credResponse.clientDataJSON  ? this.passkeyService.arrayBufferToBase64(credResponse.clientDataJSON) : null,
        attestationObject: credResponse.attestationObject ? this.passkeyService.arrayBufferToBase64(credResponse.attestationObject) : null,
        auth_token: res.data.auth_token
      };

      console.log('authenticatorAttestationResponse', authenticatorAttestationResponse)

      this.globals.startLoader();
      // check auth on server side
      this.passkeyService.connectPOST(authenticatorAttestationResponse, {...this.user_info, pass_key_identifier: this.pass_key_identifier})
        .subscribe(async (res: any) => {
          this.globals.stopLoader();
          console.log('authenticatorAttestationServerResponse', res);
          const authenticatorAttestationServerResponse = res;
          // prompt server response
          if (authenticatorAttestationServerResponse &&
            authenticatorAttestationServerResponse.code == 'success') {
            // reloadServerPreview();
            // localStorage.setItem('pass_key_identifier', JSON.stringify(this.pass_key_identifier));
            this.nav.checkUser(true);
            this.dialogService.alertsModal('passkeys', 1, 4000, true);
          } else {
            throw new Error(authenticatorAttestationServerResponse.msg);
          }
      }, err => {
        this.dialogService.alertsModal('passkeys', err.code, 3000, false);
        this.globals.stopLoader();
      })


    }, err => {
      this.dialogService.alertsModal('passkeys', err.code, 3000, false);
      this.globals.stopLoader();
    })
  }



  // *** PASSKEY LOGIN ***
  async checkRegistration() {
    if(!this.checkBrowser()) {return}


    this.passkeyService.checkGET({pass_key_identifier: this.saved_pass_key_identifier_from_Storage}).subscribe(async(res: any) => {
      // console.log('checkRegistration',res);
      if (res.data && res.data.hasOwnProperty('getGetArgs')) {
        const getArgs = res.data.getGetArgs;
        const token = res.data.token;
        console.log('getArgs',getArgs);
        // error handling
        // if (res.success === false) {
        //   this.error.nativeElement.innerHTML = getArgs.msg || 'unknown error occured';
        // }
        // replace binary base64 data with ArrayBuffer. a other way to do this
        // is the reviver function of JSON.parse()
        this.passkeyService.recursiveBase64StrToArrayBuffer(getArgs);
        // create credentials
        const cred = await navigator.credentials.get(getArgs) as PublicKeyCredential;
        console.log('cred',cred);
        let resp = cred.response as AuthenticatorAssertionResponse;

        // create object for transmission to server
        const authenticatorAttestationResponse = {
          id: cred.rawId ? this.passkeyService.arrayBufferToBase64(cred.rawId) : null,
          clientDataJSON: cred.response.clientDataJSON  ? this.passkeyService.arrayBufferToBase64(cred.response.clientDataJSON) : null,
          authenticatorData: resp.authenticatorData ? this.passkeyService.arrayBufferToBase64(resp.authenticatorData) : null,
          signature: resp.signature ? this.passkeyService.arrayBufferToBase64(resp.signature) : null,
          userHandle: resp.userHandle ? this.passkeyService.arrayBufferToBase64(resp.userHandle) : null,
          auth_token: token
        };

        console.log('authenticatorAttestationResponse', authenticatorAttestationResponse)

        // check auth on server side
        this.passkeyService.checkPOST(authenticatorAttestationResponse, {pass_key_identifier: this.saved_pass_key_identifier_from_Storage})
          .subscribe(async (res: any) => {
            this.globals.stopLoader();
            console.log('authenticatorAttestationServerResponse', res);
            const authenticatorAttestationServerResponse = res;
            // prompt server response
            if (authenticatorAttestationServerResponse.original) {
              // reloadServerPreview();
              await this.loginWithPasskeySuccess(authenticatorAttestationServerResponse);

            } else {
              throw new Error(authenticatorAttestationServerResponse.msg);
            }
          }, err => {
            this.dialogService.alertsModal('passkeys', err.code, 3000, false);
            this.globals.stopLoader();
          })
      } else {
        this.dialogService.alertsModal('passkeys', 7, 8000, false);
      }



    }, err => {
      this.dialogService.alertsModal('passkeys', err.code, 3000, false);
      this.globals.stopLoader();
    })
  }

  // *** DELETE PASSKEYS FROM ACCOUNT ***
  async deleteAllRegistrations() {
    this.globals.startLoader();
    this.passkeyService.deleteAllRegistrations(this.user_info).subscribe(async(res: any) => {
      if(res && res.code == 'success') {
        // localStorage.removeItem('pass_key_identifier');
        this.nav.checkUser(true);
      }
      this.globals.stopLoader();
    }, err => {
      this.dialogService.alertsModal('passkeys', err.code, 3000, false);
      this.globals.stopLoader();
    })
  }
  // *** CHECK IF BROWSER SUPPORT navigator.credentials.create ***
  checkBrowser() {
    if (!navigator && !navigator.credentials || !navigator.credentials.create) {
      this.dialogService.alertsModal('passkeys', 4.2, 3000, false);
      return false
    } else {
      return true
    }
  }

  // *** LOGIN USER IN OUR SYSTEM ***
  async loginWithPasskeySuccess(response) {
    if(response && response.original && response.original?.success == 'letsGoHome') {
      if (response.auth.session && response.auth.refresh) {
        this.authService.storeTokens(response.auth.session, response.auth.refresh)
      }
      this.authService.saveUserCookies(response.user_data, response.original.facebook_id);
      this.authCacheService.clearCache();
      this.nav.checkUser();
      if (response.original.uid) {
        this.nav.UpdateUser(parseInt(response.original.uid), response.user_data.type, response.original.facebook_id, false)
      }
      this.globals.stopLoader();
      if (this.isEventPage) {
        this.passkeyLoginSuccess.emit(response);
      } else {
        this.router.navigate(['/']).then(()=> {
          if (this.dialogRef && Object.keys(this.dialogRef).length > 0) {
            this.dialogRef.close();
          }
          this.dialogService.alertsModal('success', 'readerLogin', 3000, true);
        });
      }
    } else {
      this.globals.stopLoader();
      this.dialogService.alertsModal('errors', 53, 2000, false);
    }
  }

}
