import {Component, OnInit, Input, Renderer2, ChangeDetectorRef} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {NavbarService} from '../services/navbar.service';
import {DialogService} from '../services/dialog.service';
import {FreshChatService} from '../services/freshchat.service';
import {environment} from '../../environments/environment';
import {UserService} from '../services/user.service';
import {IntercomService} from '../services/intercom.service';
import {PaytweedService} from '../services/paytweed.service';
import * as Sentry from "@sentry/angular-ivy";
declare var window: any;

@Component({
  selector: 'app-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.css'],
  host: {
    '(document:mousedown)': 'onClickCloseMenu($event)'
  }
})
export class SidenavMenuComponent implements OnInit {
  @Input() isMobile: boolean;
  @Input() direction: string;
  user;
  api_base_url = environment.api_base_url;
  apiDocumentation: string = ''; // from api/user-details
  redis_dashboard_access = false; // from api/user-details
  payment_stripe_connect_accounts: boolean = false;
  // TODO BELOW
  firebaseInfo = null;
  firebasePublicKey = environment.firebase.messagingSenderId;
  emptyNotifs = {
    'read': [],
    'unread': [],
  };
  notifications = this.emptyNotifs;
  isNewNotifs: boolean;
  unreadArr = [];
  userImg = '';
  // TODO BELOW END
  telescope_view_url: string = ''; // from api/user-details
  constructor(private authService: AuthService,
              private _cookieService: CookieService,
              private router: Router,
              public nav: NavbarService,
              private dialogService: DialogService,
              private freshChatService: FreshChatService,
              private intercomService: IntercomService,
              private userService: UserService,
              private paytweedService: PaytweedService) { }

  ngOnInit(): void {
    this.user = this.authService.getUserInfo();
    this.userImg = this.user.social_img ? this.user.social_img : '';
    this.nav.getIsDrawerOpened.subscribe(o => {
      if (o) {this.user = this.authService.getUserInfo()}
    })
    this.nav.currentUserId.subscribe(user_id => this.user['user_id'] = user_id);
    this.nav.apiDocumentation.subscribe((apiDoc) => this.apiDocumentation = apiDoc);// Show or Hide
    this.nav.paymentStripeConnectAccounts.subscribe(
      (payment_stripe_connect_accounts) => this.payment_stripe_connect_accounts = payment_stripe_connect_accounts);
    this.nav.currentUserProfileImage.subscribe((imgObj: any) => {
      // {imageLink: defaultImage, isNoPhoto}
      if (imgObj) {
        this.userImg = imgObj.imageLink;
      }
    })
    this.nav.telescope_view_url.subscribe((telescope_view_url) => this.telescope_view_url = telescope_view_url);// Show or Hide
    this.nav.redis_dashboard_access.subscribe((redis_dashboard_access) => this.redis_dashboard_access = redis_dashboard_access);// Show or Hide
  }
  openLoginModal() {
    this.userService.openLoginModal();
  }
  // Close opened menu if clicked outside menu button or menu itself
  onClickCloseMenu(e) {
    if(!e.target.closest('.menu-toggle-btn') &&
      !e.target.closest('.mat-drawer') &&
      !e.target.closest('.cdk-overlay-container')) {
      this.nav.closeSidenav();
    }
  }
  close() {
    this.nav.toggleSidenav();
  }
  showFreshChat() {
    this.freshChatService.startFC();
  }
  bootIntercom() {
    this.intercomService.boot();
    this.intercomService.show();
  }
  showSpaceIntercom(space) {
    this.intercomService.boot();
    this.intercomService.showSpace(space);
  }

  intercomMessage() {
    this.intercomService.message('')
  }
  getActiveEvents() {
    return this.authService.getActiveEvents();
  }

  logout() {
    this.freshChatService.clearFCUser();
    if (!this.firebaseInfo && (window['Android'] || !/CriOS/i.test(navigator.userAgent)) && this._cookieService.get('FCMT')) {this.firebaseInfo = {
      token: this._cookieService.get('FCMT'),
      public_key: this.firebasePublicKey
    }
    }
    this.authService.logout(this.firebaseInfo).subscribe(
      response =>{
        if(response.success){
          this.nav.UpdateUser('0', '0', '0');
          this.nav.UpdateFbID(0);
          this.nav.updateUserProfileImage('');
          this.intercomService.shutdown();
          this.intercomService.boot(true);
          this.router.navigate(['/']);
          this.dialogService.alertsModal('success','byeBye',2000,true);
          this.clearNotifications();
          if (window.Echo) {
            window.Echo.disconnect();
          }
          this.paytweedService.clearTweedWalletsFromDB();
        } else {
          this.dialogService.alertsModal('errors','4.4',3000,false);
        }
      },
      error => {
        this.dialogService.alertsModal('errors','4.4',3000,false);
      },
    );

    Sentry.setUser(null);
  }


  clearNotifications() {
    this.notifications = this.emptyNotifs;
    this.isNewNotifs = false;
    this.refreshUnreadArr();
  }
  refreshUnreadArr() {
    this.unreadArr = [];
    this.notifications.unread.forEach(item => this.unreadArr.push(item.id));
  }

  redirrectToSwagger(e) {
    e.preventDefault();
    // const token = this.authService.getRefreshToken();
    const url = this.api_base_url + '/api/documentation?token=' + this.apiDocumentation;
    if (this.apiDocumentation) {window.open(url, "_blank")};
  }

}
