import { Component, OnInit, Input } from '@angular/core';
// --------------------Material------------------------
import { MatDialog } from '@angular/material/dialog';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { ThemePalette} from '@angular/material/core';
// --------------------Services------------------------
import { GlobalsService } from '../../globals';
// import { AuthService } from '../../services/auth.service';
import { NavbarService } from '../../services/navbar.service';
import { UserService } from '../../services/user.service';
import { DialogService } from '../../services/dialog.service';
// ----------------------------------------------------
import { CropImageModalComponent } from '../../shared-dialogs/crop-image-modal/crop-image-modal.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: "app-user-avatar",
  templateUrl: "./user-avatar.component.html",
  styleUrls: ["./user-avatar.component.css"],
})
export class UserAvatarComponent implements OnInit {
  @Input() verificationPercent;
  diameter: any;
  isShow = false;
  api_base_url = environment.api_base_url;
  images_base_url = environment.images_base_url;
  userProfileImage;
  isSocialImg: boolean;
  isNoPhoto: boolean;
  user_id;
  // -----------Spinner----------------
  dir: string;
  color: ThemePalette = "primary";
  mode: ProgressSpinnerMode = "determinate";

  constructor(
    private globals: GlobalsService,
    // private authService: AuthService,
    private dialogService: DialogService,
    private userService: UserService,
    public nav: NavbarService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.dir = this.globals.getDir();
    this.nav.currentUserProfileImage.subscribe((img: any) => {
      (this.userProfileImage = img.imageLink),
        (this.isSocialImg = img.isSocialImg),
        (this.isNoPhoto = img.isNoPhoto);
    });
    this.nav.currentUserId.subscribe((id) => (this.user_id = id));
    this.setSpinnerDiameter();
  }

  setSpinnerDiameter() {
    const viewWidth = document.documentElement.clientWidth;
    // console.log(viewWidth);
    if (viewWidth <= 767) {
      this.diameter = 100;
    } else {
      this.diameter = 262;
    }
  }

  saveProfile($event, result) {
    const formData = new FormData();
    formData.append("userPic", result);
    this.globals.startLoader();
    this.userService.saveProfilePicture(formData, this.user_id).subscribe(
      (response: any) => {
        this.globals.stopLoader();
        this.nav.updateUserProfileImage(response.data.imgLink);
        formData.delete("userPic");
        $event.target.value = "";
      },
      (error) => {
        this.globals.stopLoader();
        this.dialogService.alertsModal(
          "fileUploads",
          Number(error.code),
          5000,
          false
        );
        formData.delete("userPic");
        $event.target.value = "";
      }
    );
  }

  cropImage($event) {
    const cropeImageModal = this.dialog.open(CropImageModalComponent, {
      data: { element: $event, configRatio: 1, ratio: "1:1", fileMaxSize: 5 },
      disableClose: true,
      autoFocus: false,
      panelClass: "updateImageModal",
    });
    cropeImageModal.afterClosed().subscribe((result) => {
      if (result !== undefined && result.success) {
        // stat save profile
        this.saveProfile($event, result.data);
      } else {
        $event.target.value = "";
      }
    });
  }

  deleteFile() {
    this.dialogService
      .areUSureModal("deleteProfileImage")
      .subscribe((response) => {
        if (response) {
          this.globals.startLoader();
          this.userService
            .deleteProfilePicture({ userId: this.user_id })
            .subscribe(
              (response: any) => {
                this.globals.stopLoader();
                this.nav.updateUserProfileImage("");
              },
              (error) => {
                this.globals.stopLoader();
                this.dialogService.alertsModal(
                  "error",
                  error.code,
                  5000,
                  false
                );
              }
            );
        }
      });
  }
}
