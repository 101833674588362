import { AppResponse } from './../../../interfaces/app-response.interface';
import { slideIn } from './../../../animation';
import { DialogService } from '../../../services/dialog.service';

import { EventService } from '../../../services/event.service';
import {FormArray, FormBuilder, FormGroup, NgForm} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { MatSort } from "@angular/material/sort"
import { MatTableDataSource } from '@angular/material/table';
import { GlobalsService } from './../../../globals';
import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';
import { PaymentGatewayService } from '../../../services/payment-gateway.service';
import { EventCategories } from '../../../interfaces/event-categories.interface';
import { SelectionModel } from '@angular/cdk/collections';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import {
  CmsFeeManagementTemplateDialogComponent
} from "../../cms-fee-management-template/cms-fee-management-template-dialog.component";
import {
  CmsFeeManagmentTemplatesService
} from "../../../services/cms-fee-managment-templates.service";
import {CmsCurrenciesService} from "../../../services/cms-currencies.service";
import {CmsCurrency} from "../../../interfaces/currency.interface";
import moment from 'moment';

@Component({
  selector: "app-approve-event-modal",
  templateUrl: "./approve-event-modal.component.html",
  styleUrls: ["./approve-event-modal.component.css"],
  animations: [slideIn],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApproveEventModalComponent implements OnInit {
  @ViewChild("approveEventForm") approveEventForm: NgForm;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ["select", "id", "name", "status"];
  cf_displayedColumns: string[] = ["select", "id", "title", "opinions"];
  categoryList = new MatTableDataSource<EventCategories>([]);
  customFieldsList = new MatTableDataSource([]);
  selection = new SelectionModel<EventCategories>(true, []);
  cf_selection = new SelectionModel<EventCategories>(true, []);

  show_purchase_fee_block = true;
  event = {
    id: 0,
    producer_commission: 0,
    purchaser_commission: 0,
    refund_commission: 0,
    service_commission: 0,
    tax_commission: 0,
    transfer_ticket_mode: false,
    platform_fee: 0,
    refund_type: "ticket_price",
    purchase_passcode_mode: 0,
    purchase_passcodes: "",
    isNft: false,
    is_svix_webhook_mode: false,
    isDonation: false,
    stripe_connect_account_mode: 0,
    stripe_connect_account_id: "",
    seats_mode: 0,
    event_seat_key: "",
    seat_terms: "",
    boost_fee: 0,
    payout_account_type: '0',
    payout_service_agreement: 0,
    boost_status: '0',
    address: '',
    currency: '',
    donation_tickets: [],
    scanner_provider_type: 0,
    donation_fee_mode: false,
    show_price_with_fees: false,
    stripe_connect_account_data: {
      enable_indirect_payment: false,
      stripe_connect_account_id: '',
      producer_stripe_connect_accounts: [],
      stripe_connect_account_payment_gateway_id: ''
    },
    user_stripe_connect_payout_account: null,
    user_stripe_connect_payout_request: null,
    auto_approve_in_the_future: 0,
    auto_approve_template_name: '',
    fee_template_configuration: null,
    reveal_barcode_hours: 0,
    plaid_connect_request: false,
    transfer_start_after_hours: 0,
    transfer_start_after_date_time: '',
    transfer_charge_transaction_flow: false
  };
  apiAction;

  gatewayTypes: Promise<String[]> | any;
  gateways: Promise<String[]> | any;
  gateways_payout: Promise<String[]> | any;
  selectedGatewayType: string | null;
  selectedGatewayType_payout: string | null;
  selectedGatewayId: number;
  selectedGateway = "";
  selectedGateway_payout = "";
  selectedTypes: any[] = [];
  selectedGateways: any = {};
  selectedGateways_payout: any = {};
  paymentGateways: any[] = [];
  paymentGateways_payout: any[] = [];
  gatewayIds: any[] = [];
  scanner_providers: any[] = [];
  count_tickets: number = 0;
  premium_ticket_threshold: number = 0;
  gatewayTypeChangedByUser: boolean = false;
  stripe_connect_account_types: any = {};
  stripe_connect_account_service_agreements: any = {};

  gateway_id: string;
  hasChange: boolean;
  language;
  refundType: string = "ticket_price";
  crypto_payment: string = "No";
  // it is temporary checking for test crypto payments on event
  isProd: boolean = environment.getin_base_url === "https://get-in.com";
  isAdmin: boolean = false;

  donation_tickets: any[] = [];
  donation_tickets_init: any[] = [];
  donation_tickets_changed: boolean = false;
  countApprovedDonation_tickets: number = 0;
  ifUserRequested_nftStatus: boolean = false;
  ifUserRequested_donateStatus: boolean = false;
  show_purchase_passcode: boolean = false;
  selectedStripeConnectAccount = '';
  passcodesForm: FormGroup;

  selectedFeeTemplateId: string | null;
  selectedFeeTemplate: any = null;
  feeTemplates: Promise<String[]> | any;
  event_currency: CmsCurrency;
  allowedGatewaysByCurrency: any[]=[];
  changeAll_donation_ticket_approved_checked: boolean = false;


  reveal_barcode_hours_min: any = moment( new Date()).format('YYYY-MM-DDTHH:mm');
  reveal_barcode_hours_max:  any = '';
  reveal_barcode_hours_value: any = '';
  constructor(
    private globals: GlobalsService,
    public authService: AuthService,
    private eventService: EventService,
    private dialogService: DialogService,
    private gatewayService: PaymentGatewayService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data,
    private feeService: CmsFeeManagmentTemplatesService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private cmsCurrenciesService: CmsCurrenciesService,
  ) {
    this.language = this.globals.getLocaleId();
    this.passcodesForm = this.fb.group({
      passcodes: this.fb.array([]) ,
    });
  }

  ngOnInit() {
    this.isAdmin = this.authService.getType() === 2;
    this.categoryList = new MatTableDataSource<EventCategories>(
      this.data.categories
    );
    this.categoryList.sort = this.sort;
    this.customFieldsList = new MatTableDataSource<EventCategories>(
      this.data.custom_inputs
    );
    this.customFieldsList.sort = this.sort;
    // this.selectedGatewayType = this.data.payment_type ? this.data.payment_type: "0";
    this.selectedGatewayType_payout = 'stripe_gateway';
    this.event.id = this.data.id;
    this.event.producer_commission = this.data.commission;
    this.event.purchaser_commission = this.data.purchaser_commission;
    this.event.isNft = this.data.isNft ? true : false;
    this.event.is_svix_webhook_mode = this.data.is_svix_webhook_mode ? true : false;
    this.event.transfer_ticket_mode = this.data.transfer_ticket_mode ? true : false;
    this.event.isDonation = this.data.isDonation ? true : false;
    this.event.donation_fee_mode = this.data.donation_fee_mode ? true : false;
    this.event.show_price_with_fees = this.data.show_price_with_fees ? true : false;
    this.event.stripe_connect_account_mode =
      this.data.stripe_connect_account_mode;
    this.stripe_connect_account_types = this.data.stripe_connect_account_types;
    this.stripe_connect_account_service_agreements = this.data.stripe_connect_account_service_agreements;
    this.event.stripe_connect_account_id = this.data.stripe_connect_account_id;
    this.event.purchase_passcode_mode = this.data.purchase_passcode_mode;
    this.event.purchase_passcodes = this.data.purchase_passcodes;
    this.event.stripe_connect_account_data.stripe_connect_account_id = this.data.stripe_connect_account_data ? this.data.stripe_connect_account_data.stripe_connect_account_id : '';
    this.event.stripe_connect_account_data.producer_stripe_connect_accounts = this.data.stripe_connect_account_data ? this.data.stripe_connect_account_data.producer_stripe_connect_accounts : [];
    this.event.stripe_connect_account_data.enable_indirect_payment = this.data.stripe_connect_account_data ? this.data.stripe_connect_account_data.enable_indirect_payment : false;
    this.event.seats_mode = this.data.seats_mode;
    this.event.event_seat_key = this.data.event_seat_key;
    this.event.seat_terms = this.data.seat_terms;
    this.event.scanner_provider_type = this.data.scanner_provider_type;
    this.scanner_providers = this.data.scanner_providers;
    this.count_tickets = this.data.count_tickets;
    this.premium_ticket_threshold = this.data.premium_ticket_threshold;
    this.event.address = this.data.address;
    this.event.currency = this.data.currency;
    this.event_currency = this.data.event_currency;
    this.ifUserRequested_nftStatus = this.data.inputIsNft ? true : false;
    this.ifUserRequested_donateStatus = this.data.isDonation ? true : false;
    this.event.service_commission = this.data.service_commission;
    this.event.platform_fee = this.data.platform_fee;
    this.event.tax_commission = this.data.tax_commission;
    this.event.boost_fee = this.data.boost_fee;
    this.event.boost_status = this.data.boost_status;
    this.gatewayIds = this.data.paymentGateways;
    this.event.refund_type = this.data.refund_type
      ? this.data.refund_type
      : this.refundType;
    this.event.refund_commission = this.data.refund_commission;
    this.crypto_payment = this.data.accept_crypto_payment ? "Yes" : "No";
    this.apiAction =
      this.data.action === "approve" ? "approveEvent" : "updateCommission";
    this.donation_tickets = this.data.donation_tickets || [];
    this.donation_tickets_init = this.donation_tickets || []; // for compare
    this.event.user_stripe_connect_payout_account = this.data.user_stripe_connect_payout_account;
    this.event.user_stripe_connect_payout_request = this.data.user_stripe_connect_payout_request;
    this.event.auto_approve_in_the_future = this.data.auto_approve_in_the_future;
    this.event.auto_approve_template_name = this.data.auto_approve_template_name ? this.data.auto_approve_template_name : '';
    this.event.fee_template_configuration = this.data.fee_template_configuration;
    this.event.payout_account_type = this.data.user_stripe_connect_payout_request ? this.data.user_stripe_connect_payout_request.type : '0';
    this.event.payout_service_agreement = this.data.user_stripe_connect_payout_request ? this.data.user_stripe_connect_payout_request.service_agreement : '0';
    this.event.reveal_barcode_hours = this.data.reveal_barcode_hours;
    if(this.data.stripe_connect_account_data) {
      this.selectedStripeConnectAccount = this.data.stripe_connect_account_data.stripe_connect_account_id ? this.data.stripe_connect_account_data.stripe_connect_account_id : '';
    }
    this.event.plaid_connect_request = this.data.plaid_connect_request ? true : false;
    this.event.transfer_start_after_hours = this.data.transfer_start_after_hours;
    this.event.transfer_start_after_date_time = this.data.transfer_start_after_date_time;
    this.event.transfer_charge_transaction_flow = this.data.transfer_charge_transaction_flow ? true : false;

    this.show_purchase_fee_block = this.event.purchaser_commission > 0;

    // iterate over passcodes array and fill inputs
    if(this.data.purchase_passcodes && this.data.purchase_passcodes.length) {
      this.data.purchase_passcodes.forEach(code => {
        let arr = this.passcodesForm.get('passcodes') as FormArray;
        arr.push(this.newPassCode(code));
      })
    }

    // iterate over Donation tickets array for Approved All checkbox
    this.iterateDonationTickets(this.data.donation_tickets);

    // Reveal Barcode
    const init_start_date = this.data.start_date.replace(' ', 'T')
    let start_date = new Date(init_start_date);
    this.reveal_barcode_hours_max = moment(start_date).format('YYYY-MM-DDTHH:mm');
    this.reveal_barcode_hours_value = moment(start_date).subtract(this.data.reveal_barcode_hours, 'h').format('YYYY-MM-DDTHH:mm');
    // Reveal Barcode END


    if (this.data.paymentGateways.length > 0) {
      let tranzillaAndStripeCount = 0;
      this.data.paymentGateways.forEach((gateways, i) => {
        this.selectedGateways[gateways.payment_type] = gateways;
        if (
          (gateways.payment_type === "tranzilla" ||
            gateways.payment_type === "stripe_gateway") &&
          this.data.status !== 3
        ) {
          this.selectedGatewayType = gateways.payment_type;
          this.selectedGatewayId = gateways.id;
          tranzillaAndStripeCount++;
        }
        if (i === 0 && this.data.status === 3) {
          this.selectedGatewayType = gateways.payment_type;
        }
      });

      if (tranzillaAndStripeCount === 0 && this.data.status !== 3) {
        this.selectedGatewayType = this.data.paymentGateways[0].payment_type;
        this.selectedGatewayId = this.data.paymentGateways[0].id;
      }
    } else {
      this.selectedGatewayType = "0";
      this.selectedGatewayId = 0;
    }
    this.gatewayTypes = this.getGatewayTypes(this.event.id).catch((error) => {
      if (error.code) {
        this.dialogService.alertsModal(
          "gateways",
          error.code,
          3000,
          false,
          error.log_id ? error.log_id : null
        );
      }
    });
    this.getGateways_payout(); // For Payout section
    this.feeTemplates = this.getFeeTemplates();
    this.getGatewayByCurrency();
  }
  changeDonateMinMax() {
    this.donation_tickets_changed = true;
  }
  changeAll_donation_ticket_approved(checked_state) {
    this.donation_tickets.forEach((t, i) => {
      // console.log(t,i);
      this.donation_tickets[i].donation_ticket_approved = checked_state;
    })
    if(!checked_state) {
      this.countApprovedDonation_tickets = 0;
    }
  }
  change_donation_ticket_approved(checked_state) {
    // console.log(checked_state);
    this.iterateDonationTickets(this.donation_tickets);
  }
  iterateDonationTickets(donation_tickets) {
    if(donation_tickets && donation_tickets.length) {
      this.countApprovedDonation_tickets = 0;
      donation_tickets.forEach(t => {
        if (t.donation_ticket_approved) {
          this.countApprovedDonation_tickets = this.countApprovedDonation_tickets + 1
        }
      })
      if(this.countApprovedDonation_tickets == donation_tickets.length) {
        this.changeAll_donation_ticket_approved_checked = true;
      } else {
        this.changeAll_donation_ticket_approved_checked = false;
      }
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected
      ? this.selection.selected.length
      : 0;
    const numRows = this.categoryList.data ? this.categoryList.data.length : 0;
    return numSelected === numRows;
  }

  cf_isAllSelected() {
    const numSelected = this.cf_selection.selected
      ? this.cf_selection.selected.length
      : 0;
    const numRows = this.customFieldsList.data
      ? this.customFieldsList.data.length
      : 0;
    return numSelected === numRows;
  }

  checkboxLabel(row?: EventCategories): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.id + 1
    }`;
  }

  cf_checkboxLabel(row?: EventCategories): string {
    if (!row) {
      return `${this.cf_isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.cf_selection.isSelected(row) ? "deselect" : "select"} row ${
      row.id + 1
    }`;
  }

  masterToggle(event) {
    this.isAllSelected()
      ? this.selection.clear()
      : this.categoryList.data.forEach((row: any) =>
          this.selection.select(row)
        );
  }

  cf_masterToggle(event) {
    this.cf_isAllSelected()
      ? this.cf_selection.clear()
      : this.customFieldsList.data.forEach((row: any) =>
          this.cf_selection.select(row)
        );
  }

  isCheckPurchaseFee(event) {
    this.show_purchase_fee_block = true;
    if (!event.target.checked) {
      this.show_purchase_fee_block = false;
    }
  }

  setRefundType(event) {
    console.log(this.approveEventForm.value);
    this.event.refund_type =
      this.refundType =
      this.approveEventForm.value.refund_type =
        event.target.value;
    // console.log('setRefundPrice', event.target.value, this.refundType, event.target.checked);
  }

  onSubmit() {
    // console.log('this.approveEventForm.value', this.approveEventForm.value, this.passcodesForm.value)
    // return
    if(this.event.purchase_passcode_mode && this.passcodesForm.invalid) { // check passcode form before save
      return;
    }
    // console.log(this.donation_tickets, this.donation_tickets_init, this.donation_tickets_changed);
    if (!this.approveEventForm.value.refund_type) {
      this.approveEventForm.value.refund_type = this.event.refund_type;
    }
    const categories = [],
      blocked_custom_inputs = [];
    this.selection.selected.forEach((category) => {
      categories.push(category.id);
    });
    this.cf_selection.selected.forEach((field) => {
      blocked_custom_inputs.push(field.id);
    });
    const gateway_ids = [];
    const compareGateways = [];
    Object.keys(this.selectedGateways).forEach((key: any) => {
      gateway_ids.push(this.selectedGateways[key].id);
      compareGateways.push(this.selectedGateways[key]);
    });
    // console.log('gateway_ids', gateway_ids);
    let data = {
      event_id: this.event.id,
      categories,
      blocked_custom_inputs,
      gateway_ids: gateway_ids,
      purchaser_commission: this.approveEventForm.value.purchaser_commission
        ? +this.approveEventForm.value.purchaser_commission
        : 0,
      commission: this.approveEventForm.value.commission
        ? this.approveEventForm.value.commission
        : 0,
      refund_commission: this.approveEventForm.value.refund_commission
        ? +this.approveEventForm.value.refund_commission
        : 0,
      service_commission: this.approveEventForm.value.service_commission
        ? +this.approveEventForm.value.service_commission
        : 0,
      platform_fee: this.approveEventForm.value.platform_fee
        ? +this.approveEventForm.value.platform_fee
        : 0,
      tax_commission: this.approveEventForm.value.tax_commission
        ? +this.approveEventForm.value.tax_commission
        : 0,
      payment_type: this.approveEventForm.value.gatewayType
        ? this.approveEventForm.value.gatewayType
        : "",
      refund_type: this.approveEventForm.value.refund_type
        ? this.approveEventForm.value.refund_type
        : "ticket_price",
      gateway_id: this.approveEventForm.value.gateway
        ? +this.approveEventForm.value.gateway
        : 0,
      action: "approving",
      isNft: this.event.isNft,
      is_svix_webhook_mode: this.event.is_svix_webhook_mode,
      transfer_ticket_mode: this.event.transfer_ticket_mode,
      isDonation: this.event.isDonation,
      stripe_connect_account_mode: this.event.stripe_connect_account_mode,
      purchase_passcode_mode: this.event.purchase_passcode_mode,
      scanner_provider_type: +this.event.scanner_provider_type,

      payout_payment_gateway_id: this.selectedGateway_payout, // If Stripe Gateway ID, else 0
      payout_account_type: +this.event.payout_account_type,
      // payout_service_agreement: +this.event.payout_service_agreement,
      purchase_passcodes: this.event.purchase_passcode_mode ? this.passcodesForm.get('passcodes').value: [],

      seats_mode: this.event.seats_mode,
      event_seat_key: this.event.event_seat_key,
      seat_terms: this.event.seat_terms,
      stripe_connect_account_id: this.event.stripe_connect_account_data.stripe_connect_account_id,
      enable_indirect_payment: this.event.stripe_connect_account_data.enable_indirect_payment,
      boost_fee: this.approveEventForm.value.boost_fee
        ? +this.approveEventForm.value.boost_fee
        : 0,
      boost_status: +this.event.boost_status,
      donation_tickets: this.donation_tickets,
      donation_fee_mode: this.event.donation_fee_mode,
      show_price_with_fees: this.event.show_price_with_fees,
      auto_approve_in_the_future: this.event.auto_approve_in_the_future,
      auto_approve_template_name: this.event.auto_approve_template_name,
      reveal_barcode_hours: this.event.reveal_barcode_hours,
      plaid_connect_request: this.event.plaid_connect_request,
      transfer_start_after_hours: this.event.transfer_start_after_hours,
      transfer_start_after_date_time: this.event.transfer_start_after_date_time ? this.event.transfer_start_after_date_time.replace('T', ' ') : null,
      transfer_charge_transaction_flow: this.event.transfer_charge_transaction_flow,
    };

    if (!(+this.event.payout_account_type)) {
      delete data['payout_account_type'];
      delete data['payout_payment_gateway_id'];
    }

    if (!this.event.auto_approve_in_the_future) {
      delete data['auto_approve_template_name'];
    }

    // Boost Fee check
    let initBoostStatus = this.data.boost_status;
    if(initBoostStatus == 0 ) {
      if(data.boost_status == 1) {
        this.dialogService.alertsModal("cms",29,4000,false);
        return;
      }
    }
    if(initBoostStatus == 1 ) {
      if(data.boost_status == 0 || data.boost_status == 1) {
        this.dialogService.alertsModal("cms",29,4000,false);
        return;
      }
    }
    if(initBoostStatus == 2 || initBoostStatus == 3  ) {
      if(data.boost_status == 1) {
        this.dialogService.alertsModal("cms",29,4000,false);
        return;
      }
    }
    // Boost Fee check END
    // Check with user_stripe_connect_payout_request data
    // Payout section
    // if (this.event.user_stripe_connect_payout_request && this.selectedGateway_payout) {
    //   const cpr_gateway_id = this.event.user_stripe_connect_payout_request.gateway_id;
    //   const cpr_type = this.event.user_stripe_connect_payout_request.type;
    //   const pa_type = +this.event.payout_account_type;
    //   console.log('id:',cpr_gateway_id, this.selectedGateway_payout ,' type:',cpr_type, pa_type);
    //   if (+this.selectedGateway_payout !== cpr_gateway_id) {
    //     this.dialogService.alertsModal("gateways",2,5000,false);
    //     return
    //   }
    //   if (pa_type !== cpr_type) {
    //     this.dialogService.alertsModal("gateways",3,5000,false);
    //     return;
    //   }
    //
    // }

    let need_update_commission = true;
    let need_update_gateway = true;
    // tslint:disable-next-line:max-line-length
    if (
      data.purchaser_commission === +this.data.purchaser_commission &&
      data.commission === this.data.commission &&
      data.refund_commission === +this.data.refund_commission &&
      data.service_commission === +this.data.service_commission &&
      data.platform_fee === +this.data.platform_fee &&
      data.tax_commission === +this.data.tax_commission
    ) {
      need_update_commission = false;
    }
    // console.log('need_update_commission', need_update_commission);
    // console.log('1', data.purchaser_commission === +this.data.purchaser_commission);
    // console.log('2', data.commission === this.data.commission);
    // console.log('3', data.refund_commission === +this.data.refund_commission);
    // console.log('4', data.service_commission === +this.data.service_commission);
    // console.log('5', data.tax_commission === +this.data.tax_commission);
    // console.log('6', data.stripe_connect_account_mode === this.data.stripe_connect_account_mode);
    // console.log('7', data.seats_mode === +this.data.seats_mode);
    // console.log('8', data.platform_fee === +this.data.platform_fee);
    let isNotSame = false;
    if (this.gatewayIds.length <= compareGateways.length) {
      isNotSame = this.checkGateways(compareGateways, this.gatewayIds);
    } else {
      isNotSame = this.checkGateways(this.gatewayIds, compareGateways);
    }
    // console.log('gatewayIds length', isNotSame, this.gatewayIds.length, compareGateways.length);

    if (
      (data.payment_type === this.data.payment_type && !isNotSame) ||
      this.data.hasAtLeastOnePurchase
    ) {
      need_update_gateway = false;
    }

    let need_update_refund_type = true;
    if (data.refund_type === this.data.refund_type) {
      need_update_refund_type = false;
    }
    let need_update_isNft = true;
    if (data.isNft == this.data.isNft) {
      need_update_isNft = false;
    }
    let need_update_isDonation = true;
    if (data.isDonation == this.data.isDonation) {
      need_update_isDonation = false;
    }
    let need_update_donation_fee_mode = true;
    if (data.donation_fee_mode == this.data.donation_fee_mode) {
      need_update_donation_fee_mode = false;
    }
    let need_update_event_seat_key = true;
    if (data.event_seat_key == this.data.event_seat_key) {
      need_update_event_seat_key = false;
    }
    let need_update_seat_terms = true;
    if (data.seat_terms == this.data.seat_terms) {
      need_update_seat_terms = false;
    }
    let need_update_stripe_connect_account_id = true;
    if (data.stripe_connect_account_id == this.data.stripe_connect_account_data.stripe_connect_account_id) {
      need_update_stripe_connect_account_id = false;
    }
    let need_update_boost_fee = true;
    if (data.boost_fee == this.data.boost_fee) {
      need_update_boost_fee = false;
    }

    data["need_update_commission"] = need_update_commission;
    data["need_update_gateway"] = need_update_gateway;
    data["need_update_refund_type"] = need_update_refund_type;

    // console.log('onSubmit', this.event.refund_type, this.data.refund_type, data.refund_type, this.data, this.approveEventForm.value);

    if (
      need_update_commission !== false ||
      need_update_gateway !== false ||
      need_update_refund_type !== false ||
      need_update_isNft !== false ||
      need_update_isDonation !== false ||
      need_update_event_seat_key !== false ||
      need_update_boost_fee !== false ||
      need_update_seat_terms !== false ||
      need_update_donation_fee_mode !== false ||
      need_update_stripe_connect_account_id !== false ||
      this.donation_tickets_changed ||
      this.data.status == 3
    ) {
      if (this.apiAction) {
        this.globals.startLoader();
        this.eventService[this.apiAction](data).subscribe(
          (response: any) => {
            this.globals.stopLoader();
            this.dialogRef.close({ success: true, event_id: response.data });
            this.dialogService.alertsModal(
              "success",
              response.code,
              5000,
              true
            );
          },
          (error) => {
            this.globals.stopLoader();
            this.dialogService.alertsModal(
              error.code ? "cms" : "errors",
              error.code ? error.code : 4,
              5000,
              false,
              error.log_id
            );
          }
        );
      } else {
        this.dialogService.alertsModal("errors", 4.1, 5000, false);
      }
    } else {
      this.dialogService.alertsModal("success", "success", 5000, true);
      this.dialogRef.close({ success: true });
    }
  }

  checkGateways(arr1: any[], arr2: any[]): boolean {
    return arr1.every((result: any) => {
      return arr2.every((value: any) => {
        return value.id !== result.id;
      });
    });
  }

  getGatewayTypes(eventId) {
    return new Promise((resolve, reject) => {
      this.gatewayService.getGatewayTypes(eventId).subscribe(
        (response: any) => {
          if (!this.selectedGatewayType) {
            this.selectedGatewayType = response.find((object) => {
              return object === "tranzilla";
            });
          }
          this.selectedTypes = response.filter((object) => {
            return object === "tranzilla";
          });
          // console.log('selectedTypes', this.selectedTypes, this.selectedGatewayType);

          // temporary for prod
          if (!this.isAdmin && this.isProd) {
            // if (this.isProd) {
            response.forEach((type, i) => {
              type === "walletconnect" || type === "solana"
                ? response.splice(i, 1)
                : false;
            });
          }
          //-------------------

          this.getGateways();
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getGateways(event = null) {
    if (this.selectedGatewayType) {
      this.gateways = this.getPaymentGateways().catch((error) => {
        if (error.code) {
          if (error.code === 4.1) {
            this.dialogService.alertsModal(
              "errors",
              error.code,
              3000,
              false,
              error.log_id ? error.log_id : null
            );
          } else {
            this.dialogService.alertsModal(
              "gateways",
              error.code,
              3000,
              false,
              error.log_id ? error.log_id : null
            );
          }
        }
      });
    }
    if(event) {
      // console.log(event.target.value);
      let gw = event.target.value;
      if (gw == 'tranzilla' || gw == 'hyper_switch') {
        this.gatewayTypeChangedByUser = true;
        this.selectedStripeConnectAccount = '';
        this.event.stripe_connect_account_data.stripe_connect_account_id = '';
      }
    }
  }

  // Get gateways according the Currency event setting
  getGatewayByCurrency() {
    this.cmsCurrenciesService.getCurrencyGateway(this.event_currency.id).subscribe((res: any) => {
      if(res && res.data) {
        this.allowedGatewaysByCurrency = res.data
      }
    })
  }
  // Check gateway fow a currency
  disableOptionByCurrency(id) {
    if (!id) {return false}
    let gateway = this.allowedGatewaysByCurrency.find((gw)=> gw.id == id);
    // console.log(gateway)
    if (gateway) {
      return false
    } else {
      return true
    }
  }

  // Payout section
  getGateways_payout(event = null) {
    if (this.selectedGatewayType_payout) {
      this.gateways_payout = this.getPaymentGateways_payout().catch((error) => {
        if (error.code) {
          if (error.code === 4.1) {
            this.dialogService.alertsModal(
              "errors",
              error.code,
              3000,
              false,
              error.log_id ? error.log_id : null
            );
          } else {
            this.dialogService.alertsModal(
              "gateways",
              error.code,
              3000,
              false,
              error.log_id ? error.log_id : null
            );
          }
        }
      });
    }
  }

  getPaymentGateways() {
    return new Promise((resolve, reject) => {
      this.gatewayService.getGateways(this.selectedGatewayType).subscribe(
        (response: any) => {
          resolve(response);
          this.paymentGateways = response;
          // console.log('selectedGateway:', this.selectedGateway, 'type:', this.selectedGatewayType, 'paymentGateways', this.paymentGateways);
          if (this.selectedGatewayType && this.selectedGatewayType !== "0") {
            const gatewaytype = this.paymentGateways.find((object) => {
              if (this.data.status === 1 || this.selectedFeeTemplate) {
                return object.payment_type === this.selectedGatewayType && object.id === this.selectedGatewayId
              } else {
                return object.payment_type === this.selectedGatewayType
              }
            });
            if (gatewaytype === undefined) {
              this.selectedGateway = this.paymentGateways[0].id;
            }
            this.allowOnlyOneCardMethod();
            if (gatewaytype) {
              let countGateways = 0;
              let countType = '';
              Object.keys(this.selectedGateways).forEach((key: any) => {
                if (key !== 'coinbase' && key !== 'walletconnect' && key !== 'solana') {
                  countGateways = countGateways +1;
                  countType = key;
                }
              });
              if (countGateways > 0 && this.selectedGatewayType !== 'coinbase' && this.selectedGatewayType !== 'walletconnect' && this.selectedGatewayType !== 'solana') {
                this.deleteGateway(countType);
                // console.log('deleteGateway', countType);
              }
              this.selectedGateways[this.selectedGatewayType] = gatewaytype;
              this.selectedGateway = this.gateway_id = gatewaytype.id;
              // console.log('selectedGateway', this.selectedGateway, this.selectedGatewayType, this.selectedGateways);
            }
            // console.log('set gateway type', this.selectedGateway, gatewaytype, this.selectedGateways);
            if (this.selectedGateway) {
              const gateway = this.paymentGateways.find(object => {return object.id == this.selectedGateway});
              gateway ? this.selectedGateways[this.selectedGatewayType] = gateway : false;
              this.verifyStripeAccount();
              // console.log('set gateway', gateway, this.selectedGateways);

            }
          }
          // console.log('getPaymentGateways',this.gateway_id, this.selectedGateway, response, this.selectedGateways);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  // Payout section
  getPaymentGateways_payout() {
    return new Promise((resolve, reject) => {
      this.gatewayService.getGateways(this.selectedGatewayType_payout).subscribe(
        (response: any) => {
          resolve(response);
          this.paymentGateways_payout = response;
          //console.log('selectedGateway:', this.selectedGateway, 'type:', this.selectedGatewayType);
          if (this.selectedGatewayType_payout && this.selectedGatewayType_payout !== "0") {
            const gatewaytype = this.paymentGateways_payout.find((object) => {
              return this.data.status === 1
                ? object.payment_type === this.selectedGatewayType_payout &&
                object.id === this.selectedGatewayId
                : object.payment_type === this.selectedGatewayType_payout;
            });
            if (gatewaytype === undefined) {
              this.selectedGateway_payout = this.paymentGateways_payout[0].id;
            }
            this.allowOnlyOneCardMethod_payout();
            if (gatewaytype) {
              let countGateways = 0;
              let countType = "";
              Object.keys(this.selectedGateways_payout).forEach((key: any) => {
                if (
                  key !== "coinbase" &&
                  key !== "walletconnect" &&
                  key !== "solana"
                ) {
                  countGateways = countGateways + 1;
                  countType = key;
                }
              });
              if (
                countGateways > 0 &&
                this.selectedGatewayType_payout !== "coinbase" &&
                this.selectedGatewayType_payout !== "walletconnect" &&
                this.selectedGatewayType_payout !== "solana"
              ) {
                this.deleteGateway_payout(countType);
              }
              this.selectedGateways_payout[this.selectedGatewayType_payout] = gatewaytype;
              // this.selectedGateway_payout = gatewaytype.id;
              this.selectedGateway_payout = this.data.user_stripe_connect_payout_request ? this.data.user_stripe_connect_payout_request.gateway_id : '';
            }
            if (this.selectedGateway_payout) {
              const gateway = this.paymentGateways_payout.find((object) => {
                return object.id == this.selectedGateway_payout;
              });
              gateway
                ? (this.selectedGateways_payout[this.selectedGatewayType_payout] = gateway)
                : false;
              // console.log('set gateway', gateway, this.selectedGateways);
            }
          }
          // console.log('getPaymentGateways_payout',this.gateway_id, this.selectedGateway, response, this.selectedGateways);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  deleteGateway(key) {
    delete this.selectedGateways[key];
    this.cd.detectChanges();
  }
  // Payout section
  deleteGateway_payout(key) {
    delete this.selectedGateways_payout[key];
  }

  setSelectedGateway(selectedGateway) {
    this.allowOnlyOneCardMethod();
    selectedGateway && this.selectedGatewayType
      ? (this.selectedGateways[this.selectedGatewayType] =
          this.paymentGateways.find((object) => {
            return object.id == selectedGateway;
          }))
      : false;


    // console.log('selectedGateway && this.selectedGatewayType', selectedGateway, 'this.selectedGatewayType)', this.selectedGatewayType)
      if(selectedGateway) {
          this.verifyStripeAccount();
      }


    if (selectedGateway === "-1") {
      this.dialogRef.close(selectedGateway);
    }
    this.cd.detectChanges();
  }
  // Payout section
  setSelectedGateway_payout(selectedGateway) {
    selectedGateway && this.selectedGatewayType_payout
      ? (this.selectedGateways_payout[this.selectedGatewayType_payout] =
        this.paymentGateways_payout.find((object) => {
          return object.id == selectedGateway;
        }))
      : false;

    if (selectedGateway === "-1") {
      this.dialogRef.close(selectedGateway);
    }
  }

  togglePassword() {
    this.show_purchase_passcode = !this.show_purchase_passcode;
  }
  changeScannerType(type) {
    // 1 - Platform scanner
    // 2 - Guest manager scanner
  }

  // Payout section
  showGatewayNameById(id) {
    const gatewayType = this.paymentGateways_payout.find((obj) => {
      return id === obj.id
    });
    // console.log('gatewaytype type', gatewayType)
    if (gatewayType === undefined) {
      return ''
    }
    return gatewayType.name
  }

  // Leave only Stripe or Tranzilla or Hyper_switch at the same time (not all)
  allowOnlyOneCardMethod() {
    if (this.selectedGatewayType == "tranzilla") {
      this.deleteGateway("stripe_gateway");
      this.deleteGateway("hyper_switch");
    }
    if (this.selectedGatewayType == "stripe_gateway") {
      this.deleteGateway("tranzilla");
      this.deleteGateway("hyper_switch");
    }
    if (this.selectedGatewayType == "hyper_switch") {
      this.deleteGateway("tranzilla");
      this.deleteGateway("stripe_gateway");
    }
  }
  // Payout section
  allowOnlyOneCardMethod_payout() {
    if (this.selectedGatewayType_payout == "tranzilla") {
      this.deleteGateway_payout("stripe_gateway");
      this.deleteGateway_payout("hyper_switch");
    }
    if (this.selectedGatewayType_payout == "stripe_gateway") {
      this.deleteGateway_payout("tranzilla");
      this.deleteGateway_payout("hyper_switch");
    }
    if (this.selectedGatewayType_payout == "hyper_switch") {
      this.deleteGateway_payout("tranzilla");
      this.deleteGateway_payout("stripe_gateway");
    }
  }

  onChangeStripeAccSelect(account_id) {
    this.event.stripe_connect_account_data.stripe_connect_account_id = account_id;
  }
  onChangeStripeAccInput(account_id) {
    this.selectedStripeConnectAccount = '';
    this.event.stripe_connect_account_data.stripe_connect_account_id = account_id;
  }
    // FOR STRIPE CONNECT ID ACCOUNT
    // RESET OR RESTORE IF gateway CHANGED
  verifyStripeAccount() {
      if(this.selectedGatewayType) {
          let savedStripeGatewayId:any = null;
          let savedAccountId = '';
          if((this.data.stripe_connect_account_data && this.data.stripe_connect_account_data.stripe_connect_account_payment_gateway_id)
            || (this.selectedFeeTemplate && this.event.stripe_connect_account_data.stripe_connect_account_id)) {
              savedAccountId = this.selectedFeeTemplate ? this.event.stripe_connect_account_data.stripe_connect_account_id : this.data.stripe_connect_account_data.stripe_connect_account_id;
              savedStripeGatewayId = this.data.stripe_connect_account_data.stripe_connect_account_payment_gateway_id;
          }
          if ((savedStripeGatewayId &&
              this.selectedGateways[this.selectedGatewayType] &&
              this.selectedGateways[this.selectedGatewayType].id == savedStripeGatewayId)
              || (this.selectedFeeTemplate && this.event.stripe_connect_account_data.stripe_connect_account_id)) {
              // console.log('RESTORE Stripe Account');
              this.event.stripe_connect_account_data.stripe_connect_account_id = savedAccountId;
              this.selectedStripeConnectAccount = savedAccountId;
              this.gatewayTypeChangedByUser = false;
          } else {
              // console.log('CLEAR Stripe Account');
              this.selectedStripeConnectAccount = '';
              this.event.stripe_connect_account_data.stripe_connect_account_id = '';
              this.gatewayTypeChangedByUser = true;
          }


          // Check if Fee template selected
          if (this.selectedFeeTemplate && this.data.stripe_connect_account_data && this.data.stripe_connect_account_data.stripe_connect_account_id) {
            if(this.event.stripe_connect_account_data.stripe_connect_account_id !== this.data.stripe_connect_account_data.stripe_connect_account_id) {
              this.gatewayTypeChangedByUser = true;
            }
          }


      }
  }
  get passcodes() : FormArray {
    return this.passcodesForm.get("passcodes") as FormArray
  }
  newPassCode(item = null): FormGroup {
    return this.fb.group({
      id: item ? item.id : null,
      passcode: item ? item.passcode : '',
    })
  }
  addPassCode() {
    this.passcodes.push(this.newPassCode());
  }
  clearPassCodeArray() {
    this.passcodes.clear();
  }
  removePassCode(i:number) {
    this.passcodes.removeAt(i);

    if(!this.passcodes.length) {
      this.event.purchase_passcode_mode = 0; // uncheck mode checkbox
    }
  }

  // Fee Templates Feature
  openFeeManagmentDialog(action: 'update'|'preview', template =  null) {
    this.globals.startLoader();
    this.feeService.getFeeTemplate(template.id).subscribe((res:any)=> {
      if (res.data) {
        this.dialog.open(CmsFeeManagementTemplateDialogComponent, {
          data: {action: action, template: res.data},
          panelClass: 'fee-management-template-modal'
        });
      }
      this.globals.stopLoader();
    }, error => {
      this.globals.stopLoader();
    })
  }

  getFeeTemplates() {
    return new Promise((resolve, reject) => {
      this.feeService.getFeeTemplates().subscribe(
        (response: any) => {
          resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  changeFeeTemplate(event = null) {
    this.globals.startLoader();

    this.feeService.getFeeTemplate(event.target.value).subscribe((res: any) => {
      this.globals.stopLoader();
      if (res.code == 'success' && res.data) {
        this.selectedFeeTemplate = res.data;
        this.bootstrapFromFeeTemplate(res.data);
      }
    })
  }

  // Update from fields from Template
  bootstrapFromFeeTemplate(template) {
    if (template) {
      for (let key in template) {
        // console.log(key, template[key], this.event.hasOwnProperty(key));
        if (this.event.hasOwnProperty(key)) {
          let value = template[key];

          // 0 or 1 vs true false before send some fields
          // Converting to boolean
          if (key === 'is_svix_webhook_mode' ||
            key ==='isNft' ||
            key ==='show_price_with_fees' ||
            key ==='isDonation' ||
            key ==='donation_fee_mode' ||
            key ==='plaid_connect_request' ||
            key ==='transfer_ticket_mode') {
            (this.event[key] as boolean) = value ? true : false;
          } else if (key === 'stripe_connect_account_id') {
            this.event.stripe_connect_account_data.stripe_connect_account_id = value;
          } else {
            this.event[key] = value; // Update our inputs
          }

          // console.log(key, this.event[key] + ':' + value)
        }
      }
      // if key === 'id'
      this.event.id = this.data.id; // skip for ID because we are using event_id for ID field


      // Fill gateways
      this.selectedGateways = {};
      template.gateways.forEach(gateway => {
        this.selectedGateways[gateway.payment_type] = gateway;
        this.selectedGatewayType = gateway.payment_type;
        this.selectedGatewayId = gateway.id;
        this.selectedGateway = gateway;
      })
      this.getGateways();

      // Fill categories
      this.selection.clear();
      template.categories.forEach((cat: EventCategories) => {
        this.categoryList.data.forEach((row: any) => {
          if (cat.id == row.id) {
            this.selection.select(row)
          }
        });
      })

      // Fill passcodes
      this.clearPassCodeArray();
      template.purchase_passcodes.forEach((passcode: any) => {
        this.passcodes.push(this.newPassCode(passcode));
      })


      this.cd.detectChanges();
    }
  }
  changeAutoApproveInTheFuture() {
    this.event.auto_approve_in_the_future = this.event.auto_approve_in_the_future === 0 ? 1 : 0;
    this.cd.detectChanges();
  }
  changePayoutSchedule(e, input_type) {
    console.log(e.target.value, input_type);
    if(e.target.value) {
      if(input_type == 'hours') {
        this.event.transfer_start_after_date_time = null;
      }
      if(input_type == 'date_time') {
        this.event.transfer_start_after_hours = null;
      }
    }
  }

  changeRevealHours(e) {
    // console.log(e.target.value);
    const date1: any = new Date(e.target.value);
    const date2: any = new Date(this.reveal_barcode_hours_max);
    const diffTime = Math.abs(date2 - date1);
    const diffHours = (diffTime / (1000 * 60 * 60));
    // console.log(diffTime + " milliseconds");
    // console.log(diffHours + " hours");
    this.event.reveal_barcode_hours = Math.round(diffHours);
  }

  // Fee Templates Feature END

}
