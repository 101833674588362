import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import {AuthService} from './auth.service';
declare var window: any;

@Injectable()

export class PusherService {
  api_base_url = environment.api_base_url;
  pusher_key = environment.pusher.key;
  pusher_cluster = environment.pusher.cluster;

  constructor(
    private _authService: AuthService
  ) {}

  initPusher() {
    Pusher.logToConsole = false;
    if (window.Echo) { // Prevent reinit pusher
      return
    }

    const authToken = this._authService.getToken() ? this._authService.getToken() : this._authService.getRefreshToken();

    window['Echo'] = new Echo({
      broadcaster: 'pusher',
      key: this.pusher_key,
      cluster: this.pusher_cluster,
      forceTLS: true,
      authEndpoint: `${this.api_base_url}/broadcasting/auth`,
      auth:
        {
          headers:
            {
              'Authorization': `Bearer ${authToken}`
            }
        }
    });
  }
}


