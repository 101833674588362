
import {tap, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GlobalsService } from '../globals';
import { LoginComponent } from '../login/login.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthCacheService } from '../services-cache/auth-cache.service';
import { EventCacheService } from '../services-cache/event-cache.service';
import {AuthService} from './auth.service';
// import { ActivatedRoute } from '@angular/router';
// import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  api_base_url = environment.api_base_url;
  currentUrl = '';
  user_info = {
    first_name: '',
    last_name: '',
    facebook_id: 0,
    phone: '',
    email: '',
    birth_date: '',
    identification_number: '',
    company: '',
    gender: '',
    img: '',
    user_link: '',
    phoneCountryCode: '',
    user_id: 0,
    profile_img: '',
    bio: '',
    facebookPageLink: '',
    instagramPageLink: ''
  };


  constructor(
    // private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private globals: GlobalsService,
    private dialog: MatDialog,
    private authCacheService: AuthCacheService,
    private eventCacheService: EventCacheService,
    private authService: AuthService
  ) { }



	updateFollowProducer (data) {
    this.authCacheService.clearCache();
    this.eventCacheService.clearCache();
    this.authService.isTokenExist();
		return this.http.post(this.api_base_url + '/api/update-follow-producer', data, {
			headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
		});
	}

	saveProfilePicture(data, userId) {
		return this.http.post(this.api_base_url + '/api/save-user-profile-picture', data, {
				params: new HttpParams().set('userId', userId)
		});
  }

  // user profile media

  getUserProfileMedia() {
    this.authService.isTokenExist();
    return this.http.get(this.api_base_url + '/api/user-profile-media', {
			headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
		});
  }

  delUserProfileMedia(media_id) {
    this.authService.isTokenExist();
    return this.http.delete(this.api_base_url + '/api/user-profile-media/' + media_id, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
		});
  }

  saveUserProfileMedia(data) {
    this.authService.isTokenExist();
    return this.http.post(this.api_base_url + '/api/user-profile-media', data, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  // producer profile media

  getProducerProfileMedia() {
    this.authService.isTokenExist();
    return this.http.get(this.api_base_url + '/api/update-producer-details/media/files', {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  delProducerProfileMedia(media_id) {
    this.authService.isTokenExist();
    return this.http.delete(this.api_base_url + '/api/update-producer-details/media/file/' + media_id, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  saveProducerProfileMedia(data, type) { // type (vodeo or image)
    this.authService.isTokenExist();
    return this.http.post(this.api_base_url + '/api/update-producer-details/media/' + type , data, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  // ------------------------------------------------------
  saveProducerCoverPhoto(data) {
    this.authService.isTokenExist();
    this.authCacheService.clearCache(); // Clear Producer Info
    // console.log('data', data, 'api', this.api_base_url);
    return this.http.post(this.api_base_url + '/api/update-producer-details/save-background-image', data, {
			headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    })
  }

  deleteProducerCoverPhoto() {
    this.authService.isTokenExist();
    this.authCacheService.clearCache(); // Clear Producer Info
    return this.http.delete(this.api_base_url + '/api/update-producer-details/delete-background-image', {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

	deleteProfilePicture(data) {
    this.authService.isTokenExist();
		return this.http.post(this.api_base_url + '/api/delete-user-profile-picture', data, {
			headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
	});
	}

  updateProducerDetails(form: any) {
    this.authService.isTokenExist();
    this.authCacheService.clearCache(); // Clear Producer Info
    return this.http.post(this.api_base_url + '/api/update-producer-details',
      {
        producer_name: form.producerName,
        producer_phone: form.producerPhone,
        producer_email: form.producerEmail,
        bio: form.bio,
        producer_pixel: form.producer_pixel,
        producer_url_identifier: form.producer_url_identifier,
      },
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})}).pipe(
      map(
        (response:any) => {
          return {response: response};
        }
      ),
      tap(
        response => {
          return response;
        }
      ),);

  }

  // -------------------Login Modal------------------------
  openLoginModal(loginData?: {}) {
    this.currentUrl = window.location.href;
    // console.log('openLoginModal Current Path:', this.currentUrl, loginData);

    let dialogRef = this.dialog.open(LoginComponent, {
      data: loginData,
      autoFocus: true,
      disableClose: true,
      width: '100%',
      height: (window.innerWidth < 768 )? '100%': '90%',
      maxWidth: '1280px',
      maxHeight: (window.innerWidth < 768 )? '100%': '840px',
      backdropClass: 'loginModalBackdrop',
      panelClass: 'loginModal'
  });
      return dialogRef.afterClosed();
  }
}
