import {Component, OnInit, OnDestroy, OnChanges, ChangeDetectorRef, AfterViewInit, ViewChild, ElementRef, Renderer2, HostListener} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
// ------------------AngularMaterial----------------------
import {MatDialog} from '@angular/material/dialog';
// -----------------------RxJs---------------------------
import {Subscription} from 'rxjs';
// ----------------------Services------------------------
import {GlobalsService} from '../globals';
import {NavbarService} from '../services/navbar.service';
import {AuthService} from '../services/auth.service';
import {UserService} from './../services/user.service';
import {EventService} from '../services/event.service';
import {DialogService} from '../services/dialog.service';
// import {ClipboardService} from 'ngx-clipboard';
// --------------------Components------------------------
import {CropImageModalComponent} from '../shared-dialogs/crop-image-modal/crop-image-modal.component';
// ------------------------------------------------------
import {LoginToAppResponse} from './../interfaces/authentication.interface';
import {environment} from './../../environments/environment';
import {slideIn} from '../animation';
import {AppResponse} from '../interfaces/app-response.interface';
import {RudderstackService} from '../services/rudderstack.service';
import {
  SvixSettingsComponent
} from "../cms/cms-svix-settings/svix-settings.component";

declare var window: any;

@Component({
  selector: 'app-producer-feed',
  templateUrl: './producer-feed.component.html',
  styleUrls: ['./producer-feed.component.css'],
  animations: [slideIn]
})

export class ProducerFeedComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  api_base_url = environment.api_base_url;
  getin_base_url = environment.getin_base_url;
  images_base_url = environment.images_base_url;
  // ---------------------------
  allEvents: any = null;
  activeEvents: any = null;
  pastEvents: any = null;
  filteredEvents: any = null;
  showCategories = false;
  showSearchInput = false;
  file: File;
  activeCategory = null;
  searchString = '';

  producerImages: any = null;
  producerVideos: any = null;
  producer_media = [];
  galleryLength = 12;
  showMedia = false;
  activeMedia = '';
  scrollPoint = true;
  videoHeight = 350;

  producerCoverPhoto: string = '';
  producerCoverPhotoDefault = '/assets/images/producerFeed/cover-photo-default.png';
  // ---------------------------
  viewport: string = '';

  no_events: boolean = false;
  screenWidth: number;
  producer_id: number;
  producer_idOrPagelink: string;
  nav = true;
  localeId: string;
  dir: string;
  lang: string;
  connectedUserInfo: any;
  isfollowingProducer = false;
  producerDetails: any;
  producerImage: any;


  disableFollowButton = false;
  isProducer = true;
  eventsFilterCurrent = true;
  href: string;
  whatsUpHref;
  shareProducer = false;
  associateEvents: any = null;
  listener;
  user_info: any | null;
  producerFollowers: number | 0;



  user_id;

  windowWidth = null;
  isMobile: boolean;

  loader: boolean = false;
  svixBtn = false;

  @ViewChild('gallery_filter', { read: ElementRef }) galleryContainer: ElementRef;

  constructor(
    private cdr: ChangeDetectorRef,
    public sanitizer: DomSanitizer,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private globals: GlobalsService,
    private navbar: NavbarService,
    public authService: AuthService,
    private userService: UserService,
    private eventService: EventService,
    private dialogService: DialogService,
    private rudderstackService: RudderstackService
  ) {

    this.screenWidth = window.screen.width;
    this.producer_idOrPagelink = this.activatedRoute.snapshot.paramMap.get('producer_id');
    // Detect if it's producer id or custom producer link
    ((typeof this.producer_idOrPagelink == 'number') && !isNaN(this.producer_idOrPagelink)) ? this.producer_id = +this.producer_idOrPagelink : this.producer_id = null;
    this.dir = this.globals.getDir();
    const currentLangUrl = this.globals.getCurrentLangUrl();
    this.lang = (currentLangUrl) ? currentLangUrl + '/' : '/';

    this.windowWidth = window.innerWidth;
    this.isMobile = this.windowWidth <= 768;

  }

  @HostListener('window:resize', ['$event']) onResizeWindow(event) {
    if (window.innerWidth > 920) {
      this.videoHeight = 350;
    } else if (window.innerWidth > 767) {
      this.videoHeight = 200;
    } else if (window.innerWidth > 480) {
      this.videoHeight = 160;
    } else if (window.innerWidth <= 480) {
      this.videoHeight = 100;
    }
    // console.log('window:resize', this.videoHeight);
  };



  ngOnChanges() {
    this.cdr.detectChanges();
  }

  ngOnInit() {

    this.user_id = this.authService.getUser();
    this.navbar.setIsNewUi(true);
    this.navbar.show();
    // Hide Images and Videos section
    // this.getProducerMedia();
    const shareText = $localize`:@@ProducerFeed.shareText:Hi, I would like to invite you to the event`
    this.href = `${this.getin_base_url}/${this.localeId}${this.router.url}`;
    this.whatsUpHref = this.sanitizer.bypassSecurityTrustUrl(`whatsapp://send?text=${encodeURIComponent(shareText)}${this.href}?lang=${this.localeId}`);
    this.setViewPort();
    this.getUserData();
    this.getProducerData();
    this.rudderstackService.page('Producer page','Producer - ' + this.producer_idOrPagelink);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    document.addEventListener('scroll', this.onScroll.bind(this), { passive: true });
    this.onResizeWindow('');
  }

  onScroll() {
    if (this.galleryContainer) {
      const containerOffset = this.galleryContainer.nativeElement.getBoundingClientRect().top;
      this.scrollPoint = containerOffset > 110;
    }
  }

  safeVideoUrl(media_id) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(media_id);
  }

  getUserData() {
    if (this.authService.isConnected()) {
      this.authService.getUserDetails().subscribe(
        response => {
          response && response.user_id ? this.connectedUserInfo = response : false;
          // console.log('connectedUserInfo', this.connectedUserInfo, this.producer_id);
        },
        error => {
          this.dialogService.alertsModal('errors', error.code, 3000, false);
          // console.log('producer feed error auth', error);
        });
    }
  }

  getProducerData() {
    this.authService.getProducerDetails(this.producer_idOrPagelink).subscribe(
      response => {
        if (Object.keys(response).length === 0) {
          this.router.navigate(['/']);
        }
        this.producerDetails = response;
        this.producer_id = this.producerDetails.user_id;
        this.svixBtn = this.producerDetails.hasOwnProperty('svix_mode') ? this.producerDetails.svix_mode : false;
        this.getProducerEvents();

        this.producerImage = this.navbar.getProfileImageLink(this.producerDetails.social_img).imageLink;

        this.producerCoverPhoto = this.producerDetails.background_image_url ? this.producerDetails.background_image_url : this.producerCoverPhotoDefault;
        this.producerFollowers = this.producerDetails.followers.length;

        if (this.producerDetails) {
          this.producerDetails['event_title'] = '';
          this.producerDetails['event_id'] = '';
          this.producerDetails['producer_cover'] = this.producerCoverPhoto;
          // console.log('send producer details', this.producerDetails);
        }
      }
    );
  }

  setViewPort() {
    if (this.screenWidth > 812) {
      this.viewport = 'desktop';
    } else if (this.screenWidth <= 812) {
      this.viewport = 'mobile';
    }
  }

  getProducerMedia() {
    this.globals.startLoader();
    this.userService.getProducerProfileMedia().subscribe(
      (response: any) => {
        this.globals.stopLoader();
        if (response && response.files) {
          if (response.files.length) {
            this.producer_media = response.files;
            // for (let i = this.producer_media.length; i < this.galleryLength; i++) {
            //   console.log('add to array');
            //   this.producer_media.push({ id: '', file_url: '', type: '' });
            // }
            this.producerImages = this.producer_media.filter(media => media.type === 1);
            this.producerVideos = this.producer_media.filter(media => media.type === 2);
          } else {
            // for (let i = this.producer_media.length; i < this.galleryLength; i++) {
            //   console.log('add to array');
            //   this.producer_media.push({ id: '', file_url: '', type: ''});
            // }
          }
        }
        // console.log('producer_media', response, this.producer_media);
      }, error => {
        this.globals.stopLoader();
        this.dialogService.alertsModal('fileUploads', Number(error.code), 5000, false);

      });
  }

  // -------------------- Events -------------------------
  getProducerEvents() {
    this.loader = true;
    this.eventService.getProducerEvents(this.producer_id)
      .subscribe(
        (data: any) => {
          if (data.events.length) {
            this.allEvents = data.events;
            if (this.allEvents && this.allEvents.filter(event => new Date(event.end_date.replace(' ', 'T')) > new Date()).length) {
              this.activeEvents = this.allEvents.filter(event => new Date(event.end_date.replace(' ', 'T')) > new Date());
              if(this.activeEvents.length && this.user_id == this.producerDetails.user_id) {
                this.rudderstackService.group(this.producerDetails.user_id, {
                  'bio': this.producerDetails.bio,
                  'followers': this.producerDetails.followers.length,
                  'name': this.producerDetails.producer_name,
                  'slug': this.producerDetails.producer_url_identifier,
                  'img': this.producerDetails.social_img,
                  'user_id': this.producerDetails.user_id
                })
              }
            }
            if (this.allEvents && this.allEvents.filter(event => new Date(event.end_date.replace(' ', 'T')) < new Date()).length) {
              this.pastEvents = this.allEvents.filter(event => new Date(event.end_date.replace(' ', 'T')) < new Date());
            }

            // for (let event of this.allEvents) {
            //   event.coverDisplay = this.viewport + event.file;
            //   event.shareUrl = (event.url_friendly.length > 0) ? this.api_base_url + '/' + this.localeId + '/' + event.url_friendly : this.api_base_url + '/' + this.localeId + '/' + event.event_id;
            //   event.like = false;
            //   event.toggle = false;

            //   if (!event.address) {
            //     event.address = (event.location_desc) ? event.location_desc : '';
            //   }
            // }
          }
          // else {
          //   this.no_events = true;
          // }
          if (data.associateEvents && data.associateEvents.length) {
            this.associateEvents = data.associateEvents;
          }
          if (!data.events.length && !(data.associateEvents && data.associateEvents.length)) {
            this.no_events = true;
          }
          // console.log('allEvents', data, this.activeEvents, this.pastEvents, this.associateEvents);
          this.loader = false;
        },
        error => {
          this.loader = false;
        }
      );
  }

  createEvent() {
    this.user_info = this.navbar.getUserInfo();
    if (this.user_info && !this.user_info.personal_info) {
      // console.log('checkUser and open personal info');
      this.dialogService.personalInfo(this.user_info, false);
    } else {
      this.eventService.createNewEvent()
      .subscribe(
        (response: AppResponse) => this.router.navigate(['/edit-event/' + response.data.event_id]),
        (error: AppResponse) => this.dialogService.alertsModal('errros', (error.code) ? error.code : '4.1', 5000, false)
      );
    }
  }

  toggleSearch() {
    this.searchString = '';
    this.showSearchInput = !this.showSearchInput;
    if (this.showSearchInput) {
      setTimeout(() => {
        document.getElementById('searchInput').focus();
      });
      this.filteredEvents = [];
    } else {
      this.filteredEvents = null;
    }
  }

  searchFilter(e) {console.log(e.target.value);
    this.searchString = e.target.value.toLowerCase().trim();
    this.filteredEvents = null;

    if (this.searchString.length >= 3) {
      this.activeCategory = null;
      this.allEvents.forEach(event => {
        if (
          (!this.filteredEvents || !this.filteredEvents.filter(item => item.event_id === event.event_id).length) &&
          (event.title.toLowerCase().indexOf(this.searchString) > -1 ||
            event.address.toLowerCase().indexOf(this.searchString) > -1 )
        ) {
          this.filteredEvents ? this.filteredEvents.push(event) : this.filteredEvents = new Array(event);
          setTimeout(() => {
            const results = (<HTMLElement>document.getElementById('search-results'));
            results.scrollIntoView({behavior: 'smooth', block: 'center'});
          })
        }
      });
      this.associateEvents.forEach(event => {
        if ((!this.filteredEvents || !this.filteredEvents.filter(item => item.event_id === event.event_id).length) &&
          (event.title.toLowerCase().indexOf(this.searchString) > -1 ||
            event.address.toLowerCase().indexOf(this.searchString) > -1 ))
        {
          this.filteredEvents ? this.filteredEvents.push(event) : this.filteredEvents = new Array(event);
          setTimeout(() => {
            const results = (<HTMLElement>document.getElementById('search-results'));
            results.scrollIntoView({ behavior: 'smooth', block: 'center' });
          })
        }
      });
    } else {
      this.filteredEvents = null;
    }
  }

  categoryChoosed(category: string) {
    this.activeCategory = null;
    const el = (<HTMLElement>document.getElementById(category))
    if (el) {
      el.scrollIntoView({behavior: 'smooth', block: 'center'});
      this.activeCategory = category;
    }
  }

  mediaChoosed(media: string) {
    this.activeMedia = media;
  }

  // -------------------- Cover Photo-------------------------
  saveCoverPhoto(image) {
    if (image) {
      this.globals.startLoader();
      const formData = new FormData();
      formData.append('background_image', image);
      this.userService.saveProducerCoverPhoto(formData).subscribe(res => {
          this.producerCoverPhoto = res['background_image_url'];
          this.globals.stopLoader();
          this.dialogService.alertsModal('success', res['code'], 5000, true);
        },
        error => {
          this.globals.stopLoader();
          this.dialogService.alertsModal('producerCoverPhotoErrors', error.code, 5000, false);
        }
      );
    }
  }

  deleteCoverPhoto() {
    this.dialogService.areUSureModal('deleteCoverPhoto').subscribe(response => {
        if (response) {
          this.globals.startLoader();
          this.userService.deleteProducerCoverPhoto().subscribe(
            res => {
              this.producerCoverPhoto = this.producerCoverPhotoDefault;
              this.globals.stopLoader();
              this.dialogService.alertsModal('success', res['code'], 5000, true);
            },
            error => {
              this.globals.stopLoader();
              this.dialogService.alertsModal('producerCoverPhotoErrors', error.code, 5000, false);
            }
          );
        }
      },
      error => {
        this.globals.stopLoader();
        this.dialogService.alertsModal('errors', 2, 5000, false);
      }
    )
  }

  cropImage($event) {
    if ($event && $event.target.value) {
      const cropeImageModal = this.dialog.open(CropImageModalComponent, {
        data: {
          element: $event,
          configRatio: 21 / 9,
          ratio: '21:9',
          fileMaxSize: 5
        },
        disableClose: true,
        autoFocus: false,
        panelClass: 'updateImageModal'
      });
      cropeImageModal.afterClosed().subscribe(
        result => {
          if (result !== undefined && result.success) {
            this.saveCoverPhoto(result.croppedFile);
          } else {
            $event.target.value = '';
          }
        }
      );
    }
  }
  showSvixModal(user_id) {
    if (user_id && user_id !== 0) {
      this.dialog.open(SvixSettingsComponent, {
        data: {user_id: user_id, cmsPage: false},
        disableClose: false,
        // height: '80vh',
        // width: '90vw',
        // maxWidth: '100vw',
        // maxHeight: '100vh',
        autoFocus: false,
        panelClass: 'svix-settings-modal'
      });
    }
  }

  ngOnDestroy() {
    this.navbar.setIsNewUi(false);
    document.removeEventListener('scroll', this.onScroll.bind(this), true);
  }

}
