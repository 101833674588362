<div class="payout-modal">
  <div class="payout-modal-header">
    <button type="button" [matDialogClose]>
      <img src="/assets/images/svg-icons/close.svg" alt="close">
    </button>
  </div>

  <div>
    <mat-checkbox [formControl]="terms"></mat-checkbox>
    <span style="font-size: 15px; margin: 0 10px;">I accept <a class="bank-info-terms" href="https://intercom.help/getin-93198e5c33cd/en/collections/3932185-event-payout-terms" target="_blank">term of payout</a></span>
  </div>

  <button class="continue">Continue</button>

</div>
