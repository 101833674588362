import { GlobalsService } from './../../globals';
import { CurrencyLocaleService } from './../../services/currency-locale.service';
import { EventTicketsService } from './../event-tikets-service/event-tickets.service';
import { Component, Input, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-order-summaries',
  templateUrl: './order-summaries.component.html',
  styleUrls: ['./order-summaries.component.css']
})
export class OrderSummariesComponent implements OnInit {

  // choosedItemsCheckIfSoldOut: any = {};
  @Input() promoCode: string;
  @Input() currency: string;
  @Input() couponCodeSent = false;
  @Input() choosedItems: any = {};
  @Input() commission_ils: string;
  @Input() tax_commission = '0.00';
  @Input() total_tax_commission = 0;
  @Input() total_purchaser_commission: string;
  @Input() total_service_commission: string;
  @Input() tax_and_fees = 0;
  @Input() couponTicketId = 0;
  @Input() total_discount = 0;
  @Input() total_ticket_discount = 0;
  @Input() total_price_w_commission: string;
  @Input() isSelectedTicketOutOfStock: boolean;

  dir: string | null;

  constructor(private eventTicketsService: EventTicketsService, private currencyLocale: CurrencyLocaleService, public globals: GlobalsService) {

  }

  ngOnInit(): void {
    this.dir = this.globals.getDir() === 'rtl' ? 'rtl' : 'ltr' ;
  }

  getLocale(currency) {
    return this.currencyLocale.getLocaleForCurrencyPipe(currency)
  }

  checkPromo(couponCode) {
    this.eventTicketsService.checkPromo(couponCode);
  }

  setPromo(event) {}

}
