import { Injectable } from '@angular/core';
import {TweedSDKContext} from '../paytweed/sdk-context';
import {GlobalsService} from '../globals';
import {WalletConnectService} from './wallet-connect.service';
declare var window: any;
import {environment} from '../../environments/environment';
import {RudderstackService} from './rudderstack.service';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PaytweedService {
  tweedWalletsFromDB: any = [];
  isProd = environment.api_tweed_base_url === 'https://tweed.get-in.com' ? true : false;
  chain = this.isProd?'polygon':'polygon'; // static blockchains
  fetched: boolean = false;
  api = environment.api_tweed_base_url;

  constructor(private rudderstackService: RudderstackService, public context: TweedSDKContext, private globals: GlobalsService,
              private walletConnectService: WalletConnectService, private router: Router, private http: HttpClient  ) {



  }

  // ------------Wallet Functions-------------
  // Creates a wallet for the user using a transperent widget
  async create(reload = false, main_purchase_identifier = '', goToProfile = false) {
    if (this.context.tweedSdk === undefined) {
      // console.log('context.tweedSdk NOT INITIALIZED')
      return null
    }
    return await this.context.tweedSdk.wallet.create({
      callbacks: {
        onClose: () => {
          console.log('closed');
          this.globals.stopLoader();
        },
        onError: (error) => {
          console.log('error', error);
          if (main_purchase_identifier) {
            this.walletConnectService.failOnCreatePaytweed(main_purchase_identifier).subscribe(res=> {
              console.log(res);
            });
          }
          this.globals.stopLoader();
        },
        onSuccess: (s) => {
         // s = {
         //    "arbitrum": "0x7Fb8A2b738af6fD6c5FBE7FaeA71D3d6d76Bc8a3",
         //    "arbitrumGoerli": "0x7Fb8A2b738af6fD6c5FBE7FaeA71D3d6d76Bc8a3",
         //  }
          const wallets = this.prepareAdresses(s);
          console.log('onSuccess wallet created', s, 'wallets prepareAdresses 1', wallets);

          // Track Wallet created
          this.rudderstackService.track('Wallet created', {
            wallets: s
          })

          this.globals.stopLoader();
          if (wallets.length) {
            if(goToProfile) {
              setTimeout(()=> {
                this.router.navigate(['/personal-info']);
              },3000)
            }
            this.globals.startLoader();
            this.walletConnectService.setPaytweedWallets(wallets).subscribe(res=> {
              this.globals.stopLoader();
              if (reload) {window.location.reload();}
            }, error => {
              this.globals.stopLoader();
            })
          }
        }
      }
    })
  }
  // Create recovery kit
  async createRecovery() {
    return await this.context.tweedSdk.wallet.createRecovery()
  }
  // Checks if the user already owns a wallet
  async exists() {
    if (this.context.tweedSdk) {
      return this.context.tweedSdk.wallet.exists()
    } else {
      return null
    }

  }
  // Returns the address of the user's wallet for a supported blockchain
  async getAddress(blockchainId = { blockchainId: this.isProd?'polygon':'polygon'}) {
    return await this.context.tweedSdk.wallet.getAddress(blockchainId)
  }
  // Returns the list of wallet addresses for all the supported blockchains
  async getAddresses() {
    return await this.context.tweedSdk.wallet.getAddresses()
  }
  async getAddressesByBlochainId(blockchainIds = this.isProd?['polygon']:['polygon']) {
    return await this.context.tweedSdk.wallet.getAddresses({ blockchainIds: blockchainIds })
  }
  // Returns the recovery status of the user's wallet by email or user id, if the user created a recovery kit
  // the function will return recoveryKit,
  // if the user saved their recovery phrase to their google drive
  // the function will return savedMnemonics
  async getRecoveryStatus() {
    return await this.context.tweedSdk.wallet.getRecoveryStatus()
  }
  // Checks that the user is currently logged in to the wallet
  async loggedIn() {
    return await this.context.tweedSdk.wallet.loggedIn()
  }
  // Logs out the user from his wallet
  async logout() {
    return await this.context.tweedSdk.wallet.logout()
  }
  // A widget with the wallet address encoded in QR code and a dropdown to choose the blockchain of the address.
  async showAddress(blockchainId = { blockchainId: 'ethereum' }) {
    return await this.context.tweedSdk.wallet.showAddress(blockchainId)
  }
  // Shows a widget where the user can see his recovery phrase and
  // a button to save the save the recovery phrase to their Google drive.
  async showRecoveryPhrase() {
    return await this.context.tweedSdk.wallet.showRecoveryPhrase()
  }
  // Sign on a message sent from the platform. A widget will be shown to the user with the message itself,
  // the user will be able to approve or cancel the signage.
  async signMessage(blockchainId = 'ethereum', message = 'hello tweed') {
    return await this.context.tweedSdk.wallet.signMessage({
      blockchainId: blockchainId,
      message: message,
      callbacks: {
        onClose: () => {
          console.log('msg');
        },
        onError: (error) => {
          console.log('error', error);
        },
        onSuccess: (s) => {
          console.log('success', s);
        }
      }
    })
  }

  async recreate() {
    return await this.context.tweedSdk.wallet.recreate()
  }

  // ------------On-chain Functions-------------
  // Returns list of all the supported blockchains
  async list() {
    return await this.context.tweedSdk.blockchain.list()
  }

  async getBalance() {
    return await this.context.tweedSdk.crypto.getBalance()
  }

  // Returns a list of NFTs owned by the user's wallet for multiple blockchains
  async getNftBalance() {
    return await this.context.tweedSdk.nft.getBalance()
  }

  // Returns a url of a widget where the user can buy an NFT using a credit / debit card
  // https://docs.paytweed.com/frontend-sdk/javascript/on-chain-functions/#buy-with-fiat
  // nftId = {
  //   nftId : "123",
  //   priceInCents : "10000",
  //   currency:  'USD',
  //   tokenUri : "ipfs://QmTy8w65yBXgyfG2ZBg5TrfB2hPjrDQH3RCQFJGkARStJb",
  //   chain : "ethereumGoerli",
  //   contractAddress : "0x90fcab7b307128ceb4a678bdb5240f4bcf950875",
  //   description : "This is an example NFT",
  //   title: 'Example NFT'
  // }
  async buyWithFiat(nftId) {
    console.log('buyWithFiat nftId', nftId)
    let payload = {
      nftId: nftId
    }
    return await this.context.tweedSdk.nft.buyWithFiat(payload)
  }

  // ------------CUSTOM Functions-------------
  async initTweedContext() {
    await this.context.setupTweed();
  }
  async createWallet(main_purchase_identifier = '', goToProfile ) {
    // this.globals.startLoader();
    const exist = await this.exists();
    if (exist) {
      console.log('Wallet already exist!');
      if(!this.tweedWalletsFromDB.length) {
        const wallet_addresses = await this.getAddresses();
        const wallets = this.prepareAdresses(wallet_addresses);
        console.log('wallets prepareAdresses 2', wallets, this.tweedWalletsFromDB);
        this.walletConnectService.setPaytweedWallets(wallets).subscribe(res=> {
          this.globals.stopLoader();
        }, error => {
          this.globals.stopLoader();
        })
      }
      this.globals.stopLoader();
    } else {
      try {
        await this.create(false, main_purchase_identifier, goToProfile);
      } catch (e) {
        if (main_purchase_identifier) {
          this.walletConnectService.failOnCreatePaytweed(main_purchase_identifier).subscribe(res=> {
            console.log(res);
          });
        }
        console.error(e);
        this.globals.stopLoader();
      }
    }

  }
  setTweedWalletsFromDB(wallets){
    this.tweedWalletsFromDB = wallets;
    this.globals.stopLoader();
  }
  getTweedWalletsFromDB() {
    return this.tweedWalletsFromDB;
  }
  clearTweedWalletsFromDB() {
    this.tweedWalletsFromDB = [];
  }
  prepareAdresses(list) {
    let wallets  = []
    for (const key in list) {
      if (list.hasOwnProperty(key) && key == this.chain) {
        wallets.push({
          'wallet': list[key],
          'network': key
        })
      }
    }
    return wallets
  }

  compareSavedAndTweedApiWallets(tweed_wallets = {}, saved_wallets = []) {
    let saved_walletsObj = saved_wallets.reduce((obj, item) => (obj[item.network] = item.wallet, obj) ,{});
    // console.log('compareSavedAndTweedApiWallets', tweed_wallets, saved_wallets, saved_walletsObj)
    let new_wallets = [];

    for (const key in tweed_wallets) {
      if (tweed_wallets.hasOwnProperty(key) && key == this.chain) {
        // let isWalletTheSame = tweed_wallets[key] == saved_walletsObj[key];
        if(!saved_walletsObj.hasOwnProperty(key)) {
          // console.log('NOT EXIST key', key, tweed_wallets[key])
          new_wallets.push({
            wallet: tweed_wallets[key],
            network: key
          })
        }
      }
    }

    return new_wallets
  }

  // TWEED WALLET CREATION START
  async walletCreateInit(main_purchase_identifier = '', goToProfile ) {
    this.globals.startLoader();
    if (!this.fetched) {
      this.fetched = true;
      await this.initTweedContext();
      if (!this.getTweedWalletsFromDB().length) {
        const exist = await this.exists();

        if (!exist) {
          await this.createWallet(main_purchase_identifier, goToProfile);
          this.fetched = false;
        } else {
          const tweed_wallets = await this.getAddresses();
          this.walletConnectService.getPaytweedAddress().subscribe((res: any) => {

            if (res && !res.data.length) {
              this.createWallet(main_purchase_identifier, goToProfile);
            }
            if (res && res.data.length) {
              const new_wallets = this.compareSavedAndTweedApiWallets(tweed_wallets, res.data);
              if (new_wallets.length) {
                this.walletConnectService.setPaytweedWallets(new_wallets).subscribe(res=> {
                  console.log(res);
                  this.globals.stopLoader();
                });
              }
              const tweed_walletsArr = this.prepareAdresses(tweed_wallets);

              this.setTweedWalletsFromDB(tweed_walletsArr);
            }
            this.fetched = false;
          })
        }
      }
    } else {
      this.setTweedWalletsFromDB([]);
    }
  }
  // TWEED WALLET CREATION END

  async postUser(user) {
     return this.http.post(this.api + `/user`, user , { }).toPromise();
  }

}
