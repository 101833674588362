import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EventCategoryService {
api_base_url = environment.api_base_url;
constructor(private httpClient: HttpClient) { }

  getCategories() {
    return this.httpClient.get(this.api_base_url + '/api/event-categories', {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  setCategories(categories_id, event_id) {
    return this.httpClient.post(this.api_base_url + '/api/event-categories-add/' + event_id, {categories_id},
      {headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  getCategoriesByEvent(event_id) {
    return this.httpClient.get(this.api_base_url + '/api/event-categories-get/' + event_id , {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  deleteCategory(id: string) {
    return this.httpClient.delete(this.api_base_url + '/api/event-categories/' + id, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  addCategory(data) {
    return this.httpClient.post(this.api_base_url + '/api/event-categories', data.value,
      {headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  editCategory(data) {
    return this.httpClient.post(this.api_base_url + '/api/event-categories/' + data.id, data.value,
      {headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

}
