import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  HostListener
} from '@angular/core';

@Component({
  selector: 'app-check-otp',
  templateUrl: './check-otp.component.html',
  styleUrls: ['./check-otp.component.scss']
})
export class CheckOtpComponent implements OnInit, OnDestroy {

  regEx = '/[^0-9]/g';
  isShowOtp: boolean = true;
  isDisabbleBtn = {isDisable: false, otp: null};
  @Output() timeCounter = new EventEmitter();
  @Output() disabbleBtnChange = new EventEmitter<any>();
  @Output() sendUserData = new EventEmitter();
  @Output() otpIsShow = new EventEmitter();
  @Input() isShowTimer = true;
  @Input() userEmailOrPhone = '';
  @Input() isSendAgainAttempts = 0;
  @Input() isPhone = false;
  @Input() set disabbleBtn(value:{isDisable: boolean, otp: string}) {
    this.isDisabbleBtn.isDisable = value.isDisable;
    // this.disabbleBtnChange.emit(this.isDisabbleBtn);
  }
  @Input() set showOtp(value) {
    this.isShowOtp = value;
    this.isShowOtp ? this.otpIsShow.emit(true) : false;
  }
  inputsOtp = [{0:''},{1:''},{2:''},{3:''},{4:''},{5:''}];
  interval;
  @Input() timeLeft: number | null = null;
  otpString: string = '';
  regexStr = '^[0-9]*$';
  index;
  otpValue = '';
  isSent = false;

  @HostListener('window:beforeunload', ['$event'])
  tabClose($event) {
    $event.preventDefault();
    return ($event.returnValue = "Prevent tab closing!");
  }

  constructor() { }

  ngOnInit(): void {

  }

  // get disabbleBtn() {
  //   return this.isDisabbleBtn;
  // }
  isNumber(value) {
    return typeof value === 'number' && isFinite(value);
  }
  hasWhiteSpace(s) {
    return s.indexOf(' ') >= 0;
  }

  get iOS() {
    return (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1));
  }

  onChangeInput(event, otpInput) {
    // console.log('onChangeInput otpInput', event, event.target.value, otpInput.value);
  }

  onPaste(event, type, input, index) { // 198756
    event.preventDefault();
    // console.log('onPaste: ', event, type, index, input, input.value);
    let otp;
    if (type=='paste') {
      otp = event.clipboardData.getData('text').replace(/\s/g, ''); // ClipboardEvent
    } else {
      otp = event.target.value.replace(/\s/g, ''); // InputEvent
      input.value = input.value[0];
    }
    this.index = index; this.otpValue = otp;
    if (otp && this.isNumber(+otp)) {
      // console.log('otp is number');
     if ( otp.length == 6) { // (this.isIOS || this.iOS) && index == 0 &&
        // console.log('this is IOS test autoFill', this.isNumber(+otp));
        this.inputsOtp.forEach((_, index) => {
          this.inputsOtp[index][index] = otp[index];
        });
        this.checkAndSendOtp();
        return;
      }
    } else {
      this.inputsOtp[index][index] = '';
    }
  }

  onChangeOtp(event: HTMLInputElement, index) {
  // onChangeOtp(event: HTMLInputElement, index, inputOtp?, keyPress?: KeyboardEvent) {
    // console.log('check Otp', keyPress.key, keyPress.keyCode, this.isIOS, event.value, event.value.length, index, this.isNumber(+event.value));

    // if (keyPress.key == 'Backspace' && index > 0) { // keyCode: 8 or 46
    //   const currentElementFocus = (document.getElementById("otpInputId"+(index < 5 ? index+1 : index)) as HTMLInputElement).value;
    //   if (currentElementFocus) {
    //     const prevElementFocus = document.getElementById("otpInputId"+(index));
    //     prevElementFocus.focus();
    //     this.inputsOtp[index][index] = '';
    //   } else {
    //     const prevElementFocus = document.getElementById("otpInputId"+(index-1));
    //     prevElementFocus.focus();
    //     this.inputsOtp[index-1][index-1] = '';
    //   }
    //   return;
    // } //else if (keyPress.key == 'Backspace') {}


    if (event.value && this.isNumber(+event.value)) {
      let counter = index+1 > 5? index : index+1; // index-3
      if (event.value.length == 6) { // (this.isIOS || this.iOS) && index == 0 &&
        // console.log('this is test fill');
        return;
      }
      const nextElementFocus = document.getElementById("otpInputId"+counter);
      if (nextElementFocus) {
        nextElementFocus.focus();
        // console.log("nextElementFocus", nextElementFocus.id, nextElementFocus.attributes, nextElementFocus);
      };
      this.inputsOtp[index][index] = event.value[0]; // event.value.replace(/[^0-9]/g, event.value.substr(0,1));
      // console.log('onChangeOtp', "otpInputId"+counter, counter, index, event.value, event, inputOtp, inputOtp[index], this.isNumber(+event.value));
    } else {
      this.inputsOtp[index][index] = '';
      // console.log('Otp not entered, please re-enter',  inputOtp[index], inputOtp, event.value, index, this.isNumber(+event.value));
    }
    this.checkAndSendOtp();
  }


  onKeyUpOTP(index, keyPress?: KeyboardEvent) {
    // console.log('onKeyUpOTP', keyPress.key, keyPress.keyCode, index);
    if (keyPress.key == 'Backspace' && index > 0) { // keyCode: 8 or 46
      const currentElementFocus = (document.getElementById("otpInputId" + (index < 5 ? index + 1 : index)) as HTMLInputElement).value;
      if (currentElementFocus) {
        const prevElementFocus = document.getElementById("otpInputId" + (index));
        prevElementFocus.focus();
        this.inputsOtp[index][index] = '';
      } else {
        const prevElementFocus = document.getElementById("otpInputId" + (index - 1));
        prevElementFocus.focus();
        this.inputsOtp[index - 1][index - 1] = '';
      }
      return;
    }
  }

    startTimer(timeLeft) {
    const time = 1000;
    this.timeLeft = timeLeft;
    clearInterval(this.interval);
    this.interval = null;
    this.interval = setInterval( () => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
        this.interval = null;
        this.inputsOtp = [{0:''},{1:''},{2:''},{3:''},{4:''},{5:''}];
        this.disabbleBtnChange.emit({isDisable: false, otp: this.otpString});
        this.setReadOnly('false');
        this.isSent = false;
        // console.log('clearInterval!')
      }
      // console.log('startTimer', this.timeLeft);
      this.timeCounter.emit(this.timeLeft);
      // this.timeLeft > 0 ? this.timeCounter.emit(this.timeLeft) : false;
    }, time);

  }

  checkAndSendOtp() {
    let checkingOtp = '';
    let isFillOtp = true;
    this.inputsOtp.forEach((arr, val) => {
      // console.log('checkAndSendOtp', arr, val, arr[val]);
      if (!arr[val]) {isFillOtp = false;} else {checkingOtp += arr[val];}
    });
    if (isFillOtp && checkingOtp.length == 6 && !this.isSent) {
      this.isSent = true;
      this.otpString = checkingOtp;
      this.setReadOnly('true');
      this.startTimer(this.timeLeft ? this.timeLeft : 5);
      this.sendUserData.emit(this.otpString);
      this.disabbleBtnChange.emit({isDisable: true, otp: this.otpString});
      this.otpIsShow.emit(false);
      // console.log('Send otp', checkingOtp);
    } else {
      this.otpString = '';
      // console.log('OTP not sent', isFillOtp, checkingOtp.length);
    }
  }

  checkAndSendOtpV2(e) {
    let isFillOtp = true;

    if (isFillOtp && e.value.length == 6 && !this.isSent) {
      this.isSent = true;
      this.otpString = e.value;
      // this.setReadOnly('true');
      e.setAttribute('disabled', true)
      this.startTimerV2(this.timeLeft ? this.timeLeft : 5, e);
      this.sendUserData.emit(this.otpString);
      this.disabbleBtnChange.emit({isDisable: true, otp: this.otpString});
      // this.otpIsShow.emit(false);
      console.log('Send otp', this.otpString);
    } else {
      this.otpString = '';
      // console.log('OTP not sent', isFillOtp, checkingOtp.length);
    }
  }

  startTimerV2(timeLeft, e) {
    const time = 1000;
    this.timeLeft = timeLeft;
    clearInterval(this.interval);
    this.interval = null;
    this.interval = setInterval( () => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
        this.interval = null;
        this.otpString = '';
        this.disabbleBtnChange.emit({isDisable: false, otp: this.otpString});
        if(this.isPhone) {
          // if(this.isSendAgainAttempts < 3) {
            e.removeAttribute('disabled');
          // }
        } else {
          e.removeAttribute('disabled');
        }

        e.value = '';
        e.focus();
        this.isSent = false;
      }
      this.timeCounter.emit(this.timeLeft);
    }, time);

  }

  setReadOnly(value: string) {
    this.inputsOtp.forEach((_, val) => {
      const nextElementFocus = document.getElementById("otpInputId"+val);
      nextElementFocus ? nextElementFocus.setAttribute('disabled', value) : false;
    });
    setTimeout(()=>{
      const elementFocus = document.getElementById("otpInputId0");
      if (value == 'false' && elementFocus) {
        elementFocus.focus();
      };
      // console.log('setReadOnly', value, elementFocus.id);
    },300);
  }

  onFocusInput(event: HTMLInputElement, $event?) {
    event.select();
    // console.log("onFocus", event, event.id, $event);
  }

  // new Input OTP function
  onInputOtp(e) {console.log(e.target)
    // console.log('onInput',  e.value, this.isNumber(+e.value));
    // e.style.setProperty('--_otp-digit', e.value.length); //styling purpose
    if (e.value && this.isNumber(+e.value) && !this.hasWhiteSpace(e.value)) {
      if (e.value.length == 6) {
        this.checkAndSendOtpV2(e);
        // return;
      }
      if (e.value.length > 6) {
        let val = e.value;
        e.value = val.substring(0, 6);
        // e.style.setProperty('--_otp-digit', 6); //styling purpose
        return;
      }
    } else {
      // e.style.setProperty('--_otp-digit', 0); //styling purpose
      e.value = '';
    }

  }

  // new Paste OTP function
  onPasteOtp(event, input) {
    // console.log('onPasteOtp', event, input);
    event.preventDefault();
    let otp = event.clipboardData.getData('text').replace(/\s/g, ''); // ClipboardEvent
    this.otpValue = otp;
    // input.style.setProperty('--_otp-digit', otp.length); // styling purpose

    if (otp && this.isNumber(+otp)) {
      if ( otp.length == 6) {
        input.value = otp;
        this.checkAndSendOtpV2(input);
        return;
      } else {
        input.value = otp;
        input.focus();
      }
    } else {
      input.value = otp;
    }
  }

  get isIOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes('Mac')) ||
    /^Mac/.test(navigator.platform) && navigator.maxTouchPoints > 4;
  }

  ngOnDestroy(): void {
    clearInterval(this.interval), this.interval = null;
  }

}
