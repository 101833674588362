import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CmsCurrenciesService {
  api_base_url = environment.api_base_url + '/api/cms/currency/platform';
  constructor(private http: HttpClient) { }

  getAllCurrencies() {
    return this.http.get(this.api_base_url, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }),
    });
  }
  createCurrency(data) {
    // 'name' - string, 'symbol' - string, 'rate_usd' - float, 'active' - bool
    return this.http.post(this.api_base_url, data,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      });
  }
  updateCurrency(id, data) {
    // 'name' - string, 'symbol' - string, 'rate_usd' - float, 'active' - bool
    return this.http.patch(this.api_base_url +`/${id}`, data,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      });
  }
  deleteCurrency(id) {
    return this.http.delete(this.api_base_url + `/${id}`,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      });
  }
  getStripeCurrencies() {
    return this.http.get(environment.api_base_url+'/api/cms/currency/stripe', {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }),
    });
  }


  getCurrencyGateway(currency_id) {
    return this.http.get(this.api_base_url + '/'+currency_id+'/gateways',
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      });
  }

  addCurrencyGateway (currency_id, data) {
    // data = {
    //   "payment_gateway_ids": [1,2,3]
    // }
    return this.http.post(this.api_base_url + '/'+currency_id+'/gateways-assign', data,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      });
  }
  deleteCurrencyGateway(currency_id, id) {
    return this.http.delete(this.api_base_url + '/'+currency_id+'/gateways/'+id,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      });
  }


  getGatewayCurrency(payment_gateway_id) {
    return this.http.get(environment.api_base_url+'/api/payment-gateways/' +payment_gateway_id+'/currencies',
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      });
  }

  addGatewayCurrency (payment_gateway_id, data) {
    // data = {
    //   "currency_ids": [1,2,3]
    // }
    return this.http.post(environment.api_base_url+'/api/payment-gateways/' +payment_gateway_id+'/currencies-assign', data,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      });
  }

  deleteGatewayCurrency(payment_gateway_id, currency_id) {
    return this.http.delete(environment.api_base_url+'/api/payment-gateways/' +payment_gateway_id+'/currencies/'+currency_id,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      });
  }
}
