import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, UntypedFormControl } from '@angular/forms';

@Directive({
  selector:
    '[appValidationMin]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: ValidationMinDirective, multi: true }
  ]
})
export class ValidationMinDirective implements Validator {
  @Input('appValidationMin') ValidationMinDirective: number;

  validate(c: UntypedFormControl): { [key: string]: any } {
    const v = c.value;
    if (v !== '' && v !== null) {
      return v <= this.ValidationMinDirective ? { ValidationMinDirective: true } : null;
    } else {
      return null;
    }
  }
}
