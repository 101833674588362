import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { PhoneVerificationDialogService } from '../../services/phone-verification-dialog.service';

export interface DialogData {
  phone: string,
  phoneCountryCode: string,
  phone_validate_code: string,
  isVerified: boolean
}

@Component({
  selector: 'phone-verification-dialog',
  templateUrl: './phone-verification-dialog.html',
  styleUrls: ['./phone-verification-dialog.css']
})

export class PhoneVerificationDialog {
  isVerified: boolean;
  btnDisable: boolean;
  error: string;
  seconds: number;

  constructor(
    public dialogRef: MatDialogRef<PhoneVerificationDialog>,
    private authService: AuthService,
    private phoneVerificationDialogService: PhoneVerificationDialogService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.isVerified = this.data.isVerified;
    this.btnDisable = true;
  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    this.phoneVerificationDialogService.timerSeconds.subscribe(second => this.seconds = second);
  }


  change(code) {
    this.data.phone_validate_code = code;
    code.length === 6 ? this.btnDisable = false : this.btnDisable = true;
    this.error = '';
  }
  resend() {
    this.data.phone_validate_code = '';
    this.resendCode();
  }

  send(): void {
    this.verifyCode();
  }

  // Post API call to check code
  verifyCode() {
    this.authService.verifyPhoneBySMSCheck(this.data.phone, this.data.phoneCountryCode, this.data.phone_validate_code).subscribe(
    (res: any) => {
      if (res.code === 'success') {
        this.error = '';
        this.phoneVerificationDialogService.setIsVerified(true); // Method to set that phone was verified
        this.phoneVerificationDialogService.setLastVerifiedPhone(this.data.phone); // Set last successfully verified phone in buffer
        this.dialogRef.close();
      }
    }, error => {
      console.log('Error', error);
      if (error.code === 1) { // If invalid code
        this.data.phone_validate_code = '';
        this.btnDisable = true;
        this.error = 'invalid code';
      }
    });
  }

  resendCode() {
    this.authService.verifyPhoneBySMS(this.data.phone, this.data.phoneCountryCode)
      .subscribe(
        (res: any) => {
          // console.log("res", res);
          if (res.code === 'success') {
            if (this.phoneVerificationDialogService.getSeconds() <= 0) {
              this.phoneVerificationDialogService.startTimer();
            }
          }},(error) => {
             console.log('Error', error);
          })
  }

}
