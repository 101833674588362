<div class="col-md-12 col-xs-12">
    <!-- <div class="modal-header">
        <button type="button" class="close" (click)="dialogRef.close()">
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 i18n="modal title | crop user image" class="text-center" mat-dialog-title>Crop image</h4>
    </div> -->
    <div class="modal-body" mat-dialog-content>
        <div class="row mt20">
            <div class="gallery-controlls">
                <div class="crop-image-profile col-md-12 col-xs-12">
                    <div class="col-md-12 col-xs-12 p0 mt5 mb10">
                        <h4 i18n="cover image | title ">Upload image</h4>
                        <p i18n="cover image | required aspect ratio">Image aspect ratio {{ratio}}</p>
                        <p i18n="cover image | maximum image size">Maximum image size: {{fileMaxSize}}MB</p>
                        <div *ngIf="imageCropperComponent" [@fade] class="cover-cropper-image">
                            <angular-cropper (touchstart)="cropperTouchStart($event)" (ready)="readyCrop($event)"
                                #profileImageCropper [imageUrl]="imageUrl" [cropperOptions]="imageCropperConfig">
                            </angular-cropper>
                        </div>
                        <div class="docs-buttons">
                            <div class="btn-group manage-btn-group">
                                <button id="inputImage" class="btn btn-upload manage-btn" type="button"
                                    (click)="imageFileInput.click()">
                                    <label for="inputImage" title="Upload image file">
                                        <span class="docs-tooltip">
                                            <span class="fa fa-upload"></span>
                                            <br>
                                            <small i18n="@@Upload">Upload</small>
                                        </span>
                                    </label>
                                </button>
                                <button (click)="saveCroppedImage(profileImageCropper)" type="button"
                                    class="btn btn-save manage-btn" title="getCroppedCanvas">
                                    <span class="docs-tooltip">
                                        <span class="fa fa-check"></span>
                                        <br>
                                        <small i18n="@@OK">OK</small>
                                    </span>
                                </button>
                                <button (click)="cropperSetDragMode(profileImageCropper, 'move')" type="button"
                                    class="btn manage-btn default-btn" title="Move">
                                    <span class="fa fa-arrows"></span>
                                    <br>
                                    <small i18n="@@Move">Move</small>
                                </button>
                                <button (click)="cropperSetDragMode(profileImageCropper, 'crop')" type="button"
                                    class="btn manage-btn default-btn" title="Crop">
                                    <span class="fa fa-crop"></span>
                                    <br>
                                    <small i18n="@@Crop">Crop</small>
                                </button>
                                <button (click)="cropperDestroy(profileImageCropper, 'image')" type="button"
                                    class="btn  mutate-float-right manage-btn default-btn" title="Destroy">
                                    <span class="fa fa-close"></span>
                                    <br>
                                    <small i18n="@@Delete">Delete</small>
                                </button>

                                <input type="file" class="sr-only" #imageFileInput
                                    (change)="setCropper($event.srcElement,'image')" name="file" accept="image/*">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
