import {AfterContentChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {MyTicketsModalComponent} from '../my-tickets-modal/my-tickets-modal.component';
import {environment} from '../../../../environments/environment';
import { NFTSuccessEventData } from '../../../interfaces/my-tickets.interface';
import {NguCarousel, NguCarouselConfig} from '@ngu/carousel';
import {Router} from '@angular/router';
@Component({
  selector: 'app-nft-success-modal',
  templateUrl: './nft-success-modal.component.html',
  styleUrls: ['./nft-success-modal.component.css']
})
export class NftSuccessModalComponent implements OnInit, AfterContentChecked {
  showInfo: boolean;
  main_purchase_identifier: string; // for add to apple wallet button
  purchase_validation: number; // (2) approved, (1) pending
  images_base_url = environment.images_base_url;
  api_base_url = environment.api_base_url;
  party_emoji = '&#127881;';

  sliderData: any[] = [];
  @ViewChild('myCarousel') myCarousel: NguCarousel<any>;

  public carouselTile: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1, all: 0 },
    slide: 1,
    speed: 350,
    point: {
      visible: true
    },
    velocity: 0,
    interval: {
      timing: 5000,
      initialDelay: 1000
    },
    loop: true,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)',
    RTL: this.data.dir === 'rtl' ? true : false
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private dialog: MatDialog,
              private router: Router,
              private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.sliderData = this.data.tickets;
    this.main_purchase_identifier = this.data.main_purchase_identifier;
    this.purchase_validation = this.data.purchase_validation;
    console.log('NftSuccessModalComponent', this.data, this.sliderData);
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  openTicketsModal() {
    setTimeout(() => {
      this.dialog.closeAll();
      }, 500);
    this.router.navigate(['/my-tickets'], { replaceUrl: true });
  }

  switchTemplate() {
    // this.showInfo = true; // switch template in this component
    // this.dialog.closeAll();
  }

  setTicketSrc(ticket_image) {
    const eventDefaultImg = '/assets/images/nft-ticket.gif';

    if (ticket_image) {
      return ticket_image;
    }
    return eventDefaultImg
  }

}
