<div class="col-md-12 col-xs-12">
  <form (ngSubmit)="onSubmit()" #categoryForm="ngForm">
    <div class="modal-header">
      <button type="button" class="close" style="position: absolute; z-index: 1;" (click)="dialogRef.close()">
        <span aria-hidden="true">&times;</span>
      </button>
      <h4 i18n="modal title | edit user" class="text-center" mat-dialog-title>Category</h4>
    </div>
    <div class="modal-body" mat-dialog-content>
      <div class="row mt20">
        <div class="form-group col-md-12 col-xs-12"  *ngFor="let language of languages; index as i; first as isFirst">
          <label i18n="modal label | name" for="name" *ngIf="isFirst">Name</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="title">{{language.lang}}</span>
            </div>
            <input i18n-placeholder="placeholder | name" type="text" [name]="'name'+language.lang" class="form-control"
              [placeholder]="'Category name ' + language.lang" [(ngModel)]="categoryData.name[language.lang]" #name="ngModel" required>
          </div>
          <span i18n="@@Thisfieldisrequired" *ngIf="name.dirty && name.invalid" class="item-error">This field is required</span>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-12 col-xs-12">
          <label i18n="label | category status" for="status" class="col-form-label">Category status</label>
          <select id="status" name="status" class="form-control" [(ngModel)]="categoryData.status" #status="ngModel"
            required #slide_status>
            <option i18n="slide status filter" *ngFor="let status of status_codes"
              [selected]="status.code == categoryData.status" value="{{status.code}}">{
              status.code, plural,
              =1 {Active category}
              =0 {Inactive category}
              }</option>
          </select>
          <span i18n="@@Thisfieldisrequired" *ngIf="status.dirty && status.invalid" class="item-error">This field is required</span>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button i18n="@@Cancel" type="button" class="btn btn-default mutate-float-right mr10"
        (click)="dialogRef.close()">Cancel</button>
      <button i18n="@@Update" type="submit" class="btn btn-success mutate-float-left"
        [disabled]="categoryForm.invalid">Update</button>
    </div>
  </form>
</div>
