import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export function blockedEmailValidator(invalidEmailsError: any[] = []): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

    const value = control.value;
    // console.log('value', value, 'invalidEmailsError', invalidEmailsError);
    if (!value || !invalidEmailsError.length) {
      return null;
    }

    const emailBlocked = invalidEmailsError.includes(value);
    // console.log('emailBlocked', emailBlocked);
    return emailBlocked ? {emailBlocked:true}: null;
  }
}

