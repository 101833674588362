import { slideIn } from './../../animation';
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-error-handling',
  templateUrl: './error-handling.component.html',
  styleUrls: ['./error-handling.component.css'],
  animations: [slideIn]
})
export class ErrorHandlingComponent implements OnInit {

  @Input() formControl: UntypedFormControl;
  @Input() inputName: string;
  @Input() formSubmited: boolean;
  @Input() customClass: string;

  constructor() {}

  ngOnInit() {
  }

}
