import { environment } from './../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { ReportsService } from '../services/reports.service';
import {ActivatedRoute} from '@angular/router';
import { DialogService } from '../services/dialog.service';
import {GlobalsService} from '../globals';
import {AuthService} from "../services/auth.service";
import {RudderstackService} from "../services/rudderstack.service";


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
	api_base_url = environment.api_base_url;
	event;
	reportUser;
	reportTypes;
	selectedReportTypeCategory = -1;
	selectedReportType = -1;
	reportPeriodOption = 0;
	startDate;
	endDate;
	editMode;
	context;
  isAdmin = this.authService.isAdmin();
	constructor(private reportService: ReportsService,
				private activatedRoute: ActivatedRoute,
				private dialogService: DialogService,
        public globals: GlobalsService,
        public rudderstackService: RudderstackService,
        private authService: AuthService,
              ) {
	}

	ngOnInit() {
		this.activatedRoute.params.subscribe(params => {
			if(!params['event_id'] && !params['user_reports']) {
				this.context = 'system';
				this.event = null;
			}
			else if(params['event_id']) {
				this.context = 'event';
				this.event = params['event_id'];
			}
			else if(params['user_reports']) {
				this.context = 'user';
				this.reportUser = params['user_reports'];
				this.event = null;
			}

			this.reportTypes = this.reportService.getPossibleReports(this.context, this.event)
			.subscribe(reportTypes => this.reportTypes = reportTypes);
    	});
    this.rudderstackService.page('Event management','Reports');
	}

	initFields() {
		this.selectedReportType = -1;
		this.reportPeriodOption = 0;
		this.startDate = null;
		this.endDate = null;
		this.editMode = false;
	}

	areFieldsCorrect() {
		if(this.selectedReportTypeCategory == -1) {
			return false;
		} else if(this.reportTypes[this.selectedReportTypeCategory].reports.length > 0 && this.selectedReportType < 0) {
			return false;
		} else if(this.selectedReportType > -1 &&
				this.reportPeriodOption == this.reportService.REPORT_PERIOD_DATES &&
				(!this.startDate || !this.endDate)) {
			return false;
		}
		return true;
	}

	getReport() {
    this.globals.startLoader();
		this.reportService.getReportHash(this.event,
											this.reportTypes[this.selectedReportTypeCategory].value,
											this.selectedReportType,
											this.reportPeriodOption,
											this.startDate,
											this.endDate)
			.subscribe(res => {
        this.globals.stopLoader();
				if(res.hash) {
					window.location.href = this.api_base_url + '/api/export-report/' + res.hash
				}
				if(res.error) {
					this.dialogService.alertsModal('dashboardReports', res.error, 3000, false);
				}
			});
	}


}
