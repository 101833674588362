
import {throwError as observableThrowError, Observable, EMPTY} from 'rxjs';

import {catchError, tap, map, shareReplay} from 'rxjs/operators';
import { AppPurchaseRequest } from '../interfaces/app-purchase-request.interface';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from './auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalsService } from '../globals';
import { AppResponse } from '../interfaces/app-response.interface';
import { environment } from '../../environments/environment';
import moment from 'moment';
import { EventCacheService } from '../services-cache/event-cache.service';
import {AuthCacheService} from '../services-cache/auth-cache.service';

@Injectable()
export class EventService {
	api_base_url = environment.api_base_url;
  api_nft_base_url = environment.api_nft_base_url;
  // alchemy_url = environment.nft.alchemy_url;
	static readonly EVENT_STATUS_ACTIVE = 1;
	static readonly EVENT_STATUS_EDITING = 2;
	static readonly EVENT_STATUS_PENDING = 3;
	static readonly EVENT_STATUS_CLOSED = 4;
	static readonly EVENT_STATUS_DELETED = 5;
	static readonly EVENT_STATUS_ENDED = 6;
	static readonly EVENT_STATUS_REJECTED = 0;
	static readonly EVENT_STATUS_ALL = 10;

	base_url = window.location.protocol + '//' + window.location.host;
	options:Option[] = [];
	statuses:Number[] = [];
	localeId;
	lang;

	constructor(private http: HttpClient, private authService: AuthService, private authCacheService: AuthCacheService, private sanitizer:DomSanitizer, private globals : GlobalsService, private eventCacheService: EventCacheService){
		this.buildStatuses();
		this.localeId = this.globals.getLocaleId();
		const currentLangUrl = this.globals.getCurrentLangUrl();
		this.lang = (currentLangUrl) ? currentLangUrl+'/' : '';
	}

	buildStatuses() {
		this.statuses = [];

		this.statuses.push(
			EventService.EVENT_STATUS_ACTIVE
		);
		this.statuses.push(
			EventService.EVENT_STATUS_EDITING
		);
		this.statuses.push(
			EventService.EVENT_STATUS_PENDING
		);
		this.statuses.push(
			EventService.EVENT_STATUS_ENDED
		);
		this.statuses.push(
			EventService.EVENT_STATUS_CLOSED
		);
		this.statuses.push(
			EventService.EVENT_STATUS_DELETED
		);
		this.statuses.push(
			EventService.EVENT_STATUS_REJECTED
		);
		this.statuses.push(
			EventService.EVENT_STATUS_ALL
		);

	}

	/*
	* Function that returns all the posible event statuses
	* in order to filter results
	* @param currentStatus if given this status will be at the head of the list
	*
	* @returns Array containing all the statuses
	*/
	getPosibleStatuses(user: number, currentStatus?: number) {
		var selectedStatus;
		var allStatuses;

		this.buildStatuses();

		if((!currentStatus || currentStatus==1) && user != 0)  {
			return this.statuses;
		}

		allStatuses = this.statuses;

		//if no user given, don't give the option to get all events
		if(user == 0) {
			selectedStatus = allStatuses.findIndex(function(status) {
				return status === 10;
			});

			allStatuses.splice(selectedStatus, 1);
		}

		if(currentStatus) {
			selectedStatus = allStatuses.findIndex(function(status) {
				return status === currentStatus;
			});

			selectedStatus = allStatuses.splice(selectedStatus, 1);
			allStatuses.splice(0, 0, selectedStatus[0]);
		}

		return allStatuses;
	}

	getEvents(producer_id: any, last_id: any = null, latLng = {latitude: null, longitude: null}): Observable<any> {

    let events$ = this.eventCacheService.getValue({'eventsHash': 'eventsHash'});
    if (!events$) {
      events$ = this.http.post(this.api_base_url + '/api/events',
        {
          producer_id: producer_id, last_id: last_id, lat: latLng.latitude, lon: latLng.longitude,
          user_timezone_offset: moment(new Date().getTime()).format('ZZ')
        },
        {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})}).pipe(
        map(
          (response: any) => {
            const events = response.events;
            const categories = response.categories;
            const sliders = response.sliders;
            const associates = response.associateEvents;

            if (events) {
              for (const e of events) {
                const shareText = this.localeId === 'he' ? encodeURIComponent('אירוע מעניין, שווה בדיקה: ') : encodeURIComponent('Interesting event, check it out: ');
                if (producer_id) {
                  if (e.url_friendly) {
                    e.whatsapp_link = this.sanitizer.bypassSecurityTrustUrl('whatsapp://send?text=' + shareText + this.api_base_url + '/' + e.url_friendly + '?lang=' + this.localeId);
                  } else {
                    e.whatsapp_link = this.sanitizer.bypassSecurityTrustUrl('whatsapp://send?text=' + shareText + this.api_base_url + '/' + e.event_id + '?lang=' + this.localeId);
                  }
                } else {
                  if (e.url_friendly) {
                    e.whatsapp_link = this.sanitizer.bypassSecurityTrustUrl('whatsapp://send?text=' + shareText + this.api_base_url + '/' + this.localeId + '/' + e.url_friendly + '?seller_code=getin');
                    e.shareUrl = encodeURIComponent(this.api_base_url + '/' + this.localeId + '/' + e.url_friendly + '?seller_code=getin');
                  } else {
                    e.whatsapp_link = this.sanitizer.bypassSecurityTrustUrl('whatsapp://send?text=' + shareText + this.api_base_url + '/' + this.localeId + '/' + e.event_id + '?seller_code=getin');
                    e.shareUrl = encodeURIComponent(this.api_base_url + '/' + this.localeId + '/' + e.event_id + '?seller_code=getin');
                  }
                }
              }
            }

            const data = {
              events: events,
              categories: categories,
              sliders: sliders,
              associateEvents: associates
            };

            return data;
          }
        ),
        shareReplay(1)
      );
      this.eventCacheService.setValue(events$, {'eventsHash': 'eventsHash'});
    }

    return events$
	}

  getProducerEvents(producer_id: any, last_id: any = null, latLng = {latitude: null, longitude: null}): Observable<any> {
    let events$ = this.eventCacheService.getValue(producer_id);

    if (!events$) {
      events$ = this.http.post(this.api_base_url + '/api/producer-events',
        {
          producer_id: producer_id, last_id: last_id, lat: latLng.latitude, lon: latLng.longitude,
          user_timezone_offset: moment(new Date().getTime()).format('ZZ')
        },
        {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})}).pipe(
        map(
          (response: any) => {
            const events = response.events;
            const associates = response.associateEvents;

            if (events) {
              for (const e of events) {
                const shareText = this.localeId === 'he' ? encodeURIComponent('אירוע מעניין, שווה בדיקה: ') : encodeURIComponent('Interesting event, check it out: ');
                if (producer_id) {
                  if (e.url_friendly) {
                    e.whatsapp_link = this.sanitizer.bypassSecurityTrustUrl('whatsapp://send?text=' + shareText + this.api_base_url + '/' + e.url_friendly + '?lang=' + this.localeId);
                  } else {
                    e.whatsapp_link = this.sanitizer.bypassSecurityTrustUrl('whatsapp://send?text=' + shareText + this.api_base_url + '/' + e.event_id + '?lang=' + this.localeId);
                  }
                } else {
                  if (e.url_friendly) {
                    e.whatsapp_link = this.sanitizer.bypassSecurityTrustUrl('whatsapp://send?text=' + shareText + this.api_base_url + '/' + this.localeId + '/' + e.url_friendly + '?seller_code=getin');
                    e.shareUrl = encodeURIComponent(this.api_base_url + '/' + this.localeId + '/' + e.url_friendly + '?seller_code=getin');
                  } else {
                    e.whatsapp_link = this.sanitizer.bypassSecurityTrustUrl('whatsapp://send?text=' + shareText + this.api_base_url + '/' + this.localeId + '/' + e.event_id + '?seller_code=getin');
                    e.shareUrl = encodeURIComponent(this.api_base_url + '/' + this.localeId + '/' + e.event_id + '?seller_code=getin');
                  }
                }
              }
            }

            const data = {
              events: events,
              associateEvents: associates
            };

            return data;
          }
        ),
        shareReplay(1)
      );

      this.eventCacheService.setValue(events$, producer_id, 3600);
    }
      return events$
  }

	createNewEvent() {
    this.authService.isTokenExist();
		return this.http.get(this.api_base_url + '/api/createEvent',
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
	}

	deleteEvent(event_id: number) {
    this.authService.isTokenExist();
		return this.http.post(this.api_base_url + '/api/delete-event',
			{id: event_id},
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})}).pipe(
				map(
					(response: any) => {
						return response.status;
					}
				),tap(
					eventData => {
					}
				),);
	}

	closeEvent(event_id: number) {
    this.authService.isTokenExist();
		return this.http.post(this.api_base_url + '/api/update-event-status',
			{event_id: event_id, status: 4},
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})}).pipe(
				map(
					(response: any) => {
						var success = response.success;
						return {success: success}
					}
				),tap(
					eventData => {
						eventData.success;
					}
				),);
	}

	getDashboardStats(event_id: number, type: string, clearCache: boolean = false) {
    this.authService.isTokenExist();
    if (clearCache) {
      this.eventCacheService.clearCacheByHash({event_id: event_id, type: type}); // clear cache
    }

    let eventstat$ = this.eventCacheService.getValue({event_id, type});

    if (!eventstat$) {
      eventstat$ = this.http.get(this.api_base_url + '/api/eventstat/' + event_id + '/' + type,
        {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})}).pipe(
          shareReplay(1)
      );
      this.eventCacheService.setValue(eventstat$, {event_id, type}, 300);
    }

    return eventstat$
	}


	getStats(event_id: number) {
		return this.http.get(this.api_base_url + '/api/eventstat/' + event_id,
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
	}

	getEventGraphs(event_id: number, weeks: number, all_event: number) {
		return this.http.post(this.api_base_url + '/api/eventstatgraph',
			{event_id, weeks, full_event: all_event},
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
	}

	getSellerProducerInfo(event_id: number) {
    this.authService.isTokenExist();
		return this.http.get(this.api_base_url + '/api/producer-sellerinfo/'+event_id,
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
	}
  getSellerInfo(salesman_id: number, event_id: number) {
    this.authService.isTokenExist();
    return this.http.get(this.api_base_url + '/api/sellerinfo/'+salesman_id+'/'+event_id,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }

	updateCash(salesman_id: number, event_id: number, cash_amount: number) {
    this.authService.isTokenExist();
		return this.http.post(this.api_base_url + '/api/update-cash',
			{salesman_id, event_id, cash_amount},
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
	}

	getDashboard(filter: string, user_id = null, version = null) {
    this.authService.isTokenExist();
    const data = {filter: filter, user_timezone_offset: moment(new Date().getTime()).format('ZZ')};
    if (user_id) { data['user_id'] = user_id};
    if (version) { data['version'] = version};
    // let dashboard_events$ = this.eventCacheService.getValue(filter);
    let dashboard_events$ = null; // disable cache
    if (!dashboard_events$) {
      dashboard_events$ = this.http.post(this.api_base_url + '/api/dashboard',
        data,
        {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})}).pipe(
        map(
          (response: any) => {
            var user_name = response.user_name;
            var events = response.events;
            var seller_events = [];
            var manager_events = [];
            var head_seller_events = [];
            var total_events = 0;

            if (response.events) {
              for (let event of events) {

                total_events++;
                event.encodedShareUrl = (event.url_friendly) ? encodeURIComponent(this.api_base_url + '/' + event.url_friendly + '?lang=' + this.localeId) : encodeURIComponent(this.api_base_url + '/' + event.event_id + '?lang=' + this.localeId);

                event.whatsapp_link = this.sanitizer.bypassSecurityTrustUrl('whatsapp://send?text=' + event.encodedShareUrl);

              }
            }

            if (response.seller_events) {
              for (let event of response.seller_events) {

                total_events++;

                if (event.type == 1) {
                  seller_events.push(event);
                } else if (event.type == 2) {
                  head_seller_events.push(event);
                } else if (event.type == 3) {
                  manager_events.push(event);
                }

                event.encodedShareUrl = (event.url_friendly) ? encodeURIComponent(this.api_base_url + '/' + this.localeId + '/' + event.url_friendly + '?seller_code=' + event.code) : encodeURIComponent(this.api_base_url + '/' + this.localeId + '/' + event.event_id + '?seller_code=' + event.code);


                event.whatsapp_link = this.sanitizer.bypassSecurityTrustUrl('whatsapp://send?text=' + event.encodedShareUrl);
              }
            }
            if (response.error == 17.5) {setTimeout(() => { window.location.reload() }, 500);} // no token, refresh-token not valid

            return {
              user_name: user_name,
              events: events,
              seller_events: seller_events,
              manager_events: manager_events,
              head_seller_events: head_seller_events,
              total_events: total_events
            };
          }
        ),
        tap(
          eventData => {
            eventData.user_name,
              eventData.events,
              eventData.seller_events,
              eventData.manager_events,
              eventData.head_seller_events,
              eventData.total_events
          }
        ),
        shareReplay(1)
      );

      this.eventCacheService.setValue(dashboard_events$, filter, 300);
    }

    return dashboard_events$

	}

	// get gallery image for resize:
	getImage(event_id: number, type: number) {
		return this.http.get(this.api_base_url + '/api/get-image/'+ event_id +'/' + type,
			// {format: format_type},
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'image/png'}), responseType: 'arraybuffer'}).pipe(
				map(
					(response) => response['_body']
				),catchError((err) => observableThrowError(err.json())),);
	}

	getImageTest() {
		return this.http.get(this.api_base_url + '/api/test',
			{
				headers: new HttpHeaders({'Content-Type': 'image/*'}),
				responseType: 'blob',
        observe: 'response'}).pipe(
				map(
					(response) => {
        		return ({content: response, fileName: response.headers.get('content-filename')});
					}
				));
	}

	uploadImage(image: File){
		const body = JSON.stringify(image);
		const headers = new HttpHeaders({ 'Content-Type': 'image/jpeg' });

		return this.http.patch('/assets/images/uploads/', body, { headers: headers }).pipe(
			catchError((error: any) => {
			  return observableThrowError(error.body);
			}));
	}

	getSellers(event_id: number) {
    this.authService.isTokenExist();
		return this.http.post(this.api_base_url + '/api/eventsellers',
			{event_id: event_id},
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
	}

	getProducerSellers(event_id: number) {
		return this.http.post(this.api_base_url + '/api/producersellers',
			{ event_id },
			{ headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }) })
	}

	addSeller(event_id: number, email: string, sellersArray: string = null) {
    this.authService.isTokenExist();
		return this.http.post(this.api_base_url + '/api/add-seller',
		{event_id: event_id, email: email,sellersArray: sellersArray},
		{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
	}

	copySeller(event_id, fromEvent) {
    this.authService.isTokenExist();
		return this.http.post(this.api_base_url + '/api/add-sellers-by-event',
			{ fromEvent, event_id },
			{ headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }) })
	}

	sellersActions(event_id: number, action: string, event_seller: number, owner_id: number = undefined) {
    this.authService.isTokenExist();
		return this.http.post(this.api_base_url + '/api/sellers-actions',
			{event_id: event_id, action: action, event_seller: event_seller, owner_id: owner_id},
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
	}

	getSellerCoupons(event_id: number, salesman_id: number) {
    this.authService.isTokenExist();
		return this.http.post(this.api_base_url + '/api/seller-coupons',
			{event_id: event_id, salesman_id: salesman_id},
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
	}

	addUpdateCoupon(event_id: number, action: string, salesman_id: number, coupon_id: number, quantity: number) {
    this.authService.isTokenExist();
		return this.http.post(this.api_base_url + '/api/update-seller-coupons',
			{event_id: event_id,action: action, salesman_id: salesman_id, coupon_id: coupon_id, quantity: quantity},
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
	}

	getParticipants(event_id: string, filter: string, salesman_id: string = '', scope: number, filters: {}) {
    this.authService.isTokenExist();
    // let participants$ = this.eventCacheService.getValue({event_id, filter, salesman_id});
    let participants$ = null; // disable cache

    const not_cached_tabs = ['all', 'approved', 'pending', 'sent_for_approval', 'failed_approval'];
    if (not_cached_tabs.includes(filter)) {
      participants$ = null; // DO NOT Cache these tabs
    }

    if (!participants$) {
      participants$ = this.http.post(this.api_base_url + '/api/participants',
			{ event_id: event_id, filter: filter, filters: filters, salesman_id: salesman_id, scope: scope, user_timezone_offset: moment(new Date().getTime()).format('ZZ') },
			{ headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }) }).pipe(shareReplay(1));
      this.eventCacheService.setValue(participants$, {event_id, filter, salesman_id}, 1800);
      }
    return participants$
	}

	updateParticipantComment(event_id: number, payment_id: number, producer_comment: string) {
		return this.http.post(this.api_base_url + '/api/participants-update-comment',
			{event_id, payment_id, producer_comment},
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
	}

  getParticipantsTotalAmount(event_id: number, user_ids: any[]) {
    return this.http.post(this.api_base_url + '/api/participants-purchases',
      { event_id: event_id, user_ids: user_ids},
      { headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }) })
  }



	/*========================================
	=          CMS - DASHBOARD API's         =
	========================================*/

  getOrderedEventsByStatus(status) {
    return this.http
      .get(this.api_base_url + '/api/get-events/' + status + '/' + moment(new Date().getTime()).format('ZZ'), {
        headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
      }).pipe(
      map((response: any) => {
        let result = response.events;

        return { result: result };
      }),
      tap(eventData => {
        eventData.result;
      }),);
  }

	getChartData(scope) {
		return this.http.get(this.api_base_url + '/api/get-graph/' + scope, {
		headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
		});
	}

  getDashboardGraphEvent(scope, type, month = null) {
   // type 'incomes' or 'counts'
    let options = {};
    console.log('scope', scope, 'month', month);
    if(scope == '2' && type == 'incomes') {
      if(month) {
        options =  { params: new HttpParams().set('month', month) };
      } else {
        const d = new Date();
        options =  { params: new HttpParams().set('month', d.getMonth()+1) };
      }
    }
    return this.http.get(this.api_base_url + `/api/get-graph-event-${type}/` + scope, options);
  }

	setPrivacy(event_id) {
		return this.http.post(this.api_base_url + '/api/event/set-privacy', {event_id},
				{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
	}

	updateEventByStatus(event_id, status) {
		return this.http.post(this.api_base_url + '/api/updateEventStatus',
			{event_id: event_id, status: status, user_timezone_offset: moment(new Date().getTime()).format('ZZ')},
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
	}

	getEventsByUserAndByStatus(user_id: string, status: number){
		return this.http.post(this.api_base_url + '/api/users-events',
			{user_id: ((user_id)? user_id : 0), status: status},
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  	}

	addPurchase(data: any) {
		return this.http.post(this.api_base_url + '/api/boa-auth-response', data,
		{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
	}

	addStripeData(data: any) {
		return this.http.post(this.api_base_url + '/api/stripe-card-response', data,
		{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
	}

	getPaymentMethods(event_id, user_id) {
    let token = this.authService.getToken();
		const param = { event_id: event_id, user_id: user_id };
		return this.http.post(this.api_base_url + '/api/purchase/get-stripe-payments/methods', param,
			{headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest', 'authorization': `Bearer ${token}` }) });
	}

	getAttendeeBySearch(data: any) {
		return this.http.post(this.api_base_url + '/api/find-user-for-checkout', data,
		{ headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }) });
	}

	getEventDatalp(event: string, seller_code: string, coupon_code: string, isPreview: boolean = false) {
		const params = new HttpParams().set('user_timezone_offset', moment(new Date().getTime()).format('ZZ'));
		let url = this.api_base_url + '/api/landingpage/' + event;

		if(seller_code) {
			url += '?seller_code=' + seller_code;
		}
    if(coupon_code && seller_code) {
      url += '&coupon_code=' + coupon_code;
    } else if (coupon_code && !seller_code) {
      url += '?coupon_code=' + coupon_code;
    }
		if(isPreview) {

			url += (url.search(/\?/) != -1) ? ('&isPreview='+isPreview) : ('?isPreview='+isPreview);
		}

		return this.http.get(url,
			{params, headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})}).pipe(
				map(

				(response: AppResponse) => {
					var salesman_user = (response.data.salesman_user) ? response.data.salesman_user : false;
					var lp_salesman_id = (response.data.salesman_id) ? response.data.salesman_id : 0;
					var event_details = (response.data.event_details) ? response.data.event_details : '';
					var marketing = (response.data.marketing) ? response.data.marketing : '';
					var discounts = (response.data.discount) ? JSON.stringify(response.data.discount[0]) : 0;
					var tickets = (response.data.tickets) ? response.data.tickets : [];
					var categories = (response.data.categories) ? response.data.categories : [];
					var rooms = (response.data.rooms) ? response.data.rooms : [];
					var tables = (response.data.tables) ? response.data.tables : [];
					var tickets_reg = (response.data.tickets_reg) ? response.data.tickets_reg : [];
					var rooms_reg = (response.data.rooms_reg) ? response.data.rooms_reg : [];
					var tables_reg = (response.data.tables_reg) ? response.data.tables_reg : [];
					var media = (response.data.media) ? response.data.media : '';
					var coupons = (response.data.coupons) ? response.data.coupons : 0;
					var all_tickets = [];
					var event_fb_friends = (response.data.event_fb_friends) ? response.data.event_fb_friends : '';
					let payment_gateway_type =  (response.data.payment_gateway_type) ? response.data.payment_gateway_type : '';
					let custom_data =  (response.data.custom_data) ? response.data.custom_data : '';
					let payment_gateways = response.data.payment_gateways ? response.data.payment_gateways : [];
					let active_coupon = response.data.active_coupon ? response.data.active_coupon : '';
					let custom_inputs = response.data.custom_inputs ? response.data.custom_inputs : [];
          let event_categories = response.data.event_categories ? response.data.event_categories : '';
          let seats = response.data.seats ? response.data.seats : []; // seats.io
          let csrf_token = response.data.csrf_token ? response.data.csrf_token : ''; // seats.io
          let spike_protected_session = response.data.spike_protected_session ? response.data.spike_protected_session : false;
          let waiting_list_gateway_features = response.data.waiting_list_gateway_features ? response.data.waiting_list_gateway_features : false;

					if(tickets && tickets.length > 0) {
						for(let ticket of tickets) {
							ticket.type = 1;
							ticket.reg = 0;
							ticket.price_with_commission = this.calcItemPriceWithCommission(ticket.price, event_details[0].purchaser_commission);
							all_tickets.push(ticket);
						}
					}
					if(rooms && rooms.length > 0) {
						for(let room of rooms) {
							room.type = 2;
							room.reg = 0;
							room.price_with_commission = this.calcItemPriceWithCommission(room.price, event_details[0].purchaser_commission);
							all_tickets.push(room);
						}
					}
					if(rooms_reg && rooms_reg.length > 0) {
						for(let room of rooms_reg) {
							room.type = 1;
							room.reg = 1;
							room.price_with_commission = this.calcItemPriceWithCommission(room.price, event_details[0].purchaser_commission);
							all_tickets.push(room);
							rooms.push(room);
						}
					}
					if(tables && tables.length > 0) {
						for(let table of tables) {
							table.type = 3;
							table.reg = 0;
							table.price_with_commission = this.calcItemPriceWithCommission(table.price, event_details[0].purchaser_commission);
							all_tickets.push(table);
						}
					}
					if(tables_reg && tables_reg.length > 0) {
						for(let table of tables_reg) {
							table.type = 1;
							table.reg = 1;
							table.price_with_commission = this.calcItemPriceWithCommission(table.price, event_details[0].purchaser_commission);
							all_tickets.push(table);
							tables.push(table);
						}
					}
					if(tickets_reg && tickets_reg.length > 0) {
						for(let ticket of tickets_reg) {
							ticket.type = 1;
							ticket.reg = 1;
							ticket.price_with_commission = this.calcItemPriceWithCommission(ticket.price, event_details[0].purchaser_commission);
							all_tickets.push(ticket);
							tickets.push(ticket);

						}
					}

					if(!event_fb_friends) {
						event_fb_friends = [{'name':'Eran Azar','id':'10155763367906052'},{'name':'Ofer Peer','id':'10211567815004100'},{'name':'Yotam Alaluf','id':'10214133072064845'},{'name':'Niv Ariel','id':'1923414897675557'}];
					}

					return {event_categories: event_categories,
            payment_gateways: payment_gateways,
            payment_gateway_type: payment_gateway_type,
            lp_salesman_id: lp_salesman_id,
            salesman_user: salesman_user,
            event_details: event_details,
            marketing: marketing,
            discounts: discounts,
            all_tickets: all_tickets,
            tickets: tickets,
            rooms: rooms,
            tables: tables,
            tickets_reg: tickets_reg,
            rooms_reg: rooms_reg,
            tables_reg: tables_reg,
            media: media,
            coupons: coupons,
            event_fb_friends: event_fb_friends,
            categories: categories,
            custom_data: custom_data,
            active_coupon: active_coupon,
            custom_inputs: custom_inputs,
            seats: seats, // seats.io,
            csrf_token: csrf_token,
            spike_protected_session: spike_protected_session,
            waiting_list_gateway_features: waiting_list_gateway_features
					};
				},
				error => {return error;}

			),tap(

					eventData => {
						eventData.payment_gateways,
						eventData.payment_gateway_type,
						eventData.lp_salesman_id,
						eventData.salesman_user,
						eventData.event_details,
						eventData.marketing,
						eventData.rooms,
						eventData.tables,
						eventData.media,
						eventData.coupons,
						eventData.discounts,
						eventData.all_tickets,
						eventData.event_fb_friends,
						eventData.categories,
            eventData.custom_data,
            eventData.active_coupon,
            eventData.custom_inputs,
            eventData.event_categories
					}

				),);
	}

	calcItemPriceWithCommission(ticket_price, purchaser_commission){
		//Getting the commision in %
		let commision = (purchaser_commission > 0) ? purchaser_commission / 100 : 1;
		let item_price_with_commission: string;

		if(commision != 1){
			// Price with commission
			item_price_with_commission = (parseFloat(ticket_price)*((commision * 1.17)+1)).toFixed(2);
		} else {
			// Price with commission (No change here)
			item_price_with_commission = ticket_price;
		}
		// prevent total price with tax from being a negative number:
		if(parseFloat(item_price_with_commission) < 0) {
			item_price_with_commission = '0.00';
		}

		return item_price_with_commission;

	}


	getEventDatalpPreview(user_id: number, event_id: string) {

		return this.http.get(this.api_base_url + '/api/preview/' + event_id,
			// {format: format_type},
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})}).pipe(
				map(

				(response: any) => {
					let success = (response.success) ? response.success : '';
					let event_details = (response.event_details) ? response.event_details : '';
					let media = (response.media) ? response.media : '';
					return {success: success, event_details: event_details,  media: media };
				}

			),tap(

					eventData => {
						eventData.success,
						eventData.event_details,
						eventData.media

					}

				),);
	}

	getCheckoutDetails(event_id: number, salesman_id:number = null) {
    this.authService.isTokenExist();
		return this.http.post(this.api_base_url + '/api/checkout',
			{event_id: event_id, salesman_id: salesman_id},
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
	}

	getReaderData(reader_id: number) {
		return this.http.get(this.api_base_url + '/api/get-reader-data/'+reader_id,
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest', 'x-api-key': this.authService.getReaderApiKey()})});
	}

	saveOrderedEvents(user_id: string, ordered_events: any){
		return this.http.post(this.api_base_url + '/api/?',
			{user_id: ((user_id)? user_id : 0), ordered_events: ordered_events},
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})}).pipe(
				map(
				(response: any) => {
					let events = response;
					return {events:events};
				}
			),tap(
				affiliateData => {
					affiliateData.events
				}
			),);
	}

	getBarcodeData(hash: string){
		return this.http.get(this.api_base_url + '/api/tickets/' + hash,
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
	}

	getReport() {
		return this.http.get(this.api_base_url + '/api/reports/3265/1/2',
			// {format: format_type},
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/x-www-form-urlencoded'}), responseType: 'arraybuffer'}).pipe(
				map(
					(response) => response['_body']
				),catchError((err) => observableThrowError(err.json())),);
	}

	getPaymentFormData(event_id: number){
		return this.http.post(this.api_base_url + '/api/cms-payment-form-data',
		{event_id},
		{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
	}


	couponCheckout(event_id, search_key, ticket_ids: any = []){
    let token = this.authService.getToken();
    const data = {
      event_id: event_id,
      search_key: search_key,
      ticket_ids: ticket_ids
    }
		return this.http.post(this.api_base_url + '/api/checkDiscount', data,
		{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest', 'authorization': `Bearer ${token}`})})
	}

	purchase(data: AppPurchaseRequest){
		return this.http.post(this.api_base_url + '/api/purchase',
		data,
		{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
	}

	checkoutPurchase(data: AppPurchaseRequest){
		return this.http.post(this.api_base_url + '/api/checkoutPurchase',
		data,
		{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
	}

	participantAction(action: string, payment_id: number, event_id: number, approveNoItems: boolean = false,
                    first_name: string = '', last_name: string = '', phone: string = '', email: string = '',
                    identification_number: string = '', phoneCountryCode: string = '', dispute_id: string = '', comment: string = ''){
    this.eventCacheService.clearCache();
    let data = {};
    if(action == 'to_dispute' || action == 'edit_dispute' || action == 'return_from_dispute' ) {
      data = {action, dispute_id, comment, payment_id,event_id, user_timezone_offset: moment(new Date().getTime()).format('ZZ')};
    } else {
      data = {action, payment_id, event_id, first_name, last_name, phone, email,
        identification_number, approveNoItems, phoneCountryCode,
        user_timezone_offset: moment(new Date().getTime()).format('ZZ')};
    }
		return this.http.post(this.api_base_url + '/api/participants-actions',
      data,
		{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
	}

	updateAgentStatus(action: string,event_id: number, salesman_id: number, event_seller: number){
		return this.http.post(this.api_base_url + '/api/update-agt-status',
		{action: action,event_id: event_id, salesman_id: salesman_id, event_seller: event_seller},
		{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
	}

	getEventInfo(event_id: number ,salesman_id: number, type: number, event_seller: number){
		return this.http.post(this.api_base_url + '/api/eventinfo',
		{event_id, salesman_id, type, event_seller},
		{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
	}

	removeEvent(event_id: number){
    this.eventCacheService.clearCache();
		return this.http.post(this.api_base_url + '/api/delete-event',
		{event_id},
		{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
	}

	getById(event_id: number) {
		return this.http.get(this.api_base_url + '/api/event/' + event_id, {
		headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
		});
	}

	approveEvent(data) {
    this.eventCacheService.clearCache();
		return this.http.post(this.api_base_url + '/api/approve-event' , data, {
		headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
		});
	}
	updateCommission(data) {
		return this.http.post(this.api_base_url + '/api/update-commissions' , data, {
		headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
		});
	}

	setEventInputs(data) {
		return this.http.post(this.api_base_url + '/api/cms-set-form-inputs' , data, {
		headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
		});
	}

	validateFriendlyUrl(data) {
		return this.http.post(this.api_base_url + '/api/validate-friendly-url',data, {
			headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
		});
	}

	delBankAccont(eventId: number, type: string, id) {
		return this.http.post(this.api_base_url + '/api/event/withdraw/' + eventId + '/delete-bank/' + id, {
			headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
		});
	}

	setEventDraft(eventId: number, type: string, data) {
		return this.http.post(this.api_base_url + '/api/event-draft/' + eventId + '/' + type, data, {
			headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
		});
	}

	getEventDraft(eventId: number, type: string) {
		return this.http.get(this.api_base_url + '/api/event-draft/' + eventId + '/' + type,  {
			headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
		});
	}

	getEventData(eventId: number, type: string) {
    this.authService.isTokenExist();
		return this.http.get(this.api_base_url + '/api/event-data/' + eventId + '/' + type,  {
			headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
		});
	}

	getCouponsData(eventId: number, type: string, start: number, end: number) {
    this.authService.isTokenExist();
		return this.http.get(this.api_base_url + '/api/discount-data/' + eventId + '/' + type + '/' + start + '/' + end, {
			headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
		});
	}

	getTimeZones() {
		return this.http.get(environment.api_base_url + '/api/get-time-zones',
			{ headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }) });
	}

	setEventData(eventId: number, type: string, data) {
    this.eventCacheService.clearCache();
    this.authService.isTokenExist();
    return this.http.post(this.api_base_url + '/api/event-data/' + eventId + '/' + type, data, {
			headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
		});
	}

	saveImages(data,event_id, media_type){
		return this.http.post(this.api_base_url + `/api/save-gallery`,data, {
			params: new HttpParams().set('event_id',event_id).set('media_type',media_type),
		});
	}

	removeImage(data){
		return this.http.post(this.api_base_url + `/api/remove-image`,data, {
			headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
		});
	}

  removeCategory(eventId, categoryId) {
    return this.http.delete(`${this.api_base_url}/api/remove-ticket-category/${eventId}/${categoryId}`, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

	removeItem(data){
		return this.http.post(this.api_base_url + `/api/remove-item`,data, {
			headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
		});
	}

  checkCouponUniqueness(data){
		return this.http.post(this.api_base_url + `/api/coupon-code-uniqueness`, data, {
			headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
		});
	}
  checkDiscountUniqueness(data){
    return this.http.post(this.api_base_url + `/api/discount-code-uniqueness`, data, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

	setColorBackground(data){
		return this.http.post(this.api_base_url + '/api/save-gallery',data, {
			headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
		});
	}

	getAllSellers(event_id: number) {
    this.authService.isTokenExist();
		return this.http.post<AppResponse>(this.api_base_url + '/api/geteventsellers',
			{event_id},
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
	}

	setAllSellers(data) {
		return this.http.post<AppResponse>(this.api_base_url + '/api/seteventsellers',
			data,
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
	}

	getBankDetailsByEventId(event_id) {
		return this.http.get(this.api_base_url + '/api/event/bank-details/' + event_id, {
			headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
			});
	}

	getRecentEvents(user_id, event_id) {
    this.authService.isTokenExist();
    return this.http.post(this.api_base_url + `/api/participants/recent_events/${user_id}`,
      {event_id},
      {headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })});
  }

  getMyTicketsEvents(status: string = '1') {
    this.authService.isTokenExist();
    let my_tickets$ = this.eventCacheService.getValue({'my_ticketsHash': status});

    if (!my_tickets$) {
      my_tickets$ = this.http.get(this.api_base_url + '/api/user-tickets?status=' + status, {
        headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      }).pipe(shareReplay(1));
      this.eventCacheService.setValue(my_tickets$, {'my_ticketsHash': status}, 5);
    }
    return my_tickets$
  }

  downloadMyTickets(event_id, purchase_user_id) {
    return this.http.get(this.api_base_url + '/api/user-tickets/event/' + event_id + '/' + purchase_user_id + '/download', {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/pdf' } ),
      responseType: 'blob',
      observe: 'events',
      reportProgress: true
    });
  }

  sendRefundRequest(order_number: number = 0) {
    this.authService.isTokenExist();
		return this.http.post(this.api_base_url + '/api/user-tickets/refund/request',
			{
			  purchase_user_id: order_number
			},
		{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
	}

	changeRefundRequestStatus(request_id, event_id, status_id) {
    return this.http.patch(this.api_base_url + '/api/user-tickets/refund/request/' + request_id, 			{
			  event_id: event_id,
        status: status_id // ACTIVE_STATUS = 1, DECLINE_STATUS = 2, CANCELLED_STATUS = 3, SUCCESS_STATUS = 4;
			}, { headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'}) });
  }


	declineRefundRequest(id: number = 0) {
    this.authService.isTokenExist();
		return this.http.delete(this.api_base_url + '/api/user-tickets/refund/request/' + id,
		{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
	}

  getUserTickets(event_id, purchase_user_id) {
    this.authService.isTokenExist();
    return this.http.get(this.api_base_url + `/api/user-tickets/event/${event_id}/${purchase_user_id}`, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  getCustomInputs(eventId: number) {
    this.authService.isTokenExist();
    return this.http.get(this.api_base_url + '/api/custom-inputs/' + eventId,  {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  setCustomInputs(eventId: number, data) {
    this.authService.isTokenExist();
    return this.http.post(this.api_base_url + '/api/custom-inputs/' + eventId, data, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  getEventPurchases(event_id) {
    this.authService.isTokenExist();
    return this.http.get(this.api_base_url + '/api/get-event-purchases/' + event_id, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  setRefundEventPurchases(event_id, all: boolean, purchase_ids = null) {
    this.authService.isTokenExist();
    const body = {
      all: all // true - refund ALL, false - refund selected purchase_ids
    }
    if (!all && purchase_ids.length) { body['purchase_ids'] = purchase_ids }
		return this.http.post(this.api_base_url + '/api/refund-event-purchases/'  + event_id,
			body,
		{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
	}

  cancelEventPurchases(event_id) {
    this.authService.isTokenExist();
    return this.http.post(this.api_base_url + `/api/refund-event-purchases/${event_id}/cancel`,
			{},
		{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
  }

  cancelPurchase(main_purchase_identifier: any) {
    this.authService.isTokenExist();
    return this.http.post(this.api_base_url + '/api/cancel-purchase', {
        main_purchase_identifier: main_purchase_identifier
      },
      { headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }) });
  }

  getTicketImages(event_id) {
    return this.http.get(this.api_base_url + `/api/event/${event_id}/nft-tickets/draft`, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  // Ticket with ID
  uploadTicketImage(data, event_id, ticket_id) {
    this.authService.isTokenExist();
    return this.http.post(this.api_base_url + `/api/event/${event_id}/nft-tickets/${ticket_id}/images`, data, {});
  }

  // New ticket without ID
  uploadTicketImageNoId(data, event_id) {
    this.authService.isTokenExist();
    return this.http.post(this.api_base_url + `/api/event/${event_id}/nft-tickets/draft`, data, {});
  }

  // Delete ticket Image
  deleteTicketImageById(event_id, id) {
    this.authService.isTokenExist();
    return this.http.delete(this.api_base_url + `/api/event/${event_id}/nft-tickets/images/${id}`, {});
  }

  // SEND TICKETS request
  getUserVipTickets(event_id) {
    this.authService.isTokenExist();
    return this.http.get(this.api_base_url + `/api/user-vip-purchase/tickets/${event_id}`, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  // Get History VIP purchase
  getUserVipTicketsHistory(event_id) {
    this.authService.isTokenExist();
    return this.http.get(this.api_base_url + `/api/user-vip-purchase/records/${event_id}`, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  uploadUserVipFile(file, event_id) {
    this.authService.isTokenExist();
    let formParams = new FormData();
    formParams.append('file', file)
    console.log('uploadUserVipFile', file, formParams)
    return this.http.post(this.api_base_url + `/api/user-vip-purchase/upload/${event_id}`, formParams, {});
  }

  uploadUserVipFileSuccess(data, event_id) {
    this.authService.isTokenExist();
    return this.http.post(this.api_base_url + `/api/user-vip-purchase/save/${event_id}`, data, {});
  }
  // SEND TICKETS requests END

  getTicketsForPurchase(event_id) {
    this.authService.isTokenExist();
    return this.http.get(this.api_base_url + `/api/get-tickets-for-purchase/${event_id}`, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    })
  }

  hyperswitchResponse(data) {
    this.authService.isTokenExist();
    return this.http.post(this.api_base_url + `/api/hyperswitch/response`, data, {});
  }

  stripePaymentResponse(data, flow: 'separate_payments'| 'future_payments') {
    let stripe_url = 'stripe-payment-response';
    if (flow == 'future_payments') {
      stripe_url = 'stripe-future-payment-response';
    }
    this.authService.isTokenExist();
    return this.http.post(this.api_base_url + `/api/${stripe_url}`, data, {});
  }

  eventStripeAccountCheck(hash) {
    this.authService.isTokenExist();
    return this.http.post(this.api_base_url + `/api/event-stripe-account/check/${hash}`,{}, {});
  }

  // Seats IO Book
  eventSeatsBook(event_id, objects= [], holdToken = null) {
    this.authService.isTokenExist();
    const body = {
      event_id: event_id,
      objects: objects,
      holdToken: holdToken
    }
    return this.http.post(this.api_base_url + '/api/event-seats/book',
      body,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
  }
  // Seats IO Unbook
  eventSeatsUnBook(hash, event_id, objects, holdToken ) {
    this.authService.isTokenExist();
    const body = {
      event_id: event_id,
      objects: objects,
      holdToken: holdToken
    }
    console.log('body', body);
    return this.http.post(this.api_base_url + '/api/event-seats/unbook/' + hash,
      body,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
  }

  // Seats IO Best available seats
  eventSeatsBestAvailable(event_id, number, categories = [], holdToken ) {
    const body = {
      event_id: event_id,
      number: number,
      categories: categories,
      holdToken: holdToken,
    }
    return this.http.post(this.api_base_url + '/api/event-seats/bestAvailable',
      body,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
  }
  // Seats IO Get config
  getTicketCategories(event_id) {
    this.authService.isTokenExist();
    return this.http.get(this.api_base_url + `/api/event-seats/${event_id}/tickets-categories`, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    })
  }

  setTicketCategories(eventId: number, data) {
    this.authService.isTokenExist();
    return this.http.post(this.api_base_url + `/api/event-seats/${eventId}/tickets-categories`, data, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  sendProducerEmail(event_id, data) {
    this.authService.isTokenExist();
    console.log('sendUserQuestion', data);
    return this.http.post(this.api_base_url + '/api/event/user-question/'  + event_id,
      data,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
  }

  getStripeAccount(event_id) {
    this.authService.isTokenExist();
    return this.http.post(this.api_base_url + `/api/event-stripe-account/get-stripe-url/${event_id}`,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
  }

  checkOwnerAndAvailable(seats_hash) {
    return this.http.post(this.api_base_url + `/api/event-seats/checkOwnerAndAvailable/${seats_hash}`,
      { headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }) });
  }
  checkAvailablePurchase(main_purchase_identifier) {
    return this.http.post(this.api_base_url + `/api/check-available-purchase`, {main_purchase_identifier},
      { headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }) });
  }
  // Fans-tickets
  sendTicketSecondHand(purchase_user_id, price) {
    this.authService.isTokenExist();
    let data = {
      purchase_user_id,
      price
    }
    console.log('sendTicketSecondHand', data);
    return this.http.post(this.api_base_url + '/api/ticket-second-hand/sale',
      data,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
  }
 // Fans-tickets
  getTicketSecondHand(event_id) {
    this.authService.isTokenExist(false);
    return this.http.get(this.api_base_url + `/api/event-second-hand/${event_id}/info`, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    })
  }

  eventViews(csrf_token) {
    if (csrf_token.length) {
      this.http.post(this.api_base_url + `/api/event-views`, {csrf_token}, {}).subscribe(()=>{});
    }
  }
  eventCheckCanPurchase(event_id, purchase_passcode) {
    console.log('eventCheckCanPurchase', event_id, purchase_passcode);
    return this.http.post(this.api_base_url + '/api/check-can-purchase',
      {event_id, purchase_passcode},
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})})
  }


  userRedirectMobileApp(token) {
    return this.http.post(this.api_base_url + `/api/user-redirect-mobile-app/check`, {
      "token": token
    }, {});
  }

  plaidTokensLink(country_code = '') {
    let data = {country_code};
    let token = this.authService.getToken();
    return this.http.post(this.api_base_url + '/api/plaid/tokens/link', data,
      {headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest', 'authorization': `Bearer ${token}` }) });
  }

  plaidTokensExchange(public_token = null) {
    let data = {};
    const user_plaid_id = localStorage.getItem('user_plaid_id');
    const link_token = localStorage.getItem('link_token');
    if(public_token) {
      data['public_token'] = public_token;
    }
    if(link_token) {
      data['link_token'] = JSON.parse(link_token);
    }
    console.log('user_plaid_id', user_plaid_id, 'link_token', link_token, 'public_token', public_token );
    if(!link_token || !public_token || user_plaid_id == undefined) {
      return observableThrowError('No parameters');
    }

    let token = this.authService.getToken();
    return this.http.post(this.api_base_url + `/api/plaid/tokens/${user_plaid_id}/exchange-public`, data,
      {headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest', 'authorization': `Bearer ${token}` }) });
  }

  plaidTokensLinkRedirrect(user_plaid_id = null) {

    let token = this.authService.getToken();
    return this.http.post(this.api_base_url + `/api/plaid/tokens/${user_plaid_id}/get-link-token`, {},
      {headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest', 'authorization': `Bearer ${token}` }) });
  }

  plaidGetBankInfo() {
      const user_plaid_id = localStorage.getItem('user_plaid_id');
      return this.http.post(this.api_base_url + `/api/plaid/users/${user_plaid_id}/save_bank`, {
        headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
      })
  }

  plaidDelete(user_plaid_id = null) {
    let data = {};
    if(!user_plaid_id) {
      return
    }
    let token = this.authService.getToken();
    return this.http.post(this.api_base_url + `/api/plaid/users/${user_plaid_id}/disconnect`, data,
      {headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest', 'authorization': `Bearer ${token}` }) });
  }

  // spike_protected_session can be true or false
  setSpikeToStorage(spike_protected_session: boolean = false) {
    if (spike_protected_session) {
      sessionStorage.setItem('spike_protection_session', 'true')
    } else {
      sessionStorage.removeItem('spike_protection_session');
    }
  }


  guestManagerSaveDeviceName(event_id = 0, device_id = null, name: string = '') {
    return this.http.post(this.api_base_url + `/api/event-guest-manager/${event_id}/save-device-name/`, {device_id: device_id, name: name}, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  transferTickets(purchase_user_id: number, data = {}) {
    // data = {
    //   main_purchase_identifier: "",
    //   email: "",
    //   phone: "",
    //   phone_country_code: "",
    // }
    this.authService.isTokenExist();
    return this.http.post(this.api_base_url + '/api/user-tickets/transfer/' + purchase_user_id, data, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  leaveWaitingList(purchase_user_id: number, pui_hash = '') {
    this.authService.isTokenExist();
    return this.http.post(this.api_base_url + '/api/user-tickets/leave-waiting-list/' + purchase_user_id, {main_purchase_identifier: pui_hash}, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  approveWaitingList(event_id, ticket_id, count) {
    this.authService.isTokenExist();
    return this.http.post(this.api_base_url + `/api/event-waiting-list/${event_id}/mass-approve`, {ticket_id: ticket_id, count: count}, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  addLiveCart(event_id = 0, tickets = []) {
    return this.http.post(this.api_base_url + `/api/live-cart/${event_id}`, {tickets: tickets}, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  updateLiveCart(event_id, tickets = [], live_cart_hash = '') {
    return this.http.patch(this.api_base_url + `/api/live-cart/${event_id}/${live_cart_hash}`, {tickets: tickets}, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  deleteLiveCart(event_id, live_cart_hash) {
    return this.http.delete(`${this.api_base_url}/api/live-cart/${event_id}/${live_cart_hash}`, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  getSalesmanAllPermissions(type) {
    return this.http.get(this.api_base_url + `/api/event-salesmans-permissions/${type}`, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    })
  }

  getSalesmanPermission(event_id, event_seller) {
    return this.http.get(this.api_base_url + `/api/event-salesmans/${event_id}/permissions/${event_seller}`, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    })
  }

  setSalesmanPermission(event_id, event_seller, permissions: number[] = []) {
    return this.http.post(this.api_base_url + `/api/event-salesmans/${event_id}/permissions/${event_seller}`, {permissions: permissions}, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  // uploadImgixImage(file, origin_path) {
  //   return this.http.post(`https://api.imgix.com/api/v1/sources/${environment.imgix.source_id}/upload/${origin_path}`, file, {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/vnd.api+json' ,
  //       'Authorization': `Bearer ${environment.imgix.api_token}`
  //     })
  //   });
  // }

  getLineUpArtists(event_id) {
    return this.http.get(this.api_base_url + `/api/event-artists/${event_id}`, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    })
  }

}
interface Option {
  value: string;
  title: string;
}
