import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class FirebaseMessagingService {
  api_base_url = environment.api_base_url;

  constructor(
    private http: HttpClient,
    private angularFireMessaging: AngularFireMessaging) {
    if (!window['Android'] && !/^((?!chrome|android).)*safari/i.test(navigator.userAgent) && !((!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) && !/CriOS/i.test(navigator.userAgent))) {
      this.angularFireMessaging.messages
        .subscribe(
          (_messaging: any) => {
            _messaging.onMessage = _messaging.onMessage.bind(_messaging);
            _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
          }
        )
    }
  }

  requestPermission(currentFirebaseSenderId) {
    if ( localStorage && localStorage.getItem('currentFirebaseSenderId') && currentFirebaseSenderId !== localStorage.getItem('currentFirebaseSenderId') ) {
      this.deleteFireTokenBack()
    } else {
      this.setToken();
    }
    localStorage.setItem('currentFirebaseSenderId', currentFirebaseSenderId);
  }

  deleteFireTokenBack() {
    this.angularFireMessaging.getToken
      .subscribe(oldToken => this.setToken(oldToken))
  }

  setToken(oldToken?) {
    const storageToken = localStorage ? localStorage.getItem('lastFireToken') : '';
    this.angularFireMessaging.requestToken.subscribe(
      (newToken) => {
        localStorage.setItem('lastFireToken', newToken);
        this.sendTokenToBackend(newToken, oldToken ? oldToken : storageToken && storageToken !== newToken ? storageToken : null)
          .subscribe(response => {
          }, error => {

          });
      },
      (err) => {
        //console.error('Unable to get permission to notify.', err);
      }
    );
  }

  getDeviceType() {
    //const ua = navigator.userAgent;
    let deviceType = 'web';
    // if (ua.match(/Android/i)) {
    //   deviceType = 'android'
    // }
    // if (ua.match(/iPhone|iPad|iPod/i)) {
    //   deviceType = 'ios'
    // }
    return deviceType;
  }

  sendTokenToBackend(token, oldToken) {
    return this.http.post(this.api_base_url + '/api/user-notification/firebase/' + this.getDeviceType() + '/save',
      {'token': token, 'public_key': environment.firebase.messagingSenderId, 'old_token': oldToken},
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }
}
