import { Component, OnInit } from '@angular/core';
import { NavbarService } from '../services/navbar.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {
  radioBox: any = [true];

  constructor(private navbar: NavbarService) {}

  ngOnInit() {
    this.navbar.setIsNewUi(true);
    setTimeout(() => {this.navbar.noPadding = 'removePadding';}, 200);
    this.navbar.hide();
  }

  radioHandler(eValue, eTarget: any, index) {
    const radioValue = eValue.value;
    const isChecked = eTarget.checked;
    this.radioBox.forEach((element, i) => {
      this.radioBox[i] = !isChecked;
    });
    this.radioBox[index] = isChecked;
    console.log('radioHandler', isChecked, index, this.radioBox);
    // Giving information for parent component to validate the checkboxes
    // this.radio.emit({ counter: counter, radioName: radioName, radioValue: radioValue, isChecked: isChecked });
  }

}
