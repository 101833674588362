<div class="sidenav-categories" [ngClass]="{'rtl' : dir === 'rtl'}">
  <div class="sidenav-cat">
    <p class="cat-name">Categories</p>
    <ul class="sidenav-menu" #cats_scroll>
      <li class="sidenav-menu-item" [ngClass]="{active: active === '' || active == null}">
        <a [routerLink]="['/']" class="sidenav-menu-link" attr.aria-label="home_page">
<!--          <img src="/assets/images/svg-icons/cat-airplane.svg">-->
          <span t key="homepage.filters.Discover" default="Discover">Discover</span>
        </a>
      </li>
      <li class="sidenav-menu-item" [ngClass]="{active: active === 'all'}">
        <a [routerLink]="['/categories', 'all']" class="sidenav-menu-link" attr.aria-label="category_all">
          <img src="/assets/images/svg-icons/cat-book.svg" alt="">
          <span t key="homepage.filters.category_all" default="All Events">All Events</span>
        </a>
      </li>
      <li class="sidenav-menu-item" [ngClass]="{active: active === 'featured'}">
        <a [routerLink]="['/categories', 'featured']" class="sidenav-menu-link" attr.aria-label="category_featured">
          <img src="/assets/images/svg-icons/cat-box-2.svg" alt="">
          <span t key="homepage.filters.Featured" default="Featured">Featured</span>
        </a>
      </li>
      <li class="sidenav-menu-item" [ngClass]="{active: active === 'trending'}">
        <a [routerLink]="['/categories', 'trending']" class="sidenav-menu-link" attr.aria-label="category_trending">
          <img src="/assets/images/svg-icons/cat-airplane.svg" alt="">
          <span t key="homepage.filters.Trending" default="Trending">Trending</span>
        </a>
      </li>
      <li class="sidenav-menu-item" [ngClass]="{active: active === 'free'}">
        <a [routerLink]="['/categories', 'free']" class="sidenav-menu-link" attr.aria-label="category_free">
          <img src="/assets/images/svg-icons/cat-trade.svg" alt="">
          <span t key="homepage.filters.Free" default="Free">Free</span>
        </a>
      </li>
      <li class="sidenav-menu-item" [ngClass]="{active: active === 'followed'}">
        <a [routerLink]="['/categories/followed']" class="sidenav-menu-link" attr.aria-label="category_followed">
          <img src="/assets/images/svg-icons/cat-bag.svg" alt="">
          <span t key="homepage.filters.Followed" default="Followed">Followed</span>
        </a>
      </li>
      <li class="sidenav-menu-item" [ngClass]="{active: active === 'week'}">
        <a [routerLink]="['/categories', 'week']" class="sidenav-menu-link" attr.aria-label="category_week">
          <img src="/assets/images/svg-icons/cat-audio-square.svg" alt="">
          <span t key="homepage.filters.ThisWeek" default="This Week">This Week</span>
        </a>
      </li>
<!--      <li class="sidenav-menu-item">-->
<!--        <img src="/assets/images/svg-icons/cat-trade.svg">-->
<!--        <a [routerLink]="['/']" class="sidenav-menu-link">-->
<!--          Clubbing-->
<!--        </a>-->
<!--      </li>-->
<!--      <li class="sidenav-menu-item">-->
<!--        <img src="/assets/images/svg-icons/cat-audio-square.svg">-->
<!--        <a [routerLink]="['/']" class="sidenav-menu-link">-->
<!--          Music-->
<!--        </a>-->
<!--      </li>-->
<!--      <li class="sidenav-menu-item">-->
<!--        <img src="/assets/images/svg-icons/cat-bag.svg">-->
<!--        <a [routerLink]="['/']" class="sidenav-menu-link">-->
<!--          E-commerce-->
<!--        </a>-->
<!--      </li>-->
<!--      <li class="sidenav-menu-item">-->
<!--        <img src="/assets/images/svg-icons/cat-book.svg">-->
<!--        <a [routerLink]="['/']" class="sidenav-menu-link">-->
<!--          Education-->
<!--        </a>-->
<!--      </li>-->
<!--      <li class="sidenav-menu-item">-->
<!--        <img src="/assets/images/svg-icons/cat-box-2.svg">-->
<!--        <a [routerLink]="['/']" class="sidenav-menu-link">-->
<!--          Web3-->
<!--        </a>-->
<!--      </li>-->
<!--      <li class="sidenav-menu-item">-->
<!--        <img src="/assets/images/svg-icons/cat-triangle.svg">-->
<!--        <a [routerLink]="['/']" class="sidenav-menu-link">-->
<!--          Digital Marketing-->
<!--        </a>-->
<!--      </li>-->

      <ng-container *ngIf="customCategories">
        <li class="sidenav-menu-item" [ngClass]="{active: active == category.id}"
            *ngFor="let category of customCategories">
          <a [routerLink]="['/categories', category.id]" class="sidenav-menu-link" attr.aria-label="category">
            <img src="/assets/images/svg-icons/cat-triangle.svg" alt="">
            <span>{{category.name[localeId]}}</span>
          </a>
        </li>
      </ng-container>

    </ul>
  </div>
</div>
<!--<app-language-select></app-language-select>-->
<div class="sidenav-social">
  <a href="https://www.facebook.com/getinofficiaI" target="_blank" attr.aria-label="facebook">
    <img class="social-logo-img" src="/assets/images/svg-icons/menu-facebook.svg" alt="facebook"/>
    <span>facebook</span>
  </a>
  <a href="https://www.instagram.com/getinofficial/" target="_blank" attr.aria-label="instagram">
    <img class="social-logo-img" src="/assets/images/svg-icons/menu-instagram.svg" alt="instagram"/>
    <span>instagram</span>
  </a>
  <a href="#" target="_blank" attr.aria-label="telegram">
    <img class="social-logo-img" src="/assets/images/svg-icons/menu-telegram.svg" alt="telegram"/>
    <span>telegram</span>
  </a>
  <a href="https://twitter.com/getin_official" target="_blank" attr.aria-label="twitter">
    <img class="social-logo-img" src="/assets/images/svg-icons/menu-x-twitter.svg" alt="twitter"/>
    <span>twitter</span>
  </a>
</div>
<ul class="sidenav-additional-links">
  <li>
    <a href="https://twitter.com/getin_official" [routerLink]="['/terms']" class="sidenav-additional-link" i18n="sidenav|terms" attr.aria-label="Terms">
      Terms
    </a>
  </li>
  <li>
    <a href="https://twitter.com/getin_official" [routerLink]="['/privacy']" class="sidenav-additional-link" i18n="sidenav|privacy" attr.aria-label="Privacy">
      Privacy
    </a>
  </li>
</ul>
