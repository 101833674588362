<div class="cms_cancel_refund">
  <h3>Cancel and Refund for event {{event_id}}</h3>

  <div class="success-data" *ngIf="successDataReceived">
    <h4>Refund Result</h4>
    <p><span class="label">Amount fee <span class="badge">{{successData.amount_fee}}</span></span></p>
    <p><span class="label">Amount refund <span class="badge">{{successData.amount_refund}}</span></span></p>
    <p><span class="label">Count free tickets <span class="badge">{{successData.count_free_tickets}}</span></span></p>
    <p><span class="label">Count tickets <span class="badge">{{successData.count_tickets}}</span></span></p>
  </div>

  <div class="bg-danger" *ngIf="errorData">
    <div *ngFor="let item of errorData | keyvalue; let k = index">
      <p *ngIf="k == 0">Errors:</p>
      Key: <b>{{item.key}}</b><br><b>{{item.value | json}}</b>
    </div>
  </div>

  <div class="row btns">
    <div class="col-md-3">
      <button class="btn btn-danger" [disabled]="!check_cancel_event ? true : false" (click)="cancelEvent()">Cancel Event</button>
    </div>
    <div class="col-md-3">
      <button class="btn btn-warning" [disabled]="!check_cancel_event && selection.hasValue() ? false : true"
              *ngIf="!isAllSelected()"
              (click)="refund(false)">
        Refund Selected Tickets
        <span *ngIf="selection.selected.length" class="badge">({{allSelectedTickets}})</span>
      </button>
      <button class="btn btn-warning" [disabled]="!check_cancel_event && selection.hasValue() ? false : true"
              *ngIf="isAllSelected()"
              (click)="refund(true)">
        Refund All Tickets
        <span *ngIf="selection.selected.length" class="badge">({{allSelectedTickets}})</span>
      </button>
    </div>
  </div>

  <table mat-table [dataSource]="dataSource" matSort class="mat-cancel-event">

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null; calcSelectedTickets(selection);"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null; calcSelectedTickets(selection);"
                      [checked]="selection.isSelected(row)"
                      [aria-label]="checkboxLabel(row)"
                      [disabled]="disabledRow(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <!-- item_name Column -->
    <ng-container matColumnDef="item_name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Ticket</th>
      <td mat-cell *matCellDef="let element">{{element.item_name}} ({{element.items_amount}})</td>
    </ng-container>

    <!-- name Column -->
    <ng-container matColumnDef="first_name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">{{element.first_name}} {{element.last_name}}
        <span class="email">( {{element.email}}, {{element.phone}} )</span></td>
    </ng-container>

    <!-- item_name Column -->
    <ng-container matColumnDef="producer_credit">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Tickets price</th>
      <td mat-cell *matCellDef="let element">{{element.producer_credit | currency: element.currency:'symbol':'1.'}}</td>
    </ng-container>


    <!-- status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">{{element.status}} - <span class="status-txt" i18n="ticket status">
      {
        element.status, plural,
        =1 {Pending}
        =2 {Approved}
        =3 {Declined}
        =4 {Refund}
        =5 {Rejected_trans}
        =6 {Rejected_refund}
        =7 {Hidden}
        =8 {Abandon}
        =9 {Outer}
        =10 {Sent for approval}
        =11 {Failed approval}
        =13 {Boa_status_fraud}
        other {Status}
        }
    </span></td>
    </ng-container>

    <!-- sub_users Column -->
    <ng-container matColumnDef="sub_users">
      <th mat-header-cell *matHeaderCellDef>Sub users</th>
      <td mat-cell *matCellDef="let element">
        <p class="sub-btn" (click)="showUsers(element.sub_users)" *ngIf="element.sub_users.length">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <circle cx="9" cy="7" r="4"></circle>
            <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
            <path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
          </svg>
          <span> ({{element.sub_users.length}})</span>
        </p>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (click)="toggleRow(row); calcSelectedTickets(selection);">
    </tr>
  </table>
</div>

<!--SUB USERS POPUP-->
<div [@slideIn] *ngIf="showSubUsers">
  <ng-container *ngTemplateOutlet="subUsersTemplate"></ng-container>
</div>
<ng-template #subUsersTemplate>
  <div class="sub-users">
    <ol>
      <li *ngFor="let sub_u of subUsers;">
       {{sub_u.id}} - {{sub_u.first_name + ' ' + sub_u.last_name}} ({{sub_u.email}} / {{sub_u.phone}} / Created at: {{sub_u.created_at}})
      </li>
    </ol>
    <button class="close" (click)="hideUsers()">&#10006;</button>
  </div>
</ng-template>
