<div class="support-section">
  <div class="support-wrapper">
      <div i18n="title | my data" class="support-page-title">My data</div>
      <div class="support-page-menu">
        <div i18n="label | subscribed" class="support-menu-text">Subscribed</div>
        <div i18n="label | unsubscribed" class="support-menu-text">Unsubscribed</div>
        <div i18n="label | paid" class="support-menu-text">Paid</div>
        <div i18n="label | registered" class="support-menu-text">Registered</div>
      </div>
      <div class="support-page-section">
        <div i18n="label | select all" class="select-all-section select">Select all</div>
        <div class="total-section">
          <span i18n="label | total" class="total">TOTAL</span>
          <span class="total">89</span>
        </div>
      </div>
      <div class="main-section">
        <div class="person-section">
          <div class="person-wrapper" *ngFor="let i of [0,1,2,3,4,5]; let x = index;">
          <div class="select-person">
              <div class="checkbox-section">
                <div class="custom-radio">
                  <span [ngClass]="radioBox[x] ? 'radio-mark': ''"></span>
                </div>
                <input class="promotion-radio"
                  (change)="radioHandler($event.target, $event.target,x)" [checked]="radioBox[x]" type="radio" value="">
                  <!-- id="{{value.input_name}}-{{counter}}" formControlName="{{input.input_name}}" name="{{input.input_name}}-{{counter}}" -->
                <!-- <label class="inline  checkbox-text" for="{{value.input_name}}-{{counter}}">&nbsp;{{value.placeholder}}</label> -->
              </div>
          </div>
          <div class="data-person">
            <div class="profile-img">
              <!-- <img class="promoter-avatar-img" [src]="salesman.seller_profile_img ? salesman.seller_profile_img : salesman.seller_social_img ? salesman.seller_social_img : '../assets/images/profile _pic_LOOGED_main.png'"> -->
              <img class="profile-avatar" [src]="'../assets/images/profile _pic_LOOGED_main.png'">
            </div>
            <div class="profile-wrapper">
              <div class="profile-data">
                <div i18n="label | name" class="profile-text">Name</div>
                <div class="delimiter"></div>
                <div class="profile-text">8</div>
                <div class="delimiter"></div>
                <div i18n="label | gender" class="profile-text">Gender</div>
                <div class="delimiter"></div>
                <div i18n="label | phone" class="profile-text">Phone</div>
              </div>
              <div i18n="label | profile" class="profile-btn">Profile</div>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div class="buttons-section">
          <div i18n="button | email" class="email-btn">Email</div>
          <div i18n="button | SMS" class="sms-btn">SMS</div>
      </div>
  </div>
</div>
