import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { EventService } from '../../services/event.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalsService } from '../../globals';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormArray} from '@angular/forms';
import { NavbarService } from '../../services/navbar.service';
import { slideIn } from '../../animation';
import { DialogService } from '../../services/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { CustomFieldsDialogComponent } from '../../create-edit-event/create-edit-event-dialogs/custom-fields-dialog/custom-fields-dialog.component';

@Component({
	selector: 'app-cms-payment-form',
	templateUrl: './cms-payment-form.component.html',
	styleUrls: ['./cms-payment-form.component.css'],
	animations: [slideIn]
})
export class CmsPaymentFormComponent implements OnInit, OnDestroy {

	event_id = 0;
	form_inputs;
	custom_inputs;
	blocked_inputs;

	formInputsForm: UntypedFormGroup;
	blockedInputsForm: UntypedFormGroup;

	constructor(private dialogService: DialogService,private formBuilder:UntypedFormBuilder, private eventService: EventService, private router: Router, private activatedRoute: ActivatedRoute, private zone:NgZone, public globals : GlobalsService, public nav: NavbarService, private dialog: MatDialog) {
		this.nav.customWrapperClass = 'full-screen';
	 }

	ngOnInit() {
		if(this.activatedRoute.params){
			this.activatedRoute.params.subscribe(params => {
				this.event_id = parseInt(params['event_id']);
			});
		}

		this.formInputsForm = this.formBuilder.group({});
		this.blockedInputsForm = this.formBuilder.group({});
		this.getPaymentForm();

	}

	getPaymentForm() {
    this.eventService.getPaymentFormData(this.event_id).subscribe(
      (response: any) => {
        if(response.data) {
          this.form_inputs = response.data.form_inputs;
          this.blocked_inputs = response.data.blocked_inputs;
          this.custom_inputs = response.data.custom_inputs;
          //console.log(this.custom_inputs);
        }
        this.formInputsForm = this.createFormControls(this.form_inputs);
        this.blockedInputsForm = this.createFormControls(this.blocked_inputs);
      },
      (error: any) =>  {
        this.dialogService.alertsModal((error.code) ? 'cms' :'errors', (error.code) ? error.code : 4 ,5000, false, error.log_id);
      }
    );
  }

	createFormControls(inputsObj, namePrefix = '', ){
		const group = this.formBuilder.group({});
		for (const input in inputsObj) {
		  const name = input;
		  const val = inputsObj[input]
      // console.log(inputsObj, name, val);
      group.addControl(name, this.formBuilder.control(val));
    }

		return group;
	}


	ngOnDestroy() {
		this.nav.customWrapperClass = undefined;

	}

	async onSubmit(){
    console.log(this.formInputsForm.value, this.blockedInputsForm.value);
		if(this.form_inputs instanceof Object) {
			let sendData = {
				event_id: this.event_id,
        custom_inputs: this.custom_inputs,
				form_inputs: this.formInputsForm.value,
        blocked_inputs: this.blockedInputsForm.value
			}
console.log("sendData", sendData);
			this.eventService.setEventInputs(sendData).subscribe(
				(response: any) => {
					if(response.success) {
						if(response.web_hooks){
							this.dialogService.alertsModal('success', 'successWebhook', 5000, true);
						} else {
							this.dialogService.alertsModal('success', 'success', 5000, true);
						}
						this.router.navigate([this.event_id])
					} else {
						this.dialogService.alertsModal('cms', response.error, 5000, false);
					}
				},
				error => {
					this.dialogService.alertsModal('cms', 4, 5000, false);
				}
			);
		} else {
			this.dialogService.alertsModal('cms', 1, 5000, false);
		}
	}


  openCustomFieldsModal() {
    const CustomFieldsModal = this.dialog.open(CustomFieldsDialogComponent, {
      data: {id: this.event_id, cmsPage: true},
      panelClass: 'custom-fields-modal',
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100vh',
      width: '100vw',
    });
    CustomFieldsModal.afterClosed().subscribe(
      result => {
        if (result === undefined || result.length === 0 ) {
          return
        }
        this.custom_inputs = result.controls['custom_inputs'].value;
        //console.log("result CustomFieldsModal.afterClosed", result.controls['custom_inputs'].value, this.custom_inputs);
      }
    );
  }
}
