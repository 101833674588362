<div *ngIf="bigArrow !== true && closeArrow === null" (click)="goBack()" class="event-back-arrow"
     [ngStyle]="{
        'top': top + 'px',
        'left': left + 'px'
      }">
  <div class="close-dialog-arrow" *ngIf="hideDialogArrow !== true">
    <svg class="arrow-close-modal" width="10" height="14" viewBox="0 0 10 14" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M9.49219 1.64063L4.13281 7L9.49219 12.3594L7.85156 14L0.851562 7L7.85156 0L9.49219 1.64063Z"
            fill="white" />
    </svg>
  </div>
</div>
<span *ngIf="bigArrow === true && closeArrow === null"
      [ngStyle]="{
        'top': top + 'px',
        'left': left + 'px'
      }"
      class="close-nav cursor-pointer" (click)="goBack()"></span>
<div class="close-arrow-pos_" *ngIf="closeArrow === true">
  <span class="close-arrow" (click)="goBack()">&times;</span>
</div>

