import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { getCurrencySymbol } from '@angular/common';
import { GlobalsService } from '../../globals';
import { CurrencyLocaleService } from '../../services/currency-locale.service';
import { EventService } from '../../services/event.service';

@Component({
  selector: 'app-event-counters',
  templateUrl: './event-counters.component.html',
  styleUrls: ['./event-counters.component.css'],
})
export class EventCountersComponent implements OnInit {
  @Input() views;
  @Input() sales;
  @Input() approved;
  @Input() pending;
  @Input() eventID;
  dir: string;
  @Input() currency: string;
  currencySymbol: string;

  constructor(
    private globals: GlobalsService,
    private currencyLocale: CurrencyLocaleService,
    private eventService: EventService,
    @Inject(LOCALE_ID) public LOCALE_ID
  ) {}

  getLocale(currency) {
    return this.currencyLocale.getLocaleForCurrencyPipe(currency);
  }

  ngOnInit() {
    this.dir = this.globals.getDir();
    if (this.views === null || this.views === undefined) {
      this.views = 0;
    }
    if (this.sales === null || this.sales === undefined) {
      this.sales = 0;
    }
    if (this.approved === null || this.approved === undefined) {
      this.approved = 0;
    }
    if (this.pending === null || this.pending === undefined) {
      this.pending = 0;
    }

    if (!this.currency) {
      this.eventService
        .getEventDatalp(this.eventID, '0', '0')
        .subscribe(eventData => {
          this.currency = eventData.event_details[0].currency;
          this.currencySymbol = getCurrencySymbol(this.currency, 'narrow');
        });
    } else {
      this.currencySymbol = getCurrencySymbol(this.currency, 'narrow');
    }
  }
}
