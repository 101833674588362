    <!-- ------------------Arrow close Modal---------------------------->
  <span class="close-login" (click)="closeLoginModal()" *ngIf="!isSignInPage && !sendSmsTimer"></span>

  <div class="login-wrap" [ngStyle]="{'background-image': topViewsEvent ? 'url(' + baseEventImgUrl + topViewsEvent.file + ')' : 'none'}">
    <div class="login-video" *ngIf="topViewsEvent">
      <div class="top-event-card"
           [ngStyle]="{'background-image': 'url(' + baseEventImgUrl + topViewsEvent.file + ')' }">
        <div class="top-event-card-2nd-bg">
          <ng-container *ngTemplateOutlet="topEventCardInfo"></ng-container>
        </div>

      </div>
<!--      <video width="100%" height="100%" loop autoplay>-->
<!--        <source src="./assets/images/login/SampleVideo_1280x720_1mb.mp4" type="video/mp4">-->
<!--      </video>-->
    </div>
    <div class="login-modal" [ngClass]="{'login-modal-w-event' : topViewsEvent}">
      <div class="login-modal-2nd-bg">
        <div mat-dialog-title class="login-header">
          <!--------------------Logo in login page------------------>
          <button class="logo-btn" [disabled]="sendSmsTimer" [routerLink]="['/']" (click)="closeLoginModal()">
            <img src="/assets/images/svg-icons/logo_big.svg" alt="Logo">
          </button>
          <!-- ------------------Log in title---------------------------->
          <h2 class="login-title" *ngIf="loginShow && !this.selectedFlow">{{'login.Login_SignUp' | translate:'Login / Sign Up'}}</h2>
          <h2 class="login-title" *ngIf="loginShow && this.selectedFlow">{{'login.Login_SignUp' | translate:'Login / Sign Up'}}</h2>
          <h2 i18n="registration" class="login-title" *ngIf="!loginShow">Registration</h2>
        </div>

        <section mat-dialog-content class="login-container">
          <!-- ------------------Log in with Wallet Connect----------------->
          <div *ngIf="!isShowOtp && false">
            <button *ngIf="!isWalletSignUp" (click)="loginShow ? signInWallet() : signUpWalletBtn()" [disabled]="isConnect" class="login-btn login-wallet-box">
              <!-- <img src="../../../assets/images/login/insta-icon.png" alt="" class="login-icon"> -->
              <span i18n="Sign in by WalletConnect" class="login-by" *ngIf="loginShow">
            WalletConnect
          </span>
              <span i18n="Sign up by WalletConnect" class="login-by" *ngIf="!loginShow">
            Signup by WalletConnect
          </span>
            </button>
          </div>

          <!-----------------Log in / Registration Forms------------>
          <app-signin (signIn)="closeLoginModal($event);" (signUp)="closeLoginModal();" *ngIf="loginShow" (currenUsertUrl)="setUsertUrl($event)" [userEmail]="userEmail"
                      [isCreateEvent]="isCreateEvent" [(showOtp)]="isShowOtp" (setCaptcha)="setCaptcha($event)"
                      (signinAction)=toggleIfUserNotExitAfterLogin($event) (logInToggle)="logInToggle()" (selectedFlowEmit)="setSelectedFlow($event)"
                      (sendSmsTimerEmit)="issendSmsTimer($event)"></app-signin>
          <app-signup (signUp)="closeLoginModal();" *ngIf="!loginShow" (userWalletReg)="signUpWallet($event)" [userEmail]="userEmail"
                      [isWalletSignUp]="isWalletSignUp" [walletReg]="walletReg" [(showOtp)]="isShowOtp" (setCaptcha)="setCaptcha($event)"
                      [userNotExitsFields]="userNotExitsFields" (logInToggle)="logInToggle()" [selectedFlow]="selectedFlow"
                      (sendSmsTimerEmit)="issendSmsTimer($event)"></app-signup>
          <div i18n="@@CapsLockOn" [hidden]="!capsLock" class="alerts text-center col-md-12 col-xs-12">Caps Lock on</div>

        </section>

        <ng-container *ngTemplateOutlet="topEventCardInfo"></ng-container>
      </div>
    </div>
  </div>


  <ng-template #topEventCardInfo>
    <div class="top-event-card-info"
         *ngIf="topViewsEvent && topViewsEvent.title">
      <div><img src="/assets/images/login/location_on.svg" alt=""></div>
      <div>
        <p>{{topViewsEvent.producer_name}}:
          <a class="top-event-card-link"
             [routerLink]="[(topViewsEvent.url_friendly) ? '/'+topViewsEvent.url_friendly : '/'+topViewsEvent.event_id]"
             [queryParams]="{ seller_code: 'getin'}"
             (click)="closeLoginModal()"
             attr.aria-label="{{topViewsEvent.title}}"
          >{{topViewsEvent.title}}</a>
        </p>
        <p>{{topViewsEvent.address}}</p>
      </div>
    </div>
  </ng-template>
