import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {MatDatepicker} from '@angular/material/datepicker';
import moment from 'moment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {delay, map, shareReplay} from 'rxjs/operators';
import {EventCacheService} from '../services-cache/event-cache.service';
import {environment} from '../../environments/environment';
import {GlobalsService} from '../globals';
import {NavbarService} from './navbar.service';

@Injectable({
  providedIn: 'root'
})
export class FilterEventsService {
  autocomplete = new BehaviorSubject([]);
  // https://stackoverflow.com/questions/63229070/expressionchangedafterithasbeencheckederror-angular
  // delay 0 added
  getAutocompleteSource = this.autocomplete.asObservable().pipe( delay(0) );
  datepicker = new BehaviorSubject<Datepicker>({start: '', end: ''});
  getDatepickerSource = this.datepicker.asObservable();
  select = new BehaviorSubject<string>('');
  getSelectSource = this.select.asObservable();


  pickerRef: MatDatepicker<Date>; // to open it when calendar selected from dropdown
  eventsSource = new BehaviorSubject([]);
  getEventsSource = this.eventsSource.asObservable();
  categoriesSource = new BehaviorSubject([]);
  getCategoriesSource = this.categoriesSource.asObservable();
  slidersSource = new BehaviorSubject([]);
  getSlidersSource = this.slidersSource.asObservable();
  activeCategoryId = new BehaviorSubject('');
  getActiveCategoryId = this.activeCategoryId.asObservable();
  categoriesIdsWithEvents = new BehaviorSubject([]);
  getCategoriesIdsWithEvents = this.categoriesIdsWithEvents.asObservable();
  api_base_url = environment.api_base_url;
  constructor(private http: HttpClient,
              private eventCacheService: EventCacheService,
              private globals: GlobalsService,
              private navbar: NavbarService) { }


  setAutocomplete(val) {  // app-autocomplete-filter-events
    this.autocomplete.next(val);
  }

  getAutocomplete() {  // app-autocomplete-filter-events
    return this.autocomplete.getValue();
  }

  setDatepicker(val) { // app-datepicker-filter-events
    this.datepicker.next(val);
  }

  getDatepicker() { // app-datepicker-filter-events
    return this.datepicker.getValue();
  }

  setSelect(val) { // app-select-filter-events
    this.select.next(val);
  }

  getSelect() { // app-select-filter-events
    return this.select.getValue();
  }

  initPicker(picker) {
    this.pickerRef = picker;
  }

  openPicker() {
    this.pickerRef.open();
  }

  getEvents(producer_id: any,region: string = '', last_id: any = null, latLng = {latitude: null, longitude: null}, enableCache = true): Observable<any> {
    let events$ = enableCache ? this.eventCacheService.getValue({'eventsHash': 'eventsHash'}) : null;
    if (!events$) {
      events$ = this.http.post(this.api_base_url + '/api/events',
        {
          producer_id: producer_id, last_id: last_id, lat: latLng.latitude, lon: latLng.longitude, region: region,
          user_timezone_offset: moment(new Date().getTime()).format('ZZ')
        },
        {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})}).pipe(
        map(
          (response: any) => {
            const events = response.events;
            const categories = response.categories;
            const sliders = response.sliders;
            const associateEvents = response.associateEvents;

            const data = {
              events: events,
              categories: categories,
              sliders: sliders,
              associateEvents: associateEvents
            };

            return data;
          }
        ),
        shareReplay(1)
      );
      this.eventCacheService.setValue(events$, {'eventsHash': 'eventsHash'});
    }

    return events$
  }

  setEvents(events) {
    // console.log('setEvents', events);
    this.eventsSource.next(events);
  }

  setCategories(categories) {
    // console.log('setCategories', categories);
    this.categoriesSource.next(categories);
  }

  setActiveCategory(category) {
    this.activeCategoryId.next(category);
  }

  setSliders(sliders) {
    // console.log('setCategories', categories);
    this.slidersSource.next(sliders);
  }

  setCategoriesIdsWithEvents(categoriesIds) {
    // console.log('setCategories', categories);
    this.categoriesIdsWithEvents.next(categoriesIds);
  }

  // Filter events by type and return the result
  // events - events list
  // type - switch case, to filter events
  // Categories - for categorized events
  handleEvents(events, type, categories = []) {
    const date = new Date();
    let fromDate, toDate;
    const yesterday = date.setDate((date.getDate()) - 1).valueOf();
    const today = date.setHours(23, 59, 59, 0).valueOf();
    const today_end = date.setDate((date.getDate()) + 1).valueOf();
    const week = new Date(date.getTime() + (7*24*60*60*1000)).valueOf();
    const month = new Date(date.getTime() + (30*24*60*60*1000)).valueOf();

    const usaTimezones = [
      'America/Adak',
      'America/Anchorage',
      'America/Los_Angeles',
      'America/Phoenix',
      'America/Chicago',
      'America/New_York',
      'Pacific/Honolulu',
      'America/Denver'
    ];

    // SWITCH STATEMENTS
    switch (type) {
      case ( !isNaN(type) ? type : 0 > 0 ): // Statement for customCategories with 'ID'( type is digit and equals ID)
        if (events.length) {
          return events.filter(event => event.categories.indexOf(+type) > -1)
        } else {
          return []
        }
        break;
      case "all":
        return events
        break;
      case "featured":
        let featured = [];
        // const temp_events = events.forEach(event => {
        //   event.daysToStart = Math.floor( (new Date(event.start_date.replace(/-/g, '/')).valueOf() - new Date().valueOf()) / (1000 * 60 * 60 * 24) );
        // });
        let usaEvents = [];
        let otherEvents = [];
        if (!events.length) {return []}
        events.forEach(event => usaTimezones.indexOf(event.time_zone) > -1 ? usaEvents.push(event) : otherEvents.push(event));

        usaEvents = this.sortByStartDate(usaEvents);
        otherEvents = this.sortByStartDate(otherEvents);

        featured = [...usaEvents, ...otherEvents];

        return featured
      case "followed":
        const followedProducers = this.navbar.getUserInfo() ? this.navbar.getUserInfo().following : null;

        if (followedProducers && [...events].filter(event => followedProducers.indexOf(event.user_id) > -1).length) {
          return [...events].filter(event => followedProducers.indexOf(event.user_id) > -1)
        } else {
          return []
        }

        break;
      case "free":
        if (events.filter(event => event.registration_mode > 0).length) {
          let free = events.filter(event => event.registration_mode > 0);
          free =  this.sortByStartDate(free);
          return free
        } else {
          return []
        }
        break;
      case "trending":
        const trending = events.filter(event => event.views >= 1000);
        if (trending.length) {
          trending.sort((a, b) => {
            return b.views - a.views; // sorting by views from greater to lower
          });

          return trending
        } else {
          return []
        }
        break;
      case "categorized":
        if (events.length && categories.length) {
          const categorized = {};
          const categoriesWithEvents = [];
          categories.forEach(category => {
            const catEvents = events.filter(event => event.categories.indexOf(category.id) > -1).map((event)=> {
              categoriesWithEvents.push(category.id);
              return {
                ...event,
                filteredCategoryId: category.id, // to show proper category on homepage and categories
              }
            });
            if (catEvents.length) {
              if (category.name[this.globals.getLocaleId()] === undefined) {
                categorized[category.name['en']] = catEvents; // If category doesn't translated use EN version by default
              } else {
                categorized[category.name[this.globals.getLocaleId()]] = catEvents;
              }

            }
          })
          // Object.keys(categorized).forEach(key => {
          //   categorized[key] = this.sortByStartDate(categorized[key]);
          // })
          this.setCategoriesIdsWithEvents([...new Set(categoriesWithEvents)]); // Set Unique category Id which has events
          return categorized
        } else {
          return []
        }

        break;
      case "location":
        const autocomplete = this.getAutocomplete();
        // console.log('autocomplete', autocomplete, autocomplete.length, typeof(autocomplete))
        if (autocomplete.length && events.length) {
          const location = events.filter((obj) => obj.address.includes(autocomplete));
          return location
        } else {
          return []
        }
        break;
      case "datepicker":
        if (!events.length) {return []}
        const select = this.getSelect();
        const datepicker = this.getDatepicker();
        let date_events;
        if (select === 'yesterday') {
          fromDate = yesterday;
          toDate = today;
        }
         if (select === 'today') {
           fromDate = today;
           toDate = today_end;
         }
        if (select === 'week') {
          fromDate = today;
          toDate = week;
        }
        if (select === 'month') {
          fromDate = today;
          toDate = month;
        }
        if (select === 'calendar' && datepicker.start && datepicker.end) {
          fromDate = new Date(datepicker.start).valueOf();
          toDate = new Date(datepicker.end).valueOf();
        }

        date_events = events.filter((e: any) => {
            // console.log('filter', e, this.dateToMs(e.start_date));
            return this.dateToMs(e.start_date) >= fromDate && this.dateToMs(e.start_date) <= toDate
          }
        );

        // console.log('DPCKR', events, date_events, select, datepicker, fromDate, toDate);

        return date_events

        break;
      case "week":
        let week_events;
        fromDate = date.setHours(23, 59, 59, 0).valueOf();
        toDate = new Date(date.getTime() + (7*24*60*60*1000)).valueOf();
        week_events = events.filter((e: any) => {
            return this.dateToMs(e.start_date) >= fromDate && this.dateToMs(e.start_date) <= toDate
          }
        );
        return week_events
        break;
      default:
        return []
    }
  }

  updateFollowers(producerAction, userId) {
    // console.log('producerAction updateFollowers', producerAction, userId);

    const eventsSource = this.eventsSource.getValue();
    let defaultData = JSON.parse(JSON.stringify(eventsSource));

    // console.log('BEFORE updateFollowers1', eventsSource);
    eventsSource.forEach((element, index) => {
      if (producerAction.producer_id === element.user_id) {

        if (producerAction.action == 'follow') {
          if (!element.followers.includes(userId)) {
            console.log('follow1', index, defaultData[index].followers);
            defaultData[index].followers.push(userId)
            console.log('follow2', index, defaultData[index].followers);
          }
        }

        if (producerAction.action == 'unfollow') {
          if (element.followers.includes(userId)) {
            console.log('unfollow1', index, defaultData[index].followers);
            let indx = element.followers.findIndex(obj => obj === userId);
            defaultData[index].followers.splice(indx, 1);
            console.log('unfollow2',  index, defaultData[index].followers);
          }
        }

      }
    });
    // console.log('AFTER updateFollowers2', defaultData);

    this.setEvents(defaultData);

  }

  dateToMs(date: string) {
    // from "2022-06-03 12:23:00" to "2022/06/03 12:23:00"
    return new Date(date.replace(/-/g, '/')).valueOf()
  }

  sortByStartDate(data) {
    return data.sort((a, b) => {
      return new Date(a.start_date.replace(/-/g, '/')).valueOf() - new Date(b.start_date.replace(/-/g, '/')).valueOf();
    });
  }

}
interface Datepicker {
  start?: string;
  end: string;
}
