import { Injectable } from '@angular/core';
import { GlobalsService } from '../globals';
import { AuthService } from './auth.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';
declare const window: any;
interface RestoreId {
  data: {
    restore_id: string | null
  }
}
@Injectable({
  providedIn: 'root'
})

export class FreshChatService {
  api_base_url = environment.api_base_url;
  token = environment.freshchat.token;
  host = environment.freshchat.host;
  localeId: string;
  fcWidget: any;

  constructor(
    private http: HttpClient,
    private globals: GlobalsService,
    private authService: AuthService
  ) { }

  // FC - FreshChat https://developers.freshchat.com/web-sdk/
  startFC() {
    if (this.isFCWidgetInitialized()) {
      this.openFCWidget();
    } else {
      this.initFC();
    }
  }

  initFC() {
    const locale = this.globals.getLocaleId(),
    user = this.authService.getUser(),
    userInfo = this.authService.getUserInfo();

    //Anonymous User
    if (!user) {
      window.fcWidget.init({
        token: this.token,
        host: this.host,
        open: true,
        locale: locale,
        config: {
          showFAQOnOpen: true,
          hideFAQ: false
        }
      });
      this.addChatListener();
      this.openFCWidget();
    }

    // Logged IN user
    if (user) {
      this.getFCUserRestoreId().subscribe((res: RestoreId) => {
        const restoreId = res.data.restore_id ? res.data.restore_id : null;
        console.log('getUserFreshChatRestoreId restoreId', res, restoreId);
        window.fcWidget.init({
          token: this.token, //Replace with your Freshchat Token by going to Admin > Web Messenger
          host: this.host,
          externalId: userInfo.email,     // user’s id unique to your system
          restoreId: restoreId,  // https://developers.freshchat.com/web-sdk/#restore-user
          open: true,
          locale: locale,
          config: {
            showFAQOnOpen: true,
            hideFAQ: false
          }
        });
        this.addChatListener();
        window.fcWidget.user.get((resp) => {
          const status = resp && resp.status,
            data = resp && resp.data;
          console.log('fcWidget.user.get', resp, 'info', userInfo.first_name, userInfo.last_name, userInfo.email)
          if (status !== 200) {
            window.fcWidget.user.setProperties({
              firstName: userInfo.first_name,              // user's first name
              lastName: userInfo.last_name,                // user's last name
              email: userInfo.email,    // user's email address
              // phone: '8668323090',            // phone number without country code
              // phoneCountryCode: '+1',         // phone's country code
            });
            window.fcWidget.on('user:created', (resp) => {
              const status = resp && resp.status,
                data = resp && resp.data;
              if (status === 200) {
                if (data.restoreId) {
                  // Update restoreId in your database
                  this.setFCUserRestoreId(data.restoreId).subscribe(res => {
                    console.log('setFCUserRestoreId', res);
                  });
                }
              }
            });
          }
        });
      })
    }
  }

  // FreshChat methods
  initiateCall() {
    const i = document, t =  'freshchat-js-sdk';
    var e;
    i.getElementById(t) ? this.initFC() : ((e=i.createElement('script')).id=t,e.async=!0,e.src='https://wchat.freshchat.com/js/widget.js',e.onload=this.initFC,i.head.appendChild(e))
  }
  addChatListener() {
    window.addEventListener ? window.addEventListener('load',this.initiateCall,!1) : window.attachEvent('load',this.initiateCall,!1);
  }

  isFCWidgetInitialized() {
    if (window.fcWidget && window.fcWidget.isInitialized() == true) {
      console.log('Widget already initialised');
      return true
    }
    else {
      console.log('Widget Not Initialised');
      return false
    }
  }

  openFCWidget() {
    window.fcWidget.open();
  }

  destroyFCWidget() {
    window.fcWidget.destroy();
  }

  clearFCUser() {
    window.fcWidget.user.clear().then(function() {
      console.log('FC User cleared');
    }, function() {
      console.log('FC User Not cleared');
    });
  }

  isFreshChatWidgetOpen() {
    return (window.fcWidget.isOpen() == true) ? true : false;
  }

  // Fetch RestoreId from our db
  getFCUserRestoreId() {
    return this.http.get(this.api_base_url + '/api/freshchat/user', {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  // Set RestoreId to our db
  setFCUserRestoreId(restoreId) {
    return this.http.post(this.api_base_url + '/api/freshchat/user',
      {restore_id: restoreId},
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }

}
