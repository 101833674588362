import { CurrencyLocaleService } from './../../services/currency-locale.service';
import { EventTicketsService } from './../event-tikets-service/event-tickets.service';
import { DialogService } from './../../services/dialog.service';
import { fade, slideIn } from './../../animation';
import { DatePipe } from '@angular/common';
import { LocalizationService } from './../../services/localization.service';
import { MbscSelectOptions, MbscDatetimeOptions } from '@mobiscroll/angular';
import { GlobalsService } from './../../globals';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  ValidatorFn, AbstractControl, ValidationErrors
} from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import moment from 'moment';
import * as customValidator from '../../directives/blocked-email-validator';
declare var Mailcheck;

@Component({
  selector: 'app-guest-info',
  templateUrl: './guest-info.component.html',
  styleUrls: ['./guest-info.component.css'],
  animations: [slideIn, fade]
})
export class GuestInfoComponent implements OnInit, OnDestroy {

  @Input() form_inputs;
  @Input() user_info;
  @Input() ageDisabled = false;
  @Input() choosedItems: any = {};
  // @Input() ticketLimit: any = {};
  @Input() purchase_type: number;
  @Input() formSubmited = false;
  @Input() event_details: any = [];
  participantForm: UntypedFormGroup = this.fbuilder.group({participants: this.fbuilder.array([])});
  @Output() switchStep: EventEmitter<any> = new EventEmitter();
  @Output() showTerms: EventEmitter<boolean> = new EventEmitter();
  @Output() setParticipantFormChange = new EventEmitter();
  @Input() set setParticipantForm(value: UntypedFormGroup) {
    this.participantForm = value;
  }
  checkBoxAgree = false;
  checkBoxProducerSubscription = false;
  checkBoxCustom = false;
  @Output() setCheckedAgreementChange = new EventEmitter();
  @Input() set setCheckedAgreement(value: boolean) {
    // console.log('checkbox terms ', value);
    this.checkBoxAgree = value;
  }
  @Output() setCheckedProducerSubscriptionChange = new EventEmitter();
  @Input() set setCheckedProducerSubscription(value: boolean) {
    // console.log('setCheckedProducerSubscription', value)
    this.checkBoxProducerSubscription = value;
  }
  @Input() payBtnAddon: any;
  @Input() showSeatsIo: boolean = false;
  @Input() showProducerSubscription: boolean = false;
  @Input() hasWaitedTicket: number = 0;
  @Input() invalidEmailsError: any[] = [];

  new_custom_inputs: any[]  = [];
  checkboxArr: Object = {};
  @Output() setCheckBoxArrayChange = new EventEmitter();
  @Input() set setCheckBoxArray(value) {
    this.checkboxArr = value;
    // console.log('checkboxArr', this.checkboxArr);
  }
  @Input() set newCustomInputs(value) {
    this.new_custom_inputs = value;
    // console.log('new_custom_inputs', this.new_custom_inputs);
  }
  toggleParticipant: number | undefined = 1;
  localId = this.globals.getLocaleId();
  suggestion: {} = {};

  today = new Date();
  genderSettings: MbscSelectOptions = {
    select: 'single',
    theme: this.globals.mobiscrollTheme(),
    display: 'center',
    lang: this.globals.LOCALE_ID,
    filter: false,
  }
  myBirthdaySettings: MbscDatetimeOptions = {
    theme: this.globals.mobiscrollTheme(),
    lang: this.globals.getLocaleId(),
    // animate: 'pop',
    headerText: (this.globals.getLocaleId() === 'he') ? 'תאריך לידה' : 'Date of Birth',
    min: new Date(this.today.setFullYear(this.today.getFullYear() - 120)),
    display: 'center',
    dateWheels: 'yy MM dd',
    defaultValue: '1980-01-01',
    animate: 'slideup',
    returnFormat: 'iso8601',
    max: new Date(),
  };
  formInputsOthers: any;
  localeId: string;
  dir: string;
  customClass = '';
  label=false;
  isActiveBtn = true;

  constructor(
    public globals: GlobalsService,
    public localizationService: LocalizationService,
    private fbuilder: UntypedFormBuilder,
    private datePipe: DatePipe,
    private dialogService: DialogService,
    private eventTicketsService: EventTicketsService,
    private currencyLocale: CurrencyLocaleService
    ) {
    this.formInputsOthers = this.globals.formInputsOthers;
    this.localeId = this.globals.getLocaleId();
    this.dir = this.globals.getDir();
  }

  ngOnInit(): void {
    // console.log('choosedItems', this.choosedItems);
    // console.log('form_inputs', this.form_inputs);
    // console.log('participantForm', this.participantForm);
    // console.log('user_info', this.user_info);
    // console.log('event_details', this.event_details);

    const participentData = this.eventTicketsService.getDataParticipents();
    if (participentData) {
      this.choosedItems = participentData.choosedItems
      this.form_inputs = participentData.form_inputs
      this.user_info = participentData.user_info
      this.event_details = participentData.event_details
      this.formSubmited = participentData.formSubmited
      this.purchase_type = participentData.purchase_type
      this.participantForm = participentData.participantForm

      this.ageDisabled = participentData.ageDisabled
      this.new_custom_inputs = participentData.new_custom_inputs
      this.checkboxArr = participentData.checkboxArr
      this.toggleParticipant = participentData.toggleParticipant
    }
    // Fill only for the first time
    if(this.participantForm.controls['participants']
      && !this.participantForm.controls['participants']['controls'][0].controls['user_phone'].value) {
      this.fillForMe(0); // fill from user info first ticket
    }

  }

  getTerms() {
    this.showTerms.emit(true);
  }

  onCheckBox(type: string, e = null) {

    this['checkBox' + type] = !this['checkBox' + type]
    if (type == 'Agree') {
      this.setCheckedAgreementChange.emit(this['checkBox' + type]);
    } else if (type == 'ProducerSubscription'){
      this.setCheckedProducerSubscriptionChange.emit(this['checkBox' + type]);
    }
  }

  getLocale(currency) {
    return this.currencyLocale.getLocaleForCurrencyPipe(currency)
  }

  sendParticipentForm() {
    if (!this.checkBoxAgree) {
      console.log('not agree with terms');
      this.dialogService.alertsModal('errors', '16', 3000, false);
      return;
    }
    if(this.event_details?.event_id == 8297 || this.event_details?.event_id == 337438) {
      if (!this.checkBoxCustom) {
        this.dialogService.alertsModal('errors', '16', 3000, false);
        return;
      }
    }
    let invalid_first_name = this.checkFormFields();
    if (this.participantForm.valid && this.isActiveBtn) {
      this.isActiveBtn = false;
      this.setParticipantFormChange.emit(this.participantForm);
      this.onSubmit();
    } else {
      this.participantForm.controls.participants['controls'].forEach(form => {
        this.markFormGroupTouched(form);
      });
      if (invalid_first_name) {
        this.dialogService.alertsModal('errors', '51', 3000, false);
      } else {
        this.dialogService.alertsModal('errors', '18', 3000, false);
      }

      // this.markFormGroupTouched(this.userRegistrationForm.form);
    }
    // console.log('sendParticipentForm', this.participantForm, this.isActiveBtn);
  }

  onSubmit() {
    // const step = this.showSeatsIo ? 'Seats' : 'Order';
    this.switchStep.emit('Order');
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      // console.log('control', control, 'group', formGroup);
      control.markAsTouched();
      if (formGroup.controls['user_phone'] && formGroup.controls['user_phone'].valid) {
        control.updateValueAndValidity();
      }
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  fillForMe(index) {
    console.log('fillForMe', index, this.participantForm.controls['participants']);
    const ticketControls = this.participantForm.controls['participants']['controls'][index].controls;
    // console.log('fillForMe', ticketControls.full_name, ticketControls);
    if (ticketControls.full_name) {
      ticketControls.full_name.setValue(this.user_info.full_name ? this.user_info.full_name : this.user_info.first_name ? this.user_info.first_name + ' ' + this.user_info.last_name : '');
    }
    if (ticketControls.gender) {
      ticketControls.gender.setValue(this.user_info.gender ? this.user_info.gender : '');
    }
    if (ticketControls.identification_number) {
      ticketControls.identification_number.setValue(this.user_info.identification_number ? this.user_info.identification_number : '');
    }
    if (ticketControls.user_phone) {
      ticketControls.user_phone.setValue(this.user_info.phone ? this.user_info.phone : '');
    }
    if (ticketControls.user_email) {
      ticketControls.user_email.setValue(this.user_info.email ? this.user_info.email : '');
      ticketControls.user_email.setValidators([customValidator.blockedEmailValidator(this.invalidEmailsError)]);
    }
    if (ticketControls.birth_date) {
      ticketControls.birth_date.setValue(this.user_info.birth_date ? this.user_info.birth_date : '');
    }
    if (ticketControls.age) {
      ticketControls.age.setValue(this.user_info.age ? this.user_info.age : '');
    }
    if (ticketControls.instagram_url) {
      ticketControls.instagram_url.setValue(this.user_info.instagram_url ? this.user_info.instagram_url : '');
    }
    if (ticketControls.age && this.user_info.birth_date) {
      const userAgeMs = new Date().valueOf() - new Date(this.user_info.birth_date.replace(/-/g, '/')).valueOf();
      const userAgeYears = Math.floor(userAgeMs / (1000 * 60 * 60 * 24 * 365));
      ticketControls.age.setValue(userAgeYears);
    }
  }

  clearAttendee(index) {
    // console.log('clearAttendee');
    const ticketControls = this.participantForm.controls['participants']['controls'][index].controls;
    Object.keys(ticketControls).forEach(control => {
      if (control !== 'item_id' && control !== 'category') {
        ticketControls[control].setValue('')
      }
    })
  }

  radioToggle(event, counter, form, item) {
    // console.log('radioToggle', event.value, counter, this.user_info.gender, form.controls.gender.value, item.controls.gender.value, this.participantForm.controls['participants']['controls']);
    const control = form.controls.gender;
    if (control) { control.setValue(event.value); }
  }

  toggleParticipantForm(counter) {
    // console.log('toggleParticipantForm', counter, this.toggleParticipant);
    if (counter === this.toggleParticipant) {
      this.toggleParticipant = undefined;
    } else {
      this.toggleParticipant = counter;
    }
  }

  birthDateCange(counter, event) {
    const value = event.target.value;
    const userAgeYears = moment(new Date()).diff(new Date(value), 'years');
    if (this.user_info) { this.user_info.age = userAgeYears; }
    const control = this.participantForm.controls['participants']['controls'][counter].controls['age'];
    if (control) { control.setValue(userAgeYears); }
  }

  ageChange (counter, event) {
    const value = event.target.value;
    if (isNaN(value)) {
      return
    }
    const userBirthday = new Date (new Date().setFullYear(new Date().getFullYear() - value));
    const control = this.participantForm.controls['participants']['controls'][counter].controls['birth_date'];
    if (control) { control.setValue(this.datePipe.transform(userBirthday, 'MM/dd/yyyy')); }
  }

  radioHandler(i, counter, value: { counter, radioName, radioValue, isChecked, index }) {
    this.participantForm.controls['participants']['controls'][counter].controls[value.radioName].setValue(value.radioValue);
  }

  selectHandler(value: { counter, selectName, selectValue }) {
    this.participantForm.controls['participants']['controls'][value.counter].controls[value.selectName].setValue(value.selectValue);
  }

  // counter, checkboxName, checkboxValue, isChecked, val, index
  checkboxHandler(i, counter, value: {counter, checkboxName, checkboxValue, isChecked, val, index}) {
    const checkboxArr = this.checkboxArr[value.counter + '-' + value.checkboxName];
    // console.log("checkboxHandler guest info ", i, counter, value);
    if (value.isChecked) {
      if (!checkboxArr.includes(value.checkboxValue)) {
        checkboxArr.push(value.checkboxValue);
      }
    } else {
      for (let i = 0; i < checkboxArr.length; i++) {
        if (checkboxArr[i] === value.checkboxValue) {
          checkboxArr.splice(i, 1);
        }
      }
    }
    this.participantForm.controls['participants']['controls'][counter].controls[value.checkboxName].setValue(checkboxArr);
    this.setCheckBoxArrayChange.emit(this.checkboxArr);
  }

  setErrorOnphoneNumber(hasErrorfromNg2TelInput, counter) {
    const countryCode = this.participantForm.controls['participants']['controls'][counter].get('phoneCountryCode').value;
    const userPhone = this.participantForm.controls['participants']['controls'][counter].get('user_phone').value;
    const hasError = this.globals.hasErrorOnPhoneNumber(hasErrorfromNg2TelInput, userPhone);
    const { isILSError, phone } = this.globals.hasErrorOnIlsPhoneNumber(hasErrorfromNg2TelInput, userPhone, countryCode);
    if (countryCode === 'il' && !isILSError) {
      this.setPhoneNumber(phone, counter);
    }
    const actionOnSetErrors = hasError && isILSError ? { 'invalid': true } : null;
    this.participantForm.controls['participants']['controls'][counter].get('user_phone').setErrors(actionOnSetErrors);
  }

  setPhoneNumber(phoneNumber, counter) {
    // console.log('setPhoneNumber', phoneNumber, counter);
    this.participantForm.controls['participants']['controls'][counter].get('user_phone').patchValue(phoneNumber);
  }

  telInputObject(obj, counter, item) {
    const form = this.participantForm.controls['participants']['controls'][counter];
    // console.log('telInputObject', item, obj, counter, this.participantForm, form, form.get('user_phone'), form.get('user_phone').value);
    if (this.participantForm.controls['participants']['controls'][counter].get('user_phone').value) {
      obj.setNumber(this.participantForm.controls['participants']['controls'][counter].get('user_phone').value);
    }
    this.participantForm.controls['participants']['controls'][counter].get('phoneCountryCode').patchValue(obj.s.iso2);
  }
  onCountryChange(obj, counter) {
    // console.log('onCountryChange', obj, counter);
    this.participantForm.controls['participants']['controls'][counter].get('phoneCountryCode').patchValue(obj.iso2);
  }

  checkFullName(counter, $event) {
    let value = $event.target.value;
    if(value.trim().indexOf(' ') === -1){
      this.participantForm.controls['participants']['controls'][counter].get('full_name').setErrors({ 'invalid': true });
    }
  }

  checkFormFields() {
    let controls = this.participantForm.controls['participants']['controls'];
    let invalidField = false;
    controls.forEach((c,i)=> {
      let full_name = c.get('full_name').value;
      if(full_name.trim().indexOf(' ') === -1) {
        invalidField = true;
        this.participantForm.controls['participants']['controls'][i].get('full_name').setErrors({ 'invalid': true });
      }
    })
    return invalidField
  }

  mailChange(email, counter) {
    // console.log(counter, email, this.suggestion);
    if(Mailcheck) {
      Mailcheck.run({
        email: email,
        // domains: domains,                       // optional or check mailcheck.js
        // topLevelDomains: topLevelDomains,       // optional or check mailcheck.js
        // secondLevelDomains: secondLevelDomains, // optional or check mailcheck.js
        // distanceFunction: superStringDistance,  // optional or check mailcheck.js
        suggested: (suggestion: any) => {
          // callback code
          this.suggestion[counter] = suggestion;
        },
        empty: ()=> {
          // callback code
          this.suggestion[counter] = null;
        }
      });
    }

  }
  setSuggestedMail(mail, counter) {
    const control = this.participantForm.controls['participants']['controls'][counter].controls['user_email'];
    if (control) { control.setValue(mail); }
    this.suggestion[counter] = null;
  }

  ngOnDestroy() {
    // console.log('onDestroy guest-info component');
    const data = {choosedItems: this.choosedItems, form_inputs: this.form_inputs, user_info: this.user_info, event_details: this.event_details,
      formSubmited:  this.formSubmited, purchase_type: this.purchase_type, participantForm: this.participantForm, ageDisabled: this.ageDisabled,
      new_custom_inputs: this.new_custom_inputs, checkboxArr: this.checkboxArr, toggleParticipant: this.toggleParticipant
    };
    this.eventTicketsService.setDataParticipents(data);
  }

}
