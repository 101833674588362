<div class="col-md-12 col-xs-12">
    <form (ngSubmit)="onSubmit()" #restoreEventForm="ngForm">
    <div class="modal-header">
        <button type="button" class="close" (click)="dialogRef.close()">
           <span aria-hidden="true">&times;</span>
        </button>
    <div class="text-center"><h4>Restore event</h4></div>
    </div>
      <div class="modal-body mb15">
        <div class="mt15 mb15">
          <p>What status would you like to set the event to?</p>
        </div>
        <div class="row mb5">
            <div class="col-md-12 col-xs-12">
                <select name="status" class="form-control" ngModel #status="ngModel" required>
                  <option value="">Please select the desired action</option>
                  <option value="1">Restore to Approved</option>
                  <option value="2">Restore to Event Creation</option>
                  <option value="3">Restore to Pending Approval</option>
                  <option value="4">Restore to Closed</option>
                  <option *ngIf="event.status != 5" value="5">Move to Deleted</option>
                </select>
             <span *ngIf="status?.dirty && status?.invalid" class="item-error"> Must select an option </span>
              </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default mutate-float-left mr10" (click)="dialogRef.close()">Cancel</button>
        <button type="submit" class="btn btn-success mutate-float-right" [disabled]="restoreEventForm.invalid">Update</button>
    </div>
    </form>
</div>
