<mat-form-field appearance="fill" class="select-filter-events-form" [ngClass]="{'rtl' : dir === 'rtl'}">
<!--  <mat-label>label</mat-label>-->
  <mat-select [formControl]="panelValue"
              (selectionChange)="change($event)"
              panelClass="select-filter-events">
    <mat-option value="" [disabled]="true" i18n="select-filter|choose dates">choose dates</mat-option>
    <mat-option value="calendar" (click)="selectClick()" i18n="select-filter|Calendar">Calendar</mat-option>
    <mat-option value="today" i18n="select-filter|Today">Today</mat-option>
    <mat-option value="yesterday" i18n="select-filter|yesterday">yesterday</mat-option>
    <mat-option value="week" i18n="select-filter|week">this week</mat-option>
    <mat-option value="month" i18n="select-filter|month">this month</mat-option>
  </mat-select>
</mat-form-field>
