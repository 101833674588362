<div class="reader_signup">
  <section class="reader-main-section">
    <app-go-back [bigArrow]="true" [top]="90"></app-go-back>
    <div class="reader-title-section">
      <!-- <h1 i18n="page title">ניהול ברקודים</h1> -->
      <div class="reader-title">Barcode managment</div>
    </div>
    <div class="reader-form-section">
      <form class="reader-form col-md-12 col-xs-12"  (ngSubmit)="onSubmit()" #signupForm="ngForm">
        <input type="hidden" name="phoneCountryCode" ngModel #phoneCountryCode="ngModel" value="">
        <div class="form-group col-md-12 col-xs-12">
            <!-- <label i18n="label | reader title" for="phone" class="col-form-label">*שם הסורק / משתמש</label> -->
            <input
              i18n-placeholder="@@ReaderSignupScannerusername"
              class="form-control new-ui-input"
              type="text"
              id="title"
              name="title"
              ngModel
              #title="ngModel"
              required
              placeholder="Scanner \ user name">
            <span i18n="@@ReaderSignupErrorInvalidReaderName" class="item-error" *ngIf="title.touched && !title.valid">Please enter valid scanner name</span>
        </div>
        <div class="form-group col-md-12 col-xs-12">
            <!-- <label i18n="label | phone" for="phone" class="col-form-label">*טלפון</label> -->
            <input appPhoneNumber ng2TelInput
              i18n-placeholder="@@Phone"
              class="form-control new-ui-input"
              trim="blur"
              type="tel"
              name="phone"
              placeholder="Phone"
              value=""
              ngModel
              #phone="ngModel"
              required
              (hasError)="setErrorOnphoneNumber(!$event)"
              (ng2TelOutput)="setPhoneNumber($event)"
              (intlTelInputObject)="telInputObject($event, phone.value)"
              (countryChange)="onCountryChange($event)"
              [ng2TelInputOptions]="localizationService.getPhoneInputOptions()"
            />
            <app-error-creator [@slideIn] *ngIf="phone.touched && phone.invalid" [name]="'phone'" [control]="phone"></app-error-creator>
        </div>
        <div class="reader-btn col-md-12 col-xs-12 form-group">
          <button i18n="@@ReaderSignupAddUser" class="reader-add-user col-md-12 col-xs-12" type="submit">Add user</button>
        </div>
      </form>
      <div class="registered_events" *ngIf="readerData?.availableEvents.length > 0">
          <div class="col-md-12 col-xs-12 registered-scanners">
            <p i18n="@@ReaderSignupAddmoreeventstoreader" class="events-text text-center mb0">Add more events to scanner?</p>
          </div>
          <div class="col-md-12 col-xs-12 more-events table-responsive borderNone m0 p0">
            <table class="table table-hover">
              <tr *ngFor="let event of readerData?.availableEvents">
                <td class="col-md-1 col-xs-1">
                  <label class="radio-label">
                    <input
                      type="checkbox"
                      name="selected_events"
                      value="{{event.event_id}}"
                      [checked]="event.checked"
                      (change)="checkedEvents($event.target)">
                      <span class="checkmark"></span>
                  </label>
                </td>
                <td class="col-md-3 col-xs-3">{{event.event_id}}</td>
                <td class="col-md-8 col-xs-8">{{event.title}}</td>
              </tr>
            </table>
          </div>
        </div>
    </div>
  </section>

  <section class="registered_readers" *ngIf="readerData?.readers.length > 0">
    <div class="col-md-12 col-xs-12 registered-scanners">
      <p i18n="@@ReaderSignupRegisteredscanners" class="reg-scanners text-center mb0">Registered scanners:</p>
    </div>
    <div class="scanners-wrapper">
      <div class="scanners-section">
        <div class="table-responsive_ borderNone">
          <table class="table_ table-hover_ table-view">
            <tr *ngFor="let reader of readerData?.readers; let last = last; let even = even;" class="scanners-table">
              <td class="col-md-6 col-xs-6">
                <p class="scanner-title">{{reader.title}}</p>
                <p class="scanner-text">{{ reader.phone }}</p>
              </td>
              <td i18n-matTooltip="@@ReaderSignupPasswordHint" class="pwd" #tooltip="matTooltip" (click)="tooltip.toggle()" (mouseenter)="tooltip.toggle()" matTooltip="Password: {{reader.password}}"><i class="fa fa-key redKey"></i></td>
              <td class="text-center readerControls col-md-6 col-xs-6">
                <span i18n="@@Remove" class="btn removeReader" (click)="removeReader(reader.id)">Remove</span>
                <span i18n-title="@@ReaderSignupSendpasswordtomobile" i18n="@@ReaderSignupSendPassword" class="btn resend-btn" (click)="resendPassword(reader.id)" title="Send password to mobile">Send password</span>
              </td>
              <div *ngIf="even && !last" class="scanners-delimiter"></div>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div [routerLink]="['/reader-signin']" [queryParams]="{event_id: event_id}" class="reader-signin">
      <div class="signin-signup">
        <p i18n="@@ReaderSignupScannerLogin" class="text-center back_to_login">Scanner log in</p>
      </div>
    </div>
  </section>
</div>
