
import {map, startWith} from 'rxjs/operators';
import { ChangeSalesmanPipe } from '../../pipes/change-salesman.pipe';
import { EventService } from '../../services/event.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Seller } from '../../interfaces/seller.interface';

@Component({
  selector: 'app-change-seller-modal',
  templateUrl: './change-seller-modal.component.html',
  styleUrls: ['./change-seller-modal.component.css']
})
export class ChangeSellerModalComponent implements OnInit {
  sellerInput = new UntypedFormControl();
  options: Seller[] = [];
  filteredOptions: Observable<Seller[]>;
  
  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data, private eventService: EventService, private changeSalesmanPipe: ChangeSalesmanPipe) {}

  ngOnInit() {
    this.eventService.getAllSellers(this.data.event_id).subscribe(
      response => {

        if(this.data.purchase_user.salesman_id === 0) {
          this.options = response.data.filter(value => value.event_seller !== this.data.purchase_user.salesman_id);
        } else {
          this.options = response.data.filter(value => value.salesman_id !== this.data.purchase_user.salesman_id);
        }
        this.filteredOptions = this.sellerInput.valueChanges.pipe(startWith(''),map(value => this._filter(value)),);
      },
      error => this.dialogRef.close(error)
    );
  }

  private _filter(value: string) {
    const filterValue = value.toLowerCase();
    if(filterValue && filterValue !== '' && filterValue.length > 2){
      return this.changeSalesmanPipe.transform(this.options,filterValue);
    } else {
      return this.options;
    }
  }

  changeSeller(){
    if(this.sellerInput.invalid){
      this.sellerInput.markAsTouched();
      return;
    }

    let seller = this.options.filter(seller => seller.email === this.sellerInput.value);
    if(seller.length === 0 || seller.length > 1) {
      this.sellerInput.setErrors({'noSeller': true});
      return
    } else if(seller[0].event_seller === 0) {
      seller[0].salesman_id = 0;
    }
    let data = {
      'event_id': this.data.event_id,
      'purchase_id': this.data.purchase_user.purchase_id,
      'salesman_id': seller[0].salesman_id
    }
    this.eventService.setAllSellers(data).subscribe(
      response => {
        if(response.data){
          this.dialogRef.close(response.data);
        }
      },
      error => {
        this.dialogRef.close(error);
      }
    );
  }

  setSeller(){
    this.sellerInput.updateValueAndValidity();
  }

}
