import {Observable} from 'rxjs';
import hash from 'hash-it';
import moment from 'moment';

export abstract class AbstractCacheService<T> {
  readonly CACHE_DURATION_IN_SECONDS = 300; // 5 minute cache
  readonly DEFAULT_KEY = 'DEFAULT';
  // CHANGE IS_CACHE_SERVICE_ACTIVE PARAM to enable/disable cache
  readonly IS_CACHE_SERVICE_ACTIVE = true; // 'true' - enable or 'false' - disable

  private cache: {
    [id: string]: {
      expires: Date,
      value: Observable<T>
    }
  } = {};

  getValue(object?: any): Observable<T> {
    const key = object ? hash(object).toString() : this.DEFAULT_KEY;
    const item = this.cache[key];
    if (!item || !this.IS_CACHE_SERVICE_ACTIVE) {
      return null;
    }

    if (moment().isAfter(item.expires)) {
      return null;
    }

    return item.value;
  }

  /**
  * @function setValue - write value to cache
  * @params:
  * value - cached value;
  * object - pass object to create unique hash key ID;
  * duration - cache lifetime in seconds;
  *
  */
  setValue(value: Observable<T>, object?: any, duration: number = 0) {
    const key = object ? hash(object).toString() : this.DEFAULT_KEY;
    const expires = moment()
      .add(duration ? duration : this.CACHE_DURATION_IN_SECONDS, 'seconds')
      .toDate();

    this.cache[key] = {expires, value};
  }

  clearCache() {
    this.cache = {};
  }

  clearCacheByHash(object?: any) {
    const key = object ? hash(object).toString() : this.DEFAULT_KEY;
    //console.log(this.cache[key], key);
    if (this.cache.hasOwnProperty(key)) {
      this.cache[key].value = null;
      this.cache[key].expires = moment().subtract(1, 'minutes').toDate();
      //console.log(this.cache[key], key);
    }
  }
}
