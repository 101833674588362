import {Component, OnInit} from '@angular/core';
declare var window: any;
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-plasmic',
  templateUrl: './plasmic.component.html',
  styleUrls: ['./plasmic.component.css']
})


export class PlasmicComponent implements OnInit{
  component: string = 'Blog';
  type: 'preview'|'published'|'versioned'|string = environment.plasmic.type;
  // The above use the /preview/ route, which fetches the latest revisions, whether or not they were published!
  // Replace with /published/ to ensure you render only published changes, or /versioned/ to render specific versions
  // (specifying the version numbers with the project names in the URL, i.e. /versioned/PROJECTID@VERSION/COMPONENT).
  fetching: boolean = false;
  noData: boolean = false;
  async ngOnInit() {
    // document.documentElement.style.overflow = 'visible';

    this.fetching = true;
    const response = await fetch(
      `https://codegen.plasmic.app/api/v1/loader/html/${this.type}/${environment.plasmic.project_id}/${this.component}?hydrate=1&embedHydrate=1`,
      {
        headers: {
          // Your project ID and public API token
          'x-plasmic-api-project-tokens': `${environment.plasmic.project_id}:${environment.plasmic.api_token}`
          // You can find your PROJECT token by visiting
          // https://studio.plasmic.app/projects/[iuXTGxKy6H7DhoAS8VUTvy]/docs/loader#showToken=true
        }
      }
    );
    const result = await response.json();
    this.fetching = false;
    if(result.html && result.html.length) {
      document.body.innerHTML = result.html;
    }

// If you want hydration to happen, load and call the hydrater
    if (window.__plasmicHydrater) {
      window.__plasmicHydrater.hydrateAll();
    } else {
      var script = document.createElement('script');
      script.src = 'https://codegen.plasmic.app/static/js/loader-hydrate.js';
      document.body.appendChild(script);
    }
  }
}
