import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from '../../services/dialog.service';
import { GlobalsService } from '../../globals';
import { slideIn } from '../../animation';
import { NgForm } from '@angular/forms';
import { EventCategoryService } from '../../services/event-category.service';
import { AppResponse } from '../../interfaces/app-response.interface';
import { EventCategory } from '../../interfaces/event-category.interface';

@Component({
  selector: 'app-event-category-modal',
  templateUrl: './event-category-modal.component.html',
  styleUrls: ['./event-category-modal.component.css'],
  animations: [slideIn]
})
export class EventCategoryModalComponent implements OnInit {
  @ViewChild('categoryForm') categoryForm: NgForm;
  categoryData: EventCategory;
  title: string;
  serviceAction: string;
  status_codes = [
    {code: 1, name: 'active'},
    {code: 0, name: 'inactive'},
  ];
  languages;
  constructor(
    private eventCategoryService: EventCategoryService,
    public dialogRef: MatDialogRef<any>,
    private dialogService: DialogService,
    private globals: GlobalsService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { this.languages = this.globals.getCountryData(); }

  ngOnInit() {
    if (this.data && this.data.category && this.data.serviceAction) {
      this.categoryData = this.data.category;
      if (!this.categoryData.name) this.categoryData.name = {}; 
    } else {
      this.categoryData = {
      id: 0,
      name: {},
      status: 0,
      created_at: new Date(),
      updated_at: new Date()
      };
    }
      this.title = this.data.title;
      this.serviceAction = this.data.serviceAction;
  }

  onSubmit() {
    let categoryData = {};
        categoryData['id'] = this.data.category ? this.data.category.id : 0;
        Object.keys(this.categoryForm.value).forEach(item => {
            if (item.includes('name')) {
              delete this.categoryForm.value[item];
            }
        });
        this.categoryForm.value['name'] = this.categoryData.name;
        categoryData['value'] = this.categoryForm.value;
    if (this.categoryForm.valid) {
      this.globals.startLoader();
      this.eventCategoryService[this.serviceAction](categoryData).subscribe((response: any) => {
        this.globals.stopLoader();
        this.dialogRef.close({ success: true });
        this.dialogService.alertsModal('success', this.serviceAction, 3000, true);
      }, (error: AppResponse) => {
        this.globals.stopLoader();
        this.dialogService.alertsModal('eventCategories', (error.code) ? error.code : 4, 5000, false, error.log_id);
      });
    }
  }

}
