<!-- create a div under graphs will important alerts (like payment due time on event X) -->
<section class="cms_dashboard">
  <div class="row">
    <div class="col-md-12">
      <h1 i18n="@@CmsDashboard">Dashboard</h1>
    </div>
  </div>
  <div class="row scope_filters">
    <div class="col-md-6 col-xs-12">
      <p i18n="Display graph">Display graph</p>
      <select class="form-control" #showGraph (change)="changeGraph(showGraph.value)">
        <option selected="selected" value="" i18n="Choose option">Choose option...</option>
        <option value="incomes" i18n="Income">Income</option>
        <option value="counts" i18n="Total active events">Total active events</option>
      </select>
    </div>
  </div>
  <!-- <img *ngIf="loader" class="loader img-responsive" src="assets/images/preloader.gif" /> -->
  <mat-spinner *ngIf="loader" style="margin:0 auto;" strokeWidth="5" diameter="70"></mat-spinner>
  <div class="row">
    <div class="col-md-6 col-xs-12">
      <ng-container *ngIf="barChartIncomeData.length > 0">
        <p i18n="Display type">Display type</p>
        <select class="form-control" #scopeDropdownIncome (change)="changeScope(scopeDropdownIncome.value, 'incomes')">
<!--          <option i18n="yearly display" value="{{chartScope.yearly}}">תצוגה שנתית</option>-->
          <option i18n="@@CmsMonthlyD" value="{{chartScope.monthly}}">Monthly display</option>
          <option i18n="@@CmsWeeklyD" value="{{chartScope.weekly}}">Weekly display</option>
          <option i18n="weekly display" value="{{chartScope.hourly}}">Hourly</option>
        </select>
        <ng-container *ngIf="scopeDropdownIncome.value === '2'">
          <span *ngFor="let m of monthArr; let i = index;" (click)="getAndUpdateChartData(scopeDropdownIncome.value, 'incomes', i+1);setIndex(i)">
          <span class="month-name" [class.active]="selectedMonthIndex === i">{{m}}</span>
        </span>
        </ng-container>
        <p i18n="@@CmsIncomeBymonth" *ngIf="scopeDropdownIncome.value === '1'">Income by month</p>
        <p i18n="income by days" *ngIf="scopeDropdownIncome.value === '2'">Income by days</p>
        <p i18n="income by days per week" *ngIf="scopeDropdownIncome.value === '3'">Income by days (weekly)</p>
        <p i18n="income by hours" *ngIf="scopeDropdownIncome.value === '4'">Income by hours</p>
        <div style="display: block;" *ngIf="barChartIncomeData.length > 0 && barChartIncomeLabels.length > 0">
          <canvas baseChart [datasets]="barChartIncomeData" [labels]="barChartIncomeLabels" [options]="barChartOptions" [type]="barChartType" [legend]="true"></canvas>
        </div>
      </ng-container>
    </div>
    <mat-spinner *ngIf="loader" style="margin:0 auto;" strokeWidth="5" diameter="70"></mat-spinner>
    <div class="col-md-6 col-xs-12">
      <ng-container *ngIf="barChartCountData.length > 0">
        <p i18n="Display type">Display type</p>
        <select class="form-control" #scopeDropdownCount (change)="changeScope(scopeDropdownCount.value, 'counts')">
          <option i18n="@@CmsYearlyD" value="{{chartScope.yearly}}">Yearly display</option>
          <option i18n="@@CmsMonthlyD" value="{{chartScope.monthly}}">Monthly display</option>
          <option i18n="@@CmsWeeklyD" value="{{chartScope.weekly}}" [selected]="true">Weekly display</option>
          <option i18n="hourly display" value="{{chartScope.hourly}}">Hourly display</option>
        </select>
        <p i18n="@@CmsTotalActiveEventsTitle">Total active events during period</p>
        <div style="display: block" *ngIf="barChartCountData.length > 0 && barChartCountLabels.length > 0">
          <canvas baseChart [datasets]="barChartCountData" [labels]="barChartCountLabels" [options]="barChartOptions" [type]="barChartType" [legend]="false"></canvas>
        </div>
      </ng-container>
    </div>
  </div>
  <hr />
  <div class="pending_events">
    <h4 i18n="@@CmsEventCalendar">Event calendar:</h4>
    <app-cms-events-table [events_user_id]="0"></app-cms-events-table>
  </div>
</section>
