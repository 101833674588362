
import {tap, map} from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import 'rxjs/Rx';
import { GlobalsService } from '../globals';
import {AuthService} from './auth.service';

@Injectable()
export class ReportsService {
	api_base_url = environment.api_base_url;
	base_url = window.location.protocol + '//' + window.location.host;
	readonly REPORT_PERIOD_ALL = 0;
	readonly REPORT_PERIOD_DATES = 1;
	readonly REPORT_PERIOD_DAILY = 2;

	constructor(private http: HttpClient, private globals : GlobalsService, private authService: AuthService){}

	getPossibleReports(context, subject = null) {
    this.authService.isTokenExist();
		var url = '/api/possible-reports/' + context;
		if(context != 'system') {
			url += '/' + subject;
		}

		return this.http.get(this.api_base_url + url).pipe(
				map(
					(res: Response) => {
						return res;
					}
				),tap( res => res ),);
	}

	getReportHash(event_id, report_type, report_id,  periodOption, startDate, endDate) {

		if(periodOption == this.REPORT_PERIOD_DAILY) {
			startDate = new Date();
			endDate = new Date();
			startDate.setHours(0,0,0,0);
			endDate.setHours(23,59,59,0)
		}
		else if(periodOption == this.REPORT_PERIOD_ALL) {
			startDate = null;
			endDate = null;
		}

		return this.http.post(this.api_base_url + '/api/ready-report', {
					event_id: event_id,
					report_type: report_type,
					report_id: report_id,
					report_period: periodOption,
					start_date: startDate,
					end_date: endDate
				} ).pipe(
				map(
					(res: hashRes) => {
						return res;
					}
				),tap( res => res ),);
	}

	saveReport(name, query, context, event = null, reportId = null) {
		return this.http.post(this.api_base_url + '/api/new-report', {
			name: name,
			query: query,
			role: 1,
			context: context,
			event: event,
			report_id: reportId
		} ).pipe(
		map(
			(res: SavedReport) => {
				return res;
			}
		),tap( res => res ),);
	}


}

interface hashRes {
	hash: string;
	error: string;
}

interface SavedReport {
	error: string;
	details: string;
}

interface CustomReportRes {
	error: string;
	name: string;
	query: string

}
