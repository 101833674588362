import {Component, OnInit, AfterViewInit, HostListener, OnDestroy, Inject, ElementRef, ViewChild} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
// -----------------------------RxJS------------------------------
import { Subscription } from 'rxjs';
// -----------------------Angular Material------------------------
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
// ---------------------------Services----------------------------
import { GlobalsService } from '../globals';
import { NavbarService } from '../services/navbar.service';
import { AuthService } from '../services/auth.service';
import { FacebookService } from '../services/facebook.service';
import { DialogService } from '../services/dialog.service';
import { LoginToAppResponse } from './../interfaces/authentication.interface';
import { WalletConnectService } from '../services/wallet-connect.service';

import $ from 'jquery';
import {CookieService} from "ngx-cookie-service";
import {environment} from "../../environments/environment";
// ---------------------------------------------------------------

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostListener('window:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
      const capsOn = event.getModifierState && event.getModifierState('CapsLock');
      if(capsOn){
        this.capsLock = true;
      } else {
        this.capsLock = false;
      }
    }

  loginShow: boolean = true;
  clicked: boolean = false;
  capsLock: boolean = false;
  queryParams;
  facebookUserInfo: any;

  //Activation query param variables
  userEmail: string;
  error: string;

  currentUrl = '';
  isCreateEvent: boolean = false;
  isSignInPage: boolean = false;

  isConnect = false;
  walletLoginSubscriptopn: Subscription;
  walletSignUpSubscriptopn: Subscription;
  isWalletSignUp = false;
  zIndex: any = 1000;
  walletReg: boolean = false;
  currenUsertUrl: string = '';
  isShowOtp: boolean = false;
  captchaToken = '';
  userNotExitsFields = {};
  @ViewChild('ref') ref;
  selectedFlow: 'phone'|'email'|'p_key'| ''   = '';
  topViewsEvent: null|any = null; // event object
  public baseEventImgUrl = environment.images_base_url + '/gallery/desktop';
  sendSmsTimer: number = 0;

  constructor(
    private facebookService: FacebookService,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<LoginComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,

    private nav: NavbarService,
    private globals:GlobalsService,
    private authService: AuthService,
    private router: Router,
    private walletConnectService: WalletConnectService,
    private elementRef: ElementRef,
    private _cookieService:CookieService
    ) {
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        this.userEmail = params['email'];
        this.error = params['error'];
        if(this.error === 'link'){
          this.dialogService.alertsModal('errors',21,3000,false);
        } else if(this.error === 'activated'){
          this.dialogService.alertsModal('errors',22,3000,false);
        }
        this.queryParams = this.router.routerState.snapshot.root.queryParams;

      });
  }

  ngOnInit() {
    this.isCreateEvent = (this.data && this.data.action == 'create-event');
    if (this.data) {
      if (this.data.hasOwnProperty('loginShow')) {
        this.loginShow = this.data.loginShow;
      }
      if (this.data.email) {
        this.userEmail = this.data.email;
      }
    }
    // console.log('login', this.data, this.isCreateEvent);
    this.nav.setIsNewUi(true);
    this.isSignInPage = this.router.url.includes('/signin');
    // // Left Block Top Views event
    // const topEventInCookie = this._cookieService.get('eventWithMostViews');
    // if(topEventInCookie) {
    //   this.topViewsEvent = JSON.parse(topEventInCookie);
    // }
  }

  ngAfterViewInit() {
    this.activatedRoute.queryParams.subscribe(params => {
        if (params.signUpWithEmail) {
          this.router.navigate(['/']).then(()=> {
            let dialogRef = this.dialog.open(LoginComponent, {
              data: {email: params.signUpWithEmail, loginShow: false},
              autoFocus: true,
              width: '95%',
              maxWidth: '1280px',
              backdropClass: 'loginModalBackdrop',
              panelClass: 'loginModal'
            });
            // https://localhost:4200/signin?signUpWithEmail=test@test.net
          });

        }
      }
    );
    if (this.data && this.data.email) {
      this.userEmail = this.data.email;
    }
    this.zIndex = $(this.elementRef.nativeElement).closest('.cdk-overlay-container');
  }

  ngOnDestroy(){
    console.log('OnDestroy login');
    this.walletLoginUnsubscribe();
  }

  FBlogin(){
    this.clicked = true;
    this.globals.startLoader();
    this.facebookService.getFBAccessCode('', this.isCreateEvent);
  }

  signInWallet() {
    console.log('signInWallet', this.isConnect);
    this.isConnect = true;
    this.zIndex.attr("style", "z-index:1000");
    this.zIndex.attr("style", "display: none;");
    // this.closeLoginModal();
    this.walletConnectService.setProviderOptions();
    this.walletConnectService.waletAuthorization();
    this.walletLoginSubscriptopn = this.walletConnectService.getWalletConnectUser.subscribe((data: any) => {
      console.log('walletLogin response: ', data);
      if (data && data.error) {
        this.closeLoginModal();
        console.log('walletLogin response error: ', data);
        if (data.error && data.error.code == 4001) {
          this.dialogService.alertsModal('cryptoCurrency', 'walletConnect', 3000, false);
        }
        if (data.error && data.error.code == 4.1) {
          this.dialogService.alertsModal('cryptoCurrency', 'walletConnectUnique', 3000, false);
        }
        if (data.connected) {
          this.walletLoginUnsubscribe();
        }
        if (data.errors == 5) {
          console.log('signInWallet User log-in error: ', data);
          this.zIndex.attr("style", "z-index:1000");
          this.dialogService.alertsModal('cryptoCurrency', 'walletConnectModalClosed', 3000, false);
          this.walletReg = false;
        }
        if (data.errors == 6) {
          console.log('signInWallet error: User Rejected: ', data);
          this.zIndex.attr("style", "z-index:1000");
          this.isWalletSignUp = false;
          // this.isConnect = false;
          // this.isShowOtp = false;
          this.dialogService.alertsModal('cryptoCurrency', 'userRejected', 3000, false);
        }
        if (data.errors == 1) { // User denied message signature
          console.log('signInWallet error: User denied message signature: ', data);
          this.zIndex.attr("style", "z-index:1000");
          this.isWalletSignUp = false;
          this.isConnect = false;
          this.isShowOtp = false;
          this.dialogService.alertsModal('cryptoCurrency', 'userDenied', 3000, false);
        }
        if (data.errors == 11) {
          console.log('signUpWallet User registration server error, need to login later. ', data);
          this.zIndex.attr("style", "z-index:1000");
          this.isWalletSignUp = false;
          // this.isShowOtp = false;
          // this.loginShow = true;
          this.isConnect = false;
          this.dialogService.alertsModal('errors', 40, 5000, false);
        }
        if (data.errors == 42 || data.error.code == 42) {
          this.captchaToken = '';
          console.log('signUpWallet User registration error, need to check captcha. ', data);
          this.zIndex.attr("style", "z-index:1000");
          this.isWalletSignUp = false;
          this.isConnect = false;
          this.dialogService.alertsModal('errors', 42, 5000, false);
        }
      } else if (data && !data.error && data.connected && data.response) {
        this.closeLoginModal();
        console.log('walletLogin: ', data);
        this.clicked = false;
        if (data.response.data && data.response.data.auth) {
          setTimeout(()=>{
            this.zIndex.attr("style", "display: block;");
            this.dialogService.alertsModal('success', 'letsGoHome' , 2000, true);
          }, 500)
          setTimeout(()=>{
            this.updateAuthUser(data);
            window.location.replace('');
          }, 3000)
        } else {
          // this.dialogService.alertsModal('errors', tokenData.original.error, 5000, false);
          this.nav.checkUser();
        }
      } else if (data && !data.error && !data.connected && data.response) {
        if (data.errors == 4) { // Selected wallet not registered
          this.zIndex.attr("style", "display: block;");
          this.dialogService.alertsModal('cryptoCurrency', 'walletConnectReg', 3000, false);
          // const appSignin = $(this.elementRef.nativeElement).closest('.cdk-global-overlay-wrapper');
          // appSignin ? appSignin.attr("style", "display: none;") : false;
          console.log('walletConnectReg');
          this.loginShow = false;
          this.isWalletSignUp = true;
          this.walletReg = true;
        } else if (!data.errors && data.response.code.success) {
          console.log('data.response.code.success'); // return nonce
        }
        console.log('data response');
      }
      if (data && data.connected) {
        this.walletLoginUnsubscribe();
      }
    }, error =>  {
      this.dialogService.alertsModal('errors', 4.1, 5000, false);
      this.clicked = false;
      this.globals.stopLoader();
      this.closeLoginModal();
    });
    console.log('wConnect', this.isConnect);
  }

  walletLoginUnsubscribe() {
    if (this.walletLoginSubscriptopn) { this.walletLoginSubscriptopn.unsubscribe();}
  }

  setCaptcha(captcha) {
    this.captchaToken = captcha;
  }

  signUpWallet(userData = null) {
    console.log('signUpWallet', userData);
    if (userData) {
      userData['captchaToken']=this.captchaToken;
      this.walletReg = true;
      this.zIndex.attr("style", "z-index:0");
      // console.log('set style', this.zIndex);
      this.walletConnectService.waletAuthorization(null, false, userData);
      this.walletSignUpSubscriptopn = this.walletConnectService.getWalletConnectUser.subscribe((data: any) => {
        console.log('signUpWallet response: ', data);
        this.zIndex.attr("style", "z-index:1000");
        if (data && data.error && !data.connected && !data.response) {
          console.log('signUpWallet response error: ', data, data.error.code);
          if (data.error.code == 4001) {
            this.dialogService.alertsModal('cryptoCurrency', 'walletConnect', 3000, false);
            console.log('signUpWallet response error: ', data.error.code);
            this.closeLoginModal();
          }
          if (data.error.code == 4.1) {
            this.dialogService.alertsModal('cryptoCurrency', 'walletConnectError', 3000, false);
            console.log('signUpWallet response error: ', data.error.code);
            this.walletReg = false;
          }
          if (data.error.code == 101) {
            this.dialogService.alertsModal('cryptoCurrency', 'walletConnectPhoneInvalid', 3000, false);
            console.log('signUpWallet phone error: ', data.error.code);
            this.walletReg = false;
          }
          // if (data.error.code == 35) {
          //   this.dialogService.alertsModal('errors', data.error.code, 3000, false);
          //   console.log('signUpWallet OTP error: ', data.error.code);
          //   this.walletReg = false;
          // }
          if (data.errors == 5) {
            console.log('signUpWallet User registration error: ', data);
            this.zIndex.attr("style", "z-index:1000");
            this.dialogService.alertsModal('cryptoCurrency', 'walletConnectModalClosed', 3000, false);
            this.walletReg = false;
            this.closeLoginModal();
          }
          if (data.errors == 6) {
            console.log('signUpWallet User registration error: ', data);
            this.zIndex.attr("style", "z-index:1000");
            this.dialogService.alertsModal('cryptoCurrency', 'userRejected', 3000, false);
            this.walletReg = false;
            this.closeLoginModal();
          }
          if (data.errors == 7) {
            console.log('signUpWallet User registration error: ', data);
            this.zIndex.attr("style", "z-index:1000");
            this.dialogService.alertsModal('cryptoCurrency', 'walletConnectAuthorization', 3000, false);
            this.walletReg = false;
            this.closeLoginModal();
          }
          if (data.errors == 11) {
            console.log('signUpWallet User registration server error, need to login later. ', data);
            this.isWalletSignUp = false;
            this.isShowOtp = false;
            this.loginShow = true;
            this.dialogService.alertsModal('errors', 40, 5000, false);
          }
          if (data.errors == 42) {
            console.log('signUpWallet User registration error, need to check captcha. ', data);
            this.isWalletSignUp = false;
            this.isShowOtp = false;
            this.loginShow = true;
            this.captchaToken = '';
            this.dialogService.alertsModal('errors', 42, 5000, false);
          }

        } else if (data && !data.error && data.connected && data.response) {
          console.log('signUpWallet User registration start: ', data);
          this.zIndex.attr("style", "z-index:1000");
          this.closeLoginModal();
          this.updateAuthUser(data);
          this.walletSignUpUnsubscribe();
        }
        if (data && !data.error && !data.connected && data.response) {
          this.zIndex.attr("style", "z-index:1000");
          if (data.errors == 2) { // Connected wallet already used
            console.log('signUpWallet walletConnectUnique: ', data.response);
            this.dialogService.alertsModal('cryptoCurrency', 'walletConnectUnique', 3000, false);
            this.walletReg = false;
          } else if (data.errors == 42) {
            console.log('signUpWallet User registration error, need to check captcha. ', data);
            this.captchaToken = '';
            this.dialogService.alertsModal('errors', 42, 3000, false);
          } else if (data.errors == 3) {
            this.captchaToken = '';
            console.log('signUpWallet email is used: ', data.response);
            this.dialogService.alertsModal('cryptoCurrency', 'walletConnectEmail', 3000, false);
            this.walletReg = false;
            this.walletSignUpUnsubscribe()
          } else if (data.errors == 3.1) {
            console.log('signUpWallet phone is used: ', data.response);
            this.dialogService.alertsModal('cryptoCurrency', 'walletConnectPhone', 3000, false);
            this.walletReg = false;
            this.walletSignUpUnsubscribe()
          } else if (data.errors == 8) {
            console.log('signUpWallet request OTP code: ', data.response);
            this.dialogService.alertsModal('success', 'otpCode', 3000, true);
            this.isShowOtp = true;
          } else if (data.errors == 10 || (data.response.original && data.response.original.error == 37)) {
            console.log('Requests sending too often: ', data.response);
            this.dialogService.alertsModal('errors', data.response.original.error, 3000, false);
            this.walletReg = false;
            this.walletSignUpUnsubscribe();
          } else if (data.response && data.response.code == 35) {
            console.log('signUpWallet OTP code not corrected: ', data.response);
            this.dialogService.alertsModal('errors', data.response.code, 3000, false);
            this.walletReg = false;
            this.walletSignUpUnsubscribe();
          } else if (data.response && (data.response.code == 43 || data.response.code == 44 || data.response.code == 45)) {
            this.dialogService.alertsModal('errors', data.response.code, 3000, false);
            this.walletReg = false;
            this.walletSignUpUnsubscribe();
          }
          // if ((data.response && data.response.code == 35) || (data.response.original && data.response.original.error == 37)) {
          //   this.walletSignUpUnsubscribe();
          // }
        }
      }, error =>  {
        console.log('signUpWallet error: ', error);
        this.zIndex.attr("style", "z-index:1000");
        this.dialogService.alertsModal('errors', 4.1, 5000, false);
        this.clicked = false;
        this.globals.stopLoader();
      });
    } else {
      console.log('Not have user data', userData);
    }
  }

  walletSignUpUnsubscribe() {
    if (this.walletSignUpSubscriptopn) { this.walletSignUpSubscriptopn.unsubscribe();}
  }

  updateAuthUser(data) {
    this.authService.storeTokens(data.response.data.auth.session, data.response.data.auth.refresh);
    this.authService.saveUserCookies(data.response.data.user_data, data.response.data.user_data.facebook_id);
    // this.dialogService.alertsModal('success', data.response.code, 2000, true);
    this.nav.checkUser();
    this.nav.updateUserProfileImage(data.response.data.user_data.social_img);
    let tokenData = data.response.data;
    tokenData['original'] = {personal_info: data.response.data.user_data.personal_info};
    tokenData['original'] = {success: data.response.code};
    tokenData['original'] = {uid: data.response.data.user_data.user_id};
    tokenData['original'] = {facebook_id: data.response.data.user_data.facebook_id};
    if (data.response.data.user_data.personal_info) {
      this.nav.UpdateUser(tokenData.original.uid,tokenData.user_data.type, tokenData.original.facebook_id, false);
    } else {
      this.dialogService.personalInfo(tokenData, true);
    }
    if (data.response.hasOwnProperty('wallet')) {
      this.authService.storeLoggedInWallet(data.response.wallet);
    }
    if (!this.isCreateEvent) {
      window.location.replace(this.currenUsertUrl);
    }
  }

  setUsertUrl(value) {
    // console.log('setUserUrl', value);
    this.currenUsertUrl = value;
  }

  signUpWalletBtn() {
    console.log('signUpWalletBtn before: ', this.isConnect, this.isWalletSignUp);
    this.isConnect = true;
    this.isWalletSignUp = true;
    console.log('signUpWalletBtn after: ', this.isConnect, this.isWalletSignUp);
  }

  logInToggle() {
    console.log('logInToggle before', this.isWalletSignUp, this.isConnect);
    this.isConnect = false; // !this.isConnect;
    this.loginShow = !this.loginShow;
    this.isWalletSignUp = false;
    this.isShowOtp = false;
    console.log('logInToggle after', this.isWalletSignUp, this.isConnect, this.isShowOtp);
  }

  closeLoginModal(e: any = {}) {
    // console.log('reloadOnSuccess', e); // e.urlToNavigate - from signIn.emit eventEmitter
    if(e.hasOwnProperty('urlToNavigate') && e.urlToNavigate === '') {
      setTimeout(()=>{
        this.router.navigate(['/']);
      },500);
    }
    if (this.dialogRef && Object.keys(this.dialogRef).length > 0) {
      this.dialogRef.close(this.data);
    }

  }


  toggleIfUserNotExitAfterLogin(e) {
    this.userNotExitsFields = e.userNotExitsFields;
    // console.log(e);
    this.logInToggle();
  }

  setSelectedFlow(value) {
    this.selectedFlow = value;
    // console.log('selectedFlow', this.selectedFlow);
  }

  issendSmsTimer(time) {
    // console.log('issendSmsTimer', time);
    this.sendSmsTimer = time;
  }


}
