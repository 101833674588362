import { DialogService } from '../../services/dialog.service';
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { EventService } from '../../services/event.service';
import {UserService} from '../../services/user.service';
import {AuthService} from '../../services/auth.service';
import {CurrencyLocaleService} from '../../services/currency-locale.service';
import {GlobalsService} from '../../globals';

@Component({
	selector: 'app-ticket-templates',
	templateUrl: './ticket-templates.component.html',
	styleUrls: ['./ticket-templates.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TicketTemplatesComponent implements OnInit {

	hash: string = "";
	data: any;
	noHash: boolean = false;
	localeId: string;

	constructor(private eventService: EventService,
              private activatedRoute: ActivatedRoute,
              private dialogService: DialogService,
              private router: Router,
              private userService: UserService,
              public auth: AuthService,
              private currencyLocale: CurrencyLocaleService,
				private globalsService: GlobalsService) {
		this.localeId = this.globalsService.getLocaleId();
	}

	ngOnInit() {
		this.activatedRoute.params.subscribe(params => {
			this.hash = params['hash'];
		});

	    if(this.hash){
			this.eventService.getBarcodeData(this.hash)
			.subscribe(
				(response: any) => {
					if(response.data) {
            if (response.data.hasOwnProperty('redirect_url') && response.code === 'success') {
              if(!this.auth.getUser()){
                // console.log(this.router.url);
                this.userService.openLoginModal();
                return false;
              }
              this.router.navigateByUrl('/', {skipLocationChange: false}).then(() => {
                this.router.navigate(['/my-tickets']);
              });
            } else {
              this.data = response.data;
              this.data.location = (this.data.hasOwnProperty('location')) ? this.data.location : '';
              const startDateSplited = this.data.start_date.split(' ');
              const dateSPlited = startDateSplited[0].split('-');
              this.data.start_date = dateSPlited[2] + '-' + dateSPlited[1] + '-' + dateSPlited[0];
            }
					} else {
						this.noHash = true;
					}
				},
				error => {
					this.dialogService.alertsModal('errors', (error.code) ? error.code : 4 ,5000, false, error.log_id);
					this.noHash = true;
				}
			);
	    }
	}
  getLocale(currency) {
    return this.currencyLocale.getLocaleForCurrencyPipe(currency)
  }
}
