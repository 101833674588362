import { GlobalsService } from '../globals';
import { Component, OnInit } from '@angular/core';
import { NavbarService } from '../services/navbar.service';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css'],
})
export class TermsComponent implements OnInit {
  local_id: string;

  constructor(
    private globals: GlobalsService,
    private navbarServise: NavbarService,
    private titleService: Title,
    private meta: Meta
  ) {
    this.setLocalId();
  }

  ngOnInit() {
    this.navbarServise.setIsNewUi(true);
    let top = document.getElementById('page');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
    this.titleService.setTitle('Getin - Terms and Conditions');
    this.meta.updateTag({ name: 'description', content: 'Terms and conditions for consumers'});
  }

  setLocalId() {
    this.local_id = this.globals.getLocaleId();
  }
}
