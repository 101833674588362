import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import {DomSanitizer, Meta, Title} from '@angular/platform-browser';
import { Router } from '@angular/router';
import { GlobalsService } from '../../globals';
import { emailRegex } from '../../regex';
import { DialogService } from '../../services/dialog.service';
import { NavbarService } from '../../services/navbar.service';
import { SupportService } from '../../services/support.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit, OnDestroy {

  @ViewChild('contactUsPageForm') contactForm: NgForm;
  emailRegex = emailRegex;
  main_info = [{
    city: 'MIAMI: ',
    address: '33 SW 2nd Ave, Suite 1102, FL 33130',
    phone: ''
  },];
  contact_info = {
      name: '',
      email: '',
      description: ''
    }
  localeId = this.globals.getLocaleId();

  constructor(private navbar: NavbarService,
              private globals: GlobalsService,
              private supportService: SupportService,
              private dialogService: DialogService,
              private router: Router,
              public sanitizer: DomSanitizer,
              private titleService: Title,
              private meta: Meta) { }

  ngOnInit() {
    this.navbar.setIsNewUi(true);
    setTimeout(() => {this.navbar.noPadding = 'removePadding';}, 200);
    this.navbar.hide();

    this.titleService.setTitle('Getin - Contact Us');
    this.meta.updateTag({ name: 'description', content: `${this.main_info[0].city}${this.main_info[0].address} ☎ ${this.main_info[0].phone}`});
  }

  closePage() {
    this.router.navigate(['/']);
  }

  onSubmit(){
		this.globals.startLoader();
    if (this.contactForm && this.contactForm.valid) {
      console.log('send', this.contactForm);
      const formData = new FormData();
      for (const item in this.contactForm.value) {
        formData.append(item, this.contactForm.value[item]);
      }
      this.supportService.sendContactForm(formData).subscribe(
        response => {
            this.globals.stopLoader();
            this.dialogService.alertsModal('success', 'success', 5000, true);
            this.contactForm.resetForm();
            setTimeout(() => {
              this.router.navigate(['/']);
            }, 2000);
          },
            error => {
          this.globals.stopLoader();
          this.dialogService.alertsModal('errors', 2, 5000, false);
          this.contactForm.resetForm();
        }
      );
    } else {
      console.log('form', this.contactForm);
      this.globals.stopLoader();
      this.markFormGroupTouched(this.contactForm.form);
    }
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      console.log('control', control);
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
    this.scrollToErrorField();
  }

  scrollToErrorField() {
    setTimeout(() => {
      const results = (<HTMLElement>document.querySelector('input.form-control-input.ng-pristine.ng-invalid.ng-touched'));
      console.log('results', results);
      if (results) {
        results.focus();
        results.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 200);
  }

  ngOnDestroy() {
    this.titleService.setTitle('Getin');
  }

}
