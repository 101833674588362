import {TweedFrontendSDK} from "@paytweed/frontend-sdk";
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TweedSDKContext {
    disableTweed = false;
    api = environment.api_tweed_base_url;
    isProd = this.api === 'https://tweed.get-in.com' ? true : false;
    defaultBlockchainIds = this.isProd ?
      ["polygon"] :
      ["polygon"];
    user_prefix = this.isProd ? 'get-in.com-' : 'getin-nextgen.com-';
    constructor(private authService: AuthService) {
      const user = this.authService.getUserInfo();
      const fromWallet = this.authService.getFromWallet();
      const toWallet = this.authService.getToWallet();
      const tokenId = this.authService.getTokenId();
      // console.log('TweedSDKContext UserInfo', user, user.email, user.phone, user.user_id);
      if (user.user_id !== undefined) {
        this.tweedSdk = this.disableTweed ? undefined : TweedFrontendSDK.setup({
          sendMessageToBackend: async (message, ) => {
            const authenticatedUser = {
              email: user.email ? user.email : user.phone,
              id: this.user_prefix + user.user_id.toString(),
            };
            // const authenticatedUser = {
            //   email: "12347test@example.com",
            //   id: "12347",
            // };
            let payload = { message, id: authenticatedUser.id, email: authenticatedUser.email };
            if(fromWallet) {
              payload['fromWallet'] = fromWallet;
            }
            if(toWallet) {
              payload['toWallet'] = toWallet;
            }
            if(tokenId) {
              payload['tokenId'] = tokenId;
            }
            const response = await fetch(this.api + "/message", {
              body: JSON.stringify(payload),
              headers: { "Content-Type": "application/json" },
              method: "POST",
            });
            const { answer } = await response.json();
            return answer;
          },
          theme: 'dark',
          defaultBlockchainIds: this.defaultBlockchainIds,
        })
      }
    }

    async setupTweed() {
      if(this.disableTweed) {
        this.tweedSdk = undefined;
        return
      } else {
        const user = this.authService.getUserInfo();
        const fromWallet = this.authService.getFromWallet();
        const toWallet = this.authService.getToWallet();
        const tokenId = this.authService.getTokenId();
        // console.log('TweedSDKContext setupTweed UserInfo ', user, user.email, user.phone, user.user_id);
        if (user !== undefined && user.user_id !== undefined) {
          this.tweedSdk = await TweedFrontendSDK.setup({
            sendMessageToBackend: async (message, ) => {
              const authenticatedUser = {
                email: user.email ? user.email : user.phone,
                id: this.user_prefix + user.user_id.toString(),
              };
              let payload = { message, id: authenticatedUser.id, email: authenticatedUser.email };
              if(fromWallet) {
                payload['fromWallet'] = fromWallet;
              }
              if(toWallet) {
                payload['toWallet'] = toWallet;
              }
              if(tokenId) {
                payload['tokenId'] = tokenId;
              }
              const response = await fetch(this.api + "/message", {
                body: JSON.stringify(payload),
                headers: { "Content-Type": "application/json" },
                method: "POST",
              });
              const { answer } = await response.json();
              return answer;
            },
            theme: 'dark',
            defaultBlockchainIds: this.defaultBlockchainIds,
          })
        }
      }

    }

    tweedSdk: any;
  }
