<div class="btn-wrapper">
  <button
    *ngIf="userId && userId === producerId && authService.isConnected() && page!=='producer'" style="color: #82FFCA"
    [routerLink]="['/producer-edit']"
    [ngClass]="{'follow-producer-btn': page=='producer'}"
    i18n="producer-header | edit"
    class="follow-btn"
    type="button"
  >
    Edit
  </button>
  <!--Producer page "Edit page" button-->
  <button
    *ngIf="userId && userId === producerId && authService.isConnected() && page=='producer'"
    [routerLink]="['/producer-edit']"
    [ngClass]="{'follow-producer-btn': page=='producer'}"
    i18n="producer-header | edit"
    class="follow-btn"
    type="button"
  >
    Edit page
  </button>
  <!--"Follow" button-->
  <button
    *ngIf="userId && userId !== producerId && (authService.isConnected() && isFollowingProducer)"
    [disabled]="disableFollowButton"
    [ngClass]="{'follow-producer-btn': page=='producer'}"
    (click)="updateFollowProducer('unfollow')"
    i18n="producer-header | following"
    class="follow-btn"
    type="button"
  >
    Following
  </button>
  <button
    *ngIf="userId !== producerId && (!authService.isConnected() || !isFollowingProducer)"
    [disabled]="disableFollowButton"
    [ngClass]="{'follow-producer-btn': page=='producer'}"
    (click)="updateFollowProducer('follow')"
    i18n="producer-header | follow"
    class="follow-btn follow" type="button"
  >
    Follow
  </button>
</div>
