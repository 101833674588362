import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-sound-cloud-url-dialog',
  templateUrl: './sound-cloud-url-dialog.component.html',
  styleUrls: ['./sound-cloud-url-dialog.component.css']
})
export class SoundCloudUrlDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<any>) {}
  url;

  ngOnInit() {
  }

  submit(form) {
    this.dialogRef.close(form.controls.url.value);
  }

}