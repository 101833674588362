import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, ElementRef } from '@angular/core';
import { GlobalsService } from '../../globals';
import { NavbarService } from 'src/app/services/navbar.service';
import $ from 'jquery';

@Component({
  selector: 'app-are-u-sure-modal',
  templateUrl: './are-u-sure-modal.component.html',
  styleUrls: ['./are-u-sure-modal.component.css']
})
export class AreUSureModalComponent implements OnInit {

  strings: object;
  eventInfo;
  type = 0;
  noData = false;
  extrasStr: string;
  modalText: string = '';
  isNewUi: boolean = false;
  newUI: any;

  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data,
  private globals: GlobalsService, public nav: NavbarService, private elementRef: ElementRef) {
    this.extrasStr = data.extrasStr;
  }

  ngOnInit(){
    // this.nav.setIsNewUi(true);
    this.nav.currentNewUiStatus.subscribe(status => {
      this.isNewUi = status;
      console.log('new UI status: ', status);
      if (status) {
        this.newUI = $(this.elementRef.nativeElement).closest('.areUSUreModal .mat-dialog-container');
        console.log('new UI is: ', this.newUI);
        if (this.newUI) {
          this.newUI.attr("style", "color: white; background-color: #242831; border-radius: 25px;");
        }
      }
      // this.changeDetector.detectChanges();
    });

    this.strings = {
      'markAsTrending': {
        he: 'האם אתה בטוח שברצונך לשנות את המגמה של האירוע?',
        en: 'Are you sure you want to change the trending of the event?',
        es: 'Está seguro de que desea cambiar la tendencia del evento?'
      },
      'deletePaymentGateway': {
        he: 'האם אתה בטוח שברצונך למחוק מסוף סליקה זה?',
        en: 'Are you sure you want to delete this payment gateway?',
        es: 'Está seguro de que desea eliminar esta pasarela de pago?'
      },
      'declineEvent': {
        he: 'האם אתה בטוח שברצונך לדחות את האירוע?',
        en: 'Are you sure you want to decline the event?',
        es: 'Está seguro de que desea rechazar el evento?'
      },
      'deleteEvent': {
        he: 'האם אתה בטוח שברצונך למחוק את האירוע?',
        en: 'Are you sure you want to delete the event?',
        es: 'Está seguro de que desea eliminar el evento?'
      },
      'closeEvent': {
        he: 'האם אתה בטוח שברצונך לסגור את האירוע?',
        en: 'Are you sure you want to close the event?',
        es: 'Está seguro de que desea cerrar el evento?'
      },
      'removeCustomInputs': {
        he: 'בטוח תרצה להסיר את כל השדות המותאמים אישית?',
        en: 'Are you sure you want to remove all custom inputs?',
        es: 'Está seguro de que desea eliminar todas las entradas personalizadas?'
      },
      'deleteUser': {
        he: 'האם אתה בטוח שברצונך למחוק את המשתמש?',
        en: 'Are you sure you want to delete this user?',
        es: 'Está seguro de que desea eliminar este usuario?'
      },
      'restoreUser': {
        he: 'האם אתה בטוח שברצונך לשחזר את המשתמש?',
        en: 'Are you sure you want to restore this user?',
        es: 'Está seguro de que desea restaurar este usuario?'
      },
      'approveNoItems': {
        he: 'באישור משתתף זה תחרוג מכמות הכרטיסים שהוגדרו מראש לפריט זה. להמשיך באישור?',
        en: 'If approved, the quantity set for this item will exceed the limit. Continue anyway?',
        es: 'Si se aprueba, la cantidad establecida para este artículo excederá el límite.Continuar de todos modos?'
      },
      'overrideSalesmen': {
        he: 'שכפול אנשי מכירות מאירוע אחר ידרוס את אנשי המכירות הקיימים, האם אתה בטוח?',
        en: 'Duplicating salesmen from another event will override the existing salesmen. Continue anyway?',
        es: 'Duplicar vendedores de otro evento anulará a los vendedores existentes. Continuar de todos modos?'
      },
      'deleteEventPlusTitle': {
        he: 'האם ברצונך למחוק את אירוע '+this.extrasStr+'?',
        en: 'Are you sure you want to delete event '+this.extrasStr+'?',
        es: 'Está seguro de que desea eliminar el evento' + this.extrasStr + '?'
      },
      'deleteProfileImage': {
        he: 'האם ברצונך למחוק את תמונת הפרופיל שלך?',
        en: 'Are you sure you want to delete your profile picture?',
        es: 'Estás seguro de que deseas eliminar tu foto de perfil?'
      },
      'deleteSlide': {
        he: 'זענט איר זיכער אַז איר ווילן צו ויסמעקן די רוק',
        en: 'Are you sure you want to delete slide',
        es: '¿Está seguro de que desea eliminar la diapositiva?'
      },
      'deleteCoverPhoto': {
        he: 'Are you sure you want to delete your cover photo?',
        en: 'Are you sure you want to delete your cover photo?',
        es: 'Estás seguro de que deseas eliminar tu foto de portada?'
      },
      'instagramDisconnect': {
        he: 'Are you sure you would like to disconnect your Instagram account?',
        en: 'Are you sure you would like to disconnect your Instagram account?',
        es: 'Está seguro de que le gustaría desconectar su cuenta de Instagram?'
      },
      'facebookDisconnect': {
        he: 'Are you sure you would like to disconnect your Facebook account?',
        en: 'Are you sure you would like to disconnect your Facebook account?',
        es: 'Está seguro de que le gustaría desconectar su cuenta de Facebook?'
      },
      'setPrivacyStatus': {
        he: 'Are you sure you would like to remove event from feed?',
        en: 'Are you sure you would like to remove event from feed?',
        es: 'Está seguro de que desea eliminar el evento del feed?'
      },
      'walletDisconnect': {
        he: 'בטוח שברצונך לנתק את הארנק?',
        en: 'Are you sure you would like to disconnect your Wallet?',
        es: 'Estás seguro de que deseas desconectar tu Wallet?'
      },

    }
    this.modalText = this.getAlertStr(this.data.textCode);
  }
  getAlertStr(textCode) {
    let locale = this.globals.getLocaleId();
    return (this.strings[textCode][locale]) ? this.strings[textCode][locale] : '';
  }

  close(boolean: boolean){
    this.dialogRef.close(boolean);
  }
}
