<div class="main-balance-container" [ngClass]="{'rtl' : dir === 'rtl'}">
  <div class="mobile-balance-container">
    <div class="balance-container">
      <!-- <div class="events-stat" *ngFor="let total of totals; let i = index; let first = first; let last = last;">
              <div class="events-type-container">
                <div class="events-type-name" [ngStyle]="{'justify-content': last ? 'space-between': 'flex-start'}" >{{total.name}}<span class="availableNow">&nbsp;{{total.ext}}</span></div>
                <div class="events-type-number" [ngClass]="first? 'events-balance ':''" >${{total.col}}</div>
              </div>
            </div> -->
      <div class="events-stat">
        <div class="events-type-container">
          <div i18n="analitic total balance" class="events-type-name" [ngStyle]="{'justify-content': 'flex-start'}">
            Total Balance</div>
          <div class="events-type-number" [ngClass]="'events-balance'">{{balance?.total_balance || 0 | currency:
            event_currency:'symbol':'1.': getLocale(event_currency)}}</div>
        </div>
      </div>
      <div class="events-stat">
        <div class="events-type-container">
          <div i18n="analitic Total cash" class="events-type-name" [ngStyle]="{'justify-content': 'space-between'}">
            Total cash</div>
          <div class="events-type-number">{{balance?.total_cash || 0 | currency: event_currency:'symbol':'1.':
            getLocale(event_currency)}}</div>
        </div>
      </div>
      <div class="events-stat">
        <div class="events-type-container">
          <div i18n="analitic Total credit" class="events-type-name" [ngStyle]="{'justify-content': 'space-between'}">
            Total credit</div>
          <div class="events-type-number">{{balance?.total_cred || 0 | currency: event_currency:'symbol':'1.':
            getLocale(event_currency)}}</div>
        </div>
      </div>
    </div>

  </div>
  <div class="crypto-container" *ngIf="crypto && crypto.length">
    <div class="crypto-section-container" >
      <div class="crypto-section" *ngFor="let item of crypto">
          <div class="crypto-title">{{item.currency}}</div>
          <div i18n="Label | Total tickets" class="crypto-total">Total Tickets: {{item.ticket_amount}}</div>
          <div i18n="Label | Total tickets amount" class="crypto-total">Total Tickets Amount: {{item.ticket_price}} {{event_currency}}</div>
          <div i18n="Label | Total imcome" class="crypto-total">Total Income: {{item.amount | number: '0.2-8'}} {{item.currency}}</div>

      </div>
    </div>
  </div>
  <div class="expenses-container" *ngIf="expenses?.check_display_getin_marketing">
    <div i18n="expenses section Expenses" class="expenses-title">Expenses</div>
    <!-- <div class="expenses-section">
          <div i18n="expenses section label Rental" class="expenses-labels">Total cash</div>
          <div class="expenses-numbers">{{expenses?.total_cash || 0 | currency: event_currency:'symbol':'1.'}}</div>
        </div>
        <div class="expenses-section">
          <div i18n="expenses section label Rental" class="expenses-labels">Total credit</div>
          <div class="expenses-numbers">{{expenses?.total_cred || 0 | currency: event_currency:'symbol':'1.'}}</div>
        </div> -->
    <div class="expenses-section mt15">
      <div i18n="expenses section label Rental" class="expenses-labels">GetIn Marketing</div>
      <div class="expenses-numbers">{{expenses?.getin_marketing | currency: event_currency:'symbol':'1.':
        getLocale(event_currency)}}</div>
    </div>

  </div>
</div>
