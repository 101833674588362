<div class="row">
  <div class="col-md-12 col-xs-12 c22">
    <h1 class="c21"><span>TERMS AND CONDITIONS <br>FOR CONSUMERS</span></h1>
    <ol class="" start="1">
        <li class="c7"><span class="c4">Introduction</span><span>. &nbsp;Welcome to the consumer services
                offered by Get In, Inc., a Florida corporation with its principal office located at 1100 Brickell Bay
                Drive, Suite 81D, Miami, Florida 33131, its affiliates, successors, and assigns (collectively, the
                &ldquo;</span><span class="c4">Company</span><span>&rdquo;) at </span><span><a
                    class="c11"
                    href="https://www.google.com/url?q=https://www.get-in.com/&amp;sa=D&amp;source=editors&amp;ust=1629767882023000&amp;usg=AOvVaw0bU41PfGu79ZtGbB59sXy4">https://www.get-in.com/</a></span><span
               >&nbsp;and through its network of other websites and software applications and through other
                products or services offered by Company. &nbsp;Other services offered by the Company may be subject to
                separate terms. </span></li>
        <li class="c7"><span class="c4">IMPORTANT LEGAL NOTICES</span><span>. </span></li>
    </ol>
    <ol start="1" type="a">
        <li class="c1"><span class="c4">AGREEMENT, BINDING EFFECT</span><span>. &nbsp;PLEASE READ THESE TERMS
                AND CONDITIONS CAREFULLY AND IN THEIR ENTIRETY BECAUSE THEY ARE LEGALLY BINDING AND THEY CONTAIN TERMS,
                RESTRICTIONS, LIMITATIONS, AND CONDITIONS THAT MAY WAIVE, LIMIT, OR RESTRICT CERTAIN IMPORTANT LEGAL
                RIGHTS. FOR EXAMPLE, THESE TERMS AND CONDITIONS LIMIT OUR LIABILITY IN SEVERAL WAYS (WHICH MEANS THAT WE
                MAY NOT BE RESPONSIBLE FOR CERTAIN THINGS), THEY RESTRICT YOUR ENTITLEMENT TO REFUNDS AND/OR
                CHARGEBACKS, AND THEY WAIVE YOUR RIGHT TO GO TO COURT TO RESOLVE A DISPUTE BETWEEN YOU AND US, AMONG
                OTHER THINGS. </span></li>
        <li class="c1"><span class="c4">IMPORTANT LEGAL RIGHTS</span><span>. IF YOU DO NOT AGREE TO THESE
                TERMS, OR IF YOU DO NOT AGREE TO ASSUME THE RISKS ASSOCIATED WITH EITHER YOUR USE OF THIS SITE AND/OR
                WITH YOUR PURCHASE OF THE PRODUCTS OR SERVICES FEATURED ON THE SITE, OR IF YOU DO NOT INTEND TO BE BOUND
                BY THESE TERMS, DO NOT PURCHASE, REGISTER FOR, PARTICIPATE IN, OR SUBSCRIBE TO, DOWNLOAD, VIEW, OR
                UTLIZE ANY PRODUCTS AND/OR SERVICES OFFERED OR ADVERTISED ON THIS SITE, INCLUDING TICKETS, SERVICES,
                MERCHANDISE, MEMBERSHIPS, SUBSCRIPTIONS, OFFERINGS, &nbsp;CONTESTS, &nbsp;GIVEAWAYS, OR OTHERWISE. BY
                PROCEEDING, YOU ACKNOWLEDGE AND AGREE THAT YOU ARE FREELY AND VOLUNTARILY GIVING UP IMPORTANT LEGAL
                RIGHTS. &nbsp;</span></li>
    </ol>
    <ol start="3">
        <li class="c7"><span class="c4">Definitions</span><span>. The following definitions apply: </span>
        </li>
    </ol>
    <ol start="1" type="a">
        <li class="c1"><span>&ldquo;</span><span class="c4">Bearer</span><span>&rdquo; shall refer
                to either an Event attendee, participant, subscriber, registrant, or an individual who received, used,
                presented for entry, registration, and/or participation a Product (including actual or electronic
                tickets (E-tickets)) or Service(s). &nbsp;Bearer is sometimes collectively referred to in this Agreement
                with Purchaser as &ldquo;you&rdquo; or &ldquo;yours.&rdquo; &nbsp;</span></li>
        <li class="c1"><span>&ldquo;</span><span class="c4">Company</span><span>&rdquo; is Get In,
                Inc., a Florida corporation with its principal office located at 1100 Brickell Bay Drive, Suite 81D,
                Miami, Florida 33131 and its Indemnitees, designees, successors, and assigns. Company is sometimes
                referred to herein as &ldquo;we,&rdquo; &ldquo;our,&rdquo; and/or &ldquo;us.&rdquo;</span></li>
        <li class="c1"><span>&ldquo;</span><span class="c4">Dispute</span><span>&rdquo; shall have
                the meaning ascribed to it in </span><span class="c6"(click)="scrollTo('id.4d34og8')"><a class="c11">Paragraph
                    9.a.</a></span><span>&nbsp;of these terms and conditions.</span></li>
        <li class="c1"><span>&ldquo;</span><span class="c4">Event</span><span>&rdquo; shall
                include a live, digital, </span>live streaming<span>&nbsp;or
                rebroadcast event, or other engagement, production, or conference including cultural, educational,
                theatrical, performing art, business, sporting, or other concert, event, or engagement promoted,
                advertised, or offered on the Site.</span></li>
        <li class="c1"><span>&ldquo;</span><span class="c4">Indemnitees</span><span>&rdquo; shall
                have the meaning ascribed to it in </span><span class="c6" (click)="scrollTo('id.3rdcrjn')"><a class="c11">
                  Paragraph 11</a></span><span>&nbsp;of these terms and
                conditions.</span></li>
        <li class="c1"><span>&ldquo;</span><span class="c4">Organizer</span><span>&rdquo; or
                &ldquo;</span><span class="c4">Event Organizer</span><span>&rdquo; shall include and refer to
                the person(s) responsible for producing, staging, marketing, organizing, and promoting the Event and
                which person may be a third party.</span></li>
        <li class="c1"><span>&ldquo;</span><span class="c4">Product(s)</span><span>&rdquo; shall
                refer to offerings, goods, and/or services including Event Tickets, merchandise, subscriptions,
                memberships, or other items that are offered or promoted on the Site and which are provided to or
                received by you in exchange for payment by Purchaser.</span></li>
        <li class="c1"><span>&ldquo;</span><span class="c4">Purchaser</span><span>&rdquo; shall
                refer to the credit card obligor (when credit card transactions are used to acquire Products or
                Services) and shall also refer to persons who either actually ordered or authorized the ordering or
                acquisition of a Product or Service from the Site. Purchaser is sometimes collectively referred to in
                this Agreement with Bearer as &ldquo;you&rdquo; or &ldquo;yours.&rdquo; &nbsp;</span></li>
        <li class="c1"><span>&ldquo;</span><span class="c4">Service(s)</span><span>&rdquo; shall
                refer to subscriptions, memberships, offerings, experiences, viewings, digital experiences, or
                interactions that are offered or advertised on the Site to Purchasers, Bearers, or registrants and
                includes undertakings by the Company to share or store information or to process payments an any actions
                ancillary or incidental thereto and whether now existing or hereinafter created. </span></li>
        <li class="c1"><span>&ldquo;</span><span class="c4">Service Fees</span><span>&rdquo; shall
                have the meaning(s) set forth in </span><span class="c6" (click)="scrollTo('id.3znysh7')"><a class="c11">Paragraph
                    6</a></span><span>&nbsp;of these terms and conditions.</span></li>
        <li class="c1"><span>&ldquo;</span><span class="c4">Site</span><span>&rdquo; shall refer
                to </span><span><a class="c11"
                    href="https://www.google.com/url?q=https://www.get-in.com/&amp;sa=D&amp;source=editors&amp;ust=1629767882026000&amp;usg=AOvVaw1gEzfjj6JRBaKwrud6CqeB">https://www.get-in.com/</a></span><span
               >.</span></li>
    </ol>
    <ol start="4">
        <li class="c7"><span class="c4">License</span><span>. &nbsp;</span></li>
    </ol>
    <ol start="1" type="a">
        <li class="c1"><span class="c4">Grant of Rights</span><span>. In exchange for purchasing or utilizing
                a Product or Service including an Event ticket, Company hereby grants to you a revocable license to
                either enter, attend, or access an Event, or to otherwise exploit or download the Services or Products
                to which the purchase relates. &nbsp;Company may, at its option, elect to revoke this License for cause,
                including, but not limited to, breach of this License by you, without advanced notification to, or
                consent from, you. </span><span class="c3">IF YOU DO NOT INTEND TO BE BOUND BY THESE TERMS, YOU SHOULD
                NOT PURCHASE, UTILIZE, REGISTER FOR, SUBSCRIBE TO, ENTER, DOWNLOAD, ACCESS, ATTEND, OR IN ANY WAY
                EXPLOIT SUCH PRODUCT OR SERVICE.</span></li>
        <li class="c1"><span class="c4">LEGAL DISCLAIMERS</span><span>. &nbsp;COMPANY GRANTS A LICENSE TO
                USE THE PRODUCTS AND/OR SERVICES ON AN &ldquo;AS IS&rdquo; BASIS AND GRANTS THIS LICENSE TO PURCHASE
                PRODUCTS AND/OR SERVICES ON AN &ldquo;AS AVAILABLE&rdquo; BASIS, AND GRANTS NO WARRANTIES OF ANY KIND,
                WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE AVAILABILITY OF TICKETS, THE
                PURCHASE OF TICKETS, ANY EVENT VENUE, OR THE ARTISTS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OR
                GUARANTEES RESPECTING THE AVAILABILITY OF POTENTIAL SEATING, SITE CONFIGURATIONS, FOOD, BEVERAGE, AND/OR
                MERCHANDISE CHOICES, VIEWING CAPACITY, FORMAT, PRODUCTION QUALITY, AND/OR OVERALL PRODUCTION EXPERIENCE.
                TO THE EXTENT THAT COMPANY PUBLISHES PROMOTIONAL, MARKETING, AND/OR ADVERTISING STATEMENTS THAT APPEAR
                TO BE INCONSISTENT WITH THESE TERMS, YOU ACKNOWLEDGE AND AGREE THAT ONLY THESE TERMS CONTROL</span></li>
        <li class="c1"><span class="c4">Ownership of Materials</span><span>. The Services are owned and
                operated by Company. Unless otherwise indicated, all content, information, and other materials on the
                Services including, without limitation, Company&rsquo;s trademarks and logos, the visual interfaces,
                graphics, design, compilation, information, software, computer code (including source code or object
                code), services, text, pictures, information, data, sound files, other files, and the selection and
                arrangement thereof (collectively, the &ldquo;Materials&rdquo;) are protected by relevant intellectual
                property and proprietary rights and laws. All Materials are the property of Company or its subsidiaries
                or affiliated companies and/or third-party licensors. Unless otherwise expressly stated in writing by
                Company, by agreeing to these terms and conditions, you are granted a limited, non-sublicensable License
                to access and use the Services for personal use only.</span></li>
        <li class="c1"><span class="c4">Restrictions and Prohibited Conduct</span><span>. Company reserves
                all rights not expressly granted in these terms and conditions. This License is subject to these terms
                and conditions and does not permit you to engage in any of the following: (a) resale, commercial use, or
                any other unauthorized use of the Services or the Materials; (b) distribution, public performance, or
                public display of any Materials; (c) modifying or otherwise making any derivative uses of the Services
                or the Materials, or any portion of them; (d) use of any data mining, robots, or similar data gathering
                or extraction methods; (e) downloading of any portion of the Services, the Materials, or any information
                contained in them, except as expressly permitted in the Services; or (f) any use of the Services or the
                Materials except for their specified purposes. Any use of the Services or the Materials, except as
                specifically authorized in these terms and conditions without the prior written permission of Company,
                is strictly prohibited and may violate intellectual property rights or other laws. Unless explicitly
                stated in these terms and conditions, nothing in them shall be interpreted as conferring any license to
                intellectual property rights, whether by estoppel, implication, or other legal principles. Company can
                revoke this License in accordance with the terms specified in this Agreement. </span></li>
        <li class="c1"><span class="c4">Termination</span><span>. To the fullest extent permitted by
                applicable law, Company reserves the right, without notice and in our sole discretion, to terminate your
                License to use the Services and to block or prevent your future access to and use of the Services,
                including where Company reasonably considers that (a) your use of the Services violates these terms and
                conditions or applicable law; (b) you fraudulently use or misuse the Services; or (c) we are unable to
                continue providing the Services to you due to technical or legitimate business reasons. Company&rsquo;s
                right to terminate your License includes the ability to terminate or to suspend your access to any
                Products or Services. To the fullest extent permitted by applicable law, your only remedy with respect
                to any dissatisfaction with (i) the Services, (ii) any term of these terms and conditions, or (iii) any
                content or information transmitted through the Services, is to terminate your account and to discontinue
                use of any and all parts of the Services.</span></li>
    </ol><a id="id.gjdgxs"></a>
    <ol start="5">
        <li class="c7"><span class="c4">Refund Policy</span><span>. &nbsp;</span></li>
    </ol>
    <ol start="1" type="a">
        <li class="c1" id="h.30j0zll"><span class="c4">No Refunds</span><span>. &nbsp;ALL PRODUCTS AND
                SERVICES ARE </span><span class="c3">NON-REFUNDABLE</span><span>&nbsp;EXCEPT TO THE EXTENT
                OTHERWISE EXPRESSLY ON THE SITE. &nbsp;TO THE EXTENT THAT PURCHASER IS ELIGIBLE TO RECEIVE REFUNDS, THE
                AMOUNTS TO BE REFUNDED (1) SHALL BE LIMITED ONLY TO THE FACE VALUE OF THE PRODUCT OR SERVICE AND (2)
                COMPANY MAY FURTHER APPLY (DEDUCT) A FEE FROM THE FACE VALUE OF THE PRODUCT OR SERVICE AS A FEE RELATING
                TO THE PROCESSING OF PURCHASER&rsquo;S REFUND REQUEST. </span></li>
        <li class="c1"><span class="c4">Event Cancellation; Recourse</span><span>. &nbsp;IN CASE OF AN EVENT
                CANCELLATION (WHETHER IN WHOLE OR IN PART), PURCHASER SHALL ONLY BE ENTITLED TO A FUTURE CREDIT.
                PURCHASER SHALL NOT BE ENTITLED TO A REFUND. In the event that Organizer elects, in its sole and
                absolute discretion, to issue a refund, which shall at all times remain subject to the terms and
                limitations otherwise set forth herein, Purchaser shall only be entitled to recover the face value of a
                Product or Service. &nbsp;PURCHASER ACKNOWLEDGES AND AGREES THAT COMPANY IS A TICKETING INTERMEDIARY AND
                MAY NOT BE THE EVENT ORGANIZER, AND, IN CASES WHERE COMPANY IS NOT THE EVENT ORGANIZER, PURCHASER AGREES
                THAT PURCHASER&rsquo;S ONLY LEGAL RECOURSE SHALL BE AGAINST EVENT ORGANIZER, AND TO THAT END, YOU HEREBY
                RELEASE, ACQUIT, SATISFY, AND FOREVER DISCHARGE COMPANY AND ALL OF ITS SUBSIDIARIES, AFFILIATES,
                SHAREHOLDERS, DIRECTORS, PRINCIPALS, OFFICERS, LICENSEES, EMPLOYEES, AND AGENTS, WHETHER NOW EXISTING OR
                HEREINAFTER CREATED, OF AND FROM ALL CAUSE(S) OF ACTION, SUITS, </span><span><a class="c11"
                    href="https://www.google.com/url?q=http://www.delafe.com/form/frmgrel.htm&amp;sa=D&amp;source=editors&amp;ust=1629767882029000&amp;usg=AOvVaw2DwJH7l_1x9a1vIyMzs0gD">DEBTS</a></span><span
               >, SUMS OF MONEY, ACCOUNTS, RECKONINGS, BONDS, BILLS, SPECIALTIES, COVENANTS, CONTRACTS,
                CONTROVERSIES, AGREEMENTS, OBLIGATIONS, PROMISES, VARIANCES, DAMAGES, JUDGMENTS, EXECUTIONS, CLAIMS,
                WHETHER IN LAW OR </span><span><a class="c11"
                    href="https://www.google.com/url?q=http://www.delafe.com/form/frmgrel.htm&amp;sa=D&amp;source=editors&amp;ust=1629767882029000&amp;usg=AOvVaw2DwJH7l_1x9a1vIyMzs0gD">EQUITY</a></span><span
               >, WHICH YOU HAD, NOW HAVE, OR WHICH ANY PERSONAL REPRESENTATIVE, SUCCESSOR, HEIR, OR ASSIGN
                OF YOU, SHALL OR MAY HAVE, AGAINST COMPANY, BY REASON OF ANY MATTER, CAUSE, OR THING WHATSOEVER. </span>
        </li>
        <li class="c1"><span class="c4">Force Majeure</span><span>. In case of an Event cancellation (whether
                in whole or in part) due to governmental action, or for any reason or cause other than Organizer
                Cancellation, including, but not limited to, public health issues, pandemic, epidemic, acts of God, war,
                civil unrest, riots, production delays, strikes, natural disasters, inclement weather (regardless of
                severity), or inaccessibility or unavailability of any venue (&ldquo;Force Majeure&rdquo;), </span><span
                class="c3">PURCHASER SHALL NOT BE ENTITLED TO RECEIVE A REFUND</span><span>, rebate, or
                credit; instead, at the option of the Event Organizer, Purchaser shall be entitled to a future benefit
                equal to the face value of the Product or Service purchased, or Event Organizer shall reschedule the
                Event and honor Purchaser&rsquo;s prior purchase. Notwithstanding the foregoing, Organizer of the
                respective Event shall, at its sole option, elect to issue a full or partial refund to Purchaser in lieu
                of a future credit. </span></li>
        <li class="c1"><span class="c4">Delayed Venue Opening or Delayed Performance.</span><span
               >&nbsp;Delayed venue openings or delayed performances and/or your inability to access specific
                parts of the venue or Event due to governmental action, Force Majeure, or technical difficulties
            </span><span class="c3">SHALL NOT ENTITLE PURCHASER TO RECEIVE A REFUND</span><span>&nbsp;as a
                matter of right.</span><span class="c3">&nbsp; </span><span>Instead, Purchaser shall only be
                entitled to a future credit equivalent to the face value of the original Product or Service purchased,
                unless otherwise determined by Event Organizer.</span></li>
        <li class="c1"><span class="c4">Rescheduling of Event</span><span>. Upon rescheduling (whether in
                whole or in part) of an Event by Organizer or due to governmental action or Force Majeure, </span><span
                class="c3">PURCHASER SHALL NOT BE ENTITLED TO RECEIVE A REFUND. </span><span>Instead,
                Purchaser shall only be entitled to a future credit equivalent to the face value of the original Product
                or Service purchased, unless otherwise determined by Event Organizer. </span></li>
    </ol><a id="id.3znysh7"></a>
    <ol start="6">
        <li class="c7" id="h.1fob9te"><span class="c4">Service Fees</span><span>.
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Purchaser acknowledges and agrees that the price of a
                Product or Service is comprised of several components including (a) the face value and (b) various other
                fees, surcharges, taxes, and other assessments collectively, some of which may relate to third-party or
                related-party arrangements (&ldquo;</span><span class="c4">Service Fees</span><span>&rdquo;).
                &nbsp;</span><span class="c3">UNDER NO CIRCUMSTANCES SHALL PURCHASER BE ENTITLED TO A REFUND, REBATE, OR
                CREDIT OF ANY SHIPPING, HANDLING, OR OTHER PROCESSING AND SERVICE FEES</span><span>.</span>
        </li>
    </ol>
    <ol start="1" type="a">
        <li class="c1"><span>Upon purchasing or ordering a Product or Service, Purchaser agrees and shall be
                obligated to pay to Company the face value of the Product or Service, plus all applicable Service Fees
                including, but not limited to, shipping and handling charges, processing fees, customer service fees,
                fees associated with administration of the E-ticket program, as well as all applicable taxes and other
                assessments and surcharges. </span><span class="c3">SERVICE FEES, AS THAT TERM IS DEFINED, ARE NOT
                REFUNDABLE.</span><span>&nbsp; </span></li>
        <li class="c1"><span class="c4">Waiver and Release</span><span>. </span><span class="c3">PURCHASER
                AGREES TO WAIVE, RELEASE, AND FOREVER DISCHARGE COMPANY AND COVENANTS NOT TO SUE COMPANY TO RECOVER
                UNPAID SERVICE FEES OR FOR ANY REASON</span><span>, including, but not limited to, reasons
                relating to postponements, cancellations, delays, refunds, and/or disputes between the Parties. By
                proceeding to purchase a Ticket under these terms, you are, among other things, </span><span
                class="c3">KNOWINGLY AND VOLUNTARILY WAIVING</span><span>&nbsp;your legal rights and/or
                entitlements to recover such Service Fees.</span></li>
    </ol><a id="id.2et92p0"></a>
    <ol start="7">
        <li class="c7"><span class="c4">Chargebacks</span><span>. </span><span class="c3">WITH THE INTENTION
                OF BEING FULLY BOUND BY THESE TERMS, PURCHASER ACKNOWLEDGES AND AGREES</span><span>&nbsp;to
                strictly comply with the provisions of the Fair Credit Billing Act and agrees to only initiate credit or
                debit card disputes or chargebacks based on legitimate computational errors only, and only after
                Purchaser has first made a good faith attempt to obtain satisfactory resolution of a disagreement or
                problem relative to the transaction from the Company by emailing vera@get-in.com.</span></li>
    </ol>
    <ol start="1" type="a">
        <li class="c12"><span>Purchaser agrees not to initiate credit or debit card disputes or chargebacks
                and also agrees that Purchaser shall not be entitled to a credit or debit charge reversal once Products
                or Services were delivered to either a physical or email address. &nbsp;</span></li>
        <li class="c12"><span>Purchaser shall not be entitled to initiate credit or debit card disputes or
                chargebacks and also agrees that Purchaser shall not be entitled to receive a credit or debit charge
                reversal if Company delivers the Product or Service, but Purchaser fails or refuses to attend, access,
                or exploit the Event or Service. </span></li>
        <li class="c12"><span>Purchaser shall not be entitled to initiate credit or debit card disputes or
                chargebacks and also agrees that Purchaser shall not be entitled to receive a credit or debit charge
                reversal if (i) the Event or any Products or Services are delayed or cancelled, whether in whole or in
                part; (ii) if inclement weather impacted the Event, Product or Service; or (iii) Products or Services
                were deemed to be undesirable, unsatisfactory, or disappointing in any way to Purchaser. &nbsp;</span>
        </li>
        <li class="c12"><span>Purchaser acknowledges and agrees that Purchaser shall only be entitled to
                initiate a debit or credit card dispute or chargeback if such claim was a computational error as defined
                or contemplated under applicable laws including under Part D of the Fair Billing Act, 15 USC 41,
                SubChapter 1 or if the Product or Service was never delivered. In cases of Event Cancellation, the terms
                of </span><span class="c6" (click)="scrollTo('id.gjdgxs')"><a class="c11">Paragraph 5</a></span><span
               >&nbsp;shall apply.</span><span class="c3">&nbsp; </span></li>
        <li class="c12"><span>Purchaser also agrees not to initiate a debit or credit card dispute or
                chargeback</span><span class="c3">&nbsp;</span><span>or undertake any other or similar
                action(s) that operate, in any way, to dispute, cancel, or reverse any portion of a validly purchased
                Product or Service, and if such action is in contravention of either these terms and conditions or of
                the spirit of this Agreement, or if the basis for such action relates to an Event being delayed,
                cancelled, rescheduled (whether in whole or in part) and/or for any reason, including reasons relating
                to a Force Majeure, governmental action, unforeseen events, production issues, or otherwise. </span>
        </li>
        <li class="c12"><span class="c3">PURCHASER ACKNOWLEDGES AND AGREES THAT BY ENTERING INTO THIS AGREEMENT,
                PURCHASER IS VOLUNTARILY AND KNOWINGLY WAIVING CERTAIN OF PURCHASER&rsquo;S LEGAL RIGHTS RELATING TO
                PURCHASER&rsquo;S ENTITLEMENT TO CHARGEBACKS OR TO CHARGEBACK PROTECTIONS</span><span
               >&nbsp;under the Fair Credit Billing Act. &nbsp;</span></li>
        <li class="c12"><span>The foregoing terms shall apply even if Purchaser&rsquo;s chargeback protection
                periods have not expired and/or even if Purchaser has other legal rights available to Purchaser
                respecting chargebacks.</span></li>
    </ol><a id="id.tyjcwt"></a>
    <ol start="8">
        <li class="c7"><span class="c4">Assumption of Risk; Mitigation</span><span>.</span></li>
    </ol>
    <ol start="1" type="a">
        <li class="c1"><span class="c4">Potential Risk</span><span>. By entering into this Agreement,
            </span><span class="c3">PURCHASER KNOWINGLY AND VOLUNTARILY ASSUMES THE RISK</span><span>&nbsp;of
                not being able to recover, reverse, or recoup charges relating to Products or Services based on any
                Event being delayed, cancelled, or rescheduled (whether in whole or in part) for any reason, your
                inability or unwillingness to attend, enter, download, access, or exploit the Product or Service,
                including reasons related to governmental action, Force Majeure, unforeseen events, production issues,
                or otherwise.</span></li>
        <li class="c1"><span class="c4">Lost, Stolen, or Destroyed Products</span><span>. Company assumes no
                legal, financial, or other liability whatsoever for any lost, stolen or destroyed Products. &nbsp;Upon
                delivery of your Products, Company and Event Organizer shall have no further responsibility or legal
                liability relating thereto. </span><span class="c3">YOU ASSUME 100% OF THE RISK OF LOSS RELATING TO THE
                TICKETING PRODUCTS</span><span>. &nbsp;If you obtain Products(s) from any unauthorized
                source(s), you assume all risks associated with such Products(s), including that such Ticket(s) may have
                been reported lost or stolen or that such Products(s) may be counterfeit, and in all cases, </span><span
                class="c3">SUCH </span><span>PRODUCTS</span><span class="c3">(S) MAY BE VOID AND DISHONORED
                BY ORGANIZER WITHOUT FURTHER </span><span>LIABILITY</span><span class="c3">&nbsp;TO
                YOU.</span><span>&nbsp; You acknowledge and agree that the unlawful or unauthorized resale or
                attempted resale of Ticketing Products is grounds for forfeiture of such Products and immediate
                revocation of Bearer&rsquo;s License without compensation to you and without liability to Company.
            </span></li>
        <li class="c1"><span class="c4">Purchase of Tickets; Loss or Damage to Stolen Property; Personal
                Injury</span><span>. </span><span class="c3">YOU HEREBY ASSUME ANY AND ALL RISKS, WHETHER OR
                NOT EXPRESSLY SET FORTH IN THIS AGREEMENT, AS WELL AS ANY RISKS OR DANGERS INCIDENTAL TO, OR IN ANY WAY
                RELATING TO, ANY EVENT, THE PURCHASE OF PRODUCTS OR SERVICES HEREUNDER, OR USE OF THE SITE, INCLUDING
                THOSE ARISING FROM, OR RELATING TO, THE ACTS OR OMISSIONS OF THIRD PARTIES</span><span
               >&nbsp;(including: artists; Event attendees; Venue owners, operators, staff, employees, or
                agents; Event Organizer, its affiliates, subsidiaries, officers, directors, employees, members,
                partners, agents, or designees), </span><span class="c3">INCLUDING RISK OF LOSS OR DAMAGE TO
                BEARER&rsquo;S PERSONAL PROPERTY AND RISKS OF PERSONAL INJURY</span><span>. Company and its
                respective Indemnitees, designees, successors, or assigns shall not be held responsible, legally,
                financially, or otherwise, for (i) any personal property that is left, lost, stolen, destroyed,
                confiscated, damaged, or misplaced anywhere in any Event venue or at any Event venue access/entry points
                or (ii) personal injuries sustained by Bearer arising from, or relating to, the following non-exhaustive
                list: extremely loud sounds and special effects, such as flashing lights, rapidly changing or
                alternating images, the use of fog, haze or smoke with theatrical stage lighting, laser projections,
                strobe lights, and fireworks; whether such injuries or manifestation of such injuries occur during or
                subsequent to any Event. </span><span class="c3">YOU FURTHER ACKNOWLEDGE AND AGREE THAT COMPANY SHALL
                HAVE NO OBLIGATION TO MITIGATE ANY OF BEARER&rsquo;S POTENTIAL OR ACTUAL LOSSES OR DAMAGES SUSTAINED
                HEREUNDER.</span></li>
        <li class="c1"><span class="c4">Public Health Waiver</span><span>. &nbsp;You acknowledge that there
                is an inherent risk of exposure to COVID-19 in any public place where people are present.
                &nbsp;According to the World Health Organization, COVID-19 is a pandemic affecting many countries
                globally and spreads primarily from person to person through small droplets from the nose or mouth,
                which are expelled when a person with COVID-19 coughs, sneezes or speaks. Organizer may, in its sole and
                absolute discretion, subject to applicable governmental regulations, put in place preventive measures to
                reduce the spread of COVID-19. &nbsp;Company cannot guarantee (and does not guarantee)</span><span
                class="c3">&nbsp;</span><span>that Bearer will not become infected with COVID-19, any related
                mutation or variant, and/or any other communicable disease, whether now existing or hereinafter
                identified (collectively &ldquo;</span><span class="c4">Diseases</span><span>&rdquo;).
            </span></li>
    </ol>
    <ol start="1" type="i">
        <li class="c1"><span>Upon attending or entering an Event or entering or upon exploiting a Product or
                Service, you acknowledge the contagious nature of certain Diseases, and you nonetheless elect to
                voluntarily assume the risk that you may be exposed to or infected with the Diseases by attending,
                accessing, or entering such Event. </span></li>
        <li class="c1"><span>Company and its respective Indemnitees, designees, successors, or
                assigns</span><span class="c3">&nbsp;</span><span>shall not be held responsible, legally,
                financially, or otherwise, for any exposure to or infection with COVID-19 or any other Disease that may
                cause personal injury, illness, permanent disability, and/or death. </span></li>
        <li class="c1"><span>By entering any Event venue, you attest that (i) you are not experiencing any
                symptoms of illness such as cough, shortness of breath or difficulty breathing, fever, chills, repeated
                shaking with chills, muscle pain, headache, sore throat, or new loss of taste or smell; (ii) you have
                not been exposed to persons with a suspected and/or confirmed case of COVID-19 or other communicable
                disease that is known or suspected as being highly transmittable in the 14 DAYS prior to the Event;
                (iii) you have not been diagnosed with or tested positive for COVID-19, any mutation relating thereto,
                or with any other communicable disease that is known or suspected as being highly transmittable, or, if
                you were previously diagnosed with COVID-19, any mutation relating thereto, or with any other
                communicable disease that is known or suspected as being highly transmittable, you have since been
                cleared as non-contagious by public health authorities; (iv) you are not considered &ldquo;high
                risk&rdquo; when taking into account all Centers for Disease Control recommended guidelines; and (v) you
                are following all Centers for Disease Control recommended guidelines. </span></li>
        <li class="c1"><span>You hereby acknowledge and agree that upon discovery of any violation of any of
                the above conditions shall result in the Company&rsquo;s or Organizer&rsquo;s right to immediately
                revoke your License to an Event venue and to potentially deny you any future entry to an Event or venue
                without any legal or financial liability to Organizer or Company. You further acknowledge and agree that
                (i) Organizer or Company each reserves the right to implement public health requirements in order to
                enhance protections at any Event and (ii) you shall comply with such public health requirements at such
                Event.</span></li>
    </ol><a id="id.3dy6vkm"></a>
    <ol start="9">
        <li class="c7"><span class="c4">Dispute Resolution</span><span>.</span></li>
    </ol><a id="id.4d34og8"></a>
    <ol start="8" type="a">
        <li class="c12" id="h.1t3h5sf"><span class="c4">Arbitration</span><span>. You acknowledge and agree
                that any disputes arising from or relating to your purchase of Products or Services hereunder (a
                &ldquo;</span><span class="c4">Dispute</span><span>&rdquo;) shall only be resolved subject to
            </span><span class="c3">FINAL AND BINDING ARBITRATION</span><span>&nbsp;and may only be resolved
                through an individual arbitration governed by the Federal Arbitration Act or by the applicable sections
                of the Florida Arbitration Code. </span><span class="c3">YOU ACKNOWLEDGE THAT YOU ARE KNOWINGLY AND
                VOLUNTARILY GIVING UP YOUR RIGHTS TO GO TO COURT</span><span>&nbsp;in connection with any
                potential Dispute and further acknowledge and agree that such Dispute(s) shall only be determined by a
            </span><span class="c3">NEUTRAL ARBITRATOR, AND NOT A JUDGE OR JURY. </span></li>
        <li class="c12"><span class="c4">Mediation</span><span>. As to Disputes, you also agree to
                participate in non-binding mediation at least 45 days before commencing any arbitration (or taking any
                other action if applicable). &nbsp;If the Parties are unable to resolve a Dispute by informal means, the
                arbitration of Disputes will be administered by the American Arbitration Association (AAA) in accordance
                with Commercial Arbitration Rules and such arbitration shall be conducted at the AAA office located in
                Miami, Florida. &nbsp;Any proceeding to enforce this arbitration agreement must be brought only in the
                Eleventh Circuit Court in and for Miami-Dade County, Florida, or, if jurisdiction permits, in the U.S.
                District Court for the Southern District of Florida, with venue lying in Miami-Dade County. You agree
                that arbitration is appropriate in the venue specified in this paragraph.</span></li>
        <li class="c12"><span class="c4">Class Action Waiver</span><span>.</span><span class="c3">&nbsp;YOU
                AGREE THAT YOU SHALL NOT BRING OR PARTICIPATE IN ANY CLASS ACTION OR OTHER CLASS OR MASS PROCEEDING,
                INCLUDING CLASS ARBITRATION, IN CONNECTION WITH ANY DISPUTE</span><span>.</span></li>
        <li class="c12"><span class="c4">Limitations Period</span><span>. </span><span
                class="c3">NOTWITHSTANDING ANY LAW TO THE CONTRARY, THE PARTIES AGREE THAT CLAIMS ARISING UNDER THIS
                AGREEMENT SHALL BE BROUGHT WITHIN ONE YEAR OF ACCRUAL OF THE DISPUTE, AS ACCRUAL IS DEFINED BY GOVERNING
                LAW.</span><span>&nbsp;If a claim is not filed within one year of the Dispute&rsquo;s
                accrual, the Dispute shall be time-barred.</span></li>
        <li class="c12"><span class="c4">Arbitration Costs</span><span>. </span><span class="c3">YOU
                ACKNOWLEDGE AND AGREE THAT ANY COSTS ASSOCIATED WITH A DISPUTE SHALL BE SHARED EQUALLY BETWEEN YOU AND
                COMPANY, NOTWITHSTANDING ANY RULES CONTAINED IN THE AAA CONSUMER ARBITRATION RULES</span><span
               >, including, but not limited to, rules pertaining to filing fee limitations, mediation fees,
                case management fees, neutral arbitrator compensation, hearing fees, hearing room rental fees, abeyance
                fees, expenses, consumer clause review and registry fees, reallocation of arbitrator compensation, and
                AAA administrative fees, and you waive any fee limitations contained in the AAA Consumer Arbitration
                Rules. </span></li>
        <li class="c12"><span class="c4">Damages.</span></li>
    </ol>
    <ol start="1" type="i">
        <li class="c2"><span class="c4">No Special Damages; Attorneys&rsquo; Fees</span><span>. In the event
                of any Dispute(s) between you and Company in any tribunal of competent jurisdiction, (i) you acknowledge
                and agree that you shall not be entitled to apply for or recover punitive, consequential, or special
                damages of any kind, nor shall you be entitled to recover attorneys&rsquo; fees and costs &nbsp;filing
                fees, hotel, air, or travel fees, among others, and (ii) the Parties agree that each shall bear 100% of
                its respective costs, expert fees, attorneys&rsquo; fees, and other fees incurred in connection with
                this Agreement, notwithstanding any law to the contrary that potentially entitles you to recover fees
                and costs from Company. To the extent that an action is brought by a third party on behalf of you in
                contravention of the terms and spirit of this Agreement, you hereby expressly waive any claim to any
                form of monetary damages or other damages or any other form of recovery or relief in connection with any
                such third-party action.</span></li>
        <li class="c2"><span class="c4">Liquidated Damages for Breach by You</span><span>. You agree that, if
                you violate, breach, or threaten to breach any of your obligations under this Agreement, including
                initiating a chargeback or undertaking to receive a refund or rebate in contravention of the terms of
                this Agreement, damages will be extremely difficult or impossible to establish, ascertain or prove.
                Therefore, you knowingly and voluntarily agree that the limitations set forth in </span><span
                class="c6" (click)="scrollTo('id.17dp8vu')"><a class="c11">Paragraph 10</a></span><span>&nbsp;shall
                constitute an accurate measure of damages sustained by Company. You acknowledge and agree that the
                liquidated damages stipulated in this paragraph are not grossly disproportionate to any damage that
                might reasonably be expected to follow from such a breach by you. Company shall, in addition to its
                other rights and remedies, also have the right to elect to apply for or to seek, in arbitration, interim
                or non-monetary relief, specific performance, and/or injunctive relief in order to enforce, or prevent
                any violations of, any provisions of this Agreement (without the posting of a bond or other security),
                and you acknowledge and agree that such relief would be appropriate relief for any such breach and to
                prevent further breaches.</span></li>
    </ol><a id="id.17dp8vu"></a>
    <ol start="10">
        <li class="c7" id="h.2s8eyo1"><span class="c4">Limitation of Liability</span><span>. </span></li>
    </ol>
    <ol start="1" type="a">
        <li class="c1"><span class="c4">No Special Damages.</span><span>&nbsp;</span><span>TO
                THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW: (A) IN NO EVENT SHALL COMPANY BE LIABLE TO YOU FOR ANY
                DIRECT, SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING BUT NOT
                LIMITED TO LOSS OF USE, LOSS OF PROFITS, OR LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT
                (INCLUDING BUT NOT LIMITED TO NEGLIGENCE), OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE
                USE OF OR INABILITY TO USE THE PRODUCTS OR SERVICES, THE CONTENT, OR THE MATERIALS, INCLUDING WITHOUT
                LIMITATION ANY DAMAGES CAUSED BY OR RESULTING FROM RELIANCE ON ANY INFORMATION OBTAINED FROM COMPANY, OR
                THAT RESULT FROM MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION OF FILES OR EMAIL, ERRORS, DEFECTS,
                VIRUSES, DELAYS IN OPERATION OR TRANSMISSION, OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING
                FROM ACTS OF GOD, COMMUNICATIONS FAILURE, THEFT, DESTRUCTION, OR UNAUTHORIZED ACCESS TO COMPANY&rsquo;S
                RECORDS, PROGRAMS, OR SERVICES. </span></li>
        <li class="c1"><span class="c3">THIS SECTION SHALL APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
                &nbsp;IN NO EVENT SHALL COMPANY OR ANY OF ITS SUBSIDIARIES, SPONSORS, INDEMNITEES, OR AFFILIATES,
                TOGETHER WITH THEIR RESPECTIVE DIRECTORS, OFFICERS, SHAREHOLDERS, EMPLOYEES, AND AGENTS, BE LIABLE FOR
                ANY ATTORNEYS&rsquo; FEES OR OTHER LEGAL COSTS OR DIRECT, INCIDENTAL, INDIRECT, CONSEQUENTIAL, SPECIAL,
                PUNITIVE, OR EXEMPLARY DAMAGES OF ANY KIND. &nbsp;IN ADDITION TO THE FOREGOING, YOU AGREE THAT, IN THE
                EVENT OF DISPUTE UNDER THIS AGREEMENT AND BETWEEN THE PARTIES, INCLUDING DISPUTES RELATING TO INJURY OR
                PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, WHETHER ARISING IN CONTRACT OR IN TORT, COMPANY&rsquo;S
                AGGREGATE LIABILITY SHALL BE LIMITED, IN ALL INSTANCES TO THE LESSER OF ONE HALF OF THE FACE VALUE OF
                THE PRODUCT OR SERVICE OR FIFTY DOLLARS. </span><span>&nbsp;</span></li>
        <li class="c1"><span class="c4">Limitation on Legal Defenses</span><span>. &nbsp;In the event of a
                Dispute between the Parties, you agree not to raise any claims or defenses on grounds including that the
                foregoing limitations are either unconscionable, illusory, or unenforceable because the Parties lacked
                equal bargaining power or otherwise. You further agree not to file any legal papers or undertake any
                legal actions that are contrary to or inconsistent with the terms or spirit of </span><span
                class="c6" (click)="scrollTo('id.3dy6vkm')"><a class="c11">Paragraph 9</a></span><span>&nbsp;or this
            </span><span class="c6" (click)="scrollTo('id.17dp8vu')"><a class="c11">Paragraph 10</a></span><span
               >&nbsp;and/or which may prevent Company from receiving the full benefits and protections
                contemplated by the Parties under </span><span class="c6" (click)="scrollTo('id.3dy6vkm')"><a class="c11">Paragraph
                    9</a></span><span>&nbsp;or this </span><span class="c6" (click)="scrollTo('id.17dp8vu')"><a class="c11"
                    >Paragraph 10</a></span><span>. &nbsp;YOU ACKNOWLEDGE AND AGREE THAT
                COMPANY HAS OFFERED THE SERVICES, MATERIALS, AND OTHER CONTENT AND INFORMATION, SET ITS PRICES, AND
                ENTERED INTO THESE TERMS AND CONDITIONS IN RELIANCE UPON THE DISCLAIMERS AND LIMITATIONS OF LIABILITY
                SET FORTH IN THIS AGREEMENT, THAT THE DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN REFLECT
                A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN YOU AND COMPANY, AND THAT THE DISCLAIMERS AND
                LIMITATIONS OF LIABILITY SET FORTH HEREIN FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN YOU AND
                COMPANY. COMPANY WOULD NOT BE ABLE TO PROVIDE THE SERVICES TO YOU ON AN ECONOMICALLY REASONABLE BASIS
                WITHOUT THESE LIMITATIONS.</span></li>
        <li class="c1"><span class="c3">TO THE EXTENT THAT APPLICABLE LAW PROHIBITS LIMITATION OF SUCH LIABILITY,
                COMPANY SHALL LIMIT ITS LIABILITY TO THE FULL EXTENT ALLOWED BY APPLICABLE LAW.</span></li>
    </ol><a id="id.3rdcrjn"></a>
    <ol start="11">
        <li class="c7"><span class="c4">Indemnification</span><span>. You agree to defend, to covenant not to
                sue, to indemnify and hold harmless, and to forever release and discharge Company and Company&rsquo;s
                subsidiaries, affiliates, officials, officers, directors, principals, members, employees, personnel,
                attorneys, &nbsp;authorized agents, predecessors, assigns, successors, licensees, insurers, and
                designees (collectively &ldquo;</span><span class="c4">Indemnitees</span><span>&rdquo;) from
                any and all injuries (whether personal injuries, property damage or otherwise), including those injuries
                set forth in </span><span class="c6" (click)="scrollTo('id.tyjcwt')"><a class="c11">Paragraph 8</a></span><span
               >, demands, suits, costs, and/or claims relating in any way to any Event, the purchase of
                Ticket(s) hereunder, or use of the Site. </span></li>
        <li class="c7"><span class="c4">Data Collection</span><span>. </span></li>
    </ol>
    <ol start="1" type="a">
        <li class="c1"><span class="c4">Third Party Data Collection</span><span>. Company may permit
                you to register for and log on to the Site to access Products or Services operated by certain
                third-party services. Such third party&rsquo;s collection, use, and disclosure of your information will
                be subject to that third-party service&rsquo;s privacy notice. &nbsp;You agree that Company shall not be
                liable to you for any losses or claims sustained by you as a result of such third party&rsquo;s use,
                storage, or exploitation. &nbsp;If applicable, information about the collection, use, and/or disclosure
                of your personal information when you link your Company account with your account on any third-party
                service can be found in our Privacy Notice.</span></li>
        <li class="c1"><span>Upon accessing and using the Site, which includes logging in to existing
                accounts, registering, entering contests, subscribing for Services, purchasing Products, viewing or
                downloading content, and otherwise, you grant Company the right to automatically collect, store, and
                exploit your information, including, but not limited to, IP addresses, operating systems, referring web
                pages, locations, mobile carriers, devices used (i.e., cell phones, laptops, and tablets, among others),
                search terms, and Cookie information (collectively, the &ldquo;</span><span class="c4">Derivative
                Data</span><span>&rdquo;) to help Company diagnose problems and improve the Site for user
                experience, including to measure traffic and usage trends. </span></li>
    </ol>
    <ol start="1" type="i">
        <li class="c13"><span>In order to purchase a Ticket(s), you acknowledge and agree that you may be
                required to provide personal information including, but not limited to, name, email address, mailing
                address, zip code, telephone number, date of birth, financial information, age verification, and/or
                country of residence (collectively, &ldquo;</span><span class="c4">Personal Information</span><span
               >&rdquo;). </span></li>
        <li class="c13"><span>You acknowledge and agree that Company may collect, store, and use the Personal
                Information for legitimate business purposes, such as, but not limited to, processing your payment,
                fulfilling your order, updating you with the order status, shipping and tracking information, responding
                to customer service requests, and providing updated information to Purchaser regarding any respective
                Event, among others. </span></li>
        <li class="c13"><span>Additionally, you grant Company the right to share the foregoing data with
                third parties, including Event Organizers. </span></li>
    </ol>
    <ol start="13">
        <li class="c7"><span class="c4">Assignment</span><span>. Company shall, at all times, have the right
                to assign or delegate any or all of its rights, titles, interests, or duties under this Agreement
                without prior notification to, or consent from, you. You shall not have the right to assign any of your
                rights, title, privileges, and/or interests in and under this Agreement without the prior written
                consent of Company (which consent may be withheld, delayed, or conditioned by Company in its sole
                discretion), including any potential claims that you may assert in relation to any Disputes arising
                under this Agreement. Any attempt by you to assign any of your rights, title, privileges, and/or
                interest in and under this Agreement in contravention of the terms of this Agreement shall be void
            </span><span>ab initio</span><span>.</span></li>
        <li class="c7"><span class="c4">Governing Law</span><span>. &nbsp;This Agreement and all claims
                relating to or arising out of this Agreement or the breach thereof shall be governed and construed in
                accordance with the laws of the State of Florida, notwithstanding any contrary choice-of-law principles.
                The Parties acknowledge that in the event of a Dispute between the Parties, venue shall lie exclusively
                in Miami-Dade County. You further agree not to raise any defenses on grounds of forum non-conveniens or
                lack of personal jurisdiction.</span></li>
        <li class="c7"><span class="c4">Entire Agreement</span><span>. This Agreement, which represents the
                entire agreement between you and Company with respect to the subject matter of this Agreement,
                supersedes and replaces all prior terms and conditions and other governing documents between the
                Parties, which prior terms and conditions are acknowledged by the Parties to be of no further binding
                legal effect</span><span class="c4">.</span></li>
        <li class="c7"><span class="c4">Modification of Terms.</span><span>&nbsp;Company may amend any of
                the terms of these terms and conditions on the Site by posting the amended terms. Your continued use of
                the Site or your continued use of the Services after the effective date of the revised terms and
                conditions constitutes your acceptance of the terms.</span></li>
        <li class="c7"><span class="c4">Severability</span><span>. &nbsp;If any term, condition, limitation,
                or other provision of this Agreement is found by a court or other tribunal of competent jurisdiction to
                be invalid, illegal, or otherwise unenforceable, the unenforceable provision shall not affect the
                otherwise valid terms, provisions, spirit of, or the whole of this Agreement. The applicable terms or
                provisions shall be deemed modified to the extent necessary to render such provision enforceable, and
                the rights and obligations of the Parties shall be construed and enforced accordingly, preserving to the
                fullest permissible extent the intent and agreements of the Parties set forth in this Agreement.</span>
        </li>
        <li class="c7"><span class="c4">Section Headings</span><span>. &nbsp;The Section headings used in
                these terms and conditions are for convenience only and shall not be given any legal import.</span></li>
        <li class="c7"><span class="c4">Reasonableness; No Penalty</span><span>. You acknowledge and agree
                that the restrictions specified in these terms and conditions are not burdensome and shall not be deemed
                to constitute a penalty or any other action contrary to public policy but instead are only intended to
                cause or induce you to perform or fully and strictly comply with the terms and conditions of this
                Agreement. </span></li>
        <li class="c7"><span class="c4">Adjudication of Suspicious Transactions</span><span>. You acknowledge
                and agree that Company reserves the sole and exclusive right to investigate and adjudicate suspicious
                transactions and shall be the sole and final arbiter regarding actual, potential, threatened, or
                suspected violations of this Agreement, and Company shall have the right to revoke your License
                hereunder as a remedy.</span></li>
        <li class="c7"><span>Material Breach. &nbsp;You acknowledge and agree that any violation of
                provisions including, but not limited to, </span><span class="c6" (click)="scrollTo('id.2et92p0')"><a class="c11"
                    >Paragraph 7</a></span><span>, </span><span class="c6" (click)="scrollTo('id.3dy6vkm')"><a
                    class="c11">Paragraph 9</a></span><span>, and </span><span
                class="c6" (click)="scrollTo('id.17dp8vu')"><a class="c11">Paragraph 10</a></span><span>, by you shall
                constitute a material breach of this Agreement entitling Company and Event Organizer to the remedies
                available at law. </span></li>
    </ol>
  </div>
</div>
