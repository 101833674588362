<h2 i18n="souncloud | page title" mat-dialog-title style="color: #fff; text-align: center;">SoundCloud</h2>

<mat-dialog-content>
  <form #form="ngForm">
    <div class=""></div>
    <input type="text" name="url" class="form-control" [ngModel]="url" required>
  </form>

</mat-dialog-content>

<mat-dialog-actions>
  <div class="col-md-6 col-xs-6 pull-right p0">
    <button i18n="@@EnterLink" class="btn btn-success" [disabled]="form.invalid" (click)="submit(form)">Enter link</button>
  </div>
  <div class="col-md-6 col-xs-6 pull-left p0">
    <button i18n="@@Close" class="btn btn-danger pull-left" [matDialogClose]>Close</button>
  </div>
</mat-dialog-actions>
