
  <div class="row">
    <div class="col-md-12 col-xs-12" style="background-color: #24262B;">
    <p class="c14" dir="rtl"><span style="font-weight: 700;font-size: 14px;">מדיניות פרטיות – מערכת ניהול אירועים&nbsp;גט אין בע"מ</span></p>
      <p style="height: 12px;" dir="rtl"></p>
    <ol class="c6 lst-kix_list_1-0 start" start="1">
      <li class="c4 li-bullet-0" dir="rtl"><span class="c2">כללי:</span></li>
    </ol>
    <ol class="c6 lst-kix_list_1-1 start" start="1">
      <li class="c1 li-bullet-1" dir="rtl"><span>מדיניות הפרטיות תחול על השימוש במערכת מבוססת אתר אינטרנט ו/או
          אפליקציה סלולרית "גט אין" שנועדה בכדי לספק שירותים של רכישה, גבייה, מידע ופרסום למפיקים ולמשתתפים באירועים
          ובכנסים תרבותיים, חינוכיים, עסקיים, ספורטיביים ואחרים (להלן: "</span><span class="c10">המערכת</span><span
         >") ושהינה בבעלותה הבלעדית של גט אין בע״מ, ח.פ. 515441160&nbsp;(להלן: "</span><span
          class="c10">החברה</span><span>") .</span></li>
      <li class="c1 li-bullet-1" dir="rtl"><span>מהטעם שהחברה מקפידה על שמירת פרטיותם של המשתמשים במערכת, הנך
          מתבקש לקרוא בעיון את מדיניות הפרטיות המפורטת במסמך זה (להלן: "מדיניות הפרטיות"), בטרם תעשה שימוש במערכת. </span>
      </li>
      <li class="c1 li-bullet-1" dir="rtl"><span>מדיניות הפרטיות מהווה חלק בלתי נפרד מתנאי השימוש במערכת, אשר
          זמינים באתר החברה אשר בכתובת www.getin.com (להלן: "</span><span class="c10">תנאי השימוש</span><span
         >"). מובהר, כי ההגדרות שבתנאי השימוש חלות גם על מדיניות הפרטיות.</span></li>
      <li class="c1 li-bullet-2" dir="rtl"><span>מדיניות הפרטיות מפרטת את הנהלים והאופן בו תעשה החברה שימוש
          במידע שיסופק על ידך ו/או שיגיע לחברה עקב השימוש שתעשה במערכת. </span></li>
      <li class="c1 li-bullet-2" dir="rtl"><span>סמכות השיפוט הייחודית והבלעדית בכל הקשור לנושאים הנובעים או
          הקשורים לחברה ו/או לתנאי השימוש ו/או למדיניות פרטיות זו, תהא לבית המשפט המוסמך שמקום מושבו בעיר תל אביב שבישראל.
        </span></li>
      <li class="c1 li-bullet-1" dir="rtl"><span>הדין שחל על מדיניות הפרטיות הנו הדין הישראלי. מדיניות הפרטיות
          כפופה לחוק הגנת הפרטיות, התשמ"א-1981 (להלן: "</span><span class="c10">חוק הגנת הפרטיות</span><span>")
          ולכל הוראת דין שלא ניתן להתנות עליה.</span></li>
      <li class="c1 li-bullet-1" dir="rtl" id="h.gjdgxs"><span>מדיניות הפרטיות מנוסחת בלשון זכר יחיד אך פונה
          לגברים ונשים, ביחיד וברבים.</span></li>
    </ol>
      <p style="height: 12px;" dir="rtl"></p>
    <ol class="c6 lst-kix_list_1-0" start="2">
      <li class="c4 li-bullet-0" dir="rtl"><span class="c2">הסכמה:</span></li>
    </ol>
    <ol class="c6 lst-kix_list_1-1 start" start="1">
      <li class="c1 li-bullet-1" dir="rtl"><span>מעצם השימוש במערכת אתה באופן עצמאי ומרצונך החופשי, מאשר את
          תנאי השימוש ומדיניות הפרטיות של החברה ובכלל זאת אתה מאשר לחברה לאסוף ולהשתמש במידע הפרטי שלך בהתאם להוראות
          מדיניות פרטיות זו.</span></li>
      <li class="c1 li-bullet-2" dir="rtl"><span>ידוע לך כי לא חלה עליך כל חובה לספק מידע במהלך השימוש שתעשה
          במערכת אלא בהתאם לרצונך החופשי, אך אם תימנע מלהעביר את המידע הרי שההנאה של מן השימוש במערכת עשויה להיפגם.</span>
      </li>
      <li class="c1 li-bullet-2" dir="rtl"><span>אתה מאשר שידוע לך כי מידע שתפרסם במערכת הנוגע לאירועים שהנך
          מארגן, עשוי להיות גלוי לצדי ג' נוספים שיעשו במידע שימוש כזה או אחר, והנך פוטר את החברה מכל חבות כלפיך בעניין זה
          ומתחייב כי תשפה את החברה בגין כל חבות שתקום לה כלפיך, כתוצאה מהפרסום האמור במערכת.</span></li>
    </ol>
      <p style="height: 12px;" dir="rtl"></p>
    <ol class="c6 lst-kix_list_1-0" start="3">
      <li class="c4 li-bullet-0" dir="rtl"><span class="c2">איסוף מידע</span></li>
    </ol>
    <p class="c5 c25" dir="rtl" id="h.30j0zll"><span>להלן יפורט המידע שעשוי להיאסף על ידי החברה עקב השימוש
        שתעשה במערכת:</span></p>
    <ol class="c6 lst-kix_list_1-1" start="1">
      <li class="c1 li-bullet-2" dir="rtl"><span>פרטי התקשרות: שם פרטי, שם משפחה, דוא"ל, טלפון, כתובת.</span>
      </li>
      <li class="c1 li-bullet-2" dir="rtl"><span>פרטים אישיים נוספים: ת.ז., גיל, מיקום, מספר כרטיס אשראי, גישה
          לאנשי קשר, גישה לפרופיל שלך ברשתות חברתיות, תמונות.</span></li>
      <li class="c1 li-bullet-1" dir="rtl"><span>כל מידע אחר שתתבקש לספק במהלך השימוש שתעשה במערכת.</span></li>
      <li class="c1 li-bullet-2" dir="rtl"><span>פרטי תשלום בהתאם לדרישת חברות האשראי, מידע זה לא יישמר בידי
          החברה.</span></li>
      <li class="c1 li-bullet-2" dir="rtl"><span>מידע שנוגע להרגלי הגלישה שלך באינטרנט, לתחומים ונושאים שמצאת
          בהם עניין, למידע מן הדפדפן שלך, לכתובת אינטרנט (IP), לעוגיות (cookies), לדפוסי שימוש במערכת, למעקב ולצילום
          תנועתיות במערכת, לחותמות שעה ותאריך ועוד.</span></li>
      <li class="c1 li-bullet-2" dir="rtl"><span>מידע סטטיסטי (להלן: "</span><span class="c10">מידע
          סטטיסטי</span><span>") שאינו מזוהה אישית ושנוגע לפעילות שעושים פרטים במערכת. </span></li>
    </ol>
      <p style="height: 12px;" dir="rtl"></p>
    <ol class="c6 lst-kix_list_1-0" start="4">
      <li class="c4 li-bullet-0" dir="rtl"><span class="c2">השימוש במידע</span></li>
    </ol>
    <ol class="c6 lst-kix_list_1-1 start" start="1">
      <li class="c1 li-bullet-1" dir="rtl" id="h.1fob9te"><span>להלן יפורטו המטרות של השימוש שתעשה החברה במידע
          שיימסר על ידך אגב השימוש שתעשה במערכת. החברה לא תעשה שימוש ולא תמסור מידע שנוגע אליך, אלא בהתאם למטרות המפורטות
          בסעיף 4 זה, במדיניות הפרטיות ולהוראות הדין.</span></li>
      <li class="c1 li-bullet-1" dir="rtl"><span>השימוש במידע שהחברה עשויה לעשות כולל, בין השאר, את המטרות
          הבאות: (1) זיהוי המשתמש לצורך התחברות ושימוש במערכת; (2) הנגשת מידע הנוגע לאירוע שמשתמש מצא בו עניין; (3) יצירת
          קשר בין מארגנים למשתמשים לשם העברת מידע שלהערכת החברה ו/או המארגן עשוי להיות רלוונטי למשתמש; (4) שמירת תכתובות
          ו/או שיחות שנערכו עימך לצורך בירור עתידי; (5) מחקר, ניתוח נתונים וסטטיסטיקות; (6) התאמה אישית ושיפור של תוכן
          השירות למשתמש; (7) ציות לחוק, בירור תלונות, התדיינויות משפטיות, חשד להונאה ו/או להתנהגות בלתי הולמת.</span></li>
      <li class="c1 li-bullet-1" dir="rtl" id="h.3znysh7"><span>יובהר כי מארגן אירוע רשאי לקבל מן החברה כל מידע
          שברשות החברה הנוגע לאירוע שהוא המארגן שלו ובכלל זה מידע של משתמשים אשר הביעו עניין באירוע האמור ו/או רכשו
          כרטיסים המיועדים להשתתפות באירוע האמור. </span></li>
      <li class="c1 li-bullet-1" dir="rtl"><span>דיוור ורשימת תפוצה - החברה ו/או מארגן עשויים להתקשר עמך
          ולהעביר לידיך מידע פרסומי שלהערכת החברה ייתכן שתמצא בו עניין. המידע הפרסומי יועבר אליך באמצעות הודעות מסרון ו/או
          הודעות דוא"ל ו/או הודעות push ו/או דואר ישראל ו/או שיחת טלפון ו/או בכל דרך התקשרות רלוונטית אחרת. </span></li>
    </ol>
    <p class="c20" dir="rtl"><span>אתה רשאי להורות לחברה בכל עת על הפסקת הדיוור אליך באמצעות משלוח דוא"ל
        לכתובת [_______________] ו/או משלוח מסרון עם המילה "הסר" לטלפון מספר [_______________].</span></p>
    <ol class="c6 lst-kix_list_1-1" start="5">
      <li class="c1 li-bullet-1" dir="rtl"><span>יובהר כי מעת שמידע יצא מידי החברה, בכפוף להוראות מדיניות
          פרטיות זו, אין החברה אחראית לשימוש בו ולא יהיה כל תוקף לטענה ו/או לתביעה כלפי החברה בעניין המידע האמור.</span>
      </li>
    </ol>
      <p style="height: 12px;"></p>
    <ol class="c6 lst-kix_list_1-0" start="5">
      <li class="c4 li-bullet-0" dir="rtl"><span class="c2">שמירת המידע</span></li>
    </ol>
    <ol class="c6 lst-kix_list_1-1 start" start="1">
      <li class="c1 li-bullet-1" dir="rtl"><span>מידע שנמסר לחברה אגב השימוש במערכת יישמר במאגרי המידע של החברה
          ו/או אצל קבלני משנה שהחברה התקשרה עימם לשם שמירת המידע. </span></li>
      <li class="c1 li-bullet-1" dir="rtl"><span>לשם הגנה על המידע שברשות החברה, מיישמת החברה נהלי אבטחת מידע
          מקובלים וסבירים והיא אף עשויה לבקש ממך מעת לעת להזדהות ולעדכן את פרטי ההתקשרות שלך לחברה ולמערכת.</span></li>
      <li class="c1 li-bullet-2" dir="rtl"><span>בכדי לסייע לחברה להגן על המידע הנוגע לשימוש שלך במערכת הנך
          נדרש להימנע ממסירה לאחר של פרטי האימות והשימוש שעשית במערכת.</span></li>
      <li class="c1 li-bullet-2" dir="rtl"><span>מובהר החברה אינה יכולה להתחייב כי המידע וכל הכלול באתר
          ובשירותים הניתנים בו חסינים מפני גישה בלתי מורשית אליהם והיא אינה יכולה להבטיח שצדדים שלישיים בלתי מאושרים לא
          ישיגו גישה למידע. בהתאם, החברה לא תישא באחריות על כל נזק שייגרם למשתמש, אם ייגרם, כתוצאה מחטיפת מידע, שינוי או
          שימוש בלתי ראוי במידע במהלך העברות מידע.</span></li>
    </ol>
      <p style="height: 12px;" dir="rtl"></p>
    <ol class="c6 lst-kix_list_1-0" start="6">
      <li class="c4 li-bullet-0" dir="rtl"><span class="c2">שיתוף במידע</span></li>
    </ol>
    <p class="c5 c25" dir="rtl"><span>החברה תהיה רשאית להעביר לצדדים שלישיים מידע, לרבות מידע אישי, מבלי לקבל את
        הסכמת נוספת לכך מעת המשתמש במקרים הבאים:</span></p>
    <ol class="c6 lst-kix_list_1-1" start="5">
      <li class="c1 li-bullet-1" dir="rtl"><span>החברה סבורה כי מסירת המידע דרושה למניעת נזק למשתמש או לצדדים
          שלישיים.</span></li>
      <li class="c1 li-bullet-1" dir="rtl"><span>מסירת המידע נדרשת מכח הדין ו/או צו שיפוטי ו/או הוראה של רשות
          מוסמכת כלשהי.</span></li>
      <li class="c1 li-bullet-1" dir="rtl"><span>משתמש מפר את תנאי השימוש באתר או מבצע פעולות אסורות על פי דין
          (לרבות ניסיון לביצוע פעולות שכאלה).</span></li>
      <li class="c1 li-bullet-2" dir="rtl"><span>הגילוי נדרש לצורך הליכים משפטיים בהם החברה מעורבת.</span></li>
      <li class="c1 li-bullet-2" dir="rtl"><span>במקרה של מיזוג ו/או שינוי מבני של&nbsp;החברה או מיזוג פעילותה
          עם פעילותו של צד שלישי ו/או בקשר להעברה כלשהי של העסקים של האתר.</span></li>
    </ol>
      <p style="height: 12px;"></p>
    <ol class="c6 lst-kix_list_1-0" start="7">
      <li class="c4 li-bullet-0" dir="rtl"><span class="c2">שימוש מארגן במידע</span></li>
    </ol>
    <ol class="c6 lst-kix_list_1-1 start" start="1">
      <li class="c1 li-bullet-2" dir="rtl"><span>מארגן אירוע מחויב להקפיד הקפדה יתרה על מלוא הוראות תנאי
          השימוש, מדיניות הפרטיות של החברה והוראות כל דין.</span></li>
      <li class="c1 li-bullet-2" dir="rtl"><span>המשתמש מאשר כי ידוע לו שמידע שלו יועבר למארגן אירוע שהמשתמש
          רכש כרטיסים לשם השתתפות באירוע שהמארגן האמור מפיק.</span></li>
      <li class="c1 li-bullet-1" dir="rtl"><span>המידע שיימסר למארגן האירוע יכול שישמש ל: (1) כדי לבצע, להשלים
          או לאשר עסקה או השתתפות באירוע (2) למטרות העסקיות הפנימיות של המפיק כגון מיקוד פרסום, ניתוח נתונים, ביקורות
          ומטרות פנימיות אחרות (3) משלוח למשתמש, באמצעות דואר, דוא"ל, מסרונים לטלפון הנייד או כל אמצעי תקשורת אחר ישיר
          (לרבות באמצעות דואר, טלפון, דואר אלקטרוני, הודעות מסר קצר, מערכת חיוג אוטומטית וכיו"ב), מידע אינפורמטיבי אודות
          המפיק, שירותיו ומוצריו, כמו גם הודעות מותאמות לתחומי העניין שלך, מידע בנוגע למוצרים ו/ו לשירותים אחרים שעשויים,
          לדעת המארגן לעניין את המשתמש, לרבות תוכן שיווקי ו/או תוכן קידום מכירות.</span></li>
    </ol>
    <p style="height: 12px;" dir="rtl"></p>
    <ol class="c6 lst-kix_list_1-0" start="8">
      <li class="c4 li-bullet-0" dir="rtl"><span class="c2">ספקי החברה</span></li>
    </ol>
    <ol class="c6 lst-kix_list_1-1 start" start="1">
      <li class="c1 li-bullet-2" dir="rtl" id="h.2et92p0"><span>לשם מתן השירותים שמספקת החברה באמצעות המערכת,
          לרבות, שירותי האחסנה של מידע ו/או מחקר וניתוח פעילות משתמשים ו/או סטטיסטיקות בנוגע לשימוש במערכת ו/או שיפור של
          חווית השימוש במערכת, עשויה החברה לשתף מידע שנוגע למשתמשים עם ספקים וקבלני משנה, בארץ ו/או בחו"ל (להלן:
          "</span><span class="c10">ספקי החברה</span><span>"). </span></li>
      <li class="c1 li-bullet-1" dir="rtl"><span>החברה דורשת מספקי החברה לעמוד בהוראות מדיניות פרטיות זו, חל
          עליהם איסור לשתף את המידע עם כל צד ג' אחר ואין הם רשאים לעשות שימוש במידע בעצמם, מבלי לקבל את האישור המפורש של
          החברה לכך, אשר יינתן בכפוף למדיניות פרטיות זו ולכל דין.</span></li>
      <li class="c1 li-bullet-1" dir="rtl"><span>בתום התקשרות החברה עם ספק חברה, יידרש ספק החברה האמור להשיב
          לחברה כל מידע רלוונטי שהועבר לידי ספק החברה על ידי החברה ולמחוק כל מידע קיים שברשותו.</span></li>
    </ol>
      <p style="height: 12px;"></p>
    <ol class="c6 lst-kix_list_1-0" start="9">
      <li class="c4 li-bullet-0" dir="rtl"><span class="c2">עיון במידע</span></li>
    </ol>
    <p class="c5 c25" dir="rtl"><span>כל משתמש זכאי לפנות לחברה לצורך עיון במידע המתייחס אליו והמצוי במאגר המידע של
        החברה. בנוסף, זכאי המשתמש בהתאם להוראות כל דין לבקש שינוי ו/או תיקון המידע לגביו ו/או מחיקתו. יצוין כי על אף האמור
        לעיל החברה רשאית בכל מקרה לשמור במאגריה מידע לגבי המשתמש אשר נאסף על ידה באופן עצמאי ו/או שאינו משמש אותה לצורך
        פניה למשתמש (כגון מידע הדרוש לחברה או מסייע לה בפעילותה העסקית) והכל בהתאם ובכפוף להוראות הדין.</span></p>
      <p style="height: 12px;" dir="rtl"></p>
    <ol class="c6 lst-kix_list_1-0" start="10">
      <li class="c4 li-bullet-0" dir="rtl"><span class="c2">המחאה של זכויות</span></li>
    </ol>
    <p class="c5 c25" dir="rtl"><span>החברה תהיה רשאית להמחות ו/או להסב את הזכויות וההתחייבויות שלה לפי מדיניות
        פרטיות זו, ולרבות העברת המידע שנוגע אליך לצד שלישי כלשהו ללא הגבלה כלשהי ובלבד שהצד השלישי האמור יאשר בכתב את
        מחויבותו לעמוד בהוראות מדיניות פרטיות זו ובהתאם לדין.</span></p>
      <p style="height: 12px;" dir="rtl"></p>
    <ol class="c6 lst-kix_list_1-0" start="11">
      <li class="c4 li-bullet-0" dir="rtl"><span class="c2">שם המשתמש וסיסמה</span></li>
    </ol>
    <p class="c5 c25" dir="rtl"><span>החברה תקצה לכל משתמש שם משתמש, סיסמה ואמצעי זיהוי חשבון אחרים במטרה לאפשר את
        כניסתו למערכת. שם המשתמש והסיסמה מיועדים לשימוש אישי בלבד וכל כניסה למערכת באמצעותם, תיחשב ככניסה מאושרת של אותו
        המשתמש שהינו בעליהם ועל החברה לא תחול כל חובה נוספת לבדוק ו/או לוודא את אמינות ו/או טיב האישור ו/או המקור של כל
        כניסה אמורה.</span></p>
      <p style="height: 12px;" dir="rtl"></p>
    <ol class="c6 lst-kix_list_1-0" start="12">
      <li class="c4 li-bullet-0" dir="rtl"><span class="c2">פרטיות ילדים וקטינים</span></li>
    </ol>
    <p class="c5 c25" dir="rtl"><span>המערכת אינה מיועדת לשימוש קטינים מתחת לגיל 18 וכל שימוש כזה הינו אסור אלא אם
        ניתן לכך אישור אפוטרופוס ו/או הורה. משתמש במערכת מצהיר בזה כי הינו בגיר וכשיר כחוק להתחייב משפטית כלפי החברה.
        החברה לא תהא חייבת לכבד, והיא שומרת לעצמה את הזכות לבטל בדיעבד בהתקשרויות אשר נעשו על ידי קטינים מתחת לגיל 18 שלא
        קיבלו להתקשרות אישור אפוטרופוס ו/או הורה. &nbsp;</span></p>
    <p style="height: 12px;" dir="rtl"></p>
    <ol class="c6 lst-kix_list_1-0" start="13">
      <li class="c4 li-bullet-0" dir="rtl"><span class="c2">גישה שלך למידע אישי</span></li>
    </ol>
    <p class="c5 c25" dir="rtl" id="h.tyjcwt"><span>הנך רשאי לבקש מידע הנוגע אליך, אשר בידי החברה, כמו גם לבקש את
        מחיקתו ו/או תיקונו. כדי לממש זכות זו, עליך לשלוח דואר אלקטרוני עם פרטים שיאפשרו לנו לאמת את זהותך ולציין את בקשתך
        המפורשת לכתובת הבאה: [_______________].</span></p>
      <p style="height: 12px;" dir="rtl"></p>
    <ol class="c6 lst-kix_list_1-0" start="14">
      <li class="c4 li-bullet-0" dir="rtl" id="h.3dy6vkm"><span class="c2">עדכון מדיניות הפרטיות</span></li>
    </ol>
    <p class="c5 c25" dir="rtl"><span>לחברה עומדת הזכות לעדכן מדיניות פרטיות זו בכל עת וככל שתעשה כן תיודע על
        העדכון האמור על-ידי באמצעות הודעת דוא"ל ו/או מסרון ותתבקש לאשר את העדכון האמור. ככל שתבחר שלא לאשר את העדכון האמור
        החברה עשויה שלא לאפשר את המשך השימוש במערכת.</span></p>
      <p style="height: 12px;" dir="rtl"></p>
    <ol class="c6 lst-kix_list_1-0" start="15">
      <li class="c4 li-bullet-0" dir="rtl"><span class="c2">בירור מחלוקות</span></li>
    </ol>
    <p class="c5 c25" dir="rtl"><span>המשתמש מבין, מסכים ומאשר כי על מדיניות הפרטיות זו חל הדין הישראלי בלבד וכי
        לבתי המשפט המוסמכים המצויים בתל אביב, ישראל, תהיה סמכות שיפוט בלעדית בכל מקרה של כל מחלוקת, תלונה או תביעה הנוגעת
        למדיניות הפרטיות ו/או לאתר ו/או לשירות המוצע באתר ו/או כל תביעה שהחברה ו/או בעליה הינם צד לה.</span></p>
    <p style="height: 12px;" dir="rtl"></p>
    <ol class="c6 lst-kix_list_1-0" start="16">
      <li class="c4 li-bullet-0" dir="rtl"><span class="c2">פרטי התקשרות עם החברה</span></li>
    </ol>
    <h3 class="c19" dir="rtl"><span class="c12">שם החברה: &nbsp;גט אין בע"מ</span></h3>
    <h3 class="c19" dir="rtl"><span class="c12">מען למכתבים: &nbsp;הגולן 74 א' בתל אביב</span></h3>
    <h3 class="c19" dir="rtl"><span>דוא״ל: </span><span><a style="color:#fff; font-weight: 700;"
          href="mailto:law.office@pekerman-sagi.com">support@get-in.com</a></span></h3>
      <p style="height: 12px;" dir="rtl"></p>
    <p class="c5 c25" dir="rtl"><span class="c12">תנאי שימוש אלה תקפים ליום ה: 06/07/21</span></p>
      <p style="height: 12px;" dir="rtl"></p>
    </div>
  </div>
