import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Validators} from "@angular/forms";
import {FeeTemplate} from "../interfaces/fee-templates.interface";

@Injectable({
  providedIn: 'root'
})
export class CmsFeeManagmentTemplatesService {
  api_base_url = environment.api_base_url + '/api/';
  constructor(private http: HttpClient) { }

  getFeeTemplates (global_producer_id = null, event_producer_id = null) {
    let params = new HttpParams();
    if(global_producer_id) {
      params = params.set('global_producer_id', global_producer_id)
    }
    if(event_producer_id) {
      params = params.set('event_producer_id', event_producer_id)
    }

    return this.http.get(this.api_base_url + 'cms/fee-management/template',
      {
        headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'}),
        params: params
      });
  }

  getFeeTemplate (id) {
    return this.http.get(this.api_base_url + 'cms/fee-management/template/'+id,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      });
  }

  createFeeTemplate (data: FeeTemplate) {
    return this.http.post(this.api_base_url + 'cms/fee-management/template', data,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      });
  }

  updateFeeTemplate(id, data) {
    return this.http.patch(this.api_base_url +'cms/fee-management/template/'+id, data,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      });
  }
  deleteFeeTemplate(id) {
    return this.http.delete(this.api_base_url + 'cms/fee-management/template/'+id,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      });
  }


  // cms-producers-list
  getProducerTemplate(template_id) {
    return this.http.get(this.api_base_url + 'cms/fee-management/template/'+template_id+'/producers',
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      });
  }

  addProducerTemplate (template_id, data) {
    // data = {
    //   "user_id": {id}
    // }
    return this.http.post(this.api_base_url + 'cms/fee-management/template/'+template_id+'/producers', data,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      });
  }
  deleteProducerTemplate(template_id, id) {
    return this.http.delete(this.api_base_url + 'cms/fee-management/template/'+template_id+'/producers/'+id,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      });
  }
  // cms-producers-list END
  // cms-events-list
  getEventTemplate(template_id) {
    return this.http.get(this.api_base_url + 'cms/fee-management/template/'+template_id+'/events',
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      });
  }

  addEventTemplate (template_id, data) {
    // data = {
    //   "event_id": {id}
    // }
    return this.http.post(this.api_base_url + 'cms/fee-management/template/'+template_id+'/events', data,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      });
  }
  deleteEventTemplate(template_id, id) {
    return this.http.delete(this.api_base_url + 'cms/fee-management/template/'+template_id+'/events/'+id,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      });
  }
  // cms-events-list END
  getEventsHistoryTemplate(template_id) {
    return this.http.get(this.api_base_url + 'cms/fee-management/template/'+template_id+'/events-history',
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      });
  }

}
