<section *ngIf="lineup.length">
    <h3>{{'eventPageLineUp.Lineup' | translate:'Lineup'}}</h3>
    <hr>
    <div class="lineup-wrap">
        <mat-expansion-panel hideToggle [expanded]="panelOpenState" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header class="lineup-header" [ngClass]="{'hide': panelOpenState}">
                <mat-panel-description (click)="$event.stopPropagation();" style="cursor: default;">
                    <div class="lineup-header-artists">
                        <ng-container *ngFor="let item of lineup | slice:0:3; let i=index">
                          <a class="lineup-artist-logo" href="{{item.external_urls}}" target="_blank">
                            <img *ngIf="item.image_url" [src]="item.image_url" alt="{{item.name}}">
                          </a>
                        </ng-container>
                        <span *ngIf="lineup.length > 3" class="lineup-artist-more">{{lineup.length - 3}}+</span>
                    </div>
                    <div class="lineup-header-artists-names">
                        <ng-container *ngFor="let item of lineup | slice:0:3; let i=index;let last = last">
                          <a href="{{item.external_urls}}"><span *ngIf="item.name" class="lineup-artist-name" target="_blank">{{item.name}}{{last? '': ', '}}</span></a>
                        </ng-container>
                        <p *ngIf="lineup.length > 3" class="lineup-artist-name-more">and {{lineup.length - 3}} more</p>
                    </div>
                    <div>
                        <button *ngIf="lineup.length > 3" class="btn-event-action" (click)="scrollToArtists();panelOpenState = true" type="button">
                            <span class="btn-event-action-txt">{{'eventPageLineUp.ShowMore' | translate:'Show More'}}</span>
                            <span class="btn-event-action-icon"><img src="/assets/images/svg-icons/arrow_drop_down_circle.svg"></span>
                        </button>
                    </div>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="lineup-body-artists">
                <ng-container *ngFor="let item of lineup; let i=index">
                    <div>
                      <a href="{{item.external_urls}}" target="_blank">
                        <img *ngIf="item.image_url" class="lineup-body-artist-logo" [src]="item.image_url" alt="{{item.name}}">
                      </a>
                      <a href="{{item.external_urls}}" target="_blank" *ngIf="item.name" class="lineup-body-artist-name">{{item.name}}</a>
                        <div class="lineup-body-artist-follow">
<!--                          <span>-->
<!--                              <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg" style="fill: black;">-->
<!--<path d="M5.5 9.99995L4.775 9.34995C3.93333 8.59162 3.2375 7.93745 2.6875 7.38745C2.1375 6.83745 1.7 6.3437 1.375 5.9062C1.05 5.4687 0.822917-->
<!--5.06662 0.69375 4.69995C0.564583 4.33328 0.5 3.95828 0.5 3.57495C0.5 2.79162 0.7625 2.13745 1.2875 1.61245C1.8125 1.08745 2.46667 0.824951 3.25-->
<!--0.824951C3.68333 0.824951 4.09583 0.916618 4.4875 1.09995C4.87917 1.28328 5.21667 1.54162 5.5 1.87495C5.78333 1.54162 6.12083 1.28328 6.5125-->
<!--1.09995C6.90417 0.916618 7.31667 0.824951 7.75 0.824951C8.53333 0.824951 9.1875 1.08745 9.7125 1.61245C10.2375 2.13745 10.5 2.79162 10.5 3.57495C10.5-->
<!--3.95828 10.4354 4.33328 10.3063 4.69995C10.1771 5.06662 9.95 5.4687 9.625 5.9062C9.3 6.3437 8.8625 6.83745 8.3125 7.38745C7.7625 7.93745 7.06667-->
<!--8.59162 6.225 9.34995L5.5 9.99995Z" />-->
<!--</svg>-->
<!--                          </span>-->
                        </div>
                    </div>
                </ng-container>
                <button class="btn-event-action btn-right" (click)="panelOpenState = false">
                    <span class="btn-event-action-txt">{{'eventPageLineUp.ShowLess' | translate:'Show Less'}}</span>
                    <span class="btn-event-action-icon"><img style="transform: rotate(180deg)" src="/assets/images/svg-icons/arrow_drop_down_circle.svg"></span>
                </button>
            </div>
        </mat-expansion-panel>
    </div>
</section>
