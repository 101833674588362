<!-- Require error + form submited -->
<div i18n="dynamic error alert | required inputs" *ngIf="
(formControl.hasError('required')
&& formControl.touched) ||
(formSubmited && formControl.hasError('required'))
" [ngClass]="customClass" [@slideIn]>field {
 inputName, select,
 first_name {First Name}
 last_name {Last Name}
 user_phone {Phone Number}
 user_email {Email}
 identification_number {Id Number}
 full_name {first & last name}
 age {Age}
 birth_date {Date of Birth}
 id_number {ID Number}
 gender {Gender}
 instagram_url {Instagram}
 custom_instagram_url {Instagram account}
 other { {{inputName}} }
} Required</div>

<!-- Pattern error + form submited -->
<div i18n="@@ErrorHandlingInvalidInput" *ngIf="(formControl.hasError('pattern') && formControl.touched) || (formSubmited && formControl.hasError('pattern')) || (formControl.hasError('invalid') && formControl.touched) || (formSubmited && formControl.hasError('invalid'))"
[ngClass]="customClass" [@slideIn]>Please enter correct {
 inputName, select,
first_name {First Name}
last_name {Last Name}
user_phone {Phone Number}
user_email {Email}
identification_number {Id Number}
age {Age}
other {}
} value</div>

<!-- Min / Max length -->
<div i18n="@@ErrorHandlingInvalididentificationnumber" *ngIf="
((formControl.hasError('minlength') || formControl.hasError('maxlength'))
&& formControl.touched) && inputName !== 'full_name' && inputName !== 'user_phone' && inputName !== 'user_email' ||
(formSubmited && formControl.hasError('minlength') || formControl.hasError('maxlength')) && inputName !== 'full_name'
" [ngClass]="customClass" [@slideIn]>Please enter a valid ID Number</div>

<div *ngIf="
(formControl.hasError('validateDates')
&& formControl.touched && !formControl.hasError('required') ||
formSubmited && formControl.hasError('validateDates') && !formControl.hasError('required'))
" [ngClass]="customClass" [@slideIn]>{{formControl.getError('validateDates')}}</div>

<div i18n="@@ErrorHandlingmaximumage120" *ngIf="
(formControl.hasError('max')
&& formControl.touched ||
formSubmited && formControl.hasError('max'))
" [ngClass]="customClass" [@slideIn]>Maximum age - 120</div>

<div i18n="@@ErrorHandlingValuemustBePositive" *ngIf="
(formControl.hasError('min')
&& formControl.touched ||
formSubmited && formControl.hasError('min'))
" [ngClass]="customClass" [@slideIn]>Field {{inputName}} must contain a positive value</div>

<div *ngIf="(formControl.hasError('phoneNumber') && formControl.touched && inputName == 'user_phone')"
     [ngClass]="customClass" [@slideIn]>
  {{'errorHandlings.PleaseEnterValidPhoneNumber' | translate:'Please enter a valid phone number'}}
</div>
