import { NavbarService } from './../services/navbar.service';
import { DialogService } from './../services/dialog.service';
import { EventService } from './../services/event.service';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { AppResponse } from '../interfaces/app-response.interface';
import {Router} from '@angular/router';
import { UserService } from './../services/user.service';
import {AuthService} from '../services/auth.service';
declare var window: any;

@Component({
  selector: 'app-create-event-button',
  templateUrl: './create-event-button.component.html',
  styleUrls: ['./create-event-button.component.css']
})
export class CreateEventButtonComponent implements OnInit {
  @Input() direction: string;
  @Input() textFromRight = false;
  user_id = 0;
  screenWidth: Number;
  buttonText = '';
  private user_info: any | null;

  @HostListener('window:resize', ['$event']) onResizeWindow(event) {
    this.screenWidth = window.innerWidth;
    this.changeButtonText();
  }

  constructor(
    private userService: UserService,
    private eventService: EventService,
    private router: Router,
    private dialogService: DialogService,
    public nav: NavbarService,
    private authService: AuthService
    ) {
      this.screenWidth = window.innerWidth;
    }

  ngOnInit() {
    this.nav.currentUserId.subscribe(user_id => this.user_id = user_id);
    this.nav.currentAuthStatus.subscribe(status => {
      // console.log('currentAuthStatus', status);
      if (status) {
        this.user_info = this.nav.getUserInfo();
        // console.log('user_info', this.user_info);
      }
    });
    this.changeButtonText();
    // this.userService.openLoginModal();// TEST PURPOSE
  }

  createEvent(){
    // first need to check is user logged in
    // console.log('createEvent', this.user_id, this.user_info);
    this.user_info = this.authService.getUserInfo();
    if (this.user_info && this.user_info.personal_info) {
      if (this.user_id > 0 ) {
        this.eventService.createNewEvent().subscribe(
          (response: AppResponse) => {
            // console.log('createEvent response', response.data);
            if (response.data &&  response.data.event_id) {
              this.router.navigate(['/edit-event/' + response.data.event_id]);
            }
          },
          (error: AppResponse) => this.dialogService.alertsModal('errros', (error.code) ? error.code : '4.1', 5000, false)
        );
      }
    } else if (this.user_info && !this.user_info.personal_info) {
      // console.log('checkUser and open personal info');
      this.dialogService.personalInfo(this.user_info, false);
    }
  }

  changeButtonText() {
    if (this.screenWidth <= 768) {
      this.buttonText = 'event';
    } else  {
      this.buttonText = 'create event';
    }
  }

  onLogin() {
    // console.log('openLoginModal create-event');
    let loginModal = this.userService.openLoginModal({action: 'create-event'});
    loginModal.subscribe(modal => {
      // console.log('close LoginModal', modal);
      if (modal.action == 'create-event' && this.user_id > 0) {
        this.createEvent();
      }
    });
  }
}
