<div class="row">
    <div class="col-md-12 col-xs-12 pull-right pr35">
        <button type="button" class="btn btn-success" *ngIf="web_hook_obj.status == 0 && web_hook_obj.status != -1" (click)="updateStatus('1')">Enable web-hook</button>
        <button type="button" class="btn btn-danger" *ngIf="web_hook_obj.status == 1 && web_hook_obj.status != -1" (click)="updateStatus('0')">Disable web-hook</button>
    </div>
  </div>

<form [formGroup]="web_hook_form" (ngSubmit)="submitForm()">
    <div class="col-md-12 col-xs-12 pl0 pr0 mt20">
      <div class="col-md-12 col-xs-12">
        <p>*Please select the desired trigger:</p>
        <select class="form-control" formControlName="trigger" name="trigger">
          <option value="">Choose trigger</option>
          <option value="1">New order</option>
          <option value="2">Refund order</option>
          <option value="3">New website registration</option>
          <option value="4">Event approved</option>
          <option value="5">new ticket purchase</option>
        </select>
        <div *ngIf="web_hook_form.controls.trigger.invalid && (web_hook_form.controls.trigger.dirty || web_hook_form.controls.trigger.touched)" class="item-error">
            <div *ngIf="web_hook_form.controls.trigger.errors.required">Must choose an option for this field</div>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-xs-12 pl0 pr0 mt20">
      <div class="ltr form-group col-md-2 col-xs-12">
        <label class="ltr" for="http_method_{{web_hook_obj.id}}">*Method:</label><br>
        <input type="radio" formControlName="http_method_{{web_hook_obj.id}}" name="http_method_{{web_hook_obj.id}}" id="http_method_get_{{web_hook_obj.id}}" value="get" (click)="toggle_method_block($event)"><label for="http_method_get_{{web_hook_obj.id}}">&nbsp; <span>GET</span></label>
        <input type="radio" formControlName="http_method_{{web_hook_obj.id}}" name="http_method_{{web_hook_obj.id}}" id="http_method_post_{{web_hook_obj.id}}" value="post" (click)="toggle_method_block($event)"><label for="http_method_post_{{web_hook_obj.id}}">&nbsp; <span>POST</span></label>
      </div>
      <div class="form-group col-md-10 col-xs-12">
        <label class="ltr pull-left" for="api_url">*URL:</label>
        <input type="text" formControlName="api_url" name="api_url" id="api_url" class="form-control ltr" placeholder="example: {{getin_base_url}} or {{getin_base_url}}/?first_name=$participant_first_name&last_name=$participant_last_name&email=$participant_email" required [pattern]="url_pattern">
        <div *ngIf="web_hook_form.controls.api_url.invalid && (web_hook_form.controls.api_url.dirty || web_hook_form.controls.api_url.touched)" class="item-error">
            <div *ngIf="web_hook_form.controls.api_url.errors.required">This field is required</div>
            <div *ngIf="web_hook_form.controls.api_url.errors.pattern">Must specify valid URL</div>
          </div>
      </div>
      <div *ngIf="show_block_method" class="form-group col-md-12 col-xs-12">
        <label class="ltr pull-left" for="post_data">Post data:</label><br>
        <textarea rows="5" *ngIf="show_block_method" formControlName="post_data" name="post_data" id="post_data" class="form-control ltr" placeholder="example: firstName=$participant_first_name&lastName=$participant_last_name&email=$participant_email" required [pattern]="post_data_pattern"></textarea>
        <div *ngIf="web_hook_form.controls?.post_data?.invalid && (web_hook_form.controls?.post_data?.dirty || web_hook_form.controls?.post_data?.touched)" class="item-error">
            <div *ngIf="web_hook_form.controls?.post_data?.errors?.required">This field is required</div>
            <div *ngIf="web_hook_form.controls.post_data.errors.pattern">
              Invalid value - valid value example : "first_name=$participant_first_name&last_name=$participant_last_name&email=$participant_email"
            </div>
          </div>
      </div>
    </div>
    <div *ngIf="web_hook_obj.status != -1" class="row">
        <div class="col-md-12 col-xs-12 pull-right pr30">
          <button type="submit" class="btn btn-success" [disabled]="!web_hook_form.valid">Save settings</button>
        </div>
    </div>
    <div *ngIf="web_hook_obj.status == -1" class=" col-md-12 col-xs-12 pl0 pr0 mt20"  [ngStyle]="{'opacity': !web_hook_form.valid ? 0.6: 1}">
        <div class="col-md-12 col-xs-12 pl0 pr0 save_settings text-center">
          <button type="submit" [disabled]="!web_hook_form.valid">Save settings</button>
        </div>
      </div>
</form>
