import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-payout-modal',
  templateUrl: './payout-modal.component.html',
  styleUrls: ['./payout-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PayoutModalComponent implements OnInit {
  terms = new UntypedFormControl(false, Validators.required);
  constructor(@Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    console.log('PayoutModalComponent', this.data);
  }

}
