import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import { DialogService } from '../../services/dialog.service';
import {RudderstackService} from '../../services/rudderstack.service';

@Component({
  selector: 'app-producer-follow-edit-btn',
  templateUrl: './producer-follow-edit-btn.component.html',
  styleUrls: ['./producer-follow-edit-btn.component.css']
})
export class ProducerFollowEditBtnComponent implements OnInit {
  @Input() producerId: number;
  @Input() userId: number = null;
  @Input() set producerfollowers(data) {
    this.producerFollowers = data;
  };
  @Input() eventId: any;
  @Input() page: string;
  @Output() updateFolowers: EventEmitter<any> = new EventEmitter();

  get producerfollowers() {
    return this.producerFollowers;
  }

  isFollowingProducer = false;
  disableFollowButton = false;
  public producerFollowers: any = [];

  constructor(
    public authService: AuthService,
    private dialogService: DialogService,
    private userService: UserService,
    public rudderstackService: RudderstackService
  ) { }

  ngOnInit() {
    this.setIsFollowingProducer();
  }

  updateFollowProducer(action) {
    if (!this.userId) {
      this.userService.openLoginModal();
      return;
    }
    if (this.userId === this.producerId) {
      return;
    }
    if ((!this.isFollowingProducer && action === 'follow') || (this.isFollowingProducer && action === 'unfollow')) {
      this.disableFollowButton = true;
      this.userService.updateFollowProducer({userId: this.userId, producerId: this.producerId, action: action}).subscribe(
        (response: any) => {
          this.disableFollowButton = false;
          if(action === 'follow') {
            this.openSnackBar();
            this.rudderSubscribeToProducer(true);
            this.isFollowingProducer = true;
          } else {
            this.rudderSubscribeToProducer(false);
            this.isFollowingProducer = false;
          }
          this.setFollowers(action);
        }, error => {
          this.disableFollowButton = false;
          this.dialogService.alertsModal('errors', error.code, 3000, false);
        }
      );
    }
  }

  setFollowers(action) {
    this.updateFolowers.emit({producer_id: this.producerId, event_id: this.eventId, action});
  }

  setIsFollowingProducer () {
    const isFollowing = this.producerFollowers.indexOf(this.userId);
    this.isFollowingProducer = isFollowing !== -1;
  }

  openSnackBar() {
    this.dialogService.openSnackBarFromComponent('confirmFollow');
  }

  rudderSubscribeToProducer(subscribe_status) {
    this.rudderstackService.track('Subscribe to producer', {
      subscribe_status: subscribe_status,
      user_id: this.userId,
      producer_id: this.producerId,
      event_id: this.eventId
    })
  }

}
