<div class="main-promoters-container" [ngClass]="{'rtl' : dir === 'rtl'}">
  <div *ngIf="statsData.event_sellers_stat_main.length < 1 && statsData.event_editable" class="no-promoters" [routerLink]="['/event-salesmen', event_id]">
    <div i18n="analitic promoters | add promoter" class="add-promoter">Add your first promoter <span>&nbsp;+&nbsp;</span></div>
  </div>


  <div class="promoters-container">

    <!--PRODUCER STAT-->
    <div class="promoters-section-container" *ngIf="producer_stat">
      <div class="promoters-section">
        <div class="promoter-main">
          <div class="promoter-avatar">
            <img class="promoter-avatar-img" [ngClass]="{'no-avatar': !producer_stat.producer_social_img}" [src]="producer_stat.producer_profile_img ? producer_stat.producer_profile_img : producer_stat.producer_profile_img ? producer_stat.producer_social_img : '../assets/images/svg-icons/logo-new.svg'">
          </div>
          <span class="promoter-data">{{producer_stat.producer_name}}</span>
          <a class="promoter-initials" *ngIf="producer_stat.producer_main_link" href="{{producer_stat.producer_main_link}}">Main Link</a>
        </div>
        <div class="promoter-ext">
          <div class="promoter-data"><span i18n="analitic promoters | total transactions">Total Deals:&nbsp;</span>{{+producer_stat.total_cash + +producer_stat.total_cred + +producer_stat.total_free + +producer_stat.total_register | number:'1.'}}
          </div>
          <div class="promoter-data"><span i18n="analitic promoters | credit sales">Total credit card:&nbsp;</span>{{producer_stat.cred_sum | currency: statsData.event_currency:'symbol':'1.': getLocale(statsData.event_currency)}}</div>
          <div class="promoter-data"><span i18n="analitic promoters | cash to collect">Total cash:&nbsp;</span><span
          >{{producer_stat.cash_sum | currency: statsData.event_currency:'symbol':'1.': getLocale(statsData.event_currency)}}</span>
          </div>
          <div class="promoter-data"><span i18n="analitic promoters | total link view">Total link views:&nbsp;</span>{{producer_stat.views}}</div>
        </div>
        <div class="promoter-data-btn">
            <span i18n="analitic promoters | salesman detail view more" class="promoter-btn-text"
                  (click)="showSellerInfo(producer_stat, 'producer')">View more</span>
        </div>
      </div>
    </div>
    <!--PRODUCER STAT END-->

    <div class="promoters-section-container" id="{{salesman.salesman_id}}"
      *ngFor="let salesman of statsData.event_sellers_stat_main | salesman_search: filterText; index as i">
      <div class="promoters-section">
        <div class="promoter-main">
          <div class="promoter-avatar">
            <img class="promoter-avatar-img" [ngClass]="{'no-avatar': !salesman.seller_social_img}" [src]="salesman.seller_profile_img ? salesman.seller_profile_img : salesman.seller_social_img ? salesman.seller_social_img : '../assets/images/svg-icons/logo-new.svg'">
          </div>
          <a class="promoter-initials" [routerLink]="(salesman.currentUser) ? ['/event-participants/' + event_id + '/all'] : ['/event-participants/' + event_id + '/' + salesman.salesman_id + '/all']">{{salesman.seller_name}}</a>
        </div>
        <div class="promoter-ext">
          <div class="promoter-data"><span i18n="analitic promoters | total transactions">Total Deals:&nbsp;</span>{{+salesman.total_cash + +salesman.total_cred + +salesman.total_free + +salesman.total_register | number:'1.'}}
          </div>
          <div class="promoter-data"><span i18n="analitic promoters | credit sales">Total credit card:&nbsp;</span>{{salesman.cred_sum | currency: statsData.event_currency:'symbol':'1.': getLocale(statsData.event_currency)}}</div>
          <div class="promoter-data"><span i18n="analitic promoters | cash to collect">Total cash:&nbsp;</span><span
              (click)="showSellerInfo(salesman, 'salesman')">{{salesman.cash_sum | currency: statsData.event_currency:'symbol':'1.': getLocale(statsData.event_currency)}}</span>
          </div>
          <div class="promoter-data"><span i18n="analitic promoters | total link view">Total link views:&nbsp;</span>{{salesman.views}}</div>
          <div class="promoter-data"><span i18n="analitic promoters | coupons left">Coupons left &nbsp;{{salesman.coupons_remaining}}&nbsp;out of &nbsp;{{salesman.coupons_quantity}}&nbsp;</span></div>
        </div>
        <div class="promoter-data-btn">
            <span i18n="analitic promoters | salesman detail view more" class="promoter-btn-text"
                  (click)="showSellerInfo(salesman, 'salesman')">View more</span>
        </div>
      </div>
    </div>

  </div>
</div>
