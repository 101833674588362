import {Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter} from '@angular/core';
import {FilterEventsService} from '../../services/filter-events.service';
import {Subject} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  host: {
    '(document:mousedown)': 'onClickCloseSearch($event)'
  }
})
export class SearchComponent implements OnInit {
  filteredEvents: any = null;
  allEvents = [];
  @Input() isMobile = false;
  @Input() direction = '';
  @Input('clickOpenSearch') clickOpenSearch: Subject<any>;
  @Output() searchOpenedChange = new EventEmitter<boolean>();
  @ViewChild('result') result: ElementRef;
  @ViewChild('headerSearch') headerSearch: ElementRef;
  @ViewChild('headerSearchInput') headerSearchInput: ElementRef;
  region = '';

  constructor(private filterEventsService: FilterEventsService, private router: Router,
              private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if(params['region']){
        this.region = params['region'];
      }
    });
  }

  // Close opened menu if clicked outside menu button or menu itself
  onClickCloseSearch(e) {
    if(!e.target.closest('.header-search-input') && !e.target.closest('.header-search-result')) {
      this.close();
    }
  }

  ngOnInit(): void {
    this.filterEventsService.getEventsSource.subscribe(value => {
      this.allEvents = value;
    })
    // Open search from header
    this.clickOpenSearch.subscribe(status => {
      // console.log('SearchComponent click', status);
      // status: true -> opened, false -> closed
      if (status) {
        this.result.nativeElement.style.display = 'block';
        this.headerSearch.nativeElement.style.display = 'inline-block';
        this.headerSearchInput.nativeElement.focus();
      } else {
        this.result.nativeElement.style.display = 'none';
        this.headerSearch.nativeElement.style.display = 'none';
      }
    });
    // CLOSE SEARCH-RESULT on ROUTE change
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.close();
      }
    });
  }

  search(e) {
    const searchString = e.target.value.toLowerCase().trim();
    // for desktop show result box from 1 symbol
    if (searchString.length >= 1) {this.result.nativeElement.style.display = 'block';}

    if (searchString.length >= 2) {
      // Get all events
      if(!this.allEvents.length) {
        this.filterEventsService.getEvents( null , this.region).subscribe(value => {
          this.allEvents = value.events;
          this.filterEventsService.setEvents(value.events);
          this.searchEvents(this.allEvents, searchString);
        });
      } else {
        this.searchEvents(this.allEvents, searchString);
      }
    } else {
      this.filteredEvents = [];
    }
  }

  searchEvents(events, searchString) {
    // console.log(searchString, this.allEvents);
    const searchResult = [];
    // this.activeCategory = null;
    events.forEach(event => {
      if (
        (!searchResult || !searchResult.filter(item => item.event_id === event.event_id).length) &&
        (event.title && event.title.toLowerCase().indexOf(searchString) > -1 ||
          event.address && event.address.toLowerCase().indexOf(searchString) > -1 ||
          event.producer_name && event.producer_name.toLowerCase().indexOf(searchString) > -1)
      ) {
        searchResult.push(event);
      }

      // this.eventCategories.forEach(category => {
      //   if (
      //     (!searchResult || !searchResult.filter(item => item.event_id === event.event_id).length) &&
      //     event.categories.indexOf(category.id) > -1 &&
      //     category.name[this.localeId].toLowerCase().indexOf(searchString) > -1
      //   ) {
      //     searchResult.push(event);
      //   }
      // });
    });

    if(JSON.stringify(searchResult) !== JSON.stringify(this.filteredEvents)) {
      this.filteredEvents = [...searchResult];
      // this.scrollToSearchResult();
    }
    // console.log('filteredEvents', this.filteredEvents);
  }

  onFocus(e) {
    // this.result.nativeElement.style.display = 'block';
  }
  close() {
    this.searchOpenedChange.emit(false);
  }

}
