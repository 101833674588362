<div *ngIf="formDataIsSet" [formGroup]="bank_details" class="bank_details_wrapper">
    <div class="col-md-12 col-xs-12 mb20" *ngIf="eventData && eventData.bank['event_id'] != event_id">
        <div class="row">
          <div class="col-md-12 col-xs-12 vertical-align">
            <div class="col-md-10 col-xs-10 pl0 pr0">
              <label i18n="@@BankFormInputsChooseFromPrev" for="bank_account">Choose from previous bank details</label>
            </div>
          </div>
          <select class="bankaccount-control ba-control-text" i18n-placeholder="@@BankFormInputsChooseBankAccount" name="bank_account" id="bank_account" placeholder="Choose bank account" (change)="setBankFromDraft($event)">
            <option i18n="@@BankFormInputsChooseBankAccount" value="">Choose bank account</option>
            <option value="1">{{eventData.bank.country | uppercase}} / {{eventData.bank.bank_name}} / {{eventData.bank.account_name}} / {{eventData.bank.account_number}}</option>
          </select>
        </div>
      </div>

    <div class="account-container">
      <div formGroupName="bank" class="bankaccount-form">
        <div class="ba-control-group">
          <label i18n="@@ManageBankAccountsCountry" for="country" class="control-label">Country</label>
          <select (ngModelChange)="filterUSCities($event)" i18n-placeholder="@@ManageBankAccountsCountry" class="bankaccount-control ba-control-text" id="country" formControlName="country" placeholder="Country" mbsc-select [mbsc-options]="countrySettings">
            <option i18n="@@ManageBankAccountsSelectCountry" value="" [selected]="(bank_details.get('bank')['controls'].country?.value)">Select Country</option>
            <option i18n="input option | country - custom" *ngFor="let bankCountry of bankService.availableCountriesForBankList; let i = index;" value="{{bankService.availableCountriesForBankList[i].value}}">{
                bankCountry.name, select,
                Israel {Israel}
                United States {United States}
                Spain {Spain}
                Other {Other}
                other {{{bankCountry.name}}}
            }</option>
          </select>
          <app-error-creator [@slideIn] *ngIf="bank_details.get('bank')['controls'].country?.touched && bank_details.get('bank')['controls'].country?.invalid" [control]="bank_details.get('bank')['controls']?.country"></app-error-creator>
        </div>

        <div *ngIf="formControlsReady && bank_details.get('bank')['controls']['country'] && bank_details.get('bank')['controls']['country'].value === 'il'">
          <div class="ba-control-group">
            <label i18n="@@ManageBankAccountsBankName" for="bank_id" class="control-label">Bank Name</label>
            <select i18n-placeholder="@@ManageBankAccountsBankName" class="bankaccount-control ba-control-text" id="bank_id" formControlName="bank_id" placeholder="Bank Name" mbsc-select [mbsc-options]="localSettings">
              <option i18n="@@BankFormInputsChooseBank" value="">Choose bank</option>
              <option *ngFor="let bank of bankService.israeliBankList;" value="{{bank.id}}">{{bank.name[localeId]}}</option>
            </select>
            <app-error-creator [@slideIn] *ngIf="bank_details.get('bank')['controls'].bank_id?.touched && bank_details.get('bank')['controls'].bank_id?.invalid" [control]="bank_details.get('bank')['controls']?.bank_id"></app-error-creator>
          </div>
          <div class="account-number-group">
          <div class="ba-control-group ba-control-num">
            <label i18n="@@BankFormInputsBranchNumber" for="branch" class="control-label">Branch number</label>
            <input i18n-placeholder="@@BankFormInputsBranchNumber" id="branch" class="bankaccount-control ba-control-text" type="number" formControlName="branch" placeholder="Branch number" min="0" step="1" pattern="^\d*">
            <app-error-creator [@slideIn] *ngIf="bank_details.get('bank')['controls'].branch?.touched && bank_details.get('bank')['controls'].branch?.invalid" [control]="bank_details.get('bank')['controls']?.branch"></app-error-creator>
          </div>
          <div class="ba-control-group ba-control-num">
            <label i18n="@@BankFormInputsAccountNumber" class="control-label" for="account_number">Account number</label>
            <input i18n-placeholder="@@BankFormInputsAccountNumber" id="account_number" class="bankaccount-control ba-control-text" type="number" formControlName="account_number" placeholder="Account number" min="0" step="1" pattern="^\d*">
            <app-error-creator [@slideIn] *ngIf="bank_details.get('bank')['controls'].account_number?.touched && bank_details.get('bank')['controls'].account_number?.invalid" [control]="bank_details.get('bank')['controls']?.account_number"></app-error-creator>
          </div>
          </div>
          <div class="ba-control-group">
            <label i18n="@@BankFormInputsAccountOwnerName" class="control-label" for="account_name">Account owner's name</label>

              <input i18n-placeholder="@@BankFormInputsAccountOwnerName" id="account_name" class="bankaccount-control ba-control-text" trim="blur" type="text" formControlName="account_name" placeholder="Account owner's name">
              <app-error-creator [@slideIn] *ngIf="bank_details.get('bank')['controls'].account_name?.touched && bank_details.get('bank')['controls'].account_name?.invalid" [control]="bank_details.get('bank')['controls']?.account_name"></app-error-creator>

          </div>
          <div class="ba-control-group">
            <label i18n="@@BankFormInputsIDorCompany" class="control-label" for="company_user_id">ID or Company Number</label>

              <input i18n-placeholder="@@BankFormInputsIDorCompany" id="company_user_id" class="bankaccount-control ba-control-text" type="number" formControlName="company_user_id" placeholder="ID or Company Number" min="0" step="1" pattern="^\d*">
              <app-error-creator [@slideIn] *ngIf="bank_details.get('bank')['controls'].company_user_id?.touched && bank_details.get('bank')['controls'].company_user_id?.invalid" [control]="bank_details.get('bank')['controls']?.company_user_id"></app-error-creator>

          </div>
        </div>

        <div *ngIf="formControlsReady && bank_details.get('bank')['controls']['country'] && bank_details.get('bank')['controls']['country'].value === 'es'">
          <div class="ba-control-group">
            <label i18n="@@ManageBankAccountsBankName" for="bank_id" class="control-label">Bank Name</label>
            <select i18n-placeholder="@@ManageBankAccountsBankName" class="bankaccount-control ba-control-text" id="bank_id" formControlName="bank_id" placeholder="Bank Name" mbsc-select [mbsc-options]="localSettings">
              <option i18n="@@BankFormInputsChooseBank" value="">Choose Bank</option>
              <option *ngFor="let bank of bankService.spainBankList;" value="{{bank.id}}">{{bank.name[localeId]}}</option>
            </select>
            <app-error-creator [@slideIn] *ngIf="bank_details.get('bank')['controls'].bank_id?.touched && bank_details.get('bank')['controls'].bank_id?.invalid" [control]="bank_details.get('bank')['controls']?.bank_id"></app-error-creator>
          </div>
          <div class="account-number-group">
            <div class="ba-control-group ba-control-num">
              <label i18n="@@BankFormInputsBranchNumber" for="branch" class="control-label">Branch Number</label>
              <input i18n-placeholder="@@BankFormInputsBranchNumber" id="branch" class="bankaccount-control ba-control-text" type="text" formControlName="branch" placeholder="Branch Number">
              <app-error-creator [@slideIn] *ngIf="bank_details.get('bank')['controls'].branch?.touched && bank_details.get('bank')['controls'].branch?.invalid" [control]="bank_details.get('bank')['controls']?.branch"></app-error-creator>
            </div>
            <div class="ba-control-group ba-control-num">
              <label i18n="@@BankFormInputsAccountNumber" class="control-label" for="account_number">Account Number</label>
              <input i18n-placeholder="@@BankFormInputsAccountNumber" id="account_number" class="bankaccount-control ba-control-text" type="text" formControlName="account_number" placeholder="Account Number">
              <app-error-creator [@slideIn] *ngIf="bank_details.get('bank')['controls'].account_number?.touched && bank_details.get('bank')['controls'].account_number?.invalid" [control]="bank_details.get('bank')['controls']?.account_number"></app-error-creator>
            </div>
          </div>
          <div class="ba-control-group">
            <label i18n="@@BankFormInputsAccountOwnerName" class="control-label" for="account_name">Account owner's name</label>
            <input i18n-placeholder="@@BankFormInputsAccountOwnerName" id="account_name" class="bankaccount-control ba-control-text" trim="blur" type="text" formControlName="account_name" placeholder="Account owner's name">
            <app-error-creator [@slideIn] *ngIf="bank_details.get('bank')['controls'].account_name?.touched && bank_details.get('bank')['controls'].account_name?.invalid" [control]="bank_details.get('bank')['controls']?.account_name"></app-error-creator>

          </div>
          <div class="ba-control-group">

            <label i18n="@@BankFormInputsIDorCompany" class="control-label" for="company_user_id">ID or Company Number</label>
            <input i18n-placeholder="@@BankFormInputsIDorCompany" id="company_user_id" class="bankaccount-control ba-control-text" type="text" formControlName="company_user_id" placeholder="ID or Company Number">
            <app-error-creator [@slideIn] *ngIf="bank_details.get('bank')['controls'].company_user_id?.touched && bank_details.get('bank')['controls'].company_user_id?.invalid" [control]="bank_details.get('bank')['controls']?.company_user_id"></app-error-creator>

          </div>
        </div>
<!--OLD US logic-->
        <div *ngIf="formControlsReady && bank_details.get('bank')['controls']['country']">
          <div *ngIf="bank_details.get('bank')['controls']['country'].value === 'us' && usCities.length > 0" class="ba-control-group">
            <label i18n="@@City" class="control-label" for="city">City</label>
            <ngx-select-dropdown *ngIf="!isOnMobile" id="city" formControlName="city" [multiple]="false" [config]="config" [options]="bankCitiesOptions"></ngx-select-dropdown>
            <select *ngIf="isOnMobile" i18n-placeholder="@@Select" class="bankaccount-control ba-control-text" id="city" formControlName="city" mbsc-select [mbsc-options]="localSettings" placeholder="Select">
              <option i18n="create edit event | choose currency dropdown" value="">Set city</option>
              <option *ngFor="let city of bankCitiesOptions; let i = index;" [value]="bankCitiesOptions[i].value">{{city.text}}</option>
            </select>
            <app-error-creator [@slideIn] *ngIf="bank_details.get('bank')['controls'].city?.touched && bank_details.get('bank')['controls'].city?.invalid" [control]="bank_details.get('bank')['controls']?.city"></app-error-creator>
          </div>
          <div *ngIf="bank_details.get('bank')['controls']['country'].value === 'us' && usCities.length === 0" class="ba-control-group">
            <label></label>
            <img src="/assets/images/preloader.gif" width="30" style="display: block; margin: 0 auto;">
          </div>
          <div *ngIf="bank_details.get('bank')['controls']['country'].value === 'us' && bank_details.get('bank')['controls']['city']?.value">
            <div class="ba-control-group">
              <label i18n="@@ManageBankAccountsBankName" class="control-label" for="bank_name">Bank Name</label>
              <input type="text" i18n-placeholder="@@ManageBankAccountsBankName" class="bankaccount-control ba-control-text" id="bank_name" formControlName="bank_name" placeholder="Bank Name">
              <app-error-creator [@slideIn] *ngIf="bank_details.get('bank')['controls'].bank_name?.touched && bank_details.get('bank')['controls'].bank_name?.invalid" [control]="bank_details.get('bank')['controls']?.bank_name"></app-error-creator>
            </div>
            <div class="ba-control-group">
              <div class="ba-control-group">
                <label i18n="@@BankFormInputsAccountOwnerName" class="control-label" for="account_name">Account owner's name</label>
                  <input i18n-placeholder="@@BankFormInputsAccountOwnerName" id="account_name" class="bankaccount-control ba-control-text" trim="blur" type="text" formControlName="account_name" placeholder="Account owner's name">
                  <app-error-creator [@slideIn] *ngIf="bank_details.get('bank')['controls'].account_name?.touched && bank_details.get('bank')['controls'].account_name?.invalid" [control]="bank_details.get('bank')['controls']?.account_name"></app-error-creator>

              </div>
              <div class="ba-control-group">
                <label i18n="@@BankFormInputsLivingAddress" class="control-label" for="address">Living Address</label>

                  <input i18n-placeholder="@@BankFormInputsLivingAddress" id="address" class="bankaccount-control ba-control-text" trim="blur" type="text" formControlName="address" placeholder="Living Address">
                  <app-error-creator [@slideIn] *ngIf="bank_details.get('bank')['controls'].address?.touched && bank_details.get('bank')['controls'].address?.invalid" [control]="bank_details.get('bank')['controls']?.address"></app-error-creator>

              </div>
            </div>
            <div class="account-number-group">
              <div class="ba-control-group ba-control-num">
                <label i18n="@@BankFormInputsAbaNum" class="control-label" for="aba_number">ABA Number</label>
                <input i18n-placeholder="@@BankFormInputsAbaNum" id="aba_number" class="bankaccount-control ba-control-text" type="text" formControlName="aba_number" placeholder="ABA Number">
                <app-error-creator [@slideIn] *ngIf="bank_details.get('bank')['controls'].aba_number?.touched && bank_details.get('bank')['controls'].aba_number?.invalid" [name]="'aba_number'" [control]="bank_details.get('bank')['controls']?.aba_number"></app-error-creator>
              </div>
              <div class="ba-control-group ba-control-num">
                <label i18n="@@BankFormInputsAccountNumber" class="control-label" for="account_number">Account Number</label>
                <input i18n-placeholder="@@BankFormInputsAccountNumber" id="account_number" class="bankaccount-control ba-control-text" type="text" formControlName="account_number" placeholder="Account Number">
                <app-error-creator [@slideIn] *ngIf="bank_details.get('bank')['controls'].account_number?.touched && bank_details.get('bank')['controls'].account_number?.invalid" [control]="bank_details.get('bank')['controls']?.account_number"></app-error-creator>
              </div>
            </div>
            <div class="ba-control-group">
              <label i18n="@@Phone" class="control-label" for="recipient_phone">Phone</label>

                  <input appPhoneNumber ng2TelInput
                  i18n-placeholder="@@Phone"
                  id="recipient_phone"
                  class="bankaccount-control ba-control-text"
                  trim="blur"
                  type="text"
                  formControlName="recipient_phone"
                  placeholder="Phone"
                  (hasError)="setErrorOnphoneNumber(!$event)"
                  (ng2TelOutput)="setInitialPhoneNumber($event)"
                  (intlTelInputObject)="telInputObject($event)"
                  (countryChange)="onCountryChange($event)"
                  [ng2TelInputOptions]="localizationService.getPhoneInputOptions()"
                  />
                  <app-error-creator [@slideIn] *ngIf="bank_details.get('bank')['controls']?.recipient_phone.touched && bank_details.get('bank')['controls']?.recipient_phone.invalid" [name]="'recipient_phone'" [control]="bank_details.get('bank')['controls']?.recipient_phone"></app-error-creator>

            </div>
          </div>
        </div>

        <!--NEW US logic-->
<!--        <div *ngIf="formControlsReady && bank_details.get('bank')['controls']['country']">-->
<!--          <div *ngIf="bank_details.get('bank')['controls']['country'].value === 'us'">-->

<!--            <div class="ba-control-group">-->
<!--              <label for="">Connect bank</label>-->
<!--              <div style="display: flex;">-->
<!--                <button class="plaid-btn" [disabled]="plaidService.fetching.getValue()" (click)="connectPlaid()"></button>-->
<!--                <button *ngIf="ctrlUser_plaid_id.value" class="plaid-disconnect-btn" (click)="disconnectPlaid()">Disconnect</button>-->
<!--              </div>-->
<!--            </div>-->

<!--            <input type="hidden" id="user_plaid_id" formControlName="user_plaid_id" #ctrlUser_plaid_id placeholder="user_plaid_id">-->

<!--            <div class="ba-control-group">-->
<!--              <label i18n="input label | city" class="control-label" for="city">City</label>-->
<!--              <input type="text" i18n-placeholder="placeholder | city" class="bankaccount-control ba-control-text" id="city" formControlName="city" placeholder="City" [readOnly]="ctrlUser_plaid_id.value">-->
<!--              <app-error-creator [@slideIn] *ngIf="bank_details.get('bank')['controls'].city?.touched && bank_details.get('bank')['controls'].city?.invalid" [control]="bank_details.get('bank')['controls']?.city"></app-error-creator>-->
<!--            </div>-->
<!--            <div class="ba-control-group">-->
<!--              <label i18n="input label | bank name" class="control-label" for="bank_name">שם הבנק</label>-->
<!--              <input type="text" i18n-placeholder="placeholder | bank name" class="bankaccount-control ba-control-text" id="bank_name" formControlName="bank_name" placeholder="שם בנק" [readOnly]="ctrlUser_plaid_id.value">-->
<!--              <app-error-creator [@slideIn] *ngIf="bank_details.get('bank')['controls'].bank_name?.touched && bank_details.get('bank')['controls'].bank_name?.invalid" [control]="bank_details.get('bank')['controls']?.bank_name"></app-error-creator>-->
<!--            </div>-->
<!--            <div class="ba-control-group">-->
<!--              <div class="ba-control-group">-->
<!--                <label i18n="input label | account owner name" class="control-label" for="account_name">שם בעל החשבון</label>-->

<!--                <input i18n-placeholder="placeholder | account owner name" id="account_name" class="bankaccount-control ba-control-text" trim="blur" type="text" formControlName="account_name" placeholder="שם בעל החשבון" [readOnly]="ctrlUser_plaid_id.value">-->
<!--                <app-error-creator [@slideIn] *ngIf="bank_details.get('bank')['controls'].account_name?.touched && bank_details.get('bank')['controls'].account_name?.invalid" [control]="bank_details.get('bank')['controls']?.account_name"></app-error-creator>-->

<!--              </div>-->
<!--              <div class="ba-control-group">-->
<!--                <label i18n="input label | account owner name" class="control-label" for="address">כתובת מגורים</label>-->

<!--                <input i18n-placeholder="placeholder | account owner name" id="address" class="bankaccount-control ba-control-text" trim="blur" type="text" formControlName="address" placeholder="כתובת מגורים" [readOnly]="ctrlUser_plaid_id.value">-->
<!--                <app-error-creator [@slideIn] *ngIf="bank_details.get('bank')['controls'].address?.touched && bank_details.get('bank')['controls'].address?.invalid" [control]="bank_details.get('bank')['controls']?.address"></app-error-creator>-->

<!--              </div>-->
<!--            </div>-->
<!--            <div class="account-number-group">-->
<!--              <div class="ba-control-group ba-control-num">-->
<!--                <label i18n="input label | bank number" class="control-label" for="aba_number">מספר ABA</label>-->
<!--                <input i18n-placeholder="placeholder | aba_number number" id="aba_number" class="bankaccount-control ba-control-text" type="text" formControlName="aba_number" placeholder="מספר ABA">-->
<!--                <app-error-creator [@slideIn] *ngIf="bank_details.get('bank')['controls'].aba_number?.touched && bank_details.get('bank')['controls'].aba_number?.invalid" [name]="'aba_number'" [control]="bank_details.get('bank')['controls']?.aba_number"></app-error-creator>-->
<!--              </div>-->
<!--              <div class="ba-control-group ba-control-num">-->
<!--                <label i18n="input label | account number" class="control-label" for="account_number">מספר חשבון</label>-->
<!--                <input i18n-placeholder="placeholder | account number" id="account_number" class="bankaccount-control ba-control-text" type="text" formControlName="account_number" placeholder="מספר חשבון">-->
<!--                <app-error-creator [@slideIn] *ngIf="bank_details.get('bank')['controls'].account_number?.touched && bank_details.get('bank')['controls'].account_number?.invalid" [control]="bank_details.get('bank')['controls']?.account_number"></app-error-creator>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="ba-control-group" *ngIf="true">-->
<!--              <label i18n="input label | account owner identification number or vat number" class="control-label" for="recipient_phone">טלפון</label>-->

<!--              <input ng2TelInput-->
<!--                     i18n-placeholder="placeholder | owner id number or vat number"-->
<!--                     id="recipient_phone"-->
<!--                     class="bankaccount-control ba-control-text"-->
<!--                     trim="blur"-->
<!--                     type="text"-->
<!--                     formControlName="recipient_phone"-->
<!--                     placeholder="טלפון"-->
<!--                     (hasError)="setErrorOnphoneNumber(!$event)"-->
<!--                     (ng2TelOutput)="setInitialPhoneNumber($event)"-->
<!--                     (intlTelInputObject)="telInputObject($event)"-->
<!--                     (countryChange)="onCountryChange($event)"-->
<!--                     [ng2TelInputOptions]="localizationService.getPhoneInputOptions()"-->
<!--                     [readOnly]="ctrlUser_plaid_id.value"-->
<!--              />-->
<!--              <app-error-creator [@slideIn] *ngIf="bank_details.get('bank')['controls']?.recipient_phone.touched && bank_details.get('bank')['controls']?.recipient_phone.invalid" [name]="'recipient_phone'" [control]="bank_details.get('bank')['controls']?.recipient_phone"></app-error-creator>-->

<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <div *ngIf="formControlsReady && bank_details.get('bank')['controls']['country']">
          <div *ngIf="bank_details.get('bank')['controls']['country'].value === 'other'" class="ba-control-group">
            <div class="ba-control-group ba-control">
              <label i18n="input label | other_bank_details" class="control-label" for="other_bank_details">Bank details</label>
              <input i18n-placeholder="placeholder | other_bank_details" id="other_bank_details" class="bankaccount-control ba-control-text" type="text" formControlName="other_bank_details" placeholder="Bank details">
              <app-error-creator [@slideIn] *ngIf="bank_details.get('bank')['controls'].other_bank_details?.touched && bank_details.get('bank')['controls'].other_bank_details?.invalid" [name]="'other_bank_details'" [control]="bank_details.get('bank')['controls']?.other_bank_details"></app-error-creator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
