import {AuthService} from './auth.service';
import {DialogService} from './dialog.service';
import {AppResponse} from './../interfaces/app-response.interface';
import {environment} from './../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {GlobalsService} from '../globals';
import {Injectable} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {NavbarService} from './navbar.service';
import {LoginToAppResponse} from '../interfaces/authentication.interface';
import {BehaviorSubject} from 'rxjs';

declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class FacebookService {
  api_base_url = environment.api_base_url;
  getin_base_url = environment.getin_base_url;
  facebookClientId = environment.facebookClientId;
  isProduction = environment.production;
  date = new Date();
  localeId: string;
  isCreateEvent: boolean = false;

  constructor(
    private http: HttpClient,
    private globals: GlobalsService,
    private router: Router,
    public nav: NavbarService,
  ) {
    this.date.setDate(this.date.getDate() + 7); // 7 days from now
    this.localeId = this.globals.getLocaleId();
  }


  isConnected() {
    return new Promise(resolve => {
      window.FB.getLoginStatus(function (response) {
        if (response && (response.status === 'connected' || response.status === 'unknown')) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  getPixelParams(main_purchase_identifier) {
    return this.http.post(this.api_base_url + '/api/get-purchase-info',main_purchase_identifier, {
			headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
		});
  }


  getLang() {
    let lng = '/';
    if (this.isProduction) {
      lng = this.localeId === 'he' ? '/he' : '/' + this.localeId;
    }
    return lng;
  }

  getFBAccessCode(extraStateData: string = '', isCreate: boolean = false) {
    const lng = this.getLang();
    const url = this.encodeUrlForRequest(this.router.url + extraStateData);
    const params = JSON.stringify({path: url, social: 'facebook', isCreate});
    window.location = encodeURI('https://www.facebook.com/v3.2/dialog/oauth?client_id=' + this.facebookClientId + '&scope=public_profile,email,user_friends,user_link&redirect_uri=' + this.getin_base_url + lng + `/&response_type=code&state=${params}`);
  }

  encodeUrlForRequest(url: string = '') {
    url = this.addLangToQueryString(url);
    url = this.removeParamFromQuery('change_facebook_user', url);
    if (url) {
      url = encodeURIComponent(url);
    }
    return url;
  }

  addLangToQueryString(url: string = '') {
    if (url.includes('?') && !url.includes('getinLang')) {
      url = url + '&getinLang=' + this.localeId;
    } else if (!url.includes('getinLang')) {
      url = url + '?getinLang=' + this.localeId;
    }
    return url;
  }

  removeParamFromQuery(paramToRemove: string, url: string = '') {
    if (url.includes(paramToRemove)) {
      let urlbreakDown = url.split('?');
      const urlRoute = urlbreakDown[0];
      urlbreakDown = urlbreakDown[1].split('&');
      let newQueryString = '';
      urlbreakDown.forEach(function (partical) {
        if (!partical.includes(paramToRemove)) {
          if (newQueryString.substring(newQueryString.length - 1) === '&') {
            newQueryString = newQueryString + partical;
          } else {
            newQueryString = (newQueryString.length > 0) ? newQueryString + '&' + partical : partical;
          }
        }
      });
      url = (newQueryString.length > 0) ? urlRoute + '?' + newQueryString : urlRoute;
    }
    return url;
  }

}
