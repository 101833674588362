<div class="custom-fields-modal-header">
  <button class="close-btn" (click)="close()">
    <img src="/assets/images/svg-icons/close.svg" alt="transfer-money">
  </button>
</div>

<div class="custom-fields new-form">
  <h2 i18n="custom_fields|Add new question" *ngIf="!data.cmsPage">Add new question</h2>
	<form [formGroup]="customInputsForm">

    <div formArrayName="custom_inputs" class="custom_inputs_container" *ngIf="form_inputs?.custom_inputs.length > 0">

        <div [formGroupName]="i" id="custom_input-{{i}}" *ngFor="let custom_input of form_inputs.custom_inputs; let i = index; last as isLast">
          <!--          Blocked checkbox (only for admins)-->
          <div class="form-group">
            <label class="check-label blocked-label">
              <input type="checkbox" name="blocked" [attr.disabled]="!data.cmsPage ? true : null"
                     formControlName="blocked"
                     [checked]="custom_input?.blocked ? custom_input.blocked : false">
              <span class="checkmark" *ngIf="custom_input?.blocked && !data.cmsPage || data.cmsPage"></span>
              <span i18n="custom_fields|blocked setting" *ngIf="custom_input?.blocked && !data.cmsPage || data.cmsPage">Blocked</span>
            </label>
          </div>

          <section [ngClass]="{'blocked': (custom_input.blocked === true || custom_input.blocked === false)
           && customInputsForm.controls['custom_inputs']['controls'][i].controls['blocked'].value === true}">
            <div class="form-group">
              <input id="id_{{i}}" class="form-control" type="hidden" formControlName="id" value="{{(custom_input?.id) ? custom_input.id : ''}}">
              <input i18n-placeholder="question-title" id="title_{{i}}" class="form-control" type="text" formControlName="title" i18n-placeholder="custom_fields|title" placeholder="Question title" value="{{(custom_input?.title) ? custom_input.title : ''}}"
                     [attr.disabled]="custom_input?.blocked && !data.cmsPage ? true : null">
              <app-error-creator *ngIf="customInputsForm.controls['custom_inputs']['controls'][i].controls['title'].touched
              && customInputsForm.controls['custom_inputs']['controls'][i].controls['title'].invalid" [control]="customInputsForm.controls['custom_inputs']['controls'][i].controls['title']"></app-error-creator>
            </div>
            <div class="form-group">
              <select class="form-control" formControlName="type" #type (change)="changeType(type,i, custom_input?.opinions)" [attr.disabled]="custom_input?.blocked && !data.cmsPage ? true : null">
                <option value="" disabled i18n="custom_fields | choose type">Choose type</option>
                <option *ngFor="let type of types" value="{{type.name}}" i18n="select types">{type.id, plural,=0 {Text} =1 {Single choice} =2 {Multiply choice} =3 {Dropdown}}</option>
              </select>
              <app-error-creator *ngIf="customInputsForm.controls['custom_inputs']['controls'][i].controls['type'].touched
              && customInputsForm.controls['custom_inputs']['controls'][i].controls['type'].invalid"
                                 [control]="customInputsForm.controls['custom_inputs']['controls'][i].controls['type']"></app-error-creator>
            </div>
            <!--          Opinions-->
            <div formArrayName="opinions" *ngIf="customInputsForm?.controls['custom_inputs']['controls'][i].controls['opinions']['controls'].length > 0 || custom_input?.opinions.length > 0">
              <ng-container [formGroupName]="x" *ngFor="let option of custom_input?.opinions; let x = index; count as isCount">
                <input *ngIf="custom_input?.opinions.length > 0" id="id_{{i+'_'+x}}" class="form-control" type="hidden" formControlName="id" value="{{(option?.id) ? option.id : ''}}">
                <input *ngIf="custom_input?.opinions.length > 0" id="number_{{i+'_'+x}}" class="form-control" type="hidden" formControlName="number" value="{{(option?.number) ? option.number : ''}}">
                <div class="form-group sub-opinion">
                  <input i18n-placeholder="custom_fields|option" id="opinions_title_{{i+'_'+x}}" class="form-control" type="text" formControlName="title" placeholder="Option"  value="{{(option.title)?option.title:''}}"
                         [attr.disabled]="custom_input?.blocked && !data.cmsPage ? true : null">
                  <app-error-creator *ngIf="customInputsForm.controls['custom_inputs']['controls'][i].controls['opinions']['controls'][x].controls['title'].touched
                  && customInputsForm.controls['custom_inputs']['controls'][i].controls['opinions']['controls'][x].controls['title'].invalid"
                                     [control]="customInputsForm.controls['custom_inputs']['controls'][i].controls['opinions']['controls'][x].controls['title']"></app-error-creator>
                  <button (click)="removeCustomInputOption(i,x)" *ngIf="isCount > 1" class="btn btn-remove-opinion"
                          [attr.disabled]="custom_input?.blocked && !data.cmsPage ? true : null">&times;</button>
                </div>
              </ng-container>
            </div>
            <!--          Add new Opinion btn -->
            <div class="row add_sub_item" *ngIf="customInputsForm.controls['custom_inputs']['controls'][i].controls['type'].value !== 'text'">
              <button i18n="sub-options|add_sub_item button" class="btn btn-add-new-option"
                      (click)="addCustomInputOption(i, custom_input?.opinions.length)"
                      [attr.disabled]="custom_input?.blocked && !data.cmsPage ? true : null">Add new option</button>
            </div>
            <!--          SET AS REQUIRED chkbox -->
            <div class="row required-input">
              <div class="form-group">
                <label class="check-label">
                  <input type="checkbox" name="required"
                         formControlName="required"
                         [attr.disabled]="custom_input?.blocked && !data.cmsPage ? true : null"
                         [checked]="customInputsForm.controls['custom_inputs']['controls'][i].controls['required'].value === true">
                  <span class="checkmark"></span>
                  <span i18n="custom_fields | required setting">Set as required</span>
                </label>
              </div>
            </div>
            <!--          CREATE & DELETE btns-->
            <div class="actions-box row">
              <button i18n="custom_fields|add new question" class="btn btn-add-new-question" *ngIf="isLast" (click)="addCustomInput()">Add new question</button>
              <button i18n="custom_fields|delete question" class="btn btn-delete-question" (click)="removeCustomInput(i)" [attr.disabled]="custom_input?.blocked && !data.cmsPage ? true : null">Delete question</button>
            </div>
          </section>

        </div>

    </div>
    <button i18n="custom_fields|add new question" class="btn btn-add-new-question" *ngIf="form_inputs?.custom_inputs.length === 0" (click)="addCustomInput()">Add new question</button>
    <button (click)="onSubmit()" *ngIf="isShowSave()" class="btn btn-save" i18n="custom_fields | save">SAVE</button>
  </form>
</div>


