import { Component, EventEmitter, Injectable, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SolanaConnectService } from '../services/solana-connect.service';

import { 
  CoinbaseWalletAdapter, 
  PhantomWalletAdapter, 
  SafePalWalletAdapter, 
  HuobiWalletAdapter, 
  SolflareWalletAdapter, 
  MathWalletAdapter, 
  TrustWalletAdapter, 
  LedgerWalletAdapter, 
  TorusWalletAdapter,
  SalmonWalletAdapter,
  Coin98WalletAdapter,
  CloverWalletAdapter,
  BitgetWalletAdapter,
  NekoWalletAdapter,
  NightlyWalletAdapter,
  SolongWalletAdapter,
  SolflareWalletName,
  PhantomWalletName,  
} from '@solana/wallet-adapter-wallets'
import { WalletAdapter, WalletReadyState } from '@solana/wallet-adapter-base'
import { ConnectionStore, Wallet, WalletStore } from '@heavy-duty/wallet-adapter';
import * as solanaWeb3 from '@solana/web3.js';
import { encode } from 'bs58';

import { defer, from, Observable, Subscription, throwError } from 'rxjs';
import { concatMap, first, map, take } from 'rxjs/operators';
import { isNotNull } from './not-null';
import { DomSanitizer } from '@angular/platform-browser';
import { ClipboardService } from 'ngx-clipboard';
import { DialogService } from '../services/dialog.service';
import { GlobalsService } from '../globals';
import { environment } from 'src/environments/environment';

export declare class WalletError extends Error {
  error: any;
  constructor(message?: string, error?: any);
}

export enum WalletAdapterNetwork {
  Mainnet = 'mainnet-beta',
  Testnet = 'testnet',
  Devnet = 'devnet',
}

export type WalletName<T extends string = string> = T & { __brand__: 'WalletName' };

@Injectable()
@Component({
  selector: 'app-solana-connect',
  templateUrl: './solana-connect.component.html',
  styleUrls: ['./solana-connect.component.scss'],
  providers: [ConnectionStore, WalletStore]
})
export class SolanaConnectComponent implements OnInit, OnDestroy {

  readonly withOutFinalization = true;
  readonly connection$: Observable<solanaWeb3.Connection>;
  readonly wallets$: Observable<Wallet[]>;
  readonly wallet$: Observable<Wallet>;
  readonly walletName$;
  readonly ready$;
  readonly connected$: Observable<boolean>;
  readonly errors$ : Observable<WalletError>;
  readonly publicKey$: Observable<solanaWeb3.PublicKey>;
  isOpenWallet = false;
  lamports = 0;
  recipient = environment.solana.getin_wallet;
  isReady: boolean;
  transaction: any = {};
  isConnected: boolean = false;
  eventTicketsData: any | null;
  ticketsPrice: number | null = null;
  showConnectBtn: boolean = false; // to show button connect on the page
  showReconnectBtn: boolean = false;
  isSuccessful: boolean = false; // transaction save successful
  hasBalance: boolean = false; // check if walles has balence > 0
  founds:boolean = false;
  dir: string | null;
  isUsd: boolean = false;
  transactionData: any = {};
  eventCurRate: number | null;
  usdCurRate: number | null;
  chainData: any = {};
  isSelectedWallet: boolean;
  pKey: solanaWeb3.PublicKey;
  isOpenWallets: boolean;
  accountBalances: any[] = [];
  accountBalance: number = 0;
  siteCurrencies = [];
  networkCurrency = 'solana';
  solanaConnection: solanaWeb3.Connection;
  isCheckTrnStatus: boolean = false;
  selectedWallet: any;
  isChecking = false;
  signature: string | null;
  private subscriptions: Subscription[] = [];
  prevStates: any[] = [];
  claster: any = environment.solana;
  transactionUrl: string | null;
  solanaModalIsClose: boolean;
  solanaInfoModalIsClose: boolean;
  browseUniversalLink: String = null;

  @Output() getTransactionWallet: EventEmitter<{transaction, state}> = new EventEmitter();
  @Output() getTrnBtnStatus: EventEmitter<boolean> = new EventEmitter();
  @Input() set openWallets(value: boolean) {
    // console.log('set openWallets', value);
    this.isOpenWallets = value;
  }
  @Input() set walletData(value) {
    if (value && value.payment_details) {
      this.eventTicketsData = value;
      this.connectionSubscribe();
    }
    // console.log('get walletData', value);
  };
  @Input() set solanaTransactionBtnStatus(value: boolean) {
    // console.log('solanaTransactionBtnStatus', value, 'wallet', this.pKey ? this.pKey.toString() : undefined);
    value && this.pKey ? this.onSendTransaction(this.pKey) : false;
  }

  constructor(
    private solanaConnectService: SolanaConnectService,
    private connectionStore: ConnectionStore,
    private walletStore: WalletStore,
    readonly domSanitizer: DomSanitizer,
    private clipboardService: ClipboardService,
    private dialogService: DialogService,
    public globals: GlobalsService,
    ) {
      this.timerWhatcher();
      this.transactionData.fee = 0.000005;
      this.dir = this.globals.getDir();
      // this.solanaConnection = new solanaWeb3.Connection(solanaWeb3.clusterApiUrl('devnet'),'confirmed',);
      // console.log('ConnectionStore ', this.connectionStore, 'WalletStore ', this.walletStore);

      this.connection$ = this.connectionStore.connection$;
      this.wallets$ = this.walletStore.wallets$;
      this.wallet$ = this.walletStore.wallet$;
      this.errors$ = this.walletStore.error$;
      // this.walletStore.onAdapterChangeDisconnectPreviousAdapter();
      // this.walletStore.onWalletChanged();
      // this.walletStore.er

      this.errors$.subscribe(error => {
        if (error) {
          console.log('Wallet error: ', error.name, error.message);
          this.globals.stopLoader();
          if (error.name == 'WalletSendTransactionError' && error.message == 'User rejected the request.') { // code: 4001
            // console.log('Transaction simulation failed: Blockhash not found. Perhaps you should change the network to another');
            this.dialogService.alertsModal('cryptoCurrency', 'walletConnectReject', 3000, false);
          } else if (error.name == 'WalletSendTransactionError' && error.error.code == '-32003') {
            this.dialogService.alertsModal('cryptoCurrency', 'walletChainNotFound', 3000, false);
          } else if (error.name == 'WalletConnectionError' && error.message == 'User aborted.') { // Glow
            this.dialogService.alertsModal('cryptoCurrency', 'walletConnectReject', 3000, false);
          } else if (error.name == 'WalletConnectionError' && error.message == 'User rejected the request.') {
            this.dialogService.alertsModal('cryptoCurrency', 'walletConnectReject', 3000, false); // Phantom
          } else if (error.name.includes('WalletConnectionError') && error.message.includes('Connection error')) {
            this.dialogService.alertsModal('cryptoCurrency', 'walletConnectReject', 3000, false); // Coinbase
          } else if (error.name == 'WalletAccountError') {
            this.dialogService.alertsModal('cryptoCurrency', 'walletConnectReject', 3000, false); // Slope wallet
          } else if (error.name == 'WalletSendTransactionError' && error.message == 'You cancelled the transaction.') {
            this.dialogService.alertsModal('cryptoCurrency', 'walletConnectReject', 3000, false);
          } else {
            console.log('Error message: ', error.message, 'Error name: ', error.name);
          }
        }
      }, error => {console.log('get errors: ', error);});

      this.walletStore.wallet$.subscribe(wallet => {
        // this.accountBalances = [];
        if (wallet) {
          this.isOpenWallet = true;
          this.accountBalances[0]=wallet;
        } else {
          this.isOpenWallet = false;
        }
        // console.log('Update accountBalances: ', this.accountBalances, this.hasBalance, 'isOpenWallet ', this.isOpenWallet);
        // wallet && wallet.adapter.name ? console.log('wallet$.subscribe name') : console.log('wallet$.subscribe no name');
      }, error => {console.log('walletStore.wallet error: ', error);});

      this.walletName$ = this.wallet$.pipe(
        map((wallet: Wallet) => {
          let name = null;
          if (wallet) {
            name = wallet.adapter.name || null;
          }
          return name
        })
      );
      this.ready$ = this.wallet$.pipe(
        map((wallet: any) => {
            const state = wallet && (wallet.adapter.readyState === WalletReadyState.Installed || wallet.adapter.readyState === WalletReadyState.Loadable);
            this.isReady = state;
            return state;
          }
        )
      );

      this.connected$ = this.walletStore.connected$;
      this.publicKey$ = this.walletStore.publicKey$;
      // console.log('constructor', this.isConnected, this.isReady);


      this.walletStore.destroy$.subscribe(walletStore => {
        // console.log('walletStore destroy', walletStore);
      });

      this.walletStore.disconnecting$.subscribe(walletStore => {
        // console.log('walletStore disconnecting', walletStore);
      });

      this.walletStore.anchorWallet$.subscribe(walletStore => {
        // console.log('walletStore anchorWallet', walletStore);
      });

      // this.walletStore.onWalletChanged()

    }

    checkIsMobile(value) {
      const isMobile = this.globals.isOnMobile() || window.innerWidth <= 768;
      // console.log('checkIsMobile', isMobile);
      isMobile ? this.connect(value) : false;
    }

    async connect(value) {
      const deepLink = null;
      const params = new URLSearchParams({
        dapp_encryption_public_key: this.pKey.toString(),
        cluster: "devtnet", // mainnet-beta
        app_url: deepLink || window.location.href,
        redirect_link: deepLink || window.location.href
      });

      const url = this.buildUrl("connect", params);
      if (value.adapter.name == 'Phantom') {
        value.adapter.url = url;
        // console.log('Adapter Url seted to ', url, value);
      } else {
        // console.log('Document location is', url);
        // document.location = url;
      }
    };

    buildUrl = (path: string, params: URLSearchParams) =>
    `https://phantom.app/ul/v1/${path}?${params.toString()}`;

  connectionSubscribe() {
    this.connection$.subscribe((connection: solanaWeb3.Connection) => {
      // console.log('solanaConnection', connection);
      this.solanaConnection = connection;
    }, error => {console.log('connection error: ', error);});

    this.connected$.subscribe(value => {
      // console.log('connected', value, this.pKey);
      this.isConnected = value;
    }, error => {console.log('connected error: ', error);});

    this.publicKey$.subscribe(value => {
      if (value) {
        this.pKey = value;
        console.log('Public Key', this.pKey, this.pKey.toString());
        this.refreshCurrencyPrice();
        // this.isConnected ? this.checkIsMobile(walletState): false; // test for mobile devices
        // this.getTransactionHistory(this.pKey); // getting 10 transaction from transaction history
      }
    });

    const state = this.walletStore.state$;
    state.subscribe(value => {
      if (value && value.error && value.error.error) {
        if (value.error.name == 'WalletConnectionError') {
          console.log('Wallet store error message: ',  value.error.message, 'Error name: ', value.error.name);
          this.globals.stopLoader();
          // this.dialogService.alertsModal('cryptoCurrency', 'walletConnectReject', 3000, false);
        } else {
          console.log('Wallet store error: ', value.error.name, 'message: ', value.error.message);
          this.globals.stopLoader();
        }
      } else {
        // console.log('Wallet state', value);
      }
    }, error => {
      console.log('Wallet state error', error);
    });
  }

  async getTransactionHistory(wallet: solanaWeb3.PublicKey) {
    const transactionHistory = await this.solanaConnection.getConfirmedSignaturesForAddress2(wallet, {limit: 10,});
    console.log('Transaction History: ', transactionHistory);
  }

  ngOnInit() {
    this.connectionStore.setEndpoint(this.claster.rpc ?? solanaWeb3.clusterApiUrl(this.claster.solana_endpoint_url, this.claster.tls));
    this.walletStore.setAdapters([
      new PhantomWalletAdapter({network: this.claster.solana_endpoint_url}),
      new SolflareWalletAdapter({network: this.claster.solana_endpoint_url}),
      new TorusWalletAdapter({ params: { network: this.claster.solana_endpoint_url } }),
      new SalmonWalletAdapter({ network: this.claster.solana_endpoint_url}),
      new MathWalletAdapter({network: this.claster.solana_endpoint_url}), 
      new Coin98WalletAdapter({ network: this.claster.solana_endpoint_url}),
      new CloverWalletAdapter({ network: this.claster.solana_endpoint_url}),
      new HuobiWalletAdapter({network: this.claster.solana_endpoint_url}),
      new CoinbaseWalletAdapter({network: this.claster.solana_endpoint_url}),
      new BitgetWalletAdapter({ network: this.claster.solana_endpoint_url}),
      new NekoWalletAdapter({ network: this.claster.solana_endpoint_url}),
      new TrustWalletAdapter({network: this.claster.solana_endpoint_url}),
      new NightlyWalletAdapter(),
      new SafePalWalletAdapter({network: this.claster.solana_endpoint_url}),
      new SolongWalletAdapter({ network: this.claster.solana_endpoint_url }),
      new LedgerWalletAdapter(), 
      
      // new SolletWalletAdapter({ network: WalletAdapterNetwork.Devnet }),
    ]);
    // console.log('WalletAdapterNetwork', WalletAdapterNetwork);
    this.walletStore.onError()
  }

  checkFounds() {
    this.founds = +this.accountBalance > this.transactionData.ticketPriceTotal;
    // console.log('checkFounds: ', this.transactionData.ticketPriceTotal, this.founds);
  }

  sendTransactionBtn(value) {
    this.getTrnBtnStatus.emit(value);
  }

  checkBtn() {
    // console.log('checkBtn', this.hasBalance, this.accountBalance, this.isConnected, this.isCheckTrnStatus, this.founds, this.isConnected && !this.isCheckTrnStatus && this.founds);
    if (this.isConnected && !this.isCheckTrnStatus && this.founds) {
      this.sendTransactionBtn(true);
    } else {
      this.sendTransactionBtn(false);
    }
  }

  refreshCurrencyPrice() {
    const currency = this.eventTicketsData.payment_details.currency.toLowerCase();
    const price = this.eventTicketsData.payment_details.payment;
    this.isUsd = currency === 'usd';
    const siteCurrencies = !this.isUsd ? [currency+",usd"] : [currency];
    this.solanaConnectService.getCoinGecko(siteCurrencies)
    .then((value: any) => {
      // console.log('get simplePrice value: ', value);
        if (value && !value.error) {
        const eventCurrency = this.networkCurrency ? this.networkCurrency : 'solana';
        this.eventCurRate = value[eventCurrency][currency];
        this.usdCurRate = value[eventCurrency]['usd'];
        this.ticketsPrice = +parseFloat((price / this.eventCurRate).toFixed(8));
        this.transactionData.ticketPriceTotal = parseFloat((this.ticketsPrice + +this.transactionData.fee).toFixed(8));
        this.transactionData.ticketPriceTotalSol = parseFloat((this.transactionData.ticketPriceTotal * solanaWeb3.LAMPORTS_PER_SOL).toFixed(0));
        // console.log('simplePrice', this.transactionData, this.ticketsPrice, this.networkCurrency, this.eventCurRate, this.usdCurRate);
        // console.log('Solana min fee un USD', (0.000005 * this.usdCurRate).toFixed(6));
        // console.log('Solana min fee in SOL', (0.00017 / this.usdCurRate).toFixed(6));
        // console.log('PublicKey is: ', this.pKey.toString());
        this.chainData['shortName'] = 'sol';
        this.chainData['network'] = 'Solana';
        const account = this.solanaConnection.getAccountInfo(this.pKey);
        account.then(info => {
          // console.log('getAccountInfo', info);

          info ? this.accountBalance = (info.lamports / solanaWeb3.LAMPORTS_PER_SOL): this.accountBalance = 0.00;
          if (this.accountBalance >= 0) {
            this.checkFounds();
            this.hasBalance = true;
            this.accountBalances.forEach(wallet => {
              // console.log('balance wallet', wallet);
              const balanceInUSD = this.accountBalance ? +parseFloat( (this.usdCurRate * this.accountBalance).toString()).toFixed(2) : 0.00;
              wallet['balanceInUSD']=balanceInUSD;
              wallet['shortName'] = 'sol';
              wallet['humanFriendlyBalance']=this.accountBalance;
              wallet['address']=this.pKey.toString();
              wallet['name']=wallet.adapter.name || null;
              wallet['state']=wallet.readyState || null;
              // wallet['readyState']=this.pKey.toString();
            });
            this.checkBtn();
            // console.log('accountBalances ', this.accountBalances, this.hasBalance, 'info: ', info);
          }
        }).catch(error => {
          console.log('getAccountInfo Error: ', error);
        });

        // this.getOtherInfo(); // Getting advanced info

      } else {
        console.log('Unknown Error: ', value);
      }
    }).catch(error => {
      console.log('getCryptoCurrencyPrice error:', error);
    });
  }

  async getOtherInfo() {
    // console.log('getOtherInfo', this.walletName$, this.wallets$, this.wallet$, this.ready$, this.connected$, this.connection$);

    const accountChange = await this.solanaConnection.onAccountChange(this.pKey, this.onAccountChange);
    console.log('onAccountChange subscription id ', accountChange);

    const programAccountChange = await this.solanaConnection.onProgramAccountChange(this.pKey, this.onProgramAccountChange);
    console.log('onProgramAccountChange subscription ', programAccountChange);

    const onLogs = await this.solanaConnection.onLogs(this.pKey, this.onLogs);
    console.log('onLogs subscription ', onLogs);

    console.log('SystemProgram programId: ', solanaWeb3.SystemProgram.programId.toString());

    // working
    // const getMultipleAccountsInfo = await this.solanaConnection.getMultipleAccountsInfo([this.pKey]);
    // console.log('getMultipleAccountsInfo ', getMultipleAccountsInfo);

        // const nonce = this.solanaConnection.getNonce(this.pKey);
        // nonce.then(value => {
        //   console.log('getNonce ', value);
        // }).catch(error => {
        //   console.log('getNonce error', error);
        // });

        // const tokenAccountBalance = this.solanaConnection.getTokenAccountBalance(this.pKey);
        // tokenAccountBalance.then(value => {
        //   console.log('getTokenAccountBalance ', value);
        // }).catch(error => {
        //   console.log('getTokenAccountBalance error', error);
        // });

        // const onSlotChange = await this.solanaConnection.onSlotChange(this.onSlotChange);
        // console.log('onSlotChange subscription id ', onSlotChange);

        // const onSlotUpdate = await this.solanaConnection.onSlotUpdate(this.onSlotUpdate);
        // console.log('onSlotUpdate subscription id ', onSlotUpdate);

        // const getProgramAccounts = await this.solanaConnection.getProgramAccounts(this.pKey);
        // console.log('getProgramAccounts', getProgramAccounts);

        // const getParsedAccountInfo = await this.solanaConnection.getParsedAccountInfo(this.pKey);
        // console.log('getParsedAccountInfo', getParsedAccountInfo);

        // const getNonce = await this.solanaConnection.getNonce(this.pKey);
        // console.log('getNonce id ', getNonce);

        // const getTokenAccountsByOwner = await this.solanaConnection.getTokenAccountsByOwner(this.pKey, {programId: solanaWeb3.SystemProgram.programId});
        // console.log('getTokenAccountsByOwner subscription ', getTokenAccountsByOwner);

        // const getTokenLargestAccounts = await this.solanaConnection.getTokenLargestAccounts(this.pKey);
        // console.log('getTokenLargestAccounts subscription ', getTokenLargestAccounts);
  }

  // saveTransaction(onSignatureConfirmed) {
  //   if (onSignatureConfirmed && !onSignatureConfirmed.err) {
  //     setTimeout(() => {
  //       // this.getTransactionWallet.emit('finalized');
  //     }, 3000);
  //     this.isSuccessful = true;
  //     // this.onDisconnect();
  //   }
  // }

  getState(signature) {
    this.signature = signature;
    this.isChecking = true;
    this.transaction.hashPrew = this.hashPrewiev(signature);
    this.transaction.hash = signature;
    this.transaction.message = 'Pending...';
    // this.checkTransactionStatus(signature, 'pending'); // get transaction status

    // this.getTransactionStatus(signature, 'pending');
    // this.solanaConnection.onSignature(signature, (onSignaturesubScription) => {
    //   console.log('getState onSignature subscription ', onSignaturesubScription);
    //     this.transaction.message = 'Finalized';
    //     this.saveTransaction(onSignaturesubScription);
    // });

    this.solanaConnection.onSignature(signature, (onSignatureConfirmed) => {
      // console.log('getState onSignature confirmed subscription ', onSignatureConfirmed);
      if (onSignatureConfirmed && !onSignatureConfirmed.err) {
        this.transaction.message = 'Confirmed';
        // this.checkTransactionStatus(signature, 'confirmed');
        // this.getTransactionStatus(signature, 'confirmed');
      } else { // if error
        console.log('onSignatureConfirmed error');
      }
    }, 'confirmed');

    this.solanaConnection.onSignature(signature, (onSignatureProcessed) => {
      // console.log('getState onSignature processed subscription ', onSignatureProcessed);
      if (onSignatureProcessed && !onSignatureProcessed.err) {
        this.transaction.message = 'Processed';
        // this.getTransactionStatus(signature, 'processed');
        // this.checkTransactionStatus(signature, 'processed');
      }
    }, 'processed');

    this.solanaConnection.onSignature(signature, (onSignatureFinalized) => {
      // console.log('getState onSignature finalized subscription ', onSignatureFinalized);
      if (onSignatureFinalized && !onSignatureFinalized.err) {
        !this.withOutFinalization ? this.transaction.message = 'Finalized' : false;
        // this.getTransactionStatus(signature, 'processed');
        // this.checkTransactionStatus(signature, 'finalized');
      }
    }, 'finalized');

    const confirmTransactionConfirmed = this.solanaConnection.confirmTransaction(signature, 'confirmed');
    confirmTransactionConfirmed.then(confirmTransactionConfirmed => {
      // console.log('confirmTransaction Confirmed ', confirmTransactionConfirmed);
      // this.getTransactionStatus(signature, 'confirmed');
      this.checkTransactionStatus(signature, 'confirmed');
    }).catch(error => { console.log('confirmTransactionConfirmed error: ', error); });

    const confirmTransactionProcessed = this.solanaConnection.confirmTransaction(signature, 'processed');
    confirmTransactionProcessed.then(confirmTransactionProcessed => {
    // console.log('confirmTransaction Processed ', confirmTransactionProcessed);
    // this.getTransactionStatus(signature, 'processed');
    this.checkTransactionStatus(signature, 'processed');
    }).catch(error => { console.log('confirmTransactionProcessed error: ', error); });

    const confirmTransactionFinalized = this.solanaConnection.confirmTransaction(signature, 'finalized');
    confirmTransactionFinalized.then(confirmTransactionFinalized => {
      // console.log('confirmTransaction Finalized: ', confirmTransactionFinalized);
      // this.getTransactionStatus(signature, 'finalized');
      !this.withOutFinalization ? this.checkTransactionStatus(signature, 'finalized') : false;
    }).catch(error => { console.log('confirmTransactionFinalized error: ', error); });

    // const getConfirmedTransaction = this.solanaConnection.getConfirmedTransaction(signature);
    // getConfirmedTransaction.then(getConfirmedTransaction => {
    //   console.log('getConfirmedTransaction ', getConfirmedTransaction);
    // }).catch(error => { console.log('getConfirmedTransaction error: ', error); });
  }

  checkTransactionStatus(signature, state) {
    const getSignatureStatuses = this.solanaConnection.getSignatureStatus(signature); // .getSignatureStatuses([signature]);
    getSignatureStatuses.then(getSignatureStatuse => {
      // console.log('getSignatureStatuses subscription ', getSignatureStatuse);
      if (getSignatureStatuse && getSignatureStatuse.value) {
        // console.log('Signature Status is: ', getSignatureStatuse.value.confirmationStatus);
        this.getTransactionStatus(signature, state, getSignatureStatuse);
        // this.saveTransactionToDb(signature, state, transaction, getSignatureStatuse);
      }
    }).catch(error => { console.log('getSignatureStatuses error: ', error); });
  }

  getTransactionStatus(signature, state?, signatureStatuse?) {
    // Check status of transaction
    const commitment = state === 'confirmed' || state === 'finalized' ? {commitment: state} : null;
    // console.log('getTransactionStatus ', commitment, state, signature);
    if (signature) {
      this.solanaConnection.getTransaction(signature, commitment)
      .then(value => {
        // console.log('getTransaction ', state, value);
        if (value) {
          this.accountBalances.forEach(balance => {
            if (value.meta.postBalances[0] >= 0) {
              this.accountBalance = value.meta.postBalances[0] ?  (value.meta.postBalances[0] / solanaWeb3.LAMPORTS_PER_SOL) : 0.00;
              // console.log('postBalances 0', this.accountBalance, 'postBalances 1', value.meta.postBalances[1] / solanaWeb3.LAMPORTS_PER_SOL);
                this.checkFounds();
                this.hasBalance = true;
                const balanceInUSD = this.accountBalance ? +parseFloat( (this.usdCurRate * this.accountBalance).toString()).toFixed(2) : 0.00;
                balance['humanFriendlyBalance']=this.accountBalance;
                balance['balanceInUSD']=balanceInUSD;
            } else {
              balance['humanFriendlyBalance']=0.00;
              balance['balanceInUSD']=0.00;
            }
            this.checkBtn();
            // console.log('Changed accountBalances', this.accountBalances);
          });
          // this.checkTransactionStatus(signature, state, value);
          // console.log('saveTransactionToDb...', signature, state, value);
          this.saveTransactionToDb(signature, state, value);
        } else {
          // console.log('saveTransactionToDb...', signature, state, signatureStatuse);
          setTimeout(()=> {
            this.saveTransactionToDb(signature, state, signatureStatuse);
          }, 3000);

        }
      }).catch(error => { console.log('getTransaction error: ', error); });

    } else {
      console.log('Invalid signature!');
    }
  }

  saveTransactionToDb(signature, state, data?, response?) {
    if (this.eventTicketsData && this.eventTicketsData.event_details) {
      let txHash: any = {};
      if (state && signature) {
        this.prevStates.forEach(prev => {
          txHash[prev.state] = prev.data;
          // console.log('state', prev.state);
        });
        this.prevStates.push({state, data});
        txHash[state] = data;
      } else if (state === 'pending') {
        this.prevStates.push({state, data: txHash});
      }
      // console.log('Prev states: ', this.prevStates);
      let params;
      const res = response && response.transaction_data ? response.transaction_data : false;
        txHash.hash = res && res.hash ?  res.hash : signature;
        txHash.event_id = res && res.event_id ?  res.event_id : this.eventTicketsData.event_details.eventId;
        txHash.event_name = res && res.event_name ?  res.event_name : this.eventTicketsData.event_details.eventName;
        txHash.total_tickets =res && res.total_tickets ?  res.total_tickets : this.eventTicketsData.event_details.totalTickets;
        txHash.user_email = res && res.user_email ?  res.user_email : this.eventTicketsData.event_details.userEmail;
        txHash.chain_currency = res && res.chain_currency ?  res.chain_currency : this.chainData.shortName;
        txHash.transaction_amount = res && res.transaction_amount ?  res.transaction_amount : this.transactionData.ticketPriceTotal;
        txHash.transaction_fee = res && res.transaction_fee ?  res.transaction_fee :  this.transactionData.fee;
        txHash.event_currency_rate = res && res.event_currency_rate ?  res.event_currency_rate : this.eventCurRate;
        txHash.tickets_currency = res && res.tickets_currency ?  res.tickets_currency : this.eventTicketsData.payment_details.currency.toLowerCase();
        txHash.tickets_price = res && res.tickets_price ?  res.tickets_price : this.eventTicketsData.payment_details.payment;
        txHash.tickets_price_without_fee = res && res.tickets_price_without_fee ?  res.tickets_price_without_fee : this.eventTicketsData.payment_details.net_payment;
        txHash.tickets_price_in_chain_cur_wo_fee = res && res.tickets_price_in_chain_cur_wo_fee ?  res.tickets_price_in_chain_cur_wo_fee : +parseFloat((this.eventTicketsData.payment_details.net_payment / this.eventCurRate).toString()).toFixed(8);
        txHash.tickets_amount = res && res.tickets_amount ?  res.tickets_amount : (this.eventCurRate * this.transactionData.ticketPriceTotal).toFixed(2).toString();
        txHash.usd_currency_rate = res && res.usd_currency_rate ?  res.usd_currency_rate :  this.usdCurRate;
        txHash.network =  res && res.network ?  res.network : this.chainData.network;
        txHash.payment_id =  res && res.payment_id ?  res.payment_id : this.eventTicketsData.payment_details.id;
        txHash.main_purchase_identifier =  res && res.main_purchase_identifier ?  res.main_purchase_identifier : this.eventTicketsData.payment_details.main_purchase_identifier;
        txHash.status = state;
        params = {
          payment_id: response && response.main_purchase_identifier ? response.main_purchase_identifier : this.eventTicketsData.payment_details.main_purchase_identifier,
          transaction_hash: txHash.hash,
          transaction_status: state === 'finalized' ? 1 : 0,
          transaction_data: txHash //{transaction_amount: this.transactionData.ticketPriceTotal, txHash}
        };
        // console.log('createTransactionToDb params', params, state);
        this.globals.startLoader();
        this.solanaConnectService.saveSolanaTransaction(params).subscribe((response: any) => {
          this.globals.stopLoader();
          if (response.data && response.data.url && response.data.success) {
            // console.log('saveWalletConnectTransaction was ', state);
            this.transactionUrl = '';
            this.isSuccessful = true;
            this.isChecking = false;
            this.getTransactionWallet.emit({transaction: response, state});
            this.onDisconnect();
            if (state === 'finalized') {
              setTimeout(() => {
                // console.log('send finish and go to home page... ', state, response.data.url);
                this.getTransactionWallet.emit({transaction: response, state: 'finish'});
              }, 3000);
            }
          } else if (response.code === 'success' && !response.data.success) {
            // console.log('saveWalletConnectTransaction was ', state,  response.data.check_wallet_url);
            // this.transaction.message = 'Pending...'
            this.transactionUrl = response.data.check_wallet_url;
            this.isChecking = true;
            this.getTransactionWallet.emit({transaction: response, state});
            // this.dialogService.alertsModal('success', 'walletConnectProcessing', 5000, true);
            if (state === 'processed' && this.withOutFinalization) {
              setTimeout(() => {
                // console.log('send finish and go to home page... ', state, response.data.url);
                this.isSuccessful = true;
                this.isChecking = false;
                this.onDisconnect();
                this.getTransactionWallet.emit({transaction: response, state: 'finish'});
              }, 5000);
            }
          } else {
            console.log('saveWalletConnectTransaction error', response.code, state);
            // this.getTransactionWallet.emit({transaction: response, state});
            this.isChecking = false;
            this.dialogService.alertsModal('cryptoCurrency', 'walletTransactionSave', 3000, false);
          }
        },
        error => {
          console.log('Transaction error', error);
          this.dialogService.alertsModal('cryptoCurrency', 'walletTransactionSave', 3000, false);
          // this.dialogService.alertsModal('errors', (error.code) ? error.code : 4 ,3000, false, error.log_id);
        });
      } else {
        console.log('Cannot read properties of null (reading event_details)');
      }
  }

  saveTransactionWallet(signature, state) {
    // console.log('saveTransactionWallet ', signature, state);
    setTimeout(() => {
      this.getTransactionWallet.emit({transaction: {}, state});
    }, 1000);

    if (state === 'finalized') {
      setTimeout(() => {
        // console.log('send finish and go to home page... ');
      // this.getTransactionWallet.emit({transaction: {}, state: 'finish'});
      }, 3000);
      this.isSuccessful = true;
      this.onDisconnect();
    }
  }

  onAccountChange(value) {
    console.log('onAccountChange subscription id', value);
  }

  onLogs(value) {
    console.log('onLogs subscription id', value);
  }

  onProgramAccountChange(value): void {
    console.log('ChangeCallback subscription id', value);
    // return value; // solanaWeb3.AccountChangeCallback
  }

  copyToClipboard(value) {
    if (value === 'hash') {
      this.clipboardService.copyFromContent(this.transaction.hash);
    } else if (value === 'url') {
      this.clipboardService.copyFromContent(this.transactionUrl);
    }
    this.clipboardService.destroy();
    this.dialogService.alertsModal('success', 'couponCopied', 2000, true);
  }

  onConnect(wallet = null) {
    // console.log('Connecting', wallet);
    if (wallet && wallet.readyState === 'Installed') {
      this.walletStore.selectWallet(wallet.adapter.name);
      this.selectedWallet = wallet;
      this.globals.startLoader();
      this.walletStore.connect().subscribe(() => {
        console.log('onConnect', true);
        this.globals.stopLoader();
        this.isCheckTrnStatus = false;
        this.isSelectedWallet = true;
        const lightbox = document.getElementById('solana-modal-lightbox');
        if (lightbox) {
          lightbox.style.display = 'none';
          this.solanaModalIsClose = true;
        };
        this.checkBtn();
      });
    } else if (this.isSelectedWallet && this.selectedWallet) {
      this.globals.startLoader();
      this.walletStore.connect().subscribe(() => {
        console.log('onConnect', true);
        this.globals.stopLoader();
        this.isCheckTrnStatus = false;
        this.isSelectedWallet = true;
        const lightbox = document.getElementById('solana-modal-lightbox');
        if (lightbox) {
          lightbox.style.display = 'none';
          this.solanaModalIsClose = true;
        };
        this.checkBtn();
      });
    } else {
      // console.log('onConnect', this.isSelectedWallet && this.selectedWallet, this.solanaModalIsClose, this.isConnected);
      const lightbox = document.getElementById('solana-modal-lightbox');
        if (lightbox) {
          lightbox.style.display = 'flex';
          this.solanaModalIsClose = false;
        };
    }
  }

  onDisconnect() {
    // console.log('Disconnecting...', this.walletStore, this.connectionStore);
    this.walletStore.disconnect().subscribe((value) => {
      // console.log('onDisconnect', true, value);
      this.isCheckTrnStatus = true;
      this.isConnected = false;
      this.globals.stopLoader();
      this.isChecking = false;
      this.prevStates = [];
      this.checkBtn();
      // this.accountBalances = [];
      // this.transaction = null;
      // this.eventTicketsData = null;
      // this.founds = false;
    }, error => {console.log('Disconnect error: ', error);});
  }

  onSelectWallet(walletName: WalletName) {
    // console.log('onSelectWallet', walletName);
    this.walletStore.selectWallet(walletName);
  }

  onSetWallet(walletName: WalletName, walletState: string, wallet: any) {
    // console.log('onSelectWallet', walletName, walletState, wallet);
    this.globals.startLoader();
    if (wallet && walletName && walletState === 'Installed') {
      // console.log('Connecting');
      this.walletStore.selectWallet(walletName);
      this.selectedWallet = wallet;
      this.walletStore.connect().subscribe((value) => {
        this.globals.stopLoader();
        const lightbox = document.getElementById('solana-modal-lightbox');
        if (lightbox) {
          lightbox.style.display = 'none';
          this.solanaModalIsClose = true;
        };
        this.isSelectedWallet = true;
        this.isCheckTrnStatus = false;
        // console.log('onSetConnect', value, true, this.isSelectedWallet, this.isCheckTrnStatus);
        // this.solanaConnection.getTokenSupply(this.pKey).then(value => {console.log('getTokenSupply', value);}).catch(error => {console.log('getTokenSupply error ', error)});
        // this.solanaConnection.getBalance(this.pKey).then(value => {console.log('getBalance', value);});
        // this.solanaConnectService.requestAirDrop(this.pKey.toString(), this.solanaConnection); // '96J2VU72hTU1xMsVfPs3ZazJkcMHhsefbH84jwRMNCWZ'
        // this.solanaConnection.getAccountInfo(this.pKey.toString()).then(value => {console.log('getAccountInfo', value);});
      });
    }
  }

  onNotInstalledWallet(walletAdapter: WalletAdapter, wallet: any) {
    console.log('Clicked on a not Installed wallet')
    this.selectedWallet = wallet;

    // Support for browse deeplink on Mobile
     if ([SolflareWalletName.toString(), PhantomWalletName.toString()].includes(walletAdapter.name)) {
           const params = new URLSearchParams({
             ref: window.location.host,
           });
           
           this.browseUniversalLink = `${walletAdapter.url}/ul/browse/${encodeURIComponent(window.location.href)}?${params.toString()}`
     }

    this.openSolanaInfoModal()
  }

  closeSolanaModal() {
    // console.log('closeSolanaModal', true);
    const lightbox = document.getElementById('solana-modal-lightbox');
    lightbox ? lightbox.style.display = 'none' : false;
    this.solanaModalIsClose = true;
  }

  openSolanaModal() {
    // console.log('openSolanaModal', true);
    const lightbox = document.getElementById('solana-modal-lightbox');
    lightbox ? lightbox.style.display = 'flex' : false;
    this.solanaModalIsClose = false;
  }

  closeSolanaInfoModal() {
    // console.log('closeSolanaModal', true);
    const lightbox = document.getElementById('solana-info-modal-lightbox');
    const lightboxOverlay = document.getElementById('solana-info-modal-lightbox--overlay');
    lightboxOverlay ? lightboxOverlay.style.display = 'none' : false;
    lightbox ? lightbox.style.display = 'none' : false;
    this.solanaInfoModalIsClose = true;
  }

  openSolanaInfoModal() {
    // console.log('openSolanaModal', true);
    const lightbox = document.getElementById('solana-info-modal-lightbox');
    const lightboxOverlay = document.getElementById('solana-info-modal-lightbox--overlay');
    lightboxOverlay ? lightboxOverlay.style.display = 'block' : false;
    lightbox ? lightbox.style.display = 'flex' : false;
    this.solanaInfoModalIsClose = false;
  }

  timerWhatcher() {
    const timerStatus = this.solanaConnectService.timerStatus.subscribe(status => {
      // console.log('timerWhatcher', status);
      if (status >= 30) {
        this.getTransactionWallet.emit({transaction: {}, state: 'timerStopped'});
      }
    }, error => {console.log('timerWhatcher error: ', error);});
    this.subscriptions.push(timerStatus);
  }

  transactionInstruction(fromPubkey, blockhash, lastValidBlockHeight): solanaWeb3.Transaction {
    this.startTimer();

    const toPubkey = new solanaWeb3.PublicKey(this.recipient);
    // console.log('Create transaction Instructions...', this.recipient, toPubkey, this.lamports, this.transactionData.ticketPriceTotalSol);

    const instruction: solanaWeb3.TransactionInstruction = solanaWeb3.SystemProgram.transfer({
      fromPubkey,
      toPubkey,
      lamports: this.lamports,
    });
    // console.log('Transaction Instructions ', instruction);

    const transaccion: solanaWeb3.Transaction = new solanaWeb3.Transaction({
      blockhash,
      feePayer: fromPubkey,
      lastValidBlockHeight,
    });
    // console.log('new solanaWeb3.Transaction', transaccion);

    transaccion.add(instruction);
    // console.log('Transaction created successfully', transaccion);

    this.getEstimate(transaccion);

    return transaccion;
  }

  async getEstimate(transaccion) {
    const fees = await transaccion.getEstimatedFee(this.solanaConnection);
    // console.log(`Estimated SOL transfer cost: ${fees} lamports `, 'Solana min fee: ', fees / solanaWeb3.LAMPORTS_PER_SOL, ' SOL');
  }

  hashPrewiev(hash?: string) {
    const showChar = 8;
    let prewHash;
    prewHash = hash.substring(0,showChar) + ' . . . ' + hash.substring(hash.length - showChar, hash.length);
    return prewHash;
  }

  onSendTransaction(fromPubkey: solanaWeb3.PublicKey) {
    this.isCheckTrnStatus = true;
    this.checkBtn();
    const total = this.transactionData.ticketPriceTotalSol;
    !this.lamports ? this.lamports = total : false;
    !this.recipient ? this.recipient = this.pKey.toString() : false;
    // console.log('onSendTransaction', this.accountBalances, this.hasBalance,  fromPubkey? fromPubkey.toString() : fromPubkey, this.recipient, this.lamports, total, solanaWeb3.LAMPORTS_PER_SOL, this.transactionData.ticketPriceTotal);
    if (!this.lamports) {
      // TODO: if no lamports need to restart processes
      // console.log('lamports is: ', this.lamports);
    }
    this.globals.startLoader();
    this.connection$
      .pipe(
        isNotNull,
        take(1), // first()
        concatMap((connection: solanaWeb3.Connection) =>
          from(defer(() => connection.getLatestBlockhash())).pipe(
            concatMap(({ blockhash, lastValidBlockHeight }) => {
              // console.log('sendTransaction', fromPubkey, blockhash, lastValidBlockHeight);
              this.transaction.blockhash = blockhash;
              this.transaction.lastValidBlockHeight = lastValidBlockHeight;
              return this.walletStore.sendTransaction(
                this.transactionInstruction(fromPubkey, blockhash, lastValidBlockHeight),
                connection
              )}
              // concatMap(({ blockhash, lastValidBlockHeight }) =>
              // this.walletStore.sendTransaction(
              //   new solanaWeb3.Transaction({
              //     blockhash,
              //     feePayer: fromPubkey,
              //     lastValidBlockHeight,
              //   }).add(
              //     solanaWeb3.SystemProgram.transfer({
              //       fromPubkey,
              //       toPubkey: new solanaWeb3.PublicKey(this.recipient),
              //       lamports: this.lamports,
              //     })
              //   ), connection)
            )
          )
        )
      )
      .subscribe({
        next: (signature) => {
          // console.log(`Transaction sent (${signature})`);
          this.stopTimer();
          this.globals.stopLoader();
          this.getState(signature);
        },
        error: (error: WalletError) => {
          if (error) {
            if (error.name == 'WalletSignTransactionError') {
              console.log('User rejected Sign Transaction',  error.name,  error.message);
              this.dialogService.alertsModal('cryptoCurrency', 'walletConnectReject', 3000, false);
            } else if (error.name == 'TransactionExpiredTimeoutError') {
              // console.error('Transaction was not confirmed in 30.00 seconds. It is unknown if it succeeded or failed. Check signature using the Solana Explorer', error);
              console.log('Transaction error message: ', error.message, 'Error name: ', error.name);
            } else if (error.name == 'WalletSendTransactionError' && (error.message == 'User canceled request' || error.error.code == '4001')) { // error.message == 'User rejected the request.'
              console.log('Transaction error: ', error.name,  error.message);
              this.dialogService.alertsModal('cryptoCurrency', 'walletConnectReject', 3000, false);
            } else if (error.name == 'WalletSendTransactionError' && error.error.code == '-32003') {
              console.log('Transaction error',  error.name,  error.message);
              this.dialogService.alertsModal('cryptoCurrency', 'walletChainNotFound', 3000, false);
              this.transaction.message = 'Failed to send transaction. Please try again later.';
            } else {
              // console.log('Transaction error: ', error.error?.code ? error.error?.code : error);
              console.log('Transaction error message: ', error.message, 'Error name: ', error.name);
            }
            this.getTransactionWallet.emit({transaction: {}, state: 'rejected'});
          }
          this.globals.stopLoader();
          this.stopTimer();
          // console.error(error);
        },
      });
  }

  onSignTransaction(fromPubkey: solanaWeb3.PublicKey) {
    // console.log('onSignTransaction', fromPubkey);
    this.connection$
      .pipe(
        isNotNull,
        take(1), // take(1), first()
        concatMap((connection: any) =>
          from(defer(() => connection.getLatestBlockhash())).pipe(
            map(({ blockhash, lastValidBlockHeight }) =>
              new solanaWeb3.Transaction({
                blockhash,
                feePayer: fromPubkey,
                lastValidBlockHeight
              }).add(
                solanaWeb3.SystemProgram.transfer({
                  fromPubkey,
                  toPubkey: new solanaWeb3.PublicKey(this.recipient),
                  lamports: this.lamports,
                })
              )
            )
          )
        ),
        concatMap((transaction: any) => {
          const signTransaction$ =
            this.walletStore.signTransaction(transaction);

          if (!signTransaction$) {
            return throwError(
              () => new Error('Sign transaction method is not defined')
            );
          }

          return signTransaction$;
        })
      )
      .subscribe({
        next: (transaction) => console.log('Transaction signed', transaction),
        error: (error) => console.log('onSignAllTransactions error: ', error)
      });
  }

  onSignAllTransactions(fromPubkey: solanaWeb3.PublicKey) {
    // console.log('onSignAllTransactions', fromPubkey);
    this.connection$
      .pipe(
        isNotNull,
        take(1), // take(1), first()
        concatMap((connection: any) =>
          from(defer(() => connection.getLatestBlockhash())).pipe(
            map(({ blockhash, lastValidBlockHeight }) =>
              new Array(3).fill(0).map(() =>
                new solanaWeb3.Transaction({
                  blockhash,
                  feePayer: fromPubkey,
                  lastValidBlockHeight
                }).add(
                  solanaWeb3.SystemProgram.transfer({
                    fromPubkey,
                    toPubkey: new solanaWeb3.PublicKey(this.recipient),
                    lamports: this.lamports,
                  })
                )
              )
            )
          )
        ),
        concatMap((transactions: any) => {
          const signAllTransaction$ =
            this.walletStore.signAllTransactions(transactions);

          if (!signAllTransaction$) {
            return throwError(
              () => new Error('Sign all transactions method is not defined')
            );
          }

          return signAllTransaction$;
        })
      )
      .subscribe({
        next: (transactions) => console.log('Transactions signed', transactions),
        error: (error) => console.log('onSignAllTransactions error: ', error)
      });
  }

  generateNonce() {
    const generatedNonce = Math.floor(Math.random() * 100000000).toString();
    return generatedNonce;
  }

  onSignMessage() {
    // console.log('onSignMessage');
    const signMessage$ = this.walletStore.signMessage(
      new TextEncoder().encode('Get-in Authentication id: ' + this.generateNonce())
    );

    if (!signMessage$) {
      return console.log(new Error('Sign message method is not defined'));
    }

    signMessage$.pipe(
      take(1), // take(1),first()
    ).subscribe((signature) => {
      console.log(`Message signature: ${{ encode }.encode(signature)}`);
    });
  }

  stopTimer() {
    this.solanaConnectService.stopTimer();
  }

  startTimer() {
    this.solanaConnectService.startTimer();
  }

  ngOnDestroy(): void {
    this.stopTimer();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    // this.eventTicketsData = null;
    // this.getTransactionWallet.emit({transaction: {}, state: 'timerStopped'});
    // console.log('ngOnDestroy...', this.walletStore, this.connectionStore);
  }
}
