import { Injectable } from '@angular/core';
declare var window: any;
declare var rudderanalytics;

@Injectable({
  providedIn: 'root'
})
export class RudderstackService {

  constructor() { }

  identify(userId, traits = {}){
    if(!window.rudderanalytics) {return}
    rudderanalytics.identify(userId, traits);
  }

  track(event, properties = {}){
    if(!window.rudderanalytics) {return}
    rudderanalytics.track(event, properties);
  }

  group(id, traits = {}){
    if(!window.rudderanalytics) {return}
    rudderanalytics.group(id, traits);
  }

  page(category = '', name = '') {
    if(!window.rudderanalytics) {return}
    rudderanalytics.page(category,name);
  }

}
