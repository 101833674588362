import { Injectable } from '@angular/core';
import {
  HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

@Injectable()
export class SpikeProtectionInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get true or false from spike_protection_session
    const spike_protected_session = sessionStorage.getItem('spike_protection_session');
    // Clone the request and replace the original headers with
    // cloned headers, updated with the spike_protection_session.
    // if value exist in the session storage
    const isExternalRequest = req.url.includes('spotify.com');
    let spikeReq: any;
    if (spike_protected_session && !isExternalRequest) {
      spikeReq = req.clone({ setHeaders: { spike_protected_session: spike_protected_session } });
    } else {
      spikeReq = req.clone();
    }


    // send cloned request with or without spike_protected_session header to the next handler.
    return next.handle(spikeReq);
  }
}
