<div class="ticket-img-modal-header">
  <button class="close-btn" mat-dialog-close>
    <span>&#x2715;</span>
  </button>
</div>
<div class="ticket-img-modal-body" mat-dialog-content>
  <img src="{{imgURL}}" alt="ticket" class="ticket-img">
</div>


