<div class="page-header-arrow-back">
  <div class="page-back-arrow">
    <div class="close-dialog-arrow" (click)="closePage()">
      <svg class="arrow-close-modal" width="10" height="14" viewBox="0 0 10 14" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M9.49219 1.64063L4.13281 7L9.49219 12.3594L7.85156 14L0.851562 7L7.85156 0L9.49219 1.64063Z"
          fill="white" />
      </svg>
    </div>
  </div>
</div>
<div class="support-section">
  <div class="support-wrapper">
    <div i18n="title | page title" class="support-page-title">CONTACT US</div>
    <div class="main-section">
      <div class="person-section">

        <form class="support-form" (ngSubmit)="onSubmit()" #contactUsPageForm="ngForm">
          <div class="contacts-info-controls">
            <div class="contacts-info-control" *ngFor="let info of main_info">
              <div class="place-label">
                <svg class="svg-label" width="36" height="52" viewBox="0 0 36 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 0C8.90434 0 0.666672 7.37317 0.666672 16.471C0.666672 25.5667 8.18284 36.426 18 52C27.8172 36.426 35.3333 25.5667 35.3333 16.471C35.3333 7.37317 27.0978 0 18 0ZM18 23.8333C14.4098 23.8333 11.5 20.9235 11.5 17.3333C11.5 13.7432 14.4098 10.8333 18 10.8333C21.5902 10.8333 24.5 13.7432 24.5 17.3333C24.5 20.9235 21.5902 23.8333 18 23.8333Z" fill="#FF6465" />
                </svg>
                <label for="name" class="place-text">{{info.city}}
                  <span class="place-address">{{info.address}}</span>
                </label>
              </div>
              <div class="phone-label" *ngIf="info.phone">
                <svg class="svg-label" width="37" height="55" viewBox="0 0 37 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M36.8333 51.8398L28.7644 36.2679C28.746 36.2771 24.2406 38.4908 24.0344 38.5848C18.901 41.0735 8.45334 20.664 13.4721 17.9735L18.2456 15.6223L10.2408 0L5.41459 2.38104C-11.09 10.9863 15.1152 61.9231 31.9979 54.2071C32.2752 54.081 36.815 51.849 36.8333 51.8398Z" fill="#FF6465" />
                </svg>
                <label for="name" class="phone-number">
                  <a [href]="sanitizer.bypassSecurityTrustUrl('tel:'+ info.phone)" class="show-phone" >{{info.phone}}</a>
                </label>
              </div>
            </div>
          </div>
          <div class="attachment">
            <div class="personal-info-controls">
              <div class="personal-info-control">
                <label i18n="label | user name" for="name" class="col-form-label">Name</label>
                <input i18n-placeholder="@@FirstName" class="form-control-input" type="text" value=""
                  name="name" placeholder="First name" [(ngModel)]="contact_info.name" #user_name="ngModel" required>

                <span i18n="error alert | required user name" *ngIf="user_name.invalid && user_name.touched"
                  class="item-error">Your name is required</span>
              </div>
            </div>
            <div class="personal-info-controls">
              <div class="personal-info-control">
                <label i18n="label | email" for="email" class="col-form-label">Email</label>
                <input i18n-placeholder="placeholder | email" class="form-control-input" type="email" name="email"
                  placeholder="Email" value="" [(ngModel)]="contact_info.email" [pattern]="emailRegex" #email="ngModel"
                  required>
                <span i18n="@@PersonalInfoErrorInvalidEmail" *ngIf="email.errors?.pattern && email.touched"
                  class="item-error">Please enter a valid email address.</span>
                <span i18n="@@PersonalInfoErrorRequiredEmail" *ngIf="email.errors?.required && email.touched"
                  class="item-error">Email required</span>
              </div>
            </div>
            <div class="personal-info-control description-control">
              <label for="description" class="form-labels">
                <span i18n="label | description" class="description">Description</span>
              </label>
              <textarea i18n-placeholder="placeholder | description" placeholder="Description" rows="8"
                class="form-control-input area-control area-radius" type="text" name="description" value=""
                [(ngModel)]="contact_info.description" #description="ngModel" maxlength="250" required>
              </textarea>
              <span i18n="error alert | required description" *ngIf="description.invalid && description.touched"
                class="item-error">Field is required</span>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="buttons-section">
      <div i18n="button | submit" class="email-btn" (click)="onSubmit()">SUBMIT</div>
    </div>
  </div>
</div>
