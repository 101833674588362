<div class="personal-info-container" [ngClass]="{'rtl' : dir === 'rtl'}">
	<!-- <svg (click)="closePersonalInfoModal()" class="arrow-close-modal" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M18 3.515L6.83445 15L18 26.485L14.5828 30L0 15L14.5828 0L18 3.515Z" fill="white"/>
	</svg> -->
	<span class="close-nav" (click)="closePersonalInfoModal()"></span>
	<!------------------Personal Info: Main Title---------------->
	<!-- <div mat-dialog-title class="personal-info-title">
		<p>Verify your account in 4 steps and get discounts for tickets!</p>
	</div> -->

	<!------------------Personal Info: Form---------------->
	<form  mat-dialog-content class="personal-info-form" (ngSubmit)="onSubmit()" #personalInfoModalForm="ngForm">
		<div  class="personal-info-wrap" [ngClass]="{'rtl' : dir === 'rtl'}">

		<!------------------Personal Info: Form controls------------->
		<div class="personal-info-controls">
			<div class="personal-info-control">
			  	<label i18n="@@PersonalInfoFirstName" for="fname" class="col-form-label">First name</label>
			  	<input i18n-placeholder="@@PersonalInfoFirstName" pattern="[a-zA-Zñáéíóúü\u0590-\u05fe\u200f\u200e\u0400-\u04FF][a-zA-Zñáéíóúü\u0590-\u05fe\u200f\u200e\u0400-\u04FF -'.ʼ-]+$" [minlength]="2" class="form-control-input" type="text" value="" name="fname" placeholder="First name" [(ngModel)]="user_info.first_name" #first_name="ngModel" required (change)="onChange($event)">
			  	<span i18n="@@PersonalInfoErrorRequiredFirstName" *ngIf="first_name.invalid && first_name.touched" class="item-error">Please enter first name</span>
			</div>

			<div class="personal-info-control">
			  <label i18n="@@PersonalInfoLastName" for="lname" class="col-form-label">Last name</label>
			  <input i18n-placeholder="@@PersonalInfoLastName" pattern="[a-zA-Zñáéíóúü\u0590-\u05fe\u200f\u200e\u0400-\u04FF][a-zA-Zñáéíóúü\u0590-\u05fe\u200f\u200e\u0400-\u04FF -'.ʼ-]+$" [minlength]="2" class="form-control-input" type="text" name="lname" placeholder="Last name" value="" [(ngModel)]="user_info.last_name" #last_name="ngModel" required (change)="onChange($event)">
			  <span i18n="@@PersonalInfoErrorRequiredLastName" *ngIf="last_name.invalid && last_name.touched" class="item-error">Please enter last name</span>
			</div>
		  </div>

		  <div class="personal-info-controls controls-with-verify">
			<div class="personal-info-control">
        <div class="input-label">
			      <label i18n="@@Email" for="email" class="col-form-label">Email</label><label class="change-btn" style="color: #FF6465;" (click)="changeEmail()">Change</label>
			  </div>
        <input readonly disabled i18n-placeholder="@@Email" class="form-control-input" [disabled]="isEmail" type="email" name="email" placeholder="Email" value="" [(ngModel)]="user_info.email" [pattern]="emailRegex" #email="ngModel"  (change)="onChange($event)" inputmode="email">
			  <span i18n="@@PersonalInfoErrorInvalidEmail" *ngIf="email.errors?.pattern && email.touched" class="item-error">Please enter a valid email address.</span>
			  <span i18n="@@PersonalInfoErrorRequiredEmail" *ngIf="email.errors?.required && email.touched" class="item-error">Email required</span>
			</div>

			<div class="personal-info-phone-control">
        <div class="input-label">
			      <label i18n="@@Phone" for="phone" class="col-form-label">Phone</label><label class="change-btn" style="color: #FF6465;" (click)="changePhone()">Change</label>
				</div>
        <input readonly disabled appPhoneNumber ng2TelInput i18n-placeholder="@@Phone" class="form-control-input input-padding" trim="blur" inputmode="tel" type="tel" name="phone" placeholder="Phone" value="" [(ngModel)]="user_info.phone" #phone="ngModel" (hasError)="setErrorOnphoneNumber(!$event)" (change)="onChange($event)"
					   (ng2TelOutput)="setPhoneNumber($event)" (intlTelInputObject)="telInputObject($event, user_info.phone)" (countryChange)="onCountryChange($event)" [ng2TelInputOptions]="localizationService.getPhoneInputOptions()" />
			  <!-- <app-error-creator [@slideIn] *ngIf="phone.touched && phone.invalid && !phone.errors?.required" [name]="'phone'" [control]="phone"></app-error-creator> -->
			  <span i18n="@@PersonalInfoErrorInvalidPhone" *ngIf="phone.invalid && phone.errors?.required && phone.touched" class="item-error">Please enter a valid phone number</span>

        <!--        <input type="checkbox" #is_verified="ngModel" [(ngModel)]="isPhoneVerified" name="is_verified" [hidden]="true" required >-->
        <!--        ENABLE/DISABLE Phone validation on backend side validate_phone = true or false-->
        <input type="checkbox" #validate_phone="ngModel" [(ngModel)]="validatePhone" name="validate_phone" [hidden]="true">
			</div>
        <div class="personal-info-verify-control" *ngIf="false">
          <div class="verify-btn-container">
            <button i18n="verify_phone_button" class="personal-info-btn verify-btn" *ngIf="!isPhoneVerified" (click)="openModal()" [ngClass]="{'disabled': phone.invalid}" type="button">Verify</button>
            <span i18n="error alert | required verify_phone" *ngIf="!isPhoneVerified && phone.valid" class="item-error">Please verify phone</span>
            <span *ngIf="isPhoneVerified" class="verified-success"></span>
          </div>
        </div>
		  </div>

		  <div class="personal-info-controls">
			<div class="personal-info-control">
			  <label i18n="@@DateOfBirth" for="dob" class="col-form-label">Date of Birth</label>
			  <input i18n-placeholder="@@DateOfBirth" id="datepicker_dob" class="form-control-input" type="text" name="dob" placeholder="Date of Birth" [(ngModel)]="user_info.birth_date" [mbsc-options]="myBirthdaySettings" mbsc-date value="" (change)="onChange($event)">
			</div>

			<div class="personal-info-control">
			  <label i18n="@@IDNumber" for="id_number" class="col-form-label">ID number</label>
			  <input i18n-placeholder="@@IDNumber" (keypress)="isNumber($event)" class="form-control-input" type="text" name="id_number" placeholder="ID number" value="" [(ngModel)]="user_info.identification_number" minlength="7" maxlength="10" #id_number="ngModel" (change)="onChange($event)">
			  <span i18n="error alert | invalid id - minimum 7 characters" *ngIf="id_number.errors?.minlength && id_number.touched" class="item-error">ID Number should have at least 7 characters</span>
			</div>
		  </div>

		  <div class="personal-info-controls">
			<div class="personal-info-control">
				<label i18n="@@Gender" for="gender" class="col-form-label">Gender</label>
				<div class="personal-info-select">
					<select class="form-control-input" name="gender" [(ngModel)]="user_info.gender" id="gender"  #gender="ngModel" required (change)="onChange($event)" placeholder="Gender">
						<option i18n="@@Gender" [value]="null" [selected]="true" disabled>Gender</option>
						<option i18n="@@Male" value="1">Male</option>
						<option i18n="@@Female" value="2">Female</option>
            <option i18n="option label | female" value="0">Other</option>
					</select>
				</div>
				<span i18n="@@PersonalInfoErrorRequiredGender" *ngIf="gender.errors?.required && gender.touched" class="item-error">Please select a gender</span>
			</div>
		  </div>

		</div>

		<!------------------Personal Info: Form submit------------->
		<!-- 				  [disabled]="personalInfoModalForm.invalid" -->
		<div class="personal-info-submit">
		  <button class="personal-info-btn" [disabled]="isChangePhone || isChangeEmail" [ngStyle]="{'background': isChangePhone || isChangeEmail ? '#ff6464bf' : '#FF6465'}"
				  i18n="update profile button"
				  type="submit" id="updateProfile">
			finish
		  </button>
		</div>

	</form>

  <div [@slideIn] *ngIf="isChangePhone">
    <ng-container *ngTemplateOutlet="changePhoneTemplate"></ng-container>
  </div>
  <div [@slideIn] *ngIf="isChangeEmail">
    <ng-container *ngTemplateOutlet="changeEmailTemplate"></ng-container>
  </div>

  <ng-template #changePhoneTemplate >
    <form class="change-info-container" #newPhoneForm="ngForm" [ngStyle]="{'display': showPhoneForm? 'block' : 'none'}">
      <div class="personal-info-wrap-modal change-info-modal">
        <div class="close-modal" (click)="closePhoneModal()">x</div>
        <div class="phone-info-controls" *ngIf="!isShowOtp">
          <div class="phone-control">
            <label i18n="@@Phone" for="phone" class="col-form-label">Phone</label>
              <input appPhoneNumber ng2TelInput i18n-placeholder="placeholder | phone" class="form-control-input" trim="blur" inputmode="tel" type="tel" name="phone" placeholder="New phone" value="" [(ngModel)]="newPhone" #formPhone="ngModel" ngModel (hasError)="setErrorOnphoneNumber(!$event)" (change)="onPhoneChange($event, newPhoneForm, formPhone)"
                    (ng2TelOutput)="setPhoneNumber($event)" (intlTelInputObject)="telInputObject($event, newPhone)" (countryChange)="onCountryChange($event)" [ng2TelInputOptions]="localizationService.getPhoneInputOptions()" />
            <app-error-creator [@slideIn] *ngIf="formPhone.touched && formPhone.invalid && !formPhone.errors?.required" [name]="'phone'" [control]="formPhone"></app-error-creator>
            <span i18n="@@PersonalInfoErrorRequiredPhone" *ngIf="formPhone.errors?.required && formPhone.touched" class="item-error">Please enter a valid phone number</span>
          </div>
        </div>
        <div class="phone-info-controls" [ngStyle]="{'display': isShowOtp? 'flex' : 'none'}">
          <div i18n="Verification section | Phone Number Verification" class="otp-section-title">One Time Verification code <span *ngIf="otpTimer">&nbsp;&nbsp;{{otpTimer}}&nbsp;&nbsp;</span><span style="color: #FFFFFF;">&nbsp;&nbsp;{{testOnlyOtpToken}}&nbsp;&nbsp;</span></div>
          <app-check-otp [showOtp]="isShowOtp" [isShowTimer]="isShowTimer" (timeCounter) = setTimeCounter($event) (sendUserData)="resolved($event,  'changeUserPhone', true)" [(disabbleBtn)]="disabbleBtn"></app-check-otp>
          <div *ngIf="isTimeUp" class="otp-sub-title-section">
            <div class="otp-section-title">Didn't receive SMS? </div>
            <div class="otp-section-sub-title">&nbsp;send again&nbsp;</div>
          </div>
        </div>
        <div *ngIf="!isShowOtp" class="change-email-info-btn" (click)="resolved(null, 'changeUserPhone',false)">Change</div>
      </div>
    </form>
  </ng-template>

  <ng-template #changeEmailTemplate>
    <form class="change-info-container" #newEmailForm="ngForm" [ngStyle]="{'display': showEmailForm? 'block' : 'none'}">
      <div class="personal-info-wrap-modal change-info-modal">
        <div class="close-modal" (click)="closeEmailModal()">x</div>
        <div class="email-info-controls" *ngIf="!isShowOtp">
          <div class="email-control">
            <label i18n="@@Email" for="email" class="col-form-label">Email</label>
            <input i18n-placeholder="placeholder | email" class="form-control-input" type="email" name="email" placeholder="New email" value="" [(ngModel)]="newEmail" [pattern]="emailRegex" #formEmail="ngModel" ngModel inputmode="email">
            <span i18n="@@PersonalInfoErrorInvalidEmail" *ngIf="formEmail.errors?.pattern && formEmail.touched" class="item-error">Please enter a valid email address.</span>
            <span i18n="@@PersonalInfoErrorRequiredEmail" *ngIf="formEmail.errors?.required && formEmail.touched" class="item-error">Email required</span>
          </div>
        </div>
        <div class="email-info-controls" *ngIf="!isShowOtp">
          <div class="email-control">
            <input i18n-placeholder="placeholder | password" class="form-control-input" type="password" name="password" [(ngModel)]="newPassword" placeholder="Current password" #formPassword="ngModel" ngModel required minlength="6">
            <span i18n="@@PersonalInfoErrorRequiredPassword" *ngIf="passwordMissing" class="item-error mt10">Password required.</span>
            <span i18n="@@AccountSettingsPassError" *ngIf="formPassword.dirty && formPassword.invalid" class="item-error">The password must contain at least 6 characters</span>
          </div>
        </div>
        <div class="email-info-controls" [ngStyle]="{'display': isShowOtp? 'flex' : 'none'}">
          <div i18n="Verification section | Phone Number Verification" class="otp-section-title">One Time Verification code <span *ngIf="otpTimer">&nbsp;&nbsp;{{otpTimer}}&nbsp;&nbsp;</span><span style="color: #FFFFFF;">&nbsp;&nbsp;{{testOnlyOtpToken}}&nbsp;&nbsp;</span></div>
          <app-check-otp [showOtp]="isShowOtp" [isShowTimer]="isShowTimer" (timeCounter) = setTimeCounter($event) (sendUserData)="resolved($event,   'changeUserEmail', true)" [(disabbleBtn)]="disabbleBtn"></app-check-otp>
          <div *ngIf="isTimeUp" class="otp-sub-title-section">
            <div class="otp-section-title">Didn't receive SMS? </div>
            <div class="otp-section-sub-title">&nbsp;send again&nbsp;</div>
          </div>
        </div>
        <div *ngIf="!isShowOtp" class="change-email-info-btn" (click)="resolved(null, 'changeUserEmail',false)" >Change</div>
      </div>
    </form>
  </ng-template>


</div>
