import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray} from '@angular/forms';
import {EventService} from '../../../services/event.service';
import {DialogService} from '../../../services/dialog.service';



@Component({
  selector: 'app-custom-fields-dialog',
  templateUrl: './custom-fields-dialog.component.html',
  styleUrls: ['./custom-fields-dialog.component.css']
})
export class CustomFieldsDialogComponent implements OnInit {
  form_inputs;
  customInputsForm: UntypedFormGroup;
  customInput: UntypedFormArray;
  types = [
    { id: 0, name: 'text' },
    { id: 1, name: 'radio' },
    { id: 2, name: 'checkbox' },
    { id: 3, name: 'select' }
  ];
  customInputsInitCountWithID = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private fb: UntypedFormBuilder,
              private eventService: EventService,
              private dialogService: DialogService,
              private dialogRef: MatDialogRef<CustomFieldsDialogComponent>) { }

  ngOnInit(): void {
    //console.log("MAT_DIALOG_DATA", this.data);
    this.customInputsForm = this.fb.group({});
    this.data.cmsPage ? this.getCMSFormInputs() : this.getCustomInputs();

  }

  getCustomInputs() {
    this.eventService.getCustomInputs(this.data.id).subscribe(
        (response: any) => {
          if (response) {
            this.form_inputs = response;
            //console.log("getEventDraft", response);
          } else {
            this.form_inputs = {custom_inputs: []}
          }
          this.customInputsForm = this.createFormControls();
          this.customInputsInitCountWithID = this.getCustomInputsCount().idCount;

          // if (response.custom_inputs.length === 0) {
          //   this.addCustomInput();
          // }

        },
        (error: any) =>  {
          console.log(error);
        });
  }

  getCMSFormInputs() {
    this.eventService.getPaymentFormData(this.data.id).subscribe(
      (response: any) => {
        if (response.data) {
          this.form_inputs = response.data;
          // console.log("getPaymentFormData", response.data);
        } else {
          this.form_inputs = {custom_inputs: []}
        }
        this.customInputsForm = this.createFormControls();
        this.customInputsInitCountWithID = this.getCustomInputsCount().idCount;
        // if (response.data.custom_inputs.length === 0) {
        //   this.addCustomInput();
        // }
        // console.log(this.customInputsForm);
      },
      (error: any) =>  {
        console.log(error);
      });
  }
  addCustomInput() {
		this.customInput = this.customInputsForm.get('custom_inputs') as UntypedFormArray;
		this.customInput.push(this.createCustomFormControls());
	}

  createFormControls() {
		const group = this.fb.group({});
		group.addControl('custom_inputs', this.createCustomFormControls(this.form_inputs.custom_inputs));
		return group;
	}

  createCustomFormControls(customInputs = undefined): UntypedFormArray | UntypedFormGroup {
		const array = this.fb.array([]);
		if (customInputs) {
			customInputs.forEach(control => {
				const group = this.fb.group({});
				group.addControl('id', this.fb.control(control.id ? control.id : 'new', Validators.required));
				group.addControl('title', this.fb.control(control.title,[Validators.required, Validators.maxLength(50)]));
				group.addControl('type', this.fb.control(control.type, Validators.required));
				group.addControl('required', this.fb.control(control.required === true));
				group.addControl('opinions', this.createCustomInputOptions(control.opinions));
				group.addControl('blocked', this.fb.control(control.blocked, Validators.required));

				array.push(group);
			});
			return array;
		} else {
			const group = this.fb.group({});
			group.addControl('id', this.fb.control('new', Validators.required));
			group.addControl('title', this.fb.control('',[Validators.required, Validators.maxLength(50)]));
			group.addControl('type', this.fb.control('', Validators.required));
			group.addControl('required', this.fb.control(false));
			group.addControl('opinions', this.createCustomInputOptions([]));
			group.addControl('blocked', this.fb.control(false, Validators.required));

			this.form_inputs.custom_inputs.push({
				type: 'text',
				required: false,
				title: '',
				opinions: [],
        blocked: false
			});
			return group;
		}
	}

	createCustomInputOptions(options = undefined, index = undefined, opinion_number = undefined): UntypedFormArray | UntypedFormGroup{
		const array = this.fb.array([]);
		if (options) {
			options.forEach(option => {
				const group = this.fb.group({});
				group.addControl('id', this.fb.control(option.id ? option.id : 'new', Validators.required));
				group.addControl('number', this.fb.control(option.number, Validators.required));
				group.addControl('title', this.fb.control(option.title,[Validators.required, Validators.maxLength(1500)]));
				array.push(group);
			});
			return array;
		} else {
			const group = this.fb.group({});
			group.addControl('id', this.fb.control('new', Validators.required));
			group.addControl('number', this.fb.control((opinion_number + 1), Validators.required));
			group.addControl('title', this.fb.control('',[Validators.required, Validators.maxLength(1500)]));
			this.form_inputs.custom_inputs[index].opinions.push({
				title: ''
			});

			return group;
		}
	}


  addCustomInputOption(i, opinion_number) {
    this.customInput = this.customInputsForm.get('custom_inputs')['controls'][i]['controls']['opinions'] as UntypedFormArray;
		this.customInput.push(this.createCustomInputOptions(undefined, i, opinion_number));
  }

  removeCustomInputOption(main_index, secondaryIndex) {
    this.customInput = this.customInputsForm.get('custom_inputs')['controls'][main_index]['controls']['opinions'] as UntypedFormArray;
		this.customInput.removeAt(secondaryIndex);
		this.form_inputs.custom_inputs[main_index].opinions.splice(secondaryIndex, 1);
	}

  removeCustomInput(i) {
    this.customInput = this.customInputsForm.get('custom_inputs') as UntypedFormArray;
		this.customInput.removeAt(i);
		this.form_inputs.custom_inputs.splice(i, 1);
  }

  changeType(type, i, opinions) {
    if (type.value === '') {
      return
    }

    if (type.value === 'text') {
     this.customInputsForm.get('custom_inputs')['controls'][i]['controls']['opinions'].clear();
     this.form_inputs.custom_inputs[i].opinions = [];
    } else {
      if (!opinions.length) {
        this.addCustomInputOption(i, opinions.length);
      }
    }

    if (type.options[0].value === ''){
      type.remove(0);
    }
  }

  getCustomInputsCount() {
    if (this.form_inputs === undefined) return {blockedCount: 0, newCount: 0, idCount: 0}

    const blockedCount = this.form_inputs.custom_inputs.filter((i) => {
      return (i.hasOwnProperty('blocked') && i.blocked);
    });
    const newCount = this.form_inputs.custom_inputs.filter( (i) => {
      return !( i.hasOwnProperty('id') );
    });
    const idCount = this.form_inputs.custom_inputs.filter( (i) => {
      return i.hasOwnProperty('id');
    });

    return {
      blockedCount: blockedCount.length,
      newCount: newCount.length,
      idCount: idCount.length
    }

  }

  isShowSave() {
    const {blockedCount, newCount, idCount} = {...this.getCustomInputsCount()};
    // console.log(blockedCount, newCount, idCount, this.customInputsInitCountWithID);
    if (newCount || this.data.cmsPage) {
      return true
    }
    if (blockedCount && blockedCount === idCount && this.customInputsInitCountWithID === idCount && !newCount) {
      return false
    }
    if (!blockedCount && !newCount && !idCount && !this.customInputsInitCountWithID) {
      return false
    }
    if (!blockedCount && !newCount && !idCount && this.customInputsInitCountWithID) {
      return true
    }
    if (blockedCount && (this.customInputsInitCountWithID > idCount || this.customInputsInitCountWithID !== idCount)) {
      return true
    } else {
      return true
    }
  }

  close() {

    if (this.customInputsForm.value && Object.keys(this.customInputsForm.value).length === 0) {
      //console.log("close1", this.customInputsForm.value)
      this.dialogRef.close();
    } else {
      //console.log("close2", this.customInputsForm.value)
      const form_inputs = this.prepareNewControls(this.customInputsForm);
      const data = this.customInputsForm.valid ? form_inputs : [];
      this.dialogRef.close(data);
    }
  }

  prepareNewControls(form) {
    form.get('custom_inputs')['controls'].forEach((c_input, i) => {

    //console.log(c_input,i)

    // If new ID field detected
    if (c_input.contains('id') && c_input.controls['id'].value === 'new') {
      // console.log("REMOVE ID",c_input, i);
      c_input.removeControl('id');
      // this.customInputsForm.get('custom_inputs')['controls'][i].removeControl('id');
      // console.log("REMOVED ID",this.customInputsForm.get('custom_inputs')['controls'][i]);
    }

    // If new Opinion field detected
    c_input.controls['opinions']['controls'].forEach((opinion, k) => {
      if (opinion.contains('id') && opinion.controls['id'].value === 'new') {
        // console.log("REMOVE OPINION", opinion, k);
        opinion.removeControl('id');
        // this.customInputsForm.get('custom_inputs')['controls'][i]['controls']['opinions']['controls'][k]['controls'].removeControl('id');
        // console.log("REMOVED OPINION",this.customInputsForm.get('custom_inputs')['controls'][i]);
      }
    });

  });
    return form;
  }

  onSubmit() {
    //console.log("customInputsForm", this.customInputsForm.valid, this.customInputsForm);
    if (!this.customInputsForm.valid) {
      this.customInputsForm.markAllAsTouched();
      return
    }
    const form_inputs = this.prepareNewControls(this.customInputsForm);
    const data = {
      ...this.form_inputs
    }

    data.custom_inputs = form_inputs.value.custom_inputs;

    this.data.cmsPage ? this.setEventInputs(data, form_inputs) : this.setCustomInputs(data, form_inputs);

	}

  // *** For EDIT EVENT PAGE ***
  setCustomInputs(data, form_inputs) {
    //console.log("setEventDraftdata", {main: data});
    this.eventService.setCustomInputs(this.data.id, data).subscribe(
      (response: any) => {

        this.dialogRef.close(form_inputs);
        if (response.success) {
          this.dialogService.alertsModal('success', 'success', 5000, true);
        }
        if (response.error) {
          this.dialogService.alertsModal('editEvent', response.error, 5000, false);
        }
      },
      error => {
        this.dialogService.alertsModal('editEvent', error.code, 5000, false);
      }
    );
  }

  // *** cms-set-form-inputs For CMS pages ***
  setEventInputs(data, form_inputs) {
    data['event_id'] = this.data.id;
    //console.log("setEventInputsdata", data)
    this.eventService.setEventInputs(data).subscribe(
      (response: any) => {
        if (response.success) {
          this.dialogService.alertsModal('success', 'success', 5000, true);
          this.dialogRef.close();
        }
        if (response.error) {
          this.dialogService.alertsModal('editEvent', response.error, 5000, false);
        }
      },
      error => {
        this.dialogService.alertsModal('editEvent', error.code, 5000, false);
      }
    );
  }

}
