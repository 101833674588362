<div *ngIf="completed && fetched">
  <img style="margin: 80px auto 40px;" class="img-responsive successTag" src="/assets/images/svg-icons/order-success.svg">
  <h3 style="color: #fff;text-align: center;margin-top: 0px;">
    Stripe account successfully activated!
  </h3>
</div>
<div *ngIf="!completed && fetched">
  <h3
    style="color: #fff; text-align: center;margin-top: 80px;">
    Your account is being verified! <br>
    Please try again later.
  </h3>
</div>
<div *ngIf="error">
  <h3 style="text-align: center; color: #FF6465; margin-top: 80px;">An error has occurred.</h3>
  <div style="display: flex; justify-content: center;">
    <a style="color: #fff; text-align: center; margin: 5px 15px;" i18n="footer menu | Home" href="javascript:void(0);" [routerLink]="['/']">Home</a>
    <a style="color: #fff; text-align: center; margin: 5px 15px;" i18n="sidenav|my events" [routerLink]="['/dashboard']">My Events</a>
  </div>
</div>

