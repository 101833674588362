import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {GlobalsService} from '../../globals';
import {EventService} from '../../services/event.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogService} from '../../services/dialog.service';
import {
  RecaptchaEnterpriseService
} from "../../services/recaptcha-enterprise.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-contact-producer',
  templateUrl: './contact-producer.component.html',
  styleUrls: ['./contact-producer.component.css']
})
export class ContactProducerComponent implements OnInit, OnDestroy {

  contactProducerForm = new UntypedFormGroup({
    subject: new UntypedFormControl('',[Validators.required]),
    description: new UntypedFormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(1000)]),
    g_recaptcha_response: new UntypedFormControl('', [Validators.required]),
  });
  subjects = [
    {
      id: 1,
      name: {
        he: 'רוצה לברר עוד פרטים על האירוע',
        en: 'Question about the event',
        es: 'Pregunta sobre el evento'
      }
    },
    {
      id: 2,
      name: {
        he: 'רוצה לברר פרטים על ההזמנה שלי',
        en: 'Question about my ticket ',
        es: 'Pregunta sobre mi boleto'
      }
    },
  ];
  captchaV3TokenSubscription: Subscription;
  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private dialogRef: MatDialogRef<ContactProducerComponent>,
              private globals: GlobalsService,
              private eventService: EventService,
              private dialogService: DialogService,
              private recaptchaService: RecaptchaEnterpriseService) { }

  ngOnInit(): void {
   // console.log(this.data);
    this.recaptchaService.render('recaptcha_contactproducer','user_question_for_producer').then(() => {
    });
    this.captchaV3TokenSubscription = this.recaptchaService.token.subscribe(token => {
      this.contactProducerForm.get('g_recaptcha_response').setValue(token); // set token to input field
    })
  }

  submit() {
    this.contactProducerForm.markAllAsTouched();
    // console.log(this.contactProducerForm.value);
    // console.log(this.contactProducerForm.valid);
    // console.log(this.contactProducerForm);
    if (!this.contactProducerForm.valid) {return}

    this.globals.startLoader();
    this.eventService.sendProducerEmail(this.data.event_id, this.contactProducerForm.value).subscribe((res: any) => {
      this.dialogRef.close(res);
      if (res.code === 5) {
        this.dialogService.alertsModal('success', 'messageSentWarning5', 8000, true);
      } else if (res.code === 6) {
        this.dialogService.alertsModal('success', 'messageSentWarning6', 8000, true);
      }
      else {
        this.dialogService.alertsModal('success', 'messageSent', 3000, true);
      }
      this.globals.stopLoader();
    }, error => {
      this.recaptchaService.reset().then(() => { // reset badge
        this.recaptchaService.setToken('');
      })
      if (error.code === 4.1) {
        this.dialogService.alertsModal('errors', error.code, 6000, false);
      } else {
        this.dialogService.alertsModal('sendProducerEmail', error.code, 6000, false);
      }

      this.globals.stopLoader();
    })

  }

  ngOnDestroy() {
    if (this.captchaV3TokenSubscription) {
      this.captchaV3TokenSubscription.unsubscribe();
    }
  }

}
