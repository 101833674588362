import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class PasskeyService {
  api_base_url = environment.api_base_url + '/api/web-authn/';
  constructor(private http: HttpClient,
              private authService: AuthService) { }

  connectGET(user_info: any = {}) {
    let httpParams = this.initParams( user_info);
    let token = this.authService.getToken();
    return this.http.get(this.api_base_url + `connect/user-registration/args`, {
      headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest', 'authorization': `Bearer ${token}`}),
      params: httpParams
    });
  }
  connectPOST(data, user_info) {
    let httpParams = this.initParams( user_info);
    let token = this.authService.getToken();
    return this.http.post(this.api_base_url + `connect/user-registration/save`, data,
      {
        headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest', 'authorization': `Bearer ${token}`}),
        params: httpParams
      });
  }

  deleteAllRegistrations(user_info) {
    let httpParams = this.initParams(user_info);
    return this.http.post(this.api_base_url + `connect/user-registration/delete-all`, {},
      {
        headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'}),
        params: httpParams
      });
  }

  checkGET(user_info) {
    let httpParams = this.initParams(user_info);
    return this.http.get(this.api_base_url + `connect/user-login/args`, {
      headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'}),
      params: httpParams
    });
  }

  checkPOST(data, user_info) {
    let httpParams = this.initParams(user_info);
    return this.http.post(this.api_base_url + `connect/user-login/check`, data,
      {
        headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'}),
        params: httpParams
      });
  }

  initParams( user_info: any = {}) {
    let httpParams = new HttpParams();

    // user verification - required or preferred or discouraged
    httpParams = httpParams.append('userVerification', 'discouraged');

    // hash
    if(user_info && user_info.hasOwnProperty('pass_key_identifier')) {
      httpParams = httpParams.append('userId', user_info.pass_key_identifier);
    }

    if(user_info && user_info.hasOwnProperty('first_name')) {
      httpParams = httpParams.append('userName', user_info.first_name);

      if(user_info.hasOwnProperty('last_name')) {
        httpParams = httpParams.append('userDisplayName', user_info.first_name + ' ' + user_info.last_name);
      }
    }

    httpParams = httpParams.append('rpId', '');
    // type of authenticator
    httpParams = httpParams.append('type_usb', '1');
    httpParams = httpParams.append('type_nfc', '1');
    httpParams = httpParams.append('type_ble', '1');
    httpParams = httpParams.append('type_int', '1');
    httpParams = httpParams.append('type_hybrid', '1');

    // Use Client-side discoverable Credentials / Passkeys
    httpParams = httpParams.append('requireResidentKey', '0');

    // attestation statement format
    httpParams = httpParams.append('fmt_android-key', '1');
    httpParams = httpParams.append('fmt_android-safetynet', '1');
    httpParams = httpParams.append('fmt_apple', '1');
    httpParams = httpParams.append('fmt_fido-u2f', '1');
    httpParams = httpParams.append('fmt_none', '1');
    httpParams = httpParams.append('fmt_packed', '1');
    httpParams = httpParams.append('fmt_tpm', '1');
    //attestation statement format
    httpParams = httpParams.append('apple', '0');
    httpParams = httpParams.append('yubico', '0');
    httpParams = httpParams.append('solo', '0');
    httpParams = httpParams.append('hypersecu', '0');
    httpParams = httpParams.append('google', '0');
    httpParams = httpParams.append('microsoft', '0');
    httpParams = httpParams.append('mds', '1');
    //attestation root certificates
    httpParams = httpParams.append('cert_apple', 0);
    httpParams = httpParams.append('cert_yubico', 0);
    httpParams = httpParams.append('cert_solo', 0);
    httpParams = httpParams.append('cert_hypersecu', 0);
    httpParams = httpParams.append('cert_google', 0);
    httpParams = httpParams.append('cert_microsoft', 0);
    httpParams = httpParams.append('cert_mds', 0);
    return httpParams
  }

  /**
   * convert RFC 1342-like base64 strings to array buffer
   * @param {mixed} obj
   * @returns {undefined}
   */
  recursiveBase64StrToArrayBuffer(obj) {
    let prefix = '=?BINARY?B?';
    let suffix = '?=';
    if (typeof obj === 'object') {
      for (let key in obj) {
        if (typeof obj[key] === 'string') {
          let str = obj[key];
          if (str.substring(0, prefix.length) === prefix && str.substring(str.length - suffix.length) === suffix) {
            str = str.substring(prefix.length, str.length - suffix.length);

            let binary_string = window.atob(str);
            let len = binary_string.length;
            let bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++)        {
              bytes[i] = binary_string.charCodeAt(i);
            }
            obj[key] = bytes.buffer;
          }
        } else {
          this.recursiveBase64StrToArrayBuffer(obj[key]);
        }
      }
    }
  }

  /**
   * Convert a ArrayBuffer to Base64
   * @param {ArrayBuffer} buffer
   * @returns {String}
   */
  arrayBufferToBase64(buffer) {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa(binary);
  }
}
