import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavbarService } from '../../services/navbar.service';

@Component({
  selector: 'app-cms-events',
  templateUrl: './cms-events.component.html',
  styleUrls: ['./cms-events.component.css']
})
export class CmsEventsComponent implements OnInit, OnDestroy {
  events_user_id = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    public nav: NavbarService
  ) {
    this.nav.customWrapperClass = 'full-screen';
  }

  ngOnInit() {
    if (this.activatedRoute.params) {
      this.activatedRoute.params.subscribe(params => {
        this.events_user_id = params['user_id'];
      });
    }
  }

  ngOnDestroy() {
    this.nav.customWrapperClass = '';
  }
}
