<div class="bank-info-modal">
  <div class="bank-info-modal-header">
    <button type="button" (click)="close()">
      <img src="/assets/images/svg-icons/close.svg" alt="close">
    </button>
  </div>
  <form [formGroup]="bank_info" (ngSubmit)="onSubmit(bank_info.value)" class="bank-info-form" (keydown.enter)="$event.preventDefault()">
    <p class="bank-info-label">{{'finance.ChooseCountry' | translate:'Choose country'}}</p>
    <div class="inputs-wrap country">

        <mat-select-country appearance="fill" style="width: 100%;" label="{{'finance.Country' | translate:'Country'}}" [value]="defaultCountry" [disabled]="stripeConnectCompleted || bank_info.get('user_plaid_id').value"
          (onCountrySelected)="onCountrySelected($event)" [itemsLoadSize]="8" [countries]="stripeConnectDisabled ? predefinedCountries : predefinedStripeConnectAvailableCountries"
          autocomplete="country">
        </mat-select-country>
        
        <app-error-creator [control]="country" [name]="'country'" [class]="'fs10'" *ngIf="country.touched"></app-error-creator>
    </div>

<!--    <div class="inputs-wrap city" *ngIf="(selectedCountry && selectedCountry.alpha2Code == 'US')-->
<!--    && bankDetailsCollectionMethod === 'manual' && !stripeConnectNotSupportedCountry && (!stripeConnectCompleted && !bank_info.get('user_plaid_id').value)">-->
<!--      <mat-form-field style="width: 100%;" appearance="fill">-->
<!--        <mat-label>{{'finance.City' | translate:'City'}}</mat-label>-->
<!--        <input type="text" matInput [formControl]="city" [matAutocomplete]="auto">-->
<!--        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="citySelected($event.option.value)">-->
<!--          <mat-option value="" disabled>None</mat-option>-->
<!--          <mat-option *ngFor="let option of filteredUSCities | async" [value]="option">-->
<!--            {{option}}-->
<!--          </mat-option>-->
<!--        </mat-autocomplete>-->
<!--        <app-error-creator [control]="city" [name]="'city'" [class]="'fs10'" *ngIf="city.touched"></app-error-creator>-->
<!--      </mat-form-field>-->

<!--      <mat-form-field  style="width: 100%;" appearance="fill">-->
<!--        <mat-label>{{'finance.bankName' | translate:'Bank Name'}}</mat-label>-->
<!--        <input matInput [formControl]="bank_name">-->
<!--        <app-error-creator [control]="bank_name" [name]="'bank_name'" [class]="'fs10'" *ngIf="bank_name.touched"></app-error-creator>-->
<!--      </mat-form-field>-->

<!--    </div>-->

    <div class="inputs-wrap bank_id" *ngIf="bankDetailsCollectionMethod === 'manual' && !stripeConnectNotSupportedCountry
    && selectedCountry && (selectedCountry.alpha2Code == 'IL') && !stripeConnectPending">
      <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>{{'finance.bankName' | translate:'Bank Name'}}</mat-label>
        <mat-select [formControl]="bank_id">
          <mat-option value="" disabled>None</mat-option>
          <mat-option *ngFor="let bank of bankIds;" [value]="bank.id">{{bank.name[globals.getLocaleId()]}}</mat-option>
        </mat-select>
      </mat-form-field>
      
      <app-error-creator [control]="bank_id" [name]="'bank_id'" [class]="'fs10'" *ngIf="bank_id.touched"></app-error-creator>
    </div>

    <ng-container *ngIf="(!stripeConnectCompleted && !stripeConnectPending)">
      <ng-container *ngIf="(bankDetailsCollectionMethod === 'manual') && !stripeConnectNotSupportedCountry && (selectedCountry && selectedCountry.alpha2Code !== '') && (!stripeConnectCompleted && !bank_info.get('user_plaid_id').value)">
        <p class="bank-info-label">{{'finance.BankAccount' | translate:'Bank Account'}}</p>
        <div class="inputs-wrap">
          <mat-form-field appearance="fill" style="margin-right: 10px;">
            <mat-label>{{'finance.BranchNumber' | translate:'Branch number'}}</mat-label>
            <input matInput [formControl]="branch">
            <app-error-creator [control]="branch" [name]="'branch'" [class]="'fs10'" *ngIf="branch.touched"></app-error-creator>
          </mat-form-field>
          <mat-form-field appearance="fill" style="margin-right: 10px;">
            <mat-label>{{'finance.AccountNumber' | translate:'Account number'}}</mat-label>
            <input matInput [formControl]="account_number">
            <app-error-creator [control]="account_number" [name]="'account_number'" [class]="'fs10'"
                               *ngIf="account_number.touched"></app-error-creator>
          </mat-form-field>
          <mat-form-field appearance="fill" style="margin-right: 10px;">
            <mat-label>{{'finance.AccountOwnerName' | translate:'Account owner\'s name'}}</mat-label>
            <input matInput [formControl]="account_name">
            <app-error-creator [control]="account_name" [name]="'account_name'" [class]="'fs10'"
                               *ngIf="account_name.touched"></app-error-creator>
          </mat-form-field>
          <mat-form-field appearance="fill" style="margin-right: 10px;">
            <mat-label>{{'finance.IDorCompanyNumber' | translate:'ID or Company Number'}}</mat-label>
            <input matInput [formControl]="company_user_id">
            <app-error-creator [control]="company_user_id" [name]="'company_user_id'" [class]="'fs10'"
                               *ngIf="company_user_id.touched"></app-error-creator>
          </mat-form-field>
<!--          <mat-form-field appearance="fill" style="margin-right: 10px;" *ngIf="country.value == 'us' && !user_plaid_id.value">-->
<!--            <mat-label>{{'finance.ABANumber' | translate:'ABA Number'}}</mat-label>-->
<!--            <input matInput [formControl]="aba_number">-->
<!--            <app-error-creator [control]="aba_number" [name]="'aba_number'" [class]="'fs10'"-->
<!--                               *ngIf="aba_number.touched"></app-error-creator>-->
<!--          </mat-form-field>-->
<!--          <mat-form-field appearance="fill" style="width: 100%;" *ngIf="country.value == 'us'">-->
<!--            <mat-label>{{'finance.LivingAddress' | translate:'Living Address'}}</mat-label>-->
<!--            <input matInput [formControl]="address">-->
<!--            <app-error-creator [control]="address" [name]="'address'" [class]="'fs10'"-->
<!--                               *ngIf="address.touched"></app-error-creator>-->
<!--          </mat-form-field>-->
<!--          <mat-form-field appearance="fill" style="margin-right: 10px;" *ngIf="country.value == 'us'">-->
<!--            <mat-label>{{'finance.PhoneNumber' | translate:'Phone Number'}}</mat-label>-->
<!--            <input matInput [formControl]="recipient_phone">-->
<!--            <app-error-creator [control]="recipient_phone" [name]="'recipient_phone'" [class]="'fs10'"-->
<!--                               *ngIf="recipient_phone.touched"></app-error-creator>-->
<!--          </mat-form-field>-->

        </div>
      </ng-container>
    </ng-container>

      <div class="inputs-wrap" *ngIf="country.value === 'other' && (!stripeConnectCompleted && !stripeConnectPending)">
        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label>{{'finance.Bankdetails' | translate:'Bank details'}}</mat-label>
          <textarea matInput [formControl]="other_bank_details" rows="5"></textarea>
          <app-error-creator [control]="other_bank_details" [name]="'other_bank_details'" [class]="'fs10'" *ngIf="other_bank_details.touched"></app-error-creator>
        </mat-form-field>
      </div>
      <ng-container *ngIf="selectedCountry && (selectedCountry.alpha2Code == 'US' || selectedCountry.alpha2Code == 'CA') && data.plaid.check_connect">
        <div style="display: flex; justify-content: center;">
          <div *ngIf="!bank_info.get('user_plaid_id').value && !stripeConnectPending && !stripeConnectCompleted && data.plaid.check_connect"> <span class="connect-via-plaid">{{'finance.ConnectVia' | translate:'Connect via'}}</span>
            <button type="button" class="plaid-btn" title="Connect Bank via Plaid" [disabled]="plaidService.fetching.getValue()" (click)="connectPlaid()"></button>
          </div>
          <button type="button" *ngIf="bank_info.get('user_plaid_id').value" title="Disconnect Plaid" class="plaid-disconnect-btn" (click)="disconnectPlaid()">Reconnect Bank</button>
        </div>
<!--        <button type="button" (click)="TESTsetPlaidTokenExchangeSubj()">TEST setPlaidTokenExchangeSubj</button>-->

        <input type="hidden" id="user_plaid_id" [formControl]="user_plaid_id" placeholder="user_plaid_id">

      </ng-container>
      <div class="stripe-connect-container"
        *ngIf="((!stripeConnectNotSupportedCountry && (bankDetailsCollectionMethod === 'stripe' || stripeConnectAccountLink) &&
        !bank_info.get('user_plaid_id').value) || (!stripeConnectDisabled && !bank_info.get('user_plaid_id').value)) && (selectedCountry && selectedCountry.alpha2Code && this.stripeRequestCountries.has(selectedCountry.alpha2Code))">
        <button type="button" mat-button class="btn stripe-connect" (click)="onStripeConnectClick()"
                *ngIf="!(data.data && data.data.hasOwnProperty('stripe_connect_request') && data.data.stripe_connect_request === false)
                && selectedCountry && selectedCountry.alpha2Code">
          <span *ngIf="!stripeConnectAccountLink" i18n>Connect with</span>
          <span *ngIf="stripeConnectAccountLink && !stripeConnectCompleted" i18n>Continue Connecting with</span>
          <span *ngIf="stripeConnectAccountLink && stripeConnectCompleted" i18n>Dashboard</span>
          <img src="./assets/images/svg-icons/stripe-blurple.svg" alt="Stripe" style="height: 40px;">
        </button>

        <!-- // TODO: add to terms checkbox by stripe account type -->
        <span class="stripe-agreement" *ngIf="!stripeConnectCompleted">By registering your account, you agree to our Services Agreement, the <a
            href="https://stripe.com/connect-account/legal/full" target="_blank">Stripe Connected Account Agreement</a> and <a
          href="https://stripe.com/connect-account/legal/recipient" target="_blank">Stripe Recipient Agreement</a>.</span>
      </div>
      
      <div *ngIf="(bankDetailsCollectionMethod === 'manual' && stripeConnectNotSupportedCountry) || stripeConnectCompleted && selectedCountry">
        <button i18n mat-button class="btn-gray support" (click)="onContactSupportClick('no_country')" type="button" style="width: 100%;">
          <img src="/assets/images/svg-icons/menu-support2.svg" alt="support">
          {{'finance.ContactCustomerSupport' | translate:'Contact customer support'}}
        </button>
      </div>
    <div *ngIf="country.value === 'other'">
      <button class="btn-gray req-method" (click)="onContactSupportClick('no_country')" type="button">
        <img src="/assets/images/svg-icons/menu-support2.svg" alt="support">
        {{'finance.Requestapayoutmethod' | translate:'Request a payout method'}}
      </button>
    </div>


    <div *ngIf="!data.bank && !stripeConnectCompleted && !stripeConnectPending && stripeConnectDisabled" class="mat-bank-terms">
      <mat-checkbox [formControl]="terms"></mat-checkbox>
      <span style="font-size: 14px;">{{'finance.IAccept' | translate:'I accept'}} <a class="bank-info-terms" href="https://intercom.help/getin-93198e5c33cd/en/collections/3932185-event-payout-terms" target="_blank">{{'finance.termOfPayout' | translate:'term of payout'}}</a></span>
    </div>
    <div *ngIf="bankDetailsCollectionMethod === 'manual'">
      <button class="btn support" type="button" (click)="onContactSupportClick()">
        <img src="/assets/images/svg-icons/menu-support2.svg" alt="support">
        {{'finance.Help' | translate:'Help'}}
      </button>
    </div>
    <button *ngIf="(bankDetailsCollectionMethod === 'manual' && selectedCountry && (selectedCountry.alpha2Code == 'IL' || selectedCountry.alpha2Code == 'other' || selectedCountry.alpha2Code == ''))
|| ( !(bankDetailsCollectionMethod === 'manual' && selectedCountry && (selectedCountry.alpha2Code == 'IL' || (selectedCountry.alpha2Code == 'other' || selectedCountry.alpha2Code == ''))) && stripeConnectDisabled)" type="submit" class="save" [disabled]="!isValid()">
      <span>{{'finance.Save' | translate:'Save'}}</span>
<!--      <span *ngIf="stripeConnectDisabled && selectedCountry && (selectedCountry.alpha2Code == 'CA' || selectedCountry.alpha2Code == 'US') && !data?.data.stripe_connect_request">{{'finance.RequestStripeAccount' | translate:'Request Stripe Account'}}</span>-->
    </button>


  </form>
</div>
