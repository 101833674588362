import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReaderIsLoggedInAuthGuardService implements CanActivate {
  constructor(public authService: AuthService, public router: Router) {}
  canActivate(): boolean {
    if(this.authService.getReaderApiKey()){
      const readerId = this.authService.getCurrentConnectedReader();
      this.router.navigate(['/reader/' + readerId]);
      return false;
    }
    return true;
  }
}