import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { DialogService } from '../../../services/dialog.service';
import { environment } from '../../../../environments/environment';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-quick-links-modal',
  templateUrl: './quick-links-modal.component.html',
  styleUrls: ['./quick-links-modal.component.css']
})
export class QuickLinksModalComponent implements OnInit {

  api_base_url: string = environment.api_base_url;
  getin_base_url: string = environment.getin_base_url;
  url_element = '';
  links_ob = []
  constructor(
    private clipboardService: ClipboardService,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    this.url_element = this.data.url_friendly ? this.data.url_friendly : this.data.event_id;
  }
  copyLink(url) {
    // document.addEventListener('copy', (e: ClipboardEvent) => {
    //   e.clipboardData.setData('text/plain', url);
    //   e.preventDefault();
    // }, { passive: true });
    // if (document.execCommand('copy')) {
    //   this.dialogService.alertsModal('success', 'success', 2000, true);
    // }
    this.clipboardService.copyFromContent(url);
    this.clipboardService.destroy();
    this.dialogService.alertsModal('success', 'couponCopied', 2000, true);
  }

}
