import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReaderAuthGuardService implements CanActivate {
  constructor(public authService: AuthService, public router: Router) {}
  canActivate(): boolean {
    if(!this.authService.getReaderApiKey()){
      this.router.navigate(['/reader-signin']);
      return false;
    }
    return true;
  }
}