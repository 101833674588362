    <div class="col-md-12 col-xs-12" style="background-color: white;color: #000;">
      <form (ngSubmit)="onSubmit()" #approveEventForm="ngForm">
      <div class="modal-header">
          <button type="button" class="close" (click)="dialogRef.close()">
              <span aria-hidden="true">&times;</span>
            </button>
      <div class="text-center"><h4 i18n="@@CmsApproveEventModalTitle">
        {apiAction, select,
          approveEvent {Event approval} updateCommission {Update fees}
        }</h4></div>
      </div>
        <div class="modal-body">
          <div class="row" style="line-height: 1.2;">
            <div class="col-xs-9" *ngIf="event.address"><i class="fa fa-globe"></i> {{event.address}}</div>
            <div class="col-xs-3" *ngIf="event.currency"><i class="fa fa-money"></i> {{event.currency}}</div>
          </div>
          <div class="row mb5">
            <div class="col-md-12">
                <span class="item-error"><i class="fa fa-exclamation-circle item-error"></i>
                <span i18n="@@CmsApproveEventModalDisclaimer">The specified percentage will be deducted during clearance, please make sure to include VAT</span>
                </span>
          </div>
        </div>

        <div class="row mb5">

          <div class="form-group col-md-12 col-xs-12" style="box-shadow: 0px 0px 1px;">
            <label class="selected-gateway-label">Fee Template:</label>
            <div style="font-size: 12px;">
              <p *ngIf="event.fee_template_configuration?.template">
                <strong>Name: </strong>
                <span class="link-bnt" (click)="openFeeManagmentDialog('preview', event.fee_template_configuration.template)">
                  {{event.fee_template_configuration.template.name}}
                </span>
              </p>
              <p *ngIf="selectedFeeTemplate">
                <strong>Selected Fee Template: </strong>
                <span class="link-bnt" (click)="openFeeManagmentDialog('preview', selectedFeeTemplate)">
                  {{selectedFeeTemplate.name}}
                </span>
              </p>
              <p>
                <select class="form-control" name="feeTemplates" (change)="changeFeeTemplate($event)" [(ngModel)]="selectedFeeTemplateId">
                  <option value="" disabled>No Template</option>
                  <option *ngFor="let t of (feeTemplates | async)" value="{{t.id}}">{{t.name}}</option>
                </select>
              </p>
            </div>

            <div class="checkbox-group">
              <label for="auto_approve_in_the_future">Auto Approve In The Future:</label>
              <div>
                <input type="checkbox" name="auto_approve_in_the_future" id="auto_approve_in_the_future"
                       style="margin: 4px;"
                       [checked]="event.auto_approve_in_the_future === 1 ? true : false"
                       (change)="changeAutoApproveInTheFuture()">&nbsp;
              </div>
              <div *ngIf="event.auto_approve_in_the_future">
                <label for="auto_approve_in_the_future">Auto Approve Template Name:</label>
                <input class="form-control mb5" type="text" name="auto_approve_template_name" [(ngModel)]="event.auto_approve_template_name" id="auto_approve_template_name">
              </div>
            </div>
          </div>

          <div class="form-group col-md-6  col-xs-12">
            <div class="form-group checkbox-group">
              <div class="box-alignment">
                <input type="checkbox" name="purchaser_fee" [checked]="event.purchaser_commission > 0" (click)="isCheckPurchaseFee($event)"><span  i18n="set purchaser commission">&nbsp;&nbsp;Purchase fee</span>
              </div>
            </div>
            <div class="form-group" *ngIf="!show_purchase_fee_block">
              <label i18n="@@CmsApproveEventModalProducerFee" for="commission" class="">% Producer fee:</label>
              <input i18n-placeholder="@@CmsApproveEventModalProducerFee" class="form-control" type="number"
                step="0.01" oninput="validity.valid||(value='');" min="0" name="commission" placeholder="% Producer fee:" [(ngModel)]="event.producer_commission" #producer_commission="ngModel" required [appValidationMin]="-1">
              <span i18n="@@CmsApproveEventModalErrorMystBePosit" *ngIf="producer_commission.errors?.ValidationMinDirective && producer_commission.dirty && producer_commission.invalid" class="item-error">Must be a positive value</span>
              <span i18n="@@CmsApproveEventModalErrorRequired" *ngIf="producer_commission.dirty && producer_commission.invalid" class="item-error">This field is required</span>
            </div>
            <div class="form-group " *ngIf="show_purchase_fee_block">
              <label i18n="@@CmsApproveEventModalPurchaseFee" for="purchaser_commission">% Purchase fee:</label>
              <input i18n-placeholder="@@CmsApproveEventModalPurchaseFee" class="form-control" type="number"
                step="0.01" oninput="validity.valid||(value='');" min="0" name="purchaser_commission" placeholder="% Purchase fee:" [(ngModel)]="event.purchaser_commission" #purchaser_commission="ngModel" required [appValidationMin]="-1">
              <span i18n="@@CmsApproveEventModalErrorMystBePosit" *ngIf="purchaser_commission.errors?.ValidationMinDirective && purchaser_commission.dirty && purchaser_commission.invalid" class="item-error">Must be a positive value</span>
              <span i18n="@@CmsApproveEventModalErrorRequired" *ngIf="purchaser_commission.errors?.required && purchaser_commission.dirty && purchaser_commission.invalid" class="item-error">This field is required</span>
            </div>
          </div>
          <!-- מחיר מלא -->
          <!-- מחיר כרטיס -->
          <div class="form-group col-md-6  col-xs-12">
            <div class="form-group checkbox-group">
              <div class="box-alignment">
                <input type="checkbox" name="refund_type" [checked]="event.refund_type == 'ticket_price'" (click)="setRefundType($event)" value="ticket_price" mgModel><span i18n="choose ticket price for refund fee">&nbsp;&nbsp;Ticket price</span>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <div class="box-alignment">
                <input type="checkbox" name="refund_type" [checked]="event.refund_type == 'full_price'" (click)="setRefundType($event)" value="full_price" mgModel><span i18n="choose full price for refund fee">&nbsp;&nbsp;Full price</span>
              </div>
            </div>
            <div class="form-group ">
              <label i18n="@@CmsApproveEventModalRefundFee" for="refund_commission" class="">% Refund fee:</label>
              <input i18n-placeholder="@@CmsApproveEventModalRefundFee" class="form-control" type="number"
                step="0.00000001" oninput="validity.valid||(value='');" min="0" name="refund_commission" placeholder="% Refund fee:" [(ngModel)]="event.refund_commission" #refund_commission="ngModel" [appValidationMin]="-1">
              <span i18n="@@CmsApproveEventModalErrorMystBePosit" *ngIf="refund_commission.errors?.ValidationMinDirective && refund_commission.dirty && refund_commission.invalid" class="item-error">Must be a positive value</span>
            </div>
          </div>
          <div *ngIf="data.status === 3 || !data.hasAtLeastOnePurchase" class="crypto-payment">
            <label i18n="event crypto payment">Coinbase payment:</label>
            <input class="form-control" trim="blur" type="text" placeholder="Crypto payment" readonly [value]="crypto_payment">
          </div>
        </div>
        <div class="row mb5">
          <div class="form-group col-md-3  col-xs-6">
            <div class="form-group">
              <label i18n="tax commission settings" for="tax_commission">Tax: </label>
              <input class="form-control" type="number"
                     step="0.01" oninput="validity.valid||(value='');" min="0" name="tax_commission" placeholder="Tax" [(ngModel)]="event.tax_commission" #tax_commission="ngModel" required [appValidationMin]="-1">
              <span i18n="@@CmsApproveEventModalErrorMystBePosit" *ngIf="tax_commission.errors?.ValidationMinDirective && tax_commission.dirty && tax_commission.invalid" class="item-error">Must be a positive value</span>
              <span i18n="@@CmsApproveEventModalErrorRequired" *ngIf="tax_commission.errors?.required && tax_commission.dirty && tax_commission.invalid" class="item-error">This field is required</span>
            </div>
          </div>
          <div class="form-group col-md-3  col-xs-6">
            <div class="form-group">
              <label i18n="service commission settings" for="service_commission">Service fee: </label>
              <input class="form-control" type="number"
                     step="0.01" oninput="validity.valid||(value='');" min="0" name="service_commission" placeholder="Service fee" [(ngModel)]="event.service_commission" #service_commission="ngModel" required [appValidationMin]="-1">
              <span i18n="@@CmsApproveEventModalErrorMystBePosit" *ngIf="service_commission.errors?.ValidationMinDirective && service_commission.dirty && service_commission.invalid" class="item-error">Must be a positive value</span>
              <span i18n="@@CmsApproveEventModalErrorRequired" *ngIf="service_commission.errors?.required && service_commission.dirty && service_commission.invalid" class="item-error">This field is required</span>
            </div>
          </div>
          <div class="form-group col-md-6 col-xs-12">
            <div class="form-group">
              <label i18n="tax commission settings" for="platform_fee">Platform fee: </label>
              <div style="display: flex;">
                <div>
                  <input class="form-control" type="number"
                         step="0.01" oninput="validity.valid||(value='');" min="0" name="platform_fee" placeholder="fee" [(ngModel)]="event.platform_fee" #platform_fee="ngModel" required [appValidationMin]="-1">
                  <span i18n="@@CmsApproveEventModalErrorMystBePosit" *ngIf="platform_fee.errors?.ValidationMinDirective && platform_fee.dirty && platform_fee.invalid" class="item-error">Must be a positive value</span>
                  <span i18n="@@CmsApproveEventModalErrorRequired" *ngIf="platform_fee.errors?.required && platform_fee.dirty && platform_fee.invalid" class="item-error">This field is required</span>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div class="row mb5">
            <div class="form-group col-md-3 col-xs-6">
              <div class="form-group">
                <label for="boost_status">Boost Status: </label>
                <select class="form-control" name="boost_status" [(ngModel)]="event.boost_status">
                  <option value="0" [disabled]="(data.boost_status == 0 || data.boost_status == 1 ) ? true : false">No Status - User do not want boost</option>
                  <option value="1" [disabled]="(data.boost_status == 0 || data.boost_status == 1 || data.boost_status == 2 || data.boost_status == 3) ? true : false">Pending -User want boost and waiting approval or reject</option>
                  <option value="2">Reject</option>
                  <option value="3">Approve</option>
                </select>
              </div>
            </div>
            <div class="form-group col-md-3 col-xs-6" *ngIf="event.boost_status == '3'">
              <div class="form-group">
                <label i18n="tax commission settings" for="boost_fee">% Marketing fee: </label>
                <input class="form-control" type="number"
                       step="1" oninput="validity.valid||(value='');" min="0" max="100" name="boost_fee" placeholder="Marketing fee" [(ngModel)]="event.boost_fee" #boost_fee="ngModel" required [appValidationMin]="-1">
                <span i18n="@@CmsApproveEventModalErrorMystBePosit" *ngIf="boost_fee.errors?.ValidationMinDirective && boost_fee.dirty && boost_fee.invalid" class="item-error">Must be a positive value</span>
                <span i18n="@@CmsApproveEventModalErrorRequired" *ngIf="boost_fee.errors?.required && boost_fee.dirty && boost_fee.invalid" class="item-error">This field is required</span>
              </div>
            </div>
          </div>

        <!-- Payment section -->
        <div *ngIf="data.status === 3 || !data.hasAtLeastOnePurchase" class="row mb5">
          <mat-expansion-panel style="width: 100%;border: 1px solid rgba(0, 0, 0, 0.2);" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <p class="col-md-12 col-xs-12"><strong>Payment</strong></p>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="form-group col-md-6 col-xs-12" *ngIf="gatewayTypes | async">
              <label i18n="@@CmsApproveEventGatewayProvider" for="gatewayType">Event gateway provider:</label>
              <select class="form-control" name="gatewayType" (change)="getGateways($event)" #gatewayType="ngModel" [(ngModel)]="selectedGatewayType" required>
                <option i18n="@@CmsApproveEventSelectaProvider" value="0">Select a provider</option>
                <option *ngFor="let type of (gatewayTypes | async)" value="{{type}}">{{type}}</option>
              </select>
              <app-error-creator [@slideIn] *ngIf="gatewayType && gatewayType.touched && gatewayType.invalid" [control]="gatewayType"></app-error-creator>
            </div>
            <div class="form-group col-md-6 col-xs-12" *ngIf="selectedGatewayType">
              <label i18n="@@CmsApproveEventGateway" for="gateway" class="">Gateway:</label>
              <select class="form-control" id="gateway" name="gateway" (change)="setSelectedGateway($event.target.value)" #gateway="ngModel" [(ngModel)]="selectedGateway" required>
                <option i18n="@@CmsApproveEventSelectaGateway" value="">Select a gateway</option>
                <option *ngFor="let gateway of (gateways | async)" [disabled]="disableOptionByCurrency(gateway.id)"
                        title="{{disableOptionByCurrency(gateway.id) ? 'Unable to select without configuring the currency for this gateway': gateway.name}}"
                        [selected]="selectedGateway == gateway.id" [value]="gateway.id">{{gateway.name}}</option>
                <option i18n="@@CmsApproveEventDefineanewgateway" value="-1">Define a new gateway</option>
              </select>
              <app-error-creator [@slideIn] *ngIf="gateway && gateway.touched && gateway.invalid" [control]="gateway"></app-error-creator>
            </div>
            <div class="selected-gateways form-group col-md-6 col-xs-12">
              <label i18n="selected gateway" for="selected_gateway" class="selected-gateway-label">Selected gateways:</label>
              <div class="gateways-table">
                <div class="selected-gateway" *ngFor="let gateway of selectedGateways | keyvalue">
                  <span class="added-gateway">{{gateway?.value?.name}}</span>
                  <span class="link-btn" (click)="deleteGateway(gateway?.key)">Delete</span>
                </div>
              </div>
            </div>

            <!-- Stripe Connect-->
            <div class="row">
              <div class="form-group col-md-6 col-xs-12" *ngIf="selectedGateways.hasOwnProperty('stripe_gateway')">
                <div class="form-group checkbox-group">
                  <label for="stripe_connect_account_mode" class="selected-gateway-label">Stripe Connect:</label>
                  <div>
                    <input type="checkbox" name="stripe_connect_account_mode" id="stripe_connect_account_mode"
                           style="margin: 4px;"
                           [checked]="event.stripe_connect_account_mode === 1 ? true : false"
                           (change)="event.stripe_connect_account_mode = event.stripe_connect_account_mode === 0 ? 1 : 0">&nbsp;
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6 col-xs-12" *ngIf="event.stripe_connect_account_mode && selectedGateways.hasOwnProperty('stripe_gateway')">
                <div class="form-group">
                  <label for="stripe_connect_account_id" class="selected-gateway-label">Account ID:
                    <span class="badge" matTooltip="If field will be empty we create a new Stripe Account ID. If you already have an account, just fill out the field (e.g 'acct_34PgAgMrrfXIUdQ').">i</span>
                    <p class="item-error" *ngIf="gatewayTypeChangedByUser && data.stripe_connect_account_data && data.stripe_connect_account_data.stripe_connect_account_id">
                      If you choose another Gateway, your Stripe Account ID will be deleted from this event.
                    </p>
                  </label>
                  <div>
                    <input class="form-control" type="text" id="stripe_connect_account_id"
                           name="stripe_connect_account_id" placeholder="Stripe Connect" [(ngModel)]="event.stripe_connect_account_data.stripe_connect_account_id" (ngModelChange)="onChangeStripeAccInput($event)">

                    <ng-container>
                      <span><strong>or</strong> choose from </span>
                      <label for="selected_producer_stripe_connect_account">Account IDs list</label>
                      <select name="selected_producer_stripe_connect_account" [(ngModel)]="selectedStripeConnectAccount" (ngModelChange)="onChangeStripeAccSelect($event)" class="form-control">
                        <option *ngFor="let item of event.stripe_connect_account_data.producer_stripe_connect_accounts | filterByGatewayId:selectedGateway | keyvalue" [value]="item.key">
                          {{item.value?.account_id}} -
                          name:  {{item.value?.name}} |
                          payment_type:  {{item.value?.payment_type}} |
                          payment_gateway_id: {{item.value?.payment_gateway_id}}
                        </option>
                      </select>
                    </ng-container>
                  </div>
                </div>
                <!-- Stripe enable_indirect_payment-->
                <div class="form-group checkbox-group">
                  <label for="enable_indirect_payment" class="selected-gateway-label">Enable indirect payment:</label>
                  <div>
                    <input type="checkbox" name="enable_indirect_payment" id="enable_indirect_payment"
                           style="margin: 4px;"
                           [checked]="event.stripe_connect_account_data.enable_indirect_payment"
                           [(ngModel)]="event.stripe_connect_account_data.enable_indirect_payment">&nbsp;
                  </div>
                </div>
                <ng-container *ngIf="event.stripe_connect_account_data.enable_indirect_payment">
                  <div style="box-shadow: 0px 0px 3px gray;">
                    <p style="text-align: center;">Schedule payment transfer</p>
                    <label for="transfer_start_after_hours">Hours after event end-date:</label>
                    <input class="form-control" type="number"
                           step="1" oninput="validity.valid||(value='');" min="0" name="transfer_start_after_hours" placeholder="Hour"
                           [(ngModel)]="event.transfer_start_after_hours" #transfer_start_after_hours="ngModel" [appValidationMin]="-1"
                           (change)="changePayoutSchedule($event, 'hours')">

                    <label for="transfer_start_after_date_time">Date&Time after event end-date:</label>
                    <input class="form-control" type="datetime-local" id="transfer_start_after_date_time" name="transfer_start_after_date_time"
                           [(ngModel)]="event.transfer_start_after_date_time" #transfer_start_after_date_time="ngModel"
                           (change)="changePayoutSchedule($event, 'date_time')">
                    <br>
                    <input type="checkbox" name="transfer_charge_transaction_flow" id="transfer_charge_transaction_flow"
                           [checked]="event.transfer_charge_transaction_flow"
                           (change)="event.transfer_charge_transaction_flow = !event.transfer_charge_transaction_flow">&nbsp;
                    <span *ngIf="event.transfer_charge_transaction_flow">&nbsp;&nbsp;each payment -  each transfer </span>
                    <span *ngIf="!event.transfer_charge_transaction_flow">&nbsp;&nbsp;all payments -  one transfer</span>
                  </div>
                </ng-container>
                <!-- Stripe enable_indirect_payment END-->
              </div>
            </div>
            <!-- Stripe Connect END-->

          </mat-expansion-panel>
        </div>
          <!-- Payment section END-->

          <!-- Payout section-->
          <div class="row" *ngIf="data.status === 3 || !data.hasAtLeastOnePurchase">
            <mat-expansion-panel style="width: 100%;border: 1px solid rgba(0, 0, 0, 0.2);">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <p class="col-md-12 col-xs-12"><strong>Payout</strong></p>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container>
                <p class="col-md-12 col-xs-12">Stripe payout:</p>
                <div class="form-group" *ngIf="gateways">
                  <label>Event gateway provider:</label>
                  <select i18n="select input | gateway type" class="form-control" name="gatewayType_payout" (change)="getGateways_payout($event)" #gatewayType_payout="ngModel" [(ngModel)]="selectedGatewayType_payout" required>
                    <option *ngFor="let type of (gatewayTypes | async)" [disabled]="type !== 'stripe_gateway'" value="{{type}}">{{type}}</option>
                  </select>
                  <app-error-creator [@slideIn] *ngIf="gatewayType_payout && gatewayType_payout.touched && gatewayType_payout.invalid" [control]="gatewayType_payout"></app-error-creator>
                </div>

                <div class="form-group">
                  <ng-container *ngIf="selectedGatewayType_payout">
                    <label for="gateway_payout" class="">Gateway:</label>
                    <select class="form-control" id="gateway_payout" name="gateway_payout" (change)="setSelectedGateway_payout($event.target.value)" #gateway_payout="ngModel" [(ngModel)]="selectedGateway_payout">
                      <option value="" selected [disabled]="event.user_stripe_connect_payout_request">Select a gateway</option>
                      <option *ngFor="let gateway of (gateways_payout | async)" [value]="gateway.id">{{gateway.name}}</option>
                    </select>
                    <p class="item-error" *ngIf="event.user_stripe_connect_payout_account && event.user_stripe_connect_payout_request && (+selectedGateway_payout !== +event.user_stripe_connect_payout_request?.gateway_id)">
                      It will remove the connected account and reset the flow (forcing the users to fill all of their details again)
                    </p>
                    <p *ngIf="event.user_stripe_connect_payout_account">
                      Payout Account: {{event.user_stripe_connect_payout_account?.account_id}}
                    </p>
                    <app-error-creator [@slideIn] *ngIf="gateway_payout && gateway_payout.touched && gateway_payout.invalid" [control]="gateway_payout"></app-error-creator>
                  </ng-container>
                </div>

                <div class="col-md-6 col-xs-12" *ngIf="event.user_stripe_connect_payout_request && event.user_stripe_connect_payout_request.hasOwnProperty('id')">
                  <strong>Producer stripe payout connect account:</strong> <br>
                  user_id: {{event.user_stripe_connect_payout_request?.user_id}} <br>
                  gateway_id: {{showGatewayNameById(event.user_stripe_connect_payout_request?.gateway_id)}} <br>
                  type: {{stripe_connect_account_types[event.user_stripe_connect_payout_request?.type]}} <br>
                </div>

                <div class="form-group col-md-6 col-xs-12">
                  <label for="payout_account_type">Stripe connect payout:</label>
                  <select class="form-control" id="payout_account_type" name="payout_account_type" #payout_account_type="ngModel" [(ngModel)]="event.payout_account_type">
                    <option value="0" disabled>Select Payout Type</option>
                    <option *ngFor="let type of stripe_connect_account_types | keyvalue" value="{{type.key}}">{{type.value}}</option>
                  </select>
                </div>

<!--                <div class="form-group">-->
<!--                  <label for="payout_service_agreement">Service agreement:</label>-->
<!--                  <select class="form-control" id="payout_service_agreement" name="payout_service_agreement" #payout_service_agreement="ngModel" [(ngModel)]="event.payout_service_agreement">-->
<!--                    <option value="0" disabled>Select Service agreement</option>-->
<!--                    <option *ngFor="let type of stripe_connect_account_service_agreements | keyvalue" value="{{type.key}}">{{type.value}}</option>-->
<!--                  </select>-->
<!--                </div>-->
              </ng-container>
              <ng-container>
                <div>
                  <input type="checkbox" name="plaid_connect_request" id="plaid_connect_request"
                         [checked]="event.plaid_connect_request"
                         (change)="event.plaid_connect_request = !event.plaid_connect_request">
                  <strong matTooltip="available on Finance page, for US and Canada only">&nbsp;Plaid flow</strong>
                </div>
              </ng-container>
<!--              <br>-->
<!--              <a [routerLink]="['/cms/stripe-payouts/', event.id]"-->
<!--                 (click)="dialogRef.close('payouts')" class="link-bnt" style="font-size: 15px; text-decoration: underline;">-->
<!--                Payouts list-->
<!--              </a>-->
            </mat-expansion-panel>
          </div>
          <!-- Payout section END-->

          <div class="row mb5 mt10">
            <div class="form-group col-md-6 col-xs-12">
              <label i18n="NFT requested" for="isNft">NFT requested: {{ifUserRequested_nftStatus ? 'Yes' : 'No'}}</label>
              <div class="form-group checkbox-group">
                <input type="checkbox" name="isNft" id="isNft"
                       [checked]="event.isNft"
                       (change)="event.isNft = !event.isNft">&nbsp;
                <span i18n="Use NFT">Allow</span>
              </div>
            </div>
            <div class="col-md-6">
              <input type="checkbox" name="show_price_with_fees" id="show_price_with_fees"
                     [checked]="event.show_price_with_fees"
                     (change)="event.show_price_with_fees = !event.show_price_with_fees">&nbsp;
              <span>&nbsp;&nbsp;Show Price with Fees on UI</span>
            </div>

            <div class="form-group col-md-6 col-xs-12">
              <label i18n="Donate requested" for="isDonate">Donate tickets</label>
              <div class="form-group checkbox-group">
                <input type="checkbox" name="isDonation" id="isDonate"
                       [checked]="event.isDonation"
                       (change)="event.isDonation = !event.isDonation">&nbsp;
                <span i18n="Use NFT">Allow</span>
                <span style="margin: 0 8px;">
                  <input type="checkbox" name="donation_fee_mode" id="donation_fee_mode"
                         [checked]="event.donation_fee_mode"
                         (change)="event.donation_fee_mode = !event.donation_fee_mode">&nbsp;
                  <span i18n="Use fee taxes">Use fee & taxes</span>
                </span>
              </div>
              <mat-expansion-panel *ngIf="donation_tickets.length">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <span i18n="donate tickets list">Donate tickets list</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="form-group">
                  <input type="checkbox" name="changeAll_donation_ticket_approved" id="changeAll_donation_ticket_approved"
                         [checked]="changeAll_donation_ticket_approved_checked"
                         (change)="changeAll_donation_ticket_approved($event.target.checked)">&nbsp;
                  <label for="changeAll_donation_ticket_approved">Approve All</label>
                  <p class="item-error" *ngIf="!event.isDonation && countApprovedDonation_tickets">Make sure you Allow 'Donate tickets' feature to see selected tickets</p>
                </div>
                <ng-container *ngFor="let ticket of donation_tickets, let x = index">
                  <div class="form-group col-md-12 col-xs-12 " style="border: 1px solid gray; padding: 5px;">
                    <p style="font-size: 10px;">ID: {{ticket.id}}<br> Name: {{ticket.name}} <br>Quantity: {{ticket.quantity}} <br>Date: ({{ticket.start_date}})-({{ticket.end_date}})</p>
                    <label>Min Price:</label>
                    <input class="form-control" type="number" [(ngModel)]="ticket.donation_min" (change)="changeDonateMinMax()"  [ngModelOptions]="{standalone: true}" min="0" placeholder="min" />
                    <label>Max Price:</label>
                    <input class="form-control" type="number" [(ngModel)]="ticket.donation_max" (change)="changeDonateMinMax()"  [ngModelOptions]="{standalone: true}" min="0" placeholder="max" />
                    <label for="donation_ticket_approved{{x}}">Approve </label>
                    <input type="checkbox" name="donation_ticket_approved{{x}}" id="donation_ticket_approved{{x}}"
                           [checked]="ticket.donation_ticket_approved"
                           [(ngModel)]="ticket.donation_ticket_approved"
                           [ngModelOptions]="{standalone: true}"
                           (change)="change_donation_ticket_approved($event.target.checked)">&nbsp;
                  </div>
                </ng-container>
              </mat-expansion-panel>
            </div>

          </div>
          <div class="row">
            <div class="form-group col-md-6 col-xs-6">
              <label>Transfer Ticket mode:</label>
              <span style="margin: 0 8px;">
                  <input type="checkbox" name="transfer_ticket_mode" id="transfer_ticket_mode"
                         [checked]="event.transfer_ticket_mode"
                         (change)="event.transfer_ticket_mode = !event.transfer_ticket_mode">
                </span>
            </div>
            <div class="form-group col-md-6 col-xs-6">
              <label for="is_svix_webhook_mode">Svix mode:</label>
              <span style="margin: 0 8px;">
                <input type="checkbox" name="is_svix_webhook_mode" id="is_svix_webhook_mode"
                         [checked]="event.is_svix_webhook_mode"
                         (change)="event.is_svix_webhook_mode = !event.is_svix_webhook_mode">
              </span>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6 col-xs-12">
              <div class="form-group checkbox-group"
                   matTooltip="Seats.io">
                <label for="seats_mode" class="selected-gateway-label">Seats:</label>
                <div>
                  <input type="checkbox" name="seats_mode" id="seats_mode"
                         style="margin: 4px;"
                         [checked]="event.seats_mode === 1 ? true : false"
                         (change)="event.seats_mode = event.seats_mode === 0 ? 1 : 0">&nbsp;
                </div>
              </div>
            </div>
            <div class="form-group col-md-6 col-xs-12" *ngIf="event.seats_mode">
              <div class="form-group">
                <label for="event_seat_key" class="selected-gateway-label">Event Key:</label>
                <div>
                  <input class="form-control" type="text" id="event_seat_key"
                          name="event_seat_key" placeholder="Event key" [(ngModel)]="event.event_seat_key">
                </div>
              </div>
            </div>
            <div class="form-group col-md-12 col-xs-12" *ngIf="event.seats_mode">
              <div class="form-group">
                <label for="seat_terms" class="selected-gateway-label">Ticket "Terms" text:</label>
                <div>
                  <textarea class="form-control" type="textarea" id="seat_terms" rows="10"
                         name="seat_terms" placeholder="Ticket Terms text" [(ngModel)]="event.seat_terms">
                  </textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-12 col-xs-12">
              <label for="scanner_provider_type">Scanner providers:</label>
              <p style="font-size: 12px;">
                <strong>Tickets:</strong> {{count_tickets}}<br>
                <strong>Premium tickets threshold:</strong> {{premium_ticket_threshold}}
              </p>
              <select class="form-control" name="scanner_provider_type" #scanner_provider_type="ngModel" (change)="changeScannerType($event.target.value)" [(ngModel)]="event.scanner_provider_type">
                <option value="0" disabled>Select provider</option>
                <option *ngFor="let type of scanner_providers" [selected]="type.type == 1" value="{{type.type}}">{{type.name}}</option>
              </select>

            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-12 col-xs-12">
              <label for="reveal_barcode_hours" style="margin-bottom: 0;">Reveal barcode hours:&nbsp;</label>
              <span class="badge">{{event.reveal_barcode_hours}}</span>&nbsp;<span style="font-size: 0.9em;color: #0075ff;">(only for NYC events)</span>
              <div>
                <strong>Set Reveal hours:&nbsp;</strong>
                <input type="datetime-local" id="reveal-time" name="reveal-time"
                [value]="reveal_barcode_hours_value"
                [min]="reveal_barcode_hours_min"
                [max]="reveal_barcode_hours_max"
                       step="3600"
                (change)="changeRevealHours($event)"
              /></div>
              <input class="form-control" type="number" style="max-width: 100px; visibility: hidden;"
                     step="1" oninput="validity.valid||(value='');" min="0" name="reveal_barcode_hours" placeholder="Hours"
                     [(ngModel)]="event.reveal_barcode_hours" #reveal_barcode_hours="ngModel" [appValidationMin]="-1">
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 col-xs-12">
              <div class="checkbox-group">
                <label for="purchase_passcode_mode" class="selected-gateway-label">Purchase passcode mode:</label>
                <div>
                  <input type="checkbox" name="purchase_passcode_mode" id="purchase_passcode_mode"
                         style="margin: 4px;"
                         [checked]="event.purchase_passcode_mode === 1 ? true : false"
                         (change)="event.purchase_passcode_mode = event.purchase_passcode_mode === 0 ? 1 : 0">&nbsp;
                </div>
              </div>
            </div>
<!--            <div class="col-md-6 col-xs-12" *ngIf="event.purchase_passcode_mode">-->
<!--              <div class="form-group">-->
<!--                <label for="purchase_passcodes" class="selected-gateway-label">Passwords (Comma Separated List):-->
<!--                  <span class="badge" matTooltip="Password for ACCESS LOCKED TICKETS feature">i</span>-->
<!--                </label>-->
<!--                <div style="display: flex;">-->
<!--                  <input class="form-control"-->
<!--                         [required]="event.purchase_passcode_mode?true:false"-->
<!--                         [type]="show_purchase_passcode?'text':'password'" id="purchase_passcode"-->
<!--                         name="purchase_passcodes" placeholder="Password" [(ngModel)]="event.purchase_passcodes">-->
<!--                  <button (click)="togglePassword()" type="button" style="width: 50px;">-->
<!--                    <img [ngStyle]="{'filter': show_purchase_passcode?'contrast(1)':'contrast(0)'}" src="/assets/images/svg-icons/eye.svg" />-->
<!--                  </button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            dynamic codes-->
            <div class="col-md-6 col-xs-12">
              <form [formGroup]="passcodesForm" *ngIf="event.purchase_passcode_mode">

                  <p><span>Passwords:</span><span class="badge" matTooltip="Passwords for ACCESS LOCKED TICKETS feature">i</span></p>

                  <div formArrayName="passcodes">
                    <div *ngFor="let code of passcodes.controls; let i=index" >
                      <div [formGroupName]="i" class="form-group" style="display: flex; margin-bottom: 2px;">
                        #{{i}}
                        <input formControlName="passcode" class="form-control"
                               [type]="show_purchase_passcode?'text':'password'"
                               [required]="event.purchase_passcode_mode?true:false">
                        <button (click)="togglePassword()" type="button" style="padding: 0 15px;" class="btn btn-default">
                          <img [ngStyle]="{'filter': show_purchase_passcode?'contrast(1)':'contrast(0)'}" src="/assets/images/svg-icons/eye.svg" />
                        </button>
                        <button class="btn btn-danger" type="button" (click)="removePassCode(i)" style="padding: 0 15px;">x</button>
                      </div>
                      <span *ngIf="code.invalid" style="padding: 0 15px;" class="item-error">Required</span>
                    </div>
                  </div>

                  <div class="text-center">
                    <button class="btn btn-default" type="button" (click)="addPassCode()">Add new passcode</button>
                  </div>

              </form>
            </div>
            <!--            dynamic codes END-->
          </div>

        <div [hidden]="apiAction !== 'approveEvent'">
        <div class="text-center">
          <label i18n="modal title" for="eventCategories">Set event categories</label>
        </div>
        <div class="categiry-container">
        <table mat-table [dataSource]="categoryList" matSort class="mat-elevation-z8">
          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox color="primary" (change)="$event ? masterToggle($event) : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)"
                [aria-label]="checkboxLabel(element)">
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Position Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name[language]}} </td>
          </ng-container>

          <!-- status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
            <td mat-cell *matCellDef="let element"> {
              element.status, plural,
              =1 {Active}
              =0 {Inactive}
              } </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>
        </table>
      </div>
          <!-- Custom-fields Table -->
          <div class="custom-fields-container" *ngIf="data.custom_inputs.length">
            <div class="text-center">
              <label i18n="approve|cf">Custom Fields</label>
              <p class="text-center" i18n="block questions|cf" style="font-size: 0.9rem;">(Choose questions to block)</p>
            </div>
            <table mat-table [dataSource]="customFieldsList">
              <!-- Checkbox Column -->
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox color="primary" (change)="$event ? cf_masterToggle($event) : null"
                                [checked]="cf_selection.hasValue() && cf_isAllSelected()"
                                [indeterminate]="cf_selection.hasValue() && !cf_isAllSelected()" [aria-label]="cf_checkboxLabel()">
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let element">
                  <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                                (change)="$event ? cf_selection.toggle(element) : null" [checked]="cf_selection.isSelected(element)"
                                [aria-label]="cf_checkboxLabel(element)">
                  </mat-checkbox>
                </td>
              </ng-container>

              <!-- Position Column -->
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> # </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
              </ng-container>

              <!-- Title Column -->
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef> Title </th>
                <td mat-cell *matCellDef="let element" class="titles"> {{element.title}} ({{element.type}}) </td>
              </ng-container>

              <!-- Opinions Column -->
              <ng-container matColumnDef="opinions">
                <th mat-header-cell *matHeaderCellDef> Opinions </th>
                <td mat-cell *matCellDef="let element">
                  <ol *ngIf="element.opinions.length" class="opinions">
                    <li *ngFor="let opinion of element.opinions">{{opinion.title}}</li>
                  </ol>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="cf_displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: cf_displayedColumns;" (click)="cf_selection.toggle(row)"></tr>
            </table>
          </div>
          <!-- Custom-fields Table END -->

      </div>
      </div>
      <div class="modal-footer">
          <button i18n="@@CmsClose" type="button" class="btn btn-default mutate-float-left mr10" (click)="dialogRef.close()">Close</button>
          <button i18n="@@CmsSaveSettigs" type="submit" class="btn btn-success mutate-float-right" [disabled]="approveEventForm.invalid || (event.purchase_passcode_mode && passcodesForm.invalid)">Save settings</button>
      </div>
      </form>
    </div>

