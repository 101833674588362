import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import 'rxjs/Rx';

@Injectable({
	providedIn: 'root'
})
export class CmsGatewaysService {
	api_base_url = environment.api_base_url;
	constructor(
		private http: HttpClient
  ) { }

  getCmsGateways(status: string, type_gateway: string, start: number, end: number, filterText: any) {
    const endQuery = filterText ? '/' + filterText : '';
    return this.http.post(this.api_base_url + '/api/get-all-payment-gateways/' + status + '/' + type_gateway + '/' + start + '/' + end + endQuery,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
    });
  }

  getGateway (gateway_id: number) {
		return this.http.get(this.api_base_url + '/api/get-gateway/for-update/' + gateway_id,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
    });
  }

  setGateway (gateway: any) {
		return this.http.post(this.api_base_url + '/api/post-payment-gateway/update', gateway,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
    });
  }

  deleteCmsGateway(gateway_id: number) {
    return this.http.get(this.api_base_url + '/api/get-payment-gateway/delete/' + gateway_id,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
    });
  }

  updateCmsGatewayStatus(gateway_id: number, new_status: number) {
    return this.http.post(this.api_base_url + '/api/get-gateway/for-update-status', {gateway_id: gateway_id, status: new_status},
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
    });
  }

  }
