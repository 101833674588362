import { AuthCacheService } from './../../services-cache/auth-cache.service';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { DialogService } from 'src/app/services/dialog.service';
import { slideIn, fade } from './../../animation';
import { GlobalsService } from './../../globals';
import { MbscDatetimeOptions } from '@mobiscroll/angular';
import { LocalizationService } from './../../services/localization.service';
import { NgForm, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Component, Input, OnInit, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UserData } from './../../interfaces/authentication.interface';
import { emailRegex } from 'src/app/regex';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.css'],
  animations: [slideIn, fade]
})
export class UserRegistrationComponent implements OnInit, OnDestroy {

  @ViewChild('userRegistrationForm') userRegistrationForm: NgForm;
  @Input() userData: any;
  @Input() showSeatsIo: boolean = false;
  disableEmailSection: boolean = false;
  disablePhoneSection: boolean = false;
  formSubmited = false;
  ipInfo: any;
  labelGender: string = '1';
  getinSlider = true;
  eventSlider = false;
  colorSlideToggle = '#FF7E7F';
  isDisabledSlideToggle = false;
  emailRegex = emailRegex;
  	//This is for default value
	user_info: UserData = {
		user_id: 0,
		first_name: '',
		last_name: '',
		phone: '',
		email: '',
		identification_number: '',
		facebook_id: '',
		age: '',
		birth_date: '',
		gender: '',
		user_link: '',
		type: 0,
		company: '',
		img: '',
		phoneCountryCode: '',
		fb_user_link: '',
		personal_info: 0,
		id: '',
    emailsFromGetin: '0',
    emailsFromEvent: '0'
	}

  today = new Date();
  myBirthdaySettings: MbscDatetimeOptions = {
    theme: this.globals.mobiscrollTheme(),
    lang: this.globals.getLocaleId(),
    // animate: 'pop',
    headerText: (this.globals.getDir() === 'rtl') ? 'תאריך לידה' : 'Date of Birth',
    min: new Date(this.today.setFullYear(this.today.getFullYear() - 120)),
    display: 'center',
    dateWheels: 'yy MM dd',
    defaultValue: '1980-01-01',
    animate: 'slideup',
    returnFormat: 'iso8601',
    max: new Date(),
  };
  counter = 0;

  @Output() switchStep: EventEmitter<any> = new EventEmitter();
  @Output() setUserData: EventEmitter<any> = new EventEmitter();
  @Output() switchTerms: EventEmitter<any> = new EventEmitter();

  constructor(
    public localizationService: LocalizationService,
    private globals: GlobalsService,
    private dialogService: DialogService,
    public nav: NavbarService,
    public authService: AuthService,
    private authCacheService: AuthCacheService
    ) {

    const IPdata = this.localizationService.getIpInfo();
    if (!IPdata) {
      this.localizationService.ourLatLng.subscribe(info => {
        console.log('localizationService: ', info);
        this.ipInfo = info;
      });
    } else {
      this.ipInfo = IPdata;
    }
  }

  ngOnInit(): void {
    this.user_info = this.userData;
    this.user_info.emailsFromEvent ? this.eventSlider = !!this.user_info.emailsFromEvent : false;
    this.user_info.emailsFromGetin ? this.getinSlider = !!this.user_info.emailsFromGetin : false;
    !this.userData.gender ? this.user_info.gender = this.labelGender : this.user_info.gender = this.userData.gender;
    this.disableEmailSection = this.userData.email ? true : false;
    this.disablePhoneSection = this.userData.phone ? true : false;
    console.log('disabled sections ', this.disableEmailSection, this.disablePhoneSection, this.userData.gender);
    console.log('UserRegistrationComponent', this.userData, this.user_info);
  }

  formChangeSub() {
    this.userRegistrationForm.controls['phone'].valueChanges.pipe(
      debounceTime(800)).subscribe((input) => {
        const hiddenBtn =  <HTMLButtonElement>document.getElementsByClassName('continue-btn')[0];
        hiddenBtn.focus();
        console.log('formChangeSub valueChanges', input, hiddenBtn);
        this.userRegistrationForm.controls['phone'] ? this.userRegistrationForm.controls['phone'].markAsTouched() : false;
      });
  }

  onChangePhone(event) {
    this.counter++;
    this.userRegistrationForm.controls['phone'] ? this.userRegistrationForm.controls['phone'].setValue(event.target.value) : false;
    this.userRegistrationForm.form.updateValueAndValidity();
    this.counter == 1 ? this.formChangeSub() : false;
    console.log('onChangePhone formField', event.target.value, this.userRegistrationForm);
  }

  setErrorOnPhoneNumber(event) {
    // console.log('setErrorOnPhoneNumber', event);
    event ? this.userRegistrationForm.form.controls['phone'].setErrors({invalid: event}) : this.userRegistrationForm.form.controls['phone'].setErrors(null);
    // this.logregForm.get('phone').updateValueAndValidity();
  }

  setRealPhoneNumber(phoneNumber) {
    // console.log('setRealPhoneNumber', phoneNumber);
    this.userRegistrationForm.form.controls['phone'].patchValue(phoneNumber);
  }

  intlTelInputObject(event) {
    // group.addControl('phoneCountryCode', new FormControl(''));
    // console.log('intlTelInputObject', event.s.iso2, event, this.userRegistrationForm); // .patchValue(event.s.iso2);
    if (this.userRegistrationForm) { // this.disablePhoneSection &&
      this.userRegistrationForm.form.addControl('phoneCountryCode', new UntypedFormControl(''));
      this.userRegistrationForm.form.controls['phoneCountryCode'].setValue(this.user_info.phoneCountryCode);
      this.userRegistrationForm.value['phoneCountryCode'] = this.user_info.phoneCountryCode;
    } else {
      // this.obj = event;
      if(this.user_info.phone) {
        event.setNumber(this.user_info.phone);
      }
      this.user_info.phoneCountryCode = event.s.iso2;
    }
  }

  intlOnCountryChange(event) {
    // console.log('intlOnCountryChange', event.iso2, event); //  .patchValue(event.iso2);
    this.user_info.phoneCountryCode = event.iso2;
    if (this.userRegistrationForm) {
    this.userRegistrationForm.value['phoneCountryCode'] = event.iso2;
    }
  }

  slideToggleSection(value) {
    // console.log('slideToggleSection', value, this.getinSlider, this.eventSlider);
    if (value == 'emailsFromGetin') {
      this.getinSlider = !this.getinSlider;
    } else if (value == 'emailsFromEvent') {
      this.eventSlider = !this.eventSlider;
    }
    console.log('result: ', this.userRegistrationForm.form.controls[value].value); // this.user_info.emailsFromEvent, this.user_info.emailsFromGetin,
  }

  redioToggle(radio) {
    console.log('redioToggle', radio.value, this.user_info.gender);
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup) {
		(<any>Object).values(formGroup.controls).forEach(control => {
		  control.markAsTouched();
		  // control.markAsPristine();
		  control.updateValueAndValidity();
		  if (control.controls) {
			this.markFormGroupTouched(control);
		  }
		});
  }

  onSubmit() {
    console.log('onSubmit', this.userRegistrationForm.form);

    if (this.userRegistrationForm.invalid) {
			this.markFormGroupTouched(this.userRegistrationForm.form);
			this.dialogService.alertsModal('errors', 18, 2000, false);
		} else if (this.userRegistrationForm.valid) {

      this.userRegistrationForm.form.value.emailsFromGetin = this.getinSlider ? '1' : '0';
      this.userRegistrationForm.form.value.emailsFromEvent = this.eventSlider ? '1' : '0';

      const index = this.userRegistrationForm.form.value['name'].indexOf(' ', 0);
      const fname = this.userRegistrationForm.form.value['name'].substring(0, index);
      const lname = this.userRegistrationForm.form.value['name'].substring(index+1);
      this.userRegistrationForm.form.value['fname'] = fname ? fname : lname ? lname : '';
      this.userRegistrationForm.form.value['lname'] = lname ? lname : ' ';

      console.log('wordSubstr', fname, lname);
      console.log('words', this.userRegistrationForm.form.value['fname'], this.userRegistrationForm.form.value['lname']);

        if (this.disablePhoneSection) {
          this.userRegistrationForm.form.value['phoneCountryCode'] = this.user_info.phoneCountryCode;
          this.userRegistrationForm.form.value['phone'] = this.userRegistrationForm.form.controls['phone'].value !== undefined && this.userRegistrationForm.form.controls['phone'].value ? this.userRegistrationForm.form.controls['phone'].value : this.user_info.phone;
        } else {
          // this.userRegistrationForm.value['phoneCountryCode'] = this.user_info.phoneCountryCode; // this.obj.s.iso2;
        }
        if (this.disableEmailSection) {
          this.userRegistrationForm.form.value.email = this.userRegistrationForm.form.controls['email'].value !== undefined && this.userRegistrationForm.form.controls['email'].value ? this.userRegistrationForm.form.controls['email'].value : this.user_info.email;
        }

        console.log('userRegistrationForm before updateUserDetails', this.userRegistrationForm.form.value, this.userRegistrationForm.value);
        this.globals.stopLoader();
        this.authService.updateUserDetails(this.userRegistrationForm.form.value).subscribe((response: any) => {
            console.log('updateUserDetails response', response);
            this.authService.saveUserCookies(response.response.user_info, this.user_info.facebook_id);
            this.authCacheService.clearCache();
            this.nav.checkUser();
            this.globals.stopLoader();
            this.dialogService.alertsModal('success', 'success', 5000, true);
            // this.nav.checkUser();
            this.setUserData.emit(response.response.user_info);
            this.switchStep.emit(this.showSeatsIo ? 'SeatsTickets' : 'Guest');
          },
            error => {
            this.globals.stopLoader();
            this.dialogService.alertsModal('errors', 2, 5000, false);
          }
        );

      } else {
        this.globals.stopLoader();
      }

  }

  getAgeementAndTerms() {
    console.log('Agree with Ageement');
    this.switchTerms.emit(true);
  }

  checkFullName($event) {
    let value = $event.target.value;
    if(value.trim().indexOf(' ') === -1){
      this.userRegistrationForm.form.get('name').setErrors({ 'invalid': true });
    }
  }

  ngOnDestroy() {
    console.log('onDestroy registration component');
  }

}
