<div *ngIf="!isShowOtp" class="button-group"
     [ngClass]="{'selected-flow' : (selectedFlow == 'phone' || selectedFlow == 'email')}">
  <div i18n="Button | Login with phone" (click)="setRegType('phone', isWalletSignUp)" class="reg-type"
       [ngClass]="{'active' : isPhone}">With phone</div>
  <div i18n="Button | Login with email" (click)="setRegType('email', isWalletSignUp)" class="reg-type"
       [ngClass]="{'active' : !isPhone}">With email</div>
</div>
<div class="captcha-container">

</div>
<!-- (ngSubmit)="onSignup()" -->
<form *ngIf="!isWalletSignUp" [formGroup]="registerForm" class="otp-form">
  <div [style]="!isShowOtp ? 'display:block': 'display:none'">
    <div class="form-group row">
      <div class="col-md-12 col-xs-12">
        <p *ngIf="!isPhone && !showPasswordInput" class="phone-txt-hint">{{'signin.EnterEmailTxt' | translate:'Enter your email and we\'ll send you a code.'}}</p>
        <input (keyup)="inputChange('email')" *ngIf="!isPhone"
               [ngClass]="eventPage? 'form-control-event' : 'form-control'" type="email" name="email" formControlName="email"
               placeholder="{{'signin.EmailAddress' | translate:'Email Address'}}" >


        <!-- Toggle Password Input-->
        <div *ngIf="!isPhone" class="toggle-password">
          <span *ngIf="!showPasswordInput">{{'signin.Lookingforthepassword' | translate:'Looking for the password?'}}</span>
          <mat-checkbox [(ngModel)]="showPasswordInput" (change)="togglePassword($event)"
                        [ngModelOptions]="{standalone: true}">
            <span *ngIf="!showPasswordInput">{{'signin.Clicktousepassword' | translate:'Click to use password'}}</span>
            <span *ngIf="showPasswordInput">{{'signin.Clicktohidepassword' | translate:'Click to hide password'}}</span>
          </mat-checkbox>
        </div>
        <!-- Toggle Password Input END-->

        <div *ngIf="isPhone" [ngStyle]="{'display': isPhone ? 'block' : 'block'}">
          <p class="phone-txt-hint">{{'signin.EnterPhoneTxt' | translate:'Enter your phone no. and we\'ll send you a code.'}}</p>
          <input appPhoneNumber ng2TelInput trim="blur"
          [ngClass]="eventPage? 'form-control-event' : 'form-control'"
          inputmode="tel" type="tel"
          name="phone"
          (change)="onChangePhone($event)"
          (keyup)="inputChange('phone')"
          placeholder="{{'signin.PhoneNoPlaceholder' | translate:'Phone No.'}}" value=""
          formControlName="phone"
          (hasError)="setErrorOnPhoneNumber(!$event, registerForm)"
          (ng2TelOutput)="setRealPhoneNumber($event, registerForm)"
          (intlTelInputObject)="telInputObject($event, registerForm.value.phone)"
          (countryChange)="onCountryChange($event)"
          [ng2TelInputOptions]="localizationService.getPhoneInputOptions()" />
        </div>
        <span i18n="Wallet Connect User Phone number" *ngIf="registerForm && registerForm.get('phone') && registerForm.get('phone').touched && registerForm.get('phone').dirty
        && registerForm.get('phone').invalid" class="item-error big-error mt15">Please enter correct phone number.</span>
        <span *ngIf="registerForm && registerForm.get('email') && registerForm.get('email').touched && registerForm.get('email').dirty
        && registerForm.get('email').invalid" class="item-error big-error mt15">Please enter correct Email.</span>
      </div>
    </div>
    <div *ngIf="!isPhone && showPasswordInput" class="form-group row">
      <div class="col-md-12 col-xs-12">
        <input i18n-placeholder="@@Password" [ngClass]="eventPage? 'form-control-event' : 'form-control'" type="password" name="password" formControlName="password" placeholder="Password">
        <span i18n="@@AccountSettingsPassError" *ngIf="registerForm.get('password').dirty && registerForm.get('password').invalid" class="item-error big-error mt15">The password must contain at least 6 characters</span>
      </div>
    </div>

    <p class="signup-terms"
       style="margin-bottom: 0">{{'signup.Byregisteringyouacceptour' | translate:'By registering you accept our'}}
      <a href="https://get-in.com/en/terms" target="_blank">{{'signup.TermsofUse' | translate:'Terms of Use'}}</a> {{'signup.and' | translate:'and'}}
      <a href="https://get-in.com/en/privacy" target="_blank">{{'signup.PrivacyPolicy' | translate:'Privacy Policy'}}</a>.
    </p>

    <p class="signup-terms">{{'signin.siteProtectedreCAPTCHAandtheGoogle' | translate:'This site is protected by reCAPTCHA and the Google'}}
      <a href="https://policies.google.com/privacy" target="_blank">{{'signin.PrivacyPolicy' | translate:'Privacy Policy'}}</a> {{'signup.and' | translate:'and'}}
      <a href="https://policies.google.com/terms" target="_blank">{{'signin.TermsofService' | translate:'Terms of Service'}}</a> {{'signup.apply' | translate:'apply'}}.
    </p>
    <div id="recaptcha_signup" #recaptcha_signup style="display: flex;justify-content: center;"></div>
  </div>
  <div *ngIf="isShowOtp && testOnlyOtpToken" style="color: #CCC; line-height: 0px;">(test only) Otp code: <span style="line-height: 0px;">&nbsp;&nbsp;{{testOnlyOtpToken}}&nbsp;&nbsp;</span></div>
  <app-check-otp [showOtp]="isShowOtp" (timeCounter) = setTimeCounter($event) (sendUserData)="sendUserData($event)"
                 [(disabbleBtn)]="disabbleBtn" [userEmailOrPhone]="registerForm?.get('phone')?.value || registerForm?.get('email')?.value" (otpIsShow)="startOTPTimer($event)"></app-check-otp>

  <!--OTP block below otp input-->
  <div *ngIf="isShowOtp">
    <ng-container *ngIf="sendSmsTimer">
      <p class="otp-timer-txt">{{'signin.TimeLeftforNewOTP' | translate:'Time Left for New OTP'}} <span class="otp-timer-count">00:{{sendSmsTimer < 10 ? '0':''}}{{sendSmsTimer}}</span></p>
    </ng-container>


    <div class="otp-warning" *ngIf="sendSmsTimer">
      <img src="./assets/images/svg-icons/information.svg" alt="info">
      <p>{{'signin.infoMsgPleasedonotclosethiswindowOTP' | translate:'Please do not close this window until your OTP is received.'}}</p>
    </div>

<!--    <ng-container *ngIf="isPhone">-->
<!--      <p class="otp-timer-txt attempts" [@fade] *ngIf="isSendAgainAttempts < 3 && isSendAgainAttempts !== 0">{{3 - isSendAgainAttempts}} of 3 Attempts Left</p>-->
<!--      <p class="otp-timer-txt no-attempts" [@fade] *ngIf="isSendAgainAttempts >= 3">No More Attempts Left</p>-->
<!--    </ng-container>-->

<!--    <div class="otp-resend" *ngIf="isSendAgainAttempts >= 3 && !sendSmsTimer">-->
<!--      <p>Please Try Again Later or</p>-->
<!--      <button (click)="intercomMessage()">Contact Support</button>-->
<!--    </div>-->

    <div class="otp-resend" [@fade] [ngClass]="{'disabled' : sendSmsTimer}">
      <p class="mt10">{{'signin.DidntReceiveOTPCode' | translate:'Didn’t Receive OTP Code?'}}</p>
      <button [disabled]="sendSmsTimer" (click)="checkUser()">{{'signin.ResentCode' | translate:'Resent Code'}}</button>
      <p style="margin-bottom: 0;">{{'signin.or' | translate:'or'}}</p>
      <button [disabled]="sendSmsTimer" (click)="showTab()"  class="proceed-btn">
        <div *ngIf="isPhone">
          <span class="type-icon"><img src="./assets/images/login/l_email_login.svg" alt="Proceed using Email"></span>
          <span>{{'signin.ProceedusingEmail' | translate:'Proceed using Email'}}</span>
        </div>
        <div *ngIf="!isPhone">
          <span class="type-icon"><img src="./assets/images/login/l_phone_login.svg" alt="Phone Login"></span>
          <span>{{'signin.ProceedusingPhone' | translate:'Proceed using Phone'}}</span>
        </div>
      </button>
    </div>
  </div>
  <!--OTP block below otp input END-->

  <div *ngIf="!isShowOtp" class="row mt10">
		<div class="col-md-12 col-xs-12">
			<button (click)="checkUser()" id="otp_btn" class="login-btn  col-md-12 col-xs-12 1" [ngClass]="eventPage? 'login-next' : 'login-next-box'"
              [class.disable]="(!registerForm.valid && registerForm.touched && registerForm.dirty) || fetched || retryTime"
              [disabled]="(!registerForm.valid && registerForm.touched && registerForm.dirty) || fetched || retryTime">
        <span *ngIf="!eventPage && !isShowOtp">{{'signup.SignUp' | translate:'Sign Up'}}</span>
			</button>
		</div>
	</div>
</form>

<form *ngIf="isWalletSignUp" [formGroup]="registerWalletForm" (ngSubmit)="onSignup()">
  <div [style]="!isShowOtp ? 'display:block': 'display:none'" class="otp-form">
    <div *ngIf="!isPhone" class="form-group row">
      <div class="col-md-12 col-xs-12">
        <!-- (focusout)="registerWalletForm.get('email').dirty && registerWalletForm.get('email').valid ? checkUserEmailorPhone('email') : false" -->
        <input i18n-placeholder="@@Email" (keyup)="inputChange('email')" [ngClass]="eventPage? 'form-control-event' : 'form-control'" type="email" name="email" formControlName="email" placeholder="Email" [pattern]="emailRegex" required>
        <span i18n="Wallet Connect User Email" *ngIf="registerWalletForm.get('email').dirty && registerWalletForm.get('email').invalid && !mailExists" class="item-error">Please enter correct email.</span>
        <span i18n="Wallet Connect User Email" *ngIf="registerWalletForm.get('email').dirty && registerWalletForm.get('email').invalid && mailExists" class="item-error">The email is already used.</span>
      </div>
    </div>
    <div *ngIf="isPhone && isWalletSignUp" class="form-group row">
      <div class="col-md-12 col-xs-12">
        <!-- (focusout)="registerWalletForm.get('phone').dirty && registerWalletForm.get('phone').valid ? checkUserEmailorPhone('phone') : false" -->
          <input #phone_input i18n-placeholder="placeholder | user phone number"

            [ngClass]="eventPage? 'form-control-event' : 'form-control'" name="phone" style="padding: 12px 50px; direction: inherit;"
            formControlName="phone" placeholder="User phone" trim="blur" inputmode="tel" type="tel"
            appPhoneNumber ng2TelInput
            (hasError)="setErrorOnphoneNumber(!$event)"
            (ng2TelOutput)="setPhoneNumber($event)"
            (intlTelInputObject)="setTelInputObject($event, phone_input.value)"
            (countryChange)="countryChange($event)"
            [ng2TelInputOptions]="localizationService.getPhoneInputOptions()" required>
        <span i18n="Wallet Connect User Phone number" *ngIf="registerWalletForm.get('phone').dirty && registerWalletForm.get('phone').invalid && !phoneExists" class="item-error">Please enter correct phone number.</span>
        <span i18n="Wallet Connect User Phone number used" *ngIf="registerWalletForm.get('phone').dirty && registerWalletForm.get('phone').invalid && phoneExists" class="item-error">The phone number is already used.</span>
      </div>
    </div>
  </div>
  <div *ngIf="isShowOtp && testOnlyOtpToken" style="color: #CCC; line-height: 0px;">(test only) Otp code: <span style="line-height: 0px;">&nbsp;&nbsp;{{testOnlyOtpToken}}&nbsp;&nbsp;</span></div>
  <app-check-otp [showOtp]="isShowOtp" (timeCounter) = setTimeCounter($event) (sendUserData)="sendUserData($event)" [(disabbleBtn)]="disabbleBtn"></app-check-otp>
  <div *ngIf="!isShowOtp" class="row mt10">
		<div class="col-md-12 col-xs-12">
      <button *ngIf="checkBtn" (click)="checkWalletPhone('phone')" class="login-btn col-md-12 col-xs-12 mt10 2" [ngClass]="eventPage? 'login-next' : 'login-next-box'"
              [class.disable]="(registerWalletForm.invalid && registerWalletForm.touched && registerWalletForm.dirty)"
              [disabled]="(registerWalletForm.invalid && registerWalletForm.touched && registerWalletForm.dirty)">
        <span i18n="signin button">Next</span>
      </button>
			<button *ngIf="!checkBtn" i18n="@@Registration" class="login-btn col-md-12 col-xs-12 mt10 3" [ngClass]="eventPage? 'login-next' : 'login-next-box'" type="submit"
              [class.disable]="(!registerWalletForm.valid && registerWalletForm.touched && registerWalletForm.dirty) || fetched || retryTime"
              [disabled]="(!registerWalletForm.valid && registerWalletForm.touched && registerWalletForm.dirty) || fetched || retryTime">Registration</button>
		</div>
	</div>
</form>

<div class="col-md-12 col-xs-12" *ngIf="!isShowOtp">
  <p class="login-subtitle text-center">
    <span i18n="already have an account?">{{'signup.Alreadyhaveanaccount' | translate:'Already have an account?'}}</span><br>
    <a class="signin_link" (click)="logInToggleEmit()">{{'signup.PleaseLogin' | translate:'Please Log in'}}</a>
  </p>
</div>
