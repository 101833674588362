import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-bank-warning-modal',
  templateUrl: './bank-warning-modal.component.html',
  styleUrls: ['./bank-warning-modal.component.css']
})
export class BankWarningModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
  }

}
