import {debounceTime, scan, takeWhile} from 'rxjs/operators';
import { LocalizationService } from './../../services/localization.service';
import { NgForm, AbstractControl, UntypedFormControl } from '@angular/forms';
import {Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, AfterViewInit, ElementRef} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
// -----------------------RxJS------------------------------
import {Subscription, timer} from 'rxjs';
// ------------------------Services-------------------------
import { GlobalsService } from '../../globals';
import { AuthService } from '../../services/auth.service';
import { NavbarService } from '../../services/navbar.service';
import { CookieService } from 'ngx-cookie-service';
import { DialogService } from '../../services/dialog.service';
// ---------------------------------------------------------
import { emailRegex } from '../../regex';
import {fade, slideIn, SlideInOutAnimation} from '../../animation';
import { EventService } from '../../services/event.service';
import { UserService } from '../../services/user.service';
import {WalletConnectService} from '../../services/wallet-connect.service';
import {RecaptchaEnterpriseService} from '../../services/recaptcha-enterprise.service';
import {AuthCacheService} from '../../services-cache/auth-cache.service';
import {MaxmindGeoIpService} from "../../services/maxmind-geoip.service";
import {IntercomService} from "../../services/intercom.service";

declare var window: any;

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  animations: [slideIn, fade, SlideInOutAnimation],
})

export class SigninComponent implements OnInit, OnDestroy, AfterViewInit {

  captchaToken: string = '';
  captchaV3Token: string = '';
  isShowOtp: boolean = false;
  @Input() userEmail: string;
  @Input() isCreateEvent: boolean;
  @Output() setCaptcha = new EventEmitter<string>()
  @Output() signIn = new EventEmitter<any>();
  @Output() signUp = new EventEmitter<any>();
  @Input() eventPage: boolean;
  @Output() currenUsertUrl = new EventEmitter<any>();
  @Output() showOtpChange = new EventEmitter();
  @Input() set showOtp(value) {
    this.isShowOtp = value;
  }
  // If user login and he is not existion in system, route to Registration
  @Output() signinAction = new EventEmitter<{ logInToggle: boolean, userNotExitsFields: {} }>();
  @Output() logInToggle = new EventEmitter<any>();
  @Output() selectedFlowEmit = new EventEmitter<any>();
  @Output() sendSmsTimerEmit = new EventEmitter<number>();

  isDisabbleBtn = {isDisable: false, otp: null};
  inputsOtp = [{0:''},{1:''},{2:''},{3:''}];
  disabbleBtn = {isDisable: false, otp: null};
  interval;
  timeLeft: number;
  timeCounter: number = null;
  // userSigninForm: any;
  ipInfo: any;
  isPhone: boolean = false;
  selectedFlow: 'phone'|'email'|'p_key'| '' = '';

  currentUserType: number;
  currentUserSubscription: Subscription;
  phone_email: string = '';
  signin: string ='';
  facebook_id: number;
  google_id: number;
  first_name: string;
  last_name: string;
  gender: string;
  locale: string;
  friends_list: string;
  Gimage: string;
  date = new Date();
  fetched : boolean = false;
  retryTime: number = 0;
  attemps: boolean = false;
  emailRegex = emailRegex;
  loginShow: boolean = true;
  passwordMissing: boolean = false;
  urlToNavigate = '';
  limit_signin = 0;

  private phone_email_query: any;
  private message_query: any;

  testOnlyOtpToken = '';
  counter = 0;
  formSubmited = false;

  viewInited: boolean = false;

  captchaV3TokenSubscription: Subscription;
  loginType: string = 'V2';
  csrf_token: string = '';
  @ViewChild('recaptcha_signin') public recaptcha_signin: ElementRef;

  @ViewChild('userSigninForm') userSigninForm: NgForm;
  showPasswordInput: boolean = false;
  sendSmsTimer: number = 0;
  isSendAgain = false;
  isSendAgainAttempts: number = 0; // max 3 Attempts


  constructor(
    private userService: UserService,
    public nav: NavbarService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _cookieService:CookieService,
    private globals: GlobalsService,
    private eventService: EventService,
    private dialogService: DialogService,
    public localizationService: LocalizationService,
    private walletConnectService: WalletConnectService,
    private recaptchaService: RecaptchaEnterpriseService,
    private authCacheService: AuthCacheService,
    private maxmindGeoIpService: MaxmindGeoIpService,
    private intercomService: IntercomService) {
      this.date.setDate(this.date.getDate() + 7); // 7 days from now

      const IPdata = this.localizationService.getIpInfo();
      // console.log('getIpInfo: ', IPdata);
      if (!IPdata) {
        this.localizationService.ourLatLng.subscribe(info => {
          console.log('localizationService: ', info);
          this.ipInfo = info;
        });
      } else {
        this.ipInfo = IPdata;
      }
  }


  ngOnInit() {
    this.urlToNavigate = this.userService.currentUrl;
    this.currenUsertUrl.emit(this.urlToNavigate);
    // console.log('app-signin', this.currentUserType, this.urlToNavigate, this.isCreateEvent);
    if(this.currentUserType) {
      // console.log('currentUserType', this.currentUserType);
      if(this.currentUserType === 2) {
        this.router.navigate(['/cms/dashboard/pending']);
      } else {
        this.router.navigate(['/']);
      }
    }
    if(this._cookieService.get('restricted5')) {
      // disable additional login attempts:
      this.attemps = true
    }
    this.phone_email_query = this.activatedRoute.queryParams.subscribe((params: Params) => {
      let phone_email = params['phone_email'];
      this.phone_email = phone_email;
    });
    this.message_query = this.activatedRoute.params.subscribe(params => {
      if(+params['id'] == 1){
        this.dialogService.alertsModal('success', 'passwordUpdated', 5000, true);
      }
    });
    if (this.currentUserSubscription) {
      this.currentUserSubscription.unsubscribe();
    }
    this.currentUserSubscription = this.nav.currentUserType.subscribe(type => this.currentUserType = +type);
    this.captchaV3TokenSubscription = this.recaptchaService.token.subscribe(token => {
      this.captchaV3Token = token;
      this.fetched = false;
    })

    // this.maxmindGeoIpService.getGeoData().subscribe(res => {
    //   console.log(res);
    // })
  }

  ngOnDestroy() {
    if(this.phone_email_query){
      this.phone_email_query.unsubscribe();
    }
    if(this.message_query){
      this.message_query.unsubscribe();
    }
    if (this.currentUserSubscription) {
      this.currentUserSubscription.unsubscribe();
    }
    if (this.captchaV3TokenSubscription) {
      this.captchaV3TokenSubscription.unsubscribe();
    }
    this.captchaV3Token = '';
    this.csrf_token = '';
    this.recaptchaService.setToken('');
  }

  setTimeCounter(value) {
    this.timeCounter = value;
    // console.log('setTimeCounter', value);
  }

  convertToFormControl(absCtrl: AbstractControl | null): UntypedFormControl {
    const ctrl = absCtrl as UntypedFormControl;
    return ctrl;
  }

  onChangePhone(event, form, type) {
    this.counter++;
    const formValid = this.userSigninForm.form.controls['phone_email'].valid;
    // console.log('onChangePhone',formValid, form.valid, type, this.userSigninForm.controls['phone_email'].valid, event.target.value, this.userSigninForm);
    this.userEmail = event.target.value;
    this.userSigninForm.form.controls['phone_email'].setValue(event.target.value);
    // this.userSigninForm.form.controls['phone_email'].markAsTouched();
    this.userSigninForm.form.updateValueAndValidity();
    // this.counter == 1 ? this.formChangeSub() : false;
  }

  formChangeSub() {
    this.userSigninForm.form.controls['phone_email'].valueChanges.pipe(
      debounceTime(800)).subscribe((input) => {
      this.userSigninForm.controls['phone_email'].markAsTouched();
      this.userSigninForm.form.markAllAsTouched();
      const hiddenBtn =  <HTMLButtonElement>document.getElementsByClassName('continue-btn')[0];
      hiddenBtn? hiddenBtn.focus(): false;
      const phoneInput =  <HTMLInputElement>document.querySelector('#phoneInput');
      // const testInput  = <any>document.getElementById('phoneInput');
      phoneInput ? phoneInput.focus() : false;
      phoneInput ? phoneInput.blur() : false;
      // console.log('onChange valueChanges', ngTelInput, input, hiddenBtn,);
    });
  }

  setRealPhoneNumber(phoneNumber, form) {
    // console.log("setRealPhoneNumber", phoneNumber, form, this.userSigninForm);
    // this.userSigninForm = form;
    this.userSigninForm.value.phone_email = phoneNumber;
		this.userSigninForm.form.controls['phone_email'].patchValue(phoneNumber);
    this.userSigninForm.form.updateValueAndValidity();
	}
	telInputObject(obj, userPhone) {
		// this.obj = obj;
		if(userPhone) {
			obj.setNumber(userPhone);
		}
		obj ? this.ipInfo.country_code = obj.s.iso2 : false;
	}
	onCountryChange(obj) {
		obj ? this.ipInfo.country_code = obj.iso2 : false;
    // console.log('onCountryChange', obj);
	}
	setErrorOnPhoneNumber(hasErrorfromNg2TelInput, form) {
		// const hasError = this.globals.hasErrorOnPhoneNumber(hasErrorfromNg2TelInput, this.userSigninForm.form.controls['phone_email'].value);
		// const actionOnSetErrors = hasError ? {'invalid': true} : null;
		// this.userSigninForm.form.controls['phone_email'].setErrors(actionOnSetErrors);
    // this.userSigninForm.form.controls['phone_email'].markAsTouched();
    // this.userSigninForm.form.updateValueAndValidity();
    // this.userSigninForm.form.markAllAsTouched();
		// console.log('setErrorOnphoneNumber', hasError, actionOnSetErrors, hasErrorfromNg2TelInput, this.userSigninForm.form.controls['phone_email'].value);
    console.log('setErrorOnPhoneNumber', hasErrorfromNg2TelInput);
    // console.log('setErrorOnPhoneNumber', form, this.userSigninForm, hasErrorfromNg2TelInput);
    if(hasErrorfromNg2TelInput) {
      this.userSigninForm.form.controls['phone_email'].setErrors({'invalid': true});
    }
  }

  setRegType(type) {

    this.userSigninForm.form.controls['phone_email'].patchValue('');
    this.selectedFlow = type;
    this.isPhone = type == 'phone' ? true : false;
    this.selectedFlowEmit.emit(this.selectedFlow);
  }

  sendUserData(event) {
    const otp = event;
    console.log('sendUserData', event);
    // event ? this.onSignin(this.userSigninForm, otp) : false; // OLD variant SIGN IN only
    event ? this.logregCheckContinue(otp) : false;

  }

  setTokenCaptcha(token) {
    this.captchaToken = token;
    this.setCaptcha.emit(token);
    token ? this.globals.stopLoader() : false;
    console.log('setTokenCaptcha', token? token.substring(token.length-11,token.length-1):'no token'); // send to backend with key: g-recaptcha-response
  }


  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  logregCheckContinue(otp, isAgain?, f?: NgForm) {
    // this.userSigninForm = f;
    this.isSendAgainAttempts = 0;
    // console.log('logregCheckContinue OTP: ',otp,' isAgain: ',isAgain,' isPhone:', this.isPhone, f);
    if (f && f.invalid) {
      return
    }
    if(
      (this.isPhone && f && !f.value['phone_email'])
      || (this.isPhone && f && f.value['phone_email'] && f.value['phone_email'].length < 7)
    ) {
      return;
    }

    if (this.loginType == 'V3' && !this.captchaV3Token && this.csrf_token) { // V3 badge is not checked
      this.dialogService.alertsModal('sendProducerEmail', 1, 2000, false);
      return;
    }
    this.startRetryTimer();

    // LOGIN V3 - additional capthca badge
    if (this.loginType == 'V3') {
      // this.preSignUp(this.captchaV3Token, 'V3');  // OLD variant SIGN IN only
     this.checkUser(this.captchaV3Token, 'V3', otp, isAgain);
    }

    // LOGIN V2  - Default
    if (this.loginType == 'V2') {
      this.recaptchaService.execute('LOGIN').then(token => {
        // this.preSignUp(token, 'V2'); // OLD variant SIGN IN only
       this.checkUser(token, 'V2', otp, isAgain);
      });
    }

    // to show OTP window for dev-nt
    // this.isShowOtp = true; // test purpose
    // this.showOtpChange.emit(true); // test purpose

  }

  preSignUp(token, type) {
    this.globals.startLoader();
    this.fetched = true;
    const cc = this.ipInfo && this.ipInfo.country_code ? this.ipInfo.country_code : 'il';
    this.authService.preSignUp('login', this.userSigninForm.value.phone_email, this.userSigninForm.value.password, cc, this.csrf_token, token, type).subscribe((response: any) => {
      this.globals.stopLoader();
      this.fetched = false;
      console.log('preSignIn', response);
      this.csrf_token = response && response.original.csrf_token ? response.original.csrf_token : this.csrf_token;
      if (response && (response.code == 'success' || (response.original.success && response.original.success !== 'letsGoHome'))) {
        this.isShowOtp = true;
        this.testOnlyOtpToken = response.original.token;
        this.showOtpChange.emit(true);

        // 3 attempts to SEND OTP
        if(this.isSendAgain) {
          this.startOTPTimer(true);
        }
        // console.log('Response success and open otp inputs');
      } else if (response && (response.original.success === 'letsGoHome')){
        console.log('letsGoHome SignIn');

        // Intercom boot
        let active_events = 0;
        if(response.user_data && response.user_data.active_events) {
          active_events = response.user_data.active_events;
        }
        this.intercomService.boot((active_events && window.innerWidth >= 768) ? false : true);

        if (response.auth.session && response.auth.refresh) {
          this.authService.storeTokens(response.auth.session, response.auth.refresh)
        } else {
          this.dialogService.alertsModal('errors', response.original.error, 3000, false);
          return;
        }

        this.checkResponse(response);

      } else if (response && (response.code != 'success' || response.original.error)) {
        if (type == 'V2' && response.original.error !== 53) {
          this.csrf_token = '';
        }
        if (response.original.error === 20) { // console.log('user do not exists');
          this.signinAction.emit({ logInToggle: true, userNotExitsFields: {
              phone_email: this.userSigninForm.value.phone_email,
              password: this.userSigninForm.value.password,
              phone_country_code: cc,
              type: this.isPhone ? 'phone' : ''
            }});
        } if (response.original.error === 53) {
          this.loginType = 'V3'; // if we receive 53 from V2 other requests should be from V3
          this.dialogService.alertsModal('errors', 53, 5000, false);
          this.recaptchaService.render('recaptcha_signin', 'LOGIN').then(() => {
            if(this.recaptcha_signin){this.recaptcha_signin.nativeElement.style.visibility = "visible";}
          });

        } else if (response.original.error === 41) {
          this.dialogService.alertsModal('success', 'loginAttemptsBlocked1hour', 5000, true);
        } else if (response.original.error === 54) {
          this.csrf_token = '';
          this.recaptchaService.reset().then(() => { // reset badge
            this.recaptchaService.setToken('');
            this.recaptcha_signin.nativeElement.style.visibility = "hidden";
          })
        } else {
          this.dialogService.alertsModal('errors', response.original.error, 5000, false);
          if (type == 'V3') {
            this.recaptchaService.reset().then(() => { // reset badge
              this.recaptchaService.setToken('');
            })
          }
        }
      } else {
        console.log('Do Not know the error!');
      }
    }, error => {
      this.fetched = false;
      this.globals.stopLoader();
    });
  }

  onSignin(form, otp?){
    form.form.removeControl('captcha'); // Prevent errors on OTP code form enter
    // console.log('Send user data and OTP', otp, this.disabbleBtn.otp, form.valid, form, this.userSigninForm);
    const cc = this.ipInfo && this.ipInfo.country_code ? this.ipInfo.country_code : 'il' ;
    this.passwordMissing = false;

    if(form.valid){
      if((this.showPasswordInput && !form.value.password) && !this.isPhone) {
        this.passwordMissing = true;
        return;
      }
      if(this._cookieService.get('restricted5')) {
        // disable additional login attempts:
        this.attemps = true
        // TODO - send email API:
        return;
      }
      this.globals.startLoader();
      this.fetched = true;
      // console.log('start sign is service');
      // this.recaptchaService.execute('LOGIN').then(token => {
        this.authService.preSignUpSignIn('login',form.value.phone_email, form.value.password, cc, this.csrf_token,  otp, this.loginType)
          .subscribe(
            tokenData => {
              this.globals.stopLoader();
              this.fetched = false;
              if(tokenData.original.success) {
                window.requestPushTokenFromApp();

                console.log('preSignUpSignIn');
                // Intercom boot
                let active_events = 0;
                if(tokenData.user_data && tokenData.user_data.active_events) {
                  active_events = tokenData.user_data.active_events;
                }
                this.intercomService.boot((active_events && window.innerWidth >= 768) ? false : true);

                this.checkResponse(tokenData);

              } else if(tokenData.original.error) {
                if (tokenData.original.error == 35) {
                  this.isShowOtp = true;
                  this.isSendAgainAttempts = this.isSendAgainAttempts+1;
                } else {
                  this.isShowOtp = false;
                }
                this.dialogService.alertsModal('errors', tokenData.original.error, 5000, false)
                form.value.password ? this.limit_signin++ : false; // count only for email login

                if(this.limit_signin == 5) {
                  this.attemps = true;
                  // TODO - send email API:
                  // create cookie to track login attempts:
                  var expiration = new Date();
                  expiration.setTime(expiration.getTime() + (15*60*1000)); // add 15 minutes
                  this._cookieService.set('restricted5', 'true', {'expires': expiration, secure: ((location.protocol == 'https:') ? true : false)});
                  setTimeout(()=>{
                    window.location.replace(this.urlToNavigate);
                    // window.location.replace('/signin');
                  },901000); // refresh page after 15 minutes and 1 second (15*60*1000)
                }
                if(this.loginType == 'V3') {
                  this.recaptchaService.reset().then(() => { // reset badge
                    this.recaptchaService.setToken('');
                  })
                } // reset badge
              }
            },
            error =>  {
              this.isShowOtp = false;
              this.dialogService.alertsModal('errors', 4.1, 5000, false);
              this.fetched = false;
              if(this.loginType == 'V3') {
                this.recaptchaService.reset().then(() => { // reset badge
                  this.recaptchaService.setToken('');
                })
              }
              this.globals.stopLoader();
            }
          );
      // });

    } else {
      // When form not valid
      this.isShowOtp = false;
      this.globals.stopLoader();
      this.dialogService.alertsModal('errors', '3.1', 5000, false);
    }
  }


  checkUser(token, type, otp_code?, isAgain?) {
    // console.log('checkUser 1 this.userSigninForm', this.userSigninForm);
    this.globals.startLoader();
    this.fetched = true;
    let cc = this.ipInfo && this.ipInfo.country_code ? this.ipInfo.country_code : 'il';
    !cc ? cc = this.ipInfo.country_code : false;
    if (otp_code) {
      // this.recaptchaService.execute('LOGIN').then(token => {
      this.authService.preSignUpSignIn('', this.userSigninForm.value.phone_email, this.userSigninForm.value.password, cc, this.csrf_token, otp_code, this.loginType)
        .subscribe(
          (response: any) => {
            this.globals.stopLoader();
            this.fetched = false;
            if(response.original.success && !response.original.personal_info) {
              console.log('tokenData no personal_info');
              if (response.auth.session && response.auth.refresh) {
                this.authService.storeTokens(response.auth.session, response.auth.refresh)
              } else {
                this.dialogService.alertsModal('errors', response.original.error, 3000, false);
                return;
              };
              this.authService.saveUserCookies(response.user_data, response.original.facebook_id);
              this.authCacheService.clearCache();
              // this.nav.checkUser();

              if (response.original && response.original.uid) {
                // this.nav.UpdateUser(parseInt(response.original.uid), response.user_data.type, response.original.facebook_id, false)
              }

              this.isShowOtp = false;
              // TODO - 1 - UserRegistration
              window.requestPushTokenFromApp();
              this.signUp.emit();
              setTimeout(() => {
                this.dialogService.alertsModal('success', response.original.success, 5000, true);
                this.dialogService.personalInfo(response, true);
              }, 1500);

              const isSuccess = response.original.success == 'letsGoHome';
              !isSuccess ? this.dialogService.alertsModal('success', response.original.success, 3000, true) : false;
              isSuccess ? this.dialogService.alertsModal('success', 'signUpSuccess', 3000, true) : false;

            } else if (response.user_data && response.user_data.personal_info) {
              if (response.auth.session && response.auth.refresh) {
                this.authService.storeTokens(response.auth.session, response.auth.refresh)
              } else {
                this.dialogService.alertsModal('errors', response.original.error, 3000, false);
                return;
              };

              this.authService.saveUserCookies(response.user_data, response.original.facebook_id);
              this.authCacheService.clearCache();
              this.nav.checkUser();
              if (response.original && response.original.uid) {
                this.nav.UpdateUser(parseInt(response.original.uid), response.user_data.type, response.original.facebook_id, false)
              };
              this.isShowOtp = false;
              console.log('Login with OTP');
              // TODO - 2 User Login
              this.signIn.emit();
              const isSuccess = response.original.success == 'letsGoHome';
              let active_events = 0;
              if(response.user_data && response.user_data.active_events) {
                active_events = response.user_data.active_events;
              }
              this.intercomService.boot((active_events && window.innerWidth >= 768) ? false : true);

              !isSuccess ? this.dialogService.alertsModal('success', response.original.success, 3000, true) : false;
              isSuccess ? this.dialogService.alertsModal('success', 'readerLogin', 3000, true) : false;

            } else if(response.original.error){
              if (response.original.error == 35) {
                this.isShowOtp = true;
                this.isSendAgainAttempts = this.isSendAgainAttempts+1;
              } else {
                this.isShowOtp = false;
              }
              this.captchaToken = '';
              this.dialogService.alertsModal('errors', response.original.error, 5000, false);
              if(this.loginType == 'V3') {
                this.recaptchaService.reset().then(() => { // reset badge
                  this.recaptchaService.setToken('');
                })
              }
            }
            this.globals.stopLoader();
          },
          error =>  {
            this.isShowOtp = false;
            this.dialogService.alertsModal('errors', 4.1, 5000, false);
            if(this.loginType == 'V3') {
              this.recaptchaService.reset().then(() => { // reset badge
                this.recaptchaService.setToken('');
              })
            }
            this.globals.stopLoader();
            this.fetched = false;
          }
        );
      // });

    } else {

      // this.recaptchaService.execute('LOGIN').then(token => {
      this.captchaToken = token;
      this.authService.preSignUp('', this.userSigninForm.value.phone_email, this.userSigninForm.value.password, cc, this.csrf_token,token, type, 0).subscribe((response: any) => {
        this.globals.stopLoader();
        this.fetched = false;
        // console.log('preSignUp', response);
        this.csrf_token = response && response.original.csrf_token ? response.original.csrf_token : this.csrf_token;
        if (response && (response.code == 'success' || (response.original.success && response.original.success !== 'letsGoHome'))) {
          isAgain ? this.dialogService.alertsModal('success', 'OtpSendAgain', 3000, true) : false;
          this.isShowOtp = true;
          this.testOnlyOtpToken = response.original.token;
          // console.log('Response success and open otp inputs');

          // 3 attempts to SEND OTP
          console.log('isSendAgain', this.isSendAgain)
          if(this.isSendAgain) {
            this.startOTPTimer(true);
          }

        } else if(response && (response.original.success === 'letsGoHome')) {
          console.log('letsGoHome user-login');
          if (response.auth.session && response.auth.refresh) {
            this.authService.storeTokens(response.auth.session, response.auth.refresh)
          } else {
            this.dialogService.alertsModal('errors', response.original.error, 3000, false);
            return;
          };
          this.authService.saveUserCookies(response.user_data, response.original.facebook_id);
          this.authCacheService.clearCache();
          this.nav.checkUser();
          if (response.original && response.original.uid) {
            this.nav.UpdateUser(parseInt(response.original.uid), response.user_data.type, response.original.facebook_id, false)
          };

          this.isShowOtp = false;
          // TODO - 3 - Login User - With Password
          this.signIn.emit();
          const isSuccess = response.original.success == 'letsGoHome';
          let active_events = 0;
          if(response.user_data && response.user_data.active_events) {
            active_events = response.user_data.active_events;
          }
          this.intercomService.boot((active_events && window.innerWidth >= 768) ? false : true);

          !isSuccess ? this.dialogService.alertsModal('success', response.original.success, 3000, true) : false;
          isSuccess ? this.dialogService.alertsModal('success', 'readerLogin', 3000, true) : false;

        } else if (response && (response.code != 'success' || response.original.error)) {
          // V2 and V3 captcha switch
          // ---------------------
          if (type == 'V2' && response.original.error !== 53) {
            this.csrf_token = '';
          }
          if (response.original.error === 53) {
            this.loginType = 'V3'; // if we receive 53 from V2 other requests should be from V3
            this.dialogService.alertsModal('errors', 53, 5000, false);
            this.recaptchaService.render('recaptcha_userlogin', 'LOGIN').then(() => {
              if(this.recaptcha_signin){this.recaptcha_signin.nativeElement.style.visibility = "visible";}
            });

          } if (response.original.error === 54) {
            this.csrf_token = '';
            this.recaptchaService.reset().then(() => { // reset badge
              this.recaptchaService.setToken('');
              this.recaptcha_signin.nativeElement.style.visibility = "hidden";
            })
          }  else {
            this.dialogService.alertsModal('errors', response.original.error, 5000, false);
            if (type == 'V3') {
              this.recaptchaService.reset().then(() => { // reset badge
                this.recaptchaService.setToken('');
              })
            }
          }
          // V2 and V3 captcha switch END
          // ---------------------
        }  else {
          console.log('Do Not know the error!');
          this.dialogService.alertsModal('errors', response.original.error, 5000, false);
        }
      });
      // });
    }
  }


  checkResponse(res) {
    if(+res.user_data.type === 2) {
      // console.log('user_data.type', tokenData);
      if (res.auth.session && res.auth.refresh) {
        this.authService.storeTokens(res.auth.session, res.auth.refresh);
      } else {
        this.dialogService.alertsModal('errors', res.original.error, 3000, false);
        return;
      };
      this.authService.saveUserCookies(res.user_data, res.original.facebook_id);
      let user_data = this.authService.emptyFacebookObj;
      user_data.data = res.user_data;
      user_data.personal_info = res.original.personal_info;
      this.dialogService.alertsModal('success', res.original.success, 2000, true);
      this.nav.checkUser();
      // this.nav.UpdateUser(tokenData.original.uid,tokenData.user_data.type, tokenData.original.facebook_id, true);
      this.nav.updateUserProfileImage(res.user_data.social_img);
      // this.router.navigate(['/cms/dashboard/pending']);
      if (!this.isCreateEvent && res.original.personal_info) {
        this.walletConnectService.shadowCheckPendingTransactionByUser();
        console.log('urlToNavigate');
        // setTimeout(() => {
        //   window.location.replace(this.urlToNavigate);
        // }, 2000); // delay to show welcome message first
      }

      this.signIn.emit({urlToNavigate: this.urlToNavigate});
    } else {
      if(res.original.success && res.original.personal_info) {
        if (res.auth.session && res.auth.refresh) {
          this.authService.storeTokens(res.auth.session, res.auth.refresh);
        } else {
          this.dialogService.alertsModal('errors', res.original.error, 3000, false);
          return;
        }
        this.authService.saveUserCookies(res.user_data, res.original.facebook_id);
        this.dialogService.alertsModal('success', res.original.success, 2000, true);
        this.nav.checkUser();
        this.nav.UpdateUser(res.original.uid,res.user_data.type, res.original.facebook_id, false);
        if (!this.isCreateEvent) {
          if (this.urlToNavigate.includes('/wallet-exists/')) { // reload only for specific urls
            setTimeout(() => {
              window.location.replace(this.urlToNavigate);
            }, 1000); // delay to show welcome message first
          }
          // setTimeout(() => {
          //   window.location.replace(this.urlToNavigate);
          // }, 1500); // delay to show welcome message first
          // this.router.navigate([this.urlToNavigate]);
          // this.router.navigate(['/dashboard']);
          this.nav.checkUser(true);
        }
        this.signIn.emit({urlToNavigate: this.urlToNavigate});
      } else if (res.original.success && !res.original.personal_info) {
        console.log('tokenData no personal_info');
        if (res.auth.session && res.auth.refresh) {
          this.authService.storeTokens(res.auth.session, res.auth.refresh);
        } else {
          this.dialogService.alertsModal('errors', res.original.error, 3000, false);
          return;
        }
        // this.authService.saveUserCookies(tokenData.user_data, tokenData.original.facebook_id);
        this.dialogService.alertsModal('success', res.original.success, 2000, true);
        this.dialogService.personalInfo(res, true);
        // this.nav.UpdateUser(tokenData.original.uid,tokenData.user_data.type, tokenData.original.facebook_id, true);
        this.signIn.emit({urlToNavigate: this.urlToNavigate});
      } else {
        this.dialogService.alertsModal('errors', res.original.error, 5000, false);
        this.nav.checkUser();
        // this.nav.UpdateUser(tokenData.original.uid,tokenData.user_data.type, tokenData.original.facebook_id, true);
      }
      this.nav.updateUserProfileImage(res.user_data.social_img);
    }
  }

  resetPassword() {
    this.signIn.emit();
    this.router.navigate(['/reset-password']);
  }
  logInToggleEmit() {
    this.logInToggle.emit();
  }

  togglePassword(e) {
    // console.log(e.checked);
    if(!e.checked) {
      this.userSigninForm.controls['password'].setValue('');
    }
  }

  startOTPTimer(value) {
    clearInterval(this.interval);
    this.interval = null;
    const time = 1000;
    if(this.isPhone && this.isSendAgainAttempts >= 3) {return}
    if (value) {
      this.sendSmsTimer = 59;
      this.sendSmsTimerEmit.emit(this.sendSmsTimer);
      this.interval = setInterval( () => {
        if (this.sendSmsTimer > 0) {
          this.sendSmsTimer--;
          this.sendSmsTimerEmit.emit(this.sendSmsTimer);
        } else {
          clearInterval(this.interval);
          this.interval = null;
          this.isSendAgain = value;
          this.sendSmsTimer = 0;
          this.sendSmsTimerEmit.emit(this.sendSmsTimer);
        }
      }, time);
    } else {
      this.sendSmsTimer = 0;
      this.sendSmsTimerEmit.emit(this.sendSmsTimer);
    }
    // console.log('startTimer', this.sendSmsTimer, this.isSendAgain, value);
  }

  intercomMessage() {
    this.intercomService.message('')
  }

  showTab() {
    this.isShowOtp = false;
    this.setRegType(this.isPhone ? 'email' : 'phone'); // switch tab
  }

  startRetryTimer() {
    // Delay Timer
    const result$ = timer(0, 1000).pipe(
      scan(ticks => --ticks, 5),
      takeWhile(v => v >= 0)
    ); // 5 s Retry delay
    result$.subscribe(res => {
      this.retryTime = res;
    })
    // Delay Timer End
  }

  ngAfterViewInit() {
    setTimeout(()=>{
      this.viewInited = true;
    }, 100)
  }
}
