
import {empty as observableEmpty,  Observable , Subject, throwError, EMPTY} from 'rxjs';

import {switchMap, catchError, tap} from 'rxjs/operators';
import { environment } from './../environments/environment';
import {Injectable, Injector} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {AuthService} from "./services/auth.service";
import { GlobalsService } from './globals';
import {DialogService} from './services/dialog.service';

@Injectable()
export class JWTInterceptor implements HttpInterceptor {

  private authService: AuthService;

  refreshTokenInProgress = false;
  tokenRefreshedSource = new Subject<void>();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(private inj: Injector, private globals: GlobalsService, private dialogService: DialogService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const urlRegex = new RegExp(environment.api_base_url, 'g');
    if (request.url.match(urlRegex)) {
      this.authService = this.inj.get(AuthService); // prevent circular dependency injection
      if (this.authService.getToken()) {
        return this.handleAuthRequest(request, next);
      } else {
        return this.setLangHeaderAndSendRequest(request, next);
      }
    } else {
      return this.handleRequestWithoutCustomHeaders(request, next); // for request outside of getin domain
    }
  }

  handleRequestWithoutCustomHeaders(request, next) {
    return next.handle(request);
  }
  refreshToken(): Observable<HttpEvent<any>> {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      return this.authService.refreshToken().pipe(
        tap(() => {
          this.refreshTokenInProgress = false;
          // notify independent listeners the token was refreshed and it is safe to re-issue requests
          this.tokenRefreshedSource.next();
        }));
    }
  }

  logout() {
    this.authService.logout();
    // go to home screen
    window.location.replace('');
  }

  addCustomHeaders(request: HttpRequest<any>): HttpRequest<any>{
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authService.getToken()}`,
        'system-lang': this.globals.getLocaleId()
      }
    });
  }

  handleAuthRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // add auth header to request
    request = this.addCustomHeaders(request);
    return next.handle(request).pipe(catchError(error => {
      if (error.status === 401) {
        // attempt to refresh token
        return this.refreshToken().pipe(
        switchMap(() => {
          // token refreshed, retry original request
          request = this.addCustomHeaders(request);
          return next.handle(request);
        }),catchError((e) => {
          // failed to refresh, kick user out
            // e.code === 4.1 || e.code === 4.2 || e.code === 4.3
            // 4.1 - Not Valid Token, 4.2 - No User, 4.3 - User logged in from other device
            this.authService.clearUser();
            if (e.code === 4.3) {
              this.dialogService.alertsModal('tokenErr', e.code,3000,false);
              setTimeout(() => { window.location.replace(''); }, 3000);
            } else {
              window.location.replace('');
            }
              // this.logout();
          return observableEmpty();
        }),);
      }

      error.error.httpStatus = error.status;
      return throwError(error.error);
    }));
  }

  setLangHeaderAndSendRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        'system-lang': this.globals.getLocaleId()
      }
    });

    // if/else Statement to detect of token exist in cookies, if no we are sending 'refreshToken' request
    // console.log('this.refreshTokenInProgress', this.refreshTokenInProgress)
    if ((this.authService.getToken() === '' && this.authService.getRefreshToken()) && !this.refreshTokenInProgress) {
      // console.log('No Token', this.authService.getToken())
      this.refreshTokenInProgress = true;

      return this.authService.refreshToken().pipe(
        switchMap(() => {
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next();
          return next.handle(this.addCustomHeaders(request));
        }),
        catchError((error) => {
          this.authService.clearUser();
          window.location.reload();
          //  return EMPTY;
          return throwError(error.error);
        })
      );
    } else {
      return next.handle(request).pipe(catchError((error) => {
          error.error.httpStatus = error.status;
          return throwError(error.error);
        }))
    }
  }
}
