import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { slideIn } from '../../animation';
import { DialogService } from '../../services/dialog.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatSort } from '@angular/material/sort';
import { EventCategoryService } from '../../services/event-category.service';
import { GlobalsService } from '../../globals';
import { SelectionModel } from '@angular/cdk/collections';
import { EventCategories } from '../../interfaces/event-categories.interface';


@Component({
  selector: 'app-categories-event-modal',
  templateUrl: './categories-event-modal.component.html',
  styleUrls: ['./categories-event-modal.component.css'],
  animations: [slideIn]
})

export class CategoriesEventModalComponent implements OnInit {
  wasChanged: boolean;
  displayedColumns: string[] = ['select', 'id', 'name', 'status'];
  categoryList = new MatTableDataSource<EventCategories>([]);
  selection = new SelectionModel<EventCategories>(true, []);
  language;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dialogRef: MatDialogRef<any>,
    private dialogService: DialogService,
    private categoryService: EventCategoryService,
    private globals: GlobalsService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.getCategoriesByEvent(this.data.event_id);
    this.language = this.globals.getLocaleId();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.categoryList.filter = filterValue.trim().toLowerCase();
  }

  isAllSelected() {
      const numSelected = this.selection.selected? this.selection.selected.length : 0;
      const numRows = this.categoryList.data? this.categoryList.data.length : 0;
      return numSelected === numRows;
  }

  checkboxLabel(row?: EventCategories): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  masterToggle(event: MatCheckboxChange) {
    this.isAllSelected() ?
        this.selection.clear() :
        this.categoryList.data.forEach((row: any) => this.selection.select(row));
  }

  onSubmit() {
    const selected = [];
    this.selection.selected.forEach(category => {
      selected.push(category.id);
    });
    this.globals.startLoader();
    this.categoryService.setCategories(selected, this.data.event_id).subscribe(
      (response: any) => {
        this.globals.stopLoader();
        this.dialogRef.close({success: true, categories: selected});
        this.dialogService.alertsModal('success', 'success' , 2000, true);
      },
        error => {
          this.globals.stopLoader();
          this.dialogService.alertsModal('eventCategories', error.code, 3000, false, (error.log_id ? error.log_id : null));
    });
  }

  getCategoriesByEvent(event_id) {
    this.globals.startLoader();
    this.categoryService.getCategoriesByEvent(event_id).subscribe(
      (response: any) => {
        this.globals.stopLoader();
           const checkedCategories = this.data.categories.filter(category => {
              return response.categories.includes(category.id);
          });
          this.categoryList = new MatTableDataSource<EventCategories>(this.data.categories);
          this.selection = new SelectionModel<EventCategories>(true, checkedCategories, true);
          this.categoryList.sort = this.sort;
      },
        error => {
          this.globals.stopLoader();
          this.dialogService.alertsModal('eventCategories', error.code, 3000, false, (error.log_id ? error.log_id : null));
    });
  }

  ngOnInit() {
  }

  fixChanges(event: MatCheckboxChange): void {
    this.wasChanged = true;
  }

}
