import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { NavbarService } from '../../services/navbar.service';
import {BankInfoModalComponent} from '../../dashboard-sub-pages/finance/bank-info-modal/bank-info-modal.component';
import {BankWarningModalComponent} from './bank-warning-modal/bank-warning-modal.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: "app-pending-event",
  templateUrl: "./pending-event.component.html",
  styleUrls: ["./pending-event.component.css"],
})
export class PendingEventComponent implements OnInit {
  user_id;
  event_id;
  event_status;
  show_bank_modal;
  auto_approve = 'false';

  constructor(
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private zone: NgZone,
    private authService: AuthService,
    private navbarServise: NavbarService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    const navPanel = document.getElementById('footer');
    if (navPanel) {
      navPanel.style.display = 'none';
    }
    this.navbarServise.hide();
    const wrapper = document.getElementById('wrapper');
    if (wrapper) {
      wrapper.style.paddingTop = '0';
    }
    this.navbarServise.setIsNewUi(true);
    this.user_id = this.authService.getUser();

    if (!this.user_id) {
      this.userService.openLoginModal();
    }

    this.zone.run(() => {
      if (this.activatedRoute.params) {
        this.activatedRoute.params.subscribe((params) => {
          this.event_id = params['event_id'];
          this.event_status = params['event_status'];
          this.show_bank_modal = params['show_bank_modal'];
          this.auto_approve = params['auto_approve'];
          if (this.show_bank_modal == 'true') {
            this.openBankWarningModal();
          }

        });
      }
    });
  }

  openBankWarningModal() {
    const dialogRef = this.dialog.open(BankWarningModalComponent, {
      data: {event_status: this.event_status, auto_approve: this.auto_approve},
      panelClass: 'bank-warning-modal',
      backdropClass: 'mint-modal-bc',
      maxWidth: '494px',
    });
  }
}
