<div id="solana-modal-lightbox" class="solana-modal-lightbox" (click)="closeSolanaModal()" *ngIf="isOpenWallets">
  <div class="solana-modal-container">
    <div class="solana-modal-card">
      <div *ngFor="let wallet of wallets$ | async" class="solana-provider-wrapper" 
           (click)="wallet.readyState == 'Installed' ? onSetWallet(wallet.adapter.name, wallet.readyState, wallet) : wallet.adapter.name !== 'Ledger' ? onNotInstalledWallet(wallet.adapter, wallet) : false">
        <div class="solana-provider-container">
          <div class="solana-provider-icon"><img class="solana-wallet-icon" [src]="domSanitizer.bypassSecurityTrustResourceUrl(wallet.adapter.icon)"></div>
          <div class="solana-provider-name">{{wallet.adapter.name}}</div>
          <div class="solana-provider-description">{{ wallet.readyState }}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="solana-info-modal-lightbox--overlay" class="solana-modal-lightbox--overlay solana-modal-lightbox" 
     *ngIf="selectedWallet && selectedWallet.readyState !== 'Installed'"
     (click)="closeSolanaInfoModal()"></div>

<div id="solana-info-modal-lightbox"
  *ngIf="selectedWallet && selectedWallet.readyState !== 'Installed'">
  <!-- notInstalled message -->
  <div class="solana-modal-container">
    <button class="close-btn solana-modal-close" (click)="closeSolanaInfoModal()">
      <img class="x-close-modal" src="assets/images/svg-icons/close.svg" alt="close">
    </button>
    <div class="solana-modal-card">

      <div class="solana-provider-icon solana-provider-icon--xl">
        <img class="solana-wallet-icon"
          [src]="domSanitizer.bypassSecurityTrustResourceUrl(selectedWallet.adapter.icon)">
      </div>

      <span style="display: none;">{{ selectedWallet.readyState }}</span>
      
      <div class="solana-provider-description">
        <h3 i18n>Have you installed {{selectedWallet.adapter.name}}?</h3>
        <a *ngIf="selectedWallet.adapter.url" href="{{selectedWallet.adapter.url}}" rel="noopener noreferrer"
          target="_blank">
          <span i18n>Install {{selectedWallet.adapter.name}} </span>
          <i class="fa fa-external-link"></i>
        </a>
        <div class="solana-provider-instructions">
          <p i18n>On mobile:</p>
          <ul>
            <li i18n *ngIf="browseUniversalLink && ['Phantom', 'Solflare'].includes(selectedWallet.adapter.name)">You should <a target="_blank" href="{{browseUniversalLink}}">open</a> the app instead</li>
            <li i18n *ngIf="!browseUniversalLink || !['Phantom', 'Solflare'].includes(selectedWallet.adapter.name)">You should open the app instead</li>
          </ul>
        </div>
        <div class="solana-provider-instructions">
          <p i18n>On desktop:</p>
          <ul>
            <li i18n>Install and refresh the page</li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="wallets-wrapper" >
    <div class="wallets-container">
      <div class="wallet-content" *ngIf="!isSelectedWallet">
        <div i18n="Title page | Confirmation" class="wallet-title">Choose wallet</div>
        <div class="wallet-data-wrapper" [style]="!isConnected ? 'background: transparent;' : 'background: #22262F00;'" >
          <div class="reconnect-bar" *ngIf="solanaModalIsClose">
            <div class="disconnect-btn" (click)="openSolanaModal()" *ngIf="(connected$ | async) === false"><span i18n="Label | Reconnect to wallet" class="disconnect-text">ReOpen</span></div>
          </div>
        </div>
      </div>
      <div class="wallet-content" *ngIf="isSelectedWallet">
        <div i18n="Title page | Confirmation" class="wallet-title">Confirmation</div>
        <div *ngIf="transactionUrl" class="transaction-copy" (click)="copyToClipboard('url')">
          <div class="transaction-url">{{transactionUrl}}</div>
          <span i18n="Label | Copy URL to check payment" class="transaction-notice">Copy this URL to check payment later</span>
        </div>
        <div class="wallet-data-wrapper" [style]="!isConnected ? 'background: transparent;' : 'background: #22262F00;'" >
          <div class="wallet-connect" *ngIf="eventTicketsData && ticketsPrice">
            <div i18n="Label | Connect to wallet" *ngIf="!isConnected && showConnectBtn" (click)="!isSuccessful ? onConnect() : null" id="btn-connect" class="connect-with-btn" [style]="!isConnected && !isSuccessful? 'opacity: 1; cursor: pointer;' : 'opacity: 0.5; cursor: not-allowed;'">Connect wallet</div>
          </div>
          <!-- <div style="display: none;" class="reconnect-bar">
            <div  class="disconnect-btn" (click)="onConnect(selectedWallet)" *ngIf="(connected$ | async) === false"><span i18n="Label | Reconnect to wallet" class="disconnect-text">Reconnect</span></div>
          </div> -->
          <!-- <div style="display: none;" #wallettestbuttons *ngIf="eventTicketsData && ticketsPrice" class="wallet-connect">
            <input class="sol-input" type="text" [(ngModel)]="signature"/>
            <div class="wallet-connect" style="display: block;">
                <div id="btn-connect" i18n="button payment | Pay with WalletConnect" class="temp-btn" (click)="getTransactionStatus(signature, 'pending')">Get Trn</div>
            </div>
          </div> -->
          <div *ngIf="hasBalance" class="all-balances">
            <div class="account-balances" *ngFor="let account of accountBalances">
              <div class="balances-block-main">
                  <div class="price-block">
                    <div class="balance-data">
                      <div class="account-address">{{account?.address}}</div>
                      <div *ngIf="account?.state == 'Installed'" class="account-network">{{account?.name}}</div>
                    </div>
                    <!-- <div i18n="Label | Wallet disconnect" class="disconnect-btn" (click)="onDisconnect()" *ngIf="connected$ | async"><span class="disconnect-text">Disonnect</span></div> -->
                  </div>
              </div>
              <div class="balances-block-wrapper">
                <div class="account-block">
                    <div i18n="Label | Balance" class="balances-amount">Balance:</div>
                    <div class="account-prices">
                        <div class="account-balance" [style]="dir ==='ltr' ? 'font-weight: 600; width: 90%;' : 'font-weight: 600; width: auto;'">{{ account?.humanFriendlyBalance }} {{account?.shortName?.toUpperCase()}}</div>
                        <div class="account-balance" [style]="dir ==='ltr' ? 'font-weight: 600; width: 90%;' : 'font-weight: 600; width: auto;'">{{"$ "}}{{account?.balanceInUSD}}</div>
                    </div>
                </div>
                <div i18n="Error label | Insufficient funds" class="error-founds" *ngIf="!founds">Insufficient funds.</div>
              </div>
            </div>
          </div>
          <div *ngIf="hasBalance && eventTicketsData" class="wallet-transaction-params">
            <!-- <div class="rate-block-wrapper">
                <div class="price-block">
                    <span  class="wallet-transaction-gas" [style]="'width: auto;'">Gas: </span>
                    <span id="wallet-transactions" class="transaction-gas" [style]="dir ==='ltr' ? 'font-weight: 600; width: 90%;' : 'font-weight: 600; width: auto;'">{{transactionData?.gas | number: '1.0-12'}} {{' GWei'}}</span>
                </div>
            </div>
            <div class="rate-block-wrapper">
                <div class="price-block">
                    <span  class="wallet-transaction-gasPrice" [style]="'width: auto;'">Gas price: </span>
                    <span id="wallet-transactions" class="transaction-gasPrice" [style]="dir ==='ltr' ? 'font-weight: 600; width: 90%;' : 'font-weight: 600; width: auto;'">{{transactionData?.gasPriceEth | number: '1.2-12' }} {{chainData?.shortName.toUpperCase()}}</span>
                </div>
            </div> -->
            <div class="balances-block">
              <span i18n="Label | Max fee" class="wallet-transaction-maxFee" [style]="'width: auto;'">Max fee: </span>
              <div class="account-prices">
                  <span id="wallet-transactions" class="transaction-maxFee" [style]="dir ==='ltr' ? 'font-weight: 600; width: 90%;' : 'font-weight: 600; width: auto;'">{{ transactionData?.fee | number: '0.2-12' }} {{chainData?.shortName.toUpperCase()}}</span>
                  <span *ngIf="!isUsd" id="wallet-transactions" class="transaction-maxFee" [style]="dir ==='ltr' ? 'width: 90%;' : 'width: auto;'">{{eventTicketsData?.payment_details?.currency + ' '}}{{eventCurRate*transactionData.fee | number: '0.2-4' }}</span>
                  <span id="wallet-transactions" class="transaction-maxFee" [style]="dir ==='ltr' ? 'width: 90%;' : 'width: auto;'">{{'$ '}}{{usdCurRate*transactionData.fee | number: '0.2-4' }}</span>
              </div>
          </div>
            <div class="balances-block" style="border-bottom: none;">
                <span *ngIf="globals.shouldBePlural(eventTicketsData?.event_details?.totalTickets)" i18n="Label | Ticket prices" class="wallet-transaction-ticket" [style]="'width: auto;'">Tickets price: </span>
                <span *ngIf="!globals.shouldBePlural(eventTicketsData?.event_details?.totalTickets)" i18n="Label | Ticket price" class="wallet-transaction-ticket" [style]="'width: auto;'">Ticket price: </span>
                <div class="account-prices">
                    <span id="wallet-transactions" class="transaction-ticket" [style]="dir ==='ltr' ? 'font-weight: 600; width: 90%;' : 'font-weight: 600; width: auto;'">{{ ticketsPrice }} {{chainData?.shortName.toUpperCase()}}</span>
                    <span *ngIf="!isUsd" id="wallet-transactions" class="transaction-ticket" [style]="dir ==='ltr' ? 'width: 90%;' : 'width: auto;'">{{eventTicketsData?.payment_details.currency + ' '}} {{eventTicketsData?.payment_details.payment}}</span>
                    <span id="wallet-transactions" class="transaction-ticket" [style]="dir ==='ltr' ? 'width: 90%;' : 'width: auto;'">{{'$ '}} {{eventTicketsData?.payment_details?.payment/eventCurRate*usdCurRate | number: '0.2-2'}}</span>
                </div>
            </div>
          </div>
          <div  *ngIf="hasBalance && eventTicketsData" class="balances-total">
            <span i18n="Label | Total price" class="wallet-transaction-total" [style]="'width: auto;'">Total price: </span>
            <div class="account-prices">
                <span id="wallet-transactions" class="transaction-total" [style]="dir ==='ltr' ? 'font-weight: 500; width: 90%;' : 'font-weight: 500; width: auto;'">{{ transactionData?.ticketPriceTotal | number: '0.2-12' }} {{chainData?.shortName.toUpperCase()}}</span>
                <span *ngIf="!isUsd" id="wallet-transactions" class="transaction-total" [style]="dir ==='ltr' ? 'width: 90%;' : 'width: auto;'">{{eventTicketsData?.payment_details?.currency + ' '}}{{eventCurRate*transactionData?.ticketPriceTotal | number: '0.2-2' }}</span>
                <span id="wallet-transactions" class="transaction-total" [style]="dir ==='ltr' ? 'width: 90%;' : 'width: auto;'">{{'$ '}}{{usdCurRate*transactionData?.ticketPriceTotal | number: '0.2-2' }}</span>
            </div>
          </div>
          <div *ngIf="isCheckTrnStatus" class="transaction-status">
            <div *ngIf="transaction?.hashPrew" class="hash-wrapper">
                <div class="hash-title">Transaction Hash: </div>
                <div id="hashPrew" class="hash-prew" (click)="copyToClipboard('hash')"> {{ transaction?.hashPrew}}</div>
            </div>
            <!-- <div *ngIf="dbTransactionState === 'stopped'" class="error-message">Something went wrong, please save the transaction hash and contact Getin support</div> -->
            <!-- <div *ngIf="transaction?.hash" class="trn-message">Transaction Hash: {{transaction?.hash}}</div> -->
            <div *ngIf="transaction?.blockhash" class="status-bar">
              <div class="trn-title">Transaction Blockhash: </div>
              <div class="transaction-hash">&nbsp;{{ transaction?.blockhash}}</div>
            </div>

            <div class="status-bar">
              <div i18n="Label | Transaction status" *ngIf="transaction?.message" class="trn-message" style="white-space: normal;">Transaction Status: {{transaction?.message}}</div>
              <div *ngIf="isChecking" class="spinner-dual-ring checking-status"></div>
            </div>
            <div *ngIf="isChecking" class="status-message">
              <span class="checking-message">Your order for event has been processed. It should be confirmed on the blockchain shortly.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
