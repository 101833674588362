<section class="reader-participants">
<!--  <app-go-back [bigArrow]="true" [top]="15"></app-go-back>-->
	<input type="hidden" style="min-height: 50vh;" (document:keydown)="scanQrCode($event)">

	<div [ngClass]="{'hideTopBar': !show_top_bar}" class="reader-top-bar row_ mb20 p10 border-bottom_">
		<div class="top-bar-section">
					<div class="first-section">
						<div class="reader-number">
							<label i18n="@@ReaderParticipantScannernumber" class="label-txt">Scanner number:</label>
							<div class="reader-num">{{reader_id}}</div>
						</div>
						<div class="reader-total-scanned" >
							<label i18n="@@ReaderParticipantScannedusingthisdevice" class="label-txt">Scanned using this device</label>
							<div class="reader-num">{{reader_participants_scaned}}</div>
						</div>
						<div *ngIf="participants" class="event-total-scanned">
							<label class="label-txt">
                <span i18n="@@ReaderParticipantTotalscansoutof">Total scans out of</span>
								<span style="display: inline;">{{participants.length}}
									({{(((event_participants_scaned) / (participants.length))*100).toFixed(0)}}%)
								</span>
							</label>
							<div class="reader-num">{{event_participants_scaned}}</div>
						</div>
						<div *ngIf="!participants" class="event-total-scanned">
							<label class="label-txt">
                <span i18n="@@ReaderParticipantTotalscansoutof">Total scans out of</span> <span class="label-txt_">0 (0%)</span></label>
							<div class="reader-num">{{event_participants_scaned}}</div>
						</div>
					</div>
					<div class="scanners-delimiter is-mobile"></div>
					<div class="second-section">
						<div class="participants-search">
							<label i18n="@@ReaderParticipantAttendeelookup" class="label-txt">Attendee lookup:</label>
							<input i18n-placeholder="@@ReaderParticipantSearchAttendees" class="form-control" type="text"
								[(ngModel)]="filterText" name="search" placeholder="Search attendees">
						</div>
						<div class="reload-users" (click)="getReaderData()">
							<!-- <img width="25" src="/assets/images/dashboard/Refresh.png"> -->
							<div i18n="@@ReaderParticipantLoadnewattendees" class="refresh-reader">Load new attendees</div>
						</div>
						<div [hidden]="!inApp" class="start-scanner">
							<div id="qr" class="scan-qrcode" (click)="startScannerOnApp()">&nbsp; SCAN QR CODE &nbsp;
								<i class="fa fa-qrcode"></i></div>
						</div>
						<div class="reader-logout">
							<button i18n-title="@@ReaderParticipantLogoutfromscanner" title="Logout from scanner" type="button" class="logout-btn readerLogout"
								(click)="onLogout()">
								<!-- <img width="25" src="/assets/images/navbar/Logout.png"> -->
								<i class="fa-logout fa fa-sign-out" aria-hidden="true"></i>
							</button>
						</div>
					</div>
		</div>
		<!-- <div class="col-md-3 col-xs-12">
			<div class="col-md-6 col-xs-6 mt15">
				<img class="img-responsive mutate-float-left" src="/assets/images/logo.png">
			</div>
			<div class="col-md-6 col-xs-6 text-center border-left">
				<p i18n="top bar labels | reader number" class="text-center mb0">מספר עמדה:</p>
				<p class="text-center mb0">{{reader_id}}</p>
			</div>
		</div> -->

		<!-- <div class="col-md-3 col-xs-12 sum_tabs text-center p10">
			<div class="col-md-6 col-xs-6"  style="display: inline-block">
				<span>{{reader_participants_scaned}}</span>
				<span i18n="top bar labels | total scanned in this reader">נסרקו בעמדה זו</span>
			</div>
			<div *ngIf="participants" class="col-md-6 col-xs-6" style="display: inline-block">
				<span>{{event_participants_scaned}}</span>
				<span i18n="top bar labels | total scanned in all readers">נסרקו סה"כ מתוך</span>&nbsp; <--Total scans out of
				<span style="display: inline;">{{participants.length}} ({{(((event_participants_scaned) / (participants.length))*100).toFixed(0)}}%)</span>
			</div>
		</div> -->

		<!-- <div [hidden]="!inApp" class="col-md-12 col-xs-12">
			<button id="qr" class="btn btn-danger center-block" style="width:100%;" (click)="startScannerOnApp()">SCAN QR CODE <i class="fa fa-qrcode"></i></button>
		</div> -->

		<!-- <div class="col-md-6 col-xs-12 search-bar-align">
			<div class="col-md-6 col-xs-12 mutate-float-right p0">
				<label i18n="top bar labels | participants search bar" class="regular-font ft-16">חיפוש משתתפים:</label>
				<input i18n-placeholder="placeholder | search for participants" class="form-control" type="text" [(ngModel)]="filterText" name="search" placeholder="חיפוש משתתפים">
			</div>
			<div class="col-md-6 col-xs-12 text-center pt10 reload-bar-align">
				<div class="col-md-9 col-xs-9 pr0 reload_users mutate-float-right" (click)="getReaderData()">
					<img width="25" src="/assets/images/dashboard/Refresh.png">
					<span i18n="top bar labels | refresh reader button" class="mr5">טעינת משתתפים חדשים</span>
				</div>
				<div class="col-md-3 col-xs-3 pl0">
					<button i18n-title="logout from reader" title="התנתק מהסורק" type="button" class="readerLogout" (click)="onLogout()"><img width="25" src="/assets/images/navbar/Logout.png"></button>
				</div>
			</div>
		</div> -->
		<div class="scanners-delimiter is-desktop"></div>
	</div>


	<div *ngIf="showConfirmedParticipant" class="user_top" [ngClass]="{'border-bottom-green': confirmedParticipant?.scan_status == 1 && responseCode != 6, 'border-bottom-red': confirmedParticipant?.scan_status != 1 || (confirmedParticipant?.scan_status == 1 && responseCode == 6)}" dir="rtl">

		<div class="confirmed-participant pr15 pl15">
			<div class="col-md-12 col-xs-12 success_header pt5 pb5" [ngClass]="{'close_user_top': true, 'bg-green': confirmedParticipant?.scan_status == 1 && responseCode != 6, 'bg-red': confirmedParticipant?.scan_status != 1 || (confirmedParticipant?.scan_status == 1 && responseCode == 6)}">
				<p i18n="@@ReaderParticipantAdmittanceapproved" *ngIf="confirmedParticipant?.scan_status == 1 && responseCode != 6" class="success_title text-center">Admittance approved</p>
				<p i18n="@@ReaderParticipantalreadyscanned" *ngIf="confirmedParticipant?.scan_status == 1 && responseCode == 6" class="success_title text-center">This participant had already been scanned</p>
				<p i18n="@@ReaderParticipantdmittancecancelled" *ngIf="confirmedParticipant?.scan_status != 1" class="success_title text-center">Attendee&apos;s admittance cancelled</p>
				<!-- <div [class]="confirmedParticipant?.scan_status != 1 ? 'admittance-declined' : 'admittance-approved'"></div> -->
				<div class="admittance-status">
					<img *ngIf="confirmedParticipant?.scan_status == 1" width="24" src="/assets/images/success.png">
					<img *ngIf="confirmedParticipant?.scan_status != 1" width="24" src="/assets/images/error.png">
				</div>
				<button type="button"
					[ngClass]="{'close_user_top': true, 'bg-green': confirmedParticipant?.scan_status == 1 && responseCode != 6, 'bg-red': confirmedParticipant?.scan_status != 1 || (confirmedParticipant?.scan_status == 1 && responseCode == 6)}"
					(click)="showConfirmedParticipant = false"><span style="vertical-align: super;"><i class="fa fa-close"></i></span>
				</button>
			</div>
		</div>

		<div class="main_user confirmed-participant pl15 pr15 pt15 mb15" [ngClass]="{'bg-green': confirmedParticipant?.scan_status == 1 && responseCode != 6,'ft-white': confirmedParticipant?.scan_status == 1 && responseCode != 6, 'bg-white': confirmedParticipant?.scan_status != 1 || (confirmedParticipant?.scan_status == 1 && responseCode == 6), 'ft-black': confirmedParticipant?.scan_status != 1 || (confirmedParticipant?.scan_status == 1 && responseCode == 6)}">
			<div class="col-md-2 col-md-{{pushRtlPullLtr}}-6_ col-xs-2 avatar-section">
        <svg *ngIf="confirmedParticipant?.facebook_id == 0" width="64" height="64" [data-jdenticon-value]="confirmedParticipant?.purchase_id"></svg>
				<img *ngIf="confirmedParticipant?.facebook_id != 0" id="user_img" class="img-responsive round_img"
					src="{{images_base_url}}/facebook/{{confirmedParticipant?.facebook_id}}_profile.jpg" />
			</div>
			<div class="col-md-6 col-md-{{pullRtlPushLtr}}-2_ col-xs-8" >
				<p class="full_name">  {{confirmedParticipant?.full_name}} </p>
				<p *ngIf="responseCode == 6 && inviterName"><span i18n="@@ReaderParticipantinvitedby">Invited by:</span>&nbsp;{{inviterName}}</p>
				<p *ngIf="confirmedParticipant?.seller_name"><span i18n="@@ReaderParticipantSalesperson">Salesperson:</span>&nbsp;{{confirmedParticipant?.seller_name}}</p>
				<p><span i18n="@@ReaderParticipantEmail">Email:</span>&nbsp;{{confirmedParticipant?.email}} </p>
				<p *ngIf="confirmedParticipant?.birth_date !== ''" i18n="@@ReaderParticipantDateofbirth"> <span>Date of birth:</span>&nbsp;{
					confirmedParticipant?.birth_date, select,
					0000-00-00 {Unknown}
					other {{{confirmedParticipant?.birth_date}}}
				}</p>
				<p *ngIf="confirmedParticipant?.age !== ''"><span i18n="@@ReaderParticipantAge">Age:</span>&nbsp;{{confirmedParticipant?.age}}</p>
				<p i18n="participant info | item name"><span>Item name:</span>&nbsp;{{confirmedParticipant?.item_name}} ({
					confirmedParticipant?.purchase_item, plural,
          =1 {Ticket} =2 {Table} =3 {Room}
				})</p>
				<p i18n="participant info | payment method"><span>Payment method:</span>&nbsp;{
						confirmedParticipant?.payment_method, plural,
          =1 {Credit} =2 {Free admission} =3 {Cash} =4 {Registration} =5 {Error} =6 {Credit external} =7 {Cash external}
					} </p>
				<p *ngIf="confirmedParticipant?.discount?.id"><span i18n="@@ReaderParticipantdiscount">Discount:</span> {{confirmedParticipant.discount.name}} - {{confirmedParticipant.discount.discount}}
						<span i18n="@@ReaderParticipantdiscount_type">
              {
                confirmedParticipant.discount.discount_type, plural,
                =1 {%} =2 {Total cost} =3 {ILS}
                }
            </span></p>
				<p *ngIf="confirmedParticipant?.comment"><span i18n="@@ReaderParticipantComment">Producer&apos;s note:</span>&nbsp;{{confirmedParticipant?.comment}}</p>
			</div>

			<div class="col-md-4 col-xs-4 col-xs-{{pullRtlPushLtr}}-8_ mark-section reader-action ">
				<p><span i18n="@@ReaderParticipantCancelentry" *ngIf="confirmedParticipant?.scan_status == 1" [ngStyle]="{'width': '100%'}" class=" cancel" (click)="cancelEnterParaticipant(confirmedParticipant?.hash)">Cancel entry</span></p>
				<p><span i18n="@@ReaderParticipantMarkEntry" *ngIf="confirmedParticipant?.scan_status != 1" [ngStyle]="{'width': '100%'}" class=" approve" (click)="confirmEnterParaticipant(confirmedParticipant?.hash)">Mark Entry</span></p>
				<div *ngIf="confirmedParticipant?.scan_status == 1" class="mt10"><span i18n="@@ReaderParticipantFirstScanAt"> First scan at</span> - {{confirmedParticipant?.first_scan}}</div>
				<p *ngIf="confirmedParticipant?.scan_status == 1 && responseCode != 6"><span i18n="@@ReaderParticipantscanid">Id number for further verification</span>&nbsp;{{confirmedParticipant?.enter}}</p>
			</div>

		</div>

		<div *ngIf="associatedParticipant.length > 0 && responseCode != 6 && confirmedParticipant?.scan_status == 1" class="col-md-12 col-xs-12">
			<p class="mb0"><span i18n="@@ReaderParticipantrelatedparticipants">Attendees related to </span> {{confirmedParticipant?.full_name}} ({{associatedParticipant.length}})</p>
		</div>


		<div *ngIf="associatedParticipant.length > 0 && responseCode != 6 && confirmedParticipant?.scan_status == 1" class="sub_users col-md-12 col-xs-12">
			<ngu-carousel #myCarousel [inputs]="carouselTile" [dataSource]="associatedParticipant">
				<div class="item" *nguCarouselDef="let a; let i = index">
					<div class="sub_item">
						<div class="col-md-5 col-xs-4 pull-right">
              <svg *ngIf="a?.facebook_id == 0" width="64" height="64" [data-jdenticon-value]="a?.purchase_id"></svg>
							<img *ngIf="a?.facebook_id != 0" id="user_img" class="img-responsive round_img" src="{{images_base_url}}/facebook/{{a.facebook_id}}_profile.jpg" />
						</div>
						<div class="col-md-7 col-xs-8 sub_data">
							<p class="full_name">  {{a.full_name}} </p>
							<p class="enter_status"></p>
							<div class="user_id_reader">{{a.id}}</div>
							<div class="col-md-12 col-xs-12 action"></div>
							<span i18n="@@ReaderParticipantMarkEntry" *ngIf="a.scan_status != 1" class=" approve" (click)="confirmEnterParaticipant(a.hash)">Mark entry</span>
							<span i18n="@@ReaderParticipantCancelentry" *ngIf="a.scan_status == 1" class=" cancel" (click)="cancelEnterParaticipant(a.hash)">Cancel entry</span>
						</div>
					</div>
				</div>
				<button NguCarouselNext class="rightRs" [style.opacity]="myCarousel.isLast ? 0.2:1"><span><i class="fa fa-angle-right"></i></span></button>
				<button NguCarouselPrev class="leftRs" [style.opacity]="myCarousel.isFirst ? 0.2:1"><span><i class="fa fa-angle-left"></i></span></button>
			</ngu-carousel>
		</div>
	</div>

	<div *ngIf="!participants?.length">
		<h2 i18n="@@ReaderParticipantNoParticipantsFound">No attendees found for this scanner</h2>
	</div>

	<div *ngIf="error_text" id="error_div" class="mb20 pr15 pl15">
		<div class="col-md-12 col-xs-12 error_header pt5 pb5">
			<button type="button" class="close_error" (click)="resetTextError()"><span><i class="fa fa-close"></i></span></button>
			<p i18n="@@Error" class="error_title text-center">Error</p>
		</div>
		<div class="col-md-12 col-xs-12 error_body text-center pb10">
			<span>{{error_text}}</span>
		</div>
	</div>

	<div *ngIf="participants?.length > 0" class="dynamic_content pl20 pr20 users_reader">
		<div lazy-load-images class="participant_block participant_block-align row" *ngFor="let participant of participants | search_params: filterText">
			<div class="col-md-2 col-md-{{pushRtlPullLtr}}-8_ col-xs-2 avatar-section">
        <svg *ngIf="participant.facebook_id == 0" style="display:block; margin: auto;" width="52" height="52" [data-jdenticon-value]="participant?.purchase_id"></svg>
				<img *ngIf="participant.facebook_id != 0" id="user_img" class="img-responsive round_img" [ngStyle]="{'margin-bottom': participant.scan_status == 1 || participant.scan_status == 2?'24px':''}"
					[attr.data-src]="images_base_url+'/facebook/'+participant.facebook_id+'_profile.jpg'" />
			</div>
			<div class="col-md-10 col-xs-10 section-padding">
			<div class="col-md-8 col-md-{{pullRtlPushLtr}}-2_ col-xs-10 user_data_{{participant.id}}">
				<p class="full_name">{{participant.full_name}}</p>
				<p *ngIf="participant.scan_status == 1" class="user_id_reader"><img width="15" src="/assets/images/success.png"> <span i18n="@@ReaderParticipantapprovedwithparticipantname"> Admittance of {{participant.id}} Approved</span></p>
				<p  *ngIf="participant.scan_status == 2" class="user_id_reader"><img width="15" src="/assets/images/error.png"> <span i18n="@@ReaderParticipantcancelledwithparticipantname"> Admittance of {{participant.id}} Declined</span></p>
				<p class="participant-info"><span i18n="@@ReaderParticipantPhone">Phone:</span>&nbsp; {{participant.phone}} | <span i18n="@@ReaderParticipantIdentificationnumber">ID number:</span>&nbsp;{{participant.identification_number}} | <span i18n="@@ReaderParticipantTicketName">Ticket name:</span>&nbsp;{{participant.item_name}} | <span style="font-weight: bold;" *ngIf="participant.isNft === 1">NFT</span><span *ngIf="participant?.discount?.id"> <span i18n="@@ReaderParticipantRealizationofbenefit" >| Realization of a benefit:</span> {{participant.discount.name}} - {{participant.discount.discount}}
				<span i18n="@@ReaderParticipantRealizationofbenefitdiscount_type">
          {
            participant.discount.discount_type, plural,
            =1 {%} =2 {Total cost} =3 {ILS}
            }
        </span></span></p>
				<p class="participant-info" *ngIf="participant.comment"> <span i18n="@@ReaderParticipantComment">Producer&apos;s note:</span>&nbsp;{{participant.comment}}</p>
			</div>
			<div class="col-md-4 col-xs-4 col-xs-{{pullRtlPushLtr}}-8 mark-position action">
				<span i18n="@@ReaderParticipantMarkEntry" *ngIf="participant.scan_status != 1" class=" approve" (click)="confirmEnterParaticipant(participant.hash, participant.isNft)">Mark entry</span>
				<span i18n="@@ReaderParticipantCancelentry" *ngIf="participant.scan_status == 1" class=" cancel" (click)="cancelEnterParaticipant(participant.hash)">Cancel entry</span>
			</div>
		</div>
		</div>
	</div>
</section>

