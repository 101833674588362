
<div class="col-md-12 col-xs-12">
  <div mat-dialog-title class="modal-header">
    <button type="button" class="close" (click)="onClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div mat-dialog-content>
    <h4><span i18n="@@SalesmanStatsSalesperson">Salesperson:</span> {{sellerData[0].seller_name}}</h4>
        <p i18n="@@SalesmanStatsNumberofattendees">Number of attendees</p>
        <div class="table-responsive">
          <table class="table table-striped table-hover table-responsive table-bordered">
        <tr>
          <th i18n="@@Male">Male</th>
          <th i18n="@@Female">Female</th>
          <th i18n="@@Other">Other</th>
          <th i18n="@@SalesmanStatsTotalattendees">Total attendees</th>
        </tr>
        <tr>
          <td>{{statsBreakdownTotals.male}}</td>
          <td>{{statsBreakdownTotals.female}}</td>
          <td>{{statsBreakdownTotals.unknown_gender}}</td>
          <td>{{+statsBreakdownTotals.male + +statsBreakdownTotals.female + +statsBreakdownTotals.unknown_gender}}</td>
        </tr>
      </table>
    </div>
    <p i18n="@@SalesmanStatsTicketDistributio">Ticket distribution</p>
    <div class="table-responsive">
          <table class="table table-striped table-hover table-responsive table-bordered seller_sales_breakdown">
        <tr>
          <th i18n="@@Ticket type">Ticket type</th>
          <th i18n="@@Credit">Credit</th>
          <th i18n="@@Cash">Cash</th>
          <th i18n="@@FreeAdmission">Free admission</th>
          <th i18n="@@Registration">Registration</th>
          <th i18n="@@Total">Total</th>
        </tr>
        <tr [ngStyle]="{'display': +item.ticket_c == 0 && 'none'}" *ngFor="let item of sellerData">
          <td>{{item.ticket_name}}</td>
          <td>{{item.total_cred}}</td>
          <td>{{item.total_cash}}</td>
          <td>{{item.total_free}}</td>
          <td>{{item.total_register}}</td>
          <td>{{item.ticket_c}}</td>
        </tr>
      </table>
    </div>
    <div class="cash_to_charge" *ngIf="+statsBreakdownTotals.cash_sum > 0">
      <h4 i18n="@@SalesmanStatsCashtobeCharged" *ngIf="userType == '0' || userType == '3'" >Cash to be charged</h4>
      <div *ngIf="userType == '0' || userType == '3'" class="table-responsive">
            <table class="table table-striped table-hover table-responsive table-bordered">
          <tr>
            <th i18n="@@SalesmanStatsBeforebilling">Before billing</th>
            <th i18n="@@SalesmanStatsAmounttobecharged">Amount to be charged</th>
            <th i18n="@@SalesmanStatsAfterbilling">After billing</th>
          </tr>
          <tr>
            <td>{{sellerData[0].pending_income}}</td>
            <td>
              <form (ngSubmit)="onSubmit()" #chargeCashForm="ngForm">
                <input
                  [appValidationMin]="0"
                  [appValidationMax]="sellerData[0].pending_income"
                  type="number"
                  name="cashToCharge"
                  placeholder="Amount to be charged"
                  #chargeInput="ngModel"
                  ngModel>
                  <button type="submit" class="collect_cash" [disabled]="chargeCashForm.invalid">
                      <i class="fa fa-plus"></i>
                  </button>
                </form>
              </td>
              <td>{{sellerData[0].income}}</td>
            </tr>
          </table>
          <span i18n="@@SalesmanStatsErrorAmountTobecharged" *ngIf="(chargeInput.errors?.ValidationMaxDirective || chargeInput.errors?.ValidationMinDirective) && chargeInput.dirty && chargeInput.invalid" class="item-error mb10">Please specify a positive value that does not exceed the amount to be charged</span>
        </div>
      </div>

    <p><span i18n="@@SalesmanStatsTotalapprovedcreditdeals">Total approved credit deals:</span> {{+statsBreakdownTotals.cred_sum | currency: currencyCode}}</p>
    <p><span i18n="@@SalesmanStatsTotalapprovedcashdeals">Total approved cash deals:</span> {{+statsBreakdownTotals.cash_sum | currency: currencyCode}}</p>
  </div>
</div>
