<div class="col-md-12 col-xs-12">
  <form (ngSubmit)="submitForm()" #updateUserPasswordUserForm="ngForm">
    <div class="modal-header">
        <button type="button" class="close" (click)="dialogRef.close()">
           <span aria-hidden="true">&times;</span>
        </button>
    <h4 i18n="@@UpdatePasswordByAdminUpdatePass" class="text-center" mat-dialog-title>Update password</h4>
    </div>
      <div class="modal-body" mat-dialog-content>
         <div class="row mt20">
           <div class="form-group col-md-8 col-xs-12">
            <label i18n="@@UpdatePasswordByAdminenternewpassword" for="new_password">Enter new password:</label>
            <input i18n-placeholder="@@NewPassword" id="new_password" class="form-control" type="password" name="new_password" placeholder="New password" [(ngModel)]="pwdModel.new_password" #new_password="ngModel" [required]="confirmed_password.value ? true :null">
            <div *ngIf="new_password?.invalid && (new_password?.dirty || new_password?.touched)" class="item-error">
                <div i18n="@@ThisFieldIsRequired" *ngIf="new_password?.errors?.required">This field is required</div>
            </div>
           </div>
         </div>
         <div class="row">
           <div class="form-group col-md-8 col-xs-12">
             <label i18n="@@UpdatePasswordByAdminconfirmnewpassword" class="mt10" for="confirmed_password">Confirm new password:</label>
             <input i18n-placeholder="@@UpdatePasswordByAdminconfirmnewpassword_" id="confirmed_password" class="form-control" type="password" name="confirmed_password" placeholder="Confirm password" [(ngModel)]="pwdModel.confirmed_password" #confirmed_password="ngModel" [required]="new_password.value ? true :null">
             <div *ngIf="confirmed_password?.invalid && (confirmed_password?.dirty || confirmed_password?.touched)" class="item-error">
                <div i18n="@@ThisFieldIsRequired" *ngIf="confirmed_password?.errors?.required">This field is required</div>
            </div>
            </div>
          </div>
          <div class="row">
             <div class="col-md-12 col-xs-12">
                <p i18n="@@UpdatePasswordByAdminIfNoPassmessage" class="info mt10">If no new password had been set, the system will generate a random password, which will be sent via email\SMS.</p>
             </div>
          </div>
    </div>
    <div class="modal-footer">
        <button i18n="@@Cancel" type="button" class="btn btn-default mutate-float-right mr10" (click)="dialogRef.close()">Cancel</button>
        <button i18n="@@Update" type="submit" class="btn btn-success mutate-float-left" [disabled]="updateUserPasswordUserForm.invalid">Update</button>
    </div>
  </form>
</div>
