<div class="event-counters" [ngClass]="{'rtl' : dir === 'rtl'}">
  <div class="counter" [routerLink]="['/analytics/dashboard/' + eventID]" [queryParams]="{type: 'insight'}">
    <span class="counter-value">{{views | shortNumber}}</span><span i18n="dashboard | view">{views, plural, =1 {View} other {Views}}</span>
  </div>
  <div class="counter" [routerLink]="['/analytics/dashboard/' + eventID]" [queryParams]="{type: 'balance'}"
    [ngStyle]="{ 'justify-content': sales == 0 ? 'center' : null }">
    <span *ngIf="sales == 0" t key="dashboard.counters.noSales" default="No sales yet">No sales
      yet</span>
    <span *ngIf="sales > 0" class="counter-value">{{sales | currency: currency:currency === 'MXN' || currency=== 'CAD'
      ?'symbol':'symbol-narrow':'1.0-0':getLocale(currency) | shortNumber:'currency':LOCALE_ID}}</span>
    <span *ngIf="sales > 0">
      <strong t key="dashboard.counters.sales" default="Sales"></strong>
      <ng-container *ngIf="sales > 0"> ({{currencySymbol}})</ng-container>
    </span>
  </div>
  <div class="counter" [routerLink]="['/event-participants/' + eventID + '/approved']">
    <span class="counter-value">{{approved | shortNumber}}</span><span i18n="dashboard | approved">{approved,
      plural, =1 {Attendee} other {Attendees}}</span>
  </div>
  <div class="counter" [routerLink]="['/event-participants/' + eventID + '/pending']">
    <span class="counter-value">{{pending | shortNumber}}</span><span i18n="dashboard | pending">Pending</span>
  </div>
</div>
