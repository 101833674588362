import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogService} from '../../services/dialog.service';
import {GlobalsService} from '../../globals';
import {EventService} from '../../services/event.service';
import {AuthService} from '../../services/auth.service';
import {RecaptchaEnterpriseService} from '../../services/recaptcha-enterprise.service';
import {NavbarService} from '../../services/navbar.service';
import {AuthCacheService} from '../../services-cache/auth-cache.service';
import {AppResponse} from '../../interfaces/app-response.interface';

@Component({
  selector: 'app-redirect-from-mobile',
  templateUrl: './redirect-from-mobile.component.html'
})
export class RedirectFromMobileComponent implements OnInit{
  token: string = '';

  constructor(private activatedRoute: ActivatedRoute,
              private dialogService: DialogService,
              private router: Router,
              private globals: GlobalsService,
              private eventService: EventService,
              private authService: AuthService,
              private recaptchaService: RecaptchaEnterpriseService,
              public nav: NavbarService,
              private authCacheService: AuthCacheService,) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {

      this.token = params['token'];

      this.globals.startLoader();
      this.eventService.userRedirectMobileApp(this.token).subscribe((res: any) => {
        this.globals.stopLoader();
        let user_id = this.authService.getUser();
        console.log('userRedirectMobileApp', res, user_id);

        // Case - 1
        // User already logged in
        if(res.user_data && res.user_data.user_id === user_id) {
          console.log('User already logged in', user_id)
          this.redirrectUser(res); // navigate to event
        }

        // Case - 2
        // Another user already logged in
        // log out  and login new user
        if(res.user_data && res.user_data.user_id !== user_id && user_id > 0) {
          console.log('Another user already logged in', user_id)
          this.authService.logout().subscribe(response => {
            console.log(response);
            if(response.success) {
              this.loginUser(res)
            }
          });

        }

        // Case - 3
        // No logged in User
        if(res.user_data && res.user_data.user_id && !user_id) {
          console.log('No logged in User', user_id)
          this.loginUser(res)
        }

      }, error => {
        console.log('userRedirectMobileApp', error);
        this.globals.stopLoader();
        this.dialogService.alertsModal('errors', error.code, 5000,false);

        setTimeout(()=>{
          this.router.navigate(['/'])
        }, 2000)
      });

    });
  }


  loginUser(response) {
   if(response && (response.original.success === 'letsGoHome')) {
      console.log('letsGoHome user-login');
      if (response.auth.session && response.auth.refresh) {
        this.authService.storeTokens(response.auth.session, response.auth.refresh)
      } else {
        this.dialogService.alertsModal('errors', response.original.error, 3000, false);
        return;
      };
      this.authService.saveUserCookies(response.user_data, response.original.facebook_id);
      this.authCacheService.clearCache();
      this.nav.checkUser();
      if (response.original && response.original.uid) {
        this.nav.UpdateUser(parseInt(response.original.uid), response.user_data.type, response.original.facebook_id, false)
      };

      const isSuccess = response.original.success == 'letsGoHome';
      if(isSuccess) {
        this.redirrectUser(response); // navigate to event
      }
      // this.intercomService.boot(true);
      !isSuccess ? this.dialogService.alertsModal('success', response.original.success, 3000, true) : false;
      isSuccess ? this.dialogService.alertsModal('success', 'readerLogin', 3000, true) : false;

    }
  }

  redirrectUser(response) {
    setTimeout(()=>{
      if (response.customData) {
        const q_params = {};
        if(response.customData.seller_code) { q_params['seller_code'] = response.customData.seller_code }

        switch(response.customData.page) {
          case 'event_page':
            this.router.navigate(['/' + response.customData.event_id], { queryParams: {...q_params}});
            break;
          case 'create_event_page':
            this.createEvent();
            break;
          default:
            this.router.navigate(['/' + response.customData.event_id], { queryParams: {...q_params}});
        }
      }


    }, 1000)
  }

  createEvent() {
    this.eventService.createNewEvent().subscribe(
      (response: AppResponse) => {
        if (response.data &&  response.data.event_id) {
          this.router.navigate(['/edit-event/' + response.data.event_id]);
        }
      },
      (error: AppResponse) => this.dialogService.alertsModal('errros', (error.code) ? error.code : '4.1', 5000, false)
    );
  }
}
