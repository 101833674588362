<div *ngIf="eventInfo" class="col-md-12 col-xs-12 p0 m0 eventInfo">
    <div class="modal-header">
      <button type="button" class="close" [matDialogClose]>
        <span aria-hidden="true">&times;</span>
      </button>
          <h2 class="text-center" id="event_title">{{eventInfo?.event_id}} {{eventInfo?.event_title}}</h2>
    </div>
    <div class="modal-body" id="event_info_body">
      <div class="col-md-12 col-xs-12 mb10">
        <h4 i18n="@@GeneralInformation">General information</h4>
        <div class="col-lg-3 col-md-4 col-xs-12 p0 m0 mutate-float-right">
          <label i18n="@@EventDetailsModalEventdates_">Event dates:</label>
          <span id="dates"> {{eventInfo?.end_date}} - {{eventInfo?.start_date}}</span>
        </div>
        <div class="col-lg-3 col-md-4 col-xs-12 p0 m0 mutate-float-right">
          <label i18n="@@EventDetailsModalStartofsalerounds">Start of sale rounds:</label>
          <span id="sale_dates_start"> {{eventInfo?.start_date_sales}} {{eventInfo?.time_zone}}</span>
        </div>
        <div class="col-lg-3 col-md-4 col-xs-12 p0 m0 mutate-float-right">
          <label i18n="@@EventDetailsModalsaleroundsenddate">End of sale rounds:</label>
          <span id="sale_dates_end"> {{eventInfo?.end_date_sales}} {{eventInfo?.time_zone}}</span>
        </div>
      </div>
      <div *ngIf="type != 1" class="col-md-12 col-xs-12 mb10">
        <h4 i18n="@@EventDetailsModalsalesmenbreakdown">Salespeople breakdown</h4>
        <div *ngIf="type === 0" class="col-lg-3 col-md-4 col-xs-6 p0 m0 mutate-float-right">
          <label i18n="@@EventDetailsModalAllpromoters">All promoters:</label>
          <span id="all_sellers"> {{(eventInfo?.all_sellers) ? eventInfo?.all_sellers : 0}}</span>
        </div>
        <div *ngIf="type === 0" class="col-lg-3 col-md-4 col-xs-6 p0 m0 mutate-float-right">
          <label i18n="@@EventDetailsModalManagers">Managers:</label>
          <span id="managers"> {{(eventInfo?.managers) ? eventInfo?.managers : 0}}</span>
        </div>
        <div *ngIf="type === 0 || type === 3" class="col-lg-3 col-md-4 col-xs-6 p0 m0 mutate-float-right">
          <label i18n="@@EventDetailsModalTeamLeaders">Team leaders:</label>
          <span id="head_sellers"> {{(eventInfo?.head_sellers) ? eventInfo?.head_sellers : 0}}</span>
        </div>
        <div class="col-lg-3 col-md-4 col-xs-6 p0 m0 mutate-float-right">
          <label i18n="@@EventDetailsModalPromoters">Promoters:</label>
          <span id="r_sellers"> {{(eventInfo?.r_sellers) ? eventInfo?.r_sellers : 0}}</span>
        </div>
      </div>
      <div class="col-md-12 col-xs-12 mb10">
        <h4 i18n="@@EventDetailsModalSalesBreakdown">Sales breakdown</h4>
        <div *ngIf="type === 0 || type === 3" class="col-lg-3 col-md-4 col-xs-6 p0 m0 mutate-float-right">
          <label i18n="@@EventDetailsModaltotalincome">Total income:</label>
          <span id="total_income"> {{eventInfo?.total_income | currency: eventInfo.currency}}</span>
        </div>
        <div class="col-lg-3 col-md-4 col-xs-6 p0 m0 mutate-float-right">
          <label i18n="@@EventDetailsModaltotalcash">Total cash:</label>
          <span id="cash_sum"> {{eventInfo?.cash_sum | currency: eventInfo.currency}}</span>
        </div>
        <div  class="col-lg-3 col-md-4 col-xs-6 p0 m0 mutate-float-right">
          <label i18n="@@EventDetailsModaltotalcredit">Total credit:</label>
          <span id="credit_sum"> {{eventInfo?.credit_sum | currency: eventInfo.currency}}</span>
        </div>
        <div class="col-lg-3 col-md-4 col-xs-6 p0 m0 mutate-float-right">
            <label i18n="@@EventDetailsModalfreetickets">Free admissions:</label>
          <span id="total_free"> {{eventInfo?.total_free}}</span>
        </div>
        <div *ngIf="eventInfo?.total_register" class="col-lg-3 col-md-4 col-xs-6 p0 m0 mutate-float-right">
            <label i18n="@@EventDetailsModalregistrations">Registrations:</label>
          <span id="total_register"> {{eventInfo?.total_register}}</span>
        </div>
        <div class="col-lg-3 col-md-4 col-xs-6 p0 m0 mutate-float-right">
          <label i18n="@@EventDetailsModaltotalcoupons">Total coupons:</label>
          <span id="total_coupons"> {{eventInfo?.total_coupons}}</span>
        </div>
        <div *ngIf="eventInfo.hasOwnProperty(sellers_coupons) && eventInfo?.sellers_coupons.length > 0" class="col-lg-3 col-md-4 col-xs-6 p0 m0 mutate-float-right">
            <label i18n="@@EventDetailsModalPromotercoupons">Promoter coupons:</label>
          <span id="sellers_coupons"> {{eventInfo?.sellers_coupons.length}}</span>
        </div>
        <!-- <div *ngIf="eventInfo?.discount_list" class="col-md-3 col-xs-6 p0 m0 mutate-float-right">
          <label i18n="modal label | discount list">רשימת הנחות:</label>
          <span id="discount_list"> {{eventInfo?.discount_list}}</span>
        </div> -->
      </div>
      <div class="col-md-12 col-xs-12 mb10" *ngIf="paymentGateways && paymentGateways.length > 0">
        <h4 i18n="@@EventDetailsModalpaymentgateway">Payment gateway</h4>
          <div class="col-md-12 col-xs-12 p0" *ngFor="let gateway of paymentGateways">
            <!-- <h4 i18n="modal sub title | payment gateway">מסוף סליקה</h4> -->
            <div class="col-lg-3 col-md-4 col-xs-6 p0 m0 mutate-float-right">
              <label i18n="@@EventDetailsModalpaymentgatewayid">Gateway id:</label>
            <span id="sellers_coupons"> {{gateway.id}}</span>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6 p0 m0 mutate-float-right">
            <label i18n="@@EventDetailsModalgatewayname">Gateway name:</label>
          <span id="pending"> {{gateway.name}}</span>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6 p0 m0 mutate-float-right">
            <label i18n="@@EventDetailsModalgatewaytype">Gateway type:</label>
            <span id="pending"> {{gateway.payment_type}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-xs-12 mb10">
        <h4 i18n="@@EventDetailsModalAttendeesbreaf">Attendees breaf</h4>
        <div class="col-lg-3 col-md-4 col-xs-6 p0 m0 mutate-float-right">
            <label i18n="@@EventDetailsModalapproved">Approved:</label>
          <span id="approved"> {{eventInfo?.approved_count}}</span>
        </div>
        <div class="col-lg-3 col-md-4 col-xs-6 p0 m0 mutate-float-right">
            <label i18n="@@EventDetailsModalpendingapproval">Pending approval:</label>
          <span id="pending"> {{eventInfo?.pending_count}}</span>
        </div>
      </div>
      <div *ngIf="eventInfo?.coupons" class="col-md-12 col-xs-12" id="coupon_list">
        <h4 i18n="@@EventDetailsModalcouponlist" class="mt15">Coupon list:</h4>
        <div class="table-responsive">
          <table class="table table-striped table-hover table-responsive">
            <tr>
              <th i18n="@@EventDetailsModalName">Name</th>
              <th i18n="@@EventDetailsModalDiscount">Discount</th>
              <th i18n="@@EventDetailsModalCode">Code</th>
              <th i18n="@@EventDetailsModalAmount">Amount</th>
              <th i18n="@@EventDetailsModalavailableforuse">Available for use</th>
            </tr>
            <tr *ngFor="let coupon of eventInfo.coupons">
              <td>{{coupon.name}}</td>
              <td >
                <span *ngIf="coupon.discount_type === 1">{{coupon.discount + '%'}}</span>
                <span *ngIf="coupon.discount_type === 3">{{coupon.discount | currency: eventInfo.currency}}</span>
              </td>
              <td>{{coupon.code}}</td>
              <td>{{coupon.quantity}}</td>
              <td>{{coupon.remaining}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="modal-footer" id="events-info-footer">
      <div class="row events-info-links pb10">

        <div class="col-md-6 col-xs-6">
            <button i18n="@@EventDetailsModalStatistics" [matDialogClose] [routerLink]="['analytics/dashboard', data.event_id]" [queryParams]="{type: 'sales'}" class="btn btn-default">Statistics</button>
        </div>
        <div class="col-md-6 col-xs-6">
            <button i18n="@@EventDetailsModalParticipants" [matDialogClose] [routerLink]="['/event-participants',data.event_id,'all']" class="btn btn-default">Attendees</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-xs-12 event_info_close">
          <button i18n="@@Close" type="button" class="btn btn-default mutate-float-left" [matDialogClose]>Close</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="noData">
    <div class="modal-header">
      <button type="button" class="close" [matDialogClose]>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <h6 i18n="@@EventDetailsModalErrorGeneral" class="item-error">An error has occured during the data&apos;s import. Please try again.</h6>
    <div class="modal-footer" id="events-info-footer">
      <div class="row">
          <div class="col-md-12 col-xs-12 event_info_close">
              <button i18n="@@Close" type="button" class="btn btn-default" [matDialogClose]>Close</button>
            </div>
      </div>
    </div>
  </div>
  <div *ngIf="!eventInfo && !noData" style="display:block;">
    <!-- <img src="/assets/images/preloader.gif"> -->
    <mat-spinner style="margin:0 auto;" strokeWidth="5" diameter="70"></mat-spinner>
  </div>
