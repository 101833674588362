import {AfterViewInit, Component, HostListener, Input, OnInit} from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';
import {NavbarService} from '../services/navbar.service';
import {UserService} from '../services/user.service';
import {NotificationService} from '../services/notification.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {FirebaseMessagingService} from '../services/firebase.messanging.service';
import {PusherService} from '../services/pusher.service';
declare var window: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  user_id = 0;
  firebaseInfo = null;
  firebasePublicKey = environment.firebase.messagingSenderId;
  currentFirebaseMessage = new BehaviorSubject(null);
  gettingNotifs = false;
  showNotifications = false;
  pauseDraw = false;
  private messageLimit = 12;
  unreadArr = [];
  tempNotificationData = new BehaviorSubject(null);
  newTempNotifications = this.tempNotificationData.asObservable();
  emptyNotifs = {
    'read': [],
    'unread': [],
  };
  notifications = this.emptyNotifs;
  isNewNotifs: boolean;
  clickOpenSearch: Subject<any> = new Subject();
  searchOpen: boolean = false;
  isProd = environment.production;
  @Input() isMobile = false;
  @Input() direction = '';


  constructor(public nav: NavbarService,
              private userService: UserService,
              private notificationService: NotificationService,
              private firebase: FirebaseMessagingService,
              private angularFireMessaging: AngularFireMessaging,
              private pusher: PusherService) {

  }


  ngOnInit(): void {
    this.nav.currentUserId.subscribe(user_id => {
      this.user_id = user_id;
      if (!user_id) {this.clearNotifications()}
    });
    this.nav.currentAuthStatus.subscribe(authed => {
      if (authed && this.isProd) {
        this.getNotifications();
        this.initNotificationListeners();
      }
    });
    this.newTempNotifications.subscribe(data => {
      if ( data && !this.pauseDraw ) {
        this.updateNotificationList(data);
      }
    });
  }

  toggleSidenav() {
    this.nav.toggleSidenav(this.isMobile);
  }

  openLoginModal() {
    this.userService.openLoginModal();
  }

  // Open search on mobile devices
  openSearch() {
    this.searchOpen = !this.searchOpen;
    this.clickOpenSearch.next(this.searchOpen);
  }

  // close from search-component X button
  onSearchOpenedChange(opened: boolean) {
    // console.log('onSearchOpenedChange', opened);
    this.searchOpen = opened;
    this.clickOpenSearch.next(this.searchOpen);
  }

  ngAfterViewInit() {

  }


  getNotifications() {
    this.gettingNotifs = true;
    this.notificationService.getNotifications()
      .subscribe(response => {
        this.tempNotificationData.next(response);
      }, error => {
        this.gettingNotifs = false;
      })
  }
  removeDuplicatesNotif(arr) {
    const arrUniq = [...new Map(arr.map(v => [v.id, v])).values()]
    return arrUniq;
  }

  updateNotificationList(notificationsData) {
     // console.log('updateNotificationList', notificationsData);


    if (notificationsData && notificationsData.notifications) {
      notificationsData['notifications'] = this.removeDuplicatesNotif(notificationsData.notifications);
      // console.log('removeDuplicatesNotif', notificationsData);

      const read = notificationsData['notifications'].filter(notification => notification.status === '1')
        ? notificationsData['notifications'].filter(notification => notification.status === '1')
        : [];
      const unread = notificationsData['notifications'].filter(notification => notification.status === '0')
        ? notificationsData['notifications'].filter(notification => notification.status === '0' && !notification.hasOwnProperty('custom_only_send') )
        : [];

      this.notifications = {
        'read': read,
        'unread': unread,
      };

      this.refreshUnreadArr();

      this.isNewNotifs = !!unread.length;
      this.gettingNotifs = false;
      // console.log('gettingNotifs',  this.isNewNotifs, this.gettingNotifs);
    }
  }

  clearNotifications() {
    this.notifications = this.emptyNotifs;
    this.isNewNotifs = false;
    this.refreshUnreadArr();
  }

  getFirebaseInfo() {
    this.angularFireMessaging.getToken.subscribe(fireToken => {
      this.firebaseInfo = {
        token: fireToken,
        public_key: this.firebasePublicKey
      }
    })
  }

  refreshUnreadArr() {
    this.unreadArr = [];
    this.notifications.unread.forEach(item => this.unreadArr.push(item.id));
  }

  fireShowNotifications() {
    // console.log('fireShowNotifications', this.gettingNotifs, this.showNotifications, this.unreadArr, this.pauseDraw);
    if(!this.gettingNotifs) {
      if (!this.showNotifications) {
        this.pauseDraw = true;
        if(this.unreadArr.length) {
          this.notificationService.readNotifications(this.unreadArr)
            .subscribe(response => null, error => null);
        }
      } else {
        this.pauseDraw = false;
        this.updateNotificationList(this.tempNotificationData.value)
      }
      this.showNotifications = !this.showNotifications;
    }
  }

  public initNotificationListeners() {
    // Init pusher for authorized users on web devices
    this.pusher.initPusher();
    window.Echo.private(`notifications-${this.user_id}`)
      .listen('.save-notification', newNotification => {
        const newData = this.tempNotificationData.value;
        newData['notifications'].unshift(newNotification);
        if (newData['notifications'].length > this.messageLimit) {
          newData['notifications'].length = this.messageLimit;
        }
        this.tempNotificationData.next(newData)
      })
      .listen('.read-notification', notificationList => {
        this.tempNotificationData.next(notificationList);
        if (!this.pauseDraw) {
          this.updateNotificationList(notificationList);
        };
      });

    // Init firebase push and notifications for authorized users on non safari web devices
    if (!window['Android'] && !/^((?!chrome|android).)*safari/i.test(navigator.userAgent) && !((!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) && !/CriOS/i.test(navigator.userAgent))) {
      this.firebase.requestPermission(this.firebasePublicKey);
      this.listenFirebaseMessages();
      this.getFirebaseInfo();
    }
  }

  public listenFirebaseMessages() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        this.createNotification(payload);
      })
  }

  public createNotification(message) {
    this.currentFirebaseMessage.next(message);
    if (message.hasOwnProperty('notification')) {
      const newNotifData = message['data'];

      const newNotification = message['notification'];
      // console.log("Has notification", newNotification, newNotifData)

      setTimeout(() => {

        if (localStorage && (newNotifData.id !== localStorage.getItem('lastNotificationId') || !localStorage.getItem('lastNotificationId'))) {
          localStorage.setItem('lastNotificationId', newNotifData.id);
          const title = newNotification.title;
          const options = {
            icon: newNotification.icon,
            body: newNotification.body
          };
          const notification = new Notification(title, options);

          notification.onclick = () => {
            newNotifData.fcm_link
              ? window.open(newNotifData.fcm_link)
              : notification.close.bind(notification);
          }
        }
      }, 0);
    }
  }

}
