
<div class="page-header-arrow-back">
  <div class="page-back-arrow">
    <div class="close-dialog-arrow" (click)="closePage()">
      <svg class="arrow-close-modal" width="10" height="14" viewBox="0 0 10 14" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M9.49219 1.64063L4.13281 7L9.49219 12.3594L7.85156 14L0.851562 7L7.85156 0L9.49219 1.64063Z"
          fill="white" />
      </svg>
    </div>
  </div>
</div>
<div class="promotion-wrapper">
  <div class="promotion-container">
    <div class="promotion-main">
      <div i18n="@@SalesPromotionsEditpromotionforanevent" class="promotion-title">Edit promotion for an event</div>
      <div class="promotion-event">&nbsp;{{event_id}}&nbsp;</div>
    </div>
    <div class="promotion-main-container">
      <form [formGroup]="event" class="status p10" *ngIf="bootstrapBoolean">


        <div formGroupName="seo" class="seo-form">
          <div formArrayName="facebook_pixels">
            <div class="promotion-form" *ngFor="let pixel of seoStep.controls['facebook_pixels']['controls']; let i = index;">
              <div class="account-container" [formGroupName]="i">
              <div class="promotion-control-group">
                <div class="btn-remove-pixel" (click)="deletePixel(i)"></div>
                <div class="promotion-section-title">
                  <div class="delete"></div>
                  <div class="section-title">
                    <label i18n="@@FacebookPixel" for="facebook_pixel_{{i}}">Facebook pixel</label>
                    <span style="margin-bottom: 5px;"> ({{ i+1 }}) </span>
                  </div>
                  <div (click)="openInfo('facebook-pixel')" class="section-info">
                    <img width="20"
                      class="info_icon" src="/assets/images/editEvent/Info.png">
                  </div>
                </div>
                <div class="promotion-control-group">
                  <input i18n-placeholder="@@FacebookPixel"
                         id="facebook_pixel_{{i}}"
                         type="text"
                         formControlName="facebook_pixel_id"
                         placeholder="Facebook pixel"
                         class="promotion-pixel-control ba-control-text"
                         [ngStyle]="{'border-color': fbChecked[i] ? '#82FFCA' : '#FF6465'}"
                         (blur)="checkInputChange($event, i)" (mouseleave)="checkInputChange($event, i)">
                    <div class="card-checkbox-on-nui">

                      <input id="fb_thankyou_{{i}}" type="checkbox" (change)="setFBtrack($event.target, i)" #fb_thankyou
                        [checked]="pixelsArray.controls[i]['type_name']" name="fb_thankyou" class="promotion-checkbox" required>
                          <svg *ngIf="fbChecked[i]" class="card-cb-on-nui" width="12" height="8" viewBox="0 0 12 8" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M11.8243 0.159541C11.59 -0.0531803 11.2101 -0.0531803 10.9757 0.159541L3.78737 6.68516L1.02428 4.17684C0.789972 3.96412 0.410097 3.96414 0.175746 4.17684C-0.058582 4.38954 -0.058582 4.73439 0.175746 4.94711L3.3631 7.84053C3.59734 8.05323 3.97749 8.05308 4.21163 7.84053L11.8243 0.929831C12.0586 0.717131 12.0586 0.372262 11.8243 0.159541Z"
                              fill="#82FFCA" />
                          </svg>
                    </div>
                </div>
              </div>
              <div [hidden]="!fbChecked[i]" class="promotion-text">
                <div i18n-placeholder="label | Add all FB Pixel options" class="">All available events have been activated</div>
              </div>
              <div [hidden]="!fbChecked[i]+'hidden'" class="promotion-section">
                <div class="promotion-section-title">
                  <div class="section-title">
                    <label i18n="@@SalesPromotionsChooseConversionType" for="facebook_track_thanks_{{i}}">Choose conversion type</label>
                  </div>
                  <div (click)="openInfo('facebook-pixel-thanks')" class="section-info">
                    <img width="20"
                      class="info_icon" src="/assets/images/editEvent/Info.png">
                  </div>
                </div>
                <div class="promotion-control-group">
                  <input i18n-placeholder="placeholder | All options for thank you page"
                  id="facebook_track_thanks_{{i}}"
                  class="form-control promotion-control ba-control-text"
                  trim="blur" type="text"
                  formControlName="facebook_track_thanks"
                  placeholder="All option have added"
                  readonly>
                </div>
              </div>
              <!-- <div [hidden]="!fbChecked[i]" class="promotion-section">
                <div class="promotion-section-title">
                  <div class="section-title">
                    <label i18n="input label | thank you page traking select" for="facebook_track_thanks_{{i}}">בחר סוג קוד
                      המרה</label>
                  </div>
                  <div (click)="openInfo('facebook-pixel-thanks')" class="section-info">
                    <img i18n-title="title attribute | example" title="דוגמה" width="20"
                      class=" info_icon" src="/assets/images/editEvent/Info.png">
                  </div>
                </div>
                <div class="promotion-control-group">
                  <select class="promotion-control ba-control-text" id="facebook_track_thanks_{{i}}"
                    formControlName="facebook_track_thanks">
                    <option i18n="input option for thank you page | blank" selected value="">בחר סוג קוד המרה </option>
                    <option i18n="input option for thank you page | Lead" value="Lead">Lead</option>
                    <option i18n="input option for thank you page | ViewContent" value="ViewContent">ViewContent
                    </option>
                    <option i18n="input option for thank you page | Search" value="Search">Search</option>
                    <option i18n="input option for thank you page | AddToCart" value="AddToCart">AddToCart</option>
                    <option i18n="input option for thank you page | AddToWishlist" value="AddToWishlist">AddToWishlist
                    </option>
                    <option i18n="input option for thank you page | InitiateCheckout" value="InitiateCheckout">
                      InitiateCheckout</option>
                    <option i18n="input option for thank you page | AddPaymentInfo" value="AddPaymentInfo">
                      AddPaymentInfo
                    </option>
                    <option i18n="input option for thank you page | CompleteRegistration" value="CompleteRegistration">
                      CompleteRegistration</option>
                    <option i18n="input option for thank you page | CompletePurchase" value="Purchase">
                      CompletePurchase</option>
                  </select>
                  <app-error-creator [@slideIn]
                    *ngIf="pixelsArray.controls[i]['controls'].facebook_track_thanks.touched && pixelsArray.controls[i]['controls'].facebook_track_thanks.invalid"
                    [control]="pixelsArray.controls[i]['controls'].facebook_track_thanks"></app-error-creator>
                </div>
              </div> -->
            </div>
          </div>
          <button i18n="button add new pixel | add_pixel_button" class="btn-add-pixel" (click)="addPixel()">{
            seoStep.controls['facebook_pixels']['controls'].length, plural,
            =0 {Add facebook pixel}
            other {Add new facebook pixel}
            } </button>
        </div>

          <div class="account-container">
            <div class="promotion-control-group">
              <div class="promotion-section-title">
                <div class="section-title">
                  <label i18n="@@GoogleAnalytics" for="google_analytics">Google Analytics</label>
                </div>
                <div (click)="openInfo('google-analitics')" class="section-info">
                  <img width="20" class="info_icon"
                    src="/assets/images/editEvent/Info.png">
                </div>
              </div>
              <div class="promotion-control-group">
                <input i18n-placeholder="@@GoogleAnalytics" id="google_analytics" class="form-control"
                  trim="blur" type="text" formControlName="google_analytics_ua" placeholder="Google Analytics"
                  class="promotion-control ba-control-text">
                <app-error-creator [@slideIn]
                  *ngIf="seoStep.controls.google_analytics_ua.touched && seoStep.controls.google_analytics_ua.invalid"
                  [control]="$any(seoStep).controls.google_analytics_ua" name="google_analytics_ua"></app-error-creator>
              </div>
            </div>
            <div class="promotion-control-group">
              <div class="promotion-section-title">
                <div class="section-title">
                  <label i18n="@@GoogleAnalyticsConversionCode" for="google_pixel">Google Analytics conversion code</label>
                </div>
                <div (click)="openInfo('google-conversion-traking')" class="section-info">
                  <img width="20" class="info_icon"
                    src="/assets/images/editEvent/Info.png">
                </div>
              </div>
              <div class="promotion-control-group">
                <input i18n-placeholder="@@ConversionCode" id="google_pixel"
                  class="form-control" type="text" formControlName="google_leads" placeholder="Conversion code"
                  class="promotion-control ba-control-text">
              </div>
            </div>
          </div>

          <div class="account-container">
            <div class="promotion-control-group">
              <div class="promotion-section-title">
                <div class="section-title">
                  <label i18n="@@SalesPromotionsServiceContact" for="marketing_contact">Sales \ Service Desk Phone</label>
                </div>
                <div (click)="openInfo('info_marketing_contact')" class="section-info">
                  <img width="20" width="20" class="info_icon"
                    src="/assets/images/editEvent/Info.png">
                </div>
              </div>
              <div class="promotion-control-group">
                <input appPhoneNumber ng2TelInput i18n-placeholder="@@SalesPromotionsServiceContact"
                  class="promotion-control ba-control-text form-control" id="marketing_contact" trim="blur"
                  type="text" formControlName="marketing_contact" placeholder="Sales \ Service Desk Phone"
                  (hasError)="setErrorOnphoneNumber(!$event)" (ng2TelOutput)="setMarketingPhoneNumber($event)"
                  (intlTelInputObject)="telInputObject($event)" (countryChange)="onCountryChange($event)"
                  [ng2TelInputOptions]="localizationService.getPhoneInputOptions()" />
                <app-error-creator [@slideIn]
                  *ngIf="seoStep.controls.marketing_contact.touched && seoStep.controls.marketing_contact.invalid"
                  [name]="'marketing_contact'" [control]="$any(seoStep).controls.marketing_contact"></app-error-creator>
              </div>
            </div>
            <div class="promotion-control-group">
              <div class="promotion-section-title">
                <div class="section-title">
                  <label i18n="@@SalesPromotionsContactEmail" for="contact_email">Contact email</label>
                </div>
                <div (click)="openInfo('info_contact')" class="section-info">
                  <img width="20" class=" info_icon" src="/assets/images/editEvent/Info.png">
                </div>
              </div>
              <input i18n-placeholder="@@Email" id="contact_email" class="form-control" trim="blur"
                type="text" formControlName="contact_email" placeholder="Email" class="promotion-control ba-control-text"
                [required]="!seoStep.controls.contact_facebook.value && !seoStep.controls.contact_email.value">
              <app-error-creator [@slideIn]
                *ngIf="seoStep.controls.contact_email.touched && seoStep.controls.contact_email.invalid"
                name="contact_email" [control]="$any(seoStep).controls.contact_email"></app-error-creator>
            </div>

            <div class="promotion-control-group">
              <div class="promotion-section-title">
                <div class="section-title">
                  <label i18n="@@SalesPromotionsOrFacebookContactlink" for="contact_facebook">or Facebook contact link</label>
                </div>
              </div>

              <div class="promotion-control-group">
                <input i18n-placeholder="@@SalesPromotionsFacebookPage" id="contact_facebook" class="form-control"
                  trim="blur" type="text" formControlName="contact_facebook" class="promotion-control ba-control-text"
                  [required]="!seoStep.controls.contact_facebook.value && !seoStep.controls.contact_email.value"
                  placeholder="Facebook page">
                <app-error-creator [@slideIn]
                  *ngIf="seoStep.controls.contact_facebook.touched && seoStep.controls.contact_facebook.invalid"
                  [control]="$any(seoStep).controls.contact_facebook"></app-error-creator>
              </div>
            </div>
          </div>

        </div>
        <button (click)="submitForm()" type="button" class="balance-field balance-btn"
          [disabled]="event.invalid && submitted">
          <span i18n="read terms & save form" class="btn-text">READ TERM OF USE & SAVE CHANGES</span>
        </button>
      </form>
    </div>
  </div>
</div>
