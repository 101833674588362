<app-header [isMobile]="isMobile" [direction]="direction"></app-header>
<app-loader (applyBlurBackound)="applyBlurBackound($event)"></app-loader>
<mat-drawer-container class="main-container" [hasBackdrop]="true" [ngClass]="{'blurryBackground': showBlurBackground} ">
  <mat-drawer #drawer class="main-sidenav" mode="over" position="end" [autoFocus]="false" [ngClass]="{'main-sidenav-mobile': isMobile}">
    <app-sidenav-menu [isMobile]="isMobile" [direction]="direction"></app-sidenav-menu>
  </mat-drawer>

  <mat-drawer-content cdkScrollable class="main-mat-drawer-content" [ngClass]="{'instagram_browser': isInstagramBrowser}">
    <div class="main-sidenav-content">

      <router-outlet></router-outlet>

      <app-footer></app-footer>


    </div>
  </mat-drawer-content>

</mat-drawer-container>


