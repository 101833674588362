import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GlobalsService} from "../../globals";
import {DialogService} from "../../services/dialog.service";
import {EventService} from "../../services/event.service";
declare var Stripe;
@Component({
  selector: 'app-stripe-checkout',
  templateUrl: './stripe-checkout.component.html',
  styleUrls: ['./stripe-checkout.component.scss']
})
export class StripeCheckoutComponent implements OnInit{
  stripe: any;
  @Input() publishable_key: string = '';
  @Input() client_secret: string = '';
  @Input() return_url: string = '';
  @Input() main_purchase_identifier: string = '';

  elementReady: boolean = false;
  stripeSubmitDisable: boolean = false;

  @Output() rudderstackAddPaymentInfo: EventEmitter<any> = new EventEmitter();
  @Output() checkAvailableFromStripe: EventEmitter<any> = new EventEmitter();
  constructor(public globals: GlobalsService,
              private dialogService: DialogService,
              private eventService: EventService,) {
  }
  ngOnInit() {
    console.log(this.publishable_key, this.client_secret)
    this.stripe = Stripe(this.publishable_key);
    const options = {
      clientSecret: this.client_secret,
      // Fully customizable with appearance API.
      appearance: {
        theme: 'night'
      },
    };

    // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in a previous step
    const elements = this.stripe.elements(options);

    // Create and mount the Payment Element
    const paymentElement = elements.create('payment');
    paymentElement.mount('#payment-element');
    paymentElement.on('ready', (event) => {
      this.elementReady = true;
    });



    const form = document.getElementById('payment-form');
    form.addEventListener('submit', async (event) => {
      event.preventDefault();
      this.stripeSubmitDisable = true;
      this.globals.startLoader();
      // Check for Available tickets
      let checkAvailable = await this.checkAvailablePurchase();
      if (!checkAvailable) {
        this.globals.stopLoader();
        this.checkAvailableFromStripe.emit()
        return
      }
      // Check for Available tickets END

      const {error} = await this.stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: this.return_url,
        },
      });

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        const messageContainer = document.querySelector('#error-message');
        messageContainer.textContent = error.message;
        this.globals.stopLoader();
        this.stripeSubmitDisable = false;
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        this.globals.stopLoader();
        this.stripeSubmitDisable = false;
      }

      console.log('error', error);
      this.rudderstackAddPaymentInfo.emit('stripe_gateway');

    });
  }

  checkAvailablePurchase() {
    return new Promise(resolve => {
      this.eventService.checkAvailablePurchase(this.main_purchase_identifier).subscribe((response: any) => {
        if(response.code == 'success') {
          resolve(true); // resolving our promise
        } else {
          if(response.code) {
            this.dialogService.alertsModal('purchaseEvent',response.code,5000,false);
          }
          resolve(false);
        }
      }, error => {
        if(error.code) {
          this.dialogService.alertsModal('purchaseEvent',error.code,5000,false);
        }
        resolve(false);
      });
    });
  }
}
