export const environment = {
  production: true,
  facebookClientId: 293218798257453,
  api_base_url: 'https://api-pacific-dev.getin.xyz',
  getin_base_url: 'https://app-pacific-dev.getin.xyz/',
  images_base_url: 'https://static-pacific-dev.getin.xyz',
  authorize_net_url: 'https://test.authorize.net/payment/payment',
  authorize_net_accept_url: 'https://jstest.authorize.net/v1/Accept.js',
  stripe_accept_url: 'https://js.stripe.com/v3/',
  hyper_script_url: 'https://beta.hyperswitch.io/v1/HyperLoader.js',
  api_nft_base_url: 'https://nfts.gettt.co.il',
  api_tweed_base_url: 'https://tweed.gettt.co.il',
  tranzila_apple_pay_url: 'https://direct.tranzila.com/js/tranzilanapple_v2.js',
  firebase: {
    apiKey: 'AIzaSyBi4qtcadsteWxnHRDyPeAkMwDmKOqHyjE',
    authDomain: 'get-in-247508.firebaseapp.com',
    databaseURL: 'https://get-in-247508.firebaseio.com',
    projectId: 'get-in-247508',
    storageBucket: 'get-in-247508.appspot.com',
    messagingSenderId: '658400122319',
    appId: '1:658400122319:web:b642dfbf6e0c76544d2eba',
    measurementId: 'G-QB85ZCKVL0',
  },
  pusher: {
    key: '95b3c8f433de354e708b',
    cluster: 'eu',
  },
  freshchat: {
    token: 'ec239adf-dbb9-4272-94f3-56b3f5dea984',
    host: 'https://wchat.freshchat.com',
  },
  intercom: {
    appId: 'dkr11a7r',
  },
  walletconnect: {
    projectId: '1c3daafa86438e9ce23f7bd02516117a',
    infuraId: 'c0043db9d72846039b536bcf8612b718',
  },
  fortmatic: {
    testKey: 'pk_test_3BF632187528C480',
    prodKey: 'pk_live_31A3031AA5F1EB5A',
  },
  portis: {
    portisId: 'fbac4e3c-4434-4a0a-a835-db2ab12e5c38',
  },
  getinWallet: {
    main: '0x001588cAb7a0B727c388174b1EF20B2E3D20d39b',
    walletMetaMaskAddress: '0x2E49601023F237e7BBDAc503D78FC7E5B42EF9F7',
    coinBaseAddress: '0xEc5aEE361F4d7aAf6135D19D6FcFD8568b1Bb186',
  },
  solana: {
    solana_endpoint_url: 'devnet',
    tls: true,
    getin_wallet: 'HifxrubHYd9Tgn49Zez3UtuWAqg5UjpMpqrQAVGVPFkk',
    rpc: 'https://solana-devnet.g.alchemy.com/v2/fhokfy8hX7ydgX8Y3EbAqNpC2vSK5HkW',
  },
  nft: {
    contract_address: '0xd6e63c474b21b26df1daa4870b58cc14e9327a67',
    providerOptions: {
      rpc: 'https://eth-goerli.g.alchemy.com/v2/2THjx5XAkdbEfobq2Il70u-dmd-V0y0j',
      chainId: 5,
    },
    // for TEST - https://eth-rinkeby.alchemyapi.io (https://testnets.opensea.io/)
    // for PROD - https://eth-mainnet.g.alchemy.com
    alchemy_url: 'https://eth-rinkeby.alchemyapi.io',
    opensea_url: 'https://opensea.io',
  },
  airdrop: {
    contract_address: '0xd6e63c474b21b26df1daa4870b58cc14e9327a67',
    providerOptions: {
      rpc: 'https://polygon-mainnet.g.alchemy.com/v2/ZZVVE5zVBjMkqGzj1pZD8Mbwo6qH86-s',
      chainId: 137,
    },
  },
  recaptcha: {
    disable: false,
    enterpriseKey: '6LefZpYnAAAAAEb7C5mjJrhfCarZVgTLq4iOIUoT',
    enterpriseChallengeKey: '6LdA8hsoAAAAALdcmwnUl51J0k4G1RbOW9kPpvCe',
  },
  sentry: {
    dsn: 'https://24ec4c3adec418fb7a25733d563a353f@o4505699650633728.ingest.sentry.io/4506453887156224',
    environment: 'stage',
  },
  tolgee: {
    apiUrl: 'https://tolgee.get-in.com',
    apiKey: '',
  },
  spotify: {
    client_id: '35a2cee6e8624721ab15a1de743eb62f',
    client_secret: '294c41766019455b995a9661ad1ec1ad',
  },
  plasmic: {
    type: 'preview',
    project_id: 'iuXTGxKy6H7DhoAS8VUTvy',
    api_token: 'IGWtiDGSCtVEzfHeqrKv6ehUi89KLrkfiPBSHCjRxMJ4xqQAjFtSqUvhhmgRIdyyC3xH2EyZV6sDvZyJhyA'
  },
  imgix: {
    source_id: '66e78c68132d0efc7b417d97',
    api_token: 'ak_abfd32b391f4d3685671fa9c9cb76332e1b01a23dd59228210c8dc7f445eff28',
    domain: 'https://getin-pacific-dev.imgix.net/'
  }
};
