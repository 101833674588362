import { Directive, ElementRef, OnInit, Output, EventEmitter } from '@angular/core';
declare var google: any;

@Directive({
  selector: '[appGoogleAutoComplete]'
})
export class GoogleAutoCompleteDirective implements OnInit {
  private element: HTMLInputElement;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  private latitude: number;
  private longitude: number;
  private countryCode: string;

  constructor(private elRef: ElementRef) {
    //elRef will get a reference to the element where
    //the directive is placed
    this.element = elRef.nativeElement;
  }

  ngOnInit() {
    //set google defaults
    this.latitude = 31.046051;
    this.longitude = 34.85161199999993;

    const autocomplete = new google.maps.places.Autocomplete(this.element);

    google.maps.event.addListener(autocomplete, 'place_changed', () => {

      //set latitude, longitude
      this.latitude = autocomplete.getPlace().geometry.location.lat();
      this.longitude = autocomplete.getPlace().geometry.location.lng();

      //get countrycode
      Object.keys(autocomplete.getPlace().address_components).forEach( comp => {
        if( autocomplete.getPlace().address_components[comp].types.indexOf('country') !== -1 ) {
          this.countryCode = autocomplete.getPlace().address_components[comp].short_name;
        }
      });

      this.onSelect.emit({place: autocomplete.getPlace().formatted_address, lat: this.latitude, lon: this.longitude, countryCode: this.countryCode});
    });
  }

}
