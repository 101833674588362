import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription ,  timer } from 'rxjs';
import {AuthService} from './auth.service';

@Injectable()
export class PhoneVerificationDialogService {

  private isVerifiedSource = new BehaviorSubject(false);
  private verifiedPhone = new BehaviorSubject('');
  private timerSecondsSource = new BehaviorSubject(0);
  isVerified = this.isVerifiedSource.asObservable();
  timerSeconds = this.timerSecondsSource.asObservable();
  countTimer = new Subscription;
  api_base_url = environment.api_base_url;

  constructor(private http: HttpClient, private authService: AuthService) { }

/**
 *
 * @param args
 * @returns
 * {
    "phone": "+382222",
    "phone_country_code": "ua",
    "otp_code": "2345"
    }
 */
  changeUserPhone(args: any) {
    return this.http.post(this.api_base_url + '/api/user/change-phone/V2', args,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }

  /**
   *
   * @param args
   * @returns
   * {
      "email": "email",
      "password": "password",
      "otp_code": "2345"
      }
   */
  changeUserEmail(args: any) {
    this.authService.isTokenExist();
    return this.http.post(this.api_base_url + '/api/user/change-email/V2', args,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }

  setIsVerified(verify: boolean) {
    this.isVerifiedSource.next(verify)
  }

  getIsVerified() {
    return this.isVerifiedSource.value
  }

  getLastVerifiedPhone() {
    return this.verifiedPhone.value;
  }

  setLastVerifiedPhone(phone: string) {
    this.verifiedPhone.next(phone);
  }

  getSeconds() {
    return this.timerSecondsSource.value;
  }

  setSeconds(sec: number) {
    this.timerSecondsSource.next(sec)
  }

  stopTimer() {
    this.countTimer.unsubscribe();
    this.timerSecondsSource.next(60);
  }

  startTimer() {
    this.countTimer = timer(60, 1000).pipe(map(i => 60 - i)).subscribe((val) => {
      val < 0 ? this.countTimer.unsubscribe() : this.timerSecondsSource.next(val);
    });
  }

}
