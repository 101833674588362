import { Component, OnInit } from '@angular/core';
import {EventService} from '../../services/event.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogService} from '../../services/dialog.service';
import {timer} from 'rxjs';
import {GlobalsService} from '../../globals';

@Component({
  selector: 'app-stripe-account-check',
  templateUrl: './stripe-account-check.component.html',
  styleUrls: ['./stripe-account-check.component.css']
})
export class StripeAccountCheckComponent implements OnInit {
  hash: string = '';
  fetched: boolean = false;
  completed: boolean = false;
  error: boolean = false;

  constructor(private activatedRoute: ActivatedRoute,
              private dialogService: DialogService,
              private router: Router,
              private globals: GlobalsService,
              private eventService: EventService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {

      this.hash = params['hash'];
      this.globals.startLoader();
      this.eventService.eventStripeAccountCheck(this.hash).subscribe((res: any) => {
        console.log('eventStripeAccountCheck', res);
        this.fetched = true;
        this.globals.stopLoader();
        if (res.code == 'success' && res.data) {
            this.completed = res.data.completed;
            timer(8000).subscribe(() => {this.router.navigate(['/dashboard'])});
        }

      }, error => {
        this.error = true;
        this.globals.stopLoader();
        this.dialogService.alertsModal('errors', error.code, 5000,false);
      });

    });
  }

}
