import {Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {GlobalsService} from '../../globals';
// import { ChartOptions, ChartType } from 'chart.js';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-analytic-insight',
  templateUrl: './analytic-insight.component.html',
  styleUrls: ['./analytic-insight.component.css']
})
export class AnalyticInsightComponent implements OnInit, AfterViewInit {
  totals = [
    { name: 'RETURN CUSTOMER', col: 23, id: 1 },
    { name: 'TOTAL WEBSITE VISITS', col: 10.547, id: 2 },
    { name: 'CONVERTION RATE', col: 9, id: 3 }
  ]
  @Input() statsData: any;
  @Input() barChartGenderData: any;
  @Input() barChartGenderLabels: any;

  @Input() barChartType: any;
  @Input() barChartColors: any;

  @Input() barChartAgeData: any;
  @Input() barChartAgeLabels: any;
  @ViewChild('genderCanvas') genderCanvas: ElementRef;
  public context: CanvasRenderingContext2D;
  dir: string;

  doughnutChartType = 'doughnut';
  barChartTypes = 'bar';

  doughnutChartOptions = {
    cutout: '80%',
    responsive: false,
    maintainAspectRatio: false,
    legend: {
      position: 'left',
      align: 'end',
      fullWidth: false,
      labels: {
        usePointStyle: true,
        // pointStyle: 'circle',
        fontColor: '#FFFFFF',
        boxWidth: 10,
      }
    },
    tooltips: {
      backgroundColor: '#FF6465',
      // yAlign: 'top',
      // xAlign: 'right'
      callbacks: {
        label: (tooltipItems, data) => {
          const label = data.datasets[0].data[tooltipItems.index];
          return ` ${label}`
        }
      }
    },
    cutoutPercentage: 83,
    animation: {
      onComplete: function(animation) {
        // const chartInstance = this.chart,
        // console.log("chartInstance", chartInstance);
      }
    }
  }

  barChartOptions = {
    indexAxis: 'y',
    responsive: true,
    legend: {
      display: false,
      // position: 'left',
       labels: { fontColor: '#000000'}
    },
    tooltips: {
      backgroundColor: '#FF6465'
    },
    scales: {
      x: {
        ticks: {
          color: 'white'
        }
      },
      y: {
        ticks: {
          color: 'white'
        }
      },
    },
  }

  onChartHover = (e: any) => {
    const canvas = e.event.chart;
    const chartInstance = e.event.chart;
    const legendItems = e.event.chart.boxes[0].legendItems;
    let ctx = canvas.ctx;
    const offsetX = (chartInstance.width / 2);
    const offsetY = (chartInstance.height / 2) + 20;
    ctx.clearRect((offsetX-40), (offsetY-35), 75, 45);
    ctx.font = '400 34px Montserrat';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = "white";
    const graphData = e.event.chart.config._config.data.datasets[0].data;
    const newGraphData = graphData.filter((val, i) => {
      return legendItems[i].hidden === false
    });
    if(e.active[0] && e.active[0].index >=0) {
      const genderValue = graphData[e.active[0].index];
      const sum = newGraphData.reduce((a, b) => a + b, 0);
      const genderPercent = Math.round(genderValue / sum * 100);
      const txt = genderPercent + '%';
      // console.log(txt, offsetX, offsetY)
      ctx.fillText(txt, offsetX, offsetY);
    }

  };

  onChartClick(e) {
    const canvas = <HTMLCanvasElement>this.genderCanvas.nativeElement;
    this.context = canvas.getContext('2d');
    if (this.genderCanvas.nativeElement.getContext && e.active[0]) {
      const chartInstance = e.event.chart;
      const graphData = e.event.chart.config._config.data.datasets[0].data;
      const genderValue = e.event.chart.config._config.data.datasets[0].data[e.active[0].index];
      const legendItems = e.event.chart.boxes[0].legendItems;
      const newGraphData = graphData.filter((val, i) => {
        return legendItems[i].hidden === false
      });
      const sum = newGraphData.reduce((a, b) => a + b, 0);
      const genderPercent = Math.round(genderValue / sum * 100);
      const canvas = e.event.chart.canvas;
      const context = e.event.chart.ctx;

      context.font = '400 34px Montserrat';
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.fillStyle = "white";
      const txt = genderPercent + '%';
      const offsetX = (chartInstance.width / 2);
      const offsetY = (chartInstance.height / 2) + 20;
      context.fillText(txt, offsetX, offsetY);
    }
  }
  maxGenderValue () {
    return Math.max(...this.barChartGenderData[0].data)
  }

  sumOfGenderValues () {
    return this.barChartGenderData[0].data.reduce((a, b) => a + b, 0)
  }

  constructor(private globals: GlobalsService) { }

  ngOnInit() {
    this.dir = this.globals.getDir();
    // console.log('barChartAgeLabels', this.barChartAgeLabels, this.barChartGenderLabels);
  }
  ngAfterViewInit() {
    const canvas = <HTMLCanvasElement>this.genderCanvas.nativeElement;
    const ctx = canvas.getContext('2d');
    const sum = this.sumOfGenderValues();
    const maxGenderValue = this.maxGenderValue();

    if (this.genderCanvas.nativeElement.getContext) {
      if (maxGenderValue && sum) {
        setTimeout(() => {
          const genderPercent = Math.round(maxGenderValue / sum * 100);
          ctx.font = '400 34px Montserrat';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = "white";
          const txt = genderPercent + '%';
          const offsetX = (ctx.canvas.width / 2);
          const offsetY = (ctx.canvas.height / 2) + 20;
          // console.log(txt,offsetX, offsetY)
          ctx.fillText(txt, offsetX, offsetY);
        }, 1000);
      }
    }
  }

}
