<div class="row event_status" *ngIf="events_user_id > 0">
	<div class="col-md-4 col-xs-12">
		<p class="disabled"><span i18n="@@CmsUsername">Username:</span> {{user_details?.first_name}} {{user_details?.last_name}}</p>
	</div>
	<div class="col-md-4 col-xs-12">
		<p class="disabled"><span i18n="@@CmsEmail">Email:</span> {{user_details?.email}}</p>
	</div>
	<div class="col-md-4 col-xs-12">
		<p class="disabled"><span i18n="@@CmsPhone">Phone:</span> {{user_details?.phone}}</p>
	</div>
</div>
<hr *ngIf="events_user_id > 0" class="mt0">
<div class="row">
		<div class="search col-md-4 col-xs-12">
			<label i18n="@@CmsEventStatus" for="user_type">Event status:</label>
			<select id="user_type" class="form-control" #status_list (change)="filterEventsByStatus(status_list.value)"  >
				<option i18n="@@CmsEventsStatuses" *ngFor="let s of filter_statuses" [selected]="s == status" value="{{s}}">{
					s, plural,
          =0 {Rejected events}
          =1 {Active events}
          =2 {Events being edited}
          =3 {Events pending approval}
          =4 {Closed events}
          =5 {Cancelled / Deleted events}
          =6 {Events that have ended}
          =10 {All events}
				}</option>
			</select>
		</div>
		<div class="search col-md-4 col-xs-12">
			<label i18n="@@CmsSearchEvents" for="user_type">Search Events:</label>
			<input i18n-placeholder="@CmsSearch" class="form-control" type="text" [(ngModel)]="filterText" name="search" placeholder="Search" (keyup)="addQueryParam(filterText)">
		</div>
		<!-- <div class="search col-md-4 col-xs-12">
			<label i18n="search by country" for="country">Filter by country:</label>
			<input i18n-placeholder="search" class="form-control" type="text" [(ngModel)]="filterByCountry" name="search" placeholder="country" (keyup)="addCountryToQueryParam(filterByCountry)">
		</div> -->
		<!-- <div class="search col-md-4 col-xs-12">
			<label i18n="search by country" for="country-filter">Filter by country:</label>
			<select id="country-filter" class="form-control" #country_list (change)="filterEventsByCountry(country_list.value)"  [(ngModel)]="filterByCountry">
				<option i18n="cms dashboard countries filter" *ngFor="let c of filter_countries[localeId]" [selected]="c.country == country" value="{{c.country}}">{{c.country}}</option>
			</select>
		</div> -->
		<div class="search col-md-4 col-xs-12">
			<label i18n="search by gateway" for="gatewayType">Filter by gateway:</label>
			<select id="gatewayType" class="form-control" name="gatewayType" (change)="filterByGateways($event.target.value)" #gatewayType="ngModel" [(ngModel)]="selectedGatewayType">
				<option value="0" [selected]="selectedGatewayType == '0'" i18n="@@CmsSelectAPaymentGateway">Select a payment gateway</option>
				<option *ngFor="let type of gatewayTypes" value="{{type}}">{{type}}</option>
			</select>
		</div>
	</div>
	<br>
	<div class="event_status" *ngIf="events_user_id > 0">
		<div class="row pr0 pl0">
			<div class="col-md-6 col-xs-12">
				<p><span i18n="@@CmsEventPending">Events pending approval:</span> {{(event_statuses?.total_pending) ? event_statuses?.total_pending : 0}}</p>
				<p><span i18n="@@CmsEventEdited">Events being edited:</span> {{(event_statuses?.total_on_edit) ? event_statuses?.total_on_edit : 0}}</p>
				<p><span i18n="@@CmsEventActive">Active events:</span> {{event_statuses?.total_active ? event_statuses?.total_active : 0}}</p>
			</div>
			<div class="col-md-6 col-xs-12">
				<p><span i18n="@@CmsEventClosed">Closed events:</span> {{(event_statuses?.total_closed) ? event_statuses?.total_closed : 0}}</p>
				<p><span i18n="@@CmsEventCancelled">Cancelled / Deleted events:</span> {{(event_statuses?.total_deleted) ? event_statuses?.total_deleted : 0}}</p>
				<p><span i18n="@@CmsEventEnded">Events that have ended:</span> {{(event_statuses?.total_ended) ? event_statuses?.total_ended : 0}}</p>
			</div>
		</div>
	</div>
	<br>
<div id="search_event" class="pending_table">
		<div class="row">
			<div class="col-md-12 col-xs-12">
				<div class="table-responsive">
					<table class="table table-striped table-hover">
						<thead>
						<tr>
							<th>#</th>
							<th i18n="@@CmsEventname">Event name</th>
							<th i18n="@@CmsProducername" *ngIf="events_user_id  == 0">Producer name</th>
							<th i18n="@@CmsEventCreationD">Creation date</th>
							<th i18n="@@CmsEventStartD">Event's start date</th>
							<th i18n="@@CmsEventEndD">Event's end date</th>
							<th i18n="@@CmsEventOptions">Options</th>
						</tr>
						</thead>
						<!-- <tr id="{{event.id}}" class="event" *ngFor="let event of events | search_params: filterText | search_country: filterByCountry | paginate: { itemsPerPage: pageSize, currentPage: page }"> -->
						<tbody>
						<tr id="{{event.id}}" class="event" *ngFor="let event of events | search_params: filterText | search_by_gateway: selectedGatewayType | paginate: { itemsPerPage: pageSize, currentPage: page }">
							<th scope="row">{{event.id}}</th>
							<td><span class="event_title">{{event.title}}</span> <span i18n="@@CmsEventsStatusesOption" class="event_sub_status" *ngIf="status == 10"> {
								event.status, plural,
                =1 {Active}
                =2 {Being edited}
                =3 {Pending approval}
                =4 {Ended}
                =5 {Suspended / Deleted}
							}</span></td>
							<td *ngIf="events_user_id == 0"> <a [routerLink]="['/cms/events/'+event.user_id+'/active']">({{event.user_id}}) {{event.producer_first_name}} {{event.producer_last_name}}</a></td>
							<td>{{event.created_at}}</td>
							<td>{{event.start_date}}</td>
							<td>{{event.end_date}}</td>
							<td *ngIf="currentStatus == 3" class="text-center col-md-2 col-xs-2">
									<div class="row">
										<div class="col-md-4 col-xs-4">
											<button (click)="approveEventAndOrUpdateCommission(event.id, 'approve')">
												<span i18n-title="@@CmsApprove" title="Approve"><i class="fa fa-check"></i></span>
											</button>
										</div>
										<div class="col-md-4 col-xs-4">
											<button>
											<span i18n-title="@@CmsView" [routerLink]="['/preview/' + event.id]" title="View"><i class="fa fa-eye"></i></span>
										</button>
										</div>
										<div class="col-md-4 col-xs-4">
											<button  (click)="updateEventByStatus(event.id, 0)">
											<span i18n-title="@@CmsReject" title="Reject"><i class="fa fa-close"></i></span>
										</button>
										</div>
									</div>
								</td>
							<td *ngIf="currentStatus != 3">
								<select class="options" id="{{event.id}}" #options (change)="setAction(options.value, event.id)">
									<option i18n="@@CmsEventOptions" value='' [selected]="dropdownSelected">Options</option>
									<option i18n="@@CmsViewEvent" *ngIf="currentStatus != 2 && currentStatus != 0" value='/{{event.id}}'>View event</option>
									<option *ngIf="currentStatus != 0" value='/edit-event/{{event.id}}'><a href="#" i18n="@@CmsEditEvent">Edit event</a></option>
<!--									<option *ngIf="currentStatus != 0" value='/bank-account/{{event.id}}'><a i18n="@@CmsEditBankAccount" href="#">Edit bank account details</a></option>-->
									<option *ngIf="currentStatus != 0" value='/sales-promotion/{{event.id}}'><a href="#" i18n="@@CmsEditSalesProm">Edit sales promotion</a></option>
									<option *ngIf="currentStatus != 0" value='/coupons/{{event.id}}'><a href="#" i18n="@@CmsEditCoupons">Edit coupons</a></option>
                  <option *ngIf="currentStatus != 0" value='/cms/discounts/{{event.id}}'>{{'CmsEventsTable.EditDiscount' | translate:'Edit Discount'}}</option>
									<option i18n="@@CmsViewStatisctics" *ngIf="currentStatus != 2 && currentStatus != 3 && currentStatus != 0" value='/analytics/dashboard/{{event.id}}'>Statistics</option>
									<option *ngIf="currentStatus != 0" value='event_info_option'>
											<button id="{{event.id}}" type="button" class="event_info" data-toggle="modal" data-target=".open_event_info"></button>
											<a i18n="@@CmsViewEventDetails" href="#">Event details</a>
									</option>
									<option i18n="@@CmsViewBankDetails" *ngIf="currentStatus != 0" value='show_bank_details'>Bank account details</option>
									<option i18n="@@CmsViewFeeManagment" *ngIf="currentStatus != 0" value='update_commission'>Fee management</option>
									<option i18n="@@CmsReports" value='/reports/{{event.id}}'>Reports</option>
									<option i18n="@@CmsAttendees" *ngIf="currentStatus != 0" value='/event-participants/{{event.id}}/all'>Attendees</option>
									<option i18n="@@CmsSalespeople" *ngIf="currentStatus != 0" value='/event-salesmen/{{event.id}}'>Salespeople</option>
									<option i18n="@@CmsScannerManagement" *ngIf="currentStatus != 0" value='/reader-signup/{{event.id}}'>Scanner management</option>
									<option i18n="event actions | send-tickets" *ngIf="currentStatus == 1" value='/send-tickets/{{event.id}}'>Send Tickets</option>
									<option i18n="event actions | seats config" *ngIf="currentStatus != 0" value='/cms/seats-config/{{event.id}}'>Seats Config</option>
									<option *ngIf="currentStatus != 0" value='/cms/stripe-payouts/{{event.id}}'>{{'CmsEventsTable.StripePayoutTransactions' | translate:'Stripe Payout Transactions'}}</option>
									<option i18n="@@CmsCheckout" *ngIf="currentStatus == 1" value='/event-payment/{{event.id}}'>Checkout</option>
									<option i18n="@@CmsWebHooksSettings" *ngIf="currentStatus == 1" value='/cms/web-hooks/{{event.id}}'>Web Hooks Settings</option>
<!--									<option i18n="event actions | external API settings" *ngIf="currentStatus != 0" value='/cms/external-api/0/{{event.id}}'>הוספת API חיצוני</option>-->
									<option i18n="@@CmsPurchaseFormSettings" *ngIf="currentStatus == 1" value='/cms/payment-form/{{event.id}}'>Purchase form settings</option>
									<option i18n="@@CmsUpdateEventStatus" *ngIf="currentStatus == 5 || currentStatus == 0" value='restore_event'>Update Event status</option>
									<!--<option i18n="event actions | manage email/sms templates" *ngIf="currentStatus != 2 && currentStatus != 0" value='cms/templates/{{event.id}}'><a href="#">תבניות</a></option>-->
									<option i18n="@@CmsDeleteEvent" *ngIf="currentStatus != 5 && currentStatus != 0" value='delete_event'>Delete event</option>
									<option i18n="@@CmsCloseEvent" *ngIf="currentStatus == 6" value='close-event'>Close event</option>
                  					<option i18n="event actions | event categories" *ngIf="currentStatus == 1 && event.status == 1" value='event-catigories'>Set event categoryies</option>
									<option i18n="@@CmsLinkToEvent" value='event-link'>Link to event</option>
                  <option i18n="event actions | cancel event" value='/cms/cancel-refund-event/{{event.id}}'>Cancel & Refund</option>
								</select>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
				<div *ngIf="events?.length > pageSize" class="col-md-12 col-xs-12 text-center">
					<pagination-controls i18n-nextLabel="@@CmsNextPage" i18n-previousLabel="@@CmsPrevPage" class="pagination col-md-12 col-xs-12" (pageChange)="page = $event" [responsive]="true" nextLabel="Next page" previousLabel="Previous page"></pagination-controls>
				</div>
			</div>
		</div>
	</div>
