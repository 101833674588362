<!--      <router-outlet></router-outlet>-->
<div class="cats-filters" #cats_filters>
  <div class="cat-sidenav">
    <app-category-sidenav></app-category-sidenav>
    <div class="event-filters" *ngIf="!isMobile">
      <app-autocomplete-filter-events class="event-filter" [ngClass]="dir"></app-autocomplete-filter-events>
      <app-select-filter-events></app-select-filter-events>
      <app-datepicker-filter-events></app-datepicker-filter-events>
    </div>
  </div>
</div>
<div class="event-filters" *ngIf="isMobile">
  <app-autocomplete-filter-events class="event-filter" [ngClass]="dir"></app-autocomplete-filter-events>
  <app-select-filter-events></app-select-filter-events>
  <app-datepicker-filter-events></app-datepicker-filter-events>
</div>
<div class="home-carousel">
  <app-home-carousel *ngIf="mainSliderData && mainSliderData.length > 0" [setSliderData]="mainSliderData" [isMobile]="isMobile"></app-home-carousel>
</div>
<div class="home-main" #home_main>
  <div id="category-date_events"><app-events-list [events]="date_events" [name]="'Filter by date'" [isMobile]="isMobile" [direction]="dir" *ngIf="date_events && date_events.length"></app-events-list></div>
  <div id="category-location"><app-events-list [events]="location" [name]="locationName" [isMobile]="isMobile" [direction]="dir" *ngIf="location && location.length"></app-events-list></div>
  <app-events-list id="category-trending" [events]="trending" [name]="'trending'" [categoryId]="'trending'" [categoryName]="'Trending'" [isMobile]="isMobile" [direction]="dir" *ngIf="trending && trending.length"></app-events-list>
  <app-events-list id="category-featured" [events]="featured" [name]="'featured'" [categoryId]="'featured'" [categoryName]="'Featured'" [isMobile]="isMobile" [direction]="dir" *ngIf="featured && featured.length"></app-events-list>
  <app-events-list id="category-free" [events]="free" [name]="'free'" [categoryId]="'free'" [categoryName]="'free'" [isMobile]="isMobile" [direction]="dir" *ngIf="free && free.length"></app-events-list>
  <app-events-list id="category-followed" [events]="followed" [name]="'followed'" [categoryId]="'followed'" [categoryName]="'Followed'" [isMobile]="isMobile" [direction]="dir" *ngIf="followed && followed.length"></app-events-list>
  <!--        <app-events-list [events]="events" [name]="'All events'" [isMobile]="isMobile" *ngIf="events && events.length"></app-events-list>-->
  <!--        <app-events-list [events]="events" [name]="'All events'" [type]="'type-1'" [isMobile]="isMobile" *ngIf="events && events.length"></app-events-list>-->

  <!-- CUSTOM CATEGORIES-->
  <div class="category-events" *ngFor="let category of categorized | keyvalue" id="{{'category-' + category.key}}" #category_child>
    <app-events-list [events]="category.value" [name]="category.key" [categoryId]="category.value[0]?.filteredCategoryId" [categoryName]="category.key" [isMobile]="isMobile" [direction]="dir" *ngIf="category.value && category.value.length"></app-events-list>
  </div>
  <app-events-list [events]="events" [name]="'All events'" [categoryId]="'all'" [categoryName]="'Events'"  [isMobile]="isMobile" [direction]="dir" *ngIf="events && events.length"></app-events-list>
<!--  <app-events-list id="category-week" [events]="isMobile ? events : week_splitBy3" [name]="'Events This Week'" [type]="'type-3'" [isMobile]="isMobile" [categoryId]="'week'" *ngIf="week_splitBy3 && week_splitBy3.length"></app-events-list>-->
  <!--        <app-events-list id="category-week" [events]="isMobile ? week : week_splitBy3" [name]="'Events This Week'" [type]="'type-3'" [isMobile]="isMobile" *ngIf="week_splitBy3 && week_splitBy3.length"></app-events-list>-->
  <!--      <app-events-list [events]="events" [isMobile]="isMobile"></app-events-list>-->
</div>
