import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild, AfterViewInit, OnDestroy
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {GlobalsService} from '../../globals';
import {FormBuilder, Validators} from "@angular/forms";
import {EventService} from "../../services/event.service";
import {DialogService} from "../../services/dialog.service";
import {Chart} from "chart.js/auto";
import moment from 'moment';
import {Observable, Subscription} from "rxjs";

@Component({
  selector: 'app-analytic-scanned',
  templateUrl: './analytic-scanned.component.html',
  styleUrls: ['./analytic-scanned.component.scss']
})
export class AnalyticScannedComponent implements OnInit, AfterViewInit, OnDestroy {
  totals = [
    { name: 'TOTAL VISITORS', col: 2.723, id: 1 },
    { name: 'SCANNED', col: 10.547, id: 2 },
    { name: 'DIDN’T SCAN', col: 9.456, id: 3 }
  ]
  @Input() statsData: any;
  @Input() lineChartType: any;
  @Input() reRenderChart: Observable<void>;
  // 2nd graph By minutes
  @Input() lineChartScannedDataByMinutes: any;
  @Input() lineChartScannedLabelsByMinutes: any;
  @Output() onFetchScanned = new EventEmitter<any>();
  @ViewChild('myScannedChart') myScannedChart!: { nativeElement: any };
  chart: any;
  canvas: any;
  ctx: any;
  zoomOptionsByMinutes = {};
  lineChartOptionByMinutes: any = {};
  checkInsPerMin = 0;
  firstCheckIn = '';

  dir: string;

  @Input() event_id: number;
  form = this.fb.group({
    name: ['', Validators.required],
    device_id: ['']
  });
  showForm: boolean = false;
  @Output() getStatsDataReaderList: EventEmitter<any> = new EventEmitter();
  selectedReaderIndex;
  private chartSubscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    // private authService: AuthService,
    private globals: GlobalsService,
    private eventService: EventService,
    private dialogService: DialogService,
    // private router: Router
  ) {
    this.activatedRoute.params
      .subscribe((params: Params) => {
        if (params['event_id']) {
          this.event_id = +params['event_id'];
          // this.getReaderSignupData();
        }
      });
  }

  // getReaderSignupData() {
  //   this.globals.startLoader();
  //   this.authService.getReaderSignupData(this.event_id)
  //     .subscribe(
  //       (response: any) => {
  //         this.globals.stopLoader();
  //         this.readerData = response.data;
  //       },
  //       error => {
  //         this.globals.stopLoader();
  //         if (error.code || error.httpStatus !== 403) {
  //           this.dialogService.alertsModal('errors', (error.code) ? error.code : 4, 5000, false, error.log_id);
  //         } else {
  //           this.router.navigate(['/reader-signin']);
  //         }
  //       }
  //     );
  // }

  showFormAs(action: 'add'| 'edit', device_id, title, index) {
    this.form.patchValue({device_id, name: title});
    // console.log(action, device_id, title, this.form.value, index);
    this.selectedReaderIndex = index;
    this.showForm = true;
  }
  saveDevice() {
    let {device_id, name} = {...this.form.value};
    // console.log('saveDevice', this.form.value, device_id, name);
    if(this.form.invalid) {return}
    this.globals.startLoader();
    this.eventService.guestManagerSaveDeviceName(this.event_id, device_id,name).subscribe((res: any) => {
      if(res && res.code == 'success') {
        if(this.selectedReaderIndex >= 0) {
          this.getStatsDataReaderList.emit({index: this.selectedReaderIndex, title: name});
          this.showForm = false;
        }
        this.globals.stopLoader();
      }
    }, error => {
      this.dialogService.alertsModal('errors', error.code, 3000, false);
      this.globals.stopLoader();
    })
  }

  ngOnInit() {
    this.dir = this.globals.getDir();
    // if (this.event_id) {
    //   this.getReaderSignupData();
    // }

    this.initOptionsForScannedByMinute();
    this.checkInsPerMin = this.calcCheckInsPerMin();

    this.chartSubscription = this.reRenderChart.subscribe(() => {
      if(this.chart) {
        this.chart.destroy();
      }
      this.initOptionsForScannedByMinute();
      this.checkInsPerMin = this.calcCheckInsPerMin();
      this.renderChart()
    });

  }

  calcCheckInsPerMin() {
    const dates = this.lineChartScannedLabelsByMinutes;
    if(dates.length) {
      const dateFirst = moment(dates[0]); // First scan date
      const dateLast = moment(dates[dates.length-1]); // Last scan date
      const diff = dateLast.diff(dateFirst, 'minutes');
      console.log(diff);
      // console.log(diff / this.statsData.scanned_graph.all_count);
      this.firstCheckIn = moment(dates[0]).format("hh:mm");
      return Math.ceil(this.statsData.scanned_graph.all_count  / diff)
    }
  }

  initOptionsForScannedByMinute() {
    this.zoomOptionsByMinutes = {
      zoom: {
        wheel: {
          enabled: true,
        },
        pinch: {
          enabled: true,
        },
        mode: 'xy',
      },
      pan: {
        enabled: true,
        mode: 'xy',
      },
      limits: {
        x: {
          min: new Date('2020-01-01 12:00').valueOf(),
          max: new Date('2050-01-01 12:00').valueOf()
        },
        y: {
          min: 0,
          max: 100
        }
      },
    };
    this.lineChartOptionByMinutes = {
      responsive: true,
      // legend: { position: 'bottom' }
      scales: {
        // We use this empty structure as a placeholder for dynamic theming.
        x: {
          position: 'bottom',
          ticks: {
            color: 'white',
            autoSkip: true,
            autoSkipPadding: 50,
            maxRotation: 0
          },
          border: {
            display: true
          },
          grid: {
            color: '#252045'
          },
          type: 'time',
          time: {
            minUnit: 'minute',
            tooltipFormat: 'HH:mm',
            displayFormats: {
              year: 'yyyy',
              quarter: 'qqq - yyyy',
              month: 'MMM yyyy',
              week:  'MMM yyyy',
              day:  'HH:mm',
              hour: 'HH:mm',
              minute: 'HH:mm',
              second: 'HH:mm',
              millisecond: 'HH:mm',
            }
          }
        },
        y:{
          ticks: {
            color: 'white',
            stepSize: 1,
            callback: (value, index, values) => {
              // console.log(value, index);
              if(index == 0) {
                return Math.floor(value)
              } else {
                return Math.ceil(value)
              }

            }
          },
          border: {
            display: true
          },
          grid: {
            color: '#252045'
          }
        }
      },
      elements: {
        line: {
          spanGaps: true
        }
      },
      tooltips: {
        backgroundColor: '#fffff',
        caretPadding: 10
      },
      plugins: {
        zoom: this.zoomOptionsByMinutes,
        legend: {
          display: false
        }
        // tooltip: {
        //   filter: (c) => {
        //
        //     // if(c.formattedValue > 0) {
        //     //   return c
        //     // }
        //   }
        // }
      }
    }

  }
  refreshScannedChart() {
    this.onFetchScanned.emit('scanned')
  }

  renderChart() {
    if(this.myScannedChart) {
      this.canvas = this.myScannedChart.nativeElement;
      this.ctx = this.canvas.getContext('2d');
      this.chart = new Chart(this.ctx, {
        type: this.lineChartType,
        data: {
          labels: this.lineChartScannedLabelsByMinutes,
          datasets: this.lineChartScannedDataByMinutes
        },
        options: this.lineChartOptionByMinutes,
      });
    }
  }

  ngAfterViewInit() {
    this.renderChart()
  }
  ngOnDestroy() {
    this.chartSubscription.unsubscribe();
  }

}
