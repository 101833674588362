import {
  Pipe,
  PipeTransform
} from '@angular/core';
import {
  DomSanitizer,
  SafeHtml
} from '@angular/platform-browser';

@Pipe({
  name: 'trustHtml'
})
export class HtmlPipe implements PipeTransform {

  constructor(private trustHtml: DomSanitizer) {}

  transform(html: string): SafeHtml {
    return this.trustHtml.bypassSecurityTrustHtml(html);
  }
}