import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogService} from '../../services/dialog.service';
import {EventService} from '../../services/event.service';
import {PlaidService} from '../../services/plaid.service';
declare var window: any;
declare var Plaid;

@Component({
  selector: 'app-plaid',
  templateUrl: './plaid.component.html'
})
export class PlaidComponent implements OnInit {
  token: string = '';

  constructor(private activatedRoute: ActivatedRoute,
              private eventService: EventService,
              private dialogService: DialogService,
              private plaidService: PlaidService,
              private router: Router) { }

  async ngOnInit() {
    console.log('PlaidComponent')

    this.eventService.plaidTokensLink().subscribe(async (res: any) => {
      console.log(res);
      if(res.data && res.data.token) {
        localStorage.setItem('link_token', JSON.stringify(res.data.token));
        console.log('localStorage SET link_token', res.data.token, window.location.href)
        if(res.data.user_plaid_id) {
          localStorage.setItem('user_plaid_id', JSON.stringify(res.data.user_plaid_id));
        }

        const handler = await this.plaidService.create(res.data.token);
        console.log(handler);
        handler.open();
      }

    })


  }

}
