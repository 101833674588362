import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ManagePasscodesService {
  api_base_url = environment.api_base_url + '/api/purchase-passcodes/';
  constructor(private http: HttpClient) { }

  getPasscodes(event_id) {
    return this.http.get(this.api_base_url + `${event_id}`, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }),
    });
  }

  updatePasscodes(event_id, data) {
    // data = {
    //   "purchase_passcodes": [
    //     {
    //       "id": 1,
    //       "passcode": "test 7"
    //     },
    //     {
    //       "passcode": "adad"
    //     }
    //   ]
    // }
    return this.http.post(this.api_base_url + `${event_id}`, data,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      });
  }
  deletePasscode(id, event_id) {
    return this.http.delete(this.api_base_url + `${event_id}/${id}`,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      });
  }
}
