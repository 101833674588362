import {Component, Inject, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
  ManagePasscodesService
} from "../../../services/manage-passcodes.service";
import {DialogService} from "../../../services/dialog.service";

@Component({
  selector: 'app-manage-passcodes-dialog',
  templateUrl: './manage-passcodes-dialog.component.html',
  styleUrls: ['./manage-passcodes-dialog.component.scss']
})
export class ManagePasscodesDialogComponent implements OnInit {
  passcodesForm: FormGroup;
  show_pass: boolean = false;
  isData: boolean = false;
  fetching: boolean = false;

  constructor(private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data,
              private dialogRef: MatDialogRef<ManagePasscodesDialogComponent>,
              private passcodesService: ManagePasscodesService,
              private dialogService: DialogService) {
    this.passcodesForm = this.fb.group({
      purchase_passcodes: this.fb.array([]) ,
    });
  }

  ngOnInit(): void {
    this.getPasscodes();
  }

  getPasscodes() {
    this.fetching = true;
    this.passcodesService.getPasscodes(this.data.id).subscribe(
      (response: any) => {
        if (response.data) {
          if(response.data.length) {
            this.isData = true;
          }
          response.data.forEach(code => {
            let arr = this.passcodesForm.get('purchase_passcodes') as FormArray;
            arr.push(this.newPassCode(code));
          })
        }
        this.fetching = false;
      },
      (error: any) =>  {
        this.fetching = false;
        this.dialogService.alertsModal('errors', 4.1, 3000, false);
      });
  }


  get passcodes() : FormArray {
    return this.passcodesForm.get("purchase_passcodes") as FormArray
  }
  newPassCode(item = null): FormGroup {
    return this.fb.group({
      id: item ? item.id : null,
      passcode: item ? item.passcode : '',
    })
  }
  addPassCode() {
    this.passcodes.push(this.newPassCode());
  }
  clearPassCodeArray() {
    this.passcodes.clear();
  }
  removePassCode(i:number, code) {
    const id = code.get('id').value;
    if(id) {
      this.fetching = true;
      this.passcodesService.deletePasscode(id, this.data.id).subscribe(
        (response: any) => {
          this.passcodes.removeAt(i);
          this.fetching = false;
        },
        (error: any) =>  {
          this.dialogService.alertsModal('errors', 4.1, 3000, false);
          this.fetching = false;
        });
    } else {
      this.passcodes.removeAt(i);
    }

  }

  togglePassword() {
    this.show_pass = !this.show_pass;
  }

  close() {
    this.dialogRef.close();
  }

  onSubmit() {
    // console.log(this.passcodesForm.value);
    if (this.passcodesForm.invalid) {return}
    this.fetching = true;
    this.passcodesService.updatePasscodes(this.data.id, this.passcodesForm.value).subscribe(res => {
      this.fetching = false;
      this.dialogService.alertsModal('success', 'success', 3000, true);
      this.dialogRef.close();
    }, error => {
      this.fetching = false;
      this.dialogService.alertsModal('errors', 4.1, 3000, false);
    })
  }
}
