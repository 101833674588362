<button class="close-button" [matDialogClose]>
  <img src="/assets/images/svg-icons/close.svg" alt="close">
</button>
<h3 class="contact-header" i18n="head | Contact">Contact the organizer</h3>

<form [formGroup]="contactProducerForm">

  <div class="control-group {{data.dir}}">
    <mat-form-field appearance="fill" style="width: 100%;" class="subject-input">
      <mat-label i18n="label | Subject">Subject</mat-label>
      <mat-select formControlName="subject">
        <mat-option *ngFor="let sub of subjects;" [value]="sub.id">{{sub.name[globals.getLocaleId()]}}</mat-option>
      </mat-select>
    </mat-form-field>
    <app-error-creator *ngIf="contactProducerForm.controls.subject.touched && contactProducerForm.controls.subject.invalid"
                       [control]="contactProducerForm.controls.subject"></app-error-creator>
  </div>

  <div class="control-group">
    <mat-form-field appearance="fill" style="width: 100%;" class="desc-input">
      <mat-label i18n="label | Your message">Your message</mat-label>
      <textarea formControlName="description" rows="5" matInput></textarea>
    </mat-form-field>
    <app-error-creator *ngIf="contactProducerForm.controls.description.touched && contactProducerForm.controls.description.invalid"
                       [control]="contactProducerForm.controls.description"></app-error-creator>
  </div>

  <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; padding: 15px 0;">
    <!--when working with formControls and formGroup-->
    <input type="hidden" data-recaptcha-token="" name="g_recaptcha_response" required formControlName="g_recaptcha_response"/>
    <div id="recaptcha_contactproducer" #recaptcha_contactproducer style="display: flex;justify-content: center;"></div>
    <div *ngIf="contactProducerForm.controls.g_recaptcha_response.touched && contactProducerForm.controls.g_recaptcha_response.invalid" class="error" i18n="captcha | error">Captcha must be solved</div>
  </div>

  <button (click)="submit()" [disabled]="!contactProducerForm.valid && contactProducerForm.touched" class="submit" i18n="button | Send your message">Send your message</button>

</form>
