<section class="cms_users">
    <h1 i18n="page title">Event categories</h1>
    <form (ngSubmit)="searchCategory()" #searchForm="ngForm">
    <div class="row">
      <div class="search col-md-6 col-xs-12">
        <label i18n="label | category status" for="status" class="col-form-label">Category status</label>
        <select id="status" name="status" class="form-control" (change)="filterStatus(category_status.value)" #category_status>
            <option i18n="category status filter" *ngFor="let status of status_codes" [selected]="status.code == status_code" value="{{status.code}}">{
              status.code, plural,
              =1 {Active category}
              =0 {Inactive category}
            }</option>
        </select>
      </div>
      <div class="search col-md-6 col-xs-12">
        <label i18n="category search title" for="search_category">Category lookup:</label>
        <input i18n-placeholder="placeholder | category search title" id="search_category" class="form-control" type="text" [(ngModel)]="filterText" name="search" placeholder="Search by category name">
      </div>
      <div class="col-md-6 col-xs-6 pt25">
          <button i18n="category search button | submit" type="submit" class="btn btn-primary" style="width:100%;">Search</button>
      </div>
      <div class="col-md-3 col-xs-3 pt25">
        <button i18n="category search button | clear search" type="button" class="btn btn-danger" style="width:100%;" (click)="clearSearch()">Clear</button>
      </div>
      <div class="col-md-3 col-xs-3 pt25">
        <button i18n="category create button | create new category" type="button" class="btn btn-success" style="width:100%;" (click)="createCategory()">Create category</button>
      </div>
    </div>
  </form>
    <br>
    <div *ngIf="filteredCategories?.length == 0"><h3 i18n="sub-title | no images found" class="text-center">Category not found</h3></div>
	<div *ngIf="filteredCategories?.length > 0" id="search_event" class="pending_table">
		<div class="row">
			<div class="col-md-12 col-xs-12">
				<div class="table-responsive">
					<div *ngIf="loader">
              <!-- <img class="loader img-responsive" src="assets/images/preloader.gif"/> -->
              <mat-spinner style="margin:0 auto;" strokeWidth="5" diameter="70"></mat-spinner>
					</div>
					<table class="table table-striped table-hover">
            <thead>
						<tr>
              <th>#</th>
							<th i18n="category table  | category title header">Name</th>
                <th i18n="category table  | status header">Status</th>
                <th i18n="category table  | option header">Created</th>
                <th i18n="category table  | option header">Updated</th>
                <th i18n="category table  | options header">Options</th>
						</tr>
            </thead>
            <tbody>
						<tr id="{{category.id}}" *ngFor="let category of filteredCategories | paginate: { id: 'server', itemsPerPage: pageSize, currentPage: page,  totalItems: total }">
								<th scope="row">{{category.id}}</th>
								<td>{{category.name ? category.name[language] : ''}}</td>
                <td i18n="category table  | category status">{category.status, plural, =1 {Active} =0 {Inactive}}</td>
                <td>{{category.created_at}}</td>
                <td>{{category.updated_at}}</td>
                <td class="options">
                <button i18n-title="@@Edit" title="Edit" class="option-transparent" (click)="editCategory(category.id)">
                    <i class="fa fa-edit"></i>
                </button>
                <button i18n-title="@@Delete" title="Delete" class="mr10 option-transparent" (click)="deleteCategory(category.id)"><i class="fa fa-trash-o"></i></button>
                </td>
						</tr>
            </tbody>
					</table>
				</div>
				<div *ngIf="total > pageSize" class="col-md-12 col-xs-12 text-center">
					<pagination-controls
						i18n-nextLabel
						i18n-previousLabel
						class="pagination col-md-12 col-xs-12"
						(pageChange)="getCategoriesData($event, category_status.value, false, true)"
						responsive="true"
            nextLabel="Next page"
            previousLabel="Previous page"
						id="server">
					</pagination-controls>
				</div>
			</div>
		</div>
	</div>
 </section>
