import { AppResponse } from './../interfaces/app-response.interface';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PaymentGatewayService {

  api_base_url = environment.api_base_url;

  constructor(private http: HttpClient) { }

  getGatewayTypes(eventId?: string) {
    const event = eventId ? '/' + eventId : '/0';
    return this.http.get(this.api_base_url + '/api/event/get-payment-gateways-type' + event,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }

  getGateways(gatewayType: string) {
    return this.http.get(this.api_base_url + '/api/event/get-payment-gateways-by-type/' + gatewayType,
    {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }

  getGatewayFields(gatewayType: string) {
    return this.http.get(this.api_base_url + '/api/event/get-payment-gateway-fields/' + gatewayType,
    {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }

  setNewGateway(arg: any) {
		return this.http.post(this.api_base_url + '/api/event/create-payment-gateway',
			arg,
			{headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
	}
}
