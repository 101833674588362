import { Injectable } from '@angular/core';
@Injectable()
export class ErrorCodesService {

    errors = {
        1: "Phone number already exists for this event",
        2: "An error has occurred, please try again. if the problem isn't solved, please contact Getin support.",
        3: "Wrong username or password.",
        3.1: "Please enter a valid Email.",
        3.2: "Please enter a valid Phone number.",
        4: "An error has occurred, please contact Getin support.",
        4.1: "An error has occurred, please try again.",
        4.2: "Failed to connect, please try again.",
        4.3: "Registration failed, please try again.",
        4.4: "Logout failed, please try again.",
        5: "The provided Email already exists.",
        6: "This field is required.",
        6.1: "Username and password are required.",
        7: "There are no salesmen to display for this filter.",
        8: "There are no events to display for this filter.",
        9: "You are unauthorized to access this page, please signin.",
        11.1: "Invalid Email.",
        11.2: "Email not found.",
        11.3: "User already exists.",
        11.4: "The password must contain at least 6 characters.",
        11.5: "Please try again later or contact support.",
        11.6: "Requests are sent too often, please try again in a hour.",
        11.7: "Captcha token is invalid. Please try again later.",
        12: "Please enter a valid phone number.",
        12.1: "Please enter a valid Email.",
        13: "Passwords don't match.",
        13.1: "Wrong old password.",
        13.2: "The specified user doesn't exist.",
        14: "Your account hasn't been activated yet, please check your Email and verify your account to proceed.",
        15: "Unidentified social network.",
        16: "Please confirm you have read terms of use in order to proceed.",
        17: "One or more of the provided Emails are invalid.",
        17.5: "User not authenticated.",
        18: "Please fill out all required fields.",
        19: "The user didn't allow Getin App to access his profile.",
        20: "The user doesn't exist.",
        21: "Invalid activation link, please make sure it's copied correctly.",
        22: "User account is already active.",
        23: "Unauthorized action.",
        24: "Sold out.",
        25: "Insufficient items.",
        26: "An error has occurred while updating password, please try again.",
        27: "The provided passwords don't match.",
        30: "Please choose a language",
        31: "Something went wrong with the bank's API",
        32: "Follow this producer is not possible",
        33: "Please confirm you have read health statement in order to proceed.",
        34: "Otp code is required",
        35: "Otp code is not valid",
        36: "Otp code not sent, please try again later.",
        37: "Requests are sent too often, please try again in a minute.",
        40: "Server error, authorization is invalid. Please try login later.",
        41: "You have been blocked for 1 hour.",
        42: "Login failed, Due to high bot activity. Please try again.",
        43: "OTP is not sent, email is block. Please contact the support team.",
        44: "Otp is not sent, try later. Error code - 1",
        45: "Could not send confirmation SMS. Please use your Email instead.",
        46: "You can not change phone",
        47: "You have been blocked for 3 hour.",
        48: "Event is on setup state. Please try again later.",
        49: "Hash is not valid.",
        50: "We can not create Connect Account for you. Please contact Get-In support. ",
        51: "Full name field is not valid.",
        52: "The selected tickets are invalid.",
        53: "Login failed, Please try again.",
        54: "Token is not valid, Please try again.",
        55: "Change email failed, Please try again.",
        56: "Change phone failed, Please try again.",
        57: "Registration failed, Please try again.",
        58: "Tickets data is not valid.",
        59: "We can’t deliver the SMS due to some reason. Please contact customer support via Intercom (Help menu).",
        60: "Problem with login. Please contact customer support via Intercom (Help menu).",
        61: "Please try again later or contact support.",
        62: "Ticket is sold out.",
        63: "Code not exists.",
        64: "Message couldn't be sent. Please contact support team. Error code 64.",
        65: "Please contact support team. Error code 65.",
        1111: "The Getin platform is temporarily unavailable due to a system maintenance upgrade",
    }

    salesman = {
        1: "This salesman is already associated to the event.",
        3: "The specified salesman doesn't exist.",
        4: "The event producer cannot be added to the event as a salesman.",
        5: "A manager cannot delete another manager.",
        6: "Coupon amount should be a positive number.",
        7: "Invalid Email or phone number.",
        8: "An error has occurred, please try again.",
        9: "No salesmen found for this event.",
        10: "The user didn't allow to be added as a salesperson",
    }
    editEvent = {
        1: "Event title is invalid or missing.",
        2: "Friendly url should include English charachters only.",
        3: "Start / End date should be defined.",
        4: "There should be at least one item for sale (Ticket / Room / Table).",
        5: "One or more of the created tickets are invalid, please make sure all item fields were set properly.",
        5.1: "No tickets found for the event, please make sure there are tickets set and that they are valid and active.",
        6: "One or more of the created rooms are invalid, please make sure all item fields were set properly.",
        6.1: "No rooms found for the event, please make sure there are rooms set and that they are valid and active.",
        7: "One or more of the created tables are invalid, please make sure all item fields were set properly.",
        7.1: "No tables found for the event, please make sure there are tables set and that they are valid and active.",
        8: "Bank details are invalid, please make sure all required fields are filled out properly.",
        9: "All fields in bank details are required.",
        11: "One or more of the coupon fields are invalid.",
        12: "The provided time to create / edit the event has ended.",
        13: "The event has ended / closed.",
        14: "This field is limited up to 10MB.",
        15: "An error occurred when saving draft.",
        16: "Minimum age value is required",
        17: "Birth date is required",
        18: "Minimum age is required",
        19: "Minimum age value is required",
        20: "Purchase type is required",
        21: "Timer type is required",
        22: "Timer title is required",
        25: "Please choose privacy settings",
        26: "One or more of the fields in Marketing tab are invalid",
        27: "Items' dates are invalid",
        28: "Coupons' dates are invalid",
        29: "Description field size exceeds the limit of 10MB, the draft will not be saved",
        30: "Please define the currency for event",
        31: "One or more of the created ticket categories are invalid, please make sure all item fields were set properly.",
        32: "The event is not editable.",
        33: "Gender is invalid or missing.",
        34: "Id number is invalid or missing.",
        35: "Getin subscription is missing.",
        36.1: "No data in input fields",
        36.2: "Field type is not valid",
        36.3: "Required type is not valid",
        36.4: "Title is not valid",
        36.5: "Opinions are not valid",
        36.6: "Opinions are not necessarry for text input",
        36.7: "Opinions fields are not necessary",
        36.8: "Opinion number is not valid",
        36.9: "Opinion title is not valid",
        37: "Ticket Quantity is invalid",
        38: "Coupon amount is invalid",
        39: "Discount amount is invalid",
    }
    purchaseEvent = {
        1: "The provided time to buy the event has ended.",
        2: "Your card was declined.",
        3: "Please choose seats.",
        4.1: "An error has occurred, please try again.",
        4.2: "The purchase period has expired.",
        4.3: "You have already canceled your purchase, please try again.",
    }
    bankService = {
        1: "An error has occurred while fetching regional bank information, please try again",
    }
    fileUploads = {
        1: "No file uploaded.",
        2.1: "Invalid file extension, please upload a file of type: .jpeg / .png / .jpg / .gif",
        2.2: "Invalid file extension, please upload a file of type: .xlsx / .ods / .odt / .odp / .csv",
        3: "Max file size limit exceeded.",
        3.1: "Max file size limit (10MB) exceeded after processing file.",
        4.1: "File too small, please upload a file size of at least 300KB.",
        4.2: "File too small, please upload a file size of at least 1KB.",
        4.3: "File too small, please upload a file size of at least ",
        5: "Invalid file extension, please upload an image file only.",
        6: "Please upload a single file.",
        7: "Column format in the provided file don't match required format.",
        8: "An error has occurred while updating.",
        9: "An error has occurred while uploading file.",
        10: "One or more parameters are missing",
    }
    sendTicketsUploads = {
        1: "Error import file",
        2: "Error not enough tickets",
        3: "Error import file is not valid",
        4: "Error apply is empty",
        5: "Error apply is not valid",
        6: "Error apply not have file in the server",
        7: "Error ticket is not valid",
        8: "Error ticket is not regular",
        9: "Error tickets not found when upload",
        9.1: "Error Regular tickets not found",
        10: "Error not valid tickets quantity"
    }
    cms = {
        1: "One or more of the required fields are missing.",
        2: "An API already exists on this user, thus no change was made.",
        3: "The update was successful, but Email wasn't sent to producer.",
        4: "Event was approved, but commissions were NOT updated, please contact dev team.",
        5: "The update failed, please contact dev team.",
        5.1: "Some gateways do not support currency. Please assign the event currency to the gateway",
        6: "Event ID missing in request, please contact dev team.",
        7: "The update failed due to a technical error, please contact dev team.",
        8: "Event cannot be moved to approved events since the event's end date has passed.",
        9: "Event cannot be moved to ended events since the event's end date has not passed yet.",
        10: "Data retrieval failed, please contact dev team.",
        11: "The param name must begin with an English charachter and can contain the following charachters only: English, numbers and underscores with no spaces.",
        12: "Please enter a valid URL.",
        13: "The provided values entered in URL are invalid, please make sure to type in the URL params and values in the following format: param=value.",
        14: "One or more of the provided params entered in URL don't exist.",
        15: "Param name cannot contain the following keywords: participant_ / custom_ / purchase_ .",
        16: "Update failed, no changes were made.",
        17: "Webhook ID is missing, please contact dev team.",
        18: "Webhook status is missing, please contact dev team.",
        19: "Failed to update webhook, query string is missing.",
        20: "Failed to update webhook, missing data to process request.",
        21: "Param name must be unique.",
        22: "Data not found.",
        23: 'User exists with this email address.',
        24: 'User exists with this phone.',
        25: 'Email is not valid.',
        26: 'Phone is not valid.',
        27: 'an event with more than X tickets can be approved by a subset of admins.',
        28: 'You don not have access for this action.',
        29: 'Please check Boost Status field. Invalid selection.',
    }
    landingPage = {
        1: "One or more of the provided details do not match system data, please contact Getin support.",
        2: "There was no cached order, please place a new order.",
        3: "Order expired, please place a new one.",
        4: "An error has occurred while validating payment details, your account was not charged. Please try again.",
        5: "Coupon doesn't exist.",
        6: "Inactive coupon.",
        7: "Coupon sold out.",
        8: "Coupon expired.",
        9: "Event not found, please make sure the link you provided is valid.",
        10: "Invalid link to event, seller code doesn't exist.",
        11: "Please select quantity.",
        12: "Invalid event",
        14: "Coupon has not started yet.",
        15: "Sorry, this coupon is not applicable to selected tickets.",
        16: "Sorry, this coupon is not applicable to registration tickets.",
        17: "Coupon linked to other User.",
        18: "Code not exists.",
    }

    participants = {
        1: "This participant is an invited participant, please approve the main participant in this order.",
        2: "The transaction was declined by credit company.",
        3: "An error has occurred while updating participant, please contact Getin support.",
        4: "Refund failed, please contact Getin support.",
        5: "The participants have already been approved.",
        6: "An error has occurred while adding cash to the provided salesman.",
        7: "Refund failed, the participant was moved to the \"failed refund\" tab.",
        8: "Can not send participant for approval",
        8.1: "The requested action can not be executed, the client is pending for approval",
        8.2: "The requested action can not be executed, the client is already approved",
        8.3: "The requested action can not be executed, the client is already declined",
        8.4: "The requested action can not be executed, the client is already credited (his order has been canceled)",
        8.5: "The requested action can not be executed, the client did not pay yet - the transaction failed",
        8.6: "The requested action can not be executed, the client is in failed refund",
        8.7: "The requested action can not be executed, the client is hidden",
        8.8: "The requested action can not be executed, the client left the transaction before its completed",
        8.9: "The requested action can not be executed, this is an external client",
        '8.10': "The requested action can not be executed, the client has already been sent for approval",
        8.11: "The requested action can not be executed, the client has already been sent for approval",
        8.13: "There are no events to display for this action.",
        9: "The value exceeds the maximum input limit (50)",
        10: "There are no salesmen in event",
        11: "An error occurred while updating salesman",
        12: "Unauthorized action",
        13: "The requested action can not be executed, the event is not active",
        14: "You cannot cancel this type of ticket, please contact Getin support.",
        15: "You cannot cancel the ticket because there is no payment gateway, please contact Getin support.",
        16: "For refund please contact support.",
        17: "Refund request is on pending state.",
        18: "You cannot return this order. It is not paid.",
        19: "Problem from our side. Please try again later.",
        20: "Error with cancellation. please contact Getin support. Code: 20",
        21: "Problem with cancellation. Please contact Getin support. Code: 21",
        22: "Problem with cancellation. Please contact Getin support. Code: 22",
        23: "Refund request is on pending state.",
        24: "Refund request is on failed state.",
        25: "Problem with cancellation. Please contact Getin support. Code: 25",
        26: "Problem with cancellation. Please contact Getin support. Code: 26",
        27: "Decline request is on pending state.",
        28: "Problem with cancellation. Please try again later",
        29: "Problem with cancellation. Please contact Getin support. Code: 29",
        30: "Problem with cancellation. Please contact Getin support. Code: 30",
        31: 'No access for this action.',
        32: "dispute field is empty",
        33: "dispute can be only for credit payment",
        34: "Error detected while saving. Please contact Support",
        35: "payment_id is not valid",
        36: "payment data is empty",
        37: "dispute with this id isset. please send another id",
        38: "can not find main purchaser",
        39: "Error detected while saving. Please contact Support",
        40: 'You don not have access for this action.',
        41: "Problem with cancellation. Please contact Getin support. Code: 41",

    }
    dashboardReports = {
        1: "No data found for report.",
        2: "Invalid query, please check log.",
        3: "An error has occurred in request.",
        4: "Report not found.",
    }
    reader = {
        1: "Please provide a valid phone number and reader name.",
        2: "Failed to log in, invalid credentials.",
        3: "Failed to log in, reader is inactive.",
        4: "Invalid barcode.",
        5.1: "Participant is pending for approval.",
        5.3: "Participant was declined.",
        5.4: "Participant was cancelled.",
        5.5: "Participant hasn't payed yet, credit was declined.",
        5.6: "Participant was cancelled.",
        5.7: "Participant was declined.",
        5.8: "Participant hasn't payed yet.",
        6: "Participant has already been scanned.",
        7: "This participants entry has already been cancelled.",
        8: "Participant hasn't been scanned yet.",
        9: "The scanned participant does not belong to this reader.",
        10: "Waiting for login with wallet from current attendee.",
        11: "You are trying to scan a NFT ticket, please click on the button for scanning.",
        '180-1': "Please try again.",
        '180-2': "User has no wallets connected.",
        '180-3': "User doesn't have wallet from which NFT was claimed.",
        '180-4': "User is offline. Please ask to login with wallet.",
        12: "This event use another scanner provider.",
    }
    event_stats = {
        1: "The desired event doesn't exist.",
        2: "Insufficient data to provide statistic graphs.",
        3: "An error has occurred while retrieving data, please try again.",
    }
    templates = {
        1: "An error occurred while saving data.",
        2: "An error occurred, Email was not sent.",
        3: "An error occurred while sending data",
        4: "Could not proceed , template size exceeds the limit of 10MB.",
    }
    gateways = {
        1: 'This gateway cannot be removed since it has associated events.',
        2: 'Stripe Payout Gateway ID is not equal with Producer stripe connect account gateway_id',
        3: 'Stripe Payout TYPE is not equal with Producer stripe connect account type',
        4.1: 'An error has occurred while pulling API data for payment gateways, please try again',
    }
    tranzilla = {
        "000": "עיסקה תקינה",
        "001": "כרטיס חסום",
        "002": "גנוב,החרם כרטיס",
        "003": "לא ניתן לבצע חיוב אוטומטי. אנא התקשר לחברת האשראי לביצוע העסקה באופן ידני.",
        "004": "העסקה לא אושרה, כרטיס האשראי סורב",
        "005": "כרטיס מזויף - החרם",
        "006": "העסקה נדחתה - ת.ז. או CVV שגוי",
        "007": "העסקה נדחתה -  cavv/ucaf שגוי",
        "008": "העסקה נדחתה - avs שגוי",
        "009": "העסקה נדחתה - מסוף לא הצליח לתקשר, אנא התקשר לחברת האשראי",
        "010": "אישור חלקי",
        "011": "העסקה נדחתה - חוסר בנקודות/כוכבים/מיילים/הטבה אחרת",
        "012": "העסקה נדחתה - הכרטיס אינו מורשה במסוף",
        "013": "העסקה נדחתה - קוד יתרה שגוי",
        "014": "העסקה נדחתה - כרטיס לא משוייך לרשת",
        "015": "העסקה נדחתה - הכרטיס אינו בתוקף",
        "016": "העסקה נדחתה - אין הרשאה לסוג מטבע",
        "017": "העסקה נדחתה - אין הרשאה לסוג אשראי בעסקה",
        "026": "העסקה נדחתה - ת.ז. שגויה, למידע נוסף אנא פנה לחברת האשראי",
        "041": "ישנה חובת יציאה לשאילתא בגין תקרה בלבד לעסקה עם פרמטר J2",
        "042": "ישנה חובת יציאה לשאילתא לא רק בגין תקרה, לעסקה עם פרמטר J2",
        "051": "חסר קובץ ווקטור 1",
        "052": "חסר קובץ ווקטור 4",
        "053": "חסר קובץ ווקטור 6",
        "055": "חסר קובץ ווקטור 11",
        "056": "חסר קובץ ווקטור 12",
        "057": "חסר קובץ ווקטור 15",
        "058": "חסר קובץ ווקטור 18",
        "059": "חסר קובץ ווקטור 31",
        "060": "חסר קובץ ווקטור 34",
        "061": "חסר קובץ ווקטור 41",
        "062": "חסר קובץ ווקטור 44",
        "063": "חסר קובץ ווקטור 64",
        "064": "חסר קובץ ווקטור 80",
        "065": "חסר קובץ ווקטור 81",
        "066": "חסר קובץ ווקטור 82",
        "067": "חסר קובץ ווקטור 83",
        "068": "חסר קובץ ווקטור 90",
        "069": "חסר קובץ ווקטור 91",
        "070": "חסר קובץ ווקטור 92",
        "071": "חסר קובץ ווקטור 93",
        "073": "חסר קובץ  PARAM_3_1",
        "074": "חסר קובץ  PARAM_3_2",
        "075": "חסר קובץ  PARAM_3_3",
        "076": "חסר קובץ  PARAM_3_4",
        "077": "חסר קובץ  PARAM_361",
        "078": "חסר קובץ  PARAM_363",
        "079": "חסר קובץ  PARAM_364",
        "080": "חסר קובץ  PARAM_61",
        "081": "חסר קובץ  PARAM_62",
        "082": "חסר קובץ  PARAM_63",
        "083": "חסר קובץ  CEIL_41",
        "084": "חסר קובץ  CEIL_42",
        "085": "חסר קובץ  CEIL_43",
        "086": "חסר קובץ  CEIL_44",
        "087": "חסר קובץ DATA",
        "088": "חסר קובץ JENR",
        "089": "חבר קובץ Start",
        "101": "חסרה כניסה בוקטור 1",
        "103": "חסרה כניסה בוקטור 4",
        "104": "חסרה כניסה בוקטור 6",
        "106": "חסרה כניסה בוקטור 11",
        "107": "חסרה כניסה בוקטור 12",
        "108": "חסרה כניסה בוקטור 15",
        "110": "חסרה כניסה בוקטור 18",
        "111": "חסרה כניסה בוקטור 31",
        "112": "חסרה כניסה בוקטור 34",
        "113": "חסרה כניסה בוקטור 41",
        "114": "חסרה כניסה בוקטור 44",
        "116": "חסרה כניסה בוקטור 64",
        "117": "חסרה כניסה בוקטור 81",
        "118": "חסרה כניסה בוקטור 82",
        "119": "חסרה כניסה בוקטור 83",
        "120": "מספר כרטיס לא תקין / ספרת ביקורת לא תקינה / כרטיס לא מוכר בישראל",
        "121": "חסרה כניסה בוקטור 91",
        "122": "חסרה כניסה בוקטור 92",
        "123": "חסרה כניסה בוקטור 93",
        "141": "חסרה כניסה מתאימה בקובץ פרמטרים 3.2",
        "142": "חסרה כניסה מתאימה בקובץ פרמטרים 3.3",
        "143": "חסרה כניסה בקובץ תחומי מועדון 3.6.1",
        "144": "חסרה כניסה בקובץ תחומי מועדון 3.6.3",
        "145": "חסרה כניסה בקובץ תחומי מועדון 3.6.4",
        "146": "חסרה כניסה בקובץ תקרות לכרטיסי PL  4.1",
        "147": "חסרה כניסה בקובץ תקרות לכרטיסים ישראלים שאינם PL שיטה 0 4.2",
        "148": "חסרה כניסה בקובץ תקרות לכרטיסים ישראלים שאינם PL שיטה 1 4.3",
        "149": "חסרה כניסה בקובץ תקרות לכרטיסי תייר 4.4",
        "150": "חסרה כניסה בקובץ כרטיסים תקפים - ישראכרט",
        "151": "חסרה כניסה בקובץ כרטיסים תקפים - כאל",
        "152": "חסרה כניסה בקובץ כרטיסים תקפים - מנפיק עתידי",
        "182": "שגיאה בערכי וקטור 4",
        "183": "שגיאה בערכי וקטור 6/12",
        "186": "שגיאה בערכי וקטור 18",
        "187": "שגיאה בערכי וקטור 34",
        "188": "שגיאה בערכי וקטור 64",
        "190": "שגיאה בערכי וקטור 90",
        "191": "נתונים לא תקינים בוקטור הרשאות מנפיק",
        "192": "נתונים לא ולידיים בסט הפרמטרים",
        "193": "נתונים לא ולידים בקובץ פרמטרים ברמת מסוף",
        "300": "אין הרשאה לסוג עסקה - הרשאת סולק",
        "301": "אין הרשאה לביצוע עסקאות במט\"ח",
        "303": "למסוף אין הרשאה לבצע עסקה טלפונית",
        "304": "לא ניתן לבצע עסקה בתשלומים, אנא פנה לחברת האשראי למידע נוסף.",
        "308": "אין הרשאה להצמדה - הרשאת סולק",
        "309": "אין הרשאת סולק לאשראי במועד קבוע",
        "310": "אין הרשאה להקלדת מספר אישור מראש",
        "311": "אין הרשאה לבצע עסקאות לקוד שירות 587/085 (יש לפנות לויזה כ.א.ל למידע נוסף)",
        "312": "אין הרשאת סולק לאשראי דחוי",
        "313": "אין הרשאת סולק להטבות",
        "314": "אין הרשאת סולק למבצעים",
        "315": "אין הרשאת סולק לקוד מבצע ספציפי",
        "316": "אין הרשאת סולק לעסקת טעינה",
        "317": "אין הרשאת סולק לטעינה/פריקה בקוד אמצעי התשלום בשילוב קוד מטבע",
        "318": "אין הרשאה לביצוע עסקאות במט\"ח",
        "319": "אין הרשאת סולק לטיפ",
        "341": "העסקה נדחתה עקב הרשאת מנפיק",
        "342": "אין הרשאה לביצוע עסקאות במט\"ח עקב הרשאת מנפיק (ייתכן שמדובר בכרטיס תייר), יש לפנות לחברת האשראי למידע נוסף",
        "343": "אין הרשאת מנפיק לביצוע עסקה כאשר הכרטיס לא נוכח",
        "344": "העסקה נדחתה - כרטיס לא רשאי לבצע עסקה עם סוג אשראי זה, אנא פנה לחברת האשראי לפרטים נוספים",
        "348": "אין הרשאה לביצוע אישור בקשה יזומה ע\"י  קמעונאי",
        "349": "אין הרשאה מתאימה לביצוע בקשה לאישור ללא עסקה J5",
        "350": "אין הרשאת מנפיק להטבות",
        "351": "אין הרשאת מנפיק לאשראי דחוי",
        "352": "אין הרשאת מנפיק לעסקת טעינה",
        "353": "אין הרשאת מנפיק לטעינה/פריקה בקוד אמצעי התשלום",
        "354": "אין הרשאת מנפיק לביצוע עסקאות במט\"ח (ייתכן שמדובר בכרטיס תייר), יש לפנות לחברת האשראי למידע נוסף",
        "381": "אין הרשאה לבצע עסקת contactless  מעל סכום מרבי",
        "382": "במסוף המוגדר כשרות עצמי ניתן לבצע רק עסקאות בשירות עצמי",
        "384": "מסוף מוגדר כרב-ספק /מוטב - חסר מספר ספק/מוטב",
        "385": "במסוף המוגדר כמסוף סחר אלקטרוני, חובה להעביר eci",
        "401": "מספר התשלומים גדול מערך שדה מספר תשלומים מקסימלי",
        "402": "מספר התשלומים קטן מערך שדה מספר תשלומים מינימלי",
        "403": "סכום העסקה קטן מערך שדה סכום מינימלי לתשלום",
        "404": "לא הוזן שדה מספר תשלומים",
        "405": "חסר נתון סכום תשלום ראשון /קבוע",
        "406": "סך סכום העסקה שונה מסכום תשלום ראשון + סכום תשלום קבוע * מספר תשלומים",
        "408": "הפס המגנטי של כרטיס האשראי  אינו תקין",
        "410": "דחיה מסיבת  dcode",
        "414": "בעסקה עם חיוב בתאריך קבוע הוכנס תאריך מאוחר משנה מביצוע העיסקה",
        "415": "הוזנו נתונים לא תקינים",
        "416": "תאריך תוקף לא במבנה תקין",
        "417": "מספר מסוף אינו תקין",
        "418": "חסרים פרמטרים חיוניים",
        "419": "שגיאה בהעברת מאפיין clientInputPan",
        "420": "מספר כרטיס לא ולידי -במצב של הזנת ערוץ 2 בעסקה ללא כרטיס נוכח",
        "421": "שגיאה כללית - נתונים לא ולידיים",
        "422": "שגיאה בבניית מסר  ISO",
        "424": "שדה לא נומרי",
        "425": "רשומה כפולה",
        "426": "הסכום הוגדל לאחר ביצוע בדיקות אשראית",
        "428": "חסר קוד שירות בכרטיס",
        "429": "כרטיס אינו תקף לפי קובץ כרטיסים תקפים",
        "431": "שגיאה כללית",
        "432": "אין הרשאה להעברת כרטיס דרך קורא מגנטי",
        "433": "חיוב להעביר ב - PinPad",
        "434": "אסור להעביר כרטיס במכשיר ה- PinPad",
        "435": "המכשיר לא מוגדר להעברת כרטיס מגנטי CTL",
        "436": "המכשיר לא מוגדר להעברת כרטיס EMV CTL",
        "439": "אין הרשאה לסוג אשראי לפי סוג עסקה",
        "440": "כרטיס תייר אינו מורשה לסוג אשראי זה",
        "441": "אין הרשאה לביצוע סוג עסקה - כרטיס קיים בוקטור 80",
        "442": "אין לבצע Stand-in לאימות אישור לסולק זה",
        "443": "לא ניתן לבצע עסקת ביטול  - כרטיס לא נמצא בקובץ תנועות הקיים במסוף",
        "445": "בכרטיס חיוב מיידי ניתן לבצע אשראי חיוב מיידי בלבד",
        "447": "מספר כרטיס שגוי",
        "448": "חיוב להקליד כתובת לקוח (מיקוד ,מספר בית ועיר)",
        "449": "חיוב להקליד מיקוד",
        "450": "קוד מבצע מחוץ לתחום, צ\"ל בתחום 1-12",
        "451": "שגיאה במהלך בניית רשומת עסקה",
        "452": "בעסקת טעינה/פריקה/בירור יתרה חיוב להזין שדה קוד אמצעי תשלום",
        "453": "אין אפשרות לבטל עסקת פריקה 7.9.3",
        "455": "לא ניתן לבצע עסקת חיוב מאולצת כאשר נדרשת בקשה לאישור (למעט תקרות)",
        "456": "כרטיס נמצא בקובץ תנועות עם קוד תשובה 'החרם כרטיס'",
        "457": "בכרטיס חיוב מיידי מותרת עסקת חיוב רגילה/זיכוי/ביטול",
        "458": "קוד מועדון לא בתחום",
        "470": "בעסקת הו\"ק סכום התשלומים גבוה משדה סכום העסקה",
        "471": "בעסקת הו\"ק מספר תשלום תורן גדול מסה\"כ מספר התשלומים",
        "472": "בעסקת חיוב עם מזומן חיוב להזין סכום במזומן",
        "473": "בעסקת חיוב עם מזומן סכום המזומן צריך להיות קטן מסכום העסקה",
        "474": "עסקת אתחול בהוראת קבע מחייבת פרמטר J5",
        "475": "עסקת הו\"ק מחייבת אחד מהשדות :מספר תשלומים או סכום כולל",
        "476": "עסקת תורן בהוראת קבע מחייבת שדה מספר תשלום",
        "477": "עסקת תורן בהוראת קבע מחייבת מספר מזהה של עסקת אתחול",
        "478": "עסקת תורן בהוראת קבע מחייבת מספר אישור של עסקת אתחול",
        "479": "עסקת תורן בהוראת קבע מחייבת שדות תאריך וזמן עסקת אתחול",
        "480": "חסר שדה מאשר עסקת מקור",
        "481": "חסר שדה מספר יחידות כאשר העסקה מתבצעת בקוד אמצעי תשלום השונה ממטבע",
        "482": "בכרטיס נטען מותרת עסקת חיוב רגילה/זיכוי/ביטול/פריקה/טעינה/בירור יתרה",
        "483": "עסקה עם כרטיס דלק במסוף דלק חיוב להזין מספר רכב",
        "484": "מספר רכב המוקלד שונה ממספר הרכב הצרוב ע\"ג הפס המגנטי/מספר בנק שונה מ-012/ספרות שמאליות של מספר הסניף שונה מ- 44",
        "485": "מספר רכב קצר  מ- 6 ספרות /שונה ממספר הרכב המופיע ע\"ג ערוץ 2 (פוזיציה 34 בערוץ 2) כרטיס מאפיין דלק של לאומי קארד",
        "486": "ישנה חובת הקלדת קריאת מונה (פוזיציה 30 בערוץ 2)  כרטיס מאפיין דלק של לאומי קארד",
        "487": "רק במסוף המוגדר כדלק דו שלבי ניתן להשתמש בעדכון אובליגו",
        "489": "בכרטיס דלקן מותרת עסקת חיוב רגילה בלבד (עסקת ביטול אסורה)",
        "490": "בכרטיסי דלק/דלקן/דלק מועדון ניתן לבצע עסקאות רק במסופי דלק",
        "491": "עסקה הכוללת המרה חייבת להכיל את כל השדות conversion_amount_06, conversion_rate_09, conversion_currency_51",
        "492": "אין המרה על עסקאות שקל /דולר",
        "493": "בעסקה הכוללת הטבה חיוב שיהיו רק אחד מהשדות הבאים: סכום הנחה/מספר יחידות/ % ההנחה",
        "494": "מספר מסוף שונה",
        "495": "אין הרשאת  fallback",
        "496": "לא ניתן להצמיד אשראי השונה מאשראי קרדיט/תשלומים",
        "497": "לא ניתן להצמיד לדולר/מדד במטבע השונה משקל",
        "498": "כרטיס ישראכרט מקומי הספרטור צ\"ל בפוזיציה 18",
        "500": "העסקה הופסקה ע\"י המשתמש",
        "504": "חוסר התאמה בין שדה מקור נתוני הכרטיס לשדה מספר כרטיס",
        "505": "ערך לא חוקי בשדה סוג עסקה",
        "506": "ערך לא חוקי בשדה eci",
        "507": "סכום העסקה בפועל גבוה מהסכום המאושר",
        "509": "שגיאה במהלך כתיבה לקובץ תנועות",
        "512": "לא ניתן להכניס אישור שהתקבל ממענה קולי לעסקה זו",
        "551": "מסר תשובה אינו מתאים למסר הבקשה",
        "552": "שגיאה בשדה 55",
        "553": "התקבלה שגיאה מהטנדם",
        "554": "במסר התשובה חסר שדה  mcc_18",
        "555": "במסר התשובה חסר שדה  response_code_25",
        "556": "במסר התשובה חסר שדה rrn_37",
        "557": "במסר התשובה חסר שדה  comp_retailer_num_42",
        "558": "במסר התשובה חסר שדה  auth_code_43",
        "559": "במסר התשובה חסר שדה f39_response_39",
        "560": "במסר התשובה חסר שדה authorization_no_38",
        "561": "במסר התשובה חסר / ריק שדה  additional_data_48.solek_auth_no",
        "562": "במסר התשובה חסר אחד מהשדות conversion_amount_06, conversion_rate_09, conversion_currency_51 ",
        "563": "ערך השדה אינו מתאים למספרי האישור שהתקבלו auth_code_43",
        "564": "במסר התשובה חסר/ריק שדה additional_amounts54.cashback_amount",
        "565": "אי-התאמה בין שדה 25 לשדה 43",
        "566": "במסוף המוגדר כתומך בדלק דו-שלבי יש חובה להחזיר שדות 90,119",
        "567": "שדות 25,127 לא תקינים במסר עידכון אובליגו במסוף המוגדר כדלק דו-שלבי",
        "997": "שגיאה כללית",
        "998": "ERROR_IN_NEG_FILE",
        "700": "עסקה נדחתה ע\"י מכשיר  PinPad",
        "701": "שגיאה במכשיר  pinpad",
        "702": "יציאת com  לא תקינה",
        "703": "עסקה נכשלה",
        "704": "עסקה בוטלה",
        "705": "עסקה הופסקה ע\"י המשתמש",
        "706": "זמן המתנה ארוך מדי",
        "707": "משתמש הוציא כרטיס לפני סיום ביצוע העסקה",
        "708": "PINPAD_UserRetriesExceeded",
        "709": "PINPAD_PINPadTimeout",
        "710": "תקלת תקשורת עם מכשיר",
        "711": "PINPAD_PINPadMessageError",
        "712": "PINPAD_PINPadNotInitialzedi",
        "713": "PINPAD_PINPadCardReadError",
        "714": "PINPAD_ReaderTimeout",
        "715": "PINPAD_ReaderCommsError",
        "716": "PINPAD_ReaderMessageError",
        "717": "PINPAD_HostMessageError",
        "718": "PINPAD_HostConfigError",
        "719": "PINPAD_HostKeyError",
        "720": "PINPAD_HostConnectError",
        "721": "PINPAD_HostTransmitError",
        "722": "PINPAD_HostReceiveError",
        "723": "PINPAD_HostTimeout",
        "724": "PINVerificationNotSupportedByCard",
        "725": "PINVerificationFailed",
        "726": "שגיאה בקליטת קובץ config.xml",
        "730": "מכשיר אישר עסקה בניגוד להחלטת אשראי",
        "731": "כרטיס לא הוכנס"
    };
    stripeErrors = {
        "incomplete_number": 'פרטי האשראי שהוזנו אינם מלאים',
        "invalid_number": 'מספר כרטיס האשראי אינו תקין',
        "incomplete_expiry": 'פרטי תוקף כרטיס האשראי שהוזנו אינם מלאים',
        "incomplete_cvc": 'מספר הCVC שהוזן אינו מלא',
        "invalid_expiry_year_past": 'תוקף כרטיס האשראי שהוזן פג'
    };
    eventCategories = {
        'SaveCategory': 'Category was not saved',
        'UpdateCategory': 'Category was not updated',
        'DeleteCategory': 'Category was not deleted',
        'CategoryNotFound': 'Category was not found',
        'CategoriesNotValid': 'This category does not exist',
        'InvalidEvent': "Invalid event",
    };

    producerCoverPhotoErrors = {
        'producerCoverPhotoSave': 'Cover photo was not saved',
        'producerCoverPhotoSaveToDB': 'Cover photo was not updated',
        'producerCoverPhotoDeleteFromDB': 'Cover photo was not deleted',
    };
    coupons = {
      'uniqueness': "Entered non-unique code",
    };
    cryptoCurrency = {
        'cryptoTransaction': 'Transaction not yet completed.',
        'walletTransaction': 'Transaction was not mined within 50 blocks. Be aware that it might still be mined!',
        'walletTransactionSave': "Something went wrong, please save the transaction hash and contact Getin support.",
        "walletTransactionHash": "Something went wrong with transaction. Please contact Getin support.",
        "walletChainNotFound": "The selected network is not supported. Please change wallet network.",
        "walletCoinNotSupport": "The selected cryptocurrency of your wallet is not supported.",
        "urlHashIndalid": "Hash is not valid.",
         "walletConnect": "User denied message signature.",
        "walletConnectUnique": "The wallet is already used.",
        "walletConnectEmail":  "The email is already used.",
        "walletConnectPhone": "The phone number is already used.",
        "walletConnectError": "The user data is not correct.",
        "walletConnectReg": "The wallet not registered.",
        "walletConnectPhoneInvalid": "Invalid phone number format.",
        "walletConnectModalClosed": "Wallet connection closed by user.",
        "walletConnectReject": "Connection rejected by user.",
        "walletConnectAuthorization": "User denied account authorization.",
        "walletConnectFormat": "The email format is invalid.",
        "userRejected": "User rejected.",
        "userDenied": "User denied message signature.",
    };

    tokenErr = {
      4.1: 'Session token is not valid',
      4.3 : 'You are logged in on another device',
      18.4: 'Problem with authorization token',
      18.5: 'Problem with session token',
    }


  sendProducerEmail = {
    1: 'Captcha is not valid',
    2: 'Problem with identifying captcha',
    3: 'You cannot send messages to current Producer',
    4: 'Problem with sending to Producer',
  }

  seats = {
    1: 'Current seat is not available for this coupon link.',
    2: 'You can not choose more tickets of this type.',
    'ticketsSoldOut': 'Sorry, selected tickets were sold out.',
  }

  backEndError = {
    1: 'No more tickets available.',
  }

  phoneBlackList = {
    1: 'Data is not available to Add or Update.',
    2: 'Phone number is not valid.',
    3: 'Phone number already added.',
    4: 'You cannot update this record because it does not exist.'
  }

  phoneCountryWhiteList = {
    1: 'Data is not available to Add or Update.',
    2: 'Country already added.',
    3: 'You cannot update this record because it does not exist.',
  }

  emailBlackList = {
    1: 'Email is not valid. It must be more than 2 symbols.',
    2: 'Data is not available to Add or Update.',
    3: 'Email is not valid.',
    4: 'You cannot update this record because it does not exist.',
  }
  transferTickets = {
    1: 'No access for this action.',
    2: 'No access for this action.',
    3: 'Token is not valid.',
    4: 'Purchase is not active.',
    4.1: "An error has occurred, please try again.",
    5: 'You already sent a request.',
    6: 'Email is not valid.',
    7: 'You cannot transfer tickets to the same account.',
    8: 'Phone is not valid.',
    9: 'Critical error. Please contact support.',
    10: 'You cannot send a request.',
    11: 'You have already sent a transfer request.',
    12: 'You cannot transfer this ticket. Not valid ticket type.',
    13: 'Failed to send SMS and email.',
  }

  feeTemplates = {
    2: "User can not be added. This user already assigned to other template.",
    4.1: "An error has occurred, please try again.",
  }

  waitingList = {
    1: 'No access for this action.',
    2: 'Critical Error. Please contact Support',
    4: 'Purchase is not active.',
    5: 'It is not your purchase.',
    4.1: "An error has occurred, please try again.",
  }

  waitingListApprove = {
    1: 'Count is not valid.',
    2: 'ticket_id is not valid.',
    4: 'You entered more or users already bought some tickets.',
    5: 'Previous request has not yet been completed. You cannot specify more.',
    6: 'Error. Please contact Support',
    7: 'Increase tickets quantity.',
    4.1: "An error has occurred, please try again.",
  }

  cmsAutoApproveProducers = {
    1: 'Invalid entry.',
    2: 'User already added.',
  }

  cmsUserSuspended = {
    1: 'Invalid data.',
    2: 'User already added.',
    3: 'User can not be found.',
    4: 'The record is missing or deleted.',
    4.1: "An error has occurred, please try again.",
  }

  passkeys = {
    1: 'User is not valid.',
    2: 'Token is not valid.',
    3: 'Token is not expired.',
    4: 'This method is not available. Please reconnect on the Account Settings page.',
    4.1: "An error has occurred, please try again.",
    4.2: "Browser is not supported.",
    5: "Public key is empty.",
    6: "User ID is not valid",
    7: "No Accounts found. If you haven't created a passkey yet you may add it in Account Settings.",
  }

  finance = {
    1: 'Selected country is not supported by Stripe. Please contact support.',
    2: 'Please choose country.',
  }

  stripe_payout = {
    1: 'Hash is not valid. Please contact support.',
    2: 'We can not receive data from Stripe. Please contact support.',
    3: 'Account has been activated. But Error occurs. Please contact support.',
    4: 'More information is required please click the button to continue the onboarding.',
    4.1: 'An error has occurred, please try again.',
    5: 'Stripe Account can not be activated.'
  }

    constructor() { }

    getAlertStr(errorGroup, errorCode) {
        if (!errorCode) {
            return this.errors[4.1];
        }
        if (!this[errorGroup] || (this[errorGroup] && !this[errorGroup][errorCode])) {
            return '';
        }

        return (this[errorGroup][errorCode]) ? this[errorGroup][errorCode] : this.errors[4.1];
    }
}
