import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalsService } from '../../../globals';

@Component({
  selector: 'app-info-button-dialog',
  templateUrl: './info-button-dialog.component.html',
  styleUrls: ['./info-button-dialog.component.css']
})
export class InfoButtonDialogComponent {

  lang: string;
  info = {
      "info_title": {
          he: "אפשר להכניס את שם האירוע, הליין, המיקום או כל פרט אחר",
          en: "Enter the event name, line, location or any other detail",
          es: "Ingrese el nombre del evento, la línea, la ubicación o cualquier otro detalle"
      },
      "info_address": {
          he: "הזן ובחר כתובת מהרשימה",
          en: "Type and choose the address from list suggestions",
          es: "Escriba y elija la dirección de la lista de sugerencias"
      },
      "info_date": {
          he: "יש להזין תאריך ושעת פתיחת/סיום האירוע",
          en: "Please set a start and end date for the event",
          es: "Establezca una fecha de inicio y finalización para el evento"
      },
      "info_location_desc": {
          he: "במידה ואת/ה מעוניין לציין את שם המקום של האירוע",
          en: "Optional - state the name of the event's location",
          es: "Opcional: indique el nombre de la ubicación del evento"
      },
      "info_description": {
          he: "תיאור האירוע",
          en: "Event description",
          es: "Descripción del evento"
      },
      "info_friendly_url": {
          he: "כתובת URL ידידותית, לדוגמא: נניח שבחרתי בכתובת הידידותית club57, כתובת האירוע תהיה https://get-in.com/club57",
          en: "Friendly URL, for example if you set 'clud57' as the friendly URL, the event URL will be as followed: https://get-in.com/club57",
          es: "URL amigable, por ejemplo, si configura 'clud57' como la URL amigable, la URL del evento será la siguiente: https://get-in.com/club57"
      },
      "info_min_age": {
          he: "גיל מינימלי עבור כניסה לאירוע",
          en: "Minimum age to enter the event",
          es: "Edad mínima para ingresar al evento"
      },
      "info_purchase_type": {
          he: "הגדרה זו משפיעה על האופן בו יוצג טופס הרכישה בדף הנחיתה. 'טופס אינדיוידואלי' משמעו מילוי טופס פרטים אישיים עבור כל רוכש בהזמנה. טופס ׳רוכש לקבוצה׳ משמעו מילוי טופס פרטים אישיים יחיד עבור מי שמבצע את ההזמנה, ללא קשר לכמות הרוכשים בהזמנה.",
          en: "This setting affects the way the payment form will be displayed in the event's landingpage. 'Individual form' will open a separate form for each of the order's participants. 'group form' will open one collective form for all of the order's participants",
          es: "Esta configuración afecta la forma en que se mostrará el formulario de pago en la página de inicio del evento. 'Formulario individual' abrirá un formulario separado para cada uno de los participantes del pedido. El 'formulario de grupo' abrirá un formulario colectivo para todos los participantes del pedido. "
        },
      "info_currency": {
        he: "על פי הגדרה זו יוגדר המטבע לסליקת העסקאות באירוע",
        en: "This setting will define the currency in which the event transactions will be made",
        es: "Esta configuración definirá la moneda en la que se realizarán las transacciones del evento"
      },
      "info_counter_title": {
          he: "הכותרת שתופיע מעל שעון העצר",
          en: "The title that will appear above the stop watch",
          es: "El título que aparecerá encima del cronómetro"
      },
      "info_facebook_validation": {
          he: "'אישור משתתפים באופן פרטני' משמעו שכל משתמש שיקנה כרטיס לאירוע יצטרך אישור של מפיק על מנת לקבל את הכרטיס. ללא אישור המפיק, המשתמש לא יקבל כרטיס ולא יחוייב. שים/י לב ש'חיבור פייסבוק' אינו מחייב באופן אוטומטי 'אישור פרטני' ושהמשתמש יהיה מחוייב להיות מחובר לפייסבוק",
          en: "'purchase validation' will add participants to the 'pending approval' list in the participants page. Their order will not be valid and the participant will not be charged until the producer (or other authorized personel) approves the transaction manually. Checking the 'Facebook login' box will require the participant to login with Facebook in order to purchase tickets to the event.",
          es: "'validación de compra' agregará participantes a la lista de 'aprobación pendiente' en la página de participantes. Su pedido no será válido y no se le cobrará al participante hasta que el productor (u otro personal autorizado) apruebe la transacción manualmente. la casilla 'Iniciar sesión en Facebook' requerirá que el participante inicie sesión con Facebook para poder comprar entradas para el evento."
      },
      "info_privacy": {
          he: "פרטיות האירוע יכולה להיות ציבורית או פרטית. אירוע ציבורי הוא אירוע שיופיע בלוח האירועים הכללי של חברת גטאין ופתוח למכירת כרטיסים לקהל הרחב. אירוע פרטי הוא אירוע שאינו מופיע בלוח האירועים של חברת גטאין, ומכירת כרטיסים אליו היא בלעדית דרך לינק המפיק.",
          en: "Selecting 'public' will display the event in Getin's feed. if you with to keep this event private and exclusive, select 'private'.",
          es: "Al seleccionar 'público' se mostrará el evento en el feed de Getin. Si desea mantener este evento privado y exclusivo, seleccione 'privado'."
      },
      "info_limits": {
          he: "במידה ויש הגבלה על כמות הכרטיסים לכל רוכש, יש לציין את המספר",
          en: "If you wish to limit the amount of tickets purchased in one order, state the desired limit (max number of tickets for one order)",
          es: "Si desea limitar la cantidad de boletos comprados en un pedido, indique el límite deseado (número máximo de boletos para un pedido)"
      },
      "info_comment": {
          he: "הוספת הערה כללית",
          en: "General comment to be displayed when selecting tickets to purchase",
          es: "Comentario general que se mostrará al seleccionar entradas para comprar"
      },
      "info_comment_category": {
        he: "General comment to be displayed for category",
        en: "General comment to be displayed for category",
        es: "Comentario general que se mostrará para la categoría"
      },
      "info_category_tickets": {
        he: "Choose tickets for this category",
        en: "Choose tickets for this category",
        es: "Elija entradas para esta categoría"
      },
      "info_marketing_contact": {
          he: "טלפון שדרכו הלקוחות יכולים לפנות ליוצר האירוע",
          en: "A phone number of the event producer / support, will be displayed in event landingpage.",
          es: "Se mostrará un número de teléfono del productor / soporte del evento en la página de inicio del evento."
      },
      "info_contact": {
          he: "מייל ליצירת קשר - אימייל שדרכו הלקוחות יכולים לפנות ליוצר האירוע. במקום שימוש במייל, ניתן להזין לינק לפייסבוק של יוצר האירוע.",
          en: "An email of the event producer / support (a link to the event's Facebook page could be set instead), will be displayed in event landingpage.",
          es: "Se mostrará un correo electrónico del productor / soporte del evento (en su lugar, se podría establecer un enlace a la página de Facebook del evento) en la página de inicio del evento."
      },
      "google-analitics":  {
        he: "בשדה זה יש להזין את ה- Traking ID שהתקבל בעת הגדרת חשבון האנליטיקס שלך",
        en: "Enter the Traking ID you received when setting up your Analytics account",
        es: "Introduzca el ID de seguimiento que recibió al configurar su cuenta de Analytics",
        code: `<!-- Global site tag (gtag.js) - Google Analytics --> \n
        <script async src="https://www.googletagmanager.com/gtag/js?id=YOUR_TRAKING_ID"></script> \n
        <script>\n
          window.dataLayer = window.dataLayer || [];\n
          function gtag(){dataLayer.push(arguments);}\n
          gtag('js', new Date());\n

          gtag('config', 'YOUR_TRAKING_ID');\n
        </script>`
      },
      "facebook-pixel":  {
        he: "בשדה זה יש להזין את ה- pixel ID שהתקבל בעת הגדרת חשבון האנליטיקס שלך",
        en: "Enter the pixel ID you received when setting up your pixel on Facebook",
        es: "Ingrese el ID de píxel que recibió al configurar su píxel en Facebook",
        code: `<script>\n
        !function(f,b,e,v,n,t,s)\n
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\n
        n.queue=[];t=b.createElement(e);t.async=!0;\n
        t.src=v;s=b.getElementsByTagName(e)[0];\n
        s.parentNode.insertBefore(t,s)}(window, document,'script',\n
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', 'YOUR_PIXEL_ID');\n
        fbq('track', 'PageView');\n
        </script>\n
        <noscript><img height="1" width="1" style="display:none"\n
          src="https://www.facebook.com/tr?id=YOUR_PIXEL_ID&ev=PageView&noscript=1"\n
        /></noscript>\n
        <!-- End Facebook Pixel Code -->`
      },
      "facebook-pixel-thanks": {
        he: "יש לבחור את הפעולה שאחריה מעוניינים לעקוב",
        en: "Select the event you would like to track",
        es: "Seleccione el evento del que desea realizar un seguimiento"
      },
      "google-conversion-traking": {
        he: "בשדה זה יש להזין את ה- conversion ID שהתקבל בעת הגדרת חשבון האנליטיקס שלך",
        en: "Enter the conversion ID you received when setting up your Analytics account",
        es: "Ingrese el ID de conversión que recibió al configurar su cuenta de Analytics",
        code: `<script type="text/javascript">\n
        /* <![CDATA[ */\n
        var google_conversion_id = YOUR_CONVERSION_ID;\n
        var google_conversion_label = "xxx-XXx1xXXX123X1xX";\n
        var google_remarketing_only = "false"\n
        var google_conversion_value = 10.0;\n
        var google_conversion_currency = "USD"\n
        /* ]]> */\n
        </script>\n
        <script type="text/javascript" src="//www.googleadservices.com/pagead/conversion.js"></script>\n
        <noscript>\n
            <img height=1 width=1 border=0 src="//www.googleadservices.com/pagead/conversion/YOUR_CONVERSION_ID/?value=10.0&amp;currency_code=USD&amp;label=xxx-XXx1xXXX123X1xX&amp;guid=ON&amp;script=0">\n
        </noscript>`
      }
    }

  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public content, private globals: GlobalsService) {
    this.lang = this.globals.getLocaleId();
  }
  }
