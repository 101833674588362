<div mat-dialog-title>
    <button type="button" class="close" [matDialogClose]>
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<mat-dialog-content>
<!--  <app-terms></app-terms>-->
  <ng-template #editEventTerms></ng-template><!--   New Terms-->
</mat-dialog-content>

<mat-dialog-actions>
  <div class="col-md-12 col-xs-12">
    <div class="col-md-12 col-xs-12 mb10">
      <input class="check-terms" type="checkbox" name="isConfirmTerms" [attr.data-cy]="'termsCheckbox'" (click)="toggleIsConfirmTerms()" #isConfirmTerms><span class="read-terms" i18n="@@TersmDialogIHaveRead">I have read and agree to the terms of use</span>
<!--      <br>-->
<!--      <input class="check-terms" type="checkbox" [(ngModel)]="auto_approve_in_the_future"><span class="read-terms"> Auto approve future events</span>-->
    </div>
    <div class="col-md-6 col-xs-6 mutate-float-right p0">
      <button i18n="@@TersmDialogSubmit" class="btn btn-success" [attr.data-cy]="'termsSubmitButton'" (click)="closeDialog()" [disabled]=!isConfirmTerms.checked>Submit event for approval</button>
    </div>
    <div class="col-md-6 col-xs-6 mutate-float-left p0" [ngClass]="{'text-direction-rtl': dir === 'rtl', 'text-direction-ltr': dir === 'ltr'}">
      <button i18n="@@Close" class="btn btn-danger" [matDialogClose]>Close</button>
    </div>
  </div>

</mat-dialog-actions>
