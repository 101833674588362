import { GlobalsService } from '../globals';
import { Component, OnInit } from '@angular/core';
import { NavbarService } from '../services/navbar.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  local_id: string;

  constructor(
    private globals: GlobalsService,
    private navbarServise: NavbarService
  ) { }

  ngOnInit() {
    this.setLocalId();
    this.navbarServise.setIsNewUi(true);
  }
  setLocalId() {
    this.local_id = this.globals.getLocaleId();
  }
}
