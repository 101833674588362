import { Subscription } from 'rxjs';
import { GlobalsService } from './../../globals';
import { EventTicketsService } from './../event-tikets-service/event-tickets.service';
import { TicketImgModalComponent } from './../ticket-img-modal/ticket-img-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { CurrencyLocaleService } from './../../services/currency-locale.service';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, AfterViewChecked, AfterViewInit } from '@angular/core';
import { getCurrencySymbol } from '@angular/common';

@Component({
  selector: 'app-user-tickets',
  templateUrl: './user-tickets.component.html',
  styleUrls: ['./user-tickets.component.scss']
})
export class UserTicketsComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() categories;
  @Input() currency: string;
  @Input() choosedItems: any = {};
  @Input() tickets;
  @Input() tickets_reg;
  @Input() stepTemplate: string;
  @Input() quantity;
  @Input() item_id: number | string = 0;
  @Input() tables;
  @Input() tables_reg;
  @Input() rooms_reg;
  @Input() rooms;
  @Input() total: { 'total_price': number, 'total_items': number };
  @Input() purchase_item: number;
  @Input() coverPreviewDefault;
  @Input() platform_fee: string = '0';
  @Input() commission: number = 0;
  @Input() purchaser_commission: string = '0';
  @Input() tax_commission: string = '0';
  @Input() service_commission: string = '0';

  @Input() ticketLimits: any = {};
  @Input() showCategory: any = {};
  @Input() ticketsFiltered: any = {};
  @Input() withoutCategory: any = [];

  @Input() hasRegularTicket: number = 0;
  @Input() hasRegularOnlyTicket: number = 0;
  @Input() hasWaitedFreeTicket: number = 0;
  @Input() hasDonateTicket: number = 0;
  @Input() hasWaitedTicket: number = 0;
  @Input() hasFreeTicket: number = 0;
  @Input() donateTickets: any = [];
  @Input() show_price_with_fees: number = 0;
  @Input() waiting_list_mode: boolean = false;
  @Input() totalTicketsInCategories: number = 0;
  @Input() totalTicketsWithoutCategories: number = 0;


  coverPreview = ''; // 'assets/images/nft-ticket.gif'; // test only
  choosedItemsCheckIfSoldOut: any = {};
  item_is_reg: boolean;
  isShowDefaultPrice: boolean = false; // show price with or without fees
  ticket_min_price;
  room_min_price;
  table_min_price;
  dir = 'ltr';
  title = '';
  ticketsData: any;
  subscriptions: Subscription[] = [];

  // @Output() totalChange: EventEmitter< { 'total_price': number, 'total_items': number }> = new EventEmitter();
  // @Output() setItemIsReg: EventEmitter<boolean> = new EventEmitter();
  @Output() purchase_itemChange: EventEmitter<number> = new EventEmitter();
  @Output() setEditTicket: EventEmitter<any> = new EventEmitter();

  showFeesHint: boolean = false;

  constructor(
    private currencyLocale: CurrencyLocaleService, private globals: GlobalsService,
    private dialog: MatDialog, private eventTicketsService: EventTicketsService
    ) {
      // console.log('Start app-user-tickets', this.tickets, this.ticketLimits, this.choosedItems);
      this.dir = this.globals.getDir();
      this.eventTicketsService.getEditTicket.subscribe(data => {
        this.editTicket(data.item, data.sign, data.category, data.purchase_item);
      });
    }

  ngOnInit(): void {
    // this.platform_fee = 1; // for test
    // this.commission = 10; // for test
    this.setDefaultPriceFlag();
    // console.log('coverPreviewDefault', this.coverPreviewDefault, this.tickets, this.ticketLimits, this.choosedItems);
    const dataTickets = this.eventTicketsService.getDataTickets();
    if (dataTickets && dataTickets.ticketsData) {
      // console.log('set all data: ', dataTickets, dataTickets.ticketsData);
      this.ticketsData = dataTickets.ticketsData;
      this.total = dataTickets.ticketsData.total;
      this.item_is_reg = dataTickets.ticketsData.item_is_reg;
      this.quantity = dataTickets.ticketsData.quantity;
      this.item_id = dataTickets.ticketsData.item_id;
      this.purchase_item = dataTickets.ticketsData.purchase_item;
      this.choosedItemsCheckIfSoldOut = dataTickets.ticketsData.choosedItemsCheckIfSoldOut;
      this.ticketsFiltered = dataTickets.ticketsData.ticketsFiltered;
      this.showCategory = dataTickets.ticketsData.showCategory;
      this.withoutCategory = dataTickets.ticketsData.withoutCategory;

      this.choosedItems = dataTickets.choosedItems;
      this.ticketLimits = dataTickets.ticketLimits;
      this.categories = dataTickets.categories;
      this.tickets = dataTickets.tickets;

      // console.log('showCategory',this.showCategory);
      this.getMinMaxPrices();
      this.categoryStepChoise();
    } else {
      this.getMinMaxPrices();
      this.categoryStepChoise();
    }
  }

  toggleFeesHint() {
    this.showFeesHint = !this.showFeesHint;
  }
  setDefaultPriceFlag() {
    // show_price_with_fees - depends from Approval event modal param
    if (this.show_price_with_fees == 1) {
      this.isShowDefaultPrice = !(Number(this.purchaser_commission) || Number(this.service_commission) || Number(this.platform_fee));
    } else {
      this.isShowDefaultPrice = true;
      return
    }
  }
  calcTicketPriceWithFee(price: number) {

    const purchaser_commission = Number(this.purchaser_commission) > 0 ? Number(this.purchaser_commission) / 100 : 0; // Purchase fee %
    const tax_commission = Number(this.tax_commission) > 0 ? Number(this.tax_commission) / 100 : 0 // Tax %
    const service_commission = Number(this.service_commission) > 0 ? Number(this.service_commission) / 100 : 0; // Service fee %
    const platform_fee = Number(this.platform_fee); // Platform fee S

    const total_commission_in_percentage =  purchaser_commission + service_commission;
    // Calculate service_commission and purchaser_commission
    const total_price_w_purchaser_commission = price * (purchaser_commission + 1);
    const total_price_w_service_commission = price * (service_commission + 1)
    const total_purchaser_commission = total_price_w_purchaser_commission - price;
    const total_service_commission =  total_price_w_service_commission - price;

    const total_fees_without_tax = total_purchaser_commission + total_service_commission +  platform_fee;
    const total_tax_commission = +(total_fees_without_tax * tax_commission).toFixed(3);

    const tax_and_fees =  price * total_commission_in_percentage + total_tax_commission + platform_fee;

    // Total commission
    let total_commission = (Math.round(tax_and_fees * 100) / 100).toFixed( 2); // like commission_ils
    // console.log(
    //   'purchaser_commission', purchaser_commission,
    //   'tax_commission', tax_commission,
    //   'service_commission', service_commission,
    //   'platform_fee', platform_fee, 'total_commission', total_commission,
    // 'commission_ils', +commission_ils);

    // Price with commission
    return price>0 ? (price + (+total_commission)) : 0

  }

  ngAfterViewInit () {
  //   setTimeout(() => {
  //   this.categories.forEach((category, index) => {
  //     this.showCategory[index] = true;
  //   });
  // }, 0);
  }

  categoryShow(index) {
    // variant with available tickets in viewport
    // let totalTickets = this.totalTicketsInCategories + this.totalTicketsWithoutCategories;
    // if ( totalTickets > 4) {
    //   let obj = this.showCategory;
    //   for (const prop in obj) {
    //     if (prop == index) {
    //       this.showCategory[prop] = !this.showCategory[prop]; // toggle
    //     } else {
    //       this.showCategory[prop] = false; // close all
    //     }
    //   }
    // }
    let obj = this.showCategory;
    for (const prop in obj) {
      if (prop == index) {
        this.showCategory[prop] = !this.showCategory[prop]; // toggle
      } else {
        this.showCategory[prop] = false; // close all
      }
    }


    // this.showCategory[index] = !obj[index]; // toggle
  }

  getLocale(currency) {
    return this.currencyLocale.getLocaleForCurrencyPipe(currency)
  }

  showTicketImgModal(url) {
    this.dialog.open(TicketImgModalComponent, {
      data: {url},
      autoFocus: true,
      maxWidth: '90vw',
      maxHeight: '90vh',
      backdropClass: 'full-width-ModalBackdrop', // 'loginModalBackdrop',
      panelClass: 'ticket-img-modal'
    });
  }

  async editTicket(item, sign, category = null, purchase_item, isLastBtn = false) {
      // console.log('editTicket', item, this.ticketLimits, this.tickets);

    if(!item.donation_type && this.hasDonateTicket || item.donation_type && this.hasRegularTicket) {
      return
    }

    const qty = 1;
    let quantity;
    if (sign === 'plus') {
      quantity = qty + this.ticketLimits[item.id]['choosed'][category ? category : 'noCat'].amount;
    } else {
      if (this.ticketLimits[item.id]['choosed'][category ? category : 'noCat'].amount > 1) {
        quantity = -qty + this.ticketLimits[item.id]['choosed'][category ? category : 'noCat'].amount;
      } else {
        quantity = 0;
      }
    }


    if (
      (item.donation_type ? quantity <= 1 : quantity <= item.limits.length)
      ||
      (this.waiting_list_mode && (item.waiting_list && !item.limits.length && quantity <= item.ticket_limit_for_purchase)) ) {
      // Recalculate ticket limits)
      this.ticketLimits[item.id]['choosed'][category ? category : 'noCat'] = {};
      this.ticketLimits[item.id]['choosed'][category ? category : 'noCat'].amount = +quantity;
      let availableToChoose = this.ticketLimits[item.id]['available'];
      Object.keys(this.ticketLimits[item.id]['choosed']).forEach(categoryName => {
        const currentAmount = this.ticketLimits[item.id]['choosed'][categoryName].amount;
        this.ticketLimits[item.id]['choosed'][categoryName].options = [];
        availableToChoose -= currentAmount;
      });
      this.ticketLimits[item.id]['availableToChoose'] = availableToChoose;
      Object.keys(this.ticketLimits[item.id]['choosed']).forEach(categoryName => {
        const currentAmount = this.ticketLimits[item.id]['choosed'][categoryName].amount;
        for (let i = 1; i <= availableToChoose + currentAmount; i++) {
          this.ticketLimits[item.id]['choosed'][categoryName].options.push(i)
        }
      });

      if (+quantity < 1) {
        // console.log('delete items', quantity);
        delete this.choosedItems[category ? category : 'noCat'][item.id];
        // delete this.choosedItems[category ? category : 'noCat'];

      } else {

        const ticket = {};
        ticket['amount'] = +quantity;
        ticket['price'] = +item.price;
        ticket['name'] = item.name;
        // console.log('choosedItems', quantity, category, item.price, item.name, item.id, ticket, item, this.choosedItems, this.choosedItems[category ? category : 'noCat']);
        this.choosedItems[category ? category : 'noCat'][item.id] = ticket;
        this.choosedItemsCheckIfSoldOut[item.id] = ticket; // check if tickets was sold out
      }

      // Category (1 for items), (2 for rooms), (3 for tables)
      this.purchase_item = purchase_item;

      // Register item
      let foundNotReg = false;

      Object.keys(this.choosedItems).forEach(cat => {
        Object.keys(this.choosedItems[cat]).forEach(id => {
          if (id !== 'name') {
            if (this.tickets.filter(ticket => ticket.id === +id)[0].reg === 0) {
              foundNotReg = true;
            }
          }
        })
      });

      this.item_is_reg = !foundNotReg;
      // this.setItemIsReg.emit(this.item_is_reg);

      this.calcRegular();

      // Price calculation when choosing ticket and price
      this.total = await this.calcTotalItemsAndPrice();
      // this.totalChange.emit(this.total);



      // Item quantity
      this.quantity = this.total.total_items;
      // console.log('this.quantity', this.quantity);

      this.item_id = item.id;

      this.ticketsData = {
        total: this.total, item_is_reg: this.item_is_reg, quantity: this.quantity, item_id: this.item_id,
        purchase_item: this.purchase_item, choosedItemsCheckIfSoldOut: this.choosedItemsCheckIfSoldOut,
        choosedItems: this.choosedItems, ticketLimits: this.ticketLimits, ticketsFiltered: this.ticketsFiltered,
        showCategory: this.showCategory, withoutCategory: this.withoutCategory,
        donateTickets: this.donateTickets,
        hasRegularTicket: this.hasRegularTicket,
        hasDonateTicket: this.hasDonateTicket,
        hasWaitedTicket: this.hasWaitedTicket,
        sign: sign,
        item: item,
        isLastBtn: isLastBtn // if last '+' qty button clicked in the column of tickets
      };
      // console.log('setEditTicket', this.ticketsData);
      this.setEditTicket.emit(this.ticketsData); // inside call calcTotalPrice instead of eventTicketsService
      // this.eventTicketsService.setTicketLimits(this.ticketLimits);
      // this.eventTicketsService.calcTotalPrice(false);
      // this.recalcPaticipents();

    }
    // console.log('choosedItems', this.ticketLimits, this.choosedItems);
  }

  async categoryStepChoise() {
    // console.log('categoryStepChoise');
    // this.title, this.purchase_item = await this.eventTicketsService.categoryStepChoise(this.tickets, this.tickets_reg, this.tables, this.tables_reg, this.rooms, this.rooms_reg);
    // Check what step should set up
    if ((this.tickets || this.tickets_reg) && (this.tables && this.tables.length === 0) && (this.tables_reg && this.tables_reg.length === 0) && (this.rooms && this.rooms.length === 0) && (this.rooms_reg && this.rooms_reg.length === 0)) {
      // this.step = 'items_section';
      this.title = 'ticket';
      this.purchase_item = 1;
      // this.noCategory = true;
    } else if ((this.tables || this.tables_reg) && (this.tickets && this.tickets.length === 0) && (this.tickets_reg && this.tickets_reg.length === 0) && (this.rooms && this.rooms.length === 0) && (this.rooms_reg && this.rooms_reg.length === 0)) {
      // this.step = 'items_section';
      this.title = 'table';
      this.purchase_item = 3;
      // this.noCategory = true;
    } else if ((this.rooms || this.rooms_reg) && (this.tickets && this.tickets.length === 0) && (this.tickets_reg && this.tickets_reg.length === 0) && (this.tables && this.tables.length === 0) && (this.tables_reg && this.tables_reg.length === 0)) {
      // this.step = 'items_section';
      this.title = 'room';
      this.purchase_item = 2;
      // this.noCategory = true;
    } else {
      // this.step = 'category_step';
    }
    // console.log('StepChoise: ', this.title, this.purchase_item);
  }

    /**
 * @function Creating total price and quantity object
 */
    calcTotalItemsAndPrice() {
      // console.log('calcTotalItemsAndPrice');
      let total_price = 0;
      let total_items = 0;

      Object.keys(this.choosedItems).forEach(cat => {
        Object.keys(this.choosedItems[cat]).forEach(ticket => {
          if (ticket !== 'name') {
            total_price += this.choosedItems[cat][ticket].price * this.choosedItems[cat][ticket].amount;
            total_items += this.choosedItems[cat][ticket].amount;
          }
        })
      });

      return { 'total_price': total_price, 'total_items': total_items };
    }

    async getMinMaxPrices() {
      // console.log('getMinMaxPrices');
      // this.ticket_min_price, this.room_min_price, this.table_min_price = await this.eventTicketsService.getMinMaxPrices(this.tickets, this.rooms, this.tables);
      if (this.tickets) {
        const prices = [];

        for (const t of this.tickets) {
          prices.push(t.price);
          if (t.pending_date == 1) {
            t.hide = 'disabled_ticket';
          } else {
            t.hide = '';
          }
        }
        this.ticket_min_price = Math.min.apply(Math, prices);
      }

      if (this.rooms) {
        const prices = [];

        for (const r of this.rooms) {
          prices.push(r.price);
        }
        this.room_min_price = Math.min.apply(Math, prices);
      }

      if (this.tables) {
        const prices = [];

        for (const t of this.tables) {
          prices.push(t.price);
        }
        this.table_min_price = Math.min.apply(Math, prices);
      }
      // console.log('MinMaxPrices: ', this.ticket_min_price, this.room_min_price, this.table_min_price);
    }
  // Prevent entered 0 zero values
  sendTheNewValue(event, cat = null, i){
    let value = +event.target.value;
    event.target.value = value === 0 ? '' : value;
    if(cat) { this.ticketsFiltered[cat][i].price = value; } else { this.withoutCategory[i].price = value; }
  }

  donateChange(e, t, cat = null) {
    let value = +e.target.value;
    let index = this.donateTickets.findIndex(i=> {
      return i.item_id == t.id
    })

    if(index === -1) {
      this.donateTickets.push({item_id: t.id, sum: value});
      this.editTicket(t,'plus',cat,1);
    } else {
      this.editTicket(t,'minus',cat,1);
      this.donateTickets[index]['item_id'] = t.id;
      this.donateTickets[index]['sum'] = value;
      this.editTicket(t,'plus',cat,1);
    }

    if((value < t.donation_min || value > t.donation_max) && (index || index===0)) {
      this.editTicket(t,'minus',cat,1);
      this.donateTickets.splice(index, 1);
    }
    console.log(this.donateTickets, index)
    // console.log('this.hasDonateTicket', this.hasDonateTicket)
  }

  calcRegular() {
    let regQty = 0;
    let freeQty = 0;
    let donQty = 0;
    let waitQty = 0;
    let waitFreeQty = 0;
    let regOnlyQty = 0;
    Object.keys(this.choosedItems).forEach(cat => {
      Object.keys(this.choosedItems[cat]).forEach(id => {
        if (id !== 'name') {
          let findTicket = this.tickets.filter(ticket => ticket.id === +id)
          if (findTicket[0].reg === 0 && findTicket[0].donation_type == 0) {
            regQty = regQty + this.choosedItems[cat][id].amount
          }
          if (findTicket[0].reg === 1 && findTicket[0].donation_type == 0 && findTicket[0].waiting_list == 0) {
            freeQty = freeQty + this.choosedItems[cat][id].amount
          }
          if (findTicket[0].reg === 1 && findTicket[0].donation_type == 0 && findTicket[0].waiting_list == 1) {
            waitFreeQty = waitFreeQty + this.choosedItems[cat][id].amount
          }
          if (findTicket[0].reg === 0 && findTicket[0].donation_type == 1) {
            donQty = donQty + this.choosedItems[cat][id].amount
          }
          if (findTicket[0].reg === 0 && findTicket[0].waiting_list == 1 && findTicket[0].availability !== 1 ) {
            waitQty = waitQty + this.choosedItems[cat][id].amount
          }
          if (findTicket[0].reg === 0 && findTicket[0].donation_type == 0 && findTicket[0].availability == 1) {
            regOnlyQty = regOnlyQty + this.choosedItems[cat][id].amount
          }
        }
      })
    });
    this.hasRegularTicket = regQty + freeQty; // calc regular and free tickets here
    this.hasDonateTicket = donQty;
    this.hasWaitedTicket = waitQty;
    this.hasFreeTicket = freeQty;
    this.hasRegularOnlyTicket = regOnlyQty;
    this.hasWaitedFreeTicket = waitFreeQty;
    console.log('regQty', regQty,'regOnlyQty',regOnlyQty, 'donQty', donQty, 'waitQty', waitQty, 'freeQty', freeQty, 'waitFreeQty', waitFreeQty)
  }

  clearDonate(t, cat = null, i) {
    this.editTicket(t,'minus',cat,1);
    if(cat) { this.ticketsFiltered[cat][i].price = ''; } else { this.withoutCategory[i].price = ''; }
  }
  getCurrencySymbolF(code) {
    return getCurrencySymbol(code, 'narrow')
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(value => value.unsubscribe());
    const data = {ticketsData: this.ticketsData, ticketLimits: this.ticketLimits, choosedItems: this.choosedItems, categories: this.categories, tickets: this.tickets};
    // console.log('ngOnDestroy UserTicketsComponent', data);
    this.eventTicketsService.setDataTickets(data);
  }

}
