import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'salesman_search'
})


export class SalesmanPipe implements PipeTransform {

    search_params = [
			'phone',
			'email',
			'seller_name',
			'sub_seller'
    ];
    
    transform(value, args) {
		if(!args) {
			return value;
		} else if(value) {
			// make the filter case insensitive:
			args = (typeof args == 'string' || args instanceof String) ? args.toLowerCase() : String(args);
			return value.filter(item => {
				for (let key in item) {
					// to limit search to these specific object keys.
					if(this.search_params.find(x => x == key)) {
						// also search in sub_object:
						if(key == 'sub_seller') {
							for(let i=0; i<item[key].length; i++) { // all sub users
								for(let sub_user_key in item[key][i]) { // search for value in sub_users object:
									if(this.search_params.find(x => x == sub_user_key)) {
                                        if(((typeof item[key][i][sub_user_key] == 'string' || item[key][i][sub_user_key] instanceof String) && (item[key][i][sub_user_key].toLowerCase().indexOf(args) !== -1)) || (typeof item[key][i][sub_user_key] == 'number' && (String(item[key][i][sub_user_key]).indexOf(args) !== -1))) {
                                            return true;
                                        }
									}
								}
							}
						// for main object - concatinate first and last name to allow search value in both:
						} else {
							if(((typeof item[key] == 'string' || item[key] instanceof String) && (item[key].toLowerCase().indexOf(args) !== -1)) || (typeof item[key] == 'number' && (String(item[key]).indexOf(args) !== -1))) {
								return true;
							}
						}
					}
				}
			});
		}
	}
}