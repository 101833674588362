const options = {
  allowedTags: [
    'a',
    'p',
    'span',
    'div',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'ul',
    'ol',
    'li',
    'b',
    'i',
    'u',
    'em',
    's',
    'strong',
    'strike',
    'sub',
    'sup',
    'hr',
    'br',
    'blockquote',
    'caption',
    'code',
    'pre',
    'col',
    'colgroup',
    'table',
    'tbody',
    'td',
    'tfoot',
    'th',
    'thead',
    'tr',
    'img',
    'iframe'
  ],
  allowedAttributes: {
    a: ['href', 'name', 'target', 'rel'],
    span: ['style'],
    strong: ['style'],
    em: ['style'],
    u: ['style'],
    s: ['style'],
    p: ['class'],
    li: ['class'],
    pre: ['class'],
    img: ['src', 'class'],
    iframe: ['src', 'class', 'frameborder', "allowfullscreen"]
  },
  allowedStyles: {
    '*': {
      'background-color': [
        /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
      ],
      color: [
        /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
      ],
    },
  },
  allowedClasses: {
    '*': [
      'ql-align-right',
      'ql-align-center',
      'ql-align-justify',
      'ql-code-block',
      'ql-code-block-container',
      'ql-syntax',
      'ql-direction-rtl',
      'ql-font-serif',
      'ql-font-monospace',
      'ql-formula',
      'ql-indent-1',
      'ql-indent-2',
      'ql-indent-3',
      'ql-indent-4',
      'ql-indent-5',
      'ql-indent-6',
      'ql-indent-7',
      'ql-indent-8',
      'ql-size-small',
      'ql-size-large',
      'ql-size-huge',
      'img-responsive',
      'ql-video'
    ],
  },
  allowedSchemesByTag: {
    img: [ 'data' ]
  },
  allowedIframeHostnames: ['www.youtube.com', 'player.vimeo.com']
}
export default options;
