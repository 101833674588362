<section class="barcode_template">

<!-- 1. main purchaser (single purchaser) - check if main_purchaser == 1 && sub_purchasers.length == 0 which means the purchaser object is in use.
	 2. main purchaser w/ subs - check if main_purchaser == 1 && sub_purchasers.length > 0
	 3. sub purchaser - check if main_purchaser == 0 && sub_purchasers.length == 0 which means the purchader object is in use.
-->
	<div *ngIf="data && data.purchaser">
		<div class="col-md-12 col-xs-12 pb20 ticket">

			<div class="getin_label col-md-12 col-xs-12">
				<div class="col-md-12 col-xs-12">
					<h2 class="text-center">GET IN</h2>
				</div>
			</div>

			<div class="col-md-12 col-xs-12 bottom_line pb10">
        <p *ngIf="data.main_purchaser == 1" class="ticket_counter text-center font-large"><span><strong i18n="@@TicketTemplateAdmissionTicket">Admission ticket</strong></span>&nbsp;<strong>{{data.purchaser.counter}}/{{data.total_tickets}}</strong></p>
				<p class="instructions text-center font-small" t key="ticketTemplates.MustBeDisplayedTxt" default="(Must be displayed at venue&apos;s entrance along with a valid identification document)"></p>
        <p class="instructions text-center font-small"><span i18n="@@TicketTemplateTransactionNumber">Transaction number:</span> {{data.purchaser.pu_id}}</p>
			</div>

			<div class="col-md-12 col-xs-12 barcode bottom_line pt10 pb10">
				<div class="col-md-12 col-xs-12">
					<p class="text-center font-large"><strong>{{data.event_title}}</strong></p>
					<p class="text-center font-medium">{{data.start_date}}</p>
					<!-- <p class="text-center font-medium">{{data.start_date | dateTimeFormatFilter: 'dddd L'}}</p> -->
					<p class="text-center font-medium">{{data.location}}</p>
				</div>
				<div class="col-md-12 col-xs-12 mt10 qrcode-align" [ngClass]="{'use_animation': data.purchaser.use_animation}"
             *ngIf="data.checkDisplayTheTicket; else elseNoQr">
					<qrcode [qrdata]="data.purchaser.hash" [width]="190" [errorCorrectionLevel]="'M'"></qrcode>
          <img *ngIf="data.purchaser.use_animation" src="/assets/images/qr-eye.gif" alt="eye" class="qr-eye">
				</div>
        <ng-template #elseNoQr>
          <div class="col-md-12 col-xs-12">
            <p class="future-ticket">
              <img src="/assets/images/svg-icons/qr_test.svg" alt="no_qr">
              <span t key="MyTicketsModal.NoQRWillBeActiveInDayOfEvent"
                    default="The QR code for this event will automatically become active about {hours} hours before the event"
                    [params]="{hours: data.checkDisplayTheTicketBeforeHours}"></span>
            </p>
          </div>
        </ng-template>
			</div>

			<div class="col-md-12 col-xs-12 purchaser_details bottom_line pt10 pb10 mb10">
				<p class="font-large"><strong>{{data.purchaser.user_name}}</strong></p>
				<p *ngIf="data.purchaser.identification_number" class="font-medium"><span i18n="@@IDNumber">ID number</span> {{data.purchaser.identification_number}}</p>

				<p class="font-large pt10">
					<span><strong i18n="@@TicketTemplatetType">Type</strong> </span>
					<span><strong i18n="@@TicketTemplatetypespecification">{
						data.item_type, select,
            ticket {Ticket} table {Table} room {Room}
					}</strong></span>
				</p>
				<p class="font-medium">{{data.purchaser.item_name}}</p>
				<p class="font-medium">{{data.purchaser?.category_name}}</p>
				<p *ngIf="data.ticket_type == 1 || data.ticket_type == 3" class="font-large pt10"><strong i18n="@@Price">Price</strong></p>
				<p *ngIf="data.ticket_type == 1 || data.ticket_type == 3" class="font-medium">{{data.purchaser.price | currency: data.currency:data.currency === 'MXN' || data.currency === 'CAD' ? 'symbol': 'symbol-narrow':'1.':getLocale(data.currency)}}</p>
				<p *ngIf="data.ticket_type == 2" class="font-large pt10"><strong i18n="@@TicketTemplateFreeadmission">Free admission</strong></p>
				<p *ngIf="data.ticket_type == 4" class="font-large pt10"><strong i18n="@@TicketTemplateRegistration">Registration</strong></p>
			</div>


      <table style="width:100%;border: 1px solid #000; text-align: center; max-width: 620px; margin: 0 auto;" *ngIf="data.purchaser?.hasOwnProperty('seat_info')">
        <tr>
          <th style="border: 1px solid #000;text-align: center;" i18n="seat|seat" *ngIf="data.purchaser.seat_info.hasOwnProperty('seat') && data.purchaser.seat_info.seat && data.purchaser.seat_info.seat.length">seat</th>
          <th style="border: 1px solid #000;text-align: center;" i18n="seat|chair" *ngIf="data.purchaser.seat_info.hasOwnProperty('chair') && data.purchaser.seat_info.chair && data.purchaser.seat_info.chair.length">chair</th>
          <th style="border: 1px solid #000;text-align: center;" i18n="seat|table" *ngIf="data.purchaser.seat_info.hasOwnProperty('table') && data.purchaser.seat_info.table && data.purchaser.seat_info.table.length">table</th>
          <th style="border: 1px solid #000;text-align: center;" i18n="seat|row" *ngIf="data.purchaser.seat_info.hasOwnProperty('row') && data.purchaser.seat_info.row && data.purchaser.seat_info.row.length">row</th>
          <th style="border: 1px solid #000;text-align: center;" i18n="seat|section" *ngIf="data.purchaser.seat_info.hasOwnProperty('section') && data.purchaser.seat_info.section && data.purchaser.seat_info.section.length">section</th>
          <th style="border: 1px solid #000;text-align: center;" i18n="seat|Entrance" *ngIf="data.purchaser.seat_info.hasOwnProperty('entrance') && data.purchaser.seat_info.entrance && data.purchaser.seat_info.entrance.length">Entrance</th>
        </tr>
        <tr>
          <td style="border: 1px solid #000;" *ngIf="data.purchaser.seat_info.hasOwnProperty('seat') && data.purchaser.seat_info.seat && data.purchaser.seat_info.seat.length">
            <p style="font-size: 1.1em;text-align: center;">{{data.purchaser.seat_info.seat}}</p>
          </td>
          <td style="border: 1px solid #000;" *ngIf="data.purchaser.seat_info.hasOwnProperty('chair') && data.purchaser.seat_info.chair && data.purchaser.seat_info.chair.length">
            <p style="font-size: 1.1em;text-align: center;">{{data.purchaser.seat_info.chair}}</p>
          </td>
          <td style="border: 1px solid #000;" *ngIf="data.purchaser.seat_info.hasOwnProperty('table') && data.purchaser.seat_info.table && data.purchaser.seat_info.table.length">
            <p style="font-size: 1.1em;text-align: center;">{{data.purchaser.seat_info.table}}</p>
          </td>
          <td style="border: 1px solid #000;" *ngIf="data.purchaser.seat_info.hasOwnProperty('row') && data.purchaser.seat_info.row && data.purchaser.seat_info.row.length">
            <p style="font-size: 1.1em;text-align: center;">{{data.purchaser.seat_info.row}}</p>
          </td>
          <td style="border: 1px solid #000;" *ngIf="data.purchaser.seat_info.hasOwnProperty('section') && data.purchaser.seat_info.section && data.purchaser.seat_info.section.length">
            <p style="font-size: 1.1em;text-align: center;">{{data.purchaser.seat_info.section}}</p>
          </td>
          <td style="border: 1px solid #000;" *ngIf="data.purchaser.seat_info.hasOwnProperty('entrance') && data.purchaser.seat_info.entrance && data.purchaser.seat_info.entrance.length">
            <p style="font-size: 1.1em;text-align: center;">{{data.purchaser.seat_info.entrance}}</p>
          </td>
        </tr>
      </table>

      <p class="copyright" style="font-size: 10px;margin-top: 10px;" *ngIf="data.purchaser?.hasOwnProperty('seat_info') && (data.hasOwnProperty('seat_terms')) && data.seat_terms.length">
        {{data.seat_terms}}
      </p>



		</div>
	</div>

	<div *ngIf="data && data.main_purchaser == 1 && data.sub_purchasers">
		<div *ngFor="let item of data.sub_purchasers" class="col-md-12 col-xs-12 pb20 ticket" >
      <div class="pagebreak"></div>
			<div class="getin_label col-md-12 col-xs-12">
				<div class="col-md-12 col-xs-12">
					<h2 class="text-center">GET IN</h2>
				</div>
			</div>

			<div class="col-md-12 col-xs-12 bottom_line pb10">
        <p class="ticket_counter text-center font-large"><span><strong i18n="@@TicketTemplateAdmissionTicket">Admission ticket</strong></span>&nbsp;<strong>{{item.counter}}/{{data.total_tickets}}</strong></p>
				<p class="instructions text-center font-small" t key="ticketTemplates.MustBeDisplayedTxt" default="(Must be displayed at venue&apos;s entrance along with a valid identification document)"></p>
        <p class="instructions text-center font-small"><span i18n="@@TicketTemplateTransactionNumber">Transaction number:</span> {{item.pu_id}}</p>
			</div>

			<div class="col-md-12 col-xs-12 barcode bottom_line pt10 pb10">
				<div class="col-md-12 col-xs-12">
					<p class="text-center font-large"><strong>{{data.event_title}}</strong></p>
					<p class="text-center font-medium">{{data.start_date}}</p>
					<p class="text-center font-medium">{{data.location}}</p>
				</div>
				<div class="col-md-12 col-xs-12 mt10" [ngClass]="{'use_animation': item.use_animation}"
             *ngIf="data.checkDisplayTheTicket; else elseNoQrSubUser">
					<qrcode [qrdata]="item.hash" [width]="190" [errorCorrectionLevel]="'M'"></qrcode>
          <img *ngIf="item.use_animation" src="/assets/images/qr-eye.gif" alt="eye" class="qr-eye">
				</div>
        <ng-template #elseNoQrSubUser>
          <div class="col-md-12 col-xs-12">
            <p class="future-ticket">
              <img src="/assets/images/svg-icons/qr_test.svg" alt="no_qr">
              <span t key="MyTicketsModal.NoQRWillBeActiveInDayOfEvent"
                    default="The QR code for this event will automatically become active about {hours} hours before the event"
                    [params]="{hours: data.checkDisplayTheTicketBeforeHours}"></span>
            </p>
          </div>
        </ng-template>
			</div>

			<div class="col-md-12 col-xs-12 purchaser_details bottom_line pt10 pb10 mb10">
				<p class="font-large"><strong>{{item.user_name}}</strong></p>
				<p *ngIf="item.identification_number" class="font-medium"><span i18n="@@IDNumber">ID number</span> {{item.identification_number}}</p>

        <p class="font-large pt10">
          <span><strong i18n="@@TicketTemplatetType">Type</strong> </span>
          <span><strong i18n="@@TicketTemplatetypespecification">{
            data.item_type, select,
            ticket {Ticket} table {Table} room {Room}
            }</strong></span>
        </p>
				<p class="font-medium">{{item.item_name}}</p>
				<p class="font-medium">{{item?.category_name}}</p>
				<p *ngIf="data.ticket_type == 1 || data.ticket_type == 3" class="font-large pt10"><strong i18n="@@Price">Price</strong></p>
				<p *ngIf="data.ticket_type == 1 || data.ticket_type == 3" class="font-medium">{{item.price | currency: data.currency:data.currency === 'MXN' || data.currency === 'CAD' ? 'symbol': 'symbol-narrow':'1.':getLocale(data.currency)}}</p>

				<p *ngIf="data.ticket_type == 2" class="font-large pt10"><strong i18n="@@TicketTemplateFreeadmission">Free admission</strong></p>
				<p *ngIf="data.ticket_type == 4" class="font-large pt10"><strong i18n="@@TicketTemplateRegistration">Registration</strong></p>
			</div>

      <table class="sub-table"  style="width:100%;border: 1px solid #000; text-align: center; max-width: 620px; margin: 0 auto;" *ngIf="item.hasOwnProperty('seat_info') && item.seat_info && (item.seat_info.seat || item.seat_info.chair)">
        <tr>
          <th style="border: 1px solid #000;text-align: center;" i18n="seat|seat" *ngIf="item.seat_info.hasOwnProperty('seat') && item.seat_info.seat && item.seat_info.seat.length">seat</th>
          <th style="border: 1px solid #000;text-align: center;" i18n="seat|chair" *ngIf="item.seat_info.hasOwnProperty('chair') && item.seat_info.chair && data.seat_info.chair.length">chair</th>
          <th style="border: 1px solid #000;text-align: center;" i18n="seat|table" *ngIf="item.seat_info.hasOwnProperty('table') && item.seat_info.table && item.seat_info.table.length">table</th>
          <th style="border: 1px solid #000;text-align: center;" i18n="seat|row" *ngIf="item.seat_info.hasOwnProperty('row') && item.seat_info.row && item.seat_info.row.length">row</th>
          <th style="border: 1px solid #000;text-align: center;" i18n="seat|section" *ngIf="item.seat_info.hasOwnProperty('section') && item.seat_info.section && item.seat_info.section.length">section</th>
          <th style="border: 1px solid #000;text-align: center;" i18n="seat|Entrance" *ngIf="item.seat_info.hasOwnProperty('entrance') && item.seat_info.entrance && item.seat_info.entrance.length">Entrance</th>
        </tr>
        <tr>
          <td style="border: 1px solid #000;" *ngIf="item.seat_info.hasOwnProperty('seat') && item.seat_info.seat && item.seat_info.seat.length">
            <p style="font-size: 1.1em;text-align: center;">{{item.seat_info.seat}}</p>
          </td>
          <td style="border: 1px solid #000;" *ngIf="item.seat_info.hasOwnProperty('chair') && item.seat_info.chair && item.seat_info.chair.length">
            <p style="font-size: 1.1em;text-align: center;">{{item.seat_info.chair}}</p>
          </td>
          <td style="border: 1px solid #000;" *ngIf="item.seat_info.hasOwnProperty('table') && item.seat_info.table && item.seat_info.table.length">
            <p style="font-size: 1.1em;text-align: center;">{{item.seat_info.table}}</p>
          </td>
          <td style="border: 1px solid #000;" *ngIf="item.seat_info.hasOwnProperty('row') && item.seat_info.row && item.seat_info.row.length">
            <p style="font-size: 1.1em;text-align: center;">{{item.seat_info.row}}</p>
          </td>
          <td style="border: 1px solid #000;" *ngIf="item.seat_info.hasOwnProperty('section') && item.seat_info.section && item.seat_info.section.length">
            <p style="font-size: 1.1em;text-align: center;">{{item.seat_info.section}}</p>
          </td>
          <td style="border: 1px solid #000;" *ngIf="item.seat_info.hasOwnProperty('entrance') && item.seat_info.entrance && item.seat_info.entrance.length">
            <p style="font-size: 1.1em;text-align: center;">{{item.seat_info.entrance}}</p>
          </td>
        </tr>
      </table>

      <p class="copyright" style="font-size: 10px;margin-top: 10px;" *ngIf="item.hasOwnProperty('seat_info') && (data.hasOwnProperty('seat_terms')) && data.seat_terms.length">
        {{data.seat_terms}}
      </p>

      <div class="pagebreak"></div>
		</div>
	</div>

	<div *ngIf="data" class="contact">
		<p class="font-small text-center"><span><strong i18n="@@TicketTemplateOrderNumber_">Order Number:</strong></span>&nbsp;<strong>{{data.reciept_id}}</strong></p>
		<p *ngIf="data.contact_us_type == 1 && data.contact_us" class="font-small text-center">
      <span i18n="@@TicketTemplateEmailforadditionalinqui">Email for additional inquiries: </span><a class="font-small" i18n-href="@@TicketTemplateHREF" href="mailto:{{data.contact_us}}?Subject=Inquiry regarding order number {{data.reciept_id}}">{{data.contact_us}}</a></p>
		<p i18n="@@TicketTemplatecontactviaFacebook" *ngIf="data.contact_us_type == 2 && data.contact_us" class="font-small text-center">Event&apos;s Facebook page: <a href="{{data.contact_us}}">{{data.contact_us}}</a></p>
	</div>

	<div *ngIf="!data && noHash">
		<h2 i18n="@@TicketTemplateNotFound" class="not_found">Order details not found</h2>
	</div>

</section>
