import { GlobalsService } from '../globals';
import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit, OnDestroy {
  @Output() applyBlurBackound = new EventEmitter<any>();
  show = false;
  private subscription: Subscription;

  constructor(
    private globals: GlobalsService
  ) { }
ngOnInit() { 
        this.subscription = this.globals.loaderState
            .subscribe((state: any) => {
              this.show = state;
              this.applyBlurBackound.emit(state);
            });
    }
ngOnDestroy() {
        this.applyBlurBackound.emit(false);
        this.subscription.unsubscribe();
    }

}
