import { Component, OnInit, Input } from '@angular/core';
import { FormControl, NgModel } from '@angular/forms';

@Component({
  selector: 'app-error-creator',
  templateUrl: './error-creator.component.html',
  styleUrls: ['./error-creator.component.css']
})
export class ErrorCreatorComponent implements OnInit {

  @Input() control: NgModel;
  @Input() name: string = '';
  @Input() class: string = '';
  constructor() { }

  ngOnInit() {
  }

}
