<ngu-carousel #myCarousel [inputs]="carouselTile" [dataSource]="setSliderData" class="home-carousel">
  <ngu-tile *nguCarouselDef="let slide; let i = index" class="carousel-tile">
      <div class="carousel-inner">
        <div class="carousel-photo-wrapper">
          <a href="{{slide.button_url}}" class="carousel-photo-link" attr.aria-label="{{slide.title[locale] ? slide.title[locale] : slide.title.en}}">
            <div class="carousel-photo" [style.backgroundImage]="slide.imageMobileUrl ? 'url(' + slide.imageMobileUrl + ')':'url(' + slide.imageUrl + ')'"></div>
          </a>
        </div>
        <div class="carousel-description">
          <h3 class="slide-heading">{{slide.title[locale] ? slide.title[locale] : slide.title.en}}</h3>
          <div>
            <a *ngIf="slide.button_name[locale]" href="{{slide.button_url}}" class="carousel-btn" attr.aria-label="{{slide.button_name[locale] ? slide.button_name[locale] : slide.button_name.en}}">
              {{slide.button_name[locale] ? slide.button_name[locale] : slide.button_name.en}}
            </a>
          </div>
        </div>
      </div>
  </ngu-tile>
  <button NguCarouselPrev class="leftRs" [style.opacity]="myCarousel.isFirst ? 0.7:1">
    <img src="/assets/images/svg-icons/carousel-arrow-left.svg" alt="left">
  </button>
  <button NguCarouselNext class="rightRs" [style.opacity]="myCarousel.isLast ? 0.7:1">
    <img src="/assets/images/svg-icons/carousel-arrow-right.svg" alt="right">
  </button>
  <div *ngIf="!isMobile" class="myPoint" NguCarouselPoint>
    <span *ngFor="let i of myCarousel.pointNumbers; let k = index" [class.active]="k==myCarousel.activePoint" (click)="myCarousel.moveTo(k)"><i></i></span>
  </div>
</ngu-carousel>
