<div class="row">
    <div class="col-md-12 col-xs-12" style="background-color: #24262B; margin-top: 45px;">
      <p style="text-align: center; font-weight: 700;">תנאי שימוש &ndash; מערכת ניהול אירועים גט אין בע&quot;מ</p>
      <p><br></p>
      <p style="font-weight: bold">&nbsp; &nbsp; 1. כללי&nbsp;</p>
      <p>אנא קרא הוראות הסכם זה (להלן: &quot;תנאי השימוש&quot;) בטרם תעשה כל שימוש במערכת &quot;גט אין&quot;.&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 1.1. גט אין בע״מ, ח.פ. 515441160 (להלן: &quot;החברה&quot;) הינה המפתחת, המפעילה והמשווקת הבלעדית של מערכת גט אין מבוססת אתר אינטרנט ו/או אפליקציה סלולרית.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 1.2. מערכת גט אין נועדה בכדי לספק שירותים של רכישה, גבייה, מידע ופרסום למפיקים ולמשתתפים באירועים ובכנסים תרבותיים, חינוכיים, עסקיים, ספורטיביים ואחרים.&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 1.3. כל שימוש שתעשה במערכת כפוף לתנאי השימוש ומדיניות הפרטיות של החברה, המפורסמים באתר האינטרנט של החברה בכתובת <a class="c20" data-fr-linked="true" href="https://www.get-in.com/">www.get-in.com</a>, כמו גם לכל דין רלוונטי.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 1.4. מעצם השימוש שתעשה במערכת הינך נותן את הסכמתך ואישורך לכך שאתה עומד במלוא ההוראות והתנאים שבתנאי השימוש ובמדיניות הפרטיות.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 1.5. תנאי השימוש נכתבו לשם הנוחיות בלשון זכר יחיד, אך הם מיועדים לשני המינים, כמו גם לרבים.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 1.6. כותרות תנאי השימוש יועדו לנוחיות בלבד ואין להן פירוש משפטי.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 1.7. ככל שאינך מסכים לאילו מהדברים האמורים בתנאי השימוש, מדיניות הפרטיות, ההנחיות והתנאים שמפרסמת בית העסק במערכת מעת לעת, הרי שאתה נדרש להפסיק באופן מיידי כל שימוש במערכת.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 1.8. לא חלה עליך החובה לאשר תנאי שימוש אלה אך אם תימנע מלעשות כן לא תוכל החברה להבטיח את ההנאה והשימוש שלך במערכת.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 1.9. במידה והינך מארגן בהתאם למוגדר להלן, תנאי שימוש אלה הינם נוספים לכל הסכם התקשרות נוסף שבינך לבין החברה ובכל מקרה של סתירה יינתן תוקף להסכם ההתקשרות שנחתם עימך.</p>
      <p><br></p>
      <p style="font-weight: bold">&nbsp; &nbsp; 2. הגדרות&nbsp;</p>
      <p>במסמך זה תהיינה משמעותם של המונחים הבאים כמצוין להלן:</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 2.1. &quot;המערכת&quot; &ndash; מערכת מבוססת אתר אינטרנט ו/או אפליקציה סלולארית, המתקראת גט אין, אשר בבעלות החברה והמיועדת בכדי לספק שירותים של רכישה, גבייה, מידע ופרסום למפיקים ולמשתתפים באירועים ובכנסים תרבותיים, חינוכיים, עסקיים, ספורטיביים ואחרים.&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 2.2. &quot;אתר האינטרנט&quot; &ndash; אתר האינטרנט של החברה בכתובת <a class="c20" data-fr-linked="true" href="https://www.get-in.com/">www.get-in.com</a>.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 2.3. &quot;אירוע&quot; - &nbsp;אירוע ו/או כנס תרבותי, חינוכי, עסקי, ספורטיבי ואחר, המיועד לציבור מסוים או לא מסוים.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 2.4. &quot;משתמש&quot; &ndash; הוא אתה וכל מי שעושה שימוש במערכת. שימוש יכול שיהיה קריאה, עיון וצפייה בתכנים, הזמנת שירותים, תשלום, העברת מידע.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 2.5. &quot;מארגן&quot; &ndash; משתמש שנעזר במערכת לשם ההפקה והארגון של אירוע ונעזר במערכת לשם מכירת כרטיסים, לאירוע שהמארגן מפיק, למשתמשים אחרים.&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 2.6. &quot;שירותים&quot; &ndash; כל שימוש שיעשה משתמש במערכת.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 2.7. &quot;הקמת אירוע&quot; &ndash; ביצוע פעולות של מארגן במערכת באופן שיאפשר למארגן לעשות שימוש במערכת לשם הרשמה ו/או מכירת כרטיסים לאירוע.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 2.8. &quot;קידום אפילייט&quot; &ndash; שירות שמספקת החברה ובאמצעותו יוכל מארגן אירוע לבחור ולרכוש שירותי קידום אפילייט מהחברה. בחירת שירותי קידום אפילייט הינה באמצעות בחירת שירותי הקידום במקום המיועד לכך בעת הקמת האירוע.</p>
      <p><br></p>
      <p style="font-weight: bold">&nbsp; &nbsp; 3. הצהרות המשתמש</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 3.1. המשתמש מאשר כי השימוש שהוא עושה במערכת הנו לאחר שקרא, הבין קיבל על עצמו את תנאי השימוש ומדיניות הפרטיות של החברה.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 3.2. המשתמש מאשר כי הנו מבוגר מגיל 18 ואם הינו קטין הרי שהשימוש שלו במערכת אושר על ידי הוריו ו/או האפוטרופוס החוקי שלו. המשתמש ער לכך שהחברה אינה יכולה לאמת את גילו, אך במקרה שיפר סעיף זה הוא עשוי להיות מושעה מפעילות במערכת והחברה מסירה כל אחריות מפעילות במערכת והוא עשוי להיות מושעה לאלתר משימוש בה.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 3.3. המשתמש מאשר כי הנו האחראי הבלעדי לכך שהשימוש שיעשה במערכת יהיה בהתאם לדין, לתנאי השימוש, למדיניות הפרטיות, להנחיות ולתנאים שמפרסמת החברה (להלן: &quot;שימוש ראוי&quot;).&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 3.4. המשתמש מאשר כי בכל שימוש שיעשה במערכת הוא יימנע מכל פגיעה אפשרית בזכויותיו של אחר, לרבות, פגיעה בזכות קניינית ו/או בזכות קניין רוחני ו/או בזכות יוצרים ו/או בזכות לשם טוב ו/או בזכות לפרטיות ו/או בזכות אחרת שקיימת לפי כל דין. המשתמש מאשר כי ידוע לו שהפרת סעיף זה תהווה הפרה יסודית של תנאי השימוש ועשויה להביא להשעיית השימוש שיעשה במערכת ולהקמת חבות משפטית מצדו כלפי החברה וכלפי כל נפגע אחר.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 3.5. לחברה תעמוד הזכות להשעות כל שימוש במערכת במקרה של חשש לפעילות במערכת שאינה מהווה שימוש ראוי, עד לבירור נסיבות העניין. המשתמש פוטר את החברה מכל טענה ו/או תביעה על רקע של השעיית שימוש במערכת לפרק זמן קצוב ו/או בלתי קצוב.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 3.6. המשתמש, מאשר כי המידע שיספק אגב השימוש במערכת הנו אמין ותקף, וזאת, בין השאר, על מנת לשפר את חווית השימוש במערכת. התברר למשתמש כי המידע שנמסר על ידו חסר או שגוי, יעדכן המשתמש את בית העסק בעניין במועד מוקדם ככל הניתן. ידוע למשתמש שמסירה של פרטים כוזבים על ידו מהווה הפרה יסודית של תנאי השימוש, אשר עשויה להביא להשעיית השימוש שלו במערכת, והחברה תהיה פטורה מכל חבות בגין נזק אשר יגרם למשתמש בשל כך וזאת מבלי לגרוע מכל סעד אחר אשר יגיע לחברה על פי דין.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 3.7. המשתמש מאשר כי ידוע לו והוא נותן הסכמתו לכך שמידע הנוגע לשימוש שלו במערכת עשוי להיות גלוי לצדי ג&apos; אחרים, לרבות מארגני האירועים, חברות אשראי ומפרסמים במערכת.&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 3.8. המשתמש מאשר כי ידוע לו שהחברה אינה צד לכל התקשרות שתתקיים בינו לבין מארגן אירוע ו/או כל צד ג&apos; אחר, לרבות, החברה אינה אחראית כלפי המשתמש לתקינות ו/או שלמות ו/או מהימנות ו/או לתוקף של השירות ו/או המוצרים ו/או המידע שיפורסם ו/או שיונגש במערכת, על ידי מארגנים ו/או מפרסמים ו/או צדי ג&apos; אחרים ומשכך הוא פוטר את בית העסק מכל חבות כלפיו בעניין המידע האמור.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 3.9. המשתמש מאשר כי אין ולא מתקיימים בין החברה לבינו, בין אם הוא מארגן ו/או משתמש, יחסי עובד מעסיק, יחסי סוכנות או יחסי שותפות כמשמעותם על פי כל דין, ועל כל המשתמע מיחסים אלה. המשתמש לא יציג את עצמו כנציג, סוכן או עובד של החברה והחברה לא תהיה אחראית ולא תחוב בגין כל מעשה, מחדל או מצג אשר יעשה על ידי המשתמש בניגוד לאמור לעיל.&nbsp;</p>
      <p><br></p>
      <p style="font-weight: bold">&nbsp; &nbsp; 4. הרשמה למערכת</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 4.1. בהתאם לשיקול דעתה הבלעדי של החברה יתאפשרו חלק מן השירותים של המערכת ללא ביצוע של רישום מלא למערכת (להלן: &quot;רישום חלקי&quot;). עם זאת מובהר כי משתמש שיבחר ברישום חלקי לא יוכל להנות מכל השירותים של המערכת אך תנאי השימוש יחולו עליו באופן מלא.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 4.2. במהלך הרישום לאתר ומטעמי שמירה על פרטיות המשתמש, יתבקש המשתמש להזין כתובת דואר אלקטרוני וסיסמא אשר יהוו את אמצעי הזיהוי שלו בכל גישה למערכת (להלן: &quot;אמצעי הזיהוי&quot;).&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 4.3. אמצעי הזיהוי המשמשים לחיבור למערכת הינם אישיים וסודיים. המשתמש יקפיד לשמור את אמצעי הזיהוי האישי שלו בסודיות, באופן שאינו גלוי ואינו בקרבת אמצעי הגישה למערכת ולא יעביר אותו לצד ג&apos; כלשהו. המשתמש מתחייב לעדכן באופן מיידי את החברה על כל שימוש בלתי חוקי בפרטיו האישיים.&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 4.4. בכל מקרה של ניצול לרעה של המערכת על ידי אחר על רקע של התרשלות המשתמש בשמירת סודיות אמצעי הזיהוי, תחול האחריות המלאה על המשתמש והוא פוטר את החברה מכל אחריות ו/או חבות בעניין.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 4.5. החברה תהיה רשאית לדרוש את עדכון אמצעי הזיהוי ו/או הוספת מידע הנוגע למשתמש מעת לעת מכל סיבה שהיא. ככל שהמשתמש יבחר שלא לספק את המידע המעודכן האמור הרי שהוא עלול לאבד את היכולת להנות מכל או מלוא משירותי המערכת והוא פוטר את החברה מכל טענה בעניין.</p>
      <p><br></p>
      <p style="font-weight: bold">&nbsp; &nbsp; 5. השימוש באתר&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 5.1. המשתמש יעשה שימוש במערכת רק לשם הנאה אישית ובהתאם למטרות שלשמה נועדה. המשתמש מאשר כי לא יעשה במערכת שימוש מסחרי שאינו אישי אלא בהתאם להסכם מפורש שנערך בינו לבין החברה בעניין זה.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 5.2. בכפוף למגבלות המפורטות בתנאי השימוש, השימוש במערכת יהיה פתוח לכלל המשתמשים הפוטנציאלים, תוך שהחברה אינה מחוייבת בביצוע סינון או בדיקה של של יכולת או התאמת המשתמשים לעמוד בהתחייבויותיהם ו/או לעשות שימוש במערכת.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 5.3. המשתמש מאשר כי לא יעשה במערכת שימוש שעשוי להוות סריקה של המערכת באמצעים ממוחשבים ו/או שיש בהם משום חיקוי גולש רגיל, לרבות Bots או Crawlers &nbsp;וכדומה ו/או לעשות שימוש בתוכנת מחשב, קוד מחשב או יישומים הכוללים נגיפי מחשב (&quot;וירוסים&quot;) ו/או תוכנות סוס טרויאני, תולעים (Worms), וואנדלים (Vandals), , יישומים מזיקים(Malicious Applications) &nbsp; וכו&apos;.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 5.4. המשתמש מאשר כי לא יעשה במערכת כל פעילות אשר עשויה להוות מניפולציה שעשויה לאפשר את הנגישות לדפים שאליהם אין למשתמש גישה ישירה (URL Hacking).</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 5.5. המשתמש מאשר כי לא יעשה במערכת כל פעולה שתגרום להשפעה על קוד המקור, עיצוב המערכת, אלמנטים או תוכן במערכת.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 5.6. משתמש לא יעשה במערכת שימוש בלתי הולם או שאינו בהתאם לדין, לרבות, יימנע מפעילות שיש בה כדי לפגוע בקניין רוחני ו/או בסימן מסחר ו/או בזכות יוצרים ו/או בסוד מסחרי ו/או מהווה פרסום לשון הרע ו/או מהווה פגיעה בפרטיותו של אחר.</p>
      <p><br></p>
      <p style="font-weight: bold">&nbsp; &nbsp; 6. ארגון אירוע &nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 6.1. באמצעות שימוש במערכת יוכל מארגן אירוע, שהנו משתמש רשום למערכת, ליצור דף אירוע אשר בו יוצגו פרטי אירוע, לרבות, סוג, מועד, כתובת, מספר מקומות, מחיר השתתפות, מחיר שירותים, פרטי המארגנים, מדיניות ביטולים והחזרים, מידע שיווקי ופרסומי וכל תוכן אחר שלהערכת מארגן האירוע עשוי להיות רלוונטי למשתמשים שנרשמו שהינם משתתפים פוטנציאליים באירוע ושאינו מהווה תוכן אסור (להלן: &quot;הקמת אירוע&quot;).</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 6.2. החברה תהיה רשאית אך לא מחויבת לבדוק את התאמת מארגן האירוע והאירוע המדובר לתנאי השימוש ולמדיניות. החברה תהא רשאית למנוע, על פי שיקול דעתה הבלעדי, את השימוש במערכת לארגון אירוע, מכל סיבה שהיא ומבלי שהיא נדרשת ליתן הודעה מוקדמת ולמשתמש ו/או למארגן לא תהיה כל טענה ו/או דרישה כלפי החברה בקשר לכך.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 6.3. בתמורה לשירותי החברה תהיה זכאית החברה מן המארגן לשיעור של 5 אחוזים מכל תשלום של משתמש באמצעות המערכת במהלך רכישת כרטיסים לאירוע של המארגן, והכל בתוספת מע&quot;מ כדין &nbsp;(להלן: &quot;תמורה לחברה&quot;).</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 6.4. התקיים האירוע שהפיק המארגן במועדו והסתיים בהצלחה וללא אירועים חריגים (להלן: &quot;אירוע מזכה&quot;), במועד הקבוע לתשלום של חברת האשראי שעימה התקשרה החברה לשם קבלת תשלום מעת המשתמשים בגין כרטיסים שרכשו לשם ההשתתפות באירוע האמור, בכפוף להסכם בין חברת האשראי לחברה ולדין, תעביר חברת האשראי את הכסף שנצבר בחברת האשראי לידי החברה (להלן: &quot;הסכום המועבר מחברת האשראי&quot;).</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 6.5. מבלי לגרוע מהוראות סעיף &lrm;6.8, התקיים אירוע מזכה ועמד מארגן האירוע במלוא התחייבויותיו כלפי החברה והמשתמשים שבהסכם ו/או שבדין והתקבל בחברה סכום מועבר מחברת האשראי בגין האירוע המזכה, תעביר החברה למארגן את הסכום מועבר מחברת האשראי בגין האירוע המזכה בניכוי התמורה לחברה וכל זאת במועד המאוחר מבין מועד האירוע המזכה ו-30 ימים מקבלת מלוא הסכום המועבר מחברת האשראי בגין האירוע המזכה האמור.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 6.6. החברה תעביר למארגן תשלום בכפוף לכל דין. תשלום יועבר למארגן בכפוף לכך ש: (1) במקרה שהדבר נדרש על פי דין ביצעה החברה לשביעות רצונה למארגן הליך &quot;הכר את הלקוח&quot;; (2) שלא עלה חשש בחברה שאיזה מהכספים שמיועדים להעברה למארגן מקורם אינו כשר, למשל כתוצאה מרכישת כרטיסים לאירוע באמצעי תשלום שאינם כשרים או בניגוד לדין; (3) שלא עלה חשש שהאמור באירוע שנערך שלא בהתאם לדין או שהמארגן פעל ו/או פועל בהקשר האירוע שלא על פי דין. לא העבירה ו/או עיכבה החברה תשלום למארגן בשל הוראות סעיף זה, ישתף המארגן פעולה עם כל דרישה של החברה ויעביר לחברה כל מסמך מבוקש אשר נדרש לדעת &nbsp;החברה לשם הבהרת הדברים. המארגן מסתלק באופן בלתי חוזר מכל טענה ו/או דרישה ו/או תביעה מן החברה בשל אי ההעברה ו/או עיכוב העברת כספים בשל האמור בסעיף זה.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 6.7. מובהר ומוסכם כי החברה לא תחוב בשום הוצאה ו/או אובדן רווח ו/או חוב שנגרם למארגן ככל שהתעכבה ו/או בוטלה העברת כספים לרשותו של המארגן בשל אירוע שאינו מהווה רשלנות של החברה ו/או בשל כך שהאירוע הרלוונטי אינו אירוע מזכה ו/או בשל אי קבלה של החברה במועד ו/או בשלמות של הסכום המועבר מחברת האשראי ו/או בשל הפרה שבהסכם ו/או שבדין של חובות המארגן כלפי החברה ו/או צד ג&apos; ו/או בשל חשש של החברה להפרת הדין מעצם העברת כספים למארגן.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 6.8. החברה תהיה רשאית לנכות ו/או לקזז מכל תשלום למארגן סכום שנשאה בו החברה בשל אי הפרה של הדין ו/או הוראות הסכם זה על ידי המארגן ו/או מי מטעמו, וזאת לרבות במקרה בו החברה הגיעה להסדר ו/או פשרה עם צד ג&apos; על רקע של חשש ממשי של הפרת מארגן ו/או מי מטעמו, את הדין ו/או הוראות הסכם זה.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 6.9. החברה עשויה לפרסם את פרטי המארגן ואת דרכי ההתקשרות עימו במערכת, בהודעות שונות שתעביר למשתמש ובכל פנייה של צד ג&apos; בבירור מידע בעניין פרסום של מארגן. באישור תנאי שימוש אלה המארגן נותן את אישורו לפרסום כאמור, ומשתמש שרכש כרטיס לאירוע נותן את אישורו לכך שיפנה ישירות למארגן בכל שאלה ו/או בירור ו/או שאינה נוגעת ישירות לפעילות המערכת (שאז המשתמש מתבקש לפנות לחברה).</p>
      <p style="font-weight: bold">&nbsp; &nbsp; 7. תכנים&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 7.1. מבלי לגרוע מכלליות האמור בתנאי השימוש, המשתמש מתחייב שלא להעלות ו/או לפרסם באמצעות המערכת תוכן שהנו מנוגד לדין, דוגמת תוכן פוגעני, גזעני, המעודד אפליה ו/או אלימות, פורנוגרפי, שעלול לפגוע ברגשות הציבור, טורדני, וולגרי, גס, מעליב, עוין, מאיים ו/או אשר עלול להתפרש כאחד מאלו ו/או שפוגע בזכויותיו של אחר או של החברה (להלן: &quot;תוכן אסור&quot;).&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 7.2. החברה אינה מחויבת לסנן ו/או לבדוק כל תוכן שמא הוא מהווה תוכן אסור אך היא תהא רשאית להסיר תוכן אסור על פי שיקול דעתה הבלעדי ומבלי שהיא נדרשת ליתן הודעה מוקדמת ולמשתמש לא תהיה כל טענה ו/או דרישה כלפי החברה בקשר לכך.&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 7.3. הועלה תוכן אסור למערכת שלא על ידי החברה, המשתמש פוטר את החברה מכל אחריות לכך ולא תהיה כל טענה, תביעה או דרישה כלפי החברה בשל פעולה כאמור. יובהר כי החברה לא תיטול אחריות על כל נזק אשר עלול להיגרם עקב העלאת מידע או תוכן על ידי משתמש.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 7.4. העלה משתמש תוכן אסור, הוא מתחייב לשפות את החברה בגין כל תביעה, טענה ו/או דרישה אשר תופנה כלפי החברה בגין פרסום תכנים אסורים ומתחייב לשפות את החברה בגין כל חבות שתצמח לה, לרבות שכ&quot;ט עו&quot;ד, מיד עם דרישתה הראשונה בעניין.</p>
      <p><br></p>
      <p style="font-weight: bold">&nbsp; &nbsp; 8. רכישת כרטיסים&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 8.1. באמצעות המערכת תתאפשר למשתמש ההזמנה והרכישה של כרטיסים אשר מאפשרים השתתפות באירועים שמפיק מארגן (להלן: &quot;רכישה&quot;).&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 8.2. ההזמנה של כרטיסים תתאפשר בהתקיימות התנאים בהתאם למוסכם בין החברה למארגן. התנאים האמורים עשויים להיות מחיר הרכישה, הכמות לרכישה, התאמת פרופיל המשתמש לאירוע, זמינות כרטיסים פנויים לרכישה ועוד, בהתאם להחלטות החברה מכל סיבה שהיא ובהתאם לדין.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 8.3. מחיר הכרטיסים להשתתפות באירוע נקבעים על ידי המארגן, נקובים בשקלים חדשים וכוללים מע&quot;מ. עם זאת, מחיר הכרטיסים אינו כולל עמלת רכישה הנגבית על ידי החברה כמפורט בדף האירוע לצד מחיר הכרטיס.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 8.4. משהושלם תהליך הזמנת כרטיסים בהתאם לנדרש על ידי החברה והמארגן יישלח אישור בדבר הזמנת כרטיסים לכתובת הדואר האלקטרוני של המשתמש (להלן: &quot;אישור הזמנה&quot;). מובהר כי אין לראות באישור הזמנה כאישור סופי של רכישת הכרטיסים והוא מהווה אישור אך ורק לכך שהזמנת הכרטיסים נקלטה במערכת.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 8.5. בכפוף להשלמת הליך הזמנת הכרטיסים, לתנאים לאישור רכישת כרטיסים כפי שהוסכמו בין החברה למארגן ואישור התשלום בגין הזמנת הכרטיסים על ידי חברות האשראי, הבנק או גורם רלוונטי אחר, יועבר לדוא&quot;ל של המשתמש אישור רכישה סופי וכן הודעה לסלולרי בצירוף ברקוד אשר מהווים אישור להשתתפות המשתמש באירוע האמור (להלן: &quot;אישור רכישה&quot;).</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 8.6. מעת לעת ובהתאם לסיכום מוקדם בין המארגן לחברה, תישלח למשתמש הודעת אישור רכישה באופן מיידי מבלי שתקדם לה הודעת אישור הזמנה ובמקרה זה יראו באישור הרכישה גם כאישור הזמנה.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 8.7. מובהר כי החברה אינה אחראית או מחויבת לבדוק את התאמתו של המשתמש לקחת חלק באירוע אך החברה ו/או המארגן רשאים שלא לאשר את השתתפות ו/או ברכישה של כרטיסים על ידי המשתמש באירוע האמור, מכל סיבה שהיא ובהתאם לדין.&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 8.8. ככל שהחברה לא תאשר הרכישה על ידי המשתמש, מכל סיבה שהיא, המשתמש לא יחויב בתשלום בגין ההזמנה והחברה לא תהא אחראית ולא תחוב כלפי המשתמש או כל צד שלישי אחר בגין אי אישור הרכישה.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 8.9. המשתמש נותן את הסכמתו לקבלת מידע וחומר פרסומי הנוגע לאירוע אליו ביצע המשתמש הזמנת כרטיסים כמו גם לאירועים אחרים שלהערכת החברה עשוי שיהיה למשתמש עניין בהם. משלוח המידע והחומר הפרסומי עשוי להיות בדוא&quot;ל, במסרונים, בדואר או בכל דרך מקובלת אחרת.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 8.10. בכפוף לאישור רכישה, תשלח החברה למשתמש כרטיס השתתפות באירוע באמצעות הדוא&quot;ל. מובהר כי אין בהזמנת הכרטיסים באמצעות המערכת כדי ליצור התקשרות כלשהי בין החברה למשתמש הנוגעת לאירוע ולא תישמע כל טענה כלפי החברה בגין כל נזק ישיר או עקיף שיגרם למשתמש עקב שירות המערכת לתיווך שבין מארגן האירוע למשתמש לשם רכישת כרטיסים להשתתפות באירוע.</p>
      <p><br></p>
      <p><br></p>
      <p style="font-weight: bold">&nbsp; &nbsp; 9. ההשתתפות באירועים&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 9.1. ככל שמארגן אירוע לא קבע אחרת, אישור הרכישה יהווה אסמכתא לרכישה של כרטיס להשתתפות באירוע על ידי המשתמש.&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 9.2. לשם כניסה לאירוע חובה על המשתמש להציג עותק מודפס של אישור הרכישה ו/או מספר ברקוד שנשלח למכשיר הסלולרי שלו בעת אישור הרכישה.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 9.3. ככל שיחולו שינויים ו/או עדכונים שנוגעים לאירוע, תחול על המארגן בלבד החובה לעדכן בכך את מי שקיבל אישור רכישה או הזמנה לאירוע האמור. מובהר כי לא תחול על החברה כל אחריות לעדכן את המשתמש בכל מידע הנוגע לאירוע והמשתמש פוטר את החברה מכל חובה כאמור.&nbsp;</p>
      <p><br></p>
      <p style="font-weight: bold">&nbsp; &nbsp; 10. תשלום על כרטיסים</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 10.1. רכישת הכרטיסים באמצעות המערכת תתאפשר באמצעות תשלום בכרטיס אשראי וכן באמצעות אמצעי תשלום אחרים אשר יפורסמו במערכת מעת לעת, בהתאם לפרטי התשלום שיספק המשתמש בעת הרכישה (להלן &quot;פרטי תשלום&quot;).</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 10.2. ככל ולא ניתן אישור כאמור לביצוע רכישת הכרטיסים מחברת האשראי ו/או כל גורם מאשר אחר יקבל המשתמש הודעה על אי קבלת אישור רכישה, אשר תשלח לכתובת בהודעת מסרון ולכתובת הדואר האלקטרוני אותה מסר בעת הזמנת הכרטיסים, והזמנת הכרטיסים תבוטל.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 10.3. השימוש של משתמש במערכת לרכישת כרטיסים והעברת פרטי תשלום על ידו מעידה על הסכמת המשתמש לחיוב ולזיכוי אמצעי התשלום בהתאם לפרטי התשלום וכן להעברת פרטי התשלום לחברת האשראי.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 10.4. לשם אבטחת התשלום בגין רכישת כרטיסים ופרטי התשלום תנקוט החברה באמצעים סבירים לרבות שימוש בטכנולוגיית SSL. עם זאת, החברה לא תישא בכל אחריות בשל נזק או אובדן אשר ייגרמו למשתמש בשל גישה לא מאושרת לפרטי התשלום אשר סיפק משתמש במערכת אלא בשל רשלנות מיוחדת של החברה.&nbsp;</p>
      <p><br></p>
      <p style="font-weight: bold">&nbsp; &nbsp; 11. &nbsp; ביטולים והחזרים&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 11.1. ביטול רכישה וקבלת החזר כספי תתאפשר בהתאם לדין, באמצעות פנייה בעניין זה למארגן האירוע ובכפוף למתן אישור של המארגן לביטול האמור.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 11.2. בהתאם לחוק הגנת הצרכן, תשמ&quot;א-1981 העסקה תהיה ניתנת לביטול אם הועברה הוראת ביטול בתוך ארבעה עשר ימים מיום עשיית העסקה או מיום קבלת המסמך המכיל את פרטי העסקה, לפי המאוחר ביניהם. עם זאת העסקה לא תהיה ניתנת לביטול אם מועד ביטול העסקה חל בתוך שבעה ימים שאינם ימי מנוחה, קודם למועד שבו אמור השירות להינתן לרוכש.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 11.3. החברה לא תהא אחראית לכל נזק שייגרם למשתמש ו/או למארגן כתוצאה מביטול עסקה שבוצע בהתאם לתנאי השימוש או בהתאם לדין.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 11.4. בכל מקרה בו בוטל אירוע מכל סיבה שהיא ובכפוף להודעת המארגן לחברה על ביטול האירוע, יבוטלו רכישות הכרטיסים לאירוע והמארגן יוכל, באמצעות החברה, להחזיר למשתמשים שביצעו רכישה של כרטיסים את כספם, למעט עמלת הרכישה לה זכאית החברה. החברה לא תהיה אחראית כלפי רוכש או כל צד שלישי בגין אירוע אשר בוטל. בכל מקרה בו יחולו עלויות בגין ביצוע הביטול ו/או הזיכוי, יחולו הן על המשתמש שביצע רכישה והן יקוזזו מהסכום המוחזר.</p>
      <p><br></p>
      <p style="font-weight: bold">&nbsp; &nbsp; 12. תוכן פרסומי</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 12.1. המשתמש מאשר לחברה להביא לידיעתו תכנים פרסומיים, שיווקיים והמלצות שונות הנוגעות לחברה ולאחרים, שלהערכת החברה ייתכן והמשתמש ימצא בהם עניין (להלן: &quot;תוכן פרסומי&quot;). התוכן הפרסומי יכול שיועבר למשתמש באמצעות מסרון ו/או הודעת דוא&quot;ל ו/או במהלך השימוש שיעשה המשתמש במערכת ו/או בדרך אחרת.&nbsp;</p>
      <p>משתמש רשאי להורות לחברה בכל עת על הפסקת הדיוור אליך באמצעות הודעה לחברה בכתב על רצונך בכך ו/או משלוח דוא&quot;ל לכתובת [<a class="c20" data-fr-linked="true" href="mailto:support@get-in.com">support@get-in.com</a>].</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 12.2. המשתמש מאשר הצגתו של כל תוכן פרסומי במערכת, כי הוא מבין שהתוכן הפרסומי אינו מהווה המלצה או עידוד של החברה לרכוש שירותים, נכסים או מוצרים, כי החברה אינה צד לכל מצג או התקשרות שבינו לבין מפרסם, כי הוא פוטר את החברה מכל חבות הקשורה בהיותו מקבל תוכן פרסומי ו/או בספקי השירותים וטיב השירותים של המשווקים בתוכן פרסומי ומאשר כי לא תקום לו כל טענה ו/או תביעה הנוגעת לתוכן הפרסומי שיקבל.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 12.3. החברה תהיה רשאית, אך לא מחויבת לכך, לבחון ולאשר כל פרסום במערכת. מצאה החברה שפרסום אינו מהווה שימוש ראוי ו/או עשוי לפגוע בך או באחר ו/או אינו בהתאם לדין ו/או מכל סיבה שהיא, רשאית החברה שלא לאשר ו/או להסיר בכל מועד, כל פרסום, לתקופה קצובה ובלתי קצובה.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 12.4. עשה המארגן שימוש במערכת ו/או בתוצרי המערכת ו/או בכתובת URL של המערכת בכדי להעביר מידע ו/או תוכן ו/או תוכן פרסומי למשתמש ו/או לכל צד ג&apos; (להלן: &quot;נמען&quot;), הרי שהוא עושה זאת על אחריותו בלבד, ותוך שהוא מאשר כי הוא מחזיק ברשותו אישור בכתב מן הנמען להעברת התוכן האמור, וכי הוא עושה זאת בהתאם להוראות חוק התקשורת (בזק ושידורים), תשמ&quot;ב-1982 (להלן: &quot;חוק התקשורת&quot;).&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 12.5. הוטלה על החברה אחריות ו/או חבות בשל הפרת חוק התקשורת ו/או כל דין אחר כפועל יוצא של פעילות משתמש, ישפה המשתמש האמור את החברה בשל כל חבות ו/או אובדן הכנסה שייגרם לחברה בשל כך. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 12.6. בכל שיווק ו/או פרסום שיעשה מארגן באמצעות שימוש במערכת ו/או בתוצריה ו/או בכתובת הURL שלה לשם שיווק ו/או פרסום של אירוע יציג המארגן ו/או מי מטעמו את פרטי התקשרות פעילים וישירים עימו לרבות מספר טלפון וכתובת דוא&quot;ל.</p>
      <p><br></p>
      <p style="font-weight: bold">&nbsp; &nbsp; 13. קידום אפילייט</p>
      <p>בחר מארגן אירוע לרכוש מהחברה שירותי קידום אפילייט ביחס לאירוע מסוים, יחולו ההוראות הבאות בעניין האירוע האמור (להלן: &quot;האירוע המקודם&quot;):&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 13.1. בהתאם לשיקול דעתה הבלעדי, החברה עשויה לפנות אף לצדי ג&apos; שאישרו זאת לחברה באופן מפורש ובכפוף לכל דין, על מנת להציע להם מידע בנוגע לאירוע המקודם.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 13.2. מארגן האירוע המקודם מאשר שבבחירתו בשירותי קידום אפילייט של החברה הוא לא התבסס וממילא לו ניתנה לו כל התחייבות הנוגעת לכדאיות העסקית ו/או השיווקית ו/או לרווחיות כלשהי שתצמח על רקע של שירותי הקידום של החברה.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 13.3. שירותי קידום אפילייט שתבצע החברה יתבססו על המידע שסיפק מארגן האירוע בעת שיזם אירוע במערכת (להלן: &quot;מידע לקידום&quot;), והמארגן נותן בזאת לחברה הרשאת שימוש במידע לקידום. חובת נכונות, תקינות, חוקיות ומהימנות המידע והשימוש בו לשם קידום האירוע תחול אך ורק על מארגן האירוע, אשר יפצה ו/או ישפה את החברה בגין כל אובדן רווח ו/או הוצאה ו/או חבות שתיגרם לחברה עקב השימוש שתעשה במידע לקידום.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 13.4. בתמורה לשירותי קידום אפילייט שתספק החברה למארגן האירוע תהיה החברה זכאית לתשלום בסך ו/או ביחס הקבוע שמארגן האירוע אישר בעת הרישום שביצע לשירותי קידום האירוע (להלן: &quot;עמלת קידום&quot;). מארגן האירוע נותן בזאת את אישורו לחברה לנכות את עמלת הקידום במועד בו תעביר לו החברה את התשלום שנגבה בשמו בגין הכרטיסים שרכשו משתמשים באמצעות המערכת.</p>
      <p>&nbsp;&nbsp;</p>
      <p style="font-weight: bold">&nbsp; &nbsp; 14. הגבלת אחריות</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 14.1. החברה עושה מאמץ על מנת שהשימוש שיעשו משתמשים במערכת יהיה רצוף וללא הפרעות. עם זאת, החברה אינה מתחייבת לכך שהשימוש ייעשה ללא טעויות ו/או גישה בלתי מורשית ו/או תקלות ו/או מבלי נזקים, קלקולים, תקלות, כשלים בחומרה, בתוכנה או בתקשורת (להלן: &quot;כשלים&quot;). המשתמש פוטר את החברה מכל טענה ו/או תביעה בעניין נזק ישיר או עקיף, עוגמת נפש וכיו&quot;ב שייגרמו לו או לרכושו עקב כשלים.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 14.2. מבלי לגרוע מהאמור לעיל, החברה לא תהא אחראית לכל נזק העלול להיגרם ו/או שייגרם לציוד מחשבים ו/או מכשירים סלולאריים ו/או אחר כתוצאה משימוש במערכת והמשתמש מוותר בזאת על כל טענה כלפי החברה בעניין זה.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 14.3. החברה לא תישא בכל אחריות כלפי המשתמש בכל מקרה בו לא תתאפשר לו ו/או למארגן גישה או אפשרות שימוש בשירותי המערכת, כולם או מקצתם.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 14.4. כל שימוש שיעשה משתמש במערכת הנו &quot;כמות שהוא&quot; (&quot;As Is&quot;) ולפי זמינות המערכת (&quot;As Available&quot;) ומבלי כל התחייבות, במפורש או מכללא מצד החברה. כך, למשתמש לא תהיה כל טענה, תביעה או דרישה הנוגעת לטיב, לאמינות, לשלמות, ליכולות ולמגבלות המערכת.&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 14.5. החברה לא תהיה אחראית, כלפי משתמש או כל צד ג&apos; בשל נזק כזה או אחר הנוגע לנזק רפואי ו/או פיזי ו/או עסקי ו/או למוניטין ו/או עקיף, אשר נובע ו/או נוגע לכל שימוש במערכת, אפילו הייתה החברה מודעת לאפשרות שיארע נזק מסוג זה.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 14.6. המערכת משמשת ככלי עזר לארגון אירוע, אך האחריות הנוגעת לאירועים המאורגנים באמצעותה ולשימוש של המארגן במערכת חלה בלעדית על מארגן האירוע. כך לא תחול על החברה כל אחריות בקשר עם האירועים המאורגנים באמצעות המערכת, לרבות טיב האירועים, איכותם, חוקיותם והתאמתם לתיאור במערכת או לצרכי המשתמש וכן לכל שינוי ועדכון הנוגע לאירועים.&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 14.7. החברה שומרת כל זכות לעדכן ו/או להסיר מן המערכת שירותים או להגביל גישה של משתמש או רבים למערכת, ללא מתן הודעה מוקדמת ובהתאם לשיקול דעתה הבלעדי.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 14.8. לא יראו בחברה כאחראית או צד להתקשרות בין המשתמש וכל צד ג&apos;, בקשר לרכישת מוצרים ו/או שירותים. יתר על כן, לא תקום למשתמש כל טענה ו/או תביעה כלפי החברה בקשר עם התקשרות כאמור.&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 14.9. החברה תעשה ככל יכולתה על מנת לשמור על המידע האישי ובכלל זה על אמצעי התשלום אשר נמסרו לה על ידי המשתמש. עם זאת, החברה לא תישא בכל אחריות בשל כל חשיפה, אבדן או גניבה אשר ייגרמו למשתמש במקרה של גישה לא מאושרת לפרטי התשלום אשר סיפק.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 14.10. החברה לא תחוב כלפי המשתמש בגין כל נזק ו/או הוצאה ו/או אובדן רווח על רקע של שימוש שיעשה מארגן אירוע ו/או צד ג&apos; אחר במערכת. כן מובהר שהמערכת מהווה פלטפורמה לשימושם של אחרים, והמשתמש לא יראה בחברה אחראית והחברה לא תחוב כלפי המשתמש, על רקע של שימוש פסול ו/או שבניגוד לדין שאחרים יעשו במערכת.&nbsp;</p>
      <p><br></p>
      <p style="font-weight: bold">&nbsp; &nbsp; 15. קישורים חיצוניים</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 15.1. אגב השימוש יעשה משתמש במערכת ייתכן ויימצא במערכת קישורים לאתרים שונים ברשת האינטרנט, המופעלים או מתפרסמים שלא על ידי החברה (להלן: &quot;קישורים חיצוניים&quot;).&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 15.2. החברה אינה מתחייבת כי קישורים חיצוניים יובילו לאתר אינטרנט פעיל ו/או לאמינותם ו/או לתקינותם ו/או לעמידתם בדרישות הדין, אינה צד לכל התקשרות עם כל גורם שאליו יפנה קישור חיצוני ולא תחוב בכל חוב בשל נזק, הוצאה או אובדן שנגרמו, או נגרמו לכאורה, בקשר עם צדי ג&apos; בעלי קישורים חיצוניים.</p>
      <p><br></p>
      <p style="font-weight: bold">&nbsp; &nbsp; 16. קניין הרוחני</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 16.1. מלוא זכויות הקניין הרוחני הקיימות בשירותי החברה ו/או במערכת מהוות את קניינה הבלעדי של החברה, זאת לרבות כל זכות בפטנטים, בזכות יוצרים, בסימני מסחר, בעיצובים, במדגמים, ביישום, בקוד מחשב, בקובץ גרפי, בטקסט וכל דבר המהווה סוד מסחרי. אין בתנאי שימוש אלה ו/או בשימוש במערכת כדי להקנות למשתמש ו/או למארגן זכות כלשהי במערכת ו/או במידע שנאסף באמצעותה.&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 16.2. על המשתמש חל איסור, להעביר ו/או להמחות ו/או למכור ו/או להשכיר ו/או לשדר בפומבי ו/או להעתיק ו/או לשכפל מי מבין התכנים ו/או השירותים ו/או המערכת בחלקה או בשלמותה.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 16.3. מעצם השימוש של משתמש במערכת יש משום אישור כי לא יעשה כל שימוש במערכת שיש בו כדי להפר את זכויותיהם של אחרים, לרבות, זכויות קניין רוחני. כך, חל איסור על משתמש לעשות שימוש במערכת בכל תוכן, לרבות תמונה ו/או לוגו ו/או סימן מסחר ו/או תרשים ו/או טקסט ו/או קובץ ו/או מידע, שהמשתמש אינו מחזיק ברשותו בזכויות לשם השימוש בהם באמצעות המערכת.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 16.4. &nbsp;החברה לא תחוב בנזק שיגרם לאחר על רקע של שימוש שיעשה משתמש במערכת שיפר את זכויותיו של צד ג&apos; והמשתמש ישפה את החברה בגין כל חבות שתקום לה עקב השימוש שיעשה במערכת.&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 16.5. ככל שמשתמש ער לשימוש במערכת שהנו מפר זכויות קניין רוחני של אחר הרי שהחברה מפצירה בו ליצור עימה קשר בהקדם ולהעביר לידה מידע רלוונטי בעניין.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 16.6. מובהר כי הערות או הצעות הנוגעות לפיתוח ו/או לעיצוב ו/או לשיפור המערכת שתעביר לחברה, לא יקנו לך כל זכות במערכת, ואתה נותן בזאת רישיון בלתי-הדיר וכלל-עולמי לחברה לעשות שימוש, ללא כל תמורה מצדך, בכל הצעה כאמור.&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 16.7. המשתמש מאשר לחברה לעשות שימוש במידע שעבר לידיה במהלך השימוש שנעשה במערכת, לרבות שימוש מסחרי, בהתאם למדיניות הפרטיות של החברה ולכל דין.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 16.8. המשתמש מוותר בזאת וויתור בלתי חוזר על כל זכות, לרבות, זכויות קניין רוחני בכל מידע שפרסם במערכת והוא מתיר בזאת לחברה לעשות בו כל שימוש בכפוף לדין.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 16.9. המשתמש, לרבות המארגן, מאשר כי כל מידע שהתקבל בחברה אגב אירוע שהפיק המארגן הינו קניינה הבלעדי של החברה. המארגן יהיה רשאי, במשך 30 ימים מתום האירוע לקבל מן החברה, באמצעות שימוש במערכת וללא תמורה נוספת, העתק של המידע שהתקבל אגב האירוע שהפיק לצורך שימוש המארגן.&nbsp;</p>
      <p><br></p>
      <p style="font-weight: bold">&nbsp; &nbsp; 17. עדכוני מערכת</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 17.1. אין בשימוש שתעשה במערכת כדי לחייב את החברה בתמיכה ו/או בתחזוקה ו/או בשדרוג ו/או בגרסאות חדשות של המערכת.&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 17.2. החברה תעדכן את המערכת והשירותים שלה מעת לעת, בהתאם לשיקול דעתה הבלעדי, ללא חובת הודעה מוקדמת, אשר עשוי להיות כרוך בתקלות ו/או באי-נוחות, לרבות כל שינוי הנוגע להיקף השירות, לטיבו, לזמינותו ולכל היבט נוסף, לרבות ההפסקה ו/או ההוספה של שירות (להלן: &quot;עדכון שירות&quot;). לא תקום למשתמש כל טענה ו/או תביעה על רקע של עדכון שירות, או תקלות שיתרחשו עקב כך.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 17.3. במידה שנודע לך על תקלה או שגיאה בפעולת המערכת, אתה מוזמן להודיע זאת לחברה. החברה תטפל בפניות שיועלו בפניה בהתאם לשיקול דעתה הבלעדי ולא תקום למשתמש כל טענה ו/או תביעה על רקע זה.</p>
      <p><br></p>
      <p style="font-weight: bold">&nbsp; &nbsp; 18. סיום ההתקשרות</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 18.1. החברה שומרת לעצמה את הזכות לשנות, להפסיק ו/או להשהות את פעילות המערכת השירותים, במלואם או בחלקם, באופן זמני או קבוע, וזאת על פי שיקול דעתה הבלעדי וללא מתן הודעה מוקדמת ומבלי שתידרש לספק נימוק ו/או הסבר לכך.&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 18.2. החברה תהא רשאית, לפי שיקול דעתה הבלעדי וללא מתן הודעה מוקדמת, להפסיק ו/או לדרוש ממשתמש לחדול משימוש במערכת ו/או בשירותים של החברה, כולם או חלקם, בקרות מעשה ו/או פעולה ו/או חשד סביר שהדבר נעשה בניגוד לאמור בתנאי שימוש אלה ו/או בקרות אחד מן האירועים הבאים:&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 18.2.1. המשתמש מסר פרטים שגויים או חסרים בעת הרישום לאתר;&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 18.2.2. המשתמש מסר במעשה או במחדל את אמצעי הזיהוי לצד שלישי;&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 18.2.3. המשתמש עשה ו/או ניסה ו/או עודד לעשות שימוש במערכת שהנו בניגוד לדין.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 18.2.4. &nbsp;המשתמש ביצע מעשה הפוגע או עלול לפגוע בחברה או בעובדיה או במשתמשים אחרים או בצד ג&apos;.</p>
      <p>הפסיקה החברה את השימוש של משתמש במערכת לאור סעיף זה המשתמש מאשר ומוותר בזאת על כל טענה ו/או דרישה בקשר לכך.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 18.3. המשתמש מצהיר ומאשר שידוע לו ששירותי המערכת והחברה תלויים בצדי ג&apos; שלחברה אין שליטה עליהם וכי כל הפרעה, שיבוש ו/או הפסקה של שירותים מאת גורמים שלישיים עשויה להפריע למתן השירותים ו/או לגרום להפסקתם. המשתמש מאשר ומוותר בזאת על כל טענה ו/או דרישה בקשר לכך.</p>
      <p><br></p>
      <p style="font-weight: bold">&nbsp; &nbsp; 19. מדיניות הפרטיות</p>
      <p>מדיניות הפרטיות העדכנית כפי שמפרסמת החברה במערכת (להלן: &quot;מדיניות הפרטיות&quot;) מהווה חלק בלתי נפרד מתנאי שימוש אלה. &nbsp;</p>
      <p><br></p>
      <p style="font-weight: bold">&nbsp; &nbsp; 20. דין וסמכות שיפוט&nbsp;</p>
      <p>על הסכם זה יחול הדין הישראלי בלבד. בתי המשפט במחוז תל אביב יהיו בעלי סמכות שיפוט ייחודית ובלעדית בכל סכסוך או מחלוקת שיתגלעו בין הצדדים להסכם זה.&nbsp;</p>
      <p><br></p>
      <p style="font-weight: bold">&nbsp; &nbsp; 21. שונות&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 21.1. משתמש אשר ביצע רכישה באמצעות המערכת יוכל לקבל תמיכת החברה בשירותים באמצעות פנייה לדוא&quot;ל <a class="c20" data-fr-linked="true" href="mailto:support@get-in.com">support@get-in.com</a> או באמצעות שיחת צ&apos;אט באמצעות המערכת. התמיכה תינתן בימי עבודה, בימים א&apos; &ndash; ה&apos;, בין השעות 09:00 &ndash; 17:00.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 21.2. תנאי שימוש אלה מייצגים את מלוא ההסכמות בנוגע לשירותים של המערכת וכל שינוי בהם &nbsp;יתאפשר רק בהסכם בכתב עם החברה.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 21.3. אם ייקבע כי הוראה כלשהי שבתנאי השימוש אינה ניתנת ליישום, אזי תנוסח הוראה זו מחדש אולם רק ככל הנחוץ על מנת לעשותה להוראה ניתנת לאכיפה.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 21.4. החברה רשאית, בכל עת, לשנות את תנאי השימוש, כולם את חלקם, באמצעות פרסומם במערכת. מוסכם כי המשך השימוש שלך במערכת, לאחר שהובא לידיעתך שינוי בתנאי השימוש, יהווה את להסכמתך לשינוי האמור.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 21.5. אי מימוש זכויות החברה על פי תנאי שימוש אלה אין משמעותם שהחברה וויתרה עליהן.&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 21.6. פקע הסכם זה, הוראות סעיפים 12, 14, 16 - 21 לתנאי השימוש ימשיכו לחול לאחר סיומו של הסכם זה, מכל סיבה שהיא.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 21.7. החברה רשאית להודיע ו/או לעדכן אותך בנושאים שתמצא לנכון באופן המפורט לעיל וככל שלא פורט באופן דווקני, באמצעות הדואר האלקטרוני שלך.&nbsp;</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 21.8. המשתמש אינו רשאי להעביר ו/או להמחות את זכויותיו לאחר.</p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; 21.9. החברה רשאית להעביר ו/או להמחות את חלק ו/או מלוא זכויותיה ו/או חובותיה לכל צד ג&apos; בהתאם לשיקול דעתה הבלעדי.</p>
      <p><br></p>
      <p><br></p>
      <p>&nbsp; &nbsp; 22. פרטי התקשרות עם החברה</p>
      <p style="padding: 0 40px">שם החברה: &nbsp;גט אין בע&quot;מ</p>
      <p style="padding: 0 40px">מען למכתבים: &nbsp;מויאל אהרון 10 בתל אביב</p>
      <p style="padding: 0 40px">דוא״ל: <a class="c20" data-fr-linked="true" href="mailto:support@get-in.com">support@get-in.com</a></p>
      <p><br></p>
      <p>&nbsp; &nbsp;  תנאי שימוש אלה תקפים ליום ה: 09/11/22</p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
      <p><br></p>
    </div>
</div>
