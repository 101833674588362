<div class="cats-filters">
  <div class="cat-sidenav">
    <app-category-sidenav [active]="active"></app-category-sidenav>
    <div class="event-filters" *ngIf="!isMobile">
      <app-autocomplete-filter-events class="event-filter" [ngClass]="direction"></app-autocomplete-filter-events>
      <app-select-filter-events></app-select-filter-events>
      <app-datepicker-filter-events></app-datepicker-filter-events>
    </div>
  </div>
</div>
<div class="event-filters" *ngIf="isMobile">
  <app-autocomplete-filter-events class="event-filter" [ngClass]="direction"></app-autocomplete-filter-events>
  <app-select-filter-events></app-select-filter-events>
  <app-datepicker-filter-events></app-datepicker-filter-events>
</div>
<div class="categories-main">
<!--  <app-category-banner [event]="banner_event" [active]="active" [direction]="direction"></app-category-banner>-->
  <div class="home-carousel">
    <app-home-carousel *ngIf="mainSliderData && mainSliderData.length > 0" [setSliderData]="mainSliderData" [isMobile]="isMobile"></app-home-carousel>
  </div>
  <div id="category-filter_events">
    <app-events-list [events]="filter_events" [name]="'Filtered events'" [isMobile]="isMobile" *ngIf="filter_events && filter_events.length"></app-events-list>
  </div>
<!--  <app-events-list [events]="trending" [name]="'trending'" [isMobile]="isMobile" *ngIf="trending && trending.length"></app-events-list>-->
  <app-events-list id="category-{{active}}" [type]="'type-5'" [events]="explore" [name]="activeName" [isMobile]="isMobile" *ngIf="explore && explore.length"></app-events-list>
  <h2 *ngIf="explore && !explore.length" class="no-events" i18n="categories|no-events">No events in selected category</h2>

</div>
