import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-follow-button',
  templateUrl: './follow-button.component.html',
  styleUrls: ['./follow-button.component.css']
})
export class FollowButtonComponent implements OnInit, AfterViewInit {
  @Input() producerId: number;
  @Input() userId: number;
  @Input() isIcon: boolean = false;
  @Input() producerFollowers: any = [];
  @Output() producer: EventEmitter<boolean> = new EventEmitter();
  @Output() login: EventEmitter<boolean> = new EventEmitter();
  @Output() followAction: EventEmitter<any> = new EventEmitter();

  isFollowingProducer = false;
  disableFollowButton = false;

  constructor(
    public authService: AuthService,
    private dialogService: DialogService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.setIsFollowingProducer();
  }

  ngAfterViewInit() {
    // this.setIsFollowingProducer();
  }

  editProducer() {
    this.producer.emit(true);
  }

  updateFollowProducer(action) {
    if (!this.userId) {
      this.userService.openLoginModal();
      return;
    }
    if (this.userId === this.producerId) {
      return;
    }
    if ((!this.isFollowingProducer && action === 'follow') || (this.isFollowingProducer && action === 'unfollow')) {
      this.disableFollowButton = true;
      this.userService.updateFollowProducer({userId: this.userId, producerId: this.producerId, action: action}).subscribe(
        (response: any) => {
          this.disableFollowButton = false;
          if(action === 'follow') {
            this.openSnackBar();
            this.isFollowingProducer = true;
            this.followAction.emit({action: action, userId: this.userId, producerId: this.producerId }) // follow
          } else {
            this.isFollowingProducer = false;
            this.followAction.emit({action: action, userId: this.userId, producerId: this.producerId })// unfollow
          }
        }, error => {
          this.disableFollowButton = false;
          this.dialogService.alertsModal('errors', error.code, 3000, false);
        }
      );
    }
  }

  setIsFollowingProducer () {
    // if (!this.userId) {
    //   this.userService.openLoginModal();
    //   console.log('openLoginModal');
    //   // this.login.emit(false);
    //   return;
    // }
    if (this.userId === this.producerId) {
      return;
    }
    const isFollowing = this.producerFollowers.indexOf(this.userId);
    this.isFollowingProducer = isFollowing !== -1;
  }

  openSnackBar() {
    this.dialogService.openSnackBarFromComponent('confirmFollow');
  }

}
