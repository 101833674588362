import { TranslationService } from './../services/translation.service';
import { NavbarService } from '../services/navbar.service';
import { LocalizationService } from './../services/localization.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import {Component, OnInit, EventEmitter, Output, OnDestroy, Input} from '@angular/core';
import { SlideInOutAnimation, slideIn, fade } from '../animation';
import { Bank } from '../interfaces/event-data.interface';
import { BankService } from '../services/bank.service';
import { DialogService } from './../services/dialog.service';
import { abaNumberRegex } from '../regex';
import { GlobalsService } from '../globals';
import { MbscSelectOptions } from '@mobiscroll/angular';
import {EventService} from '../services/event.service';
import {PlaidService} from '../services/plaid.service';

@Component({
  selector: 'app-bank-form-inputs',
  templateUrl: './bank-form-inputs.component.html',
  styleUrls: ['./bank-form-inputs.component.css'],
  animations: [SlideInOutAnimation, slideIn, fade]
})
export class BankFormInputsComponent implements OnInit, OnDestroy {
  localeId;
  @Output() initBankStep = new EventEmitter<any>();
  @Output() updateValueOfBankForm = new EventEmitter<any>();
  @Input() bankData: FormData;
  @Input() event_id: 0;
  @Input() page: ''; // plaid_page: edit-event or bank-account
  initialFormData: any;
  formControlsReady: Boolean = false;
  formDataIsSet: Boolean = false;
  bank_details: UntypedFormGroup;
  bank: UntypedFormGroup;
  usCities: string[] = [];
  usBanks: string[] = [];
  isOnMobile: boolean;
  config = {
    displayKey: 'description',
    search: true,
    height: 'auto',
    placeholder: this.translation.getStringTranslation('select', true),
    moreText: 'more',
    noResultsFound: this.translation.getStringTranslation('noResult', true),
    searchPlaceholder: this.translation.getStringTranslation('search', true),
    searchOnKey: 'name'
  }
  bankCitiesOptions = [];
  localSettings: MbscSelectOptions = {
    display: 'center',
    filter: true,
    theme: this.globals.mobiscrollTheme(),
    lang: this.globals.LOCALE_ID
  };

  countrySettings: MbscSelectOptions = {
    display: 'center',
    filter: false,
    theme: this.globals.mobiscrollTheme(),
    lang: this.globals.LOCALE_ID
  };
  eventData;

  initUSdata: any;
  initESdata: any;
  initILdata: any;
  plaidData: any;
  plaidFetch: boolean = false;
  user_plaid_id = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    public bankService: BankService,
    private dialogService: DialogService,
    private eventService: EventService,
    private plaidService: PlaidService,
    private globals: GlobalsService,
    public localizationService: LocalizationService,
    private nav: NavbarService,
    private translation: TranslationService,
  ) {

  }

  ngOnInit() {
    console.log('init bankData', this.bankData);
    this.saveInitalBankData();
    this.setPageforPlaidRedirrect();

    this.isOnMobile = this.globals.isOnMobile();
    this.localeId = this.globals.getLocaleId();
    this.getInitialBankData().then(formData => {
      console.log('INIT');

      this.plaidService.plaidRedirectSubj.subscribe(res=> {
        console.log('plaidRedirectSubj',res);
        if(res) {
          this.plaidData = res;
          this.initUSdata = res;
          this.initBankForm(res, 'us'); // for plaid
        } else {
          this.initBankForm(formData); // default
        }
      })
    })
  .then( data => {
      if (this.bank_details.controls['bank'].get('country').value === 'us') {
        this.setInitialCountryAndCityForUS();
      }
      this.formControlsReady = true;
      this.initBankStep.emit(this.bank_details.controls['bank']);
      this.updateValueOfBankForm.emit(this.bank_details.controls['bank']);
    });

    // this.plaidService.clearPlaidTokenExchangeSubj();
    // this.plaidService.onSuccessSubj.subscribe((res)=> {
    //   // console.log('onSuccess',res);
    // });
    // this.plaidService.plaidTokenExchangeSubj.subscribe((res)=> {
    //   // console.log('plaidTokenExchange',res);
    //   if(res) {
    //     this.plaidData = res;
    //     this.initUSdata = res;
    //     this.fillPlaidFields(res);
    //   }
    // })


  }

  initBankForm(formData, selectedCountry = '') {
    const country = (selectedCountry) ? selectedCountry : ((formData && formData.country) ? formData.country : '');
    let data;
    // console.log('formData', formData, 'this.bankData', this.bankData, 'this.initUSdata', this.initUSdata)
    // console.log('country', country, this.bankData, formData, this.initUSdata)
    if(country === 'us') {
      data = this.initUSdata;
    } else {
      data = this.bankData ? this.bankData['bank'] : formData
    }

    return new Promise(resolve => {

      this.bank_details = new UntypedFormGroup({
        bank: this.buildForm(data, country)
      });
      console.log('initBankForm bank_details', this.bank_details, country, data)
      this.formDataIsSet = true;
      resolve(true);
    });
  }

  getInitialBankData() {
    return new Promise(resolve => {
      this.bankService.getInitalBankData.subscribe((formData: Bank) => {
        console.log('initial data', formData, this.bankService.availableCountriesForBankList, this.bankService.israeliBankList);
        if (formData) {
          this.initialFormData = formData;
        }
        resolve(formData);
      });
    })
  }

  buildForm(data, country) {
    let defaultCountryVal = '';
    if(data && data.country) {
      defaultCountryVal = data.country;
    } else if(country) {
      defaultCountryVal = country;
    } else {
      const IPdata = this.localizationService.getIpInfo();
      if(IPdata.country_code !== undefined) {
        if(IPdata.country_code === 'IL') {
          defaultCountryVal = 'il';
        } else if(IPdata.country_code === 'ES') {
          defaultCountryVal = 'es';
        } else {
          defaultCountryVal = 'us';
        }
      }
    }
    if(((country && country === 'il') || (defaultCountryVal && defaultCountryVal === 'il')) || ((country && country === 'es') || (defaultCountryVal && defaultCountryVal === 'es'))) {
      return this.formBuilder.group({
        id: (data && data.id !== undefined) ? data.id : undefined,
        country: new UntypedFormControl(defaultCountryVal, {updateOn: 'change', validators: Validators.required}),
        bank_id: this.formBuilder.control((data && data.bank_id !== null) ? data.bank_id : '', Validators.required),
        branch: this.formBuilder.control((data && data.branch !== undefined) ? data.branch : '', Validators.required),
        account_number: this.formBuilder.control((data && data.account_number !== undefined) ? data.account_number : '', Validators.required),
        account_name: this.formBuilder.control((data && data.account_name !== undefined) ? data.account_name : '', [Validators.maxLength(50), Validators.required]),
        company_user_id: this.formBuilder.control((data && data.company_user_id !== undefined) ? data.company_user_id : '', [Validators.min(1000000), Validators.max(9999999999), Validators.required])
      });

    } else if((country && country === 'us') || (defaultCountryVal && defaultCountryVal === 'us')) {
      if(data && data.recipient_phone && data.recipient_phone.match(/^0[\d]{1,2}[-]?[\d]{3}[-]?[\d]{4}$/)) {
        if(data.recipient_phone.charAt(0) === '0') {
          data.recipient_phone = data.recipient_phone.substr(1);
        }
        data.recipient_phone = '+972' + data.recipient_phone;
      }

      let city = '';
      if(data && data.city) {
        city = data.city
      } else if (data && data.addresses && data.addresses.city) {
        city = data.addresses.city
      }
      let address = '';
      if(data && data.address) {
        address = data.address
      } else if (data && data.addresses && data.addresses.region && data.addresses.street && data.addresses.postal_code) {
        address = `${data.addresses.region},${data.addresses.street},${data.addresses.postal_code}`
      }
      let account_name = '';
      if(data && data.account_name) {
        account_name = data.account_name
      } else if (data && data.names) {
        account_name = data.names
      }
      let recipient_phone = '';
      if(data && data.recipient_phone) {
        recipient_phone = data.recipient_phone
      } else if (data && data.phone_numbers) {
        recipient_phone = data.phone_numbers
      }
      let user_plaid_id = localStorage.getItem('user_plaid_id');
      if(data && data.user_plaid_id && data.user_plaid_id !== null) {
        user_plaid_id = data.user_plaid_id
      } else {
        user_plaid_id = this.user_plaid_id;
      }

      return this.formBuilder.group({
        id: (data && data.id !== undefined) ? data.id : undefined,
        country: new UntypedFormControl(defaultCountryVal,{updateOn: 'change', validators: Validators.required}),
        city: new UntypedFormControl(city, {updateOn: 'change', validators: Validators.required}),
        bank_name: new UntypedFormControl((data && data.bank_name !== undefined) ? data.bank_name : '', {updateOn: 'change', validators: Validators.required}),
        address: this.formBuilder.control(address, Validators.required),
        recipient_phone: this.formBuilder.control(recipient_phone, [Validators.required]),
        phone_country_code: (data && data.phone_country_code !== undefined) ? data.phone_country_code : '',
        account_number: this.formBuilder.control((data && data.account_number !== undefined) ? data.account_number : '', Validators.required),
        account_name: this.formBuilder.control(account_name, [Validators.maxLength(50), Validators.required]),
        aba_number: this.formBuilder.control((data && data.aba_number !== undefined) ? data.aba_number : '', [Validators.required, Validators.pattern(abaNumberRegex)]),
        user_plaid_id: user_plaid_id,
      });
    } else {
      return this.formBuilder.group({
        id: (data && data.id !== undefined) ? data.id : undefined,
        country: new UntypedFormControl(defaultCountryVal,{updateOn: 'change', validators: Validators.required}),
        other_bank_details: this.formBuilder.control((data && data.other_bank_details !== undefined) ? data.other_bank_details : '', [Validators.required])
      });
    }
  }

  filterUSCities(country, currentCityValue?: string) {
   // console.log('filterUSCities', country, 'bank', this.bank_details.get('bank'), 'country', this.bank_details.controls['bank'], 'bankdata', this.bankData, 'this.initUSdata', this.initUSdata);

    const data = (this.initialFormData && this.initialFormData.country === country) ? this.initialFormData : ((this.initialFormData && this.initialFormData.id) ? {id: this.initialFormData.id} : '');
    const city = this.bankData ? this.bankData['bank']['city'] : undefined;

    this.initBankForm(this.bankData ? this.bankData['bank'] : data, country).then(() => {
      this.updateValueOfBankForm.emit(this.bank_details.controls['bank']);
    });

    // if(this.initUSdata && country === 'us') { // do no clear field for us
    //   this.bankData = this.initUSdata;
    // } else if(this.initILdata && country === 'il') {
    //   this.bankData = this.initILdata;
    // } else if(this.initESdata && country === 'es') {
    //   this.bankData = this.initESdata;
    // } else {
    //   this.bankData = undefined;
    // }
    this.bankData = undefined;

    if(country === 'us' && this.usCities.length === 0) {
      console.log('this.plaidData', this.plaidData)
      // this.fillPlaidFields(this.plaidData); // new US logic
      this.globals.startLoader();
      this.bankService.getUsCities().subscribe(
        (response: any) => {
          this.usCities = this.formatUsCitiesResponse(response);
          this.bankCitiesOptions = this.usCities;
          // console.log('bankCitiesOptions', this.usCities);
          if(currentCityValue) {
            this.bank_details.controls['bank'].get('city').patchValue(currentCityValue);
          }
          if(city) {
            this.bank_details.controls['bank'].get('city').patchValue(city);
          }
          this.globals.stopLoader();
        },
        error => {this.dialogService.alertsModal('bankService', 1, 3000, false)}
      );
    }
  }

  formatUsCitiesResponse(usCitiesObj) {
    usCitiesObj = usCitiesObj.data.map(record => record.data.CITY);
    const usCities = usCitiesObj.filter((city, i) => usCitiesObj.indexOf(city) === i);
    usCities.sort();
    if(this.isOnMobile) {
      const usCitiesArrTemp = [];
      for (let i = 0; i < usCities.length; i++) {
        usCitiesArrTemp.push({'text': usCities[i], 'value': usCities[i]});
      }
      return usCitiesArrTemp;
    }
    return usCities;
  }
  filterUSBanksByCity(city, setDefaultBankName?: string){
    if(city){
      this.globals.startLoader();
      this.bankService.getUsBanksByCity(city).subscribe(
        usBanks => {
          this.usBanks = usBanks;
          if(setDefaultBankName) {
            this.bank_details.controls['bank'].get('bank_name').patchValue(setDefaultBankName);
          }
          this.globals.stopLoader();
        },
        error => {this.dialogService.alertsModal('bankService',1,3000,false);this.globals.stopLoader();}
      );
    }
  }

  setInitialCountryAndCityForUS(){
    this.filterUSCities(this.bank_details.controls['bank'].get('country').value, ((this.initialFormData) ? this.initialFormData.city : ''));
    if(this.bank_details.controls['bank'].get('country').value === 'us') {
      this.filterUSBanksByCity(this.bank_details.controls['bank'].get('city').value, ((this.initialFormData) ? this.initialFormData.bank_name : ''));
    }
  }

  setErrorOnphoneNumber(hasErrorfromNg2TelInput) {
    const hasError = this.globals.hasErrorOnPhoneNumber(hasErrorfromNg2TelInput, this.bank_details.controls['bank'].get('recipient_phone').value);
    let actionOnSetErrors = hasError ? {'invalid': true} : null;
    this.bank_details.controls['bank'].get('recipient_phone').setErrors(actionOnSetErrors);
  }
  setInitialPhoneNumber(phoneNumber) {
    this.bank_details.controls['bank'].get('recipient_phone').patchValue(phoneNumber);
  }

  telInputObject(obj) {
    if(this.bank_details.controls['bank'].get('recipient_phone').value){
      obj.setNumber(this.bank_details.controls['bank'].get('recipient_phone').value);
    }
    this.bank_details.controls['bank'].get('phone_country_code').patchValue(obj.s.iso2);
  }
  onCountryChange(obj) {
    this.bank_details.controls['bank'].get('phone_country_code').patchValue(obj.iso2);
  }

  setBankFromDraft($event) {

  }
  async connectPlaid() {
    this.plaidFetch = true;
    this.eventService.plaidTokensLink().subscribe(async (res: any) => {
      console.log(res, this.event_id);
      if(res.data && res.data.token) {
        localStorage.setItem('link_token', JSON.stringify(res.data.token));
        if(res.data.user_plaid_id) {
          localStorage.setItem('user_plaid_id', JSON.stringify(res.data.user_plaid_id));
          this.user_plaid_id = res.data.user_plaid_id;
        }

        const handler = await this.plaidService.create(res.data.token, null,false);
        console.log(handler);
        handler.open();
        this.plaidFetch = false;
      }

    })
  }
  disconnectPlaid() {
    const user_plaid_id = this.bank_details.controls['bank'].get('user_plaid_id').value;

    if(user_plaid_id && this.event_id) {
      this.globals.startLoader();
      this.eventService.plaidDelete(user_plaid_id).subscribe((res:any)=> {
        if(res.code === 'success') {
          console.log(res.code);
          this.user_plaid_id = '';
          this.initUSdata = null;
          localStorage.removeItem('user_plaid_id');
          this.bank_details.reset();
          this.plaidService.clearPlaidTokenExchangeSubj();
          // this.getInitialBankData().then(formData => {
          //   this.initBankForm(formData)
          // })
        }
        this.globals.stopLoader();
      })
    }
  }
  fillPlaidFields(res) {
    // console.log('fillPlaidFields', res, this.bank_details)
    if(!res || !this.bank_details || !this.bank_details.controls['bank']) {return}

    const user_plaid_id = localStorage.getItem('user_plaid_id');
    if(res && this.bank_details && this.bank_details.controls['bank']) {
      if(user_plaid_id) {
        this.bank_details.controls['bank'].get('user_plaid_id').patchValue(user_plaid_id);
      }
      if (res.user_plaid_id) {
        this.bank_details.controls['bank'].get('user_plaid_id').patchValue(res.user_plaid_id);
      }
      if (res.addresses && res.addresses.city) {
        this.bank_details.controls['bank'].get('city').patchValue(res.addresses.city);
      }
      if (res.bank_name) {
        this.bank_details.controls['bank'].get('bank_name').patchValue(res.bank_name);
      }
      if (res.addresses && res.addresses.region) {
        this.bank_details.controls['bank'].get('address').patchValue(`${res.addresses.region},${res.addresses.street},${res.addresses.postal_code}`);
      }
      if (res.names) {
        this.bank_details.controls['bank'].get('account_name').patchValue(res.names);
      }
      if (res.phone_numbers) {
        this.bank_details.controls['bank'].get('recipient_phone').patchValue(res.phone_numbers);
      }

      // iterate over other fields
      for (const property in res) {
        // console.log(`${property}: ${res[property]}`);
        if(this.bank_details.controls['bank'] && this.bank_details.controls['bank'].get(property)) {
          this.bank_details.controls['bank'].get(property).patchValue(res[property]);
        }
      }
      this.updateValueOfBankForm.emit(this.bank_details.controls['bank']);
    }
  }
  saveInitalBankData() {

    if (this.bankData && this.bankData['bank']['country'] == 'us') {
      this.initUSdata = this.bankData['bank'];
    } else if (this.bankData && this.bankData['bank']['country'] == 'es') {
      this.initESdata = this.bankData['bank'];
    } if (this.bankData && this.bankData['bank']['country'] == 'il') {
      this.initILdata = this.bankData['bank'];
    }

    if (this.bankData && this.bankData['eventStatus'] == 2) {
      this.initUSdata = null;
      this.initESdata = null;
      this.initILdata = null;
    }

    if(this.bankData && this.bankData['bank'].hasOwnProperty('user_plaid_id')) {
      if (this.bankData['bank']['user_plaid_id'] == null) {
        this.user_plaid_id = '';
        localStorage.removeItem('user_plaid_id');
      } else {
        this.user_plaid_id = this.bankData['bank']['user_plaid_id'];
        localStorage.setItem('user_plaid_id', JSON.stringify(this.user_plaid_id));
      }

    }
  }

  setPageforPlaidRedirrect() {
    localStorage.setItem('plaid_page', JSON.stringify(this.page));
    localStorage.setItem('plaid_event_id', JSON.stringify(this.event_id));
  }
  ngOnDestroy() {
    this.plaidService.clearPlaidTokenExchangeSubj();
    this.plaidService.clearPlaidRedirectSubj();
  }
}
