<div class="passcode-modal-header">
  <button class="close-btn" mat-dialog-close [disabled]="retryTime > 0">
    <svg class="arrow-close-modal" width="10" height="14" viewBox="0 0 10 14" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M9.49219 1.64063L4.13281 7L9.49219 12.3594L7.85156 14L0.851562 7L7.85156 0L9.49219 1.64063Z"
            fill="white" />
    </svg>
  </button>
  <p class="passcode-title">Private Sale</p>
</div>
<div class="passcode-modal-body" mat-dialog-content>
  <label for="purchase_passcode">🔐 Enter your code to access and select your tickets.</label>
  <div class="passcode-box">
    <input class="passcode" id="purchase_passcode" maxlength="50" placeholder="Enter promo code" [type]="show ? 'text' : 'password'" [(ngModel)]="purchase_passcode" />
    <button (click)="password()">
      <img [ngStyle]="{'filter': show?'contrast(1)':'contrast(0)'}" src="/assets/images/svg-icons/eye.svg">
    </button>
  </div>
  <button [disabled]="retryTime > 0" (click)="sendPasscode()" class="passcode-submit">
    <span>UNLOCK TICKETS</span>
    <span *ngIf="retryTime"> (retry in {{retryTime}}) </span>
  </button>
</div>
