import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-facebook-declined-permissions',
  templateUrl: './facebook-declined-permissions.component.html',
  styleUrls: ['./facebook-declined-permissions.component.css']
})
export class FacebookDeclinedPermissionsComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit() {
  }

  onClose(reAskForPermissions: boolean) {
    this.dialogRef.close(reAskForPermissions);
  }

}
