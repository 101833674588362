import {Component, ElementRef, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {EventService} from "../../services/event.service";
import {DialogService} from "../../services/dialog.service";
declare var Stripe;
@Component({
  selector: 'app-stripe-return-url',
  templateUrl: './stripe-return-url.component.html'
})
export class StripeReturnUrlComponent implements OnInit{
  main_purchase_identifier: string = '';
  flow: 'separate_payments'| 'future_payments' = 'separate_payments';
  constructor(private activatedRoute: ActivatedRoute,
              private eventService: EventService,
              private router: Router,
              private dialogService: DialogService) { }

  async ngOnInit() {
    if (this.router.url.includes('/stripe-future-payment/')) {
      this.flow = 'future_payments'
    }
    this.activatedRoute.params.subscribe(params => {
      this.main_purchase_identifier = params['main_purchase_identifier'];
      const payment_intent = new URLSearchParams(window.location.search).get('payment_intent');
      const payment_intent_client_secret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

      const setup_intent_client_secret = new URLSearchParams(window.location.search).get('setup_intent_client_secret');

      console.log('Get main_purchase_identifier', this.main_purchase_identifier, params);
      console.log('Get payment_intent', payment_intent);
      console.log('Get payment_intent_client_secret', payment_intent_client_secret);

      if (!payment_intent_client_secret && this.flow == 'separate_payments' ) {
        return;
      }
      if(!setup_intent_client_secret && this.flow == 'future_payments') {
        return;
      }

      const data = {
        main_purchase_identifier: this.main_purchase_identifier,
        payment_intent_client_secret: payment_intent_client_secret,
        setup_intent_client_secret: setup_intent_client_secret
      }

      this.eventService.stripePaymentResponse(data, this.flow).subscribe((res: any) => {
        console.log('stripePaymentResponse', res);
        if (res.data && res.data.url) {
          this.router.navigateByUrl(res.data.url);
        }
      }, error => {
        console.log('stripePaymentError', error);
        this.dialogService.alertsModal('errors', error.code, 5000, false, error.log_id);
        this.router.navigate(['/dashboard']);
      });



      // // Retrieve the PaymentIntent
      // Stripe.retrievePaymentIntent(payment_intent_client_secret).then(({paymentIntent}) => {
      //   const message  = document.querySelector('#message') as HTMLElement;
      //
      //   // Inspect the PaymentIntent `status` to indicate the status of the payment
      //   // to your customer.
      //   //
      //   // Some payment methods will [immediately succeed or fail][0] upon
      //   // confirmation, while others will first enter a `processing` state.
      //   //
      //   // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
      //   switch (paymentIntent.status) {
      //     case 'succeeded':
      //       message.innerText = 'Success! Payment received.';
      //       break;
      //
      //     case 'processing':
      //       message.innerText = "Payment processing. We'll update you when payment is received.";
      //       break;
      //
      //     case 'requires_payment_method':
      //       message.innerText = 'Payment failed. Please try another payment method.';
      //       // Redirect your user back to your payment page to attempt collecting
      //       // payment again
      //       break;
      //
      //     default:
      //       message.innerText = 'Something went wrong.';
      //       break;
      //   }
      // });

    });
  }

}
