import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import spain_banks from '../static/spain-bank-list';
import israel_banks from '../static/israel-bank-list';

@Injectable({
  providedIn: 'root'
})
export class BankService {
  availableCountriesForBankList = [
    {
      name: 'Israel',
      value: 'il'
    },
    {
      name: 'United States',
      value: 'us'
    },
    {
      name: 'Spain',
      value: 'es'
    },
    {
      name: 'France',
      value: 'fr',
    },
    {
      name: 'Other',
      value: 'other'
    }
  ];

  availableBankInputs = {
    il: ['id','country','bank_id','branch','account_number','account_name','company_user_id'],
    us: ['id','country','city','bank_name','address','producer_phone','recipient_phone','phone_country_code','account_name','account_number','aba_number'],
    sp: ['id','country','city','bank_name','address','producer_phone','recipient_phone','phone_country_code','account_name','account_number','aba_number'],
    fr: ['id','country','city','bank_name','address','producer_phone','recipient_phone','phone_country_code','account_name','account_number','aba_number'],
    other: ['country','other_bank_details']
  }
  // Spain Banks
  spainBankList = spain_banks;
  // Israel Banks
  israeliBankList = israel_banks;


  private initialBankData = new BehaviorSubject({});
  getInitalBankData = this.initialBankData.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  getUsCities() {
    const url = encodeURI('https://banks.data.fdic.gov/api/institutions?filters=ACTIVE:1&fields=CITY&sort_by=OFFICES&sort_order=DESC&limit=10000&offset=0&format=json&download=false&filename=data_file');
    return this.http.get(url,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }

  getUsBanksByCity(city: string) {
    const url = encodeURI('https://banks.data.fdic.gov/api/institutions?filters=ACTIVE:1 AND CITY:"'+city+'"&fields=CITY,COUNTY,NAME,STNAME,OFFNUM&sort_by=OFFICES&sort_order=ASC&limit=10000&offset=0&format=json&download=false&filename=data_file');
    return this.http.get(url,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})}).pipe(
      map((response: any) => {
        return response.data.map(record => record.data);
      }));
  }

  setinitalBankData(data: any) {
    this.initialBankData.next(data);
  }

  getCurrencycodes(){
    return this.http.get(environment.api_base_url+'/api/get-currency-codes',
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }

  getBankInfo() {
    return this.http.get(environment.api_base_url+'/api/finance/bank',
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }

  createBank(data) {
    return this.http.post(environment.api_base_url + '/api/finance/bank' , data, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  editBank(bank_id, data) {
    return this.http.patch(environment.api_base_url + `/api/finance/bank/${bank_id}` , data, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

}
