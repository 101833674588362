
import {combineLatest as observableCombineLatest,  Observable } from 'rxjs';

import { UpdatePasswordByAdminComponent } from './../../shared-dialogs/update-password-by-admin/update-password-by-admin.component';
import { MatDialog } from '@angular/material/dialog';
import { UpdateUserModalComponent } from './../../shared-dialogs/update-user-modal/update-user-modal.component';
import { CmsUsersService } from '../../services/cms-users.service';
import { DialogService } from '../../services/dialog.service';
import { Component, OnInit, OnDestroy} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalsService } from '../../globals';
import { NavbarService } from '../../services/navbar.service';
import { NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import {
  SvixSettingsComponent
} from "../cms-svix-settings/svix-settings.component";
import {AuthService} from "../../services/auth.service";
import {AuthCacheService} from "../../services-cache/auth-cache.service";
@Component({
  selector: 'app-cms-users',
  templateUrl: './cms-users.component.html',
  styleUrls: ['./cms-users.component.css']
})
export class CmsUsersComponent implements OnInit, OnDestroy {

	loader = false;
	filter_type: number;
	filter_type_name: string;
	users: any;
	page = 1;
	total: number;
	pageSize = 50;
	filterText;
	fromFilterUsers = false;
	salesmen_type = [
		{type: 0},
		{type: 1},
		{type: 2},
		{type: 3},
	];
	filter_type_code = [
		{code: 1, name: 'producers'},
		{code: 2, name: 'salesmen'},
		{code: 3, name: 'active'},
		{code: 4, name: 'inactive'},
	];
	activeFilterString: string;

	constructor(
		private router: Router,
		private globals: GlobalsService,
		private activatedRoute: ActivatedRoute,
		private cmsUsersService: CmsUsersService,
		private dialogService: DialogService,
		private dialog: MatDialog,
		private location: Location,
		private nav: NavbarService,
    private authService: AuthService,
    private authCacheService: AuthCacheService
	) {
		this.nav.customWrapperClass = 'full-screen';
	}

	ngOnInit() {
		if (this.activatedRoute.params) {
			const obsComb = observableCombineLatest(this.activatedRoute.params, this.activatedRoute.queryParams,
				(params, qparams) => ({ params, qparams }));

		obsComb.subscribe( ap => {
					this.filterText = ap.qparams['s'] ? ap.qparams['s'] : '';
					this.filter_type_name = ap.params['filter_type'];
					const filter_type_index = this.filter_type_code.findIndex((obj: any) => {if (obj) { return obj.name === this.filter_type_name}});
					if (ap.qparams['s'] || !this.activeFilterString || this.activeFilterString !== this.filter_type_name || (!ap.qparams['s'] &&  this.activeFilterString === this.filter_type_name)) {
						this.activeFilterString = this.filter_type_name;
						this.filter_type = filter_type_index !== -1 ? this.filter_type_code[filter_type_index].code : 1;
						this.getCmsUsers(1, this.filter_type, this.filterText ? true : false);
					}
		});
		}
	}

	filter_users(filter_status) {
		this.fromFilterUsers = true;
		this.filterText = '';
		const filter_type_index = this.filter_type_code.findIndex((obj: any) => {if (obj) { return obj.code === +filter_status}});
		this.filter_type_name = this.filter_type_code[filter_type_index].name;
		this.filter_type = this.filter_type_code[filter_type_index].code;
		this.router.navigate(['cms/users', this.filter_type_name]);
	}

	getCmsUsers (pageNumber, filter_status, searchMode = false, fromPagination = false) {
    this.globals.startLoader();
			const perPage = this.pageSize;
			const start = (pageNumber - 1) * perPage;
			const end = start + perPage;
			this.loader = true;
			if (this.filterText) {
				searchMode = true;
			}
			this.cmsUsersService.getCmsUsers(filter_status, start, end, searchMode ? this.filterText.trim() : false).subscribe(
			(response: any) => {
        this.globals.stopLoader();
				this.loader = false;
				if (searchMode) {
					this.router.navigate(['cms/users', this.filter_type_name], { queryParams: { s: this.filterText}});
				} else if (!fromPagination && !this.fromFilterUsers) {
					this.fromFilterUsers = false;
					const filter_type_index = this.filter_type_code.findIndex((obj: any) => {if (obj) { return obj.code === +filter_status}});
					this.router.navigate(['cms/users', this.filter_type_code[filter_type_index].name], { replaceUrl: true});
				}
				this.total = response.data.users.total;
				this.users = response.data.users.users;
				this.page = pageNumber;
			},
			error => {
				this.loader = false;
			});
		}

	updateUserStatus (user_id, new_status) {
		const text = (new_status === 0) ? 'deleteUser' : 'restoreUser';
		this.dialogService.areUSureModal(text).subscribe(
			result => {
					if (result) {
						this.globals.startLoader();
						this.cmsUsersService.updateUserStatus(user_id, new_status).subscribe(
							(response: any) => {
								this.globals.stopLoader();
								this.dialogService.alertsModal('success', response.code, 2000, true);
								this.getCmsUsers(this.page, this.filter_type);
							},
							error => {
								this.globals.stopLoader();
								this.dialogService.alertsModal((error.code) ? 'cms' : 'errors', (error.code) ? error.code : 4 , 5000, false, error.log_id);
							}
						);
							}
				}
			);
	}

	updateUserDetails (user_id) {
		this.globals.startLoader();
		this.cmsUsersService.getById(user_id).subscribe(
			(response: any) => {
				this.globals.stopLoader();
				const updateUserModal = this.dialog.open(UpdateUserModalComponent, {
					data: response,
					disableClose: true,
					autoFocus: false,
					panelClass: 'updateUserModal'
			});
			updateUserModal.afterClosed().subscribe(
					result => {
						if (result !== undefined && result.success) {
							this.getCmsUsers(this.page, this.filter_type);
						}
					}
				);
			},
			error => {
				this.globals.stopLoader();
				this.dialogService.alertsModal((error.code) ? 'cms' : 'errors', (error.code) ? error.code : 4 , 5000, false, error.log_id);
			}
		);
	}

	updateUserPassword (user_id) {
		if (user_id && user_id !== 0) {
			this.dialog.open(UpdatePasswordByAdminComponent, {
				data: {user_id: user_id},
				disableClose: true,
				autoFocus: false,
				panelClass: 'updateUserPasswordModal'
			});
		} else {
			this.dialogService.alertsModal('cms', 20, 5000, false);
		}
	}

  showSvixModal(user_id) {
    if (user_id && user_id !== 0) {
      this.dialog.open(SvixSettingsComponent, {
        data: {user_id: user_id, cmsPage: true},
        disableClose: false,
        height: '100vh',
        width: '100vw',
        maxWidth: '100vw',
        maxHeight: '100vh',
        autoFocus: false,
        panelClass: ''
      });
    }
  }

  impersonation(user_id) {
    this.globals.startLoader();
    this.cmsUsersService.impersonationAsUser(user_id).subscribe((response: any)=> {
      if(response && response.original && response.original?.success == 'letsGoHome') {
        if (response.auth.session && response.auth.refresh) {
          this.authService.storeTokens(response.auth.session, response.auth.refresh)
        }
        this.authService.saveUserCookies(response.user_data, response.original.facebook_id);
        this.authCacheService.clearCache();
        this.nav.checkUser();
        if (response.original.uid) {
          this.nav.UpdateUser(parseInt(response.original.uid), response.user_data.type, response.original.facebook_id, false)
        }
        this.globals.stopLoader();
        this.router.navigate(['/']).then(()=> {
          this.dialogService.alertsModal('success', 'readerLogin', 3000, true);
        })
      } else {
        this.globals.stopLoader();
        this.dialogService.alertsModal('errors', 53, 2000, false);
      }
    }, error => {
      this.globals.stopLoader();
      this.dialogService.alertsModal('errors', 53, 2000, false);
    })
  }

	searchUser () {
		if (this.filterText === '') {
			this.clearSearch();
			return;
		}
		this.getCmsUsers(1, this.filter_type, true, false);
	}

	clearSearch () {
		this.filterText = '';
		this.location.replaceState(this.location.path().split('?')[0], '');
		this.getCmsUsers(1, this.filter_type);
	}

	ngOnDestroy() {
		this.nav.customWrapperClass = '';
	}
}
