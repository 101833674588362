import { LocalizationService } from './services/localization.service';
import { DateTimeFormatFilter } from './pipes/dateTimeFormatFilter.pipe';
import { SoundCloudUrlDialogComponent } from './create-edit-event/create-edit-event-dialogs/sound-cloud-url-dialog/sound-cloud-url-dialog.component';
// import { SalesmanPipe } from './pipes/salesmans.pipe';
import { FacebookService } from './services/facebook.service';
import { MbscModule } from '@mobiscroll/angular';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, APP_INITIALIZER, NgModule, ErrorHandler } from '@angular/core';
import { Router } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { SigninComponent } from './login/signin/signin.component';
import { AuthService } from './services/auth.service';
import { EventService } from './services/event.service';

import { TermsComponent } from './terms/terms.component';
// import { CookieService, CookieOptions} from 'angular2-cookie/core';
import { CookieService} from 'ngx-cookie-service';
// import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CreateEditEventComponent } from './create-edit-event/create-edit-event.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CmsDashboardComponent } from './cms/cms-dashboard/cms-dashboard.component';
// import { FilterArrayPipe } from './pipes/filter.pipe';
import { LimitStr } from './pipes/limitStr.pipe';
import { SalesmenPipe } from './pipes/salesmenFilter.pipe';
import { CmsEventsComponent } from './cms/cms-events/cms-events.component';
import { CmsUsersComponent } from './cms/cms-users/cms-users.component';
import { CmsEditUserComponent } from './cms/sub-pages/cms-edit-user/cms-edit-user.component';
// import { EventSalesmenComponent } from './dashboard-sub-pages/event-salesmen/event-salesmen.component';
// import { EventParticipantsComponent } from './dashboard-sub-pages/event-participants/event-participants.component';
import { EventPaymentComponent } from './dashboard-sub-pages/event-payment/event-payment.component';
// import { PersonalInfoComponent } from './personal-info/personal-info.component';
import { ReaderParticipantsComponent } from './reader-participants/reader-participants.component';
import { SuccessfulOrderComponent } from './landing-page/successful-order/successful-order.component';
import { TicketTemplatesComponent } from './landing-page/ticket-templates/ticket-templates.component';
import { PendingEventComponent } from './create-edit-event/pending-event/pending-event.component';
// import { AccountSettingsComponent } from './account-setings/account-setings.component';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeHe from '@angular/common/locales/he';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeEs_PE from '@angular/common/locales/es-PE';
import localeAr_MA from '@angular/common/locales/ar-MA';
import localeAr_AE from '@angular/common/locales/ar-AE';
import localeTh from '@angular/common/locales/th';
import localePl from '@angular/common/locales/pl';
import { GlobalsService } from './globals';
import { NavbarService } from './services/navbar.service';
import { JWTInterceptor } from './JWTInterceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CmsPaymentFormComponent } from './cms/cms-payment-form/cms-payment-form.component';
import { CmsWebHooksComponent } from './cms/cms-web-hooks/cms-web-hooks.component';
import { CmsEventsTableComponent } from './cms/cms-events-table/cms-events-table.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportsService } from './services/reports.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from "@angular/material/table";
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from "@angular/material/tabs";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PersonalInfoModalComponent } from './personal-info/personal-info-modal/personal-info-modal.component';
import { PhoneVerificationDialog } from './personal-info/phone-verification-dialog/phone-verification-dialog';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './login/signup/signup.component';
import { DialogService } from './services/dialog.service';
// import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PurchaseModalComponent } from './landing-page/purchase-modal/purchase-modal.component';
import { FormInputsComponent } from './form-inputs/form-inputs.component';
import { ErrorHandlingComponent } from './form-inputs/error-handling/error-handling.component';
// import { HtmlPipe } from './pipes/trust-html.pipe';
// import { ModalModule } from 'ngx-bootstrap/modal';
import { ApproveEventModalComponent } from './cms/modals/approve-event-modal/approve-event-modal.component';
import { ValidationMinDirective } from './directives/validation-min.directive';
import { ValidationMaxDirective } from './directives/validation-max.directive';
// import { LazyLoadImageModule,  LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import { LazyLoadImagesModule } from 'ngx-lazy-load-images';
import { EventDetailsModalComponent } from './shared-dialogs/event-details-modal/event-details-modal.component';
import { AreUSureModalComponent } from './shared-dialogs/are-u-sure-modal/are-u-sure-modal.component';
import { RestoreEventComponent } from './cms/modals/restore-event/restore-event.component';
import { QuickLinksModalComponent } from './cms/modals/quick-links-modal/quick-links-modal.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ErrorCodesService } from './code-strings/error_codes';
import { SuccessCodesService } from './code-strings/success_codes';
import { UserAlertsModalComponent } from './shared-dialogs/user-alerts-modal/user-alerts-modal.component';
import { ConfirmWebhookParamsComponent } from './cms/modals/confirm-webhook-params/confirm-webhook-params.component';
import { WebHooksFormComponent } from './cms/cms-web-hooks/web-hooks-form/web-hooks-form.component';
import { SalesmanStatsBreakdownComponent } from './shared-dialogs/salesman-stats-breakdown/salesman-stats-breakdown.component';
import { InputTrimModule } from 'ng2-trim-directive';
import { LoaderComponent } from './loader/loader.component';
import { UpdateUserModalComponent } from './shared-dialogs/update-user-modal/update-user-modal.component';
import { UpdatePasswordByAdminComponent } from './shared-dialogs/update-password-by-admin/update-password-by-admin.component';
import { ReaderSigninComponent } from './reader-signin/reader-signin/reader-signin.component';
import { ReaderSignupComponent } from './reader-signin/reader-signup/reader-signup.component';
import { QuillModule, QUILL_CONFIG_TOKEN } from 'ngx-quill';
import { TermsDialogComponent } from './create-edit-event/create-edit-event-dialogs/terms-dialog/terms-dialog.component';
import { DataSourceDialogComponent } from './create-edit-event/create-edit-event-dialogs/data-source-dialog/data-source-dialog.component';
// import { ErrorCreatorComponent } from './create-edit-event/error-creator/error-creator.component';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { InfoButtonDialogComponent } from './create-edit-event/create-edit-event-dialogs/info-button-dialog/info-button-dialog.component';
import { DateToAgePipe } from './pipes/date-to-age.pipe';
import { NguCarouselModule } from '@ngu/carousel';
import { CmsEventsTemplatesComponent } from './cms/cms-events-templates/cms-events-templates.component';
import { CmsTemplateEditAllowedFieldsComponent } from './cms/cms-template-edit-allowed-fields/cms-template-edit-allowed-fields.component';
import { FacebookPixelModel } from './facebook-pixel-model';
import { ActionPlatformFilter } from './pipes/action-platform-filter.pipe';
import { NotificationService } from './services/notification.service';
import { PhoneVerificationDialogService } from './services/phone-verification-dialog.service';

registerLocaleData(localeHe, 'he');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEs_PE); // For PEN currency symbol bug
registerLocaleData(localeAr_MA); // For MAD currency symbol bug
registerLocaleData(localeAr_AE); // For AED currency symbol bug
registerLocaleData(localeTh); // For THB currency symbol bug
registerLocaleData(localePl); // For PLN currency symbol bug
import { GoogleAutoCompleteDirective } from './directives/google-auto-complete.directive';
import { NgxPaginationModule } from 'ngx-pagination';
import { ClipboardModule } from 'ngx-clipboard';
import { ChangeSellerModalComponent } from './shared-dialogs/change-seller-modal/change-seller-modal.component';
import { ChangeSalesmanPipe } from './pipes/change-salesman.pipe';
import { FacebookDeclinedPermissionsComponent } from './shared-dialogs/facebook-declined-permissions/facebook-declined-permissions.component';
import { BankDetailsModalComponent } from './cms/modals/bank-details-modal/bank-details-modal.component';
import { CreateEventButtonComponent } from './create-event-button/create-event-button.component';
import { FlagsDropdownComponent } from './flags-dropdown/flags-dropdown.component';
// import { TermHeComponent } from './terms/terms-langs/term-he/term-he.component';
// import { TermEnComponent } from './terms/terms-langs/term-en/term-en.component';
// import { TabsModule } from 'ngx-bootstrap/tabs';
// import { PopoverModule} from 'ngx-bootstrap/popover';
// import { CancellationPolicyComponent } from './terms/cancellation-policy/cancellation-policy.component';
import { CreateNewGatewayComponent } from './gateways/create-new-gateway/create-new-gateway.component';
// import { GatewayListComponent } from './gateways/gateways-list/gateways-list.component';
import { UpdateGatewayModalComponent } from './shared-dialogs/update-gateway-modal/update-gateway-modal.component';
import { Ng2TelInputModule} from 'ng2-tel-input';
import { PhoneNumberDirective } from './directives/phone-number.directive';

import { FacebookFriendsBarComponent } from './event/facebook-friends-bar/facebook-friends-bar.component';

import { ProducerFeedComponent } from './producer-feed/producer-feed.component';
import { SnackBarMessageComponent } from './snack-bar-message/snack-bar-message.component';
// import { SmsProvidersListComponent } from './cms/sms-providers/sms-providers-list/sms-providers-list.component';
import { ActiveSmsProviderModalComponent } from './cms/sms-providers/active-sms-provider-modal/active-sms-provider-modal.component';
// import { CancellationHeComponent } from './terms/cancellation-policy/cancellation-langs/cancellation-he/cancellation-he.component';
// import { CancellationEnComponent } from './terms/cancellation-policy/cancellation-langs/cancellation-en/cancellation-en.component';
import { CropImageModalComponent } from './shared-dialogs/crop-image-modal/crop-image-modal.component';
import { FirebaseMessagingService } from './services/firebase.messanging.service';
import { PusherService } from './services/pusher.service';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { AsyncPipe } from '@angular/common';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
// import { UserAvatarComponent } from './personal-info/user-avatar/user-avatar.component';
import { CouponsComponent } from './coupons/coupons.component';
import { SalesPromotionComponent } from './sales-promotion/sales-promotion.component';
// import { ManageMainSliderComponent } from './manage-main-slider/manage-main-slider.component';
import { MainSliderService } from './services/main-slider.service';
import { PreviewSlideModalComponent } from './manage-main-slider/preview-slide-modal/preview-slide-modal.component';
// import { SlideDetailComponent } from './manage-main-slider/slide-detail/slide-detail.component';

import { EventsCarouselComponent } from './event/events-carousel/events-carousel.component';
import { ProducerFollowEditBtnComponent } from './producer-feed/producer-follow-edit-btn/producer-follow-edit-btn.component';
import { CategoriesEventModalComponent } from './manage-categories/categories-event-modal/categories-event-modal.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { PrivacyEnComponent } from './privacy/privacy-langs/privacy-en/privacy-en.component';
import { PrivacyHeComponent } from './privacy/privacy-langs/privacy-he/privacy-he.component';
import { EventCategoryModalComponent } from './manage-categories/event-category-modal/event-category-modal.component';
import { CategoryListComponent } from './manage-categories/category-list/category-list.component';
import { NgxJdenticonModule, JDENTICON_CONFIG } from 'ngx-jdenticon';
import { EventPageComponent } from './event-page/event-page.component';
import { FbFriendsComponent } from './event-page/fb-friends/fb-friends.component';
import { FollowButtonComponent } from './event-page/follow-button/follow-button.component';
import { AnalyticDashboardComponent } from './analytics/analytic-dashboard/analytic-dashboard.component';
import { AnalyticSalesComponent } from './analytics/analytic-sales/analytic-sales.component';
import { AnalyticPromotersComponent } from './analytics/analytic-promoters/analytic-promoters.component';
import { AnalyticScannedComponent } from './analytics/analytic-scanned/analytic-scanned.component';
import { AnalyticBalanceComponent } from './analytics/analytic-balance/analytic-balance.component';
import { AnalyticInsightComponent } from './analytics/analytic-insight/analytic-insight.component';
import { SalesmanDetailsComponent } from './analytics/salesman-details/salesman-details.component';
// import { ProducerEditComponent } from './producer-feed/producer-edit/producer-edit.component';

import { SupportComponent } from './support/support.component';
import { SendSupportComponent } from './support/send-support/send-support/send-support.component';
import { SupportService } from './services/support.service';
import { ContactUsComponent } from './support/contact-us/contact-us.component';
import { ModalService } from './services/modal.service';
import { FilterByCountryPipe } from './pipes/filter-by-country.pipe';
import { FilterByGatewayPipe } from './pipes/filter-by-gateway.pipe.';
// import { CmsUsersActionsComponent } from './cms/cms-users-actions/cms-users-actions.component';
import { ListenScrollDirective } from './directives/listen-scroll.directive';
import { CountdownTimerComponent } from './event-page/countdown-timer/countdown-timer.component';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { EventCountersComponent } from './dashboard/event-counters/event-counters.component';
// import { DraggableDirective, DropZonesDirective } from './directives/draggable.directive';
import { EventParticipantInfoModalComponent } from './dashboard-sub-pages/event-participants/event-participant-info-modal/event-participant-info-modal.component';
import { RecentEventsComponent } from './dashboard-sub-pages/event-participants/recent-events/recent-events.component';
import { NgChartsModule } from 'ng2-charts';
import { CdTimerModule } from 'angular-cd-timer';
// import { AboutComponent } from './about/about.component';
// import { GoBackComponent } from './go-back/go-back.component';
// import { MyTicketsComponent } from './dashboard-sub-pages/my-tickets/my-tickets.component';
import { MyTicketsModalComponent } from './dashboard-sub-pages/my-tickets/my-tickets-modal/my-tickets-modal.component';
import { CmsCancelRefundEventComponent } from './cms/cms-cancel-event/cms-cancel-refund-event.component';
import { WalletConnectComponent } from './wallet-connect/wallet-connect.component';
import { WalletConnectService } from './services/wallet-connect.service';
import { PendingTransactionComponent } from './templates/pending-transaction/pending-transaction.component';
import { CustomFieldsDialogComponent } from './create-edit-event/create-edit-event-dialogs/custom-fields-dialog/custom-fields-dialog.component';
import { CustomFormInputsComponent } from './event-page/custom-form-inputs/custom-form-inputs.component';
import { CountdownCheckoutTimerComponent } from './event-page/countdown-checkout-timer/countdown-checkout-timer.component';
import { SolanaConnectComponent } from './solana-connect/solana-connect.component';
import { SolanaConnectService } from './services/solana-connect.service';
import { SolanaTransactionComponent } from './templates/solana-transaction/solana-transaction.component';
import { NftSuccessModalComponent } from './dashboard-sub-pages/my-tickets/nft-success-modal/nft-success-modal.component';
import { TicketImgModalComponent } from './event-page/ticket-img-modal/ticket-img-modal.component';
import { ModalFooterComponent } from './shared-dialogs/modal-footer/modal-footer.component';
import { CheckOtpComponent } from './login/check-otp/check-otp.component';
import { UserRegistrationComponent } from './event-page/user-registration/user-registration.component';
import { UserLoginComponent } from './event-page/user-login/user-login.component';
import { GuestInfoComponent } from './event-page/guest-info/guest-info.component';
import { UserTicketsComponent } from './event-page/user-tickets/user-tickets.component';
import { OrderSummariesComponent } from './event-page/order-summaries/order-summaries.component';
import { HeaderComponent } from './header/header.component';
import { BurgerMenuComponent } from './header/burger-menu/burger-menu.component';
import { BankFormInputsComponent } from './bank-form-inputs/bank-form-inputs.component';
import { BankAccountComponent } from './bank-account/bank-account.component';
import { SidenavMenuComponent } from './sidenav-menu/sidenav-menu.component';
import { HomepageComponent } from './homepage/homepage.component';
import { LanguageSelectComponent } from './sidenav-menu/language-select/language-select.component';
import { HomeCarouselComponent } from './homepage/home-carousel/home-carousel.component';
import { AutocompleteFilterEventsComponent } from './homepage/autocomplete-filter-events/autocomplete-filter-events.component';
import { SelectFilterEventsComponent } from './homepage/select-filter-events/select-filter-events.component';
import { DatepickerFilterEventsComponent, ExampleDatepickerHeader } from './homepage/datepicker-filter-events/datepicker-filter-events.component';
import { EventsListComponent } from './homepage/events-list/events-list.component';
import { EventCardComponent } from './homepage/events-list/event-card/event-card.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { CatNameComponent } from './homepage/events-list/cat-name/cat-name.component';
import { CategorySidenavComponent } from './homepage/category-sidenav/category-sidenav.component';
import { FooterComponent } from './footer/footer.component';
import { OrderSummaryComponent } from './event-page/order-summary/order-summary.component';
import { SearchComponent } from './header/search/search.component';
import { CategoriesPageComponent } from './categories-page/categories-page.component';
import { CategoryBannerComponent } from './categories-page/category-banner/category-banner.component';
// import { SendTicketsComponent } from './dashboard-sub-pages/send-tickets/send-tickets.component';
import { FinanceComponent } from './dashboard-sub-pages/finance/finance.component';
import { BankInfoModalComponent } from './dashboard-sub-pages/finance/bank-info-modal/bank-info-modal.component';
import { PayoutModalComponent } from './dashboard-sub-pages/finance/payout-modal/payout-modal.component';
import { BankWarningModalComponent } from './create-edit-event/pending-event/bank-warning-modal/bank-warning-modal.component';
import {TweedSDKContext} from './paytweed/sdk-context';
import { HyperswitchPaymentComponent } from './landing-page/hyperswitch-payment/hyperswitch-payment.component';
import { SeatsioAngularModule } from '@seatsio/seatsio-angular';
// import { CmsSeatsConfigComponent } from './cms/cms-seats-config/cms-seats-config.component';
import { ContactProducerComponent } from './event-page/contact-producer/contact-producer.component';
import { StripeAccountCheckComponent } from './landing-page/stripe-account-check/stripe-account-check.component';
// import { TicketsWPricePipe } from './pipes/tickets-w-price.pipe';
import { ShowTxtMessageModalComponent } from './shared-dialogs/show-txt-message-modal/show-txt-message-modal.component';
import { FansTicketsComponent } from './event-page/fans-tickets/fans-tickets.component';
import { FansTicketsModalComponent } from './event-page/fans-tickets-modal/fans-tickets-modal.component';
import { WalletExistsComponent } from './landing-page/wallet-exists/wallet-exists.component';
import {SharedModule} from './shared/shared.module';
import { RedirectFromMobileComponent } from './event-page/redirect-from-mobile/redirect-from-mobile.component';
import { PlaidComponent } from './event-page/plaid/plaid.component';
import { PlaidRedirrectComponent } from './event-page/plaid/plaid-redirrect/plaid-redirrect.component';
import { HdWalletAdapterModule, WALLET_CONFIG, CONNECTION_CONFIG, walletConfigProviderFactory, connectionConfigProviderFactory } from '@heavy-duty/wallet-adapter';
import * as Sentry from "@sentry/angular-ivy";
import { MatSelectCountryModule, MatSelectCountrySupportedLanguages } from '@angular-material-extensions/select-country';

import {
  DevTools,
  NgxTolgeeModule,
  Tolgee,
  TOLGEE_INSTANCE,
  LanguageDetector,
  LanguageStorage,
  // FormatSimple,
} from '@tolgee/ngx';
import { FormatSimple } from '@tolgee/core';
import { FormatIcu } from '@tolgee/format-icu';
import {
  SpikeProtectionInterceptor
} from "./http-interceptors/spike-protection-interceptor";
import { PasscodeModalComponent } from './event-page/passcode-modal/passcode-modal.component';
import { FilterByGatewayIdPipe } from './pipes/filter-by-gateway-id.pipe';
import { AnalyticTicketsComponent } from './analytics/analytic-tickets/analytic-tickets.component';
import { ApproveWaitingListModalComponent } from './analytics/analytic-tickets/approve-waiting-list-modal/approve-waiting-list-modal.component';
import {
  SvixSettingsComponent
} from "./cms/cms-svix-settings/svix-settings.component";
import { ManagePasscodesDialogComponent } from './create-edit-event/create-edit-event-dialogs/manage-passcodes-dialog/manage-passcodes-dialog.component';
import { LineupComponent } from './event-page/lineup/lineup.component';
import { StripeCheckoutComponent } from './event-page/stripe-checkout/stripe-checkout.component';
import { StripeReturnUrlComponent } from './landing-page/stripe-return-url/stripe-return-url.component';
import { StripeFuturePaymentsCheckoutComponent } from './event-page/stripe-future-payments-checkout/stripe-future-payments-checkout.component';
import { PlasmicComponent } from './plasmic/plasmic.component';
import { PlasmicPostComponent } from './plasmic/post/plasmic-post.component';

@NgModule({
    imports: [
        Sentry.TraceModule,
        NgxTolgeeModule,
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        MbscModule,
        HttpClientModule,
        AppRoutingModule,
        routing,
        // ModalModule.forRoot(),
        // PopoverModule.forRoot(),
        // TabsModule.forRoot(),
        BrowserAnimationsModule,
        MatDialogModule,
        MatSidenavModule,
        CdTimerModule,
        // InfiniteScrollModule,
        NgChartsModule,
        MatTableModule,
        LazyLoadImagesModule,
        QRCodeModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        MatFormFieldModule,
        InputTrimModule,
        MatTooltipModule,
        MatProgressBarModule,
        QuillModule.forRoot(),
        AngularCropperjsModule,
        NguCarouselModule,
        NgxPaginationModule,
        ClipboardModule,
        MatAutocompleteModule,
        MatInputModule,
        MatSnackBarModule,
        Ng2TelInputModule,
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
        SelectDropDownModule,
        MatSelectModule,
        MatCheckboxModule,
        MatRadioModule,
        MatSlideToggleModule,
        MatSortModule,
        HdWalletAdapterModule.forRoot({ localStorageKey: 'HdSolanaWallet', autoConnect: false }),
        // ((navigator.userAgent.toLowerCase().indexOf('') > -1) && !window['Android'] && !/^((?!chrome|android).)*safari/i.test(navigator.userAgent) && !((!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) && !/CriOS/i.test(navigator.userAgent))) ? AngularFireModule.initializeApp(environment.firebase) : '',
        // OwlModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatPaginatorModule,
        ScrollingModule,
        SeatsioAngularModule,
        NgxJdenticonModule,
        MatSelectCountryModule.forRoot(LOCALE_ID as unknown as MatSelectCountrySupportedLanguages),
        SharedModule.forRoot()
    ],
    declarations: [
        AppComponent,
        SigninComponent,
        TermsComponent,
        // ResetPasswordComponent,
        CreateEditEventComponent,
        DashboardComponent,
        CmsDashboardComponent,
        // FilterArrayPipe,
        LimitStr,
        SalesmenPipe,
        CmsEventsComponent,
        CmsUsersComponent,
        CmsEditUserComponent,
        // EventSalesmenComponent,
        // EventParticipantsComponent,
        EventPaymentComponent,
        // PersonalInfoComponent,
        ReaderParticipantsComponent,
        SuccessfulOrderComponent,
        TicketTemplatesComponent,
        PendingEventComponent,
        // AccountSettingsComponent,
        CmsPaymentFormComponent,
        CmsWebHooksComponent,
        CmsEventsTableComponent,
        ReportsComponent,
        PersonalInfoModalComponent,
        PhoneVerificationDialog,
        LoginComponent,
        SignupComponent,
        PurchaseModalComponent,
        FormInputsComponent,
        // ErrorHandlingComponent,
        // HtmlPipe,
        // SalesmanPipe,
        ApproveEventModalComponent,
        ValidationMinDirective,
        ValidationMaxDirective,
        EventDetailsModalComponent,
        AreUSureModalComponent,
        RestoreEventComponent,
        QuickLinksModalComponent,
        UserAlertsModalComponent,
        ConfirmWebhookParamsComponent,
        WebHooksFormComponent,
        SalesmanStatsBreakdownComponent,
        LoaderComponent,
        UpdateUserModalComponent,
        UpdatePasswordByAdminComponent,
        ReaderSigninComponent,
        ReaderSignupComponent,
        DateToAgePipe,
        CmsEventsTemplatesComponent,
        CmsTemplateEditAllowedFieldsComponent,
        ActionPlatformFilter,
        SoundCloudUrlDialogComponent,
        TermsDialogComponent,
        DataSourceDialogComponent,
        // ErrorCreatorComponent,
        InfoButtonDialogComponent,
        GoogleAutoCompleteDirective,
        // DropZonesDirective,
        // DraggableDirective,
        ListenScrollDirective,
        ChangeSellerModalComponent,
        ChangeSalesmanPipe,
        FacebookDeclinedPermissionsComponent,
        BankDetailsModalComponent,
        CreateEventButtonComponent,
        FlagsDropdownComponent,
        DateTimeFormatFilter,
        // TermHeComponent,
        // TermEnComponent,
        // CancellationPolicyComponent,
        CreateNewGatewayComponent,
        // GatewayListComponent,
        UpdateGatewayModalComponent,
        PhoneNumberDirective,
        FacebookFriendsBarComponent,
        ProducerFeedComponent,
        SnackBarMessageComponent,
        // SmsProvidersListComponent,
        ActiveSmsProviderModalComponent,
        // CancellationHeComponent,
        // CancellationEnComponent,
        CropImageModalComponent,
        NotificationsListComponent,
        // ProducerEditComponent,
        PreviewSlideModalComponent,
        // UserAvatarComponent,
        CouponsComponent,
        SalesPromotionComponent,
        BankAccountComponent,
        // ManageMainSliderComponent,
        // SlideDetailComponent,
        EventsCarouselComponent,
        PrivacyComponent,
        PrivacyEnComponent,
        PrivacyHeComponent,
        ProducerFollowEditBtnComponent,
        CategoriesEventModalComponent,
        CategoryListComponent,
        EventCategoryModalComponent,
        EventPageComponent,
        FbFriendsComponent,
        FollowButtonComponent,
        AnalyticDashboardComponent,
        AnalyticSalesComponent,
        AnalyticPromotersComponent,
        AnalyticScannedComponent,
        AnalyticBalanceComponent,
        AnalyticInsightComponent,
        SalesmanDetailsComponent,
        SupportComponent,
        SendSupportComponent,
        ContactUsComponent,
        FilterByCountryPipe,
        FilterByGatewayPipe,
        // CmsUsersActionsComponent,
        CountdownTimerComponent,
        ShortNumberPipe,
        EventCountersComponent,
        EventParticipantInfoModalComponent,
        RecentEventsComponent,
        // AboutComponent,
        // GoBackComponent,
        // MyTicketsComponent,
        MyTicketsModalComponent,
        CmsCancelRefundEventComponent,
        // WalletConnectComponent,
        PendingTransactionComponent,
        CustomFieldsDialogComponent,
        CustomFormInputsComponent,
        // CountdownCheckoutTimerComponent,
        // SolanaConnectComponent,
        SolanaTransactionComponent,
        NftSuccessModalComponent,
        TicketImgModalComponent,
        ModalFooterComponent,
        // CheckOtpComponent,
        UserRegistrationComponent,
        UserLoginComponent,
        GuestInfoComponent,
        UserTicketsComponent,
        OrderSummariesComponent,
        HeaderComponent,
        BurgerMenuComponent,
        SidenavMenuComponent,
        HomepageComponent,
        LanguageSelectComponent,
        HomeCarouselComponent,
        AutocompleteFilterEventsComponent,
        SelectFilterEventsComponent,
        DatepickerFilterEventsComponent,
        ExampleDatepickerHeader,
        EventsListComponent,
        EventCardComponent,
        CatNameComponent,
        CategorySidenavComponent,
        FooterComponent,
        OrderSummaryComponent,
        SearchComponent,
        CategoriesPageComponent,
        CategoryBannerComponent,
        // SendTicketsComponent,
        FinanceComponent,
        BankInfoModalComponent,
        PayoutModalComponent,
        BankWarningModalComponent,
        BankFormInputsComponent,
        HyperswitchPaymentComponent,
        // CmsSeatsConfigComponent,
        ContactProducerComponent,
        StripeAccountCheckComponent,
        // TicketsWPricePipe,
        ShowTxtMessageModalComponent,
        FansTicketsComponent,
        FansTicketsModalComponent,
        WalletExistsComponent,
        RedirectFromMobileComponent,
        PlaidComponent,
        PlaidRedirrectComponent,
        PasscodeModalComponent,
        FilterByGatewayIdPipe,
        AnalyticTicketsComponent,
        ApproveWaitingListModalComponent,
        SvixSettingsComponent,
        ManagePasscodesDialogComponent,
        LineupComponent,
        StripeCheckoutComponent,
        StripeReturnUrlComponent,
        StripeFuturePaymentsCheckoutComponent,
        PlasmicComponent,
        PlasmicPostComponent,
    ],
    providers: [
        GlobalsService,
        {
          provide: ErrorHandler,
          useValue: Sentry.createErrorHandler({
            showDialog: false
          }),
        }, {
          provide: Sentry.TraceService,
          deps: [Router],
        },
        {
          provide: TOLGEE_INSTANCE,
          useFactory: () => {
            if (environment.production) {
              console.log('INIT Tolgee in production');
              return Tolgee()
              .use(DevTools())
              .use(LanguageDetector())
              .use(LanguageStorage())
              // .use(FormatSimple())
              .use(FormatIcu())
              .init({
                defaultNs: 'origin_tolgee',
                ns: ['origin_tolgee'],
                availableLanguages: ['en', 'he', 'es', 'fr', 'ar', 'pt', 'th', 'ru', 'uk'],
                defaultLanguage: 'en',
                fallbackLanguage: 'en',
    
                // for production
                staticData: {
                  "en:origin_tolgee": () => import('../../i18n/origin_tolgee/en.json'),
                  "he:origin_tolgee": () => import('../../i18n/origin_tolgee/he.json'),
                  "es:origin_tolgee": () => import('../../i18n/origin_tolgee/es.json'),
                  "fr:origin_tolgee": () => import('../../i18n/origin_tolgee/fr.json'),
                  "ar:origin_tolgee": () => import('../../i18n/origin_tolgee/ar.json'),
                },
              });
            } else {
              console.log('INIT Tolgee in development');
              return Tolgee()
                .use(DevTools())
                .use(LanguageDetector())
                // .use(FormatSimple())
                .use(FormatIcu())
                .init({
                  defaultNs: 'origin_tolgee',
                  ns: ['origin_tolgee'],
                  availableLanguages: ['en', 'he', 'es', 'fr', 'ar', 'pt', 'th', 'ru', 'uk'],
                  defaultLanguage: 'en',
                  fallbackLanguage: 'en',
                  
                  // for development
                  apiUrl: environment.tolgee?.apiUrl,
                  apiKey: environment.tolgee?.apiKey,
                });
            }
          },
        },
        { provide: APP_INITIALIZER, useFactory: ipInfoProviderFactory, deps: [LocalizationService, GlobalsService, Sentry.TraceService], multi: true },
        // { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
        LocalizationService,
        NavbarService,
        AuthService,
        EventService,
        CookieService,
        DatePipe,
        ReportsService,
        // { provide: CookieOptions, useValue: false },
        { provide: HTTP_INTERCEPTORS, useClass: JWTInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: SpikeProtectionInterceptor, multi: true },
        DialogService,
        ModalService,
        FacebookService,
        ErrorCodesService,
        SuccessCodesService,
        { provide: FacebookPixelModel, useClass: FacebookPixelModel },
        // { provide: LOCALE_ID, useValue: 'en' }, // issue with LOCALE_ID, was always en
        ChangeSalesmanPipe,
        FirebaseMessagingService,
        PusherService,
        NotificationService,
        AsyncPipe,
        MainSliderService,
        SupportService,
        PhoneVerificationDialogService,
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_DIALOG_DATA, useValue: {} },
        WalletConnectService,
        SolanaConnectService,
        { provide: CONNECTION_CONFIG, useValue: { commitment: 'finalized' }, useFactory: connectionConfigProviderFactory },
        { provide: WALLET_CONFIG, useValue: { autoConnect: false, adapters: [] }, useFactory: walletConfigProviderFactory },
        MatDatepickerModule,
        {
        // Custom identicon style
        // https://jdenticon.com/icon-designer.html?config=1f685700115b1c310a4e1e51
        provide: JDENTICON_CONFIG,
        useValue: {
          lightness: {
            color: [0.40, 0.80],
            grayscale: [0.28, 0.85]
          },
          saturation: {
            color: 0.31,
            grayscale: 0.40
          },
          backColor: '#0000',
          replaceMode: "observe"
        },
      },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
export function ipInfoProviderFactory(provider: LocalizationService, globals: GlobalsService) {
  return () => {
    if(environment.production) {
      const url = window.location.href;
      let pathname = window.location.pathname;
      let urlQueryParams = window.location.search;
      const isFromGetinqueyString = url.match(/f=1/);
      const isFromGetin =  isFromGetinqueyString ? true : false;
      const baseUrl = window.location.origin;
      if (isFromGetin) {
        if(url.match(/\?f=1/)) {
          urlQueryParams = urlQueryParams.replace('?f=1', '');
        } else {
          urlQueryParams = urlQueryParams.replace('&f=1', '');
        }
        return new Promise((resolve) => {
          resolve(true);
        }).then(() => {
          window.history.replaceState({}, 'getin', pathname + urlQueryParams);
        });
      }

      return new Promise((resolve: any) => {
          let userLang;
          const storageLang = globals.getLocaleId();
          const browserLang = globals.getBrowserLanguage();
          if (!storageLang) { userLang = browserLang; } else { userLang = storageLang; }
          if(!url.match(/code=/) && !url.match(/access_token=/) && !url.match(/access_denied/)) {
            const allLangs = globals.getCountryData();
            const langsArray = [];
            for (const flagObj of allLangs) {
              langsArray.push(flagObj.lang);
            }
            const urlLang = pathname.substr(pathname.length - 1) === '/' ? pathname.substring(1, pathname.length - 1) : pathname.substr(1);
            const isUrlLang = langsArray.indexOf(urlLang) !== -1;
            const isUserLang = langsArray.indexOf(userLang) !== -1
            // console.log('pathname', pathname, pathname.substr(pathname.length - 1), pathname.substr(1), urlLang, userLang, isUrlLang, isUserLang);
            let langObject;
            let isSuppLang = true;
            if (isUrlLang ) {
              langObject = allLangs.find(object => {return object.lang === urlLang});
            } else if (isUserLang) {
              langObject = allLangs.find(object => {return object.lang === userLang});
            } else {
              // For set default language to EN if browser language not support
              langObject = allLangs.find(object => { return object.lang === 'en' });
              isSuppLang = false;
            }
            const isFeedPage = pathname === '/' || isUrlLang;
            // console.log('isFeedPage', isFeedPage, langObject, !isFromGetin, globals.getLocaleId(), !isSuppLang);
            if (!isFromGetin && langObject && isFeedPage) {
              if (langObject.lang !== globals.getLocaleId() || !isSuppLang) {
                if (pathname.charAt(0) === '/') {
                  pathname = pathname.substr(1);
                }
                const urlParhArray = pathname.split('/');
                if (langsArray.indexOf(urlParhArray[0]) !== -1) {
                  urlParhArray.shift();
                }
                pathname = urlParhArray.join('/');
                const urlLangPart = langObject.lang !== 'en' ? langObject.lang + '/' : '';
                const urlLocalized = baseUrl + '/' + urlLangPart + (pathname ? pathname : '') + (urlQueryParams ? urlQueryParams : '');
                console.log('urlLocalized', url, urlLocalized, urlLangPart, pathname, urlQueryParams);
                if (urlLocalized !== url) {
                  window.location.replace(urlLocalized);
                }
              }
            }

          }
        resolve(true);
        })
        .catch(error => {});
    }
  }
}
