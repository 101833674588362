<ng-container *ngIf="!isCatPage"  [ngSwitch]="name">
  <h3 *ngSwitchCase="'featured'" i18n="events category page title | featured">Featured events</h3>
  <h3 *ngSwitchCase="'followed'" i18n="events category page title | followed">Followed producers events</h3>
  <h3 *ngSwitchCase="'near'" i18n="events category page title | locale">Near events</h3>
  <h3 *ngSwitchCase="'trending'" i18n="events category page title | trending">Trending</h3>
  <h3 *ngSwitchCase="'free'" i18n="events category page title | free">Free events</h3>
  <h3 *ngSwitchCase="'Search result:'" i18n="events category page title | search result">Search Result:</h3>
  <h3 *ngSwitchDefault>{{name}}</h3>
</ng-container>

<ng-container *ngIf="isCatPage" [ngSwitch]="name">
  <h3 *ngSwitchCase="'featured'" i18n="events category page title | featured">All Featured events</h3>
  <h3 *ngSwitchCase="'followed'" i18n="events category page title | followed">All Followed producers events</h3>
  <h3 *ngSwitchCase="'near'" i18n="events category page title | locale">All Near events</h3>
  <h3 *ngSwitchCase="'trending'" i18n="events category page title | trending">All Trending events</h3>
  <h3 *ngSwitchCase="'free'" i18n="events category page title | free">All Free events</h3>
  <h3 *ngSwitchCase="'Filtered events'" i18n="events category page title | filtered events">Filtered events</h3>
  <h3 *ngSwitchCase="'Search result:'" i18n="events category page title | search result">Search result:</h3>
  <h3 *ngSwitchDefault> <ng-container *ngIf="name !== 'all'">All</ng-container> {{name}} events</h3>
</ng-container>
