import { ProducerFeedComponent } from './producer-feed/producer-feed.component';
import { ReaderSigninComponent } from './reader-signin/reader-signin/reader-signin.component';
import { ReaderSignupComponent } from './reader-signin/reader-signup/reader-signup.component';
import { NoAuthGuardService } from './route-guards/no-auth-guard.service';
import { AuthAdminGuardService } from './route-guards/auth-admin-guard.service';
import { AuthGuardService } from './route-guards/auth-guard.service';
import { ReaderAuthGuardService } from './route-guards/reader-auth-guard.service';
import { ReaderIsLoggedInAuthGuardService } from './route-guards/reader-is-logged-in-auth-guard.service';
import { PurchaseModalComponent } from './landing-page/purchase-modal/purchase-modal.component';
import { LoginComponent } from './login/login.component';
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninComponent } from './login/signin/signin.component';
import { PersonalInfoComponent } from './personal-info/personal-info.component';

import { TermsComponent } from './terms/terms.component';
// import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { CreateEditEventComponent } from './create-edit-event/create-edit-event.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CmsDashboardComponent } from './cms/cms-dashboard/cms-dashboard.component';

import { CmsPaymentFormComponent } from './cms/cms-payment-form/cms-payment-form.component';
import { CmsWebHooksComponent } from './cms/cms-web-hooks/cms-web-hooks.component';
import { CmsUsersComponent } from './cms/cms-users/cms-users.component';
import { CmsEventsComponent } from './cms/cms-events/cms-events.component';
import { CmsEditUserComponent } from './cms/sub-pages/cms-edit-user/cms-edit-user.component';
// import { EventSalesmenComponent } from "./dashboard-sub-pages/event-salesmen/event-salesmen.component";
import { EventParticipantsComponent } from './dashboard-sub-pages/event-participants/event-participants.component';
import { EventPaymentComponent } from './dashboard-sub-pages/event-payment/event-payment.component';
import { ReaderParticipantsComponent } from './reader-participants/reader-participants.component';
import { SuccessfulOrderComponent } from './landing-page/successful-order/successful-order.component';
import { TicketTemplatesComponent } from './landing-page/ticket-templates/ticket-templates.component';
import { PendingEventComponent } from './create-edit-event/pending-event/pending-event.component';
// import { AccountSetingsComponent } from './account-setings/account-setings.component';
import { ReportsComponent } from './reports/reports.component';
import { CmsEventsTemplatesComponent } from './cms/cms-events-templates/cms-events-templates.component';
import { CmsTemplateEditAllowedFieldsComponent } from './cms/cms-template-edit-allowed-fields/cms-template-edit-allowed-fields.component';
// import { CancellationPolicyComponent } from './terms/cancellation-policy/cancellation-policy.component';
import { CreateNewGatewayComponent } from './gateways/create-new-gateway/create-new-gateway.component';
// import { GatewayListComponent } from './gateways/gateways-list/gateways-list.component';
// import { SmsProvidersListComponent } from './cms/sms-providers/sms-providers-list/sms-providers-list.component';
import { CouponsComponent } from './coupons/coupons.component';
import { SalesPromotionComponent } from './sales-promotion/sales-promotion.component';
import { BankAccountComponent } from './bank-account/bank-account.component';
// import { ManageMainSliderComponent } from './manage-main-slider/manage-main-slider.component';
import { SlideDetailComponent } from './manage-main-slider/slide-detail/slide-detail.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { CategoryListComponent } from './manage-categories/category-list/category-list.component';
import { EventPageComponent } from './event-page/event-page.component';
import { AnalyticDashboardComponent } from './analytics/analytic-dashboard/analytic-dashboard.component';
// import { ProducerEditComponent } from './producer-feed/producer-edit/producer-edit.component';
import { SupportComponent } from './support/support.component';
import { SendSupportComponent } from './support/send-support/send-support/send-support.component';
import { ContactUsComponent } from './support/contact-us/contact-us.component';
// import { CmsUsersActionsComponent } from './cms/cms-users-actions/cms-users-actions.component';
// import { AboutComponent } from './about/about.component';
// import { MyTicketsComponent } from "./dashboard-sub-pages/my-tickets/my-tickets.component";
import { CmsCancelRefundEventComponent } from './cms/cms-cancel-event/cms-cancel-refund-event.component';
import { PendingTransactionComponent } from './templates/pending-transaction/pending-transaction.component';
import { SolanaTransactionComponent } from './templates/solana-transaction/solana-transaction.component';
import { HomepageComponent } from './homepage/homepage.component';
import { CategoriesPageComponent } from './categories-page/categories-page.component';
import { SendTicketsComponent } from './dashboard-sub-pages/send-tickets/send-tickets.component';
import { FinanceComponent } from './dashboard-sub-pages/finance/finance.component';
import { HyperswitchPaymentComponent } from './landing-page/hyperswitch-payment/hyperswitch-payment.component';
// import {CmsSeatsConfigComponent} from './cms/cms-seats-config/cms-seats-config.component';
import { StripeAccountCheckComponent } from './landing-page/stripe-account-check/stripe-account-check.component';
import { WalletExistsComponent } from './landing-page/wallet-exists/wallet-exists.component';
import { RedirectFromMobileComponent } from './event-page/redirect-from-mobile/redirect-from-mobile.component';
import { PlaidComponent } from './event-page/plaid/plaid.component';
import { PlaidRedirrectComponent } from './event-page/plaid/plaid-redirrect/plaid-redirrect.component';
import {
  StripeReturnUrlComponent
} from "./landing-page/stripe-return-url/stripe-return-url.component";
import {PlasmicComponent} from "./plasmic/plasmic.component";
import {PlasmicPostComponent} from "./plasmic/post/plasmic-post.component";

const APP_ROUTES: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'home_test123456789', component: HomepageComponent },
  { path: 'coupons/:event_id', component: CouponsComponent, pathMatch: 'full' },
  {
    path: 'sales-promotion/:event_id',
    component: SalesPromotionComponent,
    pathMatch: 'full',
    canActivate: [AuthGuardService],
  },
  {
    path: 'bank-account/:event_id',
    component: BankAccountComponent,
    pathMatch: 'full',
    canActivate: [AuthGuardService],
  },
  {
    path: 'producer/:producer_id',
    component: ProducerFeedComponent,
    pathMatch: 'full',
  },
  { path: 'full-purchase/:event', component: PurchaseModalComponent },
  {
    path: 'full-purchase/:event/:seller_code',
    component: PurchaseModalComponent,
  },
  {
    path: 'signin',
    component: LoginComponent,
    canActivate: [NoAuthGuardService],
    pathMatch: 'full',
  },
  {
    path: 'signin/:id',
    component: SigninComponent,
    canActivate: [NoAuthGuardService],
  },
  // {path: 'personal-info', component: PersonalInfoComponent, canActivate: [AuthGuardService], pathMatch: 'full'},

  // { path: 'producer-edit', component: ProducerEditComponent, canActivate: [AuthGuardService], pathMatch: 'full' },
  // { path: 'producer-edit/:id', component: ProducerEditComponent, canActivate: [AuthGuardService]},

  // {path: 'account-settings', component: AccountSettingsComponent, canActivate: [AuthGuardService], pathMatch: 'full'},
  { path: 'terms', component: TermsComponent, pathMatch: 'full' },
  // {path: 'cancellations', component: CancellationPolicyComponent, pathMatch: 'full'},
  // {path: 'reset-password', component: ResetPasswordComponent, pathMatch: 'full'},
  {
    path: 'create-event',
    component: CreateEditEventComponent,
    pathMatch: 'full',
  },
  { path: 'edit-event', redirectTo: '', pathMatch: 'full' },
  {
    path: 'edit-event/:id',
    component: CreateEditEventComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'pending-event/:event_id/:event_status/:show_bank_modal/:auto_approve',
    component: PendingEventComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'dashboard/:status',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
  },
  { path: 'privacy', component: PrivacyComponent, pathMatch: 'full' },
  // {path: 'about', component: AboutComponent, pathMatch: 'full'},

  // DASHBOARD SUB-PAGES:
  // {path: 'event-salesmen/:event_id', component: EventSalesmenComponent, canActivate: [AuthGuardService]},
  // {path: 'copy-event-salesmans/:event_id/:copy_to_event', component: EventSalesmenComponent, canActivate: [AuthGuardService]},
  // {path: 'event-participants/:event_id/:filter', component: EventParticipantsComponent, canActivate: [AuthGuardService]},
  // {path: 'event-participants/:event_id/:salesman_id/:filter', component: EventParticipantsComponent, canActivate: [AuthGuardService]},
  {
    path: 'event-payment/:event',
    component: EventPaymentComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'event-payment/:event/:salesman_id',
    component: EventPaymentComponent,
    canActivate: [AuthGuardService],
  },
  // {path: 'send-tickets/:event_id', component: SendTicketsComponent, canActivate: [AuthAdminGuardService]},
  // {path: 'my-tickets', component: MyTicketsComponent, canActivate: [AuthGuardService]},
  {
    path: 'my-finance',
    component: FinanceComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'my-finance/stripe',
    component: FinanceComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: 'reader/:reader_id',
    canActivate: [ReaderAuthGuardService],
    component: ReaderParticipantsComponent,
  },
  {
    path: 'reader-signin',
    canActivate: [ReaderIsLoggedInAuthGuardService],
    component: ReaderSigninComponent,
    pathMatch: 'full',
  },
  {
    path: 'reader-signup/:event_id',
    canActivate: [AuthGuardService],
    component: ReaderSignupComponent,
  },

  // ANALYTICS
  {
    path: 'analytics/dashboard/:event_id',
    component: AnalyticDashboardComponent,
    canActivate: [AuthGuardService],
  },

  // CMS:
  {
    path: 'cms/dashboard/:status',
    component: CmsDashboardComponent,
    canActivate: [AuthAdminGuardService],
  },
  {
    path: 'cms/payment-form/:event_id',
    component: CmsPaymentFormComponent,
    canActivate: [AuthAdminGuardService],
  },
  {
    path: 'cms/web-hooks/:event_id',
    component: CmsWebHooksComponent,
    canActivate: [AuthAdminGuardService],
  },
  {
    path: 'cms/events/:user_id/:status',
    component: CmsEventsComponent,
    canActivate: [AuthAdminGuardService],
  },
  {
    path: 'cms/users/:filter_type',
    component: CmsUsersComponent,
    canActivate: [AuthAdminGuardService],
  },
  {
    path: 'cms/reports',
    component: ReportsComponent,
    canActivate: [AuthAdminGuardService],
  },
  {
    path: 'cms/create-new-gateway',
    component: CreateNewGatewayComponent,
    canActivate: [AuthAdminGuardService],
  },
  {
    path: 'cms/cancel-refund-event/:event_id',
    component: CmsCancelRefundEventComponent,
    canActivate: [AuthAdminGuardService],
  },
  {
    path: 'cms/edit-user/:id',
    component: CmsEditUserComponent,
    canActivate: [AuthAdminGuardService],
  },
  {
    path: 'reports/:event_id',
    component: ReportsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'support',
    component: SupportComponent,
    canActivate: [AuthGuardService],
  },
  { path: 'send-support', component: SendSupportComponent },
  { path: 'contact-us', component: ContactUsComponent },
  {
    path: 'manage-categories',
    component: CategoryListComponent,
    canActivate: [AuthGuardService],
    pathMatch: 'full',
  },
  { path: 'categories/:id', component: CategoriesPageComponent },
  {
    path: 'preview/:event',
    component: EventPageComponent,
    data: { preview: true },
    canActivate: [AuthGuardService],
  },
  {
    path: 'order-status/:status/:payment_method/:currency/:purchase_validation/:purchaser_type/:event_id/:producer_credit/:redirect',
    component: SuccessfulOrderComponent,
  },
  {
    path: 'order-status/:status/:payment_method/:currency/:purchase_validation/:purchaser_type/:event_id/:producer_credit/:tranzilla_response/:redirect',
    component: SuccessfulOrderComponent,
  },
  { path: 'ticket/:hash', component: TicketTemplatesComponent },
  {
    path: 'wallet-connect-transaction/:hash',
    component: PendingTransactionComponent,
  },
  { path: 'solana-transaction/:hash', component: SolanaTransactionComponent },

  { path: 'blog', component: PlasmicComponent },
  { path: 'blog/:post', component: PlasmicPostComponent },
  // ************************************************
  { path: ':event', component: EventPageComponent },
  // ************************************************
  {
    path: 'event/:event/tickets',
    component: EventPageComponent,
    data: { step: 'Tickets' },
  },
  {
    path: 'event/:event/participants',
    component: EventPageComponent,
    data: { step: 'Guest' },
  },
  {
    path: 'hyperswitch-payment/:main_purchase_identifier',
    component: HyperswitchPaymentComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'stripe-payment/:main_purchase_identifier',
    component: StripeReturnUrlComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'stripe-future-payment/:main_purchase_identifier',
    component: StripeReturnUrlComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'stripe-connect-check-account/:hash',
    component: StripeAccountCheckComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'wallet-exists/:hash',
    component: WalletExistsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'redirect-from-mobile/:token',
    component: RedirectFromMobileComponent,
  },
  { path: 'plaid/logic/redirect', component: PlaidRedirrectComponent },
  { path: 'plaid/logic', component: PlaidComponent, pathMatch: 'full' },

  //Default route
  { path: '**', component: HomepageComponent },
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(
  APP_ROUTES,
  {}
);
