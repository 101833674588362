<button class="close-dialog-btn" mat-dialog-close></button>
<div class="phone-verification">
  <p i18n="phone verification | please enter code">Please enter the 6 digits sent to you</p>
    <input matInput #phone_validate_code [(ngModel)]="data.phone_validate_code" maxlength="6" (ngModelChange)="change($event)">
    <p><span i18n="phone verification | invalid code" class="item-error" *ngIf="error === 'invalid code'">Invalid code. Please try again</span> </p>
    <p class="phone-length">{{phone_validate_code.value.length}} / 6</p>
</div>
<div>
  <p class="resend">
    <span i18n="phone verification | did not receive code">Didn't receive the code?</span>
    <button i18n="phone verification | resend" class="phone-verification-resend-btn" [disabled]="seconds" [ngClass]="{'resend-disabled': seconds}" (click)="resend()">Resend <span *ngIf="seconds">({{seconds}})</span></button>
  </p>
  <button i18n="phone verification | send"  [disabled]="btnDisable" class="phone-verification-btn" (click)="send()" [ngClass]="{'disabled': btnDisable}">Send</button>
</div>
