import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from "@angular/core";
import { environment } from 'src/environments/environment';

declare let window: any

@Injectable({
	providedIn: 'root'
})
export class FacebookPixelModel {
    isDev: boolean = true;
    constructor(@Inject(DOCUMENT) private _document: Document) {
        if(environment.production) {
            this.isDev = false;
        }
    }

    checkIfExistsAndRemove(renderer: Renderer2, pixelType: string, pixelId: number) {
      // console.log('check script and remove');
        try {
            const elScript = renderer.selectRootElement('script.'+pixelType+'-'+'pixel-event');
            // console.log('check If Exists Pixel And Remove elScript', elScript, pixelId);
            renderer.removeChild('head',elScript);
            const elNoScript = renderer.selectRootElement('noscript.'+pixelType+'-'+'pixel-event');
            // console.log('check If Exists Pixel And Remove elNoScript', elNoScript, pixelId);
            renderer.removeChild('head',elNoScript);
        } catch(e) {
            if(e instanceof Error) {
                return false;
            }
        }
    }

    createPixelMetaData() {
      // let fb_meta = renderer.createElement('meta');
      // fb_meta.name = 'facebook-domain-verification';
      // fb_meta.content='60u9hia15ga6dpzb3wsdw1jrwl3b68';
      // renderer.appendChild(this._document.head, fb_meta);
    }

    checkIfExistsIdAndRemove(renderer: any, pixelIds: number[]) {
      pixelIds.forEach(pixelId => {
        // const srcScript = renderer.selectRootElement('script[src*='+'"'+pixelId+'"'+']');
        const srcScript = this._document.querySelector('script[src*='+'"'+pixelId+'"'+']');
        // console.log('check If Exists Pixel Id And Remove it', srcScript, pixelId);
        srcScript ? renderer.removeChild('head', srcScript) : false;
      });
    }

    setGetinPixel(renderer: any, trackPurchase: boolean, currency: String = '', producerCredit: number = 0, trackExtra: string = undefined, contents: string = '') {
        let pixelId = [1760804754175718];
        this.removeOldPixels(renderer,  'fbq', pixelId);
        this.setPixel(renderer, pixelId, trackPurchase, currency, producerCredit, trackExtra, contents);
        // console.log('setGetinPixel', pixelId);
    }

    checkIfPixelsInit(pixel) {
      const promise = new Promise((resolve) => {
        setTimeout(() => {
          const hasScript = this._document.getElementsByClassName(pixel);
          // const pixelArray = Array.prototype.slice.call(<any>hasScript);
          // const pixels = document.querySelectorAll('script.'+pixel);
          // console.log('HasScript', pixel, pixels, hasScript, hasScript.length, pixelArray);
          resolve(!!(hasScript && hasScript.length));
        }, 0);
      });
      return promise;
    }

    removeOldPixels(renderer: Renderer2, pixelType: string, pixelIds: number[]) {
      // pixelIds.forEach(pixelId => {
        const pixels = this._document.getElementsByClassName(pixelType+'-'+'pixel-event');
        const pixelArray = Array.prototype.slice.call(<any>pixels);
        // const pixelArray: any[] = Array.from(<any>pixels);
        // console.log('removeOldPixels', pixels, pixels.length, pixelArray);
          pixelArray.forEach(element => {
            renderer.removeChild('body',element);
            renderer.removeChild('head',element);
          });
      // });
    }

    async setPixel(renderer: any, pixelIds: number[], trackPurchase: boolean, currency: String = '', producerCredit: number = 0, trackExtra: string = undefined, contents: any = '') {
      // console.log('Pixels content', contents);
        if(this.isDev) {
            return;
        }
        // this.checkIfExistsAndRemove(renderer, 'fbq', pixelId);
        const getinPixelId = 1760804754175718;
        let pixels = "fbq('init', '"+getinPixelId+"');";
        pixelIds.forEach(pixelId => {
          pixelId != getinPixelId ? pixels += "fbq('init', '"+pixelId+"');" : false;
        });
        // console.log('All pixels that will be init', pixels);
        const pixelIsInit = await this.checkIfPixelsInit('fbq-init');
        // 'trackSingle', 'trackSingleCustom'
        let trackAction = (trackPurchase) ? "fbq('trackCustom', 'Purchase', {currency: \""+currency+"\", value: "+producerCredit+"});" : (trackExtra) ? "fbq('trackCustom', '"+trackExtra+"');" : "";
        contents ? trackAction = (trackPurchase) ? "fbq('trackCustom', 'Purchase', {currency: \""+currency+"\", value: "+producerCredit+","+contents+"});" : (trackExtra) ? "fbq('trackCustom', '"+trackExtra+"', {"+contents+"});" : "" : (trackPurchase) ? "fbq('trackCustom', 'Purchase', {currency: \""+currency+"\", value: "+producerCredit+"});" : (trackExtra) ? "fbq('trackCustom', '"+trackExtra+"');" : "";
        // console.log('Check Pixel events: ', trackAction, pixelIsInit, trackPurchase, trackExtra, contents); // contents: "+contents+"
        if (!pixelIsInit || !window.fbq) {
        let fb_pixel = renderer.createElement('script');
        renderer.addClass(fb_pixel, 'fbq-init');
        fb_pixel.type = 'text/javascript';
        fb_pixel.text =
        `
          !function(f,b,e,v,n,t,s){
            if(f.fbq)return;n=f.fbq=function(){
              n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)
            };
            if(!f._fbq)f._fbq=n;
            n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];
            t=b.createElement(e);t.async=!0;t.src=v;
            s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)
          }
          (window, document,'script','https://connect.facebook.net/en_US/fbevents.js');
          `+pixels+`
        `;

        setTimeout(()=>{
          // console.log('pixelIsNotInit', pixelIsInit);
          if (trackAction) {
              let fb_trackAction = renderer.createElement('script');
              fb_trackAction.type = 'text/javascript';
              fb_trackAction.text = trackAction;
              renderer.addClass(fb_trackAction, 'fbq-'+'pixel-event');
              renderer.appendChild(this._document.body, fb_trackAction);
              // console.log('setPixel trackAction: ', pixelIds, trackAction);
          }
        }, 200);

        // create the noscript tag:
        // let noscript_pixel = renderer.createElement('noscript');
        // renderer.addClass(noscript_pixel, 'fbq-'+pixelId);
        // let img_tag = renderer.createElement('img');
        // img_tag.src = 'https://www.facebook.com/tr?id='+pixelId+'&ev=PageView&noscript=1';
        // img_tag.height = 1;
        // img_tag.width = 1;
        // renderer.setStyle(img_tag, 'display', 'none');
        // renderer.appendChild(noscript_pixel, img_tag);

        // append facebook pixel to document (in reverse order - since the method is prepend to body):
        renderer.insertBefore(this._document.head, fb_pixel, this._document.head.lastChild);
        // renderer.insertBefore(this._document.head, noscript_pixel, this._document.head.lastChild);
      } else {
        // console.log('pixelIsInit', pixelIsInit);
        if (trackAction) {
            let fb_trackAction = renderer.createElement('script');
            fb_trackAction.type = 'text/javascript';
            fb_trackAction.text = trackAction;
            renderer.addClass(fb_trackAction, 'fbq-'+'pixel-event');
            renderer.appendChild(this._document.body, fb_trackAction);
            // console.log('setPixel trackAction: ', pixelIds, trackAction);
        }
      }
      setTimeout(() => {
        this.removeOldPixels(renderer, 'fbq', pixelIds);
      }, 200);
    }

    removePurchaseTag(renderer: any, pixelIds: number[], trackExtra: string = undefined) {
        // if(this.isDev) {
        //     return;
        // }
        // console.log('removePurchaseTag', pixelId);
        // this.checkIfExistsAndRemove(renderer, 'fbq', pixelId);
        this.removeOldPixels(renderer, 'fbq', pixelIds);
    }

    removeGetinPurchaseTag(renderer: any, trackExtra: string = undefined) {
        let pixelId = [1760804754175718];
        this.removePurchaseTag(renderer, pixelId, trackExtra);
    }
    removeGetinPixel(renderer: any) {
        let pixelId = [1760804754175718];
        // this.checkIfExistsAndRemove(renderer, 'fbq', pixelId);
        this.removeOldPixels(renderer, 'fbq', pixelId);
        this.checkIfExistsIdAndRemove(renderer, pixelId);
    }
    removeGetInPixelInit(renderer: Renderer2) {
      let pixel = 1760804754175718;
      this.removePixelInit(renderer, pixel);
    }
    removePixelInit(renderer: Renderer2, pixel = null) {
      // console.log('removePixelInit');
      const initPixel = 'fbq-init';
      const hasScript = this._document.getElementsByClassName(initPixel).item(0);
      // const pixelArray = Array.prototype.slice.call(<any>pixel);
      if (hasScript) {
        renderer.removeChild('body',hasScript);
        renderer.removeChild('head',hasScript);
        // console.log('removePixelInit', pixel, hasScript);
      }
      if (window.fbq) {
        // console.log('fbq was disabled', window.fbq);
        window.fbq = window._fbq = null;
      }
    }
}
