<ng-container [ngSwitch]="showInfo">
  <div class="nft-success-modal" *ngSwitchDefault>
    <div class="nft-success-modal-header">
      <button type="button" class="close" [matDialogClose]>
        <span aria-hidden="true">&times;</span>
      </button>
      <p mat-dialog-title i18n="nft-success | title">Thanks For purchasing</p>
    </div>
    <div class="nft-success-modal-body" mat-dialog-content>
      <div class="nft-success-modal-body-inner">
        <ul class="myPoint" NguCarouselPoint>
          <li *ngFor="let j of myCarousel.pointNumbers; let i = index" [class.active]="i==myCarousel.activePoint" (click)="myCarousel.moveTo(i)"></li>
        </ul>
        <ngu-carousel #myCarousel [inputs]="carouselTile" [dataSource]="sliderData">
          <div class="item" *nguCarouselDef="let t; let i = index">
            <div class="event-logo">
              <img class="logo" [src]="setTicketSrc(t.ticket_image)" alt=""
                   [ngStyle]="{'border-radius': setTicketSrc(t.ticket_image).includes('logo-new.svg') ? '0' : '30px',
             'width': setTicketSrc(t.ticket_image).includes('logo-new.svg') ? '180px' : 'auto'}">
            </div>
            <div class="event-info">
              <div><p><span class="event-info-param" i18n="nft-success | event">Event: </span><span>{{t.event_title}}</span></p></div>
              <div><p><span class="event-info-param" i18n="nft-success | ticket">Ticket: </span><span>{{t.ticket_name}}</span></p></div>
              <div><p><span class="event-info-param" i18n="nft-success | payment">Payment: </span><span>{{t.ticket_price | currency: t.ticket_symbol:'symbol-narrow':'1.0'}}</span></p></div>
              <div *ngIf="t.ticket_type"><p><span class="event-info-param" i18n="nft-success | type">Type: </span>
                <span i18n="event participants | credit" *ngIf="t.ticket_type === 'credit'">Credit</span>
                <span i18n="event participants | free" *ngIf="t.ticket_type === 'free'">Free admission</span>
                <span i18n="event participants | cash" *ngIf="t.ticket_type === 'cash'">Cash</span>
                <span i18n="nft-success | registration" *ngIf="t.ticket_type === 'register'">Registration</span>
                <span i18n="event participants | crypto" *ngIf="t.ticket_type === 'crypto'">Crypto</span>
                <span i18n="event participants | unknown" *ngIf="t.ticket_type === 'unknown'">Unknown</span>
              </p></div>
            </div>
          </div>
        </ngu-carousel>

        <hr>

<!--      <p class="short-info-header" i18n="nft-success | what is nft">What is an NFT ticket?</p>-->
      <p class="short-info-header" *ngIf="data.need_to_create_wallet">
        <span>Congratulations <span [innerHtml]="party_emoji"></span><br>A Get-In wallet was created & paired to your account. </span>
        <span>Please backup your wallet recovery information using the recovery kit</span>
      </p>
      <ul class="short-info-list">
<!--        [routerLink]="['/my-tickets/how-to-claim']"-->
        <li *ngIf="purchase_validation == 1" i18n="nft-success | txt1">Upon event creator approval, An NFT ticket will be airdropped to you in a few minutes. Check your inbox for confirmation & further instructions.</li>
        <li *ngIf="purchase_validation == 2" i18n="nft-success | txt2">An NFT ticket will be airdropped to you in a few minutes. Check your inbox for confirmation & further instructions.</li>
      </ul>

        <button class="btn-see-tickets" *ngIf="purchase_validation == 2" (click)="openTicketsModal()" [routerLink]="['/my-tickets']" i18n="nft-success | btn-see-tickets">Click here to see your tickets</button>
        <div style="display: flex;">
          <a *ngIf="main_purchase_identifier.length" class="button-add-to-apple-wallet-link" href="{{api_base_url}}/api/pass-wallet/apple/{{main_purchase_identifier}}" target="_blank">
            <img src="/assets/images/svg-icons/add_to_apple_wallet.svg" alt="" style="height: 50px;">
          </a>
        </div>
      </div>
      <app-modal-footer></app-modal-footer>
    </div>
  </div>

</ng-container>
