import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthAdminGuardService implements CanActivate {

  constructor(
    private userService: UserService,
    public auth: AuthService, 
    public router: Router) {}

  canActivate(): boolean {
    const user = this.auth.getUserInfo();
    if(!user || user.type !== 2){
      this.userService.openLoginModal();
      // this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}