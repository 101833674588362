<div class="svix-settings-modal-header" [ngClass]="{'producer': !cmsPage}">
  <button class="close-btn" (click)="closeModal()">
    <svg class="arrow-close-modal" width="10" height="14" viewBox="0 0 10 14" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M9.49219 1.64063L4.13281 7L9.49219 12.3594L7.85156 14L0.851562 7L7.85156 0L9.49219 1.64063Z"
           [attr.fill]="cmsPage?'black':'white'" />
    </svg>
  </button>
  <p class="svix-settings-title">Svix Settings</p>
</div>
<div class="svix-settings-modal-body" [ngClass]="{'producer': !cmsPage}" mat-dialog-content>
  <mat-progress-spinner
    *ngIf="fetching"
    class="example-margin"
    mode="indeterminate">
  </mat-progress-spinner>
  <ng-container *ngIf="!fetching">
    <button *ngIf="!selectItems.length && admin && cmsPage" (click)="addSvixApp()" class="btn btn-success add">Add</button>
    <h3 *ngIf="svixData?.length == 0" class="text-center">No Svix Data</h3>
    <div *ngIf="svixData?.length > 0">
      <div class="row" style="margin: 0;">
        <div class="col-md-12 col-xs-12">
          <div class="table-responsive">
            <table class="table table-bordered table-hover">
              <thead style="background: #e9e9e9;">
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th *ngIf="admin && cmsPage">App_id</th>
                <th *ngIf="admin && cmsPage">Api_data</th>
                <th>Region</th>
                <th *ngIf="admin && cmsPage">Description</th>
                <th>Options</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of svixData; let i = index;">
                <th>{{item.id}}</th>
                <td>{{admin && cmsPage? item.api_data.name : item.name }}</td>
                <td *ngIf="admin && cmsPage">{{item.app_id}}</td>
                <td *ngIf="admin && cmsPage">{{item.api_data | json}}</td>
                <td>{{admin && cmsPage ? item.credential.region.name : item.region }}</td>
                <td *ngIf="admin && cmsPage">{{item.credential.region.description}}</td>

                <td style="text-align: center">
                  <button (click)="getUrl(item)" title="show URL" class="{{cmsPage ? 'btn btn-success': 'show-url-bnt' }}">
                    show URL
                  </button>
                  <div style="display: flex;justify-content: space-between; margin-top: 10px;" *ngIf="admin && cmsPage">
                    <button title="edit" class="btn btn-warning" style="margin: 0 7px;" (click)="showSvixEditModal(item, i)">
                      <i class="fa fa-edit" style="font-size: 16px;"></i>
                    </button>
                    <button title="delete" class="btn btn-danger" (click)="deleteSvixApp(item, i)">
                      <i style="font-size: 16px;" class="fa fa-trash-o"></i>
                    </button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

<!--EDIT MODAL-->
  <div *ngIf="showEditForm" class="options-popup">
    <div class="modal-inner">
      <div>
        <span (click)="closeEditForm()" type="button" class="closeEditForm btn">&#10006;</span>
      </div>
      <div class="edit-form-container">
        <div class="row">
          <div class="col-md-12 col-xs-12 mb10">
            <h4 class="edit-form-title">Edit {{editingApp.app_id}}</h4>
          </div>
        </div>
        <form [formGroup]="editSvixForm">
          <div class="row">
            <div class="col-md-6 col-xs-12 mb10">
              <label class="edit-form-label" for="name">Name</label>
              <input id="name" class="form-control" type="text" formControlName="name" placeholder="name">
              <p class="item-error" *ngIf="editSvixForm.controls.name.hasError('required')">Required</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-xs-12 mt10">
              <button [disabled]="!editSvixForm.valid"
                      (click)="updateSvixApp(this.editingApp.id, this.editingIndex)" class="btn btn-success"
                      type="button">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!--ADD MODAL-->
  <div *ngIf="selectItems.length" class="options-popup">
    <div class="modal-inner">
      <div class="filter-modal-header">
        <span (click)="closeAddForm()" type="button" class="closeEditForm btn">&#10006;</span>
      </div>
      <div class="add-new-svix-app">
        <h3>Adding new Svix App</h3>
        <select class="form-control svix_credential_id_select" [(ngModel)]="svix_credential_id">
          <option *ngFor="let s of selectItems" [value]="s.id" title="{{s.region.description}}">{{s.region.name +' / ' + s.api_key }}</option>
        </select>
        <button class="btn btn-success mt10" (click)="saveSvixApp()" [disabled]="!svix_credential_id">Save</button>
      </div>
    </div>
  </div>

</div>
