import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class MainSliderService {

  api_base_url = environment.api_base_url;
  private dataSource = new BehaviorSubject(null);
  sliderData = this.dataSource.asObservable();

  constructor(private http: HttpClient) {

  }

  sliderDetails(data: any, sliderLength: number) {
    this.dataSource.next({data, sliderLength});
  }

  getSliderImages() {
      return this.http.get(this.api_base_url + '/api/main-page-sliders',
          {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }


  createSlide(data: any) {
      return this.http.post(this.api_base_url + '/api/main-page-sliders', data,
          {headers: new HttpHeaders({
            'X-Requested-With': 'XMLHttpRequest'
            // 'Content-Type': 'multipart/form-data; boundary='+Math.random()
          })
      });
  }

  editSlide(data: any, id: string) {
    return this.http.post(this.api_base_url + '/api/main-page-sliders/'+ id, data,
      {headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest'
        // 'Content-Type': 'multipart/form-data; boundary='+Math.random()
      })
    });
  }

  previewSliderImage(id: string) {
    return this.http.get(this.api_base_url + '/api/main-page-sliders/' + id,
          {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});
  }

  deleteSliderImage(id: string) {
    return this.http.delete(this.api_base_url + '/api/main-page-sliders/' + id, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  deleteSliderCategory(id: string) {
    return this.http.delete(this.api_base_url + '/api/remove-slider-category/' + id, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }


}
