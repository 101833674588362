<section *ngIf="event_id" class="pending_event">

	<div class="col-md-12 col-xs-12 block_container pt50">

		<div class="col-md-12 col-xs-12">
			<img class="img-responsive successTag" src="/assets/images/svg-icons/order-success.svg">
		</div>
		<div class="col-md-12 col-xs-12 mt20">
      <ng-container *ngIf="auto_approve == 'false'">
        <h1 i18n="@@PendingEventbuginupdateevent" *ngIf="!event_id || !event_status">An error may have occured during the event's update. Go to your personal dashboard to check the event's status</h1>
        <h1 i18n="@@PendingEventeventsentforapproval" *ngIf="event_status ==2">Great! Your event has been submitted for approval</h1>
        <h1 i18n="@@PendingEventeventupdated" *ngIf="event_status !=2">Event updated successfully</h1>
      </ng-container>
      <h1 *ngIf="auto_approve == 'true'">{{'Success! Your event is approved!' | translate:'Success! Your event is approved!'}}</h1>
		</div>
		<div *ngIf="event_id && event_status" class="col-md-12 col-xs-12 view_event text-center mt20">
			<button i18n="@@PendingEventViewEvent" *ngIf="event_status ==2 && auto_approve == 'false'" [routerLink]="['/preview/', event_id]">View your event</button>
			<button i18n="@@PendingEventViewEvent" *ngIf="event_status !=2 || auto_approve == 'true'" [routerLink]="['/', event_id]">View your event</button>
		</div>
		<div class="col-md-12 col-xs-12 view_dashboard text-center mt10">
			<button i18n="@@PendingEventToDashboard" [routerLink]="['/dashboard']">Personal dashboard</button>
		</div>
	</div>

</section>
