import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import {GlobalsService} from '../../globals';
import {CurrencyLocaleService} from '../../services/currency-locale.service';

@Component({
  selector: 'app-analytic-promoters',
  templateUrl: './analytic-promoters.component.html',
  styleUrls: ['./analytic-promoters.component.css']
})
export class AnalyticPromotersComponent implements OnInit {
  @Input() statsData: any;
  @Input() event_id: string;
  @Input() filterText: string;
  @Output() getDetails: EventEmitter<any> = new EventEmitter();
  dir: string;
  constructor(private globals: GlobalsService, private currencyLocale: CurrencyLocaleService) {}
  producer_stat;
  getLocale(currency) {
    return this.currencyLocale.getLocaleForCurrencyPipe(currency)
  }

  showSellerInfo(sellerInfo, type) {
    sellerInfo['salesman_type'] = type;
    this.getDetails.emit(sellerInfo);
  }

  ngOnInit() {
    this.producer_stat = this.statsData.hasOwnProperty('producer_stat') ? this.statsData.producer_stat : null;
    // console.log('event_id', this.event_id);
    this.dir = this.globals.getDir();
  }

}
