import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {BehaviorSubject, lastValueFrom, throwError} from 'rxjs';
import { GlobalsService } from '../globals';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import {catchError, map, tap} from "rxjs/operators";
import {CookieService} from "ngx-cookie-service";
declare var window: any;

@Injectable({
  providedIn: 'root',
})
export class StripePayoutService {
  onSuccessSubj = new BehaviorSubject<any>('');
  createRequestSuccessSubj = new BehaviorSubject<any>('');
  fetching = new BehaviorSubject(false);
  isFetching = this.fetching.asObservable();
  api_base_url = environment.api_base_url + '/api/user-stripe-connect';
  api_getin_base_url = environment.api_base_url;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private globals: GlobalsService,
    private router: Router,
    private _cookieService: CookieService,
  ) {}

  checkStatus() {
    this.authService.isTokenExist();
    return this.http.get(this.api_base_url + '/accounts', {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }),
    });
  }

  createAccount(countryCode) {
    this.authService.isTokenExist();
    return this.http.post(
      this.api_base_url + '/accounts',
      { country: countryCode },
      {
        headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }),
      }
    );
  }

  createRequest(countryCode) {
    return this.http.post(
      this.api_base_url + '/accounts/create-request',
      { country: countryCode },
      {
        headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }),
      }
    ).pipe(tap((res: any)=> {
      if(res && res.code == "success" && res.data) {
       this.createRequestSuccessSubj.next({...res.data, country: countryCode});
      }
      // console.log('getGeoData tap', res)
    }));
  }

  getStripeCountries() {
    return this.http.get(this.api_base_url + '/request', {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }),
    });
  }

  checkAccountComplete(hash) {
    this.authService.isTokenExist();
    return this.http.get(this.api_base_url + `/check-complete/${hash}`, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }),
    });
  }

  deleteAccount(id) {
    return this.http.delete(this.api_base_url + '/accounts/'+id,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      });
  }

  getCountriesList(link) {
    this.authService.isTokenExist();
    return this.http.get(link, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }),
    });
  }

  getStripePayouts(event_id) {
    const token = this._cookieService.get('token');
    return this.http.get(environment.api_base_url+`/api/event-stripe-indirect/${event_id}/payouts`, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest', 'authorization': `Bearer ${token}` }),
    });
  }
  getStripePayoutCharges(event_id, id) {
    const token = this._cookieService.get('token');
    return this.http.get(environment.api_base_url+`/api/event-stripe-indirect/${event_id}/payouts/${id}/charges`, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest', 'authorization': `Bearer ${token}` }),
    });
  }

  //   async create(token, receivedRedirectUri = null, isRedirect = false, event_id = null) {
  //     console.log('create', token, receivedRedirectUri, isRedirect, event_id)
  //     if(!Plaid) {console.log('No Plaid initiated');return}
  //     this.fetching.next(true);

  //      return await Plaid.create({
  //         token: token,
  //         onSuccess: (public_token, metadata) => {
  //           this.onSuccessSubj.next({public_token, metadata})
  //           if(isRedirect) {
  //             this.plaidTokenExchangeRedirect(public_token)
  //           } else {
  //             this.plaidTokenExchange(public_token, event_id);
  //           }
  //         },
  //         onLoad: () => {},
  //         onExit: (err, metadata) => {
  //           console.log('err', err, 'metadata', metadata);
  //           this.fetching.next(false);
  //         },
  //         onEvent: (eventName, metadata) => {
  //           // console.log('eventName', eventName, 'metadata', metadata);
  //         },
  //         //required for OAuth; if not using OAuth, set to null or omit:
  //         receivedRedirectUri: receivedRedirectUri,
  //       });
  //   }

  //   plaidTokenExchange(public_token, event_id) {
  //     this.globals.startLoader();
  //     this.eventService.plaidTokensExchange(public_token).subscribe((res: any) => {
  //       console.log('plaidTokensExchange', res);
  //       if(res.code === 'success') {
  //         this.eventService.plaidGetBankInfo(event_id).subscribe((res: any) => {
  //           if(res.data) {
  //             this.plaidTokenExchangeSubj.next(res.data);
  //           }
  //           this.fetching.next(false);
  //         })
  //       }
  //       this.globals.stopLoader();
  //     })
  //   }

  //   plaidTokenExchangeRedirect(public_token) {
  //     this.globals.startLoader();
  //     this.eventService.plaidTokensExchange(public_token).subscribe((res: any) => {
  //       console.log('plaidTokenExchangeRedirect', res.data);
  //       this.plaidRedirectSubj.next('');
  //       this.plaidTokenExchangeSubj.next('');
  //       if(res.code === 'success') {
  //         let event_id = JSON.parse(localStorage.getItem('plaid_event_id'));
  //         let plaid_page = JSON.parse(localStorage.getItem('plaid_page'));
  //         this.eventService.plaidGetBankInfo(event_id).subscribe((res: any) => {
  //           console.log('plaidGetBankInfo', res)
  //           if(res.data) {
  //             this.plaidTokenExchangeSubj.next(res.data);
  //             this.plaidRedirectSubj.next(res.data);
  //             this.router.navigate(['/'+plaid_page+'/' +event_id]);

  //           }
  //           this.fetching.next(false);
  //         })
  //       }
  //       this.globals.stopLoader();
  //     })
  //   }

  //   plaidTokensLink(event_id) {
  //     this.eventService.plaidTokensLink(event_id).subscribe((res: any) => {
  //       if(res.data) {
  //         localStorage.setItem('link_token', JSON.stringify(res.data.token));
  //         localStorage.setItem('user_plaid_id', JSON.stringify(res.data.user_plaid_id));
  //       }
  //       this.fetching.next(false);
  //     })
  //   }
  //   setPlaidTokenExchangeSubj(data) {
  //     this.plaidTokenExchangeSubj.next(data);
  //   }
  //   clearPlaidTokenExchangeSubj() {
  //     this.plaidTokenExchangeSubj.next('');
  //   }
  //   clearPlaidRedirectSubj() {
  //     this.plaidRedirectSubj.next('');
  //   }


  loadStripeURL(hash) {
    return this.http.get(environment.api_base_url+`/api/finance/payments/stripe/account/${hash}/onboarding_url`, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }),
    });
  }

  async getClientSecretData():Promise<any> {
    const req = this.http.get(this.api_getin_base_url + `/api/finance/payments`, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }),
    }).pipe(
      map( (response: any) => {
        // console.log(response);
        return response.data ? response.data.stripe : '';
      }),
      catchError( error => {
        return throwError(error);
      })
    );
    return await lastValueFrom(req);
  }

  async getAccountClientSecret(hash, type):Promise<any> {
    const req = this.http.get(this.api_getin_base_url + `/api/finance/payments/stripe/account/`+hash+'/embedded/'+type, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }),
    }).pipe(
      map( (response: any) => {
        // console.log(response);
        return response.data ? response.data : '';
      }),
      catchError( error => {
        return throwError(error);
      })
    );
    return await lastValueFrom(req);
  }
}
