import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { EventService } from '../../services/event.service';
import {GlobalsService} from '../../globals';
import {CurrencyLocaleService} from "../../services/currency-locale.service";

@Component({
  selector: 'app-salesman-details',
  templateUrl: './salesman-details.component.html',
  styleUrls: ['./salesman-details.component.css']
})
export class SalesmanDetailsComponent implements OnInit {

  @ViewChild('chargeCashForm') chargeCashForm: NgForm;
  @ViewChild('chargeInput') chargeInput: NgForm;
  userType: number;
  eventId: number;
  currencyCode: string;
  sellerInfo: any;
  seller_cash_withdraw = [];
  genders = null;
  tickets = [];
  seller_coupons = [];
  dir: string;
  cash_sum = 0;
  salesman_type: string = 'salesman'; // salesman or producer

  constructor(private dialogRef: MatDialogRef<SalesmanDetailsComponent>, @Inject(MAT_DIALOG_DATA) public data, private eventService: EventService,
              private globals: GlobalsService, private currencyLocale: CurrencyLocaleService) {
    this.seller_cash_withdraw = this.data.salesmanData.seller_cash_withdraw;
    this.userType = this.data.extraData.userType || 0;
    this.eventId = this.data.extraData.eventId;
    this.currencyCode = this.data.extraData.currencyCode;
    this.sellerInfo = this.data.sellerInfo;
    this.seller_coupons = this.data.salesmanData.coupons;
    this.genders = this.data.salesmanData.genders;
    this.tickets = this.data.salesmanData.tickets;
    this.cash_sum = this.data.sellerInfo.cash_sum;
    this.salesman_type = this.data.sellerInfo.salesman_type;
    //console.log('data', data);
  }

  closeLoginModal() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.dir = this.globals.getDir();
  }
  getLocale(currency) {
    return this.currencyLocale.getLocaleForCurrencyPipe(currency)
  }

  onSubmit() {
    const cashToCharge = this.chargeCashForm.controls.cashToCharge.value;
    // console.log('cashToCharge', cashToCharge, this.chargeInput);
    if (this.chargeInput.valid && cashToCharge > 0) {
      // this.eventService.updateCash(this.sellerInfo[0].salesman_id, this.eventId, cashToCharge).subscribe(
      //   (response: any) => {
      //     this.dialogRef.close(response);
      //   },
      //   error => this.dialogRef.close(error)
      // );
    }
  }

}
