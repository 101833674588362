import {ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit, OnDestroy} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {EventData} from '../interfaces/event-data.interface';
import {EventService} from '../services/event.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppResponse} from '../interfaces/app-response.interface';
import {GlobalsService} from '../globals';
import {DialogService} from '../services/dialog.service';
import {BankFormInputsComponent} from '../bank-form-inputs/bank-form-inputs.component';
import { NavbarService } from '../services/navbar.service';

@Component({
  selector: 'app-bank-account',
  templateUrl: './bank-account.component.html',
  styleUrls: ['./bank-account.component.css']
})
export class BankAccountComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('bankForm') bankForm: BankFormInputsComponent;

  bankStep: UntypedFormGroup;
  event: UntypedFormGroup;
  event_id: number;
  eventData: EventData;
  submitted: boolean = false;
  formsubmited: boolean = false;
  bootstrapBoolean: boolean = false;
  feedUrl;
  dataForForm: any = null;
  showBankForm = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private eventService: EventService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public globals: GlobalsService,
    private dialogService: DialogService,
    private changeDetector: ChangeDetectorRef,
    private navbar: NavbarService
  ) { }

  ngAfterViewInit() {
    // document.getElementById('footer').style.display = 'none';
  }

  ngOnInit() {
    this.navbar.setIsNewUi(true);
    setTimeout(() => {this.navbar.noPadding = 'removePadding';}, 200);
    this.navbar.hide();
    if (this.activatedRoute.params) {
      this.activatedRoute.params.subscribe(params => {
        if (this.bootstrapBoolean) {
          this.resetAllData();
        }
        this.event_id = params['event_id'];
        this.getEventData();
      });
    }
  }

  closePage() {
    this.router.navigate(['/dashboard']);
  }

  ngOnDestroy() {
    this.navbar.setIsNewUi(false);
    // this.navbar.show();
    this.navbar.noPadding = '';
  }

  resetAllData() {
    this.eventData = undefined;
    this.bootstrapBoolean = false;
    this.feedUrl = undefined;
    this.formsubmited = false;
  }

  getEventData() {
    this.eventService.getEventData(this.event_id, 'bank').subscribe(
      (eventData: { data: EventData }) => {
        console.log('getEventData', this.event_id, eventData);
        this.eventData = eventData.data;
        if ( this.eventData.bank['event_id'] == this.event_id ) {
          this.dataForForm = this.eventData;
        }
        this.formBootstrap();
      }
    )
  }

  formBootstrap() {
    this.event = this.initEventForm();
    this.bootstrapBoolean = true;
  }

  initEventForm(): UntypedFormGroup {
    return new UntypedFormGroup({}, { updateOn: 'blur' });
  }

  initBankStep(bankFormGroup) {
    this.event.addControl('bank', bankFormGroup);
    this.bankStep = bankFormGroup;
  }

  updateValueOfBankForm(bankFormGroup) {
    this.event.removeControl('bank');
    this.event.addControl('bank', bankFormGroup);
    this.bankStep = bankFormGroup;
  }

  submitForm() {
    console.log(this.event)
    this.globals.startLoader();

    if (this.event.invalid) {
      this.markFormGroupTouched(this.event);
      this.submitted = true;
      this.globals.stopLoader();
      return;
    } else {
      this.setEventData();
    }
  }

  setEventData() {
    this.formsubmited = true;
    this.eventService.setEventData( this.event_id, 'bank', {bank: this.event.value} )
      .subscribe(
        (result: AppResponse) => {
          this.router.navigate(['/dashboard'])
            .then(() => {
              this.dialogService.alertsModal('success', 'bankUpdated', 3000, true)
            });
          this.globals.stopLoader();
        },
        (error: AppResponse) => {
          this.dialogService.alertsModal((error.code) ? 'editEvent' : 'errors', (error.code) ? error.code : 4 , 5000, false, error.log_id);
          this.globals.stopLoader();
        }
      )
  }

  setBankFromDraft(e) {
    if(e.target.value == 1) {
      this.eventData.bank['event_id'] = this.event_id;
      this.dataForForm = this.eventData;
      this.showBankForm = false;
      this.changeDetector.detectChanges();
      this.showBankForm = true;
    }
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

}
