<section class="cms_reports" *ngIf="((event != 305412 && event != 304595 && event != 8563) && !isAdmin) || isAdmin">
	<div class="row">
		<div class="col-md-12 col-xs-12">
			<h1>Reports</h1>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12 col-xs-12">
			<select *ngIf="reportTypes?.length > 0" [(ngModel)]="selectedReportTypeCategory" class="form-control mb10" (change)="initFields()" >
				<option [ngValue]="-1">Choose report type</option>
				<option i18n="report category option | options" *ngFor="let reportTypeCategory of reportTypes; let i = index" [ngValue]="i">{
						reportTypeCategory.title, select,
						Participants list {Participants list}
						Salesmen list {Salesmen list}
						Producer sales {Producer sales}
						Managers reports {Managers reports}
						Personal sales report {Personal sales report}
            Event abandoned purchase statistics {Event abandoned purchase statistics}
            other {{{reportTypeCategory.title}}}
					}</option>
			</select>
		</div>
		<div class="col-md-12 col-xs-12">
			<select *ngIf="reportTypes[selectedReportTypeCategory]?.reports.length > 0" [(ngModel)]="selectedReportType" class="form-control" >
				<option i18n="@@ReportSelectReport" [ngValue]="-1">Select report</option>
				<option i18n="@@ReportSelectReportOptions" *ngFor="let reportType of reportTypes[selectedReportTypeCategory]?.reports" [ngValue]="reportType.value">{
						reportType.title, select,
					Events periodic reports {Periodic events report}
          Events list with account number and producer name {Event list incl. account numbers and producer names}
          New Report {New report}
          Pending approval participants {Attendees pending approval}
          Approved participants {Approved attendees}
          Declined participants {Attendees Declined}
          Refunded participants {Refunds}
          Rejected transactions {Rejected transactions}
          Rejected refunds {Declined refunds}
          Hidden participants {Hidden attendees}
          External participants {External attendees}
          Approved and pending participants {Approved + pending attendees}
						other {{{reportType.title}}}
					}</option>
			</select>
		</div>
	</div>
	<div class="row" *ngIf="reportTypes[selectedReportTypeCategory]?.reports.length > 0">
		<div class="col-md-4 col-xs-4">
			<input type="radio" name="period" [value]="0" [(ngModel)]="reportPeriodOption"><span i18n="@@ReportWholeevent">Whole event</span>
		</div>
		<div class="col-md-4 col-xs-4">
			<input type="radio" name="period" [value]="1" [(ngModel)]="reportPeriodOption"><span i18n="@@ByDates">By dates</span>
		</div>
		<div class="col-md-4 col-xs-4">
			<input type="radio" name="period" [value]="2" [(ngModel)]="reportPeriodOption"><span i18n="@@Daily">Daily</span>
		</div>
    <div class="col-xs-12" *ngIf="isAdmin" style="color: gray;">
      <span t key="reports.createNewReportLink" default="To create new Report use: ">To create new Report use: </span>
      <a href="https://metabase.get-in.com/" target="_blank" style="font-size: 14px;">Metabase</a>
    </div>
	</div>
	<div class="row" *ngIf="reportPeriodOption == 1 && reportTypes[selectedReportTypeCategory]?.reports.length > 0">
		<div class="col-md-6 col-xs-6">
			<span i18n="@@Reportstartdate"> Start date </span>
			<input type="date" class="form-control" [(ngModel)]="startDate">
		</div>
			<div class="col-md-6 col-xs-6">
				<span i18n="@@Reportenddate"> End date </span>
				<input type="date" class="form-control" [(ngModel)]="endDate">
			</div>
	</div>
	<div class="row downloadBtn mt20" *ngIf="selectedReportType != -2">
		<div class="col-md-4 col-xs-4">
			<button i18n="@@DownloadReport" [disabled]="!areFieldsCorrect()" (click)="getReport()" class="btn btn-primary">Download report</button>
		</div>
	</div>
</section>
