<div class="pass-modal-header">
  <button class="close-btn" (click)="close()">
    <img src="/assets/images/svg-icons/close.svg" alt="transfer-money">
  </button>
</div>

<div class="modal-body new-form">
  <h3 style="color: #ffffff;margin: 30px auto 15px;" t key="managePasscodes.PasswordProtection" default="Password Protection"></h3>
  <button (click)="togglePassword()" *ngIf="passcodes.length" type="button"
          title="{{'managePasscodes.ShowPasscodes' | translate:'Show Passcodes'}}"
          style="padding: 15px;" class="btn btn-action">
    <img [ngStyle]="{'filter': show_pass?'contrast(1)':'contrast(0)'}" src="/assets/images/svg-icons/eye.svg" />
  </button>
  <form [formGroup]="passcodesForm" (ngSubmit)="onSubmit()">
    <div formArrayName="purchase_passcodes">
      <div *ngFor="let code of passcodes.controls; let i=index" style="margin-bottom: 10px;">
        <div [formGroupName]="i" class="form-group" style="display: flex; margin-bottom: 2px;">
          <input formControlName="passcode" class="form-control"
                 placeholder="******"
                 [type]="show_pass?'text':'password'"
                 [maxlength]="30"
                 [disabled]="fetching"
                 [required]="true" autocomplete="off">
          <button class="btn btn-action"
                  title="{{'managePasscodes.Delete' | translate:'Delete'}}"
                  [disabled]="fetching"
                  type="button" (click)="removePassCode(i, code)" style="padding: 0 15px;">x</button>
        </div>
        <span *ngIf="code.touched && code.invalid" style="padding: 0 15px;" class="item-error" t key="managePasscodes.Required" default="Required"></span>
      </div>
    </div>

    <div class="text-center">
      <button class="btn btn-action" type="button"
              *ngIf="passcodes.length <= 30"
              [disabled]="fetching"
              (click)="addPassCode()"
              t key="managePasscodes.Addpasscode" default="Add Passcode"></button>
    </div>
    <button *ngIf="isData || passcodes.length" [disabled]="!passcodes.valid || fetching || !passcodes.length" type="submit" class="btn btn-save" t key="managePasscodes.SAVE" default="SAVE"></button>
  </form>

</div>
