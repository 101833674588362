import { Injectable } from '@angular/core';
import { NftSuccessModalComponent } from '../dashboard-sub-pages/my-tickets/nft-success-modal/nft-success-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { GlobalsService } from '../globals';
@Injectable({
  providedIn: 'root'
})
export class NftThankYouModalService {

  constructor(private dialog: MatDialog,
              private globals: GlobalsService) { }


  openThankYouPage(params) {
    if ('isNft' in params.data.nft && !params.data.nft.isNft) {
      console.log('Modal doesnt show. isNft - false');
      return;
    }

    if (params.data.nft && 'tickets' in params.data.nft && !params.data.nft.tickets.length) {
      console.log('Modal doesnt show. No params.data.nft.tickets.length');
      return
    }
    if ('main_purchase_identifier' in params.data && params.data.main_purchase_identifier.length) {
      console.log('Have main_purchase_identifier');
    }


    const tickets = params.data.nft.tickets.map(t => {
      return {
        event_id: params.data.page_path,
        ticket_image: t.image,
        event_title: params.data.event_name,
        ticket_name: t.title,
        ticket_price: t.price,
        ticket_symbol: params.data.currency,
        ticket_type: params.data.nft.type
      }
    });

    setTimeout(() => {
      this.dialog.open(NftSuccessModalComponent, {
        data: {
          localeId: this.globals.getDir(),
          tickets: tickets,
          main_purchase_identifier: ('main_purchase_identifier' in params.data) ? params.data.main_purchase_identifier : '',
          purchase_validation: params.data.purchase_validation,
          need_to_create_wallet: params.data.need_to_create_wallet
        },
        panelClass: 'nft-success-modal',
        maxWidth: '578px',
        maxHeight: window.innerWidth < 768 ? '75vh' : '90vh'
      });
    }, 5300);
  }

}
