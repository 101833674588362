<h1 mat-dialog-title class="text-center dialog-title"
    t key="createEditEvent.dataSourceDialog.title" default="A draft of this event already exists"></h1>
<mat-dialog-content class="text-center">
<div class="row">
    <div class="dialog-text col-md-12 col-xs-12 mb5 info mb20">
      <b t key="createEditEvent.dataSourceDialog.NoDraft" default="* Not using the draft will delete it permanently."></b>
    </div>
</div>
<div class="row">
  <div class="col-md-12 col-xs-12 mb15">
    <button class="btn btn-success" [matDialogClose]="false"
            t key="createEditEvent.dataSourceDialog.LoadEventDraft" default="Load event draft"></button>
  </div>
  <div class="col-md-12 col-xs-12">
    <button class="btn btn-danger pull-left" [matDialogClose]="true"
            t key="createEditEvent.dataSourceDialog.DiscardEventDraft" default="Discard event draft and edit event"></button>
  </div>
</div>
</mat-dialog-content>
