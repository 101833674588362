import { Component, OnInit } from '@angular/core';
import {IntercomService} from "../services/intercom.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private intercomService: IntercomService) { }

  ngOnInit(): void {

  }

  bootIntercom() {
    this.intercomService.boot();
    this.intercomService.show();
  }

  scrollUp() {
    window.document.querySelector('.mat-sidenav-content').scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}
