import {
  Component, ElementRef, EventEmitter,
  Input,
  OnInit, Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {EventService} from "../../services/event.service";

@Component({
  selector: 'app-lineup',
  templateUrl: './lineup.component.html',
  styleUrls: ['./lineup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LineupComponent implements OnInit{
  @Input() event_id: number;
  panelOpenState = false;
  lineup = [];

  @Output() scrollToBottomDiv: EventEmitter<any> = new EventEmitter();
  constructor(private eventService: EventService) {
  }

  ngOnInit() {
    this.eventService.getLineUpArtists(this.event_id).subscribe((res: any) => {
      if(res.data) {
        this.lineup = res.data;
      }
    }, error => {

    })
  }

  expand(e) {
    e.stopPropagation();
    this.panelOpenState = true;
  }
  scrollToArtists() {
    this.scrollToBottomDiv.emit('lineupEvent');
  }

}
