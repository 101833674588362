import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlobalsService } from '../globals';
import { DialogService } from '../services/dialog.service';
import { WalletConnectService } from '../services/wallet-connect.service';

@Component({
  selector: 'app-wallet-connect',
  templateUrl: './wallet-connect.component.html',
  styleUrls: ['./wallet-connect.component.css']
})

export class WalletConnectComponent implements OnInit, OnDestroy {
  isOpenWallets = false;
  selectedAccount: any | null;
  accountBalances: any[] | null = [];
  userAccounts: any;
  // selectedAddress: any | null;
  walletAddress: any | null
  defaultGasPrice: number | null;
  estimatedGas: number = 0;
  defaultNonce: any;
  accountBalance: any;
  // currencyRates: any;
  priceInUsd: any;
  eventTicketsData: any | null;
  isConnected: boolean = false;
  hasBalances: boolean = false;
  hasPemding: boolean = false;
  ticketsPrice: number | null = null;
  eventCurRate: number | null;
  usdCurRate: number | null;
  transactionData: any = {};
  isCheckTrnStatus: boolean = false;
  transaction: any = {};
  stopingCheckStatus: boolean = false;

  transactionStatus: number = 0;
  currentTransaction: any;
  currentTransactionHash: any;
  isChecking = false;
  chainData: any;
  dbTransactionState: string = '';
  isShowButton: boolean = false;
  canCheckTransaction: boolean = false;
  isSuccessful: boolean = false;
  founds:boolean = false;
  transactionUrl: string | null;
  isShowUrl: boolean = false;
  isUsd: boolean = false;
  transaction_: any;
  showConnectBtn: boolean = false; // to show button connect on the page
  showReconnectBtn: boolean = false;
  isUser: boolean = false;
  public subscriptions: Subscription[] = [];
  prevStates: any[] = [];
  networkIdName: string = '';
  private networkIds: any[] = [];
  direction: string | null;
  isCoinSupported = true;
  stopChecking = false;

  @Output() getTransactionWallet: EventEmitter<any> = new EventEmitter();
  @Output() getTrnBtnStatus: EventEmitter<boolean> = new EventEmitter();

  @Input() set transactionBtnStatus(value: boolean) {
    // console.log('set Send transaction', value);
    value ? this.sendTransaction() : false;
  }

  @Input() set openWallets(value: boolean) {
    // console.log('set openWallets', value);
    this.isOpenWallets = value;
    // this.onConnect();
  }
  @Input() set walletData(value) {
    if (value && value.payment_details) {
      this.eventTicketsData = value;
      this.getCryptoCurrencyPrice();
    }
    // console.log('set walletData', this.eventTicketsData, value);
  };

  constructor(private walletConnectService: WalletConnectService, public globals: GlobalsService, private dialogService: DialogService, private clipboardService: ClipboardService) {
    this.direction = this.globals.getDir();
    this.networkIds = this.walletConnectService.networkIds;
    const refrashStatus = this.walletConnectService.refreshAccountStatus.subscribe(status => {
      this.refreshAccountData(status);
      // console.log('refreshAccountData', status);
    });
    this.subscriptions.push(refrashStatus);
    const fachStatus = this.walletConnectService.fetchAccountStatus.subscribe(accountData => {
      this.networkIdName = this.walletConnectService.networkIdName;
      // console.log('AccountData', accountData, this.networkIdName);
      if (accountData) {
        // console.log('fetch and Set Account Data...');
        this.setAccountData(accountData);
      }
    });
    this.subscriptions.push(fachStatus);
    const walletErrors = this.walletConnectService.getwalleChainError.subscribe(error => {
      if (error == 'network') {
        this.onDisconnect();
      } else if (error == 'coin') {
        // this.sendTransactionBtn(false);
        this.isCoinSupported = false;
        this.stopTimer();
      }
    });
    this.subscriptions.push(walletErrors);
  }

  ngOnInit(): void {
    this.isUser = (!environment.production) || (this.walletConnectService.user_id == 0 || this.walletConnectService.user_id === 299 || this.walletConnectService.user_id === 178); // to test
    this.walletAddress = this.walletConnectService.getinWallet;
    // console.log('Is user', this.isUsd, !environment.production, this.walletConnectService.user_id, this.walletConnectService.user_id === 0, this.walletConnectService.user_id === 299, this.walletConnectService.user_id === 178);
    const timerStatus = this.walletConnectService.timerStatus.subscribe(status => {
      // console.log('Timer status', status);
      this.refreshPriceData();
    });
    this.subscriptions.push(timerStatus);
    this.isShowUrl = this.walletConnectService.user_id && this.walletConnectService.user_id !== 0 ? true : false;
    const transactionStatus = this.walletConnectService.transactionStatus.subscribe((transaction:any) => {
      // console.log('transaction Status subscribe', transaction);
        this.isShowButton = false;
        const res = this.saveTx(transaction.txHash);
        // console.log('Save transaction hash before', res);
        this.saveWalletConnectTransaction(res, transaction.state, transaction.data, transaction.response);
        this.transactionStatus = 0;
        setTimeout(() => {
          this.walletConnectService.getTransactionByHash(transaction.txHash).then(response => {
            // console.log('Got currentTransaction', response);
            this.isChecking = true;
            this.transactionStatus = 0;
            this.isShowButton = false;
            this.globals.stopLoader();
            if (transaction.event === 0 && !response) {
              const res = this.saveTx(transaction.txHash);
              // console.log('Transaction data not found, we will save transaction HASH as pending transaction', res);
              this.saveWalletConnectTransaction(res, transaction.state, transaction.data, transaction.response);
            }
            if (transaction.event === 0 && response && (!response.blockHash && !response.blockNumber)) { // save transaction into DB only once for every state
                // if it blocks are blanked, they are have pending status
                // console.log('save transaction into DB only once as pending');
                // The status of the transaction is specified. Do not leave the page or come back later.
                this.saveWalletConnectTransaction(response, transaction.state, transaction.data, transaction.response); // save transaction data into DB
                // continue checking status
            }
            if (transaction.event >= 0 && response && response.blockHash && response.blockNumber) { // transaction status is success
              // this.checkTransactionStatus.next({response: response, status: 'success', isChecking: false});
              // console.log('stop checking transaction status if its success and save transaction data into DB');
              this.globals.stopLoader();
              transaction.state === 'receipt' ? this.isChecking = true : this.isChecking = false;
              // this.isChecking = false;
              this.isShowButton = false;
              // transaction.state === 'receipt' ? this.transactionStatus = 0 : this.transactionStatus = 1;
              this.transactionStatus = 1;
              this.stopingCheckStatus = true;
              // transaction.state !== 'receipt' ? this.stopCheckStatus() : false;
              this.stopCheckStatus(); // unsubsribe checking status
              this.saveWalletConnectTransaction(response, transaction.state, transaction.data, transaction.response); // save transaction data into DB
            }
          }).catch(error => {
            // this.checkTransactionStatus.next({response: error, status: 'error', isChecking: true});
            this.transactionUrl ? this.isShowUrl = true : false;
            if (!transaction.data) {
              this.isChecking = true;
              this.transaction.message = 'Waiting...';
              console.log('We are waiting when timeout will finish, to get an error...');
            } else {
              this.isChecking = false;
            }
            this.isShowButton = true;
            console.log('Get PendingTransactions error', error);
            this.stopCheckStatus(); // unsubsribe checking status
            this.transactionStatus = 0;
            this.dialogService.alertsModal('cryptoCurrency', 'walletTransactionHash', 3000, false);
            this.onDisconnect(); // wallet disconnecting
          });
        }, 2000 );
    });
    this.subscriptions.push(transactionStatus);
    const sessionStatus = this.walletConnectService.getWalletSessionStatus.subscribe((status) => {
      // console.log('sessionStatus', status);
      if (status === 'close') {
        // console.log('Dapp connect was closed!');
        // need to clear and close all UI
        this.globals.stopLoader();
        this.transaction = {};
        this.isConnected = false;
        this.hasBalances = false;
        this.accountBalances = [];
        this.selectedAccount = null;
      } else if (status === 'connected') {
        this.showReconnectBtn = false;
      } else if (status === 'disconnected') {
        this.globals.stopLoader();
        this.showReconnectBtn = true;
      } else if (status === 'nodata') {
        this.globals.stopLoader();
        this.dialogService.alertsModal('errors', 2 ,3000, false);
      } else if (status === 'changeChain') {
        // TODO: If these changes will invoke errors need to reload the page // window.location.reload();
        this.isCoinSupported = true;
        this.isCheckTrnStatus = false;
        this.walletConnectService.chainRemoveListener();
        // TODO: If these changes will invoke errors need to reload the page // window.location.reload();
      } else if (status === 'changeNetwork') {
        this.isCoinSupported = true;
        this.isCheckTrnStatus = false;
        this.walletConnectService.networkRemoveListener();
      } else if (status === 'changeAccount') {
        this.isCoinSupported = true;
        this.isCheckTrnStatus = false;
        this.walletConnectService.accountRemoveListener();
      }
    });
    this.subscriptions.push(sessionStatus);
  }

  saveTx(hash) {
    let res: any = {};
      const tx = this.getDraftTransaction();
      res['hash'] = hash;
      res['from'] = tx.from;
      res['to'] = tx.to;
      res['gas'] = this.walletConnectService.hexToNumberString(tx.gas);
      res['gasPrice'] = this.walletConnectService.hexToNumberString(tx.gasPrice);
      res['nonce'] = this.walletConnectService.hexToNumberString(tx.nonce);
      res['value'] = this.walletConnectService.hexToNumberString(tx.value);
    return res;
  }

  checkFounds(balance) {
    this.founds = +balance > this.transactionData.ticketPriceTotal;
    // console.log('checkFounds: ', +balance, this.transactionData.ticketPriceTotal, this.founds);
  }

  refreshPriceData() {
    const price = this.eventTicketsData.payment_details.payment;
    const currency = this.eventTicketsData.payment_details.currency.toLowerCase();
    this.isUsd = currency === 'usd';
    this.walletConnectService.refreshPriceData(currency).then((result: any) => {
      this.defaultGasPrice = result.gasPrice.defaultGasPrice;
      const eventCurrency = this.networkIdName ? this.networkIdName : 'ethereum';
      // console.log('refreshPriceData result', eventCurrency, currency);
      if (result.simplePrice && result.simplePrice[eventCurrency]) {
        this.eventCurRate = result.simplePrice[eventCurrency][currency]; // event currency rate
        this.usdCurRate = result.simplePrice[eventCurrency]['usd'];
        this.ticketsPrice = +parseFloat((price / this.eventCurRate).toString()).toFixed(8);
        this.getTransactionData();
      }
      // console.log('refreshPriceData', result, this.ticketsPrice, this.defaultGasPrice, this.eventCurRate, this.networkIdName);
    }).catch(error => {
      // this.getCryptoCurrencyPriceV2('gas');
      console.log('CoinGecko Error:', error);
    });
    // console.log('event currency details', price, currency);
  }

  getCryptoCurrencyPrice() {
    const currency = this.eventTicketsData.payment_details.currency.toLowerCase();
    this.isUsd = currency === 'usd';
    const price = this.eventTicketsData.payment_details.payment;
    const tx = {ids: ['ethereum,binancecoin,binance-coin,bitcoin,matic-network,fantom,xdai,usdc,busd,tether'], vs_currencies: currency !== 'usd' ? [currency+",usd"] : [currency]};
    // console.log('handlePriceTickets', currency, price, tx);
    this.walletConnectService.simplePrice(tx)
    .then((value: any) => {
      // console.log('simplePrice get value: ', value);
        if (value && !value.error) {
        const eventCurrency = this.networkIdName ? this.networkIdName : 'ethereum';
        this.eventCurRate = value[eventCurrency][currency];
        this.usdCurRate = value[eventCurrency]['usd'];
        this.ticketsPrice = +parseFloat((price / this.eventCurRate).toString()).toFixed(8);
        // console.log('simplePrice', this.ticketsPrice, this.networkIdName, this.eventCurRate, this.usdCurRate);
        if (!this.showConnectBtn) {
          setTimeout(() => {
            this.onConnect();
          }, 1000);
        }
      } else {
        console.log('Unknown Error: ', value);
      }
    }).catch(error => {
      // this.getCryptoCurrencyPriceV2('connect', tx);
      console.log('getCryptoCurrencyPrice error:', error);
    });
    // console.log('getCryptoCurrencyPrice');
  }

  async getCryptoCurrencyPriceV2(val, tx?) {
    if (val == 'gas') { this.walletConnectService.getGasPrice().then(val => {
      this.defaultGasPrice = val;
      // console.log('getGasPrice is: ', val);
    });}
    this.walletConnectService.coinCap(tx)
    .then((response: any) => {
      const currency = this.eventTicketsData.payment_details.currency.toUpperCase();
      this.isUsd = currency === 'USD';
      const price = this.eventTicketsData.payment_details.payment;
      // console.log('get CoinCap rates: ', response);
      if (response && response.data.length) {
        const eventCurrency = this.networkIdName ? this.networkIdName : 'ethereum';
        response.data.forEach(value => {
          value.symbol == currency ? this.eventCurRate = value.rateUsd : false;
          value.symbol == 'USD' ? this.usdCurRate =  value.rateUsd : false;
          this.ticketsPrice = +parseFloat((price / this.eventCurRate).toString()).toFixed(8);
          // console.log('CoinCap: ', value, value.symbol, value.rateUsd, this.ticketsPrice, this.networkIdName, this.eventCurRate, this.usdCurRate, this.defaultGasPrice);
        });
      }
      if (val == 'connect') {
        if (!this.showConnectBtn) {
          setTimeout(() => {
            this.onConnect();
          }, 1000);
        }
      } else if (val == 'gas') {
        this.getTransactionData();
      }
    })
  }

  getPriceOfETHInUSD() {
    this.walletConnectService.getStorageAt(this.selectedAccount).then(result => {
      // console.log('getStorageAt', result);
      const hexValueOfVal = '0x'.concat((result).slice(-32));
      return parseFloat(this.walletConnectService.getFromWei(hexValueOfVal));
    }).catch(error => {
      console.log('getStorageAt error', error);
    });
  }

  setAccountData(accountData) {
    this.isConnected = true;
    this.defaultNonce = accountData.defaultNonce;
    this.estimatedGas = accountData.estimatedGas;
    this.defaultGasPrice = accountData.defaultGasPrice;
    this.userAccounts = accountData;
    this.selectedAccount = accountData.accounts[0];
    this.chainData = accountData.chainData;
    accountData.eventCurrency = this.eventTicketsData.payment_details.currency.toLowerCase();
    // Purge UI elements any previously loaded accounts // Go through all accounts and get their ETH balance
    // and Display fully loaded UI for wallet data
    // walletBalance is a BigNumber instance https://github.com/indutny/bn.js/
    this.walletConnectService.getBalance(accountData).then( async (value) => {
      // console.log('getBalance: ', value);
      this.accountBalances = value;
      // this.accountBalances = this.walletConnectService.accountBalance;
      this.hasBalances = true;
      this.getTransactionData();
      // console.log('accountBalances', this.accountBalances);
      this.accountBalances.length > 0 ? await this.checkFounds(this.accountBalances[0].humanFriendlyBalance) : false;
      // this.checkBtn();
      this.refreshPriceData();
      this.startTimer(); // refresh prices every 15 seconds
    }).catch(error => {
      console.log('Get accountBalances error', error);
    });
  }

  getTransactionData() {
    // console.log('TransactionData', this.eventCurRate, this.usdCurRate);
    const trnData: any = {};
    trnData.gas = this.estimatedGas;
    trnData.gasPrice = this.defaultGasPrice;
    trnData.feeWei = (this.estimatedGas * this.defaultGasPrice);
    trnData.feeBN = this.walletConnectService.toBN(this.estimatedGas * this.defaultGasPrice).toString();
    trnData.gasPriceWei = parseFloat(this.walletConnectService.getFromGWei(this.defaultGasPrice.toString()));
    trnData.gasPriceEth = this.walletConnectService.getFromWei(this.defaultGasPrice.toString());
    trnData.eventCurRate = this.eventCurRate;
    trnData.usdCurRate = this.usdCurRate;
    trnData.fee = parseFloat(this.walletConnectService.getFromWei((this.estimatedGas * this.defaultGasPrice).toString()));
    trnData.ticketPriceTotal = this.ticketsPrice + trnData.fee;
    trnData.ticketPrice = this.ticketsPrice;
    this.transactionData = trnData;
    this.blinking();
    // console.log('get Transaction Data', trnData);
    this.accountBalances.length > 0 ? this.checkFounds(this.accountBalances[0].humanFriendlyBalance) : false;
    this.checkBtn();
  }

  checkBtn() {
    // console.log('checkBtn', !this.isCheckTrnStatus, this.founds, this.dbTransactionState !== 'stopped', this.isCoinSupported);
    if (this.isConnected && !this.isCheckTrnStatus && this.founds && this.dbTransactionState !== 'stopped' && this.isCoinSupported) {
      this.sendTransactionBtn(true);
    } else {
      this.sendTransactionBtn(false);
    }
  }

  // getChecking() {
  //   let value: any = {};
  //   value.gasPriceGwei = this.walletConnectService.setToGWei("0.000021");
  //   value.gasPriceWei = this.walletConnectService.setToWei("0.0000000035");
  //   value.gasPriceEth = this.walletConnectService.getFromGWei(value.gasPriceGwei);
  //   value.gasPrice21000 = this.walletConnectService.getFromGWei("21000");
  //   this.getDefaultGasPrice();
  //   this.getDefaultNonce();
  //   // this.minGasPrice();
  //   this.getGasEstimation('75');
  // }

  minGasPrice() {
    this.walletConnectService.getBlock("latest").then(gas => {
      let value: any = {};
      value.gasLimit = gas.gasLimit;
      // const minGas = gas.minimumGasPrice;
      value.baseFeePerGas = gas.baseFeePerGas;
      value.minGasFee = (value.baseFeePerGas / 20 * 21).toFixed(0);
      value.minBaseGasInEth = this.walletConnectService.getFromWei(value.baseFeePerGas.toString());
      value.minGasInEth = this.walletConnectService.getFromWei(value.minGasFee);
      value.gasLimitInEth = this.walletConnectService.getFromWei(value.gasLimit.toString());
      // console.log('minGasPrice', value, 'gas', gas);
    });
  }

  getDefaultGasPrice() {
    this.walletConnectService.getGasPrice().then((price: number) => {
      // console.log('GasPriceDefault', price, 'gWei', this.walletConnectService.getFromGWei(price), 'inEth', this.walletConnectService.getFromWei(price));
      this.defaultGasPrice = price;
    });
  }

  getDefaultNonce() {
    this.walletConnectService.getNonce(this.selectedAccount).then(
      defaultNonceValue => {
      // console.log('defaultNonceValue: ', defaultNonceValue);
      this.defaultNonce = defaultNonceValue;
    });
  }

  blinking() {
    const qsAll = document.querySelectorAll<HTMLElement>("#wallet-transactions");
    qsAll.forEach(selector => {
      selector.classList.add('blink');
    });
    setTimeout(() => {
      qsAll.forEach(selector => {
        selector.classList.remove('blink');
      });
    }, 1000);
  }

  // checkTransaction() {
  //   const hex = this.getDraftTransaction();
  //   const gas = this.walletConnectService.hexToNumberString(hex.gas);
  //   const gasPrice = this.walletConnectService.hexToNumberString(hex.gasPrice);
  //   const amount = this.walletConnectService.hexToNumberString(hex.value);
  //   const gasWei = this.walletConnectService.getFromGWei(gas);
  //   const priceWei = this.walletConnectService.getFromWei(gasPrice);
  //   const priceGwei = this.walletConnectService.getFromGWei(gasPrice);
  //   console.log('Draft', hex, 'values',  gas, gasPrice, amount, parseFloat(priceGwei).toFixed(0), 'inWei:', gasWei, priceWei, 'amountFee', this.walletConnectService.getFromGWei((parseFloat(gas) * parseFloat(priceGwei)).toFixed(0).toString()), this.walletConnectService.getFromWei(amount));
  // }

  getDraftTransaction() {
    // Draft transaction
    const tx = {
      from: this.selectedAccount,
      to: this.walletAddress,
      data: "0x", // Required The byte code of the contract. Either a ABI byte string containing the data of the function call on a contract, or in the case of a contract-creation transaction the initialisation code.
      gasPrice: this.walletConnectService.stringToHex(this.defaultGasPrice), // The gas price in wei to use for transactions
      gas: this.walletConnectService.stringToHex(this.estimatedGas), // The maximum gas provided for a transaction (gas limit).
      value: this.walletConnectService.stringToHex(this.walletConnectService.setToWei(this.ticketsPrice.toString())), // The value transferred for the transaction in wei, also the endowment if it’s a contract-creation transaction.
      nonce: this.defaultNonce, // The nonce to use when signing this transaction. Default will use web3.eth.getTransactionCount().
      // Integer of the nonce. This allows to overwrite your own pending transactions that use the same nonce.
    };
    return tx;
  }

  getGasEstimation(gwei?) {
    const tx = {
      from: this.selectedAccount,
      to: this.walletAddress,
      data: "0x",
      value: gwei && this.accountBalance > 0 ? this.walletConnectService.stringToHex(gwei) : "0x"
    }
    this.walletConnectService.estimateGas(tx).then((value: number) => {
      // console.log('estimateGas', value, 'inEth', this.walletConnectService.getFromGWei(value.toString()));
      this.estimatedGas = value;
    })
  }

  getPendingBlock(param = "pending") {
    this.walletConnectService.getBlock('pending').then(res => {
      // console.log('get Pending Block', res);
    });
    this.walletConnectService.getBlock('latest').then(res => {
      // console.log('get latest Block', res);
    });
  }

  getBlocks(param) {
    const accAddress = this.selectedAccount.toLowerCase();
    this.walletConnectService.getBlock(param).then(block => {
      // console.log('transactions block', block);
      if (block && block.transactions) {
        block.transactions.forEach(trn => {
          if (accAddress == trn.from.toLowerCase() || accAddress == trn.to.toLowerCase()) {
            // console.log('transactiion', trn.hash, trn);
          }
        });
      } else if (block && block.to) {
        if (accAddress == block.from.toLowerCase() || accAddress == block.to.toLowerCase()) {
          // console.log('transactiion', block.hash);
        }
      }
    });
  }

  getBlockByHash(num?) {
    const blockHash = num ? num : 14525957;
    const accAddress = this.selectedAccount.toLowerCase();
    this.walletConnectService.getBlock(blockHash).then(block => {
      // console.log('transactions block', block);
      if (block && block.transactions) {
        block.transactions.forEach(trn => {
          if (accAddress == trn.from.toLowerCase() || accAddress == trn.to.toLowerCase()) {
            // console.log('transactiion found', trn.hash, trn);
          }
        });
      } else if (block && block.to) {
        if (accAddress == block.from.toLowerCase() || accAddress == block.to.toLowerCase()) {
          // console.log('transactiion found', block.hash);
        }
      } else {
        console.log('transactiion not found');
      }
    });
  }

  // getTransactionsByFilter() { // not work
  //   this.walletConnectService.getTransactionsByFilter().then(result => {
  //     console.log('getTransactionsByFilter', result);
  //   }).catch(error => {
  //     console.log('Get TransactionsByFilter error', error);
  //   });
  // }

  // getByFilter() { // not work
  //   this.getTransactionsFromBlock();
  //   this.walletConnectService.getByFilter('pending').then(result => {
  //     console.log('get By Filter', result);
  //   }).catch(error => {
  //     console.log('Get Transactions By Filter error', error);
  //   });
  // }

  // get all transactions
  // getTransactionsFromBlock() {
  //   this.walletConnectService.transactionsByFilter(this.selectedAccount, 'latest');
  // }

  // getPendingTransactions() { // not work
  //   this.walletConnectService.getPendingTransactions(this.selectedAccount);
  // }

  getPendingTransaction() { // not work
    this.walletConnectService.getPendingTransaction().then(pTrans => {
      // console.log('getPendingTransactions', pTrans);
    }).catch(error => {
      console.log('Get PendingTransactions error', error);
    });;
  }

  saveWalletConnectTransaction(txHash?, state?, data?, response?) {
    let params;
    let txHash_: any = {};
    // console.log('Transaction data: ', txHash, state, data, response);
    if (state) {
      state === 'stopped' ? state = 'pending' : false;
    }
    if (!txHash && this.transaction_) {
      const gasPrice = this.walletConnectService.getFromGWei(this.transaction_.gasPrice.toString());
      const fee = parseFloat(this.walletConnectService.getFromGWei((this.transaction_.gas * parseFloat(gasPrice)).toFixed(0).toString()));
      const trnAmount = parseFloat(this.walletConnectService.getFromWei(this.transaction_.value));
      // console.log('Test transaction',  this.transaction_);
      txHash_ = this.transaction_;
      txHash_.transaction_amount = trnAmount + fee;
      txHash_.transaction_fee = fee;
      txHash_.tickets_amount = ((trnAmount + fee) * this.usdCurRate).toFixed(2);
      txHash_.tickets_price = (trnAmount * this.usdCurRate).toFixed(2);

      txHash_.tickets_currency = 'usd';
      txHash_.network = this.chainData.network;
      txHash_.chain_currency = this.chainData.shortName;
      txHash_.event_currency_rate = this.eventCurRate;
      txHash_.usd_currency_rate = this.usdCurRate;
      params = {
        payment_id: this.eventTicketsData.payment_details.id,
        transaction_hash: this.transaction_.hash,
        transaction_status: 0,
        transaction_data: txHash_
      }
    } else {
      const res = response && response.transaction_data ? response.transaction_data : false;
      // state && data ? txHash[state] = data : false;
      if (state && data) {
        this.prevStates.forEach(prev => {
          txHash[prev.state] = prev.data;
          // console.log('state', prev.state);
        });
        this.prevStates.push({state, data});
        txHash[state] = data;
      } else if (state === 'pending') {
        this.prevStates.push({state, data: txHash});
      }
      // console.log('walletConnectEvent', res, this.prevStates, state, txHash, this.eventTicketsData);
      txHash.event_id = res && res.event_id ?  res.event_id : this.eventTicketsData.event_details.eventId;
      txHash.event_name = res && res.event_name ?  res.event_name : this.eventTicketsData.event_details.eventName;
      txHash.total_tickets =res && res.total_tickets ?  res.total_tickets : this.eventTicketsData.event_details.totalTickets;
      txHash.user_email = res && res.user_email ?  res.user_email : this.eventTicketsData.event_details.userEmail;
      // txHash.ticket_name = res && res.ticket_name ?  res.ticket_name : this.eventTicketsData.event_details.ticketName;
      txHash.chain_currency = res && res.chain_currency ?  res.chain_currency : this.chainData.shortName;
      txHash.transaction_amount = res && res.transaction_amount ?  res.transaction_amount : this.transactionData.ticketPriceTotal;
      txHash.transaction_fee = res && res.transaction_fee ?  res.transaction_fee :  this.transactionData.fee;
      txHash.event_currency_rate = res && res.event_currency_rate ?  res.event_currency_rate : this.eventCurRate;
      txHash.tickets_currency = res && res.tickets_currency ?  res.tickets_currency : this.eventTicketsData.payment_details.currency.toLowerCase();
      txHash.tickets_price = res && res.tickets_price ?  res.tickets_price : this.eventTicketsData.payment_details.payment;
      txHash.tickets_price_without_fee = res && res.tickets_price_without_fee ?  res.tickets_price_without_fee : this.eventTicketsData.payment_details.net_payment;
      txHash.tickets_price_in_chain_cur_wo_fee = res && res.tickets_price_in_chain_cur_wo_fee ?  res.tickets_price_in_chain_cur_wo_fee : +parseFloat((this.eventTicketsData.payment_details.net_payment / this.eventCurRate).toString()).toFixed(8);
      txHash.tickets_amount = res && res.tickets_amount ?  res.tickets_amount : (this.eventCurRate * this.transactionData.ticketPriceTotal).toFixed(2).toString();
      txHash.usd_currency_rate = res && res.usd_currency_rate ?  res.usd_currency_rate :  this.usdCurRate;
      txHash.network =  res && res.network ?  res.network : this.chainData.network;
      params = {
        payment_id: response && response.main_purchase_identifier ? response.main_purchase_identifier : this.eventTicketsData.payment_details.main_purchase_identifier,
        transaction_hash: txHash.hash,
        transaction_status: this.transactionStatus,
        // transaction_amount: res.transaction_amount ?  res.transaction_amount : this.transactionData.ticketPriceTotal,
        // transaction_from: txHash.from,
        // transaction_to: txHash.to,
        transaction_data: txHash //{transaction_amount: this.transactionData.ticketPriceTotal, txHash}
      };
    }

    // console.log('before save WalletConnect Transaction to back-end', txHash.hasOwnProperty('blockHash'), params);
    this.globals.startLoader();
    this.walletConnectService.saveWalletConnectTransaction(params).subscribe((response: any) => {
      this.globals.stopLoader();
      this.transaction && this.transaction.message ? false : this.transaction = {};
      // emit data response to event page
      if (response.data && response.data.url) {
        this.dbTransactionState = this.transactionStatus !== 1 ? state : 'success';
        this.transaction.message = 'Success!'
        // console.log('saveWalletConnectTransaction was saved success');
        this.globals.stopLoader();
        this.isChecking = false;
        this.canCheckTransaction = false;
        this.isShowButton = false;
        setTimeout(() => {
          this.getTransactionWallet.emit(response);
        }, 3000); // code: success,  data: {success: true/false, url:/order_status........}
        this.stopingCheckStatus = false;
        this.isSuccessful = true;
        this.transactionUrl = '';
        this.onDisconnect();
      } else if (response.code === 'success' && !response.data.success) {
        this.dbTransactionState = this.transactionStatus !== 1 ? state : 'success';
        this.globals.stopLoader();
        this.isShowButton = true;
        this.transaction.message = 'Pending...'
        this.transactionUrl = response.data.check_wallet_url;
        // console.log('saveWalletConnectTransaction was saved pending');
        this.isChecking = true;
        // test speedup purchase for etereum network ----------
        // stop checking state of transaction in component and start checking in background if network is ETHEREUM
        if (this.chainData && txHash.hasOwnProperty('blockHash') && (this.chainData.chainId == 1 || this.chainData.chainId == 97)) {
          this.stopChecking = true;
          this.globals.stopLoader();
          this.dialogService.alertsModal('success', 'walletConnectProcessing', 5000, true);
          this.stopCheckStatus();
          response.data.sendTo = '/'; // add routing to send user to home page
          setTimeout(() => {
            this.getTransactionWallet.emit(response);
            this.walletConnectService.setShadowProvider(this.chainData.network, this.chainData.shortName);
          }, 5000);
          // this.onDisconnect();
        } else {
          // pending transaction was saved into DB
          setTimeout(() => {
            this.getTransactionWallet.emit(response);
          }, 3000);
        }
        // test end -------------------------------------------
      } else {
        this.globals.stopLoader();
        this.transactionUrl ? this.isShowUrl = true: false;
        this.dbTransactionState = 'stopped';
        this.transactionStatus = 0;
        this.canCheckTransaction = true;
        this.isShowButton = true;
        this.isChecking = false;
        // console.log('saveWalletConnectTransaction error', response.code);
        this.dialogService.alertsModal('cryptoCurrency', 'walletTransactionSave', 3000, false);
      }
      // console.log('saveWalletConnectTransaction response', response, this.dbTransactionState);
    },
    error => {
      console.log('Transaction error', error);
      // this.dialogService.alertsModal('errors', (error.code) ? error.code : 4 ,3000, false, error.log_id);
    });
  }

  setGasPrice() {
    const gasPrice = parseFloat((this.defaultGasPrice / 2).toFixed(0));
    this.defaultGasPrice = gasPrice;
  }

  sendTransactionBtn(value) {
    this.getTrnBtnStatus.emit(value);
  }

  sendTransaction() {
    this.stopChecking = false;
    this.transaction = {};
    this.globals.startLoader();
    this.stopTimer();

    const tx = this.getDraftTransaction();
    // console.log('sendTransaction draft', tx);
    this.isCheckTrnStatus = true;
    this.checkBtn();
    this.transaction.message = 'Pending...';
    const transaction = this.walletConnectService.sendTransactions(tx)
    .once("sending", (trn)=> {
      // console.log('Transaction is sending...', trn); // Fired immediately before transmitting the transaction request
      this.transaction.message = 'Sending...';
    })
    .once("sent", (trn)=> {
      // console.log('Transactions was sent', trn); // Fired immediately after the request body has been written to the client, but before the transaction hash is received.
      this.transaction.message = 'Sent!';
    })
    .once('transactionHash', (txHash) => {
      this.globals.stopLoader();
      this.currentTransactionHash = txHash;
      this.transaction.hash = txHash;
      this.transaction.hashPrew = this.hashPrewiev(txHash);
      this.transaction.message = 'Pending...';
      // console.log('Transaction hash', txHash); // Fired when the transaction hash is available.
      this.walletConnectService.checkStatus(this.currentTransactionHash, 'pending'); // get transaction and save into DB with pending status
    })
    .once('receipt', (receipt) => {
      // console.log('Transactions receipt', receipt); // Fired when the transaction receipt is available. need to study this topic
      this.transaction.message = 'Receipted!';
      this.walletConnectService.checkStatus(this.currentTransactionHash, 'receipt', receipt);
    })
    .on('confirmation', (confirmationNumber, receipt, latestBlockHash) => { // waiting 24 confirmation by default
      /* Fired for every confirmation up to the 12th confirmation.
          Receives the confirmation number as the first and the receipt as the second argument.
          Fired from confirmation 0 on, which is the block where it’s mined.*/
      // this.transaction.hash = txHash;
      this.transaction.message = 'Confirmed!';
      // console.log('Transactions confirmation', confirmationNumber, receipt, latestBlockHash);
      if (confirmationNumber === this.walletConnectService.transactionConfirmationBlocks) {
        // waiting while get 6-12 confirmation before request trn full data, need to study this question
        !this.stopChecking ? this.walletConnectService.checkStatus(this.currentTransactionHash, 'confirmation', receipt) : false;
      }
    })
    .on('error', (error, receipt) => {
      this.globals.stopLoader();
      this.transactionUrl ? this.isShowUrl = true : false;
      // this.isCheckTrnStatus = false;
      console.log('Error returned when rejected', error, 'receipt:', receipt); // If a out of gas error, the second parameter is the receipt.
      this.transaction.message = error.message;
      // check transaction status
      if (this.currentTransactionHash) {
        if (this.dbTransactionState !== 'success') {
          this.canCheckTransaction = true;
          this.isShowButton = true;
          this.isChecking = false;
          console.log('Show button to check transaction manually'); // need to show message about it
        }
      } else {
        console.log('Might be worth run disconnect from wallet...');
        this.globals.stopLoader();
        // this.onDisconnect();
      }
      if (!this.walletConnectService.transactionSubscription)  {
        // if currentTransactionHash exist and transactionSubscription is undefined we have to start check transaction staus
        console.log('start check transaction ???', this.currentTransactionHash);
      } else {
        this.globals.stopLoader();
        // console.log('continue checking transaction...', this.currentTransactionHash);
        if (this.currentTransactionHash) {
          this.dialogService.alertsModal('cryptoCurrency', 'walletTransaction', 3000, false); // transaction was rejected
          this.isChecking = true;
        } else {
          // console.log('we can not continue this process');
          this.isChecking = false;
          this.isShowButton = false
          this.canCheckTransaction = false;
          this.dialogService.alertsModal('cryptoCurrency', 'cryptoTransaction', 3000, false); // transaction was rejected
        }
      }
    });
    // console.log('send Transaction', transaction);
  }

  hashPrewiev(hash?: string) {
    const showChar = 8;
    let prewHash;
    prewHash = hash.substring(0,showChar) + ' . . . ' + hash.substring(hash.length - showChar, hash.length);
    return prewHash;
  }

  checkTransactionAfter() {
    let txHash = this.currentTransactionHash;
    const dbState = this.dbTransactionState ? this.dbTransactionState : 'pending'; // to test
    this.transaction_ && this.transaction_.hash ? txHash = this.transaction_.hash : false; // to test checkTransaction
    if (txHash) {
    this.isShowButton = false;
    this.isChecking = true;
    this.walletConnectService.checkStatus(txHash, dbState);
    } else {
      console.log('Transaction hash not found!');
    }
  }

  copyToClipboard(value) {
    if (value === 'hash') {
      this.clipboardService.copyFromContent(this.transaction.hash);
    } else if (value === 'url') {
      this.clipboardService.copyFromContent(this.transactionUrl);
    }
    this.clipboardService.destroy();
    this.dialogService.alertsModal('success', 'couponCopied', 2000, true);
  }

  stopCheckStatus() {
    this.walletConnectService.stopCheckStatus();
  }

  getPendingTrn() {
    this.walletConnectService.getAllPendingTransactions().subscribe((response: any) => {
      // console.log('getAllPendingTransactions', response);
      if (response.code === 'success') {
        if (response.data && response.data.length > 0) {
          // this.checkPendingTrn(response.data[0]);
          // console.log('checkPendingTrn', response.data[0]);
          // this.transaction_ = response;
          this.transactionUrl = "https://localhost:4200/transaction/GwNSDmvV21gGsVFhrEnf67W3UZKOpxlEut8IAjnhZdPCDmMvR8JANgHlb9oResSzrfTI3kQyB1HJiBxC";
          this.isShowUrl = true;
          // response.data.forEach(transaction => {
          // this.checkPendingTrn(transaction);
          // });
        } else {
          console.log('User do not have any pending transaction');
        }
      } else {
        console.log('Something went wrong...');
      }
    });
  }

  checkPendingTrn(transaction) {
    this.walletConnectService.checkStatus(transaction.transaction_hash, 'pending', transaction.transaction_data, transaction);
  }

  getTransactionByHash(txHash?) {
    // 0x9a4b5ac0a2903b988f335a3e2826772c66b90c8de1a935506df873e9218f67b0
    const hash = txHash ? txHash : '0x9a4b5ac0a2903b988f335a3e2826772c66b90c8de1a935506df873e9218f67b0';
    // console.log('Check pending transaction by hash', hash);
    this.walletConnectService.setNetwork(this.chainData.network).then(net => {
      if (net) {
        console.log('setNetwork', this.chainData.network, net);
      }
      if (hash) this.walletConnectService.getShadowTransactionByHash(hash).then(response => {
        console.log('Get PendingTransactions by hash', response);
        this.transaction_ = response;
        this.transactionUrl = 'https://localhost:4200/transaction/GwNSDmvV21gGsVFhrEnf67W3UZKOpxlEut8IAjnhZdPCDmMvR8JANgHlb9oResSzrfTI3kQyB1HJiBxC';
        this.isShowUrl = true;
        this.isCheckTrnStatus = true;
        this.checkBtn();
      }).catch(error => {
        console.log('Get PendingTransactions error', error);
      });
    });

  }

  refreshAccountData(status) {
    // console.log('refreshAccount', status);
    if (!status) {
      this.globals.startLoader();
    } else {
      this.globals.stopLoader();
    }
  }

  onConnect() {
      this.walletConnectService.setProvider().then((status) => {
        // console.log('onConnect', status);
        this.canCheckTransaction = false;
        this.isCheckTrnStatus = false;
        this.transaction = {};
      });
  }

  onDisconnect() {
    this.walletConnectService.closeProvider()
    .then(() => {
      // this.checkBtn();
      this.sendTransactionBtn(false);
      this.isCoinSupported = true;
      this.isCheckTrnStatus = true;
      this.canCheckTransaction = false;
      this.isChecking = false;
      this.isConnected = false;
      this.hasBalances = false;
      this.accountBalances = [];
      this.selectedAccount = null;
      this.prevStates = [];
      this.globals.stopLoader();
      this.stopCheckStatus();
      this.stopTimer();
      // this.eventTicketsData = null;
      // console.log('Disconnected');
    })
    .catch(error => {
      console.log('onDisconnect error', error);
    });
  }

  getCoinbase() {
    this.walletConnectService.getCoinBase().then(result => {
      // console.log('getCoinbase', result); // Returns the coinbase address to which mining rewards will go.
    }).catch(error => {
      console.log('getCoinbase error', error);
    })
  }

  signTransaction() {
    const tx = this.getDraftTransaction();
    // const signedTx = await this.web3.eth.signTransaction(tx);
    this.walletConnectService.signTransaction(tx)
    .then((txHash) => {
      // Returns transaction id (hash)
      // this.transaction = txHash;
      // console.log('Returns transaction id', txHash);
    })
    .catch((error) => {
      // Error returned when rejected
      console.log('Error returned when rejected', error);
    });
  }

  // getCriptoPrice() {
  //   this.walletConnectService.simplePrice( {ids: ['ethereum'], vs_currencies: ['usd']}) // {ids: ['ethereum', 'bitcoin'], vs_currencies: ['usd',"ils","eur", "cad"]}
  //   .then((result: any) => {
  //     // this.currencyRates = result;
  //     console.log('simplePrice', result);
  //   });
  // }

  // getCoinsList() {
  //   this.walletConnectService.coinsList().then((result) => {
  //     console.log('getCoinsList', result);
  //   })
  // }

  startTimer() {
    this.walletConnectService.startTimer();
  }

  stopTimer() {
    this.walletConnectService.stopTimer();
  }

  setSecondProvider() {
    this.walletConnectService.setSecondProvider(this.selectedAccount.toLowerCase());
  }

  ngOnDestroy(): void {
    if (this.walletConnectService.hasProvider) {
      this.onDisconnect();
    } else {
      this.stopTimer();
      this.stopCheckStatus();
    }
    this.eventTicketsData = null;
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.walletConnectService.unsubscribePendingTransactions();
  }

}
