<div class="page-header-arrow-back">
  <div class="page-back-arrow">
    <div class="close-dialog-arrow" (click)="closePage()">
      <svg class="arrow-close-modal" width="10" height="14" viewBox="0 0 10 14" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M9.49219 1.64063L4.13281 7L9.49219 12.3594L7.85156 14L0.851562 7L7.85156 0L9.49219 1.64063Z"
          fill="white" />
      </svg>
    </div>
  </div>
</div>
<div *ngIf="!editEventTimeout" class="settings-container col-md-12 col-xs-12">
  <div class="settings-content-wrap col-md-12 col-xs-12">
    <div class="settings-content">
      <div class="row">
        <div class="text col-md-12 col-xs-12">
          <h1 i18n="@@CreateEditEventCreateTitle" *ngIf="eventData?.eventStatus === 2" class="event-settings-title">Create new event</h1>
          <h1 i18n="@@CreateEditEventEditTitle" [ngStyle]="{'display': eventData ? 'block' : 'none'}" *ngIf="eventData?.eventStatus !== 2" class="event-settings-title">Edit event</h1>
        </div>
      </div>
      <div *ngIf="eventData?.eventStatus === 2 && eventData?.eventCreated && timerDate" class="stat-text col-xs-12 col-xs-12">
        <p i18n="time left to edit event">Time left to edit the event:
          <cd-timer #basicTimer format="hms" [startTime]="timerDate" [countdown]="true" [autoStart]="true" (onComplete)="handleCDTimer($event)" (onStop)="onStopCDTimer($event)"></cd-timer>
        </p>
      </div>

      <form [formGroup]="event" class="status new-form center_" *ngIf="bootstrapBoolean">
        <div id="onCreateEvent" class="col-md-12 col-xs-12 p0"
             [ngClass]="{'lang-es': localeId == 'es'}">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header class="mat-section" collapsedHeight="*" expandedHeight="*">
                <div class="col-md-12 col-xs-12 title-align event-steps">
                  <div class="col-md-12 col-xs-12 step_name">
                    <span i18n="@@CreateEditEventDetails" [attr.data-cy]="'eventDetailsTab'">Event details</span>
                  </div>
                  <span [hidden]="!mainStep.valid" class="valid"></span>
                </div>
              </mat-expansion-panel-header>
              <div formGroupName="main" class="details-section mat-section">
                <div class="main-details">
                <div class="mb10 mt10 row">
                  <div class="col-md-12 col-xs-12 title-align" >
                    <div class="label-wrap">
                      <label i18n="@@CreateEditEventEventTitle" id="title">Event title</label><span i18n="@@CreateEditEventEventTitleLimit" class="restrictions"> (up to 100 characters)</span>
                    </div>
                    <!--<div (click)="openInfo('info_title')" class="col-md-2 col-xs-2 pl0 pr0 text-center">
                      <img width="20" class="img-responsive info_icon" src="/assets/images/editEvent/Info.png">
                    </div>-->
                  </div>

                  <div class="col-md-12 col-xs-12">
                    <input id="input_title" i18n-placeholder="@@CreateEditEventEventTitlePlaceholder" class="form-control" trim="blur" type="text" formControlName="title" placeholder="Enter event name, line, location, or any other detail">
                    <app-error-creator [@slideIn] *ngIf="mainStep.controls.title.touched && mainStep.controls.title.invalid" [control]="mainStep.controls.title"></app-error-creator>
                  </div>
                </div>
                <div class="mb15 mt15 row">
                  <div class="col-md-12 col-xs-12 title-align" >
                    <div class="label-wrap">
                      <label i18n="input label | event address" for="locality">Event adress:</label>
                    </div>
                    <!-- <div (click)="openInfo('info_address')" class="col-md-2 col-xs-2 pl0 pr0 text-center">
                       <img width="20" class="img-responsive info_icon" src="/assets/images/editEvent/Info.png">
                     </div>-->
                  </div>
                  <div class="col-md-12 col-xs-12">
                    <input i18n-placeholder="@@CreateEditEventEventAddressPlaceholder" trim="blur" appGoogleAutoComplete id="input_address" formControlName="address" (onSelect)="setAddress($event)" (change)="checkAddress($event)" class="form-control" type="text" placeholder="Street, number, city, country" required>
                    <app-error-creator [@slideIn] *ngIf="mainStep.controls.address.touched && mainStep.controls.address.invalid" [control]="mainStep.controls.address"></app-error-creator>
                  </div>
                </div>

                <div class="row start-end-dates">
                  <div class="col-md-6 col-xs-6">
                    <div class="col-md-12 col-xs-12 title-align pr0">
                      <div class="label-wrap">
                        <label i18n="@@CreateEditEventStartDate" id="start_date">Event starts:</label>
                      </div>
                      <!--<div (click)="openInfo('info_date')" class="col-md-2 col-xs-2 pl0 pr0 text-center">
                        <img width="20" class="img-responsive info_icon" src="/assets/images/editEvent/Info.png">
                      </div>-->
                    </div>
                    <div class="col-md-12 col-xs-12 mb15 pl0 pr0">
                      <input i18n-placeholder="@@CreateEditEventStartDatePlaceholder"  i18n-headerText="@@CreateEditEventStartDatePlaceholder" class="form-control event-date" formControlName="main_start_date" type="text" placeholder="Pick a starting date for the event" [attr.data-cy]="'eventStartDate'" headerText="Pick a starting date for the event" [mbsc-options]="startDateSettings" mbsc-datetime>
                      <app-error-creator [@slideIn] *ngIf="mainStep.controls.main_start_date.touched && mainStep.controls.main_start_date.invalid" [control]="mainStep.controls.main_start_date"></app-error-creator>
                    </div>
                  </div>

                  <div class="col-md-6 col-xs-6">
                    <div class="col-md-12 col-xs-12 title-align pl0 pr0">
                      <div class="label-wrap">
                        <label i18n="@@CreateEditEventEndDate" id="end_date">Event ends:</label>
                      </div>
                      <!--<div (click)="openInfo('info_date')" class="col-md-2 col-xs-2 pl0 pr0 text-center">
                        <img width="20" class="img-responsive info_icon" src="/assets/images/editEvent/Info.png">
                      </div>-->
                    </div>

                    <div class="col-md-12 col-xs-12 mb15 pl0 pr0">
                      <input i18n-placeholder="@@CreateEditEventEndDatePlaceholder" [attr.data-cy]="'eventEndDate'" i18n-headerText="@@CreateEditEventEndDatePlaceholder" class="form-control event-date" formControlName="main_end_date" type="text" placeholder="Pick an ending date for the event" headerText="Pick an ending date for the event" [mbsc-options]="endDateSettings" mbsc-datetime>
                      <app-error-creator [@slideIn] *ngIf="mainStep.controls.main_end_date.touched && mainStep.controls.main_end_date.invalid" [control]="mainStep.controls.main_end_date"></app-error-creator>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 col-xs-12 title-align mb10 pl0 pr0">
                  <mbsc-form input-style="box" label-style="stacked" class="form-group label-wrap">
                    <label i18n="input label | event langs">Languages:</label>
                    <select mbsc-select [mbsc-options]="selectLangSettings" formControlName="langs" class="form-control" [rows]="2" (ngModelChange)="setLang($event)">
                      <option *ngFor="let lang of langsList" value="{{lang.lang}}">{{lang.langName}}</option>
                    </select>
                  </mbsc-form>
                  <app-error-creator [@slideIn] *ngIf="mainStep.controls.langs.touched && mainStep.controls.langs.invalid" [control]="mainStep.controls.langs"></app-error-creator>
                </div>

                <div class="mb10 col-md-12 col-xs-12 pl0 pr0 quill-wrapper" formArrayName="description">
                  <div class="label-wrap">
                    <label i18n="@@CreateEditEventDescription" id="description">Event description:</label><span i18n="@@CreateEditEventDescriptionLimit" class="restrictions"> (Up to 15,000 characters)</span>
                  </div>
                    <ng-container *ngFor="let item of selectedlanguages;let i = index" class="sub-quill-wrapper quill-{{item.lang}}" (select)="item.active = true;setCurrentLangOnQuill(item.lang);" [formGroupName]="i">
                      <input type="hidden" formControlName="lang" value="{{item.lang}}">
                      <quill-editor id="{{item.lang}}" class="col-md-12 col-xs-12 p0 m0" [attr.data-cy]="'eventDescription'" #quillEditor formControlName="value"
                                    (onEditorCreated)="quillCreated($event, item.lang)"
                                    (onContentChanged)="updateQuillsValues($event, item.lang)"
                                    [modules]="quillModule"  [placeholder]="'placeholderText' | i18nSelect: quillPlaceholder[item.lang]" [hidden]="i !== 0"></quill-editor>
                    </ng-container>
                </div>


                <ng-container>
                  <div class="age-restrictions">
                    <div class="pr0 pl0" style="margin-bottom: 0; display: flex; align-items: center;">
                      <label class="ml10 mr10">{{'createEditEvent.AgeRestrictions' | translate:'Age restrictions'}}</label>
                      <label class="switch" style="margin-bottom: 0;">
                        <input type="checkbox" [formControl]="advancedStep.controls.age_display" [value]="advancedStep.controls.age_display.value ? true : false" (change)="age_displayChange($event)">
                        <span class="slider round"></span>
                      </label>
                      <app-error-creator [@slideIn]
                                         *ngIf="advancedStep.controls.age_display.touched && advancedStep.controls.age_display.invalid"
                                         [control]="advancedStep.controls.age_display"></app-error-creator>
                    </div>
                    <div style="width: 50%;">
                      <div class="age-input-wrap" [ngClass]="advancedStep.controls.age_display.value? '' : 'disabled-input'">
                        <label for="min_age">{{'createEditEvent.AgeLimit' | translate:'Age Limit'}}</label>
                        <input [formControl]="advancedStep.controls.min_age" placeholder="18+" #min_age
                               type="number" class="form-control age-input" [required]="advancedStep.controls['age_display'].value ? true : false">
                      </div>
                    </div>
                  </div>
                  <div style="display: flex;">
                    <div style="width: 50%;"></div>
                    <div style="width: 50%;">
                      <app-error-creator [@slideIn] *ngIf="advancedStep.controls.min_age.touched && advancedStep.controls.min_age.invalid"
                                         [control]="advancedStep.controls.min_age"></app-error-creator>
                    </div>
                  </div>
                </ng-container>



                  <div class="row mt30">
                    <div class="col-md-12 col-xs-12 label-wrap">
                      <label>{{'createEditEvent.DiscoverYourArtistsonSpotify' | translate:'Discover Your Artists on Spotify'}} <img style="width: 21px;" src="assets/images/Spotify_Icon_RGB_White.png"></label>
                    </div>
                    <div class="col-md-12 col-xs-12 mb10">
                      <input (click)="checkSpotifyToken()" type="text" class="form-control spotify-input" placeholder="{{'createEditEvent.Typeyourartistname' | translate:'Type your artist name'}}" matInput [formControl]="spotifyControl" [matAutocomplete]="auto">
                      <mat-autocomplete #auto="matAutocomplete"
                                        class="mat-spotify"
                                        (optionSelected)="optionSelected($event.option.value)"
                                        [displayWith]="getOptionText">
                        <mat-option *ngFor="let option of spotifyOptions | async" [value]="option">
                          <div class="spotifyOptions">
                            <img class="art-img" *ngIf="option.images.length" src="{{option.images[0].url}}" alt="{{option.name}}">
                            <div class="art-img" *ngIf="!option.images.length"></div>
                            <span class="art-name">{{option.name}}</span>
                            <span class="art-followers" *ngIf="option.followers">{{option.followers.total|shortNumber}} {{'createEditEvent.Followers' | translate:'Followers'}}</span>
                          </div>
                        </mat-option>
                      </mat-autocomplete>
                    </div>
                    <div *ngFor="let artist of selectedSpotifyArtists|keyvalue;" class="col-md-12 col-xs-12" style="width: 100%;">
                      <div class="spotifyOptions selected">
                        <a class="art-img" *ngIf="artist.value.images.length" href="{{artist.value.external_urls.spotify}}" target="_blank"><img class="art-img" src="{{artist.value.images[0].url}}" alt="{{artist.value.name}}"></a>
                        <div class="art-img" *ngIf="!artist.value.images.length"></div>
                        <span class="art-name">{{artist.value.name}}</span>
                        <span class="art-followers" *ngIf="artist.value.followers">{{+artist.value.followers.total|shortNumber}} {{'createEditEvent.Followers' | translate:'Followers'}}</span>
                        <button class="art-remove" title="Remove Artist" (click)="removeSpotifyArtist(artist.key)"><img src="/assets/images/svg-icons/cancel.svg"></button>
                      </div>
                    </div>
                  </div>

                <div class="col-md-12 col-xs-12 title-align pl0 pr0">
                  <app-error-creator [@slideIn] *ngIf="mainStep?.controls?.description?.touched && mainStep?.controls?.description?.invalid" [control]="mainStep.controls.description"></app-error-creator>
                </div>
              </div>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header class="mat-section" collapsedHeight="*" expandedHeight="*">
                <div class="col-md-12 col-xs-12 title-align event-steps">
                  <div class="col-md-12 col-xs-12 step_name">
                    <span class="center-block" i18n="section title | gallery">Upload photos</span>
                  </div>
                  <span [hidden]="!(gallery_cover && gallery_bg)" class="valid"></span>
                </div>
              </mat-expansion-panel-header>
              <div class="mt10 details-section">
                <div class="gallery-controlls">
                  <div class="gallery-details form-group col-md-12 col-xs-12 mt20">
                    <div id="cover_row" class="col-md-12 col-xs-12 pl0 pr0">
                      <div class="col-md-12 col-xs-12 p0 mt5">
                        <h4 i18n="@@CreateEditEventUploadMainImage">Upload main image</h4>
                        <p i18n="@@CreateEditEventImageAspectRat">Image aspect ratio 1:1</p>
                        <p i18n="@@CreateEditEventImageMazSize">Maximum image size: 5MB</p>
                        <div *ngIf="coverCropperComponent" [@fade] class="col-md-12 col-xs-12 mb5">
                          <angular-cropper #coverImageCropper [imageUrl]="coverUrl" [cropperOptions]="coverCropperConfig"></angular-cropper>
                          <div class="docs-buttons">
                            <div class="btn-group manage-btn-group">
                              <button id="inputImage" class="btn btn-upload manage-btn" type="button" (click)="coverFileInput.click()">
                                <!-- <label for="inputImage" title="Upload image file"> -->
                                  <span class="docs-tooltip">
                                    <span class="fa fa-upload"></span>
                                    <br>
                                    <small i18n="@@Upload">Upload</small>
                                  </span>
                                <!-- </label> -->
                              </button>
                              <button (click)="saveCroppedImage(coverImageCropper, 1)" type="button" class="btn btn-save manage-btn"
                                title="getCroppedCanvas">
                                <span class="docs-tooltip">
                                  <span class="fa fa-check"></span>
                                  <br>
                                  <small i18n="@@OK">OK</small>
                                </span>
                              </button>
                              <button (click)="cropperSetDragMode(coverImageCropper, 'move')" type="button" class="btn manage-btn default-btn" title="Move">
                                <span class="fa fa-arrows"></span>
                                <br>
                                <small i18n="@@Move">Move</small>
                              </button>
                              <button (click)="cropperSetDragMode(coverImageCropper, 'crop')" type="button" class="btn manage-btn default-btn" title="Crop">
                                <span class="fa fa-crop"></span>
                                <br>
                                <small i18n="@@Crop">Crop</small>
                              </button>
                              <button (click)="cropperDestroy(coverImageCropper, 'cover')" type="button" class="btn mutate-float-right manage-btn default-btn"
                                title="Destroy">
                                <span class="fa fa-close"></span>
                                <br>
                                <small i18n="@@Delete">Delete</small>
                              </button>

                              <input type="file" class="sr-only" #coverFileInput (change)="setCropper($event.srcElement,'cover')" name="file" accept="image/*">
                            </div>
                          </div>
                        </div>
                        <div *ngIf="gallery_cover && !coverCropperComponent" [@fade] class="col-md-12 col-xs-12 pl0 pr0 mt15 edit_cover gallery-image">
                          <div id="cover_image">
                            <img class="preview_image img-responsive" src="{{images_base_url}}/gallery/{{gallery_cover.file}}">
                          </div>
                          <div class="image-controlls manage-btn-group">
                            <button id="inputImage" class="btn btn-upload manage-btn" type="button" (click)="coverFileInput.click()">
                              <label for="inputImage" title="Upload image file">
                                <span class="docs-tooltip">
                                  <span class="fa fa-upload"></span>
                                  <br>
                                  <small i18n="@@Upload">Upload</small>
                                </span>
                              </label>
                            </button>
                            <input type="file" class="sr-only" #coverFileInput (change)="setCropper($event.srcElement,'cover')" name="file" accept="image/*">
                            <!-- <div (click)="removeImage(gallery_cover.id,'cover')" class="remove_image remove_uploaded col-md-4 col-xs-5 col-md-offset-4 col-xs-offset-4 mt5">
                              <img class="img-responsive" width="15" src="/assets/images/dashboard/Delete.png">
                              <span i18n="cropper controls | delete image"> מחק תמונה</span>
                            </div> -->
                            <button (click)="removeImage(gallery_cover.id,'cover')" type="button" class="btn  mutate-float-right manage-btn default-btn"
                              title="Destroy">
                              <span class="fa fa-close"></span>
                              <br>
                              <small i18n="@@Delete">Delete</small>
                            </button>
                          </div>
                        </div>
                        <div class="upload-image" *ngIf="!gallery_cover && !coverCropperComponent">
                          <button id="inputImage" class="btn btn-upload manage-btn" type="button" (click)="coverFileInput.click()">
                            <label for="inputImage" title="Upload image file">
                                <span class="docs-tooltip">
                                  <span class="fa fa-upload"></span>
                                  <br>
                                  <small i18n="@@Upload">Upload</small>
                                </span>
                            </label>
                          </button>
<!--                          <input type="file" accept="image/*" #file (change)="uploadImgix($event)">-->
<!--                          <img [src]="file.value" alt="" >-->
<!--                          <button (click)="file.click()">Upload IMGIX file</button>-->
                          <input type="file" class="sr-only" #coverFileInput (change)="setCropper($event.srcElement,'cover')" name="file" accept="image/*">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="false" class="gallery-details form-group col-md-12 col-xs-12 mt20">
                    <div class="col-md-12 col-xs-12 pl0 pr0 edit_bg gallery-image">
                      <div class="col-md-12 col-xs-12 p0 mt5">
                        <h4 i18n="@@CreateEditEventUploadBGImage">Upload background image</h4>
                        <p i18n="@@CreateEditEventBGImageRatio">Image aspect ratio 16:9</p>
                        <p i18n="@@CreateEditEventBGImageSize">Maximum file size: 5MB</p>
                        <div *ngIf="bgCropperComponent" [@fade] class="col-md-12 col-xs-12 mb5 cover-cropper-image">
                          <angular-cropper #bgImageCropper [imageUrl]="bgUrl" [cropperOptions]="bgCropperConfig"></angular-cropper>
                          <div class="docs-buttons">
                            <div class="btn-group manage-btn-group">
                              <button id="inputImage2" class="btn btn-upload manage-btn" type="button" (click)="bgFileInput.click()">
                                <label for="inputImage2" title="Upload image file">
                                  <span class="docs-tooltip">
                                    <span class="fa fa-upload"></span>
                                    <br>
                                    <small i18n="@@Upload">Upload</small>
                                  </span>
                                </label>
                              </button>
                              <button (click)="saveCroppedImage(bgImageCropper, 2)" type="button" class="btn btn-save manage-btn" title="getCroppedCanvas">
                              <span class="docs-tooltip">
                                <span class="fa fa-check"></span>
                                <br>
                                <small i18n="@@OK">OK</small>
                              </span>
                              </button>
                              <button (click)="cropperSetDragMode(bgImageCropper, 'move')" type="button" class="btn manage-btn default-btn" title="Move">
                                <span class="fa fa-arrows"></span>
                                <br>
                                <small i18n="@@Move">Move</small>
                              </button>
                              <button (click)="cropperSetDragMode(bgImageCropper, 'crop')" type="button" class="btn manage-btn default-btn" title="Crop">
                                <span class="fa fa-crop"></span>
                                <br>
                                <small i18n="@@Crop">Crop</small>
                              </button>
                              <button (click)="cropperDestroy(bgImageCropper, 'bg')" type="button" class="btn  mutate-float-right manage-btn default-btn"
                                title="Destroy">
                                <span class="fa fa-close"></span>
                                <br>
                                <small i18n="@@Delete">Delete</small>
                              </button>

                              <input type="file" class="sr-only" #bgFileInput (change)="setCropper($event.srcElement,'bg')" name="file" accept="image/*">
                            </div>
                          </div>
                        </div>
                        <div *ngIf="gallery_bg && !bgCropperComponent" [@fade] id="bg_img" class="cover-cropper-image">
                          <div class="preview_bg col-md-12 col-xs-12 pr0 pl0 mt5">
                            <img class="preview_image img-responsive" src="{{images_base_url}}/gallery/{{gallery_bg?.file}}">
                          </div>
                          <div class="image-controlls manage-btn-group">
                            <button id="inputImage2" class="btn btn-upload manage-btn" type="button" (click)="bgFileInput.click()">
                              <label for="inputImage2" title="Upload image file">
                                <span class="docs-tooltip">
                                  <span class="fa fa-upload"></span>
                                  <br>
                                  <small i18n="@@Upload">Upload</small>
                                </span>
                              </label>
                            </button>
                            <input type="file" class="sr-only" #bgFileInput (change)="setCropper($event.srcElement,'bg')" name="file" accept="image/*">
                            <!-- <div (click)="removeImage(gallery_bg.id,'bg')" class="remove_image remove_uploaded col-md-4 col-xs-5 col-md-offset-4 col-xs-offset-4 mt5">
                              <img class="img-responsive" width="15" src="/assets/images/dashboard/Delete.png">
                              <span i18n="cropper controls | delete image"> מחק תמונה</span>
                            </div> -->
                          <button (click)="removeImage(gallery_bg.id,'bg')" type="button" class="btn  mutate-float-right manage-btn default-btn" title="Destroy">
                            <span class="fa fa-close"></span>
                            <br>
                            <small i18n="@@Delete">Delete</small>
                          </button>
                          </div>
                        </div>
                        <div class="upload-image" *ngIf="!gallery_bg && !bgCropperComponent">
                          <button id="inputImage2" class="btn btn-upload manage-btn" type="button" (click)="bgFileInput.click()">
                            <label for="inputImage2" title="Upload image file">
                                <span class="docs-tooltip">
                                  <span class="fa fa-upload"></span>
                                  <br>
                                  <small i18n="@@Upload">Upload</small>
                                </span>
                            </label>
                          </button>
                          <input type="file" class="sr-only" #bgFileInput (change)="setCropper($event.srcElement,'bg')" name="file" accept="image/*">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="false" class="gallery-details form-group col-md-12 col-xs-12 mt20">
                    <div class="col-md-12 col-xs-12 pl0 pr0 edit_bg gallery-image">
                      <div class="col-md-12 col-xs-12 p0 mt5">
                        <h4 i18n="background image | title">Mobile background image</h4>
                        <p i18n="background image | required aspect ratio">Image aspect ratio: 1/2</p>
                        <p i18n="background image | maximum image size"> Maximum file size: 5MB</p>
                        <div *ngIf="feedCropperComponent" [@fade] class="col-md-12 col-xs-12 mb5 cover-cropper-image">
                          <angular-cropper #feedImageCropper [imageUrl]="feedUrl" [cropperOptions]="feedCropperConfig"></angular-cropper>
                          <div class="docs-buttons">
                            <div class="btn-group manage-btn-group">
                              <button id="inputImageFeed" class="btn btn-upload manage-btn" type="button" (click)="feedFileInput.click()">
                                <label for="inputImageFeed" title="Upload image file">
                                  <span class="docs-tooltip">
                                    <span class="fa fa-upload"></span>
                                    <br>
                                    <small i18n="@@Upload">Upload</small>
                                  </span>
                                </label>
                              </button>
                              <button (click)="saveCroppedImage(feedImageCropper, 4)" type="button" class="btn btn-save manage-btn"
                                title="getCroppedCanvas">
                                <span class="docs-tooltip">
                                  <span class="fa fa-check"></span>
                                  <br>
                                  <small i18n="@@OK">OK</small>
                                </span>
                              </button>
                              <button (click)="cropperSetDragMode(feedImageCropper, 'move')" type="button"
                                class="btn manage-btn default-btn" title="Move">
                                <span class="fa fa-arrows"></span>
                                <br>
                                <small i18n="@@Move">Move</small>
                              </button>
                              <button (click)="cropperSetDragMode(feedImageCropper, 'crop')" type="button"
                                class="btn manage-btn default-btn" title="Crop">
                                <span class="fa fa-crop"></span>
                                <br>
                                <small i18n="@@Crop">Crop</small>
                              </button>
                              <button (click)="cropperDestroy(feedImageCropper, 'feed')" type="button"
                                class="btn  mutate-float-right manage-btn default-btn" title="Destroy">
                                <span class="fa fa-close"></span>
                                <br>
                                <small i18n="@@Delete">delete</small>
                              </button>

                              <input type="file" class="sr-only" #feedFileInput (change)="setCropper($event.srcElement,'feed')" name="file"
                                accept="image/*">
                            </div>
                          </div>
                        </div>
                        <div *ngIf="gallery_feed && !bgCropperComponent" [@fade] id="feed_img" class="cover-cropper-image">
                          <div class="preview_bg col-md-12 col-xs-12 pr0 pl0 mt5">
                            <img class="preview_image img-responsive" src="{{images_base_url}}/gallery/{{gallery_portrait_feed}}">
                          </div>
                          <div class="image-controlls manage-btn-group">
                            <button id="inputImageFeed" class="btn btn-upload manage-btn" type="button" (click)="feedFileInput.click()">
                              <label for="inputImageFeed" title="Upload image file">
                                <span class="docs-tooltip">
                                  <span class="fa fa-upload"></span>
                                  <br>
                                  <small i18n="@@Upload">Upload</small>
                                </span>
                              </label>
                            </button>
                            <input type="file" class="sr-only" #feedFileInput (change)="setCropper($event.srcElement,'feed')" name="file"
                              accept="image/*">
                            <button (click)="removeImage(gallery_feed.id,'feed')" type="button"
                              class="btn  mutate-float-right manage-btn default-btn" title="Destroy">
                              <span class="fa fa-close"></span>
                              <br>
                              <small i18n="@@Delete">Delete</small>
                            </button>
                          </div>
                        </div>
                        <div class="upload-image" *ngIf="!gallery_feed && !bgCropperComponent">
                          <button id="inputImageFeed" class="btn btn-upload manage-btn" type="button" (click)="feedFileInput.click()">
                            <label for="inputImageFeed" title="Upload image file">
                              <span class="docs-tooltip">
                                <span class="fa fa-upload"></span>
                                <br>
                                <small i18n="@@Upload">upload</small>
                              </span>
                            </label>
                          </button>
                          <input type="file" class="sr-only" #feedFileInput (change)="setCropper($event.srcElement,'feed')" name="file"
                            accept="image/*">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
            <!-- [disabled]="mainStep.invalid" -->
            <mat-expansion-panel>
              <mat-expansion-panel-header class="mat-section" collapsedHeight="*" expandedHeight="*">
                <div class="col-md-12 col-xs-12 title-align event-steps">
                  <div class="col-md-12 col-xs-12 step_name">
                    <span class="center-block" i18n="@@CreateEditEventTicketSettings" [attr.data-cy]="'ticketSettingsTab'">Ticket settings</span>
                  </div>
                  <span [hidden]="!itemsStep.valid" class="valid"></span>
                </div>
              </mat-expansion-panel-header>
              <div formGroupName="items" class="mt30 details-section mat-section">
                <div formArrayName="tickets" *ngFor="let ticket of ticketsArray.controls; let i = index;" class="tickets-form anyform col-ms-12 col-xs-12 pl0 pr0">
                  <div [@fade] (click)="removeItem('tickets', i)" class="col-md-6 col-xs-6 text-center remove_ticket mutate-float-left" title="Delete ticket"></div>
                  <div class="sort-tickets" *ngIf="!categoriesArray.controls.length">
                    <div i18n="Ticket sorting" class="title-sorting">Sort ticket</div>
                    <!-- mbsc-select [mbsc-target]="selCur" [mbsc-options]="selectTicketsSettings" -->
                    <select class="form-control input-sorting" name="order-tickets" id="order-tickets"
                      [value]="i+1" (change)="onChangeArrayIndex($event, i, false)">
                      <option *ngFor="let ticket of ticketsArray.controls, let c = index" [value]="c+1">{{c+1}}</option>
                    </select>
                  </div>
                  <div class="ticket-details" [formGroupName]="i" [ngStyle]="!categoriesArray.controls.length ? {'padding': '60px 15px 10px'} : {'padding': '0 15px 10px'}">
                    <div class="gallery-details mt20">
                      <h4 i18n="ticket image | title">Upload Ticket image</h4>
                      <p i18n="ticket image | required aspect ratio">Image aspect ratio 1:1</p>
                      <p i18n="ticket image | maximum image size">Maximum image size: 5MB</p>
<!--                      <div *ngIf="gallery_bg" [@fade] class="cover-cropper-image">-->
                      <div *ngIf="ticket.get('nft_image_id').value || getTicketImageSRC(ticket.get('nft_image_id').value, ticket.get('id').value)" [@fade] class="cover-cropper-image">
                        <div class="pr0 pl0 mt5">
                          <img class="preview_image img-responsive" src="{{getTicketImageSRC(ticket.get('nft_image_id').value, ticket.get('id').value)}}">
                        </div>
                        <div class="image-controlls manage-btn-group">
                          <button id="inputImage_reupload{{i}}" class="btn btn-upload manage-btn" type="button" (click)="ticketFileInput.click()">
                            <label for="inputImage_reupload{{i}}" title="Upload image file">
                            <span class="docs-tooltip">
                              <span class="fa fa-upload"></span>
                              <br>
                              <small i18n="cropper controls | upload">Upload</small>
                            </span>
                            </label>
                          </button>
                          <input type="file" class="sr-only" #ticketFileInput (change)="setTicketImage(ticket.get('nft_image_id').value, ticket.get('id').value, ticketFileInput, i)" name="file" accept="image/*">
                          <button (click)="removeTicketImage(ticket.get('nft_image_id').value, i)" type="button" class="btn  mutate-float-right manage-btn default-btn" title="Destroy">
                            <span class="fa fa-close"></span>
                            <br>
                            <small i18n="cropper controls | delete">Delete</small>
                          </button>
                        </div>
                      </div>

<!--                      <div class="upload-image" *ngIf="!gallery_bg">-->
                      <div class="upload-image" *ngIf="!ticket.get('nft_image_id').value && !getTicketImageSRC(ticket.get('nft_image_id').value, ticket.get('id').value)">
                        <button id="inputImage_NoIMG{{i}}" class="btn btn-upload manage-btn" type="button" (click)="ticketFileInput.click()">
                          <label for="inputImage_NoIMG{{i}}" title="Upload image file">
                            <span class="docs-tooltip">
                              <span class="fa fa-upload"></span>&nbsp;<small i18n="cropper controls | upload">Upload</small>
                            </span>
                          </label>
                        </button>
                        <input type="file" class="sr-only" #ticketFileInput (change)="setTicketImage(ticket.get('nft_image_id').value, ticket.get('id').value, ticketFileInput, i)" name="file" accept="image/*">
                      </div>
                    </div>


                    <div *ngIf="!ticketsArray.controls[i].value.id" class="form-group" >
                      <label for="name">
                        <span i18n="@@TicketType">Ticket type</span>
                        <span>&nbsp;</span>
                        <span *ngIf="donateStatus.blocked"
                              i18n-matTooltip="@@CreateEditEventTooltipUnlockDonation"
                              matTooltip="Please contact support to unlock Donation tickets"
                              matTooltipTouchGestures="on"
                              #tooltip1="matTooltip" (click)="tooltip1.toggle()">&#9432;</span>
                      </label>
                      <div class="w100">
                        <select *ngIf="!isOnMobile" i18n-placeholder="@@CreateEditEventnametype" class="form-control" (change)="checkWhatStepsToShow(); removeItemPriceForRegItem('tickets', i); changedToDonateType($event, i)" formControlName="reg" placeholder="Name/Ticket type">
                          <option i18n="@@Regularticket" [ngValue]="0">Regular ticket</option>
                          <option i18n="@@Registration" [ngValue]="1">Registration</option>
                          <option [ngValue]="2" [disabled]="donateStatus.blocked">
                            <span i18n="item type option | donation_type">Donation</span>
                            <i *ngIf="donateStatus.blocked"> - blocked</i>
                          </option>
                        </select>
                        <select *ngIf="isOnMobile" mbsc-select [mbsc-options]="selectTicketsSettings" [rows]="2" i18n-placeholder="@@CreateEditEventnametype"
                          class="form-control" (onSet)="ticketTypeChange($event); removeItemPriceForRegItem('tickets', i); changedToDonateType($event, i)" formControlName="reg"
                          placeholder="Name/Ticket type">
                          <option i18n="@@Regularticket" [value]="0">Regular ticket</option>
                          <option i18n="@@Registration" [value]="1">Registration</option>
                          <option [value]="2" [disabled]="donateStatus.blocked">
                            <span i18n="item type option | donation_type">Donation</span>
                            <i *ngIf="donateStatus.blocked"> - blocked</i>
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="form-group">
                      <label i18n="@@Name" for="name">Name</label>
                      <div class="mb15 w100">
                        <input [attr.data-cy]="'ticketName'" i18n-placeholder="@@CreateEditEventnametype" class="form-control" trim="blur" type="text" formControlName="name" placeholder="Name/Ticket type">
                        <app-error-creator [@slideIn] *ngIf="ticketsArray.controls[i]['controls'].name && ticketsArray.controls[i]['controls'].name.touched && ticketsArray.controls[i]['controls'].name.invalid" [control]="ticketsArray.controls[i]['controls'].name"></app-error-creator>
                        <app-error-creator [@slideIn] *ngIf="ticketsArray.controls[i]['controls'].name.touched && ticketsArray.controls[i]['controls'].name.invalid && ticketsArray.controls[i]['controls'].name.hasError('pattern')" [control]="ticketsArray.controls[i]['controls'].name" name="name"></app-error-creator>
                      </div>
                    </div>

<!--                    Advanced step checkbox marked && ticket is not reg && donate was not blocked-->
<!--                    <div class="form-group" *ngIf=" !ticketsArray.controls[i]['controls'].reg.value && !donateStatus.blocked">-->
<!--                      <label class="check-label col-xs-12 col-sm-12" [ngStyle]="{'opacity': donateStatus.blocked ? '0' : '1'}">-->
<!--                        <input formControlName="open_ticket_price" type="checkbox"-->
<!--                               [checked]="ticketsArray.controls[i]['controls'].open_ticket_price.value"-->
<!--                               (change)="changeDonateTicketStatus($event.srcElement.checked, 'tickets', i)">-->
<!--                        <span class="checkmark"></span>-->
<!--                        <span i18n="open price ticket">Open price ticket</span>-->
<!--                      </label>-->
<!--                    </div>-->

                    <div class="row">
                      <div class="form-group mutate-float-right col-md-6 col-xs-6">
                        <label i18n="@@CreateEditEventSalestartdate" for="start_date" >Sale start date</label>
                        <input i18n-placeholder="@@Choosedate" class="form-control datepicker_start event-date" [attr.data-cy]="'saleStartDate'" type="text" formControlName="start_date" placeholder="Choose date" [mbsc-options]="itemsDatesSetting" mbsc-datetime>
                        <app-error-creator [@slideIn] *ngIf="ticketsArray.controls[i]['controls'].start_date && ticketsArray.controls[i]['controls'].start_date.touched && ticketsArray.controls[i]['controls'].start_date.invalid" [control]="ticketsArray.controls[i]['controls'].start_date"></app-error-creator>
                      </div>
                      <div class="form-group col-md-6 col-xs-6">
                        <label i18n="@@CreateEditEventFinalSaleedate" for="end_date">Final sale date</label>
                        <input i18n-placeholder="@@Choosedate" class="form-control datepicker_end event-date"  [attr.data-cy]="'saleEndDate'" type="text" formControlName="end_date" placeholder="Choose date" [mbsc-options]="itemsDatesSetting" mbsc-datetime>
                        <app-error-creator [@slideIn] *ngIf="ticketsArray.controls[i]['controls'].end_date && ticketsArray.controls[i]['controls'].end_date.touched && ticketsArray.controls[i]['controls'].end_date.invalid" [control]="ticketsArray.controls[i]['controls'].end_date"></app-error-creator>
                      </div>
                    </div>

                    <div class="row date-section">
                      <div class="form-group mutate-float-right col-md-6 col-xs-6">
                        <label i18n="item label | quantity" for="quantity">Quantity</label>
                        <input i18n-placeholder="placeholder | quantity" class="form-control" type="number" [attr.data-cy]="'ticketQuantityInput'" formControlName="quantity" placeholder="Quantity" (change)="quantityChange($event)">
                        <app-error-creator [@slideIn] *ngIf="ticketsArray.controls[i]['controls'].quantity.touched && ticketsArray.controls[i]['controls'].quantity.invalid" [control]="ticketsArray.controls[i]['controls'].quantity"></app-error-creator>
                      </div>
                      <div class="form-group col-md-6 col-xs-6">
                        <label i18n="@@CreateEditEventTicketsLimitation" for="limits">Tickets limitation</label>
                        <input i18n-placeholder="@@CreateEditEventTicketsLimitationPlaceholder" [attr.data-cy]="'ticketLimitInput'" class="form-control" type="number" formControlName="limits" placeholder="Maximum amount per deal">
                        <app-error-creator [@slideIn] *ngIf="ticketsArray.controls[i]['controls'].limits.touched && ticketsArray.controls[i]['controls'].limits.invalid" [control]="ticketsArray.controls[i]['controls'].limits"></app-error-creator>
                        <!--<div (click)="openInfo('info_limits')" class="col-md-2 col-xs-2 pl0 pr0">
                          <img width="20" class="img-responsive info_icon" src="/assets/images/editEvent/Info.png">
                        </div>-->
                      </div>
                    </div>

                    <!--Price for donation tickets-->
                    <div class="row date-section" *ngIf="ticketsArray.controls[i].value.reg == 2" [ngStyle]="{'opacity': (donateStatus.blocked || (!donateStatus.blocked && ticketsArray.controls[i]['controls'].donation_ticket_approved.value === 1))  ? '0.5' : '1'}">
                      <div class="form-group mutate-float-right col-md-6 col-xs-6">
                        <label for="donation_min">
                          <span i18n="item label | donation_min">Min price</span>
                         </label>
                        <input i18n-placeholder="placeholder | donation_min" class="form-control" type="number" formControlName="donation_min" [readonly]="(donateStatus.blocked || (!donateStatus.blocked && ticketsArray.controls[i]['controls'].donation_ticket_approved.value === 1))" placeholder="min" (change)="donatePriceChange($event, i)">
                        <app-error-creator [@slideIn] *ngIf="ticketsArray.controls[i]['controls'].donation_min.touched && ticketsArray.controls[i]['controls'].donation_min.invalid" [control]="ticketsArray.controls[i]['controls'].donation_min"></app-error-creator>
                      </div>
                      <div class="form-group mutate-float-right col-md-6 col-xs-6">
                        <label for="donation_max">
                          <span i18n="item label | donation_max">Max price </span>
                          <span *ngIf="!donateStatus.blocked && !donateStatus.approved"
                               i18n-matTooltip="contact customer support to change max value"
                               matTooltip="You may contact customer support to change Max value"
                               matTooltipTouchGestures="on"
                               #tooltip2="matTooltip" (click)="tooltip2.toggle()">&#9432;</span>
                          <span *ngIf="donateStatus.approved"
                                i18n-matTooltip="Contact customer support to change max value"
                                matTooltip="Contact customer support to change Max price value"
                                matTooltipTouchGestures="on"
                                #tooltip3="matTooltip" (click)="tooltip3.toggle()">&#9432;</span>
                          <span *ngIf="donateStatus.blocked"
                                i18n-matTooltip="Contact customer support to unlock donation tickets"
                                matTooltip="Contact customer support to unlock donation tickets"
                                matTooltipTouchGestures="on"
                                #tooltip4="matTooltip" (click)="tooltip4.toggle()">&#9432;</span>
                        </label>
                        <input i18n-placeholder="placeholder | donation_max" class="form-control" type="number" formControlName="donation_max" [readonly]="(donateStatus.blocked || (!donateStatus.blocked && ticketsArray.controls[i]['controls'].donation_ticket_approved.value === 1))" placeholder="max" (change)="donatePriceChange($event, i)">
                        <app-error-creator [@slideIn] *ngIf="ticketsArray.controls[i]['controls'].donation_max.touched && ticketsArray.controls[i]['controls'].donation_max.invalid" [control]="ticketsArray.controls[i]['controls'].donation_max"></app-error-creator>
                      </div>
                    </div>

                    <div class="row">
                      <div *ngIf="!ticketsArray.controls[i].value.id" [style.display]="(ticketsArray.controls[i].value.reg == 1 || ticketsArray.controls[i].value.reg == 2) ? 'none' : 'inherit'" class="form-group col-md-12 col-xs-12">
                        <label i18n="@@Price" id="price_label" for="price">Price</label>
                        <input i18n-placeholder="@@Price" id="price{{i}}" class="form-control" [attr.data-cy]="'ticketPriceInput'" type="number" formControlName="price" placeholder="Price" [required]="ticketsArray.controls[i].value.reg == 0" onkeydown="return event.keyCode !== 69" (change)="priceChange($event)">
                        <app-error-creator [@slideIn] *ngIf="ticketsArray.controls[i]['controls'].price.touched && ticketsArray.controls[i].value.reg == 0 && ticketsArray.controls[i]['controls'].price.invalid" [control]="ticketsArray.controls[i]['controls'].price" [name]="'ticket_price'"></app-error-creator>
                      </div>
                      <div *ngIf="ticketsArray.controls[i].value.id" [style.display]="(ticketsArray.controls[i].value.reg == 1 || ticketsArray.controls[i].value.reg == 2) ? 'none' : 'inherit'" class="form-group col-md-12 col-xs-12 title-align">
                        <label i18n="@@CreateEditEventPriceSemicol" for="price">Price:</label>
                        <input disabled="disabled" class="form-control" [value]="ticketsArray.controls[i].value.price">
                      </div>
                      <!-- <div *ngIf="ticketsArray.controls[i].value.reg !== 1" class="form-group col-ms-6 col-xs-6">
                        <div class="row">
                          <div class="col-md-12 col-xs-12 title-align">
                            <div class="col-md-12 col-xs-12 pl0 pr0">
                              <label i18n="input label | currency for ticket" for="currency{{i}}">Currency:</label>&nbsp;
                              <span *ngIf="eventData.eventStatus !== 2">{{ticketsArray.controls[i].value.currency}}</span>
                            </div>
                          </div>
                          <div class="col-md-12 col-xs-12">
                            <select
                              class="form-control"
                              i18n-placeholder="select tag | currency"
                              name="currency" id="currency{{i}}"
                              formControlName="currency"
                              placeholder="בחר מטבע"
                              *ngIf="eventData.eventStatus === 2"
                            >
                              <option i18n="create edit event | choose currency dropdown" value="">בחר מטבע</option>
                              <option *ngFor="let currency of currencyCodes" [ngValue]="currency">{{currency}}</option>
                            </select>
                            <app-error-creator [@slideIn] *ngIf="ticketsArray.controls[i].controls.currency.touched && ticketsArray.controls[i].controls.currency.invalid" [control]="ticketsArray.controls[i].controls.currency"></app-error-creator>
                          </div>
                        </div>
                      </div> -->
                    </div>

                    <div class="row mb10">
                      <div class="col-md-12 col-xs-12 title-align">
                        <div class="col-md-12 col-xs-12 pl0 pr0 label-wrap">
                          <label i18n="@@CreateEditEventItemcomment" for="comment">Item comment</label>
                        </div>
                        <!--<div (click)="openInfo('info_comment')" class="col-md-2 col-xs-2 pl0 pr0">
                          <img width="20" class="img-responsive info_icon" src="/assets/images/editEvent/Info.png">
                        </div>-->
                      </div>
                      <div class="col-md-12 col-xs-12">
                        <input i18n-placeholder="@@CreateEditEventItemcomment" [attr.data-cy]="'ticketCommentInput'" class="form-control" trim="blur" type="text" formControlName="comment" placeholder="Item comment">
                        <app-error-creator [@slideIn] *ngIf="ticketsArray.controls[i].value.comment.touched && ticketsArray.controls[i].value.comment.invalid" [control]="ticketsArray.controls[i].value.comment"></app-error-creator>
                      </div>
                    </div>
                    <div class="row mb10" *ngIf="ticketsArray.controls[i] && ticketsArray.controls[i]['controls'].id.value && eventData.eventStatus !== 2">
                      <label class="check-label col-xs-12 col-sm-12">
                        <input type="checkbox" [attr.checked]="ticketsArray.controls[i]['controls'].status.value == 1 ? 'checked' : null"  (change)="changeItemStatus($event.srcElement.checked, 'tickets', i)">
                        <span class="checkmark"></span>
                        <span i18n="@@CreateEditEventActiveTicket">Active ticket</span>
                      </label>
                    </div>
                    <div class="row mb10" *ngIf="ticketsArray.controls[i]">
                      <label class="check-label col-xs-12 col-sm-12">
                        <input type="checkbox"
                               [attr.checked]="ticketsArray.controls[i]['controls'].waiting_list.value == 1 ? 'checked' : null"
                               (change)="changeWaitingList($event.srcElement.checked, 'tickets', i)">
                        <span class="checkmark"></span>
                        <span i18n="item label | Waiting List">Waiting List</span>
                      </label>
                    </div>
                    <div class="actions-box row">
                      <div [hidden]="ticketsArray.invalid" [@fade] (click)="addItem( 'tickets', 'new')" class="col-md-6 col-xs-6 text-center add-item">
                        <!--<img class="img-responsive" width="30" src="/assets/images/editEvent/Plus2.png">-->
                        <span i18n="@@CreateEditEventcreatenewitembutton" [attr.data-cy]="'createNewTicketButton'" class="add-new-item">Create new ticket</span>
                      </div>
                      <div [hidden]="categoriesArray.invalid" [@fade] (click)="addItem('categories','new')" class="col-md-6 col-xs-6 text-center add-item">
                        <!--<img class="img-responsive" width="30" src="/assets/images/editEvent/Plus2.png">-->
                        <span i18n="@@CreateEditEventcreatenewcategorybutton" class="add-new-item">Add category</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="col-md-12 col-xs-12 add_ticket add-item add_category"
                     *ngIf="ticketsArray.length > 1 && !categoriesArray.length"
                     [hidden]="categoriesArray.invalid || categoriesArray.length >= ticketsArray.length" [@fade]
                     (click)="addItem('categories','new')">
                  <img class="img-responsive" width="30" src="/assets/images/editEvent/Plus2.png">
                  <span i18n="create new category button">הוסף קטגוריה</span>
                </div> -->

                <div formArrayName="categories" *ngFor="let category of categoriesArray.controls; let i = index;" class="tickets-form anyform col-ms-12 col-xs-12 pl0 pr0">
                  <div *ngIf="categoriesArray.controls.length > 1" class="sorting-block">
                    <div i18n="Category sorting" class="title-sorting">Sort category</div>
                    <!-- mbsc-select [mbsc-target]="selCur" [mbsc-options]="selectTicketsSettings" -->
                    <select class="form-control input-sorting" name="order-categories" id="order-categories"
                      [value]="i+1" (change)="onChangeArrayIndex($event, i, true)">
                      <option *ngFor="let category of categoriesArray.controls, let y = index" [value]="y+1">{{y+1}}</option>
                    </select>
                  </div>
                  <div class="ticket-details overflowhidden" [formGroupName]="i" [ngStyle]="categoriesArray.controls.length > 1 ? {'padding': '60px 15px 10px'} : {'padding': '0 15px 10px'}">

                    <div class="col-md-12 col-xs-12 form-group pl0 pr0">
                      <label i18n="@@Name" for="cat_name{{i}}">Name</label>
                      <div class="col-md-12 col-xs-12 pl0 pr0">
                        <input id="cat_name{{i}}" i18n-placeholder="@@CreateEditEventTypeCatName" class="form-control" trim="blur" type="text" formControlName="name" placeholder="Type category name">
                        <app-error-creator [@slideIn] *ngIf="categoriesArray.controls[i]['controls'].name && categoriesArray.controls[i]['controls'].name.invalid" [control]="categoriesArray.controls[i]['controls'].name"></app-error-creator>
                      </div>
                    </div>

                    <div class="row mb10">
                      <div class="col-md-12 col-xs-12 title-align">
                        <div class="col-md-12 col-xs-12 pl0 pr0 label-wrap">
                          <label i18n="@@Comment" for="comment">Comment</label>
                        </div>
                        <!--<div (click)="openInfo('info_comment_category')" class="col-md-2 col-xs-2 pl0 pr0">
                          <img width="20" class="img-responsive info_icon" src="/assets/images/editEvent/Info.png">
                        </div>-->
                      </div>
                      <div class="col-md-12 col-xs-12">
                        <input i18n-placeholder="@@AddCommentPlaceholder" class="form-control" trim="blur" type="text" formControlName="comment" placeholder="add comment">
                        <app-error-creator [@slideIn] *ngIf="categoriesArray.controls[i].value.comment.touched && categoriesArray.controls[i].value.comment.invalid" [control]="categoriesArray.controls[i].value.comment"></app-error-creator>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 col-xs-12 title-align">
                        <div class="col-md-12 col-xs-12 pl0 pr0 label-wrap">
                          <label i18n="@@Tickets" for="tickets">Tickets</label>
                        </div>
                        <!--<div (click)="openInfo('info_category_tickets')" class="col-md-2 col-xs-2 pl0 pr0">
                          <img width="20" class="img-responsive info_icon" src="/assets/images/editEvent/Info.png">
                        </div>-->
                      </div>
                      <app-error-creator style="padding-left: 15px" [@slideIn] *ngIf="categoriesArray.controls[i].value.tickets.touched && categoriesArray.controls[i].value.tickets.invalid" [control]="categoriesArray.controls[i].value.tickets"></app-error-creator>
                      <label class="check-label col-xs-12 col-sm-12" *ngFor="let ticket of ticketsArray.controls, let x = index">
                        <div class="order-tickets">
                          <div class="tickets-container" style="display: flex;">
                            <input type="checkbox" name="ticket{{x}}"
                            [checked]="(ticket['controls'].category_id.value && category['controls'].id.value && ticket['controls'].category_id.value.indexOf(category.controls.id.value.toString()) > -1) || (ticket['controls'].front_category_id.value && category['controls'].front_id.value && ticket['controls'].front_category_id.value.indexOf(category.controls.front_id.value.toString()) > -1)"
                            (change)="categoryTicketChange($event, x, categoriesArray.controls[i])">
                            <span class="checkmark"></span>
                            <span class="tickets-name">{{ticketsArray.controls[x]['controls'].name.value || 'Ticket ' + (x + 1)}}</span>
                          </div>
                          <div class="sort-container" style="display: flex;">
                            <div i18n="Ticket sorting" class="title-sorting">Sort ticket</div>
                            <!-- mbsc-select [mbsc-target]="selCur" [mbsc-options]="selectTicketsSettings" -->
                            <select class="form-control input-sorting" name="order-tickets" id="order-tickets"
                              [value]="x+1" (change)="onChangeArrayIndex($event, x, false)">
                              <option *ngFor="let ticket of ticketsArray.controls, let i = index" [value]="i+1">{{i+1}}</option>
                            </select>
                          </div>
                        </div>
                      </label>
                      <app-error-creator style="padding-left: 15px" [@slideIn] *ngIf="categoriesArray.controls[i]['controls'].tickets.touched && categoriesArray.controls[i]['controls'].tickets.invalid" [control]="categoriesArray.controls[i]['controls'].tickets" [name]="'tickets'"></app-error-creator>
                    </div>

                    <div class="actions-box row">
                      <div [hidden]="categoriesArray.invalid" [@fade] (click)="addItem('categories','new')" class="col-md-12 col-xs-12 text-center add_ticket add-item">
                        <!--<img class="img-responsive" width="30" src="/assets/images/editEvent/Plus2.png">-->
                        <span i18n="@@AddCategory" class="add-new-item">Add category</span>
                      </div>
                      <div [@fade] (click)="removeItem('categories', i)" class="text-center remove_ticket">
                        <!--<img class="img-responsive" width="30" src="/assets/images/dashboard/Delete.png">-->
                        <!--<span i18n="delete category button">מחק קטגוריה</span>-->
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </mat-expansion-panel>

            <!-- Advanced settings -->
            <mat-expansion-panel>
              <mat-expansion-panel-header class="mat-section" collapsedHeight="*" expandedHeight="*">
                <div class="col-md-12 col-xs-12 title-align event-steps">
                  <div class="col-md-12 col-xs-12 step_name">
                    <span i18n="section title | advanced" [attr.data-cy]="'advancedSettingsTab'">Advanced settings</span>
                  </div>
                  <span [hidden]="advancedStep && !advancedStep.valid" class="valid"></span>
                </div>
              </mat-expansion-panel-header>
              <div class="mt30 details-section mat-section">
                <div class="advanced-options">
                  <!-- <p class="advanced-heading mt10" (click)="toggleOptions = !toggleOptions">
                    <span i18n="section title | advanced">הגדרות מתקדמות</span>
                  </p> -->
                  <div formGroupName="advanced" [@slideIn] class="col-sm-12 col-lg-12">
                    <div class="row mb10">

                        <div *ngIf="eventData.eventStatus === 2 && needCurrency" class="time-zone-section">
                            <div class="">
                              <label i18n="input label | currency for ticket" for="currency">Currency:</label>
                              <span *ngIf="eventData.eventStatus !== 2">{{advancedStep.controls.currency.value}}</span>
                            </div>
                            <div class="set-currency">
                            <label class="check-label check-curr" *ngIf="isAccept">
                              <input type="checkbox" name="accept_crypto_payment"
                                    [checked]="advancedStep.controls.accept_crypto_payment.value"
                                    (change)="purchaseCheckboxChange('accept_crypto_payment')"
                              >
                              <span class="checkmark" style="margin-left: 0; margin-right: 10px;"></span>
                              <span i18n="input label | purchase id" class="accept-payment">Accept Crypto Payments</span>
                            </label>
                              <mat-form-field appearance="fill" class="create-edit-currency-select" style="min-width: 160px;">
                                <mat-label>{{'createEditEvent.ChooseCurrency' | translate:'Choose currency'}}</mat-label>
                                <mat-select formControlName="currency" required panelClass="currency-panel">
                                  <mat-option *ngFor="let currency of currencyCodes; let i = index;" [value]="currencyCodes[i].name">
                                    <img class="curr-icon" [src]="'/assets/images/country-flags3x2/' +currFlags[currency?.name]+'.svg'" alt="">
                                    {{currency?.name}} ({{currency?.symbol}})
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
<!--                            <select  class="form-control" i18n-placeholder="select tag | currency" name="currency" id="currency"-->
<!--                              formControlName="currency" placeholder="בחר מטבע" (change)="onChangeCurrency($event)" >-->
<!--                              <option i18n="create edit event | choose currency dropdown" value="">בחר מטבע</option>-->
<!--                              <option *ngFor="let currency of currencyCodes; let i = index;" [value]="currencyCodes[i].name" style="padding: 0 15px; background-position: left;"-->
<!--                                      [ngStyle]="{'backgroundImage': 'url(' + images_base_url + '/assets/images/country-flags3x2/' +currFlags[currency?.name]+'.svg'+ ')'}">{{currency?.name}} ({{currency?.symbol}})</option>-->
<!--                            </select>-->
                          </div>
                          <app-error-creator [@slideIn]
                                             *ngIf="advancedStep.controls.currency.touched && advancedStep.controls.currency.invalid"
                                             [control]="advancedStep.controls.currency"></app-error-creator>
                        </div>
                      <!-- Event Time Zone -->
                        <div class="time-zone-section event" [ngStyle]="{'display': !ticketTimeZoneChangedByUser ? 'block' : 'none'}">
                          <label i18n="input label | time zone for ticket" for="time_zone">Time zone:</label>
                          <span matTooltip="{{'createEditEvent.PickatimezoneHint' | translate:'Pick a time zone for your event'}}"
                                matTooltipTouchGestures="on"
                                #tooltip5="matTooltip" (click)="tooltip5.toggle()">&#9432;</span>
                          <select *ngIf="!ticketTimeZoneChangedByUser" #timeZone i18n-placeholder="input placeholder | event time zone" class="form-control" (change)="onChangeTimeZone($event, timeZone)"
                                  formControlName="time_zone" type="text" placeholder="time zone" name="time_zone" id="time_zone" mbsc-select [mbsc-options]="selectZonesSettings">
                            <option i18n="create edit event | choose currency dropdown" value="">Set time zone</option>
                            <option *ngFor="let zone of timeZones; let i = index;" [value]="zone">{{zone}}</option>
                          </select>
                          <app-error-creator [@slideIn]
                            *ngIf="advancedStep.controls.time_zone.touched && advancedStep.controls.time_zone.invalid"
                            [control]="advancedStep.controls.time_zone"></app-error-creator>
                        </div>
                      <!-- Ticket Time Zone -->
                      <div class="time-zone-section ticket" [ngStyle]="{'display': ticketTimeZoneChangedByUser ? 'block' : 'none'}" >
                        <label *ngIf="eventData.eventStatus == 2" i18n="input label | time zone for ticket" for="ticket_time_zone">Time zone:</label>&nbsp;
                        <select *ngIf="ticketTimeZoneChangedByUser" #ticketTimeZone i18n-placeholder="input placeholder | event time zone" class="form-control" (change)="onChangeTimeZone($event, ticketTimeZone)"
                                formControlName="ticket_time_zone" type="text" placeholder="time zone" name="ticket_time_zone" id="ticket_time_zone" mbsc-select [mbsc-options]="selectZonesSettings">
                          <option i18n="create edit event | choose currency dropdown" value="">Set time zone</option>
                          <option *ngFor="let zone of timeZoneFiltered;" [value]="zone">{{zone}}</option>
                        </select>
                        <app-error-creator [@slideIn]
                                           *ngIf="advancedStep.controls.ticket_time_zone.touched && advancedStep.controls.ticket_time_zone.invalid"
                                           [control]="advancedStep.controls.ticket_time_zone"></app-error-creator>
                      </div>
                        <div class="time-zone-section">
                          <label i18n="@@CreateEditEventFriendlyURL" id="friendly">Friendly URL</label><span
                          i18n="@@CreateEditEventFriendlyURLLimit" class="restrictions"> (Up to 15 characters)</span>
                          <span matTooltip="{{'createEditEvent.friendlyurlHint' | translate:'You can add up to 15 characters of your choice that will appear in your URL, this can help make your link more memorable!'}}"
                                matTooltipTouchGestures="on"
                                #tooltip6="matTooltip" (click)="tooltip6.toggle()">&#9432;</span>
                          <div class="friendly-url">
                            <span>{{getin_base_url}}</span>&nbsp;
                            <input class="form-control ltr" style="padding-bottom: 8px;" trim="blur" type="text" formControlName="url_friendly" placeholder="club57">
                          </div>
                          <app-error-creator [@slideIn]
                            *ngIf="advancedStep.controls.url_friendly.touched && advancedStep.controls.url_friendly.invalid"
                            [control]="advancedStep.controls.url_friendly" name="url_friendly"></app-error-creator>
                        </div>

                      <div class="col-md-12 col-xs-12 title-section">
                        <div class="col-md-12 col-xs-12 pl0 pr0">
                          <label i18n="@@CreateEditEventDealtype">Deal type:</label>
                          <span matTooltip="{{'createEditEvent.DealTypeHint' | translate:'Individual purchase form will request information about each attendee. A group purchase form will ask to provide information only about the ticket buyer, leaving the other tickets listed as &quot;sub participants&quot;'}}"
                                matTooltipTouchGestures="on"
                                #tooltip7="matTooltip" (click)="tooltip7.toggle()">&#9432;</span>
                        </div>
                        <!--<div (click)="openInfo('info_purchase_type')" class="col-md-2 col-xs-2 pl0 pr0 text-center">
                          <img width="20" class="img-responsive info_icon" src="/assets/images/editEvent/Info.png">
                        </div>-->
                      </div>
                      <div class="col-md-12 col-xs-12 form-group pr0 pl0">
                        <label class="radio-label">
                          <input type="radio" formControlName="purchase_type" [value]="2">
                          <span class="checkmark"></span>
                          <span i18n="@@CreateEditEventIndividualpurchaseform">Individual purchase form</span>
                        </label>
                        <label class="radio-label">
                          <input type="radio" formControlName="purchase_type" [value]="1">
                          <span class="checkmark"></span>
                          <span i18n="@@CreateEditEventGrouppurchaseform">Group purchase form</span>
                        </label>
                        <app-error-creator [@slideIn] *ngIf="advancedStep.controls.purchase_type.touched && advancedStep.controls.purchase_type.invalid" [control]="advancedStep.controls.purchase_type"></app-error-creator>
                      </div>
                      <div class="col-md-12 col-xs-12 title-section">
                        <div class="col-md-12 col-xs-12 pl0 pr0">
                          <label i18n="input option | one form for each participant" for="purchase_validation">Individual attendee approval</label>
                          <!-- <div (click)="openInfo('info_facebook_validation')" class="col-md-2 col-xs-2 pl0 pr0 text-center">
                                  <img width="20" class="img-responsive info_icon" src="/assets/images/editEvent/Info.png">
                                </div> -->

                          <span matTooltip="{{'createEditEvent.IndividualAttendeeApprovalHint' | translate:'If you check this box, you will have the ability to manually approve/decline/waitlist each purchase. The purchases will remain in a &quot;pending&quot; status until approved or declined. If you do not check the box, each purchase will be automatically approved.'}}"
                                matTooltipTouchGestures="on"
                                #tooltip8="matTooltip" (click)="tooltip8.toggle()">&#9432;</span>
                        </div>
                      </div>
                      <div class="col-md-12 col-xs-12 form-group pr0 pl0" >
                        <label class="radio-label">
                          <input id="purchase_validation" type="checkbox" [checked]="purchaseValidation" formControlName="purchase_validation"
                            (change)="setPurchaseValidation($event.target.checked)">
                          <span class="checkmark"></span>
                          <span i18n="input option | one form for each participant">Individual attendee approval</span>
                        </label>
                        <app-error-creator [@slideIn]
                          *ngIf="advancedStep.controls.purchase_validation.touched && advancedStep.controls.purchase_validation.invalid"
                          [control]="advancedStep.controls.purchase_validation"></app-error-creator>
                      </div>
                      <!-- <div class="col-md-12 col-xs-12 form-group pr0 pl0" [hidden]="!purchaseValidation">
                        <label class="radio-label">
                          <input type="checkbox" name="facebook_validation"
                          [checked]="advancedStep.controls.facebook_validation.value"
                          (change)="setSocialValidation('facebook_validation')">
                          <span class="checkmark"></span>
                          <span i18n="input option | one form for each participant">Login with Facebook</span>
                        </label>
                        <label class="radio-label">
                          <input type="checkbox"  name="instagram_validation"
                          [checked]="advancedStep.controls.instagram_validation.value"
                          (change)="setSocialValidation('instagram_validation')">
                          <span class="checkmark"></span>
                          <span i18n="input option | one form for each participant">Login with Instagram</span>
                        </label>
                        <label class="radio-label">
                          <input type="checkbox" name="email_validation"
                          [checked]="advancedStep.controls.email_validation.value"
                          (change)="setSocialValidation('email_validation')" >
                          <span class="checkmark"></span>
                          <span i18n="input option | one form for entire purchase">Login with email</span>
                        </label>
                        <app-error-creator [@slideIn]
                          *ngIf="advancedStep.controls.facebook_validation.touched && advancedStep.controls.facebook_validation.invalid"
                          [control]="advancedStep.controls.facebook_validation"></app-error-creator>
                      </div> -->

                        <div class="col-md-12 col-xs-12 title-section">
                          <div class="col-md-12 col-xs-12 pl0 pr0">
                            <label i18n="@@CreateEditEventEventprivacysettings">Event privacy settings:</label>
                            <span matTooltip="{{'createEditEvent.EventPrivacySettingsHint' | translate:'Public events will be displayed on our website while private events will be hidden and can only be accessed if you have the link.'}}"
                                  matTooltipTouchGestures="on"
                                  #tooltip9="matTooltip" (click)="tooltip9.toggle()">&#9432;</span>
                          </div>
                          <!-- <div (click)="openInfo('info_privacy')" class="col-md-2 col-xs-2 pl0 pr0 text-center">
                            <img width="20" class="img-responsive info_icon" src="/assets/images/editEvent/Info.png">
                          </div> -->
                        </div>
                        <div class="col-md-12 col-xs-12 form-group pr0 pl0">
                          <label class="radio-label">
                          <input type="radio" formControlName="privacy" [value]="0" (change)="privacyPublic($event)">
                          <span class="checkmark"></span>
                          <span i18n="@@CreateEditEventPublicEvent">Public event</span>

                          </label>
                          <label class="radio-label">
                          <input type="radio" formControlName="privacy" [value]="1" (change)="privacyPrivate($event)">
                          <span class="checkmark"></span>
                          <span i18n="@@CreateEditEventPrivateEvent">Private event</span>
                          </label>
                          <app-error-creator [@slideIn]
                            *ngIf="advancedStep.controls.privacy.touched && advancedStep.controls.privacy.invalid"
                            [control]="advancedStep.controls.privacy"></app-error-creator>
                        </div>



                    </div>

                    <div class="row mb-10">
                      <div class="col-md-12 col-xs-12 pl10 pr10">
                        <label i18n="input label | Checkout timer">Checkout timer:</label>
                      </div>
                      <div class="col-md-6 col-xs-6 pr10 pl10">
                        <input formControlName="checkout_timeout_counter" i18n-placeholder="placeholder | minutes" placeholder="minutes"
                               type="number" class="form-control" [required]="true" min="5" max="15" step="1"
                               onkeydown="if(event.key==='.'||event.key===','||event.key==='+'){event.preventDefault();}">
                        <app-error-creator [@slideIn] *ngIf="advancedStep.controls.checkout_timeout_counter.touched && advancedStep.controls.checkout_timeout_counter.invalid"
                                           [control]="advancedStep.controls.checkout_timeout_counter"></app-error-creator>
                      </div>
                    </div>


                    <div class="purchase-form-settings mb10 row pt10">
                      <p class="advanced-heading">
                        <span i18n="section title | purchase-form">Purchase form settings</span>
                        <span matTooltip="{{'createEditEvent.PurchaseFormSettingsHint' | translate:'Choose the fields you wish to be required upon purchase. You can also add your own in the &quot;manage custom field&quot; options by simply adding the question title and the type of answer (text, single choice, multiple choice or dropdown). Choose if you would like to set the question as required and save.'}}"
                              matTooltipTouchGestures="on"
                              #tooltip10="matTooltip" (click)="tooltip10.toggle()">&#9432;</span>
                      </p>
                      <p class="heading-desc">
                        <span i18n="section description | purchase-form">The purchase form’s field are listed below. Please tick the field you wish to set as required.</span>
                      </p>
                      <label class="check-label" *ngIf="isShowId" [ngStyle]="{'opacity': blockedInputs.id_number ? '0.7' : '1'}">
                        <input type="checkbox" name="id_number"
                               [disabled]="blockedInputs.id_number"
                               [checked]="advancedStep.controls.id_number.value"
                               (change)="purchaseCheckboxChange('id_number')"
                        >
                        <span class="checkmark"></span>
                        <span i18n="input label | purchase id">ID Number</span>
                        <span *ngIf="blockedInputs.id_number"
                              i18n-matTooltip="ID Number Blocked"
                              matTooltip="ID Number field blocked"
                              matTooltipTouchGestures="on"
                              #tooltip11="matTooltip" (click)="tooltip11.toggle()">&#9432;</span>
                      </label>
                      <label class="check-label" [ngStyle]="{'opacity': blockedInputs.gender ? '0.7' : '1'}">
                        <input type="checkbox" name="gender"
                               [disabled]="blockedInputs.gender"
                               [checked]="advancedStep.controls.gender.value"
                               (change)="purchaseCheckboxChange('gender')"
                        >
                        <span class="checkmark"></span>
                        <span i18n="input label | purchase gender">Gender</span>&nbsp;
                        <span *ngIf="blockedInputs.gender"
                              i18n-matTooltip="Gender Blocked"
                              matTooltip="Gender field blocked"
                              matTooltipTouchGestures="on"
                              #tooltip12="matTooltip" (click)="tooltip12.toggle()">&#9432;</span>
                      </label>
                      <label class="check-label">
                        <input id="birthday_validation" type="checkbox" [checked]="birthdayValidation" formControlName="birthday_validation"
                               (change)="setBirthDay($event.target.checked)">
                        <span class="checkmark"></span>
                        <span i18n="input option | event display birth of date">Date of birth</span>
                      </label>
                      <app-error-creator [@slideIn]
                                         *ngIf="advancedStep.controls.birthday_validation.touched && advancedStep.controls.birthday_validation.invalid"
                                         [control]="advancedStep.controls.birthday_validation"></app-error-creator>
                      <label class="check-label" [ngStyle]="{'opacity': blockedInputs.custom_instagram_url ? '0.7' : '1'}">
                        <input type="checkbox" name="custom_instagram_url"
                               [disabled]="blockedInputs.custom_instagram_url"
                               [checked]="advancedStep.controls.custom_instagram_url.value"
                               (change)="purchaseCheckboxChange('custom_instagram_url')"
                        >
                        <span class="checkmark"></span>
                        <span i18n="input label | purchase gender">Instagram account</span>&nbsp;
                        <span *ngIf="blockedInputs.custom_instagram_url"
                              i18n-matTooltip="Instagram Blocked"
                              matTooltip="Instagram field blocked"
                              matTooltipTouchGestures="on"
                              #tooltip13="matTooltip" (click)="tooltip13.toggle()">&#9432;</span>
                      </label>
                      <label class="check-label">
                        <input type="checkbox" name="producer_subscription"
                               [disabled]="true"
                               [checked]="advancedStep.controls.producer_subscription.value"
                               (change)="purchaseCheckboxChange('producer_subscription')"
                        >
                        <span class="checkmark" [ngStyle]="{'opacity': '0.7'}"></span>
                        <span i18n="input label | Convert purchaser" [ngStyle]="{'opacity': '0.7'}">Convert purchaser to follow</span>
                        <span
                              i18n-matTooltip="Set by Get-In admin, contact us to change the value here"
                              matTooltip="Set by Get-In admin, contact us to change the value here"
                              matTooltipTouchGestures="on"
                              #tooltip14="matTooltip" (click)="tooltip14.toggle()">&#9432;</span>
                      </label>
                      <label class="check-label" [ngStyle]="{'opacity': nftStatus.blocked ? '0.7' : '1'}">
                        <input type="checkbox" name="inputIsNft"
                               [disabled]="nftStatus.blocked"
                               [checked]="advancedStep.controls.inputIsNft.value"
                               (change)="purchaseCheckboxChange('inputIsNft')"
                        >
                        <span class="checkmark"></span>
                        <span i18n="input label | purchase inputIsNft">NFT</span>
                        &nbsp;
                        <span *ngIf="nftStatus.blocked"
                              i18n-matTooltip="NFT Blocked"
                              matTooltip="NFT feature blocked by admins"
                              matTooltipTouchGestures="on"
                              #tooltip15="matTooltip" (click)="tooltip15.toggle()">&#9432;</span>
                      </label>
                      <label class="check-label advanced-heading">
                        <span i18n="label | mng custom fields" (click)="openCustomFieldsModal($event, eventData)">Manage Custom Fields</span>
                      </label>
                      <label class="check-label advanced-heading">
                        <span (click)="openManagePasscodesModal($event, eventData)" t key="createEditEvent.PasswordProtection" default="Password Protection">Password Protection</span>
                        <span matTooltip="{{'createEditEvent.PasswordProtectionHint' | translate:'Subject to Getin team approval'}}"
                              matTooltipTouchGestures="on"
                              #tooltip16="matTooltip" (click)="tooltip16.toggle()">&#9432;</span>
                      </label>
                    </div>
                    <a class="help-link" href="{{localeId == 'he' ?
                     'https://intercom.help/getin-93198e5c33cd/he/articles/8489324-%D7%99%D7%A6%D7%99%D7%A8%D7%AA-%D7%90%D7%99%D7%A8%D7%95%D7%A2':
                     'https://intercom.help/getin-93198e5c33cd/en/articles/8489324-creating-an-event'}}" target="_blank"
                       t key="createEditEvent.ReadOurGuideFor" default="Read our guide for detailed explanations about our advanced options">
                    </a>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
            
            <div>

              <mat-expansion-panel *ngIf="registrationMode === 0 && showBoost">
                <mat-expansion-panel-header class="mat-section boost" collapsedHeight="*" expandedHeight="*">
                  <div class="col-md-12 col-xs-12 title-align event-steps">
                    <div class="col-md-12 col-xs-12 step_name">
                      <span class="beta" i18n="edit-event|beta">Beta</span>
                      <span><span i18n="section title|GetIn Marketing">GetIn Marketing</span> <span [innerHtml]="party_emoji"></span>
                        <span class="marketing-status pending" i18n="edit-event|Pending" *ngIf="eventData && eventData.boost && eventData.boost.status === 1">Pending</span>
                        <span class="marketing-status rejected" i18n="edit-event|Rejected" *ngIf="eventData && eventData.boost && eventData.boost.status === 2">Rejected</span>
                        <span class="marketing-status approved" i18n="edit-event|Approved" *ngIf="eventData && eventData.boost && eventData.boost.status === 3">Approved</span>
                      </span>
                    </div>
                  </div>
                </mat-expansion-panel-header>
                <div formGroupName="boost" class="marketing-section mat-section">
                  <p class="marketing-txt" i18n="edit-event|boost txt">
                    Expand your reach to top tickets buyers with Getin exclusive data matches you potential attendees. See exactly how many tickets our campaigns sell with clear statistics.
                  </p>
                  <ng-container>
                    <label class="check-label" [ngClass]="{'hideLabel' : disabledBoost()}">
                      <input type="checkbox" name="marketing_terms"
                             (change)="boostCheckboxChange('marketing_terms', $event)"
                             [disabled]="disabledBoost()"
                      >
                      <span class="checkmark"></span>
                      <span>
                        <span i18n="edit-event|checkbox1">I approve </span>
                        <a href="https://intercom.help/getin-93198e5c33cd/en/articles/8140238-getin-marketing-boost-terms" target="_blank" class="link" i18n="edit-event|boost terms">Getin marketing terms of use</a>
                      </span>&nbsp;
                    </label>
                    <p style="font-size: 0.9rem;color: red; text-align: center;"
                       i18n="edit-event|error terms"
                      [@slideIn] *ngIf="boostGroup.controls.marketing_terms.touched && boostGroup.controls.marketing_terms.invalid">Please approve marketing terms of use</p>
                    <label class="check-label" [ngClass]="{'hideLabel' : disabledBoost()}">
                      <input type="checkbox" name="status"
                             [checked]="boostGroup.controls['status'].value === 1"
                             (change)="boostCheckboxChange('status', $event)"
                             [disabled]="disabledBoost()"
                      >
                      <span class="checkmark"></span>
                      <span i18n="edit-event|checkbox2">I approve <span style="font-weight: 600;">Getin boost fee - 10%</span> from each sold ticket by Getin marketing.</span>&nbsp;
                    </label>
                  </ng-container>
                </div>
              </mat-expansion-panel>

            </div>
            <!-- *ngIf="event.valid" [disabled]="event.invalid && submitted"-->
            <div class="save-event">
              <button (click)="submitForm()" type="button" [attr.data-cy]="'readTermsButton'" class="col-md-12 col-xs-12 text-center save_event_btn" >
                <span i18n="@@CreateEditEventReadTermsandSaveBtn" *ngIf="eventData.eventStatus === 2">Read terms of use and save event</span>
                <span i18n="@@CreateEditEventSaveChanges" *ngIf="eventData.eventStatus !== 2">Save changes</span>
              </button>
            </div>
          </mat-accordion>

        </div>
      </form>
    </div>
  </div>
</div>

<div *ngIf="editEventTimeout" class="settings-container col-md-12 col-xs-12">
	<div class="row">
		<div class="event_text col-md-12 col-xs-12">
			<h1 i18n="@@CreateNewEvent" *ngIf="eventData?.eventStatus === 2" class="event-settings-title">Create new event</h1>
			<h1 i18n="@@CreateEditEvent" *ngIf="eventData?.eventStatus !== 2" class="event-settings-title">Edit event</h1>
		</div>
	</div>
  <h3 class="stop-event-timer">00:00:00</h3>
	<h2 class="no-longer-time" i18n="@@CreateEditEventEditingTimeExpired">Event editing time expired</h2>
</div>
