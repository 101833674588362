import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../environments/environment";
declare var window: any;
@Component({
  selector: 'app-post',
  templateUrl: './plasmic-post.component.html',
  styleUrls: ['./plasmic-post.component.css']
})

export class PlasmicPostComponent implements OnInit{
  constructor(private activatedRoute: ActivatedRoute) {}
  component: string = '';
  type: 'preview'|'published'|'versioned'|string = environment.plasmic.type;

  fetching: boolean = false;
  noData: boolean = false;

  async ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.component = params['post'].replace(/ /g,'');
      console.log('Component to Render',this.component);
    });

    this.fetching = true;
    const response = await fetch(
      `https://codegen.plasmic.app/api/v1/loader/html/${this.type}/${environment.plasmic.project_id}/${this.component}?hydrate=1&embedHydrate=1`,
      {
        headers: {
          // Your project ID and public API token
          'x-plasmic-api-project-tokens': `${environment.plasmic.project_id}:${environment.plasmic.api_token}`
        }
      }
    );
    const result = await response.json();
    this.fetching = false;
    if(result.html && result.html.length) {
      document.body.innerHTML = result.html;
    } else {
      this.noData = true;
    }


// If you want hydration to happen, load and call the hydrater
    if (window.__plasmicHydrater) {
      window.__plasmicHydrater.hydrateAll();
    } else {
      var script = document.createElement('script');
      script.src = 'https://codegen.plasmic.app/static/js/loader-hydrate.js';
      document.body.appendChild(script);
    }
  }
}
