<div *ngIf="!isNewUi" class="col-md-12 col-xs-12">
    <div class="modal-header">
      <button type="button" class="close" (click)="close(false)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <h2 class="text-center">{{modalText}}</h2>
    <div class="modal-footer" id="events-info-footer">
      <div class="col-md-12 col-xs-12">
        <div class="col-md-6 col-xs-6 mutate-float-left">
            <button i18n="@@No" type="button" class="btn btn-default col-md-12 col-xs-12" (click)="close(false)">No</button>
        </div>
        <div class="col-md-6 col-xs-6 mutate-float-right">
            <button i18n="@@Yes" type="button" class="btn btn-danger col-md-12 col-xs-12" (click)="close(true)">Yes</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isNewUi" class="col-md-12 col-xs-12">
    <div class="new-ui-modal-header">
      <button type="button" class="new-ui-close" (click)="close(false)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <h2 class="text-center new-ui-message-style">{{modalText}}</h2>
    <div class="new-ui-modal-footer" id="wallet-info-footer">
      <div class="col-md-12 col-xs-12 new-ui-buttons">
        <div class="col-md-6 col-xs-6 mutate-float-left">
            <button i18n="@@No" type="button" class="new-ui-btn-default col-md-12 col-xs-12" (click)="close(false)">No</button>
        </div>
        <div class="col-md-6 col-xs-6 mutate-float-right">
            <button i18n="@@Yes" type="button" class="new-ui-btn-danger col-md-12 col-xs-12" (click)="close(true)">Yes</button>
        </div>
      </div>
    </div>
  </div>
