import { LocalizationService } from './../../services/localization.service';
import { DialogService } from '../../services/dialog.service';
import { GlobalsService } from './../../globals';
import { CmsUsersService } from './../../services/cms-users.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {NgForm, Validators} from '@angular/forms';
import { phoneRegex, emailRegex } from '../../regex';
import { slideIn } from '../../animation';

@Component({
	selector: 'app-update-user-modal',
	templateUrl: './update-user-modal.component.html',
	styleUrls: ['./update-user-modal.component.css'],
	animations: [slideIn]
})
export class UpdateUserModalComponent implements OnInit {
	@ViewChild('editUserForm') editUserForm: NgForm;
	user_details = {
		id: 0,
		first_name: '',
		last_name: '',
		email: '',
		phone_number: '',
		company: '',
		identification_number: '',
		agent: false,
		phoneCountryCode : ''
	};
	phoneRegex = phoneRegex;
	emailRegex = emailRegex;

	constructor(
			public dialogRef: MatDialogRef<any>,
			@Inject(MAT_DIALOG_DATA) public data,
			private cmsUsersService: CmsUsersService,
			private globals: GlobalsService,
			private dialogService: DialogService,
			public localizationService: LocalizationService) { }

	ngOnInit() {
		this.user_details.id = this.data.id;
		this.user_details.first_name = this.data.first_name;
		this.user_details.last_name = this.data.last_name;
		this.user_details.email = this.data.email ? this.data.email : '';
		this.user_details.phone_number = this.data.phone ? this.data.phone : '';
		this.user_details.company = this.data.company;
		this.user_details.identification_number = this.data.identification_number;
		this.user_details.agent = this.data.agt === 1 ? true : false;
		if(this.user_details && this.user_details.phone_number && this.user_details.phone_number.match(/^0[\d]{1,2}[-]?[\d]{3}[-]?[\d]{4}$/)) {
			if(this.user_details.phone_number.charAt(0) === '0') {
				this.user_details.phone_number = this.user_details.phone_number.substr(1);
			}
		this.user_details.phone_number = '+972' + this.user_details.phone_number;
	}
	}
	setErrorOnphoneNumber(hasErrorfromNg2TelInput) {
		// const hasError = this.globals.hasErrorOnPhoneNumber(hasErrorfromNg2TelInput, this.editUserForm.form.controls['phone_number'].value);
		// const actionOnSetErrors = hasError ? {'invalid': true} : null;
		// this.editUserForm.form.controls['phone_number'].setErrors(actionOnSetErrors);
	}
	setPhoneNumber(phoneNumber) {
		this.editUserForm.form.controls['phone_number'].patchValue(phoneNumber);
	}
	telInputObject(obj, userPhone) {
		if(userPhone) {
			obj.setNumber(userPhone);
		}
		this.user_details.phoneCountryCode = obj.s.iso2;
	}
	onCountryChange(obj, v?) {
		this.user_details.phoneCountryCode = obj.iso2;
	}
	submitForm () {
	  // CUSTOM CHECK EMAIL AND PHONE
	  let email = this.editUserForm.controls.email.value;
	  let phone_number = this.editUserForm.controls.phone_number.value;
	  if(email === '' && phone_number === '') {
      this.editUserForm.form.markAllAsTouched();
      this.dialogService.alertsModal('salesman', 7, 4000, false);
      return;
    } else if (email && phone_number === ''){
      this.editUserForm.form.controls['phone_number'].setErrors(null);
      this.editUserForm.form.controls['email'].setValidators([Validators.email, Validators.required]);
      this.editUserForm.form.markAllAsTouched();
    } else if (phone_number && email === ''){
      this.editUserForm.form.controls['email'].setErrors(null);
      this.editUserForm.form.controls['phone_number'].setValidators([Validators.required]);
      this.editUserForm.form.markAllAsTouched();
    } else {
      this.editUserForm.form.markAllAsTouched();
    }

    // if (this.editUserForm.controls.email && this.editUserForm.controls.email.invalid) {
    //   this.editUserForm.form.controls['email'].setErrors({'invalid': true});
    //   this.editUserForm.form.markAllAsTouched();
    // }
	  if (this.editUserForm.invalid) {
      this.dialogService.alertsModal('salesman', 7, 4000, false);
      return
    }
    // CUSTOM CHECK EMAIL AND PHONE END
		const data = {
			id: this.user_details.id,
			salesman_id: this.data.salesman_id,
			first_name: this.editUserForm.controls.first_name.value,
			last_name: this.editUserForm.controls.last_name.value,
			email: this.editUserForm.controls.email.value,
			phone_number: this.editUserForm.controls.phone_number.value,
			company: this.editUserForm.controls.company.value ? this.editUserForm.controls.company.value : '',
			identification_number: this.editUserForm.controls.identification_number.value ? this.editUserForm.controls.identification_number.value : '',
			agent: this.editUserForm.controls.agent.value === true ? 1 : 0,
			phoneCountryCode: this.user_details.phoneCountryCode
		};
		this.globals.startLoader();
		this.cmsUsersService.editUser(data).subscribe(
			(response: any) => {
				this.globals.stopLoader();
				this.dialogRef.close({success: true});
				this.dialogService.alertsModal('success', response.code, 2000, true);
			},
			error => {
				this.globals.stopLoader();
				this.dialogService.alertsModal( (error.code) ? 'cms' : 'errors', (error.code) ? error.code : 4, 5000, false, error.log_id);
				if (error.code === 23 || error.code === 25) {
          this.editUserForm.form.controls['email'].setErrors({'incorrect': true});
        }
        if (error.code === 24 || error.code === 26) {
          this.editUserForm.form.controls['phone_number'].setErrors({'incorrect': true});
        }
			}
			);
	}
}
