import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SvixService} from "../../services/svix.service";
import {DialogService} from "../../services/dialog.service";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {GlobalsService} from "../../globals";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-svix-settings-modal',
  templateUrl: './svix-settings.component.html',
  styleUrls: ['./svix-settings.component.scss']
})
export class SvixSettingsComponent implements OnInit{
  user_id = 0;
  user;
  fetching = false;
  svixData = [];
  admin = false;
  cmsPage: false;

  // Select dropdown
  selectItems = [];
  svix_credential_id = '';

  // Name input
  showEditForm = false;
  editSvixForm = new UntypedFormGroup({
    name: new UntypedFormControl('',[Validators.required]),
  });
  editingApp: any;
  editingIndex: number;

  constructor(private svixService: SvixService,
              private dialogService: DialogService,
              public globals : GlobalsService,
              public dialogRef: MatDialogRef<SvixSettingsComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.user_id = this.data.user_id;
    this.user = this.authService.getUserInfo();
    // this.user.type = 1; // for test purpose
    this.admin = (this.user && this.user.type == 2) ? true : false;
    this.cmsPage = this.data.cmsPage;
    this.getData();

  }

  getData() {
    // console.log(this.data);
    let endPoint = this.cmsPage ? 'getSvixApp': 'getSvixAppProducer';
    this.fetching = true;
    this.svixService[endPoint](this.user_id).subscribe((res: any)=> {
      if (res.data) {
        this.svixData = res.data;
      }
      this.fetching = false;
    },error => {
      this.fetching = false;
      this.dialogService.alertsModal('errors', 4.1, 3000, false);
      // this.closeModal();
    })
  }

  getUrl(item) {
    this.globals.startLoader();
    let endPoint = this.cmsPage ? 'getUrl' : 'getUrlProducer';
    this.svixService[endPoint](this.user_id, item.id).subscribe((res: any) => {
      if(res.data) {
        this.dialogService.txtMsgModal(res.data.url, 30000, true);
      }
      this.globals.stopLoader();
    }, error => {
      this.dialogService.alertsModal('errors', 4.1, 3000, false);
      this.globals.stopLoader();
    })
  }
  //get Items for select
  addSvixApp() {
    this.globals.startLoader();
    this.svixService.getSvixSelect(this.user_id).subscribe((res: any) => {
      this.selectItems = res.data;
      this.globals.stopLoader();
    },error => {
      this.globals.stopLoader();
      this.dialogService.alertsModal('errors', 4.1, 3000, false);
    })
  }
  saveSvixApp() {
    this.globals.startLoader();
    this.svixService.setSvixApp(this.user_id, {svix_credential_id: +this.svix_credential_id}).subscribe((res: any) => {
      this.selectItems = [];
      if (res.data && res.code == 'success') {
        this.svixData.push(res.data);
      }
      this.globals.stopLoader();
    }, error => {
      this.globals.stopLoader();
      this.selectItems = [];
      this.dialogService.alertsModal('errors', 4.1, 3000, false);
    })
  }
  showSvixEditModal(item, index) {
    this.editingApp = item;
    this.editingIndex = index;
    this.editSvixForm.get('name').patchValue(item.api_data.name)
    this.showEditForm = true;
  }
  closeEditForm() {
    this.showEditForm = false;
  }
  closeAddForm() {
    this.selectItems = [];
  }
  updateSvixApp(id, index) {
    let name = this.editSvixForm.get('name').value;
    if(this.editingApp.api_data.name == name) {
      this.showEditForm = false;
      return;
    }
    let data = {name: name}
    this.globals.startLoader();
    this.svixService.updateSvixApp(id, this.user_id, data).subscribe((res: any) => {
      this.globals.stopLoader();
      this.svixData[index].api_data.name = data.name;
      this.editingApp = {};
      this.editingIndex = null;
      this.showEditForm = false;
    }, error => {
      this.globals.stopLoader();
      this.dialogService.alertsModal('errors', 4.1, 3000, false);
    })
  }
  deleteSvixApp(item, i) {
    let result = confirm(`Want to delete?`);
    if (result) {
      this.globals.startLoader();
      this.svixService.deleteSvixApp(item.id, this.user_id).subscribe(res=> {
        this.svixData.splice(i, 1);
        this.globals.stopLoader();
      }, error => {
        this.globals.stopLoader();
        this.dialogService.alertsModal('errors', 4.1, 3000, false);
      })
    }


  }

  closeModal() {
    this.dialogRef.close()
  }
}
