import { Injectable } from '@angular/core';
import { run } from 'vanilla-cookieconsent';

@Injectable({
  providedIn: 'root'
})
export class CookieConsentService {

  constructor() { }

  init() {
    return run({
      // autoClearCookies: true,
      // autoShow: true,
      // disablePageInteraction: false,
      // hideFromBots: true,
      manageScriptTags: false,
      // revision: 0,
      // root: null,
      // mode: 'opt-in',

      guiOptions: {
        consentModal: {
          layout: 'bar inline',
          position: 'bottom center',
          equalWeightButtons: false,
          flipButtons: false,
        },
        preferencesModal: {
          layout: 'box',
          equalWeightButtons: true,
          flipButtons: false,
        },
      },

      // onFirstConsent: ({ cookie }) => {
      //   console.log('onFirstConsent fired');
      // },
      //
      // onConsent: ({ cookie }) => {
      //   console.log('onConsent fired');
      // },
      //
      // onChange: ({ cookie, changedCategories, changedServices }) => {
      //   console.log('onChange fired', changedCategories, changedServices);
      // },
      //
      // onModalShow: ({ modalName }) => {
      //   console.log(modalName, 'visible');
      // },
      //
      // onModalHide: ({ modalName }) => {
      //   console.log(modalName, 'hidden');
      // },

      categories: {
        necessary: {
          enabled: true,
          readOnly: true,
        },
        analytics: {
          enabled: false,
          readOnly: false,
          autoClear: {
            cookies: [{ name: /^(_ga|_gid|_fbp|intercom-device-id-dkr11a7r|intercom-id-dkr11a7r|intercom-session-dkr11a7r|rl_user_id|rl_trait|rl_anonymous_id|rl_group_id|rl_group_trait|rl_page_init_referrer|rl_page_init_referring_domain|rl_session|rl_auth_token)/ }],
            // _ga|_gid - google
            // _fbp - facebook,
            // intercom-device-id-dkr11a7r|intercom-id-dkr11a7r|intercom-session-dkr11a7r - intercom
            // rl_user_id|rl_trait|rl_anonymous_i.... - rudderstack
          },
          services: {
            ga: {
              label: 'Google Analytics',
              onAccept: () => {
                // console.log('enabled ga');
              },
              onReject: () => {
                console.log('disabled ga');
              },
              cookies: [{name: /^(_ga|_gid)/}
              ]
            },
            rudderstack: {
              label: 'Rudderstack',
              onAccept: () => {
                // console.log('enabled Rudderstack');
              },
              onReject: () => {
                console.log('disabled Rudderstack');
              },
              cookies: [{name: /^(rl_user_id|rl_trait|rl_anonymous_id|rl_group_id|rl_group_trait|rl_page_init_referrer|rl_page_init_referring_domain|rl_session|rl_auth_token)/}]
            },
            intercom: {
              label: 'Intercom',
              onAccept: () => {
                // console.log('enabled Intercom');
              },
              onReject: () => {
                console.log('disabled Intercom');
              },
              cookies: [{name: /^(intercom-device-id-dkr11a7r|intercom-id-dkr11a7r|intercom-session-dkr11a7r)/}]
            },
            facebook: {
              label: 'Facebook',
              onAccept: () => {
                // console.log('enabled facebook');
              },
              onReject: () => {
                console.log('disabled facebook');
              },
              cookies: [{name: /^(_fbp)/}]
            },
            // another: {
            //   label: 'Generic Service',
            //   onAccept: () => {
            //     console.log('enabled generic service');
            //   },
            //   onReject: () => {
            //     console.log('disabled generic service');
            //   },
            // },
          },
        },
        ads: {
          enabled: false,
          readOnly: false,
        },
      },

      language: {
        default: 'en',
        // autoDetect: "browser"
        translations: {
          en: {
            consentModal: {
              title: 'About cookies on this site',
              description:
                'This website uses analytical, functional, and retargeting cookies to ensure you get the best experience.',
              acceptAllBtn: 'Allow all cookies',
              acceptNecessaryBtn: 'Reject all',
              showPreferencesBtn: 'Manage individual preferences',
              footer: `
                <a href="/privacy">Privacy Policy</a>
              `,
            },
            preferencesModal: {
              title: 'Cookie Preferences',
              acceptAllBtn: 'Allow all cookies',
              acceptNecessaryBtn: 'Reject all',
              savePreferencesBtn: 'Accept current selection',
              closeIconLabel: 'Close modal',
              serviceCounterLabel: 'Service|Services',
              sections: [
                {
                  title: 'Cookie Usage',
                  description:
                    'Cookies used on the site are categorized and below you can read about each category and allow or deny some or all of them. When categories than have been previously allowed are disabled, all cookies assigned to that category will be removed from your browser. Additionally you can see a list of cookies assigned to each category.',
                },
                {
                  title:
                    'Strictly Necessary Cookies <span class="pm__badge">always enabled</span>',
                  description: 'These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website, use a shopping cart or make use of e-billing services.',
                  linkedCategory: 'necessary',
                },
                {
                  title: 'Performance & Analytics Cookies',
                  description: 'They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.',
                  linkedCategory: 'analytics',
                },
                {
                  title: 'Marketing cookies',
                  description: 'Marketing cookies are used to track visitors across websites to allow publishers to display relevant and engaging advertisements. By enabling marketing cookies, you grant permission for personalized advertising across various platforms.',
                  linkedCategory: 'ads',
                }
              ],
            },
          },
        },
      },
    });
  }
}
