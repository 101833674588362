<div class="col-md-12 col-xs-12">
    <div class="modal-header">
        <button type="button" class="close" (click)="dialogRef.close()">
           <span aria-hidden="true">&times;</span>
        </button>
    <div class="text-center"><h4>Accept parameters</h4></div>
    </div>
      <div class="modal-body mb15">
        <div class="mt15 mb15">
            <p>Request contains parameters that do not exist in parameter&apos;s legend - proceed?</p>
        </div>
        <div class="row mb5">
            <div class="col-md-12 col-xs-12">
                <p>The following parameters were identified:</p>
               <div class="ltr" *ngIf="data.params.get.length > 0">
                 Get:
                <ul class="ltr">
                  <li  class="ltr" *ngFor="let param of data.params.get">
                    {{param}}
                  </li>
                </ul>
               </div>
               <div class="ltr" *ngIf="data.params.post.length > 0">
                Post: <br/>
                <ul class="ltr">
                  <li class="ltr" *ngFor="let param of data.params.post">
                    {{param}}
                  </li>
                </ul>
              </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default pull-left mr10" (click)="dialogRef.close()">Cancel</button>
        <button type="button" class="btn btn-success" (click)="confirmModal()">Save</button>
    </div>
</div>
