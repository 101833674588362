import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { KeyValue } from '@angular/common';
import { GlobalsService } from '../globals';
import { DialogService } from '../services/dialog.service';
import { EventService } from '../services/event.service';


@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.css'],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    '(document:mousedown)': 'onClick($event)'
  }
})

export class NotificationsListComponent implements OnInit {

  @Input() notifications: any;
  @Output() fireShowNotifications = new EventEmitter<any>();
  @Output() updateNotificationList = new EventEmitter<any>();

  constructor(private globals: GlobalsService,
              private dialogService: DialogService,
              private eventService: EventService) { }

  ngOnInit() {
    Object.keys(this.notifications).forEach(block => {
      this.notifications[block].forEach(notification => {
        const dateCreated: number = new Date(notification.date_created.replace(/-/g, '/')).valueOf();
        const dateNow: number = new Date().valueOf();
        const diffTime = Math.abs(dateNow - dateCreated);
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
        const diffMinutes = Math.floor(diffTime / (1000 * 60));
        const diffSeconds = Math.floor(diffTime / (1000));
        const timeDiff = diffDays > 0 ? diffDays + 'd' : diffHours > 0 ? diffHours + 'h' : diffMinutes > 0 ? diffMinutes + 'm' : diffSeconds + 's';
        notification.timeDiff = timeDiff;
      });
    })
  }

  onClick(e) {
    if(!e.target.closest('.notif-wrap') && !e.target.closest('.bell-wrap')) {
      this.fireShowNotifications.emit();
    }
  }

  reverseKeyOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
  }

  updateAgentStatus(notification, action, index){
    //console.log(action, notification.custom_event_id, notification.custom_salesman_id, notification.custom_event_seller, index);
    this.globals.startLoader();
    if(action && notification.custom_event_id && notification.custom_salesman_id && notification.custom_event_seller){
      this.eventService.updateAgentStatus(action, notification.custom_event_id, notification.custom_salesman_id, notification.custom_event_seller).subscribe(
        (response: any)=>{
          if(response.success && (response.action_made === 'approve' || response.action_made === 'decline')){
            const newNotifications = [];
            // HIDE Approve and Decline btns by setting custom_blockAction to 0
            Object.keys(this.notifications).forEach(block => { // foreach 'read' & 'unread'
              if (this.notifications[block].length) {
                this.notifications[block].forEach((notif, i) => {
                  if((( 'custom_event_id' in notif) && notif.custom_event_id === notification.custom_event_id) &&
                    (( 'custom_event_seller' in notif) && notif.custom_event_seller === notification.custom_event_seller) &&
                    (( 'custom_salesman_id' in notif) && notif.custom_salesman_id === notification.custom_salesman_id) &&
                    (( 'user_id' in notif) && notif.user_id === notification.user_id)) {
                    this.notifications[block][i].custom_blockAction = '0';
                  }
                });
                newNotifications.push(...this.notifications[block])
              }
            });
            this.updateNotificationList.emit({'notifications': [...newNotifications]}); // Update notifications also in app.component
            this.dialogService.alertsModal('success', 'reportSaved', 3000, true);
          } else {
            this.dialogService.alertsModal('errors', 4.1, 3000, false);
          }
          this.globals.stopLoader();
        },
        (error)=> {
          this.globals.stopLoader();
          this.dialogService.alertsModal('errors', (error.code) ? error.code : 4 ,5000, false, error.log);
        }
      );
    } else {
      this.globals.stopLoader();
      this.dialogService.alertsModal('errors', 4.1, 3000, false);
    }
  }
}
