import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {NavbarService} from '../../../services/navbar.service';
import {FilterEventsService} from '../../../services/filter-events.service';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.css']
})
export class EventCardComponent implements OnInit {
  @Input() event;
  @Input() type = '';
  userId = null;
  public baseProducerImgUrl = environment.images_base_url + '/profile/'
  public baseEventImgUrl = environment.images_base_url + '/gallery/mobile';

  constructor(private nav: NavbarService, private filterEventsService: FilterEventsService) {
    this.nav.currentUserId.subscribe(value => this.userId = value);
  }

  ngOnInit(): void {
    // console.log('app-event-card', this.event);
  }

  updateFolowers(producerAction) {
     this.filterEventsService.updateFollowers(producerAction, this.userId);
    // TODO CHANGE UPDATE FOLLOWERS
  }

}
