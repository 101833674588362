<section class="transaction-template-wrapper">
    <div i18n="Label | Check payment status" class="transaction-title">Check payment status</div>
    <div class="transaction-data-wrapper">
        <div class="transaction-data">
            <div *ngIf="transaction?.transaction_data?.from" class="transaction-block">
               <div i18n="Label | Address" class="transaction"> Address: </div>
               <div class="transaction-address">{{transaction?.transaction_data?.from}}</div>
            </div>
            <div *ngIf="eventId" class="transaction-block">
              <div i18n="Label | Event name" class="transaction">Event name:</div>
              <div class="transaction-value">
                <div class="transaction-price" style="font-weight: 600; width: 100%;">{{eventName}} {{' ('+ eventId +') '}}</div>
                <div i18n="Label | Ticket prices" *ngIf="globals.shouldBePlural(totalTickets)" class="transaction-price" style="font-weight: 400;">{{totalTickets}} {{' tickets '}}</div>
                <div i18n="Label | Ticket price" *ngIf="!globals.shouldBePlural(totalTickets)" class="transaction-price" style="font-weight: 400;">{{totalTickets}} {{' ticket '}}</div>
              </div>
            </div>
            <div *ngIf="ticketsPrice" class="transaction-block">
                <div i18n="Label | Tickets price" class="transaction">Tickets price:</div>
                <div class="transaction-value">
                    <div class="transaction-price" style="font-weight: 600;"> {{ticketsPrice | number: '0.2-12' }}{{' ETH'}}</div>
                    <span *ngIf="!isUsd" class="transaction-price">{{ticketsCurrency + ' '}} {{ticketPrice}}</span>
                    <span class="transaction-price">{{'$ '}} {{ticketPrice/eventCurRate*usdCurRate | number: '0.2-2'}}</span>
                    <!-- <div class="transaction-price"> {{transaction?.transaction_data?.value}}</div> -->
                </div>
            </div>
            <div *ngIf="transaction_fee" class="transaction-block">
              <div i18n="Label | Network fee" class="transaction">Network fee:</div>
              <div class="transaction-value">
                <div class="transaction-price" style="font-weight: 600;"> {{transaction_fee}}{{' ETH'}}</div>
                <span *ngIf="!isUsd" class="transaction-price">{{ticketsCurrency + ' '}}{{eventCurRate*transaction_fee | number: '0.2-2' }}</span>
                <span class="transaction-price">{{'$ '}}{{usdCurRate*transaction_fee | number: '0.2-2' }}</span>
              </div>
            </div>
            <div *ngIf="ticketsTotal" class="transaction-block">
              <div i18n="Label | Total price" class="transaction">Total price:</div>
              <div class="transaction-value">
                <div class="transaction-price" style="font-weight: 600;"> {{ticketsTotal | number: '0.2-12' }}{{' ETH'}}</div>
                    <span *ngIf="!isUsd" class="transaction-price">{{ticketsCurrency + ' '}} {{ticketsAmount}}</span>
                    <span class="transaction-price">{{'$ '}} {{ticketsAmount/eventCurRate*usdCurRate | number: '0.2-2'}}</span>
              </div>
            </div>
            <div *ngIf="transaction?.transaction_hash" class="transaction-block">
                <div class="transaction">Transaction Hash: </div>
                <div class="transaction-hash" (click)="copyToClipboard()" >{{transaction?.transaction_hash}}</div>
            </div>
        </div>
        <div class="checking-status">
            <div i18n="Label | Status" class="transaction-confirmation">Status: </div>
            <div class="transaction-status" style="text-align: left;">{{transactionStatus}}</div>
            <div *ngIf="isChecking" class="spinner-dual-ring"></div>
        </div>
        <div i18n="Label | Successfully purchased" *ngIf="transactionStatus === 'Success!' " class="transaction-notice">Ticket successfully purchased. Please check your email.</div>
        <!-- <div *ngIf="transactionStatus === 'Pending.' " class="transaction-explorer" (click)="checkTxExplorer()" >View on block explorer.</div> -->
        <div *ngIf="transactionStatus === 'Error' " class="transaction-error" style="color: crimson;">Transaction hash invalid or expired.</div>
    </div>
</section>
