<section class="dialog-container">
  <div class="container-wrapper">
    <div class="tickets-cover">
      <div class="caregories">
        <div class="category-wrapper" *ngIf="categories && categories.length">
          <div class="tickets-categories"
            *ngFor="let category of categories; index as i; first as isFirst; last as isLastCat">
            <div class="category-view" *ngIf="category.show">
              <div class="category-header cat-header-align" (click)="categoryShow(i)"
                   [ngClass]="{'cat-expanded': showCategory[i]}">
                <span *ngIf="category.name" class="category-name">{{category.name}}</span>
                <span *ngIf="category.comment" class="category-description">{{category.comment}}</span>
                <span class="category-arrow" [ngClass]="{'expanded': showCategory[i], 'rtl' : dir === 'rtl'}"></span>
              </div>
              <div class="tickets" [attr.data-cy]="'ticketItem'" [attr.data-cy-id]="ticket.id" [attr.data-cy-name]="ticket.name"
                [attr.item_id]="ticket.id" [hidden]="!showCategory[i]"
                [ngClass]="{'disabled_bg_': ticket.availability == 2 || ticket.availability == 3 || ticket.availability == 4 }"
                *ngFor="let ticket of ticketsFiltered[category.id]; let j = index; let isLastTick = last">
                <!-- <div [ngClass]="{'sold-out': (ticket?.limits.length < 1 || ticket.availability == 4)}"></div> -->
                <div class="ticket-align">
                  <!-- [ngClass]="{'sold-out-blur': (ticket?.limits.length < 1 || ticket.availability == 4)}"> -->

                <div class="ticket-data-container" [ngClass]="{'hideTicket': ( (hasRegularTicket && ticket.donation_type) || (hasDonateTicket && !ticket.donation_type) ||
                (waiting_list_mode &&
                        (
                         ((hasWaitedTicket || hasWaitedFreeTicket) && (!ticket.waiting_list || (ticket.waiting_list && ticket.availability == 1 && ticket.reg == 0)))
                        || ((hasRegularOnlyTicket || hasFreeTicket) && ((ticket.waiting_list && ticket.availability == 1 && ticket.reg == 1) || (ticket.waiting_list && ticket.availability != 1)) )
                        )
                        ) )}">
                  <div [ngClass]="{'ticket-photo-wrapper': dir == 'ltr', 'ticket-photo-wrapper-rtl': dir == 'rtl'}">
                    <div class="ticket-photo" *ngIf="ticket?.nft_image_url || coverPreview"
                    (click)="showTicketImgModal(ticket?.nft_image_url)">
                      <div class="ticket-img"
                        [style.background-image]="!ticket?.nft_image_url? 'url(' + coverPreview + ')' : 'url(' + ticket?.nft_image_url + ')'">
                      </div>
                    </div>
                  </div>
                  <div class="ticket-data">
                    <div [ngClass]="dir == 'ltr' ? 'ticket-data-align' : 'ticket-data-align-rtl'">
                      <div class="category-ticket-title"
                        [ngClass]="{'disabled': (waiting_list_mode && ticket.reg == 1) || ((ticket.availability == 2 || ticket.availability == 3 || ticket.availability == 4) && !waiting_list_mode) }">
                        {{ticket.name}}
                        <!-- <span i18n="item label | sold out" *ngIf="ticket.availability == 2"> - Sold out</span> -->
                        <!-- <span i18n="item label | sold out" *ngIf="ticket.availability == 4"> - Sold out 44</span> -->
                      </div>
                      <div [ngClass]="{'disabled c': (waiting_list_mode && ticket.reg == 1) || ((ticket.availability == 2 || ticket.availability == 3 || ticket.availability == 4) && !waiting_list_mode) }"
                        class="ticket-price" *ngIf="ticket.availability != 3 && !ticket.donation_type">
                        <ng-container
                          *ngIf="isShowDefaultPrice; then priceDefault; else priceWithFees">
                        </ng-container>
                        <span *ngIf="((ticket?.limits.length < 1 || ticket.availability == 4) && (!waiting_list_mode))
                    || (waiting_list_mode && !ticket.waiting_list && ticket.availability == (2||4) )" class="ticket-price-qnty disabled-price 1">Sold out</span>
                        <!-- <span class="ticket-registration" i18n="item label | registration" *ngIf="ticket.reg == 1"> - Registration</span> -->
                      </div>

                      <!--Donation type-->
                      <div [ngClass]="{'disabled d': ticket.availability == 2 || ticket.availability == 3 || ticket.availability == 4 }"
                           class="ticket-price" *ngIf="ticket.availability != 3 && ticket.donation_type">
                        <ng-container *ngIf="ticket?.limits.length > 0 && ticket.availability != 4">
                          <mat-form-field class="donate-form-field" [floatLabel]="'always'">
                            <mat-label i18n="amount">Amount</mat-label>
                            <input placeholder="0" matInput [disabled]="hasRegularTicket > 0" #ctrl="ngModel" type="number" (change)="donateChange($event, ticket, category.id)" (focus)="sendTheNewValue($event, category.id, j)" (input)="sendTheNewValue($event, category.id, j)" [min]="+ticket.donation_min" [max]="+ticket.donation_max" [step]="10" [(ngModel)]="ticket.price">
                            <span matTextPrefix>{{getCurrencySymbolF(currency)}}&nbsp;</span>
                            <button *ngIf="ticket.price" matSuffix mat-icon-button aria-label="Clear" class="btn-clear" (click)="ticket.price=0; clearDonate(ticket, category.id, j)">
                              <img src="/assets/images/svg-icons/close.svg" alt="clear">
                            </button>
                            <mat-error *ngIf="ctrl.errors?.min"><span i18n="Min">Min</span>: {{+ticket.donation_min}}</mat-error>
                            <mat-error *ngIf="ctrl.errors?.max"><span i18n="Max">Max</span>: {{+ticket.donation_max}}</mat-error>
                          </mat-form-field>
                        </ng-container>
                        <span  *ngIf="ticket?.limits.length < 1 || ticket.availability == 4" class="ticket-price-qnty disabled-price">Sold out</span>
                        <span *ngIf="ticket?.limits.length > 0 && ticket.availability != 4 && !ticket.donation_type" class="ticket-price-qnty">{{ticket.price | currency: currency:currency === 'MXN' || currency === 'CAD' ? 'symbol': 'symbol-narrow':'1.':getLocale(currency)}}</span>
                      </div>

                    </div>
                    <!-- <p *ngIf="ticket.reg != 1 && ticket.availability != 3 && ticket.price_with_commission != ticket.price"
                                  class="ticket-description dim-description">
                                  (<span i18n="item label | price with commission">מחיר כולל דמי טיפול של</span>
                                  {{ticket.price_with_commission - ticket.price | number:'1.0-2' | currency: currency}})
                                  </p> -->

                    <div class="description-and-qty-selector">
                      <div  [ngClass]="dir == 'ltr'? 'ticket-description' : 'ticket-description-rtl'">
                        <div class="description-text" *ngIf="ticket.comment">{{ticket.comment}}</div>
                        <div class="description-text waiting-list-txt" t key="eventPage.UserTickets.WaitingList" default="Waiting List" *ngIf="waiting_list_mode && (ticket.waiting_list && ticket.availability !== 1 && ticket.reg == 0)">Waiting List</div>
                      </div>

                      <!-- *ngIf="ticket?.limits.length > 0 && ticket?.availability == 1" -->
                      <div [ngStyle]="{'border-radius': dir === 'rtl' ? '0 8px;' : '8px 0'}"
                           [ngClass]="{'disabled_select': waiting_list_mode && (ticket.waiting_list == 0 && ticket.reg == 0 && ticket.availability == 4) }"
                           *ngIf="!ticket.donation_type" class="ticket-amount">
                        <div *ngIf="(waiting_list_mode &&
                        (
                         ((hasWaitedTicket || hasWaitedFreeTicket) && (!ticket.waiting_list || (ticket.waiting_list && ticket.availability == 1 && ticket.reg == 0)))
                        || ((hasRegularOnlyTicket || hasFreeTicket) && ((ticket.waiting_list && ticket.availability == 1 && ticket.reg == 1) || (ticket.waiting_list && ticket.availability != 1)) )
                        )
                        )
                        || (ticket?.limits.length <= 0 && ticket?.availability != 1 && ticket.waiting_list == 0 && !waiting_list_mode)
                        || (ticket.reg == 1 && ticket?.availability != 1 && !waiting_list_mode)
                        || (ticket.reg == 0 && ticket?.availability != 1 && !waiting_list_mode)
                        || hasDonateTicket" class="ticket-amount-soldout"></div>
                        <div class="ticket-btn-align">
                          <div class="ticket-btn"
                            [ngStyle]="{'background': ( (waiting_list_mode &&
                            (
                         ((hasWaitedTicket || hasWaitedFreeTicket) && (!ticket.waiting_list || (ticket.waiting_list && ticket.availability == 1 && ticket.reg == 0)))
                        || ((hasRegularOnlyTicket || hasFreeTicket) && ((ticket.waiting_list && ticket.availability == 1 && ticket.reg == 1) || (ticket.waiting_list && ticket.availability != 1)) )
                        )
                        )
                            || ((!(ticket?.limits.length > 0 && ticket?.availability == 1)) && !waiting_list_mode) ) ? '#615c5c': '#FFFFFF'}"
                            [attr.data-cy]="'selectTicketIncreaseQuantity'"
                            (click)="editTicket(ticket,'plus', category.id, 1, withoutCategory.length>1?false:(isLastTick && isLastCat))">
                            <div class="tickets-edit" [ngClass]="{'sold-out-btn-hide': ticket?.limits.length < 1}">
                              <span class="sign-plus"
                                [ngClass]="{'out-limits': ticket?.limits.length <= ticketLimits[ticket.id]['choosed'][category.id].amount}">
                                <svg width="13px" height="13px" viewBox="0 0 23 22" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M21.8422 10.6375V10.3375H21.5422H12.3371V1.27344V0.973437H12.0371H11.0866H10.7866V1.27344V10.3375H1.58154H1.28154V10.6375V11.5739V11.8739H1.58154H10.7866V20.9379V21.2379H11.0866H12.0371H12.3371V20.9379V11.8739H21.5422H21.8422V11.5739V10.6375Z"
                                    fill="#000000" stroke="#000000" stroke-width="1" />
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                        <span class="ticket-qnty" [ngStyle]="{'color': ( (waiting_list_mode &&
                            (
                         ((hasWaitedTicket || hasWaitedFreeTicket) && (!ticket.waiting_list || (ticket.waiting_list && ticket.availability == 1 && ticket.reg == 0)))
                        || ((hasRegularOnlyTicket || hasFreeTicket) && ((ticket.waiting_list && ticket.availability == 1 && ticket.reg == 1) || (ticket.waiting_list && ticket.availability != 1)) )
                        )
                        )
                            || ((!(ticket?.limits.length > 0 && ticket?.availability == 1)) && !waiting_list_mode) ) ? '#615c5c': '#FFFFFF'}">{{ticketLimits[ticket.id]['choosed'][category.id].amount}}</span>
                        <div class="ticket-btn-align">
                          <div class="ticket-btn"
                            [ngStyle]="{'background': ( (waiting_list_mode &&
                            (
                         ((hasWaitedTicket || hasWaitedFreeTicket) && (!ticket.waiting_list || (ticket.waiting_list && ticket.availability == 1 && ticket.reg == 0)))
                        || ((hasRegularOnlyTicket || hasFreeTicket) && ((ticket.waiting_list && ticket.availability == 1 && ticket.reg == 1) || (ticket.waiting_list && ticket.availability != 1)) )
                        )
                        )
                            || ((!(ticket?.limits.length > 0 && ticket?.availability == 1)) && !waiting_list_mode) ) ? '#615c5c': '#FFFFFF'}"
                            [attr.data-cy]="'selectTicketDecreaseQuantity'" (click)="editTicket(ticket,'minus', category.id, 1)">
                            <div class="tickets-edit" [ngClass]="{'sold-out-btn-hide': ticket?.limits.length < 1}">
                              <span class="sign-minus">
                                <svg _ngcontent-xjf-c17="" xml:space="preserve" xmlns:xlink="http://www.w3.org/1999/xlink" height="13px" id="Capa_1" style="enable-background:new 0 0 83 83;" version="1.1" viewBox="0 0 83 83" width="13px" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px"><g _ngcontent-xjf-c17=""><path _ngcontent-xjf-c17="" d="M81,36.166H2c-1.104,0-2,0.896-2,2v6.668c0,1.104,0.896,2,2,2h79c1.104,0,2-0.896,2-2v-6.668   C83,37.062,82.104,36.166,81,36.166z"></path></g></svg>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>
                    <!--                                                        <p i18n="item label | total tickets" class="total-tickets"> Total tickets-->
                    <!--                                                            <span DIR="LTR"-->
                    <!--                                                                class="tickets-qty">({{ticketLimits[ticket.id]['choosed'][category.id].amount}})</span>:-->
                    <!--                                                            {{total_price_n_commission}}-->
                    <!--                                                            {{currency}}-->
                    <!--                                                        </p>-->

                  </div>
                </div>
                  <p class="ticket-date-start" i18n="item label | available soon" *ngIf="ticket.availability == 3"
                    [ngClass]="{'disabled': ticket.availability == 2 || ticket.availability == 3 || ticket.availability == 4 }">
                    Coming soon {{ticket.start_date}}
                  </p>
                  <span class="ticket-date-start" i18n="Please use a separate" *ngIf="( (hasRegularTicket && ticket.donation_type) || (hasDonateTicket && !ticket.donation_type) )">Please use a separate order for donation ticket and regular ticket</span>
              </div>
                <!-- <p *ngIf="ticket.comment" class="ticket-description dim-description col-md-12 col-xs-12 text-center">{{ticket.comment}}</p> -->
                <ng-template #priceDefault>
<!--                  Default price without fees -->
                  <span *ngIf="(waiting_list_mode && ( ((ticket.availability == 1 && !ticket.waiting_list)||(ticket.availability != 1 && ticket.waiting_list)||(ticket.availability == 1 && ticket.waiting_list))))
              || (ticket?.limits.length > 0 && ticket.availability != 4 && !waiting_list_mode)" class="ticket-price-qnty 1">
                    {{ticket.price | currency: currency:currency === 'MXN' || currency === 'CAD' ? 'symbol': 'symbol-narrow':'1.':getLocale(currency)}}
                  </span>
                </ng-template>
                <ng-template #priceWithFees>
                  <ng-container *ngIf="(waiting_list_mode && ( ((ticket.availability == 1 && !ticket.waiting_list)||(ticket.availability != 1 && ticket.waiting_list)||(ticket.availability == 1 && ticket.waiting_list))))
              || (ticket?.limits.length > 0 && ticket.availability != 4 && !waiting_list_mode)">
                    <span  class="ticket-price-qnty 1">
                      {{calcTicketPriceWithFee(ticket.price) | currency: currency:currency === 'MXN' || currency === 'CAD' ? 'symbol': 'symbol-narrow':'1.':getLocale(currency)}}
                    </span>
                    <span class="ticket-price-detailed">
                      <span t key="eventPage.UserTickets.finalPriceNoSurprise" default="This is the final price - no surprises">This is the final price - no surprises</span>
                    </span>
                  </ng-container>
                </ng-template>
              </div>
            </div>
          </div>

        </div>
        <!-- without categories -->
        <div class="without-category" [attr.data-cy]="'ticketCategoryNoCategory'">
          <div class="tickets" [attr.data-cy]="'ticketItem'" [attr.data-cy-id]="ticket.id" [attr.data-cy-name]="ticket.name"
            [attr.item_id]="ticket.id" [hidden]="!withoutCategory.length"
            [ngClass]="{'disabled_bg_': ticket.availability == 2 || ticket.availability == 3 || ticket.availability == 4 }"
            *ngFor="let ticket of withoutCategory;let k = index; let wlast = last">
            <!-- <div [ngClass]="{'sold-out': (ticket?.limits.length < 1 || ticket.availability == 4)}"></div> -->
            <div class="ticket-align">
               <!-- [ngClass]="{'sold-out-blur': (ticket?.limits.length < 1 || ticket.availability == 4)}"> -->


            <div class="ticket-data-container" [ngClass]="{'hideTicket': ( (hasRegularTicket && ticket.donation_type) || (hasDonateTicket && !ticket.donation_type) ||
                (waiting_list_mode &&
                        (
                         ((hasWaitedTicket || hasWaitedFreeTicket) && (!ticket.waiting_list || (ticket.waiting_list && ticket.availability == 1 && ticket.reg == 0)))
                        || ((hasRegularOnlyTicket || hasFreeTicket) && ((ticket.waiting_list && ticket.availability == 1 && ticket.reg == 1) || (ticket.waiting_list && ticket.availability != 1)) )
                        )
                        ) )}">
                <div [ngClass]="dir == 'ltr' ? 'ticket-photo-wrapper' : 'ticket-photo-wrapper-rtl'">
                  <div class="ticket-photo" *ngIf="ticket?.nft_image_url || coverPreview"
                  (click)="showTicketImgModal(ticket?.nft_image_url)">
                    <div class="ticket-img"
                      [style.background-image]="!ticket?.nft_image_url ? 'url(' + coverPreview  + ')' : 'url(' + ticket?.nft_image_url + ')'">
                    </div>
                  </div>
                </div>
              <div class="ticket-data">
                <div [ngClass]="dir == 'ltr'? 'ticket-data-align' : 'ticket-data-align-rtl'">
                  <div class="category-ticket-title"
                    [ngClass]="{'disabled': (waiting_list_mode && ticket.reg == 1) || ((ticket.availability == 2 || ticket.availability == 3 || ticket.availability == 4) && !waiting_list_mode) }">
                    {{ticket.name}}
                    <!-- <span i18n="item label | sold out" *ngIf="ticket.availability == 2"> - Sold out</span> -->
                    <!-- <span i18n="item label | sold out" *ngIf="ticket.availability == 4"> - Sold out 4</span> -->
                    <!--                availability == 4 - ticket Final End date is Up, availability == 2 - sold out-->
                  </div>
                  <div [ngClass]="{'disabled w': (waiting_list_mode && ticket.reg == 1) || ((ticket.availability == 2 || ticket.availability == 3 || ticket.availability == 4) && !waiting_list_mode) }"
                    class="ticket-price" *ngIf="ticket.availability != 3 && !ticket.donation_type">
                    <ng-container
                      *ngIf="isShowDefaultPrice; then priceDefault_noCat; else priceWithFees_noCat">
                    </ng-container>
                    <span  *ngIf="((ticket?.limits.length < 1 || ticket.availability == 4) && (!waiting_list_mode))
                    || (waiting_list_mode && !ticket.waiting_list && ticket.availability == (2||4) )" class="ticket-price-qnty disabled-price 2">Sold out</span>
                    <!-- <span class="ticket-registration" i18n="item label | registration" *ngIf="ticket.reg == 1"> - Registration</span> -->
                  </div>

                  <!--Donation type-->
                  <div [ngClass]="{'disabled d2': ticket.availability == 2 || ticket.availability == 3 || ticket.availability == 4 }"
                       class="ticket-price" *ngIf="ticket.availability != 3 && ticket.donation_type">
                        <ng-container *ngIf="ticket?.limits.length > 0 && ticket.availability != 4">
                          <mat-form-field class="donate-form-field" [floatLabel]="'always'">
                            <mat-label i18n="amount">Amount</mat-label>
                            <input placeholder="0" matInput [disabled]="hasRegularTicket > 0" #ctrl="ngModel" type="number" (change)="donateChange($event, ticket, null)" (focus)="sendTheNewValue($event, null, k)" (input)="sendTheNewValue($event, null, k)" [min]="+ticket.donation_min" [max]="+ticket.donation_max" [step]="10" [(ngModel)]="ticket.price">
                            <span matTextPrefix>{{getCurrencySymbolF(currency)}}&nbsp;</span>
                            <button *ngIf="ticket.price" matSuffix mat-icon-button aria-label="Clear" class="btn-clear" (click)="ticket.price=0; clearDonate(ticket, null, k)">
                              <img src="/assets/images/svg-icons/close.svg" alt="clear">
                            </button>
                            <mat-error *ngIf="ctrl.errors?.min"><span i18n="Min">Min</span>: {{+ticket.donation_min}}</mat-error>
                            <mat-error *ngIf="ctrl.errors?.max"><span i18n="Max">Max</span>: {{+ticket.donation_max}}</mat-error>
                          </mat-form-field>
                        </ng-container>
                        <span *ngIf="ticket?.limits.length < 1 || ticket.availability == 4" class="ticket-price-qnty disabled-price">Sold out</span>
                        <span *ngIf="ticket?.limits.length > 0 && ticket.availability != 4 && !ticket.donation_type" class="ticket-price-qnty">{{ticket.price | currency: currency:currency === 'MXN' || currency === 'CAD' ? 'symbol': 'symbol-narrow':'1.':getLocale(currency)}}</span>
                  </div>

                </div>
                <!--                                               <p *ngIf="ticket.reg != 1 && ticket.availability != 3 && ticket.price_with_commission != ticket.price"-->
                <!--                                          class="ticket-description dim-description">-->
                <!--                                          (<span i18n="item label | price with commission">Price with commission:</span>-->
                <!--                                          {{ticket.price_with_commission - ticket.price | number:'1.0-2' | currency: currency:'symbol':'1.':getLocale(currency)}})-->
                <!--                                          </p>-->
                <div class="description-and-qty-selector">
                  <div [ngClass]="dir == 'ltr'? 'ticket-description' : 'ticket-description-rtl'">
                    <div class="description-text" *ngIf="ticket.comment">{{ticket.comment}}</div>
                    <div class="description-text waiting-list-txt" t key="eventPage.UserTickets.WaitingList" default="Waiting List" *ngIf="waiting_list_mode && (ticket.waiting_list && ticket.availability !== 1 && ticket.reg == 0)">Waiting List</div>
                  </div>

                  <!-- *ngIf="ticket?.limits.length > 0 && ticket?.availability == 1" -->
                  <div [ngStyle]="{'border-radius': dir === 'rtl' ? '0 8px;' : '8px 0'}"
                       [ngClass]="{'disabled_select': waiting_list_mode && (ticket.waiting_list == 0 && ticket.reg == 0 && ticket.availability == 4) }"
                       *ngIf="!ticket.donation_type" class="ticket-amount" >
                    <div *ngIf="(waiting_list_mode &&
                        (
                         ((hasWaitedTicket || hasWaitedFreeTicket) && (!ticket.waiting_list || (ticket.waiting_list && ticket.availability == 1 && ticket.reg == 0)))
                        || ((hasRegularOnlyTicket || hasFreeTicket) && ((ticket.waiting_list && ticket.availability == 1 && ticket.reg == 1) || (ticket.waiting_list && ticket.availability != 1)) )
                        )
                        )
                        || (ticket?.limits.length <= 0 && ticket?.availability != 1 && ticket.waiting_list == 0 && !waiting_list_mode)
                        || (ticket.reg == 1 && ticket?.availability != 1 && !waiting_list_mode)
                        || (ticket.reg == 0 && ticket?.availability != 1 && !waiting_list_mode)
                        || hasDonateTicket" class="ticket-amount-soldout"></div>
                    <div class="ticket-btn-align">
                      <div class="ticket-btn"
                        [ngStyle]="{'background': ( (waiting_list_mode &&
                            (
                         ((hasWaitedTicket || hasWaitedFreeTicket) && (!ticket.waiting_list || (ticket.waiting_list && ticket.availability == 1 && ticket.reg == 0)))
                        || ((hasRegularOnlyTicket || hasFreeTicket) && ((ticket.waiting_list && ticket.availability == 1 && ticket.reg == 1) || (ticket.waiting_list && ticket.availability != 1)) )
                        )
                        )
                            || ((!(ticket?.limits.length > 0 && ticket?.availability == 1)) && !waiting_list_mode) ) ? '#615c5c': '#FFFFFF'}"
                        [attr.data-cy]="'selectTicketIncreaseQuantity'" (click)="editTicket(ticket,'plus', null, 1, wlast)">
                        <div class="tickets-edit" [ngClass]="{'sold-out-btn-hide': ticket?.limits.length < 1}">
                          <span class="sign-plus"
                            [ngClass]="{'out-limits': ticket?.limits.length <= ticketLimits[ticket.id]['choosed']['noCat'].amount}">
                            <svg width="13px" height="13px" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M21.8422 10.6375V10.3375H21.5422H12.3371V1.27344V0.973437H12.0371H11.0866H10.7866V1.27344V10.3375H1.58154H1.28154V10.6375V11.5739V11.8739H1.58154H10.7866V20.9379V21.2379H11.0866H12.0371H12.3371V20.9379V11.8739H21.5422H21.8422V11.5739V10.6375Z"
                                fill="#000000" stroke="#000000" stroke-width="1" />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                    <span class="ticket-qnty" [ngStyle]="{'color':( (waiting_list_mode &&
                            (
                         ((hasWaitedTicket || hasWaitedFreeTicket) && (!ticket.waiting_list || (ticket.waiting_list && ticket.availability == 1 && ticket.reg == 0)))
                        || ((hasRegularOnlyTicket || hasFreeTicket) && ((ticket.waiting_list && ticket.availability == 1 && ticket.reg == 1) || (ticket.waiting_list && ticket.availability != 1)) )
                        )
                        )
                            || ((!(ticket?.limits.length > 0 && ticket?.availability == 1)) && !waiting_list_mode) ) ? '#615c5c': '#FFFFFF'}">{{ticketLimits[ticket.id]['choosed']['noCat'].amount}}</span>
                    <div class="ticket-btn-align">
                      <div class="ticket-btn"
                        [ngStyle]="{'background': ( (waiting_list_mode &&
                            (
                         ((hasWaitedTicket || hasWaitedFreeTicket) && (!ticket.waiting_list || (ticket.waiting_list && ticket.availability == 1 && ticket.reg == 0)))
                        || ((hasRegularOnlyTicket || hasFreeTicket) && ((ticket.waiting_list && ticket.availability == 1 && ticket.reg == 1) || (ticket.waiting_list && ticket.availability != 1)) )
                        )
                        )
                            || ((!(ticket?.limits.length > 0 && ticket?.availability == 1)) && !waiting_list_mode) ) ? '#615c5c': '#FFFFFF'}"
                        [attr.data-cy]="'selectTicketDecreaseQuantity'" (click)="editTicket(ticket,'minus', null, 1)">
                        <div class="tickets-edit" [ngClass]="{'sold-out-btn-hide': ticket?.limits.length < 1}">
                          <span class="sign-minus">
                            <svg _ngcontent-uho-c17="" xml:space="preserve" xmlns:xlink="http://www.w3.org/1999/xlink" _ngcontent-xjf-c17="" height="13px" id="Capa_1" style="enable-background:new 0 0 83 83;" version="1.1" viewBox="0 0 83 83" width="13px" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px"><g _ngcontent-uho-c17="" _ngcontent-xjf-c17=""><path _ngcontent-uho-c17="" _ngcontent-xjf-c17="" d="M81,36.166H2c-1.104,0-2,0.896-2,2v6.668c0,1.104,0.896,2,2,2h79c1.104,0,2-0.896,2-2v-6.668   C83,37.062,82.104,36.166,81,36.166z"></path></g></svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <!--                                              <p DIR="LTR" i18n="item label | total tickets" class="total-tickets"> Total tickets-->
                <!--                                                  <span DIR="LTR"-->
                <!--                                                      class="tickets-qty">{{'('+ticketLimits[ticket.id]['choosed']['noCat'].amount+')'}}</span>:-->
                <!--                                                  {{total_price_n_commission}}-->
                <!--                                                  {{currency}}-->
                <!--                                              </p>-->

              </div>
              </div>
                <p class="ticket-date-start" i18n="item label | available soon" *ngIf="ticket.availability == 3"
                  [ngClass]="{'disabled': ticket.availability == 2 || ticket.availability == 3 || ticket.availability == 4 }">
                  Coming soon {{ticket.start_date}}
                </p>
              <span class="ticket-date-start" i18n="Please use a separate" *ngIf="( (hasRegularTicket && ticket.donation_type) || (hasDonateTicket && !ticket.donation_type) )">Please use a separate order for donation ticket and regular ticket</span>
            </div>
            <!-- <p *ngIf="ticket.comment" class="ticket-description dim-description col-md-12 col-xs-12 text-center">{{ticket.comment}}</p> -->

            <ng-template #priceDefault_noCat>
              <span *ngIf="(waiting_list_mode && ( ((ticket.availability == 1 && !ticket.waiting_list)||(ticket.availability != 1 && ticket.waiting_list)||(ticket.availability == 1 && ticket.waiting_list))))
              || (ticket?.limits.length > 0 && ticket.availability != 4 && !waiting_list_mode)" class="ticket-price-qnty 2">
                {{ticket.price | currency: currency :currency === 'MXN' || currency === 'CAD' ? 'symbol': 'symbol-narrow':'1.':getLocale(currency)}}
              </span>
            </ng-template>
            <ng-template #priceWithFees_noCat>
              <ng-container *ngIf="(waiting_list_mode && ( ((ticket.availability == 1 && !ticket.waiting_list)||(ticket.availability != 1 && ticket.waiting_list)||(ticket.availability == 1 && ticket.waiting_list))))
              || (ticket?.limits.length > 0 && ticket.availability != 4 && !waiting_list_mode)">
                <span  class="ticket-price-qnty 2">
                  {{calcTicketPriceWithFee(ticket.price) | currency: currency:currency === 'MXN' || currency === 'CAD' ? 'symbol': 'symbol-narrow':'1.':getLocale(currency)}}
                </span>
                <span class="ticket-price-detailed">
                  <span t key="eventPage.UserTickets.finalPriceNoSurprise" default="This is the final price - no surprises">This is the final price - no surprises</span>
                </span>
              </ng-container>
            </ng-template>

          </div>
        </div>
      </div>
    </div>
    <div i18n="No tickets available" class="no-available"
      *ngIf="(categories && !categories.length) && !withoutCategory.length">No tickets available.</div>
  </div>
</section>
