import { Component, OnInit, OnDestroy } from '@angular/core';
import { EventCategoryService } from '../../services/event-category.service';
import { GlobalsService } from '../../globals';
import { DialogService } from '../../services/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { NavbarService } from '../../services/navbar.service';
import { EventCategory } from '../../interfaces/event-category.interface';
import { EventCategoryModalComponent } from '../../manage-categories/event-category-modal/event-category-modal.component';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.css']
})
export class CategoryListComponent implements OnInit, OnDestroy {

  categoriesLength: number;
  eventCategories: EventCategory[] = [];
  filteredCategories: EventCategory[] = [];
  categoryData: EventCategory;
  filterText;
  filter_type: number;
  filter_type_name: string;
  loader = false;
  page = 1;
  total: number;
  pageSize = 50;

  status_codes = [
    { code: 1, name: 'active' },
    { code: 0, name: 'inactive' },
  ];
  status_code: number;

  activeFilterString: string;
  language: string;

  constructor(
    private eventCategoryService: EventCategoryService,
    private globals: GlobalsService,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private nav: NavbarService
    ) { this.nav.customWrapperClass = 'full-screen'; this.language = this.globals.getLocaleId(); }

  getCategories() {
    // get all categories
    this.eventCategoryService.getCategories().subscribe(
      (response: any) => {
        if (response && response.categories.length) {
          this.loader = false;
          this.eventCategories = this.filteredCategories = response.categories;
          this.getCategoriesData(this.page, this.status_code);
        }
      },
      error => {
        this.dialogService.alertsModal('eventCategories', (error.code) ? error.code : 4, 5000, false, error.log);
      });
  }

  deleteCategory(id) {
    this.dialogService.areUSureModal('deleteSlide').subscribe(response => {
      if (response) {
        this.globals.startLoader();
        this.eventCategoryService.deleteCategory(id).subscribe((response: any) => {
          if (response) {
            this.globals.stopLoader();
            this.getCategories();
            this.dialogService.alertsModal('success', 'deleteCategory', 3000, true);
          }
        }, error => {
          this.globals.stopLoader();
          this.dialogService.alertsModal('error', error.code, 5000, false);
        });
      }
    });
  }

  createCategory() {
    const addCategoryModal = this.dialog.open(EventCategoryModalComponent, {
      data: { category: null, title: 'Create category', serviceAction: 'addCategory' },
      disableClose: true,
      autoFocus: false,
      width: '500px',
      panelClass: 'dialogModal'

    });
    addCategoryModal.afterClosed().subscribe(
      result => {
        if (result !== undefined && result.success) {
            this.getCategories();
        } else {
          // todo
        }
      }
    );
  }

  editCategory(id) {
    const categoryData = this.eventCategories.find(category => category.id === id);
    const editCategoryModal = this.dialog.open(EventCategoryModalComponent, {
      data: { category: categoryData, title: 'Edit category', serviceAction: 'editCategory' },
      disableClose: true,
      autoFocus: false,
      width: '500px',
      panelClass: 'dialogModal'
    });
    editCategoryModal.afterClosed().subscribe(
      result => {
        if (result !== undefined && result.success) {
            this.getCategories();
        } else {
          // todo
        }
      }
    );
  }

  clearSearch() {
    this.filteredCategories = this.eventCategories.filter(f => f);
  }

  searchCategory() {
    if (this.filterText === '') {
      this.clearSearch();
      return;
    }
    this.filteredCategories = this.eventCategories.filter((item: EventCategory) => {
      return item.name.toLowerCase().includes(this.filterText.toLowerCase());
    });
  }

  filterStatus(filter_status) {
    this.filterText = '';
    const filter_type_index = this.status_codes.findIndex((obj: any) => { if (obj) { return obj.code === +filter_status } });
    this.filter_type_name = this.status_codes[filter_type_index].name;
    this.status_code = this.status_codes[filter_type_index].code;
    this.filteredCategories = this.eventCategories.filter(f => f.status === this.status_code);
  }

  getCategoriesData(pageNumber, filter_status, searchMode = false, fromPagination = false) {
    const perPage = this.pageSize;
    const start = (pageNumber - 1) * perPage;
    const end = start + perPage;
    if (this.filterText) {
      searchMode = true;
    }
    // need to do or delete if no need pagination
    // console.log('get category pagination data', perPage, start, end, pageNumber, filter_status, this.eventCategories);
    this.total = this.filteredCategories.length;
    this.page = pageNumber;
  }

  ngOnInit() {
    this.page = 1;
    this.getCategories();
  }

  ngOnDestroy() {
    this.nav.customWrapperClass = '';
  }

}
