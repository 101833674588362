<section class="cms_web_hooks row mb30" [attr.event_id]="event_id">
	<mat-tab-group [@.disabled]="true">
    <mat-tab label="Settings">

			<h1 style="color: #fff;">Web Hooks Settings</h1>
      <ng-container *ngTemplateOutlet="web_hooks_params"></ng-container>

			<app-web-hooks-form *ngIf="form_inputs" [web_hook_obj]="" [form_inputs]="form_inputs" [actionMode]="'add'" [event_id]="event_id" [custom_inputs]="custom_inputs" [instagram_url]="instagram_url" (getWebHook)="getWebHooks()"></app-web-hooks-form>
		</mat-tab>
	<mat-tab label="Edit">
    <h1 style="color: #fff;">Edit Web Hooks</h1>
    <ng-container *ngTemplateOutlet="web_hooks_params"></ng-container>
		<div class="main-wrapper mt40" *ngIf="existing_web_hook.length == 0"><p class="text-center">No Web Hooks set for this event</p></div>
		<div class="main-wrapper mt40" *ngIf="existing_web_hook.length > 0">
					<div class="pannel-wrapper" *ngFor="let web_hook of existing_web_hook">
								<div class="row vertical-align cursor-pointer"  (click)="toggleWebHookBlock(web_hook.id)">
										<div class="col-md-2 col-xs-6">
											<span class="ft-18 mr5"> &nbsp;Web hook id: {{web_hook.id}}</span>
										</div>
										<div class="col-md-1 col-xs-2">
											<span class="label label-primary ml20">{{web_hook.method | uppercase}}</span>
										</div>
										<div class="col-md-1 col-xs-2">
											<span class="label label-success" *ngIf="web_hook.status == 1">Active</span>
											<span class="label label-danger" *ngIf="web_hook.status == 0">Inactive</span>
										</div>
										<div class="col-md-8 col-xs-2">
												<button type="button" class="fa-position" style="background-color: transparent;">
														<span title="Show options"><i style="font-size: 2em;" class="fa fa-angle-down"></i></span>
													</button>
										</div>
									</div>
									<div class="row mt20" *ngIf="web_hook.visible_state == 'opened'" [@slideIn]>
											<div class="col-md-12 col-xs-12">
												<app-web-hooks-form [web_hook_obj]="web_hook" [form_inputs]="form_inputs" [actionMode]="'edit'" [event_id]="event_id" [custom_inputs]="custom_inputs" [instagram_url]="instagram_url"  (http_method_updated)="updateWehookLabel($event)"></app-web-hooks-form>
											</div>
									</div>
							<div class="divider mt15"></div>
					</div>
			</div>
	</mat-tab>


	</mat-tab-group>
</section>


<ng-template #web_hooks_params>
  <div class="param_map col-md-12 col-xs-12 mt20">
    <p>Valid paramenters for API request:</p>
    <div class="table-responsive">
      <table class="table table-striped table-hover" id="parameters_table" style="font-size: 0.9em;">
        <tr>
          <th class="bold">Description</th>
          <th class="bold">Parameter name</th>
        </tr>
        <tr *ngFor="let default of form_inputs?.default_inputs">
          <td *ngIf="default.input_name == 'first_name' && default.required == 1">First name</td>
          <td *ngIf="default.input_name == 'first_name' && default.required == 1" class="ltr">$participant_first_name</td>
          <td *ngIf="default.input_name == 'last_name' && default.required == 1">Last name</td>
          <td *ngIf="default.input_name == 'last_name' && default.required == 1" class="ltr">$participant_last_name</td>
          <td *ngIf="default.input_name == 'user_email' && default.required == 1">Email</td>
          <td *ngIf="default.input_name == 'user_email' && default.required == 1" class="ltr">$participant_email</td>
          <td *ngIf="default.input_name == 'user_phone' && default.required == 1">Phone</td>
          <td *ngIf="default.input_name == 'user_phone' && default.required == 1" class="ltr">$participant_phone</td>
          <td *ngIf="default.input_name == 'identification_number' && default.required == 1">ID number</td>
          <td *ngIf="default.input_name == 'identification_number' && default.required == 1" class="ltr">$participant_identification_number</td>
          <td *ngIf="default.input_name == 'birth_date' && default.required == 1">Date of Birth</td>
          <td *ngIf="default.input_name == 'birth_date' && default.required == 1" class="ltr">$participant_birth_date</td>
          <td *ngIf="default.input_name == 'age' && default.required == 1">Age</td>
          <td *ngIf="default.input_name == 'age' && default.required == 1" class="ltr">$participant_age</td>
          <td *ngIf="default.input_name == 'gender' && default.required == 1">Gender</td>
          <td *ngIf="default.input_name == 'gender' && default.required == 1" class="ltr">$participant_gender</td>
        </tr>
        <tr *ngFor="let custom of custom_inputs">
          <td [innerHtml]="custom.title | trustHtml"></td>
          <td class="ltr">$custom_{{custom.id}}</td>
        </tr>
        <tr *ngIf="instagram_url">
          <td>Instagram URL</td>
          <td class="ltr">$participant_instagram_url</td>
        </tr>
        <tr>
          <td>Attendee status</td>
          <td class="ltr">$participant_status</td>
        </tr>
        <tr>
          <td>סMailing status</td>
          <td class="ltr">$participant_mailing</td>
        </tr>
        <tr>
          <td>Total items purchased in transaction</td>
          <td class="ltr">$purchase_items_amount</td>
        </tr>
        <tr>
          <td>Amount paid</td>
          <td class="ltr">$participant_payment</td>
        </tr>
        <tr>
          <td>Payment method</td>
          <td class="ltr">$participant_payment_method</td>
        </tr>
        <tr>
          <td>Amount to pay excluding fees</td>
          <td class="ltr">$payment_no_vat</td>
        </tr>
      </table>
    </div>
  </div>
</ng-template>
