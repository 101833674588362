import { Component, OnInit, ViewChild, Inject} from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
// ---------------Angular Material------------------
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// ---------------Services------------------
import { GlobalsService } from '../../globals';
import { LocalizationService } from './../../services/localization.service';
// -----------------------------------------
import { UserData } from './../../interfaces/authentication.interface';
import { MbscDatetimeOptions } from '@mobiscroll/angular';
import { slideIn } from '../../animation';
import { emailRegex } from '../../regex';
// import { DialogService } from '../../services/dialog.service';
// import { NavbarService } from '../../services/navbar.service';
import { ModalService } from '../../services/modal.service';
import { PhoneVerificationDialog } from '../phone-verification-dialog/phone-verification-dialog';
import { PhoneVerificationDialogService } from '../../services/phone-verification-dialog.service';
import {MatDialog} from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
import {RudderstackService} from '../../services/rudderstack.service';
import {RecaptchaEnterpriseService} from '../../services/recaptcha-enterprise.service';

@Component({
  selector: 'app-personal-info-modal',
  templateUrl: './personal-info-modal.component.html',
  styleUrls: ['./personal-info-modal.component.css'],
  animations: [slideIn]
})
export class PersonalInfoModalComponent implements OnInit {
	@ViewChild('personalInfoModalForm') personalInfoModalForm: NgForm;
  @ViewChild('newPhoneForm') newPhoneForm: NgForm;
  @ViewChild('newEmailForm') newEmailForm: NgForm;

	emailRegex = emailRegex;
	dir: string;
	isEmail: boolean;
	isPhoneVerified: boolean;
  validatePhone: boolean;
	phoneToVerify: string;
  verifySubscription: Subscription;

	//This is for default value
	user_info: UserData = {
		user_id: 0,
		first_name: '',
		last_name: '',
		phone: '',
		email: '',
		identification_number: '',
		facebook_id: '',
		age: '',
		birth_date: '',
		gender: '',
		user_link: '',
		type: 0,
		company: '',
		img: '',
		phoneCountryCode: '',
		fb_user_link: '',
		personal_info: 0,
		id: ''
	}

	myBirthdaySettings: MbscDatetimeOptions = {
        theme: this.globals.mobiscrollTheme(),
        lang: this.globals.LOCALE_ID,
        animate: 'pop',
        headerText: (this.globals.getLocaleId() === 'he') ? 'תאריך לידה' : 'Date of Birth',
	};

  isChangePhone = false;
  isChangeEmail = false;
  ipInfo: any;
  passwordMissing: boolean = false;
  isShowOtp = false;
  disabbleBtn = {isDisable: false, otp: null};
  isShowTimer = true;
  isTimeUp = false;
  otpTimer = 0;
  testOnlyOtpToken = '';
  phoneCountryCode = 'il';
  newPhone;
  newPassword;
  newEmail;
  showPhoneForm = true;
  showEmailForm = true;


	constructor(
		private globals: GlobalsService,
		public dialogRef: MatDialogRef<PersonalInfoModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		public localizationService: LocalizationService,
		private modalService: ModalService,
    private dialog: MatDialog,
    private phoneVerificationDialogService: PhoneVerificationDialogService,
    private authService: AuthService,
    private rudderstackService: RudderstackService,
    private recaptchaService: RecaptchaEnterpriseService
		) {
			this.dir = this.globals.getDir();
		}

	ngOnInit() {
		console.log('user info', this.data);
    this.isPhoneVerified = false;
		this.user_info = this.data;
    this.newEmail = this.user_info.email;
    this.newPhone = this.user_info.phone;
    this.phoneCountryCode = this.user_info.phoneCountryCode;
		this.isEmail = !!this.user_info.email;
		this.validatePhone = false; // set to false to disable phone validation on backend and delete Verify btn
    this.verifySubscription = this.phoneVerificationDialogService.isVerified.subscribe(verify => this.isPhoneVerified = verify); // Detect if phone is verified
		if (this.user_info && this.user_info.phone && this.user_info.phone.match(/^0[\d]{1,2}[-]?[\d]{3}[-]?[\d]{4}$/)) {
			if (this.user_info.phone.charAt(0) === '0') {
				this.user_info.phone = this.user_info.phone.substr(1);
			}
			this.user_info.phone = '+972' + this.user_info.phone;
		}
	}

  changeEmail() {
    this.isChangeEmail = true;
  }

  changePhone() {
    this.isChangePhone = true;
  }

  closePhoneModal() {
    this.isChangePhone = false;
    this.isShowOtp = false;
  }

  closeEmailModal() {
    this.isChangeEmail = false;
    this.isShowOtp = false;
  }

  setTimeCounter(event) {
    console.log('setTimeCounter', event);
    this.isTimeUp = event == 0;
    this.otpTimer = event;
    this.isTimeUp ? this.globals.stopLoader() : false;
  }

  checkingEmail(otp?) {
    console.log('checkingEmail: ', this.newEmail, this.newPassword, this.newEmailForm.value.email, this.newEmailForm.value.password, this.user_info.email, this.newEmailForm);
    if (this.newEmailForm.valid) {
      this.showEmailForm = false;
      this.globals.startLoader();
      let otp_code = otp;
      let email = this.newEmail;
      let password = this.newPassword;

      this.recaptchaService.execute('change_email').then(token => {
        const data = {email, password,  otp_code, 'g_recaptcha_response': token};
        this.phoneVerificationDialogService.changeUserEmail(data).subscribe((response: any) => {
          console.log('checkingEmail response', response);
          this.globals.stopLoader();
          this.showEmailForm = true;
          if (response && response.code == 'success') {
            console.log('Enter OTP code', response.data.code);
            this.isShowOtp = true;
            if (response.data && response.data.code) {
              this.testOnlyOtpToken = response.data.code;
            }
          }else if (response && response.user_info) {
            this.newEmail = '';
            this.newPassword = '';
            this.closeEmailModal();
            this.user_info = response.user_info;
            this.modalService.alertsModal('success', 'success', 3000, true);
          }
        }, error => {
          // this.closeEmailModal();
          this.globals.stopLoader();
          this.showEmailForm = true;
          console.log('Error', error);
          if (error.code) {
            this.modalService.alertsModal('errors', error.code, 3000, false);
          }
        });
      });

    } else {
			this.markFormGroupTouched(this.newEmailForm.form);
			this.modalService.alertsModal('errors', 18, 2000, false);
    }

  }

  resolved(otp, endpoint = 'changeUserEmail' || 'changeUserPhone', isOTPForm) {

    let otp_code = otp;
    let data;
    let action;

    if (endpoint === 'changeUserEmail') {
      if(!this.newEmailForm.valid) {
        this.modalService.alertsModal('errors', 18, 2000, false);
        return
      }

      let email = this.newEmail;
      let password = this.newPassword;
      data = {email, password, otp_code, 'g_recaptcha_response': ''};
      action = 'change_email';
    }

    if (endpoint === 'changeUserPhone') {
      if(!this.newPhoneForm.valid) {
        this.modalService.alertsModal('errors', 18, 2000, false);
        return
      }

      let phone = this.newPhone;
      let phone_country_code = this.phoneCountryCode; // this.user_info.phoneCountryCode;
      data = {phone, phone_country_code, otp_code, 'g_recaptcha_response': ''};
      action = 'change_phone';
    }

    this.recaptchaService.execute(action).then(token => {
      data.g_recaptcha_response = token;
      this.phoneVerificationDialogService[endpoint](data).subscribe((response: any) => {
        console.log('checkingPhone response', response);
        this.globals.stopLoader();
        this.showPhoneForm = true;
        if (response && response.code == 'success') {
          console.log('Enter OTP code', response.data.code);
          // this.user_info.phone = this.newPhoneForm.value.phone;
          this.isShowOtp = true;
          if (response.data && response.data.code) {
            this.testOnlyOtpToken = response.data.code;
          }
        } else if (response && response.user_info) {
          if (endpoint === 'changeUserEmail') {
            this.newEmail = '';
            this.newPassword = '';
            this.closeEmailModal();
            this.user_info = response.user_info;
            this.modalService.alertsModal('success', 'success', 3000, true);
          }
          if (endpoint === 'changeUserPhone') {
            // this.newPhone = '';
            this.closePhoneModal();
            this.user_info = response.user_info;
            this.modalService.alertsModal('success', 'success', 3000, true);
          }
        }

      }, error => {
        // this.closePhoneModal();
        // this.isShowOtp = false;
        this.globals.stopLoader();
        if (endpoint === 'changeUserEmail' && !isOTPForm) {
          this.showEmailForm = true;
        }
        if (endpoint === 'changeUserPhone' && !isOTPForm) {
          this.showPhoneForm = true;
        }

        console.log('Error', error);
        if (error.code === 53) {
          if ( endpoint === 'changeUserEmail') {
            this.modalService.alertsModal('errors', 55, 3000, false);
          }
          if ( endpoint === 'changeUserPhone') {
            this.modalService.alertsModal('errors', 56, 3000, false);
          }
        } else {
          this.modalService.alertsModal('errors', error.code, 3000, false);
        }
      });
    });
  }
  checkingPhone(otp?, form?: NgForm) {
    console.log('checkingPhone', form, this.newPhoneForm, this.newPhoneForm.value.phone, this.phoneCountryCode, this.user_info.phone, this.user_info.phoneCountryCode);
    if (this.newPhoneForm.valid) {
      this.showPhoneForm = false;
      this.globals.startLoader();
      let otp_code = otp;
      let phone = this.newPhone;
      let phone_country_code = this.phoneCountryCode; // this.user_info.phoneCountryCode;

      this.recaptchaService.execute('change_phone').then(token => {
        const data = {phone, phone_country_code, otp_code, 'g_recaptcha_response': token};
        this.phoneVerificationDialogService.changeUserPhone(data).subscribe((response: any) => {
          console.log('checkingPhone response', response);
          this.globals.stopLoader();
          this.showPhoneForm = true;
          if (response && response.code == 'success') {
            console.log('Enter OTP code', response.data.code);
            // this.user_info.phone = this.newPhoneForm.value.phone;
            this.isShowOtp = true;
            if (response.data && response.data.code) {
              this.testOnlyOtpToken = response.data.code;
            }
          } else if (response && response.user_info) {
            // this.newPhone = '';
            this.closePhoneModal();
            this.user_info = response.user_info;
            this.modalService.alertsModal('success', 'success', 3000, true);
          }
        }, error => {
          // this.closePhoneModal();
          this.globals.stopLoader();
          this.showPhoneForm = true;
          console.log('Error', error);
          if (error.code) {
            this.modalService.alertsModal('errors', error.code, 3000, false);
          }
        });
      });


    } else {
			// this.markFormGroupTouched(this.newPhoneForm.form);
			this.modalService.alertsModal('errors', 18, 2000, false);
    }
  }

  onPhoneChange(event?, phomeForm?, form?) {
    console.log('onPhoneChange', event.target.value, phomeForm, form);
  }

	onChange(formField) {
		// console.log('formField', formField.target.value);
		this.markFormGroupTouched(this.personalInfoModalForm.form);
    this.setPhoneVerified();
	}

  setErrorOnphoneNumber(hasErrorfromNg2TelInput) {
    console.log('setErrorOnphoneNumber');
    let userPhone = this.newPhone; // this.user_info.phone;
		const countryCode = userPhone.substr(0,4);
		if (userPhone.charAt(0) === '0') {
			userPhone = userPhone.substr(1);
			userPhone = '+972' + userPhone;
		}
		if (countryCode.includes('972')) {
			if (userPhone.charAt(0) !== '+') {userPhone = '+' + userPhone};

      this.isChangePhone ? this.newPhoneForm.form.controls['phone'].patchValue(userPhone) : this.personalInfoModalForm.form.controls['phone'].patchValue(userPhone);
			const localPrefix = userPhone.substr(4,4);
			const localNumber = userPhone.substr(8);
			// console.log('includes 972', localPrefix, localNumber);
			if(localNumber.length === 5 && (localPrefix.includes('5526') || localPrefix.includes('5527') || localPrefix.includes('5551'))) {
				hasErrorfromNg2TelInput = false;
			}
		}
		const hasError = this.globals.hasErrorOnPhoneNumber(hasErrorfromNg2TelInput, this.isChangePhone ? this.newPhoneForm.form.controls['phone'].value : this.personalInfoModalForm.form.controls['phone'].value);
		const actionOnSetErrors = hasError ? {'invalid': true} : null;
    this.isChangePhone ? this.newPhoneForm.form.controls['phone'].setErrors(actionOnSetErrors) : this.personalInfoModalForm.form.controls['phone'].setErrors(actionOnSetErrors);
		// console.log('setErrorOnphoneNumber', hasError, actionOnSetErrors, hasErrorfromNg2TelInput, this.personalForm.form.controls['phone'].value);
	}
	setPhoneNumber(phoneNumber) {
    this.isChangePhone ? this.newPhone = phoneNumber :
		this.personalInfoModalForm.form.controls['phone'].patchValue(phoneNumber);
    console.log('setPhoneNumber', phoneNumber, this.newPhone);
	}
	telInputObject(obj, userPhone) {
		// this.obj = obj;
		if(userPhone && !this.isChangePhone) {
			obj.setNumber(userPhone);
		} else if (userPhone && userPhone.value && this.isChangePhone) {
      console.log('userPhone:', userPhone.value);
      obj.setNumber(userPhone.value);
    }
		this.isChangePhone ? this.phoneCountryCode = obj.s.iso2 : this.user_info.phoneCountryCode = obj.s.iso2;
    console.log('telInputObject: ', obj.s.iso2, 'userPhone: ', userPhone, 'newPhoneForm: ',  this.phoneCountryCode, 'phoneCountryCode: ', this.user_info.phoneCountryCode);
  }
	onCountryChange(obj) {
		this.isChangePhone ? this.phoneCountryCode = obj.iso2 : this.user_info.phoneCountryCode = obj.iso2;
    console.log('onCountryChange', obj.iso2, this.phoneCountryCode);
  }

	// setErrorOnphoneNumber(hasErrorfromNg2TelInput) {
	// 	const countryCode = this.user_info.phone.substr(0,4);
	// 	if (this.user_info.phone.charAt(0) === '0') {
	// 		this.user_info.phone = this.user_info.phone.substr(1);
	// 		this.user_info.phone = '+972' + this.user_info.phone;
	// 	}
	// 	if (countryCode.includes('972')) {
	// 		if (this.user_info.phone.charAt(0) !== '+') {this.user_info.phone = '+' + this.user_info.phone};
	// 		this.personalInfoModalForm.form.controls['phone'].patchValue(this.user_info.phone);
	// 		const localPrefix = this.user_info.phone.substr(4,4);
	// 		const localNumber = this.user_info.phone.substr(8);
	// 		// console.log('includes 972', localPrefix);
	// 		if(localNumber.length === 5 && (localPrefix.includes('5526') || localPrefix.includes('5527') || localPrefix.includes('5551'))) {
	// 			hasErrorfromNg2TelInput = false;
	// 		}
	// 	}
	// 	const hasError = this.globals.hasErrorOnPhoneNumber(hasErrorfromNg2TelInput, this.personalInfoModalForm.form.controls['phone'].value);
	// 	const actionOnSetErrors = hasError ? {'invalid': true} : null;
	// 	this.personalInfoModalForm.form.controls['phone'].setErrors(actionOnSetErrors);
	// 	// console.log('setErrorOnphoneNumber', hasError, actionOnSetErrors, hasErrorfromNg2TelInput, this.personalInfoModalForm.form.controls['phone'].value);
	// }

	// setPhoneNumber(phoneNumber) {
	// 	this.personalInfoModalForm.form.controls['phone'].patchValue(phoneNumber);
	// }

	// telInputObject(obj, userPhone) {
	// 	if(userPhone) {
	// 		obj.setNumber(userPhone);
	// 	}
	// 	this.user_info.phoneCountryCode = obj.s.iso2;
	// }

	// onCountryChange(obj) {
	// 	this.user_info.phoneCountryCode = obj.iso2;
	// }

	isNumber(event){
		var charCode = event.which ? event.which : event.keyCode;
		return /\d/.test(String.fromCharCode(charCode));
	}
  setPhoneVerified() {
    // If phone will be changed after SMS confirmation
    if (this.isPhoneVerified && this.phoneToVerify !==  this.user_info.phone) {
      this.isPhoneVerified = false;
    }
    // Check if user entered the same phone which was already verified
    if (this.ifPhoneAlreadyVerified()) {
      this.isPhoneVerified = this.ifPhoneAlreadyVerified();
    }
    // If user wants to change number after last successfully verification his Phone
    if (!this.ifPhoneAlreadyVerified() && this.phoneVerificationDialogService.getSeconds() > 0) {
      this.phoneVerificationDialogService.stopTimer();
      this.isPhoneVerified = false;
    }
  }
  openModal() {
	  const timerCount = this.phoneVerificationDialogService.getSeconds();
	  const lastVerifiedPhone = this.phoneVerificationDialogService.getLastVerifiedPhone();
	  const phone = this.data.phone;
	  const phoneCountryCode = this.data.phoneCountryCode;
	  const isEnteredPhoneValid = this.personalInfoModalForm.form.controls['phone'].valid;
    this.phoneToVerify = this.user_info.phone;

	  if (!isEnteredPhoneValid) {
	    return // exit if phone is not valid
    } else if (timerCount > 0 && timerCount < 60 ) {  // if modal was opened before, just show the modal again
        this.showModal();
    } else if (timerCount === 60 && lastVerifiedPhone !== '') {
      this.phoneVerificationDialogService.stopTimer();
      this.authService.verifyPhoneBySMS(phone, phoneCountryCode).subscribe((res: any) => {
          // console.log(res);
          this.showModal();
          this.phoneVerificationDialogService.startTimer();
      }, error => {
        console.log('Error', error);
      });
    } else {
      this.authService.verifyPhoneBySMS(phone, phoneCountryCode).subscribe((res: any) => {
        // console.log(res);
        this.showModal();
        if (this.phoneVerificationDialogService.getSeconds() <= 0) {
          this.phoneVerificationDialogService.startTimer();
        }
      }, error => {
        console.log('Error', error);
      });
    }
  }

  showModal() {
    const dialogPhoneRef = this.dialog.open(PhoneVerificationDialog, {
      width: '300px',
      data: {
        phone: this.user_info.phone,
        phoneCountryCode: this.user_info.phoneCountryCode,
        phone_validate_code: '',
        isVerified: this.isPhoneVerified
      },
      panelClass: 'phone-verify'
    });
  }

  ifPhoneAlreadyVerified() {
	  const bufferPhone = this.phoneVerificationDialogService.getLastVerifiedPhone();
	  return (bufferPhone === this.user_info.phone && this.user_info.phone.length) ? true : false;

  }

	onSubmit(){
		console.log('Submit form value', this.personalInfoModalForm.value, this.personalInfoModalForm.form);
		if (this.personalInfoModalForm.invalid) {
			this.markFormGroupTouched(this.personalInfoModalForm.form);
			this.modalService.alertsModal('errors', 18, 2000, false);
		} else {
			this.personalInfoModalForm.value.phone = this.personalInfoModalForm.form.controls['phone'].value !== undefined && this.personalInfoModalForm.form.controls['phone'].value ? this.personalInfoModalForm.form.controls['phone'].value : this.user_info.phone;
			this.personalInfoModalForm.value['phoneCountryCode'] = this.user_info.phoneCountryCode;
			this.dialogRef.close(this.personalInfoModalForm.value);

      // Track: Sign Up
      this.rudderstackService.track('Sign Up', {
        "fname": this.personalInfoModalForm.form.controls['fname'].value,
        "lname": this.personalInfoModalForm.form.controls['lname'].value,
        "gender": this.personalInfoModalForm.form.controls['gender'].value,
        "phone": this.personalInfoModalForm.form.controls['phone'].value,
      })
    }
	}

	closePersonalInfoModal() {
		// console.log('user_info', this.user_info);
		if (this.personalInfoModalForm.invalid) {
			this.markFormGroupTouched(this.personalInfoModalForm.form);
			this.modalService.alertsModal('errors', 18, 2000, false);
		} else  {
			this.dialogRef.close();
		}
	}

	private markFormGroupTouched(formGroup: UntypedFormGroup) {
		(<any>Object).values(formGroup.controls).forEach(control => {
		  control.markAsTouched();
		  // control.markAsPristine();
		  control.updateValueAndValidity();
		  if (control.controls) {
			this.markFormGroupTouched(control);
		  }
		});
	  }

  ngOnDestroy() {
    this.verifySubscription.unsubscribe();
  }

}
