import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'changeSalesmanPipe'
})
export class ChangeSalesmanPipe implements PipeTransform {

  search_params = [
		'phone',
		'email',
		'seller_name',
		'code'
	];
    
	transform(value, args) {
		if(!args) {
			return value;
		} else if(value) {
			// make the filter case insensitive:
			args = (typeof args == 'string' || args instanceof String) ? args.toLowerCase() : String(args);
			return value.filter(item => {
				for (let key in item) {
					if(this.search_params.find(x => x == key)) {
							if(((typeof item[key] == 'string' || item[key] instanceof String) && (item[key].toLowerCase().indexOf(args) !== -1)) || (typeof item[key] == 'number' && (String(item[key]).indexOf(args) !== -1))) {
								return true;
							}
						}
					}
			});
		}
	}

}
