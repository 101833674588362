import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Country } from '@angular-material-extensions/select-country';
import { COUNTRIES_DB } from '@angular-material-extensions/select-country';
import { BankService } from '../../../services/bank.service';
import { GlobalsService } from '../../../globals';
import { DialogService } from '../../../services/dialog.service';
import { StripePayoutService } from '../../../services/stripe-payout.service';
import { IntercomService } from '../../../services/intercom.service';
import { PayoutModalComponent } from '../payout-modal/payout-modal.component';
import {map, startWith} from "rxjs/operators";
import {Observable} from "rxjs";
import us_cities from "../../../static/US-cities";
import {PlaidService} from "../../../services/plaid.service";
import {EventService} from "../../../services/event.service";

type ApiStripeResponse = {
  code: 'success' | number;
  data: {
    url: string;
  };
};

@Component({
  selector: 'app-bank-info-modal',
  templateUrl: './bank-info-modal.component.html',
  styleUrls: ['./bank-info-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BankInfoModalComponent implements OnInit {
  bank_info: UntypedFormGroup;
  // type = new FormControl('business');
  country = new UntypedFormControl('', Validators.required);
  bank_id = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern('^[0-9]*$'),
  ]);
  bank_name = new UntypedFormControl('', Validators.required);
  city = new UntypedFormControl('', Validators.required);
  branch = new UntypedFormControl('', [
    Validators.pattern('^[0-9]*$'),
    Validators.required,
  ]);
  account_number = new UntypedFormControl('', [
    Validators.pattern('^[0-9]*$'),
    Validators.required,
  ]);
  aba_number = new UntypedFormControl('', [
    Validators.minLength(9),
    Validators.maxLength(9),
    Validators.pattern('^[0-9]*$'),
    Validators.required,
  ]);
  recipient_phone = new UntypedFormControl('', [
    Validators.pattern(
      '^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$'
    ),
    Validators.required,
  ]);
  account_name = new UntypedFormControl('', Validators.required);
  address = new UntypedFormControl('', Validators.required);
  company_user_id = new UntypedFormControl('', [
    Validators.pattern('^[0-9]*$'),
    Validators.min(1000000),
    Validators.max(9999999999),
    Validators.required,
  ]);
  other_bank_details = new UntypedFormControl('', [
    Validators.required,
    Validators.minLength(3),
  ]);
  user_plaid_id = new UntypedFormControl('');
  terms = new UntypedFormControl(false, this.data.bank ? Validators.nullValidator :Validators.required);
  bankCities: any[] = [];
  bankIds: any[] = [];
  defaultCountry = null;
  selectedCountry: Country = null;
  bankDetailsCollectionMethod: 'manual' | 'plaid' | 'stripe' = 'manual';
  stripeConnectDisabled: boolean = false;
  stripeConnectNotSupportedCountry: boolean = false;
  stripeConnectAvailableCountries: Set<string> = null;
  stripeConnectAccountLink: string = null;
  stripeConnectPending: boolean = false;
  stripeConnectCompleted: boolean = false;
  stripeTemporaryBlockedCountriesSet = new Set(['al']);
  stripeRequestCountries = new Set([]);

  filteredUSCities: Observable<any>;

  predefinedCountries: Country[] = [
    {
      name: 'United States of America',
      alpha2Code: 'US',
      alpha3Code: 'USA',
      numericCode: '840',
      callingCode: '+1'
    },
    {
      name: 'Israel',
      alpha2Code: 'IL',
      alpha3Code: 'ISR',
      numericCode: '376',
      callingCode: '+972'
    },
    {
      name: 'Canada',
      alpha2Code: 'CA',
      alpha3Code: 'CAN',
      numericCode: '124',
      callingCode: '+1'
    },
    {
      name: 'Other',
      alpha2Code: '',
      alpha3Code: '',
      numericCode: 'other',
      callingCode: 'other'
    },
    ...COUNTRIES_DB.filter(c => {
      return (c.alpha2Code !== 'US' && c.alpha2Code !== 'IL')
    }),

  ];

  predefinedStripeConnectAvailableCountries: Country[] = [];


  constructor(
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    public bankService: BankService,
    public globals: GlobalsService,
    private dialogService: DialogService,
    private stripePayoutService: StripePayoutService,
    private dialogRef: MatDialogRef<PayoutModalComponent>,
    private intercomService: IntercomService,
    private plaidService: PlaidService,
    private eventService: EventService,
    @Inject(MAT_DIALOG_DATA) public data,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    console.log('BankInfoModalComponent', this.data);
    this.stripeConnectDisabled = this.data.stripeConnectDisabled;console.log('stripeConnectDisabled', this.stripeConnectDisabled);
    this.stripeConnectPending = this.data.stripeConnectPending;console.log('stripeConnectPending', this.stripeConnectPending);
    this.stripeConnectCompleted = this.data.stripeConnectCompleted;console.log('stripeConnectCompleted', this.stripeConnectCompleted);

    // pending Stripe connect onboarding link
    if (this.data.stripeConnectOnboardingLink) {
      this.stripeConnectAccountLink = this.data.stripeConnectOnboardingLink;
    }
console.log('this.data.stripeConnectRequestCountriesListLink', this.data.stripeConnectRequestCountriesListLink)
    // Get stripe supported countries
    if (this.data.stripeConnectRequestCountriesListLink) {
      this.globals.startLoader();
      this.stripePayoutService
        .getCountriesList(this.data.stripeConnectRequestCountriesListLink)
        .subscribe(
          ({ data, code }: { code: 'success' | number; data: string[] }) => {
            if (code === 'success') {
              console.log('getCountriesList', data);
              this.stripeConnectAvailableCountries = new Set(
                data.map(c => c.toLowerCase())
              );
              this.predefinedStripeConnectAvailableCountries = COUNTRIES_DB.filter(((item:Country) => data.includes(item.alpha2Code)));
              this.predefinedStripeConnectAvailableCountries.push(    {
                name: 'Other',
                alpha2Code: '',
                alpha3Code: '',
                numericCode: 'other',
                callingCode: 'other'
              });
              console.log('predefinedStripeConnectAvailableCountries', this.predefinedStripeConnectAvailableCountries)
              this.globals.stopLoader();
            }
          }
        );
    }
    if(this.data && this.data.data && this.data.data.hasOwnProperty('countries')) {
      this.stripeRequestCountries = new Set<any>(this.data.data.countries)
    }
    this.createForm();
    // console.log('usCities', us_cities)
    this.filteredUSCities = this.bank_info.get('city').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

    if(this.data.bank && this.data.bank.country) {
      let alpha2Code =  this.data.bank.country == 'other' ? '' : this.data.bank.country.toUpperCase()
      this.defaultCountry = {
        alpha2Code: alpha2Code,
      }
      this.selectedCountry = {
        name: '',
        alpha2Code: alpha2Code,
        alpha3Code: '',
        numericCode: '',
        callingCode: '',
      }
    }

    if(( (this.stripeConnectPending || this.stripeConnectCompleted)
        || (this.data.data.hasOwnProperty('stripe_connect_request') && this.data.data.stripe_connect_request) )
      && this.data.data.record) {
      this.defaultCountry = {
        alpha2Code: this.data.data.record.country,
      }
      this.selectedCountry = {
        name: '',
        alpha2Code: this.data.data.record.country,
        alpha3Code: '',
        numericCode: '',
        callingCode: '',
      }
      console.log('SET', this.data.data);
      this.country.removeValidators([Validators.required]);
    }

    // Get stripe countries for Request creation check
    this.getStripeCountries();

    setTimeout(() => {
      Object.keys(this.bank_info.controls).forEach(key => {
        // console.log(this.bank_info.controls[key], key)
        if (this.data && this.data.bank) {
          if (this.data.bank.hasOwnProperty(key)) {
            this.bank_info.controls[key].patchValue(this.data.bank[key]);

            if(this.data.bank?.user_plaid_id) {
              this.bank_info.controls[key].disable();
              this.bank_info.controls[key].clearValidators();
            }
          }
        }
      });

      if (this.data) {
        // if (this.bank_info.controls['country'].value == 'es') {
        //   this.bankIds = this.bankService.spainBankList;
        //   this.bank_id.setValue(this.bank_info.controls['bank_id'].value);
        // }
        if (this.bank_info.controls['country'].value == 'il') {
          this.bankIds = this.bankService.israeliBankList;
          this.bank_id.setValue(this.bank_info.controls['bank_id'].value);
        }
      }
      this.detectCollectionMethodByCountry(this.country.value);
      this.countryChange(this.country.value, true); // to delete unused form controls
    }, 10);

    this.plaidService.clearPlaidTokenExchangeSubj();
    this.plaidService.onSuccessSubj.subscribe((res)=> {
      console.log('onSuccess',res);
    });
    this.plaidService.onExitSubj.subscribe((res)=> {
      console.log('onExit',res);
      if(!res.err && res.metadata) {
        if (res.metadata.status == "requires_credentials") {
          this.user_plaid_id.patchValue('');
          this.bank_info.get('user_plaid_id').patchValue('');
        }
      }
    });
    this.plaidService.plaidTokenExchangeSubj.subscribe((res)=> {
      console.log('plaidTokenExchange',res);
      if(res) {
        this.fillPlaidFields(res);
      }
    })
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return us_cities.filter(option => option.toLowerCase().includes(filterValue)).slice(0, 40);
  }

  detectCollectionMethodByCountry(country) {
    if (country == 'us' || country == 'ca') {
      this.bankDetailsCollectionMethod = 'plaid';
      // this.bankDetailsCollectionMethod = 'manual';
    } else if (country == 'il' || country == 'other') {
      this.bankDetailsCollectionMethod = 'manual';
    } else if (this.stripeRequestCountries.has(country.toUpperCase())
      || !this.stripeConnectDisabled
      // && this.stripeConnectAvailableCountries !== null
      // && this.stripeConnectAvailableCountries.has(country) &&
      // !this.stripeTemporaryBlockedCountriesSet.has(country)
    ) {
      this.bankDetailsCollectionMethod = 'stripe';
    } else {
      this.stripeConnectNotSupportedCountry = true;
      // this.bankDetailsCollectionMethod = 'manual';
    }
console.log(' this.bankDetailsCollectionMethod',  this.bankDetailsCollectionMethod)
    return this.bankDetailsCollectionMethod;
  }

  createForm() {
    this.bank_info = this.fb.group({
      // type: this.type,
      country: this.country,
      city: this.city,
      bank_id: this.bank_id,
      bank_name: this.bank_name,
      branch: this.branch,
      account_number: this.account_number,
      aba_number: this.aba_number,
      recipient_phone: this.recipient_phone,
      account_name: this.account_name,
      address: this.address,
      company_user_id: this.company_user_id,
      other_bank_details: this.other_bank_details,
      terms: this.terms,
      user_plaid_id: this.user_plaid_id
    });
  }

  // REMOVE UNUSED CONTROLS FOR SELECTED COUNTRY
  removeControls(names = []) {
    names.forEach(name => {
      this.bank_info.removeControl(name);
    });
  }
  removeControlsUS(names = []) {
    names.forEach(name => {
      this.bank_info.removeControl(name);
    });
  }

  // REINIT CONTROLS
  addControls(country = '') {
    switch (country) {
      case 'il':
        this.bank_info.addControl('bank_id', this.bank_id);
        this.bank_info.addControl('branch', this.branch);
        this.bank_info.addControl('account_number', this.account_number);
        this.bank_info.addControl('account_name', this.account_name);
        this.bank_info.addControl('company_user_id', this.company_user_id);
        break;
      case 'plaid':
        // this.bank_info.addControl('city', this.city);
        // this.bank_info.addControl('bank_name', this.bank_name);
        // this.bank_info.addControl('account_number', this.account_number);
        // this.bank_info.addControl('account_name', this.account_name);
        // this.bank_info.addControl('address', this.address);
        // this.bank_info.addControl('recipient_phone', this.recipient_phone);
        break;
      case 'other': // Other Bank Details
        this.bank_info.addControl('other_bank_details', this.other_bank_details);
        break;
      default:
        break;
    }

  }

  addControlsOther() {
    this.bank_info.addControl('other_bank_details', this.other_bank_details);
  }

  onCountrySelected($event: Country) {
    if ($event === null) {
      return;
    }

    // Check if country exist in Stripe List
    // if(this.stripeConnectAvailableCountries !== null) {
    //   console.log(this.stripeConnectAvailableCountries, $event)
    //   // console.log(this.stripeConnectAvailableCountries.has($event.alpha2Code.toLowerCase()),)
    //   if(  !this.stripeConnectAvailableCountries.has($event.alpha2Code.toLowerCase()) ) {
    //     this.defaultCountry = {
    //       alpha2Code: '',
    //     }
    //     alert(`${$event.name} is not available to select!`)
    //     return;
    //   }
    // }

    // user changed country, clear stripe account
    if(this.stripeConnectPending) {
      console.log('stripeConnectPending', this.stripeConnectPending)
      this.deleteStripeAccount(this.data.data.record.id, $event);
      return;
    }

    this.stripeConnectNotSupportedCountry = false;
    this.selectedCountry = $event;

    const selectedCountryCode = $event.alpha2Code ? $event.alpha2Code.toLowerCase() : 'other' ;
    this.bank_info.controls['country'].patchValue(selectedCountryCode);
    this.detectCollectionMethodByCountry(selectedCountryCode);
    this.countryChange(selectedCountryCode);
  }

  countryChange(countryCode, onInit = false) {
    if (!countryCode) return [];

    switch (this.bankDetailsCollectionMethod) {
      case 'manual':
        this.bankIds = [];
        if (countryCode == 'il') {
          this.bankIds = this.bankService.israeliBankList;
          this.removeControls([
            'address',
            'city',
            'recipient_phone',
            'bank_name',
            'aba_number',
            'other_bank_details',
          ]);
        }
        // if (countryCode == 'es') {
        //   this.bankIds = this.bankService.spainBankList;
        //   this.removeControls([
        //     'address',
        //     'city',
        //     'recipient_phone',
        //     'bank_name',
        //     'aba_number',
        //     'other_bank_details',
        //   ]);
        // }
        if (countryCode == 'us') {
          this.removeControls([
            'bank_id',
            'branch',
            'address',
            'city',
            'recipient_phone',
            'company_user_id',
            'bank_name',
            'aba_number',
            'other_bank_details',
          ]);
        }
        if (countryCode == 'ca') {
          this.removeControls([
            'bank_id',
            'branch',
            'address',
            'city',
            'recipient_phone',
            'company_user_id',
            'bank_name',
            'aba_number',
            'other_bank_details',
          ]);
        }
        if (countryCode == 'other') { // Other Bank Details
          this.removeControls([
            'bank_id',
            'bank_name',
            'branch',
            'account_number',
            'account_name',
            'address',
            'city',
            'recipient_phone',
            'company_user_id',
            'aba_number',
          ]);
        }
        if( !(countryCode == 'il' || countryCode =='other') ) {
          this.removeControls([
            'bank_id',
            'branch',
            'company_user_id',
            'address',
            'city',
            'recipient_phone',
            'bank_name',
            'aba_number',
            'account_number',
            'account_name',
            'other_bank_details',
          ]);
        }
        this.addControls(countryCode);
        break;
      case 'plaid':
        this.removeControls([
          'bank_id',
          'branch',
          'company_user_id',
          'address',
          'city',
          'recipient_phone',
          'bank_name',
          'aba_number',
          'account_number',
          'account_name',
          'other_bank_details',
        ]);
        this.addControls('plaid');
        console.log('Load Plaid');
        break;
      case 'stripe':
        this.removeControls([
          'bank_id',
          'branch',
          'company_user_id',
          'address',
          'city',
          'recipient_phone',
          'bank_name',
          'aba_number',
          'account_number',
          'account_name',
          'other_bank_details',
        ]);
        console.log('Load Stripe');
        break;
      // case 'other':
      //   this.removeControls(['bank_id', 'branch', 'company_user_id', 'address', 'city', 'recipient_phone', 'bank_name', 'aba_number', 'account_number', 'account_name']);
      //   this.addControlsOther();
      //   console.log("Load Other open details");
      //   break;
      default:
        break;
    }

    if (!onInit) {
      // Reset field if country changed
      this.bank_id.reset();
      this.bank_name.reset();
    }
  }

  // displayFn(city: any) {
  //   return city && city.name ? city.name : '';
  // }
  citySelected(city) {
    this.bank_info.get('city').patchValue(city)
  }

  onStripeConnectClick() {
    console.log('onStripeConnectClick');
    // Stripe connect enabled - User already started onboarding
    if (this.stripeConnectPending) {
      this.document.location.href = this.stripeConnectAccountLink;
      return;
    }

    if (this.stripeConnectCompleted) {
      // TODO: redirect to Stripe dashboard
      console.log('onStripeConnectClick:', 'redirect to Stripe dashboard');
      this.document.location.href = 'https://dashboard.stripe.com/';
      return;
    }

    if(!this.selectedCountry || !this.selectedCountry.alpha2Code) {
      console.log('onStripeConnectClick:', 'No selectedCountry');
      this.dialogService.alertsModal('finance', 2, 3000, false);
      return;
    }

    // Stripe  connect enabled - User requesting to onboard for the first time
    if (!this.stripeConnectAccountLink) {
      this.globals.startLoader();
      this.stripePayoutService
        .createAccount(this.selectedCountry.alpha2Code)
        .subscribe(
          ({ data, code }: ApiStripeResponse) => {
            if (code === 'success') {
              console.log('onStripeConnectClick', data);
              this.stripeConnectAccountLink = data.url;
              this.globals.stopLoader();
              this.document.location.href = data.url;
            } else {
              console.log('onStripeConnectClick: error', code);
              this.dialogService.alertsModal('errors', code, 3000, false);
              this.globals.stopLoader();
            }
          },
          error => {
            console.log('onStripeConnectClick: error', error);
            this.dialogService.alertsModal('errors', error.code, 3000, false);
            this.globals.stopLoader();
          }
        );
    }
  }

  getStripeCountries() {
    if(this.stripeConnectDisabled) {
      this.globals.startLoader();
      this.stripePayoutService.getStripeCountries().subscribe((res: any) => {

        if(res && res.data) {
          this.stripeRequestCountries = new Set(res.data);
          console.log(this.stripeRequestCountries);
          console.log(this.selectedCountry);
          if(this.selectedCountry) {
            this.detectCollectionMethodByCountry(this.selectedCountry.alpha2Code.toLowerCase())
          }
        }
        this.globals.stopLoader();
      })
    }
  }

  onContactSupportClick(type = null) {
    // if (this.selectedCountry !== null) {
    //   this.intercomService.message(
    //     $localize`I would like to set my payout bank account. When I select country "${this.selectedCountry.name}:CountryName:", it leads me to contact support.`
    //   );
    // }
    let message = `Hello, I would like to set my bank account.`;
    console.log(this.selectedCountry, type)
    if (type == 'no_country') {
      message = `I would like to set my payout bank account. When I select country "${this.selectedCountry.name ? this.selectedCountry.name : this.selectedCountry.alpha2Code}", it leads me to contact support.`
    }
    this.intercomService.message(
    $localize`${message}`
    );
  }

  deleteStripeAccount(id, new_country_selected: Country) {
    console.log('deleteStripeAccount', id, new_country_selected.alpha2Code);
    console.log('deleteStripeAccount', this.stripeRequestCountries.has(new_country_selected.alpha2Code));
    if (!id) return
    if (!this.stripeRequestCountries.has(new_country_selected.alpha2Code)) {
      this.dialogService.alertsModal('finance', 1, 4000, false);
      return
    }
    this.globals.startLoader();
    this.stripePayoutService.deleteAccount(id).subscribe((res: any) => {
      if(res && res.code == 'success') {
        this.dialogRef.close({
          ...res.data,
          record: {country:new_country_selected.alpha2Code}
        });
        this.stripeConnectAccountLink = null;
      }

      this.globals.stopLoader();
    }, error => {
      this.globals.stopLoader();
    });

  }

  async connectPlaid() {
    this.globals.startLoader();
    this.eventService.plaidTokensLink(this.selectedCountry.alpha2Code).subscribe(async (res: any) => {
      console.log(res);
      this.globals.stopLoader();
      if(res.data && res.data.token) {
        localStorage.setItem('link_token', JSON.stringify(res.data.token));
        if(res.data.user_plaid_id) {
          localStorage.setItem('user_plaid_id', JSON.stringify(res.data.user_plaid_id));
          this.bank_info.get('user_plaid_id').patchValue(res.data.user_plaid_id);
        }

        const handler = await this.plaidService.create(res.data.token, null,false);
        console.log(handler);
        handler.open();
      }

    })
  }
  disconnectPlaid() {
    const user_plaid_id = this.bank_info.get('user_plaid_id').value;

    if(user_plaid_id) {
      this.globals.startLoader();
      this.eventService.plaidDelete(user_plaid_id).subscribe((res:any)=> {
        if(res.code === 'success') {
          console.log(res.code);
          this.bank_info.get('user_plaid_id').patchValue('');
          localStorage.removeItem('user_plaid_id');
          this.bank_info.reset();
          this.bank_info.enable();
          this.plaidService.clearPlaidTokenExchangeSubj();
          this.dialogRef.close(null);
          this.dialogService.alertsModal('success', 'success', 3000, true);
          // Update form
          // let selectedCountryCode = this.selectedCountry.alpha2Code.toLowerCase();
          // this.bank_info.get('country').patchValue(selectedCountryCode)
          // this.detectCollectionMethodByCountry(selectedCountryCode);
          // this.countryChange(selectedCountryCode, true);
          // this.bank_info.get('country').addValidators([Validators.required]);
          // this.bank_info.get('city').addValidators([Validators.required]);
          // this.bank_info.get('bank_name').addValidators([Validators.required]);
          // this.bank_info.get('account_number').addValidators([Validators.pattern('^[0-9]*$'),Validators.required]);
          // this.bank_info.get('recipient_phone').addValidators([
          //   Validators.pattern('^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$'),Validators.required]);
          // this.bank_info.get('account_name').addValidators([Validators.required]);
          // Object.keys(this.bank_info.controls).forEach(key => {
          //   this.bank_info.controls[key].updateValueAndValidity();
          // });
          // Update form END



          // this.detectCollectionMethodByCountry(this.selectedCountry.alpha2Code.toLowerCase());
          // this.countryChange(this.selectedCountry.alpha2Code.toLowerCase(), true); // to delete unused form controls
          // this.getInitialBankData().then(formData => {
          //   this.initBankForm(formData)
          // })
        }
        this.globals.stopLoader();
      })
    }
  }
  fillPlaidFields(bank) {
    if(!bank || !this.bank_info) {return}
    Object.keys(this.bank_info.controls).forEach(key => {
      console.log(this.bank_info.controls[key], key)
      if (bank) {
        if (bank.hasOwnProperty(key)) {
          this.bank_info.controls[key].patchValue(bank[key]);
          this.bank_info.controls[key].clearValidators()
          this.bank_info.controls[key].updateValueAndValidity()
        }
      }
    });

    this.bank_info.disable();
    this.dialogService.alertsModal('success', 'success', 3000, true);
    this.dialogRef.close();
  }

  // TODO = DELETE THIS TEST FUNCTION
  TESTsetPlaidTokenExchangeSubj() {
    console.log(this.bank_info)

    let testDATA = {
      "id": 1212,
      "user_id": 269,
      "event_id": null,
      "country": "ca",
      "city": "Gingham",
      "bank_name": "RBC Royal Bank",
      "aba_number": null,
      "bank_id": 0,
      "branch": "",
      "account_number": "**** ",
      "account_name": "",
      "recipient_phone": "1112223333",
      "phone_country_code": "",
      "company_user_id": 0,
      "producer_email": "accountholder0@example.com",
      "producer_phone": "1112223333",
      "producer_name": "Alberta Bobbeth Charleson",
      "address": "AB, 478 Plaid Street, T0B 0Z0",
      "agreement_date": "2024-07-08 11:13:36",
      "agreement": 1,
      "created_at": "2024-07-08 11:13:36",
      "updated_at": "2024-07-08 11:13:36",
      "bank_type": 1,
      "other_bank_details": null,
      "user_plaid_id": 80,
      "stripe_connect_account_id": null
    }
    this.plaidService.setPlaidTokenExchangeSubj(testDATA);

    // Init Disconnect Plaid
    setTimeout(()=> {
      console.log('this.selectedCountry.alpha2Code.toLowerCase()', this.selectedCountry.alpha2Code.toLowerCase())
      this.bank_info.get('user_plaid_id').patchValue('');
      localStorage.removeItem('user_plaid_id');
      this.bank_info.reset();
      this.bank_info.enable();
      let selected_CC = this.selectedCountry.alpha2Code.toLowerCase();
      this.bank_info.get('country').patchValue(selected_CC)
      this.plaidService.clearPlaidTokenExchangeSubj();
      this.detectCollectionMethodByCountry(selected_CC);
      this.countryChange(selected_CC, true);
      this.bank_info.get('country').addValidators([Validators.required]);
      this.bank_info.get('city').addValidators([Validators.required]);
      this.bank_info.get('bank_name').addValidators([Validators.required]);
      this.bank_info.get('account_number').addValidators([Validators.pattern('^[0-9]*$'),Validators.required]);
      this.bank_info.get('recipient_phone').addValidators([
        Validators.pattern('^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$'),Validators.required]);
      this.bank_info.get('account_name').addValidators([Validators.required]);
      Object.keys(this.bank_info.controls).forEach(key => {
        this.bank_info.controls[key].updateValueAndValidity();
      });
      this.dialogRef.close(null);
    }, 5000)
  }

  isValid() {
    // return this.bank_info.valid && this.terms.valid;
    return this.bank_info.valid;
  }
  close() {
    this.dialogRef.close()
  }

  stripeCreateRequest(country) {
    this.close(); // close dialog
    this.globals.startLoader();
    this.stripePayoutService.createRequest(country).subscribe((res: any) => {
      console.log(res);
      if(res && res.data && res.data.url) {
        console.log('INIT Connect');
        window.location.href = res.data.url;
      }
      this.globals.stopLoader();
    })
  }

  onSubmit(form: UntypedFormGroup) {
    const country = this.bank_info.get('country').value.toUpperCase();
    // Create user Stripe account for CA and USA
    // console.log(country, form, this.data.bank);
    console.log(country, this.stripeRequestCountries.has(country))
    // return;
    if(this.stripeRequestCountries.has(country) && !this.data?.data.stripe_connect_request) {
      this.stripeCreateRequest(country)
      return;
    }

    this.bank_info.markAllAsTouched();
    if (this.bank_info.invalid) return;
    this.globals.startLoader();
    if (this.data.bank) {
      this.bankService.editBank(this.data.bank.id, form).subscribe(
        (res: any) => {
          console.log('editBank', res);
          this.globals.stopLoader();
          if (res && res.data) {
            this.dialogRef.close(res.data);
          }
        },
        error => {
          this.dialogService.alertsModal('errors', 4, 3000, false);
          this.globals.stopLoader();
        }
      );
    } else {
      this.bankService.createBank(form).subscribe(
        (res: any) => {
          console.log('createBank', res);
          this.globals.stopLoader();
          if (res && res.data) {
            this.dialogRef.close(res.data);
          }
        },
        error => {
          this.dialogService.alertsModal('errors', 4, 3000, false);
          this.globals.stopLoader();
        }
      );
    }
  }
}
