import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SvixService {
  api_base_url = environment.api_base_url + '/api/user-svix/';
  api_base_producer_url = environment.api_base_url + '/api/producer-svix/';
  constructor(private http: HttpClient) { }


  getSvixApp(user_id) {
    return this.http.get(this.api_base_url + `${user_id}/applications`, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }),
    });
  }
  getSvixAppProducer(user_id) {
    return this.http.get(this.api_base_producer_url + `${user_id}/applications`, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }),
    });
  }

  setSvixApp(user_id, data) {
    return this.http.post(this.api_base_url + `${user_id}/applications`, data,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      });
  }
  updateSvixApp(id, user_id, data) {
    return this.http.patch(this.api_base_url +`${user_id}/applications/${id}`, data,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      });
  }
  deleteSvixApp(id, user_id) {
    return this.http.delete(this.api_base_url + `${user_id}/applications/${id}`,
      {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})
      });
  }

  // Show URL button
  getUrl(user_id, id) {
    return this.http.get(this.api_base_url + `${user_id}/applications/${id}/app-portal-access`, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }),
    });
  }
  getUrlProducer(user_id, id) {
    return this.http.get(this.api_base_producer_url + `${user_id}/applications/${id}/app-portal-access`, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }),
    });
  }

  // For Select dropdown values
  getSvixSelect(user_id) {
    return this.http.get(this.api_base_url + `${user_id}/credentials`, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }),
    });
  }

}
