import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { slideIn, fade} from '../../animation';
import { DialogService } from '../../services/dialog.service';
import { CropperComponent } from 'angular-cropperjs';
import { DomSanitizer } from '@angular/platform-browser';
import { imageRegex } from '../../regex';

@Component({
	selector: 'app-crop-image-modal',
	templateUrl: './crop-image-modal.component.html',
	styleUrls: ['./crop-image-modal.component.css'],
	animations: [slideIn, fade]
})
export class CropImageModalComponent implements OnInit {
    cropperConfig = {
        movable: true,
        scalable: true,
        zoomable: true,
        viewMode: 0,
        aspectRatio: 1
        // responsive: false,
      }

    @ViewChild('profileImageCropper') public profileImageCropper: CropperComponent;
    @ViewChild('imageFileInput') public imageFileInput: ElementRef;
    imageCropperComponent;
    imageCropperConfig = {...this.cropperConfig};
    imageUrl;
    imageFile: File;
    ratio: string = '1:1';
    fileMaxSize: number = 5;

    constructor(
        private sanitizer:DomSanitizer,
        public dialogRef: MatDialogRef<any>,
        private dialogService: DialogService,
        @Inject(MAT_DIALOG_DATA) public data) {}

    ngOnInit() {
        if (this.data.configRatio) {
            this.cropperConfig.aspectRatio = this.data.configRatio;
            // this.cropperConfig['responsive'] = true;
            this.imageCropperConfig = {...this.cropperConfig};
        }
        if (this.data.ratio) this.ratio = this.data.ratio;
        if (this.data.fileMaxSize) this.fileMaxSize = this.data.fileMaxSize;
        if (this.data.element.target.value) this.setCropper(this.data.element, 'image');
    }

    cropperDestroy(cropperComponent, imageType) {
        if (cropperComponent) {
            this[`${imageType}Url`] = undefined, this[`${imageType}CropperComponent`] = false;
            this.dialogRef.close({data: null, croppedFile: null, success: false});
        }
    }

    saveCroppedImage(cropperComponent) {
        if (cropperComponent) {
                cropperComponent.cropper.getCroppedCanvas().toBlob((blob: Blob) => {
                    const croppedFile = new File([blob], this.imageFile.name, { type: blob.type, lastModified: new Date().getTime() });
                    this.dialogRef.close({data: blob, croppedFile, success: true});
                },
                    'image/jpeg', // this.imageFile.type,
            0.9);
        }
    }

    setCropper(input,type: string) {
        // console.log('setCropper', input.target.files[0].type, input.srcElement);
        if ((input.target.files[0].type === 'image/svg+xml') || (input.srcElement == undefined)) {
          this.dialogRef.close()
          return
        }
        let imgError = {action: true, error: 0};
        let file = input.srcElement.files[0];
        if(file){
            this.imageFile = file;
        if(file.size / 1000000 > this.fileMaxSize){
            imgError = {action: false, error: 3}
            return imgError;
        }
        if(file.length === 0){
            imgError = {action: false, error: 9}
            return imgError;
        }
        if(!imageRegex.test(file.type)){
            imgError = {action: false, error: 2.1}
            return imgError;
        }
        if (imgError.action) {
            this[`${type}CropperComponent`] = false;
            setTimeout(() => {
                this[`${type}Url`] = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
                this[`${type}CropperComponent`] = true;
                this[`${type}FileInput`].nativeElement.value = '';
            },1);
        } else {
            this.dialogService.alertsModal('fileUploads', imgError.error, 5000, false);
        }

        }
    }

    cropperSetDragMode(cropperComponent: any, mode: string) {
        if(cropperComponent){
          cropperComponent.cropper.setDragMode(mode);
        }
    }

    cropperTouchStart(event){
        event.stopPropagation();
        event.preventDefault();
    }

    readyCrop(event) {
        if (event.srcElement) {
            const previewAspectRatio = event.srcElement.width / event.srcElement.height;
        }
    }
}
