import { AuthCacheService } from './../../services-cache/auth-cache.service';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { slideIn, fade } from './../../animation';
import { NavbarService } from 'src/app/services/navbar.service';
import { DialogService } from 'src/app/services/dialog.service';
import { GlobalsService } from 'src/app/globals';
import { Subscription } from 'rxjs';
import { WalletConnectService } from 'src/app/services/wallet-connect.service';
import { emailRegex } from 'src/app/regex';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { LocalizationService } from 'src/app/services/localization.service';
import { Component, Input, OnInit, EventEmitter, Output, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import {IntercomService} from '../../services/intercom.service';
declare var Mailcheck;

import {RecaptchaEnterpriseService} from '../../services/recaptcha-enterprise.service';
import { phoneWCountryCodeRegex } from '../../regex';


@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss'],
  animations: [slideIn, fade]
})
export class UserLoginComponent implements OnInit, OnDestroy {

  pressEnter = false;
  captchaToken: string = '';
  captchaV3Token: string = '';
  isWalletConnect = false;
  walletIsChecked = false;
  isBtnClicked: any = {}; //{phone: boolean, email: boolean, wallet: boolean, p_key: boolean};
  formSubmited = false;
  showWalletEmailSection = false;
  ipInfo: any;
  isDisabledSlideToggle = false;
  showWallets = false;
  isShowTimer = false;
  otpTimer = 0;
  sendSmsTimer: number = 0;
  testOnlyOtpToken = '';

  disabbleBtn = {isDisable: false, otp: null};
  isTimeUp = false;
  isSendAgain = false;
  currentRegType: string = 'phone';
  isWalletRegistration = false;
  colorSlideToggle: '#FF7E7F';
  isCheckedSlideToggle = false;
  userData;
  currentWallet = '';
  walletLoginSubscription: Subscription;
  walletSignUpSubscription: Subscription;
  counter = 0;
  interval;
  captchaReset: boolean = false;
  captchaV3TokenSubscription: Subscription;
  loginType: string = 'V2';
  csrf_token: string = '';
  clicked : boolean = false;
  @ViewChild('recaptcha_userlogin') public recaptcha_userlogin: ElementRef;

  @Input() event_id: number = 0;
  @Input() isShowOtp = false;
  @Input() showSeatsIo = false;
  @Output() updateAuthUser: EventEmitter<any> = new EventEmitter();
  @Output() switchStep: EventEmitter<any> = new EventEmitter();
  @Output() setUserData: EventEmitter<any> = new EventEmitter();
  @Output() showTerms: EventEmitter<boolean> = new EventEmitter();
  @Output() showPrivacy: EventEmitter<boolean> = new EventEmitter();
  @Output() sendSmsTimerEmit = new EventEmitter<number>();

  logregForm: UntypedFormGroup = new UntypedFormGroup({
    // email: new FormControl('',{updateOn: 'change', validators: [Validators.pattern(emailRegex), Validators.required]}),
    phone: new UntypedFormControl('',{updateOn: 'change', validators: [ Validators.required, Validators.pattern(phoneWCountryCodeRegex)]}),
    // password: new FormControl('',{updateOn: 'change', validators: [ Validators.required]}),
    phone_email: new UntypedFormControl(''),
    phoneCountryCode:  new UntypedFormControl('')
  });

  suggestion: {}|null = null;

  showPasswordInput: boolean = false;
  isSendAgainAttempts: number = 0; // max 3 Attempts

  constructor(
    public localizationService: LocalizationService,
    private walletConnectService: WalletConnectService,
    public globals: GlobalsService,
    private dialogService: DialogService,
    public nav: NavbarService,
    public authService: AuthService,
    private router: Router,
    private authCacheService: AuthCacheService,
    private intercomService: IntercomService,
    // private elementRef: ElementRef
    private recaptchaService: RecaptchaEnterpriseService,
    ) {
      this.isBtnClicked['phone'] = true;
      // console.log('isBtnClicked', this.isBtnClicked);
      const IPdata = this.localizationService.getIpInfo();
      if (!IPdata) {
        this.localizationService.ourLatLng.subscribe(info => {
          // console.log('localizationService: ', info);
          this.ipInfo = info;
        });
      } else {
        this.ipInfo = IPdata;
      }


  }

  walletSignUpUnsubscribe() {
    if (this.walletSignUpSubscription) { this.walletSignUpSubscription.unsubscribe();}
  }
  walletLoginUnsubscribe() {
    if (this.walletLoginSubscription) { this.walletLoginSubscription.unsubscribe();}
  }

  resetPassword() {
    this.router.navigate(['/reset-password']);
  }

  logRegBtnClick(value: string) {
    this.currentRegType = value;
    // console.log('logRegBtnClick', value, this.logregForm.get('phone'), this.logregForm.get('email'));
    this.isBtnClicked = {};
    this.isBtnClicked[value] = true;
    // this.isShowOtp = false;
    if (value == 'email') {
      this.isWalletConnect = false;
      this.walletLoginUnsubscribe();
      // this.walletSignUpUnsubscribe();
      this.logregForm = new UntypedFormGroup({
        email: new UntypedFormControl('',{updateOn: 'change', validators: [Validators.pattern(emailRegex), Validators.required]}),
        password: new UntypedFormControl('',{updateOn: 'change'}),
        phone_email: new UntypedFormControl('')
      });
    } else if (value == 'phone') {
      this.isWalletConnect = false;
      this.walletLoginUnsubscribe();
      // this.walletSignUpUnsubscribe();
      this.logregForm = new UntypedFormGroup({
        phone: new UntypedFormControl('',{updateOn: 'change', validators: [ Validators.required]}),
        phoneCountryCode: new UntypedFormControl(''),
        phone_email: new UntypedFormControl('')
      });
      this.suggestion = null;
    } else if (value == 'wallet') {
      this.isWalletConnect = true;
      this.logRegWithWallet();
      console.log('logRegWithWallet');
      this.createWalletRegForm(); // creating reg form
    } else {
      console.log('Something went wrong!');
    }
  }
  setTimeCounter(event) {
    this.isTimeUp = event == 0;
    this.otpTimer = event;
    this.isTimeUp ? this.globals.stopLoader() : false;
    // console.log('setTimeCounter', event, this.isTimeUp, this.disabbleBtn);
  }

  sendUserData(event) {
    this.isTimeUp = false;
    this.globals.startLoader();
    this.isWalletConnect ? this.logregWalletContinue(event) : this.logregCheckContinue(event); // check with wallet or not
    // console.log('sendUserData', event, this.isTimeUp);
  }

  logregFormContinue(isAgain?) {
    // console.log('logregForm is valid ', this.logregForm.valid);
    this.isSendAgainAttempts = 0;
      if (this.pressEnter) {
        const hiddenBtn =  <HTMLButtonElement>document.getElementsByClassName('continue-btn')[0];
        hiddenBtn.focus();
        this.pressEnter = false;
      }
      if(this.logregForm.valid) {
        // this.dialogService.alertsModal('errors', 37, 3000, false);
        this.logregCheckContinue(null, isAgain);

      } else {
        // console.log('markAllAsTouched');
        this.logregForm.markAllAsTouched();
        // this.registerWalletForm.updateValueAndValidity();
        this.dialogService.alertsModal('errors', 18, 2000, false);
      }

  }

  logregCheckContinue(otp?, isAgain?) {
    if (this.loginType == 'V3' && !this.captchaV3Token && this.csrf_token) { // V3 badge is not checked
      this.dialogService.alertsModal('sendProducerEmail', 1, 2000, false);
      return;
    }

    // LOGIN V3 - additional capthca badge
    if (this.loginType == 'V3') {
      this.checkUser(this.captchaV3Token, 'V3', otp, isAgain);
    }

    // LOGIN V2  - Default
    if (this.loginType == 'V2') {
      this.recaptchaService.execute('LOGIN').then(token => {
        this.checkUser(token, 'V2', otp, isAgain);
      });
    }
  }

logregWalletContinue(otp_code='') {
    // console.log('Continue wallet Registration', this.logregForm, this.logregForm.get('phoneCountryCode').value);
    if(this.logregForm.valid) {
      // let phone_country_code = this.logregForm.get('phoneCountryCode').value;
      let phone_country_code = this.logregForm.get('phoneCountryCode') && this.logregForm.get('phoneCountryCode').value;
      !phone_country_code ? phone_country_code = this.ipInfo.country_code : phone_country_code = '';

      !phone_country_code ? phone_country_code = this.ipInfo.country_code : false;
      // console.log('registerWalletForm', this.logregForm.valid);
      // this.clicked = true;
      const email = this.logregForm.get('email') ? this.logregForm.get('email').value : '';
      const phone = this.logregForm.get('phone') ? this.logregForm.get('phone').value : '';
      // const userData = {first_name: this.registerWalletForm.get('first_name').value, last_name: this.registerWalletForm.get('last_name').value, email: this.registerWalletForm.get('email').value, phone: this.registerWalletForm.get('phone').value};
      const userData = {email, phone, phone_country_code, otp_code, captchaToken: this.captchaToken};
      // console.log('send userWalletReg data', userData);
      this.walletConnectService.waletAuthorization(null, false, userData);
      this.walletSignUpSubscription = this.walletConnectService.getWalletConnectUser.subscribe((data: any) => {
        // console.log('walletSignUp response: ', data);
        // this.walletSignUpUnsubscribe();
        this.globals.stopLoader();
        if (data && data.error) {
          // console.log('signUpWallet: ', data);
          if (data.errors == 5) {
            // console.log('signInWallet User log-in error: ', data);
            this.walletSignUpUnsubscribe();
            // this.zIndex.attr("style", "z-index:1000");
            this.dialogService.alertsModal('cryptoCurrency', 'walletConnectModalClosed', 3000, false);
            this.showWallets = true;
          }
          if (data.errors == 6) {
            // console.log('signUpWallet User registration error: ', data);
            // this.zIndex.attr("style", "z-index:1000");
            this.dialogService.alertsModal('cryptoCurrency', 'walletConnectReject', 3000, false);
          }
          if (data.errors == 7) {
            console.log('signUpWallet User registration error: ', data);
            // this.zIndex.attr("style", "z-index:1000");
            this.dialogService.alertsModal('cryptoCurrency', 'walletConnectAuthorization', 3000, false);
          }
          if (data.error.code == 4001) {
            this.dialogService.alertsModal('cryptoCurrency', 'walletConnect', 3000, false);
            console.log('signUpWallet response error: ', data.error.code);
          }
          if (data.error.code == 4.1) {
            this.dialogService.alertsModal('cryptoCurrency', 'walletConnectError', 3000, false);
            console.log('signUpWallet response error: ', data.error.code);
          }
          if (data.error.code == 101) {
            this.dialogService.alertsModal('cryptoCurrency', 'walletConnectPhoneInvalid', 3000, false);
            console.log('signUpWallet phone error: ', data.error.code);
          }

        } else if (data && !data.error && data.connected && data.response) { // Selected wallet was registered
          if (data.response.data && data.response.data.auth) {
            setTimeout(()=>{
              // this.zIndex.attr("style", "display: block;");
              this.dialogService.alertsModal('success', 'readerLogin' , 2000, true);
            }, 500)
            this.authCacheService.clearCache();
            setTimeout(()=>{
              // console.log('signUpWallet User registration start: ', data);
              this.updateAuthUser.emit(data);
              this.walletSignUpUnsubscribe();
              if (data.response.data.user_data && !data.response.data.user_data.personal_info) {
                this.userData = data.response.data.user_data;
                this.setUserData.emit(this.userData);

                this.switchStep.emit('UserRegistration');

              } else if (data.response.data.user_data && data.response.data.user_data.personal_info) {
                this.userData = data.response.data.user_data;
                this.setUserData.emit(this.userData);
                this.switchStep.emit(this.showSeatsIo ? 'SeatsTickets': 'Guest');
              } else {
                console.log('Something went wrong!');
              }
            }, 3000)
          // } else if (data.check) {
          //   console.log('wallet address was checked');
          } else {
            // this.dialogService.alertsModal('errors', tokenData.original.error, 5000, false);
            this.nav.checkUser();
          }

        } else if (data && !data.error && !data.connected && data.response) { // Selected wallet not registered
          if (data.errors == 4) { // the wallet can be registered
            // this.zIndex.attr("style", "display: block;");
            this.dialogService.alertsModal('cryptoCurrency', 'walletConnectReg', 3000, false);
            // console.log('currentWallet', this.currentWallet);
            this.walletIsChecked = true;
            this.walletSignUpUnsubscribe();
          } else if (data.errors == 2) { // Connected wallet already used
            // console.log('signUpWallet walletConnectUnique: ', data.response);
            this.dialogService.alertsModal('cryptoCurrency', 'walletConnectUnique', 3000, false);
          } else if (data.errors == 42) {
            this.dialogService.alertsModal('errors', 42, 3000, false);
            this.captchaToken = '';
          } else if (data.errors == 3) {
            this.captchaToken = '';
            // console.log('signUpWallet email is used: ', data.response);
            this.dialogService.alertsModal('cryptoCurrency', 'walletConnectEmail', 3000, false);
            this.walletSignUpUnsubscribe();
          } else if (data.errors == 3.1) {
            // console.log('signUpWallet emain is used: ', data.response);
            this.dialogService.alertsModal('cryptoCurrency', 'walletConnectPhone', 3000, false);
            this.walletSignUpUnsubscribe();
            this.isShowOtp = false;
          } else if (data.errors == 8) {
            // console.log('signUpWallet request OTP code: ', data.response);
            this.testOnlyOtpToken = data.response.data.code;
            this.isShowOtp = true;
            this.captchaToken = '';
            this.walletSignUpUnsubscribe();
          } else if (data.errors == 10 || (data.response.original && data.response.original.error == 37)) {
            // console.log('Requests sending too often: ', data.response);
            this.dialogService.alertsModal('errors', data.response.original.error, 3000, false);
            this.walletSignUpUnsubscribe();
          } else if (data.errors == 9 || (data.response && data.response.code == 35)) {
            // console.log('signUpWallet OTP code not corrected: ', data.response);
            this.dialogService.alertsModal('errors', data.response.code, 3000, false);
          } else if (data.response && (data.response.code == 43 || data.response.code == 44 || data.response.code == 45)) {
            this.dialogService.alertsModal('errors', data.response.code, 3000, false);
          }

        }
      });
    } else {
      this.logregForm.markAllAsTouched();
      this.dialogService.alertsModal('errors', 18, 2000, false);
    }
  }
  logRegWithWallet() {
    // console.log('logRegWithWallet ', this.logregForm);
    this.walletConnectService.setProviderOptions();
    this.walletConnectService.waletAuthorization();
    this.walletLoginSubscription = this.walletConnectService.getWalletConnectUser.subscribe((data: any) => {
      this.globals.stopLoader();
      // console.log('walletLogin response: ', data);
      if (data && data.error) {
        if (data.errors == 5) {
          // console.log('signInWallet User log-in error: ', data);
          this.walletLoginUnsubscribe();
          // this.zIndex.attr("style", "z-index:1000");
          this.dialogService.alertsModal('cryptoCurrency', 'walletConnectModalClosed', 3000, false);
          this.showWallets = true;
        }
        if (data.errors == 6) {
          // console.log('signUpWallet User registration error: ', data);
          // this.zIndex.attr("style", "z-index:1000");
          this.dialogService.alertsModal('cryptoCurrency', 'walletConnectReject', 3000, false);
        }
        if (data.errors == 7) {
          // console.log('signUpWallet User registration error: ', data);
          // this.zIndex.attr("style", "z-index:1000");
          this.dialogService.alertsModal('cryptoCurrency', 'walletConnectAuthorization', 3000, false);
        }
        if (data.error.code == 4001) {
          this.dialogService.alertsModal('cryptoCurrency', 'walletConnect', 3000, false);
          // console.log('signUpWallet response error: ', data.error.code);
        }
        if (data.error.code == 4.1) {
          this.dialogService.alertsModal('cryptoCurrency', 'walletConnectError', 3000, false);
          // console.log('signUpWallet response error: ', data.error.code);
        }
        if (data.error.code == 101) {
          this.dialogService.alertsModal('cryptoCurrency', 'walletConnectPhoneInvalid', 3000, false);
          // console.log('signUpWallet phone error: ', data.error.code);
        }
        if (data.errors == 11 || data.error.code == 40) {
          // console.log('signUpWallet User registration server error, need to login later. ', data);
          this.dialogService.alertsModal('errors', 40, 5000, false);
        }
      }  else if (data && !data.error && data.connected && data.response) { // Selected wallet was registered
        // console.log('walletLogin: ', data);
        if (data.response.data && data.response.data.auth) {
          setTimeout(()=>{
            // this.zIndex.attr("style", "display: block;");
            this.dialogService.alertsModal('success', 'letsGoHome' , 2000, true);
          }, 500);
          this.authCacheService.clearCache();
          setTimeout(()=>{
            // console.log('signUpWallet User registration start: ', data);
            this.updateAuthUser.emit(data);
            this.walletLoginUnsubscribe();
            if (data.response.data.user_data && !data.response.data.user_data.personal_info) {
              this.userData = data.response.data.user_data;
              this.setUserData.emit(this.userData);
              this.switchStep.emit('UserRegistration');
            } else if (data.response.data.user_data && data.response.data.user_data.personal_info) {
              this.userData = data.response.data.user_data;
              this.setUserData.emit(this.userData);
              this.switchStep.emit(this.showSeatsIo ? 'SeatsTickets': 'Guest');
            } else {
              console.log('Something went wrong!');
            }
          }, 3000)
        // } else if (data.check) {
        //   console.log('wallet address was checked');
        } else {
          // this.dialogService.alertsModal('errors', tokenData.original.error, 5000, false);
          this.nav.checkUser();
        }
      } else if (data && !data.error && !data.connected && data.response) { // Selected wallet not registered
        if (data.errors == 4) { // the wallet can be registered
          // this.zIndex.attr("style", "display: block;");
          this.dialogService.alertsModal('success', 'walletConnectReg', 3000, true);
          // console.log('currentWallet', this.currentWallet);
          this.walletIsChecked = true;
          this.walletLoginUnsubscribe();
        } else if (data.errors == 2) { // Connected wallet already used
          // console.log('signUpWallet walletConnectUnique: ', data.response);
          this.dialogService.alertsModal('cryptoCurrency', 'walletConnectUnique', 3000, false);
        } else if (data.errors == 42) {
          this.dialogService.alertsModal('errors', 42, 3000, false);
          this.captchaToken = '';
        } else if (data.errors == 3) {
          this.captchaToken = '';
          console.log('signUpWallet email is used: ', data.response);
          this.dialogService.alertsModal('cryptoCurrency', 'walletConnectEmail', 3000, false);
          this.walletLoginUnsubscribe();
        } else if (data.errors == 3.1) {
          this.captchaToken = '';
          console.log('signUpWallet phone is used: ', data.response);
          this.dialogService.alertsModal('cryptoCurrency', 'walletConnectPhone', 3000, false);
        } else if (data.errors == 8) {
          // console.log('signUpWallet request OTP code: ', data.response);
          this.testOnlyOtpToken = data.response.data.code;
          this.isShowOtp = true;
          this.captchaToken = '';
        } else if (data.errors == 10 || (data.response.original && data.response.original.error == 37)) {
          // console.log('Requests sending too often: ', data.response);
          this.dialogService.alertsModal('errors', data.response.original.error, 3000, false);
        } else if (data.errors == 9 || (data.response && data.response.code == 35)) {
          // console.log('signUpWallet OTP code not corrected: ', data.response);
          this.dialogService.alertsModal('errors', data.response.code, 3000, false);
        } else if (data.response && (data.response.code == 43 || data.response.code == 44 || data.response.code == 45)) {
          this.dialogService.alertsModal('errors', data.response.code, 3000, false);
        }
      }
    }, error =>  {
      // console.log('signUpWallet error: ', error);
      this.dialogService.alertsModal('errors', 4.1, 5000, false);
      this.globals.stopLoader();
    });
  }

  checkUser(token, type, otp_code?, isAgain?) {
    // console.log('Check OTP', this.currentRegType, this.disabbleBtn.otp, this.logregForm);
    if (this.logregForm.get('phone') && this.logregForm.get('phone').value) {
      this.logregForm.controls['phone_email'].patchValue(this.logregForm.get('phone').value);
      // console.log('Phone to Phone_Email value: ', this.logregForm.get('phone_email').value, 'phone', this.logregForm.get('phone').value);
    } else if (this.logregForm.get('email') && this.logregForm.get('email').value) {
      this.logregForm.controls['phone_email'].patchValue(this.logregForm.get('email').value);
      // console.log('Email to Phone_Email value: ', this.logregForm.get('phone_email').value, 'email', this.logregForm.get('email').value);
    }

    this.globals.startLoader();
    let cc = this.logregForm.get('phoneCountryCode') && this.logregForm.get('phoneCountryCode').value;
    !cc ? cc = this.ipInfo.country_code : false;
    if (otp_code) {
      // this.recaptchaService.execute('LOGIN').then(token => {
        this.authService.preSignUpSignIn('', this.logregForm.value.phone_email, this.logregForm.value.password, cc, this.csrf_token, otp_code, this.loginType)
          .subscribe(
            (response: any) => {
              this.globals.stopLoader();
              if(response.original.success && !response.original.personal_info) {
                console.log('tokenData no personal_info');
                if (response.auth.session && response.auth.refresh) {
                  this.authService.storeTokens(response.auth.session, response.auth.refresh)
                } else {
                  this.dialogService.alertsModal('errors', response.original.error, 3000, false);
                  return;
                };
                this.authService.saveUserCookies(response.user_data, response.original.facebook_id);
                this.authCacheService.clearCache();
                // this.nav.checkUser();

                if (response.original && response.original.uid) {
                  // this.nav.UpdateUser(parseInt(response.original.uid), response.user_data.type, response.original.facebook_id, false)
                }

                this.isShowOtp = false;
                this.userData = response.user_data;
                this.setUserData.emit(this.userData);
                this.switchStep.emit('UserRegistration');
                clearInterval(this.interval);this.sendSmsTimerEmit.emit(0);
                // console.log('openUserInfoForm', response);
                const isSuccess = response.original.success == 'letsGoHome';
                !isSuccess ? this.dialogService.alertsModal('success', response.original.success, 3000, true) : false;
                isSuccess ? this.dialogService.alertsModal('success', 'readerLogin', 3000, true) : false;

              } else if (response.user_data && response.user_data.personal_info) {
                if (response.auth.session && response.auth.refresh) {
                  this.authService.storeTokens(response.auth.session, response.auth.refresh)
                } else {
                  this.dialogService.alertsModal('errors', response.original.error, 3000, false);
                  return;
                };

                this.authService.saveUserCookies(response.user_data, response.original.facebook_id);
                this.authCacheService.clearCache();
                this.nav.checkUser();
                if (response.original && response.original.uid) {
                  this.nav.UpdateUser(parseInt(response.original.uid), response.user_data.type, response.original.facebook_id, false)
                };
                this.isShowOtp = false;
                this.userData = response.user_data;
                this.setUserData.emit(this.userData);
                this.switchStep.emit(this.showSeatsIo ? 'SeatsTickets': 'Guest');
                clearInterval(this.interval);this.sendSmsTimerEmit.emit(0);
                const isSuccess = response.original.success == 'letsGoHome';
                let active_events = 0;
                if(response.user_data && response.user_data.active_events) {
                  active_events = response.user_data.active_events;
                }
                this.intercomService.boot((active_events && window.innerWidth >= 768) ? false : true);

                !isSuccess ? this.dialogService.alertsModal('success', response.original.success, 3000, true) : false;
                isSuccess ? this.dialogService.alertsModal('success', 'readerLogin', 3000, true) : false;

              } else if(response.original.error){
                if (response.original.error == 35) {
                  this.isShowOtp = true;
                  this.isSendAgainAttempts = this.isSendAgainAttempts+1;
                } else {
                  this.isShowOtp = false;
                }
                this.captchaToken = '';
                this.dialogService.alertsModal('errors', response.original.error, 5000, false);
                if(this.loginType == 'V3') {
                  this.recaptchaService.reset().then(() => { // reset badge
                    this.recaptchaService.setToken('');
                  })
                }
              }
              this.globals.stopLoader();
            },
            error =>  {
              this.isShowOtp = false;
              this.dialogService.alertsModal('errors', 4.1, 5000, false);
              if(this.loginType == 'V3') {
                this.recaptchaService.reset().then(() => { // reset badge
                  this.recaptchaService.setToken('');
                })
              }
              this.globals.stopLoader();
            }
          );
      // });

    } else {
      console.log('start check user service', cc, this.ipInfo);

      // this.recaptchaService.execute('LOGIN').then(token => {
        this.captchaToken = token;
        this.authService.preSignUp('', this.logregForm.value.phone_email, this.logregForm.value.password, cc, this.csrf_token,token, type, this.event_id).subscribe((response: any) => {
          this.globals.stopLoader();
          // console.log('preSignUp', response);
          this.csrf_token = response && response.original.csrf_token ? response.original.csrf_token : this.csrf_token;
          if (response && (response.code == 'success' || (response.original.success && response.original.success !== 'letsGoHome'))) {
            isAgain ? this.dialogService.alertsModal('success', 'OtpSendAgain', 3000, true) : false;
            this.isShowOtp = true;
            this.testOnlyOtpToken = response.original.token;
            // console.log('Response success and open otp inputs');

            // 3 attempts to SEND OTP
            console.log('isSendAgain', this.isSendAgain)
            if(this.isSendAgain) {
              this.startOTPTimer(true);
            }

          } else if(response && (response.original.success === 'letsGoHome')) {
            console.log('letsGoHome user-login');
            if (response.auth.session && response.auth.refresh) {
              this.authService.storeTokens(response.auth.session, response.auth.refresh)
            } else {
              this.dialogService.alertsModal('errors', response.original.error, 3000, false);
              return;
            };
            this.authService.saveUserCookies(response.user_data, response.original.facebook_id);
            this.authCacheService.clearCache();
            this.nav.checkUser();
            if (response.original && response.original.uid) {
              this.nav.UpdateUser(parseInt(response.original.uid), response.user_data.type, response.original.facebook_id, false)
            };

            this.isShowOtp = false;
            this.userData = response.user_data;
            this.setUserData.emit(this.userData);
            this.switchStep.emit(this.showSeatsIo ? 'SeatsTickets': 'Guest');
            const isSuccess = response.original.success == 'letsGoHome';
            clearInterval(this.interval);this.sendSmsTimerEmit.emit(0);
            let active_events = 0;
            if(response.user_data && response.user_data.active_events) {
              active_events = response.user_data.active_events;
            }
            this.intercomService.boot((active_events && window.innerWidth >= 768) ? false : true);

            !isSuccess ? this.dialogService.alertsModal('success', response.original.success, 3000, true) : false;
            isSuccess ? this.dialogService.alertsModal('success', 'readerLogin', 3000, true) : false;

          } else if (response && (response.code != 'success' || response.original.error)) {
            // V2 and V3 captcha switch
            // ---------------------
            if (type == 'V2' && response.original.error !== 53) {
              this.csrf_token = '';
            }
            if (response.original.error === 53) {
              this.loginType = 'V3'; // if we receive 53 from V2 other requests should be from V3
              this.dialogService.alertsModal('errors', 53, 5000, false);
              this.recaptchaService.render('recaptcha_userlogin', 'LOGIN').then(() => {
                // this.clicked = true;
                if(this.recaptcha_userlogin){this.recaptcha_userlogin.nativeElement.style.visibility = "visible";}
              });

            } if (response.original.error === 54) {
              this.csrf_token = '';
              this.recaptchaService.reset().then(() => { // reset badge
                this.recaptchaService.setToken('');
                this.recaptcha_userlogin.nativeElement.style.visibility = "hidden";
              })
            } else if (response.original.error === 41) {
              this.dialogService.alertsModal('success', 'loginAttemptsBlocked1hour', 5000, true);
            } else {
              this.dialogService.alertsModal('errors', response.original.error, 5000, false);
              if (type == 'V3') {
                this.recaptchaService.reset().then(() => { // reset badge
                  this.recaptchaService.setToken('');
                })
              }
            }
            // V2 and V3 captcha switch END
            // ---------------------
          }  else {
            console.log('Do Not know the error!');
            this.dialogService.alertsModal('errors', response.original.error, 5000, false);
          }
        });
      // });
    }
  }

  createWalletRegForm() {
    this.logregForm = new UntypedFormGroup({
      phone: new UntypedFormControl('',{updateOn: 'change', validators: [ Validators.required]}),
      email: new UntypedFormControl('',{updateOn: 'change', validators: [Validators.pattern(emailRegex), Validators.required]}),
      password: new UntypedFormControl('',{updateOn: 'change'}),
      phone_email: new UntypedFormControl('')
    });
    this.showWallets = false;
    this.setFormValidation();
  }

  slideToggleSection() {
    this.showWalletEmailSection = !this.showWalletEmailSection;
    // console.log('showWalletEmailSection', this.showWalletEmailSection);
    this.setFormValidation();
  }

  setFormValidation() {
      // phone if false
    if (this.showWalletEmailSection) {
      this.logregForm = new UntypedFormGroup({
        email: new UntypedFormControl('', {updateOn: 'change', validators: [Validators.pattern(emailRegex), Validators.required]}),
        password: new UntypedFormControl(''),
        phone_email: new UntypedFormControl('')
      });
    } else if (!this.showWalletEmailSection) {
      this.logregForm = new UntypedFormGroup({
        phone: new UntypedFormControl('',{updateOn: 'change', validators: [ Validators.required]}),
        phoneCountryCode:  new UntypedFormControl(''),
        phone_email: new UntypedFormControl('')
      });
    }
    this.logregForm.updateValueAndValidity();
    // console.log('setFormValidation', this.showWalletEmailSection, this.logregForm);
  }

  setErrorOnPhoneNumber(event) {
    console.log('setErrorOnPhoneNumber', event, this.logregForm.controls['phone']);
		// const hasError = this.globals.hasErrorOnPhoneNumber(event, this.logregForm.controls['phone'].value);
		// const actionOnSetErrors = hasError ? {'invalid': true} : null;
    if(event) {
      this.logregForm.controls['phone'].setErrors({'invalid': true}, {emitEvent: true});
      this.logregForm.controls['phone'].markAsTouched();
      this.logregForm.updateValueAndValidity();
    }

  }

  onChangePhone(event) {
    this.counter++;
    const formValid = this.logregForm.controls['phone'].valid;
    // console.log('onChangePhone',formValid, event.target.value, this.logregForm, !this.logregForm.invalid , this.logregForm.touched);
    this.logregForm.controls['phone'] ? this.logregForm.controls['phone'].setValue(event.target.value) : false;
    // this.logregForm.updateValueAndValidity();
    this.counter == 1 && formValid ? this.formChangeSub() : false;
  }

  formChangeSub() {
    this.logregForm.controls['phone'].valueChanges.pipe(
      debounceTime(1000)).subscribe((input) => {
        const hiddenBtn =  <HTMLButtonElement>document.getElementsByClassName('continue-btn')[0];
        if(hiddenBtn) { hiddenBtn.focus();}

        // console.log('onChange valueChanges', input, hiddenBtn);
        // this.logregForm.controls['phone'] ? this.logregForm.controls['phone'].markAsTouched() : false;
      });
  }

  onKeyPressFn(event) {
    if (event.keyCode === 13 && !this.isShowOtp && this.isWalletConnect ) {
      // console.log('onKeyPressFn logregFormContinue 1');
      this.walletIsChecked ? this.logregWalletContinue() : this.logRegWithWallet();
    } else if (event.keyCode === 13 && !this.isWalletConnect ) {
      // console.log('onKeyPressFn logregFormContinue 2');
      this.pressEnter = true;
      return;
    }
  }

  startOTPTimer(value) {
    clearInterval(this.interval);
    this.interval = null;
    const time = 1000;
    if(this.isBtnClicked.phone && this.isSendAgainAttempts >= 3) {return}
    if (value) {
      this.sendSmsTimer = 59;
      this.sendSmsTimerEmit.emit(this.sendSmsTimer);
      this.interval = setInterval( () => {
        if (this.sendSmsTimer > 0) {
          this.sendSmsTimer--;
          this.sendSmsTimerEmit.emit(this.sendSmsTimer);
        } else {
          clearInterval(this.interval);
          this.interval = null;
          this.isSendAgain = value;
          this.sendSmsTimer = 0;
          this.sendSmsTimerEmit.emit(this.sendSmsTimer);
        }
      }, time);
    } else {
      this.sendSmsTimer = 0;
      this.sendSmsTimerEmit.emit(this.sendSmsTimer);
    }
    // console.log('startTimer', this.sendSmsTimer, this.isSendAgain, value);
  }

  setRealPhoneNumber(phoneNumber) {
    // console.log('setRealPhoneNumber', phoneNumber);
    this.logregForm.controls['phone'].patchValue(phoneNumber);
  }

  intlTelInputObject(event) {
    // console.log('intlTelInputObject', event.s.iso2, event, this.logregForm.controls); // .patchValue(event.s.iso2);
		if(this.logregForm.controls['phone'].value) {
			event.setNumber(this.logregForm.controls['phone'].value);
		}
		this.logregForm.controls['phoneCountryCode'].patchValue(event.s.iso2);
  }

  intlOnCountryChange(event) {
    // console.log('intlOnCountryChange', event.iso2);
    this.logregForm.controls['phoneCountryCode'].patchValue(event.iso2);
  }
  convertToFormControl(absCtrl: AbstractControl | null): UntypedFormControl {
    const ctrl = absCtrl as UntypedFormControl;
    return ctrl;
  }


  ngOnInit(): void {
    // console.log('ipInfo.location.calling_code: ', this.ipInfo);
    // this.recaptchaService.render('recaptcha_userlogin', 'LOGIN')
    // this.captchaV3TokenSubscription = this.recaptchaService.token.subscribe(token => {
    //   this.captchaV3Token = token;
    // })
    this.captchaV3TokenSubscription = this.recaptchaService.token.subscribe(token => {
      this.captchaV3Token = token;
      this.clicked = false;
    })
    // setTimeout(()=> {
    //   this.recaptchaService.render('recaptcha_userlogin', 'LOGIN')
    // }, 5000)
  }
  getTerms() {
    this.showTerms.emit(true);
  }
  getPrivacy() {
    this.showPrivacy.emit(true);
  }

  mailChange(email) {
    if(Mailcheck) {
      Mailcheck.run({
        email: email,
        // domains: domains,                       // optional or check mailcheck.js
        // topLevelDomains: topLevelDomains,       // optional or check mailcheck.js
        // secondLevelDomains: secondLevelDomains, // optional or check mailcheck.js
        // distanceFunction: superStringDistance,  // optional or check mailcheck.js
        suggested: (suggestion: any) => {
          // callback code
          this.suggestion = suggestion;
        },
        empty: ()=> {
          // callback code
          this.suggestion = null;
        }
      });
    }

  }
  setSuggestedMail(mail) {
    const control = this.logregForm.get('email');
    if (control) { control.setValue(mail); }
    this.suggestion = null;
  }

  setPasskeyLoginData(response) {
    this.isShowOtp = false;
    this.userData = response.user_data;
    this.setUserData.emit(this.userData);
    this.switchStep.emit(this.showSeatsIo ? 'SeatsTickets': 'Guest');
    const isSuccess = response.original.success == 'letsGoHome';
    let active_events = 0;
    if(response.user_data && response.user_data.active_events) {
      active_events = response.user_data.active_events;
    }
    this.intercomService.boot((active_events && window.innerWidth >= 768) ? false : true);

    !isSuccess ? this.dialogService.alertsModal('success', response.original.success, 3000, true) : false;
    isSuccess ? this.dialogService.alertsModal('success', 'readerLogin', 3000, true) : false;
  }

  togglePassword(e) {
    // console.log(e.checked, this.logregForm);
    if(!e.checked) {
      this.logregForm.controls['password'].setValue('');
      this.logregForm.controls['password'].removeValidators(Validators.required);
    }
  }

  intercomMessage() {
    this.intercomService.message('')
  }

  showTab() {
    this.isShowOtp = false;
    this.logRegBtnClick(this.isBtnClicked.phone ? 'email' : 'phone'); // switch tab
  }
  ngOnDestroy() {
    this.isShowOtp = false;
    // console.log('onDestroy user login component');
    this.walletSignUpUnsubscribe();
    this.walletSignUpUnsubscribe();
    if (this.captchaV3TokenSubscription) {
      this.captchaV3TokenSubscription.unsubscribe();
    }
    this.captchaV3Token = '';
    this.csrf_token = '';
    this.recaptchaService.setToken('');
  }

}
