import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MbscSelectOptions } from '@mobiscroll/angular';
import { GlobalsService } from '../../../globals';
import { emailRegex } from '../../../regex';
import { DialogService } from '../../../services/dialog.service';
import { NavbarService } from '../../../services/navbar.service';
import { SupportService } from '../../../services/support.service';
import {IntercomService} from "../../../services/intercom.service";

@Component({
  selector: 'app-send-support',
  templateUrl: './send-support.component.html',
  styleUrls: ['./send-support.component.css']
})
export class SendSupportComponent implements OnInit {
  @ViewChild('supportPageForm') supportForm: NgForm;
  emailRegex = emailRegex;
  support_info = {
    first_name: '',
    last_name: '',
    email: '',
    order_id: '',
    issue_type: '',
    event_venue: '',
    subject: '',
    description: '',
    attachment: null
  }
  localeId = this.globals.getLocaleId();
  event_venues = { he: ['venue 1', 'venue 2'], en: ['venue 1', 'venue 2'], es: ['venue 1', 'venue 2'], };
  issue_types = {
    he: ['תקלה בפתיחת אירוע', 'בעיה כספית', 'אחר'],
    en: ['Other', 'Financial problem', 'Error opening event'],
    es: ['Otro', 'Problema financiero', 'Error al abrir el evento'],
    fr: ['Other', 'Financial problem', 'Error opening event'],
    ar: ['Otro', 'Problema financiero', 'Error al abrir el evento']
  };

  fileToUpload: File = null;
  fileMaxSize: number  = 2;

  // mbsc-select [mbsc-options]='selectIssueSettings'
  selectIssueSettings: MbscSelectOptions = {
    display: 'center',
    filter: false,
    theme: this.globals.mobiscrollTheme(),
    lang: this.globals.LOCALE_ID,
  }

  constructor(private navbar: NavbarService,
    private globals: GlobalsService,
    private dialogService: DialogService,
    private supportService: SupportService,
    public intercomService: IntercomService,
    private router: Router) {}

  ngOnInit() {
    this.router.navigate(['/']).then(res => {
      console.log('redirrect from support page', res);
      this.intercomService.boot();
      this.intercomService.showSpace('messages');
    });
    // this.navbar.setIsNewUi(true);
    // setTimeout(() => {this.navbar.noPadding = 'removePadding';}, 200);
    // this.navbar.hide();
  }

  closePage() {
    this.router.navigate(['/']);
  }

  setIssueType(value) {
    // console.log('setIssueType', value, this.support_info.issue_type);
  }

  setEventVenue(value) {
    // console.log('setEventVenue', value, this.support_info.event_venue);
  }

  handleFileInput(eFiles: any) {
    let files = eFiles.files as FileList;
    let imgError = { action: true, error: 0 };
    let file: any = files.item(0);
    if (file) {
      this.fileToUpload = file;
      if (file.size / 1000000 > this.fileMaxSize) {
        imgError = { action: false, error: 3 }
        return imgError;
      }
      if (file.length === 0) {
        imgError = { action: false, error: 9 }
        return imgError;
      }
      if (imgError.action) {
        // send file
      } else {
        this.dialogService.alertsModal('fileUploads', imgError.error, 5000, false);
      }
    }
    // console.log('handleFileInput', this.fileToUpload);
  }

  onSubmit(){
		this.globals.startLoader();
    if (this.supportForm.valid) {
      this.supportForm.value['attachment'] = this.fileToUpload;
      const formData = new FormData();
      for (const item in this.supportForm.value) {
        formData.append(item, this.supportForm.value[item]);
      }
    // console.log('form submited', this.supportForm.form, this.supportForm.value, this.fileToUpload);
			this.supportService.sendSupportForm(formData).subscribe(
					response => {
							this.globals.stopLoader();
              this.dialogService.alertsModal('success', 'success', 5000, true);
              this.supportForm.resetForm();
              setTimeout(() => {
                this.router.navigate(['/']);
              }, 2000);
						},
							error => {
						this.globals.stopLoader();
            this.dialogService.alertsModal('errors', 2, 5000, false);
            this.supportForm.resetForm();
          }
        );
    } else {
      // console.log('supportForm invalid', this.supportForm.form);
      this.globals.stopLoader();
      this.markFormGroupTouched(this.supportForm.form);
    }
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      // console.log('control', control);
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
    this.scrollToErrorField();
  }

  scrollToErrorField() {
    setTimeout(() => {
      const results = (<HTMLElement>document.querySelector('input.form-control-input.ng-pristine.ng-invalid.ng-touched'));
      console.log('results', results);
      if (results) {
        results.focus();
        results.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 200);
  }

}
