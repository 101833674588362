<div class="modal-wrapper">
  <div class="modal-state">
    <div id="rotation" class="jump-scale" *ngIf="!type">
      <svg class="invalid" width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M28.4856 4.04786L24.8218 0.384033L0.38408 24.8217L4.0479 28.4856L28.4856 4.04786Z" fill="#FF6465" />
        <path d="M28.4908 24.8222L4.0531 0.384521L0.389278 4.04834L24.827 28.486L28.4908 24.8222Z" fill="#FF6465" />
      </svg>
    </div>
    <div id="write_hand" class="jump-scale" *ngIf="type">
      <svg class="valid" width="34" height="23" viewBox="0 0 34 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="write-by-hand" stroke="#82FFCA" stroke-width="2"
              d="M33.5021 0.45868C32.8382 -0.152893 31.7619 -0.152893 31.0979 0.45868L10.7309 19.2198L2.90212 12.0084C2.23825 11.3968 1.16194 11.3969 0.497947 12.0084C-0.165982 12.6199 -0.165982 13.6114 0.497947 14.2229L9.52879 22.5415C10.1925 23.153 11.2696 23.1526 11.933 22.5415L33.5021 2.67326C34.166 2.06175 34.1659 1.07025 33.5021 0.45868Z"
              fill="none" />
      </svg>
    </div>
  </div>
  <div class="alert-state" [ngClass]="(type) ? 'state-valid' : 'state-invalid'">
    <div class="modal-text" (click)="copyMsg()">
      <p *ngIf="message">{{message}}</p>
  </div>
</div>
