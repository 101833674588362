import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { GlobalsService } from '../../globals';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import {CurrencyLocaleService} from '../../services/currency-locale.service';
import {Chart, ChartOptions} from 'chart.js/auto';
import zoomPlugin, {resetZoom} from 'chartjs-plugin-zoom';
import 'chartjs-adapter-date-fns';
Chart.register(zoomPlugin);
@Component({
  selector: 'app-analytic-sales',
  templateUrl: './analytic-sales.component.html',
  styleUrls: ['./analytic-sales.component.css']
})
export class AnalyticSalesComponent implements OnInit, AfterViewInit, OnDestroy {
  saleTypes: any[] = [
    { name: 'DECLINED', id: 1, col: 12 },
    { name: 'APPROVED', id: 2, col: 54 },
    { name: 'ABANDONING', id: 3, col: 4 },
    { name: 'HIDDEN', id: 4, col: 99 },
    { name: 'CANCELED', id: 5, col: 2 },
  ];

  @Input() statsData: any;
  @Input() lineChartOptions: any;
  @Input() lineChartType: any;
  @Input() lineChartEventData: any;
  @Input() lineChartEventLabels: any;
  @Input() limitsMax: number = 50;
  chart: any;
  canvas: any;
  ctx: any;
  @ViewChild('mychart') mychart!: { nativeElement: any };
  private routeSub: Subscription;
  dir: string;
  eventId: string;

  isShowDis = true; // Show discounts +
  isShowSaleR = true; // Show sales round +

  zoomOptions = {};
  lineChartOption: any = {};

  constructor(private route: ActivatedRoute,
              private globals: GlobalsService,
              private currencyLocale: CurrencyLocaleService) { }

  ngOnInit() {
    this.dir = this.globals.getDir();
    // console.log('lineChartEventData', this.lineChartEventData, this.lineChartEventLabels);
    this.routeSub = this.route.params.subscribe(param => {
      this.eventId = param['event_id'];
    })
    this.zoomOptions = {
      zoom: {
        wheel: {
          enabled: true,
        },
        pinch: {
          enabled: true,
        },
        mode: 'xy',
      },
      pan: {
        enabled: true,
        mode: 'xy',
      },
      limits: {
        x: {
          min: new Date('2020-01-01 12:00').valueOf(),
          max: new Date('2050-01-01 12:00').valueOf()
        },
        y: {
          min: 0,
          max: this.limitsMax+1
        }
      },
    };
    this.lineChartOption = {
      responsive: true,
      // legend: { position: 'bottom' }
      scales: {
        // We use this empty structure as a placeholder for dynamic theming.
        x: {
          position: 'top',
          ticks: {
            color: 'white',
            autoSkip: true,
            autoSkipPadding: 50,
            maxRotation: 0
          },
          border: {
            display: true
          },
          type: 'time',
          time: {
            minUnit: 'hour',
            tooltipFormat: 'MMM d, HH:mm',
            displayFormats: {
              year: 'yyyy',
              quarter: 'qqq - yyyy',
              month: 'MMM yyyy',
              week:  'MMM yyyy',
              day:  'MMM d',
              hour: 'MMM d, HH:00',
              minute: 'MMM d, HH:00',
              second: 'MMM d, HH:00',
              millisecond: 'MMM d, HH:00',
            }
          }
        },
        y:{
          ticks: {
            color: 'white',
            stepSize: 1,
            callback: (value, index, values) => {
              // console.log(value, index);
              if(index == 0) {
                return Math.floor(value)
              } else {
                return Math.ceil(value)
              }

            }
          },
          border: {
            display: true
          },
          grid: {
            color: '#4b4b4b'
          }
        }
      },
      // elements: {
      //   line: {
      //     spanGaps: true
      //   }
      // },
      tooltips: {
        backgroundColor: '#FF6465',
        caretPadding: 10
      },
      plugins: {
        zoom: this.zoomOptions,
        // tooltip: {
        //   filter: (c) => {
        //
        //     // if(c.formattedValue > 0) {
        //     //   return c
        //     // }
        //   }
        // }
      }
    }

  }
  ngAfterViewInit() {
    // console.log(this.mychart);
    if(this.mychart) {
      this.canvas = this.mychart.nativeElement;
      this.ctx = this.canvas.getContext('2d');
      this.chart = new Chart(this.ctx, {
        type: this.lineChartType,
        data: {
          labels: this.lineChartEventLabels,
          datasets: this.lineChartEventData
        },
        options: this.lineChartOption,
      });
    }

  }

  getLocale(currency) {
    return this.currencyLocale.getLocaleForCurrencyPipe(currency)
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  toggleSection(section) {
    if (section === 'discount' ) {
      this.isShowDis = !this.isShowDis;
    }
    if (section === 'sale-round' ) {
      this.isShowSaleR = !this.isShowSaleR
    }
  }

  resetZoom() {
    this.chart.resetZoom();
  }

}
