<!--OLD LANG SWITCHER-->
<!--<select class="lang-switcher" name="lang-switcher" i18n-title title="Choose language"-->
<!--        [ngModel]="localeId"-->
<!--        (ngModelChange)="changeLanguage($event)"-->
<!--        [ngClass]="{'rtl' : dir === 'rtl'}">-->
<!--  <option *ngFor="let lang of languages" [value]="lang.lang">-->
<!--    {{lang.langName}}-->
<!--  </option>-->
<!--</select>-->

<!--NEW LANG SWITCHER-->
<mat-form-field [ngClass]="{'rtl' : dir === 'rtl'}" class="lang-mat-select-form">
<!--  <mat-label></mat-label>-->
  <div class="selected-mat-opt">
    <span>{{localeId}}</span>
    <img src="assets/images/svg-icons/{{localeId}}.svg">
  </div>
  <mat-select (selectionChange)="changeLanguage($event)" [(value)]="localeId" hideSingleSelectionIndicator="true" disableRipple="true" panelClass="lang-mat-select">
    <mat-option *ngFor="let lang of languages" [value]="lang.lang">
      <img src="assets/images/svg-icons/{{lang.lang}}.svg" style="max-width: 15px; height: 10px; display: inline-block; margin: 0 3px;">{{lang.langName}}
    </mat-option>
  </mat-select>
</mat-form-field>
