import {Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {FilterEventsService} from '../../services/filter-events.service';
import {GlobalsService} from '../../globals';

@Component({
  selector: 'app-category-sidenav',
  templateUrl: './category-sidenav.component.html',
  styleUrls: ['./category-sidenav.component.css']
})
export class CategorySidenavComponent implements OnInit {
  @Input() active = '';
  customCategories;
  categoriesIdsWithEvents: Array<number> = [];
  localeId: string;
  dir: string;

  private isDown = false;
  private startX;
  private scrollLeft;
  @ViewChild('cats_scroll') cats_scroll;

  @HostListener('mousedown', ['$event']) onCarouselMouseDown(e) {
    this.isDown = true;
    this.startX = e.pageX - this.cats_scroll.nativeElement.offsetLeft;
    this.scrollLeft = this.cats_scroll.nativeElement.scrollLeft;
    // this.cats_scroll.nativeElement.classList.add('dragging');
  }
  @HostListener('mousemove', ['$event']) onCarouselMouseMove(e) {
    if (!this.isDown) return;
    e.preventDefault();
    const x = e.pageX - this.cats_scroll.nativeElement.offsetLeft;
    const walk = (x - this.startX) * 2; //scroll-fast
    // console.log('startX', this.startX, e.pageX)
    if (!this.cats_scroll.nativeElement.classList.contains('dragging') && Math.abs(this.startX - e.pageX) > 2) {
      this.cats_scroll.nativeElement.classList.add('dragging');
    }
    this.cats_scroll.nativeElement.scrollLeft = this.scrollLeft - walk;
  }
  @HostListener('mouseleave', ['$event']) onCarouselMouseLeave(e) {
    this.isDown = false;
    if (this.cats_scroll !== undefined) {
      this.cats_scroll.nativeElement.classList.remove('dragging');
    }
  }
  @HostListener('mouseup', ['$event']) onCarouselMouseUp(e) {
    this.isDown = false;
    this.cats_scroll.nativeElement.classList.remove('dragging');
  }


  constructor(private filterEventsService: FilterEventsService, public globals: GlobalsService) { }

  ngOnInit(): void {

     this.customCategories = this.filterEventsService.categoriesSource.getValue();
     this.categoriesIdsWithEvents = this.filterEventsService.categoriesIdsWithEvents.getValue();
     // console.log('customCategories INIT', this.categoriesIdsWithEvents, this.customCategories);
     this.joinValues();
     this.localeId = this.globals.getLocaleId();
     this.dir = this.globals.getDir();
     this.subscribeForCategories();
     // console.log('CategorySidenavComponent', this.active, this.customCategories);
  }

  subscribeForCategories() {
    if (!this.customCategories.length) {
      this.filterEventsService.getCategoriesSource.subscribe(categories => {
        this.customCategories = categories;
        // console.log('customCategories UPDATED', this.active, this.categoriesIdsWithEvents, this.customCategories);
        this.joinValues();
      })
    }
    if (!this.categoriesIdsWithEvents.length) {
      this.filterEventsService.getCategoriesIdsWithEvents.subscribe(categoriesIds => {
        this.categoriesIdsWithEvents = categoriesIds;
        this.joinValues();
        // console.log('categoriesIdsWithEvents UPDATED', this.active, this.categoriesIdsWithEvents, this.customCategories);
      })
    }
  }

  joinValues(){
    if(this.customCategories.length && this.categoriesIdsWithEvents.length) {
      this.customCategories = this.customCategories.filter(item => this.categoriesIdsWithEvents.indexOf(item.id) >= 0);
      // console.log(' this.customCategories ',  this.customCategories )
    }
  }

  scrollTo(id) {
    const el = (<HTMLElement>document.getElementById('category-' + id));
    this.active = id;
    el.scrollIntoView({behavior: 'smooth', block: 'center' });
    console.log(id, el);
  }

}
