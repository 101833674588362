<header>

  <div class="header-wrap">
    <div class="header-logo">
      <a [routerLink]="['/']" class="header-logo-link">
        <img class="text-logo" src="assets/images/svg-icons/{{isMobile ? 'small-logo.svg' : 'new-get-in-logo.svg'}}" alt="logo"/>
      </a>
      <!--    <ng-container *ngIf="!isMobile">-->
      <!--      <span class="header-link-wrap">-->
      <!--      <a [routerLink]="['/']" class="header-link">-->
      <!--        <img src="assets/images/svg-icons/home.svg" alt="home"/>-->
      <!--        <span>Home</span>-->
      <!--      </a>-->
      <!--      </span>-->
      <!--      <span class="header-link-wrap">-->
      <!--        <a [routerLink]="['/discover']" class="header-link">-->
      <!--        <img src="assets/images/svg-icons/discover.svg" alt="discover"/>-->
      <!--        <span>Discover</span>-->
      <!--      </a>-->
      <!--      </span>-->
      <!--    </ng-container>-->
    </div>

    <app-search [clickOpenSearch]="clickOpenSearch" [isMobile]="isMobile" (searchOpenedChange)="onSearchOpenedChange($event)" [direction]="direction"></app-search>

    <div class="header-items">
      <div class="header-item">
        <app-create-event-button [direction]="'left'" [textFromRight]="true"></app-create-event-button>
      </div>

      <div class="header-item" *ngIf="isMobile" (click)="openSearch()">
        <img class="chat-main-icon"  src="assets/images/svg-icons/search-normal.svg" alt="search">
      </div>

      <a class="header-item bell-wrap" (click)="fireShowNotifications()"[class.bell-disabled]="gettingNotifs" *ngIf="user_id !== 0">
        <img class="bell-icon"  src="assets/images/svg-icons/notification-bing.svg" alt="bell">
        <span class="count-messages" *ngIf="isNewNotifs">{{notifications.unread.length}}</span>
      </a>

      <div class="example-sidenav-content menu-toggle-btn" [attr.data-cy]="'sideMenuButton'" (click)="toggleSidenav()">
        <app-burger-menu></app-burger-menu>
      </div>
    </div>
  </div>
</header>
<div class="container notif-wrap" *ngIf="showNotifications">
  <app-notifications-list (fireShowNotifications)="fireShowNotifications()" (updateNotificationList)="updateNotificationList($event)" [notifications]="notifications"></app-notifications-list>
</div>
