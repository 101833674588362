import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventTicketsService {
  private onCheckPromo = new Subject<any>();
  getCheckPromo = this.onCheckPromo.asObservable();
  private onEditTicket = new Subject<any>();
  getEditTicket = this.onEditTicket.asObservable();
  private onCalcTotalPrice = new Subject<any>();
  getCalcTotalPrice = this.onCalcTotalPrice.asObservable();
  ticketLimits: any = null;
  participentData: any = null;

  constructor() {
    // console.log('Start EventTicketsService');
  }

  editTicket(item, sign, category = null, purchase_item) {
    const data = {item, sign, category, purchase_item};
    this.onEditTicket.next(data);
  }

  calcTotalPrice(buyWithCash: boolean = false) {
    this.onCalcTotalPrice.next(buyWithCash);
  }

  checkPromo(couponCode) {
    this.onCheckPromo.next(couponCode);
  }

  setDataTickets(ticketLimits) {
    // console.log('setDataTickets EventTicketsService', ticketLimits)
    this.ticketLimits = ticketLimits;
  }
  getDataTickets() {
    // console.log('getDataTickets EventTicketsService');
    return this.ticketLimits;
  }

  setDataParticipents(participentData) {
    // console.log('setDataParticipents EventTicketsService', participentData)
    this.participentData = participentData;
  }

  getDataParticipents() {
    // console.log('getDataParticipents EventTicketsService');
    return this.participentData;
  }

  clearDataParticipents() {
    // console.log('clearDataTickets EventTicketsService');
    this.participentData = null;
  }

  clearDataTickets() {
    // console.log('clearDataTickets EventTicketsService');
    this.ticketLimits = null;
  }

  getMinMaxPrices(tickets, rooms, tables) {
    let ticket_min_price;
    let room_min_price;
    let table_min_price;
    if (tickets) {
      const prices = [];

      for (const t of tickets) {
        prices.push(t.price);
        if (t.pending_date == 1) {
          t.hide = 'disabled_ticket';
        } else {
          t.hide = '';
        }
      }
      ticket_min_price = Math.min.apply(Math, prices);
    }

    if (rooms) {
      const prices = [];

      for (const r of rooms) {
        prices.push(r.price);
      }
      room_min_price = Math.min.apply(Math, prices);
    }

    if (tables) {
      const prices = [];

      for (const t of tables) {
        prices.push(t.price);
      }
      table_min_price = Math.min.apply(Math, prices);
    }
    return {ticket_min_price, room_min_price, table_min_price};
  }

  categoryStepChoise(tickets, tickets_reg, tables, tables_reg, rooms, rooms_reg) : any {
    // console.log('categoryStepChoise');
    // Check what step should set up
    let title: string;
    let purchase_item: number;
    if ((tickets || tickets_reg) && (tables && tables.length === 0) && (tables_reg && tables_reg.length === 0) && (rooms && rooms.length === 0) && (rooms_reg && rooms_reg.length === 0)) {
      // this.step = 'items_section';
      title = 'ticket';
      purchase_item = 1;
      // this.noCategory = true;
    } else if ((tables || tables_reg) && (tickets && tickets.length === 0) && (tickets_reg && tickets_reg.length === 0) && (rooms && rooms.length === 0) && (rooms_reg && rooms_reg.length === 0)) {
      // this.step = 'items_section';
      title = 'table';
      purchase_item = 3;
      // this.noCategory = true;
    } else if ((rooms || rooms_reg) && (tickets && tickets.length === 0) && (tickets_reg && tickets_reg.length === 0) && (tables && tables.length === 0) && (tables_reg && tables_reg.length === 0)) {
      // this.step = 'items_section';
      title = 'room';
      purchase_item = 2;
      // this.noCategory = true;
    } else {
      // this.step = 'category_step';
    }
    return {title, purchase_item};
  }

  initChoosedTickets(tickets, categories, ticketLimits) {
    // console.log('initChoosedTickets', this.ticketLimits, this.choosedItems);
    tickets.forEach(ticket => {
      ticketLimits[ticket.id] = {
        available: ticket.limits[ticket.limits.length - 1],
        choosed: {},
        availableToChoose: ticket.limits[ticket.limits.length - 1],
        options: ticket.limits
      }
    });

    if (categories) {
      categories.forEach(category => {
        Object.keys(ticketLimits).forEach(ticket => {
          ticketLimits[ticket]['choosed'][category.id] = {};
          ticketLimits[ticket]['choosed'][category.id].amount = 0;
          ticketLimits[ticket]['choosed'][category.id].options = ticketLimits[ticket].options;
        })
      })
    }

    Object.keys(ticketLimits).forEach(ticket => {
      ticketLimits[ticket]['choosed']['noCat'] = {};
      ticketLimits[ticket]['choosed']['noCat'].amount = 0;
      ticketLimits[ticket]['choosed']['noCat'].options = ticketLimits[ticket].options;
    });

    return ticketLimits;
  }

  initChoosedItems(categories, ticketsFiltered, tickets, showCategory, choosedItems, withoutCategory, stepTemplate): any {
    // console.log('initChoosedItems', this.ticketLimits, this.choosedItems);
    categories.forEach((category, index) => {
      ticketsFiltered[category.id] = tickets.filter(ticket => ticket.category_id.indexOf(category.id) > -1);
      category.show = !!ticketsFiltered[category.id].length;
      showCategory[index] = false;
      choosedItems[category.id] = { 'name': category.name };
      ticketsFiltered[category.id].sort((a,b) => {
        return a.sort - b.sort;
      });
    });

    withoutCategory = tickets.filter(ticket => !ticket.category_id);
    withoutCategory.sort((a,b)=> {
      return a.sort - b.sort;
    });
    categories.sort((a, b) => {
      return a.sort - b.sort;
    });
    // console.log('For sort', this.ticketsFiltered, this.withoutCategory, this.categories);

    if (withoutCategory.length && (stepTemplate !== 'templateOrder')) {
      choosedItems['noCat'] = {};
    }
    // console.log('this.categories', this.categories, this.choosedItems);
    return {categories, ticketsFiltered, tickets, showCategory, choosedItems, withoutCategory, stepTemplate}
  }
}
