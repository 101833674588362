import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {map, tap} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MaxmindGeoIpService {
  api_base_url = environment.api_base_url;

  constructor(private http: HttpClient) { }


  getGeoData () {
    if(this.api_base_url == 'http://localhost:8000') {this.api_base_url = 'https://api.getin-nextgen.com'} // for local development

    let geoData = sessionStorage.getItem('geoData');

    if(geoData) {
      return new Observable(observer => {
        // console.log('getGeoData observer', geoData);
        observer.next(JSON.parse(geoData));
      });
    } else {
      return this.http.post(this.api_base_url + '/api/user-country', {},
        {headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})}).pipe(
        tap((res: any)=> {
          if(res && res.code == "success" && res.data) {
            sessionStorage.setItem('geoData', JSON.stringify(res.data));
          }
          // console.log('getGeoData tap', res)
        }),
          map((res: any) => {
            if(res && res.code == "success" && res.data) {
              return res.data
            } else {
              return res
            }
          })
      );
    }

  }
}
