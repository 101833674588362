import {AfterViewInit, Component, OnInit, ViewChild, Inject,
  OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef,} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {FilterEventsService} from '../../services/filter-events.service';
import {MatDatepicker} from '@angular/material/datepicker';
import {MatCalendar} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats} from '@angular/material/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-datepicker-filter-events',
  templateUrl: './datepicker-filter-events.component.html',
  styleUrls: ['./datepicker-filter-events.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class DatepickerFilterEventsComponent implements OnInit, AfterViewInit {
  exampleHeader = ExampleDatepickerHeader;
  @ViewChild('picker') picker: MatDatepicker<Date>;

  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl()
  });
  constructor(private filterEventsService: FilterEventsService) { }

  ngOnInit(): void {
    this.range.valueChanges.subscribe(selectedValue  => {
      // console.log(selectedValue);
      this.filterEventsService.setDatepicker(selectedValue);
    })
  }

  ngAfterViewInit(): void {
    this.filterEventsService.initPicker(this.picker);
  }

}

/** Custom header component for datepicker. */
@Component({
  selector: 'example-header',
  styles: [`
    .example-header {
      display: flex;
      align-items: center;
      padding: 1em;
    }

    .example-header-label {
      flex: 1;
      height: 18px;
      font-weight: 700;
      font-size: 15px;
      text-align: center;
      line-height: 1;
    }

    .example-double-arrow, .example-single-arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      width: 24px;
      background: #322956;
      border-radius: 5px;
    }
    .example-single-arrow {
      margin: 0 5px;
    }
    .r180{
      transform: rotate(180deg);
    }
    .example-double-arrow img, .example-single-arrow img{
      max-height: 10px;
    }
  `],
  template: `
    <div class="example-header">
      <button mat-icon-button class="example-double-arrow" (click)="previousClicked('year')">
        <img src="./assets/images/svg-icons/left_angle_arrow.svg" alt="">
        <img src="./assets/images/svg-icons/left_angle_arrow.svg" alt="">
      </button>
      <button mat-icon-button class="example-single-arrow" (click)="previousClicked('month')">
        <img src="./assets/images/svg-icons/left_angle_arrow.svg" alt="">
      </button>
      <span class="example-header-label">{{periodLabel}}</span>
      <button mat-icon-button class="example-single-arrow r180" (click)="nextClicked('month')">
        <img src="./assets/images/svg-icons/left_angle_arrow.svg" alt="">
      </button>
      <button mat-icon-button class="example-double-arrow r180" (click)="nextClicked('year')">
        <img src="./assets/images/svg-icons/left_angle_arrow.svg" alt="">
        <img src="./assets/images/svg-icons/left_angle_arrow.svg" alt="">
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ExampleDatepickerHeader<D> implements OnDestroy {
  private _destroyed = new Subject<void>();

  constructor(
    private _calendar: MatCalendar<D>, private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats, cdr: ChangeDetectorRef) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    return this._dateAdapter
      .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
      .toLocaleUpperCase();
  }

  previousClicked(mode: 'month' | 'year') {
    this._calendar.activeDate = mode === 'month' ?
      this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1) :
      this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
  }

  nextClicked(mode: 'month' | 'year') {
    this._calendar.activeDate = mode === 'month' ?
      this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1) :
      this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
  }
}
/** END Custom header component for datepicker. */
