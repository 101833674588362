<div class="salesman-wrapper" [ngClass]="{'rtl' : dir === 'rtl'}">
  <div class="salesman-container">
    <div class="salesman-main">
      <div class="event-back-arrow">
        <div class="close-dialog" (click)="closeLoginModal()">
          <svg class="arrow-close-modal" width="10" height="14" viewBox="0 0 10 14" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M9.49219 1.64063L4.13281 7L9.49219 12.3594L7.85156 14L0.851562 7L7.85156 0L9.49219 1.64063Z"
              fill="white" />
          </svg>
          <img class="x-close-modal" src="assets/images/svg-icons/close.svg" alt="close">
        </div>
      </div>
      <div class="salesman-avatar">
        <img class="salesman-avatar-img" *ngIf="salesman_type === 'salesman'"
             [ngClass]="{'no-avatar': !sellerInfo.seller_social_img}"
             [src]="sellerInfo.seller_profile_img ? sellerInfo.seller_profile_img : sellerInfo.seller_social_img ? sellerInfo.seller_social_img : '../assets/images/svg-icons/logo-new.svg'">
        <img class="salesman-avatar-img" *ngIf="salesman_type === 'producer'"
             [ngClass]="{'no-avatar': !sellerInfo.producer_social_img}"
             [src]="sellerInfo.producer_profile_img ? sellerInfo.producer_profile_img : sellerInfo.producer_social_img ? sellerInfo.producer_social_img : '../assets/images/svg-icons/logo-new.svg'">
      </div>
      <div i18n="seller name | salesman" class="salesman-initials">{{sellerInfo.seller_name}}</div>
      <div class="salesman-initials">{{sellerInfo.producer_name}}</div>
    </div>
    <div class="salesman-attendees" *ngIf="genders">
      <div i18n="section number of attendis" class="attendees-section">Number of attendees&nbsp;({{+genders.male + +genders.female + +genders.unknown_gender}})&nbsp;</div>
      <div class="attendees-data-section-ext">
        <div i18n="section male" class="attendees-data">Men:&nbsp; {{genders.male}}&nbsp;</div>
        <div i18n="section female" class="attendees-data">Women:&nbsp; {{genders.female}}&nbsp;</div>
        <div i18n="section other" class="attendees-data">Other:&nbsp; {{genders.unknown_gender}}&nbsp;</div>
      </div>
    </div>
    <div class="salesman-attendees">
      <div i18n="section number of attendis" class="coupons-section">Coupons&nbsp;({{seller_coupons.length}})&nbsp;</div>
      <div class="attendees-data-section-coupons" *ngFor="let coupon of seller_coupons">
        <div i18n="section male" class="attendees-data coupons-data">Name:&nbsp; {{coupon.name}}&nbsp;</div>
        <div class="coupons-data-group">
          <div i18n="section female" class="attendees-data">Discount:&nbsp; {{coupon.discount}}&nbsp;</div>
          <div i18n="section used" class="attendees-data">Used:&nbsp; {{coupon.quantity - coupon.remaining}}&nbsp;</div>
          <div i18n="section unknown" class="attendees-data">Total:&nbsp; {{coupon.quantity}}&nbsp;</div>
        </div>
      </div>
    </div>
    <div class="salesman-tickets">
      <div i18n="ticket type title" class="tickets-section">Ticket type&nbsp;({{tickets.length}})&nbsp;</div>
      <!-- tickets -->
      <div class="salesman-data" [ngStyle]="{'display': tickets.length == 0 && 'none'}" *ngFor="let item of tickets">
        <div i18n="ticket name" class="tickets-title">{{item.item_name}}</div>
        <!-- <div i18n="section total" class="tickets-title">{{item.ticket_c}}</div> -->
        <div class="attendees-data-section-ext">
          <div class="tickets-data-group">
            <div i18n="ticket section label Credit"  class="tickets-data">Credit: &nbsp;{{item.total_cred}}&nbsp;</div>
            <div i18n="ticket section label Registration" class="tickets-data">Registration: &nbsp;{{item.total_register}}&nbsp;</div>
            <hr>
            <div i18n="ticket section label Free admission" class="tickets-data">Free admission: &nbsp;{{item.total_free}}&nbsp;</div>
            <div i18n="ticket section label Cash" class="tickets-data">Cash: &nbsp;{{item.total_cash}}&nbsp;</div>
          </div>
        </div>
      </div>
    </div>

    <div  class="salesman-cash">
      <div *ngIf="userType.toString() == '0' || userType.toString() == '3'" class="cash-section"><span t key="analytics.salesmanDetails.cashToBeCharged" default="Cash to be charged"></span> ({{+cash_sum  | currency:
        currencyCode: 'symbol':'1.0-2':getLocale(currencyCode)}})</div>
<!--      <div *ngIf="userType.toString() == '0' || userType.toString() == '3'" class="attendees-data-section">-->
<!--        <div i18n="section name | before charge" class="cash-data">Before billing: {{sellerData[0].pending_income}}</div>-->
<!--        <div i18n="section name | after charge" class="cash-data">After billing: {{sellerData[0].income}}</div>-->
<!--      </div>-->
<!--      <div *ngIf="userType.toString() == '0' || userType.toString() == '3'" class="balance-graph-container charge-container">-->
<!--        <div i18n="section name | Amount be charge" class="amount-text amount-title-pos">Amount be charge</div>-->

<!--          <form class="balance-field balance-amount" (ngSubmit)="onSubmit()" #chargeCashForm="ngForm">-->
<!--            &lt;!&ndash; <div i18n="section amount to charge" class="request-sum"><span class="request-cur">$</span>120.50</div> &ndash;&gt;-->
<!--            <input class="request-sum" i18n-placeholder="placeholder | sum to collect" appValidationMin="0" min="0" max="99" pattern="[0-9]{0,}"-->
<!--              [appValidationMax]="sellerData[0].pending_income" type="text" name="cashToCharge" placeholder="סכום לגביה"-->
<!--              #chargeInput="ngModel" ngModel>-->
<!--            <button i18n="Request Cash To Charge button" type="submit" class="request-text" >Request Cash</button>-->
<!--          </form>-->
<!--          <span i18n="error alert | value must be positive and lower than total amount to charge"-->
<!--            *ngIf="(chargeInput.errors?.pattern || chargeInput.errors?.ValidationMaxDirective || chargeInput.errors?.ValidationMinDirective) && chargeInput.dirty && chargeInput.invalid"-->
<!--            class="item-error mb10">יש להזין ערך חיובי בלבד שאינו עולה על הסכום הזמין לגביה </span>-->

<!--        &lt;!&ndash; <div class="balance-field balance-btn">-->
<!--            <div class="btn-text">Redrew now</div>-->
<!--          </div> &ndash;&gt;-->
<!--      </div>-->
      <div class="expenses-container charge-container">
        <!-- <div class="expenses-title">Expenses</div> -->
        <!-- <div class="expenses-section">
          <div class="expenses-labels">11.12.2019</div>
          <div class="expenses-numbers">$ -200.00</div>
        </div> -->
        <div class="expenses-section" *ngFor="let seller_cash of seller_cash_withdraw">
          <div class="expenses-labels">{{seller_cash.created_at | date: 'dd.M.yyyy'}}</div>
          <div class="expenses-numbers">{{seller_cash.amount | currency: currencyCode:'symbol':'1.':getLocale(currencyCode)}}</div>
          <hr class="expenses-hr">
        </div>
      </div>
    </div>

  </div>
</div>
