import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'search_params'})
export class FilterArrayPipe implements PipeTransform {

	search_params = [
		'id',
		'user_id',
		'event_id',
		'title',
    'event_title',
		'phone',
		'email',
		'first_name',
		'last_name',
		'full_name',
		'sub_users',
		'url_friendly',
		'identification_number',
		'comment',
		'address',
		'purchase_id',
    'ticket_type'
	];

	transform(value, args) {
		if(!args) {
			return value;
		} else if(value) {
			// make the filter case insensitive:
			args = (typeof args == 'string' || args instanceof String) ? args.toLowerCase().trim() : String(args);
      // console.log(value, args);
			return value.filter(item => {
				for (let key in item) {

					// to limit search to these specific object keys.
					if(this.search_params.find(x => x == key)) {

						// also search in sub_object:
						if(key == 'sub_users') {
							for(let i=0; i<item[key].length; i++) { // all sub users
								for(let sub_user_key in item[key][i]) { // search for value in sub_users object:
									if(this.search_params.find(x => x == sub_user_key)) {

										// concatinate first and last name to allow search value in both:
										if(sub_user_key == 'first_name' || sub_user_key == 'last_name') {
											let full_name = item[key][i]['first_name'] + ' ' + item[key][i]['last_name'];
											if((full_name.toLowerCase().indexOf(args) !== -1)) {
												return true;
											}


										} else if(sub_user_key == 'ticket_type') { // Check by Ticket Type for SUBs
                      let type = 0;
                      if (args == 'registration') { type = 1}
                      if (args == 'regular') { type = 2}
                      if (args == 'donation') { type = 3}
                      if(item[key][i][sub_user_key] == type) {
                        return true;
                      }
                    } else { // every other field in sub object
											if(((typeof item[key][i][sub_user_key] == 'string' || item[key][i][sub_user_key] instanceof String) && (item[key][i][sub_user_key].toLowerCase().indexOf(args) !== -1)) || (typeof item[key][i][sub_user_key] == 'number' && (String(item[key][i][sub_user_key]).indexOf(args) !== -1))) {
												return true;
											}
										}
									}
								}
							}

						// for main object - concatinate first and last name to allow search value in both:
						} else if(key == 'first_name' || key == 'last_name') {
							let full_name = item['first_name'] + ' ' + item['last_name'];
							if((full_name.toLowerCase().indexOf(args) !== -1)) {
								return true;
							}

						} else if(key == 'ticket_type') { // Check by Ticket Type for Main User
              let type = 0;
              if (args == 'registration') { type = 1}
              if (args == 'regular') { type = 2}
              if (args == 'donation') { type = 3}
              if(item[key] == type) {
                return true;
              }
            }  else {  // every other field in main object
							if(((typeof item[key] == 'string' || item[key] instanceof String) && (item[key].toLowerCase().indexOf(args) !== -1)) || (typeof item[key] == 'number' && (String(item[key]).indexOf(args) !== -1))) {
								return true;
							}
						}

					}
				}
			});
		}
	}
}
