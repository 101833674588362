import { Injectable } from '@angular/core';
import {EventService} from './event.service';
import {BehaviorSubject} from 'rxjs';
import {GlobalsService} from '../globals';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from "@angular/common/http";
declare var window: any;
declare var Plaid;

@Injectable({
  providedIn: 'root'
})
export class PlaidService {
  onSuccessSubj = new BehaviorSubject<any>('');
  onExitSubj = new BehaviorSubject<any>('');
  plaidTokenExchangeSubj = new BehaviorSubject<any>('');
  fetching = new BehaviorSubject(false);
  isFetching = this.fetching.asObservable();
  getin_base_url = environment.getin_base_url;
  api_base_url = environment.api_base_url

  plaidRedirectSubj = new BehaviorSubject<any>('');

  constructor(private eventService: EventService, private globals: GlobalsService,
              private router: Router, private http: HttpClient) { }

  async create(token, receivedRedirectUri = null, isRedirect = false) {
    console.log('create', token, receivedRedirectUri, isRedirect)
    if(!Plaid) {console.log('No Plaid initiated');return}
    this.fetching.next(true);

     return await Plaid.create({
        token: token,
        onSuccess: (public_token, metadata) => {

          this.onSuccessSubj.next({public_token, metadata})
          if(isRedirect) {
            this.plaidTokenExchangeRedirect(public_token)
          } else {
            this.plaidTokenExchange(public_token);
          }
        },
        onLoad: () => {},
        onExit: (err, metadata) => {
          console.log('err', err, 'metadata', metadata);
          this.onExitSubj.next({err, metadata})
          this.fetching.next(false);
        },
        onEvent: (eventName, metadata) => {
          // console.log('eventName', eventName, 'metadata', metadata);
        },
        //required for OAuth; if not using OAuth, set to null or omit:
        receivedRedirectUri: receivedRedirectUri,
      });
  }

  plaidTokenExchange(public_token) {
    this.globals.startLoader();
    this.eventService.plaidTokensExchange(public_token).subscribe((res: any) => {
      console.log('plaidTokensExchange', res);
      if(res.code === 'success') {
        this.eventService.plaidGetBankInfo().subscribe((res: any) => {
          if(res.data) {
            this.plaidTokenExchangeSubj.next(res.data);
          }
          this.fetching.next(false);
        })
      }
      this.globals.stopLoader();
    })
  }

  plaidTokenExchangeRedirect(public_token) {
    this.globals.startLoader();
    this.eventService.plaidTokensExchange(public_token).subscribe((res: any) => {
      console.log('plaidTokenExchangeRedirect', res.data);
      this.plaidRedirectSubj.next('');
      this.plaidTokenExchangeSubj.next('');
      if(res.code === 'success') {
        this.fetching.next(false);
        this.router.navigate(['/my-finance']);
      }
      this.globals.stopLoader();
    })
  }

  plaidTokensLink() {
    this.eventService.plaidTokensLink().subscribe((res: any) => {
      if(res.data) {
        localStorage.setItem('link_token', JSON.stringify(res.data.token));
        localStorage.setItem('user_plaid_id', JSON.stringify(res.data.user_plaid_id));
      }
      this.fetching.next(false);
    })
  }
  setPlaidTokenExchangeSubj(data) {
    this.plaidTokenExchangeSubj.next(data);
  }
  clearPlaidTokenExchangeSubj() {
    this.plaidTokenExchangeSubj.next('');
  }
  clearPlaidRedirectSubj() {
    this.plaidRedirectSubj.next('');
  }


  checkStatus() {
    return this.http.post(this.api_base_url + '/api/plaid/user-check-connect', {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }),
    });
  }

}
