import { DialogService } from '../../../services/dialog.service';
import { ConfirmWebhookParamsComponent } from './../../modals/confirm-webhook-params/confirm-webhook-params.component';
import { GlobalsService } from './../../../globals';
import { CmsDashboardService } from './../../../services/cms-dashboard.service';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-web-hooks-form',
  templateUrl: './web-hooks-form.component.html',
  styleUrls: ['./web-hooks-form.component.css']
})
export class WebHooksFormComponent implements OnInit {
  @Input() web_hook_obj: any;
  @Input() form_inputs: any;
  @Input() custom_inputs: any;
  @Input() instagram_url: any;
  @Input() actionMode: string;
  @Input() event_id: any;
  @Output() getWebHook =  new EventEmitter<any>();
  @Output() http_method_updated = new EventEmitter<any>();

  getin_base_url = environment.getin_base_url;
  web_hook_form;
  url_pattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  post_data_pattern = /[^{\/}|=](=\$\w+(&)?)/;
  wrong_params_get = [];
  wrong_params_post = [];
  show_block_method = false;

  constructor(
    public globals: GlobalsService,
    public cmsDashboardService: CmsDashboardService,
    private dialog: MatDialog,
    private dialogService: DialogService
    ) {}

  ngOnInit() {
    if (this.actionMode === 'add') {
      this.web_hook_obj = {
        event_id: this.event_id,
        event_trigger: '',
        method: 'get',
        post_data: '',
        url: '',
        status: -1,
        id: 0
      }
    }
    this.buildWebHookForm();
    this.toggle_method_block(this.web_hook_obj.method);
  }

  buildWebHookForm() {
    this.web_hook_form = new UntypedFormGroup({
      trigger: new UntypedFormControl(this.web_hook_obj.event_trigger, [Validators.required]),
      ['http_method_' + this.web_hook_obj.id]: new UntypedFormControl(this.web_hook_obj.method, [Validators.required]),
      api_url: new UntypedFormControl(this.web_hook_obj.url, [Validators.required])
      });
  }

  toggle_method_block(data) {
    data = data.target !== undefined ? data.target.value : data;
	  if (data === 'get') {
	  	this.show_block_method = false;
	  	this.web_hook_form.removeControl('post_data');
	  } else {
	  	this.show_block_method = true;
	  	this.web_hook_form.addControl('post_data', new UntypedFormControl(this.web_hook_obj.post_data, [Validators.required]));
	  }
  }

  submitForm() {
    var error = false;
    if (!this.isParamsExist(this.web_hook_form.controls.api_url.value, 'get')) {
      error = true;
    }
    if (this.web_hook_form.controls['http_method_' + this.web_hook_obj.id].value === 'post') {
      if (!this.isParamsExist(this.web_hook_form.controls.post_data.value, 'post')) {
        error = true;
      }
    }
    if (error) {
      const data = {
        params: {
          get: [],
          post: []
        }
      };
      this.wrong_params_get.forEach(e => {
        data.params.get.push(e);
      });
      this.wrong_params_post.forEach(e => {
        data.params.post.push(e);
      });

      const webhookParamsComponent = this.dialog.open(ConfirmWebhookParamsComponent, {
        data: data,
        disableClose: true,
        autoFocus: false,
        panelClass: 'webhookParamsmodal'
      });
      webhookParamsComponent.beforeClosed().subscribe(
        result => {
          if (result !== undefined && result.confirm) {
            let is_same_object = false;
            if (this.actionMode === 'edit') {
              if (this.isParamsAreSame()) {
                is_same_object = true;
              }
            }
            if (!is_same_object) {
              this.sendWebHook(false);
              } else {
                this.dialogService.alertsModal('cms', 16, 5000, false, 0);
              }
          }
        }
        );
    } else {
      let is_same_object = false;
      if (this.actionMode === 'edit') {
        if (this.isParamsAreSame()) {
          is_same_object = true;
        }
      }
      if (!is_same_object) {
        this.sendWebHook(true);
        } else {
        console.log('Update failed, no changes were made.');
          this.dialogService.alertsModal('cms', 16, 5000, false, 0);
        }
    }
  }

  sendWebHook (need_params_check) {
    let data = {
      event_id: this.event_id,
      event_trigger: this.web_hook_form.controls.trigger.value,
      method: this.web_hook_form.controls['http_method_' + this.web_hook_obj.id].value,
      url: this.web_hook_form.controls.api_url.value,
      post_data: this.web_hook_form.controls.post_data !== undefined ? this.web_hook_form.controls.post_data.value : '',
      need_params_check: need_params_check
    };
    console.log("sendWebHook", data);
    if (this.actionMode === 'edit') {data['id'] = this.web_hook_obj.id};
    this.globals.startLoader();
    const httpAction = this.actionMode === 'add' ? 'setWebHook' : 'updateWebHook';
    this.cmsDashboardService[httpAction](data).subscribe(
      (response: any) => {
        this.globals.stopLoader();
        this.dialogService.alertsModal('success', response.code, 5000, true);
        if (this.actionMode === 'edit') {
          this.updateExistingWebhooks();
          this.http_method_updated.emit({http_method: data.method, id: data['id']});
        } else {
          this.web_hook_form.reset();
          this.web_hook_form.controls['http_method_' + this.web_hook_obj.id].setValue('get');
          this.web_hook_form.controls.trigger.setValue('');
          this.show_block_method = false;
          this.getWebHook.emit()
        }
      },
      error => {
        this.globals.stopLoader();
        this.dialogService.alertsModal((error.code) ? 'cms' :'errors', (error.code) ? error.code : 4 ,5000, false, error.log_id);
      }
      );
    }

    updateExistingWebhooks () {
      this.cmsDashboardService.getWebHookById(this.web_hook_obj.id).subscribe(
        (response: any) => {
          this.web_hook_obj = response.data[0];
        },
        error =>
          this.dialogService.alertsModal((error.code) ? 'cms' :'errors', (error.code) ? error.code : 4 ,5000, false, error.log_id)
      );
    }

    isParamsAreSame () {
      let error = false;
      if (this.web_hook_form.controls['http_method_' + this.web_hook_obj.id].value === 'get') {
        if (this.web_hook_form.controls.api_url.value === this.web_hook_obj.url &&
          this.web_hook_form.controls.trigger.value === this.web_hook_obj.event_trigger &&
          this.web_hook_obj.method === 'get') {
          error = true;
        }
      } else {
        if ((this.web_hook_form.controls.post_data !== undefined &&
          this.web_hook_form.controls.post_data.value === this.web_hook_obj.post_data) &&
          this.web_hook_form.controls.api_url.value === this.web_hook_obj.url &&
          this.web_hook_form.controls.trigger.value === this.web_hook_obj.event_trigger &&
          this.web_hook_obj.method === 'post') {
          error = true;
        }
      }
      return error;
    }

    isParamsExist (data, method) {
      var param_exist = true;
      var computed_params = [];
      const existing_params = this.getExistingParams();
      if (method === 'get') {
        this.wrong_params_get = [];
        var temp_query_string = data.substr(data.indexOf('?') + 1);
      } else {
        this.wrong_params_post = [];
        var temp_query_string = data;
      }
      temp_query_string = temp_query_string.split('&');
      for (const q in temp_query_string) {
        if (q) {
          let param = temp_query_string[q].split('=')[1];
          if (param !== undefined) {
            computed_params.push(param.trim());
          }
        }
      }
      for (const cp in computed_params) {
        if (existing_params.indexOf(computed_params[cp]) === -1) {
          param_exist = false;
          if (method === 'get') {
            this.wrong_params_get.push(computed_params[cp]);
          } else {
            this.wrong_params_post.push(computed_params[cp]);
          }
        }
      }
      return param_exist;
    }

    getExistingParams () {
      let result = [];
      for (const param of this.form_inputs.default_inputs) {
        if (param.input_name === 'user_email') {
          result.push('$participant_email');
        } else if (param.input_name === 'user_phone') {
          result.push('$participant_phone');
        } else {
          result.push('$participant_' + param.input_name);
        }
      };
      if (this.custom_inputs.length) {
        for (const param of this.custom_inputs) {
          result.push('$custom_' + param.id);
        };
      }
      if (this.instagram_url) {
        result.push('$participant_instagram_url');
      }
      result.push('$participant_status');
      result.push('$participant_mailing');
      result.push('$purchase_items_amount');
      result.push('$participant_payment');
      result.push('$participant_payment_method');
      result.push('$payment_no_vat');

      return result;
    }

    updateStatus (status) {
      this.globals.startLoader();
      this.cmsDashboardService.updateWebHookStatus({id: this.web_hook_obj.id, status: status}).subscribe(
        (response: any) => {
          this.globals.stopLoader();
          this.web_hook_obj.status = response.data.new_status;
          this.dialogService.alertsModal('success', response.code, 5000, true);
        },
        error => {
          this.globals.stopLoader();
          this.dialogService.alertsModal((error.code) ? 'cms' :'errors', (error.code) ? error.code : 4 ,5000, false, error.log_id);
        }
    );
  }
}
