<div class="col-md-12 col-xs-12 mt30 mb50">
    <form (ngSubmit)="onSubmit()" #gatewayNewForm="ngForm">
      <div class="form-group col-md-12 col-xs-12 pl0 pr0 vertical-align">
        <div class="col-md-12 col-xs-12 pl0 pr0 text-center">
          <h1 i18n="@@CreateGatewayCreateNew" style="color: #fff;">Create a new gateway</h1>
        </div>
      </div>
      <div class="form-group row">
        <div class="form-group col-md-12 col-xs-12" *ngIf="gatewayTypes | async">
            <label i18n="@@CreateGatewayGatewayprovider:" for="gatewayType">Gateway provider:</label>
          <select class="form-control" name="payment_type" (change)="getGatewayFields($event.target.value)" #gatewayType="ngModel" [(ngModel)]="selectedGatewayType" required>
            <option value="" i18n="@@CreateGatewaySelectprovider:" >Select a gateway provider</option>
            <option *ngFor="let type of (gatewayTypes | async)" value="{{type}}">{{type}}</option>
          </select>
          <app-error-creator [@slideIn] *ngIf="gatewayType && gatewayType.touched && gatewayType.invalid" [control]="gatewayType"></app-error-creator>
        </div>
      </div>
      <div class="form-group row" *ngIf="gatewayMainFields">
          <div *ngFor="let mainfields of gatewayMainFields" class="form-group col-md-12 col-xs-12">
                <div *ngIf="mainfields.input_type == 'text'">
                  <label for="gatewayName" class="">{{mainfields?.placeholder}}</label>
                  <input class="form-control" [type]="mainfields.input_type" value="" [name]="mainfields?.input_name" [(ngModel)]="mainFields[mainfields?.input_name]" #gatewayName="ngModel" [placeholder]="mainfields?.placeholder"  required >
                  <span i18n="@@CreateGatewayErrorRequiredGatewayName" *ngIf="gatewayName.invalid && gatewayName.touched" class="item-error">Please enter a gateway name</span>
                </div>
                <div *ngIf="mainfields.input_type == 'select'">
                  <label for="gatewayStatus" class="">{{mainfields?.placeholder}}</label>
                  <select class="form-control" [name]="mainfields?.input_name" #gatewayStatus="ngModel" [(ngModel)]="selectedOption" required>
                    <option value="" i18n="@@CreateGatewayGatewaystatus">Gateway status</option>
                    <option *ngFor="let status of mainfields?.input_options" value="{{status.value}}">{{status.name}}</option>
                  </select>
                  <span i18n="@@CreateGatewayErrorRequiredGatewayStatus" *ngIf="gatewayStatus.invalid && gatewayStatus.touched" class="item-error">Please select a gateway status</span>
                </div>
          </div>
      </div>

      <div class="form-group row" *ngIf="gatewayExtFields">
          <div class="form-group col-md-12 col-xs-12" *ngFor="let extfields of gatewayExtFields">
            <div *ngIf="extfields.input_type == 'text'">
              <label class="">{{extfields?.placeholder}}</label>
              <input class="form-control" [type]="extfields.input_type" value=""
                     [name]="extfields?.input_name" [placeholder]="extfields?.placeholder" [(ngModel)]="extFields[extfields?.input_name]"
                     #gatewayExtField="ngModel" required >
              <span i18n="@@CreateGatewayErrorFieldisrequired"
                    *ngIf="gatewayExtField.invalid && gatewayExtField.touched" class="item-error">Field is required</span>
            </div>
            <div *ngIf="extfields.input_type == 'select'">
              <label class="">{{extfields?.placeholder}}</label>
              <select class="form-control" [name]="extfields?.input_name" #gatewayExtField="ngModel"
                      [(ngModel)]="extFields[extfields?.input_name]" required>
                <option value="" disabled>None</option>
                <option *ngFor="let status of extfields?.input_options" value="{{status.value}}">{{status.name}}</option>
              </select>
              <span i18n="@@CreateGatewayErrorFieldisrequired"
                    *ngIf="gatewayExtField.invalid && gatewayExtField.touched" class="item-error">Field is required</span>
            </div>
          </div>
      </div>
      <div [hidden]="!gatewayNewForm.valid" class="form-group row create_gateway">
          <div class="col-md-12 col-xs-12">
            <button i18n="@@CreateGatewayCreategatewayBtn" type="submit" id="createGateway">Create gateway</button>
          </div>
        </div>
    </form>
  </div>
