import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-webhook-params',
  templateUrl: './confirm-webhook-params.component.html',
  styleUrls: ['./confirm-webhook-params.component.css']
})
export class ConfirmWebhookParamsComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
  }

  confirmModal () {
    this.dialogRef.close({confirm: true});
  }
}
