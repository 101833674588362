import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CmsDashboardService {

  constructor(private http: HttpClient) { }
  api_base_url = environment.api_base_url;
  setWebHook(data) {
    return this.http.post(this.api_base_url + '/api/cms-set-web-hook' , data, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  updateWebHook(data) {
    return this.http.post(this.api_base_url + '/api/cms-update-web-hook' , data, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  updateWebHookStatus(data) {
    return this.http.post(this.api_base_url + '/api/cms-update-web-hook-status' , data, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  getWebHookByEventId(event_id: number) {
    return this.http.get(this.api_base_url + '/api/get-web-hook/' + event_id, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  getWebHookById(id: number) {
    return this.http.get(this.api_base_url + '/api/get-web-hook-by-id/' + id, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }

  setExternalApi(data) {
    return this.http.post(this.api_base_url + '/api/cms-external-api' , data, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' })
    });
  }
}
