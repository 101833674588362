<div class="row">
  <div class="col-md-12 col-xs-12 c4">
    <p class="c16"><span class="c17">PRIVACY POLICY</span></p>
    <ol start="1" type="I">
        <li class="c14"><span class="c3">INTRODUCTION</span></li>
    </ol>
    <p class="c20"><span>This Get-In, Inc. Privacy Policy (the &ldquo;Privacy Policy&rdquo;) applies to your
            use, installation, download, exploitation, and/or access to the Services and/or the Platform, both as
            defined below, and any other websites [or applications] provided, owned, or operated by Get-In, Inc. (the
            &ldquo;Company&rdquo;). Company respects the right to privacy of Users of the Platform, and Company protects
            the personal data and applies appropriate organizational and technical solutions to prevent interference
            with the privacy of Users by third parties. By using, installing, downloading, exploiting, and/or accessing
            the Services and/or the Platform, you are accepting the practices described in this Privacy Policy, to the
            extent permitted by law. &nbsp;</span></p>
    <p class="c20"><span>You may share personal information when using the Services and/or the Platform. One
            example is when you provide information about yourself as part of the User Account creation process. Another
            is when you take certain actions on the Services and/or the Platform that are public or intended to be
            public in nature. Given the social nature of some of the Services, that information may be collected, used,
            or disclosed by others who are part of that social interaction; other Users and third parties may search
            for, see, use, or share any of your Content that you make publicly available through the Services and/or the
            Platform, consistent with the settings you choose in your account. Note that once you have shared Content or
            made it public, that Content may be re-shared by others. If you remove information that you posted to the
            Service and/or the Platform, copies may remain viewable in cached and archived pages of the Service and/or
            the Platform, or if other Users or third parties using the Service and/or the Platform have copied or saved
            that information. In addition, some features of the Services are designed to provide others with information
            about User&rsquo;s activity. We encourage you to be mindful of this when considering your activity on the
            Services and/or the Platform.</span></p>
    <p class="c20"><span class="c6">Storage and access to Cookies that are set in connection with the Services are
            governed by the Company Cookie Policy&nbsp;as set forth below (the &ldquo;</span><span
            class="c6">Cookie Policy</span><span>&rdquo;).</span></p>
    <ol start="2" type="I">
        <li class="c14"><span class="c3">SCOPE OF THE PRIVACY POLICY</span></li>
    </ol>
    <ol start="1" type="A">
        <li class="c10"><span>This Privacy Policy specifies and includes:</span></li>
    </ol>
    <ol start="1">
        <li class="c2"><span>The Personal Data Administrator and the Data Protection Officer of
                Company;</span></li>
        <li class="c2"><span>The services that require the User to provide personal data;</span>
        </li>
        <li class="c2"><span>The type of personal data processed on the Platform;</span></li>
        <li class="c2"><span>The purposes and grounds for the processing of personal data on the
                Platform;</span></li>
        <li class="c2"><span>The period of personal data processing by Company;</span></li>
        <li class="c2"><span>Privacy protection rules;</span></li>
        <li class="c2"><span>Rights of the Users with regards to the processing of personal data
                by Company;</span></li>
        <li class="c2"><span>Entities with whom personal data is made available by Company;
                and</span></li>
        <li class="c2"><span>Rules for the use of Cookies.</span></li>
    </ol>
    <ol start="3" type="I">
        <li class="c14"><span class="c3">SERVICES REQUIRING THE TRANSFER OF PERSONAL DATA BY THE USER</span>
        </li>
    </ol>
    <ol start="1" type="A">
        <li class="c10"><span>The User provides Company with personal data for the purpose
                of:</span></li>
    </ol>
    <ol start="1">
        <li class="c2"><span>Creating a User Account;</span></li>
        <li class="c2"><span>Purchase of Ticket(s);and</span></li>
    </ol>
    <ol start="4" type="I">
        <li class="c14"><span class="c3">DEFINITIONS</span></li>
    </ol>
    <p class="c20"><span>For the purposes of this Privacy Policy, all capitalized but undefined terms will
            have the following meanings:</span></p>
    <ol start="1" type="a">
        <li class="c10"><span class="c3">&ldquo;Affiliate(s)&rdquo;</span><span>&nbsp;shall refer
                to entities controlled by, controlling, or under common control with Company, including our owners,
                parent companies, subsidiaries, affiliated companies, officers, directors, suppliers, partners, agents,
                sponsors, advertisers, and employees, and includes (without limitation) all parties involved in
                creating, producing, and/or delivering this Site and/or its Services.</span></li>
    </ol>
    <ol start="1" type="A">
        <li class="c10"><span class="c3">&ldquo;Company,&rdquo; &ldquo;we,&rdquo;</span><span
               >&nbsp;</span><span class="c3">&ldquo;us,&rdquo; </span><span>and</span><span
                class="c3">&nbsp;&ldquo;our&rdquo;</span><span>&nbsp;shall include references to Get-In,
                Inc., a Florida corporation, with its principal place of business located at 1100 Brickell Bay Drive,
                #81D, Miami, Florida, 33131. </span></li>
        <li class="c10"><span class="c3">&ldquo;Content&rdquo;</span><span>&nbsp;shall include
                any documents, information, communication, advertisement, software, photo, video, graphics (including
                illustrations, images, drawings, logos), music, sound, text, and other material and service that can be
                viewed by Users on the Site.&nbsp;</span></li>
        <li class="c10"><span class="c3">&ldquo;Device&rdquo;</span><span>&nbsp;shall include
                references to an electronic device (</span><span class="c6">e.g., </span><span
               >personal computer or mobile device) enabling the User to access the Platform or Services or
                other communications with the Company or its Affiliates. &nbsp;</span></li>
        <li class="c10"><span class="c3">&ldquo;Event&rdquo;</span><span>&nbsp;shall include a
                private or public cultural, educational, business, sporting, or other event produced or organized by a
                Promoter.</span></li>
        <li class="c10"><span class="c3">&ldquo;IP Address&rdquo;</span><span>&nbsp;shall mean an
                individual number assigned to the Device, used to uniquely identify the Device in the internal network
                (local address, internal address) or on the Internet (public address, external address).</span></li>
        <li class="c10"><span class="c3">&ldquo;Metadata&rdquo;</span><span>&nbsp;shall include
                technical data that is associated with User Content, which can, among others, describe how, when, and by
                whom a piece of Content was created, collected, modified, and formatted.</span></li>
        <li class="c10"><span class="c3">&ldquo;Platform&rdquo;</span><span>&nbsp;shall refer to
                the Company&rsquo;s online ticketing and Services portal. </span></li>
        <li class="c10"><span class="c3">&ldquo;Promoter(s)&rdquo;</span><span>&nbsp;shall
                include any User utilizing the Site to produce or organize an Event (defined above), as well as to
                collect a payment or transfer information or advertisements to other Users.</span></li>
        <li class="c10"><span class="c3">&ldquo;Service(s)&rdquo;</span><span>&nbsp;shall include
                services available, but not limited, to Users, and shall include sharing information, advertising,
                making purchases, making payments, and collecting payments, as well as providing a platform for Users to
                share Content to the Site and publicly. </span></li>
        <li class="c10"><span class="c3">&ldquo;Site&rdquo;</span><span>&nbsp;shall include
                references to </span><span class="c6 c8"><a
                style="color: #fff;"  href="https://www.google.com/url?q=https://www.get-in.com/&amp;sa=D&amp;source=editors&amp;ust=1629767815515000&amp;usg=AOvVaw2zP6XatSGBjSHSxNLgF55y">https://www.get-in.com/</a></span><span
               >. </span></li>
        <li class="c10"><span class="c3">&ldquo;Ticket(s)&rdquo;</span><span>&nbsp;shall refer to
                a valid ticket sold by a Promoter and purchased by a User to attend or participate in an Event produced
                or organized by a Promoter.</span></li>
        <li class="c10"><span class="c3">&ldquo;User(s)&rdquo;</span><span>&nbsp;shall include
                references to any natural person(s) using the Services and/or the Platform offered by Company. Use can
                include, but is not limited to: reading, browsing, or viewing Content; ordering services, goods, or
                Ticket(s) (defined above); making or collecting a payment; or transferring information.</span></li>
        <li class="c10"><span class="c3">&ldquo;User Account&rdquo;</span><span>&nbsp;shall
                include references to an individual User account placed on the Platform, created in the manner specified
                and which enables access to the selected Services. </span></li>
        <li class="c10"><span class="c3">&ldquo;You,&rdquo; &ldquo;your,&rdquo;</span><span
               >&nbsp;and </span><span class="c3">&ldquo;yourself&rdquo;</span><span>&nbsp;shall
                include references to the party reading, acknowledging, and agreeing to these terms, including
                User.</span></li>
    </ol>
    <ol start="5" type="I">
        <li class="c14"><span class="c3">INFORMATION COLLECTED BY COMPANY</span></li>
    </ol>
    <p class="c20"><span>Company obtains information about you through the means discussed below when Company
            provides the Services and/or you use the Platform. In order to provide the Services and/or access to the
            Platform, Company requires certain types of information. &nbsp;If you do not provide us with such
            information, or ask us to delete it, you may no longer be able to access or use the Services and/or the
            Platform.</span></p>
    <ol start="1" type="A">
        <li class="c10"><span class="c3">Information User Provides to Company Directly: </span><span
               >You may provide a variety of information about yourself to us, such as your name, age,
                address, e-mail address, phone number, country in which the Services will be accessed, image, likeness,
                nickname, User Account username and password, and payment information when you register for the Services
                and/or the Platform, or when you purchase Ticket(s).</span></li>
        <li class="c10"><span class="c3">Information User Provides or Includes in Content: </span><span
               >You have choices about the information to include in your Content, such as your education,
                work experience, skills, hobbies, likes, and dislikes, as well as Metadata associated with Content. The
                information in your Content helps you to get more from our Service, including helping Organizers find
                you. It is your choice whether to include sensitive information on your Content. Please do not post or
                add personal data to your Content that you would not want to be publicly available.</span></li>
        <li class="c10"><span class="c3">Automatically Collected Information:</span><span
               >&nbsp;When you access the Services and/or the Platform, or open one of our e-mails, Company
                automatically records and stores certain information about your system by using Cookies and other types
                of technologies (now existing or hereinafter created), like pixels, web beacons, and local storage.
                Cookies are small text files containing a string of alphanumeric characters that are sent to your
                browser. For information about what Cookies are, how they work, how Company uses them, and how to remove
                them, please see Company&rsquo;s Cookie Policy (as set forth below). &nbsp;Examples of such information
                we automatically collect include IP Addresses, a unique user ID, Device and browser types and
                identifiers, referring and exit page addresses, software and system type, and information about your
                usage of the Services, such as, but not limited to, number of clicks and how you interact with links on
                the Service and/or the Platform, domain names, landing pages, and pages viewed. Examples of how Company
                uses automatically collected information may include to: (a) automatically update the Company
                application on your system; (b) remember your information so that you will not have to re-enter it
                during your visit or the next time you access the Services; (c) provide customized advertisements,
                content, and information; (d) monitor the effectiveness of marketing campaigns; (e) monitor and store
                aggregate site usage metrics such as total number of visitors and pages accessed; and (f) track your
                entries, submissions, and status in any promotions or other activities.</span></li>
        <li class="c10"><span class="c3">Information from Other Sources:</span><span>&nbsp;We may
                obtain additional information from third parties and sources other than through the Services and/or the
                Platform. For example, we may obtain additional information from advertisers or services you use or from
                social media networks for which you have approved our access. &nbsp;When you access the Services and/or
                the Platform through social media networks, or when you connect the Services to social media networks,
                you are authorizing Company</span><span class="c3">&nbsp;</span><span>to collect, store, and
                use such additional information and content in accordance with this Privacy Policy. Company</span><span
                class="c3">&nbsp;</span><span>may also obtain information from third-party services regarding
                your use of such services. We use this information to supplement the information we collect about you in
                order to provide more relevant, safer experiences for you within the Services and/or the Platform and
                improve the Services and/or the Platform, analytics, and advertising. If we combine or associate
                information from other sources with information that we collect through the Services and/or the
                Platform, we will treat the combined information in accordance with this Privacy Policy.</span></li>
    </ol>
    <ol start="6" type="I">
        <li class="c14"><span class="c3">HOW COMPANY USES YOUR INFORMATION</span></li>
    </ol>
    <ol start="1" type="A">
        <li class="c10"><span>Company</span><span class="c3">&nbsp;</span><span>uses
                such information to operate, maintain, enhance, provide, create, and develop all of the features,
                functionality, and services (new or existing) found on and/or in the Services; provide security for our
                websites, products, software, or applications; manage relationships with Users, including making or
                receiving payment; improve Users&rsquo; experience with the Services and/or the Platform by providing
                content recommendations and by delivering content that Company</span><span class="c3">&nbsp;</span><span
               >hopes Users will find relevant and interesting, including advertising and marketing messages;
                allow you to participate in the Services; prevent fraud and abuse; and understand the usage trends of
                Company&rsquo;s Users, among others.</span></li>
        <li class="c10"><span>Company uses your e-mail address and/or telephone number to
                communicate with you, including to notify you of major updates to the Services and/or the Platform, for
                customer service purposes, or to contact you regarding any content that you have posted to or downloaded
                from the Services and/or Platform. If you want to stop receiving notifications by telephone, reply STOP
                to any text message from us. Note that we may still send you additional informational or transactional
                notifications.</span></li>
        <li class="c10"><span>Company may periodically send promotional materials or
                notifications to you related to the Services. If you want to stop receiving promotional materials, you
                can go to your account settings once you have registered and logged in to the Services and update your
                preferences to unsubscribe to promotional materials. Note that you may not opt out of service-related
                e-mails.</span></li>
        <li class="c10"><span>Company may also ask for your consent to process your personal
                information for a specific purpose that we communicate to you. When you consent to Company</span><span
                class="c3">&nbsp;</span><span>processing your personal information for a specified purpose,
                you may withdraw your consent at any time, and we will stop the processing of your data for that
                purpose.</span></li>
        <li class="c10"><span>Company will rely on legal grounds to collect and process your
                personal information to the extent permitted by applicable law, which may include, without limitation:
                to honor contractual commitments, to take steps in anticipation of entering into contract, to fulfill
                legal obligations, your consent, and Company&rsquo;s legitimate interests.</span></li>
    </ol>
    <ol start="7" type="I">
        <li class="c14"><span class="c3">PURPOSE AND GROUNDS FOR PROCESSING PERSONAL DATA</span></li>
    </ol>
    <ol start="1" type="A">
        <li class="c10"><span class="c4">The provision by </span><span>the</span><span
                class="c4">&nbsp;</span><span>User</span><span class="c4">&nbsp;of the personal data
                referred to in this Privacy Policy </span><span>shall be made</span><span
                class="c4">&nbsp;with the consent of the User.</span><span>&nbsp; </span><span
                class="c4">Refusal to provide such data by the User will result in the refusal to provide certain
                Services by Company.</span></li>
        <li class="c10"><span class="c4">User must consent to the processing of personal data
                when:</span></li>
    </ol>
    <ol start="1">
        <li class="c2"><span>When setting up a User Account on the Platform; and</span></li>
        <li class="c2"><span>When purchasing Ticket(s).</span></li>
    </ol>
    <ol start="3">
        <li class="c10"><span>By checking the appropriate box on the website, the User: </span>
        </li>
    </ol>
    <ol start="1">
        <li class="c2"><span>Consents to the processing of his/her personal data; and</span></li>
        <li class="c2"><span>Confirms that the User has read and accepted this Privacy
                Policy.</span></li>
    </ol>
    <ol start="4">
        <li class="c10"><span>Company processes the personal </span><span
                class="c4">data</span><span>&nbsp;in order</span><span class="c3">&nbsp;</span><span
                class="c4">to </span><span>provide the Services. Such processing is in particular aimed at
                fulfilling all or selected Services to the User, handling complaints, withdrawing from the contract of
                service and communication with Users who have made a complaint or made a declaration of withdrawal from
                the contract for the provision of Services, as well as ensuring the security of the Services by
                verifying the identity of the person logging into the User Account.</span></li>
        <li class="c10"><span>In addition, Company processes the personal data referred to in
                this Privacy Policy in order to pursue the legitimate interests of Company, such as:</span></li>
    </ol>
    <ol start="1">
        <li class="c2"><span>Conducting marketing by Company and Promoters; </span></li>
        <li class="c2"><span>Promotion of Services of Company;</span></li>
        <li class="c2"><span>Tailoring the Services to the needs and convenience of Users,
                including personalizing content on the Services;</span></li>
        <li class="c2"><span>Statistical measurements and improvement of the Services; and</span>
        </li>
        <li class="c2"><span>other legitimate interests, whether now existing or hereinafter
                created.</span></li>
    </ol>
    <ol start="6">
        <li class="c10"><span>Company processes the personal data of persons who have submitted
                an inquiry to Company using the contact form, with the consent of that person, to the extent necessary
                in order to respond. We may monitor or record your requests and our responses for quality control
                purposes.</span></li>
    </ol>
    <ol start="8" type="I">
        <li class="c14"><span class="c3">DISCLOSURE OF USER INFORMATION</span></li>
    </ol>
    <p class="c20"><span>Company shares such information as described below:</span></p>
    <ol start="1" type="A">
        <li class="c10"><span>Company discloses information to service providers working on
                Company&rsquo;s</span><span class="c3">&nbsp;</span><span>behalf, in order to: provide
                website hosting, maintenance, and security services; fulfill orders; conduct data analysis and create
                reports; offer certain functionality; or assist Company</span><span class="c3">&nbsp;</span><span
               >in improving the Services and/or Platform and creating new services and features, among
                others. Company requires that these parties process such information in compliance with this Privacy
                Policy and Company authorizes them to use the information only for the purposes for which it is provided
                to them, and Company requires them to use reasonable confidentiality measures.</span></li>
        <li class="c10"><span>Company</span><span class="c3">&nbsp;</span><span>may
                make information and Content available to Company&rsquo;s Affiliates that are either subject to this
                Privacy Policy or follow practices at least as protective as those described in this Privacy
                Policy.</span></li>
        <li class="c10"><span>Company may share information and Content with Promoters if you:
                have visited the page of a Promoter&rsquo;s Event; have ordered or purchased a Ticket or other product
                to a Promoter&rsquo;s Event; or have attended or participated in a Promoter&rsquo;s Event. Promoters may
                use this information to, among other things, share information with you concerning other Events that is
                believed to be of interest to you. </span></li>
        <li class="c10"><span>Company</span><span class="c3">&nbsp;</span><span>may
                disclose User&rsquo;s information if Company believes in good faith that such disclosure is necessary to
                comply with any local, state, or federal laws or other applicable laws around the world (for example, in
                the country of User&rsquo;s residence), or to respond to a court order, judicial or other government
                request, subpoena, or warrant in the manner legally required.</span></li>
        <li class="c10"><span>Company also reserves the right to disclose information that
                Company believes, in good faith, is appropriate or necessary to protect Company from potential liability
                or from fraudulent, abusive, or unlawful uses; investigate and defend ourselves against third-party
                claims, or allegations; protect the security or integrity of the Services and/or the Platform; or
                protect the rights, property, or safety of Company, our users, or others.</span></li>
        <li class="c10"><span>Other than as set out in this Privacy Policy, a User will
                receive notice when personal information about a User might be shared with third parties and the User
                will have an opportunity to choose not to share such information.</span></li>
    </ol>
    <ol start="9" type="I">
        <li class="c14"><span class="c3">ENTITIES TO WHOM PERSONAL DATA ARE MADE AVAILABLE BY COMPANY</span>
        </li>
    </ol>
    <ol start="1" type="A">
        <li class="c10">
            <h4 style="display:inline"><span>Company may transfer or disclose the User&rsquo;s personal data
                    to entities processing personal data on the User&rsquo;s behalf, in particular to IT service
                    providers, service technicians, or marketing agencies (&ldquo;Processors&rdquo;). </span></h4>
        </li>
        <li class="c10">
            <h4 style="display:inline"><span>The transfer or disclosure of the User&#39;s personal data takes
                    place on the basis of an agreement, to entrust the processing of personal data, between Company and
                    Processors.</span></h4>
        </li>
        <li class="c10">
            <h4 style="display:inline"><span>Agreement on entrusting the processing of personal data in
                    particular:</span></h4>
        </li>
    </ol>
    <ol start="1">
        <li class="c2">
            <h4 style="display:inline"><span>Obligates processors to ensure the security and confidentiality
                    of the User&rsquo;s personal data provided or shared; and</span></h4>
        </li>
        <li class="c2">
            <h4 style="display:inline"><span>Entitles Company to verify the application by processors of
                    appropriate security measures for the User&#39;s personal data, provided or disclosed by
                    Company.</span></h4>
        </li>
    </ol>
    <ol start="4">
        <li class="c10">
            <h4 style="display:inline"><span>Company has a legal obligation to transmit or disclose the
                    User&rsquo;s personal data at the request of authorized state authorities, in particular law
                    enforcement authorities.</span></h4>
        </li>
        <li class="c10">
            <h4 style="display:inline"><span>The User&rsquo;s personal data may be made available to third
                    parties, based on the legitimate interest of Company or on the basis of the User&rsquo;s voluntary
                    consent to their processing. Such data shall include in particular:</span></h4>
        </li>
    </ol>
    <ol start="1">
        <li class="c2">
            <h4 style="display:inline"><span>Information stored in Cookies on the Device and its cache
                    (including information shared in the browsing history and data collected during activity on the
                    User&#39;s services); and</span></h4>
        </li>
        <li class="c2">
            <h4 style="display:inline"><span>Location data generated by the User&rsquo;s Device.</span></h4>
        </li>
    </ol>
    <ol start="6">
        <li class="c10">
            <h4 style="display:inline"><span>The data referred to in this Privacy Policy may be made
                    available to third parties for marketing purposes, including:</span></h4>
        </li>
    </ol>
    <ol start="1">
        <li class="c2">
            <h4 style="display:inline"><span>Automated analysis of user activity on websites and applications
                    in order to determine the potential interests of the User in order to customize advertising;
                    and</span></h4>
        </li>
        <li class="c2">
            <h4 style="display:inline"><span>Placing internet tags (e.g., Cookies or Metadata) on devices and
                    reading such tags.</span></h4>
        </li>
    </ol>
    <ol start="7">
        <li class="c10">
            <h4 style="display:inline"><span>Prior to the transfer of personal data to third parties
                    established outside the European Union, Company each time examines, in accordance with Article 46(2)
                    of the GDPR, whether third parties provide an adequate guarantee of the protection of personal
                    data.</span></h4>
        </li>
        <li class="c10">
            <h4 style="display:inline"><span>The User has the right to obtain from Company a copy of the
                    standard contractual clauses concluded by Company with third parties for the transfer or protection
                    of personal data.</span></h4>
        </li>
    </ol>
    <ol start="10" type="I">
        <li class="c14"><span class="c3">YOUR CHOICES ABOUT YOUR INFORMATION</span></li>
    </ol>
    <ol start="1" type="A">
        <li class="c10"><span>You may update your User Account at any time by logging in and
                changing your account settings. Additionally, you may delete or cancel your User Account at any
                time.</span></li>
        <li class="c10"><span>You may unsubscribe from e-mail communications from us by clicking
                on the &ldquo;unsubscribe link&rdquo; provided in such communications. As noted above, you may not opt
                out of Service-related communications, including: account verification; purchase and billing
                confirmations and reminders; changes or updates to features of the Service; and technical and security
                notices.</span></li>
        <li class="c10"><span>Following termination or deactivation of your account, Company, its
                Affiliates, or Processors may retain information (including your account information) and Content for a
                commercially reasonable time for backup, archival, and/or audit purposes.</span></li>
    </ol>
    <ol start="11" type="I">
        <li class="c14"><span class="c3">THIRD-PARTY SERVICES AND WEBSITES</span></li>
    </ol>
    <ol start="1" type="A">
        <li class="c10"><span>The Services and/or the Platform may link to third-party websites
                or services. The privacy practices of those third parties are not governed by this Privacy Policy. We
                encourage you to review the privacy policies of these third-party websites and services to understand
                their practices.</span></li>
        <li class="c10"><span>If you connect to a third-party service (such as Facebook,
                Instagram, LinkedIn, Google, PayPal, or another third-party application) through the Services and/or the
                Platform or otherwise link your User Account with a third-party service, you are requesting and
                authorizing us to share or grant access to information on your behalf (such as your username, the fact
                that your connection originated from the Services and/or the Platform, and other relevant usage and
                diagnostic information) with such third party. We may also send information about the content you watch
                or your activities on the Services and/or Platform to such third parties.</span></li>
    </ol>
    <ol start="12" type="I">
        <li class="c14"><span class="c3">ADVERTISERS AND ANALYTICS PROVIDERS</span></li>
    </ol>
    <ol start="1" type="A">
        <li class="c7"><span>We may use third-party analytics tools to help us measure traffic
                and usage trends for the Service. These tools collect information sent by your device or our Service,
                including the web pages you visit, add-ons, and other information that assists us in improving the
                Service. We collect and use this analytics information with analytics information from other Users so
                that it cannot reasonably be used to identify any particular individual User.</span></li>
        <li class="c7"><span>We may use other services hosted by third parties to assist in
                providing our Service and to help us understand our Users&rsquo; use of our Service. These third-party
                services may collect information sent by your device when you use the Service, including Internet
                Protocol (&ldquo;IP&rdquo;) address, type of device, operating system version, unique or persistent
                identifiers associated with your device (such as UDID, MAC address, IFA, or identifiers derived from
                your Device&rsquo;s technical information), network information, technical characteristics of your
                device or its software, language settings, and usage information relating to your use of the
                Service.</span></li>
    </ol>
    <ol start="13" type="I">
        <li class="c14"><span class="c3">PRIVACY PROTECTION RULES</span></li>
    </ol>
    <p class="c20"><span>The Platform may contain external links enabling the User to directly access other
            third-party websites. &nbsp;The collection of Users&rsquo; personal data is treated as a database with a
            high degree of security, stored on a server protected against both remote and physical access. &nbsp;Company
            applies appropriate technical and organizational measures to ensure a degree of security corresponding to
            the risk of violation of Users&rsquo; rights and freedoms. &nbsp;However, no security precautions or systems
            can be completely secure. &nbsp;Company cannot ensure or warrant the security of any information you
            transmit to Company, and you do so at your own risk. &nbsp;Company cannot guarantee that such information
            may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or
            managerial safeguards. &nbsp;All persons processing personal data in Company have the appropriate
            authorizations issued by the administrator of personal data in accordance with Article 29 of the General
            Data Protection Regulation.</span></p>
    <ol start="14" type="I">
        <li class="c14"><span class="c3">TIME OF PROCESSING OF PERSONAL DATA</span></li>
    </ol>
    <ol start="1" type="A">
        <li class="c10"><span>Company processes the User&rsquo;s personal data until there is a
                basis not to do so, which includes:</span></li>
    </ol>
    <ol start="1">
        <li class="c2"><span>If the User grants consent, when the consent has been revoked,
                restricted or other actions have been taken on the part of the User to limit the consent;</span></li>
        <li class="c2"><span>If there is a necessity to perform a contract, for the duration of
                the term of the contract and until the expiration of the statute of limitations for claims under such
                contract.</span></li>
        <li class="c2"><span>If the processing is based on a legitimate interest of the
                administrator, then until the User objects effectively; and</span></li>
        <li class="c2"><span>If based on tax and accounting purposes, until such time and to the
                extent in accordance with applicable regulations.</span></li>
    </ol>
    <ol start="15" type="I">
        <li class="c14"><span class="c3">CHILDREN&rsquo;S PRIVACY</span></li>
    </ol>
    <p class="c20"><span>Company does not knowingly collect or solicit any information from anyone under the
            age of 13, or anyone under the age of 18 who has not received permission from a parent or legal guardian to
            use the Service and/or the Platform. Company does not knowingly allow such persons to register for the
            Service and/or the Platform. The Service and/or the Platform and its content are not directed at children
            under the age of 13, or to children under the age of 18 who have not received permission from a parent or
            legal guardian to use the Service and/or the Platform.&nbsp; In the event that we learn that we have
            collected personal information from a child under age 13, or a child under the age of 18 who has not
            received permission from a parent or legal guardian to use the Service, we will delete that information as
            quickly as possible. If you are a parent or legal guardian and think either that your child under 13 has
            given us information, or that your child under 18 has used the Service and given us information without your
            permission, please contact us at:&nbsp;support@get-in.com.</span></p>
    <ol start="16" type="I">
        <li class="c14"><span class="c3">INTERNATIONAL DATA TRANSFERS</span></li>
    </ol>
    <ol start="1" type="A">
        <li class="c7"><span>Your information collected through the Service and/or the Platform
                may be stored and processed in the United States or any other country in which Company, its Affiliates,
                or Processors maintain facilities.</span></li>
        <li class="c7"><span>Company, its Affiliates, or Processors may transfer information that
                we collect about you, including personal information across borders and from your country or
                jurisdiction to other countries or jurisdictions around the world. If you are located in the European
                Union or other regions with laws governing data collection and use that may differ from United States
                law, please note that we may transfer information, including personal information, to a country and
                jurisdiction that does not have the same data protection laws as your jurisdiction. By registering for
                and using the Service you consent to the transfer of information to the United States or to any other
                country in which Company, its Affiliates, or Processors maintain facilities and the use and disclosure
                of information about you as described in this Privacy Policy.</span></li>
    </ol>
    <ol start="17" type="I">
        <li class="c14"><span class="c3">COOKIE POLICY</span></li>
    </ol>
    <p class="c20"><span class="c6">This Cookie Policy applies to your use of the Services and/or the Platform, and
            any other websites, applications, or services provided by Company that link to this Cookie Policy. Company
            uses certain technologies to collect information through the Services and/or the Platform.
            Company</span><span class="c17">&nbsp;</span><span>may change this Cookie Policy at any time due
            to changes or additional requirements of applicable laws.</span></p>
    <p class="c20"><span class="c6">By using, installing, downloading, exploiting, and/or accessing the Services
            and/or the Platform, or by setting up a User Account, you are accepting the information handling practices
            and storage and access of Cookies as described in this Cookie Policy.</span></p>
    <ol start="1" type="A">
        <li class="c10"><span class="c3">Cookies, defined:</span><span
               >&nbsp;&ldquo;Cookies&rdquo; are small text files that are stored on your Device. They are
                widely used in order to make websites and software applications work, or to work in a better, more
                efficient way. They can do this because websites and software applications can read and write these
                files, enabling them to recognize a particular device and remember important information that will make
                your use of a website or software application more convenient (e.g., by remembering your user
                preferences).</span></li>
        <li class="c10"><span class="c3">Use of Cookies.</span><span>&nbsp;Company uses Cookies
                stored on the User&rsquo;s Device as referred to in art. 173 of </span><span class="c6 c13">the
                Act of July 16, 2004 Telecommunications Law </span><span>for the following purposes:</span>
        </li>
        <li class="c10"><span class="c3">Types of Cookies.</span><span>&nbsp;Company uses the
                following types of Cookies:</span></li>
    </ol>
    <ol start="1">
        <li class="c2"><span>To make it easier for the User to use the Services and/or
                Platform;</span></li>
        <li class="c2"><span>To ensure the User&rsquo;s subsequent association with the Device
                when reconnecting to the Services and/or Platform;</span></li>
        <li class="c2"><span>To create statistics that assist Company in understanding how Users
                use the Services and/or Platform and to improve the structure and content;</span></li>
        <li class="c2"><span>To adapt the content of the Services and/or Platform to the
                User&rsquo;s specific preferences and to optimize the use of the Services and/or Platform to the
                User&rsquo;s individual needs; and</span></li>
        <li class="c2"><span>Other for other legitimate business purposes.</span></li>
    </ol>
    <ol start="4">
        <li class="c10"><span class="c3">Collection and Storage of Cookies.</span></li>
    </ol>
    <ol start="1">
        <li class="c2"><span>Company stores information about http queries directed by Users to
                the server on which the Platform is hosted.</span></li>
        <li class="c2"><span>The browsed resources are identified by URL addresses and relate to
                the following information: </span></li>
    </ol>
    <ol start="1">
        <li class="c11"><span>The public IP address of the terminal equipment from which the
                query came;</span></li>
        <li class="c11"><span>The User&rsquo;s station name, which identification is carried out
                by http protocol;</span></li>
        <li class="c11"><span>The User&rsquo;s name provided in the authorization process;</span>
        </li>
        <li class="c11"><span>Time of the inquiry;</span></li>
        <li class="c11"><span>The first line of the http request;</span></li>
        <li class="c11"><span>The http response code;</span></li>
        <li class="c11"><span>The amount of data sent by the server;</span></li>
        <li class="c11"><span>The URL of the page previously visited by the User, provided that
                the transition to the Platform was made by an external link;</span></li>
        <li class="c11"><span>Information about the User&rsquo;s browser; and</span></li>
        <li class="c11"><span>Information about errors that occurred during the execution of the
                http transaction.</span></li>
    </ol>
    <ol start="3">
        <li class="c2"><span>Company, through its Platform, may automatically collect any
                information contained in Cookies. The information collected in this way is used, for example, to:</span>
        </li>
    </ol>
    <ol start="1">
        <li class="c11"><span>Manage the Platform;</span></li>
        <li class="c11"><span>Identify possible security risks;</span></li>
        <li class="c11"><span>Research User traffic on the Platform for statistical purposes,
                including by using Google Analytics and Get Response tools, to the extent that mailing campaigns are
                additionally carried out; and</span></li>
        <li class="c11"><span>For other legitimate business purposes. </span></li>
    </ol>
    <ol start="4">
        <li class="c2"><span>The information obtained from Cookies is in no way combined with the
                User&rsquo;s personal data and is not used to determine the identity of the User.</span></li>
        <li class="c2"><span>The scope of information automatically obtained from cookies by
                Company depends on the settings of the User&#39;s web browser. The User should check the browser
                settings of their Device to find out what information is automatically provided by the browser or to
                change those settings. To do this, it is recommended to read the &ldquo;Help&rdquo; content of the web
                browser used.</span></li>
        <li class="c2"><span>Software for browsing websites, i.e., a web browser, usually allows
                cookies to be stored on your Device by default. Users can change the settings.&nbsp;The web browser
                allows you to delete Cookies. It is also possible to automatically block Cookies. &nbsp;To do this, it
                is recommended to read the &ldquo;Help&rdquo; content of the web browser used.</span></li>
        <li class="c2"><span>If the User does not wish to receive cookies, he/she may change
                browser settings. However, disabling Cookies necessary for authentication, security, or maintenance
                processes may make it difficult for, and, in extreme cases, also prevent the User from, logging into the
                system or using the services offered by Company.</span></li>
        <li class="c2"><span class="c4">When using the Services and/or the Platform, Cookies may also be
                placed on the Device in order to enable the use of the functionalities of the Platform, which are
                integrated with services offered by other providers, in particular, </span><span>Facebook,
                Instagram, LinkedIn, Google, PayPal, or another third-party application.</span><span
                class="c4">&nbsp;Each provider sets out the rules for the use of Cookies in its privacy policy, which
                is not affected by </span><span>Company. </span><span class="c4">For security </span><span
               >reasons, Company recommends that each User read the privacy policy and the use of Cookies
                before using the resources offered</span><span class="c4">&nbsp;by other websites or services.</span>
        </li>
    </ol>
    <ol start="18" type="I">
        <li class="c14"><span class="c3">CHANGES AND UPDATES TO THIS PRIVACY POLICY</span></li>
    </ol>
    <ol start="1" type="A">
        <li class="c10"><span>Company reserves the right to change, modify, add, or remove
                portions of this Privacy Policy at any time (for example to reflect updates to the Services or to
                reflect changes in the law). Please check this Privacy Policy periodically for those changes. Your
                continued use of the Services after the posting of changes constitutes your binding acceptance of such
                changes.</span></li>
        <li class="c10"><span>We will not materially change our policies and practices to make
                them less protective of personal information collected in the past under a prior version of the Privacy
                Policy without the consent of affected individuals.</span></li>
    </ol>
    <ol start="19" type="I">
        <li class="c14"><span class="c3">PERSONAL DATA ADMINISTRATOR AND DATA PROTECTION OFFICER</span></li>
    </ol>
    <ol start="1">
        <li class="c10"><span>The Personal Data Administrator, determining the purposes and
                methods of processing User&rsquo;s personal data on the Platform, is: </span></li>
    </ol>
    <p style="text-align: center;"><span>Neev Ariel, President<br>Get In Inc., a Florida corporation with principal offices
            located at <br>1100 Brickell Bay Drive, Suite 81D, Miami, Florida 33131.</span></p>
    <ol start="2">
        <li class="c10"><span>In Company, the position of the Data Protection Officer has been
                appointed to ensure easy contact between the User and Company for all matters related to the processing
                of User&rsquo;s personal data, including the exercise of their rights related to the processing of their
                personal data on the Platform.</span></li>
    </ol>
    <ol start="1">
        <li class="c2"><span>The Data Protection Officer is:</span></li>
    </ol>
    <p class="c16"><span>Neev Ariel, President<br>Get In Inc., a Florida corporation with principal offices
            located at <br>1100 Brickell Bay Drive, Suite 81D, Miami, Florida 33131.</span></p>
    <ol start="20" type="I">
        <li class="c14"><span class="c3">GENERAL DATA PROTECTION REGULATION AND USERS&rsquo; RIGHTS</span>
        </li>
    </ol>
    <ol start="1" type="A">
        <li class="c10">
            <h4 style="display:inline"><span class="c6">All Users&rsquo; personal data is collected and processed in
                    accordance with the provisions of Regulation </span><span class="c6 c13">(EU) 2016/679 of the
                    European Parliament and of the Council of 27 April 2016 on the protection of individuals
                </span><span class="c6">with</span><span class="c6 c13">&nbsp;</span><span class="c6">regard to the
                    processing of personal data and on the free movement of such data and on the repeal of Directive
                    95/46/EC </span><span class="c6 c13">(General Data Protection Regulation (GDPR) </span><span
                    class="c6">and the Act of 18 July </span><span class="c6 c13">2002 on the provision of electronic
                    services.</span></h4>
        </li>
        <li class="c10">
            <h4 style="display:inline"><span class="c6">In connection with the processing of the User&rsquo;s personal
                    data - in accordance with the provisions of </span><span class="c6 c13">Regulation (EU) 2016/679 of
                    the European Parliament and of the Council of 27 April 2016 on the protection of individuals with
                    regard to the processing of personal data and on the free movement of such data</span><span
                    class="c6">&nbsp;</span><span class="c6 c13">and the repeal of Directive</span><span
                   >&nbsp;95/46/EC - (GDPR), the User has the following rights:</span></h4>
        </li>
    </ol>
    <ol start="1">
        <li class="c2">
            <h4 style="display:inline"><span>The right to access to his/her personal data, including
                    obtaining a copy of it;</span></h4>
        </li>
        <li class="c2">
            <h4 style="display:inline"><span>The right to request rectification of his/her personal
                    data;</span></h4>
        </li>
        <li class="c2">
            <h4 style="display:inline"><span>The right to erasure of his/her data;</span></h4>
        </li>
        <li class="c2">
            <h4 style="display:inline"><span>The right to lodge a complaint with the supervisory authority
                    for the protection of personal data;</span></h4>
        </li>
        <li class="c2">
            <h4 style="display:inline"><span>The right to restrict the processing of his/her personal
                    data;</span></h4>
        </li>
        <li class="c2">
            <h4 style="display:inline"><span>The right to object to:</span></h4>
        </li>
    </ol>
    <ol start="1">
        <li class="c11">
            <h4 style="display:inline"><span>Processing of his/her personal data;</span></h4>
        </li>
        <li class="c11">
            <h4 style="display:inline"><span>Direct marketing, including profiling; and</span></h4>
        </li>
        <li class="c11">
            <h4 style="display:inline"><span>Processing of his/her personal data in the legitimate interest
                    of the Administrator.</span></h4>
        </li>
    </ol>
    <ol start="7">
        <li class="c2">
            <h4 style="display:inline"><span>If personal data is processed on the basis of the User&#39;s
                    consent, the User has the right to withdraw consent. Withdrawal of consent does not affect the
                    lawfulness of processing based on consent before its withdrawal.</span></h4>
        </li>
        <li class="c2">
            <h4 style="display:inline"><span>If personal data is processed on the basis of the User&#39;s
                    consent or for the performance of the contract, the User has the right to transfer personal
                    data.</span></h4>
        </li>
        <li class="c2">
            <h4 style="display:inline"><span>In order to exercise the rights referred to in paragraphs 2 and
                    3, the User should contact Company via the application form located on the Site under &ldquo;Contact
                    us.&rdquo;</span></h4>
        </li>
    </ol>
    <ol start="21" type="I">
        <li class="c14"><span class="c3">CALIFORNIA PRIVACY DISCLOSURE</span></li>
    </ol>
    <p class="c20"><span>The Privacy Policy&nbsp;describes the personal information that we collect, the
            sources from which we collect it, the purposes for which we use it, the limited circumstances under which we
            share personal information, and with whom we share it. These additional disclosures are required by the
            California Consumer Privacy Act and the California &ldquo;Shine the Light&rdquo; Law.</span></p>
    <ol start="1" type="A">
        <li class="c7"><span class="c3">Categories of Personal Information Collected</span></li>
    </ol>
    <p class="c5"><span>The personal information that Company collects, or has collected, from consumers in
            the twelve months prior to the effective date of this California Notice, fall into the following categories
            established by the California Consumer Privacy Act, depending on which Services are used:</span></p>
    <ol start="1">
        <li class="c2"><span>Identifiers such as your name, alias, E-mail address, phone number,
                or IP address;</span></li>
        <li class="c2"><span>Personal information, such as credit card number;</span></li>
        <li class="c2"><span>Age or other protected classifications, for example as collected on
                sign-up and disclosed in your user-generated content;</span></li>
        <li class="c2"><span>Commercial information, such as purchase and content streaming
                activity;</span></li>
        <li class="c2"><span>Internet or other electronic network activity information, including
                content interaction information, browsing history, and search history;</span></li>
        <li class="c2"><span>Audio or visual information, such as video and audio recordings if
                you upload user-generated content;</span></li>
        <li class="c2"><span>Professional information, for example data related to your channel
                if you stream on Company; and</span></li>
        <li class="c2"><span>Inference data, such as information about your purchase and viewing
                preferences.</span></li>
    </ol>
    <ol start="2" type="A">
        <li class="c7"><span class="c3">Categories of Personal Information Disclosed for a Business
                Purpose</span></li>
    </ol>
    <p class="c18"><span>The personal information that Company disclosed about consumers for a business
            purpose in the twelve months prior to the effective date of this California Notice fall into the following
            categories established by the California Consumer Privacy Act, depending on which Services are used.</span>
    </p>
    <ol start="1">
        <li class="c2"><span>Identifiers such as your name, alias, email address, phone numbers,
                or IP address, for example when you visit a channel with an Extension that requires your IP address (see
                &ldquo;Extensions&rdquo; in our Privacy Notice for more information);</span></li>
        <li class="c2"><span>Personal information, such as a credit card number, for example if
                we use a service provider to process payments;</span></li>
        <li class="c2"><span>Age or other protected classifications, for example if you choose to
                participate in a survey distributed by a survey provider;</span></li>
        <li class="c2"><span>Commercial information, such as purchase details if a third party
                service provider is assisting to provide the Services with which you interact;</span></li>
        <li class="c2"><span>Internet or other electronic network activity information, such as
                if we use a service provider to help us gather crash reports for analyzing the health of our
                services;</span></li>
        <li class="c2"><span>Professional information, for example if an affiliate or service
                provider assists in processing your payments; and</span></li>
        <li class="c2"><span>Inference data, such as information about your purchase and viewing
                preferences, for example if a service provider assists us in serving you relevant advertisements.</span>
        </li>
    </ol>
    <ol start="3" type="A">
        <li class="c7"><span class="c3">Data Access &amp; Deletion Rights</span></li>
    </ol>
    <p class="c18"><span>You may have the right under the California Consumer Privacy Act to request
            information about the collection of your personal information by Company, or access to or deletion of your
            personal information. You may contact us at the following email address: </span><span class="c8 c6"><a style="color: #fff;"
                href="mailto:support@get-in.com">support@get-in.com</a></span><span>.</span></p>
    <p class="c18"><span>Please note that certain information may be exempt from such requests under
            California law and that, depending on your data choices, certain services may be limited or unavailable. We
            will not discriminate against you for exercising your rights.</span></p>
    <ol start="4" type="A">
        <li class="c7"><span class="c3">No Sale of Personal Information</span></li>
    </ol>
    <p class="c18"><span>In the twelve months prior to the effective date of this California Notice, Company
            has not sold any personal information of consumer &nbsp;as those terms are defined under the California
            Consumer Privacy Act.</span></p>
    <ol start="5" type="A">
        <li class="c7"><span class="c3">California &ldquo;Shine the Light&rdquo; Disclosure</span></li>
    </ol>
    <p class="c5"><span>The California &ldquo;Shine the Light&rdquo; law gives residents of California the
            right under certain circumstances to opt out of the sharing of certain categories of personal information
            (as defined in the Shine the Light law) with third parties for their direct marketing purposes. We do not
            share your personal information with third parties for their own direct marketing purposes.</span></p>
    <p><span class="c3"></span></p>
    <p><span></span></p>
    <div>
        <p><span></span></p>
        <p><span></span></p>
    </div>
  </div>
</div>
