import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {BehaviorSubject} from 'rxjs';
declare var window: any;
declare var grecaptcha;

@Injectable({
  providedIn: 'root',
})
export class RecaptchaEnterpriseService {
  token = new BehaviorSubject<string>('');

  KEY = environment.recaptcha.enterpriseKey;
  CHALLENGE_KEY = environment.recaptcha.enterpriseChallengeKey;
  constructor() {}

  async execute(action) {
    if (environment.recaptcha.disable) {
      return 'test';
    }
    const token = await grecaptcha.enterprise.execute(this.KEY, {
      action: action,
    });
    // console.log('w', window.grecaptcha, 'grecaptcha', grecaptcha)
    // console.log('token', token)
    return token;
  }

  async render(html_element = '', action = '') {
    try {
      grecaptcha.enterprise.render(html_element, {
        sitekey: this.CHALLENGE_KEY,
        theme: 'dark',
        action: action,
        callback: (token: any) => {
          this.token.next(token);
        },
        'expired-callback': (err: any) => {
          console.log('expired-callback', err);
          this.token.next('');
        },
        'error-callback': (err: any) => {
          console.log('error-callback', err);
          this.token.next('');
        },
      });
    } catch (e) {
      console.log(e);
    }
  }

  async reset() {
    return grecaptcha.enterprise.reset();
  }

  setToken(val) {
    this.token.next(val);
  }
}
