<!-- For Age input -->
<div class="" [formGroup]="form" *ngIf="input.input_type === 'number' && input.input_name ==='age'" [ngClass]="">
  <label i18n="@@Age" *ngIf="label" for="{{input.input_name}}">Age</label>
  <input i18n-placeholder="@@Age" class="guest-input-text-nui form-control" (keyup)="ageToDate(counter,input.input_name)" (mouseup)="ageToDate(counter,input.input_name)" type="hidden" id="{{input.input_name}}" formControlName="{{input.input_name}}" placeholder="Age">

  <!-- Setting up the hidden input to transfer the birth date callculation -->
  <!-- <input class="form-control" type="hidden" id="{{input.input_name}}" formControlName="birth_date" placeholder="{{input.placeholder}}"> -->
</div>

<!-- For Date && input.input_name !== 'birth_date'-->
<div class="cards-nui" [formGroup]="form" *ngIf="input.input_type === 'date' " [ngClass]="customClass">
  <label *ngIf="label" for="{{input.input_name}}">{{input.placeholder}}</label>
  <input class="guest-input-text-nui form-control" id="{{input.input_name}}" type="text" formControlName="{{input.input_name}}" placeholder="{{(input.input_name | i18nSelect: formInputsOthers[localId]) ? (input.input_name | i18nSelect: formInputsOthers[localId]) : (input.placeholder)}}" [mbsc-options]="myBirthdaySettings" mbsc-date>
</div>

<!-- for Gender -->
<div class="cards-nui" [formGroup]="form" *ngIf="input.input_type === 'select' && input.input_name === 'gender' &&  gender !== 0" [ngClass]="customClass">
    <label *ngIf="label" for="{{input.input_name}}">{{input.placeholder}}</label>
    <select [mbsc-options]="genderSettings" mbsc-select (change)="selectHandler(counter,input.input_name ,$event.target.value)" class="guest-input-text-nui guest-select-option form-control" name="{{input.input_name}}" formControlName="{{input.input_name}}" id="{{input.input_name}}">
      <option selected disabled hidden value="">{{(input.input_name | i18nSelect: formInputsOthers[localId]) ? (input.input_name | i18nSelect: formInputsOthers[localId]) : (input.placeholder)}}</option>
      <option i18n="select label | gender" *ngFor="let option of input.input_options; let x = index" [value]="option.input_name">
        {option.input_name,plural,
          =0 {Other}
          =1 {Male}
          =2 {Female}
          other {Unknown}
        }
      </option>
    </select>
  </div>

<!-- For Select -->
<div class="cards-nui" [formGroup]="form" *ngIf="input.input_type === 'select' && input.input_name !== 'gender'" [ngClass]="customClass">
  <label *ngIf="label" for="{{input.input_name}}">{{input.placeholder}}</label>
  <select [mbsc-options]="genderSettings" mbsc-select (change)="selectHandler(counter,input.input_name ,$event.target.value)" class="guest-input-text-nui form-control" name="{{input.input_name}}" formControlName="{{input.input_name}}" id="{{input.input_name}}">
    <option selected disabled hidden value="">{{input.placeholder}}</option>
    <option *ngFor="let option of input.input_options" [value]="option.placeholder">{{option.placeholder}}</option>
  </select>
</div>

<!-- For Radio buttons -->
<div class="custom" [formGroup]="form" *ngIf="input.input_type === 'radio'" [ngClass]="customClass">
  <label class="white" [innerHTML]="input.placeholder | trustHtml"></label><br>
  <div class="checkbox-section" *ngFor="let value of input.input_options; let x = index;" >
    <div class="custom-radio">
      <span [ngClass]="radioBox[x] ? 'radio-mark': ''"></span>
    </div>
    <input class="promotion-radio" [checked]="radioBox[x]" (change)="radioHandler(counter,input.input_name ,$event.target.value, $event.target.checked, value, x)" id="{{value.input_name}}-{{counter}}" formControlName="{{input.input_name}}" name="{{input.input_name}}-{{counter}}" type="{{input.input_type}}" value="{{value.placeholder}}">
    <label class="inline  checkbox-text" for="{{value.input_name}}-{{counter}}">&nbsp;{{value.placeholder}}</label>
  </div>
</div>

<!-- For Checkboxes -->
<div class="custom" [formGroup]="form" *ngIf="input.input_type === 'checkbox'" [ngClass]="customClass">
  <label class="white" [innerHTML]="input.placeholder | trustHtml"></label><br>
<div class="checkbox-section" *ngFor="let value of input.input_options; let x = index;" style="display: flex;">
    <div class="card-checkbox-on-nui">
      <svg *ngIf="checkBox[x]" class="card-cb-on-nui" fill="none" height="8" viewBox="0 0 12 8"
        width="12" xmlns="http://www.w3.org/2000/svg">
        <path _ngcontent-c9="" class="ng-tns-c9-1"
          d="M11.8243 0.159541C11.59 -0.0531803 11.2101 -0.0531803 10.9757 0.159541L3.78737 6.68516L1.02428 4.17684C0.789972 3.96412 0.410097 3.96414 0.175746 4.17684C-0.058582 4.38954 -0.058582 4.73439 0.175746 4.94711L3.3631 7.84053C3.59734 8.05323 3.97749 8.05308 4.21163 7.84053L11.8243 0.929831C12.0586 0.717131 12.0586 0.372262 11.8243 0.159541Z"
          fill="#82FFCA"></path>
      </svg>
    </div>
    <input class="promotion-checkbox" [checked]="checkBox[x]" (change)="checkboxHandler(counter,input.input_name ,value.placeholder, $event.target.checked, value, x)" id="{{value.input_name}}-{{counter}}" formControlName="{{input.input_name}}" name="{{input.input_name}}-{{counter}}" type="{{input.input_type}}">
    <label class="inline checkbox-text" for="{{value.input_name}}-{{counter}}">&nbsp;{{value.placeholder}}</label>
  </div>
</div>

<!-- PHONE NUMBER -->
<div class="cards-nui" [formGroup]="form"
*ngIf="input.input_name === 'user_phone'"
[ngClass]="customClass" style="color: black;">
  <label *ngIf="label" for="{{input.input_name}}">{{input.placeholder}}</label>
  <input appPhoneNumber ng2TelInput
  i18n-placeholder="placeholder | customer service contact info"
  id="{{input.input_name}}"
  class="guest-input-text-nui phone-input-nui form-control"
  trim="blur"
  type="{{input.input_type}}"
  name="{{input.input_name}}"
  formControlName="{{input.input_name}}"
  (hasError)="setErrorOnphoneNumber(!$event)"
  (ng2TelOutput)="setMarketingPhoneNumber($event)"
  (intlTelInputObject)="telInputObject($event)"
  (countryChange)="onCountryChange($event)"
  [ng2TelInputOptions]="localizationService.getPhoneInputOptions()"
  placeholder="{{(input.input_name | i18nSelect: formInputsOthers[localId]) ? (input.input_name | i18nSelect: formInputsOthers[localId]) : (input.placeholder)}}"
  />
</div>

<!-- for identification -->
<div class="cards-nui" [formGroup]="form" [ngClass]="customClass" *ngIf="id_number !== 0 && (input.input_name === 'id_number' || input.input_name === 'identification_number')">
  <label *ngIf="label" for="{{input.input_name}}">{{input.placeholder}}</label>
  <input trim="blur" id="{{input.input_name}}" class="guest-input-text-nui form-control" type="{{input.input_type}}"
    name="{{input.input_name}}" formControlName="{{input.input_name}}"
    placeholder="{{(input.placeholder | i18nSelect: formInputsOthers[localId])}}">
</div>

<!-- For all the others -->
<div class="cards-nui ddd" [formGroup]="form"
*ngIf="input.input_type !== 'select'
&& input.input_type !== 'date'
&& input.input_type !== 'checkbox'
&& input.input_type !== 'radio'
&& input.input_name !== 'age'
&& input.input_name !== 'user_phone'
&& input.input_name !== 'id_number'
&& input.input_name !== 'identification_number'"

[ngClass]="customClass" >
  <label *ngIf="label" for="{{input.input_name}}">{{input.placeholder}}</label>
  <input trim="blur" id="{{input.input_name}}" class="guest-input-text-nui form-control" type="{{input.input_type}}" name="{{input.input_name}}" formControlName="{{input.input_name}}" placeholder="{{(input.input_name | i18nSelect: formInputsOthers[localId]) ? (input.input_name | i18nSelect: formInputsOthers[localId]) : (input.placeholder)}}">
</div>
