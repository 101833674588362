<div class="captcha-container">

</div>
<form class="user-login-form" [formGroup]="logregForm">
  <div *ngIf="!isShowOtp" class="buttons-section">
    <div i18n="Button | Login with phone" class="phone-btn" (click)="logRegBtnClick('phone')"
      [attr.data-cy]="'LoginWithPhoneButton'" [ngClass]="isBtnClicked && isBtnClicked.phone? 'active-btn' : ''">Login with
      phone</div>
    <div i18n="Button | Login with email" class="email-btn" (click)="logRegBtnClick('email')"
      [attr.data-cy]="'LoginWithEmailButton'" [ngClass]="isBtnClicked && isBtnClicked.email? 'active-btn' : ''">Login with
      email</div>
    <div class="email-btn" (click)="logRegBtnClick('p_key')"
         [attr.data-cy]="'LoginWithPasskeyButton'" [ngClass]="isBtnClicked && isBtnClicked.p_key? 'active-btn' : ''">
      {{'user-login.Touch_FaceID' | translate:'Touch / Face ID'}}
    </div>
<!--    <div i18n="Button | Login with wallet" class="wallet-btn" (click)="logRegBtnClick('wallet')" [ngClass]="isBtnClicked && isBtnClicked.wallet? 'active-btn' : ''">Login with wallet</div>-->
  </div>
  <div *ngIf="!isShowOtp && isBtnClicked && isBtnClicked.phone" class="phone-section" [ngStyle]="{'display': (isBtnClicked && isBtnClicked.phone) ? 'block' : 'none'}">
    <div *ngIf="false" i18n="Ensure section | Ensure" class="phone-section-title">Enter your phone number to receive a one time password to ensure it's you</div>
    <div class="phone-section-input">
      <input ng2TelInput i18n-placeholder="placeholder | phone"  trim="blur" pattern="^[\+0-9]{5,14}$"
        class="ng2-tel-input 1"
        inputmode="tel" type="tel"
        (keypress)="onKeyPressFn($event)"
        (keyup)="onChangePhone($event)"
        name="phone" id="phone"
        placeholder="phone number" value=""
        formControlName="phone"
        (hasError)="setErrorOnPhoneNumber(!$event)"
        (ng2TelOutput)="setRealPhoneNumber($event)"
        (intlTelInputObject)="intlTelInputObject($event)"
        (countryChange)="intlOnCountryChange($event)"
        [ng2TelInputOptions]="localizationService.getPhoneInputOptions()" />
    </div>
      <app-error-handling [@slideIn]
        *ngIf="!isShowOtp && isBtnClicked && isBtnClicked.phone"
        ngDefaultControl
        style="font-size: 13px;"
        [formControl]="convertToFormControl(logregForm.get('phone'))"
        [inputName]="'phone'"
        [formSubmited]="formSubmited"
        [customClass]="'error item-error'">
      </app-error-handling>
    <p class="signup-terms">
      {{'user-login.byPurchasingYoull' | translate:'By purchasing you\'ll receive an account, and agree to our general'}}
      <span (click)="getTerms()" class="signup-terms-link">{{'user-login.termsofUse' | translate:'Terms of Use'}}</span>,
      <span (click)="getPrivacy()" class="signup-terms-link">{{'user-login.privacyPolicy' | translate:'Privacy Policy'}}</span> {{'user-login.and_the' | translate:'and the'}}
      <span (click)="getTerms()" class="signup-terms-link">{{'user-login.ticketPurchaseTerms' | translate:'Ticket Purchase Terms'}}</span>.
      {{'user-login.WeProcessYour' | translate:'We process your personal data in accordance with our Privacy Policy.'}}
    </p>
  </div>
  <div class="otp-section" [ngStyle]="{'display': isShowOtp? 'block' : 'none'}">
    <div i18n="Verification section | Phone Number Verification" class="otp-phone-title">Code was sent to <span class="otp-phone">&nbsp;{{logregForm.get('phone')?.value || logregForm.get('email')?.value}}&nbsp;</span></div>
<!--    <div i18n="Verification section | Phone Number Verification" class="otp-section-title">One Time Verification Code -->
<!--      <span *ngIf="isShowOtp && sendSmsTimer">&nbsp;&nbsp;{{sendSmsTimer}}&nbsp;&nbsp;</span>-->
<!--      <span *ngIf="testOnlyOtpToken" style="color: #FFFFFF;">&nbsp;&nbsp;{{testOnlyOtpToken}}&nbsp;&nbsp;</span>-->
<!--    </div>-->
    <app-check-otp [showOtp]="isShowOtp" [isShowTimer]="isShowTimer" (timeCounter) = setTimeCounter($event) (sendUserData)="sendUserData($event)"
                   [(disabbleBtn)]="disabbleBtn" (otpIsShow)="startOTPTimer($event)" [isSendAgainAttempts]="isSendAgainAttempts" [isPhone]="isBtnClicked.phone"></app-check-otp>
<!--    <div *ngIf="isSendAgain" class="otp-sub-title-section">-->
<!--      <div i18n="Verification section | Didn't receive SMS" class="otp-section-title">Didn't receive a code? </div>-->
<!--      <div i18n="Verification section | send again" class="otp-section-sub-title" (click)="logregFormContinue(true)">&nbsp;Request again&nbsp;</div>-->
<!--    </div>-->
    <!--OTP block below otp input-->
    <div *ngIf="isShowOtp">
      <ng-container *ngIf="sendSmsTimer">
        <p class="otp-timer-txt">{{'signin.TimeLeftforNewOTP' | translate:'Time Left for New OTP'}} <span class="otp-timer-count">00:{{sendSmsTimer < 10 ? '0':''}}{{sendSmsTimer}}</span></p>
      </ng-container>

      <div class="otp-warning" *ngIf="sendSmsTimer">
        <img src="./assets/images/svg-icons/information.svg" alt="info">
        <p>{{'signin.infoMsgPleasedonotclosethiswindowOTP' | translate:'Please do not close this window until your OTP is received.'}}</p>
      </div>

<!--      <ng-container *ngIf="isBtnClicked && isBtnClicked.phone">-->
<!--        <p class="otp-timer-txt attempts" [@fade] *ngIf="isSendAgainAttempts < 3 && isSendAgainAttempts !== 0">{{3 - isSendAgainAttempts}} of 3 Attempts Left</p>-->
<!--        <p class="otp-timer-txt no-attempts" [@fade] *ngIf="isSendAgainAttempts >= 3">No More Attempts Left</p>-->
<!--      </ng-container>-->

      <!--    <div class="otp-resend" *ngIf="isSendAgainAttempts >= 3 && !sendSmsTimer">-->
      <!--      <p>Please Try Again Later or</p>-->
      <!--      <button (click)="intercomMessage()">Contact Support</button>-->
      <!--    </div>-->

      <div class="otp-resend" [@fade] [ngClass]="{'disabled' : sendSmsTimer}">
        <p class="mt10">{{'signin.DidntReceiveOTPCode' | translate:'Didn’t Receive OTP Code?'}}</p>
        <button [disabled]="sendSmsTimer" (click)="logregFormContinue(true)">{{'signin.ResentCode' | translate:'Resent Code'}}</button>
        <p style="margin-bottom: 0;">{{'signin.or' | translate:'or'}}</p>
        <button [disabled]="sendSmsTimer" (click)="showTab()" class="proceed-btn">
          <div *ngIf="isBtnClicked.phone">
            <span class="type-icon"><img src="./assets/images/login/l_email_login.svg" alt="Proceed using Email"></span>
            <span>{{'signin.ProceedusingEmail' | translate:'Proceed using Email'}}</span>
          </div>
          <div *ngIf="!isBtnClicked.phone">
            <span class="type-icon"><img src="./assets/images/login/l_phone_login.svg" alt="Phone Login"></span>
            <span>{{'signin.ProceedusingPhone' | translate:'Proceed using Phone'}}</span>
          </div>
        </button>
      </div>
    </div>
    <!--OTP block below otp input END-->
  </div>
  <div *ngIf="!isShowOtp && isBtnClicked && isBtnClicked.email" class="email-section" [ngStyle]="{'display': (isBtnClicked && isBtnClicked.email) ? 'block' : 'none'}">
    <div i18n="Label email | email input" class="email-section-title">Email</div>
    <div class="email-section-input">
      <!-- <img [src]="'assets/images/svg-icons/sms.svg'" alt="email icon"> -->
      <input i18n-placeholder="placeholder | email" class="email-input" type="email" [attr.data-cy]="'emailInput'"
        name="email" formControlName="email" placeholder="email" inputmode="email" (change)="mailChange($event.target.value)">
    </div>
    <!-- Toggle Password Input-->
    <div *ngIf="isBtnClicked && isBtnClicked.email" class="toggle-password">
      <span *ngIf="!showPasswordInput">{{'signin.Lookingforthepassword' | translate:'Looking for the password?'}}</span>
      <mat-checkbox [(ngModel)]="showPasswordInput" (change)="togglePassword($event)"
                    [ngModelOptions]="{standalone: true}">
        <span *ngIf="!showPasswordInput">{{'signin.Clicktousepassword' | translate:'Click to use password'}}</span>
        <span *ngIf="showPasswordInput">{{'signin.Clicktohidepassword' | translate:'Click to hide password'}}</span>
      </mat-checkbox>
    </div>
    <!-- Toggle Password Input END-->
    <app-error-handling [@slideIn]
      *ngIf="!isShowOtp && isBtnClicked && isBtnClicked.email"
      ngDefaultControl
      style="font-size: 13px;"
      [formControl]="convertToFormControl(logregForm.get('email'))"
      [inputName]="'email'"
      [formSubmited]="formSubmited"
      [customClass]="'error item-error'">
    </app-error-handling>

    <div *ngIf="suggestion" class="suggestion">
      {{'guestInfo.DidYouMean' | translate:'Did you mean'}} <span (click)="setSuggestedMail(suggestion?.full)">{{suggestion?.full}}</span> ?
    </div>

    <ng-container *ngIf="showPasswordInput">
      <div i18n="Title password | password input" *ngIf="!isShowOtp && isBtnClicked && isBtnClicked.email" class="email-section-title" style="margin-top: 20px;">Password</div>
      <div class="password-section-input 1">
        <!-- <img [src]="'assets/images/svg-icons/lock.svg'" alt="email icon"> -->
        <input i18n-placeholder="placeholder | password" class="password-input" type="password" [attr.data-cy]="'passwordInput'"
               name="password" (keypress)="onKeyPressFn($event)" formControlName="password" placeholder="enter password"
               minlength="6">
      </div>
      <span i18n="@@AccountSettingsPassError" [@slideIn] *ngIf="logregForm.get('password').touched && logregForm.get('password').invalid" class="item-error">The password must contain at least 6 characters</span>
      <div i18n="Forgot the password | section password" class="passwd-sub-title" (click)="resetPassword()">
        Forgot the password?
      </div>
    </ng-container>

    <p class="signup-terms">
      {{'user-login.byPurchasingYoull' | translate:'By purchasing you\'ll receive an account, and agree to our general'}}
      <span (click)="getTerms()" class="signup-terms-link">{{'user-login.termsofUse' | translate:'Terms of Use'}}</span>,
      <span (click)="getPrivacy()" class="signup-terms-link">{{'user-login.privacyPolicy' | translate:'Privacy Policy'}}</span> {{'user-login.and_the' | translate:'and the'}}
      <span (click)="getTerms()" class="signup-terms-link">{{'user-login.ticketPurchaseTerms' | translate:'Ticket Purchase Terms'}}</span>.
      {{'user-login.WeProcessYour' | translate:'We process your personal data in accordance with our Privacy Policy.'}}
    </p>
  </div>
  <div *ngIf="!isShowOtp && isBtnClicked && isBtnClicked.wallet" class="wallet-section" [ngStyle]="{'display': (isBtnClicked && isBtnClicked.wallet) ? 'block' : 'none'}">
    <!-- select wallet to login/registration -->
    <div *ngIf="showWallets" class="">
      <div i18n="Wallet section title | section title" class="wallet-section-title">
        This is a wallet select section
      </div>

    </div>
    <!-- show registration section if selected wallet not registered -->
    <div *ngIf="!showWallets" class="">
      <div i18n="Wallet section title | section reg" class="wallet-section-title">
        This is a wallet reg section
      </div>

      <!-- showing slider swith section -->
      <section class="slide-toggle-section">
        <div i18n="Slider section | phone" [ngStyle]="{'color': showWalletEmailSection ? '#CCC' : '#FFF'}">Phone</div>
        <mat-slide-toggle
            (toggleChange)="slideToggleSection()"
            class="slide-toggle-margin"
            [color]="colorSlideToggle"
            [checked]="showWalletEmailSection"
            [disabled]="isDisabledSlideToggle">
        </mat-slide-toggle>
        <div i18n="Slider section | email" [ngStyle]="{'color': showWalletEmailSection ? '#FFF' : '#CCC'}">Email</div>
      </section>

      <!-- showing wallet reg phone section -->
      <div *ngIf="!showWalletEmailSection" class="wallet-phone-section" [ngStyle]="{'display': !showWalletEmailSection ? 'flex':'none'}">
        <div i18n="Ensure section | Ensure" class="phone-section-title">Enter your phone number to receive a one time password to ensure it's you</div>
        <div class="phone-section-input">
          <input appPhoneNumber ng2TelInput i18n-placeholder="placeholder | phone"  trim="change" pattern="^[\+0-9]{5,14}$"
            class="ng2-tel-input 2"
            inputmode="tel" type="tel"
            name="phone" id="phone"
            (keypress)="onKeyPressFn($event)"
            (keydown)="onChangePhone($event)"
            placeholder="phone number"
            formControlName="phone"
            (hasError)="setErrorOnPhoneNumber(!$event)"
            (ng2TelOutput)="setRealPhoneNumber($event)"
            (intlTelInputObject)="intlTelInputObject($event)"
            (countryChange)="intlOnCountryChange($event)"
            [ng2TelInputOptions]="localizationService.getPhoneInputOptions()" />
        </div>
          <app-error-handling [@slideIn]
            *ngIf="!isShowOtp && isBtnClicked && isBtnClicked.wallet"
            ngDefaultControl
            style="font-size: 13px;"
            [formControl]="convertToFormControl(logregForm.get('phone'))"
            [inputName]="'phone'"
            [formSubmited]="formSubmited"
            [customClass]="'error item-error'">
          </app-error-handling>
      </div>
      <!-- showing wallet reg email section -->
      <div *ngIf="showWalletEmailSection" class="wallet-email-section" [ngStyle]="{'display': showWalletEmailSection ? 'flex':'none'}">
        <div i18n="Label email | email input" class="email-section-title">Email</div>
        <div class="email-section-input">
          <!-- <img [src]="'assets/images/svg-icons/sms.svg'" alt="email icon"> -->
          <input i18n-placeholder="placeholder | email" class="email-input" type="email" name="email" formControlName="email" placeholder="email" inputmode="email">
        </div>
          <app-error-handling [@slideIn]
            *ngIf="!isShowOtp && isBtnClicked && isBtnClicked.wallet"
            ngDefaultControl
            style="font-size: 13px;"
            [formControl]="convertToFormControl(logregForm.controls.email)"
            [inputName]="'email'"
            [formSubmited]="formSubmited"
            [customClass]="'error item-error'">
          </app-error-handling>
          <div i18n="Title password | password input" *ngIf="!isShowOtp && isBtnClicked && isBtnClicked.wallet" class="email-section-title" style="margin-top: 20px;">Password</div>
          <div class="password-section-input 2">
            <img [src]="'assets/images/svg-icons/lock.svg'" alt="email icon">
            <input i18n-placeholder="placeholder | password" class="password-input" type="password" name="password" formControlName="password" placeholder="enter password" required minlength="6">
          </div>
          <span i18n="@@AccountSettingsPassError" [@slideIn] *ngIf="logregForm.get('password').touched && logregForm.get('password').invalid" class="item-error">The password must contain at least 6 characters</span>
      </div>
    </div>
  </div>
  <div id="recaptcha_userlogin" #recaptcha_userlogin style="display: flex;justify-content: center;"></div>
  <div *ngIf="!isWalletConnect && isBtnClicked && !isBtnClicked.p_key" class="login-reg-continue 1">
    <button *ngIf="!isShowOtp" #continuebtn i18n="Continue to login | button" [disabled]="(isShowOtp)"
      [attr.data-cy]="'LoginWithEmailContinueButton'" id="continuebtn" class="continue-btn" (click)="logregFormContinue()"
      [ngStyle]="{'background-color': (!logregForm.invalid && logregForm.touched && !isShowOtp) ? '#ffffff' : '#cccccc50'}">Continue</button>
<!--    <button *ngIf="isShowOtp" #continuebtn i18n="Continue to login | button" [disabled]="(isShowOtp)"-->
<!--      [attr.data-cy]="'LoginWithPhoneContinueButton'" id="continuebtn" class="continue-btn" (click)="logregFormContinue()"-->
<!--      [ngStyle]="{'background-color': (!logregForm.invalid && logregForm.touched && !isShowOtp) ? '#ffffff' : '#cccccc50'}">Verify-->
<!--      to purchase</button>-->
  </div>
  <div *ngIf="isBtnClicked && isBtnClicked.p_key">
    <app-passkeys [showCheckPassKeyBtn]="true" [isEventPage]="true" (passkeyLoginSuccess)="setPasskeyLoginData($event)"></app-passkeys>
  </div>
  <div *ngIf="!isShowOtp && isWalletConnect" class="login-reg-continue 2">
    <button #continuebtn i18n="Continue to login | button" id="continuebtn" class="continue-btn" (click)="walletIsChecked ? logregWalletContinue() : logRegWithWallet()" [ngStyle]="{'background-color': (!logregForm.invalid && logregForm.touched) ? '#ffffff' : '#cccccc50'}">Continue</button>
  </div>
</form>
