import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-term-en',
  templateUrl: './term-en.component.html',
  styleUrls: ['./term-en.component.css']
})
export class TermEnComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  scrollTo(el) {
    const element = document.getElementById(`${el}`);
    element.scrollIntoView({behavior: 'smooth'});
  }

}
