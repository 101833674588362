import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { EventService } from '../../services/event.service';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from '../../services/dialog.service';
import { GlobalsService } from '../../globals';
import { slideIn } from '../../animation';

export interface TicketRow {
  name: string;
  id: number;
  status: number;
}
export interface SuccessData {
  amount_fee: number;
  amount_refund: number;
  count_tickets: number;
  count_free_tickets: number;
}

@Component({
  selector: 'app-cms-cancel-event',
  templateUrl: './cms-cancel-refund-event.component.html',
  styleUrls: ['./cms-cancel-refund-event.component.css'],
  animations: [slideIn]
})
export class CmsCancelRefundEventComponent implements OnInit {
  event_id: string;
  data;
  dataSource = new MatTableDataSource([]);
  @ViewChild(MatSort) sort: MatSort;
  selection = new SelectionModel<TicketRow>(true, []);
  displayedColumns: string[] = [
    'select',
    'id',
    'item_name',
    'first_name',
    'producer_credit',
    'status',
    'sub_users'
  ];
  showSubUsers: boolean;
  subUsers: any;
  check_cancel_event: boolean;
  successData: SuccessData = {amount_fee: 0,  amount_refund: 0, count_tickets: 0,  count_free_tickets: 0};
  successDataReceived: boolean = false;
  errorData: any;

  allSelectedTickets;

  constructor(private activatedRoute: ActivatedRoute,
              private eventService: EventService,
              private dialogService: DialogService,
              private globals: GlobalsService) { }

  ngOnInit(): void {
		this.activatedRoute.params.subscribe(params => {
			this.event_id = params['event_id'];
		});

		this.globals.startLoader();
		this.getPurchases();
  }

  getPurchases() {
    this.eventService.getEventPurchases(this.event_id).subscribe((res: any) => {
      this.data = res.data;
		  this.dataSource = new MatTableDataSource(res.data.purchases);
		  this.dataSource.sort = this.sort;
		  this.check_cancel_event = res.data.check_cancel_event;
		  this.globals.stopLoader();
    },
      error => {
      console.log(error);
      this.globals.stopLoader();
    })
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    // const numSelected = this.selection.selected.length;
    const numSelectedApprovedItems = this.selection.selected.filter(i => i.status === 2);
    // const numRows = this.dataSource.data.length;
    const numRowsApprovedItems = this.dataSource.data.filter(i => i.status === 2);
    return numSelectedApprovedItems.length === numRowsApprovedItems.length;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => {
        // this.selection.select(row)
        if (row.status === 2) {this.selection.select(row)}
      });
  }

  calcSelectedTickets(selection) {
    this.allSelectedTickets = selection.selected.reduce((a, b) => {
      return a + b.items_amount // calc all tickets length
    }, 0);
  }

  toggleRow(row) {
    //this.selection.toggle(row);
    if (row.status === 2) {
      this.selection.toggle(row);
    }
  }

  disabledRow(row) {
    return row.status !== 2
  }

  /** Show popup */
  showUsers(sub_users) {
    //console.log(sub_users, this.selection);
    this.showSubUsers = true;
    if (sub_users.length) {
      this.subUsers = sub_users;
    } else {
      this.subUsers = [];
    }
    // setTimeout(() => {
    //   console.log(this.selection.selected);
    // }, 1000);
  }

  /** Close popup */
  hideUsers() {
    this.showSubUsers = false;
    this.subUsers = [];
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: TicketRow): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.id + 1
    }`;
  }

  /** Cancel Button */
  cancelEvent() {
    //if (!this.dataSource.data.length) return
    this.eventService.cancelEventPurchases(this.event_id).subscribe(
      (response: any) => {
        this.errorData = '';
        console.log("res",response);
        this.dialogService.alertsModal('success', 'success', 3000, true);
        this.getPurchases();
      },
      (error) => {
        console.log("err",error)
        this.dialogService.alertsModal('participants', error.error, 3000, false);
        if (error.data) {
          this.errorData = error.data;
        }
      }
    );
  }

  /** Refund Button */
  refund(all) { // all - true or false

    const purchase_ids = this.selection.selected.map(i => i.id);

    this.globals.startLoader();
    this.eventService.setRefundEventPurchases(+this.event_id, all, !all ? purchase_ids : null ).subscribe(
      (response: any) => {
        const res = response.data;
        this.successData = res.successData;
        this.successDataReceived = true;

        this.errorData = '';
        if (res.errorData) {
          this.errorData = res.errorData;
        }
        // console.log("res",response);
        this.selection.clear()
        //
        if (res.export_url !== false) {
          window.open(res.export_url, '_blank');
        }
        this.getPurchases();
      },
      (error) => {
        console.log("err",error)
        this.dialogService.alertsModal('participants', error.error, 3000, false);
        this.globals.stopLoader();
      }
    );

  }

}
