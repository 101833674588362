import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {FilterEventsService} from '../../services/filter-events.service';
import {GlobalsService} from '../../globals';

@Component({
  selector: 'app-select-filter-events',
  templateUrl: './select-filter-events.component.html',
  styleUrls: ['./select-filter-events.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectFilterEventsComponent implements OnInit {
  panelValue = new UntypedFormControl('');
  dir: string;

  constructor(private filterEventsService: FilterEventsService, public globals: GlobalsService) { }

  ngOnInit(): void {


    this.dir = this.globals.getDir();
  }
  change(e) {
    console.log(e.value)
    this.filterEventsService.setSelect(e.value);
  }

  selectClick() {
    if (this.panelValue.value === 'calendar') {
      this.filterEventsService.openPicker();
    }
  }

}
