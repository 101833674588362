import { LocalizationService } from '../../services/localization.service';
import { DialogService } from '../../services/dialog.service';
import { Component, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { NgForm } from '@angular/forms';
import { phoneRegex } from '../../regex';
import { GlobalsService } from '../../globals';
import { slideIn } from '../../animation';
import { NavbarService } from '../../services/navbar.service';
import {RudderstackService} from "../../services/rudderstack.service";

@Component({
  selector: 'app-reader-signup',
  templateUrl: './reader-signup.component.html',
  styleUrls: ['./reader-signup.component.css'],
  animations: [slideIn]
})
export class ReaderSignupComponent implements OnInit {
  @ViewChild('signupForm') signupForm: NgForm;
  event_id: number;
  readerData: any;
  phoneRegex = phoneRegex;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private dialogService: DialogService,
    private globals: GlobalsService,
    public rudderstackService: RudderstackService,
    private router: Router,
    public localizationService: LocalizationService,
    private navbar: NavbarService
  ) {
    this.activatedRoute.params
    .subscribe((params: Params) => {
      if (params['event_id']) {
        this.event_id = +params['event_id'];
        this.getReaderSignupData();
      }
    });
  }

  ngOnInit() {
    this.navbar.setIsNewUi(true);
    setTimeout(() => {this.navbar.noPadding = 'removePadding';}, 200);
    this.navbar.hide();
    this.rudderstackService.page('Event management','Barcode managment');
  }

  onSubmit() {
    if (!this.signupForm.valid) {
      this.signupForm.form.markAllAsTouched();
      return
    }
      if (this.signupForm.form.controls['phone'].value && !this.signupForm.form.controls['phoneCountryCode'].value) {
        const countryCode: any = this.localizationService.getPhoneInputOptions();
        this.signupForm.form.controls['phoneCountryCode'].setValue(countryCode.initialCountry);
      }
      const selectedEvents = this.readerData.availableEvents.filter(el => el.checked === true);
      let total_events = this.event_id.toString();
      let type = 1;
      if (selectedEvents.length > 0) {
        type = 2;
        selectedEvents.forEach(event => {
          total_events = total_events + '?!?' + event.event_id;
          const  originalIndex = this.readerData.availableEvents.findIndex((obj: any) => {if (obj) {return obj.event_id === +event.event_id}});
          this.readerData.availableEvents[originalIndex].checked = false;
        });
      }
      this.globals.startLoader();
      this.authService.readerSignup(this.event_id, total_events, type, this.signupForm.value)
        .subscribe(
          (response: any) => {
            this.globals.stopLoader();
            this.readerData.readers.push(response.data);
          },
          error => {
            this.globals.stopLoader();
            this.dialogService.alertsModal('errors', (error.code) ? error.code : 4 , 5000, false, error.log_id);
          }
      );
      this.signupForm.reset();
  }

  resendPassword(reader_id: number) {
		this.globals.startLoader();
		this.authService.resendReaderPassword(reader_id, this.event_id)
    .subscribe(
      (response: any) => {
        this.globals.stopLoader();
        this.dialogService.alertsModal('success', response.code, 5000, true);
      },
      error => {
        this.globals.stopLoader();
        this.dialogService.alertsModal('errors', (error.code) ? error.code : 4 , 5000, false, error.log_id);
      }
    );
  }

  removeReader(reader_id: number) {
    this.globals.startLoader();
		this.authService.removeReader(reader_id, this.event_id)
    .subscribe(
      (response: any) => {
        this.globals.stopLoader();
        this.dialogService.alertsModal('success', response.code, 5000, true);
        const removedReaderIndex = this.readerData.readers.findIndex((obj: any) => {if (obj) {return obj.id === reader_id}});
        this.readerData.readers.splice(removedReaderIndex, 1);
      },
      error => {
        this.globals.stopLoader();
        this.dialogService.alertsModal('errors', (error.code) ? error.code : 4 , 5000, false, error.log_id);
      }
    );
  }

  getReaderSignupData() {
    this.globals.startLoader();
    this.authService.getReaderSignupData(this.event_id)
    .subscribe(
        (response: any) => {
          this.globals.stopLoader();
          this.readerData = response.data;
        },
        error => {
          this.globals.stopLoader();
          if (error.code || error.httpStatus !== 403) {
            this.dialogService.alertsModal('errors', (error.code) ? error.code : 4 , 5000, false, error.log_id);
          } else {
            this.router.navigate(['/reader-signin']);
          }
        }
    );
  }

  checkedEvents(event: any) {
    const eventIndex = this.readerData.availableEvents.findIndex((obj: any) => {if (obj) {return obj.event_id === +event.value}});
    if (event.checked) {
      this.readerData.availableEvents[eventIndex].checked = true;
    } else {
      this.readerData.availableEvents[eventIndex].checked = false;
    }
  }

  setErrorOnphoneNumber(hasErrorfromNg2TelInput) {
		const hasError = this.globals.hasErrorOnPhoneNumber(hasErrorfromNg2TelInput, this.signupForm.form.controls['phone'].value);
		const actionOnSetErrors = hasError ? {'invalid': true} : null;
		this.signupForm.form.controls['phone'].setErrors(actionOnSetErrors);
	}
	setPhoneNumber(phoneNumber) {
		this.signupForm.form.controls['phone'].patchValue(phoneNumber);
	}
	telInputObject(obj, userPhone) {
		if (userPhone) {
			obj.setNumber(userPhone);
		}
	}
	onCountryChange(obj) {
		if (this.signupForm.form.controls['phoneCountryCode']) {
      this.signupForm.form.controls['phoneCountryCode'].setValue(obj.iso2);
		}
	}
}
