import {Component, OnInit, Input} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-go-back',
  templateUrl: './go-back.component.html',
  styleUrls: ['./go-back.component.css'],
})
export class GoBackComponent implements OnInit {
  @Input() closeArrow: boolean|null = null;
  @Input() bigArrow: boolean|null;
  @Input() hideDialogArrow: boolean|null;
  @Input() top: number|0; // top offset
  @Input() left: number|0; // left offset
  @Input() disableNavigate: boolean|null; // stop navigate to '/'

  constructor(private router: Router) {};

  ngOnInit() {}

  goBack() {
    if (this.disableNavigate) {return}
    this.router.navigate(['/']);
  }
}
