import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SalesmanPipe} from '../pipes/salesmans.pipe';
import {FilterArrayPipe} from '../pipes/filter.pipe';
import {HtmlPipe} from '../pipes/trust-html.pipe';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ErrorCreatorComponent} from '../create-edit-event/error-creator/error-creator.component';
import {TermHeComponent} from '../terms/terms-langs/term-he/term-he.component';
import {TermEnComponent} from '../terms/terms-langs/term-en/term-en.component';
import {TermsComponent} from '../terms/terms.component';
import {WalletConnectComponent} from '../wallet-connect/wallet-connect.component';
import {SolanaConnectComponent} from '../solana-connect/solana-connect.component';
import {CountdownCheckoutTimerComponent} from '../event-page/countdown-checkout-timer/countdown-checkout-timer.component';
import {ErrorHandlingComponent} from '../form-inputs/error-handling/error-handling.component';
import {CustomFormInputsComponent} from '../event-page/custom-form-inputs/custom-form-inputs.component';
import {FormInputsComponent} from '../form-inputs/form-inputs.component';
import {CheckOtpComponent} from '../login/check-otp/check-otp.component';
import {UserAvatarComponent} from '../personal-info/user-avatar/user-avatar.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MbscModule} from '@mobiscroll/angular';
import {CancellationEnComponent} from '../terms/cancellation-policy/cancellation-langs/cancellation-en/cancellation-en.component';
import {CancellationHeComponent} from '../terms/cancellation-policy/cancellation-langs/cancellation-he/cancellation-he.component';
import {GoBackComponent} from '../go-back/go-back.component';
import {TicketsWPricePipe} from '../pipes/tickets-w-price.pipe';
import {NgxTolgeeModule} from "@tolgee/ngx";
import {NgxJdenticonModule} from "ngx-jdenticon";
import {PasskeysComponent} from "../login/passkeys/passkeys.component";


const Components = [
 // import Components which need to share
  ErrorCreatorComponent,
  ErrorHandlingComponent,
  TermEnComponent,
  TermHeComponent,
  CancellationEnComponent,
  CancellationHeComponent,
  WalletConnectComponent,
  SolanaConnectComponent,
  CountdownCheckoutTimerComponent,
  // CheckOtpComponent,
  UserAvatarComponent,
  GoBackComponent,
  CheckOtpComponent,
  PasskeysComponent

];

const Pipes = [
  // import Pipes which need to share
  HtmlPipe,
  SalesmanPipe,
  FilterArrayPipe,
  TicketsWPricePipe
];

const Modules = [
  FormsModule,
  ReactiveFormsModule,
]


@NgModule({
  declarations: [
    Components,
    Pipes
  ],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        NgxTolgeeModule,
        NgxJdenticonModule
    ],
  exports: [
    Components,
    Pipes,
    Modules
  ]
})

export class SharedModule{
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [
        //services that you want to share across modules
        // SharedService,
        // SharedService2
      ]
    }
  }
}
