<button class="passkey-btn" (click)="createRegistration()" *ngIf="showAddPassKeyBtn" type="button">
  <img src="./assets/images/login/l_phone.svg" alt="Create passkey">
  <span>{{'passkeys.CreatePasskey' | translate:'Create Passkey'}}</span>
</button>
<!--<div class="keys">-->
<!--  Passkeys:-->
<!--  <span *ngFor="let key of pass_key_data_accounts; let i = index" class="key">{{i+1}}</span>-->
<!--</div>-->
<button class="passkey-btn" (click)="deleteAllRegistrations()"
        *ngIf="showAddPassKeyBtn && pass_key_data_accounts.length" type="button">
  <img src="./assets/images/svg-icons/trash.svg" alt="Delete all passkeys">
  <span>{{'passkeys.RemoveAllPasskeys' | translate:'Remove All Passkeys'}}</span>
</button>

<button class="passkey-btn passkey-login" (click)="checkRegistration()" *ngIf="showCheckPassKeyBtn" type="button">
  <span class="type-icon"><img src="./assets/images/login/l_passkey_login.svg" alt="Sign In using passkeys on your device"></span>
  <span>{{'passkeys.ProceedusingDeviceAccess' | translate:'Proceed using Device Access'}}</span>
</button>
<!--<p #error class="err"></p>-->
