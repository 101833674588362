<div class="row">
    <div class="col-md-12 col-xs-12">
      <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Get In Inc.&rsquo;s Privacy Statement</span></strong></p>
      <p>&nbsp;</p>
      <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Effective Date: 1.1.2019</span></p>
      <p>&nbsp;</p>
      <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Your privacy is important to Get In Inc. (&ldquo;Get In,&rdquo; &ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;our&rdquo;).&nbsp; This Privacy Statement applies to the online collection and sharing of personal and other information via our mobile services, applications, websites (including www.get-in.com), and any software provided on or in connection with Get In services (collectively, the &ldquo;</span><strong><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Service</span></strong><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&rdquo;).&nbsp;&nbsp;</span></p>
      <p style="line-height: 1.2; text-align: justify; margin-top: 5pt; margin-bottom: 5pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">As used in these Privacy Statement, &ldquo;</span><strong><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">User</span></strong><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&rdquo; is defined as you and anyone who uses the Site.&nbsp; Use can include, but is not limited to, reading, browsing or viewing content, ordering services, goods or Tickets (defined below), making a payment, collecting a payment, or transferring information.</span></p>
      <p style="line-height: 1.2; text-align: justify; margin-top: 5pt; margin-bottom: 5pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">As used in these Privacy Statement, &ldquo;</span><strong><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Organizer</span></strong><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&rdquo; is defined as a User who uses the Site to produce or organize an Event (defined below), to collect a payment, or to transfer information or advertisements to other Users.</span></p>
      <p style="line-height: 1.2; text-align: justify; margin-top: 5pt; margin-bottom: 5pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">As used in these Privacy Statement, &ldquo;</span><strong><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Event</span></strong><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&rdquo; is defined as a private or public cultural, educational, business, sporting or other event or conference produced or organized by an Organizer.</span></p>
      <p style="line-height: 1.2; text-align: justify; margin-top: 5pt; margin-bottom: 5pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">As used in these Privacy Statement, &ldquo;</span><strong><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Content</span></strong><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&rdquo; is defined as any documents, information, communication, advertisement, software, photo, video, graphics (including illustrations, images, drawings, logos), music, sound, text and other material and service that can be viewed by Users on the Site.&nbsp;</span></p>
      <p style="line-height: 1.2; text-align: justify; margin-top: 5pt; margin-bottom: 5pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">As used in these Privacy Statement, &ldquo;</span><strong><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Ticket</span></strong><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&rdquo; is defined as a ticket sold by an Organizer and purchased by a User to attend or participate in an Event produced or organized by an Organizer.</span></p>
      <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Our Privacy Statement applies to all Users who access the Service, including you (&ldquo;</span><strong><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">you</span></strong><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&rdquo; or &ldquo;</span><strong><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">your</span></strong><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&rdquo;).</span></p>
      <p>&nbsp;</p>
      <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">By using our Service, you understand and agree that we are providing a platform for you to share Content to the Service, and to share Content publicly.&nbsp; This means that other Users and third-parties may search for, see, use, or share any of your Content that you make publicly available through the Service, consistent with the settings you set in your account, and with the terms and conditions of this Privacy Policy and our Terms of Use (which can be found at: </span><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000 !important; background-color: #ffff00; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">www.get-in.com/terms)</span></p>
      <p>&nbsp;</p>
      <ol style="margin-top: 0; margin-bottom: 0;">
      <li><strong><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Information We Collect</span></strong></li>
      </ol>
      <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Information you provide us directly:</span></p>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Your username, password, name, age, address(es), e-mail address(es), telephone number(s), payment information (e.g., credit card(s)) and billing information when you register for the Service or purchase a Ticket.</span></li>
      </ul>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Content that you post to the Service or share through the Service.</span></li>
      </ul>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Information that you provide or include in your Content.&nbsp; You have choices about the information to include in your Content, such as your education, work experience, skills, hobbies, likes and dislikes. The information in your Content helps you to get more from our Service, including helping Organizers find you. It is your choice whether to include sensitive information on your Content. Please do not post or add personal data to your Content that you would not want to be publicly available.</span></li>
      </ul>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Communications between you and Get In. For example, we may send you Service-related emails (e.g., account verification, changes/updates to features of the Service, technical and security notices).&nbsp; Note that you may not opt out of Service-related e-mails.&nbsp;&nbsp;</span></li>
      </ul>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Customer-Support communications.&nbsp; If you contact us for customer support, we may ask you to provide information about your computer/mobile device or about the issues you are trying to resolve. This information is necessary to help us answer your questions. We may monitor or record your requests and our responses for quality control purposes.</span></li>
      </ul>
      <p>&nbsp;</p>
      <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Information you provide us through other third-party accounts that you maintain:</span></p>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You may register or enhance your account with us by linking your accounts from third-party companies and services like Facebook, Instagram, LinkedIn, Google and PayPal. By doing this, you are asking them to send us certain information from those accounts, and you are authorizing us to collect, store, and use what they send us in accordance with this Privacy Policy.</span></li>
      </ul>
      <p>&nbsp;</p>
      <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Analytics information and third-party services:</span></p>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">We use third-party analytics tools to help us measure traffic and usage trends for the Service. These tools collect information sent by your device or our Service, including the web pages you visit, add-ons, and other information that assists us in improving the Service. We collect and use this analytics information with analytics information from other Users so that it cannot reasonably be used to identify any particular individual User.</span></li>
      </ul>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">We may use other services hosted by third-parties to assist in providing our Service and to help us understand our Users&rsquo; use of our Service. These third-party services may collect information sent by your device when you use the Service, including Internet Protocol (&ldquo;IP&rdquo;) address, type of device, operating system version, unique or persistent identifiers associated with your device (such as UDID, MAC address, IFA, or identifiers derived from your device&rsquo;s technical information), network information, technical characteristics of your device or its software, language settings, and usage information relating to your use of the Service.</span></li>
      </ul>
      <p>&nbsp;</p>
      <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Cookies and similar technologies:</span></p>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">When you visit the Service, we may use cookies and similar technologies like pixels, web beacons, and local storage to collect information about how you use the Service and to provide features to you.</span></li>
      </ul>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">We may ask advertisers or other partners to serve ads or services to your devices, which may use cookies or similar technologies placed by us or the third party.</span></li>
      </ul>
      <p>&nbsp;</p>
      <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Log file information:</span></p>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Log file information is automatically reported by your browser each time you make a request to access (i.e., visit) a web page or application. It can also be provided when the content of the webpage or application is downloaded to your browser or device.</span></li>
      </ul>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">When you use our Service, our servers automatically record certain log file information, including your web request, IP address, browser type, referring / exit pages and URLs, number of clicks and how you interact with links on the Service, domain names, landing pages, pages viewed, and other such information. We may also collect similar information from emails or messages sent to our Users which then help us track which emails or messages are opened and which links are clicked by recipients.&nbsp; The information allows us to administer the Service, allows for more accurate reporting and improvement of the Service, and protects the Service and its content from inappropriate use.</span></li>
      </ul>
      <p>&nbsp;</p>
      <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Device identifiers:</span></p>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">When you use a mobile device like a tablet or phone to access our Service, we may access, collect, monitor, store on your device, and/or remotely store one or more &ldquo;device identifiers.&rdquo; Device identifiers are small data files or similar data structures stored on or associated with your mobile device, which uniquely identify your mobile device. A device identifier may be data stored in connection with the device hardware, data stored in connection with the device's operating system or other software, or data sent to the device by Get In.</span></li>
      </ul>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">A device identifier may deliver information to us or to a third-party partner about how you browse and use the Service and may help us or others provide reports or personalized content and ads. Some features of the Service may not function properly if use or availability of device identifiers is impaired or disabled.</span></li>
      </ul>
      <p>&nbsp;</p>
      <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Metadata:</span></p>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Metadata is usually technical data that is associated with User Content. For example, Metadata can describe how, when and by whom a piece of Content was created, collected and modified, and how that Content is formatted.</span></li>
      </ul>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Users can add or may have Metadata associated with or added to their Content including a hashtag (e.g., to mark keywords when you post a photo), geotag (e.g., to mark your location to a photo), comments or other data concerning the creation, collection or editing of Content. This can make your Content more informative or interactive. If your photos are geotagged or tagged using other's APIs then, your latitude and longitude will be stored with the photo, and will be accessible and searchable (e.g., through a location or map feature) if your photo is made public by you in accordance with your privacy settings.&nbsp; Similarly, if your documents are tagged with Metadata, the Metadata will be stored with the document, and will be accessible and searchable.</span></li>
      </ul>
      <p>&nbsp;</p>
      <ol style="margin-top: 0; margin-bottom: 0;">
      <li><strong><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">How We Use Your Information</span></strong></li>
      </ol>
      <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">In addition to some of the specific uses of information we describe in this Privacy Policy, we may use information that we receive to:</span></p>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">help you efficiently access your information after you sign in;</span></li>
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">remember information so you will not have to re-enter it during your visit or the next time you visit the Service;</span></li>
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">contact you to convey information that we, or an Organizer, consider relevant to you;</span></li>
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">provide personalized Content and information to you and others, which could include online ads or other forms of marketing;</span></li>
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">provide, improve, test, and monitor the effectiveness of our Service;</span></li>
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">develop and test new services, products and features;</span></li>
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">monitor metrics such as total number of visitors, traffic, and demographic patterns;</span></li>
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">diagnose or fix technology problems;</span></li>
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">automatically update the Get In Services on your computer, mobile phone, or other device;</span></li>
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">provide you with goods or services;</span></li>
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">respond to your requests or questions.</span></li>
      </ul>
      <p>&nbsp;</p>
      <ol style="margin-top: 0; margin-bottom: 0;">
      <li><strong><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Sharing of Your Information</span></strong></li>
      </ol>
      <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">We will not share your information to third parties outside Get In (or the group of businesses that are legally part of the same group of companies that Get In is part of) without your consent, except as noted in this Privacy Policy.&nbsp;</span></p>
      <p>&nbsp;</p>
      <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Parties with whom we may share your information:</span></p>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">We may share Content and your information (including but not limited to, information from cookies, log files, device identifiers, location data, and usage data) with businesses that are legally part of the same group of companies that Get In is part of, or that become part of that group (&ldquo;Affiliates&rdquo;).&nbsp; Affiliates may use this information to help provide, understand, and improve the Service (including by providing analytics) and Affiliates' own services (including by providing you with better and more relevant experiences).</span></li>
      </ul>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">We may share Content and your information (including but not limited to, information from cookies, log files, device identifiers, location data, and usage data) with Organizers if: you have visited the page of an Organizer&rsquo;s Event; have ordered or purchased a ticket to an Organizer&rsquo;s Event; have&nbsp; attended or participated in an Organizer&rsquo;s Event. Organizers may use this information to, among other things, share information with you concerning other Events that they believe will be of interest to you.</span></li>
      </ul>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">We also may share your information as well as information from tools like cookies, log files, and device identifiers and location data, with third-party organizations that help us provide the Service to you (&ldquo;Service Providers&rdquo;). Our Service Providers will be given access to your information as is reasonably necessary to provide the Service under reasonable confidentiality terms.</span></li>
      </ul>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">We may also share certain information such as cookie data with third-party advertising partners. This information would allow third-party ad networks to, among other things, deliver targeted advertisements that they believe will be of interest to you.</span></li>
      </ul>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">We may remove parts of data that can identify you and share anonymized data with other parties. We may also combine your information with other information in a way that it is no longer associated with you and share that aggregated information.</span></li>
      </ul>
      <p>&nbsp;</p>
      <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Parties with whom you may choose to share your Content:</span></p>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Any information or content that you voluntarily disclose for posting to the Service, such as Content, becomes available to the public, as controlled by any applicable account settings that you set on the Service.&nbsp; To change your settings on the Service, please log into your account and change your account settings. Once you have shared Content or made it public, that Content may be re-shared by others.</span></li>
      </ul>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Subject to your account settings, any Content that you make public is searchable by other Users and subject to use under our Terms of Use, which incorporates the terms of this Privacy Policy.</span></li>
      </ul>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">If you remove information that you posted to the Service, copies may remain viewable in cached and archived pages of the Service, or if other Users or third-parties using the Service have copied or saved that information.</span></li>
      </ul>
      <p>&nbsp;</p>
      <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">What happens in the event of a change of control:</span></p>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">If we sell or otherwise transfer part or the whole of Get In or our assets to another organization (e.g., in the course of a transaction like a merger, acquisition, bankruptcy, dissolution, liquidation), your information such as name, email address, and telephone number, Content and any other information collected through the Service may be among the items sold or transferred. You will continue to own your Content. The buyer or transferee will have to honor the commitments we have made in this Privacy Policy.</span></li>
      </ul>
      <p>&nbsp;</p>
      <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Responding to legal requests and preventing harm:</span></p>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">We may access, preserve and share your information in response to a legal request (like a search warrant, court order or subpoena) if we have a good faith belief that the law requires us to do so. This may include responding to legal requests from jurisdictions outside of the United States where we have a good faith belief that the response is required by law in that jurisdiction, affects Users in that jurisdiction, and is consistent with internationally recognized standards. We may also access, preserve and share information when we have a good faith belief it is necessary to: detect, prevent and address fraud and other illegal activity; to protect ourselves, you and others, including as part of investigations; and to prevent death or imminent bodily harm. Information we receive about you may be accessed, processed and retained for an extended period of time when it is the subject of a legal request or obligation, governmental investigation, or investigations concerning possible violations of our terms or policies, or otherwise to prevent harm.</span></li>
      </ul>
      <p>&nbsp;</p>
      <ol style="margin-top: 0; margin-bottom: 0;">
      <li><strong><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;How We Store Your Information</span></strong></li>
      </ol>
      <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Storage and Processing</span></p>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Your information collected through the Service may be stored and processed in the United States or any other country in which Get In, its Affiliates or Service Providers maintain facilities.</span></li>
      </ul>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Get In, its Affiliates, or Service Providers may transfer information that we collect about you, including personal information across borders and from your country or jurisdiction to other countries or jurisdictions around the world. If you are located in the European Union or other regions with laws governing data collection and use that may differ from United States law, please note that we may transfer information, including personal information, to a country and jurisdiction that does not have the same data protection laws as your jurisdiction.</span></li>
      </ul>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">By registering for and using the Service you consent to the transfer of information to the United States or to any other country in which Get In, its Affiliates or Service Providers maintain facilities and the use and disclosure of information about you as described in this Privacy Policy.</span></li>
      </ul>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">We use commercially reasonable safeguards to help keep the information collected through the Service secure and take reasonable steps (such as requesting a unique password) to verify your identity before granting you access to your account. However, Get In cannot ensure the security of any information you transmit to Get In or guarantee that information on the Service may not be accessed, disclosed, altered, or destroyed.</span></li>
      </ul>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Please do your part to help us. You are responsible for maintaining the secrecy of your unique password and account information, and for controlling access to emails and messages between you and Get In, at all times. Your privacy settings may also be affected by changes the social media services you connect to Get In make to their services. We are not responsible for the functionality, privacy, or security measures of any other organization.</span></li>
      </ul>
      <p>&nbsp;</p>
      <ol style="margin-top: 0; margin-bottom: 0;">
      <li><strong><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Your Choices About Your Information</span></strong></li>
      </ol>
      <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Your account information and settings:</span></p>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Update your account at any time by logging in and changing your account settings.</span></li>
      </ul>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Unsubscribe from email communications from us by clicking on the &ldquo;unsubscribe link&rdquo; provided in such communications. As noted above, you may not opt out of Service-related communications (e.g., account verification, purchase and billing confirmations and reminders, changes/updates to features of the Service, technical and security notices).</span></li>
      </ul>
      <p>&nbsp;</p>
      <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">How long we keep your Content:</span></p>
      <p>&nbsp;</p>
      <ul style="margin-top: 0; margin-bottom: 0;">
      <li><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Following termination or deactivation of your account, Get In, its Affiliates, or its Service Providers may retain information (including your account information) and Content for a commercially reasonable time for backup, archival, and/or audit purposes.</span></li>
      </ul>
      <p>&nbsp;</p>
      <ol style="margin-top: 0; margin-bottom: 0;">
      <li><strong><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Other Web Sites and Services</span></strong></li>
      </ol>
      <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">We are not responsible for the practices employed by any websites or services linked to or from our Service, including the information or content contained within them. Please remember that when you use a link to go from our Service to another website or service, our Privacy Policy does not apply to those third-party websites or services. Your browsing and interaction on any third-party website or service, including those that have a link on our website, are subject to that third party's own rules and policies. In addition, you agree that we are not responsible and do not have control over any third-parties that you authorize to access your Content. If you are using a third-party website or service and you allow them to access your Content you do so at your own risk.</span></p>
      <p>&nbsp;</p>
      <ol style="margin-top: 0; margin-bottom: 0;">
      <li><strong><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Children&rsquo;s Privacy</span></strong></li>
      </ol>
      <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Get In does not knowingly collect or solicit any information from anyone under the age of 13, or anyone under the age of 18 who has not received permission from a parent or legal guardian to use the Service.&nbsp; Get In does not knowingly allow such persons to register for the Service. The Service and its content are not directed at children under the age of 13, or to children under the age of 18 who have not received permission from a parent or legal guardian to use the Service.&nbsp; In the event that we learn that we have collected personal information from a child under age 13, or a child under the age of 18 who has not received permission from a parent or legal guardian to use the Service, we will delete that information as quickly as possible. If you are a parent or legal guardian and think either that your child under 13 has given us information, or that your child under 18 has used the Service and given us information without your permission, please </span><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000 !important; background-color: #ffff00; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">contact us at:</span><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp; .Support@get-in.com</span></p>
      <p>&nbsp;</p>
      <ol style="margin-top: 0; margin-bottom: 0;">
      <li><strong><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">How to Contact Us</span></strong></li>
      </ol>
      <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">If you have any questions about this Privacy Policy or the Service, please </span><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000 !important; background-color: #ffff00; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">contact us at:</span><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp; Support@get-in.com</span></p>
      <p>&nbsp;</p>
      <ol style="margin-top: 0; margin-bottom: 0;">
      <li><strong><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Changes to Our Privacy Policy</span></strong></li>
      </ol>
      <p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: 'Times New Roman'; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Get In may modify or update this Privacy Policy from time to time, so please review it periodically. We may provide you additional forms of notice of modifications or updates as appropriate under the circumstances. Your continued use of Get In or the Service after any modification to this Privacy Policy will constitute your acceptance of such modification.</span></p>
      <p><br /><br /></p>
    </div>
  </div>
  