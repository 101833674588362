import { GlobalsService } from '../globals';
import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, UntypedFormControl } from '@angular/forms';

@Directive({
  selector: '[appPhoneNumber]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: PhoneNumberDirective, multi: true }
  ]
})
export class PhoneNumberDirective {
@Input('appPhoneNumber') phoneNumber: string;

constructor(private globals : GlobalsService) { }

validate(c: UntypedFormControl): { [key: string]: any } {
  // console.log('appPhoneNumber', c.value, c);
  const v = c.value;
  if (v != null && v !== '') {
    const hasError = this.globals.hasErrorOnPhoneNumber(false, v);
    return hasError ? { phoneNumber: true } : null;
  } else {
    return { phoneNumber: true };
  }
}

}
