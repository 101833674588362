<!--SUCCESS-->
<section *ngIf="order_status == 1 && payment_method && purchaser_type && purchase_validation" class="order_status" (window:beforeunload)="tabClose($event)">

	<!-- payment_method: (1) credit, (2) free, (3) cash, (4) register -->
	<!-- purchase_validation = (2) purchaser approved, (1) purchaser pending approval -->
	<!-- purchaser_type: (1) main purchaser - paid for order, (2) sub purchaser - didn't pay. -->
	<!-- credit - success order w/ or w/out text -->
	<!-- free - success order w/ or w/out text -->
	<!-- cash - success order no text -->
	<!-- reg - success reg w/ or w/out text -->

	<div class="block_container">

    <div class="event-info-wrap">
      <div class="event-info-container" *ngIf="eventInfo">
        <a class="event-info-img" [routerLink]="['/'+event_id]" [ngStyle]="{
        'backgroundImage': 'url(' +  (eventInfo.file ? (baseEventImgUrl + eventInfo.file) : '/assets/images/svg-icons/logo-new.svg') + ')',
        'backgroundSize': eventInfo.file ? 'cover' : 'contain'}">
        </a>
        <div class="event-descr">
          <p class="event-info-name">{{eventInfo.event_name}}</p>
          <p class="event-info-address" matTooltip="{{eventInfo.event_address}}">
            <img [src]="'assets/images/svg-icons/pointonmap.svg'" alt="point">
            {{eventInfo.event_address}}</p>
          <p class="event-info-date">
            <img [src]="'assets/images/svg-icons/calendar.svg'" alt="date">
            {{eventInfo.event_start_date | date:'short'}} - {{eventInfo.event_end_date | date:'short'}}</p>
        </div>
      </div>
      <a class="close-btn" (click)="navigateTo('/')"><img src="/assets/images/svg-icons/close.svg"></a>
    </div>

    <div class="blur-wrap" *ngIf="purchaseInfoFetched">
      <div class="img-wrap">
        <img class="img-responsive" src="/assets/images/svg-icons/order-success.svg">
      </div>
      <div class="">
        <h1 *ngIf="purchase_validation == 2"><span i18n="success order | got tickets">You've got the tickets</span><span [innerHtml]="party_emoji"></span></h1>
        <h1 *ngIf="purchase_validation == 1 && !waiting_list_purchase_ticket"><span i18n="success order | pending tickets">Your Tickets are Pending Final Approval</span></h1>
        <h1 *ngIf="purchase_validation == 1 && waiting_list_purchase_ticket" style="margin-bottom: 15px;"><span t key="SuccesfullOrder.WaitingList2" default="YOU ARE ON THE WAITING LIST">YOU ARE ON THE WAITING LIST</span></h1>

<!--        <h1 *ngIf="payment_method <= 3"><span i18n="success order | successful order">Registration successful!</span><span [innerHtml]="party_emoji"></span></h1>-->
<!--        <h1 *ngIf="payment_method == 4"><span i18n="success order | successful registration">Your order has been accepted successfully!</span><span [innerHtml]="party_emoji"></span></h1>-->
      </div>
      <div class="">
        <p *ngIf="purchase_validation == 1 && payment_method <= 3" class="desc">
<!--          <span i18n="success order | order pending approval">Your invitation is pending final approval from event's organizers</span><br>-->
<!--          <span i18n="success order | account billed" *ngIf="purchaser_type == 1 && payment_method != 2">Your account will be billed accordingly after being granted approval.</span>-->
          <span i18n="success order | order pending approval v2" *ngIf="!waiting_list_purchase_ticket">Thank you for your purchase! Please note that your tickets are currently pending final approval. We will send you an update as soon as they are confirmed. Thank you for your patience.</span>
          <span *ngIf="waiting_list_purchase_ticket" t key="SuccesfullOrder.WaitingListDetailed2" default="⏰ once tickets become available you’ll be notified via email, text and in app.">
            ⏰ once tickets become available you’ll be notified via email, text and in app.</span>
        </p>
        <p i18n="success order | emailed details" *ngIf="payment_method == 4 && purchase_validation == 2" class="desc">Confirmation will be sent to your email address <br>See you there!</p>
        <p i18n="success order | registration pending approval" *ngIf="payment_method == 4 && purchase_validation == 1" class="desc">Your registration is pending final approval from event's organizers</p>
      </div>

      <div class="btns" [ngClass]="{'iOS' : globals.isIosNew()}">
        <ng-container *ngIf="purchase_validation == 1">
          <a (click)="navigateTo('/')" class="btn-white" i18n="success order | discover btn"
             *ngIf="!waiting_list_purchase_ticket">
            discover more events
          </a>
          <a (click)="navigateTo('/')" class="btn-white"
             *ngIf="waiting_list_purchase_ticket"
             t key="SuccesfullOrder.WaitingListContinue"
             default="Continue">
            Continue
          </a>
        </ng-container>
        <ng-container *ngIf="purchase_validation == 2">
          <a (click)="navigateTo('/my-tickets')" [attr.data-cy]="'viewTicketsButton'" class="btn-gray"
            i18n="success order | view btn">
            View ticket details
          </a>
          <ng-container  *ngIf="globals.isIosNew()"><!--      SHOW 'add-to-apple-wallet' ONLY on iOS Devices-->
<!--            <a *ngIf="main_purchase_identifier.length && !isNft"-->
            <a *ngIf="main_purchase_identifier.length && add_ticket_to_apple_wallet.length"
               class="button-add-to-apple-wallet-link"
               href="{{add_ticket_to_apple_wallet}}" target="_blank">
              <img src="/assets/images/svg-icons/add_to_apple_wallet.svg" alt="" style="height: 50px;">
            </a>
          </ng-container>
        </ng-container>
      </div>
    </div>

	</div>

</section>
<!--FAILED-->
<section *ngIf="order_status == 2" class="order_status">

	<div class="block_container">

    <div class="event-info-wrap">
      <a class="close-btn" (click)="navigateTo('/')"><img src="/assets/images/svg-icons/close.svg"></a>
    </div>

    <div class="blur-wrap">
      <div class="img-wrap">
        <img class="img-responsive" src="/assets/images/svg-icons/order-failed.svg">
      </div>
      <div class="">
        <h1 i18n="@@SuccessfulOrderOrderfailedTryagain">Order failed. Try again.</h1>
        <p  *ngIf="tranzilla_error" class="desc"><span i18n="@@SuccessfulOrderDetails">Details:</span> {{tranzilla_error}}</p>
      </div>
      <div class="btns">
        <a (click)="navigateTo('/'+event_id)" class="btn-white" i18n="success order | try btn">
          Try again
        </a>
      </div>
    </div>

	</div>

</section>

<!--ERROR-->
<section *ngIf="!payment_method && !purchaser_type && !purchase_validation" class="order_status">
	<div class="block_container">

    <div class="event-info-wrap">
      <a class="close-btn" (click)="navigateTo('/')"><img src="/assets/images/svg-icons/close.svg"></a>
    </div>

    <div class="blur-wrap">
      <div class="img-wrap">
        <img class="img-responsive" src="/assets/images/svg-icons/order-failed.svg">
      </div>
      <div class="">
        <h1 i18n="page title | error">Error</h1>
        <p i18n="@@SuccessfulOrderOopsError" class="desc">Oops... An error has occured while loading the page. Please contact the producer to ask about the status of your order.</p>
      </div>
      <div class="btns">
        <a (click)="navigateTo('/'+event_id)" class="btn-white" i18n="success order | try btn">
          Try again
        </a>
      </div>
    </div>

	</div>
</section>

<!--PENDING-->
<section *ngIf="order_status == 3" class="order_status">
  <div class="block_container">

    <div class="event-info-wrap">
      <a class="close-btn" (click)="navigateTo('/')"><img src="/assets/images/svg-icons/close.svg"></a>
    </div>

    <div class="blur-wrap">
      <div class="img-wrap">
        <img style="width: 130px;" class="img-responsive" src="/assets/images/svg-icons/menu-info-circle.svg">
      </div>
      <div class="">
        <h1 i18n="page title | processing">Your order is processing</h1>
        <p i18n="page sub title | processing desc" class="desc">Please check your phone/email for further information. We’ll keep you updated there</p>
      </div>
      <div class="btns">
        <a (click)="navigateTo('/')" class="btn-white" i18n="success order | home">
          Home
        </a>
      </div>
    </div>

  </div>
</section>
