<div class="blur-section" style="width:100vw; height: 100vh; position:absolute; top:0; left:0; filter: blur(63px);" [ngStyle]="{'background-image': 'linear-gradient(180deg, rgba(8, 4, 22, 0.62) -35%, #080416 100%),'+bgPreview}"></div>
<div>
  <div *ngIf="false" class="event-navbar" [ngClass]="(stepTemplate !== 'templateDescription')? 'menu-hide':''">
    <div class="main-menu" (click)="sideNavToggle()"></div>
    <div class="language-bar">
      <div class="languages" (mouseover)="langShow=!langShow" (mouseout)="langShow=false" (click)="langShow=!langShow">
        <div *ngFor="let language of languages" class="border-dir"
          [ngClass]="dir ==='ltr' ? 'ltr-dir' : 'rtl-dir'"
          [ngStyle]="{'order': localeId !== language.lang ? '1' : '0'}">
          <div [ngStyle]="{'display': localeId !== language.lang && !langShow ? 'none' : 'block'}"
            [ngClass]="localeId === language.lang? 'language-active' : 'language-inactive'" class="language"
            (click)="changeLanguage(language.lang)">{{language.lang}}</div>
          <!-- [href]="language.urlLink+currentRoute" -->
        </div>
      </div>
    </div>

  </div>



  <div class="lp" id="previewSection" #previewSection>
    <!-- <div class="blur-section" [ngStyle]="{'background-image': 'linear-gradient(180deg, rgba(8, 4, 22, 0.22) 0%, #080416 100%),'+bgPreview}"></div> -->
    <!-- [ngStyle]="isMobile ? {'background-image': 'linear-gradient(180deg, rgba(8, 4, 22, 0.22) 0%, #080416 100%),'+bgPreview,  'background-size': 'cover !important', 'background-repeat': 'no-repeat', 'background-position': 'center top', 'background-attachment': 'fixed'} : {}" -->
    <section *ngIf="event_details" class="bg-preview-2" [ngClass]="{'es' : localeId === 'es', 'bg-preview-instagram_browser': isInstagramBrowser}">
      <div class="bar-header" [ngStyle]="{'position': (stepTemplate !== 'templateDescription') ? 'relative' : 'unset'}">
        <div class="dialog-header" *ngIf="stepTemplate !== 'templateDescription'" [ngClass]="{'seats-header' : stepTemplate == 'templateSeats'}">
          <div class="close-dialog-arrow-3" [ngStyle]="dir == 'rtl' ? {'transform':'rotate(180deg)'} : {}"
              *ngIf="!sendSmsTimer">
            <!-- <svg *ngIf="stepTemplate === 'templateLogin'" (click)="backStep(stepTemplate)"
                                    class="arrow-close-modal" width="10" height="14" viewBox="0 0 10 14"
                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9.49219 1.64063L4.13281 7L9.49219 12.3594L7.85156 14L0.851562 7L7.85156 0L9.49219 1.64063Z"
                                        fill="white" />
                                </svg> -->
            <svg
              *ngIf="(stepTemplate !== 'templateDescription' && stepTemplate !== 'templateTerms' && stepTemplate !== 'templatePrivacy' ) && isShowBackArrow"
              (click)="backStep(stepTemplate)" style="margin: 0;" class="arrow-close-modal" width="10"
              height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.49219 1.64063L4.13281 7L9.49219 12.3594L7.85156 14L0.851562 7L7.85156 0L9.49219 1.64063Z"
                fill="white" />
            </svg>
            <svg *ngIf="stepTemplate === 'templateTerms' || stepTemplate === 'templatePrivacy'"
                 (click)="stepTemplate === 'templateTerms' ? getTerms() : getPrivacy()" class="arrow-close-modal"
              style="margin: 0;" width="10" height="14" viewBox="0 0 10 14" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.49219 1.64063L4.13281 7L9.49219 12.3594L7.85156 14L0.851562 7L7.85156 0L9.49219 1.64063Z"
                fill="white" />
            </svg>
          </div>
        </div>
        <div [ngStyle] ="dir !== 'rtl' ? {'left': '60px'}: {'right': '60px'}" [ngClass]="(stepTemplate === 'templateDescription')? 'section-name-hide':'section-name'">
          <div i18n="go back section | title" *ngIf="stepTemplate === 'templateTickets'">Select tickets</div>
          <div i18n="go back section | title" *ngIf="stepTemplate === 'templateGuest'">Attendees</div>
          <div i18n="go back section | title" *ngIf="stepTemplate === 'templateOrder'">Choose payment method</div>
          <div i18n="go back section | title" *ngIf="stepTemplate === 'templateTerms'">Terms</div>
          <div *ngIf="stepTemplate === 'templatePrivacy'">Privacy Policy</div>
          <div *ngIf="stepTemplate === 'templateWaitedTicketsTerms'">Waiting list</div>
        </div>
        <div *ngIf="stepTemplate !== 'templateDescription' && stepTemplate !== 'templateSeats' && !sendSmsTimer" class="event-manage">
          <div class="close-event" (click)="closeEvent()">
            <app-go-back [disableNavigate]="true" [hideDialogArrow]="stepTemplate !== 'templateDescription' && isMobile" [closeArrow]="(stepTemplate === 'templateDescription')? null : true"></app-go-back>
          </div>
        </div>
<!--        close seats only-->
        <div *ngIf="stepTemplate === 'templateSeats'" class="event-manage">
          <div class="close-event 2" (click)="closeEvent2()">
            <app-go-back [disableNavigate]="true" [hideDialogArrow]="false" [closeArrow]="(stepTemplate === 'templateDescription')? null : true"></app-go-back>
          </div>
        </div>

      </div>
      <div *ngIf="stepTemplate === 'templateDescription'" class="event-manage-desc">
        <div class="close-event-desc">
          <app-go-back [hideDialogArrow]="stepTemplate !== 'templateDescription' && isMobile" [closeArrow]="(stepTemplate === 'templateDescription')? null : true"></app-go-back>
        </div>
        <div class="event-lng-container" (click)="toggleBoxLng()" [ngStyle]="{'background-color': isMobile && showlngBox ? '#0000008c':''}">
          <div class="lng-container-align">
            <div class="event-lng">{{localeId}}</div>
            <div class="event-lng-flag">
                <img class="flag-img" [src]="currentLngFlag?.imgLink">
            </div>
          </div>
          <div *ngIf="showlngBox" class="box-languages">
            <div class="box-language" *ngFor="let language of languages ;let i = index" [ngStyle]="{'background-color': language?.lang == localeId ? '#322956':'#16112B'}" (click)="setNewLanguage(language.lang)">
              <div class="box-language-flag">
                <img class="flag-img" [src]="language?.imgLink">
              </div>
              <div class="box-language-title">{{language?.langName}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="wrapp" [ngClass]="(stepTemplate == 'templateSeats')?'seats-full-width':''">
        <!-- <div class="wrapp-content-view"> -->
          <div [ngClass]="(stepTemplate !== 'templateDescription')?'main-image-hide':'main-2'">
            <div class="main-wrapper">
              <div class="image-wrapper-3">
                <div class="img-gradient">
                  <img class="cover-image" *ngIf="coverPreview" [src]="coverPreview">
                  <div class="cover-image-placeholder-wrap" *ngIf="!coverPreview && coverPreviewDefault">
                    <img class="cover-image-placeholder" [src]="coverPreviewDefault">
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div [ngClass]="(stepTemplate !== 'templateDescription' && stepTemplate !== 'templateSeats')?'main-image-show':'main-image-hide'">
            <div class="little-title-section">
              <div class="ticket-image">
                <img class="little-cover-image" *ngIf="coverPreview" [src]="coverPreview">
                <div class="cover-image-placeholder-wrap-new" *ngIf="!coverPreview && coverPreviewDefault">
                  <img class="cover-image-placeholder-new" [src]="coverPreviewDefault">
                </div>
              </div>
              <div class="new-ticket-desc">
                <div class="ticket-title-next">{{event_details.title}}</div>
                <div class="main-title"
                [ngClass]="[stepTemplate === 'templateDescription'?'new-desc-title-show':'new-desc-title-hide']">
                <div class="new-address-section">
                  <!-- <div class="ticket-title">{{event_details.title}}</div> -->
                  <div *ngIf="isBottomTitle && event_details.title" class="main-title-item">
                    <div class="title-icon">
                      <img class="map-point-img" [src]="'assets/images/svg-icons/pointonmap.svg'" alt="point">
                    </div>
                    <div class="title-address-next">{{(event_details.address) ? event_details.address : event_details.location_desc}}</div>
                    <!-- <span i18n="view map button | link to view map" class="view-map">View Map</span> -->
                  </div>
                  <div *ngIf="isBottomTitle && event_details.title" class="main-title-item">
                    <div class="title-icon">
                      <img class="calendar-img" [src]="'assets/images/svg-icons/calendar.svg'" alt="date">
                    </div>
                    <div class="title-address-next">{{eventStartDate | date:'mediumDate':'': localeId}}&nbsp;<div class="event-time-next">
                        {{event_details.full_start_date | date: 'shortTime'}}</div>
                    </div>
                  </div>
                  <div *ngIf="event_details.min_age && event_details.age_display" class="main-title-item">
                    <div class="title-icon">
                      <img class="info-img" [src]="'assets/images/svg-icons/info.svg'" alt="age limitation">
                    </div>
                    <div class="title-age-txt">{{'EventPage.ExclusiveFor' | translate:'Exclusive for'}}&nbsp;
                      <span class="title-age-number">{{event_details.min_age}}+</span>
                    </div>
                  </div>
                </div>
              </div>
              </div>

            </div>
            <!-- Added template Order -->
            <div class="boxMargin bglgrd-mob" [@fade] *ngIf="stepTemplate === 'templateGuest' || stepTemplate === 'templateOrder' || stepTemplate === 'templatePay'">
              <div class="order-summary">
                <ng-container *ngTemplateOutlet="templateOrderSummary"></ng-container>
              </div>
            </div>
          </div>
          <!-- [ngStyle]="{'background': description?.value ? 'transparent' : 'linear-gradient(180deg, #24283100 0%, #22262f 10%, #22262f 100%)' }" -->
          <div class="wrapp-content-2">
<!--            <div class="main-event-wrapper" [ngClass]="[stepTemplate === 'templateDescription'?'main-event-position':'']">-->
            <div class="main-event-wrapper" [ngClass]="{
              'main-event-position': stepTemplate === 'templateDescription',
              'seats-full-width-wrapp': stepTemplate === 'templateSeats'}">
              <div class="main-tickets-wrapper-nui"
                   [ngClass]="{
              'main-tickets-no-bkg': (description && description.value),
              'main-tickets-bkg': (!description),
              'seats-main-tickets-wrapper-nui': stepTemplate === 'templateSeats',
              'hyper-increase-height': hyper}">
                <div class="main-title_"
                  [ngClass]="[stepTemplate === 'templateDescription'? 'desc-title-show main-title':'desc-title-hide new-desc-title-hide']">
                  <div class="tickets-title-nui" [ngClass]="[stepTemplate === 'templateDescription'?'main-event-position':'']">
                    <div class="ticket-title">{{event_details.title}}</div>
                    <!-- <div *ngIf="isBottomTitle" class="title-address">
                                          {{(event_details.address) ? event_details.address : event_details.location_desc}}
                                          <span i18n="view map button | link to view map" class="view-map">View Map</span>
                                      </div>
                                      <div *ngIf="isBottomTitle" class="title-address">{{event_details.start_date}}
                                          {{event_details.start_time}}
                                      </div> -->
                  </div>
                </div>
                <!-- template-wrapper -->
                <div class="templateOrder " [ngClass]="event_details.title && (stepTemplate != 'templateDescription' && stepTemplate != 'templateLoginRegistration') ? 'bglgrd-desk' : ''">

                  <div id="templateBox" class="templateBox" #templateBox>
                    <div [@fade] *ngIf="stepTemplate === 'templateDescription'">
                      <ng-container *ngTemplateOutlet="templateDescription"></ng-container>
                    </div>
                    <div class="boxMargin bglgrd-mob" [@fade] *ngIf="stepTemplate === 'templateOrder'">
                      <ng-container *ngTemplateOutlet="templateOrder"></ng-container>
                    </div>
                    <div class="boxMargin bglgrd-mob" [@fade] *ngIf="stepTemplate === 'templateTickets'"
                      style="height: auto">
                      <ng-container *ngTemplateOutlet="templateTickets"></ng-container>
                    </div>

                    <div class="boxMargin bglgrd-mob" [@fade] *ngIf="stepTemplate === 'templateSeatsTickets'"
                         style="height: auto">
                      <ng-container *ngTemplateOutlet="templateSeatsTickets"></ng-container>
                    </div>
                    <div style="min-height: 360px;" [ngStyle]="{'display': showSeatsDisplay ? 'block' : 'none' }" [ngClass]="{'iOS' : globals.isIos()}" class="seating-chart" *ngIf="showSeatsIo">
                      <si-seatsio-seating-chart [config]="seatsConfig"></si-seatsio-seating-chart>
                    </div>
                    <div class="boxMargin seats-fixed {{localeId}}" [@fade] *ngIf="stepTemplate === 'templateSeats'">
                      <ng-container *ngTemplateOutlet="templateSeats"></ng-container>
                    </div>
                    <div class="boxMargin" [@fade] *ngIf="stepTemplate === 'templateLoginRegistration'"
                      style="height: 100%">
                      <ng-container *ngTemplateOutlet="templateLoginRegistration"></ng-container>
                    </div>
                    <div class="boxMargin" [@fade] *ngIf="stepTemplate === 'templateUserRegistration'"
                      style="height: 100%">
                      <ng-container *ngTemplateOutlet="templateUserRegistration"></ng-container>
                    </div>
                    <div class="boxMargin bglgrd-mob" [@fade] *ngIf="stepTemplate === 'templateGuest'">
                      <ng-container *ngTemplateOutlet="templateGuest"></ng-container>
                    </div>
                    <div class="boxMargin" [@fade] *ngIf="stepTemplate === 'templateCard'">
                      <ng-container *ngTemplateOutlet="templateCard"></ng-container>
                    </div>
                    <div class="boxMargin" [@fade] [hidden]="stepTemplate !== 'templatePay'">
                      <ng-container *ngTemplateOutlet="templatePay"></ng-container>
                    </div>
                    <div [@fade] *ngIf="stepTemplate === 'templateTerms'">
                      <ng-container *ngTemplateOutlet="templateTerms"></ng-container>
                    </div>
                    <div style="padding: 35px;" [@fade] *ngIf="stepTemplate === 'templatePrivacy'">
                      <ng-container *ngTemplateOutlet="templatePrivacy"></ng-container>
                    </div>
                    <div [@fade] *ngIf="stepTemplate === 'templateNoValidCoupon'">
                      <ng-container *ngTemplateOutlet="templateNoValidCoupon"></ng-container>
                    </div>
                    <div class="boxMargin" [@fade] *ngIf="stepTemplate === 'templateLogin'" style="align-items: center">
                      <ng-container *ngTemplateOutlet="templateLogin"></ng-container>
                    </div>
                    <div class="boxMargin bglgrd-mob" [@fade] *ngIf="stepTemplate === 'templateWaitedTicketsTerms'"
                         style="height: auto">
                      <ng-container *ngTemplateOutlet="templateWaitedTicketsTerms"></ng-container>
                    </div>
                  </div>
                  <!-- BUTTONS -->
                  <div *ngIf="stepTemplate === 'templateDescription'" style="position: relative;">

                    <div
                      *ngIf="!preview_page && sub_purchaser_id <= 0 && (facebook_validation == 0 || togglePurchaseModal) && !seatsRendering"
                      class="buy-ticket-show">
                      <ng-container *ngIf="!waiting_list_mode">
                        <div class="buy-ticket-btn-nui" #buy_ticket_btn_nui [ngClass]="{'instagram_browser': isInstagramBrowser, 'timerTimestamp': timerTimestamp && (ticketsAvailability !== 1 && ticketsAvailability !== 'register_only') }"
                             [attr.data-cy]="'orderTicketsButton'" (click)="((ticketsAvailability === 1 || ticketsAvailability == 'register_only') && !showSeatsIo) ? switchStep('Tickets') : (showSeatsIo && ticketsAvailability === 1) ? switchStep('Seats') : switchStep('');rudderstackBuyTickets()">
                        <span i18n="event btn | purchase" *ngIf="ticketsAvailability == 1 && !purchase_passcode_mode" #orderticket
                              id="orderticket" class="icon-right">Order ticket</span>
                          <span *ngIf="ticketsAvailability == 1 && purchase_passcode_mode && !purchase_passcode" #orderticket
                                id="orderticket">Access locked tickets</span>
                          <span i18n="event btn | no tickets"
                                *ngIf="ticketsAvailability == 0 && !timerTimestamp && !ticketsSoldOut" #orderticket
                                id="orderticket">No available tickets</span>
                          <span i18n="event btn | registration" *ngIf="ticketsAvailability == 'register_only'" #orderticket
                                id="orderticket">Registration</span>
                          <span i18n="event btn | no available ticket" *ngIf="ticketsSoldOut" class="btn-sold-out">No
                        available tickets</span>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="waiting_list_mode">
<!--                        Waited list order button  - without ticketsAvailability check-->
                        <div *ngIf="!timerTimestamp"
                          class="buy-ticket-btn-nui" #buy_ticket_btn_nui [ngClass]="{'instagram_browser': isInstagramBrowser, 'timerTimestamp': timerTimestamp && (ticketsAvailability !== 1 && ticketsAvailability !== 'register_only') }"
                             [attr.data-cy]="'orderTicketsButton'" (click)="!showSeatsIo ? switchStep('Tickets') : showSeatsIo ? switchStep('Seats') : switchStep('');rudderstackBuyTickets()">
                          <span *ngIf="purchase_passcode_mode && !purchase_passcode" #orderticket id="orderticket">Access locked tickets</span>
                          <span #orderticket id="orderticket" class="icon-right" *ngIf="!purchase_passcode_mode && isAtLeastOneTicketFromWaitingList && !isAtLeastOneAvailableRegularTicket"
                                t key="eventPageComponent.JoinTheWaitingList2Button" default="Join Waiting List">Join Waiting List</span>
                          <span #orderticket id="orderticket" *ngIf="!purchase_passcode_mode && isAtLeastOneAvailableRegularTicket"
                                t key="eventPageComponent.OrderNowButton" default="Order Now" class="icon-right">Order Now</span>
                        </div>
                      </ng-container>
                      <app-countdown-timer
                        *ngIf="timerTimestamp && (ticketsAvailability !== 1 && ticketsAvailability !== 'register_only')"
                        [countDownTimestamp]="timerTimestamp"></app-countdown-timer>
                    </div>

                    <div
                      *ngIf="!preview_page && sub_purchaser_id <= 0 && (facebook_validation == 1 && !togglePurchaseModal)"
                      class="buy-ticket-show">
                      <div class="buy-ticket-btn-nui"
                        (click)="(ticketsAvailability === 1 || ticketsAvailability == 'register_only')? FBLogin(null, 'Tickets') : FBLogin()">
                        <span i18n="event btn | purchase" *ngIf="ticketsAvailability == 1 && !purchase_passcode_mode" #orderticket
                          id="orderticket" class="icon-right">Order ticket</span>
                        <span *ngIf="ticketsAvailability == 1 && purchase_passcode_mode && !purchase_passcode" #orderticket
                              id="orderticket">Access locked tickets</span>
                        <span i18n="event btn | no tickets"
                          *ngIf="ticketsAvailability == 0 && !timerTimestamp && !ticketsSoldOut" #orderticket
                          id="orderticket">No available tickets</span>
                        <span i18n="event btn | registration" *ngIf="ticketsAvailability == 'register_only'" #orderticket
                          id="orderticket">Registration</span>
                        <span i18n="event btn | no available ticket" *ngIf="ticketsSoldOut" class="btn-sold-out">No
                        available tickets</span>
                      </div>
                      <app-countdown-timer
                        *ngIf="timerTimestamp && (ticketsAvailability !== 1 && ticketsAvailability !== 'register_only')"
                        [countDownTimestamp]="timerTimestamp"></app-countdown-timer>
                    </div>

                    <div *ngIf="preview_page" class="buy-ticket-show">
                      <div class="buy-ticket-btn-nui">
                        <span i18n="@@EventPageExamplebutton">Example button</span>
                      </div>
                    </div>

                  </div>
                  <!-- END BUTTONS -->

                  <div *ngIf="stepTemplate === 'templatePay' && !isCloseWallet">
                    <div class="purchase-btn-nui fixed-btn-nui" *ngIf="trnBtnStatus"
                      [style]="trnBtnStatus ? 'opacity: 1; cursor: pointer;' : 'opacity: 0.5; cursor: not-allowed;'"
                      (click)="trnBtnStatus ? walletConnectPay(true) : null">
                      <div i18n="payment method button | Send Payment" class="button-text-upper-nui">Continue</div>
                      <div class="purchase-btn-total"
                      [ngStyle]="{'border-right': dir == 'rtl' ? '1px solid #A29BB1' : 'none',
                                        'border-left': dir == 'rtl' ? 'none' : '1px solid #A29BB1'}">
                      <p class="purchase-btn-total-tickets">{{quantity}}&nbsp;<span i18n="purchase-btn-total">{quantity,
                          plural, =1 {ticket} other {tickets}}</span></p>
                      <p class="purchase-btn-total-price">{{total_price_w_commission | currency:
                        currency:currency === 'MXN' || currency === 'CAD' ? 'symbol': 'symbol-narrow':'1.0-2':getLocale(currency)}}</p>
                    </div>
                    </div>
                  </div>
                  <div *ngIf="stepTemplate === 'templatePay' && !isCloseSolanaWallet">
                    <div class="purchase-btn-nui fixed-btn-nui " *ngIf="trnSolBtnStatus"
                      [style]="trnSolBtnStatus ? 'opacity: 1; cursor: pointer;' : 'opacity: 0.5; cursor: not-allowed;'"
                      (click)="trnSolBtnStatus ? solanaConnectPay(true) : null">
                      <div i18n="payment method button | Send Payment" class="button-text-upper-nui">Continue</div>
                      <div class="purchase-btn-total"
                      [ngStyle]="{'border-right': dir == 'rtl' ? '1px solid #A29BB1' : 'none',
                                        'border-left': dir == 'rtl' ? 'none' : '1px solid #A29BB1'}">
                      <p class="purchase-btn-total-tickets">{{quantity}}&nbsp;<span i18n="purchase-btn-total">{quantity,
                          plural, =1 {ticket} other {tickets}}</span></p>
                      <p class="purchase-btn-total-price">{{total_price_w_commission | currency:
                        currency:currency === 'MXN' || currency === 'CAD' ? 'symbol': 'symbol-narrow':'1.0-2':getLocale(currency)}}</p>
                    </div>
                    </div>
                  </div>
                  <!-- <div *ngIf="stepTemplate === 'templateTickets'">
                    <div class="purchase-btn-nui fixed-btn-nui" *ngIf="quantity && showPurchaseBtn"
                          (click)="switchStep('LoginRegistration')">
                          <div class="button-text-upper-nui">
                            <p i18n="payment button | continue" class="purchase-btn-continue mb0">continue</p>
                          </div>
                          <div class="purchase-btn-total"
                                [ngStyle]="{'border-right': dir == 'rtl' ? '1px solid rgba(255, 255, 255, 0.3)' : 'none',
                                'border-left': dir == 'rtl' ? 'none' : '1px solid rgba(255, 255, 255, 0.3)'}">
                            <p class="purchase-btn-total-tickets">{{quantity}}&nbsp;<span i18n="purchase-btn-total">{quantity, plural, =1 {ticket} other {tickets}}</span></p>
                            <p class="purchase-btn-total-price">{{total_price_n_commission | currency: currency:'symbol-narrow':'1.0':getLocale(currency)}}</p>
                          </div>
                    </div>
                  </div> -->
                  <div *ngIf="stepTemplate === 'templateTickets' || stepTemplate === 'templateSeatsTickets'">
                    <div [attr.data-cy]="'TicketSelectContinueButton'"
                      class="purchase-btn-nui fixed-btn-nui fixed-btn-position {{isInstagramBrowser?'instagram_browser_continue':''}}"
                      *ngIf="quantity && showPurchaseBtn"
                      (click)="switchStep(hasWaitedTicket ?'WaitedTicketsTerms':'Guest');liveCart()">
                        <!-- <div i18n="payment method button | continue" class="button-text-upper-nui">continue
                          <span *ngIf="globals.shouldBePlural(quantity)" class="button-text-nui">({{quantity}}&nbsp;tickets)</span>
                          <span *ngIf="!globals.shouldBePlural(quantity)" class="button-text-nui">({{quantity}}&nbsp;ticket)</span>
                        </div> -->
                      <div class="button-text-upper-nui">
                        <p i18n="payment button | continue" class="purchase-btn-continue mb0 1" *ngIf="!hasWaitedTicket">continue</p>
                        <p t key="eventPageComponent.JoinTheWaitingListButton" default="JOIN THE WAITING LIST" class="purchase-btn-continue icon-right join-waited-btn mb0 1" *ngIf="hasWaitedTicket"></p>
                      </div>
                      <div class="purchase-btn-total"
                        [ngStyle]="{'border-right': dir == 'rtl' ? '1px solid #A29BB1' : 'none',
                                          'border-left': dir == 'rtl' ? 'none' : '1px solid #A29BB1'}">
                        <p class="purchase-btn-total-tickets">{{quantity}}&nbsp;<span i18n="purchase-btn-total">{quantity,
                            plural, =1 {ticket} other {tickets}}</span></p>
                        <p class="purchase-btn-total-price" *ngIf="stepTemplate === 'templateTickets'">{{(show_price_with_fees?total_price_w_commission:total_price_n_commission) | currency:
                          currency:currency === 'MXN' || currency === 'CAD' ? 'symbol': 'symbol-narrow':'1.0-2':getLocale(currency)}}</p>
                        <p class="purchase-btn-total-price" *ngIf="stepTemplate === 'templateSeatsTickets'">{{(show_price_with_fees?total_price_w_commission:total_price_n_commission) | currency:
                          currency:currency === 'MXN' || currency === 'CAD' ? 'symbol': 'symbol-narrow':'1.0-2':getLocale(currency)}}</p>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="stepTemplate === 'templateCard' || (cardTemplate === 'templateCardInner')">
                    <div class="order-purchase-section">
                      <div class="purchase-btn-nui fixed-btn-nui saved-card" (click)="switchStep('Pay');rudderstackStartedCheckout();">
                        <!-- <div class="button-text-upper-nui">PURCHASE</div> -->
                        <span i18n="proceed to payment button" *ngIf="!canUseCash && !freeItem && !item_is_reg"
                          class="button-text-upper-nui">CONFIRM PURCHEST
                          <span>&nbsp;{{total_price_w_commission | currency:
                            currency:'symbol':'1.':getLocale(currency)}}</span>
                        </span>
                        <span i18n="@@EventPagePaymentbycredit" *ngIf="canUseCash && !freeItem && !item_is_reg"
                          class="button-text-upper-nui">Payment by credit</span>
                        <span i18n="@@EventPageRegistration" *ngIf="item_is_reg"
                          class="button-text-upper-nui">Registration</span>
                        <span i18n="@@EventPageFreeAdmission" *ngIf="!item_is_reg && freeItem"
                          class="button-text-upper-nui">Free admission</span>
                        <div *ngIf="canUseCash && !freeItem && !item_is_reg">
                          <span i18n="@@EventPagePayWithCash" (click)="toPayment(true)"
                            class="button-text-upper-nui">Pay with cash</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="stepTemplate === 'templateGuest'">
                    <div class="purchase-btn-nui fixed-btn-nui fixed-btn-position" *ngIf="quantity && showPurchaseBtn && false"
                      (click)="switchStep('Order')">
                        <!-- <span i18n="proceed to continue button"
                            *ngIf="!canUseCash && !freeItem && !item_is_reg"
                            class="button-text-upper-nui">continue
                          <span *ngIf="globals.shouldBePlural(quantity)" class="button-text-nui">({{quantity}}&nbsp;tickets)</span>
                          <span *ngIf="!globals.shouldBePlural(quantity)" class="button-text-nui">({{quantity}}&nbsp;ticket)</span>
                        </span> -->
                      <div class="button-text-upper-nui" *ngIf="!canUseCash && !freeItem && !item_is_reg">
                        <p i18n="payment button | continue" class="purchase-btn-continue mb0 2">continue</p>
                      </div>
                      <div class="purchase-btn-total" *ngIf="!canUseCash && !freeItem && !item_is_reg"
                        [ngStyle]="{'border-right': dir == 'rtl' ? '1px solid rgba(255, 255, 255, 0.3)' : 'none',
                                            'border-left': dir == 'rtl' ? 'none' : '1px solid rgba(255, 255, 255, 0.3)'}">
                        <p class="purchase-btn-total-tickets">{{quantity}}&nbsp;<span i18n="purchase-btn-total">{quantity,
                            plural, =1 {ticket} other {tickets}}</span></p>
                        <p class="purchase-btn-total-price">{{total_price_w_commission | currency:
                          currency:currency === 'MXN' || currency === 'CAD' ? 'symbol': 'symbol-narrow':'1.0-2':getLocale(currency)}}</p>
                      </div>
                      <span i18n="proceed to registration button" *ngIf="item_is_reg" class="button-text-upper-nui">Free
                        <span *ngIf="globals.shouldBePlural(quantity)"
                          class="button-text-nui">({{quantity}}&nbsp;tickets)</span>
                        <span *ngIf="!globals.shouldBePlural(quantity)"
                          class="button-text-nui">({{quantity}}&nbsp;ticket)</span>
                      </span>
                    </div>
                  </div>

                  <div *ngIf="stepTemplate === 'templateWaitedTicketsTerms'">
                    <mat-checkbox
                      class="waited-terms-chk"
                      [(ngModel)]="waitedTermsChecked">
                      <span>I accept auto-pay waiting list <a target="_blank" href="https://intercom.help/getin-93198e5c33cd/en/articles/9138031-waiting-list-terms">terms</a></span>
                    </mat-checkbox>
                    <button class="waited-terms-continue purchase-btn-nui fixed-btn-nui fixed-btn-position"
                            (click)="switchStep('Guest')" [disabled]="!waitedTermsChecked">
                      <div class="button-text-upper-nui">
                        <p t key="eventPageComponent.JoinTheWaitingListButton" default="JOIN THE WAITING LIST" class="purchase-btn-continue join-waited-btn icon-right mb0 1"></p>
                      </div>
                      <div class="purchase-btn-total"
                           [ngStyle]="{'border-right': dir === 'rtl' ? '1px solid #A29BB1' : 'none',
                                          'border-left': dir === 'rtl' ? 'none' : '1px solid #A29BB1'}">
                        <p class="purchase-btn-total-tickets">{{quantity}}&nbsp;<span i18n="purchase-btn-total">{quantity,
                          plural, =1 {ticket} other {tickets}}</span></p>
                        <p class="purchase-btn-total-price">{{(show_price_with_fees?total_price_w_commission:total_price_n_commission) | currency:
                          currency:currency === 'MXN' || currency === 'CAD' ? 'symbol': 'symbol-narrow':'1.0-2':getLocale(currency)}}</p>
                      </div>
                    </button>
                  </div>



                </div>

              </div>
            </div>

          </div>
        <!-- </div> -->
      </div>

      <!-- templateLogin -->
      <ng-template #templateLogin>
        <section class="login col-md-12 col-xs-12">
          <div i18n="log in title" class="login-title">Please Login To continue for purchase</div>
          <!-- ------------------Log in with social nets----------------->
          <!-- <button (click)="FBLogin()" [disabled]="clicked" class="login-btn login-fb-box">
                            <img src="../../../assets/images/login/fb-icon.png" alt="login" class="login-icon">
                                <span i18n="log in by facebook" class="login-by" >
                                    Log in by Facebook
                                </span>
                        </button> -->
          <!-- <button (click)="IGLogin()" class="login-btn login-insta-box">
                            <img src="../../../assets/images/login/insta-icon.png" alt="" class="login-icon">
                                <span i18n="log in by instagram" class="login-by" >
                                    Log in by Instagram
                                </span>
                        </button> -->
          <!-- <button (click)="signUp()" class="login-btn register-box">
                                <span i18n="user registration" class="login-by" >
                                    Register
                                </span>
                        </button> -->
          <!--------------------Log in subtitles------------------>
          <div *ngIf="loginShow" class="row mt10">
            <div class="col-md-12 col-xs-12">
              <p class="login-subtitle text-center">
                <span i18n="haven't met yet?">Haven't met yet?</span>
                <a i18n="register here" class="signin_link" (click)="loginShow = !loginShow">Register here</a>
              </p>
            </div>
          </div>
          <div *ngIf="!loginShow" (click)="loginShow = !loginShow" class="row mt10">
            <div class="col-md-12 col-xs-12">
              <p class="login-subtitle text-center">
                <span i18n="already have an account?">Already have an account?</span>
                <a i18n="please log in" class="signin_link" (click)="loginShow = !loginShow">Please log in</a>
              </p>
            </div>
          </div>
          <!-----------------Log in / Registration Forms------------>
          <app-signin *ngIf="loginShow" [userEmail]="userEmail" [eventPage]="true"></app-signin>
          <app-signup *ngIf="!loginShow" [eventPage]="true"></app-signup>
          <!-- <div i18n="capsLock alert" [hidden]="!capsLock" class="alerts text-center col-md-12 col-xs-12">Caps Lock מופעל</div> -->
        </section>
      </ng-template>
      <!-- templateLoginRegistration -->
      <ng-template #templateLoginRegistration>
        <section>
          <div class="login-reg-container">
            <div class="log-reg-section col-md-12 col-xs-12">
              <div *ngIf="!isShowOtp" [attr.data-cy]="'PurchaseLoginTitle'" class="login-reg">
                {{'eventPageComponent.Login_SignUp' | translate:'Login / Sign Up'}}
              </div>
              <div *ngIf="isShowOtp" i18n="label | Login to continue" [attr.data-cy]="'PurchaseVerifyPhoneTitle'" class="login-reg">
                Verify Phone Number</div>
              <app-user-login *ngIf="showUserLoginComponent" [isShowOtp]="isShowOtp" [event_id]="event_id"
                (updateAuthUser)="updateAuthUser($event)" (switchStep)="switchStep($event)"
                (setUserData)="setUserData($event)" [showSeatsIo]="showSeatsIo" (showTerms)="getTerms()"
                (showPrivacy)="getPrivacy()" (sendSmsTimerEmit)="issendSmsTimer($event)"></app-user-login>
            </div>
          </div>
        </section>
      </ng-template>

      <!-- templateUserRegistration -->
      <ng-template #templateUserRegistration>
        <section>
          <div class="login-reg-container">
            <div class="log-reg-section col-md-12 col-xs-12">
              <div i18n="label | first time here" class="login-reg">Welcome to Getin</div>
              <app-user-registration *ngIf="showPersonalInfoComponent" [userData]="userData" [showSeatsIo]="showSeatsIo"
                (setUserData)="getFormInputs()" (switchStep)="switchStep($event)" (switchTerms)="getTerms()"></app-user-registration>
            </div>
          </div>
        </section>
      </ng-template>

      <!-- templateDescription -->
      <ng-template #templateDescription>
        <div class="event-template-desc">
          <section [ngClass]="[stepTemplate === 'templateDescription'?'tickets-container':'']"
            class="description-container-3">
            <div class="ww">
              <div class="follow-section-nui">
                <a *ngIf="producerName" class="event-under-img link-active"
                  [routerLink]="['/producer/' + producerDetails.producer_url_identifier]">
                  <img *ngIf="producerDetails && producerDetails?.profile_img"
                    [src]="images_base_url + '/profile/' + producerDetails.profile_img" alt="Producer">
                  <img *ngIf="producerDetails && !producerDetails?.profile_img && producerDetails.social_img"
                       [src]="producerDetails.social_img" alt="Producer">
                  <svg *ngIf="producerDetails && !producerDetails?.profile_img && !producerDetails?.social_img" width="45" height="45" [data-jdenticon-value]="producerDetails?.user_id"></svg>
                </a>
                <div *ngIf="producerName"class="event-under-txt-nui" [ngStyle]="{'width': !showEventEdit && isShowBtn ? 'auto':'100%'}">
                  <a [routerLink]="['/producer/' + producerDetails.producer_url_identifier]">{{producerName}}</a>
                </div>
                <div *ngIf="!showEventEdit && isShowBtn" class="event-follow">
                  <!-- Follow -->
                  <div i18n-title="follow" title="follow" class="follow-wrapper" *ngIf="producerDetails">
                    <div class="follow-dot"></div>&nbsp;&nbsp;&nbsp;&nbsp;
                    <app-follow-button (producer)="editProducer()" [producerId]="producer_id" (login)="isLogin($event)"
                      [userId]="user_id" [producerFollowers]="producerDetails.followers" [isIcon]="isIcon" (followAction)="followAction($event)">
                    </app-follow-button>
                  </div>
                </div>
                <!-- <div class="produser-data">
                  <div *ngIf="producerDetails && !!producerDetails.producer_phone" class="produser-info" (click)="onCall()">
                    <img *ngIf="!showPhone" [src]="'assets/images/user_phone.png'" alt="call"> -->
                <!-- <div (click)="onDialer()" class="show-phone" *ngIf="showPhone">{{producerDetails.producer_phone}}</div> -->
                <!-- <a [href]="sanitizer.bypassSecurityTrustUrl('tel:'+ producerDetails.producer_phone)" class="show-phone" *ngIf="showPhone" >{{producerDetails.producer_phone}}</a>
                    </div>
                    <div class="info-space"></div>
                    <div *ngIf="producerDetails && !!producerDetails.email" class="produser-info" (click)="onEmail()">
                        <a href="mailto:{{producerDetails.email}}"><img [src]="'assets/images/user_email.png'" alt="email"></a>
                    </div>
                </div> -->
                <div title="open producer contacts" *ngIf="isShowBtn" class="event-text-place"
                     [ngStyle]="dir === 'rtl' ? {'left': '15px', 'right': 'unset'} : {'left': 'unset', 'right': '15px'}"
                     (click)="user_id && user_info ? openContactProducerDialog() : isLogin(false)">
                  <!-- <span class="chat-main-text" i18n="event_page | contact_btn">Contact</span> -->
                  <img style="width: 45px;" [src]="'assets/images/chat.png'" alt="contacts">
                </div>
                <div *ngIf="producerName" class="event-text-place" (click)="toggleShareBox()">
                  <img style="width: 45px;" [src]="'assets/images/svg-icons/shareonevent.svg'" alt="share">
                  <!-- <a i18n-title="copy link" href="javascript:void(0)" title="העתק לינק"
										(click)="copyUrl(api_base_url + '/' + localeId + '/' +  event_details.event_id)">
										<img [src]="'assets/images/svg-icons/shareonevent.svg'" alt="share">
									</a> -->
                </div>
                <div i18n-title="edit event" title="edit event" *ngIf="showEventEdit && isShowBtn" class="event-text-place" (click)="editEvent()">
                  <img [src]="'assets/images/svg-icons/edit-round.svg'" alt="edit" style="width: 45px; background: white; border-radius: 25px;">
                </div>

                <!-- SHARE BOX -->
                <div class="share-box" *ngIf="isOpenShareBox">
                  <div class="share-box-details">
                    <div i18n="label | share box text" class="share-box-text">Share with friends</div>
                    <div class="share-box-icons">
                      <div *ngIf="false" i18n-title="social link" title="Instagram" class="share-box-icon">
                        <a href="https://www.instagram.com/getinofficial/" target="_blank">
                          <img [src]="'assets/images/svg-icons/Instagram-icon.svg'" alt="Instagram">
                        </a>
                      </div>
                      <div i18n-title="social link" title="Twitter" class="share-box-icon">
                          <!-- TWITTER_HANDLE (GET-IN) - That's just a placeholder for your Twitter handle -->
                        <a i18n-title="share by Twitter" i18n-href="share by Twitter link" target="_blank" title="Share on Twitter"
                          href="https://twitter.com/share?url={{shareUrl?.encodedShareUrl}}&via=GET-IN&text=Crafted to empower creators">
                          <img [src]="'assets/images/svg-icons/Twitter-icon.svg'" alt="Twitter">
                        </a>
                      </div>
                      <div i18n-title="social link" title="Facebook" class="share-box-icon">
                        <a i18n-title="@@EventPageSharebyFacebook" i18n-href="share by facebook link" target="_blank" title="Share on Facebook"
										      href="https://www.facebook.com/sharer/sharer.php?u={{shareUrl?.encodedShareUrl}}&t=Crafted to empower creators">
                          <img [src]="'assets/images/svg-icons/Facebook-icon.svg'" alt="Facebook">
                        </a>
                      </div>
                      <div i18n-title="social link" title="Telegram" class="share-box-icon">
                        <a i18n-title="share by Telegram" i18n-href="share by Telegram link" target="_blank" title="Share on Telegram"
                          href="https://telegram.me/share/url?url={{shareUrl?.encodedShareUrl}}&text=Crafted to empower creators">
                          <img [src]="'assets/images/svg-icons/Telegram-icon.svg'" alt="Telegram">
                        </a>
                      </div>
                      <div i18n-title="social link" title="Whatsapp" class="share-box-icon">
                        <a i18n-title="share by Whatsapp" i18n-href="share by Whatsapp link" target="_blank" title="Share on Whatsapp"
                          href="https://api.whatsapp.com/send?text={{shareUrl?.encodedShareUrl}}" target="_blank">
                          <img [src]="'assets/images/svg-icons/Whatsapp-icon.svg'" alt="Whatsapp">
                        </a>
                      </div>
                    </div>
                    <div class="share-box-links">
                        <div class="share-box-link">{{api_base_url + '/' + localeId + '/' +  event_details.event_id}}</div>
                        <div i18n-title="Copy link" title="Copy link" class="share-box-link-btn" (click)="copyUrl(api_base_url + '/' + localeId + '/' +  event_details.event_id)">
                          <img [src]="'assets/images/svg-icons/Copy-link-icon.svg'" alt="Copy link">
                        </div>
                    </div>
                  </div>
                </div>

                <!-- SHARE BOX SHOW PRODUCER EMAIL-->
<!--                <div class="share-box share-box-email" *ngIf="false">-->
<!--                  <div class="share-logo" style="user-select: none;">-->
<!--                    <img-->
<!--                      [src]="producerImage ? producerImage: producerDetails && producerDetails.profile_img ? images_base_url + '/profile/' + producerDetails.profile_img : 'assets/images/under.png'"-->
<!--                      alt="Under">-->
<!--                  </div>-->
<!--                  <div class="share-email">-->
<!--                    <div i18n="label | Contact me" class="share-box-text" style="user-select: none;">Contact me</div>-->
<!--                    <div style="display: flex;">-->
<!--                      <a href="mailto:{{producerEmail}}" class="producer-email">{{producerEmail}}</a>-->
<!--                      <div i18n-title="Copy email" title="Copy email" class="share-box-link-btn" (click)="copyUrl(producerEmail, 'emailCopied')">-->
<!--                        <img [src]="'assets/images/svg-icons/Copy-link-icon.svg'" alt="Copy email">-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

              </div>
              <div *ngIf="event_details.title" class="main-title"
                [ngClass]="[stepTemplate === 'templateDescription'?'desc-title-show':'desc-title-hide','is-desktop']">
                <div class="address-section">
                  <!-- <div class="ticket-title">{{event_details.title}}</div> -->
                  <div *ngIf="isBottomTitle && event_details.title" class="main-title-item">
                    <div class="title-icon">
                      <img class="map-point-img" [src]="'assets/images/svg-icons/pointonmap.svg'" alt="point">
                    </div>
                    <div class="title-address">{{(event_details.address) ? event_details.address : event_details.location_desc}}</div>
                    <!-- <span i18n="view map button | link to view map" class="view-map">View Map</span> -->
                  </div>
                  <div *ngIf="isBottomTitle && event_details.title" class="main-title-item">
                    <div class="title-icon">
                      <img class="calendar-img" [src]="'assets/images/svg-icons/calendar.svg'" alt="date">
                    </div>
                    <div class="title-address">{{eventStartDate | date:'mediumDate':'': localeId}}&nbsp;<div class="event-time">
                        {{event_details.full_start_date | date: 'shortTime'}}</div>
                    </div>
                  </div>
                  <div *ngIf="event_details.min_age && event_details.age_display" class="main-title-item">
                    <div class="title-icon">
                      <img class="info-img" [src]="'assets/images/svg-icons/info.svg'" alt="age limitation">
                    </div>
                    <div class="title-age-txt">{{'EventPage.ExclusiveFor' | translate:'Exclusive for'}}&nbsp;
                      <span class="title-age-number">{{event_details.min_age}}+</span>
                    </div>
                  </div>
                </div>
<!--                <ng-container *ngIf="true">-->
<!--                <ng-container *ngIf="event_id == 6382 || api_base_url == 'http://localhost:8000'">-->
<!--                  <app-fans-tickets [event_id]="event_id"></app-fans-tickets>-->
<!--                </ng-container>-->
              </div>
              <div class="event-video">
                <div #eventDescription [style.padding.%]="5"
                  [ngClass]="{'instagram_browser_descr': isInstagramBrowser}"
                  class="col-md-12 col-xs-12 ql-editor event_description description"
                  [innerHtml]="((description && description.value) ? description.value : '') | trustHtml"></div>
                <!-- <div class="video-watch-now">Watch Now</div> -->
              </div>
              <!-- <div class="event-terms">
                  <div class="terms-title-nui">Terms</div>
                  <div class="terms-text-nui">User’s Acknowledgment and Acceptance of Terms
                      Get In Inc. (referred to as “Get In”, “us” or “we”) provides the www.get-in.com
                      website ...
                  </div>
                  <div class="terms-read-more-nui" (click)="getTerms()"><span
                          class="terms-read-text-nui">Read more &nbsp;&nbsp;&nbsp;+</span>
                  </div>
              </div> -->
              <app-lineup *ngIf="event_id" [event_id]="event_id" (scrollToBottomDiv)="scrollToBottomDiv($event)"></app-lineup>
            </div>
          </section>
        </div>
      </ng-template>

      <!-- templateOrderSummary -->
      <ng-template #templateOrderSummary>
        <div class="order-summary-wrapper">
          <app-order-summary [promoCode]="promoCode" [couponCodeSent]="couponCodeSent" [choosedItems]="choosedItems"
            [isSelectedTicketOutOfStock]="isSelectedTicketOutOfStock" [isShowPromo]="isShowPromo"
            [couponTicketId]="couponTicketId" [currency]="currency" [commission_ils]="commission_ils"
            [total_discount]="total_discount" [total_ticket_discount]="total_ticket_discount" [setMobile]="isMobile"
            [total_price_w_commission]="total_price_w_commission" [total_purchaser_commission]="total_purchaser_commission"
            [total_service_commission]="total_service_commission" [tax_commission]="tax_commission"
            [total_tax_commission]="total_tax_commission" [tax_and_fees]="tax_and_fees"
            [waiting_list_mode]="waiting_list_mode" [hasWaitedTicket]="hasWaitedTicket"
            [hasRegularTicket]="hasRegularTicket" [hasDonateTicket]="hasDonateTicket" [donation_fee_mode]="donation_fee_mode" [total_platform_fee]="total_platform_fee"></app-order-summary>
          <ng-container *ngIf="waiting_list_mode && hasWaitedTicket">
            <p class="waited-txt-1" t key="EventPage.WaitedListEnabledAutoPay" default="Enabled Auto-Pay"></p>
            <p class="waited-txt-2" t key="EventPage.WaitedListChooseMethod" default="choose the payment method you want to use, You won’t be charged now!"
               *ngIf="stepTemplate === 'templatePay'"></p>
          </ng-container>
        </div>
      </ng-template>

      <!-- templateOrder -->
      <ng-template #templateOrder>
        <div class="order-summaries-wrapper">
          <div class="time-left-section" style="display:none;">
            <div *ngIf="stepTemplate === 'templateOrder'" id="clock" class="timer-align">
              <!-- <div i18n="label timer | time left text" class="time-left-nui">&nbsp;Time left to complete purchese:&nbsp;
                  <countdown-timer [end]="dateTimer" (zeroTrigger)="handleEvent($event)"></countdown-timer>
              </div> -->
            </div>
          </div>
          <div class="title-section" style="display: none;">
            <div class="ticket-image">
              <img class="little-cover-image" *ngIf="coverPreview" [src]="coverPreview">
              <div class="cover-image-placeholder-wrap-new" *ngIf="!coverPreview && coverPreviewDefault">
                <img class="cover-image-placeholder-new" [src]="coverPreviewDefault">
              </div>
            </div>
            <div class="ticket-title">{{event_details.title}}</div>
          </div>
          <app-order-summaries *ngIf="false" [promoCode]="promoCode" [couponCodeSent]="couponCodeSent" [choosedItems]="choosedItems"
            [localeId]="localeId" [isSelectedTicketOutOfStock]="isSelectedTicketOutOfStock"
            [couponTicketId]="couponTicketId" [currency]="currency" [commission_ils]="commission_ils"
            [total_discount]="total_discount" [total_ticket_discount]="total_ticket_discount"
            [total_price_w_commission]="total_price_w_commission" [total_purchaser_commission]="total_purchaser_commission"
            [total_service_commission]="total_service_commission" [tax_commission]="tax_commission"
            [total_tax_commission]="total_tax_commission" [tax_and_fees]="tax_and_fees"></app-order-summaries>
          <!-- <div i18n="label | continue purchase with" class="order-purchase-title-nui col-md-12 col-xs-12" *ngIf="!isSelectedTicketOutOfStock">Continue purchase with</div> -->
          <div class="order-purchase-section" *ngIf="!isSelectedTicketOutOfStock">
            <div *ngIf="savedCard && showPurchaseBtn" style="width: 100%;">
              <div *ngFor="let method of cardMethods" (click)="setPaymentMethod(method);rudderstackStartedCheckout();">
                <!-- (click)="switchStep('SavedCard')" -->
                <div class="order-buttons-nui pay-with-button new-card_">
                  <div class="crypto-btn">
                    <div class="credit-btn">
                      <!-- <mat-radio-button i18n="label | Pay with saved card" class="slide-toggle-margin" value="0" [checked]="radioBtn" (click)="checkRadioBtn($event, radioBtn)">Pay with Saved {{method.card.funding}}
                        {{method.type}} {{method.card.brand}} ends {{method.card.last4}}</mat-radio-button> -->
                      <div class="payment-icon-wrapper"><img class="crypto-img" src="/assets/images/svg-icons/credit_card.svg" alt="Credit card"></div>
                      <div i18n="label | Pay with saved card" class="pay-with-text-nui">Pay with Saved {{method.card.funding}}
                        {{method.type}} {{method.card.brand}} ends {{method.card.last4}}</div>
                    </div>
                    <div class="crypto-btn-addon"><span i18n="label | Pay button" class="crypto-btn-text">Pay</span></div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="showPurchaseBtn" style="width: 100%;">
              <div (click)="switchStep('Pay', gateway);rudderstackStartedCheckout();" *ngFor="let gateway of paymentGateways">
                <div class="order-buttons-nui pay-with-button new-card_" *ngIf="gateway.payment_type !== 'coinbase' && gateway.payment_type !== 'walletconnect' && gateway.payment_type !== 'solana' && gateway.payment_type !== 'hyper_switch'">
                  <div class="crypto-btn">
                    <div class="credit-btn">
                      <!-- <mat-radio-button i18n="label | Pay with credit card" class="slide-toggle-margin" value="1" [checked]="radioBtn" (click)="checkRadioBtn($event, radioBtn)" >Pay with Credit Card</mat-radio-button> -->
                      <div class="payment-icon-wrapper"><img class="crypto-img" src="/assets/images/svg-icons/credit_card.svg" alt="Credit card"></div>
                      <div i18n="label | Pay with credit card" class="pay-with-text-nui">Pay with Credit Card</div>
                    </div>
                    <div class="crypto-btn-addon"><span class="crypto-btn-text">Pay</span></div>
                  </div>
                </div>

                <div class="order-buttons-nui pay-with-button new-card_" *ngIf="gateway.payment_type === 'coinbase'">
                  <div class="crypto-btn">
                    <div class="credit-btn">
                      <!-- <mat-radio-button i18n="label | Pay with credit card" class="slide-toggle-margin" value="2" [checked]="radioBtn" (click)="checkRadioBtn($event, radioBtn)">Pay with Crypto Coin</mat-radio-button> -->
                      <div class="payment-icon-wrapper"><img class="crypto-img" src="/assets/images/svg-icons/crypto_wallet.svg" alt="CoinBase"></div>
                      <div i18n="label | Pay with credit card" class="pay-with-text-nui">Pay with Crypto Coin</div>
                    </div>
                    <div class="crypto-btn-addon"><span class="crypto-btn-text">Pay</span></div>
                  </div>
                </div>

                <div class="order-buttons-nui pay-with-button new-card_" *ngIf="gateway.payment_type === 'walletconnect'">
                  <div class="crypto-btn">
                    <div class="credit-btn">
                      <!-- <mat-radio-button i18n="label | Pay with credit card" class="slide-toggle-margin" value="3" [checked]="radioBtn" (click)="checkRadioBtn($event, radioBtn)">Pay with WalletConnect</mat-radio-button> -->
                      <div class="payment-icon-wrapper"><img class="crypto-img" src="/assets/images/svg-icons/crypto_wallet.svg" alt="WalletConnect"></div>
                      <div i18n="label | Pay with credit card" class="pay-with-text-nui">Pay with WalletConnect</div>
                    </div>
                    <div class="crypto-btn-addon"><span class="crypto-btn-text">Pay</span></div>
                  </div>
                </div>

                <div class="order-buttons-nui pay-with-button new-card_" *ngIf="gateway.payment_type === 'solana'">
                  <div class="crypto-btn">
                    <div class="credit-btn">
                      <!-- <mat-radio-button i18n="label | Pay with credit card" class="slide-toggle-margin" value="4" [checked]="radioBtn" (click)="checkRadioBtn($event, radioBtn)">Pay with Solana</mat-radio-button> -->
                      <div class="payment-icon-wrapper"><img class="crypto-img" src="/assets/images/svg-icons/crypto_wallet.svg" alt="Solana"></div>
                      <div i18n="label | Pay with credit card" class="pay-with-text-nui">Pay with Solana</div>
                    </div>
                    <div class="crypto-btn-addon"><span class="crypto-btn-text">Pay</span></div>
                  </div>
                </div>

                <div class="order-buttons-nui pay-with-button new-card_" data-type="hyper_switch" *ngIf="gateway.payment_type === 'hyper_switch'">
                  <div class="crypto-btn">
                    <div class="credit-btn">
                      <div class="payment-icon-wrapper"><img class="crypto-img" src="/assets/images/svg-icons/credit_card.svg" alt="Credit card"></div>
                      <div i18n="label | Pay with credit card" class="pay-with-text-nui">Pay with Credit Card</div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div *ngIf="false" class="add-new-card-link" (click)="switchCardTemplate('CardInner')">
              <div i18n="label | Add new card" class="add-new-card">Add new credit card <span
                  class="add-new-card-arrow transform-card-arrow">></span></div>
            </div>
            <div [@fade] *ngIf="cardTemplate === 'templateCardInner'">
              <ng-container *ngTemplateOutlet="templateCardInner"></ng-container>
            </div>
            <div *ngIf="cardTemplate !== 'templateCardInner' && customIsPresent" class="card-checkbox-nui">
              <!-- <span class="card-checkbox-on-nui" (click)="onCheckBox('CustomEvent')">
                <svg *ngIf="checkBoxCustomEvent" class="card-cb-on-nui" width="12" height="8" viewBox="0 0 12 8"
                  fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.8243 0.159541C11.59 -0.0531803 11.2101 -0.0531803 10.9757 0.159541L3.78737 6.68516L1.02428 4.17684C0.789972 3.96412 0.410097 3.96414 0.175746 4.17684C-0.058582 4.38954 -0.058582 4.73439 0.175746 4.94711L3.3631 7.84053C3.59734 8.05323 3.97749 8.05308 4.21163 7.84053L11.8243 0.929831C12.0586 0.717131 12.0586 0.372262 11.8243 0.159541Z"
                    fill="#82FFCA" />
                </svg>
              </span> -->
              <label class="switch">
                <input type="checkbox" type="checkbox" name="agt" [checked]="!checkBoxCustomEvent" id="updateAgt" (click)="onCheckBox('CustomEvent')">
                <span class="slider round"></span>
              </label>
              <div class="checkbox-text"><span i18n="@@EventPageIAgreeTo">I agree to</span><span class="order-terms"
                  (click)="getAgeement()" i18n="@@EventPageTerms">Terms</span>
              </div>
            </div>
            <div *ngIf="cardTemplate !== 'templateCardInner'" class="card-checkbox-nui" style="display: none;">
              <!-- <span class="card-checkbox-on-nui" (click)="onCheckBox('Agree')">
                <svg *ngIf="checkBoxAgree" class="card-cb-on-nui" width="12" height="8" viewBox="0 0 12 8" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.8243 0.159541C11.59 -0.0531803 11.2101 -0.0531803 10.9757 0.159541L3.78737 6.68516L1.02428 4.17684C0.789972 3.96412 0.410097 3.96414 0.175746 4.17684C-0.058582 4.38954 -0.058582 4.73439 0.175746 4.94711L3.3631 7.84053C3.59734 8.05323 3.97749 8.05308 4.21163 7.84053L11.8243 0.929831C12.0586 0.717131 12.0586 0.372262 11.8243 0.159541Z"
                    fill="#82FFCA" />
                </svg>
              </span> -->
              <label class="switch">
                <input type="checkbox" type="checkbox" name="agt" [checked]="!checkBoxAgree" id="updateAgt" (click)="onCheckBox('Agree')">
                <span class="slider round"></span>
              </label>
              <div i18n="label | agreement & terms" class="checkbox-text">I agree to<span class="order-terms"
                  (click)="getTerms()">Terms</span>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <!-- templateTickets -->
      <ng-template #templateTickets>
        <div class="tickets-section-wrapper">

          <!-- <div class="title-section">
            <div class="ticket-image">
              <img class="little-cover-image" *ngIf="coverPreview" [src]="coverPreview">
              <div class="cover-image-placeholder-wrap-new" *ngIf="!coverPreview && coverPreviewDefault">
                <img class="cover-image-placeholder-new" [src]="coverPreviewDefault">
              </div>
            </div>
            <div class="ticket-title">{{event_details.title}}</div>
          </div> -->
          <app-user-tickets [categories]="categories" [currency]="currency" [choosedItems]="choosedItems" [ticketLimits]="ticketLimits"
            [tickets]="tickets" [tickets_reg]="tickets_reg" [stepTemplate]="stepTemplate" [quantity]="quantity" [showCategory]="showCategory"
            [item_id]="item_id" [tables]="tables" [tables_reg]="tables_reg" [rooms_reg]="rooms_reg" [rooms]="rooms" [ticketsFiltered]="ticketsFiltered"
            [(purchase_item)]="purchase_item" (setEditTicket)="setEditTicket($event)" [withoutCategory]="withoutCategory" [hasWaitedTicket]="hasWaitedTicket"
            [coverPreviewDefault]="coverPreviewDefault" [hasDonateTicket]="hasDonateTicket"  [hasRegularTicket]="hasRegularTicket" [donateTickets]="donate_tickets"
            [commission]="commission" [platform_fee]="platform_fee" [purchaser_commission]="purchaser_commission" [tax_commission]="tax_commission"
            [service_commission]="service_commission" [show_price_with_fees]="show_price_with_fees" [waiting_list_mode]="waiting_list_mode"
            [totalTicketsInCategories]="totalTicketsInCategories" [totalTicketsWithoutCategories]="totalTicketsWithoutCategories"></app-user-tickets>
        </div>
      </ng-template>

      <!-- templateCard -->
      <ng-template #templateCard>
        <section>
          <div class="time-left-section-nui">
            <div *ngIf="stepTemplate === 'templateCard'" id="clock" class="timer-align">
              <!-- <div i18n="label timer | time left text" class="time-left-nui">&nbsp;Time left to complete purchese:&nbsp;
                  <countdown-timer [end]="dateTimer" (zeroTrigger)="handleEvent($event)" #countdowntimer>
                  </countdown-timer>
              </div> -->
            </div>
          </div>
          <div class="order-section col-md-12 col-xs-12">
            <div i18n="label | Add new card" class="card-title-nui">Add new Credit Card</div>
            <div class="dropdown-card-inputs-nui cards-nui">
              <div class="promo-code-sign">
                <img class="credit-card-img" src="/assets/images/credit_card.svg" alt="Credit card">
              </div>
              <input class="section-input-text" type="text" i18n-placeholder="input label | Credit card number"
                placeholder="Credit card number">
            </div>
            <div class="dropdown-section-nui">
              <div class="card-inputs cards-nui">
                <select (change)="choose()" class="form-control dropdown-section-text-nui select-option" name="month">
                  <option i18n="option label | month" [selected]="true" [value]="0">Month</option>
                  <option id="{{month.label}}" *ngFor="let month of months; let currentElementIndex=index"
                    [value]="month.label">{{month.label}}</option>
                </select>
              </div>
              <div class="card-inputs cards-nui">
                <select (change)="choose()" class="form-control dropdown-section-text-nui select-option" name="year">
                  <option i18n="option label | year" [selected]="true" [value]="0">Year</option>
                  <option id="{{year.label}}" *ngFor="let year of years; let currentElementIndex=index"
                    [value]="year.label">
                    {{year.label}}</option>
                </select>
              </div>
              <div class="card-inputs cards-nui">
                <input class="dropdown-section-text-nui" type="text" placeholder="CVV">
              </div>
            </div>
            <div class="dropdown-card-inputs-nui cards-nui">
              <input class="dropdown-section-text-nui" type="text" i18n-placeholder="input label | id number"
                placeholder="I.D number">
            </div>
            <div class="card-checkbox-group-nui">
              <div class="card-checkbox-nui">
                <!-- <span class="card-checkbox-on-nui" (click)="onCheckBox('Save')">
                  <svg *ngIf="checkBoxSave" class="card-cb-on-nui" width="12" height="8" viewBox="0 0 12 8" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.8243 0.159541C11.59 -0.0531803 11.2101 -0.0531803 10.9757 0.159541L3.78737 6.68516L1.02428 4.17684C0.789972 3.96412 0.410097 3.96414 0.175746 4.17684C-0.058582 4.38954 -0.058582 4.73439 0.175746 4.94711L3.3631 7.84053C3.59734 8.05323 3.97749 8.05308 4.21163 7.84053L11.8243 0.929831C12.0586 0.717131 12.0586 0.372262 11.8243 0.159541Z"
                      fill="#82FFCA" />
                  </svg>
                </span> -->
                <label class="switch">
                  <input type="checkbox" type="checkbox" name="agt" [checked]="!checkBoxSave" id="updateAgt" (click)="onCheckBox('Save')">
                  <span class="slider round"></span>
                </label>
                <div i18n="chackbox label | save for future use" class="checkbox-text">Save for future use</div>
              </div>
              <div class="card-checkbox-nui">
                <!-- <span class="card-checkbox-on-nui" (click)="onCheckBox('Agree')">
                  <svg *ngIf="checkBoxAgree" class="card-cb-on-nui" width="12" height="8" viewBox="0 0 12 8" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.8243 0.159541C11.59 -0.0531803 11.2101 -0.0531803 10.9757 0.159541L3.78737 6.68516L1.02428 4.17684C0.789972 3.96412 0.410097 3.96414 0.175746 4.17684C-0.058582 4.38954 -0.058582 4.73439 0.175746 4.94711L3.3631 7.84053C3.59734 8.05323 3.97749 8.05308 4.21163 7.84053L11.8243 0.929831C12.0586 0.717131 12.0586 0.372262 11.8243 0.159541Z"
                      fill="#82FFCA" />
                  </svg>
                </span> -->
                <label class="switch">
                  <input type="checkbox" type="checkbox" name="agt" [checked]="!checkBoxAgree" id="updateAgt" (click)="onCheckBox('Agree')">
                  <span class="slider round"></span>
                </label>
                <div i18n="label | agreement & terms" class="checkbox-text">I agree to<span class="order-terms"
                    (click)="getTerms()">Terms</span>
                </div>
              </div>
            </div>

          </div>

        </section>
      </ng-template>

      <!-- templateCardInner -->
      <ng-template #templateCardInner>
        <div class="order-section">
          <form #creditCardForm="ngForm">
            <div class="dropdown-card-inputs-nui cards-nui">
              <div class="promo-code-sign">
                <img class="credit-card-img" src="/assets/images/credit_card.svg" alt="Credit card">
              </div>
              <input maxlength="19" minlength="13" pattern="[0-9]*" class="section-input-text" name="cardNumber"
                type="card" i18n-placeholder="input label | Credit card number" placeholder="Credit card number"
                [(ngModel)]="creditCard.cardNumber" #cardNumber="ngModel" required>
            </div>
            <span i18n="error alert | required card number"
              *ngIf="cardNumber.errors && (cardNumber.dirty || cardNumber.touched)" class="item-error">Enter card
              number</span>
            <div class="dropdown-section-nui">
              <div class="card-inputs cards-nui">
                <select (change)="choose()" class="form-control dropdown-section-text-nui select-option" name="month"
                  [(ngModel)]="creditCard.cardMonth" #cardMonth="ngModel" required>
                  <option i18n="option label | month" [selected]="true" [value]="0">Month</option>
                  <option id="{{month.label}}" *ngFor="let month of months; let currentElementIndex=index"
                    [value]="month.label">{{month.label}}</option>
                </select>
              </div>
              <div class="card-inputs cards-nui">
                <select (change)="choose()" class="form-control dropdown-section-text-nui select-option" name="year"
                  [(ngModel)]="creditCard.cardYear" #cardYear="ngModel" required>
                  <option i18n="option label | year" [selected]="true" [value]="0">Year</option>
                  <option id="{{year.label}}" *ngFor="let year of years; let currentElementIndex=index"
                    [value]="year.label">
                    {{year.label}}</option>
                </select>
              </div>
              <div class="card-inputs cards-nui">
                <input class="dropdown-section-text-nui" pattern="[0-9]+" minlength="3" maxlength="3" type="text"
                  placeholder="CVV" name="cardCvv" [(ngModel)]="creditCard.cardCvv" #cardCvv="ngModel" required>
              </div>
            </div>
            <span i18n="error alert | required card number" *ngIf="cardMonth.invalid && cardMonth.touched"
              class="item-error">Enter month</span>
            <span i18n="error alert | required card number" *ngIf="cardYear.invalid && cardYear.touched"
              class="item-error">Enter year</span>
            <span i18n="error alert | required card number" *ngIf="cardCvv.invalid && cardCvv.touched"
              class="item-error">Enter cvv</span>
            <div class="dropdown-card-inputs-nui cards-nui">
              <input class="dropdown-section-text-nui" pattern="[0-9]*" type="text"
                i18n-placeholder="input label | id number" placeholder="I.D number" name="cardId"
                [(ngModel)]="creditCard.cardId" #cardId="ngModel" required>
            </div>
            <span i18n="error alert | required card number" *ngIf="cardId.errors && (cardId.dirty || cardId.touched)"
              class="item-error">Enter id number</span>
          </form>
          <div class="card-checkbox-group-nui">
            <div *ngIf="creditCardForm.valid" class="card-checkbox-nui">
              <!-- <span class="card-checkbox-on-nui" (click)="onCheckBox('Save')">
                <svg *ngIf="checkBoxSave" class="card-cb-on-nui" width="12" height="8" viewBox="0 0 12 8" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.8243 0.159541C11.59 -0.0531803 11.2101 -0.0531803 10.9757 0.159541L3.78737 6.68516L1.02428 4.17684C0.789972 3.96412 0.410097 3.96414 0.175746 4.17684C-0.058582 4.38954 -0.058582 4.73439 0.175746 4.94711L3.3631 7.84053C3.59734 8.05323 3.97749 8.05308 4.21163 7.84053L11.8243 0.929831C12.0586 0.717131 12.0586 0.372262 11.8243 0.159541Z"
                    fill="#82FFCA" />
                </svg>
              </span> -->
              <label class="switch">
                <input type="checkbox" type="checkbox" name="agt" [checked]="!checkBoxSave" id="updateAgt" (click)="onCheckBox('Save')">
                <span class="slider round"></span>
              </label>
              <div i18n="chackbox label | save for future use" class="checkbox-text">Save for future use</div>
            </div>
            <div class="card-checkbox-nui">
              <!-- <span class="card-checkbox-on-nui" (click)="onCheckBox('Agree')">
                <svg *ngIf="checkBoxAgree" class="card-cb-on-nui" width="12" height="8" viewBox="0 0 12 8" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.8243 0.159541C11.59 -0.0531803 11.2101 -0.0531803 10.9757 0.159541L3.78737 6.68516L1.02428 4.17684C0.789972 3.96412 0.410097 3.96414 0.175746 4.17684C-0.058582 4.38954 -0.058582 4.73439 0.175746 4.94711L3.3631 7.84053C3.59734 8.05323 3.97749 8.05308 4.21163 7.84053L11.8243 0.929831C12.0586 0.717131 12.0586 0.372262 11.8243 0.159541Z"
                    fill="#82FFCA" />
                </svg>
              </span> -->
              <label class="switch">
                <input type="checkbox" type="checkbox" name="agt" [checked]="!checkBoxAgree" id="updateAgt" (click)="onCheckBox('Agree')">
                <span class="slider round"></span>
              </label>
              <div i18n="label | agreement & terms" class="checkbox-text">I agree to<span class="order-terms"
                  (click)="getTerms()">Terms</span>
              </div>
            </div>
          </div>

        </div>
      </ng-template>

      <!-- templatePay -->
      <ng-template #templatePay>
        <div class="time-left-section-nui">
          <!-- <div class="time-left">Payment Form</div> -->
          <!-- <div class="time-left-section"> -->
          <div *ngIf="stepTemplate === 'templatePay'" id="clock" class="timer-align">
            <!-- <div i18n="label timer | time left text" class="time-left-nui">&nbsp;Time left to complete purchese:&nbsp;
                            <countdown-timer [end]="dateTimer" (zeroTrigger)="handleEvent($event)">
                            </countdown-timer>
                        </div> -->
          </div>
          <!-- </div> -->
        </div>
        <div *ngIf="isSelectedTicketOutOfStock && !showSeatsIo" class="ticket-out-of-stock dark-bg">OUT OF STOCK</div>

        <div [ngClass]="{
              'order-show': (stepTemplate === 'templatePay'),
              'order-hide': !(stepTemplate === 'templatePay'),
              'no-flex': enable_indirect_payment}"
               class="order-section">
          <app-countdown-checkout-timer (stopTimer)="getStoppedCheckoutTimer($event)"></app-countdown-checkout-timer>
          <!--------------------------------------------------------  SolanaConnect --------------------------------------------->
          <div style="width: 100%;" [hidden]="!(gateway_type === 'solana')">
            <!-- <span class="wallet-img"><img width="80" class="img-responsive wallet-icon" src="./assets/images/cripto_currency/walletconnect1.svg"></span> -->
            <div class="cripto-wrapper col-md-12 col-xs-12 pl0 pr0" *ngIf="!isSelectedTicketOutOfStock">
              <div class="cripto-section col-md-12 col-xs-12 pl0 pr0">
                <div id="solana_crypto_holder" class="solana-block">
                  <app-solana-connect *ngIf="!isCloseSolanaWallet" (getTrnBtnStatus)="setSolBtnStatus($event)"
                    (getTransactionWallet)="setSolanaTransactionWallet($event)" [openWallets]="isOpenSolanaWallets"
                    [walletData]="solanaConnectData" [solanaTransactionBtnStatus]="solanaTransactionBtnStatus">
                  </app-solana-connect>
                </div>
              </div>
            </div>
          </div>
          <!--------------------------------------------------------  WalletConnect --------------------------------------------->
          <div style="width: 100%;" [hidden]="!(gateway_type === 'walletconnect')">
            <!-- <span class="wallet-img"><img width="80" class="img-responsive wallet-icon" src="./assets/images/cripto_currency/walletconnect1.svg"></span> -->
            <div class="cripto-wrapper col-md-12 col-xs-12 pl0 pr0" *ngIf="!isSelectedTicketOutOfStock">
              <div class="cripto-section col-md-12 col-xs-12 pl0 pr0">
                <div id="crypto_holder" class="center-block">
                  <app-wallet-connect *ngIf="!isCloseWallet" [openWallets]="isOpenWallets"
                    [walletData]="walletConnectData" (getTransactionWallet)="setTransactionWallet($event)"
                    [transactionBtnStatus]="transactionBtnStatus" (getTrnBtnStatus)="setTrnBtnStatus($event)">
                  </app-wallet-connect>
                </div>
              </div>
            </div>
          </div>
          <!--------------------------------------------------------  CoinBase --------------------------------------------->
          <div style="width: 100%;" [hidden]="!(gateway_type === 'coinbase')">
            <div class="cripto-wrapper col-md-12 col-xs-12 pl0 pr0" *ngIf="!isSelectedTicketOutOfStock">
              <div class="cripto-section col-md-12 col-xs-12 pl0 pr0">
                <div id="crypto_holder" class="center-block">
                  <div *ngIf="coinBaseData?.data && cryptoPaymentStatus === 0">
                    <div class="wallet-title">
                      <!-- <div class="wallet-user">User:<span class="wallet-info">{{coinBaseData?.data.name}}</span></div>
                                            <div class="wallet-desc">Description:<span class="wallet-info">{{coinBaseData?.data.description}}</span></div> -->
                      <!-- <div class="wallet-amount">Payment amount: <span class="wallet-info">{{coinBaseData?.data.pricing.local.amount}} {{coinBaseData?.data.pricing.local.currency}}</span></div> -->
                    </div>
                    <div class="wallet-title">
                      <a i18n="button payment | Pay with coinbase" class="pay-with-btn"
                        href="{{coinBaseData.data.hosted_url}}" target="_blank">Pay with coinbase</a>
                    </div>
                    <div class="wallet-coins"><span i18n="section title | Crypto wallets" class="wallet-coin">Crypto
                        wallets:</span>
                      <div class="wallet-crypto" *ngFor="let item of coinBaseData?.data.addresses | keyvalue">
                        <div class="cripto-currency">
                          <img class="cripto-cur-img"
                            [src]="'./../../assets/images/cripto_currency/' + item.key + '_icon.png'"
                            alt="cripto currency icon">
                          <div class="wallet-name" (click)="toggleCoinBase(item.key)">{{item.key}}</div>
                        </div>
                        <div class="wallet-wrapper" [@slideIn] *ngIf="toggleCripto === item.key">
                          <div class="exchange-rate-wrapper" *ngIf="coinBaseData?.data.pricing[item.key]">
                            <div class="wallet-data">
                              <span i18n="section title | Exchange rate" class="exchange-rate-title">Exchange rate:
                              </span>
                              <span class="exchange-rate"> {{coinBaseData?.data.pricing[item.key].amount | number :
                                '1.0-9'}} {{coinBaseData?.data.pricing[item.key].currency}}</span>
                            </div>
                            <span i18n="button | copy wallet key" class="wallet-copy"
                              (click)="copyWalletData(coinBaseData?.data.pricing[item.key].amount)">Copy</span>
                          </div>
                          <div class="wallet-key">
                            <div class="wallet-data">
                              <span class="wallet-key-start">{{item.value.substring(0,item.value.length/2)}}</span>
                              <span
                                class="wallet-key-middle">..........................................................................</span>
                              <span class="wallet-key-end">{{item.value.substr(item.value.length/2)}}</span>
                            </div>
                            <span i18n="button | copy wallet key" class="wallet-copy"
                              (click)="copyWalletData(item.value)">Copy</span>
                          </div>
                          <div *ngIf="false" class="wallet-payment"
                            (click)="checkCryptoPayment(coinBaseData?.data.code, coinBaseData?.data.id, item.key, item.value, coinBaseData?.data.pricing[item.key].amount, coinBaseData?.data.pricing[item.key].currency)">
                            <!-- <div class="wallet-check-btn">Check payment <span class="wallet-check-coin"> {{item.key}}</span></div> -->
                            <div i18n="button | Send request" class="wallet-check-btn">Send request</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="wallet-check">
                        <div class="wallet-check-btn" (click)="checkCriptoPayment()">Check payment</div>
                    </div> -->
                    <!-- <div> Exchange Rate:
                        <p *ngFor="let item of coinBaseData?.data.pricing | keyvalue">
                            <b>{{item.key}}</b>: {{item.value.amount}} {{item.value.currency}}
                        </p>
                    </div> -->
                  </div>
                  <div *ngIf="cryptoPaymentStatus === 1" class="crypto-status">
                    <!-- <div class="crypto-attention">IMG isset transaction</div> -->
                    <div class="crypto-title"><span class="title-text">Verifying payment</span></div>
                    <div class="crypto-attention attention-true">Transfer from your wallet <span
                        class="crypto-check check-true">&#10004;</span></div>
                    <div class="crypto-message" style="margin: 0;"><span>You've successfully sent a payment.</span>
                    </div>
                    <div class="crypto-message"><span>We are waiting for it to be veryfied by the network. Your payment
                        has recive 0 of 1 confirmation.</span></div>
                  </div>
                  <div *ngIf="cryptoPaymentStatus === 2" class="crypto-status">
                    <!-- <div class="crypto-attention">IMG success</div> -->
                    <div class="crypto-title"><span class="title-text">Successfully payment</span></div>
                    <div class="crypto-attention attention-true">Payment received successfully<span
                        class="crypto-check check-true">&#10004;</span></div>
                  </div>
                  <div *ngIf="cryptoPaymentStatus === 3" class="crypto-status">
                    <!-- <div class="crypto-attention">IMG unresolved</div> -->
                    <div class="crypto-title"><span class="title-text">Underpayment</span></div>
                    <div class="crypto-attention attention-false">Payment is less than required.<span
                        class="crypto-check check-false">&#10006;</span></div>
                    <div class="crypto-message"><span>The merchant received your payment but the amount received was
                        less then that what was required.</span></div>
                    <div class="crypto-message"><span>Please contact the merchant
                        at</span><span>merchant@get-in.com</span><span>with your order code to resolve your
                        payment.</span></div>
                    <div *ngIf="cryptoPaymentOrderCode" class="crypto-order-text"><span>Your order code is:</span></div>
                    <div *ngIf="cryptoPaymentOrderCode" class="crypto-order-code"><span
                        class="order-code">{{cryptoPaymentOrderCode}}</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-----------------------------------------------------------  BOA --------------------------------------------->
          <div [hidden]="!(gateway_type === 'bank_of_america')">
            <div class="col-md-12 col-xs-12 pl0 pr0" *ngIf="!isSelectedTicketOutOfStock">
              <div class="col-md-12 col-xs-12 pl0 pr0">
                <div id="iframe_holder" class="center-block">
                  <iframe [ngStyle]="{'display': iframeDisplay}" id="load_payment" (load)="onLoadIframe()"
                    class="embed-responsive-item" name="load_payment" frameborder="0" width='60vw' height='750px'
                    scrolling="no">You must use a browser that supports Iframe to view the content.</iframe>
                  <form #tokenForm [action]="boa_url" method="post" target="load_payment">
                    <input #tokenInput id="hptoken" type="hidden" name="token" [value]="token" />
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!------------------------------------------------------------ Stripe -------------------------------------------->
          <div [hidden]="!(gateway_type === 'stripe_gateway')">
            <div class="col-md-12 col-xs-12 pl0 pr0 mt-2" *ngIf="!isSelectedTicketOutOfStock">

              <div class="col-md-12 col-xs-12 pl0 pr0" *ngIf="!enable_indirect_payment">
                <div id="holder" class="center-block" [ngStyle]="{'display': showStripeForm ? 'block' : 'none' }">
                  <form #checkout="ngForm" (ngSubmit)="stripeOnSubmit(checkout)" class="checkout">
                    <div class="card-grp">
                      <div class="form-row">
                        <div id="card-info" [attr.data-cy]="'cardInfoStripeElement'" #cardInfo></div>
                        <div id="card-errors" role="alert" *ngIf="cardErrors">
                          {{ cardErrors?.message }}
                        </div>
                      </div>
                      <div class="form-group pr0 pl0">
                        <div class="round-switch">
                          <label class="switch">
                            <input type="checkbox" type="checkbox" name="agt" [checked]="cardIsChecked" id="updateAgt" (click)="setSaveCard($event)">
                            <span class="slider round"></span>
                          </label>
                          <span class="switch-label" i18n="input option | one form for each participant">Remember this card</span>
                        </div>
                        <!-- <label class="radio-label">
                          <input id="purchase_validation" type="checkbox" [checked]="cardIsChecked"
                            (change)="setSaveCard($event)">
                          <span class="checkmark"></span>
                          <span i18n="input option | one form for each participant">Remember this card</span>
                        </label> -->
                      </div>
                    </div>
                    <div class="group-btn">
                      <button class="pay-btn" [attr.data-cy]="'payContinueButton'" *ngIf="gateway_type == 'stripe_gateway'"
                        [disabled]="!stripe_form?.complete || isClickedStripeButton" type="submit">
                        <span i18n="pay button" *ngIf="!hasWaitedTicket">Continue</span>
                        <span t key="eventPageComponent.JoinTheWaitingListButton" default="JOIN THE WAITING LIST" class="join-waited-btn icon-right" *ngIf="hasWaitedTicket"></span>
                      </button>


                      <div class="purchase-btn-total"
                        [ngStyle]="{'border-right': dir == 'rtl' ? '1px solid #A29BB1' : 'none',
                                          'border-left': dir == 'rtl' ? 'none' : '1px solid #A29BB1'}">
                        <p class="purchase-btn-total-tickets">{{quantity}}&nbsp;
                          <span i18n="purchase-btn-total">{quantity, plural, =1 {ticket} other {tickets}}</span></p>
                        <p class="purchase-btn-total-price">{{total_price_w_commission | currency:
                          currency:currency === 'MXN' || currency === 'CAD' ? 'symbol': 'symbol-narrow':'1.0-2':getLocale(currency)}}</p>
                      </div>
                    </div>
                  </form>
                  <div id="payment-request-button">
                    <!-- A Stripe Element will be inserted here. -->
                  </div>
                </div>
              </div>
              <div style="padding-top: 10px;" [ngStyle]="{'display': showStripeForm ? 'block' : 'none' }">
                 <app-stripe-checkout *ngIf="enable_indirect_payment && !waiting_list_checkout"
                                      [client_secret]="stripe_client_secret"
                                      [publishable_key]="stripe_publishable_key"
                                      [return_url]="stripe_return_url"
                                      [main_purchase_identifier]="main_purchase_identifier"
                                      (rudderstackAddPaymentInfo)="rudderstackAddPaymentInfo($event)"
                                      (checkAvailableFromStripe)="checkAvailableFromStripe()"></app-stripe-checkout>
                <app-stripe-future-payments-checkout *ngIf="waiting_list_checkout"
                                     [client_secret]="stripe_client_secret"
                                     [publishable_key]="stripe_publishable_key"
                                     [return_url]="stripe_return_url"
                                     [main_purchase_identifier]="main_purchase_identifier"
                                     (rudderstackAddPaymentInfo)="rudderstackAddPaymentInfo($event)"
                                     (checkAvailableFromStripe)="checkAvailableFromStripe()"></app-stripe-future-payments-checkout>
              </div>


            </div>
          </div>
          <!--------------------------------------------------------- External --------------------------------------------->
          <div *ngIf="externalApi" class="col-md-12 col-xs-12 external_api mt20 pl0 pr0">
            <div class="col-md-12 col-xs-12 pl0 pr0">
              <button class="mt10" (click)="extranalApi()">
                <span i18n="@@EventPageConfirmPayment">Confirm payment</span>
              </button>
            </div>
          </div>
          <!--------------------------------------------------------- Tranzilla --------------------------------------------->
          <div class="tranzilla-wrapper" [hidden]="!(gateway_type === 'tranzilla')" *ngIf="!externalApi">
            <div class="row tranzilla_form mt20 pl0 pr0">
              <div class="col-md-12 col-xs-12">
                <iframe *ngIf="tranzillaUrl !== undefined"
                  id="tranzilla_form" [src]="tranzillaUrl" width="100%">You must use a browser that supports Iframe to view the content.</iframe>
              </div>
            </div>
          </div>

          <!------------------------------------------------------------ Hyperswitch -------------------------------------------->
          <form id="hyper-payment-form" [ngStyle]="{'display': showHyperForm ? 'block' : 'none' }" *ngIf="gateway_type == 'hyper_switch'" (ngSubmit)="hyper_switchSubmit($event)">
            <div id="unified-checkout" #unified_checkout>
              <!--hyperLoader injects the Unified Checkout-->
            </div>
            <button id="hyper-submit" [disabled]="hyperSubmitDisable" [ngClass]="hyperSubmitHidden?'hidden':''">
              <div class="spinner hidden" id="spinner"></div>
              <div id="button-text">Pay</div>
            </button>
            <div id="hyper-payment-message" #hyper_errors></div>
          </form>

        </div>
      </ng-template>

      <!-- templateForm -->

      <!-- templateGuest -->
      <ng-template #templateGuest>
        <section class="guest-container-nui">
          <app-guest-info [(setCheckBoxArray)]="checkboxArr" [newCustomInputs]="new_custom_inputs" [payBtnAddon]="{quantity:quantity,currency:currency,total_price_w_commission:total_price_w_commission}"
            [choosedItems]="choosedItems" [purchase_type]="purchase_type" [formSubmited]="formSubmited" [(setCheckedAgreement)]="checkBoxAgree" [(setCheckedProducerSubscription)]="checkBoxProducerSubscription"
            [user_info]="user_info" [form_inputs]="form_inputs" [event_details]="event_details" [ageDisabled]="ageDisabled" (showTerms)="getTerms()"
            [(setParticipantForm)]="participantForm" [showSeatsIo]="showSeatsIo" [showProducerSubscription]="showProducerSubscription" (switchStep)="pendingStep($event)"
            [hasWaitedTicket]="hasWaitedTicket" [invalidEmailsError]="invalidEmailsError"></app-guest-info>
        </section>
      </ng-template>

      <!-- templateNoValidCoupon -->
      <ng-template #templateNoValidCoupon>
        <div class="ticket-out-of-stock" i18n="Coupon is not valid">Coupon is not valid</div>
      </ng-template>
      <!-- templateTerms -->
      <ng-template #templateTerms>
        <!-- Terms Modal -->
        <app-terms></app-terms>
      </ng-template>

      <ng-template #templatePrivacy>
        <!-- Policy Modal -->
        <app-cancellation-en></app-cancellation-en>
      </ng-template>

      <!-- templateSeats -->
      <ng-template #templateSeats>
        <div class="seats-next-head">
<!--          <p (click)="getSeatsCats()">GET CATS</p>-->
          <mat-accordion *ngIf="seatsSupportsBestAvailable">
            <mat-expansion-panel (opened)="seatsPanelOpenState = true"
                                 (closed)="seatsPanelOpenState = false; toggleBestSeats = false;" class="seats-panel"
                                 [togglePosition]="'before'"
                                  [hideToggle]="!seatsPanelOpenState"
                                 [ngStyle]="{'background': seatsPanelOpenState ? '#fff' : isMobile ? 'none' : '#fff'}">
              <mat-expansion-panel-header [expandedHeight]="'90px'" [collapsedHeight]="'64px'">
                <mat-panel-title>
                  <div class="seats-head-title" [ngStyle]="{'background': seatsPanelOpenState ? 'none' : '#e6eaee'}">
                    <p><img *ngIf="!seatsPanelOpenState" src="/assets/images/svg-icons/magicpen.svg" alt=""></p>
                    <p class="seats-p-15" *ngIf="!seatsPanelOpenState" i18n="seats|txt3">Find the Best Seats view</p>
                    <p class="seats-p-17" *ngIf="seatsPanelOpenState" i18n="seats|txt2">How Many Tickets You Need? We Will Find The Best Match 🥳</p>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="seats-qty-choose" (click)="toggleBestSeats = !toggleBestSeats" *ngIf="!toggleBestSeats">
                <p class="seats-p-14" i18n="seats|Quantity">Quantity</p>
                <p class="seats-p-14"><span i18n="seats|choose">choose</span> <img src="/assets/images/svg-icons/arrow-right-blk.svg" alt=""></p>
              </div>
              <div class="seats-best" *ngIf="toggleBestSeats">
                <div class="seats-best-item" (click)="bestSeats(key)" *ngFor="let key of [1,2,3,4,5,6,7,8,9,10]">
                  {{key}}
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div class="seats-next" [ngStyle]="!seatsSupportsBestAvailable && {'border-radius': '20px'}">


          <ng-container>
            <!--VIEW TICKETS Accordion-->
            <mat-accordion>
              <mat-expansion-panel class="seats-panel-tickets" [togglePosition]="'before'" [expanded]="!isMobile">
                <mat-expansion-panel-header *ngIf="isMobile">
                  <mat-panel-title>
                    <div>
                      <span class="seats-p-15" i18n="seats|view tickets">VIEW TICKETS</span>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="seats-selected-wrap" *ngIf="listSelectedObjects">
                  <div *ngFor="let t of seatsTickets; last as isLast">
                      <div class="seats-selected" [ngStyle]="{'border-bottom': isLast ? 'none' : '1px solid #E6EAEE'}">
                        <p>
                          <img *ngIf="t.ticket.availability == 1" src="/assets/images/seats-chair.png" alt="">
                          <img [matTooltip]="tooltipTxt(t)" *ngIf="t.ticket.availability !== 1" src="/assets/images/svg-icons/menu-info-circle.svg" alt="" class="seats-invalid-ticket">
<!--                          <img *ngIf="t.ticket.availability == 1 && (t.hasOwnProperty('limitExceed') && !t.limitExceed)" src="/assets/images/seats-chair.png" alt="">-->
<!--                          <img [matTooltip]="tooltipTxt(t)" *ngIf="t.ticket.availability !== 1 || (t.hasOwnProperty('limitExceed') && t.limitExceed)" src="/assets/images/svg-icons/menu-info-circle.svg" alt="" class="seats-invalid-ticket">-->
                        </p>
                        <div class="seats-txt"
                             [ngStyle]="{'opacity': t.ticket.availability !== 1 ? '0.5' : '1'}">
                          <div>
                            <span i18n="seats|Row" *ngIf="t.parent.type === 'row'">Row: </span>
                            <span i18n="seats|Table" *ngIf="t.parent.type === 'table'">Table: </span>
                            <span class="seat-badge">{{t.labels.parent}}</span><span i18n="seats|Seat"> Seat: </span><span class="seat-badge">{{t.labels.own}}</span>
                          </div>
                          <div style="line-height: 1;">
                            <span class="seats-price">{{t.price | currency:
                            currency:currency === 'MXN' || currency === 'CAD' ? 'symbol': 'symbol-narrow':'1.0-2':getLocale(currency)}}</span>
                          </div>
                        </div>
                        <p class="seats-remove" (click)="deselectObjects(t.seatId)"><img src="/assets/images/svg-icons/trash.svg" alt=""></p>
                      </div>


<!--                    <div style="color:{{ticket.category.color}};" *ngFor="let ticket of category.value; last as isLast;">-->
<!--                      <div class="seats-selected" [ngStyle]="{'border-bottom': isLast ? 'none' : '1px solid #E6EAEE'}">-->
<!--                        <p><img src="/assets/images/seats-chair.png" alt=""></p>-->
<!--                        <p class="seats-txt">Row: <span class="seat-badge">{{ticket.labels.parent}}</span> Seat: <span class="seat-badge">{{ticket.labels.own}}</span>-->
<!--                          <span>{{ticket.price}}</span>-->
<!--                        </p>-->
<!--                        <p class="seats-remove" (click)="deselectObjects(ticket.id)"><img src="/assets/images/svg-icons/trash.svg" alt=""></p>-->
<!--                      </div>-->
<!--                    </div>-->
                  </div>
                </div>
                <p class="no-seats-txt" *ngIf="!seatsQuantity" i18n="seats|select floor">Click on the floor plan to select your seats</p>
              </mat-expansion-panel>
            </mat-accordion>
          </ng-container>
          <!--MOBILE CHECKOUT BTN-->
          <div class="seats-btn-continue" *ngIf="chartSeatsRendered && isMobile">
            <div class="purchase-btn-total">
              <p class="purchase-btn-total-price">{{total_price_w_commission | currency:
                currency:currency === 'MXN' || currency === 'CAD' ? 'symbol': 'symbol-narrow':'1.0-2':getLocale(currency)}}</p>
              <p class="purchase-btn-total-tickets">{{seatsQuantity}}&nbsp;<span i18n="purchase-btn-total">{seatsQuantity,
                plural, =1 {ticket} other {tickets}}</span></p>
            </div>
            <div class="seats-button-text">
              <button [disabled]="!seatsQuantity || seatsTicketsHaveInvalidItem" i18n="payment button | Go To Checkout" class="seats-button-continue mb0" (click)="switchStep('SeatsTickets'); setSeatsObjects();">Go To Checkout</button>
            </div>
          </div>
          <!--DESKTOP CHECKOUT BTN-->
          <div class="seats-btn-continue-black" *ngIf="chartSeatsRendered && !isMobile">
            <button [disabled]="!seatsQuantity || seatsTicketsHaveInvalidItem" (click)="switchStep('SeatsTickets'); setSeatsObjects();" class="seats-button-continue mb0">
              <div class="seats-1-row">
                <span class="seats-checkout-txt" i18n="seats|Checkout">Checkout</span>
                <p class="purchase-btn-total-tickets">{{seatsQuantity}}&nbsp;<span i18n="purchase-btn-total">{seatsQuantity,
                  plural, =1 {ticket} other {tickets}}</span></p>
              </div>
              <p class="purchase-btn-total-price">{{total_price_w_commission | currency:
                currency:currency === 'MXN' || currency === 'CAD' ? 'symbol': 'symbol-narrow':'1.0-2':getLocale(currency)}}</p>
            </button>
          </div>
        </div>



      </ng-template>

      <!-- templateSeatsTickets -->
      <ng-template #templateSeatsTickets>
        <div class="seats-tickets-section-wrapper" style="opacity: 0; display: none;">
          <app-user-tickets [categories]="categories" [currency]="currency" [choosedItems]="choosedItems" [ticketLimits]="ticketLimits"
                            [tickets]="tickets" [tickets_reg]="tickets_reg" [stepTemplate]="stepTemplate" [quantity]="quantity" [showCategory]="showCategory"
                            [item_id]="item_id" [tables]="tables" [tables_reg]="tables_reg" [rooms_reg]="rooms_reg" [rooms]="rooms" [ticketsFiltered]="ticketsFiltered"
                            [(purchase_item)]="purchase_item" (setEditTicket)="setEditTicket($event)" [withoutCategory]="withoutCategory" [hasWaitedTicket]="hasWaitedTicket"
                            [coverPreviewDefault]="coverPreviewDefault" [hasDonateTicket]="hasDonateTicket" [hasRegularTicket]="hasRegularTicket" [donateTickets]="donate_tickets"
                            [commission]="commission" [platform_fee]="platform_fee" [purchaser_commission]="purchaser_commission" [tax_commission]="tax_commission"
                            [service_commission]="service_commission" [show_price_with_fees]="show_price_with_fees" [waiting_list_mode]="waiting_list_mode"></app-user-tickets>
        </div>
        <div class="seats-ticket-list" style="padding: 15px;">
          <div *ngFor="let category of listSelectedObjects | keyvalue">
            <p style="font-weight: bold;">{{category.key}}</p>
            <p style="color:{{ticket.category.color}};" *ngFor="let ticket of category.value">{{ticket.label}}</p>
          </div>
        </div>
      </ng-template>

      <!-- templateWaitedTicketsTerms -->
      <ng-template #templateWaitedTicketsTerms>
        <div class="waited-terms-wrap">
          <p class="waited-help">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clip-path="url(#clip0_21982_30501)">
                <path d="M12.9714 13.2592L12.9 13.2805V13.355V13.9H11.1V12.5C11.1 12.2613 11.1948 12.0324 11.3636 11.8636C11.5324 11.6948 11.7613 11.6 12 11.6C12.303 11.6 12.5998 11.5139 12.8558 11.3518C13.1118 11.1897 13.3166 10.9583 13.4462 10.6844C13.5758 10.4105 13.6249 10.1054 13.5879 9.80463C13.5509 9.50388 13.4293 9.21982 13.2372 8.98551C13.045 8.75119 12.7903 8.57625 12.5026 8.48103C12.215 8.38582 11.9062 8.37426 11.6122 8.44769C11.3182 8.52111 11.0511 8.67651 10.842 8.8958C10.656 9.0909 10.5226 9.32942 10.4536 9.58891L8.68611 9.23487C8.81383 8.68159 9.07821 8.16853 9.45573 7.74295C9.85539 7.29241 10.3675 6.95608 10.9398 6.76834C11.512 6.5806 12.1238 6.54818 12.7127 6.67439C13.3016 6.80061 13.8464 7.08094 14.2914 7.48672C14.7365 7.8925 15.0658 8.40918 15.2457 8.98393C15.4256 9.55869 15.4496 10.1709 15.3154 10.758C15.1811 11.3451 14.8934 11.886 14.4816 12.3255C14.0697 12.7649 13.5486 13.0871 12.9714 13.2592ZM12 21.9C6.53223 21.9 2.1 17.4678 2.1 12C2.1 6.53223 6.53223 2.1 12 2.1C17.4678 2.1 21.9 6.53223 21.9 12C21.9 17.4678 17.4678 21.9 12 21.9ZM12 20.1C14.1483 20.1 16.2085 19.2466 17.7276 17.7276C19.2466 16.2085 20.1 14.1483 20.1 12C20.1 9.85175 19.2466 7.79148 17.7276 6.27244C16.2085 4.75339 14.1483 3.9 12 3.9C9.85175 3.9 7.79148 4.75339 6.27244 6.27244C4.75339 7.79148 3.9 9.85175 3.9 12C3.9 14.1483 4.75339 16.2085 6.27244 17.7276C7.79148 19.2466 9.85175 20.1 12 20.1ZM12.9 15.1V16.9H11.1V15.1H12.9Z" fill="white" stroke="#2B2544" stroke-width="0.2"/>
              </g>
              <defs>
                <clipPath id="clip0_21982_30501">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <span>help</span>
          </p>
          <p class="waited-txt-up">⚡ AUTO-PAY ENABLED</p>
          <p class="waited-txt-list">💰 pay only when ticket become available, no deposit needed. </p>
          <p class="waited-txt-list">🤟 Rank higher and get your ticket faster in line. </p>


        </div>



      </ng-template>

    </section>

  </div>

</div>
