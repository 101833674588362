<!-- <div class="settings-container col-md-12 col-xs-12">
  <div class="event_text col-md-12 col-xs-12">
    <h1 i18n="page title | bank account" class="event-settings-title mt10 mb10">עריכת פרטי בנק לאירוע <span
        class="disabled">&nbsp;{{event_id}}</span></h1>
  </div> -->
  <!-- <div class="col-md-12 col-xs-12 mb20" *ngIf="eventData && eventData.bank['event_id'] != event_id">
    <div class="row">
      <div class="col-md-12 col-xs-12 vertical-align">
        <div class="col-md-10 col-xs-10 pl0 pr0">
          <label i18n="input label | brank from previous" for="bank_account">בחר מפרטי חשבון קודמים</label>
        </div>
      </div>
      <select class="form-control" i18n-placeholder="select tag | previous bank" name="bank_account" id="bank_account" placeholder="בחר פרטי בנק" (change)="setBankFromDraft($event)">
        <option i18n="bank account event | choose bank account" value="">בחר פרטי בנק</option>
        <option value="1">{{eventData.bank.country | uppercase}} / {{eventData.bank.bank_name}} / {{eventData.bank.account_name}} / {{eventData.bank.account_number}}</option>
      </select>
    </div>
  </div> -->
  <!-- <form [formGroup]="event" class="status" *ngIf="bootstrapBoolean">
    <button (click)="submitForm()" type="button" class="col-md-12 col-xs-12 text-center save_event_btn"
      [disabled]="event.invalid && submitted">
      <span i18n="save form">שמור</span>
    </button>
  </form>
</div> -->
<div class="page-header-arrow-back">
  <div class="page-back-arrow">
    <div class="close-dialog-arrow" (click)="closePage()">
      <svg class="arrow-close-modal" width="10" height="14" viewBox="0 0 10 14" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M9.49219 1.64063L4.13281 7L9.49219 12.3594L7.85156 14L0.851562 7L7.85156 0L9.49219 1.64063Z"
          fill="white" />
      </svg>
    </div>
  </div>
</div>
<div class="bankaccount-wrapper">
  <div class="bankaccount-container">
    <div class="bankaccount-main">
      <!-- <div class="event-back-arrow">
        <div class="close-dialog-arrow" (click)="closeLoginModal()">
          <svg class="arrow-close-modal" width="10" height="14" viewBox="0 0 10 14" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M9.49219 1.64063L4.13281 7L9.49219 12.3594L7.85156 14L0.851562 7L7.85156 0L9.49219 1.64063Z"
              fill="white" />
          </svg>
        </div>
      </div> -->
      <div i18n="page title | bank account details" class="bankaccount-title">Bank Account Details for event&nbsp;{{event_id}}&nbsp;</div>
    </div>
    <div class="bankaccount-main-container">
      <form [formGroup]="event" class="form-status" *ngIf="bootstrapBoolean">
        <app-bank-form-inputs #bankForm *ngIf="showBankForm" (initBankStep)="initBankStep($event)"
          (updateValueOfBankForm)="updateValueOfBankForm($event)" [bankData]="dataForForm" [event_id]="event_id" [page]="'bank-account'"></app-bank-form-inputs>
        <button (click)="submitForm()" type="button" class="balance-field balance-btn"
          [disabled]="event.invalid && submitted">
          <span i18n="@@Save" class="btn-text">Save</span>
        </button>
      </form>
    </div>
  </div>
</div>
