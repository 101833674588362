//const emailRegex = /^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(\.[a-z]{2,20})$/i;
const emailRegex = /^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(\.(?!co[^m][a-z]*|com[a-z])[a-z]{2,20})$/i; // exclude .co[^m] characters (check in the https://regex101.com/)
const phoneRegex = /^0[\d]{1,2}[-]?[\d]{3}[-]?[\d]{4}$/;
const urlRegex = /^[a-z]+[a-z_\d]+$/i;
const imageRegex = /^image\/\w+$/;
const googleAnalyticsUa = /(G)-[0-9a-zA-Z]+/;
const abaNumberRegex = /^\d{9}$/;
const socialUrlRegex = /^[a-z\d]+[a-z_.\d]+$/i;
const emojiRegex = /^[a-zA-Zא-ת0-9\s_$@_!#$%^&*)(=:;'",.<>?\/\*\+-\[\]\{\}]+$/;
const linkRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i;
const producerPageURLRegex = /^[a-z\u0590-\u05fe0-9_-]*$/i; // https://apps.timwhitlock.info/js/regex#
const phoneWCountryCodeRegex = /^[\+0-9]{5,14}$/;
export {phoneRegex, emailRegex, urlRegex, imageRegex, googleAnalyticsUa, abaNumberRegex, socialUrlRegex, emojiRegex, linkRegex, producerPageURLRegex, phoneWCountryCodeRegex};
