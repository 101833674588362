import { DomSanitizer } from '@angular/platform-browser';
import { UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GlobalsService } from '../globals';
import { MbscDatetimeOptions, MbscSelectOptions } from '@mobiscroll/angular';
import { LocalizationService } from '../services/localization.service';

@Component({
  selector: 'app-form-inputs',
  templateUrl: './form-inputs.component.html',
  styleUrls: ['./form-inputs.component.css']
})
export class FormInputsComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() input: CustomInput;
  @Input() counter: number;
  @Input() ageDisplay: string;
  @Input() birth_date: any;
  @Input() gender: number;
  @Input() id_number: number;
  @Input() getin_subscription: number;
  @Input() customClass: string;
  @Input() labels: boolean = false;
  @Input() eventPaymentMode: boolean = false;
  @Output() checkbox =  new EventEmitter<any>();
  @Output() radio =  new EventEmitter<any>();
  @Output() age =  new EventEmitter<any>();
  @Output() selectInput =  new EventEmitter<any>();
  @Output() myBirthdayChanged =  new EventEmitter<any>();
  today = new Date();
  label = false;
  isChecked = false;
  checkBox: any = [];
  radioBox: any = [true];

  myBirthdaySettings: MbscDatetimeOptions = {
    theme: this.globals.mobiscrollTheme(),
    lang: this.globals.getLocaleId(),
    // animate: 'pop',
    headerText: (this.globals.getLocaleId() === 'he') ? 'תאריך לידה' : 'Date of Birth',
    min: new Date(this.today.setFullYear(this.today.getFullYear() - 120)),
    display: 'center',
    dateWheels: 'yy MM dd',
    defaultValue: '1980-01-01',
    animate: 'slideup',
    returnFormat: 'iso8601',
    max: new Date(),
    onChange: (event, inst) => {
      // console.log(event.valueText, this.counter);
      this.myBirthdayChanged.emit({counter: this.counter, name: 'age', val: event.valueText});
    }
  };

  genderSettings: MbscSelectOptions = {
    select: 'single',
    theme: this.globals.mobiscrollTheme(),
    display: 'center',
    lang: this.globals.LOCALE_ID,
    filter: false,
  }

  formInputsOthers;
  localId = this.globals.getLocaleId();

  constructor(public sanitizer: DomSanitizer, private globals: GlobalsService, public localizationService: LocalizationService) {}

  ngOnInit() {
    this.formInputsOthers = this.globals.formInputsOthers;
     // console.log('input', this.input, this.form);
     this.initCheckboxState();
     this.initRadioboxState();
  }

  initCheckboxState() {
    if (this.input.input_type === 'checkbox') {
     this.checkBox = this.input.input_options.map(option => {
       const name = this.form.get(this.input.input_name).value;
       return name.includes(option.placeholder) ? true : false;
     });
   }
  }

  initRadioboxState() {
    if (this.input.input_type === 'radio') {
      this.radioBox = this.input.input_options.map(option => {
        const name = this.form.get(this.input.input_name).value;
        return name.includes(option.placeholder) ? true : false;
      });
   }
  }

  checkboxHandler(counter, checkboxName, checkboxValue, isChecked, val, index) {
    // Giving information for parent component to validate the checkboxes
    this.isChecked = isChecked;
    this.checkBox[index] = isChecked;
    //console.log('checkboxHandler', val, index, val.input_name, this.input.input_options[index].checked, counter, checkboxName, checkboxValue, isChecked);
    this.checkbox.emit({counter, checkboxName, checkboxValue, isChecked, val, index});
  }

  radioHandler(counter, radioName, radioValue, isChecked, val, index) {
    this.radioBox.forEach((element, i) => {
      this.radioBox[i] = !isChecked;
    });
    this.radioBox[index] = isChecked;
    // console.log('radioHandler', isChecked, index, this.radioBox);
    // Giving information for parent component to validate the checkboxes
    this.radio.emit({counter: counter, radioName: radioName, radioValue: radioValue, isChecked: isChecked, val: val, index: index});
  }

  selectHandler(counter, selectName, selectValue) {
    // Giving information for parent component to validate the checkboxes
    this.selectInput.emit({counter: counter, selectName: selectName, selectValue: selectValue});
  }


  ageToDate(counter, inputName){
    this.age.emit({counter,inputName});
  }

  setErrorOnphoneNumber(hasErrorfromNg2TelInput) {
    const hasError = this.globals.hasErrorOnPhoneNumber(hasErrorfromNg2TelInput, this.form.get('user_phone').value);
    const actionOnSetErrors = hasError ? {'invalid': true} : null;
    this.form.get('user_phone').setErrors(actionOnSetErrors);
  }
  setMarketingPhoneNumber(phoneNumber) {
    this.form.get('user_phone').patchValue(phoneNumber);
  }

  telInputObject(obj) {
    if(this.form.get('user_phone').value) {
      obj.setNumber(this.form.get('user_phone').value);
    }
    this.form.get('phoneCountryCode').patchValue(obj.s.iso2);
  }
  onCountryChange(obj) {
    this.form.get('phoneCountryCode').patchValue(obj.iso2);
  }

}

interface CustomInput {
  'input_name': string;
  'input_type': string;
  'input_options': Array<any>;
  'required': string;
  'placeholder':string;
}
