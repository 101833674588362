import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { timeout, catchError } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { GlobalsService } from '../globals';

@Injectable()
export class LocalizationService {

  latLng = new Subject();
  ourLatLng = this.latLng.asObservable();

  private ipInfo: any = { latitude: '', longitude: '' };
  private ipApi = 'https://api.ipstack.com';
  private ipApiKey = '32cade163a416ace6886e9452590e321';
  private options = {
    enableHighAccuracy: true,
    maximumAge: 20000,
    timeout: 500
  };
  constructor(
    private http: HttpClient,
    private globals: GlobalsService
  ) {
    let defLocation = { latitude: '', longitude: ''};
    this.load().then(result => {
      this.ipInfo = result;
      sessionStorage.setItem('geoData_IpStack', JSON.stringify(result));
      this.latLng.next(result);
    }).catch(error => {
      console.log(`catch error ${error} and return default location`);
      this.ipInfo = defLocation;
      sessionStorage.removeItem('geoData_IpStack');
      this.latLng.next(defLocation);
    });
  }

  public getIpInfo(): any {
    return this.ipInfo;
  }

  public getPhoneInputOptions(): Object {
    const IpInfo = this.getIpInfo();
    const countryCode = (IpInfo && IpInfo.country_code) ? IpInfo.country_code.toLowerCase() : '';
    return {initialCountry: countryCode, preferredCountries: ['us', 'il', 'es', 'fr']};
  }

  load() {
    return new Promise((resolve, reject) => {
      this.http.get(this.ipApi + '/check?access_key=' + this.ipApiKey)
        .pipe(
          timeout(3000),
          catchError(error => {
            return of(error);
          })
        )
        .subscribe((response: any) => {
          if (response && response.error) {
            const isIOS = !!this.globals.isIos();
            // console.log('is IOS', isIOS);
            // GET POSITION BY USER NAVIGATOR
            if (!isIOS) {
              if(window.navigator && window.navigator.geolocation) {
              navigator.geolocation.getCurrentPosition((pos) => {
                // console.log('geolocation resolve');
                resolve(pos.coords);
                }, (error) => {
                  // console.log('error', error);
                  switch (error.code) {
                    case 1:
                      // console.log('permissionDenied');
                      reject('permissionDenied');
                      break;
                    case 2:
                      // console.log('positionUnavailable');
                      reject('positionUnavailable');
                      break;
                    case 3:
                      // console.log('timeout');
                      reject('timeout');
                      break;
                  };
                  this.ipInfo = { latitude: '', longitude: '' };
                }, this.options);
              } else {
                reject('Unsupported browser');
              }
            } else {
              reject('Add NSLocationAlwaysUsageDescription into App');
            }
          } else {
            resolve(response);
          }
        }, error => {
          reject(error);
        });
    });
  }

}
