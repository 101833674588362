import { Component, OnInit, HostListener, AfterViewInit, OnDestroy } from '@angular/core';
import { NavbarService } from '../../services/navbar.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EventService } from '../../services/event.service';
import { GlobalsService } from '../../globals';
import { DialogService } from '../../services/dialog.service';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SalesmanDetailsComponent } from '../salesman-details/salesman-details.component';

import $ from 'jquery';
import {RudderstackService} from "../../services/rudderstack.service";
import {Subject} from "rxjs";

@Component({
  selector: 'app-analytic-dashboard',
  templateUrl: './analytic-dashboard.component.html',
  styleUrls: ['./analytic-dashboard.component.css']
})
export class AnalyticDashboardComponent implements OnInit, AfterViewInit, OnDestroy {

  analiticTypes: string[] = ['sales', 'insight', 'sale promoters', 'balance', 'scanned'];
  promoTypes: string[] = ['Active'];
  selectedPromoType: string;
  selectedAnalyticType: string;
  inputAnalyticType: string;
  statsData: any;
  event_id = 0;
  dataReceived = false;

  agesDataArr = [];
  barChartAgeData: any[] = [];
  barChartAgeLabels: string[] = [];
  genderDataArr = [];
  barChartGenderData: any[] = [];
  barChartGenderLabels: string[] = [];




  eventApprovedDataArr = [];
  eventPendingDataArr = [];
  eventWaitingDataArr = [];
  lineChartEventData: any[] = [];
  lineChartEventLabels: string[] = [];


  lineChartScannedLabelsByMinutes: string[] = [];
  eventScannedTotalByMinutesDataArr = [];
  lineChartScannedDataByMinutes: any[] = [];

  reRenderScanndeChartSubject: Subject<void> = new Subject<void>();

  lineChartOptions: any = {
    responsive: false,
    maintainAspectRatio: false,
    scales: {
      y: [{
        stacked: false
      }]
    },
    elements: {
      line: {
        tension: 0, // disables bezier curves
      }
    }
  };
  barChartType = 'bar';
  lineChartType = 'line';
  barChartColors = [
    {
      backgroundColor: this.globals.graphsBackgroundColors,
      borderColor: this.globals.graphsBorderColors,
      borderWidth: 1,
      pointBackgroundColor: '#FF6465',
      pointBorderColor: '#FF6465',
      pointRadius: 3,
      pointHoverRadius: 3,
      pointBorderWidth: 1,
      pointHoverBorderWidth: 1,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#FF6465'
    }
  ];
  showSearchInput = false;
  showPromCat: boolean;
  isMobile: boolean;
  windowWidth = null;

  dialogRef: MatDialogRef<any>;
  filterText;
  limitsMax: number = 50;

  constructor(
    private navbar: NavbarService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private eventService: EventService,
    private globals: GlobalsService,
    public rudderstackService: RudderstackService,
    private dialogService: DialogService,
    private dialog: MatDialog
  ) {
    // this.selectedAnalyticType = 'sales';
    this.selectedPromoType = 'Active';

    $(document).ready(() => {
      $('#resizing_select').change(() => {
        $('#width_tmp_option').html($('#resizing_select option:selected').text());
        // console.log('selected width is', $('#width_tmp_select').width());
        $('#resizing_select').width($('#width_tmp_select').width() + 30);
      });
    });
  }

  @HostListener('window:resize', ['$event']) onResizeWindow(event) {
    this.windowWidth = window.innerWidth;
    this.isMobile = this.windowWidth <= 768;
    this.resizeSelected();
  }

  setSizeOption(value) {
    $('#width_tmp_option').html(value);
    $('#resizing_select').width($('#width_tmp_select').width() + 30);
    // console.log('resizing_select', value, $('#width_tmp_select').width(), $('#resizing_select').width());
  }

  resizeSelected() {
        $('#width_tmp_option').html($('#resizing_select option:selected').text());
        // console.log('selected width is', $('#width_tmp_select').width());
        $('#resizing_select').width($('#width_tmp_select').width() + 30);
  }

  setPromoType(value) {
    this.selectedPromoType = value;
  }

  closePage() {
    this.router.navigate(['/dashboard']);
  }

  setAnalyticType(value, clearCache: boolean = false) {
    // this.selectedAnalyticType = value;
    this.globals.startLoader();
    if (value === 'sale promoters') {
      this.getDashboardData('sellers', clearCache).then(getStats => {
        this.globals.stopLoader();
        if (getStats) {
          // console.log('getStats', getStats, this.statsData);
          this.selectedAnalyticType = value;
        }
      });
    } else {
      this.getDashboardData(value, clearCache).then(getStat => {
        this.globals.stopLoader();
        if (getStat) {
          // console.log('getStats', getStat, this.statsData);
          this.selectedAnalyticType = value;
        }
      });
    }

  }

  // emit for analytic-tickets component
  fetchAnalytics(type) {
    this.setAnalyticType(type, true);
  }

  // emit for scanned-tickets component
  fetchScanned(type) {
    this.setAnalyticType(type, true);
  }

  ngAfterViewInit() {
    // document.getElementById('footer').style.display = 'none';
    this.resizeSelected();
  }

  ngOnInit() {
    this.navbar.setIsNewUi(true);
    this.navbar.hideHeader();
    setTimeout(() => {this.navbar.noPadding = 'removePadding';}, 200);
    this.navbar.hide();
    this.activatedRoute.params.subscribe(params => {
      this.event_id = +params['event_id'];
    });
    this.activatedRoute.queryParams.subscribe(params => {
      this.inputAnalyticType = params['type']; // ?type=sales, scanned, ... etc.
    });
    // console.log('get data ', this.event_id);
    if (this.event_id) {
          if (this.inputAnalyticType !== undefined) {
            this.setAnalyticType(this.inputAnalyticType)
          }
    } else {
      this.router.navigate(['/']);
    }
    this.rudderstackService.page('Event management','Analytics');
  }

  async getDashboardData(analyticsType, clearCache) {
    return await this.getDashboardStats(analyticsType, clearCache);
  }

  getDashboardStats(type, clearCache) {
    return new Promise((resolve) => {
      this.eventService.getDashboardStats(this.event_id, type, clearCache)
        .subscribe(
          (response: any) => {
            this.barChartAgeLabels = [];
            this.agesDataArr = [];
            this.barChartGenderLabels = [];
            this.genderDataArr = [];
            this.barChartGenderData = [];
            this.barChartAgeData = [];

            this.lineChartEventLabels = [];
            this.eventApprovedDataArr = [];
            this.eventPendingDataArr = [];
            this.eventWaitingDataArr = [];
            this.lineChartEventData = [];


            this.statsData = response.data;
            // TODO - for development purpose, Fake data
            // this.statsData = {
            //   "age_stat": {
            //     "0-20": 25,
            //     "21-24": 1,
            //     "25-30": 10,
            //     "31-34": 5,
            //     "35-40": 10,
            //     "41-50": 10,
            //     "51-60": 0,
            //     "61-70": 0,
            //     "unknown": 2
            //   },
            //   "gender_stat": [
            //     1,
            //     3,
            //     6
            //   ],
            //   "main_statistic": {
            //   "total_views": 76,
            //     "return_costumer": 0,
            //     "convertion_rate": 2.6316
            //   },
            //   "balance": {
            //     "total_balance": 22310,
            //     "available_now": 1220,
            //     "next_payment_date": '2021-11-03 15:28:55',
            //     "next_payment_amount": 0,
            //     "redrew_amount": 1,
            //     "income": 22310,
            //     "outcome": 0,
            //     "frozen": 0,
            //     "total_cred": 22310,
            //     "total_cash": 0,
            //     "pending_withdraw": 0
            //   },
            //   "banks": [
            //     {
            //       "id": 10493,
            //       "user_id": 805894,
            //       "event_id": 162736,
            //       "country": "il",
            //       "city": null,
            //       "bank_name": null,
            //       "aba_number": null,
            //       "bank_id": 20,
            //       "branch": "571",
            //       "account_number": "327799",
            //       "account_name": "מרדכי לנדסמן",
            //       "recipient_phone": null,
            //       "phone_country_code": "",
            //       "company_user_id": 300340379,
            //       "producer_email": "circleofdreamsevents@gmail.com",
            //       "producer_phone": "+972545266428",
            //       "producer_name": "Circle Of Dreams",
            //       "address": null,
            //       "agreement_date": "2021-10-31 17:34:46",
            //       "agreement": 1,
            //       "created_at": "2021-10-31 17:34:46",
            //       "updated_at": "2021-11-09 06:10:29",
            //       "bank_type": 0
            //     }
            //   ],
            //   "expenses": {
            //     "rental": 0,
            //     "equipment": 0,
            //     "redrew": 0,
            //     "sms": 0,
            //     "total_cred": 22310,
            //     "total_cash": 0
            //   },
            //   "coupons": [
            //     {
            //       "id": 17857,
            //       "event_id": 162736,
            //       "name": "הנחה",
            //       "name_en": "",
            //       "code": "79fc4eae",
            //       "quantity": 300,
            //       "remaining": 292,
            //       "discount": 10,
            //       "discount_type": 3,
            //       "start_date": "2021-11-01 15:02:00",
            //       "end_date": "2021-11-27 20:00:00",
            //       "status": 1,
            //       "created_at": "2021-11-01 15:02:45",
            //       "updated_at": "2021-11-01 15:26:21"
            //     },
            //     {
            //       "id": 17859,
            //       "event_id": 162736,
            //       "name": "כרטיס חינם",
            //       "name_en": "",
            //       "code": "f5812e0b",
            //       "quantity": 250,
            //       "remaining": 243,
            //       "discount": 100,
            //       "discount_type": 1,
            //       "start_date": "2021-11-01 15:25:00",
            //       "end_date": "2021-11-27 19:00:00",
            //       "status": 1,
            //       "created_at": "2021-11-01 15:26:21",
            //       "updated_at": "2021-11-01 15:26:21"
            //     }
            //   ],
            //   "sale_rounds": [
            //     {
            //       "pending": "0",
            //       "approved": "5",
            //       "decline": "0",
            //       "refund": "0",
            //       "rejected_tran": "0",
            //       "rejected_refund": "0",
            //       "hidden": "0",
            //       "pending_payment": "0",
            //       "outer_pur": "0",
            //       "item_count": "5",
            //       "cred_c": "5",
            //       "free_c": "0",
            //       "cash_c": "0",
            //       "register_c": "0",
            //       "cred_sum": "550.00",
            //       "cash_sum": "0.00",
            //       "item_name": "סבב א (הגעה עד השעה 18:00)"
            //     },
            //     {
            //       "pending": "0",
            //       "approved": "54",
            //       "decline": "2",
            //       "refund": "0",
            //       "rejected_tran": "0",
            //       "rejected_refund": "0",
            //       "hidden": "0",
            //       "pending_payment": "7",
            //       "outer_pur": "0",
            //       "item_count": "54",
            //       "cred_c": "50",
            //       "free_c": "4",
            //       "cash_c": "0",
            //       "register_c": "0",
            //       "cred_sum": "5440.00",
            //       "cash_sum": "0.00",
            //       "item_name": "ציפורים מקדימות (הגעה אחרי השעה 18:00)"
            //     },
            //     {
            //       "pending": "0",
            //       "approved": "129",
            //       "decline": "0",
            //       "refund": "0",
            //       "rejected_tran": "4",
            //       "rejected_refund": "0",
            //       "hidden": "0",
            //       "pending_payment": "26",
            //       "outer_pur": "0",
            //       "item_count": "129",
            //       "cred_c": "126",
            //       "free_c": "3",
            //       "cash_c": "0",
            //       "register_c": "0",
            //       "cred_sum": "11330.00",
            //       "cash_sum": "0.00",
            //       "item_name": "ציפורים מקדימות (הגעה עד השעה 18:00)"
            //     }
            //   ],
            //   "purchase_status": {
            //     "approved_count": 188,
            //     "declined_count": 2,
            //     "hidden_count": 0,
            //     "abandon_count": 33,
            //     "refund_count": 0
            //   },
            //   "purchase_times": [
            //     {
            //       "date_view": "2023-05-27T12:00:00Z",
            //       "pending": 0,
            //       "approved": 27
            //     },
            //     {
            //       "date_view": "2023-05-21T12:00:00Z",
            //       "pending": 0,
            //       "approved": 41
            //     },
            //     {
            //       "date_view": "2023-05-24T12:00:00Z",
            //       "pending": 0,
            //       "approved": 33
            //     }
            //   ],
            //   "event_sellers_stat_main": [
            //     {
            //       "salesman_id": 5,
            //       "seller_name": "Getin Salesman",
            //       "seller_social_img": "",
            //       "seller_profile_img": "",
            //       "event_seller": 4759,
            //       "type": "1",
            //       "views": 33632,
            //       "total_cred": "5",
            //       "total_cash": "0",
            //       "total_free": "1",
            //       "total_register": "0",
            //       "pending_income": "0",
            //       "income": "0",
            //       "cred_sum": "490.00",
            //       "cash_sum": "0.00",
            //       "total_money": 490,
            //       "currentUser": false,
            //       "coupons_quantity": 0,
            //       "coupons_remaining": 0
            //     },
            //     {
            //       "salesman_id": 160,
            //       "seller_name": "Moti Landesman",
            //       "seller_social_img": "http://test1.get-in.com/profile/152_1629970088_61275ea8d1796.png",
            //       "seller_profile_img": "http://test1.get-in.com/profile/152_1629970088_61275ea8d1796.png",
            //       "event_seller": 4759,
            //       "type": "3",
            //       "views": 442,
            //       "total_cred": "30",
            //       "total_cash": "0",
            //       "total_free": "1",
            //       "total_register": "0",
            //       "pending_income": "0",
            //       "income": "0",
            //       "cred_sum": "2740.00",
            //       "cash_sum": "0.00",
            //       "total_money": 2740,
            //       "currentUser": true,
            //       "coupons_quantity": 0,
            //       "coupons_remaining": 0
            //     }
            //   ],
            //   "scanned_data": {
            //   "scanned": 398,
            //     "unscanned": 80,
            //     "views": 477
            // },
            //   "reader_list": [
            //   {
            //     "total_reader_count": "106",
            //     "total_auth_user_reader_count": "0",
            //     "title": "תמר",
            //     "reader_id": 9751
            //   },
            //   {
            //     "total_reader_count": "86",
            //     "total_auth_user_reader_count": "0",
            //     "title": "מיכל הלפרין",
            //     "reader_id": 9752
            //   },
            //   {
            //     "total_reader_count": "117",
            //     "total_auth_user_reader_count": "0",
            //     "title": "דניאל סגל",
            //     "reader_id": 9753
            //   },
            //   {
            //     "total_reader_count": "89",
            //     "total_auth_user_reader_count": "0",
            //     "title": "אביגיל",
            //     "reader_id": 9754
            //   }
            // ],
            //   "scanned_graph": {
            //   "list": [
            //     {
            //       "total": "398",
            //       "date_filter": "20210918",
            //       "date_filters": "20210918",
            //       "date_view": "18/09"
            //     }
            //   ],
            //     "all_count": 398
            // },
            //   "event_currency": "ILS",
            //   "type": 3
            // };

            // SCANNED TAB with guest_manager_table
            // this.statsData = {
            //     "main_statistic": {
            //     "total_views": 76,
            //       "return_costumer": 0,
            //       "convertion_rate": 2.6316
            //     },
            //     "event_currency": "ILS",
            //   "checkins_by_ticket_type_tickets":{
            //     "2257": {
            //       "ticket_name": "reg3",
            //       "checked_in": 0,
            //       "checked_out": 0,
            //       "no_show": 6,
            //       "total": 6,
            //       "arrived": 0
            //     },
            //     "2262": {
            //       "ticket_name": "REg6",
            //       "checked_in": 0,
            //       "checked_out": 0,
            //       "no_show": 1,
            //       "total": 1,
            //       "arrived": 0
            //     }
            //   },
            //   "checkins_by_ticket_type_totals": {
            //     "checked_in": 0,
            //     "checked_out": 0,
            //     "no_show": 7,
            //     "total": 7,
            //     "arrived": 0
            //   },
            //   "reader_ticket_list": [
            //     {
            //       "id": 2334,
            //       "event_id": 8633,
            //       "name": "3333",
            //       "name_en": "",
            //       "quantity": 33333,
            //       "remaining": 33328,
            //       "limits": 3,
            //       "price": 2,
            //       "start_date": "2024-03-26 18:29:00",
            //       "end_date": "2024-03-29 18:29:00",
            //       "reg": 0,
            //       "status": 1,
            //       "comment": "",
            //       "created_at": "2024-03-26 20:30:18",
            //       "updated_at": "2024-03-26 21:25:28",
            //       "sort": 0,
            //       "donation_type": 0,
            //       "donation_min": 0,
            //       "donation_max": 0,
            //       "donation_ticket_approved": 0,
            //       "waiting_list": 0
            //     },
            //     {
            //       "id": 2335,
            //       "event_id": 8633,
            //       "name": "test",
            //       "name_en": "",
            //       "quantity": 3333,
            //       "remaining": 3330,
            //       "limits": 3,
            //       "price": 0,
            //       "start_date": "2024-03-26 18:29:00",
            //       "end_date": "2024-03-29 18:29:00",
            //       "reg": 1,
            //       "status": 1,
            //       "comment": "",
            //       "created_at": "2024-03-26 20:30:18",
            //       "updated_at": "2024-03-26 21:25:28",
            //       "sort": 0,
            //       "donation_type": 0,
            //       "donation_min": 0,
            //       "donation_max": 0,
            //       "donation_ticket_approved": 0,
            //       "waiting_list": 1
            //     }
            //   ],
            //   "guest_manager_table": true,
            //   "reader_list": [
            //     {
            //       "title": "baza",
            //       "id": "61781",
            //       "tickets": [
            //         {
            //           "ticket_name": "3333",
            //           "ticket_id": 2334,
            //           "scan_count": 1
            //         },
            //         {
            //           "ticket_name": "test",
            //           "ticket_id": 2335,
            //           "scan_count": 0
            //         }
            //       ]
            //     },
            //     {
            //       "title": "",
            //       "id": "61783",
            //       "tickets": [
            //         {
            //           "ticket_name": "test",
            //           "ticket_id": 2335,
            //           "scan_count": 6
            //         }
            //       ]
            //     }
            //
            //   ],
            //   "scanned_data": {
            //     "scanned": 2,
            //     "unscanned": 4,
            //     "views": 6
            //   },
            //   "event_editable": true,
            //   "type": 0
            // };
            if (this.statsData && this.statsData.coupons) {
              for (const coupon of this.statsData.coupons) {
                coupon.convertedDiscountType = this.globals.translateDiscount(coupon.discount_type);
              }
            }
            if (this.statsData && this.statsData.discount_list) {
              for (const discount of this.statsData.discount_list) {
                discount.dis_type = this.globals.translateDiscountMethod(discount.discount_method);
                discount.discount_type = this.globals.translateDiscountMethod(discount.discount_type);
              }
            }
            if (this.statsData && this.statsData.event_sellers_stat_main === null) {
              this.statsData.event_sellers_stat_main = [];
            }
            const data = response.data;
            // console.log('getEventGraphs', data);
            // TODO - for development purpose, INSIGHT chart data
            // data.age_stat = {
            //   "0-20": 25,
            //   "21-24": 1,
            //   "25-30": 10,
            //   "31-34": 5,
            //   "35-40": 10,
            //   "41-50": 10,
            //   "51-60": 0,
            //   "61-70": 0,
            //   "unknown": 2
            // };
            // data.gender_stat = [
            //   1,
            //   3,
            //   6
            // ];
            // data.main_statistic = {
            //   "total_views": 76,
            //   "return_costumer": 0,
            //   "convertion_rate": 2.6316
            // }
            // data.scanned_graph = {
            //   "list": [
            //     // { // Reader format
            //     //   "date_filter": "20240326",
            //     //   "date_filters": "20240326",
            //     //   "date_view": "26/03",
            //     //   "total": 1
            //     // },
            //     // {
            //     //   "date_filter": "20240627",
            //     //   "date_filters": "20240627",
            //     //   "date_view": "27/06",
            //     //   "total": 2
            //     // },
            //     { // GM format
            //       "date_filter": "2024-08-03 00:45:00",
            //       "total": 33
            //     },                {
            //       "date_filter": "2024-08-03 00:50:00",
            //       "total": 46
            //     },
            //   ],
            //   "all_count": 2
            // };
            const currentLocaleId = this.globals.getLocaleId();
            for (const key in data.age_stat) {
              if (data.age_stat.hasOwnProperty(key)) {
                this.barChartAgeLabels.push(key);
                this.agesDataArr.push(data.age_stat[key]);
              }
            }
            for (const key in data.gender_stat) {
              if (data.gender_stat.hasOwnProperty(key)) {
                this.barChartGenderLabels.push(this.globals.genders[key][currentLocaleId]);
                this.genderDataArr.push(data.gender_stat[key]);
              }
            }


            // for (const key in data.scanned_graph.list) {
            //   if (data.genders && data.genders.hasOwnProperty(key)) {
            //     this.lineChartEventLabels.push(data.scanned_graph.list[key].date_view);
            //     this.eventApprovedDataArr.push(data.scanned_graph.list[key].total);
            //     // this.eventPendingDataArr.push(data.scanned_graph.list[key].pending);
            //   }
            // }
            // TODO delete below test data, for development only
            // data.purchase_times = [
            //   {"date_view": "2024-06-12 15:00:00","pending": 0,"approved": 1,"waiting_list": 0},
            //   {"date_view": "2024-06-13 18:00:00","pending": 1,"approved": 8,"waiting_list": 0},
            //   {"date_view": "2024-06-15 15:00:00","pending": 2,"approved": 2,"waiting_list": 0},
            //   {"date_view": "2024-06-17 12:00:00","pending": 3,"approved": 1,"waiting_list": 0},
            //   {"date_view": "2024-06-17 12:00:00","pending": 3,"approved": 1,"waiting_list": 1},
            // ];
            let approvedCount = 0, pendingCount = 0, waiting_listCount = 0;
            for (const key in data.purchase_times) {
              // if (data.genders && data.genders.hasOwnProperty(key)) {
                this.lineChartEventLabels.push(data.purchase_times[key].date_view);
                // null options used for spanGaps
                this.eventApprovedDataArr.push(data.purchase_times[key].approved);
                this.eventPendingDataArr.push(data.purchase_times[key].pending);
                this.eventWaitingDataArr.push(data.purchase_times[key].waiting_list);
                approvedCount += +data.purchase_times[key].approved;
                pendingCount += +data.purchase_times[key].pending;
                waiting_listCount += +data.purchase_times[key].waiting_list;
              // }
            }

            // Reader old format + GM format
            if (data && data.scanned_graph && data.scanned_graph.list) {
              this.lineChartScannedLabelsByMinutes = [];
              this.eventScannedTotalByMinutesDataArr = [];
              for (const key in data.scanned_graph.list) {
                this.lineChartScannedLabelsByMinutes.push(data.scanned_graph.list[key].date_filter);
                this.eventScannedTotalByMinutesDataArr.push(data.scanned_graph.list[key].total);
              }
            }
            // console.log('scanned_graph', data.scanned_graph);
            const approvedLabel = (currentLocaleId === 'he') ? 'מאושרים' : 'Approved';
            const pendingLabel = (currentLocaleId === 'he') ? 'ממתינים' : 'Pending approval';
            const waitingLabel = 'Waiting list';
            const scannedLabel = (currentLocaleId === 'he') ? 'Total' : 'Total';
            if (this.agesDataArr.length > 0) { this.barChartAgeData.push({ data: this.agesDataArr, backgroundColor: [ '#FF6465'] }) };
            if (this.genderDataArr.length > 0) { this.barChartGenderData.push({ data: this.genderDataArr, borderWidth: 0, borderColor: '#36A2EB', backgroundColor: [ '#999999', '#ffffff', '#FF6465'], }) };
            if (this.eventApprovedDataArr.length > 0 && approvedCount) {
              this.lineChartEventData.push({ data: this.eventApprovedDataArr, label: approvedLabel, fill: false, tension: 0.1 })
            };
            if (this.eventPendingDataArr.length > 0 && pendingCount) {
              this.lineChartEventData.push({ data: this.eventPendingDataArr, label: pendingLabel, fill: false, tension: 0.1 })
            };
            if (this.eventWaitingDataArr.length > 0 && waiting_listCount) {
              this.lineChartEventData.push({ data: this.eventWaitingDataArr, label: waitingLabel, fill: false, tension: 0.1 })
            };

            if(this.lineChartEventData.length) {
                let arr = [];
                this.lineChartEventData.forEach(d => {  arr = arr.concat(d.data);})
                this.limitsMax = Math.max(...arr);
            }

            if (this.eventScannedTotalByMinutesDataArr.length > 0) {
              this.lineChartScannedDataByMinutes = [];
              this.lineChartScannedDataByMinutes.push({
                data: this.eventScannedTotalByMinutesDataArr,
                // fill: true,
                elements: {
                  point: {
                    radius: 4,
                    borderColor: '#B8A6CC',
                    backgroundColor: '#ffffff'
                  },
                  line: {
                    backgroundColor: 'rgba(255,255,255,0.2)',
                    borderColor: '#B8A6CC',
                    borderWidth: 1
                  }
                },
                pointBackgroundColor: '#252045',
                pointBorderColor: '#B8A6CC',
                pointHoverRadius: 5,
                tension: 0.2 })
            };
            if(type =='scanned') {
              this.reRenderScanndeChartSubject.next();
            }
            this.dataReceived = true;
            resolve(true);
          },
          error => {
            this.dataReceived = true;
            if (error.code) {
              this.dialogService.alertsModal('event_stats', error.code, 5000, false, error.log_id);
            } else if (error.httpStatus === 500) {
              this.dialogService.alertsModal('errors', 4, 5000, false);
            } else {
              this.router.navigate(['/dashboard']);
            }
            resolve(false);
          });
    });
  }


  toggleSearch() {
    this.showSearchInput = !this.showSearchInput;
  }

  toggleShowCat() {

  }

  searchFilter(e) {
    const searchString = e.target.value.toLowerCase().trim();
  }

  // openSellerDialog(salesmanData: any, extraData: any) {
  //   this.dialogService.salesmanStatsBreakdownDialog(salesmanData, extraData).subscribe(
  //     (response: any) => {
  //       if (response && response.data) {
  //         const index = this.statsData.all_event_sellers_stat.findIndex((obj: any) => {
  //           if (obj) { return obj.salesman_id === salesmanData[0].salesman_id }
  //         });
  //         this.statsData.all_event_sellers_stat[index].pending_income = response.data.u_pending_income;
  //         this.statsData.all_event_sellers_stat[index].income = response.data.u_income;
  //       } else if (response && response.error) {
  //         this.dialogService.alertsModal('event_stats', response.error.code, 5000, false, response.error.log_id)
  //       }
  //     },
  //     error => this.dialogService.alertsModal((error.code) ? 'event_stats' : 'errors', (error.code) ? error.code : 4, 5000, false, error.log_id)
  //   );
  // }

  // showSellerInfo(salesman_id: number) {
  //   this.eventService.getSellerInfo(salesman_id, this.event_id).subscribe(
  //     (response: any) => {
  //       const data = response.data;
  //       const extras = { 'eventId': this.event_id, 'userType': this.statsData.type, 'currencyCode': this.statsData.event_currency }
  //       this.openSellerDialog(data, extras);
  //     },
  //     error => {
  //       this.dialogService.alertsModal((error.code) ? 'event_stats' : 'errors', (error.code) ? error.code : 4, 5000, false, error.log_id);
  //     }
  //   );
  // }

  getDetails(sellerInfo) {
    // console.log('getDetails', sellerInfo);
    this.globals.startLoader();
    if (sellerInfo.salesman_type === 'salesman') {
      this.eventService.getSellerInfo(sellerInfo.salesman_id, this.event_id).subscribe(
        (response: any) => {
          this.globals.stopLoader();
          const data = response.data;
          const extras = { 'eventId': this.event_id, 'userType': this.statsData.type, 'currencyCode': this.statsData.event_currency };
          // const sellerInfo = this.statsData.event_sellers_stat_main.filter(seller => seller.salesman_id === salesman_id );
          this.openModalDialog(data, extras, sellerInfo);
        },
        error => {
          this.globals.stopLoader();
          this.dialogService.alertsModal((error.code) ? 'event_stats' : 'errors', (error.code) ? error.code : 4, 5000, false, error.log_id);
        }
      );
    }
    if (sellerInfo.salesman_type === 'producer') {
      this.eventService.getSellerProducerInfo(this.event_id).subscribe(
        (response: any) => {
          this.globals.stopLoader();
          const data = response.data;
          const extras = { 'eventId': this.event_id, 'userType': this.statsData.type, 'currencyCode': this.statsData.event_currency };
          this.openModalDialog(data, extras, sellerInfo);
        },
        error => {
          this.globals.stopLoader();
          this.dialogService.alertsModal((error.code) ? 'event_stats' : 'errors', (error.code) ? error.code : 4, 5000, false, error.log_id);
        }
      );
    }
  }

  getStatsDataReaderList(data) {
    this.statsData.reader_list[data.index].title = data.title;
  }

  // getSellerDetails(data) {
  //   console.log('getSellerDetails', data);
  //   const extras = { 'eventId': this.event_id, 'userType': this.statsData.type, 'currencyCode': this.statsData.event_currency }
  //   this.openModalDialog(data, extras);
  // }


  openModalDialog(salesmanData: any, extraData: any, sellerInfo: any) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig = {
      data: { salesmanData, extraData, sellerInfo },
      autoFocus: false,
      disableClose: true,
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      maxHeight: '100vh',
      backdropClass: 'full-width-ModalBackdrop',
      panelClass: 'full-width-dialog'
    };
    let dialogRef: MatDialogRef<any, any>;
    dialogRef = this.dialog.open(SalesmanDetailsComponent, dialogConfig);
  }

  ngOnDestroy() {
    // this.navbar.show();
    this.navbar.noPadding = '';
    this.navbar.setIsNewUi(false);
    this.navbar.showHeader();
    // console.log('ngOnDestroy navbar.show()');
  }

}
